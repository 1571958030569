import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  RXFORM_VOUCHER_USAGE, FILTER_TYPE_DATE_RANGE,
  RXSTATE_VOUCHER_USAGES, RXSTATE_VOUCHER_USAGE_PAGE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, STATUS_DISABLED,
  COLOR_DISABLED_ROW, COLOR_BACKGROUND,
  RXFIELD_VOUCHER_USAGE_VALID_FROM, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_VOUCHER_USAGE_VALID_UNTIL,
  RXFIELD_VOUCHER_USAGE_TYPE, FILTER_TYPE_DROPDOWN, VOUCHER_USAGE_TYPES,
  RXFIELD_VOUCHER_USAGE_ACTION_TYPE, VOUCHER_ACTION_TYPES,
  RXFIELD_VOUCHER_USAGE_VISIBILITY, visibilityStatuses, RXFIELD_VOUCHER_USAGE_MERCHANT,
  RXFIELD_VOUCHER_USAGE_APPLICABLE_AREA_MODE, VOUCHER_USAGE_APPLICABLE_AREA_MODES,
  RXFIELD_VOUCHER_USAGE_CATEGORY, RXFIELD_VOUCHER_USAGE_STATUS, status,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { SimpleDataShape } from '../../type';
import { rxFormVoucherUsage } from '../../validation';
import { toCurrency, toMoment } from '../../helper';

const VoucherUsagePage = ({
  downloading, merchants, loadingMerchant,
  handleSubmit, onAdvancedFilterPressed, onChangeMerchantText,
  onAppear, voucherCategories, loadingVoucherCategory,
  onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onDownloadPressed,
  onRefresh, onChangeCategoryText, selectedPageSize,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_VOUCHER_USAGES}
    uiPage={RXSTATE_VOUCHER_USAGE_PAGE}
    filterColumns={[
      {
        title: LocalizedString.voucherUsagePage.placeholderValidFrom,
        field: RXFIELD_VOUCHER_USAGE_VALID_FROM,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherUsagePage.placeholderValidUntil,
        field: RXFIELD_VOUCHER_USAGE_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherUsagePage.placeholderType,
        field: RXFIELD_VOUCHER_USAGE_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: VOUCHER_USAGE_TYPES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherUsagePage.placeholderActionType,
        field: RXFIELD_VOUCHER_USAGE_ACTION_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: VOUCHER_ACTION_TYPES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherUsagePage.placeholderVisibility,
        field: RXFIELD_VOUCHER_USAGE_VISIBILITY,
        type: FILTER_TYPE_DROPDOWN,
        data: visibilityStatuses,
      },
      {
        title: LocalizedString.voucherUsagePage.placeholderMerchant,
        field: RXFIELD_VOUCHER_USAGE_MERCHANT,
        type: FILTER_TYPE_DROPDOWN,
        data: merchants,
        loading: loadingMerchant,
        onChangeFilterText: onChangeMerchantText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherUsagePage.placeholderApplicableAreaMode,
        field: RXFIELD_VOUCHER_USAGE_APPLICABLE_AREA_MODE,
        type: FILTER_TYPE_DROPDOWN,
        data: VOUCHER_USAGE_APPLICABLE_AREA_MODES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherUsagePage.placeholderCategory,
        field: RXFIELD_VOUCHER_USAGE_CATEGORY,
        type: FILTER_TYPE_DROPDOWN,
        data: voucherCategories,
        loading: loadingVoucherCategory,
        onChangeFilterText: onChangeCategoryText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPage.placeholderStatus,
        field: RXFIELD_VOUCHER_USAGE_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: status,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.voucherUsagePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.voucherUsagePage.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.voucherUsagePage.labelMerchantName, field: 'merchant.name', sorting: !downloading },
      {
        title: LocalizedString.voucherUsagePage.labelTokenCount,
        field: 'tokenCount',
        sorting: false,
        render: ({ tokenCount }) => (tokenCount
          ? toCurrency(tokenCount) : 0),
      },
      {
        title: LocalizedString.voucherUsagePage.labelAvailable,
        field: 'availableTokenCount',
        sorting: false,
        render: ({ availableTokenCount }) => (availableTokenCount
          ? toCurrency(availableTokenCount) : 0),
      },
      {
        title: LocalizedString.voucherUsagePage.labelPurchased,
        field: 'purchasedTokenCount',
        sorting: false,
        render: ({ purchasedTokenCount }) => (purchasedTokenCount
          ? toCurrency(purchasedTokenCount) : 0),
      },
      {
        title: LocalizedString.voucherUsagePage.labelClaimed,
        field: 'claimedTokenCount',
        sorting: false,
        render: ({ claimedTokenCount }) => (claimedTokenCount
          ? toCurrency(claimedTokenCount) : 0),
      },
      {
        title: LocalizedString.voucherUsagePage.labelRevoked,
        field: 'revokedTokenCount',
        sorting: false,
        render: ({ revokedTokenCount }) => (revokedTokenCount
          ? toCurrency(revokedTokenCount) : 0),
      },
      {
        title: LocalizedString.voucherUsagePage.labelExpired,
        field: 'expiredTokenCount',
        sorting: false,
        render: ({ expiredTokenCount }) => (expiredTokenCount
          ? toCurrency(expiredTokenCount) : 0),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onDownloadPressed={onDownloadPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    title={LocalizedString.voucherUsagePage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
    customActions={[
      {
        icon: 'refresh',
        tooltip: GlobalLocalizedString.common.labelRefreshData,
        isFreeAction: true,
        onClick: () => onRefresh(selectedPageSize),
      },
    ]}
    enableSave
    rowStyle={({ status: itemStatus, validUntil }) => {
      const currDate = toMoment().format(DATE_TIME_FORMAT_WITHOUT_PIPE);
      const validDate = toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE);

      return ({
        backgroundColor: itemStatus === STATUS_DISABLED || toMoment(validDate).isBefore(currDate)
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      });
    }}
  >
    <div />
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_VOUCHER_USAGE,
  validate: rxFormVoucherUsage,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(VoucherUsagePage);

VoucherUsagePage.propTypes = {
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  voucherCategories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loadingMerchant: PropTypes.bool.isRequired,
  loadingVoucherCategory: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
};
