import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_CREATED_DATE, RXFIELD_DISCUSSION_COMMENT_COMMENT,
  RXFIELD_DISCUSSION_COMMENT_DISCUSSION, RXFIELD_DISCUSSION_COMMENT_DISCUSSION_ID,
  RXFIELD_DISCUSSION_COMMENT_DISCUSSION_NAME, RXFIELD_DISCUSSION_COMMENT_REACTION_ID,
  RXFIELD_DISCUSSION_COMMENT_REPLY_COUNT, RXFIELD_DISCUSSION_COMMENT_REPLY_TO_COMMENT,
  RXFIELD_DISCUSSION_COMMENT_REPLY_TO_ID, RXFIELD_DISCUSSION_COMMENT_USERNAME,
  RXFIELD_DISCUSSION_COMMENT_USER_ID, RXSTATE_DISCUSSION_COMMENTS, RXSTATE_DISCUSSION_COMMENT_PAGE,
  RXFORM_DISCUSION_COMMENT,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_USER_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderUserID}
          placeholder={LocalizedString.discussionCommentPage.placeholderUserID}
          disabled
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_USERNAME}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderFullName}
          placeholder={LocalizedString.discussionCommentPage.placeholderFullName}
          disabled
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_REPLY_COUNT}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderReplyCount}
          placeholder={LocalizedString.discussionCommentPage.placeholderReplyCount}
          disabled
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_COMMENT}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderComment}
          placeholder={LocalizedString.discussionCommentPage.placeholderComment}
          multiline
          disabled
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_REACTION_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderReactionID}
          placeholder={LocalizedString.discussionCommentPage.placeholderReactionID}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_DISCUSSION_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderDiscussionID}
          placeholder={LocalizedString.discussionCommentPage.placeholderDiscussionID}
          disabled
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_DISCUSSION_NAME}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderDiscussionName}
          placeholder={LocalizedString.discussionCommentPage.placeholderDiscussionName}
          multiline
          disabled
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_REPLY_TO_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderReplyToID}
          placeholder={LocalizedString.discussionCommentPage.placeholderReplyToID}
          disabled
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_DISCUSSION_COMMENT_REPLY_TO_COMMENT}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.discussionCommentPage.placeholderReplyToComment}
          placeholder={LocalizedString.discussionCommentPage.placeholderReplyToComment}
          multiline
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const DiscussionCommentPage = ({
  discussions, users,
  downloading, loadingDiscussion, loadingUser,
  handleSubmit, onAppear, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onChangeDiscussionText, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_DISCUSSION_COMMENTS}
    uiPage={RXSTATE_DISCUSSION_COMMENT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.discussionCommentPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.discussionCommentPage.placeholderReplyToID,
        field: RXFIELD_DISCUSSION_COMMENT_REPLY_TO_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.discussionCommentPage.placeholderUserName,
        field: RXFIELD_DISCUSSION_COMMENT_USERNAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.discussionCommentPage.placeholderDiscussion,
        field: RXFIELD_DISCUSSION_COMMENT_DISCUSSION,
        type: FILTER_TYPE_DROPDOWN,
        data: discussions,
        loading: loadingDiscussion,
        onChangeFilterText: onChangeDiscussionText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.discussionCommentPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.discussionCommentPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.discussionCommentPage.labelDiscussion, field: 'discussion.name', sorting: !downloading },
      {
        title: LocalizedString.discussionCommentPage.labelComment,
        field: 'comment',
        sorting: !downloading,
        render: ({ comment }) => (comment.length > 255 ? `${comment.slice(0, 254)}...` : comment),
      },
      {
        title: LocalizedString.discussionCommentPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.discussionCommentPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_DISCUSION_COMMENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DiscussionCommentPage);

DiscussionCommentPage.propTypes = {
  discussions: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingDiscussion: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeDiscussionText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
