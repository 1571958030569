import { INITIAL_ORDER_BY_CHARGING_STATIONS } from '../../constant';
import {
  DOWNLOADING_CHARGING_STATIONS, DOWNLOADING_DELETING_CHARGING_STATION,
  SET_CHARGING_STATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_CHARGING_STATION_SEARCH_TEXT, SET_CHARGING_STATION_SELECTED_ORDER_BY,
  SET_CHARGING_STATION_SELECTED_PAGE_SIZE, SET_CHARGING_STATION_TAPPED_ID,
  DOWNLOADING_CHARGING_STATION_CITIES, DOWNLOADING_CHARGING_STATION_PROVINCES,
} from '../action';


const initialState = {
  downloading: false,
  downloadingDeleting: false,
  downloadingChargingStationCity: false,
  downloadingChargingStationProvince: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_CHARGING_STATIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CHARGING_STATIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_CHARGING_STATION:
      return { ...state, downloadingDeleting: action.status };
    case DOWNLOADING_CHARGING_STATION_CITIES:
      return { ...state, downloadingChargingStationCity: action.status };
    case DOWNLOADING_CHARGING_STATION_PROVINCES:
      return { ...state, downloadingChargingStationProvince: action.status };
    case SET_CHARGING_STATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CHARGING_STATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CHARGING_STATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CHARGING_STATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CHARGING_STATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
