import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFORM_SCANNED_CODE,
  RXSTATE_SCANNED_CODES, RXSTATE_SCANNED_CODE_PAGE,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  RXFIELD_SCANNED_CODE_CHALLENGE, RXFIELD_SCANNED_CODE_CHALLENGE_CODE,
  RXFIELD_SCANNED_CODE_USER, RXFIELD_SCANNED_CODE_CHALLENGE_ID,
  RXFIELD_SCANNED_CODE_CHALLENGE_NAME, RXFIELD_SCANNED_CODE_CHALLENGE_LABEL,
  RXFIELD_SCANNED_CODE_USER_ID, RXFIELD_SCANNED_CODE_FULL_NAME, RXFIELD_SCANNED_CODE_POINT, RXFIELD_SCANNED_CODE_REMARK,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_CHALLENGE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelChallengeId}
            label={LocalizedString.scannedCodePage.labelChallengeId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_CHALLENGE_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelChallengeName}
            label={LocalizedString.scannedCodePage.labelChallengeName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_CHALLENGE_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelChallengeCode}
            label={LocalizedString.scannedCodePage.labelChallengeCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_CHALLENGE_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelChallengeLabel}
            label={LocalizedString.scannedCodePage.labelChallengeLabel}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelUserId}
            label={LocalizedString.scannedCodePage.labelUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_FULL_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelFullName}
            label={LocalizedString.scannedCodePage.labelFullName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_POINT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelPoint}
            label={LocalizedString.scannedCodePage.labelPoint}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SCANNED_CODE_REMARK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.labelCity}
            label={LocalizedString.scannedCodePage.labelCity}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
const ScannedCodePage = ({
  users, challenges,
  downloading,
  loadingUsers, loadingChallenge,
  handleSubmit, onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onChangeChallengeText, onChangeUserText,
  onChangePage, onChangePageSize,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged,
  onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_SCANNED_CODES}
    uiPage={RXSTATE_SCANNED_CODE_PAGE}
    filterColumns={[
      {
        title: LocalizedString.scannedCodePage.labelChallenge,
        field: RXFIELD_SCANNED_CODE_CHALLENGE,
        type: FILTER_TYPE_DROPDOWN,
        data: challenges,
        loading: loadingChallenge,
        onChangeFilterText: onChangeChallengeText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.scannedCodePage.labelChallengeCode,
        field: RXFIELD_SCANNED_CODE_CHALLENGE_CODE,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.scannedCodePage.labelCity,
        field: RXFIELD_SCANNED_CODE_REMARK,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.scannedCodePage.labelUser,
        field: RXFIELD_SCANNED_CODE_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.scannedCodePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.scannedCodePage.labelChallenge, field: 'challengeCode.challenge.title', sorting: !downloading },
      { title: LocalizedString.scannedCodePage.labelCode, field: 'challengeCode.code', sorting: !downloading },
      { title: LocalizedString.scannedCodePage.labelCodeLabel, field: 'label', sorting: !downloading },
      { title: LocalizedString.scannedCodePage.labelPoint, field: 'points', sorting: !downloading },
      { title: LocalizedString.scannedCodePage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.scannedCodePage.labelCity, field: 'remark', sorting: !downloading },
      {
        title: LocalizedString.scannedCodePage.labelScanDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.scannedCodePage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_SCANNED_CODE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ScannedCodePage);

ScannedCodePage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  challenges: PropTypes.arrayOf(SimpleDataShape).isRequired,

  downloading: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  loadingChallenge: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeChallengeText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
