import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid,
} from '@material-ui/core';
import { FunctionalPage } from '../../component';
import
{
  RXFIELD_GIIAS_MEDAN_SCANNED_CODE_TIME, RXFIELD_GIIAS_MEDAN_SCANNED_CODE_USERNAME,
  RXSTATE_GIIAS_MEDAN_SCANNED_CODES, RXSTATE_GIIAS_MEDAN_SCANNED_CODE_PAGE,
  FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, FILTER_TYPE_DROPDOWN,
  RXFORM_GIIAS_MEDAN_SCANNED_CODE, RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_CODE_LABEL,
  RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_USER_NAME,
  RXFIELD_GIIAS_MEDAN_CHALLANGE_CREATED_DATE,
  RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_USER_ID,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../../../type';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.placeholderChallangeUserId}
            label={LocalizedString.scannedCodePage.placeholderChallangeUserId}
            disabled
          />
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.placeholderChallangeUsername}
            label={LocalizedString.scannedCodePage.placeholderChallangeUsername}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_CODE_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.placeholderChallangeCodeLabel}
            label={LocalizedString.scannedCodePage.placeholderChallangeCodeLabel}
            disabled
          />
        </Grid>

        <Grid item>

          <Field
            name={RXFIELD_GIIAS_MEDAN_CHALLANGE_CREATED_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scannedCodePage.placeholderChallangeScanTime}
            label={LocalizedString.scannedCodePage.placeholderChallangeScanTime}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const GiiasMedanScannedCode = ({
  onAppear, downloading, onRefresh, onSortPressed, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed, onChangePage, onChangePageSize,
  loadingUsers, onSearchBarTextChanged, users, onChangeUserText, onViewPressed,
  onDownloadPressed,
}) => (
  <FunctionalPage
    onAppear={onAppear}
    data={RXSTATE_GIIAS_MEDAN_SCANNED_CODES}
    uiPage={RXSTATE_GIIAS_MEDAN_SCANNED_CODE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.scannedCodePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.scannedCodePage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.scannedCodePage.labelActivity, field: 'challengeCodeLabel', sorting: !downloading },
      { title: LocalizedString.scannedCodePage.labelScanTime, field: 'createdDate', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.scannedCodePage.placeholderScanTime,
        field: RXFIELD_GIIAS_MEDAN_SCANNED_CODE_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.scannedCodePage.placeholderUsername,
        field: RXFIELD_GIIAS_MEDAN_SCANNED_CODE_USERNAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    title={LocalizedString.scannedCodePage.title}
    useFullWidth
    disableDelete
    disableCreate
    disableEdit
    onRefresh={onRefresh}
    onSortPressed={onSortPressed}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onViewPressed={onViewPressed}
    onDownloadPressed={onDownloadPressed}
    enableSave
  >
    {
      renderDialogContent()
    }
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_GIIAS_MEDAN_SCANNED_CODE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(GiiasMedanScannedCode);

GiiasMedanScannedCode.propTypes = {
  onAppear: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
