import {
  addScannedCode, downloadingDeletingScannedCode,
} from '../simple-action';
import { downloadScannedCode } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingScannedCode(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiScannedCode;

    const challenge = await downloadScannedCode(tappedId, token);

    dispatch(addScannedCode(challenge));
  } finally {
    dispatch(downloadingDeletingScannedCode(false));
  }
};
