import { SET_COUPON_ACHIEVEMENT, CLEAR_COUPON_ACHIEVEMENT } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_COUPON_ACHIEVEMENT: {
      return action.data;
    }
    case CLEAR_COUPON_ACHIEVEMENT:
      return state;
    default:
      return state;
  }
};
