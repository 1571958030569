import { downloadMyConfigItems } from '../../../helper';
import { GARUDA_COMPANY_NAME, TANAMERA_COMPANY_NAME } from '../../../constant';
import { checkingCompanyName, setVoucherIsGarudaCompanyName, setVoucherIsTanameraCompanyName } from '../simple-action';

export default (companyName) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { data } = getState().vouchers;
  const { tappedId } = getState().uiVoucher;
  const voucherDetail = data[tappedId];

  try {
    dispatch(checkingCompanyName(true));
    if (companyName || tappedId) {
      const list = await downloadMyConfigItems(token);
      const found = Object.values(list).find((item) => item.value
        === (companyName || voucherDetail.merchant.name));

      switch (found?.name) {
        case GARUDA_COMPANY_NAME: {
          dispatch(setVoucherIsGarudaCompanyName(true));
          dispatch(setVoucherIsTanameraCompanyName(false));
          break;
        }
        case TANAMERA_COMPANY_NAME: {
          dispatch(setVoucherIsTanameraCompanyName(true));
          dispatch(setVoucherIsGarudaCompanyName(false));
          break;
        }
        default: {
          dispatch(setVoucherIsGarudaCompanyName(false));
          dispatch(setVoucherIsTanameraCompanyName(false));
        }
      }
    }
  } finally {
    dispatch(checkingCompanyName(false));
  }
};
