import { downloadIims2023Survey } from '../../../helper';
import { downloadingIims2023Survey, setIims2023Survey, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiIims2023Survey } = getState();

  const { token } = authentication;
  const { tappedId } = uiIims2023Survey;

  try {
    dispatch(downloadingIims2023Survey(true));

    const scannedCode = await downloadIims2023Survey(
      tappedId,
      token,
    );

    dispatch(setIims2023Survey(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingIims2023Survey(false));
  }
};
