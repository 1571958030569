import {
  addCarOwnershipReward, downloadingDeletingCarOwnershipReward, setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';
import { downloadCarOwnershipReward } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCarOwnershipReward(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCarOwnershipReward;

    const carOwnershipReward = await downloadCarOwnershipReward(tappedId, token);
    dispatch(addCarOwnershipReward(carOwnershipReward));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingCarOwnershipReward(false));
  }
};
