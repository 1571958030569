import { reset } from 'redux-form';
import {
  setAlertErrorMessage,
  settingPasswordUser, setUserSelectedOrderBy,
  setUserSelectedPageSize, setUserSetPasswordDialogVisibility,
} from '../simple-action';
import { setPasswordUser } from '../../../helper';
import { INITIAL_ORDER_BY_USERS, RXFORM_USER } from '../../../constant';
import downloadUsersAsync from './downloadUsersAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(settingPasswordUser(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiUser;

    await setPasswordUser(tappedId, values.newPassword, token);

    dispatch(reset(RXFORM_USER));
    dispatch(setUserSetPasswordDialogVisibility(false));

    dispatch(setUserSelectedPageSize(20));
    dispatch(setUserSelectedOrderBy(INITIAL_ORDER_BY_USERS));
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(settingPasswordUser(false));
  }
};
