import { downloadPointLeaderBoard, downloadUser } from '../../../helper';
import { downloadingPointLeaderBoard, setPointLeaderBoard } from '../simple-action';

const DEFAULT_START_RANK = 1;
const DEFAULT_COUNT = 10;

export default (startRank, count, startPeriod, endPeriod) => async (dispatch, getState) => {
  try {
    dispatch(downloadingPointLeaderBoard(true));

    const { token } = getState().authentication;

    const result = await downloadPointLeaderBoard(
      startRank || DEFAULT_START_RANK,
      count || DEFAULT_COUNT,
      startPeriod,
      endPeriod,
      token,
    );

    const resultUser = await Promise.all(result.map(async (x) => downloadUser(x.user.id, token)));

    const mergeResult = result.map(
      (x) => ({ ...x, email: resultUser.find((y) => y.id === x.user.id).email }),
    );

    dispatch(setPointLeaderBoard(mergeResult));
  } finally {
    dispatch(downloadingPointLeaderBoard(false));
  }
};
