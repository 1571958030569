import {
  downloadNewUserNotifications,
  transformSearchText,
} from '../../../helper';
import {
  downloadingNewUserNotifications,
  setNewUserNotifications,
} from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication,
    newUserNotifications,
    uiNewUserNotification,
  } = getState();
  const { token } = authentication;
  const { meta } = newUserNotifications;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiNewUserNotification;

  try {
    dispatch(downloadingNewUserNotifications(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadNewUserNotifications(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setNewUserNotifications(list));
    }
  } finally {
    dispatch(downloadingNewUserNotifications(false));
  }
};
