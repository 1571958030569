import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    carServiceMenu: {
      title: 'Car Service',
    },
    serviceRequestPage: {
      title: 'Service Request',

      labelNo: 'No',
      labelUser: 'User',
      labelRequestedDate: 'Requested Date',
      labelDealer: 'Dealer',
      labelRepairType: 'Repair Type',
      labelCreatedDate: 'Created Date',
      labelVehicle: 'Vehicle',

      placeholderUserId: 'User ID',
      placeholderUserName: 'User Name',
      placeholderDealerId: 'Dealer ID',
      placeholderDealerName: 'Dealer Name',
      placeholderRequestedDate: 'Requested Date',
      placeholderRepairType: 'Repair Type',
      placeholderCreatedDate: 'Created Date',
      placeholderLicensePlate: 'License Plate',
      placeholderVin: 'VIN',
      placeholderModel: 'Model',
      placeholderEngineNo: 'Engine Number',
      placeholderTrim: 'Trim',
      placeholderTransmission: 'Transmission',
      placeholderModelYear: 'Model Year',

      buttonCaptionDeleteServiceRequest: 'Delete Service Request',
    },
    reservationPage: {
      title: 'Reservation',

      labelNo: 'No',
      labelBookingId: 'Booking ID',
      labelDateAndTime: 'Date & Time',
      labelDealerName: 'Dealer Name',
      labelCustomerName: 'Customer Name',
      labelCustomerPhone: 'Customer Phone',
      labelMemberId: 'Member ID',
      labelEmail: 'Email',
      labelAddress: 'Address',
      labelCreatedDate: 'Created Date',
      labelRepairSelection: 'Repair Selection',
      labelVehicleModel: 'Vehicle Model',
      labelServiceInterval: 'Service Interval',
      labelDeliveryMethod: 'Delivery Method',
      labelPaymentMethod: 'Payment Method',
      labelPaymentAmount: 'Payment Amount',
      labelPaymentID: 'Payment ID',
      labelBookingStatus: 'Booking Status',
      labelRepairType: 'Repair Type',
      labelServiceStatus: 'Service Status',
      labelPaymenStatus: 'Payment Status',
      labelVIN: 'VIN',
      labelDealer: 'Dealer',
      labelUser: 'User',
      labelCustomerInfo: 'Customer Info',
      labelServiceInfo: 'Service Info',
      labelPicture: 'Picture',
      labelNote: 'Note',
      labelHours: 'Hours',
      labelMinutes: 'Minutes',
      labelSubTotal: 'Sub Total',
      labelTax: 'PPN 11%',
      labelPickupCharge: 'Pickup Charge',
      labelDeliveryCharge: 'Delivery Charge',
      labelDiscount: 'Discount (Voucher, Coin, Coupon)',
      labelGrandTotal: 'Grand Total',
      labelTotalTime: 'Total Time',
      labelLabors: 'Labors',
      labelParts: 'Parts',
      labelQuantity: 'Quantity',
      labelPrice: 'Price',
      labelLaborDetail: 'Labor Details',
      labelPartDetail: 'Part Details',
      labelAccessoriesDetail: 'Accessories Details',
      labelAdditionalDetail: 'Additional Details',
      labelAccessories: 'Accessories',
      labelAdditional: 'Additional',

      placeholderBookingId: 'Booking ID',
      placeholderDateAndTime: 'Date & Time',
      placeholderDealerName: 'Dealer Name',
      placeholderCustomerName: 'Customer Name',
      placeholderCustomerPhone: 'Customer Phone',
      placeholderCustomerLocation: 'Customer Location',
      placeholderCustomerType: 'Customer Type',
      placeholderContactPerson: 'Contact Person',
      placeholderContactPersonPhone: 'Contact Person Phone',
      placeholderCreatedDate: 'Created Date',
      placeholderPickupLocation: 'Pickup Location',
      placeholderDeliveryLocation: 'Delivery Location',
      placeholderBookingCreated: 'Booking Created',
      placeholderServiceScheduled: 'Service Scheduled',
      placeholderServiceStatus: 'Service Status',
      placeholderServiceFinishTime: 'Service Finish Time',
      placeholderArrivalTime: 'Arrival Time',
      placeholderRepairSelection: 'Repair Selection',
      placeholderRepairType: 'Repair Type',
      placeholderVehicleModel: 'Vehicle Model',
      placeholderServiceInterval: 'Service Interval',
      placeholderServiceDate: 'Service Date',
      placeholderDeliveryMethod: 'Delivery Method',
      placeholderPaymentMethod: 'Payment Method',
      placeholderPaymentAmount: 'Payment Amount',
      placeholderPaymentStatus: 'Payment Status',
      placeholderPaymentDate: 'Payment Date',
      placeholderPaymentTotal: 'Payment Total',
      placeholderBookingStatus: 'Booking Status',
      placeholderBookingCode: 'Booking Code',
      placeholderVin: 'VIN',
      placeholderDealer: 'Dealer',
      placeholderUser: 'User',
      placeholderOdometer: 'Odometer',
      placeholderImage1: 'Image 1',
      placeholderImage2: 'Image 2',
      placeholderImage3: 'Image 3',
      placeholderImage4: 'Image 4',
      placeholderImage5: 'Image 5',
      placeholderReservationNotes: 'Reservation Notes',
      placeholderVaNumber: 'VA Number',
    },
    dealerPage: {
      title: 'Dealer',

      labelNo: 'No',
      labelCode: 'Dealer Code',
      labelName: 'Name',
      labelCity: 'City',
      labelProvince: 'Province',
      labelRegion: 'Region',
      labelDealerType: 'Dealer Type',
      labelChargingStation: 'Charging Station',
      labelStatus: 'Status',
      labelDealerStatus: 'Dealer Status',

      buttonCaptionOpenMap: 'Open Map',

      placeholderID: 'Dealer ID',
      placeholderCode: 'Dealer Code',
      placeholderName: 'Name',
      placeholderAddress: 'Address',
      placeholderCity: 'City',
      placeholderProvince: 'Province',
      placeholderRegion: 'Region',
      placeholderPostalCode: 'Postal Code',
      placeholderEmail: 'Email',
      placeholderPhone: 'Phone',
      placeholderType: 'Type',
      placeholderDealerStatus: 'Dealer Status',
      placeholderDealerType: 'Dealer Type',
      placeholderDealerTypeCode: 'Dealer Type Code',
      placeholderChargingStation: 'Charging Station',
      placeholderLat: 'Latitude',
      placeholderLong: 'Longitude',
    },
    chargingStationPage: {
      title: 'Charging Station',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelProvince: 'Province',
      labelRegion: 'Region',
      labelChargerType: 'Charger Type',
      labelCategory: 'Category',
      labelSattus: 'Status',
      labelStationId: 'Station ID',
      labelAddress: 'Address',
      labelPostalCode: 'Postal Code',
      labelType: 'Type',
      labelLatitude: 'Latitude',
      labelLongitude: 'Longitude',

      buttonCaptionOpenMap: 'Open Map',

      placeholderName: 'Name',
      placeholderStationId: 'Station ID',
      placeholderAddress: 'Address',
      placeholderCity: 'City',
      placeholderRegion: 'Region',
      placeholderPostalCode: 'Postal Code',
      placeholderType: 'Type',
      placeholderCategory: 'Category',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
    },
    evChargingUrlPage: {
      title: 'EV Charging URL',

      labelNo: 'No',
      labelCode: 'Code',
      labelNotes: 'Notes',
      labelURL: 'URL',
      labelCreatedDate: 'Created Date',

      placeholderCode: 'Code',
      placeholderNotes: 'Notes',
      placeholderURL: 'URL',
      placeholderCreatedDate: 'Created Date',

      buttonCaptionCreateNewEvChargingUrl: 'Create New EV Charging URL',
      buttonCaptionEditEvChargingUrl: 'Edit EV Charging URL',
      buttonCaptionDeleteEvChargingUrl: 'Delete EV Charging URL',
    },
  },
  id: {
    carServiceMenu: {
      title: 'Servis Mobil',
    },
    serviceRequestPage: {
      title: 'Permintaan Servis',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelRequestedDate: 'Tanggal Permintaan',
      labelDealer: 'Dealer',
      labelRepairType: 'Tipe Reparasi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelVehicle: 'Kendaraan',

      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderDealerId: 'ID Dealer',
      placeholderDealerName: 'Nama Dealer',
      placeholderRequestedDate: 'Tanggal Permintaan',
      placeholderRepairType: 'Tipe Reparasi',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderLicensePlate: 'No Polisi',
      placeholderVin: 'VIN',
      placeholderModel: 'Model',
      placeholderEngineNo: 'No Mesin',
      placeholderTrim: 'Trim',
      placeholderTransmission: 'Transmisi',
      placeholderModelYear: 'Tahun Model',

      buttonCaptionDeleteServiceRequest: 'Hapus Permintaan Servis',
    },
    reservationPage: {
      title: 'Reservasi',

      labelNo: 'No',
      labelBookingId: 'ID Pesanan',
      labelDateAndTime: 'Tanggal & Waktu',
      labelDealerName: 'Nama Dealer',
      labelCustomerName: 'Nama Pelanggan',
      labelCustomerPhone: 'Nomor Telepon Pelanggan',
      labelMemberId: 'ID Anggota',
      labelEmail: 'Email',
      labelAddress: 'Alamat',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelRepairSelection: 'Tipe Perbaikan',
      labelVehicleModel: 'Model Kendaraan',
      labelServiceInterval: 'Interval Servis',
      labelDeliveryMethod: 'Metode Pengiriman',
      labelPaymentMethod: 'Metode Pembayaran',
      labelPaymentAmount: 'Jumlah Pembayaran',
      labelPaymentID: 'ID Pembayaran',
      labelBookingStatus: 'Status Pemesanan',
      labelRepairType: 'Jenis Perbaikan',
      labelServiceStatus: 'Status Servis',
      labelPaymenStatus: 'Status Pembayaran',
      labelVIN: 'VIN',
      labelDealer: 'Dealer',
      labelUser: 'Pengguna',
      labelCustomerInfo: 'Info Pelanggan',
      labelServiceInfo: 'Info Servis',
      labelPicture: 'Gambar',
      labelNote: 'Catatan',
      labelHours: 'Jam',
      labelMinutes: 'Menit',
      labelSubTotal: 'Sub Total',
      labelTax: 'PPN 11%',
      labelPickupCharge: 'Biaya Penjemputan',
      labelDeliveryCharge: 'Biaya Pengantaran',
      labelDiscount: 'Potongan (Voucher, Coin, Coupon)',
      labelGrandTotal: 'Total Akhir',
      labelTotalTime: 'Total Waktu',
      labelLabors: 'Jasa',
      labelParts: 'Suku Cadang',
      labelQuantity: 'Kuantitas',
      labelPrice: 'Harga',
      labelLaborDetail: 'Detail Jasa',
      labelPartDetail: 'Detail Part',
      labelAccessoriesDetail: 'Detail Aksesoris',
      labelAdditionalDetail: 'Detail Tambahan',
      labelAccessories: 'Aksesoris',
      labelAdditional: 'Tambahan',

      placeholderBookingId: 'ID Pesanan',
      placeholderDateAndTime: 'Tanggal & Waktu',
      placeholderDealerName: 'Nama Dealer',
      placeholderCustomerName: 'Nama Pelanggan',
      placeholderCustomerPhone: 'No Telepon Pelanggan',
      placeholderCustomerLocation: 'Lokasi Pelanggan',
      placeholderCustomerType: 'Tipe Pelanggan',
      placeholderContactPerson: 'Nama Kontak',
      placeholderContactPersonPhone: 'No Telepon Kontak',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderPickupLocation: 'Lokasi Penjemputan',
      placeholderDeliveryLocation: 'Lokasi Pengiriman',
      placeholderBookingCreated: 'Pemesanan Dibuat',
      placeholderServiceScheduled: 'Penjadwalan Servis',
      placeholderServiceStatus: 'Status Servis',
      placeholderServiceFinishTime: 'Waktu Selesai Servis',
      placeholderArrivalTime: 'Waktu Kedatangan',
      placeholderRepairSelection: 'Tipe Reparasi',
      placeholderRepairType: 'Tipe Reparasi',
      placeholderVehicleModel: 'Model Kendaraan',
      placeholderServiceInterval: 'Interval Servis',
      placeholderServiceDate: 'Tanggal Servis',
      placeholderDeliveryMethod: 'Metode Pengiriman',
      placeholderPaymentMethod: 'Metode Pembayaran',
      placeholderPaymentAmount: 'Jumlah Pembayaran',
      placeholderPaymentStatus: 'Status Pembayaran',
      placeholderPaymentDate: 'Tanggal Pembayaran',
      placeholderPaymentTotal: 'Total Pembayaran',
      placeholderBookingStatus: 'Status Pemesanan',
      placeholderBookingCode: 'Kode Pemesanan',
      placeholderVin: 'VIN',
      placeholderDealer: 'Dealer',
      placeholderUser: 'Pengguna',
      placeholderOdometer: 'Odometer',
      placeholderImage1: 'Gambar 1',
      placeholderImage2: 'Gambar 2',
      placeholderImage3: 'Gambar 3',
      placeholderImage4: 'Gambar 4',
      placeholderImage5: 'Gambar 5',
      placeholderReservationNotes: 'Catatan Reservasi',
      placeholderVaNumber: 'Nomor VA',
    },
    dealerPage: {
      title: 'Dealer',

      labelNo: 'No',
      labelCode: 'Kode Dealer',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelRegion: 'Wilayah',
      labelDealerType: 'Tipe Dealer',
      labelChargingStation: 'Stasiun Pengisian',
      labelStatus: 'Status',
      labelDealerStatus: 'Status Dealer',

      buttonCaptionOpenMap: 'Buka Peta',

      placeholderID: 'ID Dealer',
      placeholderCode: 'Kode Dealer',
      placeholderName: 'Nama',
      placeholderAddress: 'Alamat',
      placeholderCity: 'Kota',
      placeholderProvince: 'Provinsi',
      placeholderRegion: 'Wilayah',
      placeholderPostalCode: 'Kode Pos',
      placeholderEmail: 'Email',
      placeholderPhone: 'Nomor Telepon',
      placeholderType: 'Tipe',
      placeholderDealerStatus: 'Status Dealer',
      placeholderDealerType: 'Tipe Dealer',
      placeholderDealerTypeCode: 'Tipe Kode Dealer',
      placeholderChargingStation: 'Stasiun Pengisian',
      placeholderLat: 'Garis Bujur',
      placeholderLong: 'Garis Lintang',
    },
    chargingStationPage: {
      title: 'Stasiun Pengisian',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelRegion: 'Wilayah',
      labelChargerType: 'Tipe Pengisian',
      labelCategory: 'Kategori',
      labelSattus: 'Status',
      labelStationId: 'ID Stasiun',
      labelAddress: 'Alamat',
      labelPostalCode: 'Kode Pos',
      labelType: 'Tipe',
      labelLatitude: 'Garis Lintang',
      labelLongitude: 'Garis Bujur',

      buttonCaptionOpenMap: 'Buka Peta',

      placeholderName: 'Nama',
      placeholderStationId: 'ID Stasiun',
      placeholderAddress: 'Alamat',
      placeholderCity: 'Kota',
      placeholderRegion: 'Wilayah',
      placeholderPostalCode: 'Kode Pos',
      placeholderType: 'Tipe',
      placeholderCategory: 'Kategori',
      placeholderLatitude: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
    },
    evChargingUrlPage: {
      title: 'URL Pengisian Daya EV',

      labelNo: 'No',
      labelCode: 'Kode',
      labelNotes: 'Catatan',
      labelURL: 'URL',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderCode: 'Kode',
      placeholderNotes: 'Catatan',
      placeholderURL: 'URL',
      placeholderCreatedDate: 'Tanggal Pembuatan',

      buttonCaptionCreateNewEvChargingUrl: 'Buat URL Pengisian Daya EV Baru',
      buttonCaptionEditEvChargingUrl: 'Ubah URL Pengisian Daya EV',
      buttonCaptionDeleteEvChargingUrl: 'Hapus URL Pengisian Daya EV',
    },
  },
});

export default LocalizedString;
