import { INITIAL_ORDER_BY_CAR_VIEWING_VOUCHER_REWARD } from '../../constant';
import {
  DOWNLOADING_CAR_VIEWING_VOUCHER_REWARD, DOWNLOADING_CAR_VIEWING_VOUCHER_REWARDS,
  SET_CAR_VIEWING_VOUCHER_REWARD_ADVANCED_FILTER_STRING, SET_CAR_VIEWING_VOUCHER_REWARD_SEARCH_TEXT,
  SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_ORDER_BY,
  SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_PAGE_SIZE, SET_CAR_VIEWING_VOUCHER_REWARD_TAPPED_ID,
} from '../action/simple-action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_CAR_VIEWING_VOUCHER_REWARD,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CAR_VIEWING_VOUCHER_REWARDS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_CAR_VIEWING_VOUCHER_REWARD:
      return { ...state, downloadingDeleting: action.status };
    case SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CAR_VIEWING_VOUCHER_REWARD_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CAR_VIEWING_VOUCHER_REWARD_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CAR_VIEWING_VOUCHER_REWARD_ADVANCED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
