import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CardMedia, makeStyles } from '@material-ui/core';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import { onValue, ref } from 'firebase/database';
import useSound from 'use-sound';
import { db } from './firebase';
import { COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY } from '../../constant';

const logo = require('../../asset/iims.jpg');
const sound = require('../../asset/sound.mp3');


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
  },
  innerContainer: {
    maxWidth: 500,
    minWidth: 275,
  },
  card: {
    justifyContent: 'center',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 350,
    maxHeight: 500,
    borderRadius: 10,
    boxShadow:
      '0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 1px rgba(63, 63, 68, 0.05)',
    padding: '40px 0px 40px 0px',
  },
  topField: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  logo: {
    width: 100,
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: 'auto',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    minWidth: 250,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  imageList: {
    width: '100%',
    height: 800,
    justifyContent: 'center',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  bottomBar: {
    height: '100%',
    background: '#f0ece9',
    padding: 20,
  },
  bottomBarTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: 1.3,
    color: '#000',
  },
  bottomBarSubTitle: {
    fontSize: 150,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#000',
    lineHeight: 1.5,
  },
}));


const TvScreen = ({ onAppear }) => {
  useEffect(() => {
    onAppear();
  }, [onAppear]);


  const [cars, setCars] = useState([]);
  const [play] = useSound(sound);

  useEffect(() => {
    const playSound = () => play();

    const query = ref(db, 'cars');
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        playSound();
        setCars([]);
        Object.values(data).map((car) => {
          setCars((cars) => [...cars, car]);
        });
      }
    });
  }, [play]);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div><CardMedia component="img" className={classes.logo} image={logo} /></div>
        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}><h1 style={{ marginBottom: 0 }}>Hyundai Driving Experience Queue</h1></div>
        <div style={{ width: '100px' }}><Button style={{ display: 'none' }} onClick={play}>Click</Button></div>
      </div>
      <div className={classes.root}>
        {cars.length && (
          <ImageList
            rowHeight={380}
            cols={4}
            gap={20}
            className={classes.imageList}
          >
            {cars.map((item, index) => (
              <ImageListItem key={index}>
                <img src={item.img} alt={item.name} />
                <ImageListItemBar
                  classes={{
                    root: classes.bottomBar,
                    title: classes.bottomBarTitle,
                    subtitle: classes.bottomBarSubTitle,
                  }}
                  title={<span>{item.name}</span>}
                  subtitle={item.queue}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
        {/*

        <ImageList rowHeight={400} cols={3} gap={0} className={classes.imageList}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img src={item.img} alt={item.title} />
              <ImageListItemBar
                classes={{root: classes.bottomBar, title: classes.bottomBarTitle, subtitle: classes.bottomBarSubTitle}}
                title={item.title}
                subtitle={<span>{item.author}</span>}
              />
            </ImageListItem>
          ))}
        </ImageList> */}
      </div>
    </div>
  );
};

export default TvScreen;

TvScreen.propTypes = {
  onAppear: PropTypes.func.isRequired,
};
