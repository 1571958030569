import { addCouponPurchaseRequest, downloadingCouponPurchaseRequest } from '../simple-action';
import { downloadCouponPurchaseRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCouponPurchaseRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCouponPurchaseRequest;

    const purchaseRequest = await downloadCouponPurchaseRequest(tappedId, token);
    dispatch(addCouponPurchaseRequest(purchaseRequest));
  } finally {
    dispatch(downloadingCouponPurchaseRequest(false));
  }
};
