import { transformSearchText } from '../../../../../helper';
import { downloadGiiasSurabayaTransactions } from '../../../helper';
import { downloadingGiiasSurabaya2022Transactions, setGiiasSurabaya2022Transactions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, giiasSurabayaTransactions, uiGiiasSurabayaTransaction } = getState();
  const { token } = authentication;
  const { meta } = giiasSurabayaTransactions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGiiasSurabayaTransaction;


  try {
    dispatch(downloadingGiiasSurabaya2022Transactions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadGiiasSurabayaTransactions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setGiiasSurabaya2022Transactions(list));
    }
  } finally {
    dispatch(downloadingGiiasSurabaya2022Transactions(false));
  }
};
