import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
  ImageBroken,
} from 'mdi-material-ui';
import { FunctionalPage, CustomFieldDialog } from '../../../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, FILTER_TYPE_SWITCH,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE,
  RXFIELD_COUPON_AMOUNT, RXFIELD_COUPON_EXPIRED_COUPON_ALREADY_PROCESSED,
  RXFIELD_COUPON_IS_VOIDED, RXFIELD_COUPON_LABEL, RXFIELD_COUPON_MERCHANT_ID,
  RXFIELD_COUPON_MERCHANT_NAME, RXFIELD_COUPON_MERCHANT_REFERENCE_ID, RXFIELD_COUPON_TAGS,
  RXFIELD_COUPON_USER_NAME, RXFIELD_COUPON_VALID_UNTIL, RXFIELD_COUPON_VOID_NOTES,
  RXFIELD_COUPON_VOID_REFERENCE_ID, RXFIELD_COUPON_VOIDED_BY, RXFIELD_COUPON_VOIDED_ON,
  RXFORM_COUPON, RXSTATE_COUPONS, RXSTATE_COUPON_PAGE,
  STATUS_DISABLED, COLOR_DISABLED_ROW, COLOR_BACKGROUND,
  RXFIELD_COUPON_USER_ID, approval,
  RXFIELD_VOID_COUPON_DIALOG_REASON, RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID,
  RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION, COLOR_PRIMARY,
} from '../../constant';
import { renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';
import { rxformValidateVoidCouponDialog } from '../../validation';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
  },
  subtitle: {
    fontWeight: 200,
    textAlign: 'center',
  },
  icon: {
    fontSize: 100,
    color: COLOR_PRIMARY,
  },
  titleWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingRight: 40,
  },
}));

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderUserId}
          label={LocalizedString.couponPage.placeholderUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_USER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderUserName}
          label={LocalizedString.couponPage.placeholderUserName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_MERCHANT_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderMerchantReferenceID}
          label={LocalizedString.couponPage.placeholderMerchantReferenceID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_LABEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderLabel}
          label={LocalizedString.couponPage.placeholderLabel}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_TAGS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderTags}
          label={LocalizedString.couponPage.placeholderTags}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_IS_VOIDED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderIsVoided}
          label={LocalizedString.couponPage.placeholderIsVoided}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_VOID_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderVoidReferenceId}
          label={LocalizedString.couponPage.placeholderVoidReferenceId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_VOIDED_BY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderVoidedBy}
          label={LocalizedString.couponPage.placeholderVoidedBy}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderAmount}
          label={LocalizedString.couponPage.placeholderAmount}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_MERCHANT_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderMerchantId}
          label={LocalizedString.couponPage.placeholderMerchantId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_MERCHANT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderMerchantName}
          label={LocalizedString.couponPage.placeholderMerchantName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_VALID_UNTIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderValidUntil}
          label={LocalizedString.couponPage.placeholderValidUntil}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_EXPIRED_COUPON_ALREADY_PROCESSED}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderExpiredCouponAlreadyProcessed}
          label={LocalizedString.couponPage.placeholderExpiredCouponAlreadyProcessed}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_VOIDED_ON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderVoidedOn}
          label={LocalizedString.couponPage.placeholderVoidedOn}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COUPON_VOID_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderVoidNotes}
          label={LocalizedString.couponPage.placeholderVoidNotes}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const CouponPage = ({
  users, downloading, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  tappedId, onVoidPressed, showVoidDialog, selectedSendNotificationValue, voidingCoupon,
  onCloseVoidModal, onVoidCouponModalOKPressed, onSendNotificationOptionSelected,
  selectedCouponName, selectedUser,
}) => {
  const classes = useStyles();

  return (
    <div>
      <FunctionalPage
        data={RXSTATE_COUPONS}
        uiPage={RXSTATE_COUPON_PAGE}
        filterColumns={[
          {
            title: LocalizedString.couponPage.placeholderCreatedDate,
            field: RXFIELD_CREATED_DATE,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.couponPage.placeholderValidUntil,
            field: RXFIELD_COUPON_VALID_UNTIL,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.couponPage.placeholderMerchantName,
            field: RXFIELD_COUPON_MERCHANT_NAME,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.couponPage.placeholderMerchantReferenceID,
            field: RXFIELD_COUPON_MERCHANT_REFERENCE_ID,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.couponPage.placeholderUserName,
            field: RXFIELD_COUPON_USER_NAME,
            type: FILTER_TYPE_DROPDOWN,
            data: users,
            loading: loadingUser,
            onChangeFilterText: onChangeUserText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.couponPage.placeholderIsVoided,
            field: RXFIELD_COUPON_IS_VOIDED,
            type: FILTER_TYPE_SWITCH,
          },
          {
            title: LocalizedString.couponPage.placeholderVoidReferenceId,
            field: RXFIELD_COUPON_VOID_REFERENCE_ID,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.couponPage.placeholderExpiredCouponAlreadyProcessed,
            field: RXFIELD_COUPON_EXPIRED_COUPON_ALREADY_PROCESSED,
            type: FILTER_TYPE_SWITCH,
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.couponPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.couponPage.labelUserName, field: 'user.fullName', sorting: !downloading },
          { title: LocalizedString.couponPage.labelAmount, field: 'amount', sorting: !downloading },
          { title: LocalizedString.couponPage.labelLabel, field: 'label', sorting: !downloading },
          { title: LocalizedString.couponPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
          { title: LocalizedString.couponPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
        ]}
        moreMenus={[
          {
            caption: LocalizedString.couponPage.buttonCaptionVoid,
            disabled: false,
            onPress: () => onVoidPressed(tappedId),
          },
        ]}
        handleSubmit={handleSubmit}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        title={LocalizedString.couponPage.title}
        useFullWidth
        disableCreate
        disableDelete
        disableEdit
        rowStyle={({ status, isVoided }) => ({
          backgroundColor: isVoided || status === STATUS_DISABLED
            ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
        })}
      >
        {renderDialogContent()}
      </FunctionalPage>
      <CustomFieldDialog
        loadingPositiveButton={voidingCoupon}
        positiveButtonDisabled={voidingCoupon}
        visibility={showVoidDialog}
        onCancelPressed={onCloseVoidModal}
        onClosePressed={onCloseVoidModal}
        onPositiveButtonPressed={onVoidCouponModalOKPressed}
        positiveButtonCaption={LocalizedString.couponPage.buttonCaptionOK}
        title=""
        customDialog
        titleComponent={(
          <Typography variant="h4" className={classes.title}>
            {LocalizedString.couponPage.msgVoidCoupon}
          </Typography>
        )}
        subtitleComponent={(
          <div className={classes.titleWrapper}>
            <Typography variant="h6" className={classes.subtitle}>
              {LocalizedString.couponPage.msgVoidCouponDescription}
              <b>{selectedCouponName}</b>
              {LocalizedString.couponPage.msgVoidCouponFrom}
              <b>{selectedUser}</b>
            </Typography>
          </div>
        )}
        iconComponent={(<ImageBroken className={classes.icon} />)}
        validate={rxformValidateVoidCouponDialog}
      >
        <Field
          name={RXFIELD_VOID_COUPON_DIALOG_REASON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderReason}
          label={LocalizedString.couponPage.placeholderReason}
          disabled={voidingCoupon}
          required
          multiline
        />
        <Field
          name={RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.couponPage.placeholderReferenceId}
          label={LocalizedString.couponPage.placeholderReferenceId}
          disabled={voidingCoupon}
          required
        />
        <Field
          name={RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION}
          component={renderReduxFormSimpleDropdownField}
          placeholder={LocalizedString.couponPage.placeholderSendNotification}
          label={LocalizedString.couponPage.placeholderSendNotification}
          disabled={voidingCoupon}
          data={approval}
          value={selectedSendNotificationValue}
          onOptionSelected={onSendNotificationOptionSelected}
          onBlur={(e) => e.preventDefault()}
          required
        />
      </CustomFieldDialog>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_COUPON,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CouponPage);

CouponPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedSendNotificationValue: SimpleDataShape,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  showVoidDialog: PropTypes.bool.isRequired,
  voidingCoupon: PropTypes.bool.isRequired,
  tappedId: PropTypes.string.isRequired,
  selectedCouponName: PropTypes.string.isRequired,
  selectedUser: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVoidPressed: PropTypes.func.isRequired,
  onCloseVoidModal: PropTypes.func.isRequired,
  onVoidCouponModalOKPressed: PropTypes.func.isRequired,
  onSendNotificationOptionSelected: PropTypes.func.isRequired,
};
CouponPage.defaultProps = {
  selectedSendNotificationValue: null,
};
