import { clearPoints, setVoidingPoint } from '../simple-action';
import { addVoidPointRequest } from '../../../helper';
import downloadPointsAsync from './downloadPointsAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(setVoidingPoint(true));

    const { token } = getState().authentication;
    const { meta } = getState().points;
    const { tappedId } = getState().uiPoint;

    const reason = values?.reason || '';
    const merchantReferenceId = values?.merchantReferenceId || '';
    const pointId = tappedId || '';
    const sendNotification = values?.sendNotification?.value || false;
    const voidingPoint = true;
    await addVoidPointRequest(reason, merchantReferenceId, '', pointId, sendNotification, voidingPoint, token);
    dispatch(clearPoints());
    dispatch(downloadPointsAsync(meta.currentPage));
  } finally {
    dispatch(setVoidingPoint(false));
  }
};
