import { SET_VOUCHER_TOKEN_BY_CODE, CLEAR_VOUCHER_TOKEN_BY_CODE } from '../action';

const initialState = {
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VOUCHER_TOKEN_BY_CODE: {
      return {
        data: { ...action.data },
      };
    }
    case CLEAR_VOUCHER_TOKEN_BY_CODE:
      return initialState;
    default:
      return state;
  }
};
