import { SET_VEHICLE_MODELS, CLEAR_VEHICLE_MODELS } from '../action';

const initialState = {
  data: [],
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VEHICLE_MODELS: {
      return {
        data: [...action.data],
        meta: action.data.meta,
      };
    }
    case CLEAR_VEHICLE_MODELS:
      return initialState;
    default:
      return state;
  }
};
