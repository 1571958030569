import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import VoucherClaim from './voucher-claim.presentation';
import {
  downloadVoucherByCodeAsync, setAlertErrorMessage, claimVoucherByCodeAsync,
} from '../../redux/action';
import { RXFORM_VOUCHER_CLAIM, RXFIELD_VOUCHER_CLAIM_VOUCHER_CODE } from '../../constant';

const selector = formValueSelector(RXFORM_VOUCHER_CLAIM);

const mapStateToProps = (state) => ({
  voucherToken: state.voucherTokenByCode.data,
  downloading: state.uiVoucherClaim.downloading,
  claiming: state.uiVoucherClaim.claiming,
  voucherCode: selector(state, RXFIELD_VOUCHER_CLAIM_VOUCHER_CODE) || '',
});
const mapDispatchToProps = (dispatch) => ({
  onSearchPressed: ({ voucherCode }) => {
    dispatch(downloadVoucherByCodeAsync(voucherCode))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onClaimVoucherPressed: ({ notes, voucherCode }) => {
    dispatch(claimVoucherByCodeAsync(notes, voucherCode))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(VoucherClaim);
