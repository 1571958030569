import { reset } from 'redux-form';
import {
  addingEditingPromoCodeBatch, clearPromoCodeBatches, setAlertErrorMessage,
  setPromoCodeBatchSelectedAirport,
  setPromoCodeBatchSelectedBypassAchievement,
  setPromoCodeBatchSelectedBypassMaxPurchase,
  setPromoCodeBatchSelectedMerchant,
  setPromoCodeBatchSelectedRewardType,
  setPromoCodeBatchSelectedVouchers,
  setPromoCodeBatchTableDataVouchers,
} from '../simple-action';
import { addPromoCodeBatch, toNumber } from '../../../helper';
import { PROMO_CODE_USER_TYPE_OPTIONS, REWARD_TYPE_VOUCHER, RXFORM_PROMO_CODE_BATCH } from '../../../constant';
import downloadPromoCodeBatchesAsync from './downloadPromoCodeBatchesAsync';

export default (prefix, quantity, randomCodeLength, validFrom, validUntil, merchantTag,
  maxRedemptionCount, notificationMessageTitle, notificationMessageBody, pointAmount,
  couponAmount, userType, minimumSignUpTime, maximumSignUpTime) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPromoCodeBatch(true));

    const { token } = getState().authentication;
    const {
      selectedBypassMaxPurchase, selectedBypassAchievement, selectedMerchant,
      selectedRewardType, tableDataVouchers,
    } = getState().uiPromoCodeBatch;

    const rewardType = selectedRewardType?.value || '';
    const merchantId = selectedMerchant?.value || '';
    const vouchers = (rewardType === REWARD_TYPE_VOUCHER && tableDataVouchers.length > 0)
      ? tableDataVouchers.map((x) => ({ voucherId: x.value, airportId: x?.airport?.value || null }))
      : [];
    const bypassAchievementValidation = rewardType === REWARD_TYPE_VOUCHER
      ? selectedBypassAchievement.value : false;
    const bypassMaxPurchaseValidation = rewardType === REWARD_TYPE_VOUCHER
      ? selectedBypassMaxPurchase.value : false;

    const appliedToNewUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[0].label,
    );
    const appliedToNewUserBlue = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[1].label,
    );
    const appliedToNewUserBluePlus = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[2].label,
    );
    const appliedToWhiteUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[3].label,
    );
    const appliedToBlueUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[4].label,
    );
    const appliedToBluePlusUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[5].label,
    );

    const minSignUpTime = appliedToNewUser || appliedToNewUserBlue || appliedToNewUserBluePlus
      ? minimumSignUpTime : null;
    const maxSignUpTime = appliedToNewUser || appliedToNewUserBlue || appliedToNewUserBluePlus
      ? maximumSignUpTime : null;

    await addPromoCodeBatch(prefix, toNumber(quantity), toNumber(randomCodeLength), validFrom,
      validUntil, rewardType, merchantId, merchantTag, toNumber(maxRedemptionCount),
      notificationMessageTitle, notificationMessageBody, vouchers, bypassMaxPurchaseValidation,
      bypassAchievementValidation, toNumber(pointAmount), toNumber(couponAmount), appliedToNewUser,
      appliedToWhiteUser, appliedToBlueUser, appliedToBluePlusUser, minSignUpTime, maxSignUpTime,
      appliedToNewUserBlue, appliedToNewUserBluePlus, token);

    dispatch(reset(RXFORM_PROMO_CODE_BATCH));
    dispatch(clearPromoCodeBatches());
    dispatch(downloadPromoCodeBatchesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setPromoCodeBatchSelectedMerchant(null));
    dispatch(setPromoCodeBatchSelectedVouchers(null));
    dispatch(setPromoCodeBatchTableDataVouchers([]));
    dispatch(setPromoCodeBatchSelectedRewardType(null));
    dispatch(setPromoCodeBatchSelectedBypassMaxPurchase(null));
    dispatch(setPromoCodeBatchSelectedBypassAchievement(null));
    dispatch(setPromoCodeBatchSelectedAirport(''));
  } finally {
    dispatch(addingEditingPromoCodeBatch(false));
  }
};
