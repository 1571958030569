import { downloadBookingCreatedByDeliveryMethod } from '../../../helper';
import { downloadingBookingCreatedByDeliveryMethod, setBookingCreatedByDeliveryMethod } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingBookingCreatedByDeliveryMethod(true));
    const bookingCreatedByDeliveryMethod = await downloadBookingCreatedByDeliveryMethod(token,
      startDate, endDate);

    dispatch(setBookingCreatedByDeliveryMethod(bookingCreatedByDeliveryMethod));
  } finally {
    dispatch(downloadingBookingCreatedByDeliveryMethod(false));
  }
};
