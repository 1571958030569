import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  INITIAL_ORDER_BY_IIMS_2023_PRIZE_REDEMPTION,
  MENUID_IIMS_2023_PRIZE_REDEMPTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import Iims2023PrizeRedemptionPage from './prize-redemption.presentation';
import {
  clearVouchers, setVoucherAdvancedFilterDialogSelectedFilterString, setVoucherSearchText,
  downloadVouchersAsync,
} from '../../../voucher';
import {
  clearIims2023PrizeRedemptions, setIims2023PrizeRedemptionAdvancedFilterString,
  setIims2023PrizeRedemptionSearchText, setIims2023PrizeRedemptionSelectedOrderBy,
  setIims2023PrizeRedemptionSelectedPageSize, setIims2023PrizeRedemptionTappedId,
} from '../../redux/action/simple-action';
import {
  downloadIims2023PrizeRedemptionAsync, downloadIims2023PrizeRedemptionsAsync,
  saveIims2023PrizeRedemptionsAsync,
} from '../../redux/action';


const getInitialValues = (state) => {
  const { iims2023PrizeRedemptions, uiFunctionalPage, uiIims2023PrizeRedemption } = state;
  const { data } = iims2023PrizeRedemptions;
  const { downloadingDeleting, tappedId } = uiIims2023PrizeRedemption;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};


const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiIims2023PrizeRedemption.downloading,
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingVoucher: state.uiVoucher.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_IIMS_2023_PRIZE_REDEMPTION));
    dispatch(setIims2023PrizeRedemptionAdvancedFilterString(''));
    dispatch(setIims2023PrizeRedemptionSearchText(''));
    dispatch(clearIims2023PrizeRedemptions());
    dispatch(setIims2023PrizeRedemptionSelectedPageSize(20));
    dispatch(
      setIims2023PrizeRedemptionSelectedOrderBy(INITIAL_ORDER_BY_IIMS_2023_PRIZE_REDEMPTION),
    );
    dispatch(downloadIims2023PrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setIims2023PrizeRedemptionSelectedOrderBy(orderBy));
    dispatch(clearIims2023PrizeRedemptions());
    dispatch(downloadIims2023PrizeRedemptionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setIims2023PrizeRedemptionTappedId(id));
    dispatch(downloadIims2023PrizeRedemptionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setIims2023PrizeRedemptionSearchText(text));
    dispatch(clearIims2023PrizeRedemptions());
    dispatch(downloadIims2023PrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadIims2023PrizeRedemptionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setIims2023PrizeRedemptionSelectedPageSize(pageSize));
    dispatch(downloadIims2023PrizeRedemptionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setIims2023PrizeRedemptionSelectedPageSize(pageSize));
    dispatch(clearIims2023PrizeRedemptions());
    dispatch(downloadIims2023PrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearIims2023PrizeRedemptions());
    dispatch(setIims2023PrizeRedemptionAdvancedFilterString(''));
    dispatch(
      setIims2023PrizeRedemptionSelectedOrderBy(INITIAL_ORDER_BY_IIMS_2023_PRIZE_REDEMPTION),
    );
    dispatch(downloadIims2023PrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id')
      .replace('voucher', 'voucherId');

    dispatch(setIims2023PrizeRedemptionAdvancedFilterString(text));
    dispatch(clearIims2023PrizeRedemptions());
    dispatch(downloadIims2023PrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveIims2023PrizeRedemptionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Iims2023PrizeRedemptionPage);
