import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  RXFORM_GIIAS_2022_TRANSACTION,
  RXSTATE_GIIAS_2022_PRIZE_TRANSACTIONS, RXSTATE_GIIAS_2022_PRIZE_TRANSACTION,
  RXFIELD_GIIAS_2022_TRANSACTION_CREATED_DATE, RXFIELD_GIIAS_2022_TRANSACTION_USER,
  RXFIELD_GIIAS_2022_TRANSACTION_USER_ID, RXFIELD_GIIAS_2022_TRANSACTION_USER_FULLNAME,
  RXFIELD_GIIAS_2022_TRANSACTION_LABEL, RXFIELD_GIIAS_2022_TRANSACTION_REFERENCE_ID,
  RXFIELD_GIIAS_2022_TRANSACTION_TYPE, RXFIELD_GIIAS_2022_TRANSACTION_AMOUNT,
  RXFIELD_GIIAS_2022_TRANSACTION_REMAINING_BALANCE,
} from '../../constant';


const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_TRANSACTION_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022TransactionPage.placeholderUserId}
          label={LocalizedString.giias2022TransactionPage.labelUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_TRANSACTION_USER_FULLNAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022TransactionPage.placeholderUserFullname}
          label={LocalizedString.giias2022TransactionPage.labelUserFullname}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_TRANSACTION_LABEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022TransactionPage.placeholderLabel}
          label={LocalizedString.giias2022TransactionPage.labelLabel}
          disabled
        />
      </Grid>
      <Grid item sm={12}>
        <Field
          name={RXFIELD_GIIAS_2022_TRANSACTION_REFERENCE_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.giias2022TransactionPage.labelReferenceId}
          placeholder={LocalizedString.giias2022TransactionPage.placeholderReferenceId}
          disabled
          multiline
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_TRANSACTION_TYPE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022TransactionPage.placeholderType}
          label={LocalizedString.giias2022TransactionPage.labelType}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_TRANSACTION_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022TransactionPage.placeholderAmount}
          label={LocalizedString.giias2022TransactionPage.labelAmount}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_TRANSACTION_REMAINING_BALANCE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022TransactionPage.placeholderRemainingBalance}
          label={LocalizedString.giias2022TransactionPage.labelRemainingBalance}
          disabled
        />
      </Grid>

    </Grid>
  </Grid>
);

const Giias2022TransactionPage = ({
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText, onDownloadPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_GIIAS_2022_PRIZE_TRANSACTIONS}
    uiPage={RXSTATE_GIIAS_2022_PRIZE_TRANSACTION}
    tableColumns={[
      {
        title: LocalizedString.giias2022TransactionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.giias2022TransactionPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.giias2022TransactionPage.labelType, field: 'type', sorting: !downloading },
      { title: LocalizedString.giias2022TransactionPage.labelAmount, field: 'amount', sorting: !downloading },
      { title: LocalizedString.giias2022TransactionPage.labelRemainingBalance, field: 'remainingBalance', sorting: !downloading },
      { title: LocalizedString.giias2022TransactionPage.labelLabel, field: 'label', sorting: !downloading },
      {
        title: LocalizedString.giias2022TransactionPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.giias2022PrizeRedemptionPage.labelCreatedDate,
        field: RXFIELD_GIIAS_2022_TRANSACTION_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
      },
      {
        title: LocalizedString.giias2022PrizeRedemptionPage.labelUser,
        field: RXFIELD_GIIAS_2022_TRANSACTION_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.giias2022TransactionPage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onDownloadPressed={onDownloadPressed}
    enableSave
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_GIIAS_2022_TRANSACTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Giias2022TransactionPage);

Giias2022TransactionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
