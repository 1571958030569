import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  MENUID_DASHBOARD_SERVICE_PROMO, RXFORM_DASHBOARD_SERVICE_PROMO,
} from '../../constant';
import {
  toCurrency, localDateToUtc, getChartPercentage, toMoment,
} from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage,
  clearBookingCreated, clearBookingProceedByDealer,
  clearBookingWaitForPayment, clearBookingCompleted,
  clearBookingCanceled,
  clearBookingCreatedByRepairSelection,
  clearBookingCreatedByDeliveryMethod,
  clearBookingCreatedByServiceInterval,
  clearBookingCreatedByVehicleModel,
  downloadBookingCreatedAsync,
  downloadBookingProceedByDealerAsync,
  downloadBookingWaitForPaymentAsync,
  downloadBookingCompletedAsync,
  downloadBookingCanceledAsync,
  downloadBookingCreatedByRepairSelectionAsync,
  downloadBookingCreatedByDeliveryMethodAsync,
  downloadBookingCreatedByServiceIntervalAsync,
  downloadBookingCreatedByVehicleModelAsync,
  downloadTransactionReceivedAsync,
  downloadTransactionPendingAsync,
  downloadTransactionPaidAsync,
  downloadTransactionCanceledAsync,
  downloadTransactionAmountAsync,
  clearTransactionReceived,
  clearTransactionPending,
  clearTransactionPaid,
  clearTransactionCanceled,
  clearTransactionAmount,
  clearTransactionCountByPartName,
  clearTransactionAmountByPartName,
  clearRemaningStockByPartName,
  clearTransactionCreatedByVehicleModel,
  downloadTransactionCountByPartNameAsync,
  downloadTransactionAmountByPartNameAsync,
  downloadRemaningStockByPartNameAsync,
  downloadTransactionCreatedByVehicleModelAsync,
} from '../../redux/action';
import ServicePromoPage from './service-promo.presentation';

const defaultStartDate = '';
const defaultEndDate = '';
const ARGUMENT_FIELD = 'name';
const VALUE_FIELD = 'count';

const getTransactionAmount = (state) => {
  const value = state?.transactionAmount?.data?.count || 0;
  return `Rp${toCurrency(value)}`;
};

const getBarChartData = (state) => {
  const data = state ? Object.values(state).map((e) => ({
    Key: e.name,
    Value: e.count,
  })) : [];
  return data;
};

const mapStateToProps = (state) => ({
  bookingCreated: state?.bookingCreated?.data?.count || 0,
  bookingProceedByDealer: state?.bookingProceedByDealer?.data?.count || 0,
  bookingWaitForPayment: state?.bookingWaitForPayment?.data?.count || 0,
  bookingCompleted: state?.bookingCompleted?.data?.count || 0,
  bookingCanceled: state?.bookingCanceled?.data?.count || 0,
  bookingCreatedByRepairSelection: getBarChartData(
    state?.bookingCreatedRepairSelection,
  ),
  bookingCreatedByDeliveryMethod: getBarChartData(
    state?.bookingCreatedDeliveryMethod,
  ),
  bookingCreatedByServiceInterval: getBarChartData(
    state?.bookingCreatedServiceInterval,
  ),
  bookingCreatedByVehicleModel: getChartPercentage(
    state.bookingCreatedVehicleModel, ARGUMENT_FIELD, VALUE_FIELD,
  ),
  transactionReceived: state?.transactionReceived?.data?.count || 0,
  transactionPending: state?.transactionPending?.data?.count || 0,
  transactionPaid: state?.transactionPaid?.data?.count || 0,
  transactionCanceled: state?.transactionCanceled?.data?.count || 0,
  transactionAmount: getTransactionAmount(state),
  transactionCountByPartName: getBarChartData(
    state?.transactionCountByPartName,
  ),
  transactionAmountByPartName: getBarChartData(
    state?.transactionAmountByPartName,
  ),
  remaningStockByPartName: getBarChartData(
    state?.remaningStockByPartName,
  ),
  transactionCreatedByVehicleModel: getChartPercentage(
    state.transactionCreatedByVehicleModel, ARGUMENT_FIELD, VALUE_FIELD,
  ),
  downloadingBookingCreated: state.uiServicePromo.downloadingBookingCreated,
  downloadingBookingProceedByDealer: state.uiServicePromo.downloadingBookingProceedByDealer,
  downloadingBookingWaitForPayment: state.uiServicePromo.downloadingBookingWaitForPayment,
  downloadingBookingCompleted: state.uiServicePromo.downloadingBookingCompleted,
  downloadingBookingCanceled: state.uiServicePromo.downloadingBookingCanceled,
  downloadingBookingCreatedByRepairSelection: state.uiServicePromo
    .downloadingBookingCreatedByRepairSelection,
  downloadingBookingCreatedByDeliveryMethod: state.uiServicePromo
    .downloadingBookingCreatedByDeliveryMethod,
  downloadingBookingCreatedByServiceInterval: state.uiServicePromo
    .downloadingBookingCreatedByServiceInterval,
  downloadingBookingCreatedByVehicleModel: state.uiServicePromo
    .downloadingBookingCreatedByVehicleModel,
  downloadingTransactionReceived: state.uiServicePromo
    .downloadingTransactionReceived,
  downloadingTransactionPending: state.uiServicePromo
    .downloadingTransactionPending,
  downloadingTransactionPaid: state.uiServicePromo
    .downloadingTransactionPaid,
  downloadingTransactionCanceled: state.uiServicePromo
    .downloadingTransactionCanceled,
  downloadingTransactionAmount: state.uiServicePromo
    .downloadingTransactionAmount,
  downloadingTransactionCountByPartName: state.uiServicePromo
    .downloadingTransactionCountByPartName,
  downloadingTransactionAmountByPartName: state.uiServicePromo
    .downloadingTransactionAmountByPartName,
  downloadingRemaningStockByPartName: state.uiServicePromo
    .downloadingRemaningStockByPartName,
  downloadingTransactionCreatedByVehicleModel: state.uiServicePromo
    .downloadingTransactionCreatedByVehicleModel,
});

const downloadAllData = (dispatch, startDate, endDate) => {
  dispatch(downloadBookingCreatedAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingProceedByDealerAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingWaitForPaymentAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingCompletedAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingCanceledAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingCreatedByRepairSelectionAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingCreatedByDeliveryMethodAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingCreatedByServiceIntervalAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadBookingCreatedByVehicleModelAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionReceivedAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionPendingAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionPaidAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionCanceledAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionAmountAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionCountByPartNameAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionAmountByPartNameAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadRemaningStockByPartNameAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  dispatch(downloadTransactionCreatedByVehicleModelAsync(startDate, endDate))
    .catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
};

const clearAllData = (dispatch) => {
  dispatch(clearBookingCreated());
  dispatch(clearBookingProceedByDealer());
  dispatch(clearBookingWaitForPayment());
  dispatch(clearBookingCompleted());
  dispatch(clearBookingCanceled());
  dispatch(clearBookingCreatedByRepairSelection());
  dispatch(clearBookingCreatedByDeliveryMethod());
  dispatch(clearBookingCreatedByServiceInterval());
  dispatch(clearBookingCreatedByVehicleModel());
  dispatch(clearTransactionReceived());
  dispatch(clearTransactionPending());
  dispatch(clearTransactionPaid());
  dispatch(clearTransactionCanceled());
  dispatch(clearTransactionAmount());
  dispatch(clearTransactionCountByPartName());
  dispatch(clearTransactionAmountByPartName());
  dispatch(clearRemaningStockByPartName());
  dispatch(clearTransactionCreatedByVehicleModel());
};

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_DASHBOARD_SERVICE_PROMO));
    clearAllData(dispatch);
    downloadAllData(dispatch, defaultStartDate, defaultEndDate);
  },
  onChangeDate: (value, field) => {
    dispatch(change(RXFORM_DASHBOARD_SERVICE_PROMO, field, value));
  },
  onApplyPressed: (values) => {
    const { startDate, endDate } = values;
    const formattedNewDate = localDateToUtc(startDate);
    const formattedEndDate = localDateToUtc(toMoment(endDate).endOf('day').format());
    const newStartDate = startDate ? `${formattedNewDate}` : defaultStartDate;
    const newEndDate = endDate ? `${formattedEndDate}` : defaultEndDate;
    clearAllData(dispatch);
    downloadAllData(dispatch, newStartDate, newEndDate);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicePromoPage);
