import { downloadingSalesConsultants, setFunctionalPageMode, setSalesConsultants } from '../simple-action';
import { editSalesConsultans } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { selectedConsultant, tappedId } = getState().uiTestDriveEoReservation;

  try {
    // dispatch(downloadingSalesConsultants(true));
    await editSalesConsultans(tappedId, selectedConsultant.value, token);
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  } finally {
    // dispatch(downloadingSalesConsultants(false));
  }
};
