import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyACY5fRY_Bm1qU5lKn61TeKzt7jHwJ1GsI',
  authDomain: 'myhyundai-dev.firebaseapp.com',
  databaseURL: 'https://myhyundai-dev-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'myhyundai-dev',
  storageBucket: 'myhyundai-dev.appspot.com',
  messagingSenderId: '419002466544',
  appId: '1:419002466544:web:70205250308e301490c235',
  measurementId: 'G-JSVEC53X6D',
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
