import { downloadBookingWaitForPayment } from '../../../helper';
import { downloadingBookingWaitForPayment, setBookingWaitForPayment } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingBookingWaitForPayment(true));
    const bookingCreated = await downloadBookingWaitForPayment(token, startDate, endDate);

    dispatch(setBookingWaitForPayment(bookingCreated));
  } finally {
    dispatch(downloadingBookingWaitForPayment(false));
  }
};
