import { downloadGiiasSurabayaTransaction } from '../../../helper';
import { addGiiasSurabaya2022Transaction, downloadingGiiasSurabaya2022Transaction } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiiasSurabaya2022Transaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiiasSurabayaTransaction;

    const giias2022Transaction = await downloadGiiasSurabayaTransaction(tappedId, token);
    dispatch(addGiiasSurabaya2022Transaction(giias2022Transaction));
  } finally {
    dispatch(downloadingGiiasSurabaya2022Transaction(false));
  }
};
