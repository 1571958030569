import { reset } from 'redux-form';
import {
  addingEditingPromoCode, clearPromoCodes, setAlertErrorMessage,
  setFunctionalPageMode,
  setPromoCodeSelectedMerchant,
  setPromoCodeSelectedReward,
  setPromoCodeSelectedVoucherBypassMaxPurchase,
  setPromoCodeSelectedVoucherBypassAchievement,
  setPromoCodeSelectedVouchers,
  setPromoCodeTableDataVouchers,
  setPromoCodeSelectedAirport,
} from '../simple-action';
import { addPromoCode, editPromoCode } from '../../../helper';
import {
  PAGE_MODE_TABLE, PROMO_CODE_USER_TYPE_OPTIONS, REWARD_TYPE_VOUCHER, RXFORM_PROMO_CODE,
} from '../../../constant';
import downloadPromoCodesAsync from './downloadPromoCodesAsync';

export default (
  code,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  maxRedemptionCount,
  pointAmount,
  couponAmount,
  validFrom,
  validUntil,
  merchantTag,
  notificationMessageTitle,
  notificationMessageBody,
  userType,
  minimumSignUpTime,
  maximumSignUpTime,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPromoCode(true));
    const { token } = getState().authentication;
    const {
      tappedId,
      selectedMerchant,
      tableDataVouchers,
      selectedVoucherBypassMaxPurchase,
      selectedVoucherBypassAchievement,
      selectedReward,
    } = getState().uiPromoCode;
    const { data } = getState().promoCodes;
    const found = data[tappedId];
    const reward = selectedReward ? selectedReward.value : found.rewardType;
    const merchantId = selectedMerchant ? selectedMerchant.value : found.merchantId;
    const vouchers = (reward === REWARD_TYPE_VOUCHER && tableDataVouchers.length > 0)
      ? tableDataVouchers.map((x) => ({ voucherId: x.value, airportId: x?.airport?.value || null }))
      : [];
    const voucherBypassMaxPurchase = selectedVoucherBypassMaxPurchase?.value
    ?? found?.bypassMaxPurchaseValidation ?? false;
    const voucherBypassAchievementValidation = selectedVoucherBypassAchievement?.value
    ?? found?.bypassAchievementValidation ?? false;

    const appliedToNewUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[0].label,
    );
    const appliedToNewUserBlue = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[1].label,
    );
    const appliedToNewUserBluePlus = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[2].label,
    );
    const appliedToWhiteUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[3].label,
    );
    const appliedToBlueUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[4].label,
    );
    const appliedToBluePlusUser = userType.some(
      (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[5].label,
    );
    const minSignUpTime = appliedToNewUser || appliedToNewUserBlue
    || appliedToNewUserBluePlus ? minimumSignUpTime : null;
    const maxSignUpTime = appliedToNewUser || appliedToNewUserBlue
    || appliedToNewUserBluePlus ? maximumSignUpTime : null;

    if (tappedId) {
      await editPromoCode(tappedId,
        code,
        maxRedemptionCount,
        pointAmount,
        couponAmount,
        validFrom,
        validUntil,
        merchantTag,
        notificationMessageTitle,
        notificationMessageBody,
        merchantId,
        vouchers,
        reward,
        bypassMaxPurchaseValidation?.value ?? voucherBypassMaxPurchase ?? false,
        bypassAchievementValidation?.value ?? voucherBypassAchievementValidation ?? false,
        appliedToNewUser,
        appliedToNewUserBlue,
        appliedToNewUserBluePlus,
        appliedToWhiteUser,
        appliedToBlueUser,
        appliedToBluePlusUser,
        minSignUpTime,
        maxSignUpTime,
        token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addPromoCode(
        code,
        maxRedemptionCount,
        pointAmount,
        couponAmount,
        validFrom,
        validUntil,
        merchantTag,
        notificationMessageTitle,
        notificationMessageBody,
        merchantId,
        vouchers,
        reward,
        bypassMaxPurchaseValidation?.value ?? voucherBypassMaxPurchase ?? false,
        bypassAchievementValidation?.value ?? voucherBypassAchievementValidation ?? false,
        appliedToNewUser,
        appliedToNewUserBlue,
        appliedToNewUserBluePlus,
        appliedToWhiteUser,
        appliedToBlueUser,
        appliedToBluePlusUser,
        minSignUpTime,
        maxSignUpTime,
        token,
      );
    }

    dispatch(reset(RXFORM_PROMO_CODE));
    dispatch(clearPromoCodes());
    dispatch(downloadPromoCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setPromoCodeSelectedMerchant(null));
    dispatch(setPromoCodeSelectedReward(null));
    dispatch(setPromoCodeSelectedVoucherBypassMaxPurchase(null));
    dispatch(setPromoCodeSelectedVoucherBypassAchievement(null));
    dispatch(setPromoCodeSelectedVouchers(null));
    dispatch(setPromoCodeTableDataVouchers([]));
    dispatch(setPromoCodeSelectedAirport(''));
  } finally {
    dispatch(addingEditingPromoCode(false));
  }
};
