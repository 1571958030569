import { downloadGiias2022PrizeRedemption } from '../../../helper';
import { addGiias2022PrizeRedemption, downloadingGiias2022PrizeRedemption } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiias2022PrizeRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiias2022PrizeRedemption;

    const giias2022PrizeRedemption = await downloadGiias2022PrizeRedemption(tappedId, token);
    dispatch(addGiias2022PrizeRedemption(giias2022PrizeRedemption));
  } finally {
    dispatch(downloadingGiias2022PrizeRedemption(false));
  }
};
