import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_VOUCHER_CATEGORIES = 'DOWNLOADING_VOUCHER_CATEGORIES';
export const downloadingVoucherCategories = makeActionCreator(DOWNLOADING_VOUCHER_CATEGORIES, 'status');

export const SET_VOUCHER_CATEGORIES = 'SET_VOUCHER_CATEGORIES';
export const seVoucherCategories = makeActionCreator(SET_VOUCHER_CATEGORIES, 'data');

export const ADD_VOUCHER_CATEGORY = 'ADD_VOUCHER_CATEGORY';
export const addVoucherCategory = makeActionCreator(ADD_VOUCHER_CATEGORY, 'data');

export const CLEAR_VOUCHER_CATEGORIES = 'CLEAR_VOUCHER_CATEGORIES';
export const clearVoucherCategories = makeActionCreator(CLEAR_VOUCHER_CATEGORIES);

export const ADDING_EDITING_VOUCHER_CATEGORY = 'ADDING_EDITING_VOUCHER_CATEGORY';
export const addingEditingVoucherCategory = makeActionCreator(ADDING_EDITING_VOUCHER_CATEGORY, 'status');

export const DOWNLOADING_DELETING_VOUCHER_CATEGORY = 'DOWNLOADING_DELETING_VOUCHER_CATEGORY';
export const downloadingDeletingVoucherCategory = makeActionCreator(DOWNLOADING_DELETING_VOUCHER_CATEGORY, 'status');


export const SET_VOUCHER_CATEGORY_SELECTED_PAGE_SIZE = 'SET_VOUCHER_CATEGORY_SELECTED_PAGE_SIZE';
export const setVoucherCategorySelectedPageSize = makeActionCreator(SET_VOUCHER_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_VOUCHER_CATEGORY_SELECTED_ORDER_BY = 'SET_VOUCHER_CATEGORY_SELECTED_ORDER_BY';
export const setVoucherCategorySelectedOrderBy = makeActionCreator(SET_VOUCHER_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_VOUCHER_CATEGORY_TAPPED_ID = 'SET_VOUCHER_CATEGORY_TAPPED_ID';
export const setVoucherCategoryTappedId = makeActionCreator(SET_VOUCHER_CATEGORY_TAPPED_ID, 'id');

export const SET_VOUCHER_CATEGORY_SEARCH_TEXT = 'SET_VOUCHER_CATEGORY_SEARCH_TEXT';
export const setVoucherCategorySearchText = makeActionCreator(SET_VOUCHER_CATEGORY_SEARCH_TEXT, 'text');


export const DOWNLOADING_VOUCHER_USAGES = 'DOWNLOADING_VOUCHER_USAGES';
export const downloadingVoucherUsages = makeActionCreator(DOWNLOADING_VOUCHER_USAGES, 'status');

export const SET_VOUCHER_USAGES = 'SET_VOUCHERS_USAGES';
export const setVoucherUsages = makeActionCreator(SET_VOUCHER_USAGES, 'data');

export const CLEAR_VOUCHER_USAGES = 'CLEAR_VOUCHER_USAGES';
export const clearVoucherUsages = makeActionCreator(CLEAR_VOUCHER_USAGES);

export const ADD_VOUCHER_USAGE = 'ADD_VOUCHER_USAGE';
export const addVoucherUsage = makeActionCreator(ADD_VOUCHER_USAGE);

export const SAVING_VOUCHER_USAGES = 'SAVING_VOUCHER_USAGES';
export const savingVoucherUsages = makeActionCreator(SAVING_VOUCHER_USAGES, 'status');


export const SET_VOUCHER_USAGE_SEARCH_TEXT = 'SET_VOUCHER_USAGE_SEARCH_TEXT';
export const setVoucherUsageSearchText = makeActionCreator(SET_VOUCHER_USAGE_SEARCH_TEXT, 'text');

export const SET_VOUCHER_USAGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_VOUCHER_USAGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setVoucherUsageAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_VOUCHER_USAGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_VOUCHER_USAGE_SELECTED_PAGE_SIZE = 'SET_VOUCHER_USAGE_SELECTED_PAGE_SIZE';
export const setVoucherUsageSelectedPageSize = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_PAGE_SIZE, 'size');

export const SET_VOUCHER_USAGE_SELECTED_ORDER_BY = 'SET_VOUCHER_USAGE_SELECTED_ORDER_BY';
export const setVoucherUsageSelectedOrderBy = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_ORDER_BY, 'order');

export const SET_VOUCHER_USAGE_SELECTED_MERCHANT_ID = 'SET_VOUCHER_USAGE_SELECTED_MERCHANT_ID';
export const setVoucherUsageSelectedMerchantId = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_MERCHANT_ID, 'id');

export const SET_VOUCHER_USAGE_SELECTED_VOUCHER_CATEGORY_ID = 'SET_VOUCHER_USAGE_SELECTED_VOUCHER_CATEGORY_ID';
export const setVoucherUsageSelectedVoucherCategoryId = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_VOUCHER_CATEGORY_ID, 'id');

export const SET_VOUCHER_USAGE_SELECTED_APPLICABLE_BRANCHES = 'SET_VOUCHER_USAGE_SELECTED_APPLICABLE_BRANCHES';
export const setVoucherUsageSelectedApplicableBranches = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_APPLICABLE_BRANCHES, 'option');

export const SET_VOUCHER_USAGE_TABLE_DATA_APPLICABLE_BRANCHES = 'SET_VOUCHER_USAGE_TABLE_DATA_APPLICABLE_BRANCHES';
export const setVoucherUsageTableDataApplicableBranches = makeActionCreator(SET_VOUCHER_USAGE_TABLE_DATA_APPLICABLE_BRANCHES, 'data');

export const SET_VOUCHER_USAGE_SELECTED_PAYMENT_TYPE = 'SET_VOUCHER_USAGE_SELECTED_PAYMENT_TYPE';
export const setVoucherUsageSelectedPaymentType = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_PAYMENT_TYPE, 'option');

export const SET_VOUCHER_USAGE_SELECTED_TAGS = 'SET_VOUCHER_USAGE_SELECTED_TAGS';
export const setVoucherUsageSelectedTags = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_TAGS, 'option');

export const SET_VOUCHER_USAGE_SELECTED_TANAMERA_FLAG = 'SET_VOUCHER_USAGE_SELECTED_TANAMERA_FLAG';
export const setVoucherUsageSelectedTanameraFlag = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_TANAMERA_FLAG, 'option');

export const SET_VOUCHER_USAGE_SELECTED_GARUDA_FLAG = 'SET_VOUCHER_USAGE_SELECTED_GARUDA_FLAG';
export const setVoucherUsageSelectedGarudaFlag = makeActionCreator(SET_VOUCHER_USAGE_SELECTED_GARUDA_FLAG, 'option');


export const DOWNLOADING_VOUCHERS = 'DOWNLOADING_VOUCHERS';
export const downloadingVouchers = makeActionCreator(DOWNLOADING_VOUCHERS, 'status');

export const SET_VOUCHERS = 'SET_VOUCHERS';
export const setVouchers = makeActionCreator(SET_VOUCHERS, 'data');

export const ADD_VOUCHER = 'ADD_VOUCHER';
export const addVoucher = makeActionCreator(ADD_VOUCHER, 'data');

export const CLEAR_VOUCHERS = 'CLEAR_VOUCHERS';
export const clearVouchers = makeActionCreator(CLEAR_VOUCHERS);

export const ADDING_EDITING_VOUCHER = 'ADDING_EDITING_VOUCHER';
export const addingEditingVoucher = makeActionCreator(ADDING_EDITING_VOUCHER, 'status');

export const DOWNLOADING_DELETING_VOUCHER = 'DOWNLOADING_DELETING_VOUCHER';
export const downloadingDeletingVoucher = makeActionCreator(DOWNLOADING_DELETING_VOUCHER, 'status');

export const SAVING_VOUCHERS = 'SAVING_VOUCHERS';
export const savingVouchers = makeActionCreator(SAVING_VOUCHERS, 'status');

export const ENABLING_DISABLING_VOUCHER = 'ENABLING_DISABLING_VOUCHER';
export const enablingDisablingVoucher = makeActionCreator(ENABLING_DISABLING_VOUCHER, 'status');

export const ADDING_VOUCHER_APPLICABLE_BRANCHES = 'ADDING_VOUCHER_APPLICABLE_BRANCHES';
export const addingVoucherApplicableBranches = makeActionCreator(ADDING_VOUCHER_APPLICABLE_BRANCHES, 'status');

export const COPYING_VOUCHER_DEEP_LINK = 'COPYING_VOUCHER_DEEP_LINK';
export const copyingVoucherDeepLink = makeActionCreator(COPYING_VOUCHER_DEEP_LINK, 'status');


export const SET_VOUCHER_SELECTED_PAGE_SIZE = 'SET_VOUCHER_SELECTED_PAGE_SIZE';
export const setVoucherSelectedPageSize = makeActionCreator(SET_VOUCHER_SELECTED_PAGE_SIZE, 'size');

export const SET_VOUCHER_SELECTED_ORDER_BY = 'SET_VOUCHER_SELECTED_ORDER_BY';
export const setVoucherSelectedOrderBy = makeActionCreator(SET_VOUCHER_SELECTED_ORDER_BY, 'order');

export const SET_VOUCHER_TAPPED_ID = 'SET_VOUCHER_TAPPED_ID';
export const setVoucherTappedId = makeActionCreator(SET_VOUCHER_TAPPED_ID, 'id');

export const SET_VOUCHER_SEARCH_TEXT = 'SET_VOUCHER_SEARCH_TEXT';
export const setVoucherSearchText = makeActionCreator(SET_VOUCHER_SEARCH_TEXT, 'text');

export const SET_VOUCHER_SELECTED_MERCHANT_ID = 'SET_VOUCHER_SELECTED_MERCHANT_ID';
export const setVoucherSelectedMerchantId = makeActionCreator(SET_VOUCHER_SELECTED_MERCHANT_ID, 'id');

export const SET_VOUCHER_SELECTED_VOUCHER_CATEGORY_ID = 'SET_VOUCHER_SELECTED_VOUCHER_CATEGORY_ID';
export const setVoucherSelectedVoucherCategoryId = makeActionCreator(SET_VOUCHER_SELECTED_VOUCHER_CATEGORY_ID, 'id');

export const SET_VOUCHER_SELECTED_APPLICABLE_BRANCHES = 'SET_VOUCHER_SELECTED_APPLICABLE_BRANCHES';
export const setVoucherSelectedApplicableBranches = makeActionCreator(SET_VOUCHER_SELECTED_APPLICABLE_BRANCHES, 'option');

export const SET_VOUCHER_TABLE_DATA_APPLICABLE_BRANCHES = 'SET_VOUCHER_TABLE_DATA_APPLICABLE_BRANCHES';
export const setVoucherTableDataApplicableBranches = makeActionCreator(SET_VOUCHER_TABLE_DATA_APPLICABLE_BRANCHES, 'data');

export const SET_VOUCHER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_VOUCHER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setVoucherAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_VOUCHER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_VOUCHER_SELECTED_IS_PROMOTED = 'SET_VOUCHER_SELECTED_IS_PROMOTED';
export const setVoucherSelectedIsPromoted = makeActionCreator(SET_VOUCHER_SELECTED_IS_PROMOTED, 'data');

export const SET_VOUCHER_SELECTED_PAYMENT_TYPE = 'SET_VOUCHER_SELECTED_PAYMENT_TYPE';
export const setVoucherSelectedPaymentType = makeActionCreator(SET_VOUCHER_SELECTED_PAYMENT_TYPE, 'option');

export const SET_VOUCHER_SELECTED_TAGS = 'SET_VOUCHER_SELECTED_TAGS';
export const setVoucherSelectedTags = makeActionCreator(SET_VOUCHER_SELECTED_TAGS, 'option');

export const SET_VOUCHER_SELECTED_TANAMERA_FLAG = 'SET_VOUCHER_SELECTED_TANAMERA_FLAG';
export const setVoucherSelectedTanameraFlag = makeActionCreator(SET_VOUCHER_SELECTED_TANAMERA_FLAG, 'option');

export const SET_VOUCHER_SELECTED_GARUDA_FLAG = 'SET_VOUCHER_SELECTED_GARUDA_FLAG';
export const setVoucherSelectedGarudaFlag = makeActionCreator(SET_VOUCHER_SELECTED_GARUDA_FLAG, 'option');

export const SET_VOUCHER_IS_GARUDA_COMPANY_NAME = 'SET_VOUCHER_IS_GARUDA_COMPANY_NAME';
export const setVoucherIsGarudaCompanyName = makeActionCreator(SET_VOUCHER_IS_GARUDA_COMPANY_NAME, 'status');

export const SET_VOUCHER_IS_TANAMERA_COMPANY_NAME = 'SET_VOUCHER_IS_TANAMERA_COMPANY_NAME';
export const setVoucherIsTanameraCompanyName = makeActionCreator(SET_VOUCHER_IS_TANAMERA_COMPANY_NAME, 'status');

export const CHECKING_COMPANY_NAME = 'CHECKING_COMPANY_NAME';
export const checkingCompanyName = makeActionCreator(CHECKING_COMPANY_NAME, 'status');


export const DOWNLOADING_TOKEN_REQUESTS = 'DOWNLOADING_TOKEN_REQUESTS';
export const downloadingTokenRequests = makeActionCreator(DOWNLOADING_TOKEN_REQUESTS, 'status');

export const SET_TOKEN_REQUESTS = 'SET_TOKEN_REQUESTS';
export const setTokenRequests = makeActionCreator(SET_TOKEN_REQUESTS, 'data');

export const ADD_TOKEN_REQUEST = 'ADD_TOKEN_REQUEST';
export const addTokenRequest = makeActionCreator(ADD_TOKEN_REQUEST, 'data');

export const CLEAR_TOKEN_REQUESTS = 'CLEAR_TOKEN_REQUESTS';
export const clearTokenRequests = makeActionCreator(CLEAR_TOKEN_REQUESTS);

export const ADDING_TOKEN_REQUEST = 'ADDING_TOKEN_REQUEST';
export const addingTokenRequest = makeActionCreator(ADDING_TOKEN_REQUEST, 'status');

export const DOWNLOADING_TOKEN_REQUEST = 'DOWNLOADING_TOKEN_REQUEST';
export const downloadingTokenRequest = makeActionCreator(DOWNLOADING_TOKEN_REQUEST, 'status');

export const CANCELING_TOKEN_REQUEST = 'CANCELING_TOKEN_REQUEST';
export const cancelingTokenRequest = makeActionCreator(CANCELING_TOKEN_REQUEST, 'status');


export const SET_TOKEN_REQUEST_SELECTED_PAGE_SIZE = 'SET_TOKEN_REQUEST_SELECTED_PAGE_SIZE';
export const setTokenRequestSelectedPageSize = makeActionCreator(SET_TOKEN_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_TOKEN_REQUEST_SELECTED_ORDER_BY = 'SET_TOKEN_REQUEST_SELECTED_ORDER_BY';
export const setTokenRequestSelectedOrderBy = makeActionCreator(SET_TOKEN_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_TOKEN_REQUEST_TAPPED_ID = 'SET_TOKEN_REQUEST_TAPPED_ID';
export const setTokenRequestTappedId = makeActionCreator(SET_TOKEN_REQUEST_TAPPED_ID, 'id');

export const SET_TOKEN_REQUEST_SEARCH_TEXT = 'SET_TOKEN_REQUEST_SEARCH_TEXT';
export const setTokenRequestSearchText = makeActionCreator(SET_TOKEN_REQUEST_SEARCH_TEXT, 'text');

export const SET_TOKEN_REQUEST_SELECTED_VOUCHER_ID = 'SET_TOKEN_REQUEST_SELECTED_VOUCHER_ID';
export const setTokenRequestSelectedVoucherId = makeActionCreator(SET_TOKEN_REQUEST_SELECTED_VOUCHER_ID, 'id');


export const DOWNLOADING_VOUCHER_TOKENS = 'DOWNLOADING_VOUCHER_TOKENS';
export const downloadingVoucherTokens = makeActionCreator(DOWNLOADING_VOUCHER_TOKENS, 'status');

export const DOWNLOADING_VOUCHER_TOKEN = 'DOWNLOADING_VOUCHER_TOKEN';
export const downloadingVoucherToken = makeActionCreator(DOWNLOADING_VOUCHER_TOKEN, 'status');

export const SET_VOUCHER_TOKENS = 'SET_VOUCHER_TOKENS';
export const setVoucherTokens = makeActionCreator(SET_VOUCHER_TOKENS, 'data');

export const ADD_VOUCHER_TOKEN = 'ADD_VOUCHER_TOKEN';
export const addVoucherToken = makeActionCreator(ADD_VOUCHER_TOKEN, 'data');

export const CLEAR_VOUCHER_TOKENS = 'CLEAR_VOUCHER_TOKENS';
export const clearVoucherTokens = makeActionCreator(CLEAR_VOUCHER_TOKENS);

export const REVOKING_VOUCHER_TOKEN = 'REVOKING_VOUCHER_TOKEN';
export const revokingVoucherToken = makeActionCreator(REVOKING_VOUCHER_TOKEN, 'status');

export const SAVING_VOUCHER_TOKENS = 'SAVING_VOUCHER_TOKENS';
export const savingVoucherTokens = makeActionCreator(SAVING_VOUCHER_TOKENS, 'status');

export const REVOKING_SELECTED_VOUCHER_TOKENS = 'REVOKING_SELECTED_VOUCHER_TOKENS';
export const revokingSelectedVoucherTokens = makeActionCreator(REVOKING_SELECTED_VOUCHER_TOKENS, 'status');

export const MOVING_SELECTED_VOUCHER_TOKENS = 'MOVING_SELECTED_VOUCHER_TOKENS';
export const movingSelectedVoucherTokens = makeActionCreator(MOVING_SELECTED_VOUCHER_TOKENS, 'status');


export const SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE = 'SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE';
export const setVoucherTokenSelectedPageSize = makeActionCreator(SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE, 'size');

export const SET_VOUCHER_TOKEN_SELECTED_ORDER_BY = 'SET_VOUCHER_TOKEN_SELECTED_ORDER_BY';
export const setVoucherTokenSelectedOrderBy = makeActionCreator(SET_VOUCHER_TOKEN_SELECTED_ORDER_BY, 'order');

export const SET_VOUCHER_TOKEN_TAPPED_ID = 'SET_VOUCHER_TOKEN_TAPPED_ID';
export const setVoucherTokenTappedId = makeActionCreator(SET_VOUCHER_TOKEN_TAPPED_ID, 'id');

export const SET_VOUCHER_TOKEN_SEARCH_TEXT = 'SET_VOUCHER_TOKEN_SEARCH_TEXT';
export const setVoucherTokenSearchText = makeActionCreator(SET_VOUCHER_TOKEN_SEARCH_TEXT, 'text');

export const SET_VOUCHER_TOKEN_IS_GARUDA_COMPANY_NAME = 'SET_VOUCHER_TOKEN_IS_GARUDA_COMPANY_NAME';
export const setVoucherTokenIsGarudaCompanyName = makeActionCreator(SET_VOUCHER_TOKEN_IS_GARUDA_COMPANY_NAME, 'status');

export const SET_VOUCHER_TOKEN_MOVE_ALL_DIALOG_VISIBILITY = 'SET_VOUCHER_TOKEN_MOVE_ALL_DIALOG_VISIBILITY';
export const setVoucherTokenMoveAllDialogVisibility = makeActionCreator(SET_VOUCHER_TOKEN_MOVE_ALL_DIALOG_VISIBILITY, 'status');

export const SET_VOUCHER_TOKEN_SELECTED_VOUCHER_DESTINATION_OPTION = 'SET_VOUCHER_TOKEN_SELECTED_VOUCHER_DESTINATION_OPTION';
export const setVoucherTokenSelectedVoucherDestinationOption = makeActionCreator(SET_VOUCHER_TOKEN_SELECTED_VOUCHER_DESTINATION_OPTION, 'option');


export const DOWNLOADING_PURCHASERS = 'DOWNLOADING_PURCHASERS';
export const downloadingPurchasers = makeActionCreator(DOWNLOADING_PURCHASERS, 'status');

export const SET_PURCHASERS = 'SET_PURCHASERS';
export const setPurchasers = makeActionCreator(SET_PURCHASERS, 'data');

export const CLEAR_PURCHASERS = 'CLEAR_PURCHASERS';
export const clearPurchasers = makeActionCreator(CLEAR_PURCHASERS);

export const SET_PURCHASER_SEARCH_TEXT = 'SET_PURCHASER_SEARCH_TEXT';
export const setPurchaserSearchText = makeActionCreator(SET_PURCHASER_SEARCH_TEXT, 'text');


export const DOWNLOADING_REVOKERS = 'DOWNLOADING_REVOKERS';
export const downloadingRevokers = makeActionCreator(DOWNLOADING_REVOKERS, 'status');

export const SET_REVOKERS = 'SET_REVOKERS';
export const setRevokers = makeActionCreator(SET_REVOKERS, 'data');

export const CLEAR_REVOKERS = 'CLEAR_REVOKERS';
export const clearRevokers = makeActionCreator(CLEAR_REVOKERS);

export const SET_REVOKER_SEARCH_TEXT = 'SET_REVOKER_SEARCH_TEXT';
export const setRevokerSearchText = makeActionCreator(SET_REVOKER_SEARCH_TEXT, 'text');


export const DOWNLOADING_CLAIMERS = 'DOWNLOADING_CLAIMERS';
export const downloadingClaimers = makeActionCreator(DOWNLOADING_CLAIMERS, 'status');

export const SET_CLAIMERS = 'SET_CLAIMERS';
export const setClaimers = makeActionCreator(SET_CLAIMERS, 'data');

export const CLEAR_CLAIMERS = 'CLEAR_CLAIMERS';
export const clearClaimers = makeActionCreator(CLEAR_CLAIMERS);

export const SET_CLAIMER_SEARCH_TEXT = 'SET_CLAIMER_SEARCH_TEXT';
export const setClaimerSearchText = makeActionCreator(SET_CLAIMER_SEARCH_TEXT, 'text');


export const DOWNLOADING_TOKEN_IMPORTS = 'DOWNLOADING_TOKEN_IMPORTS';
export const downloadingTokenImports = makeActionCreator(DOWNLOADING_TOKEN_IMPORTS, 'status');

export const SET_TOKEN_IMPORTS = 'SET_TOKEN_IMPORTS';
export const setTokenImports = makeActionCreator(SET_TOKEN_IMPORTS, 'data');

export const ADD_TOKEN_IMPORT = 'ADD_TOKEN_IMPORT';
export const addTokenImport = makeActionCreator(ADD_TOKEN_IMPORT, 'data');

export const CLEAR_TOKEN_IMPORTS = 'CLEAR_TOKEN_IMPORTS';
export const clearTokenImports = makeActionCreator(CLEAR_TOKEN_IMPORTS);

export const IMPORTING_EDITING_TOKEN_IMPORT = 'IMPORTING_EDITING_TOKEN_IMPORT';
export const importingEditingTokenImport = makeActionCreator(IMPORTING_EDITING_TOKEN_IMPORT, 'status');

export const DOWNLOADING_DELETING_TOKEN_IMPORT = 'DOWNLOADING_DELETING_TOKEN_IMPORT';
export const downloadingDeletingTokenImport = makeActionCreator(DOWNLOADING_DELETING_TOKEN_IMPORT, 'status');

export const RETRYING_TOKEN_IMPORT = 'RETRYING_TOKEN_IMPORT';
export const retryingTokenImport = makeActionCreator(RETRYING_TOKEN_IMPORT, 'status');

export const SAVING_VOUCHER_TOKENS_IMPORTS = 'SAVING_VOUCHER_TOKENS_IMPORTS';
export const savingVoucherTokensImports = makeActionCreator(SAVING_VOUCHER_TOKENS_IMPORTS, 'status');


export const SET_TOKEN_IMPORT_SELECTED_PAGE_SIZE = 'SET_TOKEN_IMPORT_SELECTED_PAGE_SIZE';
export const setTokenImportSelectedPageSize = makeActionCreator(SET_TOKEN_IMPORT_SELECTED_PAGE_SIZE, 'size');

export const SET_TOKEN_IMPORT_SELECTED_ORDER_BY = 'SET_TOKEN_IMPORT_SELECTED_ORDER_BY';
export const setTokenImportSelectedOrderBy = makeActionCreator(SET_TOKEN_IMPORT_SELECTED_ORDER_BY, 'order');

export const SET_TOKEN_IMPORT_TAPPED_ID = 'SET_TOKEN_IMPORT_TAPPED_ID';
export const setTokenImportTappedId = makeActionCreator(SET_TOKEN_IMPORT_TAPPED_ID, 'id');

export const SET_TOKEN_IMPORT_SEARCH_TEXT = 'SET_TOKEN_IMPORT_SEARCH_TEXT';
export const setTokenImportSearchText = makeActionCreator(SET_TOKEN_IMPORT_SEARCH_TEXT, 'text');

export const SET_TOKEN_IMPORT_SELECTED_MERCHANT_ID = 'SET_TOKEN_IMPORT_SELECTED_MERCHANT_ID';
export const setTokenImportSelectedMerchantId = makeActionCreator(SET_TOKEN_IMPORT_SELECTED_MERCHANT_ID, 'id');

export const SET_TOKEN_IMPORT_SELECTED_CSV = 'SET_TOKEN_IMPORT_SELECTED_CSV';
export const setTokenImportSelectedCsv = makeActionCreator(SET_TOKEN_IMPORT_SELECTED_CSV, 'base64');

export const SET_TOKEN_IMPORT_SELECTED_VOUCHER = 'SET_TOKEN_IMPORT_SELECTED_VOUCHER';
export const setTokenImportSelectedVoucher = makeActionCreator(SET_TOKEN_IMPORT_SELECTED_VOUCHER, 'id');


export const DOWNLOADING_VOUCHER_PURCHASE_REQUESTS = 'DOWNLOADING_VOUCHER_PURCHASE_REQUESTS';
export const downloadingVoucherPurchaseRequests = makeActionCreator(DOWNLOADING_VOUCHER_PURCHASE_REQUESTS, 'status');

export const DOWNLOADING_VOUCHER_PURCHASE_REQUEST = 'DOWNLOADING_VOUCHER_PURCHASE_REQUEST';
export const downloadingVoucherPurchaseRequest = makeActionCreator(DOWNLOADING_VOUCHER_PURCHASE_REQUEST, 'status');

export const SET_VOUCHER_PURCHASE_REQUESTS = 'SET_VOUCHER_PURCHASE_REQUESTS';
export const setVoucherPurchaseRequests = makeActionCreator(SET_VOUCHER_PURCHASE_REQUESTS, 'data');

export const ADD_VOUCHER_PURCHASE_REQUEST = 'ADD_VOUCHER_PURCHASE_REQUEST';
export const addVoucherPurchaseRequest = makeActionCreator(ADD_VOUCHER_PURCHASE_REQUEST, 'data');

export const CLEAR_VOUCHER_PURCHASE_REQUESTS = 'CLEAR_VOUCHER_PURCHASE_REQUESTS';
export const clearVoucherPurchaseRequests = makeActionCreator(CLEAR_VOUCHER_PURCHASE_REQUESTS);


export const SET_VOUCHER_PURCHASE_REQUEST_SELECTED_PAGE_SIZE = 'SET_VOUCHER_PURCHASE_REQUEST_SELECTED_PAGE_SIZE';
export const setVoucherPurchaseRequestSelectedPageSize = makeActionCreator(SET_VOUCHER_PURCHASE_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_VOUCHER_PURCHASE_REQUEST_SELECTED_ORDER_BY = 'SET_VOUCHER_PURCHASE_REQUEST_SELECTED_ORDER_BY';
export const setVoucherPurchaseRequestSelectedOrderBy = makeActionCreator(SET_VOUCHER_PURCHASE_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_VOUCHER_PURCHASE_REQUEST_TAPPED_ID = 'SET_VOUCHER_PURCHASE_REQUEST_TAPPED_ID';
export const setVoucherPurchaseRequestTappedId = makeActionCreator(SET_VOUCHER_PURCHASE_REQUEST_TAPPED_ID, 'id');

export const SET_VOUCHER_PURCHASE_REQUEST_SEARCH_TEXT = 'SET_VOUCHER_PURCHASE_REQUEST_SEARCH_TEXT';
export const setVoucherPurchaseRequestSearchText = makeActionCreator(SET_VOUCHER_PURCHASE_REQUEST_SEARCH_TEXT, 'text');


export const DOWNLOADING_PROMOTED_MERCHANTS = 'DOWNLOADING_PROMOTED_MERCHANTS';
export const downloadingPromotedMerchants = makeActionCreator(DOWNLOADING_PROMOTED_MERCHANTS, 'status');

export const DOWNLOADING_PROMOTED_MERCHANT = 'DOWNLOADING_PROMOTED_MERCHANT';
export const downloadingPromotedMerchant = makeActionCreator(DOWNLOADING_PROMOTED_MERCHANT, 'status');

export const EDITING_PROMOTED_MERCHANT = 'EDITING_PROMOTED_MERCHANT';
export const editingPromotedMerchant = makeActionCreator(EDITING_PROMOTED_MERCHANT, 'status');

export const SET_PROMOTED_MERCHANTS = 'SET_PROMOTED_MERCHANTS';
export const setPromotedMerchants = makeActionCreator(SET_PROMOTED_MERCHANTS, 'data');

export const ADD_PROMOTED_MERCHANT = 'ADD_PROMOTED_MERCHANT';
export const addPromotedMerchant = makeActionCreator(ADD_PROMOTED_MERCHANT, 'data');

export const CLEAR_PROMOTED_MERCHANTS = 'CLEAR_PROMOTED_MERCHANTS';
export const clearPromotedMerchants = makeActionCreator(CLEAR_PROMOTED_MERCHANTS);


export const SET_PROMOTED_MERCHANT_SELECTED_PAGE_SIZE = 'SET_PROMOTED_MERCHANT_SELECTED_PAGE_SIZE';
export const setPromotedMerchantSelectedPageSize = makeActionCreator(SET_PROMOTED_MERCHANT_SELECTED_PAGE_SIZE, 'size');

export const SET_PROMOTED_MERCHANT_SELECTED_ORDER_BY = 'SET_PROMOTED_MERCHANT_SELECTED_ORDER_BY';
export const setPromotedMerchantSelectedOrderBy = makeActionCreator(SET_PROMOTED_MERCHANT_SELECTED_ORDER_BY, 'order');

export const SET_PROMOTED_MERCHANT_TAPPED_ID = 'SET_PROMOTED_MERCHANT_TAPPED_ID';
export const setPromotedMerchantTappedId = makeActionCreator(SET_PROMOTED_MERCHANT_TAPPED_ID, 'id');

export const SET_PROMOTED_MERCHANT_SEARCH_TEXT = 'SET_PROMOTED_MERCHANT_SEARCH_TEXT';
export const setPromotedMerchantSearchText = makeActionCreator(SET_PROMOTED_MERCHANT_SEARCH_TEXT, 'text');

export const SET_PROMOTED_MERCHANT_SELECTED_IS_PROMOTED = 'SET_PROMOTED_MERCHANT_SELECTED_IS_PROMOTED';
export const setPromotedMerchantSelectedIsPromoted = makeActionCreator(SET_PROMOTED_MERCHANT_SELECTED_IS_PROMOTED, 'value');


export const DOWNLOADING_GARUDA_AIRPORTS = 'DOWNLOADING_GARUDA_AIRPORTS';
export const downloadingGarudaAirports = makeActionCreator(DOWNLOADING_GARUDA_AIRPORTS, 'status');

export const SET_GARUDA_AIRPORTS = 'SET_GARUDA_AIRPORTS';
export const setGarudaAirports = makeActionCreator(SET_GARUDA_AIRPORTS, 'data');

export const ADD_GARUDA_AIRPORT = 'ADD_GARUDA_AIRPORT';
export const addGarudaAirport = makeActionCreator(ADD_GARUDA_AIRPORT, 'data');

export const CLEAR_GARUDA_AIRPORTS = 'CLEAR_GARUDA_AIRPORTS';
export const clearGarudaAirports = makeActionCreator(CLEAR_GARUDA_AIRPORTS);

export const ADDING_EDITING_GARUDA_AIRPORT = 'ADDING_EDITING_GARUDA_AIRPORT';
export const addingEditingGarudaAirport = makeActionCreator(ADDING_EDITING_GARUDA_AIRPORT, 'status');

export const DOWNLOADING_DELETING_GARUDA_AIRPORT = 'DOWNLOADING_DELETING_GARUDA_AIRPORT';
export const downloadingDeletingGarudaAirport = makeActionCreator(DOWNLOADING_DELETING_GARUDA_AIRPORT, 'status');


export const SET_GARUDA_AIRPORT_SELECTED_PAGE_SIZE = 'SET_GARUDA_AIRPORT_SELECTED_PAGE_SIZE';
export const setGarudaAirportSelectedPageSize = makeActionCreator(SET_GARUDA_AIRPORT_SELECTED_PAGE_SIZE, 'size');

export const SET_GARUDA_AIRPORT_SELECTED_ORDER_BY = 'SET_GARUDA_AIRPORT_SELECTED_ORDER_BY';
export const setGarudaAirportSelectedOrderBy = makeActionCreator(SET_GARUDA_AIRPORT_SELECTED_ORDER_BY, 'order');

export const SET_GARUDA_AIRPORT_TAPPED_ID = 'SET_GARUDA_AIRPORT_TAPPED_ID';
export const setGarudaAirportTappedId = makeActionCreator(SET_GARUDA_AIRPORT_TAPPED_ID, 'id');

export const SET_GARUDA_AIRPORT_SEARCH_TEXT = 'SET_GARUDA_AIRPORT_SEARCH_TEXT';
export const setGarudaAirportSearchText = makeActionCreator(SET_GARUDA_AIRPORT_SEARCH_TEXT, 'text');


export const DOWNLOADING_MAP_TOKEN_AIRPORTS = 'DOWNLOADING_MAP_TOKEN_AIRPORTS';
export const downloadingMapTokenAirports = makeActionCreator(DOWNLOADING_MAP_TOKEN_AIRPORTS, 'status');

export const SET_MAP_TOKEN_AIRPORTS = 'SET_MAP_TOKEN_AIRPORTS';
export const setMapTokenAirports = makeActionCreator(SET_MAP_TOKEN_AIRPORTS, 'data');

export const ADD_MAP_TOKEN_AIRPORT = 'ADD_MAP_TOKEN_AIRPORT';
export const addMapTokenAirport = makeActionCreator(ADD_MAP_TOKEN_AIRPORT, 'data');

export const CLEAR_MAP_TOKEN_AIRPORTS = 'CLEAR_MAP_TOKEN_AIRPORTS';
export const clearMapTokenAirports = makeActionCreator(CLEAR_MAP_TOKEN_AIRPORTS);


export const VIEW_MAP_VOUCHER_TOKEN_DIALOG = 'VIEW_MAP_VOUCHER_TOKEN_DIALOG';
export const viewMapVoucherTokenDialog = makeActionCreator(VIEW_MAP_VOUCHER_TOKEN_DIALOG, 'view');

export const SET_MAP_TOKEN_SELECTED_AIRPORT = 'SET_MAP_TOKEN_SELECTED_AIRPORT';
export const setMapTokenSelectedAirport = makeActionCreator(SET_MAP_TOKEN_SELECTED_AIRPORT, 'option');

export const SET_MAP_TOKEN_AIRPORT_SEARCH_TEXT = 'SET_MAP_TOKEN_AIRPORT_SEARCH_TEXT';
export const setMapTokenAirportSearchText = makeActionCreator(SET_MAP_TOKEN_AIRPORT_SEARCH_TEXT, 'text');

export const COUNTING_VOUCHER_TOKEN = 'COUNTING_VOUCHER_TOKEN';
export const countingVoucherToken = makeActionCreator(COUNTING_VOUCHER_TOKEN, 'status');

export const SET_VOUCHER_TOKEN_COUNT = 'SET_VOUCHER_TOKEN_COUNT';
export const setVoucherTokenCount = makeActionCreator(SET_VOUCHER_TOKEN_COUNT, 'count');

export const ENABLE_MAP_TOKEN_BUTTON = 'ENABLE_MAP_TOKEN_BUTTON';
export const enableMapTokenButton = makeActionCreator(ENABLE_MAP_TOKEN_BUTTON, 'toggle');

export const MAPPING_TOKEN_TO_AIRPORT = 'MAPPING_TOKEN_TO_AIRPORT';
export const mappingTokenToAirport = makeActionCreator(MAPPING_TOKEN_TO_AIRPORT, 'status');

export const VIEW_SUCCES_MAP_DIALOG = 'VIEW_SUCCES_MAP_DIALOG';
export const viewSuccessMapDialog = makeActionCreator(VIEW_SUCCES_MAP_DIALOG, 'view');


export const DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENTS = 'DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENTS';
export const downloadingVoucherTokenAssignments = makeActionCreator(DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENTS, 'status');

export const DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENT = 'DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENT';
export const downloadingVoucherTokenAssignment = makeActionCreator(DOWNLOADING_VOUCHER_TOKEN_ASSIGNMENT, 'status');

export const SET_VOUCHER_TOKEN_ASSIGNMENTS = 'SET_VOUCHER_TOKEN_ASSIGNMENTS';
export const setVoucherTokenAssignments = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENTS, 'data');

export const ADD_VOUCHER_TOKEN_ASSIGNMENT = 'ADD_VOUCHER_TOKEN_ASSIGNMENT';
export const addVoucherTokenAssignment = makeActionCreator(ADD_VOUCHER_TOKEN_ASSIGNMENT, 'data');

export const CLEAR_VOUCHER_TOKEN_ASSIGNMENTS = 'CLEAR_VOUCHER_TOKEN_ASSIGNMENTS';
export const clearVoucherTokenAssignments = makeActionCreator(CLEAR_VOUCHER_TOKEN_ASSIGNMENTS);

export const ADDING_EDITING_VOUCHER_TOKEN_ASSIGNMENT = 'ADDING_EDITING_VOUCHER_TOKEN_ASSIGNMENT';
export const addingEditingVoucherTokenAssignment = makeActionCreator(ADDING_EDITING_VOUCHER_TOKEN_ASSIGNMENT, 'status');


export const SET_VOUCHER_TOKEN_ASSIGNMENT_TAPPED_ID = 'SET_VOUCHER_TOKEN_ASSIGNMENT_TAPPED_ID';
export const setVoucherTokenAssignmentTappedId = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_TAPPED_ID, 'id');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_SEARCH_TEXT = 'SET_VOUCHER_TOKEN_ASSIGNMENT_SEARCH_TEXT';
export const setVoucherTokenAssignmentSearchText = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_SEARCH_TEXT, 'text');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_PAGE_SIZE = 'SET_VOUCHER_TOKEN_ASSIGNMENT_PAGE_SIZE';
export const setVoucherTokenAssignmentPageSize = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_PAGE_SIZE, 'size');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_ORDER_BY = 'SET_VOUCHER_TOKEN_ASSIGNMENT_ORDER_BY';
export const setVoucherTokenAssignmentOrderBy = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_ORDER_BY, 'order');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_VOUCHER = 'SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_VOUCHER';
export const setVoucherTokenAssignmentSelectedVoucher = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_VOUCHER, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_ASSIGNMENT_MODE = 'SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_ASSIGNMENT_MODE';
export const setVoucherTokenAssignmentSelectedAssignmentMode = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_ASSIGNMENT_MODE, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_PAYMENT = 'SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_PAYMENT';
export const setVoucherTokenAssignmentSelectedBypassPayment = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_PAYMENT, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_MAX_PURCHASE = 'SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_MAX_PURCHASE';
export const setVoucherTokenAssignmentSelectedBypassMaxPurchase = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_MAX_PURCHASE, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_ACHIEVEMENT = 'SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_ACHIEVEMENT';
export const setVoucherTokenAssignmentSelectedBypassAchievement = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_BYPASS_ACHIEVEMENT, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_USER = 'SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_USER';
export const setVoucherTokenAssignmentSelectedUser = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_SELECTED_USER, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD = 'SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD';
export const setVoucherTokenAssignmentAssignmentMethod = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM = 'SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM';
export const setVoucherTokenAssignmentAssignmentValidFrom = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL = 'SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL';
export const setVoucherTokenAssignmentAssignmentValidUntil = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_AIRPORT = 'SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_AIRPORT';
export const setVoucherTokenAssignmentAssignmentAirport = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_AIRPORT, 'option');

export const SET_VOUCHER_TOKEN_ASSIGNMENT_COPYING_REDEMPTION_CODE = 'SET_VOUCHER_TOKEN_ASSIGNMENT_COPYING_REDEMPTION_CODE';
export const setVoucherTokenAssignmentCopyingRedemptionCode = makeActionCreator(SET_VOUCHER_TOKEN_ASSIGNMENT_COPYING_REDEMPTION_CODE, 'status');

export const SET_VOUCHER_TOKEN_SELECTED_ROWS = 'SET_VOUCHER_TOKEN_SELECTED_ROWS';
export const setVoucherTokenSelectedRows = makeActionCreator(SET_VOUCHER_TOKEN_SELECTED_ROWS, 'data');


export const ENABLING_DISABLING_VOUCHER_TOKEN_ASSIGNMENT = 'ENABLING_DISABLING_VOUCHER_TOKEN_ASSIGNMENT';
export const enablingDisablingVoucherTokenAssignment = makeActionCreator(ENABLING_DISABLING_VOUCHER_TOKEN_ASSIGNMENT, 'status');

export const DOWNLOADING_GIFT_VOUCHER_REDEMPTIONS = 'DOWNLOADING_GIFT_VOUCHER_REDEMPTIONS';
export const downloadingGiftVoucherRedemptions = makeActionCreator(DOWNLOADING_GIFT_VOUCHER_REDEMPTIONS, 'status');

export const SET_GIFT_VOUCHER_REDEMPTIONS = 'SET_GIFT_VOUCHER_REDEMPTIONS';
export const setGiftVoucherRedemptions = makeActionCreator(SET_GIFT_VOUCHER_REDEMPTIONS, 'data');

export const ADD_GIFT_VOUCHER_REDEMPTION = 'ADD_GIFT_VOUCHER_REDEMPTION';
export const addGiftVoucherRedemption = makeActionCreator(ADD_GIFT_VOUCHER_REDEMPTION, 'data');

export const CLEAR_GIFT_VOUCHER_REDEMPTIONS = 'CLEAR_GIFT_VOUCHER_REDEMPTIONS';
export const clearGiftVoucherRedemptions = makeActionCreator(CLEAR_GIFT_VOUCHER_REDEMPTIONS);

export const DOWNLOADING_GIFT_VOUCHER_REDEMPTION = 'DOWNLOADING_GIFT_VOUCHER_REDEMPTION';
export const downloadingDeletingGiftVoucherRedemption = makeActionCreator(DOWNLOADING_GIFT_VOUCHER_REDEMPTION, 'status');


export const SET_GIFT_VOUCHER_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_GIFT_VOUCHER_REDEMPTION_SELECTED_PAGE_SIZE';
export const setGiftVoucherRedemptionSelectedPageSize = makeActionCreator(SET_GIFT_VOUCHER_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIFT_VOUCHER_REDEMPTION_SELECTED_ORDER_BY = 'SET_GIFT_VOUCHER_REDEMPTION_SELECTED_ORDER_BY';
export const setGiftVoucherRedemptionSelectedOrderBy = makeActionCreator(SET_GIFT_VOUCHER_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_GIFT_VOUCHER_REDEMPTION_TAPPED_ID = 'SET_GIFT_VOUCHER_REDEMPTION_TAPPED_ID';
export const setGiftVoucherRedemptionTappedId = makeActionCreator(SET_GIFT_VOUCHER_REDEMPTION_TAPPED_ID, 'id');

export const SET_GIFT_VOUCHER_REDEMPTION_SEARCH_TEXT = 'SET_GIFT_VOUCHER_REDEMPTION_SEARCH_TEXT';
export const setGiftVoucherRedemptionSearchText = makeActionCreator(SET_GIFT_VOUCHER_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_GIFT_VOUCHER_REDEMPTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_GIFT_VOUCHER_REDEMPTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setGiftVoucherRedemptionAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_GIFT_VOUCHER_REDEMPTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
