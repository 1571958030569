import { downloadJaw2023Survey } from '../../../helper';
import { downloadingJaw2023Survey, setJaw2023Survey, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiJaw2023Survey } = getState();

  const { token } = authentication;
  const { tappedId } = uiJaw2023Survey;

  try {
    dispatch(downloadingJaw2023Survey(true));

    const scannedCode = await downloadJaw2023Survey(
      tappedId,
      token,
    );

    dispatch(setJaw2023Survey(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingJaw2023Survey(false));
  }
};
