import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { downloadUsersAsync } from '../../../voucher';
import {
  INITIAL_ORDER_BY_ACCESSORY_TRANSACTIONS, REST_URL_GOOGLE_MAP,
  MENUID_GENUINE_ACCESSORY_TRANSACTION,
  PAGE_MODE_TABLE,
  RXFORM_ACCESSORY,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  REST_BASE_URL,
  REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  transformInitialValues, transformPaymentMethod, transformTransactionStatus,
  transformUserDropdownData,
  toMoment,
  toCurrency,
  sortAsc,
  getTimeSymbol,
  transformUserAddress,
} from '../../helper';
import {
  clearAccessoryTransactions,
  downloadAccessoryTransactionsAsync,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setAccessoryTransactionsSearchText,
  setAccessoryTransactionsSelectedPageSize,
  setAccessoryTransactionsSelectedOrderBy,
  setAccessoryTransactionTappedId,
  clearUsers,
  setUserSearchText,
  setUserAdvancedFilterDialogSelectedFilterString,
  setAccessoryTransactionsAdvancedFilterString,
  downloadAccessoryTransactionAsync,
  saveTransactionsAsync,
} from '../../redux/action';
import AccessoryTransactionPage from './transaction.presentation';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiAccessoryTransaction, accessoryTransactions } = state;
  const { data } = accessoryTransactions;
  const { downloadingDeleting, tappedId } = uiAccessoryTransaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      paymentDueDate: found?.payment?.validUntil
        ? toMoment(found.payment.validUntil).format(
          DATE_TIME_FORMAT_WITHOUT_PIPE,
        )
        : '',
      paymentDate: found?.payment?.createdDate
        ? toMoment(found.payment.createdDate).format(
          DATE_TIME_FORMAT_WITHOUT_PIPE,
        )
        : '',
      tax: found?.tax ? `Rp${toCurrency(found.tax)}` : '',
      paymentTotal: found?.tax && found?.totalWithTax
        ? `Rp${toCurrency(found?.totalWithTax - found?.tax)}`
        : '',
      grandTotal: found?.totalWithTax
        ? `Rp${toCurrency(found.totalWithTax)}`
        : '',
      items:
            found?.items?.length > 0 && !downloadingDeleting
              ? found?.items.sort((a, b) => sortAsc(a.orderId, b.orderId))
              .map((el) => ({
                ...el,
                pickupTime: el?.pickupTime
                  ? `${toMoment(el?.pickupTime, el.pickupLocation.timeZone)
                    .format(DATE_TIME_FORMAT_WITHOUT_PIPE)} ${getTimeSymbol(el.pickupLocation.timeZone)}`
                  : '',
                unitPrice: el?.unitPrice ? `Rp${toCurrency(el.unitPrice)}` : '',
                tax: el?.tax ? `Rp${toCurrency(el.tax)}` : '',
                subTotalWithTax: el?.subTotalWithTax ? `Rp${toCurrency(el.subTotalWithTax)}` : '',
                accessory: {
                  ...el.accessory,
                  price: el?.accessory?.price ? `Rp${toCurrency(el.accessory.price)}` : '',
                  image: el?.accessory?.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${el.accessory.image}` : '',
                },
                pickupLocation: {
                  ...el.pickupLocation,
                  hasChargingStation: el?.pickupLocation?.hasChargingStation === true
                    ? GlobalLocalizedString.common.labelYes
                    : GlobalLocalizedString.common.labelNo,
                  openTime: el?.pickupLocation?.openTime
                    ? `${el.pickupLocation.openTime} ${getTimeSymbol(el.pickupLocation.timeZone)}` : '',
                  closeTime: el?.pickupLocation?.closeTime
                    ? `${el.pickupLocation.closeTime} ${getTimeSymbol(el.pickupLocation.timeZone)}` : '',
                  longitude: el?.pickupLocation?.longitude ? el.pickupLocation.longitude.toString() : '',
                  latitude: el?.pickupLocation?.latitude ? el.pickupLocation.latitude.toString() : '',
                },
                quantity: el?.quantity ? el.quantity.toString() : '',
              })) : [],
      paymentMethod: found?.payment?.paymentMethod
        ? transformPaymentMethod(found.payment.paymentMethod) : '',
      userAddress: transformUserAddress(found.user),
      transactionStatus: transformTransactionStatus(found.transactionStatus),
    })
    : {};
  return result;
};

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  downloading: state.uiAccessoryTransaction.downloading,
  loadingUser: state.uiUser.downloading,
  downloadingDeleting: state.uiAccessoryTransaction.downloadingDeleting,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GENUINE_ACCESSORY_TRANSACTION));
    dispatch(setAccessoryTransactionsSearchText(''));
    dispatch(setAccessoryTransactionsAdvancedFilterString(''));
    dispatch(clearAccessoryTransactions());
    dispatch(setAccessoryTransactionsSelectedPageSize(20));
    dispatch(
      setAccessoryTransactionsSelectedOrderBy(
        INITIAL_ORDER_BY_ACCESSORY_TRANSACTIONS,
      ),
    );
    dispatch(downloadAccessoryTransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id')
      .replace('transactionStatus', 'transactionStatus')
      .replace('paymentStatus', 'payment.paymentStatus')
      .replaceAll('paymentDate', 'payment.createdDate')
      .replace('paymentMethod', 'payment.paymentMethod')
      .replace('createdDate', 'createdDate')
      .replace('transactionId', 'transactionId')
      .replace('transactionStatus=', 'transactionStatus><');

    dispatch(setAccessoryTransactionsAdvancedFilterString(text));
    dispatch(clearAccessoryTransactions());
    dispatch(downloadAccessoryTransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAccessoryTransactionsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAccessoryTransactionsSelectedPageSize(pageSize));
    dispatch(downloadAccessoryTransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveTransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setAccessoryTransactionsSelectedPageSize(pageSize));
    dispatch(clearAccessoryTransactions());
    dispatch(downloadAccessoryTransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearAccessoryTransactions());
    dispatch(setAccessoryTransactionsAdvancedFilterString(''));
    dispatch(
      setAccessoryTransactionsSelectedOrderBy(
        INITIAL_ORDER_BY_ACCESSORY_TRANSACTIONS,
      ),
    );
    dispatch(downloadAccessoryTransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setAccessoryTransactionsSearchText(text));
    dispatch(clearAccessoryTransactions());
    dispatch(downloadAccessoryTransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setAccessoryTransactionsSelectedOrderBy(orderBy));
    dispatch(clearAccessoryTransactions());
    dispatch(downloadAccessoryTransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setAccessoryTransactionTappedId(id));
    dispatch(downloadAccessoryTransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_ACCESSORY));
  },
  onOpenGoogleMaps: (lat, long) => {
    const url = REST_URL_GOOGLE_MAP.replace(/\{long\}/, long).replace(/\{lat\}/, lat);
    window.open(url);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccessoryTransactionPage);
