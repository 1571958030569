import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage, SectionTitle } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  PICKER_DATE_FORMAT, FILTER_TYPE_TEXT,
  PICKER_MODE_DATE,
  RXFORM_CAR_SERVICE_RESERVATION, RXSTATE_CAR_SERVICE_RESERVATIONS,
  RXSTATE_CAR_SERVICE_RESERVATION_PAGE,
  RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_NAME, RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_PHONE,
  RXFIELD_CAR_SERVICE_RESERVATION_CONTACT_PERSON,
  RXFIELD_CAR_SERVICE_RESERVATION_CONTACT_PERSON_PHONE,
  RXFIELD_CAR_SERVICE_RESERVATION_PICKUP_LOCATION,
  RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_LOCATION,
  RXFIELD_CAR_SERVICE_RESERVATION_BOOKING_CREATED,
  RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_SCHEDULED, RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_STATUS,
  RXFIELD_CAR_SERVICE_RESERVATION_ODOMETER, RXFIELD_CAR_SERVICE_RESERVATION_REPAIR_SELECTION,
  RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_INTERVAL,
  RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD, RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_METHOD,
  RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_STATUS, RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_DATE,
  RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_TOTAL, RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_1,
  RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_2, RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_3,
  RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_4, RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_5,
  RXFIELD_CAR_SERVICE_RESERVATION_CAR_SERVICE_RESERVATION_NOTE,
  RXFIELD_SERVICE_REQUEST_REPAIR_TYPE, RXFIELD_CAR_SERVICE_RESERVATION_VEHICLE_MODEL,
  REPAIR_TYPE_DROPDOWN_SELECTION, SERVICE_STATUS_DROPDOWN_SELECTION,
  DELIVERY_METHOD_DROPDOWN_SELECTION, PAYMENT_METHOD_DROPDOWN_SELECTION,
  PAYMENT_STATUS_DROPDOWN_SELECTION, RXFIELD_CAR_SERVICE_RESERVATION_VIN,
  RXFIELD_CAR_SERVICE_RESERVATION_BOOKING_CODE, RXFIELD_CAR_SERVICE_RESERVATION_DEALER_NAME,
  RXFIELD_CAR_SERVICE_RESERVATION_USER_NAME,
  RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_TYPE,
  RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_FINISH_TIME,
  RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_ARRIVAL_TIME,
  RXFIELD_CAR_SERVICE_RESERVATION_VA_NUMBER,
  RXFIELD_CAR_SERVICE_RESERVATION_MEMBER_ID,
  RXFIELD_CAR_SERVICE_RESERVATION_EMAIL,
  RXFIELD_CAR_SERVICE_RESERVATION_ADDRESS,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormImageInputField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import { RXFIELD_REGISTRATION_VEHICLE_VIN } from '../../../car-registration';
import {
  getDeliveryMethod, transformUserAddress,
  getPaymentMethod, getRepairType, getServiceStatus, toCurrency,
} from '../../helper';
import Header from './header';
import Footer from './footer';

const renderDialogContent = (
  initialValues, downloadingDeleting, pageMode,
  isBodyAndPaint, isDelivery, isPickup, isPickupDelivery,
) => (
  <div>
    <SectionTitle title={LocalizedString.reservationPage.labelCustomerInfo} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderCustomerName}
            placeholder={LocalizedString.reservationPage.placeholderCustomerName}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_PHONE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderCustomerPhone}
            placeholder={LocalizedString.reservationPage.placeholderCustomerPhone}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_MEMBER_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.labelMemberId}
            placeholder={LocalizedString.reservationPage.labelMemberId}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_EMAIL}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.labelEmail}
            placeholder={LocalizedString.reservationPage.labelEmail}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.labelAddress}
            placeholder={LocalizedString.reservationPage.labelAddress}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_TYPE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderCustomerType}
            placeholder={LocalizedString.reservationPage.placeholderCustomerType}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            hidden={!isBodyAndPaint}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_PICKUP_LOCATION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderPickupLocation}
            placeholder={LocalizedString.reservationPage.placeholderPickupLocation}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
            hidden={!isPickup && !isPickupDelivery}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_LOCATION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderDeliveryLocation}
            placeholder={LocalizedString.reservationPage.placeholderDeliveryLocation}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
            hidden={!isDelivery && !isPickupDelivery}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_CONTACT_PERSON}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderContactPerson}
            placeholder={LocalizedString.reservationPage.placeholderContactPerson}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_CONTACT_PERSON_PHONE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderContactPersonPhone}
            placeholder={LocalizedString.reservationPage.placeholderContactPersonPhone}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_BOOKING_CREATED}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderBookingCreated}
            placeholder={LocalizedString.reservationPage.placeholderBookingCreated}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_SCHEDULED}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderServiceScheduled}
            placeholder={LocalizedString.reservationPage.placeholderServiceScheduled}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_FINISH_TIME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderServiceFinishTime}
            placeholder={LocalizedString.reservationPage.placeholderServiceFinishTime}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_ARRIVAL_TIME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderArrivalTime}
            placeholder={LocalizedString.reservationPage.placeholderArrivalTime}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.reservationPage.labelServiceInfo} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_VEHICLE_MODEL}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderVehicleModel}
            placeholder={LocalizedString.reservationPage.placeholderVehicleModel}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REGISTRATION_VEHICLE_VIN}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderVin}
            placeholder={LocalizedString.reservationPage.placeholderVin}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_ODOMETER}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderOdometer}
            placeholder={LocalizedString.reservationPage.placeholderOdometer}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_REPAIR_SELECTION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderRepairSelection}
            placeholder={LocalizedString.reservationPage.placeholderRepairSelection}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_INTERVAL}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderServiceInterval}
            placeholder={LocalizedString.reservationPage.placeholderServiceInterval}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderDeliveryMethod}
            placeholder={LocalizedString.reservationPage.placeholderDeliveryMethod}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_STATUS}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderServiceStatus}
            placeholder={LocalizedString.reservationPage.placeholderServiceStatus}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_METHOD}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderPaymentMethod}
            placeholder={LocalizedString.reservationPage.placeholderPaymentMethod}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        {initialValues.vaNumber && (
          <Grid item>
            <Field
              name={RXFIELD_CAR_SERVICE_RESERVATION_VA_NUMBER}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.reservationPage.placeholderVaNumber}
              placeholder={LocalizedString.reservationPage.placeholderVaNumber}
              disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        )}
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_STATUS}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderPaymentStatus}
            placeholder={LocalizedString.reservationPage.placeholderPaymentStatus}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_DATE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderPaymentDate}
            placeholder={LocalizedString.reservationPage.placeholderPaymentDate}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_TOTAL}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderPaymentTotal}
            placeholder={LocalizedString.reservationPage.placeholderPaymentTotal}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.reservationPage.labelPicture} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_1}
            component={renderReduxFormImageInputField}
            label={LocalizedString.reservationPage.placeholderImage1}
            defaultValue={initialValues.image01}
            useFullWidthImage
            useAvatarWithoutCropper
            fullImageWidth="100%"
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_2}
            component={renderReduxFormImageInputField}
            label={LocalizedString.reservationPage.placeholderImage2}
            defaultValue={initialValues.image02}
            useFullWidthImage
            useAvatarWithoutCropper
            fullImageWidth="100%"
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_3}
            component={renderReduxFormImageInputField}
            label={LocalizedString.reservationPage.placeholderImage3}
            defaultValue={initialValues.image03}
            useFullWidthImage
            useAvatarWithoutCropper
            fullImageWidth="100%"
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_4}
            component={renderReduxFormImageInputField}
            label={LocalizedString.reservationPage.placeholderImage4}
            defaultValue={initialValues.image04}
            useFullWidthImage
            useAvatarWithoutCropper
            fullImageWidth="100%"
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_5}
            component={renderReduxFormImageInputField}
            label={LocalizedString.reservationPage.placeholderImage5}
            defaultValue={initialValues.image05}
            useFullWidthImage
            useAvatarWithoutCropper
            fullImageWidth="100%"
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.reservationPage.labelNote} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_SERVICE_RESERVATION_CAR_SERVICE_RESERVATION_NOTE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.reservationPage.placeholderReservationNotes}
            placeholder={LocalizedString.reservationPage.placeholderReservationNotes}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const ReservationPage = ({
  users, dealers, downloading, downloadingDeleting, initialValues,
  handleSubmit, onAppear, loadingDealer, loadingUser, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onCancelPressed, onChangePage, onChangePageSize,
  onChangeDealerText, onChangeUserText, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed, pageMode, onDownloadPressed,
  isBodyAndPaint, isDelivery, isPickup, isPickupDelivery,
}) => (
  <FunctionalPage
    data={RXSTATE_CAR_SERVICE_RESERVATIONS}
    uiPage={RXSTATE_CAR_SERVICE_RESERVATION_PAGE}
    filterColumns={[
      {
        title: LocalizedString.reservationPage.placeholderCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        format: PICKER_DATE_FORMAT,
        pickerMode: PICKER_MODE_DATE,
      },
      {
        title: LocalizedString.reservationPage.placeholderServiceDate,
        field: RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_SCHEDULED,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE,
        format: PICKER_DATE_FORMAT,
      },
      {
        title: LocalizedString.reservationPage.placeholderRepairType,
        field: RXFIELD_SERVICE_REQUEST_REPAIR_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: REPAIR_TYPE_DROPDOWN_SELECTION,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.placeholderServiceStatus,
        field: RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: SERVICE_STATUS_DROPDOWN_SELECTION,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.placeholderDeliveryMethod,
        field: RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD,
        type: FILTER_TYPE_DROPDOWN,
        data: DELIVERY_METHOD_DROPDOWN_SELECTION,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.placeholderPaymentMethod,
        field: RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_METHOD,
        type: FILTER_TYPE_DROPDOWN,
        data: PAYMENT_METHOD_DROPDOWN_SELECTION,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.placeholderPaymentStatus,
        field: RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: PAYMENT_STATUS_DROPDOWN_SELECTION,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.placeholderBookingCode,
        field: RXFIELD_CAR_SERVICE_RESERVATION_BOOKING_CODE,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.reservationPage.placeholderVin,
        field: RXFIELD_CAR_SERVICE_RESERVATION_VIN,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.reservationPage.placeholderDealer,
        field: RXFIELD_CAR_SERVICE_RESERVATION_DEALER_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: dealers,
        loading: loadingDealer,
        onChangeFilterText: onChangeDealerText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.placeholderUser,
        field: RXFIELD_CAR_SERVICE_RESERVATION_USER_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.reservationPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.reservationPage.labelBookingId, field: 'bookingCode', sorting: !downloading },
      { title: LocalizedString.reservationPage.labelDealerName, field: 'dealer.name', sorting: !downloading },
      { title: LocalizedString.reservationPage.labelCustomerName, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.reservationPage.labelCustomerPhone, field: 'user.phone', sorting: !downloading },
      { title: LocalizedString.reservationPage.labelMemberId, field: 'user.userCode', sorting: !downloading },
      { title: LocalizedString.reservationPage.labelEmail, field: 'user.email', sorting: false },
      {
        title: LocalizedString.reservationPage.labelAddress,
        field: 'address',
        sorting: false,
        render: ({ user }) => transformUserAddress(user),
      },
      {
        title: LocalizedString.reservationPage.labelRepairSelection,
        field: 'repairType',
        render: ({ repairType }) => getRepairType(repairType),
        sorting: !downloading,
      },
      { title: LocalizedString.reservationPage.labelVehicleModel, field: 'vehicle.model', sorting: !downloading },
      { title: LocalizedString.reservationPage.labelServiceInterval, field: 'periodicService.description', sorting: !downloading },
      {
        title: LocalizedString.reservationPage.labelDeliveryMethod,
        field: 'deliveryMethod',
        render: ({ deliveryMethod }) => getDeliveryMethod(deliveryMethod),
        sorting: !downloading,
      },
      {
        title: LocalizedString.reservationPage.labelPaymentMethod,
        field: 'payment.paymentMethod',
        render: ({ payment }) => getPaymentMethod(payment?.paymentMethod),
        sorting: !downloading,
      },
      {
        title: LocalizedString.reservationPage.labelPaymentAmount,
        field: 'payment.total',
        sorting: !downloading,
        render: ({ payment }) => (payment
          ? `Rp${toCurrency(payment.total)}` : null),
      },
      {
        title: LocalizedString.reservationPage.labelBookingStatus,
        field: 'serviceStatus',
        render: ({ serviceStatus }) => getServiceStatus(serviceStatus),
        sorting: !downloading,
      },
      { title: LocalizedString.reservationPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCancelPressed={onCancelPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.reservationPage.title}
    onDownloadPressed={onDownloadPressed}
    enableSave
    disableCreate
    disableEdit
    disableDelete
    useFullWidth
  >
    <div>
      <Header />
      {renderDialogContent(
        initialValues, downloadingDeleting, pageMode,
        isBodyAndPaint, isDelivery, isPickup, isPickupDelivery,
      )}
      <Footer />
    </div>
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CAR_SERVICE_RESERVATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ReservationPage);

ReservationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  dealers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingDealer: PropTypes.bool.isRequired,
  isBodyAndPaint: PropTypes.bool.isRequired,
  isPickup: PropTypes.bool.isRequired,
  isDelivery: PropTypes.bool.isRequired,
  isPickupDelivery: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeDealerText: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
