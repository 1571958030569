import { SET_TRANSACTION_CANCELED, CLEAR_TRANSACTION_CANCELED } from '../action';

const initialState = {
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_CANCELED: {
      return {
        data: action.count,
      };
    }
    case CLEAR_TRANSACTION_CANCELED:
      return initialState;
    default:
      return state;
  }
};
