import {
  mappingTokenToAirport, setAlertInfoMessage,
  viewMapVoucherTokenDialog,
} from '../simple-action';
import { mapTokenToAirport } from '../../../helper';
import LocalizedString from '../../../localization';

export default (remark) => async (dispatch, getState) => {
  try {
    dispatch(mappingTokenToAirport(true));

    const { authentication, uiGarudaAirport, garudaAirports } = getState();
    const { token } = authentication;
    const { selectedAirport, tokenCount } = uiGarudaAirport;

    await mapTokenToAirport(remark, selectedAirport, token);

    const airport = Object.values(garudaAirports.data)
      .find((i) => i.id === selectedAirport);

    const message = `${tokenCount} ${LocalizedString.voucherGarudaAirportPage.labelTokensWereMapped} ${airport.name}`;

    dispatch(viewMapVoucherTokenDialog(false));
    dispatch(setAlertInfoMessage(message));
  } finally {
    dispatch(mappingTokenToAirport(false));
  }
};
