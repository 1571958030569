import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT, PICKER_DATE_TIME_FORMAT, PICKER_MODE_DATE_TIME,
  RXFORM_CAR_OWNERSHIP_REWARD, RXSTATE_CAR_OWNERSHIP_REWARDS, RXSTATE_CAR_OWNERSHIP_REWARD_PAGE,
  CAR_OWNERSHIP_REWARD_TIER, RXFIELD_CAR_OWNERSHIP_REWARD_VIN, RXFIELD_CAR_OWNERSHIP_REWARD_MODEL,
  RXFIELD_CAR_OWNERSHIP_REWARD_TRIM, RXFIELD_CAR_OWNERSHIP_REWARD_COLOR,
  RXFIELD_CAR_OWNERSHIP_REWARD_LICENSE_PLATE, RXFIELD_CAR_OWNERSHIP_REWARD_ENGINE_NO,
  RXFIELD_CAR_OWNERSHIP_REWARD_TIER, RXFIELD_CAR_OWNERSHIP_REWARD_REWARD_AMOUNT,
  RXFIELD_CAR_OWNERSHIP_REWARD_REGISTRATION_ID,
  RXFIELD_CAR_OWNERSHIP_REWARD_REGISTRATION_APPROVED_ON,
  RXFIELD_CAR_OWNERSHIP_REWARD_SCHEDULED_REWARD,
  RXFIELD_CAR_OWNERSHIP_REWARD_ALLOCATION_STATUS,
  CAR_OWNERSHIP_REWARD_ALLOCATION_STATUS,
  RXFIELD_CAR_OWNERSHIP_REWARD_NOTES,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormEditableTableField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';
import { toCurrency } from '../../../../helper';
import { PAGE_MODE_TABLE } from '../../../attendance';

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: 'relative',
    paddingTop: 10,
  },
}));

const renderDialogContent = (
  itemInitialValues, classes, downloadingDeleting,
  pageMode, totalCurrentPage, tokenTotalCount,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_VIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderVin}
            label={LocalizedString.carOwnershipRewardPage.placeholderVin}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_MODEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderModel}
            label={LocalizedString.carOwnershipRewardPage.placeholderModel}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_TRIM}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderTrim}
            label={LocalizedString.carOwnershipRewardPage.placeholderTrim}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_COLOR}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderColor}
            label={LocalizedString.carOwnershipRewardPage.placeholderColor}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_LICENSE_PLATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderLicensePlate}
            label={LocalizedString.carOwnershipRewardPage.placeholderLicensePlate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_ENGINE_NO}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderEngineNo}
            label={LocalizedString.carOwnershipRewardPage.placeholderEngineNo}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_TIER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderTier}
            label={LocalizedString.carOwnershipRewardPage.placeholderTier}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_REWARD_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderRewardAmount}
            label={LocalizedString.carOwnershipRewardPage.placeholderRewardAmount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_ALLOCATION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderRewardAllocationStatus}
            label={LocalizedString.carOwnershipRewardPage.placeholderRewardAllocationStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderRewardNotes}
            label={LocalizedString.carOwnershipRewardPage.labelNotes}
            disabled
            multiple
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_REGISTRATION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderRegistrationId}
            label={LocalizedString.carOwnershipRewardPage.placeholderRegistrationId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_CAR_OWNERSHIP_REWARD_REGISTRATION_APPROVED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.carOwnershipRewardPage.placeholderApprovedOn}
            label={LocalizedString.carOwnershipRewardPage.placeholderApprovedOn}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
    {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
      <Grid item sm={12} className={classes.tableContainer}>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_REWARD_SCHEDULED_REWARD}
          component={renderReduxFormEditableTableField}
          disabled
          disableToolbar
          hidden={pageMode === PAGE_MODE_TABLE}
          loading={downloadingDeleting}
          currentPage={totalCurrentPage}
          totalCount={tokenTotalCount}
          defaultValue={itemInitialValues}
          tableColumns={[
            {
              title: LocalizedString.carOwnershipRewardPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            { title: LocalizedString.carOwnershipRewardPage.labelScheduledDate, field: 'scheduledDate', sorting: false },
            { title: LocalizedString.carOwnershipRewardPage.labelAmount, field: 'amount', sorting: false },
            { title: LocalizedString.carOwnershipRewardPage.labelActualDate, field: 'actualDate', sorting: false },
            { title: LocalizedString.carOwnershipRewardPage.labelRecipientId, field: 'recipient.id', sorting: false },
            { title: LocalizedString.carOwnershipRewardPage.labelRecipientName, field: 'recipient.fullName', sorting: false },
            { title: LocalizedString.carOwnershipRewardPage.labelNotes, field: 'notes', sorting: false },
          ]}
        />
      </Grid>
    )}
  </Grid>
);

const CarOwnershipRewardPage = ({
  initialValues, downloading, downloadingDeleting,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onChangePage, onChangePageSize, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  totalCurrentPage, totalCount, pageMode,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_CAR_OWNERSHIP_REWARDS}
      uiPage={RXSTATE_CAR_OWNERSHIP_REWARD_PAGE}
      filterColumns={[
        {
          title: LocalizedString.carOwnershipRewardPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.carOwnershipRewardPage.labelVin,
          field: 'vin',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.carOwnershipRewardPage.labelTier,
          field: 'tier',
          type: FILTER_TYPE_DROPDOWN,
          data: CAR_OWNERSHIP_REWARD_TIER,
        },
        {
          title: LocalizedString.carOwnershipRewardPage.labelRewardAllocationStatus,
          field: 'rewardAllocationStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: CAR_OWNERSHIP_REWARD_ALLOCATION_STATUS,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.carOwnershipRewardPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.carOwnershipRewardPage.labelVehicle, field: 'registration.vehicle.trim', sorting: !downloading },
        { title: LocalizedString.carOwnershipRewardPage.labelLicensePlate, field: 'registration.vehicle.licensePlate', sorting: !downloading },
        { title: LocalizedString.carOwnershipRewardPage.labelVin, field: 'vin', sorting: !downloading },
        { title: LocalizedString.carOwnershipRewardPage.labelTier, field: 'tier', sorting: !downloading },
        {
          title: LocalizedString.carOwnershipRewardPage.labelAmount,
          field: 'rewardAmount',
          sorting: !downloading,
          render: ({ rewardAmount }) => (rewardAmount
            ? toCurrency(rewardAmount) : toCurrency(0)),
        },
        {
          title: LocalizedString.carOwnershipRewardPage.labelRewardAllocationStatus,
          field: 'rewardAllocationStatus',
          sorting: false,
        },
        {
          title: LocalizedString.carOwnershipRewardPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      disableCreate
      disableDelete
      disableEdit
      title={LocalizedString.carOwnershipRewardPage.title}
      useFullWidth
      usefullWidthDialog
    >
      {renderDialogContent(initialValues.items, classes, downloadingDeleting,
        pageMode, totalCurrentPage, totalCount)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_CAR_OWNERSHIP_REWARD,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CarOwnershipRewardPage);

CarOwnershipRewardPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  totalCurrentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageMode: PropTypes.string.isRequired,
};
