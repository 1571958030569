import { downloadCouponAchievement } from '../../../helper';
import { downloadingCouponAchievement, setCouponAchievement } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCouponAchievement(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCouponAchievement;

    const result = await downloadCouponAchievement(tappedId, token);

    dispatch(setCouponAchievement(result));
  } finally {
    dispatch(downloadingCouponAchievement(false));
  }
};
