import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_GIIAS_MEDAN_SCANNED_CODES = 'DOWNLOADING_GIIAS_MEDAN_SCANNED_CODES';
export const downloadingGiiasMedanScannedCodes = makeActionCreator(DOWNLOADING_GIIAS_MEDAN_SCANNED_CODES, 'status');

export const DOWNLOADING_GIIAS_MEDAN_SCANNED_CODE = 'DOWNLOADING_GIIAS_MEDAN_SCANNED_CODE';
export const downloadingGiiasMedanScannedCode = makeActionCreator(DOWNLOADING_GIIAS_MEDAN_SCANNED_CODE, 'status');

export const SET_GIIAS_MEDAN_SCANNED_CODES = 'SET_GIIAS_MEDAN_SCANNED_CODES';
export const setGiiasMedanScannedCodes = makeActionCreator(SET_GIIAS_MEDAN_SCANNED_CODES, 'data');

export const SET_GIIAS_MEDAN_SCANNED_CODE = 'SET_GIIAS_MEDAN_SCANNED_CODE';
export const setGiiasMedanScannedCode = makeActionCreator(SET_GIIAS_MEDAN_SCANNED_CODE, 'data');

export const CLEAR_GIIAS_MEDAN_SCANNED_CODES = 'CLEAR_GIIAS_MEDAN_SCANNED_CODES';
export const clearGiiasMedanScannedCodes = makeActionCreator(CLEAR_GIIAS_MEDAN_SCANNED_CODES, 'data');

export const SET_GIIAS_MEDAN_SCANNED_CODES_PAGE_SIZE = 'SET_GIIAS_MEDAN_SCANNED_CODES_PAGE_SIZE';
export const setGiiasMedanScannedCodesPageSize = makeActionCreator(SET_GIIAS_MEDAN_SCANNED_CODES_PAGE_SIZE, 'size');

export const SET_GIIAS_MEDAN_SCANNED_CODES_ORDER_BY = 'SET_GIIAS_MEDAN_SCANNED_CODES_ORDER_BY';
export const setGiiasMedanScannedCodesOrderBy = makeActionCreator(SET_GIIAS_MEDAN_SCANNED_CODES_ORDER_BY, 'order');

export const SET_GIIAS_MEDAN_SCANNED_CODES_SEARCH_TEXT = 'SET_GIIAS_MEDAN_SCANNED_CODES_SEARCH_TEXT';
export const setGiiasMedanScannedCodesSearchText = makeActionCreator(SET_GIIAS_MEDAN_SCANNED_CODES_SEARCH_TEXT, 'text');

export const SET_GIIAS_MEDAN_SCANNED_CODES_TAPPED_ID = 'SET_GIIAS_MEDAN_SCANNED_CODES_TAPPED_ID';
export const setGiiasMedanScannedCodesTappedId = makeActionCreator(SET_GIIAS_MEDAN_SCANNED_CODES_TAPPED_ID, 'id');

export const SET_GIIAS_MEDAN_SCANNED_CODES_FILTER_STRING = 'SET_GIIAS_MEDAN_SCANNED_CODES_FILTER_STRING';
export const setGiiasMedanScannedCodesFilterString = makeActionCreator(SET_GIIAS_MEDAN_SCANNED_CODES_FILTER_STRING, 'text');

export const SAVING_GIIAS_MEDAN_2022_SCANNED_CODES = 'SAVING_GIIAS_MEDAN_2022_TSCANNED_CODE';
export const savingGiiasMedan2022ScannedCodes = makeActionCreator(SAVING_GIIAS_MEDAN_2022_SCANNED_CODES, 'status');


export const DOWNLOADING_GIIAS_MEDAN_PRIZE_REDEMPTIONS = 'DOWNLOADING_GIIAS_MEDAN_PRIZE_REDEMPTIONS';
export const downloadingGiiasMedanPrizeRedemptions = makeActionCreator(DOWNLOADING_GIIAS_MEDAN_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_GIIAS_MEDAN_PRIZE_REDEMPTION = 'DOWNLOADING_GIIAS_MEDAN_PRIZE_REDEMPTION';
export const downloadingGiiasMedanPrizeRedemption = makeActionCreator(DOWNLOADING_GIIAS_MEDAN_PRIZE_REDEMPTION, 'status');

export const ADD_GIIAS_MEDAN_PRIZE_REDEMPTION = 'ADD_GIIAS_MEDAN_PRIZE_REDEMPTION';
export const addGiiasMedanPrizeRedemption = makeActionCreator(ADD_GIIAS_MEDAN_PRIZE_REDEMPTION, 'data');

export const SET_GIIAS_MEDAN_PRIZE_REDEMPTIONS = 'SET_GIIAS_MEDAN_PRIZE_REDEMPTIONS';
export const setGiiasMedanPrizeRedemptions = makeActionCreator(SET_GIIAS_MEDAN_PRIZE_REDEMPTIONS, 'data');

export const CLEAR_GIIAS_MEDAN_PRIZE_REDEMPTIONS = 'CLEAR_GIIAS_MEDAN_PRIZE_REDEMPTIONS';
export const clearGiiasMedanPrizeRedemptions = makeActionCreator(
  CLEAR_GIIAS_MEDAN_PRIZE_REDEMPTIONS,
);

export const SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE';
export const setGiiasMedanPrizeRedemptionSelectedPageSize = makeActionCreator(SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SELECTED_ORDER_BY = 'SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SELECTED_ORDER_BY';
export const setGiiasMedanPrizeRedemptionSelectedOrderBy = makeActionCreator(SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_MEDAN_PRIZE_REDEMPTION_TAPPED_ID = 'SET_GIIAS_MEDAN_PRIZE_REDEMPTION_TAPPED_ID';
export const setGiiasMedanPrizeRedemptionTappedId = makeActionCreator(SET_GIIAS_MEDAN_PRIZE_REDEMPTION_TAPPED_ID, 'id');

export const SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SEARCH_TEXT = 'SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SEARCH_TEXT';
export const setGiiasMedanPrizeRedemptionSearchText = makeActionCreator(SET_GIIAS_MEDAN_PRIZE_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_MEDAN_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_MEDAN_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING';
export const setGiiasMedanPrizeRedemptionAdvancedFilterString = makeActionCreator(SET_GIIAS_MEDAN_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_GIIAS_MEDAN_2022_PRIZE_REDEMPTIONS = 'SAVING_GIIAS_MEDAN_2022_PRIZE_REDEMPTIONS';
export const savingGiiasMedan2022PrizeRedemptions = makeActionCreator(SAVING_GIIAS_MEDAN_2022_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_GIIAS_MEDAN_2022_TRANSACTIONS = 'DOWNLOADING_GIIAS_MEDAN_2022_TRANSACTIONS';
export const downloadingGiiasMedan2022Transactions = makeActionCreator(DOWNLOADING_GIIAS_MEDAN_2022_TRANSACTIONS, 'status');

export const DOWNLOADING_GIIAS_MEDAN_2022_TRANSACTION = 'DOWNLOADING_GIIAS_MEDAN_2022_TRANSACTION';
export const downloadingGiiasMedan2022Transaction = makeActionCreator(DOWNLOADING_GIIAS_MEDAN_2022_TRANSACTION, 'status');

export const SAVING_GIIAS_MEDAN_2022_TRANSACTIONS = 'SAVING_GIIAS_MEDAN_2022_TRANSACTIONS';
export const savingGiiasMedan2022Transactions = makeActionCreator(SAVING_GIIAS_MEDAN_2022_TRANSACTIONS, 'status');

export const ADD_GIIAS_MEDAN_2022_TRANSACTION = 'ADD_GIIAS_MEDAN_2022_TRANSACTION';
export const addGiiasMedan2022Transaction = makeActionCreator(ADD_GIIAS_MEDAN_2022_TRANSACTION, 'data');

export const SET_GIIAS_MEDAN_2022_TRANSACTIONS = 'SET_GIIAS_MEDAN_2022_TRANSACTIONS';
export const setGiiasMedan2022Transactions = makeActionCreator(SET_GIIAS_MEDAN_2022_TRANSACTIONS, 'data');

export const CLEAR_GIIAS_MEDAN_2022_TRANSACTIONS = 'CLEAR_GIIAS_MEDAN_2022_TRANSACTIONS';
export const clearGiiasMedan2022Transactions = makeActionCreator(
  CLEAR_GIIAS_MEDAN_2022_TRANSACTIONS,
);

export const SET_GIIAS_MEDAN_2022_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_MEDAN_2022_TRANSACTION_SELECTED_PAGE_SIZE';
export const setGiiasMedan2022TransactionSelectedPageSize = makeActionCreator(SET_GIIAS_MEDAN_2022_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_MEDAN_2022_TRANSACTION_SELECTED_ORDER_BY = 'SET_GIIAS_MEDAN_2022_TRANSACTION_SELECTED_ORDER_BY';
export const setGiiasMedan2022TransactionSelectedOrderBy = makeActionCreator(SET_GIIAS_MEDAN_2022_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_MEDAN_2022_TRANSACTION_TAPPED_ID = 'SET_GIIAS_MEDAN_2022_TRANSACTION_TAPPED_ID';
export const setGiiasMedan2022TransactionTappedId = makeActionCreator(SET_GIIAS_MEDAN_2022_TRANSACTION_TAPPED_ID, 'id');

export const SET_GIIAS_MEDAN_2022_TRANSACTION_SEARCH_TEXT = 'SET_GIIAS_MEDAN_2022_TRANSACTION_SEARCH_TEXT';
export const setGiiasMedan2022TransactionSearchText = makeActionCreator(SET_GIIAS_MEDAN_2022_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_MEDAN_2022_TRANSACTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_MEDAN_2022_TRANSACTION_ADVANCED_FILTER_STRING';
export const setGiiasMedan2022TransactionAdvancedFilterString = makeActionCreator(SET_GIIAS_MEDAN_2022_TRANSACTION_ADVANCED_FILTER_STRING, 'text');
