import { downloadGiiasMedanPrizeRedemption } from '../../../helper';
import { addGiiasMedanPrizeRedemption, downloadingGiiasMedanPrizeRedemption } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiiasMedanPrizeRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiiasMedanPrizeRedemption;

    const giiasMedanPrizeRedemption = await downloadGiiasMedanPrizeRedemption(tappedId, token);
    dispatch(addGiiasMedanPrizeRedemption(giiasMedanPrizeRedemption));
  } finally {
    dispatch(downloadingGiiasMedanPrizeRedemption(false));
  }
};
