import { downloadJaw2023Transaction } from '../../../helper';
import { addJaw2023Transaction, downloadingJaw2023Transaction } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingJaw2023Transaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiJaw2023Transaction;

    const Jaw2023Transaction = await downloadJaw2023Transaction(tappedId, token);
    dispatch(addJaw2023Transaction(Jaw2023Transaction));
  } finally {
    dispatch(downloadingJaw2023Transaction(false));
  }
};
