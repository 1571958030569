import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    eWaste: {
      title: 'E-Waste',
    },
    wasteCollection: {
      title: 'Waste Collection',

      labelNo: 'No',
      labelDealer: 'Dealer',
      labelUser: 'User',
      labelActivityStatus: 'Activity Status',
      labelCreatedDate: 'Created Date',
      labelProfile: 'Profile',
      labelUserID: 'User ID',
      labelUserName: 'User Name',
      labelFirstName: 'First Name',
      labelLastName: 'Last Name',
      labelGender: 'Gender',
      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelReceiveNewsUpdate: 'Receive News Update',
      labelDealerID: 'Dealer ID',
      labelDealerName: 'Dealer Name',
      labelUniqueCode: 'Unique Code',
      labelCompletedTime: 'Completed Time',
      labelImage: 'Image',

      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderFirstName: 'First Name',
      placeholderLastName: 'Last Name',
      placeholderGender: 'Gender',
      placeholderEmail: 'Email',
      placeholderPhone: 'Phone',
      placeholderReceiveNewsUpdate: 'Receive News Update',
      placeholderDealerID: 'Dealer ID',
      placeholderDealerName: 'Dealer Name',
      placeholderUniqueCode: 'Unique Code',
      placeholderCompletedTime: 'Completed Time',
      placeholderActivityStatus: 'Activity Status',
    },
    dealerCode: {
      title: 'Dealer Code',

      placeholderDealerId: 'Dealer ID',
      placeholderDealerName: 'Dealer Name',
      placeholderDealerCode: 'Code',

      labelDealerId: 'Dealer ID',
      labelDealerName: 'Dealer Name',
      labelDealerCode: 'Code',

      buttonCaptionCreateDealerCode: 'Create New Dealer Code',
      buttonCaptionEditDealerCode: 'Edit Dealer Code',
      buttonCaptionDeleteDealerCode: 'Delete Dealer Code',
    },
  },
  id: {
    eWaste: {
      title: 'E-Waste',
    },
    wasteCollection: {
      title: 'Pengumpulan Sampah',

      labelNo: 'No',
      labelDealer: 'Penjual',
      labelUser: 'Pengguna',
      labelActivityStatus: 'Status Aktivitas',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelProfile: 'Profil',
      labelUserID: 'ID Pengguna',
      labelUserName: 'Nama Pengguna',
      labelFirstName: 'Nama Depan',
      labelLastName: 'Nama Belakang',
      labelGender: 'Jenis Kelamin',
      labelEmail: 'Email',
      labelPhone: 'Nomor Telepon',
      labelReceiveNewsUpdate: 'Terima Pembaharuan Berita',
      labelDealerID: 'ID Penjual',
      labelDealerName: 'Nama Penjual',
      labelUniqueCode: 'Kode Unik',
      labelCompletedTime: 'Waktu yang Diselesaikan',
      labelImage: 'Gambar',

      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderFirstName: 'Nama Depan',
      placeholderLastName: 'Nama Belakang',
      placeholderGender: 'Jenis Kelamin',
      placeholderEmail: 'Email',
      placeholderPhone: 'Nomor Telepon',
      placeholderReceiveNewsUpdate: 'Terima Pembaharuan Berita',
      placeholderDealerID: 'ID Penjual',
      placeholderDealerName: 'Nama Penjual',
      placeholderUniqueCode: 'Kode Unik',
      placeholderCompletedTime: 'Waktu yang Diselesaikan',
      placeholderActivityStatus: 'Status Aktivitas',
    },
    dealerCode: {
      title: 'Kode Dealer',

      placeholderDealerId: 'ID Dealer',
      placeholderDealerName: 'Nama Dealer',
      placeholderDealerCode: 'Kode',

      labelDealerId: 'ID Dealer',
      labelDealerName: 'Nama Dealer',
      labelDealerCode: 'Kode',

      buttonCaptionCreateDealerCode: 'Buat Kode Dealer Baru',
      buttonCaptionEditDealerCode: 'Ubah Kode Dealer',
      buttonCaptionDeleteDealerCode: 'Hapus Kode Dealer',
    },
  },
});

export default LocalizedString;
