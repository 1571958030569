import { downloadBookingCreatedByVehicleModel } from '../../../helper';
import { downloadingBookingCreatedByVehicleModel, setBookingCreatedByVehicleModel } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingBookingCreatedByVehicleModel(true));
    const bookingCreatedByVehicleModel = await downloadBookingCreatedByVehicleModel(token,
      startDate, endDate);

    dispatch(setBookingCreatedByVehicleModel(bookingCreatedByVehicleModel));
  } finally {
    dispatch(downloadingBookingCreatedByVehicleModel(false));
  }
};
