import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  PAGE_MODE_TABLE, MENUID_COUPON_VOID_REQUEST, INITIAL_ORDER_BY_COUPON_VOID_REQUESTS,
  RXFIELD_COUPON_VOID_REQUEST_COUPON, RXFORM_COUPON_VOID_REQUEST,
} from '../../constant';
import { transformInitialValues, transformUserDropdownData } from '../../helper';
import {
  clearCoupons, clearUsers, clearVoidCouponRequests, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setCouponAdvancedFilterDialogSelectedFilterString, setCouponSearchText,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  setVoidCouponRequestSearchText, setVoidCouponRequestSelectedOrderBy,
  setVoidCouponRequestSelectedPageSize, setVoidCouponRequestSelectedCoupon,
  setVoidCouponRequestTappedId,
  addVoidCouponRequestAsync, downloadCouponsAsync, downloadUsersAsync,
  downloadVoidCouponRequestAsync, downloadVoidCouponRequestsAsync,
} from '../../redux/action';
import VoidRequestPage from './void-request.presentation';

const getInitialValues = (state) => {
  const {
    couponVoidRequests, uiFunctionalPage, uiCouponVoidRequest,
  } = state;
  const { data } = couponVoidRequests;
  const { downloadingDeleting, tappedId } = uiCouponVoidRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    reason: '',
    merchantName: '',
    coupon: { label: '', value: '' },
    requestStatus: '',
    createdDate: '',
  };
  return result;
};

const transformCouponDropdownData = (state) => Object.values(state).map((item) => ({
  label: item.label,
  value: item.id,
}));

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  coupons: transformCouponDropdownData(state.coupons.data),
  downloading: state.uiCouponVoidRequest.downloading,
  addingEditing: state.uiCouponVoidRequest.addingEditing,
  loadingUser: state.uiUser.downloading,
  loadingCoupon: state.uiCoupon.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_COUPON_VOID_REQUEST));
    dispatch(setVoidCouponRequestSearchText(''));
    dispatch(clearVoidCouponRequests());
    dispatch(setVoidCouponRequestSelectedPageSize(20));
    dispatch(setVoidCouponRequestSelectedOrderBy(INITIAL_ORDER_BY_COUPON_VOID_REQUESTS));
    dispatch(downloadVoidCouponRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(reset(RXFORM_COUPON_VOID_REQUEST));
    dispatch(setVoidCouponRequestTappedId(''));
    dispatch(setCouponSearchText(''));
    dispatch(clearCoupons());
    dispatch(setCouponAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(downloadCouponsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COUPON_VOID_REQUEST));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVoidCouponRequestsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoidCouponRequestSelectedPageSize(pageSize));
    dispatch(downloadVoidCouponRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoidCouponRequestSelectedOrderBy(orderBy));
    dispatch(clearVoidCouponRequests());
    dispatch(downloadVoidCouponRequestsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setVoidCouponRequestSelectedPageSize(pageSize));
    dispatch(clearVoidCouponRequests());
    dispatch(downloadVoidCouponRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setVoidCouponRequestSearchText(text));
      dispatch(clearVoidCouponRequests());
      await dispatch(downloadVoidCouponRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onViewPressed: (id) => {
    dispatch(setVoidCouponRequestTappedId(id));
    dispatch(downloadVoidCouponRequestAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCouponText: async (text) => {
    try {
      dispatch(setCouponSearchText(text));
      dispatch(clearCoupons());
      await dispatch(downloadCouponsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCouponAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setCouponSearchText(''));
    dispatch(clearUsers());
    dispatch(clearCoupons());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCouponsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearVoidCouponRequests());
    dispatch(setVoidCouponRequestSelectedOrderBy(INITIAL_ORDER_BY_COUPON_VOID_REQUESTS));
    dispatch(downloadVoidCouponRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/coupon/, 'coupon.id').replace(/user/, 'coupon.user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearVoidCouponRequests());
    dispatch(downloadVoidCouponRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCouponOptionSelected: (option) => {
    if (option) {
      dispatch(setVoidCouponRequestSelectedCoupon(option));
      dispatch(change(RXFORM_COUPON_VOID_REQUEST, RXFIELD_COUPON_VOID_REQUEST_COUPON, option));
    }
  },
  onSubmitPressed: async ({ reason, merchantReferenceId, callbackUrl }) => {
    await dispatch(addVoidCouponRequestAsync(reason, merchantReferenceId, callbackUrl));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoidRequestPage);
