import { downloadPartnerMerchant, deletePartnerMerchant } from '../../../helper';
import {
  addPartnerMerchant, downloadingDeletingPartnerMerchant,
  setPartnerMerchantSelectedCompany, setPartnerMerchantTappedId,
  setFunctionalPageMode, clearPartnerMerchants,
  setAlertErrorMessage,
} from '../simple-action';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadPartnerMerchantsAsync from './downloadPartnerMerchantsAsync';
import { getDeepLinkScheme } from '../../../../configuration/helper';

const DEEP_LINK_PATH = 'app/partner/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPartnerMerchant(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPartnerMerchant;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deletePartnerMerchant(tappedId, token);
      dispatch(setPartnerMerchantTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearPartnerMerchants());
      dispatch(downloadPartnerMerchantsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const partnerMerchant = await downloadPartnerMerchant(tappedId, token);

      const result = await getDeepLinkScheme(partnerMerchant, DEEP_LINK_PATH, tappedId, token);

      dispatch(addPartnerMerchant(result));
      dispatch(setPartnerMerchantSelectedCompany(
        { label: result.company?.name || '', value: result.company?.id || '' },
      ));
    }
  } finally {
    dispatch(downloadingDeletingPartnerMerchant(false));
  }
};
