import { reset } from 'redux-form';
import {
  addingEditingProfile, clearProfiles, setAlertErrorMessage,
  setProfileSelectedGender, setFunctionalPageMode,
  setProfileSelectedRole, setProfileSelectedCompany,
} from '../simple-action';
import {
  editProfile, addUser, delay, downloadUser, editUser, toMoment,
} from '../../../helper';
import {
  PAGE_MODE_TABLE, RXFORM_PROFILE, REVERSED_ISO_DATE_FORMAT, TAG_WHITE,
} from '../../../constant';
import downloadProfilesAsync from './downloadProfilesAsync';

export default (fullName, email, phone, dateOfBirth, password) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingProfile(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedCompany, selectedRole, selectedGender,
    } = getState().uiProfile;
    const { data } = getState().profiles;

    const found = data[tappedId];
    const birthDate = dateOfBirth ? toMoment(dateOfBirth).format(REVERSED_ISO_DATE_FORMAT) : null;

    if (tappedId) {
      const user = await downloadUser(tappedId, token);
      const userRoles = user.roles.map((x) => x.name);
      await editUser(tappedId, user.username, fullName, email, phone, user.info1, user.info2,
        user.info3, user.status, user.origin, userRoles, token);
      await delay(1000);
      await editProfile(tappedId, birthDate, selectedGender, selectedCompany, found.tags, token);
    } else {
      const response = await addUser(fullName, email, phone, birthDate, selectedGender.value,
        password, selectedRole.label, selectedCompany, token);
      await delay(1000);
      await editProfile(response.id, birthDate, selectedGender, selectedCompany,
         response?.tags || TAG_WHITE, token);
    }

    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));

    dispatch(reset(RXFORM_PROFILE));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedGender(''));
    dispatch(setProfileSelectedRole(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setProfileSelectedGender(''));
  } finally {
    dispatch(addingEditingProfile(false));
  }
};
