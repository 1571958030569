import moment from 'moment';
import {
  TIME_FORMAT,
  RXFIELD_USERNAME, RXFIELD_PASSWORD,
  RXFIELD_PROVINCE_NAME, RXFIELD_PROVINCE_TIME_ZONE,
  RXFIELD_CITY_NAME,
  RXFIELD_BRANCH_ADDRESS, RXFIELD_BRANCH_AREA, RXFIELD_BRANCH_CITY,
  RXFIELD_BRANCH_GOOGLE_MAP_URL, RXFIELD_BRANCH_LATITUDE,
  RXFIELD_BRANCH_LONGITUDE, RXFIELD_BRANCH_NAME,
  RXFIELD_BRANCH_COMPANY,
  RXFIELD_PERMISSION_NAME, RXFIELD_USER_USERNAME,
  RXFIELD_USER_FULLNAME, RXFIELD_USER_ROLES,
  RXFIELD_ROLE_DESCRIPTION, RXFIELD_ROLE_NAME, RXFIELD_ROLE_PERMISSION,
  RXFIELD_API_KEY_SCOPE, RXFIELD_API_KEY_CONSUMER, RXFIELD_API_KEY_OWNER,
  RXFIELD_APPLICATION_ICON, RXFIELD_APPLICATION_NAME,
  RXFIELD_APPLICATION_ORDER, RXFIELD_APPLICATION_PERMISSION, RXFIELD_APPLICATION_URL,
  RXFIELD_PROFILE_FULLNAME, RXFIELD_PROFILE_EMAIL, RXFIELD_PROFILE_PHONE,
  RXFIELD_PROFILE_PASSWORD, RXFIELD_PROFILE_RE_PASSWORD, RXFIELD_PROFILE_ROLES,
  RXFIELD_PROFILE_GENDER,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE, RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY,
  RXFIELD_SERVICE_NAME, RXFIELD_SERVICE_DESCRIPTION,
  RXFIELD_SCOPE_NAME, RXFIELD_SCOPE_DESCRIPTION,
  RXFIELD_BANNER_CLICK_ACTION, RXFIELD_BANNER_MEDIA, RXFIELD_BANNER_ORDER, RXFIELD_BANNER_TITLE,
  RXFIELD_BANNER_VALID_FROM, RXFIELD_BANNER_VALID_UNTIL,
  RXFIELD_COMPANY_CITY, RXFIELD_COMPANY_NAME,
  RXFIELD_DISTRICT_NAME, RXFIELD_SUBDISTRICT_NAME,
  RXFIELD_DISTRICT_CITY, RXFIELD_SUBDISTRICT_DISTRICT,
  RXFIELD_DEVICE_EXCEPTION_DEVICE_ID, RXFIELD_DEVICE_EXCEPTION_MAX_REGISTRATION,
  PAGE_MODE_TABLE,
  RXFIELD_SETTINGS_NEW_PASSWORD,
  RXFIELD_SETTINGS_OLD_PASSWORD,
  RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE,
  RXFIELD_USER_NEW_PASSWORD,
  RXFIELD_USER_NEW_PASSWORD_RETYPE,
} from './constant';
import LocalizedString from './localization';

export const rxFormValidateRequiredFields = (values, requiredFields) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if ((values[field] instanceof Array && values[field].length === 0)
    || (!values[field] && typeof values[field] !== 'number')
    || (((values[field] instanceof Array) === false) && typeof values[field] === 'object' && typeof values[field].value !== 'boolean' && !values[field].value)
    || (typeof values[field] === 'string' && values[field].match('<p>(.*?)</p>')
    && !values[field].match('<p>(.*?)</p>')[1])) {
      errors[field] = LocalizedString.common.errMsgRequired;
    }
  });
  return errors;
};

export const rxformValidateLogin = (values) => {
  const requiredFields = [
    RXFIELD_USERNAME,
    RXFIELD_PASSWORD,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateProvince = (values) => {
  const requiredFields = [
    RXFIELD_PROVINCE_NAME,
    RXFIELD_PROVINCE_TIME_ZONE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCity = (values) => {
  const requiredFields = [
    RXFIELD_CITY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const validEmail = (email) => {
  // eslint-disable-next-line
    const regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return email ? email.match(regexMail) : false;
};

export const validPhoneNumber = (phoneNumber) => {
  const regexPhone = /^((\+[1-9]{1,2})|[0])[1-9][0-9]{3,15}$/;
  return phoneNumber ? phoneNumber.match(regexPhone) : false;
};

const rxformValidateWorkingHourFields = (values, requiredErrors) => {
  const errors = requiredErrors;

  const startHourOfDay = moment().startOf('day').format(TIME_FORMAT); // 00.00
  const endHourOfDay = moment().endOf('day').format(TIME_FORMAT); // 23.59
  const NIGHT_START_TIME = '20:00';
  const NIGHT_END_TIME = '03:59';

  if (moment(values.endWorkingHour, TIME_FORMAT)
    .isSame(moment(values.startWorkingHour, TIME_FORMAT))) {
    errors.endWorkingHour = LocalizedString.common.errMsgStartEndWorkingHour;
  }
  if (!(moment(values.startWorkingHour, TIME_FORMAT)
    .isBetween(moment(startHourOfDay, TIME_FORMAT), moment(NIGHT_END_TIME, TIME_FORMAT)))
  && moment(values.earliestClockInDriftTimeMinutes, TIME_FORMAT)
    .isAfter(moment(values.startWorkingHour, TIME_FORMAT))) {
    errors.earliestClockInDriftTimeMinutes = LocalizedString.common.errMsgEarliestClockInTime;
  }
  if (!(moment(values.endWorkingHour, TIME_FORMAT)
    .isBetween(moment(NIGHT_START_TIME, TIME_FORMAT), moment(endHourOfDay, TIME_FORMAT)))
    && moment(values.autoClockOutAfterMinutes, TIME_FORMAT)
      .isBefore(moment(values.endWorkingHour, TIME_FORMAT))) {
    errors.autoClockOutAfterMinutes = LocalizedString.common.errMsgAutoClockOut;
  }
  return errors;
};

export const rxformValidateBranch = (values) => {
  const requiredFields = [
    RXFIELD_BRANCH_NAME,
    RXFIELD_BRANCH_ADDRESS,
    RXFIELD_BRANCH_AREA,
    RXFIELD_BRANCH_CITY,
    RXFIELD_BRANCH_COMPANY,
    RXFIELD_BRANCH_GOOGLE_MAP_URL,
    RXFIELD_BRANCH_LATITUDE,
    RXFIELD_BRANCH_LONGITUDE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidateCompanyMdata = (values) => {
  const requiredFields = [
    RXFIELD_COMPANY_CITY,
    RXFIELD_COMPANY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidatePermission = (values) => {
  const requiredFields = [
    RXFIELD_PERMISSION_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateUser = (values) => {
  const requiredFields = [
    RXFIELD_USER_USERNAME,
    RXFIELD_USER_FULLNAME,
    RXFIELD_USER_ROLES,
    RXFIELD_USER_NEW_PASSWORD,
    RXFIELD_USER_NEW_PASSWORD_RETYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.newPasswordRetype && values.newPassword !== values.newPasswordRetype) {
    errors.newPasswordRetype = LocalizedString.common.errMsgDifferentRetypePassword;
  }
  return errors;
};

export const rxformValidateDeviceException = (values) => {
  const requiredFields = [
    RXFIELD_DEVICE_EXCEPTION_DEVICE_ID,
    RXFIELD_DEVICE_EXCEPTION_MAX_REGISTRATION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateRole = (values) => {
  const requiredFields = [
    RXFIELD_ROLE_NAME,
    RXFIELD_ROLE_PERMISSION,
    RXFIELD_ROLE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateApiKey = (values) => {
  const requiredFields = [
    RXFIELD_API_KEY_SCOPE,
    RXFIELD_API_KEY_CONSUMER,
    RXFIELD_API_KEY_OWNER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateApplication = (values) => {
  const requiredFields = [
    RXFIELD_APPLICATION_ICON,
    RXFIELD_APPLICATION_NAME,
    RXFIELD_APPLICATION_ORDER,
    RXFIELD_APPLICATION_PERMISSION,
    RXFIELD_APPLICATION_URL,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateProfile = (values) => {
  const { pageMode } = values;

  const createRequiredFields = [
    RXFIELD_PROFILE_FULLNAME,
    RXFIELD_PROFILE_EMAIL,
    RXFIELD_PROFILE_PHONE,
    RXFIELD_PROFILE_PASSWORD,
    RXFIELD_PROFILE_RE_PASSWORD,
    RXFIELD_PROFILE_GENDER,
    RXFIELD_PROFILE_ROLES,
  ];

  const editRequiredFields = [
    RXFIELD_PROFILE_FULLNAME,
    RXFIELD_PROFILE_EMAIL,
    RXFIELD_PROFILE_PHONE,
    RXFIELD_PROFILE_GENDER,
  ];

  const errors = rxFormValidateRequiredFields(
    values, pageMode === PAGE_MODE_TABLE ? createRequiredFields : editRequiredFields,
  );

  if (!validPhoneNumber(values.phone)) {
    errors.phone = LocalizedString.common.errMsgInvalidPhoneNumberFormat;
  }

  if (values.password !== values.rePassword) {
    errors.rePassword = LocalizedString.common.errMsgPasswordDoesNotMatch;
  }
  if (!validEmail(values.email)) {
    errors.email = LocalizedString.common.errMsgInvalidEmailFormat;
  }

  return errors;
};

export const rxformValidateSendPersonalNotification = (values) => {
  const requiredFields = [
    RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE,
    RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateService = (values) => {
  const requiredFields = [
    RXFIELD_SERVICE_NAME,
    RXFIELD_SERVICE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateScope = (values) => {
  const requiredFields = [
    RXFIELD_SCOPE_NAME,
    RXFIELD_SCOPE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateBanner = (values) => {
  const requiredFields = [
    RXFIELD_BANNER_TITLE,
    RXFIELD_BANNER_CLICK_ACTION,
    RXFIELD_BANNER_VALID_FROM,
    RXFIELD_BANNER_VALID_UNTIL,
    RXFIELD_BANNER_ORDER,
    RXFIELD_BANNER_MEDIA,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateDistrict = (values) => {
  const requiredFields = [
    RXFIELD_DISTRICT_NAME,
    RXFIELD_DISTRICT_CITY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSubdistrict = (values) => {
  const requiredFields = [
    RXFIELD_SUBDISTRICT_NAME,
    RXFIELD_SUBDISTRICT_DISTRICT,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSettings = (values) => {
  const requiredFields = [
    RXFIELD_SETTINGS_OLD_PASSWORD,
    RXFIELD_SETTINGS_NEW_PASSWORD,
    RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.newPasswordRetype && values.newPassword !== values.newPasswordRetype) {
    errors.newPasswordRetype = LocalizedString.common.errMsgDifferentRetypePassword;
  }
  return errors;
};
