import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  PAGE_MODE_TABLE,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  MENUID_AUTHENTICATION_REGISTRATION_ATTEMPT,
  INITIAL_ORDER_BY_AUTH_REGISTRATION_ATTEMPTS,
  RXFORM_AUTH_REGISTRATION_ATTEMPT,
} from '../../constant';
import {
  transformInitialValues,
  toMoment,
  debounceSearch,
} from '../../helper';
import {
  clearAuthRegistrationAttempts,
  downloadAuthRegistrationAttemptAsync,
  downloadAuthRegistrationAttemptsAsync,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setAuthRegistrationAttemptAdvancedFilterDialogSelectedFilterString,
  setAuthRegistrationAttemptSearchText,
  setAuthRegistrationAttemptSelectedOrderBy,
  setAuthRegistrationAttemptSelectedPageSize,
  setAuthRegistrationAttemptTappedId,
} from '../../redux/action';
import GlobalLocalizedString from '../../localization';
import AuthRegistrationAttemptPage from './registration-attempt.presentation';

const getInitialValues = (state) => {
  const {
    authRegistrationAttempts,
    uiFunctionalPage,
    uiAuthRegistrationAttempt,
  } = state;
  const { data } = authRegistrationAttempts;
  const { downloadingDeleting, tappedId } = uiAuthRegistrationAttempt;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      device: `${found?.manufacturer} ${found?.model}`,
      os: `${found?.osName} ${found?.osVersion}`,
      validUntil: toMoment(found?.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      verified:
            found.verified === true
              ? GlobalLocalizedString.common.labelYes
              : GlobalLocalizedString.common.labelNo,
      verifiedOn: toMoment(found?.verifiedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
    })
    : {};
  return result;
};

const searchRegistrationAttemptDebounce = debounceSearch((dispatch) => {
  dispatch(clearAuthRegistrationAttempts());
  dispatch(downloadAuthRegistrationAttemptsAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});

const mapStateToProps = (state) => ({
  downloading: state.uiAuthRegistrationAttempt.downloading,
  downloadingDeleting: state.uiAuthRegistrationAttempt.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setAuthRegistrationAttemptAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_REGISTRATION_ATTEMPT));
    dispatch(setAuthRegistrationAttemptSearchText(''));
    dispatch(clearAuthRegistrationAttempts());
    dispatch(setAuthRegistrationAttemptSelectedPageSize(20));
    dispatch(
      setAuthRegistrationAttemptSelectedOrderBy(
        INITIAL_ORDER_BY_AUTH_REGISTRATION_ATTEMPTS,
      ),
    );
    dispatch(downloadAuthRegistrationAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('createdDate', 'createdDate')
      .replace('validUntil', 'validUntil')
      .replace('username', 'username')
      .replace('email', 'email')
      .replace('phone', 'phone')
      .replace('registerUsing', 'registerUsing')
      .replace('verified', 'verified')
      .replace('referralCode', 'referralCode')
      .replace('deviceId', 'deviceId');

    dispatch(
      setAuthRegistrationAttemptAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearAuthRegistrationAttempts());
    dispatch(downloadAuthRegistrationAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAuthRegistrationAttemptsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAuthRegistrationAttemptSelectedPageSize(pageSize));
    dispatch(downloadAuthRegistrationAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setAuthRegistrationAttemptSelectedPageSize(pageSize));
    dispatch(clearAuthRegistrationAttempts());
    dispatch(downloadAuthRegistrationAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearAuthRegistrationAttempts());
    dispatch(
      setAuthRegistrationAttemptAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(
      setAuthRegistrationAttemptSelectedOrderBy(
        INITIAL_ORDER_BY_AUTH_REGISTRATION_ATTEMPTS,
      ),
    );
    dispatch(downloadAuthRegistrationAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAuthRegistrationAttemptSearchText(text));
      if (text.length >= 3 || text.length === 0) {
        searchRegistrationAttemptDebounce(dispatch);
      }
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAuthRegistrationAttemptSelectedOrderBy(orderBy));
    dispatch(clearAuthRegistrationAttempts());
    dispatch(downloadAuthRegistrationAttemptsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setAuthRegistrationAttemptTappedId(id));
    dispatch(downloadAuthRegistrationAttemptAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_AUTH_REGISTRATION_ATTEMPT));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthRegistrationAttemptPage);
