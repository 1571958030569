
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    promoCodeMenu: {
      title: 'Promo Code',
    },
    promoCodePage: {
      title: 'Promo Code',

      labelNo: 'No',

      placeholderPromoCode: 'Promo Code',
      placeholderReward: 'Reward',
      placeholderBypassMaxPurchase: 'Bypass Max Purchase',
      placeholderBypassAchievementValidation: 'Bypass Achievement Validation',
      placeholderTotalVoucherAllocated: 'Maximal Redemption Count',
      placeholderMaxVoucherAllocated: 'Max Voucher Allocated',
      placeholderCoinsAmount: 'Points Amount',
      placeholderCouponsAmount: 'Coupons Amount',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderMerchant: 'Merchant',
      placeholderVoucher: 'Voucher',
      placeholderMerchantTag: 'Merchant Tag',
      placeholderMessageTitle: 'Message Title',
      placeholderMessageBody: 'Message Body',
      placeholderPointAmount: 'Point Amount',
      placeholderCreatedDate: 'Created Date',
      placeholderVoucherQty: 'Voucher Qty',
      placeholderUserType: 'User Type',
      placeholderMinSignupDate: 'Minimum Sign Up Date',
      placeholderMaxSignupDate: 'Maximum Sign Up Date',
      placeholderAirport: 'Airport',

      buttonCaptionCreatePromoCode: 'Create Promo Code',
      buttonCaptionEditPromoCode: 'Edit Promo Code',
      buttonCaptionDeletePromoCode: 'Delete Promo Code',

      helperMessageField: '{fullName}: Recipients full name<br/>{voucherTitle}: Voucher title<br/>{merchantName}: Merchant name of this promo code<br/>{pointAmount}: Point amount given to user<br/>{couponAmount}: Coupon amount given to user',

      errMsgPleaseFillAiport: 'Please fill Airport field',
    },
    redemptionHistoryPage: {
      title: 'Redemption History',

      labelNo: 'No',
      labelCreatedDate: 'Created Date',

      placeholderPromoCode: 'Promo Code',
      placeholderReward: 'Reward',
      placeholderBypassMaxPurchase: 'Bypass Max Purchase',
      placeholderBypassAchievementValidation: 'Bypass Achievement Validation',
      placeholderTotalVoucherAllocated: 'Maximal Redemption Count',
      placeholderMaxVoucherAllocated: 'Max Voucher Allocated',
      placeholderCoinsAmount: 'Points Amount',
      placeholderCouponsAmount: 'Coupons Amount',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderMerchant: 'Merchant',
      placeholderVoucher: 'Voucher',
      placeholderMerchantTag: 'Merchant Tag',
      placeholderMessageTitle: 'Message Title',
      placeholderMessageBody: 'Message Body',
      placeholderUser: 'User Name',
      placeholderRedemptionStatus: 'Redemption Status',
      placeholderRedemptionStatusNote: 'Redemption Status Note',
      placeholderUserId: 'User ID',
      placeholderPointAmount: 'Point Amount',

      buttonCaptionCreatePromoCode: 'Create Promo Code',
      buttonCaptionEditPromoCode: 'Edit Promo Code',
      buttonCaptionDeletePromoCode: 'Delete Promo Code',

      helperMessageField: '{fullName}: Recipients full name<br/>{voucherTitle}: Voucher title<br/>{merchantName}: Merchant name of this promo code<br/>{pointAmount}: Point amount given to user<br/>{couponAmount}: Coupon amount given to user',
    },
    promoCodeBatchPage: {
      title: 'Promo Code Batch',

      labelNo: 'No',
      labelPrefix: 'Prefix',
      labelQuantity: 'Quantity',
      labelCodeLength: 'Code Length',
      labelRewardType: 'Reward Type',
      labelStartTime: 'Start Time',
      labelProgress: 'Progress',
      labelNotes: 'Notes',
      labelTaskStatus: 'Task Status',
      labelEndTime: 'End Time',
      labelMerchant: 'Merchant',

      placeholderReward: 'Reward',
      placeholderBypassMaxPurchase: 'Bypass Max Purchase',
      placeholderBypassAchievementValidation: 'Bypass Achievement Validation',
      placeholderMaxRedemptionCount: 'Maximal Redemption Count',
      placeholderMaxVoucherAllocated: 'Maximal Voucher Allocated',
      placeholderCoinsAmount: 'Points Amount',
      placeholderCouponsAmount: 'Coupons Amount',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderMerchant: 'Merchant',
      placeholderVoucher: 'Voucher',
      placeholderMerchantTag: 'Merchant Tag',
      placeholderMessageTitle: 'Message Title',
      placeholderMessageBody: 'Message Body',
      placeholderPointAmount: 'Point Amount',
      placeholderPrefix: 'Prefix',
      placeholderQuantity: 'Quantity',
      placeholdeRandomCodeLength: 'Random Code Length',
      placeholderProgress: 'Progress',
      placeholderProgressNotes: 'Progress Notes',
      placeholderTaskStatus: 'Task Status',
      placeholderTaskStatusNotes: 'Task Status Notes',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderUserType: 'User Type',
      placeholderMinSignupDate: 'Minimum Sign Up Date',
      placeholderMaxSignupDate: 'Maximum Sign Up Date',
      placeholderAirport: 'Airport',

      buttonCaptionCreateNewPromoCodeBatch: 'Create New Promo Code Batch',
      buttonCaptionCancelTask: 'Cancel Task',

      msgCancelConfirmation: 'Are you sure want to cancel this item?',
      msgNotificationBodyFormat: '{fullName}: Recipients full name<br/>{voucherTitle}: Voucher title<br/>{merchantName}: Merchant name of this promo code<br/>{pointAmount}: Point amount given to user<br/>{couponAmount}: Coupon amount given to user',

      errMsgPleaseFillAiport: 'Please fill Airport field',
    },
  },
  id: {
    promoCodeMenu: {
      title: 'Kode Promo',
    },
    promoCodePage: {
      title: 'Kode Promo',

      labelNo: 'No',

      placeholderPromoCode: 'Kode Promo',
      placeholderReward: 'Hadiah',
      placeholderBypassMaxPurchase: 'Bypass Maksimal Pembelian',
      placeholderBypassAchievementValidation: 'Bypass Validasi Pencapaian',
      placeholderTotalVoucherAllocated: 'Jumlah Penukaran Maksimal',
      placeholderMaxVoucherAllocated: 'Alokasi Voucher Maksimal',
      placeholderCoinsAmount: 'Jumlah Poin',
      placeholderCouponsAmount: 'Jumlah Kupon',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderMerchant: 'Penjual',
      placeholderVoucher: 'Voucher',
      placeholderMerchantTag: 'Penanda Penjual',
      placeholderMessageTitle: 'Judul Pesan',
      placeholderMessageBody: 'Badan Pesan',
      placeholderPointAmount: 'Jumlah Poin',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderVoucherQty: 'Jumlah Voucher',
      placeholderUserType: 'Tipe Pengguna',
      placeholderMinSignupDate: 'Tanggal Minimum Pendaftaran',
      placeholderMaxSignupDate: 'Tanggal Maksimum Pendaftaran',
      placeholderAirport: 'Bandara',

      buttonCaptionCreatePromoCode: 'Buat Kode Promo',
      buttonCaptionEditPromoCode: 'Ubah Kode Promo',
      buttonCaptionDeletePromoCode: 'Hapus Kode Promo',

      helperMessageField: '{fullName}: Nama lengkap penerima<br/>{voucherTitle}: Judul Voucher<br/>{merchantName}: Nama penjual dari kode promo ini<br/>{pointAmount}: Jumlah poin yang diberikan kepada user<br/>{couponAmount}: Jumlah kupon yang diberikan kepada user',

      errMsgPleaseFillAiport: 'Mohon isi kolom Bandara',
    },
    redemptionHistoryPage: {
      title: 'Riwayat Penukaran',

      labelNo: 'No',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderPromoCode: 'Kode Promo',
      placeholderReward: 'Hadiah',
      placeholderBypassMaxPurchase: 'Bypass Maksimal Pembelian',
      placeholderBypassAchievementValidation: 'Bypass Validasi Pencapaian',
      placeholderTotalVoucherAllocated: 'Jumlah Penukaran Maksimal',
      placeholderMaxVoucherAllocated: 'Alokasi Voucher Maksimal',
      placeholderCoinsAmount: 'Jumlah Poin',
      placeholderCouponsAmount: 'Jumlah Kupon',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderMerchant: 'Penjual',
      placeholderVoucher: 'Voucher',
      placeholderMerchantTag: 'Penanda Penjual',
      placeholderMessageTitle: 'Judul Pesan',
      placeholderMessageBody: 'Badan Pesan',
      placeholderUser: 'Nama Pengguna',
      placeholderRedemptionStatus: 'Status Penukaran',
      placeholderRedemptionStatusNote: 'Catatan Status Penukaran',
      placeholderUserId: 'ID Pengguna',
      placeholderPointAmount: 'Jumlah Poin',

      buttonCaptionCreatePromoCode: 'Buat Kode Promo',
      buttonCaptionEditPromoCode: 'Ubah Kode Promo',
      buttonCaptionDeletePromoCode: 'Hapus Kode Promo',

      helperMessageField: '{fullName}: Nama lengkap penerima<br/>{voucherTitle}: Judul Voucher<br/>{merchantName}: Nama penjual dari kode promo ini<br/>{pointAmount}: Jumlah poin yang diberikan kepada user<br/>{couponAmount}: Jumlah kupon yang diberikan kepada user',
    },
    promoCodeBatchPage: {
      title: 'Kumpulan Kode Promo',

      labelNo: 'No',
      labelPrefix: 'Awalan',
      labelQuantity: 'Kuantitas',
      labelCodeLength: 'Panjang Kode',
      labelRewardType: 'Tipe Hadiah',
      labelStartTime: 'Waktu Dimulai',
      labelProgress: 'Kemajuan',
      labelNotes: 'Catatan',
      labelTaskStatus: 'Status Tugas',
      labelEndTime: 'Waktu Berakhir',
      labelMerchant: 'Penjual',

      placeholderReward: 'Hadiah',
      placeholderBypassMaxPurchase: 'Bypass Maksimal Pembelian',
      placeholderBypassAchievementValidation: 'Bypass Validasi Pencapaian',
      placeholderMaxRedemptionCount: 'Jumlah Penukaran Maksimal',
      placeholderMaxVoucherAllocated: 'Alokasi Voucher Maksimal',
      placeholderCoinsAmount: 'Jumlah Poin',
      placeholderCouponsAmount: 'Jumlah Kupon',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai Dengan',
      placeholderMerchant: 'Penjual',
      placeholderVoucher: 'Voucher',
      placeholderMerchantTag: 'Penanda Penjual',
      placeholderMessageTitle: 'Judul Pesan',
      placeholderMessageBody: 'Badan Pesan',
      placeholderPointAmount: 'Jumlah Poin',
      placeholderPrefix: 'Awalan',
      placeholderQuantity: 'Kuantitas',
      placeholdeRandomCodeLength: 'Panjang Kode Acak',
      placeholderProgress: 'Kemajuan',
      placeholderProgressNotes: 'Catatan Kemajuan',
      placeholderTaskStatus: 'Status Tugas',
      placeholderTaskStatusNotes: 'Catatan Status Tugas',
      placeholderStartTime: 'Waktu Dimulai',
      placeholderEndTime: 'Waktu Berakhir',
      placeholderUserType: 'Tipe Pengguna',
      placeholderMinSignupDate: 'Tanggal Minimum Pendaftaran',
      placeholderMaxSignupDate: 'Tanggal Maksimum Pendaftaran',
      placeholderAirport: 'Bandara',

      buttonCaptionCreateNewPromoCodeBatch: 'Buat Kumpulan Kode Promo Baru',
      buttonCaptionCancelTask: 'Batalkan Tugas',

      msgCancelConfirmation: 'Apa Anda yakin ingin membatalkan pilihan ini?',
      msgNotificationBodyFormat: '{fullName}: Nama lengkap penerima<br/>{voucherTitle}: Judul Voucher<br/>{merchantName}: Nama penjual dari kode promo ini<br/>{pointAmount}: Jumlah poin yang diberikan kepada user<br/>{couponAmount}: Jumlah kupon yang diberikan kepada user',

      errMsgPleaseFillAiport: 'Mohon isi kolom Bandara',
    },
  },
});

export default LocalizedString;
