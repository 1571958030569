import { connect } from 'react-redux';
import moment from 'moment';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_CAR_OWNERSHIPS,
  MENUID_CAR_REGISTRATION_CAR_OWNERSHIP, PAGE_MODE_TABLE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, SIMPLE_DATE_FORMAT,
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE,
} from '../../constant';
import {
  transformUserDropdownData, transformInitialValues, toMoment,
} from '../../helper';
import {
  clearCarOwnerships, clearUsers, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage, setCarOwnershipSearchText,
  setCarOwnershipSelectedPageSize, setCarOwnershipSelectedOrderBy, setCarOwnershipTappedId,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadCarOwnershipAsync, downloadCarOwnershipsAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import CarOwnershipPage from './car-ownership.presentation';

const getInitialValues = (state) => {
  const { carOwnerships, uiCarOwnership, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiCarOwnership;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? carOwnerships.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      wwarantyStartDate: found.warantyStartDate
        ? toMoment(found.warantyStartDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      warantyEndDate: found.warantyEndDate
        ? toMoment(found.warantyEndDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      registration: found.registration ? {
        ...found.registration,
        approvedOn: toMoment(found.registration.approvedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      } : null,
      inactivatedOn: found.inactivatedOn
        ? toMoment(found.inactivatedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
      stnkValidUntil: found.stnkValidUntil ? moment(found.stnkValidUntil).format(SIMPLE_DATE_FORMAT)
        : null,
      firstPkbReminderSentOn: found.firstPkbReminderSentOn
        ? toMoment(found.firstPkbReminderSentOn).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE)
        : null,
      secondPkbReminderSentOn: found.secondPkbReminderSentOn
        ? toMoment(found.secondPkbReminderSentOn).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE)
        : null,
      stnkImage: found.stnkImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.stnkImage}` : '',
      colorCode: found.exteriorColorCode,
    }) : {};
  return initVal;
};

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  downloading: state.uiCarOwnership.downloading,
  loadingUsers: state.uiUser.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_REGISTRATION_CAR_OWNERSHIP));
    dispatch(setCarOwnershipSearchText(''));
    dispatch(clearCarOwnerships());
    dispatch(setCarOwnershipSelectedPageSize(20));
    dispatch(setCarOwnershipSelectedOrderBy(INITIAL_ORDER_BY_CAR_OWNERSHIPS));
    dispatch(downloadCarOwnershipsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replaceAll('approvedOn', 'registration.approvedOn').replace(/user/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCarOwnerships());
    dispatch(downloadCarOwnershipsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCarOwnershipsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCarOwnershipSelectedPageSize(pageSize));
    dispatch(downloadCarOwnershipsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setCarOwnershipSelectedPageSize(pageSize));
    dispatch(clearCarOwnerships());
    dispatch(downloadCarOwnershipsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCarOwnerships());
    dispatch(setCarOwnershipSelectedOrderBy(INITIAL_ORDER_BY_CAR_OWNERSHIPS));
    dispatch(downloadCarOwnershipsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCarOwnershipSearchText(text));
      dispatch(clearCarOwnerships());
      await dispatch(downloadCarOwnershipsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCarOwnershipSelectedOrderBy(orderBy));
    dispatch(clearCarOwnerships());
    dispatch(downloadCarOwnershipsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCarOwnershipTappedId(id));
    dispatch(downloadCarOwnershipAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarOwnershipPage);
