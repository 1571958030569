import { reset } from 'redux-form';
import {
  addingEditingUser, clearUsers, setAlertErrorMessage, setUserSelectedRole,
} from '../simple-action';
import { addIntegrationUser } from '../../../helper';
import { RXFORM_USER } from '../../../constant';
import downloadUsersAsync from './downloadUsersAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingUser(true));

    const { token } = getState().authentication;
    const body = {
      fullname: values.fullName,
      password: values.newPassword,
      username: values.username,
      roles: [values.roles],
    };
    await addIntegrationUser(body, token);

    dispatch(reset(RXFORM_USER));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserSelectedRole(''));
  } finally {
    dispatch(addingEditingUser(false));
  }
};
