import { enablingDisablingVoucherTokenAssignment, setAlertErrorMessage } from '../simple-action';
import { enabledDisabledVoucherTokenAssignment } from '../../../helper';
import { STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import downloadVoucherTokenAssignmentsAsync from './downloadVoucherTokenAssignmentsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingVoucherTokenAssignment(true));

    const { token } = getState().authentication;
    const { data, meta } = getState().voucherTokenAssignments;
    const { tappedId } = getState().uiVoucherTokenAssignment;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enabledDisabledVoucherTokenAssignment(tappedId, status, token);

    dispatch(downloadVoucherTokenAssignmentsAsync(meta.currentPage))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(enablingDisablingVoucherTokenAssignment(false));
  }
};
