import {
  sendingNotifications, setBranchSearchText, setCompanySearchText, setDepartmentSearchText,
  setDivisionSearchText, setNotificationSelectedBranch, setNotificationSelectedCompany,
  setNotificationSelectedCity, setTotalPersonCount,
} from '../simple-action';
import { sendNotification } from '../../../helper';
import {
  FILTERED_RECIPIENTS_COMPANIES_TAG,
  FILTERED_RECIPIENTS_CITIES_TAG, FILTERED_RECIPIENTS_USER_TIER_TAG,
  FILTERED_RECIPIENTS_STATUS_ENABLED_TAG, RECIPIENT_TYPE_PROFILE,
} from '../../../constant';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(sendingNotifications(true));

    const { token } = getState().authentication;
    const {
      selectedCompany, selectedCity, selectedShellMembership,
      selectedUserTier,
    } = getState().notification;
    const { title, body, url } = values;
    const companiesTag = selectedCompany
      ? `${FILTERED_RECIPIENTS_COMPANIES_TAG}${selectedCompany}` : '';
    const shellMembershipTag = selectedShellMembership ?? '';
    const selectedUserTierTag = selectedUserTier && selectedUserTier.value ? `${FILTERED_RECIPIENTS_USER_TIER_TAG}${selectedUserTier.value}` : '';
    const cities = selectedCity.length
      ? selectedCity.reduce((p, c) => (!p ? c.value : `${p};${c.value}`), '') : '';
    const citiesTag = cities ? `${FILTERED_RECIPIENTS_CITIES_TAG}${cities}` : '';
    const filteredRecipients = `${FILTERED_RECIPIENTS_STATUS_ENABLED_TAG}${companiesTag}${citiesTag}${shellMembershipTag}${selectedUserTierTag}`;

    await sendNotification(RECIPIENT_TYPE_PROFILE, encodeURIComponent(filteredRecipients), title,
      body, url, token);

    dispatch(setBranchSearchText(''));
    dispatch(setCompanySearchText(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setDivisionSearchText(''));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setNotificationSelectedCity(''));
    dispatch(setTotalPersonCount(0));
  } finally {
    dispatch(sendingNotifications(false));
  }
};
