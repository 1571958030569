
import { INITIAL_ORDER_BY_PARTNER_ADMIN_VOUCHER_TOKEN } from '../../constant';
import {
  DOWNLOADING_VOUCHER_TOKENS, SET_VOUCHER_TOKEN_SEARCH_TEXT,
  SET_VOUCHER_TOKEN_SELECTED_ORDER_BY, SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE,
  SET_VOUCHER_TOKEN_TAPPED_ID, DOWNLOADING_VOUCHER_TOKEN,
  SAVING_VOUCHER_TOKENS,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PARTNER_ADMIN_VOUCHER_TOKEN,
  saving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_VOUCHER_TOKENS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_VOUCHER_TOKEN:
      return { ...state, downloadingDeleting: action.status };
    case SAVING_VOUCHER_TOKENS:
      return { ...state, saving: action.status };
    case SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOUCHER_TOKEN_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_VOUCHER_TOKEN_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOUCHER_TOKEN_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
