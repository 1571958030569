import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXSTATE_CAR_VIEWING_EVENTS, RXSTATE_CAR_VIEWING_EVENT,
  SIMPLE_DATE_FORMAT, RXFORM_CAR_VIEWING_EVENT,
  RXFIELD_CAR_VIEWING_EVENT_NAME, RXFIELD_CAR_VIEWING_EVENT_START_DATE,
  RXFIELD_CAR_VIEWING_EVENT_END_DATE, RXFIELD_CAR_VIEWING_EVENT_VISIBILITY,
  RXFIELD_CAR_VIEWING_EVENT_OPEN_TIME, RXFIELD_CAR_VIEWING_EVENT_CLOSE_TIME,
  RXFIELD_CAR_VIEWING_EVENT_DESCRIPTION, RXFIELD_CAR_VIEWING_EVENT_LOCATION,
  RXFIELD_CAR_VIEWING_EVENT_CITY, RXFIELD_CAR_VIEWING_EVENT_VOUCHER_REWARDS,
  RXFIELD_CAR_VIEWING_EVENT_IMAGE, PICKER_MODE_TIME, PICKER_MODE_DATE,
  RXFIELD_CAR_VIEWING_EVENT_QUOTA_PER_SLOT, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_DATE_RANGE, PICKER_DATE_FORMAT, PICKER_TIME_FORMAT,
  visibilityStatuses,
} from '../../constant';
import { toMoment } from '../../helper';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import {
  renderReduxFormDateTimePickerField, renderReduxFormImageInputField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateCarViewingEvent } from '../../validation';

const renderDialogContent = (
  addingEditing, pageMode, initialValues,
  cities, loadingCities, onChangeCityText, onCitySelected,
  vouchers, loadingVouchers, onChangeVoucherText, onVoucherRewardsSelected,
  onImageSelected,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingEventPage.placeholderName}
          label={LocalizedString.carViewingEventPage.placeholderName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingEventPage.placeholderDescription}
          label={LocalizedString.carViewingEventPage.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_LOCATION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingEventPage.placeholderLocation}
          label={LocalizedString.carViewingEventPage.placeholderLocation}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_CITY}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.carViewingEventPage.placeholderCity}
          label={LocalizedString.carViewingEventPage.placeholderCity}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={cities}
          loading={loadingCities}
          onChangeText={onChangeCityText}
          onOptionSelected={onCitySelected}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_VOUCHER_REWARDS}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.carViewingEventPage.placeholderVoucherRewards}
          label={LocalizedString.carViewingEventPage.placeholderVoucherRewards}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={vouchers}
          onChangeText={onChangeVoucherText}
          loading={loadingVouchers}
          onOptionSelected={onVoucherRewardsSelected}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_QUOTA_PER_SLOT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingEventPage.placeholderQuotaPerSlot}
          label={LocalizedString.carViewingEventPage.placeholderQuotaPerSlot}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          type="number"
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.carViewingEventPage.placeholderImage}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          onImageSelected={onImageSelected}
          defaultValue={initialValues.image}
          onBlur={(e) => e.preventDefault()}
          useFullWidthImage
          useAvatarWithoutCropper
          fullImageWidth="100%"
          useCropper={false}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_START_DATE}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carViewingEventPage.placeholderStartDate}
          label={LocalizedString.carViewingEventPage.placeholderStartDate}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          pickerMode={PICKER_MODE_DATE}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_END_DATE}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carViewingEventPage.placeholderEndDate}
          label={LocalizedString.carViewingEventPage.placeholderEndDate}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          pickerMode={PICKER_MODE_DATE}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_OPEN_TIME}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carViewingEventPage.placeholderOpenTime}
          label={LocalizedString.carViewingEventPage.placeholderOpenTime}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          pickerMode={PICKER_MODE_TIME}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_CLOSE_TIME}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carViewingEventPage.placeholderEndTime}
          label={LocalizedString.carViewingEventPage.placeholderEndTime}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          pickerMode={PICKER_MODE_TIME}
          required
        />
      </Grid>
      <Grid>
        <Field
          name={RXFIELD_CAR_VIEWING_EVENT_VISIBILITY}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.carViewingEventPage.placeholderVisibility}
          label={LocalizedString.carViewingEventPage.placeholderVisibility}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={visibilityStatuses}
          required
        />
      </Grid>
    </Grid>
  </Grid>
);

const CarViewingEventPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed, initialValues, onImageSelected,
  cities, loadingCities, onChangeCityText, onCitySelected,
  vouchers, loadingVouchers, onChangeVoucherText, onVoucherRewardsSelected,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onResetAdvancedFilterPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_CAR_VIEWING_EVENTS}
    uiPage={RXSTATE_CAR_VIEWING_EVENT}
    tableColumns={[
      {
        title: LocalizedString.carViewingEventPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.carViewingEventPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.carViewingEventPage.labelLocation, field: 'location', sorting: !downloading },
      {
        title: LocalizedString.carViewingEventPage.labelStartDate,
        field: 'startDate',
        sorting: !downloading,
        render: ({ startDate }) => (startDate
          ? toMoment(startDate).format(SIMPLE_DATE_FORMAT) : null),
      },
      {
        title: LocalizedString.carViewingEventPage.labelEndDate,
        field: 'endDate',
        sorting: !downloading,
        render: ({ endDate }) => (endDate
          ? toMoment(endDate).format(SIMPLE_DATE_FORMAT) : null),
      },
      { title: LocalizedString.carViewingEventPage.labelOpenTime, field: 'openTime', sorting: !downloading },
      { title: LocalizedString.carViewingEventPage.labelCloseTime, field: 'closeTime', sorting: !downloading },
      { title: LocalizedString.carViewingEventPage.labelVisibility, field: 'visibility', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.carViewingEventPage.labelCity,
        field: RXFIELD_CAR_VIEWING_EVENT_CITY,
        type: FILTER_TYPE_DROPDOWN,
        data: cities,
        loading: loadingCities,
        onChangeFilterText: onChangeCityText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.carViewingEventPage.labelStartDate,
        field: RXFIELD_CAR_VIEWING_EVENT_START_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE,
        format: PICKER_DATE_FORMAT,
      },
      {
        title: LocalizedString.carViewingEventPage.labelOpenTime,
        field: RXFIELD_CAR_VIEWING_EVENT_OPEN_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_TIME,
        format: PICKER_TIME_FORMAT,
      },
      {
        title: LocalizedString.carViewingEventPage.labelVisibility,
        field: RXFIELD_CAR_VIEWING_EVENT_VISIBILITY,
        type: FILTER_TYPE_DROPDOWN,
        data: visibilityStatuses,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={
      LocalizedString.carViewingEventPage.buttonCaptionCreateNewCarViewingEvent
    }
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    deleteButtonCaption={LocalizedString.carViewingEventPage.buttonCaptionDeleteCarViewingEvent}
    title={LocalizedString.carViewingEventPage.title}
    editButtonCaption={LocalizedString.carViewingEventPage.buttonCaptionEditCarViewingEvent}
    useFullWidth
  >
    {renderDialogContent(
      addingEditing, pageMode, initialValues,
      cities, loadingCities, onChangeCityText, onCitySelected,
      vouchers, loadingVouchers, onChangeVoucherText, onVoucherRewardsSelected,
      onImageSelected,
    )}
  </FunctionalPage>
);


export default reduxForm({
  form: RXFORM_CAR_VIEWING_EVENT,
  validate: rxformValidateCarViewingEvent,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CarViewingEventPage);

CarViewingEventPage.propTypes = {
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  initialValues: FormInitialValueShape.isRequired,
  loadingCities: PropTypes.bool.isRequired,
  loadingVouchers: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onVoucherRewardsSelected: PropTypes.func.isRequired,
  onCitySelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
};
