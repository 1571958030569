import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_CHARGING_STATIONS,
  MENUID_CAR_SERVICE_CHARGING_STATION,
  PAGE_MODE_TABLE,
  REST_URL_GOOGLE_MAP,
} from '../../constant';
import { transformDropdownData, transformInitialValues } from '../../helper';
import {
  clearChargingStations,
  clearChargingStationCities,
  clearChargingStationProvinces,
  downloadChargingStationAsync,
  downloadChargingStationsAsync,
  downloadChargingStationCitiesAsync,
  downloadChargingStationProvincesAsync,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setChargingStationAdvancedFilterDialogSelectedFilterString,
  setChargingStationSearchText,
  setChargingStationSelectedOrderBy,
  setChargingStationSelectedPageSize,
  setChargingStationTappedId,
} from '../../redux/action';
import CarServiceChargingStationPage from './charging-station.presentation';

const getInitialValues = (state) => {
  const { chargingStations, uiFunctionalPage, uiChargingStation } = state;
  const { data } = chargingStations;
  const { downloadingDeleting, tappedId } = uiChargingStation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {})
    : {};
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiChargingStation.downloading,
  downloadingDeleting: state.uiChargingStation.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiChargingStation.tappedId,
  loadingChargingStationProvince: state.uiChargingStation.downloadingChargingStationProvince,
  loadingChargingStationCity: state.uiChargingStation.downloadingChargingStationCity,
  chargingStationProvinces: transformDropdownData(state.chargingStationProvinces.data),
  chargingStationCities: transformDropdownData(state.chargingStationCities.data),
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(clearChargingStationProvinces());
    dispatch(clearChargingStationCities());
    dispatch(downloadChargingStationProvincesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadChargingStationCitiesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_SERVICE_CHARGING_STATION));
    dispatch(setChargingStationSearchText(''));
    dispatch(setChargingStationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearChargingStations());
    dispatch(setChargingStationSelectedPageSize(20));
    dispatch(setChargingStationSelectedOrderBy(INITIAL_ORDER_BY_CHARGING_STATIONS));
    dispatch(downloadChargingStationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setChargingStationAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearChargingStations());
    dispatch(downloadChargingStationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadChargingStationsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setChargingStationSelectedPageSize(pageSize));
    dispatch(downloadChargingStationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setChargingStationSelectedPageSize(pageSize));
    dispatch(clearChargingStations());
    dispatch(downloadChargingStationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearChargingStations());
    dispatch(setChargingStationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setChargingStationSelectedOrderBy(INITIAL_ORDER_BY_CHARGING_STATIONS));
    dispatch(downloadChargingStationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setChargingStationSearchText(text));
      dispatch(clearChargingStations());
      await dispatch(downloadChargingStationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setChargingStationSelectedOrderBy(orderBy));
    dispatch(clearChargingStations());
    dispatch(downloadChargingStationsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setChargingStationTappedId(id));
    dispatch(downloadChargingStationAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onOpenGoogleMaps: (lat, long) => {
    const url = REST_URL_GOOGLE_MAP.replace(/\{long\}/, lat).replace(/\{lat\}/, long);
    window.open(url);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarServiceChargingStationPage);
