import { connect } from 'react-redux';
import { setActiveSideMenuItem } from '../../redux/action';
import { MENUID_WELCOME } from '../../constant';
import WelcomePage from './welcome.presentation';

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_WELCOME));
  },
});

export default connect(null, mapDispatchToProps)(WelcomePage);
