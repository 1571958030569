import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import GlobalLocalizedString from '../../../../localization';
import {
  INITIAL_ORDER_BY_TOKEN_REQUESTS, MENUID_VOUCHER_TOKEN_REQUEST, PAGE_MODE_TABLE,
  RXFORM_TOKEN_REQUEST, DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_TOKEN_REQUEST_VOUCHER,
} from '../../constant';
import { transformInitialValues, toMoment, transformDropdownData } from '../../helper';
import {
  addTokenRequestAsync, clearTokenRequests,
  downloadTokenRequestAsync, downloadTokenRequestsAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setTokenRequestSearchText, setTokenRequestSelectedOrderBy,
  setTokenRequestSelectedPageSize, setTokenRequestTappedId,
  setVoucherSearchText, clearVouchers, downloadVouchersAsync,
  setAdvancedFilterDialogSelectedFilterString,
  setTokenRequestSelectedVoucherId, setVoucherAdvancedFilterDialogSelectedFilterString,
  setAlertConfirmationMessage, cancelTokenRequestAsync,
} from '../../redux/action';
import {
  downloadMDataCompaniesAsync, setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData, clearCompaniesMData,
} from '../../../../redux/action';
import TokenRequestPage from './token-request.presentation';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiTokenRequest, tokenRequests } = state;
  const { data } = tokenRequests;
  const { downloadingDeleting, canceling, tappedId } = uiTokenRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting && !canceling
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    voucher: found.voucher
      ? { label: found.voucher.title, value: found.voucher.id }
      : { label: '', value: '' },
    quantity: found.quantity ?? '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    validFrom: found.validFrom ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    startTime: found.startTime ? toMoment(found.startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    endTime: found.endTime ? toMoment(found.endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    progress: found.progress || found.progress === 0 ? `${found.progress}%` : '',
    merchant: found.voucher?.merchant?.name || '',
    voucherId: found.voucher?.id || '',
    voucherName: found.voucher?.title || '',
  }) : {
    voucher: '',
    quantity: 0,
    validFrom: '',
    validUntil: '',
    taskStatus: '',
    progress: 0,
    progressNotes: '',
    startTime: '',
    endTime: '',
  };
  return result;
};

const getVouchers = (state) => {
  const { vouchers } = state;
  const { data } = vouchers;
  const formattedData = Object.values(data).map((item) => ({ label: item.title, value: item.id }));
  return formattedData;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiTokenRequest.addingEditing,
  downloading: state.uiTokenRequest.downloading,
  downloadingDeleting: state.uiTokenRequest.downloadingDeleting,
  canceling: state.uiTokenRequest.canceling,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  vouchers: getVouchers(state),
  loadingVoucher: state.uiVoucher.downloading,
  taskStatus: state.tokenRequests.data[state.uiFunctionalPage.tappedId]?.taskStatus,
  merchants: transformDropdownData(state.companiesMdata.data),
  loadingMerchant: state.uiCompany.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_TOKEN_REQUEST));
    dispatch(setTokenRequestSearchText(''));
    dispatch(clearTokenRequests());
    dispatch(setTokenRequestSelectedPageSize(20));
    dispatch(setTokenRequestSelectedOrderBy(INITIAL_ORDER_BY_TOKEN_REQUESTS));
    dispatch(downloadTokenRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTokenRequestsAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTokenRequestSelectedPageSize(pageSize));
    dispatch(downloadTokenRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_TOKEN_REQUEST));
  },
  onCreatePressed: () => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setTokenRequestTappedId(''));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setTokenRequestSelectedPageSize(pageSize));
    dispatch(clearTokenRequests());
    dispatch(downloadTokenRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSubmitPressed: ({
    quantity, validFrom, validUntil,
  }) => {
    dispatch(addTokenRequestAsync(
      quantity, validFrom, validUntil,
    ))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setTokenRequestSearchText(text));
    dispatch(clearTokenRequests());
    dispatch(downloadTokenRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setTokenRequestSelectedOrderBy(orderBy));
    dispatch(clearTokenRequests());
    dispatch(downloadTokenRequestsAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setTokenRequestTappedId(id));
    dispatch(downloadTokenRequestAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onVoucherOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_TOKEN_REQUEST, RXFIELD_TOKEN_REQUEST_VOUCHER, option));
      dispatch(setTokenRequestSelectedVoucherId(option.value));
    } else {
      dispatch(change(RXFORM_TOKEN_REQUEST, RXFIELD_TOKEN_REQUEST_VOUCHER, ''));
      dispatch(setTokenRequestSelectedVoucherId());
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/voucher/, 'voucher.id')
      .replace(/merchant/, 'voucher.merchant.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearTokenRequests());
    dispatch(downloadTokenRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTokenRequests());
    dispatch(setTokenRequestSelectedOrderBy(INITIAL_ORDER_BY_TOKEN_REQUESTS));
    dispatch(downloadTokenRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelTokenRequestPressed: (id) => {
    dispatch(setTokenRequestTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgCancelConfirmation));
  },
  onConfirmCancelTokenRequestPressed: async () => {
    try {
      await dispatch(cancelTokenRequestAsync());
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TokenRequestPage);
