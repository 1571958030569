import {
  downloadingDeletingTestDriveReservation, addTestDriveReservation,
} from '../simple-action';
import { downloadTestDriveReservation } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingTestDriveReservation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiReservation;

    const reservation = await downloadTestDriveReservation(tappedId, token);
    dispatch(addTestDriveReservation(reservation));
  } finally {
    dispatch(downloadingDeletingTestDriveReservation(false));
  }
};
