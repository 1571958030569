import { transformSearchText, downloadHyundaiChallengeScannedCodes } from '../../../helper';
import { downloadingHyundaiChallengeScannedCodes, setHyundaiChallengeScannedCodes } from '../simple-action';


export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, hyundaiChallengeScannedCodes, uiHyundaiChallengeScannedCode,
  } = getState();

  const { token } = authentication;
  const { meta } = hyundaiChallengeScannedCodes;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiHyundaiChallengeScannedCode;

  try {
    dispatch(downloadingHyundaiChallengeScannedCodes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const scannedCodes = await downloadHyundaiChallengeScannedCodes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    dispatch(setHyundaiChallengeScannedCodes(scannedCodes));
  } finally {
    dispatch(downloadingHyundaiChallengeScannedCodes(false));
  }
};
