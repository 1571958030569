import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch,
} from '../../helper';

import {
  INITIAL_ORDER_BY_GIIAS_MEDAN_TRANSACTION,
  MENUID_GIIAS_MEDAN_TRANSACTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import Giias2022TransactionPage from './giias-medan-transaction.presentation';
import {
  clearGiiasMedan2022Transactions, setGiiasMedan2022TransactionAdvancedFilterString,
  setGiiasMedan2022TransactionSearchText, setGiiasMedan2022TransactionSelectedOrderBy,
  setGiiasMedan2022TransactionSelectedPageSize, setGiiasMedan2022TransactionTappedId,
  downloadGiiasMedan2022TransactionAsync,
  downloadGiiasMedan2022TransactionsAsync,
  saveGiiasMedan2022TransactionsAsync,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { giiasMedanTransactions, uiFunctionalPage, uiGiiasMedanTransaction } = state;
  const { data } = giiasMedanTransactions;
  const { downloadingDeleting, tappedId } = uiGiiasMedanTransaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiGiiasMedanTransaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_MEDAN_TRANSACTION));
    dispatch(setGiiasMedan2022TransactionAdvancedFilterString(''));
    dispatch(setGiiasMedan2022TransactionSearchText(''));
    dispatch(clearGiiasMedan2022Transactions());
    dispatch(setGiiasMedan2022TransactionSelectedPageSize(20));
    dispatch(
      setGiiasMedan2022TransactionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_MEDAN_TRANSACTION),
    );
    dispatch(downloadGiiasMedan2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiiasMedan2022TransactionSelectedOrderBy(orderBy));
    dispatch(clearGiiasMedan2022Transactions());
    dispatch(downloadGiiasMedan2022TransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiiasMedan2022TransactionTappedId(id));
    dispatch(downloadGiiasMedan2022TransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setGiiasMedan2022TransactionSearchText(text));
    dispatch(clearGiiasMedan2022Transactions());
    dispatch(downloadGiiasMedan2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiiasMedan2022TransactionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiiasMedan2022TransactionSelectedPageSize(pageSize));
    dispatch(downloadGiiasMedan2022TransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiiasMedan2022TransactionSelectedPageSize(pageSize));
    dispatch(clearGiiasMedan2022Transactions());
    dispatch(downloadGiiasMedan2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGiiasMedan2022Transactions());
    dispatch(setGiiasMedan2022TransactionAdvancedFilterString(''));
    dispatch(
      setGiiasMedan2022TransactionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_MEDAN_TRANSACTION),
    );
    dispatch(downloadGiiasMedan2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id');

    dispatch(setGiiasMedan2022TransactionAdvancedFilterString(text));
    dispatch(clearGiiasMedan2022Transactions());
    dispatch(downloadGiiasMedan2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveGiiasMedan2022TransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Giias2022TransactionPage);
