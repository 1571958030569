import { downloadGiiasSurabayaPrizeRedemption } from '../../../helper';
import { addGiiasSurabayaPrizeRedemption, downloadingGiiasSurabayaPrizeRedemption } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiiasSurabayaPrizeRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiiasSurabayaPrizeRedemption;

    const giias2022PrizeRedemption = await downloadGiiasSurabayaPrizeRedemption(tappedId, token);
    dispatch(addGiiasSurabayaPrizeRedemption(giias2022PrizeRedemption));
  } finally {
    dispatch(downloadingGiiasSurabayaPrizeRedemption(false));
  }
};
