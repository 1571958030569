import { downloadingMapTokenAirports, setMapTokenAirports } from '../simple-action';
import { downloadGarudaAirports, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiGarudaAirport } = getState();
  const { token } = authentication;
  const {
    airportSearchBarText, orderBy,
  } = uiGarudaAirport;

  try {
    dispatch(downloadingMapTokenAirports(true));

    const result = await downloadGarudaAirports(
      1,
      20,
      orderBy,
      transformSearchText(airportSearchBarText),
      token,
    );

    dispatch(setMapTokenAirports(result));
  } finally {
    dispatch(downloadingMapTokenAirports(false));
  }
};
