import { downloadingChargingStations, setChargingStations } from '../simple-action';
import { downloadChargingStations, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, chargingStations, uiChargingStation } = getState();
  const { token } = authentication;
  const { meta } = chargingStations;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiChargingStation;

  try {
    dispatch(downloadingChargingStations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadChargingStations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setChargingStations(list));
    }
  } finally {
    dispatch(downloadingChargingStations(false));
  }
};
