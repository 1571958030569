import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  transformInitialValues,
  transformUserDropdownData, transformDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  setAlertErrorMessage, setTestDriveEventOrganizerSelectedOrderBy,
  clearTestDriveEventOrganizers, downloadDeleteTestDriveEventOrganizerAsync,
  setTestDriveEventOrganizerTappedId, setTestDriveEventOrganizerSearchText,
  setTestDriveEventOrganizerSelectedPageSize, setTestDriveEventOrganizerAdvancedFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setEventAdvancedFilterDialogSelectedFilterString, clearEvents,
  downloadEventsAsync, setEventSearchText,
  downloadTestDriveEventOrganizersAsync, setTestDriveEventOrganizerSelectedUser,
  setTestDriveEventOrganizerSelectedEvent, addingEditingTestDriveEventOrgaizerAsync,
  setAlertConfirmationMessage,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE, INITIAL_ORDER_BY_EVENT_ORGANIZERS,
  RXFORM_TEST_DRIVE_EVENT_ORGANIZER,
  RXFIELD_EVENT_ORGANIZER_USER_FULLNAME, RXFIELD_EVENT_ORGANIZER_EVENT_NAME,
} from '../../constant';
import { downloadUsersAsync } from '../../../../redux/action';
import EventOrganizerPage from './event-organizer.presentation';

const getInitialValues = (state) => {
  const { eventOrganizers, uiFunctionalPage, uiEventOrganizer } = state;
  const { data } = eventOrganizers;
  const { downloadingDeleting, tappedId } = uiEventOrganizer;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.profile?.id || '',
    userFullName: found.profile?.fullName || '',
    eventId: found.event?.id || '',
    eventName: found.event?.name || '',
  }) : {
    userId: '',
    userFullName: '',
    eventId: '',
    eventName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  addingEditing: state.uiEventOrganizer.addingEditing,
  downloading: state.uiEventOrganizer.downloading,
  downloadingDeleting: state.uiEventOrganizer.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiEventOrganizer.tappedId || '',
  events: transformDropdownData(state.events.data),
  loadingEvents: state.uiEvent.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(clearTestDriveEventOrganizers());
    dispatch(setTestDriveEventOrganizerAdvancedFilterString(''));
    dispatch(setTestDriveEventOrganizerSelectedOrderBy(INITIAL_ORDER_BY_EVENT_ORGANIZERS));
    dispatch(downloadTestDriveEventOrganizersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('eventName', 'event.id')
      .replace('userFullName', 'profile.id');

    dispatch(setTestDriveEventOrganizerAdvancedFilterString(text));
    dispatch(clearTestDriveEventOrganizers());
    dispatch(downloadTestDriveEventOrganizersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_TEST_DRIVE_EVENT_ORGANIZER));
    dispatch(setTestDriveEventOrganizerSelectedUser(''));
    dispatch(setTestDriveEventOrganizerSelectedEvent(''));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestDriveEventOrganizersAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestDriveEventOrganizerSelectedPageSize(pageSize));
    dispatch(downloadTestDriveEventOrganizersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setEventSearchText(text));
      dispatch(clearEvents());
      await dispatch(downloadEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteTestDriveEventOrganizerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setTestDriveEventOrganizerTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setTestDriveEventOrganizerTappedId(id));
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onEventOptionSelected: (option) => {
    if (option) {
      dispatch(setTestDriveEventOrganizerSelectedEvent(option));
      dispatch(change(RXFORM_TEST_DRIVE_EVENT_ORGANIZER,
        RXFIELD_EVENT_ORGANIZER_EVENT_NAME, option));
    } else {
      dispatch(setTestDriveEventOrganizerSelectedEvent(''));
      dispatch(change(RXFORM_TEST_DRIVE_EVENT_ORGANIZER,
        RXFIELD_EVENT_ORGANIZER_EVENT_NAME, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setTestDriveEventOrganizerSelectedPageSize(pageSize));
    dispatch(clearTestDriveEventOrganizers());
    dispatch(downloadTestDriveEventOrganizersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestDriveEventOrganizers());
    dispatch(setTestDriveEventOrganizerAdvancedFilterString(''));
    dispatch(setTestDriveEventOrganizerSelectedOrderBy(INITIAL_ORDER_BY_EVENT_ORGANIZERS));
    dispatch(downloadTestDriveEventOrganizersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSavePressed: async () => {
    try {
      await dispatch(addingEditingTestDriveEventOrgaizerAsync());
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSubmitPressed: async () => {
    dispatch(setTestDriveEventOrganizerTappedId(''));
    await dispatch(addingEditingTestDriveEventOrgaizerAsync());
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestDriveEventOrganizerSelectedOrderBy(orderBy));
    dispatch(clearTestDriveEventOrganizers());
    dispatch(downloadTestDriveEventOrganizersAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setTestDriveEventOrganizerSearchText(text));
    dispatch(clearTestDriveEventOrganizers());
    dispatch(downloadTestDriveEventOrganizersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setTestDriveEventOrganizerSelectedUser(option));
      dispatch(change(RXFORM_TEST_DRIVE_EVENT_ORGANIZER,
        RXFIELD_EVENT_ORGANIZER_USER_FULLNAME, option));
    } else {
      dispatch(setTestDriveEventOrganizerSelectedUser(''));
      dispatch(change(RXFORM_TEST_DRIVE_EVENT_ORGANIZER,
        RXFIELD_EVENT_ORGANIZER_USER_FULLNAME, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setTestDriveEventOrganizerTappedId(id));
    dispatch(downloadDeleteTestDriveEventOrganizerAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventOrganizerPage);
