import { connect } from 'react-redux';
import { change, getFormValues, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  MENUID_CONFIGURATION_CONFIG_VALUE, PAGE_MODE_TABLE, RXFORM_CONFIG_VALUE,
} from '../../constant';
import { sortAsc, sortDesc } from '../../../../helper';
import LocalizedString from '../../localization';
import {
  setActiveSideMenuItem, setAlertErrorMessage, setFunctionalPageMode,
  setConfigValueDisplayUnsavedChangeDialog, setSelectedConfigValueFilter,
  downloadAllConfigGroupsAsync, downloadFilteredConfigItemsAsync, editConfigItemsAsync,
} from '../../redux/action';
import ConfigValuePage from './config-value.presentation';

const getAllConfigGroupFilters = (state) => {
  if (!isEmpty(state.uiConfigValue.configGroups)) {
    const { configGroups } = state.uiConfigValue;
    const filters = Object.values(configGroups)
      .map((configGroup) => (configGroup.name))
      .sort((a, b) => sortDesc(a.label, b.label));
    const filtersWithDefaultValue = [
      LocalizedString.configValueScreen.labelFilterBy,
      ...filters,
    ];
    return filtersWithDefaultValue;
  }
  return [LocalizedString.configValueScreen.labelFilterBy];
};

const getConfigData = (state) => {
  const { selectedFilter } = state.uiConfigValue;
  const { data } = state.configValues;
  if (!isEmpty(data)) {
    if (selectedFilter === LocalizedString.configValueScreen.labelFilterBy) {
      return [];
    }

    const groupBy = Object.values(data).reduce((acc, curr) => ({
      ...acc,
      [curr.configSection]: {
        sectionName: curr.configSection,
        sectionData: (acc[curr.configSection] ? acc[curr.configSection].sectionData : null || [])
          .concat(curr).sort((a, b) => sortAsc(a.order, b.order)),
      },
    }), {});

    const result = [
      groupBy[''],
      ...Object.values(groupBy).filter((x) => x.sectionName)
        .sort((a, b) => sortAsc(a.sectionData[0].order, b.sectionData[0].order)),
    ].filter((x) => x);

    return result;
  }
  return [];
};

const getInitialValues = (state) => {
  const { selectedFilter } = state.uiConfigValue;
  const { data } = state.configValues;
  if (selectedFilter === LocalizedString.configValueScreen.labelFilterBy
    || Object.values(data).length === 0) {
    return ({});
  }
  const fieldNames = Object.values(data).map((item) => item.id);
  const fieldInitialValues = Object.values(data).reduce((prev, curr, index) => ({
    ...prev,
    [fieldNames[index]]: curr.value,
  }), {});
  return fieldInitialValues;
};

const mapStateToProps = (state) => ({
  allConfigGroupFilter: getAllConfigGroupFilters(state),
  selectedFilter: state.uiConfigValue.selectedFilter,
  configData: getConfigData(state),
  initialValues: getInitialValues(state),
  copyStatus: state.uiConfigValue.copyStatus,
  downloading: state.uiConfigValue.downloadingConfigValues,
  formValues: getFormValues(RXFORM_CONFIG_VALUE)(state),
  displayUnsavedChangeDialog: state.uiConfigValue.displayUnsavedChangeDialog,
  editingConfigItems: state.uiConfigValue.editingConfigItems,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (selectedFilter) => {
    dispatch(downloadAllConfigGroupsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    if (selectedFilter !== LocalizedString.configValueScreen.labelFilterBy) {
      dispatch(downloadFilteredConfigItemsAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onCloseDialog: () => {
    dispatch(setConfigValueDisplayUnsavedChangeDialog(false));
  },
  onDropdownOptionSelected: (option, fieldName) => {
    if (option) {
      dispatch(change(RXFORM_CONFIG_VALUE, fieldName, option));
    } else {
      dispatch(change(RXFORM_CONFIG_VALUE, fieldName, ''));
    }
  },
  onFilterValueSelected: (event, valuesNotChanged) => {
    if (valuesNotChanged) {
      dispatch(setSelectedConfigValueFilter(event.target.value));
      dispatch(downloadFilteredConfigItemsAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setConfigValueDisplayUnsavedChangeDialog(true));
    }
  },
  onResetPressed: () => {
    dispatch(reset(RXFORM_CONFIG_VALUE));
  },
  onSaveButtonPressed: (values) => {
    dispatch(editConfigItemsAsync(values))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSideMenuPressed: (item, history, selectedFilter) => {
    if (item.id === MENUID_CONFIGURATION_CONFIG_VALUE) {
      if (selectedFilter !== LocalizedString.configValueScreen.labelFilterBy) {
        dispatch(downloadFilteredConfigItemsAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      }
    } else {
      dispatch(setActiveSideMenuItem(item.id));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      history.push(item.value);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigValuePage);
