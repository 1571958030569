import {
  DOWNLOADING_DEVICE_EXCEPTIONS, ADDING_EDITING_DEVICE_EXCEPTION,
  DOWNLOADING_DELETING_DEVICE_EXCEPTION, SET_DEVICE_EXCEPTION_SELECTED_PAGE_SIZE,
  SET_DEVICE_EXCEPTION_TAPPED_ID, SET_DEVICE_EXCEPTION_SEARCH_TEXT,
  SET_DEVICE_EXCEPTION_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_DEVICE_EXCEPTIONS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_DEVICE_EXCEPTIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_DEVICE_EXCEPTIONS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_DEVICE_EXCEPTION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_DEVICE_EXCEPTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_DEVICE_EXCEPTION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_DEVICE_EXCEPTION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_DEVICE_EXCEPTION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_DEVICE_EXCEPTION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
