import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_PARTNER_ADMIN_VOUCHER_TOKEN, PAGE_MODE_TABLE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  MENUID_PARTNER_ADMIN_VOUCHER_TOKEN,
} from '../../constant';
import {
  transformInitialValues, toMoment,
} from '../../helper';
import {
  clearVoucherTokens, downloadVoucherTokenAsync, downloadVoucherTokensAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setVoucherTokenSearchText, setVoucherTokenSelectedOrderBy,
  setVoucherTokenSelectedPageSize, setVoucherTokenTappedId,
  setAdvancedFilterDialogSelectedFilterString,
  saveVoucherTokensAsync,
} from '../../redux/action';
import PartnerVoucherTokenPage from './voucher-token.presentation';
import LocalizedString from '../../../../localization';
import {
  clearVouchers, downloadVouchersAsync, setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText,
} from '../../../voucher/redux/action';

const getDropdownValue = (value) => (value ? { label: LocalizedString.common.labelYes, value: true }
  : { label: LocalizedString.common.labelNo, value: false });

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiPartnerVoucherToken, partnerVoucherTokens } = state;
  const { data } = partnerVoucherTokens;
  const { downloadingDeleting, tappedId } = uiPartnerVoucherToken;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    selectedVoucher: found.voucher?.title || '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    validFrom: found.validFrom ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    purchasedOn: found.purchasedOn ? toMoment(found.purchasedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    revokedOn: found.revokedOn ? toMoment(found.revokedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    claimedOn: found.claimedOn ? toMoment(found.claimedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    isPurchased: getDropdownValue(found.isPurchased),
    isRevoked: getDropdownValue(found.isRevoked),
    isClaimed: getDropdownValue(found.isClaimed),
    purchasedById: found.purchasedBy?.id || '',
    revokedById: found.revokedBy?.id || '',
    claimedById: found.claimedBy?.id || '',
    purchasedBy: found.purchasedBy?.fullName || '',
    revokedBy: found.revokedBy?.fullName || '',
    claimedBy: found.claimedBy?.fullName || '',
    voucherImage: found.voucher ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.voucher.image}` : null,
    voucherName: found.voucher?.title || '',
    merchantName: found.voucher?.merchant?.name || '',
  }) : {
    code: '',
    validFrom: '',
    validUntil: '',
    isPurchased: null,
    purchasedOn: '',
    purchasedBy: '',
    isRevoked: null,
    revokedOn: '',
    revokedBy: '',
    revocationNotes: '',
    selectedVoucher: '',
    merchant: '',
    isClaimed: null,
    claimedOn: '',
    claimedBy: '',
    claimNotes: '',
    voucherName: '',
    merchantName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPartnerVoucherToken.downloading,
  downloadingDeleting: state.uiPartnerVoucherToken.downloadingDeleting,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingVoucher: state.uiVoucher.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PARTNER_ADMIN_VOUCHER_TOKEN));
    dispatch(setVoucherTokenSearchText(''));
    dispatch(clearVoucherTokens());
    dispatch(setVoucherTokenSelectedPageSize(20));
    dispatch(setVoucherTokenSelectedOrderBy(INITIAL_ORDER_BY_PARTNER_ADMIN_VOUCHER_TOKEN));
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVoucherTokensAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoucherTokenSelectedPageSize(pageSize));
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onDownloadPressed: () => {
    dispatch(saveVoucherTokensAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setVoucherTokenSelectedPageSize(pageSize));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setVoucherTokenSearchText(text));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoucherTokenSelectedOrderBy(orderBy));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setVoucherTokenTappedId(id));
    dispatch(downloadVoucherTokenAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/voucherId/, 'voucher.id')
      .replace(/selectedVoucher/, 'voucher.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearVoucherTokens());
    dispatch(setVoucherTokenSelectedOrderBy(INITIAL_ORDER_BY_PARTNER_ADMIN_VOUCHER_TOKEN));
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerVoucherTokenPage);
