import {
  DOWNLOADING_COUPON_SUBMISSIONS, DOWNLOADING_DELETING_COUPON_SUBMISSION,
  SET_COUPON_SUBMISSION_SELECTED_PAGE_SIZE, SET_COUPON_SUBMISSION_TAPPED_ID,
  SET_COUPON_SUBMISSION_SEARCH_TEXT, SET_COUPON_SUBMISSION_SELECTED_ORDER_BY,
  REPROCESSING_COUPON_SUBMISSION, ADDING_COUPON_SUBMISSION,
  SET_COUPON_SUBMISSION_SELECTED_USER, SET_COUPON_SUBMISSION_SELECTED_SEND_NOTIFICATION,
} from '../action';
import { INITIAL_ORDER_BY_COUPON_SUBMISSIONS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_COUPON_SUBMISSIONS,
  reprocessing: false,
  selectedSendNotification: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_COUPON_SUBMISSIONS:
      return { ...state, downloading: action.status };
    case ADDING_COUPON_SUBMISSION:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_COUPON_SUBMISSION:
      return { ...state, downloadingDeleting: action.status };
    case SET_COUPON_SUBMISSION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_COUPON_SUBMISSION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_COUPON_SUBMISSION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_COUPON_SUBMISSION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case REPROCESSING_COUPON_SUBMISSION:
      return { ...state, reprocessing: action.status };
    case SET_COUPON_SUBMISSION_SELECTED_USER:
      return { ...state, selectedUser: action.value };
    case SET_COUPON_SUBMISSION_SELECTED_SEND_NOTIFICATION:
      return { ...state, selectedSendNotification: action.value };
    default: return state;
  }
};
