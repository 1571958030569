import { INITIAL_ORDER_BY_GIIAS_SEMARANG_SCANNED_CODES } from '../../constant';
import {
  DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODE, DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODES,
  SAVING_GIIAS_SEMARANG_2022_SCANNED_CODES, SET_GIIAS_SEMARANG_SCANNED_CODES_FILTER_STRING,
  SET_GIIAS_SEMARANG_SCANNED_CODES_ORDER_BY, SET_GIIAS_SEMARANG_SCANNED_CODES_PAGE_SIZE,
  SET_GIIAS_SEMARANG_SCANNED_CODES_SEARCH_TEXT, SET_GIIAS_SEMARANG_SCANNED_CODES_TAPPED_ID,
} from '../action';

const initialState = {
  downloading: false,
  downloadingScannedCode: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_GIIAS_SEMARANG_SCANNED_CODES,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODE:
      return { ...state, downloadingScannedCode: action.status };
    case SET_GIIAS_SEMARANG_SCANNED_CODES_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_GIIAS_SEMARANG_SCANNED_CODES_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_GIIAS_SEMARANG_SCANNED_CODES_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_GIIAS_SEMARANG_SCANNED_CODES_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_GIIAS_SEMARANG_SCANNED_CODES_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SAVING_GIIAS_SEMARANG_2022_SCANNED_CODES:
      return { ...state, saving: action.status };
    default: return state;
  }
};
