import {
  addTestDriveEventOrganizer, clearTestDriveEventOrganizers,
  downloadingDeletingTestDriveEventOrganizer,
  setAlertErrorMessage, setFunctionalPageMode, setTestDriveEventOrganizerTappedId,
} from '../simple-action';
import { downloadTestDriveEventOrganizer, deleteTestDriveEventOrganizer } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadTestDriveEventOrganizersAsync from './downloadTestDriveEventOrganizersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingTestDriveEventOrganizer(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEventOrganizer;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteTestDriveEventOrganizer(tappedId, token);
      dispatch(setTestDriveEventOrganizerTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearTestDriveEventOrganizers());
      dispatch(downloadTestDriveEventOrganizersAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadTestDriveEventOrganizer(tappedId, token);
      dispatch(addTestDriveEventOrganizer(result));
    }
  } finally {
    dispatch(downloadingDeletingTestDriveEventOrganizer(false));
  }
};
