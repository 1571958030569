import {
  changingRegistrationVin, setAlertErrorMessage,
} from '../simple-action';
import { changeRegistrationVin } from '../../../helper';
import downloadRegistrationAsync from './downloadRegistrationAsync';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(changingRegistrationVin(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistration;

    await changeRegistrationVin(tappedId, notes, token);

    dispatch(downloadRegistrationAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(changingRegistrationVin(false));
  }
};
