import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Grid, makeStyles, Typography,
} from '@material-ui/core';
import {
  COLOR_LIGHT_GREEN, COLOR_SECONDARY, COLOR_BODY_TEXT_LIGHTER,
  PAYMENT_STATUS_SUCCESS,
} from '../../../constant';
import { DealerDetailShape } from '../../../type';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${COLOR_BODY_TEXT_LIGHTER}`,
    padding: '12px',
    marginTop: 24,
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  activityIndicator: {
    display: 'flex',
    justifyContent: 'center',
    margin: '11px 0px 11px 0px',
  },
  paymentStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 24px 4px 24px',
    border: `2px solid ${COLOR_BODY_TEXT_LIGHTER}`,
    borderRadius: 50,
  },
  successContainer: {
    border: `2px solid ${COLOR_SECONDARY}`,
    backgroundColor: COLOR_LIGHT_GREEN,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 24px 4px 24px',
    borderRadius: 50,
  },
  img: {
    objectFit: 'scale-down',
    width: 60,
    height: 20,
    marginRight: 12,
  },
  dealerNameText: {
    fontWeight: 'bold',
    margin: '0px 0px 4px 0px',
  },
  bookingCodeText: {
    fontWeight: 'bold',
    margin: '0px 0px 4px 0px',
    textAlign: 'center',
  },
  saveButtonWrapper: {
    marginLeft: 10,
  },
  saveButtonLabel: {
    width: '0%',
  },
}));

const logo = require('../../../../../asset/hyundai-logo.png');

const DealerHeader = ({
  downloading, dealerDetail, bookingCode, paymentStatus,
}) => {
  const classes = useStyles();

  const isPaymentStatusSuccess = paymentStatus === PAYMENT_STATUS_SUCCESS;

  return (
    <Grid item sm md>
      <Grid item sm md>
        {downloading ? (
          <div className={classes.activityIndicator}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div className={classes.headerContainer}>
            <div className={classes.row}>
              <div>
                <img alt="" src={logo} className={classes.img} />
              </div>
              <div>
                <Typography variant="h6" className={classes.dealerNameText}>{dealerDetail?.name}</Typography>
                <Typography variant="body1">
                  {`${dealerDetail?.address}, ${dealerDetail?.city}, ${dealerDetail?.province}, ${dealerDetail?.postalCode}`}
                </Typography>
              </div>
            </div>
            <div className={classes.rightContainer}>
              <Typography variant="h6" className={classes.bookingCodeText}>{bookingCode}</Typography>
              {paymentStatus ? (
                <div className={isPaymentStatusSuccess
                  ? classes.successContainer : classes.paymentStatusContainer}
                >
                  <Typography variant="body1">{paymentStatus}</Typography>
                </div>
              ) : <></>}
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default DealerHeader;

DealerHeader.propTypes = {
  downloading: PropTypes.bool,
  bookingCode: PropTypes.string,
  paymentStatus: PropTypes.string,
  dealerDetail: DealerDetailShape,
};

DealerHeader.defaultProps = {
  dealerDetail: {},
  downloading: false,
  bookingCode: '',
  paymentStatus: '',
};
