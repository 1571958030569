import { transformSearchText } from '../../../../../helper';
import { downloadIims2023Transactions } from '../../../helper';
import { downloadingIims2023Transactions, setIims2023Transactions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, iims2023Transactions, uiIims2023Transaction } = getState();
  const { token } = authentication;
  const { meta } = iims2023Transactions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiIims2023Transaction;


  try {
    dispatch(downloadingIims2023Transactions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadIims2023Transactions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setIims2023Transactions(list));
    }
  } finally {
    dispatch(downloadingIims2023Transactions(false));
  }
};
