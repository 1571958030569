import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_GIIAS_SEMARANG_TRANSACTION, MENUID_GIIAS_SEMARANG_TRANSACTION, PAGE_MODE_TABLE,
} from '../../constant';
import { debounceSearch, transformInitialValues, transformUserDropdownData } from '../../helper';
import { downloadUsersAsync } from '../../../../redux/action';
import {
  clearGiiasSemarang2022Transactions, setGiiasSemarang2022TransactionAdvancedFilterString,
  clearUsers, setUserAdvancedFilterDialogSelectedFilterString,
  downloadGiiasSemarang2022TransactionAsync, downloadGiiasSemarang2022TransactionsAsync,
  saveGiiasSemarang2022TransactionsAsync, setGiiasSemarang2022TransactionSearchText,
  setGiiasSemarang2022TransactionSelectedOrderBy, setGiiasSemarang2022TransactionSelectedPageSize,
  setGiiasSemarang2022TransactionTappedId, setUserSearchText, setActiveSideMenuItem,
  setAlertErrorMessage,
} from '../../redux/action';
import GiiasSemarangTransactionPage from './giias-semarang-transaction.presentation';

const getInitialValues = (state) => {
  const { giiasSemarangTransactions, uiFunctionalPage, uiGiiasSemarangTransaction } = state;
  const { data } = giiasSemarangTransactions;
  const { downloadingDeleting, tappedId } = uiGiiasSemarangTransaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiGiiasSemarangTransaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_SEMARANG_TRANSACTION));
    dispatch(setGiiasSemarang2022TransactionAdvancedFilterString(''));
    dispatch(setGiiasSemarang2022TransactionSearchText(''));
    dispatch(clearGiiasSemarang2022Transactions());
    dispatch(setGiiasSemarang2022TransactionSelectedPageSize(20));
    dispatch(setGiiasSemarang2022TransactionSelectedOrderBy(
      INITIAL_ORDER_BY_GIIAS_SEMARANG_TRANSACTION,
    ));
    dispatch(downloadGiiasSemarang2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('user', 'user.id');
    dispatch(setGiiasSemarang2022TransactionAdvancedFilterString(text));
    dispatch(clearGiiasSemarang2022Transactions());
    dispatch(downloadGiiasSemarang2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiiasSemarang2022TransactionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiiasSemarang2022TransactionSelectedPageSize(pageSize));
    dispatch(downloadGiiasSemarang2022TransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveGiiasSemarang2022TransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiiasSemarang2022TransactionSelectedPageSize(pageSize));
    dispatch(clearGiiasSemarang2022Transactions());
    dispatch(downloadGiiasSemarang2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGiiasSemarang2022Transactions());
    dispatch(setGiiasSemarang2022TransactionAdvancedFilterString(''));
    dispatch(setGiiasSemarang2022TransactionSelectedOrderBy(
      INITIAL_ORDER_BY_GIIAS_SEMARANG_TRANSACTION,
    ));
    dispatch(downloadGiiasSemarang2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setGiiasSemarang2022TransactionSearchText(text));
      dispatch(clearGiiasSemarang2022Transactions());
      await dispatch(downloadGiiasSemarang2022TransactionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiiasSemarang2022TransactionSelectedOrderBy(orderBy));
    dispatch(clearGiiasSemarang2022Transactions());
    dispatch(downloadGiiasSemarang2022TransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiiasSemarang2022TransactionTappedId(id));
    dispatch(downloadGiiasSemarang2022TransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GiiasSemarangTransactionPage);
