import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Paper, withStyles, Typography, makeStyles,
} from '@material-ui/core';
import {
  ArgumentAxis, BarSeries, Chart, Title, Tooltip, ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, HoverState } from '@devexpress/dx-react-chart';
import classNames from 'clsx';
import { COLOR_BODY_TEXT_LIGHTER } from '../constant';
import { toCurrency } from '../helper';

const useStyles = makeStyles(() => ({
  caption: {
    paddingTop: 15,
    color: COLOR_BODY_TEXT_LIGHTER,
    textAlign: 'center',
  },
}));

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return (Target) => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const TooltipTitle = setStyle({
  fontWeight: 'bold',
  paddingBottom: 0,
})(Tooltip.Content);

const TooltipBody = setStyle({
  paddingTop: 0,
})(Tooltip.Content);

const SimpleBarChart = ({
  data, loading, argumentField, title, valueField, useCustomTitle,
  valueAxis, argumentAxis, rotated, helperText,
}) => {
  const classes = useStyles();
  const [targetItem, setTargetItem] = useState(null);

  const TooltipContent = () => (
    <div>
      <div>
        <TooltipTitle
          text={data[targetItem.point][argumentField]}
        />
      </div>
      <div>
        <TooltipBody
          text={toCurrency(data[targetItem.point][valueField])}
        />
      </div>
    </div>
  );

  if (loading) {
    return (
      <CircularProgress color="inherit" size={20} />
    );
  }
  return (
    <Paper>
      <Chart data={data} rotated={rotated}>
        {useCustomTitle
          ? (
            <Typography variant="body2" className={classes.caption}>
              {title}
            </Typography>
          )
          : null}
        {argumentAxis ? (<ArgumentAxis />) : null}
        {valueAxis ? (<ValueAxis />) : null}
        <BarSeries
          valueField={valueField}
          argumentField={argumentField}
        />
        {useCustomTitle ? null : (<Title text={title} />)}
        <Animation />
        <EventTracker />
        <HoverState />
        <Tooltip
          targetItem={targetItem}
          onTargetItemChange={(target) => setTargetItem(target)}
          contentComponent={TooltipContent}
        />
        <Animation />
      </Chart>
      {helperText ? (
        <Typography variant="body2" className={classes.caption} style={{ marginTop: 10, fontStyle: 'italic' }}>
          {helperText}
        </Typography>
      ) : null}
    </Paper>
  );
};

export default SimpleBarChart;

SimpleBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  useCustomTitle: PropTypes.bool,
  valueAxis: PropTypes.bool,
  argumentAxis: PropTypes.bool,
  rotated: PropTypes.bool,
  argumentField: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

SimpleBarChart.defaultProps = {
  loading: false,
  useCustomTitle: false,
  rotated: false,
  valueAxis: true,
  argumentAxis: true,
  helperText: '',
};
