import {
  addDMSUserSubmission, downloadingDeletingDMSUserSubmission, setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';
import { downloadDMSUserSubmission } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingDMSUserSubmission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiDMSUserSubmission;

    const DMSUserSubmission = await downloadDMSUserSubmission(tappedId, token);
    dispatch(addDMSUserSubmission(DMSUserSubmission));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingDMSUserSubmission(false));
  }
};
