import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearBranches, clearCompanies, clearNews, clearNewsCategories,
  copyingNewsDeepLink, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText, setCompanySearchText,
  setNewsSelectedAddMediaMenu,
  setNewsCategorySearchText, setNewsSearchText, setNewsSelectedPageSize, setNewsSelectedOrderBy,
  setNewsTappedId, setNewsSelectedCategory, setNewsShortDescriptionLength,
  setNewsSelectedVisibilityStatus, setNotificationDialogVisibility, setNotificationSelectedBranch,
  setNotificationSelectedCompany, setNotificationSelectedCity,
  addEditNewsAsync, downloadDeleteNewsAsync, downloadNewsCategoriesAsync, downloadNewsAsync,
  sendNewsNotificationAsync, setNewsSelectedIsHeadline,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData,
  clearCompaniesMData,
  setCityAdvancedFilterDialogSelectedFilterString,
  setCitySearchText,
  clearCities,
} from '../../redux/action';
import {
  downloadBranchesAsync, downloadCompaniesAsync,
  downloadMDataCompaniesAsync,
  downloadCitiesAsync,
} from '../../../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_NEWS, MENUID_NEWS_MANAGEMENT_NEWS,
  PAGE_MODE_TABLE, RXFIELD_NEWS_CONTENT, RXFIELD_NEWS_IS_HEADLINE, RXFIELD_NEWS_MEDIA,
  RXFIELD_NEWS_SHORT_DESCRIPTION, RXFIELD_NEWS_VISIBILITY_STATUS, RXFORM_NEWS,
} from '../../constant';
import {
  localDateToUtc, transformDropdownData, transformInitialValues, toMoment,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import NewsPage from './news.presentation';

const getInitialValues = (state) => {
  const { news, uiNews, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiNews;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? news.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      category: { label: found.category.name, value: found.category.id },
      publishedDate: toMoment(found.publishedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      notification: {
        title: found.title,
        body: LocalizedString.newsScreen.labelClickForMoreInfo,
        url: found.deepLink,
      },
      author: found.author?.fullName || null,
      isHeadline: found.isHeadline
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
    }) : {
      title: '',
      shortDescription: '',
      publishedDate: null,
      category: { label: '', value: '' },
      visibilityStatus: '',
      sourceName: '',
      sourceUrl: '',
      tags: '',
      isHeadline: null,
    };
  return initVal;
};

const getDiscusId = (state) => {
  const { news, uiNews, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiNews;
  const { pageMode } = uiFunctionalPage;

  return pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? news.data[tappedId].discusId : '';
};

const mapStateToProps = (state) => ({
  categories: transformDropdownData(state.newsCategories.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiNews.addingEditing,
  copying: state.uiNews.copying,
  downloading: state.uiNews.downloading,
  downloadingDeleting: state.uiNews.downloadingDeleting,
  selectedAddMediaMenu: state.uiNews.selectedAddMediaMenu,
  sendingNotification: state.uiNews.sendingNotification,
  loadingCategory: state.uiNewsCategory.downloading,
  sendingNotifications: state.notification.sendingNotifications,
  shortDescriptionLength: state.uiNews.shortDescriptionLength,
  initialValues: getInitialValues(state),
  discusId: getDiscusId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setNewsSelectedAddMediaMenu(menu));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setNewsSelectedCategory(''));
    dispatch(setNewsCategorySearchText(''));
    dispatch(clearNewsCategories());
    dispatch(downloadNewsCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_NEWS_MANAGEMENT_NEWS));
    dispatch(setNewsSearchText(''));
    dispatch(clearNews());
    dispatch(setNewsSelectedPageSize(20));
    dispatch(setNewsSelectedOrderBy(INITIAL_ORDER_BY_NEWS));
    dispatch(downloadNewsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/categoryName/, 'category.name');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearNews());
    dispatch(downloadNewsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setNewsSelectedCategory(''));
    dispatch(reset(RXFORM_NEWS));
  },
  onCancelPressed: () => {
    dispatch(setNewsSelectedCategory(''));
    dispatch(setNewsShortDescriptionLength(0));
    dispatch(setNewsSelectedVisibilityStatus(''));
    dispatch(setNewsSelectedIsHeadline(null));
    dispatch(reset(RXFORM_NEWS));
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setNewsSelectedCategory(option.value));
    } else {
      dispatch(setNewsSelectedCategory(''));
    }
  },
  onChangeCategoryText: async (text) => {
    try {
      dispatch(setNewsCategorySearchText(text));
      dispatch(clearNewsCategories());
      await dispatch(downloadNewsCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeContentText: (text) => {
    dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_CONTENT, text));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadNewsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setNewsSelectedPageSize(pageSize));
    dispatch(downloadNewsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeShortDescriptionText: (text) => {
    dispatch(setNewsShortDescriptionLength(text.length));
    dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_SHORT_DESCRIPTION, text));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteNewsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCopyPressed: (status) => {
    dispatch(copyingNewsDeepLink(!status));
  },
  onCreatePressed: () => {
    dispatch(setNewsTappedId(''));
    dispatch(setNewsShortDescriptionLength(0));
    dispatch(setNewsCategorySearchText(''));
    dispatch(setNewsSelectedIsHeadline(null));
    dispatch(clearNewsCategories());
    dispatch(downloadNewsCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setNewsTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setNewsCategorySearchText(''));
    dispatch(clearNewsCategories());
    dispatch(downloadNewsCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setNewsTappedId(id));
    dispatch(downloadDeleteNewsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onHeadlineOptionSelected: (option) => {
    if (option) {
      dispatch(setNewsSelectedIsHeadline(option));
      dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_IS_HEADLINE, option));
    } else {
      dispatch(setNewsSelectedIsHeadline(''));
      dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_IS_HEADLINE, ''));
    }
  },
  onOpenSendNotificationDialog: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setNotificationSelectedCity(''));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setNewsSelectedPageSize(pageSize));
    dispatch(clearNews());
    dispatch(downloadNewsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearNews());
    dispatch(setNewsSelectedOrderBy(INITIAL_ORDER_BY_NEWS));
    dispatch(downloadNewsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, content, publishedDate, media, sourceName, sourceUrl, shortDescription,
    tags,
  }) => {
    try {
      const publishedDateUtc = localDateToUtc(publishedDate);
      await dispatch(addEditNewsAsync(title, content, publishedDateUtc, media, sourceName,
        sourceUrl, shortDescription, tags));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setNewsSearchText(text));
      dispatch(clearNews());
      await dispatch(downloadNewsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationsPressed: () => {
    dispatch(setNotificationDialogVisibility(true));
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(setBranchSearchText(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setNotificationSelectedCompany(''));
    dispatch(clearCompanies());
    dispatch(setCompanySearchText(''));
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSendToMePressed: () => {
    dispatch(sendNewsNotificationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setNewsSelectedOrderBy(orderBy));
    dispatch(clearNews());
    dispatch(downloadNewsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, content, publishedDate, media, sourceName, sourceUrl, shortDescription,
    tags,
  }) => {
    const publishedDateUtc = localDateToUtc(publishedDate);
    await dispatch(addEditNewsAsync(title, content, publishedDateUtc, media, sourceName,
      sourceUrl, shortDescription, tags));
  },
  onViewPressed: (id) => {
    dispatch(setNewsTappedId(id));
    dispatch(downloadDeleteNewsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setNewsSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_VISIBILITY_STATUS, option));
    } else {
      dispatch(setNewsSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_NEWS, RXFIELD_NEWS_VISIBILITY_STATUS, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
