import { change } from 'redux-form';
import {
  downloadPromoCode, deletePromoCode, transformVouchers, getUserType,
} from '../../../helper';
import {
  addPromoCode, downloadingDeletingPromoCode,
  setPromoCodeTappedId,
  setFunctionalPageMode, clearPromoCodes,
  setAlertErrorMessage,

  setPromoCodeSelectedMerchant,
  setPromoCodeSelectedVouchers,
  setPromoCodeSelectedReward,
  setPromoCodeSelectedVoucherBypassMaxPurchase,
  setPromoCodeSelectedVoucherBypassAchievement,
  setPromoCodeTableDataVouchers,
  setPromoCodeUserTypes,
} from '../simple-action';
import {
  PAGE_MODE_TABLE, RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER, approval,
} from '../../../constant';
import downloadPromoCodesAsync from './downloadPromoCodesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPromoCode(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPromoCode;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deletePromoCode(tappedId, token);
      dispatch(setPromoCodeTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearPromoCodes());
      dispatch(downloadPromoCodesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadPromoCode(tappedId, token);

      dispatch(addPromoCode(result));

      dispatch(setPromoCodeSelectedMerchant(
        { label: result.merchantName || '', value: result.merchantId || '' },
      ));
      dispatch(setPromoCodeSelectedReward(
        { label: result.rewardType || '', value: result.rewardType || '' },
      ));
      dispatch(setPromoCodeUserTypes(
        getUserType(result),
      ));
      dispatch(setPromoCodeSelectedVoucherBypassMaxPurchase(
        {
          label: result.bypassMaxPurchaseValidation
            ? approval[0].label : approval[1].label,
          value: result.bypassMaxPurchaseValidation
            ? approval[0].value : approval[1].value,
        },
      ));
      dispatch(setPromoCodeSelectedVoucherBypassAchievement(
        {
          label: result.bypassAchievementValidation
            ? approval[0].label : approval[1].label,
          value: result.bypassAchievementValidation
            ? approval[0].value : approval[1].value,
        },
      ));
      dispatch(setPromoCodeSelectedVouchers(null));

      const vouchers = transformVouchers(result.vouchers);
      dispatch(setPromoCodeTableDataVouchers(vouchers));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER, vouchers));
    }
  } finally {
    dispatch(downloadingDeletingPromoCode(false));
  }
};
