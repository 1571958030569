import { downloadingPromoCodeBatches, setPromoCodeBatch } from '../simple-action';
import { downloadPromoCodeBatches, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, promoCodeBatches, uiPromoCodeBatch } = getState();
  const { token } = authentication;
  const { meta } = promoCodeBatches;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiPromoCodeBatch;

  try {
    dispatch(downloadingPromoCodeBatches(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPromoCodeBatches(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setPromoCodeBatch(list));
    }
  } finally {
    dispatch(downloadingPromoCodeBatches(false));
  }
};
