import {
  REST_URL_REGISTRATIONS, REST_URL_VIEW_REGISTRATION, REST_URL_APPROVE_REJECT_REGISTRATION,
  REST_URL_CAR_OWNERSHIP_REWARDS, REST_URL_VIEW_CAR_OWNERSHIP_REWARD,
  REST_URL_MEMBERSHIP_REWARDS, REST_URL_VIEW_MEMBERSHIP_REWARD, REST_URL_ADD_REGISTRATION_NOTES,
  REST_URL_CAR_OWNERSHIPS, REST_URL_VIEW_CAR_OWNERSHIP, REST_URL_REFRESH_VEHICLE_INFO,
  REST_URL_CHANGE_REGISTRATION_VIN, REST_URL_SAVE_FILE,
  REST_URL_REGISTRATION_ATTEMPTS, REST_URL_VIEW_REGISTRATION_ATTEMPT, REST_URL_DMS_USER_SUBMISSIONS,
  REST_URL_VIEW_DMS_USER_SUBMISSION, REST_URL_RETRY_DMS_USER_SUBMISSION,
  SUBMISSION_STATUS_ERROR, SUBMISSION_STATUS_CANCELLED, REST_URL_APPROVE_RESERVATION,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendPutRequest, transformQueryResult, sendGetFileRequest,
  sendPostRequest,
} from '../../helper';

export * from '../../helper';

export const downloadRegistrations = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_REGISTRATIONS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadRegistration = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_REGISTRATION.replace(/\{id\}/, id), token);
  return response;
};

export const approveRejectRegistration = async (
  id, action, notes, warrantyStartDate, vehiclePrice, token,
) => {
  const body = {
    id, action, notes, warrantyStartDate, vehiclePrice,
  };
  await sendPutRequest(REST_URL_APPROVE_REJECT_REGISTRATION, body, token);
};

export const addRegistrationNotes = async (id, notes, token) => {
  const body = { id, notes };
  await sendPutRequest(REST_URL_ADD_REGISTRATION_NOTES, body, token);
};

export const refreshVehicleInfo = async (registrationId, token) => {
  const body = { registrationId };
  const response = await sendPutRequest(REST_URL_REFRESH_VEHICLE_INFO, body, token);
  return response;
};

export const changeRegistrationVin = async (registrationId, newVin, token) => {
  const body = { registrationId, newVin };
  await sendPutRequest(REST_URL_CHANGE_REGISTRATION_VIN, body, token);
};

export const downloadCarOwnershipRewards = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_CAR_OWNERSHIP_REWARDS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCarOwnershipReward = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CAR_OWNERSHIP_REWARD.replace(/\{id\}/, id), token);
  return response;
};

export const downloadMembershipRewards = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_MEMBERSHIP_REWARDS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadMembershipReward = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_MEMBERSHIP_REWARD.replace(/\{id\}/, id), token);
  return response;
};

export const downloadRegistrationAttempts = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_REGISTRATION_ATTEMPTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadRegistrationAttempt = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_REGISTRATION_ATTEMPT.replace(/\{id\}/, id), token);
  return response;
};

export const saveCarRegistrationAttempts = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token) => {
  const downloadUrl = REST_URL_REGISTRATION_ATTEMPTS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadCarOwnerships = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_CAR_OWNERSHIPS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCarOwnership = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CAR_OWNERSHIP.replace(/\{id\}/, id), token);
  return response;
};

export const saveCarRegistrations = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_REGISTRATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadDMSUserSubmissions = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_DMS_USER_SUBMISSIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const responseDataWithHideContextMenu = response.data.map((x) => ({
    ...x,
    hideContextMenu: (x.submissionStatus !== SUBMISSION_STATUS_ERROR
    && x.submissionStatus !== SUBMISSION_STATUS_CANCELLED),
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadDMSUserSubmission = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DMS_USER_SUBMISSION.replace(/\{id\}/, id), token);
  return response;
};

export const retryDMSUserSubmission = async (id, token) => {
  await sendPostRequest(REST_URL_RETRY_DMS_USER_SUBMISSION.replace(/{id}/, id), '', token);
};

export const approveReservation = async (
  body, token,
) => {
  await sendPostRequest(REST_URL_APPROVE_RESERVATION, body, token);
};
