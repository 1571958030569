import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_MODEL_DESCRIPTION, RXFIELD_MODEL_NAME,
  RXFORM_MODEL, RXSTATE_MODELS, RXSTATE_MODEL_PAGE,
  RXFIELD_MODEL_IMAGE, RXFIELD_MODEL_BROCHURE_URL,
  RXFIELD_MODEL_DISCONTINUED, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_SWITCH, status, approval,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { rxformValidateModel } from '../../validation';
import { FormInitialValueShape } from '../../type';

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode, onImageSelected, initialImage,
  onDiscontinuedOptionSelected, initialValueDiscontinued,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_MODEL_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.modelPage.placeholderName}
          label={LocalizedString.modelPage.labelName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_MODEL_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.modelPage.placeholderImage}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          onImageSelected={onImageSelected}
          onBlur={(e) => e.preventDefault()}
          defaultValue={initialImage}
          useFullWidthImage
          useAvatarWithoutCropper
          fullImageWidth="100%"
          useCropper={false}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_MODEL_BROCHURE_URL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.modelPage.placeholderBrochureUrl}
          label={LocalizedString.modelPage.labelBrochureUrl}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_MODEL_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.modelPage.placeholderDescription}
          label={LocalizedString.modelPage.labelDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_MODEL_DISCONTINUED}
          component={renderReduxFormSimpleDropdownField}
          placeholder={LocalizedString.modelPage.placeholderDiscontinued}
          label={LocalizedString.modelPage.placeholderDiscontinued}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={approval}
          value={initialValueDiscontinued}
          onOptionSelected={onDiscontinuedOptionSelected}
        />
      </Grid>
    </Grid>
  </Grid>
);

const ModelPage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage,
  onChangePageSize, onCreatePressed, onEditPressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed, onDeletePressed, onConfirmDeletePressed, pageMode,
  onImageSelected, initialValues, onDiscontinuedOptionSelected,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_MODELS}
    uiPage={RXSTATE_MODEL_PAGE}
    tableColumns={[
      {
        title: LocalizedString.modelPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.modelPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.modelPage.labelDiscontinued, field: 'discontinued', sorting: !downloading },
      { title: LocalizedString.modelPage.labelBrochureUrl, field: 'brochureUrl', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.modelPage.placeholderStatus,
        field: 'status',
        type: FILTER_TYPE_DROPDOWN,
        data: status,
      },
      {
        title: LocalizedString.modelPage.placeholderDiscontinued,
        field: RXFIELD_MODEL_DISCONTINUED,
        type: FILTER_TYPE_SWITCH,
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCreatePressed={onCreatePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onDeletePressed={onDeletePressed}
    createNewButtonCaption={LocalizedString.modelPage.buttonCaptionCreateNewModel}
    deleteButtonCaption={LocalizedString.modelPage.buttonCaptionDeleteModel}
    editButtonCaption={LocalizedString.modelPage.buttonCaptionEditModel}
    title={LocalizedString.modelPage.title}
    useFullWidth
  >
    {renderDialogContent(
      addingEditing, downloadingDeleting, pageMode, onImageSelected, initialValues.image,
      onDiscontinuedOptionSelected, initialValues.discontinued,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_MODEL,
  validate: rxformValidateModel,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ModelPage);
ModelPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDiscontinuedOptionSelected: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onImageSelected: PropTypes.func.isRequired,
};
