import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_CATALOG = 'DOWNLOADING_CATALOG';
export const downloadingCatalog = makeActionCreator(DOWNLOADING_CATALOG, 'status');

export const SET_CATALOG = 'SET_CATALOG';
export const setCatalog = makeActionCreator(SET_CATALOG, 'data');

export const ADD_CAR = 'ADD_CAR';
export const addCar = makeActionCreator(ADD_CAR, 'data');

export const CLEAR_CATALOG = 'CLEAR_CATALOG';
export const clearCatalog = makeActionCreator(CLEAR_CATALOG);

export const ADDING_EDITING_CAR = 'ADDING_EDITING_CAR';
export const addingEditingCar = makeActionCreator(ADDING_EDITING_CAR, 'status');

export const DOWNLOADING_DELETING_CAR = 'DOWNLOADING_DELETING_CAR';
export const downloadingDeletingCar = makeActionCreator(DOWNLOADING_DELETING_CAR, 'status');


export const SET_CATALOG_SELECTED_PAGE_SIZE = 'SET_CATALOG_SELECTED_PAGE_SIZE';
export const setCatalogSelectedPageSize = makeActionCreator(SET_CATALOG_SELECTED_PAGE_SIZE, 'size');

export const SET_CATALOG_SELECTED_ORDER_BY = 'SET_CATALOG_SELECTED_ORDER_BY';
export const setCatalogSelectedOrderBy = makeActionCreator(SET_CATALOG_SELECTED_ORDER_BY, 'order');

export const SET_CATALOG_TAPPED_ID = 'SET_CATALOG_TAPPED_ID';
export const setCatalogTappedId = makeActionCreator(SET_CATALOG_TAPPED_ID, 'id');

export const SET_CATALOG_SEARCH_TEXT = 'SET_CATALOG_SEARCH_TEXT';
export const setCatalogSearchText = makeActionCreator(SET_CATALOG_SEARCH_TEXT, 'text');

export const SET_CATALOG_SELECTED_MODEL_ID = 'SET_CATALOG_SELECTED_MODEL_ID';
export const setCatalogSelectedModelId = makeActionCreator(SET_CATALOG_SELECTED_MODEL_ID, 'id');

export const SET_CATALOG_SELECTED_IS_DEFAULT = 'SET_CATALOG_SELECTED_IS_DEFAULT';
export const setCatalogSelectedIsDefault = makeActionCreator(SET_CATALOG_SELECTED_IS_DEFAULT, 'option');

export const SET_CATALOG_SELECTED_ADD_MEDIA_MENU = 'SET_CATALOG_SELECTED_ADD_MEDIA_MENU';
export const setCatalogSelectedAddMediaMenu = makeActionCreator(SET_CATALOG_SELECTED_ADD_MEDIA_MENU, 'text');

export const SET_CATALOG_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CATALOG_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCatalogAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CATALOG_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

// -------------------------------------------------------------------------------------------------

export const DOWNLOADING_MODELS = 'DOWNLOADING_MODELS';
export const downloadingModels = makeActionCreator(DOWNLOADING_MODELS, 'status');

export const SET_MODELS = 'SET_MODELS';
export const setModels = makeActionCreator(SET_MODELS, 'data');

export const ADD_MODEL = 'ADD_MODEL';
export const addModel = makeActionCreator(ADD_MODEL, 'data');

export const CLEAR_MODELS = 'CLEAR_MODELS';
export const clearModels = makeActionCreator(CLEAR_MODELS);

export const ADDING_EDITING_MODEL = 'ADDING_EDITING_MODEL';
export const addingEditingModel = makeActionCreator(ADDING_EDITING_MODEL, 'status');

export const DOWNLOADING_DELETING_MODEL = 'DOWNLOADING_DELETING_MODEL';
export const downloadingDeletingModel = makeActionCreator(DOWNLOADING_DELETING_MODEL, 'status');


export const SET_MODEL_SELECTED_PAGE_SIZE = 'SET_MODEL_SELECTED_PAGE_SIZE';
export const setModelSelectedPageSize = makeActionCreator(SET_MODEL_SELECTED_PAGE_SIZE, 'size');

export const SET_MODEL_SELECTED_ORDER_BY = 'SET_MODEL_SELECTED_ORDER_BY';
export const setModelSelectedOrderBy = makeActionCreator(SET_MODEL_SELECTED_ORDER_BY, 'order');

export const SET_MODEL_TAPPED_ID = 'SET_MODEL_TAPPED_ID';
export const setModelTappedId = makeActionCreator(SET_MODEL_TAPPED_ID, 'id');

export const SET_MODEL_SEARCH_TEXT = 'SET_MODEL_SEARCH_TEXT';
export const setModelSearchText = makeActionCreator(SET_MODEL_SEARCH_TEXT, 'text');

export const SET_MODEL_SELECTED_DISCONTINUED = 'SET_MODEL_SELECTED_DISCONTINUED';
export const setModelSelectedDiscontinued = makeActionCreator(SET_MODEL_SELECTED_DISCONTINUED, 'option');

export const SET_MODEL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_MODEL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setModelAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_MODEL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
