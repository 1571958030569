import { downloadTransactionPaid } from '../../../helper';
import { downloadingTransactionPaid, setTransactionPaid } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingTransactionPaid(true));
    const bookingCreated = await downloadTransactionPaid(token, startDate, endDate);

    dispatch(setTransactionPaid(bookingCreated));
  } finally {
    dispatch(downloadingTransactionPaid(false));
  }
};
