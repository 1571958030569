import { downloadDealer } from '../../../helper';
import { addDealer, downloadingDeletingDealer, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiDealer } = getState();

  const { token } = authentication;
  const { tappedId } = uiDealer;

  try {
    dispatch(downloadingDeletingDealer(true));

    const wasteCollection = await downloadDealer(
      tappedId,
      token,
    );

    dispatch(addDealer(wasteCollection));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingDealer(false));
  }
};
