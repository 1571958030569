import {
  addTestDriveVoucherReward, downloadingTestDriveVoucherReward,
} from '../simple-action';
import { downloadTestDriveVoucherReward } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingTestDriveVoucherReward(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoucherReward;

    const VoucherReward = await downloadTestDriveVoucherReward(tappedId, token);
    dispatch(addTestDriveVoucherReward(VoucherReward));
  } finally {
    dispatch(downloadingTestDriveVoucherReward(false));
  }
};
