import { INITIAL_ORDER_BY_GIIAS_2022_PRIZE_REDEMPTION } from '../../constant';
import {
  DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTION, DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTIONS,
  SET_GIIAS_2022_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING,
  SET_GIIAS_2022_PRIZE_REDEMPTION_SEARCH_TEXT, SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_ORDER_BY,
  SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, SET_GIIAS_2022_PRIZE_REDEMPTION_TAPPED_ID,
  SAVING_GIIAS_2022_PRIZE_REDEMPTIONS,
} from '../action/simple-action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_GIIAS_2022_PRIZE_REDEMPTION,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_GIIAS_2022_PRIZE_REDEMPTION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_GIIAS_2022_PRIZE_REDEMPTION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_GIIAS_2022_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SAVING_GIIAS_2022_PRIZE_REDEMPTIONS:
      return { ...state, saving: action.status };
    default: return state;
  }
};
