import { saveInvoice, transformSearchText } from '../../../helper';
import {
  setInvoiceSendingPdf,
} from '../simple-action';


export default (saveAsCsv = false) => async (dispatch, getState) => {
  try {
    dispatch(setInvoiceSendingPdf(true));
    const { token } = getState().authentication;
    const {
      tappedId, searchBarText, orderBy, filterString,
    } = getState().uiInvoice;

    const invoice = await saveInvoice(
      token, tappedId, saveAsCsv, 1, 20, orderBy,
      transformSearchText(searchBarText), filterString,
    );

    invoice.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = saveAsCsv ? 'invoice.csv' : 'invoice.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(setInvoiceSendingPdf(false));
  }
};
