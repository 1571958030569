import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_GIIAS_SEMARANG_SCANNED_CODES, MENUID_GIIAS_SEMARANG_SCANNED_CODE, PAGE_MODE_TABLE } from '../../constant';
import { transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setActiveSideMenuItem,
  setGiiasSemarangScannedCodesSearchText,
  setGiiasSemarangScannedCodesFilterString,
  clearGiiasSemarangScannedCodes,
  setGiiasSemarangScannedCodesPageSize,
  setGiiasSemarangScannedCodesOrderBy,
  downloadGiiasSemarangScannedCodesAsync,
  setGiiasSemarangScannedCodesTappedId,
  downloadDeleteGiiasSemarangScannedCodeAsync,
  saveGiiasSemarang2022ScannedCodesAsync,
} from '../../redux/action';
import { downloadUsersAsync, setAlertErrorMessage } from '../../../../redux/action';
import GiiasSemarangScannedCode from './giias-semarang-scanned-code.presentation';

const getInitialValues = (state) => {
  const {
    giiasSemarangScannedCodes, uiFunctionalPage, uiGiiasSemarangScannedCode,
  } = state;
  const { data } = giiasSemarangScannedCodes;
  const { downloadingScannedCode, tappedId } = uiGiiasSemarangScannedCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingScannedCode
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    user: found.user
      ? { fullName: found.user.fullName, id: found.user.id }
      : { fullName: '', id: '' },
  };

  return result;
};

const mapStateToProps = (state) => ({
  scannedCodes: state.giiasSemarangScannedCodes.data,
  downloading: state.uiGiiasSemarangScannedCode.downloading,
  downloadingScannedCode: state.uiGiiasSemarangScannedCode.downloadingScannedCode,
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_SEMARANG_SCANNED_CODE));
    dispatch(setGiiasSemarangScannedCodesSearchText(''));
    dispatch(setGiiasSemarangScannedCodesFilterString(''));
    dispatch(clearGiiasSemarangScannedCodes());
    dispatch(setGiiasSemarangScannedCodesPageSize(20));
    dispatch(setGiiasSemarangScannedCodesOrderBy(INITIAL_ORDER_BY_GIIAS_SEMARANG_SCANNED_CODES));

    dispatch(downloadGiiasSemarangScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiiasSemarangScannedCodesPageSize(pageSize));
    dispatch(clearGiiasSemarangScannedCodes());

    dispatch(downloadGiiasSemarangScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiiasSemarangScannedCodesOrderBy(orderBy));
    dispatch(clearGiiasSemarangScannedCodes());
    dispatch(downloadGiiasSemarangScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiiasSemarangScannedCodesAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiiasSemarangScannedCodesPageSize(pageSize));
    dispatch(downloadGiiasSemarangScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setGiiasSemarangScannedCodesSearchText(text));
      dispatch(clearGiiasSemarangScannedCodes());
      await dispatch(downloadGiiasSemarangScannedCodesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/scanCodeUsername/, 'user.id');

    dispatch(setGiiasSemarangScannedCodesFilterString(text));
    dispatch(clearGiiasSemarangScannedCodes());
    dispatch(downloadGiiasSemarangScannedCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setGiiasSemarangScannedCodesFilterString(''));
    dispatch(clearGiiasSemarangScannedCodes());
    dispatch(setGiiasSemarangScannedCodesOrderBy(INITIAL_ORDER_BY_GIIAS_SEMARANG_SCANNED_CODES));
    dispatch(downloadGiiasSemarangScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiiasSemarangScannedCodesTappedId(id));
    dispatch(downloadDeleteGiiasSemarangScannedCodeAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDownloadPressed: () => {
    dispatch(saveGiiasSemarang2022ScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GiiasSemarangScannedCode);
