import {
  addEvent, clearEvents, downloadingDeletingEvent, setAlertErrorMessage,
  setFunctionalPageMode, setEventTappedId, setEventUrl1ExtensionBrowser,
  setEventUrl2ExtensionBrowser, setEventSurveyUrlExtensionBrowser,
  setEventSelectedCityId,
} from '../simple-action';
import { downloadEvent, deleteEvent } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadEventsAsync from './downloadEventsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEvent(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEvent;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteEvent(tappedId, token);
      dispatch(setEventTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEvents());
      dispatch(downloadEventsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadEvent(tappedId, token);
      dispatch(addEvent(result));
      dispatch(setEventUrl1ExtensionBrowser(result.url1OpenOnExternalBrowser));
      dispatch(setEventUrl2ExtensionBrowser(result.url2OpenOnExternalBrowser));
      dispatch(setEventSurveyUrlExtensionBrowser(result.surveyUrlOpenOnExternalBrowser));
      dispatch(setEventSelectedCityId(result.city.id));
    }
  } finally {
    dispatch(downloadingDeletingEvent(false));
  }
};
