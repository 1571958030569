import { downloadPointAchievement } from '../../../helper';
import { downloadingPointAchievement, setPointAchievement } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingPointAchievement(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPointAchievement;

    const result = await downloadPointAchievement(tappedId, token);

    dispatch(setPointAchievement(result));
  } finally {
    dispatch(downloadingPointAchievement(false));
  }
};
