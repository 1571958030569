import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, OWNERSHIP_STATUS_INACTIVE,
  OWNERSHIP_STATUS, RXFIELD_CAR_OWNERSHIP_COLOR, RXFIELD_CAR_OWNERSHIP_ENGINE_NO,
  RXFIELD_CAR_OWNERSHIP_INACTIVATED_ON, RXFIELD_CAR_OWNERSHIP_LICENSE_PLATE,
  RXFIELD_CAR_OWNERSHIP_MODEL, RXFIELD_CAR_OWNERSHIP_MODEL_YEAR,
  RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STATUS, RXFIELD_CAR_OWNERSHIP_REGISTRATION_APPROVED_ON,
  RXFIELD_CAR_OWNERSHIP_REGISTRATION_ID, RXFIELD_CAR_OWNERSHIP_TRANSMISSION,
  RXFIELD_CAR_OWNERSHIP_TRIM, RXFIELD_CAR_OWNERSHIP_USER_ID, RXFIELD_CAR_OWNERSHIP_USER_NAME,
  RXFIELD_CAR_OWNERSHIP_VIN, RXFIELD_CAR_OWNERSHIP_WARRANTY_END_DATE,
  RXFIELD_CAR_OWNERSHIP_WARRANTY_START_DATE, RXFIELD_CAR_OWNERSHIP_WARRANTY_NOTE,
  RXFORM_CAR_OWNERSHIP, RXSTATE_CAR_OWNERSHIPS, RXSTATE_CAR_OWNERSHIP_PAGE,
  RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_NUMBER,
  RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_NAME,
  RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_VALID_UNTIL,
  RXFIELD_CAR_OWNERSHIP_OWNERSHIP_PKB_FIRST_REMINDER_SENT_ON,
  RXFIELD_CAR_OWNERSHIP_OWNERSHIP_PKB_SECOND_REMINDER_SENT_ON,
  RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_IMAGE,
  RXFIELD_CAR_OWNERSHIP_COLOR_CODE,
  RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE,
  RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_CODE,
  RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_SUB,
  RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_SUB_CODE,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedTextField,
  renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const renderDialogContent = (initialValues) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_VIN}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderVin}
          label={LocalizedString.carOwnershipPage.placeholderVin}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_ENGINE_NO}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderEngineNo}
          label={LocalizedString.carOwnershipPage.placeholderEngineNo}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_MODEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderModel}
          label={LocalizedString.carOwnershipPage.placeholderModel}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_TRIM}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderTrim}
          label={LocalizedString.carOwnershipPage.placeholderTrim}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_COLOR}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderColor}
          label={LocalizedString.carOwnershipPage.placeholderColor}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_COLOR_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderColorCode}
          label={LocalizedString.carOwnershipPage.labelColorCode}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_MODEL_YEAR}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderModelYear}
          label={LocalizedString.carOwnershipPage.placeholderModelYear}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_TRANSMISSION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderTransmission}
          label={LocalizedString.carOwnershipPage.placeholderTransmission}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_LICENSE_PLATE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderLicensePlate}
          label={LocalizedString.carOwnershipPage.placeholderLicensePlate}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_WARRANTY_START_DATE}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carOwnershipPage.placeholderWarrantyStartDate}
          label={LocalizedString.carOwnershipPage.placeholderWarrantyStartDate}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_WARRANTY_END_DATE}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carOwnershipPage.placeholderWarrantyEndDate}
          label={LocalizedString.carOwnershipPage.placeholderWarrantyEndDate}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_WARRANTY_NOTE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderWarrantyNote}
          label={LocalizedString.carOwnershipPage.placeholderWarrantyNote}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationPage.placeholderBlueLinkType}
          label={LocalizedString.registrationPage.placeholderBlueLinkType}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationPage.placeholderBlueLinkTypeCode}
          label={LocalizedString.registrationPage.placeholderBlueLinkTypeCode}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_SUB}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationPage.placeholderBlueLinkTypeSub}
          label={LocalizedString.registrationPage.placeholderBlueLinkTypeSub}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_SUB_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationPage.placeholderBlueLinkTypeSubCode}
          label={LocalizedString.registrationPage.placeholderBlueLinkTypeSubCode}
          disabled
          multiline
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderUserID}
          label={LocalizedString.carOwnershipPage.placeholderUserID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_USER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderUserName}
          label={LocalizedString.carOwnershipPage.placeholderUserName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_REGISTRATION_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderOwnershipRegistrationId}
          label={LocalizedString.carOwnershipPage.placeholderOwnershipRegistrationId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_REGISTRATION_APPROVED_ON}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carOwnershipPage.placeholderOwnershipApprovedOn}
          label={LocalizedString.carOwnershipPage.placeholderOwnershipApprovedOn}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STATUS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderOwnershipStatus}
          label={LocalizedString.carOwnershipPage.placeholderOwnershipStatus}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_INACTIVATED_ON}
          component={renderReduxFormDateTimePickerField}
          placeholder={LocalizedString.carOwnershipPage.placeholderInactivatedOn}
          label={LocalizedString.carOwnershipPage.placeholderInactivatedOn}
          disabled
          hidden={initialValues.ownershipStatus !== OWNERSHIP_STATUS_INACTIVE}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_NUMBER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderStnkNumber}
          label={LocalizedString.carOwnershipPage.placeholderStnkNumber}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderStnkName}
          label={LocalizedString.carOwnershipPage.placeholderStnkName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_VALID_UNTIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderStnkValidUntil}
          label={LocalizedString.carOwnershipPage.placeholderStnkValidUntil}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_OWNERSHIP_PKB_FIRST_REMINDER_SENT_ON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderPkbFirstReminderSentOn}
          label={LocalizedString.carOwnershipPage.placeholderPkbFirstReminderSentOn}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_OWNERSHIP_PKB_SECOND_REMINDER_SENT_ON}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carOwnershipPage.placeholderPkbSecondReminderSentOn}
          label={LocalizedString.carOwnershipPage.placeholderPkbSecondReminderSentOn}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.carOwnershipPage.placeholderStnkImage}
          defaultValue={initialValues.stnkImage}
          useFullWidthImage
          useAvatarWithoutCropper
          fullImageWidth="100%"
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const CarOwnershipPage = ({
  initialValues, users,
  downloading, loadingUsers,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onChangePage, onChangePageSize, onChangeUserText, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_CAR_OWNERSHIPS}
    uiPage={RXSTATE_CAR_OWNERSHIP_PAGE}
    filterColumns={[
      {
        title: LocalizedString.carOwnershipPage.labelApprovedOn,
        field: 'approvedOn',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.carOwnershipPage.labelInactivatedOn,
        field: RXFIELD_CAR_OWNERSHIP_INACTIVATED_ON,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.carOwnershipPage.labelVin,
        field: RXFIELD_CAR_OWNERSHIP_VIN,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.carOwnershipPage.labelLicensePlate,
        field: RXFIELD_CAR_OWNERSHIP_LICENSE_PLATE,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.carOwnershipPage.labelUser,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.carOwnershipPage.labelOwnershipStatus,
        field: RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: OWNERSHIP_STATUS,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.carOwnershipPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.carOwnershipPage.labelUserName, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.carOwnershipPage.labelVin, field: 'vin', sorting: !downloading },
      { title: LocalizedString.carOwnershipPage.labelLicensePlate, field: 'licensePlate', sorting: !downloading },
      { title: LocalizedString.carOwnershipPage.labelTrim, field: 'trim', sorting: !downloading },
      { title: LocalizedString.carOwnershipPage.labelModelYear, field: 'modelYear', sorting: !downloading },
      { title: LocalizedString.carOwnershipPage.labelOwnershipStatus, field: 'ownershipStatus', sorting: !downloading },
      {
        title: LocalizedString.carOwnershipPage.labelApprovedOn,
        field: 'registration.approvedOn',
        sorting: !downloading,
        render: ({ registration }) => (registration.approvedOn
          ? toMoment(registration.approvedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.carOwnershipPage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CAR_OWNERSHIP,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CarOwnershipPage);

CarOwnershipPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
