import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CLOCK_IN_OUT_STATUS_TREND_EARLY, CLOCK_IN_OUT_STATUS_TREND_LATE, CLOCK_IN_OUT_STATUS_TREND_ONTIME,
  DATE_MONTH_FORMAT,
} from '../../constant';
import {
  getChartPercentage, sortAsc, toKFormat, toMoment,
} from '../../helper';
import {
  clearSummaryTotalUsers, clearAttendanceSummaryClockInLocationTrend,
  clearAttendanceSummaryTodayClockInLocationStatus, clearAttendanceSummaryTotalClockInToday,
  clearAttendanceSummaryTodayClockInStatus, clearAttendanceSummaryClockInStatusTrend,
  clearAttendanceSummaryClockOutLocationTrend, clearAttendanceSummaryTodayClockOutLocationStatus,
  clearAttendanceSummaryTotalClockOutToday, clearAttendanceSummaryTodayClockOutStatus,
  clearAttendanceSummaryClockOutStatusTrend, setAlertErrorMessage,
  setAttendanceClockInLocationTrendTooltipTarget, setAttendanceClockInStatusTrendTooltipTarget,
  setAttendanceClockOutLocationTrendTooltipTarget, setAttendanceClockOutStatusTrendTooltipTarget,
  downloadClockInLocationTrendAsync, downloadTodayClockInLocationStatusAsync,
  downloadTodayClockInStatusAsync, downloadClockInStatusTrendAsync, downloadTotalClockInTodayAsync,
  downloadTodayClockOutLocationStatusAsync, downloadClockOutLocationTrendAsync,
  downloadTodayClockOutStatusAsync, downloadClockOutStatusTrendAsync,
  downloadTotalClockOutTodayAsync,
} from '../../redux/action';
import { downloadSummaryTotalUsersAsync } from '../../../../redux/action';
import AttendanceSummaryPage from './attendance-summary.presentation';

const ARGUMENT_FIELD = 'name';
const VALUE_FIELD = 'count';

const getTrends = (state) => {
  if (Object.values(state).length < 1) {
    return [];
  }
  const formatGroupData = Object.values(state)
    .sort((a, b) => sortAsc(a.groupName, b.groupName))
    .map((item) => ({
      date: toMoment(item.groupName).format(DATE_MONTH_FORMAT),
      [item.name]: item.count,
    }));
  let currentDateIteration;
  const trends = formatGroupData.reduce((previous, current) => {
    if (!isEmpty(previous) && currentDateIteration === current.date) {
      const currentDateIterationIndex = previous.findIndex((item) => item.date === current.date);
      const newTrendItem = {
        ...previous[currentDateIterationIndex],
        ...current,
      };
      const newTrend = { ...previous, [currentDateIterationIndex]: newTrendItem };
      const newTrendArray = Object.values(newTrend);
      return newTrendArray;
    } if (!isEmpty(previous) && currentDateIteration !== current.date) {
      currentDateIteration = current.date;
      const newTrendArray = [...previous, current];
      return newTrendArray;
    }
    currentDateIteration = current.date;
    return [current];
  }, []);

  const trendsPadded = trends.map((item) => {
    const isEarlyKeyExist = Object.keys(item).find((key) => key
    === CLOCK_IN_OUT_STATUS_TREND_EARLY);
    const isOnTimeKeyExist = Object.keys(item).find((key) => key
    === CLOCK_IN_OUT_STATUS_TREND_ONTIME);
    const isLateKeyExist = Object.keys(item).find((key) => key === CLOCK_IN_OUT_STATUS_TREND_LATE);
    let newItem;
    if (isEarlyKeyExist && isOnTimeKeyExist && isLateKeyExist) {
      return item;
    }
    if (!isEarlyKeyExist) {
      newItem = { ...item, [CLOCK_IN_OUT_STATUS_TREND_EARLY]: 0 };
    }
    if (!isOnTimeKeyExist) {
      newItem = { ...item, ...newItem, [CLOCK_IN_OUT_STATUS_TREND_ONTIME]: 0 };
    }
    if (!isLateKeyExist) {
      newItem = { ...item, ...newItem, [CLOCK_IN_OUT_STATUS_TREND_LATE]: 0 };
    }
    return newItem;
  });

  return trendsPadded;
};

const mapStateToProps = (state) => ({
  downloadingTotalUsers: state.uiSummary.downloadingTotalUsers,
  downloadingTotalClockInToday: state.uiAttendanceSummary.downloadingTotalClockInToday,
  downloadingTotalClockOutToday: state.uiAttendanceSummary.downloadingTotalClockOutToday,
  downloadingTodayClockInStatus: state.uiAttendanceSummary.downloadingTodayClockInStatus,
  downloadingTodayClockOutStatus: state.uiAttendanceSummary.downloadingTodayClockOutStatus,
  downloadingTodayClockInLocationStatus:
  state.uiAttendanceSummary.downloadingTodayClockInLocationStatus,
  downloadingTodayClockOutLocationStatus:
  state.uiAttendanceSummary.downloadingTodayClockOutLocationStatus,
  downloadingClockInStatusTrend: state.uiAttendanceSummary.downloadingClockInStatusTrend,
  downloadingClockOutStatusTrend: state.uiAttendanceSummary.downloadingClockOutStatusTrend,
  downloadingClockInLocationTrend: state.uiAttendanceSummary.downloadingClockInLocationTrend,
  downloadingClockOutLocationTrend: state.uiAttendanceSummary.downloadingClockOutLocationTrend,

  totalUsers: Object.keys(state.totalUsers).length > 0 ? toKFormat(state.totalUsers.count) : 0,
  totalClockInToday: state.totalClockInToday.count,
  totalClockOutToday: state.totalClockOutToday.count,
  todayClockInStatus: getChartPercentage(state.todayClockInStatus, ARGUMENT_FIELD, VALUE_FIELD),
  todayClockOutStatus: getChartPercentage(state.todayClockOutStatus, ARGUMENT_FIELD, VALUE_FIELD),
  todayClockInLocationStatus: getChartPercentage(state.todayClockInLocationStatus,
    ARGUMENT_FIELD, VALUE_FIELD),
  todayClockOutLocationStatus: getChartPercentage(state.todayClockOutLocationStatus,
    ARGUMENT_FIELD, VALUE_FIELD),
  clockInStatusTrend: getTrends(state.clockInStatusTrend),
  clockOutStatusTrend: getTrends(state.clockOutStatusTrend),
  clockInStatusTrendTargetItem: state.uiAttendanceSummary.clockInStatusTrendTargetItem,
  clockOutStatusTrendTargetItem: state.uiAttendanceSummary.clockOutStatusTrendTargetItem,
  clockInLocationTrend: getTrends(state.clockInLocationTrend),
  clockOutLocationTrend: getTrends(state.clockOutLocationTrend),
  clockInLocationTrendTargetItem: state.uiAttendanceSummary.clockInLocationTrendTargetItem,
  clockOutLocationTrendTargetItem: state.uiAttendanceSummary.clockOutLocationTrendTargetItem,
});
const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(clearSummaryTotalUsers());
    dispatch(clearAttendanceSummaryTotalClockInToday());
    dispatch(clearAttendanceSummaryTotalClockOutToday());
    dispatch(clearAttendanceSummaryTodayClockInStatus());
    dispatch(clearAttendanceSummaryTodayClockOutStatus());
    dispatch(clearAttendanceSummaryTodayClockInLocationStatus());
    dispatch(clearAttendanceSummaryTodayClockOutLocationStatus());
    dispatch(clearAttendanceSummaryClockInStatusTrend());
    dispatch(clearAttendanceSummaryClockOutStatusTrend());
    dispatch(clearAttendanceSummaryClockInLocationTrend());
    dispatch(clearAttendanceSummaryClockOutLocationTrend());
    dispatch(downloadSummaryTotalUsersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTotalClockInTodayAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTotalClockOutTodayAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockInStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockOutStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockInLocationStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadTodayClockOutLocationStatusAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockInStatusTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockOutStatusTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockInLocationTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadClockOutLocationTrendAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onClockInStatusTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockInStatusTrendTooltipTarget(targetItem));
  },
  onClockOutStatusTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockOutStatusTrendTooltipTarget(targetItem));
  },
  onClockInLocationTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockInLocationTrendTooltipTarget(targetItem));
  },
  onClockOutLocationTrendTargetItemChange: (targetItem) => {
    dispatch(setAttendanceClockOutLocationTrendTooltipTarget(targetItem));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceSummaryPage);
