import { DOWNLOADING_POINT_ACHIEVEMENT, DOWNLOADING_POINT_BALANCE, SET_POINT_ACHIEVEMENT_TAPPED_ID } from '../action';

const initialState = {
  downloadingPointAchievement: false,
  downloadingPointBalance: false,
  tappedId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_POINT_ACHIEVEMENT:
      return { ...state, downloadingPointAchievement: action.status };
    case DOWNLOADING_POINT_BALANCE:
      return { ...state, downloadingPointBalance: action.status };
    case SET_POINT_ACHIEVEMENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    default: return state;
  }
};
