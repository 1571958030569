import { SET_CAR_OWNERSHIPS, ADD_CAR_OWNERSHIP, CLEAR_CAR_OWNERSHIPS } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAR_OWNERSHIPS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_CAR_OWNERSHIP: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_CAR_OWNERSHIPS:
      return initialState;
    default:
      return state;
  }
};
