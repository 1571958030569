import {
  downloadingDeletingProfile, addProfile, setProfileSelectedGender,
  setProfileSelectedRole, setProfileSelectedCompany,
} from '../simple-action';
import { downloadProfile } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingProfile(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiProfile;
    const { tappedId: pointAchievementTappedId } = getState().uiPointAchievement;
    const { tappedId: couponAchievementTappedId } = getState().uiCouponAchievement;
    const id = tappedId || pointAchievementTappedId || couponAchievementTappedId;

    const result = await downloadProfile(id, token);

    dispatch(setProfileSelectedGender(result.gender || ''));
    dispatch(setProfileSelectedRole(result.roles || ''));
    dispatch(setProfileSelectedCompany(result.userCompany
      ? result.userCompany.id : ''));
    dispatch(addProfile(result));
  } finally {
    dispatch(downloadingDeletingProfile(false));
  }
};
