import {
  downloadingDeletingAuthRegistrationAttempt,
  addAuthRegistrationAttempt,
} from '../simple-action';
import { downloadAuthRegistrationAttempt } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiAuthRegistrationAttempt } = getState();

  const { token } = authentication;
  const { tappedId } = uiAuthRegistrationAttempt;

  try {
    dispatch(downloadingDeletingAuthRegistrationAttempt(true));

    const registrationAttempt = await downloadAuthRegistrationAttempt(
      tappedId,
      token,
    );

    dispatch(addAuthRegistrationAttempt(registrationAttempt));
  } finally {
    dispatch(downloadingDeletingAuthRegistrationAttempt(false));
  }
};
