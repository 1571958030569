import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import { toMoment, toCurrency } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT,
  RXSTATE_COMPLETED_PROFILE_PAGE,
  RXSTATE_COMPLETED_PROFILES,
  RXFORM_COMPLETED_PROFILE,
  RXFIELD_COMPLETED_PROFILE_USER_ID,
  RXFIELD_COMPLETED_PROFILE_USER_NAME,
  RXFIELD_COMPLETED_PROFILE_POINT,
} from '../../constant';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_COMPLETED_PROFILE_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.completedProfilePage.placeholderUserID}
            label={LocalizedString.completedProfilePage.placeholderUserID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COMPLETED_PROFILE_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.completedProfilePage.placeholderUserName}
            label={LocalizedString.completedProfilePage.placeholderUserName}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_COMPLETED_PROFILE_POINT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.completedProfilePage.placeholderPoint}
            label={LocalizedString.completedProfilePage.placeholderPoint}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const CompletedProfilePage = ({
  onAppear, downloading, downloadingDeleting, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText,
}) => (
  <FunctionalPage
    data={RXSTATE_COMPLETED_PROFILES}
    uiPage={RXSTATE_COMPLETED_PROFILE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.completedProfilePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.completedProfilePage.labelUserName, field: 'profile.fullName', sorting: !downloading },
      {
        title: LocalizedString.completedProfilePage.labelPoint,
        field: 'pointAmount',
        sorting: !downloading,
        render: ({ pointAmount }) => (pointAmount
          ? toCurrency(pointAmount) : toCurrency(0)),
      },
      {
        title: LocalizedString.completedProfilePage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.completedProfilePage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.completedProfilePage.labelUserName,
        field: 'registeredUser',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.completedProfilePage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(downloadingDeleting, initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_COMPLETED_PROFILE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompletedProfilePage);

CompletedProfilePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
};
