import { downloadIims2023Surveys, transformSearchText } from '../../../helper';
import { downloadingIims2023Surveys, setIims2023Surveys } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, iims2023Surveys, uiIims2023Survey } = getState();

  const { token } = authentication;
  const { meta } = iims2023Surveys;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiIims2023Survey;

  try {
    dispatch(downloadingIims2023Surveys(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const surveys = await downloadIims2023Surveys(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    dispatch(setIims2023Surveys(surveys));
  } finally {
    dispatch(downloadingIims2023Surveys(false));
  }
};
