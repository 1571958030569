import { downloadCarServiceReservationSummary } from '../../../helper';
import { downloadingCarServiceReservationSummary, setCarServiceReservationSummary } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    const { authentication, uiCarServiceReservation } = getState();
    const { token } = authentication;
    const { tappedId } = uiCarServiceReservation;

    dispatch(downloadingCarServiceReservationSummary(true));

    const result = await downloadCarServiceReservationSummary(tappedId, token);
    dispatch(setCarServiceReservationSummary(result));
  } finally {
    dispatch(downloadingCarServiceReservationSummary(false));
  }
};
