import {
  DOWNLOADING_SCANNED_CODES, DOWNLOADING_DELETING_SCANNED_CODE,
  SET_SCANNED_CODE_SELECTED_PAGE_SIZE, SET_SCANNED_CODE_TAPPED_ID,
  SET_SCANNED_CODE_SEARCH_TEXT, SET_SCANNED_CODE_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_SCANNED_CODES } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_SCANNED_CODES,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SCANNED_CODES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_SCANNED_CODE:
      return { ...state, downloadingDeleting: action.status };
    case SET_SCANNED_CODE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_SCANNED_CODE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_SCANNED_CODE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_SCANNED_CODE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
