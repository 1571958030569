import {
  downloadingAttendanceSummaryTodayClockInLocationStatus,
  setAttendanceSummaryTodayClockInLocationStatus,
} from '../simple-action';
import { downloadAttendanceSummaryTodayClockInLocationStatus } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTodayClockInLocationStatus(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryTodayClockInLocationStatus(token);

    dispatch(setAttendanceSummaryTodayClockInLocationStatus(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTodayClockInLocationStatus(false));
  }
};
