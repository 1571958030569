import {
  REST_URL_PARTNER_VOUCHER_TOKENS,
  REST_URL_PARTNER_VOUCHER_TOKEN,
  REST_URL_SAVE_FILE, REST_URL_PARTNER_VOUCHER_TOKEN_BY_CODE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  REST_URL_PARTNER_CLAIM_VOUCHER_TOKEN_BY_CODE,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendPutRequest,
  transformQueryResult, sendGetFileRequest,
} from '../../helper';

export * from '../../helper';

export const downloadVoucherTokens = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PARTNER_VOUCHER_TOKENS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_PARTNER_VOUCHER_TOKENS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const dataWithHideContextMenuField = response.data.map((item) => ({
    ...item,
    hideContextMenu: item.isRevoked || item.isClaimed,
  }));
  const newResponse = { ...response, data: dataWithHideContextMenuField };
  return transformQueryResult(newResponse);
};

export const downloadVoucherToken = async (id, token) => {
  const response = await sendGetRequest(REST_URL_PARTNER_VOUCHER_TOKEN.replace(/\{id\}/, id), token);
  return response;
};

export const saveVoucherTokens = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_PARTNER_VOUCHER_TOKENS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadVoucherTokenByCode = async (code, token) => {
  const response = await sendGetRequest(
    REST_URL_PARTNER_VOUCHER_TOKEN_BY_CODE.replace(/\{code\}/, code), token,
  );
  const newResponse = {
    ...response,
    voucher: {
      ...response.voucher,
      image: response.voucher.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${response.voucher.image}` : '',
    },
  };
  return newResponse;
};

export const claimVoucherTokenByCode = async (code, notes, merchantId, token) => {
  const body = { code, notes, merchantId };
  const response = await sendPutRequest(
    REST_URL_PARTNER_CLAIM_VOUCHER_TOKEN_BY_CODE, body, token,
  );
  const newResponse = {
    ...response,
    voucher: {
      ...response.voucher,
      image: response.voucher.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${response.voucher.image}` : '',
    },
  };
  return newResponse;
};
