import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_WASTE_COLLECTIONS, MENUID_E_WASTE_WASTE_COLLECTION, PAGE_MODE_TABLE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData, debounceSearch, transformUserDropdownData,
} from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage, downloadUsersAsync,
  clearUsers, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
} from '../../../../redux/action';
import WasteCollectionPage from './waste-collection.presentation';
import {
  clearWasteCollections, downloadWasteCollectionAsync, downloadWasteCollectionsAsync,
  saveWasteCollectionsAsync,
  setWasteCollectionsAdvancedFilterString,
  setWasteCollectionSearchText, setWasteCollectionSelectedOrderBy,
  setWasteCollectionSelectedPageSize,
  setWasteCollectionsTappedId,
} from '../../redux/action';
import {
  clearDealers, downloadDealersAsync, setDealerAdvancedFilterDialogSelectedFilterString,
  setDealerSearchText,
} from '../../../car-service/redux/action';

const getInitialValues = (state) => {
  const { wasteCollections, uiFunctionalPage, uiWasteCollection } = state;
  const { data } = wasteCollections;
  const { downloadingWasteCollection, tappedId } = uiWasteCollection;
  const { pageMode } = uiFunctionalPage;
  const imageBaseUrl = `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingWasteCollection ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    image: found.image
      ? imageBaseUrl.concat(found.image) : null,
  }) : {};
  return result;
};

const searchDealerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloadingDeleting: state.uiWasteCollection.downloadingDeleting,
  downloading: state.uiWasteCollection.downloading,
  dealers: transformDropdownData(state.dealers.data),
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingDealers: state.uiDealer.downloading,
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_E_WASTE_WASTE_COLLECTION));
    dispatch(setWasteCollectionsAdvancedFilterString(''));
    dispatch(setWasteCollectionSearchText(''));
    dispatch(clearWasteCollections());
    dispatch(setWasteCollectionSelectedPageSize(20));
    dispatch(
      setWasteCollectionSelectedOrderBy(INITIAL_ORDER_BY_WASTE_COLLECTIONS),
    );
    dispatch(downloadWasteCollectionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setWasteCollectionSelectedOrderBy(orderBy));
    dispatch(clearWasteCollections());
    dispatch(downloadWasteCollectionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setWasteCollectionsTappedId(id));
    dispatch(downloadWasteCollectionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setWasteCollectionSearchText(text));
    dispatch(clearWasteCollections());
    dispatch(downloadWasteCollectionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWasteCollectionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWasteCollectionSelectedPageSize(pageSize));
    dispatch(downloadWasteCollectionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setWasteCollectionSelectedPageSize(pageSize));
    dispatch(clearWasteCollections());
    dispatch(downloadWasteCollectionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearWasteCollections());
    dispatch(setWasteCollectionsAdvancedFilterString(''));
    dispatch(
      setWasteCollectionSelectedOrderBy(INITIAL_ORDER_BY_WASTE_COLLECTIONS),
    );
    dispatch(downloadWasteCollectionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('dealer', 'dealer.id')
      .replace('user', 'profile.id');

    dispatch(setWasteCollectionsAdvancedFilterString(text));
    dispatch(clearWasteCollections());
    dispatch(downloadWasteCollectionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDealerSearchText(''));
    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeDealerText: async (text) => {
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDealerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDealerDebounce(dispatch);
    }
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveWasteCollectionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WasteCollectionPage);
