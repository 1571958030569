import { downloadPointPurchaseRequests, transformSearchText } from '../../../helper';
import { downloadingPointPurchaseRequests, setPointPurchaseRequests } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, pointPurchaseRequests, uiPointPurchaseRequest } = getState();
  const { token } = authentication;
  const { meta, data } = pointPurchaseRequests;
  const { selectedPageSize, searchBarText, orderBy } = uiPointPurchaseRequest;
  const { filterString } = getState().uiFunctionalPage;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingPointPurchaseRequests(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadPointPurchaseRequests(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setPointPurchaseRequests(list));
    }
  } finally {
    dispatch(downloadingPointPurchaseRequests(false));
  }
};
