import {
  SET_DIALOG_VISIBILITY, SET_FUNCTIONAL_PAGE_TAPPED_ID, SET_FUNCTIONAL_PAGE_MODE,
  SET_ADVANCED_FILTER_DIALOG_VISIBILITY, SET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  ADD_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM, CLEAR_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM,
  DOWNLOADING_MY_CONFIG_ITEMS,
  SET_FUNCTIONAL_PAGE_TAPPED_DATA,
} from '../action';
import { PAGE_MODE_TABLE } from '../../constant';

const initialState = {
  advancedFilterVisibility: false,
  visibility: false,
  title: '',
  dialogType: '',
  tappedId: '',
  pageMode: PAGE_MODE_TABLE,
  filterString: '',
  advancedFilterForm: {},
  downloadingMyConfigItems: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DIALOG_VISIBILITY:
      return {
        ...state, visibility: action.status, dialogType: action.dialogType, title: action.title,
      };
    case SET_FUNCTIONAL_PAGE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FUNCTIONAL_PAGE_TAPPED_DATA:
      return { ...state, tappedData: action };
    case SET_FUNCTIONAL_PAGE_MODE:
      return { ...state, pageMode: action.mode };
    case SET_ADVANCED_FILTER_DIALOG_VISIBILITY:
      return { ...state, advancedFilterVisibility: action.status };
    case SET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ADD_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM:
      return {
        ...state,
        advancedFilterForm: { ...state.advancedFilterForm, [action.field]: action.value },
      };
    case CLEAR_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM:
      return { ...state, advancedFilterForm: {} };
    case DOWNLOADING_MY_CONFIG_ITEMS:
      return { ...state, downloadingMyConfigItems: action.status };
    default: return state;
  }
};
