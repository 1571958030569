import { reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  addingEditingTestVehicle, clearTestVehicles, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addTestVehicle, editTestVehicle } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_TEST_DRIVE_TEST_VEHICLE } from '../../../constant';
import downloadTestVehiclesAsync from './downloadTestVehiclesAsync';

const getValue = (selectedOption, found) => {
  if (isEmpty(selectedOption)) return found;
  return selectedOption.value;
};

export default (dailyQuota) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingTestVehicle(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedEvent, selectedModel,
    } = getState().uiTestVehicle;

    if (tappedId) {
      const { data } = getState().testVehicles;
      const found = data[tappedId];

      const editEvent = getValue(selectedEvent, found.event.id);
      const editModel = getValue(selectedModel, found.modelId);

      await editTestVehicle(tappedId, editEvent, editModel, dailyQuota, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addTestVehicle(selectedEvent.value, selectedModel.value, dailyQuota, token);
    }

    dispatch(reset(RXFORM_TEST_DRIVE_TEST_VEHICLE));
    dispatch(clearTestVehicles());
    dispatch(downloadTestVehiclesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingTestVehicle(false));
  }
};
