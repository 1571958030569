import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid,
} from '@material-ui/core';
import { FunctionalPage } from '../../component';
import
{
  RXFIELD_JAW_2023_SPK_TIME, RXFIELD_JAW_2023_SPK_USERNAME,
  RXSTATE_JAW_2023_SPKS, RXSTATE_JAW_2023_SPK_PAGE,
  FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, FILTER_TYPE_DROPDOWN,
  RXFORM_JAW_2023_SPK, RXFIELD_JAW_2023_SPK_CHALLANGE_CODE_LABEL,
  RXFIELD_JAW_2023_SPK_CHALLANGE_USER_NAME,
  RXFIELD_JAW_2023_CHALLANGE_CREATED_DATE,
  RXFIELD_JAW_2023_SPK_CHALLANGE_USER_ID,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../../../type';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_JAW_2023_SPK_CHALLANGE_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.spkPage.placeholderChallangeUserId}
            label={LocalizedString.spkPage.placeholderChallangeUserId}
            disabled
          />
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_JAW_2023_SPK_CHALLANGE_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.spkPage.placeholderChallangeUsername}
            label={LocalizedString.spkPage.placeholderChallangeUsername}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_JAW_2023_SPK_CHALLANGE_CODE_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.spkPage.placeholderChallangeCodeLabel}
            label={LocalizedString.spkPage.placeholderChallangeCodeLabel}
            disabled
          />
        </Grid>

        <Grid item>

          <Field
            name={RXFIELD_JAW_2023_CHALLANGE_CREATED_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.spkPage.placeholderChallangeScanTime}
            label={LocalizedString.spkPage.placeholderChallangeScanTime}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const Jaw2023Spk = ({
  onAppear, downloading, onRefresh, onSortPressed, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed, onChangePage, onChangePageSize,
  loadingUsers, onSearchBarTextChanged, users, onChangeUserText, onViewPressed,
  onDownloadPressed,
}) => (
  <FunctionalPage
    onAppear={onAppear}
    data={RXSTATE_JAW_2023_SPKS}
    uiPage={RXSTATE_JAW_2023_SPK_PAGE}
    tableColumns={[
      {
        title: LocalizedString.spkPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.spkPage.placeholderUsername, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.spkPage.voucherTitle, field: 'voucherTitle', sorting: !downloading },
      { title: LocalizedString.spkPage.salesId, field: 'salesId', sorting: !downloading },
      { title: LocalizedString.spkPage.salesName, field: 'salesName', sorting: !downloading },
      { title: LocalizedString.spkPage.labelDate, field: 'scanTime', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.spkPage.placeholderScanTime,
        field: RXFIELD_JAW_2023_SPK_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    title={LocalizedString.spkPage.title}
    useFullWidth
    disableDelete
    disableCreate
    disableEdit
    onRefresh={onRefresh}
    onSortPressed={onSortPressed}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onViewPressed={onViewPressed}
    onDownloadPressed={onDownloadPressed}
    enableSave
  >
    {
      renderDialogContent()
    }
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_JAW_2023_SPK,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Jaw2023Spk);

Jaw2023Spk.propTypes = {
  onAppear: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
