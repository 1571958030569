import moment from 'moment';
import { rxFormValidateRequiredFields } from '../../validation';
import { RXFIELD_DASHBOARD_SERVICE_PROMO_START_DATE, RXFIELD_DASHBOARD_SERVICE_PROMO_END_DATE } from './constant';
import GlobalLocalizedString from '../../localization';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateServicePromo = (values) => {
  const errors = rxFormValidateRequiredFields(values, []);

  const startDate = values[RXFIELD_DASHBOARD_SERVICE_PROMO_START_DATE];
  const endDate = values[RXFIELD_DASHBOARD_SERVICE_PROMO_END_DATE];

  if (startDate && endDate && moment(startDate).isAfter(moment(endDate))) {
    errors[RXFIELD_DASHBOARD_SERVICE_PROMO_END_DATE] = GlobalLocalizedString.common
      .errMsgStartEndDate;
  }

  return errors;
};
