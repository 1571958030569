import { addCoupon, downloadingDeletingCoupon } from '../simple-action';
import { downloadCoupon } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCoupon(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCoupon;

    const coupon = await downloadCoupon(tappedId, token);
    dispatch(addCoupon(coupon));
  } finally {
    dispatch(downloadingDeletingCoupon(false));
  }
};
