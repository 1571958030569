import {
  downloadDailyCheckins,
  transformSearchText,
} from '../../../helper';
import {
  downloadingDailyCheckins,
  setDailyCheckins,
} from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication,
    dailyCheckins,
    uiDailyCheckin,
  } = getState();
  const { token } = authentication;
  const { meta } = dailyCheckins;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiDailyCheckin;

  try {
    dispatch(downloadingDailyCheckins(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadDailyCheckins(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setDailyCheckins(list));
    }
  } finally {
    dispatch(downloadingDailyCheckins(false));
  }
};
