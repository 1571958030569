import { savingGiias2022PrizeRedemptions } from '../simple-action';
import { saveGiias2022PrizeRedemptions, transformSearchText } from '../../../helper';

import '../../../../../component/functional-page/download-file.css';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { searchBarText, orderBy, filterString } = getState().uiGiias2022PrizeRedemption;

  try {
    dispatch(savingGiias2022PrizeRedemptions(true));

    const response = await saveGiias2022PrizeRedemptions(
      1,
      20,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'giias-2022-prize-redemptions.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(savingGiias2022PrizeRedemptions(false));
  }
};
