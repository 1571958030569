import { downloadPointBalance } from '../../../helper';
import { downloadingPointBalance, setPointBalance } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingPointBalance(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPointAchievement;

    const result = await downloadPointBalance(tappedId, token);

    dispatch(setPointBalance(result));
  } finally {
    dispatch(downloadingPointBalance(false));
  }
};
