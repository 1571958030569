import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, InputAdornment, Typography, makeStyles,
} from '@material-ui/core';
import { Email, Person, Smartphone } from '@material-ui/icons';
import { FunctionalPage, SectionTitle, SendNotificationDialog } from '../../component';
import {
  genders, COLOR_BACKGROUND, COLOR_DISABLED_ROW, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW, PICKER_MODE_DATE,
  RXFIELD_PROFILE_BRANCH, RXFIELD_PROFILE_COMPANY_NAME,
  RXFIELD_PROFILE_DATE_OF_BIRTH,
  RXFIELD_PROFILE_EMAIL, RXFIELD_PROFILE_FULLNAME,
  RXFIELD_PROFILE_GENDER, RXFIELD_PROFILE_PHONE,
  RXFIELD_PROFILE_PROFILE_PICTURE, RXFIELD_PROFILE_ROLES,
  RXFIELD_PROFILE_STATUS, RXFORM_PROFILE, RXSTATE_PROFILES,
  RXSTATE_PROFILE_PAGE, STATUS_DISABLED, STATUS_ENABLED, status,
  RXFIELD_PROFILE_USER_CODE, RXFIELD_PROFILE_TAGS, COLOR_SECONDARY,
  RXFIELD_PROFILE_HOME_ADDRESS_ID, RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_NAME,
  RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_PHONE, RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_1,
  RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_2, RXFIELD_PROFILE_HOME_ADDRESS_REGION,
  RXFIELD_PROFILE_HOME_ADDRESS_POSTAL_CODE,
  RXFIELD_PROFILE_WORK_ADDRESS_ID, RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_NAME,
  RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_PHONE, RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_1,
  RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_2, RXFIELD_PROFILE_WORK_ADDRESS_REGION,
  RXFIELD_PROFILE_WORK_ADDRESS_POSTAL_CODE, RXFIELD_PROFILE_COMPANY,
  PAGE_MODE_EDIT, RXFIELD_PROFILE_PASSWORD, RXFIELD_PROFILE_RE_PASSWORD,
  RXFIELD_PROFILE_SHELL_MEMBERSHIP, FILTER_TYPE_SWITCH, RXFIELD_PROFILE_CITY,
  FILTER_TYPE_MULTIPLE_VALUES, USER_TIERS, RXFIELD_CREATED_DATE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_PROFILE_MERCHANT_TAGS,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormDateTimePickerField, renderReduxFormImageInputField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateProfile } from '../../validation';

const useStyles = makeStyles(() => ({
  heading: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    margin: '0px 0px 20px 10px',
  },
}));

const renderDialogContent = (
  defaultBranchValue, defaultGenderValue, defaultProfilePictureValue,
  defaultWorkingHourTimeZoneValue, branches, addingEditing, loadingBranch,
  onBranchOptionSelected, onChangeBranchText,
  pageMode, classes, downloadingDeleting, companies, loadingCompanies, onChangeCompanyText,
  onCompanyOptionSelected, defaultCompanyValue, downloadingRoles, roles,
  defaultRoleValue, onChangeRoleText,
  onRoleOptionSelected, onGenderOptionSelected,
) => (
  <div>
    <Field
      name={RXFIELD_PROFILE_PROFILE_PICTURE}
      component={renderReduxFormImageInputField}
      label={LocalizedString.profileScreen.placeholderProfilePicture}
      defaultValue={defaultProfilePictureValue}
      disabled
      hidden={pageMode === PAGE_MODE_TABLE}
    />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderFullName}
            label={LocalizedString.profileScreen.placeholderFullName}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Person />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderEmail}
            label={LocalizedString.profileScreen.placeholderEmail}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Email />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderPhone}
            label={LocalizedString.profileScreen.placeholderPhone}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Smartphone />
                </InputAdornment>
              ),
            }}
            required
            hidden={pageMode !== PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_PASSWORD}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderPassword}
            label={LocalizedString.profileScreen.placeholderPassword}
            disabled={addingEditing}
            secureTextEntry
            required
            hidden={pageMode !== PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_RE_PASSWORD}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderRePassword}
            label={LocalizedString.profileScreen.placeholderRePassword}
            disabled={addingEditing}
            secureTextEntry
            required
            hidden={pageMode !== PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_USER_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderUserCode}
            label={LocalizedString.profileScreen.placeholderUserCode}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_COMPANY}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.profileScreen.placeholderCompany}
            label={LocalizedString.profileScreen.placeholderCompany}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={companies}
            value={defaultCompanyValue}
            loading={loadingCompanies}
            onChangeText={onChangeCompanyText}
            onOptionSelected={onCompanyOptionSelected}
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SHELL_MEMBERSHIP}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderShellMembershipId}
            label={LocalizedString.profileScreen.placeholderShellMembershipId}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_MERCHANT_TAGS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileScreen.placeholderMerchantTags}
            label={LocalizedString.profileScreen.placeholderMerchantTags}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_DATE_OF_BIRTH}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.profileScreen.placeholderDateOfBirth}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_GENDER}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.profileScreen.placeholderGender}
            label={LocalizedString.profileScreen.placeholderGender}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={genders}
            value={defaultGenderValue}
            required
            onOptionSelected={onGenderOptionSelected}
          />
        </Grid>
        {pageMode === PAGE_MODE_TABLE && (
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_COMPANY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileScreen.placeholderCompany}
              label={LocalizedString.profileScreen.placeholderCompany}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={companies}
              value={defaultCompanyValue}
              loading={loadingCompanies}
              onChangeText={onChangeCompanyText}
              onOptionSelected={onCompanyOptionSelected}
            />
          </Grid>
        )}
        {pageMode !== PAGE_MODE_TABLE && (
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderPhone}
              label={LocalizedString.profileScreen.placeholderPhone}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Smartphone />
                  </InputAdornment>
                ),
              }}
              required
            />
          </Grid>
        )}
        {pageMode === PAGE_MODE_VIEW || pageMode === PAGE_MODE_EDIT ? (
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_ROLES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderRole}
              label={LocalizedString.profileScreen.placeholderRole}
              multiline
              disabled
            />
          </Grid>
        ) : (
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_ROLES}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.profileScreen.placeholderRole}
              label={LocalizedString.profileScreen.placeholderRole}
              disabled={pageMode === PAGE_MODE_EDIT || addingEditing || downloadingDeleting}
              data={roles}
              value={defaultRoleValue}
              loading={downloadingRoles}
              onChangeText={onChangeRoleText}
              onOptionSelected={onRoleOptionSelected}
              required
            />
          </Grid>
        )}
        {pageMode !== PAGE_MODE_TABLE && (
          <Grid item>
            <Field
              name={RXFIELD_PROFILE_TAGS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.profileScreen.placeholderTags}
              label={LocalizedString.profileScreen.placeholderTags}
              disabled
            />
          </Grid>
        )}
      </Grid>
    </Grid>

    {pageMode !== PAGE_MODE_TABLE
      && (
        <>
          <SectionTitle title={LocalizedString.profileScreen.labelAddress} />

          <Grid container spacing={3}>
            <Grid item sm md>
              <Typography variant="subtitle1" className={classes.heading}>
                {LocalizedString.profileScreen.labelHomeAddress}
              </Typography>

              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_HOME_ADDRESS_ID}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressId}
                  label={LocalizedString.profileScreen.placeholderAddressId}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_NAME}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressContactName}
                  label={LocalizedString.profileScreen.placeholderAddressContactName}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_HOME_ADDRESS_CONTACT_PHONE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressContactPhone}
                  label={LocalizedString.profileScreen.placeholderAddressContactPhone}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_1}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine1}
                  label={LocalizedString.profileScreen.placeholderAddressAddressLine1}
                  disabled
                  multiline
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_HOME_ADDRESS_ADDRESS_LINE_2}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine2}
                  label={LocalizedString.profileScreen.placeholderAddressAddressLine2}
                  disabled
                  multiline
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_HOME_ADDRESS_REGION}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressRegion}
                  label={LocalizedString.profileScreen.placeholderAddressRegion}
                  disabled
                  multiline
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_HOME_ADDRESS_POSTAL_CODE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressPostalCode}
                  label={LocalizedString.profileScreen.placeholderAddressPostalCode}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item sm md>
              <Typography variant="subtitle1" className={classes.heading}>
                {LocalizedString.profileScreen.labelWorkAddress}
              </Typography>

              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_WORK_ADDRESS_ID}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressId}
                  label={LocalizedString.profileScreen.placeholderAddressId}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_NAME}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressContactName}
                  label={LocalizedString.profileScreen.placeholderAddressContactName}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_WORK_ADDRESS_CONTACT_PHONE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressContactPhone}
                  label={LocalizedString.profileScreen.placeholderAddressContactPhone}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_1}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine1}
                  label={LocalizedString.profileScreen.placeholderAddressAddressLine1}
                  disabled
                  multiline
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_WORK_ADDRESS_ADDRESS_LINE_2}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressAddressLine2}
                  label={LocalizedString.profileScreen.placeholderAddressAddressLine2}
                  disabled
                  multiline
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_WORK_ADDRESS_REGION}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressRegion}
                  label={LocalizedString.profileScreen.placeholderAddressRegion}
                  disabled
                  multiline
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_PROFILE_WORK_ADDRESS_POSTAL_CODE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.profileScreen.placeholderAddressPostalCode}
                  label={LocalizedString.profileScreen.placeholderAddressPostalCode}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
  </div>
);

const ProfilePage = ({
  initialValues, branches, companies,
  addingEditing, downloading, enablingDisabling, loadingBranch, loadingCompanies,
  sendingNotification, handleSubmit, onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed, onBranchOptionSelected, onCancelAdvancedFilterPressed,
  onCancelPressed, onChangeBranchText, onChangeCompanyText, onChangePage,
  onChangePageSize, onCompanyOptionSelected, onConfirmEnableDisablePressed,
  onDownloadPressed, onEditPressed, onEnableDisablePressed, onOpenSendNotificationDialog,
  onRefresh, onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged,
  onSendNotificationPressed, onSortPressed, onViewPressed, currentProfileStatus,
  pageMode, tappedId, downloadingRoles, roles, onChangeRoleText, onRoleOptionSelected,
  onCreatePressed, onSubmitPressed, onGenderOptionSelected,
  downloadingDeleting, cities, loadingCity, onChangeCityText,
}) => {
  const classes = useStyles();

  return (
    <div>
      <SendNotificationDialog
        loading={sendingNotification}
        useFilterSection={pageMode === PAGE_MODE_TABLE}
        onSendPressed={onSendNotificationPressed}
        recipient={initialValues.fullName}
        sendingToLabel={LocalizedString.profileScreen.labelSendNotificationTo}
        additionalUrlPlaceholder={LocalizedString.common.labelAdditionalUrlPlaceholders}
      />

      <FunctionalPage
        data={RXSTATE_PROFILES}
        uiPage={RXSTATE_PROFILE_PAGE}
        moreMenus={[
          {
            caption: LocalizedString.common.buttonCaptionEnable,
            disabled: currentProfileStatus === STATUS_ENABLED || enablingDisabling,
            onPress: () => onEnableDisablePressed(tappedId,
              LocalizedString.common.msgEnableConfirmation),
          },
          {
            caption: LocalizedString.common.buttonCaptionDisable,
            disabled: currentProfileStatus === STATUS_DISABLED || enablingDisabling,
            onPress: () => onEnableDisablePressed(tappedId,
              LocalizedString.common.msgDisableConfirmation),
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.profileScreen.labelCreatedDate,
            field: RXFIELD_CREATED_DATE,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.profileScreen.labelCompany,
            field: RXFIELD_PROFILE_COMPANY_NAME,
            type: FILTER_TYPE_DROPDOWN,
            data: companies,
            loading: loadingCompanies,
            onChangeFilterText: onChangeCompanyText,
            onFilterOptionSelected: onCompanyOptionSelected,
          },
          {
            title: LocalizedString.profileScreen.labelBranch,
            field: RXFIELD_PROFILE_BRANCH,
            type: FILTER_TYPE_DROPDOWN,
            data: branches,
            loading: loadingBranch,
            onChangeFilterText: onChangeBranchText,
            onFilterOptionSelected: (option) => onBranchOptionSelected(option, false),
            useDropdownValue: true,
          },
          {
            title: LocalizedString.profileScreen.labelStatus,
            field: RXFIELD_PROFILE_STATUS,
            type: FILTER_TYPE_DROPDOWN,
            data: status,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.profileScreen.labelShellMembership,
            field: RXFIELD_PROFILE_SHELL_MEMBERSHIP,
            type: FILTER_TYPE_SWITCH,
          },
          {
            title: LocalizedString.profileScreen.labelCity,
            field: RXFIELD_PROFILE_CITY,
            type: FILTER_TYPE_MULTIPLE_VALUES,
            data: cities,
            loading: loadingCity,
            onChangeFilterText: onChangeCityText,
          },
          {
            title: LocalizedString.profileScreen.labelUserTier,
            field: RXFIELD_PROFILE_TAGS,
            type: FILTER_TYPE_SWITCH,
            data: USER_TIERS,
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.profileScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.profileScreen.labelFullName, field: 'fullName', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelPhone, field: 'phone', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelEmail, field: 'email', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelTags, field: 'tags', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelUserCode, field: 'userCode', sorting: !downloading },
          { title: LocalizedString.profileScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
        ]}
        handleSubmit={handleSubmit}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onConfirmDeletePressed={onConfirmEnableDisablePressed}
        onDownloadPressed={onDownloadPressed}
        onEditPressed={onEditPressed}
        onOpenSendNotificationDialog={onOpenSendNotificationDialog}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSavePressed={onSavePressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        disableCreate
        // createNewButtonCaption={LocalizedString.profileScreen.buttonCaptionCreateProfile}
        // onCreatePressed={onCreatePressed}
        onSubmitPressed={onSubmitPressed}
        editButtonCaption={LocalizedString.profileScreen.buttonCaptionEditProfile}
        title={LocalizedString.profileScreen.title}
        disableDelete
        enableSave
        useFullWidth
        useNotificationCount
        useSendAllNotification
        rowStyle={(rowData) => ({
          backgroundColor: rowData.status === STATUS_DISABLED
            ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
        })}
        sendNotificationPermissionName="CORE_SEND_SIMPLE_NOTIFICATION"
      >
        {renderDialogContent(initialValues.branch ? initialValues.branch.label : '', initialValues.gender.label,
          initialValues.profilePicture, initialValues.workingHourTimeZone, branches,
          addingEditing, loadingBranch, onBranchOptionSelected,
          onChangeBranchText,
          pageMode, classes,
          downloadingDeleting, companies, loadingCompanies,
          onChangeCompanyText, onCompanyOptionSelected,
          initialValues.company, downloadingRoles, roles, initialValues.roles, onChangeRoleText,
          onRoleOptionSelected, onGenderOptionSelected)}
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_PROFILE,
  validate: rxformValidateProfile,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProfilePage);

ProfilePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  branches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  companies: PropTypes.arrayOf(SimpleDataShape).isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  roles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingBranch: PropTypes.bool.isRequired,
  loadingCompanies: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  downloadingRoles: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onBranchOptionSelected: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeBranchText: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCompanyOptionSelected: PropTypes.func.isRequired,
  onConfirmEnableDisablePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onOpenSendNotificationDialog: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendNotificationPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onChangeRoleText: PropTypes.func.isRequired,
  onRoleOptionSelected: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onGenderOptionSelected: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  currentProfileStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
