import {
  retryingTokenImport, setAlertErrorMessage, setTokenImportSelectedOrderBy,
  setTokenImportSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_TOKEN_IMPORTS } from '../../../constant';
import { retryTokenImport } from '../../../helper';
import downloadDeleteTokenImportsAsync from './downloadDeleteTokenImportsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(retryingTokenImport(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTokenImport;

    await retryTokenImport(tappedId, token);

    dispatch(setTokenImportSelectedPageSize(20));
    dispatch(setTokenImportSelectedOrderBy(INITIAL_ORDER_BY_TOKEN_IMPORTS));
    dispatch(downloadDeleteTokenImportsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(retryingTokenImport(false));
  }
};
