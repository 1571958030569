import { downloadingAllConfigGroups, setAllConfigGroups } from '../simple-action';
import { downloadConfigGroups } from '../../../helper';
import { INITIAL_ORDER_BY_CONFIG_GROUPS } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingAllConfigGroups(true));

    const list = await downloadConfigGroups(
      1,
      50,
      INITIAL_ORDER_BY_CONFIG_GROUPS,
      '%',
      token,
    );

    const result = { ...list };
    for (let i = list.meta.currentPage; i <= list.meta.totalPages; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const nextList = await downloadConfigGroups(
        i,
        50,
        INITIAL_ORDER_BY_CONFIG_GROUPS,
        '%',
        token,
      );

      const finalList = {
        data: { ...result.data, ...nextList.data },
        meta: nextList.meta,
      };

      Object.assign(result, finalList);
      dispatch(setAllConfigGroups(result.data));
    }
  } finally {
    dispatch(downloadingAllConfigGroups(false));
  }
};
