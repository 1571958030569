/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  COLOR_DISABLED_ROW, COLOR_BACKGROUND, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  RXFORM_REDEMPTION_HISTORY, RXSTATE_REDEMPTION_HISTORIES,
  RXSTATE_REDEMPTION_HISTORY_PAGE, STATUS_DISABLED,
  REWARD_TYPE_VOUCHER, REWARD_TYPE_COIN, REWARD_TYPE_COUPON,
  RXFIELD_REDEMPTION_HISTORY_REWARD, RXFIELD_REDEMPTION_HISTORY_PROMO_CODE,
  RXFIELD_REDEMPTION_HISTORY_VOUCHER, RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE,
  RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_REDEMPTION_HISTORY_TOTAL_VOUCHER_ALLOCATED, RXFIELD_REDEMPTION_HISTORY_COINS_AMOUNT,
  RXFIELD_REDEMPTION_HISTORY_COUPONS_AMOUNT, RXFIELD_REDEMPTION_HISTORY_VALID_FROM,
  RXFIELD_REDEMPTION_HISTORY_VALID_UNTIL, RXFIELD_REDEMPTION_HISTORY_MERCHANT,
  RXFIELD_REDEMPTION_HISTORY_MERCHANT_TAG, RXFIELD_REDEMPTION_HISTORY_MESSAGE_TITLE,
  RXFIELD_REDEMPTION_HISTORY_MESSAGE_BODY, RXFIELD_REDEMPTION_HISTORY_TOTAL_COIN_ALLOCATED,
  RXFIELD_REDEMPTION_HISTORY_TOTAL_COUPON_ALLOCATED, RXFIELD_REDEMPTION_HISTORY_USER,
  rewards, approval, RXFIELD_REDEMPTION_HISTORY_USER_ID,
  FILTER_TYPE_TEXT, redemptionStatus, RXFIELD_REDEMPTION_HISTORY_REDEMPTION_STATUS,
  RXFIELD_REDEMPTION_HISTORY_REDEMPTION_STATUS_NOTES,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormDateTimePickerField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxFormValidatePromoCode } from '../../validation';
import { toMoment } from '../../helper';
import { VOUCHER_FLAG_GARUDA_LOUNGE } from '../../../voucher';

const useStyles = makeStyles(() => ({
  italicText: {
    fontStyle: 'italic',
  },
  captionText: {
    marginLeft: 10,
  },
}));

const renderRewardAdditionalField = (
  initialValues, addingEditing, downloadingDeleting, pageMode, formSelectedRewardType,

  vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
  onBypassMaxPurchaseOptionSelected, onBypassAchievementValidationOptionSelected,
) => {
  const rewardTypeVoucher = formSelectedRewardType === REWARD_TYPE_VOUCHER;
  const initialRewardTypeVoucher = initialValues.rewardType === REWARD_TYPE_VOUCHER;
  const rewardTypeCoin = formSelectedRewardType === REWARD_TYPE_COIN;
  const initialRewardTypeCoin = initialValues.rewardType === REWARD_TYPE_COIN;
  const rewardTypeCoupon = formSelectedRewardType === REWARD_TYPE_COUPON;
  const initialRewardTypeCoupon = initialValues.rewardType === REWARD_TYPE_COUPON;

  if (rewardTypeVoucher) {
    return (
      <>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_VOUCHER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderVoucher}
            label={LocalizedString.redemptionHistoryPage.placeholderVoucher}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            value={initialValues.voucherRedemptions}
            loading={loadingVouchers}
            required
            multiline
          />
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderBypassMaxPurchase}
            label={LocalizedString.redemptionHistoryPage.placeholderBypassMaxPurchase}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassMaxPurchase}
            onOptionSelected={onBypassMaxPurchaseOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.redemptionHistoryPage
              .placeholderBypassAchievementValidation}
            label={LocalizedString.redemptionHistoryPage.placeholderBypassAchievementValidation}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassAchievementValidation}
            onOptionSelected={onBypassAchievementValidationOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_TOTAL_VOUCHER_ALLOCATED}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderTotalVoucherAllocated}
            label={LocalizedString.redemptionHistoryPage.placeholderTotalVoucherAllocated}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>

      </>
    );
  }
  if (rewardTypeCoin) {
    return (
      <>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_COINS_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderCoinsAmount}
            label={LocalizedString.redemptionHistoryPage.placeholderCoinsAmount}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_TOTAL_COIN_ALLOCATED}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderTotalVoucherAllocated}
            label={LocalizedString.redemptionHistoryPage.placeholderTotalVoucherAllocated}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
      </>
    );
  }
  if (rewardTypeCoupon) {
    return (
      <>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_COUPONS_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderCouponsAmount}
            label={LocalizedString.redemptionHistoryPage.placeholderCouponsAmount}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_TOTAL_COUPON_ALLOCATED}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderTotalVoucherAllocated}
            label={LocalizedString.redemptionHistoryPage.placeholderTotalVoucherAllocated}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
      </>
    );
  }
  if (initialRewardTypeVoucher) {
    return (
      <>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_VOUCHER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderVoucher}
            label={LocalizedString.redemptionHistoryPage.placeholderVoucher}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            value={initialValues.voucherRedemptions}
            loading={loadingVouchers}
            required
            multiline
          />
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.redemptionHistoryPage.placeholderBypassMaxPurchase}
            label={LocalizedString.redemptionHistoryPage.placeholderBypassMaxPurchase}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassMaxPurchase}
            onOptionSelected={onBypassMaxPurchaseOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.redemptionHistoryPage
              .placeholderBypassAchievementValidation}
            label={LocalizedString.redemptionHistoryPage.placeholderBypassAchievementValidation}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassAchievementValidation}
            onOptionSelected={onBypassAchievementValidationOptionSelected}
            required
          />
        </Grid>
      </>
    );
  }
  if (initialRewardTypeCoin) {
    return (
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_COINS_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderCoinsAmount}
          label={LocalizedString.redemptionHistoryPage.placeholderCoinsAmount}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          type="number"
          required
        />
      </Grid>
    );
  }
  if (initialRewardTypeCoupon) {
    return (
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_COUPONS_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderCouponsAmount}
          label={LocalizedString.redemptionHistoryPage.placeholderCouponsAmount}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          type="number"
          required
        />
      </Grid>
    );
  }
  return null;
};

const renderDialogContent = (
  initialValues, addingEditing, downloadingDeleting, pageMode, formSelectedRewardType,

  onRewardOptionSelected,

  vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
  onBypassMaxPurchaseOptionSelected, onBypassAchievementValidationOptionSelected,

  merchants, loadingMerchants, onChangeMerchantText, onMerchantOptionSelected,
  users, loadingUsers, onChangeUserText, onUserOptionSelected,

) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_PROMO_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderPromoCode}
          label={LocalizedString.redemptionHistoryPage.placeholderPromoCode}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_REWARD}
          component={renderReduxFormSimpleDropdownField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderReward}
          label={LocalizedString.redemptionHistoryPage.placeholderReward}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={rewards}
          value={initialValues.reward}
          onOptionSelected={onRewardOptionSelected}
          required
        />
      </Grid>
      {renderRewardAdditionalField(
        initialValues, addingEditing, downloadingDeleting, pageMode, formSelectedRewardType,

        vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
        onBypassMaxPurchaseOptionSelected, onBypassAchievementValidationOptionSelected,
      )}
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_USER}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderUser}
          label={LocalizedString.redemptionHistoryPage.placeholderUser}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={users}
          value={initialValues.user}
          loading={loadingUsers}
          onChangeText={onChangeUserText}
          onOptionSelected={onUserOptionSelected}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderUserId}
          label={LocalizedString.redemptionHistoryPage.placeholderUserId}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_REDEMPTION_STATUS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderRedemptionStatus}
          label={LocalizedString.redemptionHistoryPage.placeholderRedemptionStatus}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_REDEMPTION_STATUS_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderRedemptionStatusNote}
          label={LocalizedString.redemptionHistoryPage.placeholderRedemptionStatusNote}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>


    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_VALID_FROM}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.redemptionHistoryPage.placeholderValidFrom}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderValidFrom}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_VALID_UNTIL}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.redemptionHistoryPage.placeholderValidUntil}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderValidUntil}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_MERCHANT}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderMerchant}
          label={LocalizedString.redemptionHistoryPage.placeholderMerchant}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={merchants}
          value={initialValues.merchant}
          loading={loadingMerchants}
          onChangeText={onChangeMerchantText}
          onOptionSelected={onMerchantOptionSelected}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_MERCHANT_TAG}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderMerchantTag}
          label={LocalizedString.redemptionHistoryPage.placeholderMerchantTag}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_MESSAGE_TITLE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderMessageTitle}
          label={LocalizedString.redemptionHistoryPage.placeholderMessageTitle}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REDEMPTION_HISTORY_MESSAGE_BODY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.redemptionHistoryPage.placeholderMessageBody}
          label={LocalizedString.redemptionHistoryPage.placeholderMessageBody}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
          multiline
        />
      </Grid>

    </Grid>
  </Grid>
);

const RedemptionHistoryPage = ({
  downloading, handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh, onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  addingEditing, downloadingDeleting, pageMode,
  onCancelPressed, onRewardOptionSelected, vouchers, loadingVouchers,
  onChangeVoucherText, onVoucherOptionSelected, onBypassMaxPurchaseOptionSelected,
  onBypassAchievementValidationOptionSelected, merchants, loadingMerchants,
  onChangeMerchantText, onMerchantOptionSelected, formSelectedRewardType,
  users, loadingUsers, onChangeUserText, onUserOptionSelected,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_REDEMPTION_HISTORIES}
      uiPage={RXSTATE_REDEMPTION_HISTORY_PAGE}
      filterColumns={[
        {
          title: LocalizedString.redemptionHistoryPage.placeholderUser,
          field: RXFIELD_REDEMPTION_HISTORY_USER,
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUsers,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.redemptionHistoryPage.placeholderRedemptionStatus,
          field: RXFIELD_REDEMPTION_HISTORY_REDEMPTION_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: redemptionStatus,
          loading: loadingUsers,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.redemptionHistoryPage.placeholderMerchant,
          field: RXFIELD_REDEMPTION_HISTORY_MERCHANT,
          type: FILTER_TYPE_DROPDOWN,
          data: merchants,
          loading: loadingMerchants,
          onChangeFilterText: onChangeMerchantText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.redemptionHistoryPage.placeholderReward,
          field: RXFIELD_REDEMPTION_HISTORY_REWARD,
          type: FILTER_TYPE_DROPDOWN,
          data: rewards,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.redemptionHistoryPage.placeholderPromoCode,
          field: RXFIELD_REDEMPTION_HISTORY_PROMO_CODE,
          type: FILTER_TYPE_TEXT,
        },
      ]}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      tableColumns={[
        {
          title: LocalizedString.redemptionHistoryPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.redemptionHistoryPage.placeholderUser, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.redemptionHistoryPage.placeholderRedemptionStatus, field: 'redemptionStatus', sorting: !downloading },
        { title: LocalizedString.redemptionHistoryPage.placeholderMerchant, field: 'merchantName', sorting: !downloading },
        { title: LocalizedString.redemptionHistoryPage.placeholderPromoCode, field: 'promoCode', sorting: !downloading },
        { title: LocalizedString.redemptionHistoryPage.placeholderReward, field: 'rewardType', sorting: !downloading },
        {
          title: LocalizedString.redemptionHistoryPage.placeholderVoucher,
          field: 'voucherRedemptions',
          sorting: !downloading,
          render: ({ voucherRedemptions }) => voucherRedemptions.map((item, index) => {
            const voucherName = item.garudaFlag === VOUCHER_FLAG_GARUDA_LOUNGE
              ? `${item.voucherName} (${item.airportName})` : item.voucherName;

            return (index === voucherRedemptions.length - 1 ? voucherName : `${voucherName} | `);
          }),
        },
        {
          title: LocalizedString.redemptionHistoryPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.redemptionHistoryPage.title}
      disableCreate
      disableDelete
      disableEdit
      useFullWidth
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
      onCancelPressed={onCancelPressed}
    >
      {renderDialogContent(
        initialValues, addingEditing, downloadingDeleting, pageMode, formSelectedRewardType,

        onRewardOptionSelected,

        vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
        onBypassMaxPurchaseOptionSelected, onBypassAchievementValidationOptionSelected,

        merchants, loadingMerchants, onChangeMerchantText, onMerchantOptionSelected,
        users, loadingUsers, onChangeUserText, onUserOptionSelected,

        classes,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_REDEMPTION_HISTORY,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxFormValidatePromoCode,
})(RedemptionHistoryPage);

RedemptionHistoryPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,

  pageMode: PropTypes.string.isRequired,
  formSelectedRewardType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  loadingVouchers: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingMerchants: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onRewardOptionSelected: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onBypassMaxPurchaseOptionSelected: PropTypes.func.isRequired,
  onBypassAchievementValidationOptionSelected: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onMerchantOptionSelected: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
};

RedemptionHistoryPage.defaultProps = {
  formSelectedRewardType: null,
};
