import { addVoidCouponRequest, downloadingVoidCouponRequest } from '../simple-action';
import { downloadVoidCouponRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingVoidCouponRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCouponVoidRequest;

    const purchaseRequest = await downloadVoidCouponRequest(tappedId, token);
    dispatch(addVoidCouponRequest(purchaseRequest));
  } finally {
    dispatch(downloadingVoidCouponRequest(false));
  }
};
