import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, Typography,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';
import { AccentButton, BasePage, TooltipText } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TEXT_ON_PRIMARY,
  CONFIG_ACTION_RESYNC_CONFIGS, CONFIG_ACTION_RESYNC_DB_USERS, CONFIG_ACTION_RESYNC_MASTER_DATA,
  CONFIG_ACTION_RESYNC_USERS, MASTER_DATA_OPTIONS, RXFORM_CONFIG_ACTION, COLOR_BODY_TEXT,
  RXFIELD_CONFIG_ACTION_MASTER_DATA,
  RXFIELD_CONFIG_ACTION_CHANNEL, CHANNEL_OPTIONS,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormAutocompleteCheckboxField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  title: {
    color: COLOR_SECONDARY,
  },
  container: {
    background: COLOR_BACKGROUND,
    padding: '13px 35px 13px 35px',
    borderRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    justifyContent: 'space-between',
    marginBottom: 80,
  },
  gridContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  buttonContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    width: 360,
    height: 40,
    borderRadius: 4,
    marginRight: 10,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  resyncButton: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    width: 100,
    height: 40,
    borderRadius: 4,
    marginRight: 10,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  dropdownContainer: {
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
  },
}));

const ConfigActionPage = ({
  loadingCongfigs, loadingDBUsers, loadingMasterData, loadingUsers,
  onAppear, onConfirmResyncPressed, onResyncPressed,
  selectedResyncAction, onSelectedMasterData,
  selectedMasterData,
  onSelectedChannel, selectedChannel,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  const actions = [
    {
      id: CONFIG_ACTION_RESYNC_DB_USERS,
      loading: loadingDBUsers,
      buttonCaption: LocalizedString.configActionScreen.buttonCaptionResyncDBUsers,
      tooltip: LocalizedString.configActionScreen.labelResyncDBUsers,
    },
    {
      id: CONFIG_ACTION_RESYNC_CONFIGS,
      loading: loadingCongfigs,
      buttonCaption: LocalizedString.configActionScreen.buttonCaptionResyncConfigs,
      tooltip: LocalizedString.configActionScreen.labelResyncConfigs,
    },
    {
      id: CONFIG_ACTION_RESYNC_USERS,
      loading: loadingUsers,
      buttonCaption: LocalizedString.configActionScreen.buttonCaptionResyncUsers,
      tooltip: LocalizedString.configActionScreen.labelResyncUsers,
    },
    {
      id: CONFIG_ACTION_RESYNC_MASTER_DATA,
      loading: loadingMasterData,
      buttonCaption: LocalizedString.configActionScreen.buttonCaptionResync,
      tooltip: LocalizedString.configActionScreen.labelResyncMasterData,
      dropdown: true,
    },
  ];

  return (
    <BasePage onConfirmPressed={() => onConfirmResyncPressed(selectedResyncAction)}>
      <Typography variant="h4" className={classes.title}>{LocalizedString.configActionScreen.title}</Typography>

      <div className={classes.container}>
        <Grid container spacing={3}>
          {actions.map((item) => (
            <Grid item container key={item.id} className={classes.gridContainer}>

              {item.dropdown ? (
                <Grid item sm md className={classes.buttonContainer}>
                  <div className={classes.dropdownContainer}>
                    <Field
                      name={RXFIELD_CONFIG_ACTION_CHANNEL}
                      component={renderReduxFormSimpleDropdownField}
                      placeholder={LocalizedString.configActionScreen.placeholderChannel}
                      label={LocalizedString.configActionScreen.placeholderChannel}
                      data={CHANNEL_OPTIONS}
                      onOptionSelected={(option) => onSelectedChannel(option)}
                      disabled={item.loading}
                    />
                  </div>
                  <div className={classes.dropdownContainer}>
                    <Field
                      name={RXFIELD_CONFIG_ACTION_MASTER_DATA}
                      component={renderReduxFormAutocompleteCheckboxField}
                      placeholder={LocalizedString.configActionScreen.placebolderMasterData}
                      label={LocalizedString.configActionScreen.placebolderMasterData}
                      data={MASTER_DATA_OPTIONS}
                      onChangeText={() => {}}
                      onOptionSelected={(option) => onSelectedMasterData(option)}
                      onBlur={(e) => e.preventDefault()}
                    />
                  </div>
                  <AccentButton
                    caption={item.buttonCaption}
                    loading={item.loading}
                    disabled={isEmpty(selectedMasterData) || isEmpty(selectedChannel)}
                    onClick={() => onResyncPressed(item.id)}
                    className={item.dropdown ? classes.resyncButton : classes.button}
                  />
                </Grid>
              ) : (
                <Grid item sm md className={classes.buttonContainer}>
                  <AccentButton
                    caption={item.buttonCaption}
                    loading={item.loading}
                    onClick={() => onResyncPressed(item.id)}
                    className={item.dropdown ? classes.resyncButton : classes.button}
                  />
                </Grid>
              )}

              <Grid item sm md>
                <TooltipText caption={item.tooltip} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_CONFIG_ACTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ConfigActionPage);

ConfigActionPage.propTypes = {
  loadingCongfigs: PropTypes.bool.isRequired,
  loadingDBUsers: PropTypes.bool.isRequired,
  loadingMasterData: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onConfirmResyncPressed: PropTypes.func.isRequired,
  onResyncPressed: PropTypes.func.isRequired,
  onSelectedMasterData: PropTypes.func.isRequired,
  onSelectedChannel: PropTypes.func.isRequired,
  selectedResyncAction: PropTypes.string.isRequired,
  selectedMasterData: PropTypes.arrayOf(SimpleDataShape),
  selectedChannel: PropTypes.objectOf(PropTypes.string).isRequired,
};

ConfigActionPage.defaultProps = {
  selectedMasterData: [],
};
