
import { saveAccessories, transformSearchText } from '../../../helper';
import '../../../../../component/functional-page/download-file.css';
import { savingAccesories } from '../simple-action';

export default () => async (dispatch, getState) => {
  const {
    authentication, uiAccessory,
    uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { searchBarText, orderBy } = uiAccessory;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(savingAccesories(true));

    const response = await saveAccessories(
      1,
      20,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'accesory.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(savingAccesories(false));
  }
};
