import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_IIMS_2023_SCANNED_CODES, MENUID_IIMS_2023_SURVEY, PAGE_MODE_TABLE } from '../../constant';
import { transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  clearIims2023Surveys, downloadDeleteIims2023SurveyAsync,
  downloadIims2023SurveysAsync, saveIims2023SurveysAsync,
  setAlertErrorMessage, setIims2023SurveysOrderBy, setIims2023SurveysPageSize,
  setIims2023SurveysSearchText, setIims2023SurveysFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setIims2023SurveysTappedId, setActiveSideMenuItem,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import Iims2023Survey from './iims-2023-survey.presentation';

const getInitialValues = (state) => {
  const {
    iims2023Surveys, uiFunctionalPage, uiIims2023Survey,
  } = state;
  const { data } = iims2023Surveys;
  const { downloadingSurvey, tappedId } = uiIims2023Survey;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingSurvey
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    user: found.user
      ? { fullName: found.user.fullName, id: found.user.id }
      : { fullName: '', id: '' },
  };

  return result;
};

const mapStateToProps = (state) => ({
  surveys: state.iims2023Surveys.data,
  downloading: state.uiIims2023Survey.downloading,
  downloadingSurvey: state.uiIims2023Survey.downloadingSurvey,
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_IIMS_2023_SURVEY));
    dispatch(setIims2023SurveysSearchText(''));
    dispatch(setIims2023SurveysFilterString(''));
    dispatch(clearIims2023Surveys());
    dispatch(setIims2023SurveysPageSize(20));
    dispatch(setIims2023SurveysOrderBy(INITIAL_ORDER_BY_IIMS_2023_SCANNED_CODES));

    dispatch(downloadIims2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setIims2023SurveysPageSize(pageSize));
    dispatch(clearIims2023Surveys());

    dispatch(downloadIims2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setIims2023SurveysOrderBy(orderBy));
    dispatch(clearIims2023Surveys());
    dispatch(downloadIims2023SurveysAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadIims2023SurveysAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setIims2023SurveysPageSize(pageSize));
    dispatch(downloadIims2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setIims2023SurveysSearchText(text));
      dispatch(clearIims2023Surveys());
      await dispatch(downloadIims2023SurveysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/scanCodeUsername/, 'user.id');

    dispatch(setIims2023SurveysFilterString(text));
    dispatch(clearIims2023Surveys());
    dispatch(downloadIims2023SurveysAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setIims2023SurveysFilterString(''));
    dispatch(clearIims2023Surveys());
    dispatch(setIims2023SurveysOrderBy(INITIAL_ORDER_BY_IIMS_2023_SCANNED_CODES));
    dispatch(downloadIims2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setIims2023SurveysTappedId(id));
    dispatch(downloadDeleteIims2023SurveyAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDownloadPressed: () => {
    dispatch(saveIims2023SurveysAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Iims2023Survey);
