import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_CAR_VIEWING_EVENTS = '/carviewing/api/v1/Event?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_CAR_VIEWING_EVENT = '/carviewing/api/v1/Event';
export const REST_URL_VIEW_DELETE_CAR_VIEWING_EVENT = '/carviewing/api/v1/Event/{id}';
export const REST_URL_CAR_VIEWING_RESERVATIONS = '/carviewing/api/v1/Reservation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_CAR_VIEWING_RESERVATION = '/carviewing/api/v1/Reservation/{id}';
export const REST_URL_CAR_VIEWING_VOUCHER_REWARDS = '/carviewing/api/v1/VoucherReward?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_CAR_VIEWING_VOUCHER_REWARD = '/carviewing/api/v1/VoucherReward/{id}';

export const ROUTE_NAME_CAR_VIEWING = '/car-viewing';
export const ROUTE_NAME_CAR_VIEWING_EVENT = '/car-viewing-event';
export const ROUTE_NAME_CAR_VIEWING_RESERVATION = '/car-viewing-reservation';
export const ROUTE_NAME_CAR_VIEWING_VOUCHER_REWARD = '/car-viewing-voucher-reward';

export const RXFORM_CAR_VIEWING_EVENT = 'carViewingEventPage';
export const RXFORM_CAR_VIEWING_RESERVATION = 'carViewingReservationPage';
export const RXFORM_CAR_VIEWING_VOUCHER_REWARD = 'carViewingVoucherRewardPage';

export const RXFIELD_CAR_VIEWING_EVENT_NAME = 'name';
export const RXFIELD_CAR_VIEWING_EVENT_DESCRIPTION = 'description';
export const RXFIELD_CAR_VIEWING_EVENT_LOCATION = 'location';
export const RXFIELD_CAR_VIEWING_EVENT_CITY = 'city';
export const RXFIELD_CAR_VIEWING_EVENT_VOUCHER_REWARDS = 'voucherRewards';
export const RXFIELD_CAR_VIEWING_EVENT_IMAGE = 'image';
export const RXFIELD_CAR_VIEWING_EVENT_START_DATE = 'startDate';
export const RXFIELD_CAR_VIEWING_EVENT_END_DATE = 'endDate';
export const RXFIELD_CAR_VIEWING_EVENT_OPEN_TIME = 'openTime';
export const RXFIELD_CAR_VIEWING_EVENT_CLOSE_TIME = 'closeTime';
export const RXFIELD_CAR_VIEWING_EVENT_VISIBILITY = 'visibility';
export const RXFIELD_CAR_VIEWING_EVENT_QUOTA_PER_SLOT = 'quotaPerSlot';
export const RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR = 'additionalVisitor';
export const RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR_PHONE_NUMBER = 'additionalAttendances[0].phone';
export const RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR_FULL_NAME = 'additionalAttendances[0].fullname';
export const RXFIELD_CAR_VIEWING_RESERVATION_CODE = 'reservationCode';
export const RXFIELD_CAR_VIEWING_RESERVATION_EVENT = 'event';
export const RXFIELD_CAR_VIEWING_RESERVATION_EVENT_NAME = 'eventName';
export const RXFIELD_CAR_VIEWING_RESERVATION_EVENT_ID = 'eventId';
export const RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_DATE = 'reservedDate';
export const RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_TIME = 'reservedSlot';
export const RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_EMAIL = 'email';
export const RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_FIRST_NAME = 'firstName';
export const RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_LAST_NAME = 'lastName';
export const RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_GENDER = 'gender';
export const RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_PHONE_NUMBER = 'phone';
export const RXFIELD_CAR_VIEWING_RESERVATION_USER_ID = 'profile.id';
export const RXFIELD_CAR_VIEWING_RESERVATION_USER_FULL_NAME = 'profile.fullName';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_CREATED_DATE = 'createdDate';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_EVENT_ID = 'eventId';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_EVENT = 'event';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_EVENT_NAME = 'eventName';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_NOTE = 'note';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_USER = 'user';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_USER_FULLNAME = 'userFullname';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_USER_ID = 'userId';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_VOUCHER = 'voucher';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_VOUCHER_ID = 'voucherId';
export const RXFIELD_CAR_VIEWING_VOUCHER_REWARD_VOUCHER_NAME = 'voucherName';

export const RXSTATE_CAR_VIEWING_EVENTS = 'carViewingEvents';
export const RXSTATE_CAR_VIEWING_EVENT = 'uiCarViewingEvent';

export const RXSTATE_CAR_VIEWING_RESERVATIONS = 'carViewingReservations';
export const RXSTATE_CAR_VIEWING_RESERVATION = 'uiCarViewingReservation';

export const RXSTATE_CAR_VIEWING_VOUCHER_REWARS = 'carViewingVoucherRewards';
export const RXSTATE_CAR_VIEWING_VOUCHER_REWARD = 'uiCarViewingVoucherReward';

export const INITIAL_ORDER_BY_CAR_VIEWING_EVENTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CAR_VIEWING_RESERVATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CAR_VIEWING_VOUCHER_REWARD = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_CAR_VIEWING = 'MENUID_CAR_VIEWING';
export const MENUID_CAR_VIEWING_EVENT = 'MENUID_CAR_VIEWING_EVENT';
export const MENUID_CAR_VIEWING_RESERVATION = 'MENUID_CAR_VIEWING_RESERVATION';
export const MENUID_CAR_VIEWING_VOUCHER_REWARD = 'MENUID_CAR_VIEWING_VOUCHER_REWARD';

export const RESERVED_TIMES = [
  '00:00 - 01:00',
  '01:00 - 02:00',
  '02:00 - 03:00',
  '03:00 - 04:00',
  '04:00 - 05:00',
  '05:00 - 06:00',
  '06:00 - 07:00',
  '07:00 - 08:00',
  '08:00 - 09:00',
  '09:00 - 10:00',
  '10:00 - 11:00',
  '11:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 14:00',
  '14:00 - 15:00',
  '15:00 - 16:00',
  '16:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 19:00',
  '19:00 - 20:00',
  '20:00 - 21:00',
  '21:00 - 22:00',
  '22:00 - 23:00',
  '23:00 - 00:00',
];
