import {
  reprocessingCouponSubmission, setAlertErrorMessage, setCouponSubmissionSelectedOrderBy,
  setCouponSubmissionSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_COUPON_SUBMISSIONS } from '../../../constant';
import { reprocessCouponSubmission } from '../../../helper';
import downloadCouponSubmissionsAsync from './downloadCouponSubmissionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(reprocessingCouponSubmission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCouponSubmission;

    await reprocessCouponSubmission(tappedId, token);

    dispatch(setCouponSubmissionSelectedPageSize(20));
    dispatch(setCouponSubmissionSelectedOrderBy(INITIAL_ORDER_BY_COUPON_SUBMISSIONS));
    dispatch(downloadCouponSubmissionsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(reprocessingCouponSubmission(false));
  }
};
