import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  downloadUsersAsync,
} from '../../../../redux/action';
import {
  INITIAL_ORDER_BY_DMS_USER_SUBMISSIONS, MENUID_CAR_REGISTRATION_DMS_USER_SUBMISSION,
  PAGE_MODE_TABLE, RXFORM_DMS_USER_SUBMISSION, DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch, toMoment,
} from '../../helper';
import {
  clearDMSUserSubmissions, clearUsers,
  downloadDMSUserSubmissionAsync,
  downloadDMSUserSubmissionsAsync,
  setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setDMSUserSubmissionSearchText, setDMSUserSubmissionSelectedOrderBy,
  setDMSUserSubmissionSelectedPageSize, setDMSUserSubmissionTappedId,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  setAlertConfirmationMessage, retryDMSUserSubmissionAsync,
} from '../../redux/action';
import localizedString from '../../localization';
import DMSUserSubmissionPage from './dms-user-submission.presentation';

const getInitialValues = (state) => {
  const { dmsUserSubmissions, uiFunctionalPage, uiDMSUserSubmission } = state;
  const { data } = dmsUserSubmissions;
  const { downloadingDeleting, tappedId } = uiDMSUserSubmission;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
    submittedOn: toMoment(found?.submittedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  downloading: state.uiDMSUserSubmission.downloading,
  downloadingDeleting: state.uiDMSUserSubmission.downloadingDeleting,
  loadingUser: state.uiUser.downloading,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const searchUsersDebounce = debounceSearch((dispatch) => {
  dispatch(clearUsers());
  dispatch(downloadUsersAsync(1))
    .catch((error) => dispatch(setAlertErrorMessage(error)));
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_REGISTRATION_DMS_USER_SUBMISSION));
    dispatch(setDMSUserSubmissionSearchText(''));
    dispatch(clearDMSUserSubmissions());
    dispatch(setDMSUserSubmissionSelectedPageSize(20));
    dispatch(setDMSUserSubmissionSelectedOrderBy(INITIAL_ORDER_BY_DMS_USER_SUBMISSIONS));
    dispatch(downloadDMSUserSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id')
      .replace('vin', 'registration.vehicle.vin');

    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearDMSUserSubmissions());
    dispatch(downloadDMSUserSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_DMS_USER_SUBMISSION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDMSUserSubmissionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDMSUserSubmissionSelectedPageSize(pageSize));
    dispatch(downloadDMSUserSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUsersDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setDMSUserSubmissionSelectedPageSize(pageSize));
    dispatch(clearDMSUserSubmissions());
    dispatch(downloadDMSUserSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearDMSUserSubmissions());
    dispatch(setDMSUserSubmissionSelectedOrderBy(INITIAL_ORDER_BY_DMS_USER_SUBMISSIONS));
    dispatch(downloadDMSUserSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setDMSUserSubmissionSearchText(text));
      dispatch(clearDMSUserSubmissions());
      await dispatch(downloadDMSUserSubmissionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setDMSUserSubmissionSelectedOrderBy(orderBy));
    dispatch(clearDMSUserSubmissions());
    dispatch(downloadDMSUserSubmissionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setDMSUserSubmissionTappedId(id));
    dispatch(downloadDMSUserSubmissionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRetryPressed: (id) => {
    dispatch(setDMSUserSubmissionTappedId(id));
    dispatch(setAlertConfirmationMessage(localizedString.DMSUserSubmissionPage
      .msgRetryConfirmation));
  },
  onConfirmRetryPressed: () => {
    dispatch(retryDMSUserSubmissionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DMSUserSubmissionPage);
