
import { SET_POINT_LEADER_BOARD, CLEAR_POINT_LEADER_BOARD } from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_POINT_LEADER_BOARD: {
      return action.data;
    }
    case CLEAR_POINT_LEADER_BOARD:
      return [];
    default:
      return state;
  }
};
