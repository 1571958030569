import { ORDER_SORT_DESCENDING, ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_ACCESSORIES = '/carservice/api/v1/Accessory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_EDIT_ACCESSORY = '/carservice/api/v1/Accessory';
export const REST_URL_VIEW_ACCESSORY = '/carservice/api/v1/Accessory/{id}';
export const REST_URL_SYNC_ACCESSORIES = '/carservice/api/v1/SyncAccessory';
export const REST_URL_INVOICES = '/carservice/api/v1/PurchaseTransactionInvoice?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|transactionId|orderId&filterString=';
export const REST_URL_VIEW_INVOICE = '/carservice/api/v1/PurchaseTransactionInvoice/{id}';
export const REST_URL_SAVE_INVOICE = '/carservice/api/v1/PurchaseTransactionInvoicePdf/{id}';
export const REST_URL_SAVE_INVOICE_CSV = '/carservice/api/v1/PurchaseTransactionInvoice/Download?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|transactionId|orderId&filterString=';
export const REST_URL_VEHICLE_MODELS = '/carservice/api/v1/AccessoryCarModel';
export const REST_URL_ACCESSORY_TRANSACTIONS = '/carservice/api/v1/PurchaseTransaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|transactionId&filterString=status=enabled';
export const REST_URL_VIEW_ACCESSORY_TRANSACTION = '/carservice/api/v1/PurchaseTransaction/{id}';

export const ROUTE_NAME_GENUINE_ACCESSORY = '/genuine-accessory';
export const ROUTE_NAME_GENUINE_ACCESSORY_ACCESSORY = '/accessory';
export const ROUTE_NAME_GENUINE_ACCESSORY_INVOICE = '/invoice';
export const ROUTE_NAME_GENUINE_ACCESSORY_TRANSACTION = '/transaction';

export const RXFORM_ACCESSORY = 'accessoryPage';
export const RXFORM_INVOICE = 'invoicePage';
export const RXFORM_ACCESSORY_TRANSACTION = 'accesoryTransactionPage';

export const RXFIELD_ACCESSORY_PRODUCT_CODE = 'productCode';
export const RXFIELD_ACCESSORY_PRODUCT_NAME = 'productName';
export const RXFIELD_ACCESSORY_DESCRIPTION = 'description';
export const RXFIELD_ACCESSORY_VEHICLE_CODE = 'ltmCode';
export const RXFIELD_ACCESSORY_VEHICLE_MODEL = 'ltmName';
export const RXFIELD_ACCESSORY_VARIANT = 'variants';
export const RXFIELD_ACCESSORY_ACTIVE = 'isActive';
export const RXFIELD_ACCESSORY_MIN_ORDER_QTY = 'minOrderQty';
export const RXFIELD_ACCESSORY_WIDTH = 'width';
export const RXFIELD_ACCESSORY_HEIGHT = 'height';
export const RXFIELD_ACCESSORY_WEIGHT = 'weight';
export const RXFIELD_ACCESSORY_LENGTH = 'length';
export const RXFIELD_INVOICE_INVOICE_NO = 'invoiceNo';
export const RXFIELD_INVOICE_ORDER_ID = 'orderId';
export const RXFIELD_INVOICE_FINISH_DATE = 'finishDate';
export const RXFIELD_INVOICE_USER_ID = 'userId';
export const RXFIELD_INVOICE_USER_FULL_NAME = 'userFullName';
export const RXFIELD_INVOICE_TRANSACTION_ID = 'transactionId';
export const RXFIELD_INVOICE_TRANSACTION_STATUS = 'transactionStatus';
export const RXFIELD_INVOICE_TRANSACTION_STATUS_NOTES = 'transactionStatusNotes';
export const RXFIELD_INVOICE_PICKUP_AND_DELIVERY_STATUS = 'pickupAndDeliveryStatus';
export const RXFIELD_INVOICE_TAX = 'tax';
export const RXFIELD_INVOICE_TOTAL_WITH_TAX = 'totalWithTax';
export const RXFIELD_INVOICE_DEALER_ID = 'dealerId';
export const RXFIELD_INVOICE_DEALER_CODE = 'dealerCode';
export const RXFIELD_INVOICE_DEALER_TYPE = 'dealerType';
export const RXFIELD_INVOICE_DEALER_NAME = 'dealerName';
export const RXFIELD_INVOICE_DEALER_ADDRESS = 'dealerAddress';
export const RXFIELD_INVOICE_DEALER_POSTAL_CODE = 'dealerPostalCode';
export const RXFIELD_INVOICE_DEALER_CITY = 'dealerCity';
export const RXFIELD_INVOICE_DEALER_REGION = 'dealerRegion';
export const RXFIELD_INVOICE_DEALER_REGION_CODE = 'dealerRegionCode';
export const RXFIELD_INVOICE_DEALER_PROVINCE = 'dealerProvince';
export const RXFIELD_INVOICE_DEALER_TIME_ZONE = 'dealerTimeZone';
export const RXFIELD_INVOICE_DEALER_CHARGING_STATION = 'dealerChargingStation';
export const RXFIELD_INVOICE_DEALER_EMAIL = 'dealerEmail';
export const RXFIELD_INVOICE_DEALER_PHONE = 'dealerPhone';
export const RXFIELD_INVOICE_DEALER_OPEN_TIME = 'dealerOpenTime';
export const RXFIELD_INVOICE_DEALER_CLOSE_TIME = 'dealerCloseTime';
export const RXFIELD_INVOICE_DEALER_STATUS = 'dealerStatus';
export const RXFIELD_INVOICE_DEALER_TYPE_CODE = 'dealerTypeCode';
export const RXFIELD_INVOICE_DEALER_TYPE_DEALER = 'dealerTypeDealer';
export const RXFIELD_INVOICE_DEALER_LATITUDE = 'dealerLatitude';
export const RXFIELD_INVOICE_DEALER_LONGITUDE = 'dealerLongitude';
export const RXFIELD_INVOICE_PAYMENT_ID = 'paymentId';
export const RXFIELD_INVOICE_PAYMENT_STATUS = 'paymentStatus';
export const RXFIELD_INVOICE_PAYMENT_TOTAL = 'paymentTotal';
export const RXFIELD_INVOICE_PAYMENT_RAW_CALLBACK = 'paymentRawCallback';
export const RXFIELD_INVOICE_PAYMENT_RAW_RESPONSE = 'paymentRawResponse';
export const RXFIELD_INVOICE_PAYMENT_DATE = 'paymentDate';
export const RXFIELD_INVOICE_PAYMENT_DUE_DATE = 'paymentDueDate';
export const RXFIELD_INVOICE_PAYMENT_METHOD = 'paymentMethod';
export const RXFIELD_INVOICE_PAYMENT_VA_NUMBER = 'paymentVaNumber';
export const RXFIELD_INVOICE_PAYMENT_URL = 'paymentUrl';
export const RXFIELD_INVOICE_MEMBER_ID = 'memberId';
export const RXFIELD_INVOICE_EMAIL = 'email';
export const RXFIELD_INVOICE_ADDRESS = 'address';
export const RXFIELD_INVOICE_CONTACT_PERSON_NAME = 'contactPersonName';
export const RXFIELD_INVOICE_CONTACT_PERSON_PHONE = 'contactPersonPhone';
export const RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_STATUS = 'transactionStatus';
export const RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_STATUS = 'payment.paymentStatus';
export const RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_DATE = 'paymentDate';
export const RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_METHOD = 'paymentMethod';
export const RXFIELD_ACCESSORY_TRANSACTION_USER_FULLNAME = 'user.fullName';
export const RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_ID = 'transactionId';
export const RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_STATUS_NOTES = 'transactionStatusNotes';
export const RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_VA_NUMBER = 'payment.vaNumber';
export const RXFIELD_ACCESSORY_TRANSACTION_TAX = 'tax';
export const RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_TOTAL = 'paymentTotal';
export const RXFIELD_ACCESSORY_TRANSACTION_GRAND_TOTAL = 'grandTotal';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEMS = 'items';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_NAME = 'item.accessory.name';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_TYPE = 'item.itemType';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_ID = 'item.accessory.id';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_VARIANT_ID = 'item.accessory.variantId';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_VARIANT_CODE = 'item.accessory.variantCode';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_PRICE = 'item.accessory.price';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DESCRIPTION = 'item.accessory.description';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_IMAGE = 'item.accessory.image';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_ORDER_ID = 'item.orderId';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_PICKUP_TIME = 'item.pickupTime';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_QUANTITY = 'item.quantity';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_UNIT_PRICE = 'item.unitPrice';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_TAX = 'item.tax';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_NOTES = 'item.notes';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_GRAND_TOTAL = 'item.subTotalWithTax';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_NAME = 'item.pickupLocation.name';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_ADDRESS = 'item.pickupLocation.address';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_POSTAL_CODE = 'item.pickupLocation.postalCode';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_CITY = 'item.pickupLocation.city';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_REGION = 'item.pickupLocation.region';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_PROVINCE = 'item.pickupLocation.province';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_TIMEZONE = 'item.pickupLocation.timeZone';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_EMAIL = 'item.pickupLocation.email';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_PHONE = 'item.pickupLocation.phone';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_STATUS = 'item.pickupLocation.dealerStatus';
export const RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_DEALER_TYPE = 'item.pickupLocation.dealerType';
export const RXFIELD_ACCESSORY_TRANSACTION_MEMBER_ID = 'user.userCode';
export const RXFIELD_ACCESSORY_TRANSACTION_EMAIL = 'user.email';
export const RXFIELD_ACCESSORY_TRANSACTION_ADDRESS = 'userAddress';

export const RXSTATE_ACCESSORY = 'accessories';
export const RXSTATE_ACCESSORY_PAGE = 'uiAccessory';
export const RXSTATE_INVOICES = 'invoices';
export const RXSTATE_INVOICE_PAGE = 'uiInvoice';
export const RXSTATE_ACCESSORY_TRANSACTIONS = 'accessoryTransactions';
export const RXSTATE_ACCESSORY_TRANSACTION_PAGE = 'uiAccessoryTransaction';

export const INITIAL_ORDER_BY_ACCESSORIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_INVOICES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VEHICLE_MODELS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_ACCESSORY_TRANSACTIONS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_GENUINE_ACCESSORY = 'MENUID_GENUINE_ACCESSORY';
export const MENUID_GENUINE_ACCESSORY_ACCESSORY = 'MENUID_GENUINE_ACCESSORY_ACCESSORY';
export const MENUID_GENUINE_ACCESSORY_INVOICE = 'MENUID_GENUINE_ACCESSORY_INVOICE';
export const MENUID_GENUINE_ACCESSORY_TRANSACTION = 'MENUID_GENUINE_ACCESSORY_TRANSACTION';

export const TRANSACTION_STATUS_WAITING_PAYMENT = 'WaitingPayment';
export const TRANSACTION_STATUS_IN_PROGRESS = 'InProgress';
export const TRANSACTION_STATUS_CANCELLED = 'Cancelled';
export const TRANSACTION_STATUS_FINISHED = 'Finished';
export const TRANSACTION_STATUS_ERROR = 'Error';

export const TRANSACTION_STATUSES = [
  { label: 'Waiting for Payment', value: TRANSACTION_STATUS_WAITING_PAYMENT },
  { label: 'Paid', value: TRANSACTION_STATUS_IN_PROGRESS },
  { label: 'Cancelled', value: `${TRANSACTION_STATUS_CANCELLED};${TRANSACTION_STATUS_ERROR}` },
  { label: 'Completed', value: TRANSACTION_STATUS_FINISHED },
];

export const BANK_TRANSFER_METHOD_BCA_VA = 'BcaVa';
export const BANK_TRANSFER_METHOD_BNI_VA = 'BniVa';
export const BANK_TRANSFER_METHOD_MANDIRI_VA = 'MandiriVa';
export const BANK_TRANSFER_METHOD_PERMATA_VA = 'PermataVa';
export const GOPAY_TRANSFER_METHOD = 'GoPay';

export const PAYMENT_STATUS_WAITING = 'WaitingPayment';

export const PAYMENT_METHODS = [
  {
    value: BANK_TRANSFER_METHOD_BCA_VA, label: 'BCA Virtual Account',
  },
  {
    value: BANK_TRANSFER_METHOD_BNI_VA, label: 'BNI Virtual Account',
  },
  {
    value: BANK_TRANSFER_METHOD_PERMATA_VA, label: 'Permata Virtual Account',
  },
  {
    value: BANK_TRANSFER_METHOD_MANDIRI_VA, label: 'Mandiri Virtual Account',
  },
  {
    value: GOPAY_TRANSFER_METHOD, label: 'GoPay',
  },
];
