import { downloadHyundaiChallengePrizeRedemption } from '../../../helper';
import { addHyundaiChallengePrizeRedemption, downloadingHyundaiChallengePrizeRedemption } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingHyundaiChallengePrizeRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiHyundaiChallengePrizeRedemption;

    const hyundaiChallengePrizeRedemption = await downloadHyundaiChallengePrizeRedemption(
      tappedId, token,
    );
    dispatch(addHyundaiChallengePrizeRedemption(hyundaiChallengePrizeRedemption));
  } finally {
    dispatch(downloadingHyundaiChallengePrizeRedemption(false));
  }
};
