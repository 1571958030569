
import { INITIAL_ORDER_BY_ACCESSORIES } from '../../constant';
import {
  DOWNLOADING_DELETING_ACCESSORY,
  DOWNLOADING_ACCESSORIES, SET_ACCESSORY_SEARCH_TEXT,
  DOWNLOADING_VEHICLE_MODELS,
  SET_ACCESSORY_SELECTED_ORDER_BY,
  SET_ACCESSORY_SELECTED_PAGE_SIZE,
  SET_ACCESSORY_TAPPED_ID,
  ADDING_EDITING_ACCESSORY,
  SET_ACCESSORY_IS_ACTIVE,
  SAVING_ACCESORIES,
  SYNCING_ACCESORIES,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  downloadingVehicleModels: false,
  saving: false,
  syncing: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_ACCESSORIES,
  isActive: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ACCESSORIES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_ACCESSORY:
      return { ...state, downloadingDeleting: action.status };
    case DOWNLOADING_VEHICLE_MODELS:
      return { ...state, downloadingVehicleModels: action.status };
    case SAVING_ACCESORIES:
      return { ...state, saving: action.status };
    case SYNCING_ACCESORIES:
      return { ...state, syncing: action.status };
    case ADDING_EDITING_ACCESSORY:
      return { ...state, addingEditing: action.status };
    case SET_ACCESSORY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ACCESSORY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ACCESSORY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ACCESSORY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ACCESSORY_IS_ACTIVE:
      return { ...state, isActive: action.status };
    default: return state;
  }
};
