import {
  downloadingDeletingCompletedProfile,
  addCompletedProfile,
} from '../simple-action';
import { downloadCompletedProfile } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiCompletedProfile } = getState();

  const { token } = authentication;
  const { tappedId } = uiCompletedProfile;

  try {
    dispatch(downloadingDeletingCompletedProfile(true));

    const completedProfile = await downloadCompletedProfile(
      tappedId,
      token,
    );

    dispatch(addCompletedProfile(completedProfile));
  } finally {
    dispatch(downloadingDeletingCompletedProfile(false));
  }
};
