import { reset } from 'redux-form';
import {
  changingPassword, setChangePasswordSuccess, setSettingsDialogVisibility,
} from '../simple-action';
import { changePassword, validatePasswordComplexity } from '../../../helper';
import { RXFORM_SETTINGS_DIALOG } from '../../../constant';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(changingPassword(true));

    const { token } = getState().authentication;

    await validatePasswordComplexity(values.newPassword);
    await changePassword(values, token);

    dispatch(reset(RXFORM_SETTINGS_DIALOG));
    dispatch(setSettingsDialogVisibility(false));
    dispatch(setChangePasswordSuccess(true));
  } finally {
    dispatch(changingPassword(false));
  }
};
