import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { CircularProgress, Grid } from '@material-ui/core';
import { FunctionalPage, SectionTitle } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormEditableTableField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT,
  RXSTATE_RECURRANCE_REFERRAL_REWARD_PAGE,
  RXSTATE_RECURRANCE_REFERRAL_REWARDS,
  REWARD_STATUS_DROPDOWN_SELECTION,
  RXFORM_RECURRANCE_REFERRAL_REWARD,
  RXFIELD_RECURRANCE_REFERRAL_REWARD_USER_ID,
  RXFIELD_RECURRANCE_REFERRAL_REWARD_USER_NAME,
  RXFIELD_RECURRANCE_REFERRAL_REWARD_REWARD_STATUS,
  RXFIELD_RECURRANCE_REFERRAL_REWARD_REWARD_STATUS_NOTES,
  RXFIELD_RECURRANCE_REFERRAL_REWARD_VOUCHER_REWARDS,
  RXFIELD_RECURRANCE_REFERRAL_REWARD_REGISTRATION_REFERRALS,
} from '../../constant';

const renderDialogContent = (downloadingDeleting, initialValues) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_RECURRANCE_REFERRAL_REWARD_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.recurrenceReferralRewardPage.labelUserID}
            label={LocalizedString.recurrenceReferralRewardPage.labelUserID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RECURRANCE_REFERRAL_REWARD_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.recurrenceReferralRewardPage.labelUserName}
            label={LocalizedString.recurrenceReferralRewardPage.labelUserName}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_RECURRANCE_REFERRAL_REWARD_REWARD_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.recurrenceReferralRewardPage.labelRewardStatus}
            label={LocalizedString.recurrenceReferralRewardPage.labelRewardStatus}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_RECURRANCE_REFERRAL_REWARD_REWARD_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.recurrenceReferralRewardPage.labelRewardStatusNotes}
            label={LocalizedString.recurrenceReferralRewardPage.labelRewardStatusNotes}
            disabled
            multiline
          />
        </Grid>
      </Grid>
    </Grid>

    <Grid>
      <SectionTitle title={LocalizedString.recurrenceReferralRewardPage.labelRewards} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_RECURRANCE_REFERRAL_REWARD_VOUCHER_REWARDS}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.recurrenceReferralRewardPage.labelVoucherRewards}
            disabled
            defaultValue={initialValues.vourcherRewards}
            disableDelete
            disableToolbar
            disableCreate
            disableEdit
            tableColumns={[
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelVoucherID,
                field: 'voucherId',
                sorting: false,
              },
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelVoucherName,
                field: 'voucherName',
                sorting: false,
              },
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelStatus,
                field: 'rewardStatus',
                sorting: false,
              },
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelNotes,
                field: 'rewardStatusNotes',
                sorting: false,
              },
            ]}
            required
          />
        </Grid>
      )}
    </Grid>

    <Grid>
      <SectionTitle
        title={LocalizedString.recurrenceReferralRewardPage.labelRegistrationReferrals}
      />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_RECURRANCE_REFERRAL_REWARD_REGISTRATION_REFERRALS}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.recurrenceReferralRewardPage.labelRegistrationReferrals}
            disabled
            defaultValue={initialValues.registrationReferrals}
            disableDelete
            disableToolbar
            disableCreate
            disableEdit
            tableColumns={[
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelID,
                field: 'id',
                sorting: false,
              },
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelCreatedDate,
                field: 'createdDate',
                sorting: false,
              },
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelRegisteredUserID,
                field: 'referee.id',
                sorting: false,
              },
              {
                title: LocalizedString.recurrenceReferralRewardPage.labelRegisteredUserName,
                field: 'referee.fullName',
                sorting: false,
              },
            ]}
            required
          />
        </Grid>
      )}
    </Grid>
  </Grid>
);

const RecurrenceReferralRewardPage = ({
  onAppear, downloading, downloadingDeleting, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText,
}) => (
  <FunctionalPage
    data={RXSTATE_RECURRANCE_REFERRAL_REWARDS}
    uiPage={RXSTATE_RECURRANCE_REFERRAL_REWARD_PAGE}
    tableColumns={[
      {
        title: LocalizedString.recurrenceReferralRewardPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.recurrenceReferralRewardPage.labelUserName, field: 'profile.fullName', sorting: !downloading },
      { title: LocalizedString.recurrenceReferralRewardPage.labelRewardStatus, field: 'rewardStatus', sorting: !downloading },
      {
        title: LocalizedString.recurrenceReferralRewardPage.labelVoucherRewards,
        field: 'vourcherRewards',
        sorting: false,
        render: ({ vourcherRewards }) => {
          let rewards = '';
          vourcherRewards.forEach((reward, index) => {
            if (index === 0) rewards += `${reward.voucherName}`;
            rewards += ` | ${reward.voucherName}`;
          });
          return rewards;
        },
      },
      {
        title: LocalizedString.recurrenceReferralRewardPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.recurrenceReferralRewardPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.recurrenceReferralRewardPage.labelUser,
        field: 'registeredUser',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.recurrenceReferralRewardPage.labelRewardStatus,
        field: 'rewardStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: REWARD_STATUS_DROPDOWN_SELECTION,
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.recurrenceReferralRewardPage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(downloadingDeleting, initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_RECURRANCE_REFERRAL_REWARD,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RecurrenceReferralRewardPage);

RecurrenceReferralRewardPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
};
