import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch,
} from '../../helper';

import {
  INITIAL_ORDER_BY_JAW_2023_TRANSACTION,
  MENUID_JAW_2023_TRANSACTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import Jaw2023TransactionPage from './jaw-2023-transaction.presentation';
import {
  clearJaw2023Transactions, setJaw2023TransactionAdvancedFilterString,
  setJaw2023TransactionSearchText, setJaw2023TransactionSelectedOrderBy,
  setJaw2023TransactionSelectedPageSize, setJaw2023TransactionTappedId,
  downloadJaw2023TransactionAsync,
  downloadJaw2023TransactionsAsync,
  saveJaw2023TransactionsAsync,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { jaw2023Transactions, uiFunctionalPage, uiJaw2023Transaction } = state;
  const { data } = jaw2023Transactions;
  const { downloadingDeleting, tappedId } = uiJaw2023Transaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiJaw2023Transaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_JAW_2023_TRANSACTION));
    dispatch(setJaw2023TransactionAdvancedFilterString(''));
    dispatch(setJaw2023TransactionSearchText(''));
    dispatch(clearJaw2023Transactions());
    dispatch(setJaw2023TransactionSelectedPageSize(20));
    dispatch(
      setJaw2023TransactionSelectedOrderBy(INITIAL_ORDER_BY_JAW_2023_TRANSACTION),
    );
    dispatch(downloadJaw2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setJaw2023TransactionSelectedOrderBy(orderBy));
    dispatch(clearJaw2023Transactions());
    dispatch(downloadJaw2023TransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setJaw2023TransactionTappedId(id));
    dispatch(downloadJaw2023TransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setJaw2023TransactionSearchText(text));
    dispatch(clearJaw2023Transactions());
    dispatch(downloadJaw2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadJaw2023TransactionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setJaw2023TransactionSelectedPageSize(pageSize));
    dispatch(downloadJaw2023TransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setJaw2023TransactionSelectedPageSize(pageSize));
    dispatch(clearJaw2023Transactions());
    dispatch(downloadJaw2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearJaw2023Transactions());
    dispatch(setJaw2023TransactionAdvancedFilterString(''));
    dispatch(
      setJaw2023TransactionSelectedOrderBy(INITIAL_ORDER_BY_JAW_2023_TRANSACTION),
    );
    dispatch(downloadJaw2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id');

    dispatch(setJaw2023TransactionAdvancedFilterString(text));
    dispatch(clearJaw2023Transactions());
    dispatch(downloadJaw2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveJaw2023TransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Jaw2023TransactionPage);
