import { INITIAL_ORDER_BY_JAW_2023_SPKS } from '../../constant';
import {
  DOWNLOADING_JAW_2023_SPK, DOWNLOADING_JAW_2023_SPKS,
  SAVING_JAW_2023_2022_SPKS, SET_JAW_2023_SPKS_FILTER_STRING,
  SET_JAW_2023_SPKS_ORDER_BY, SET_JAW_2023_SPKS_PAGE_SIZE,
  SET_JAW_2023_SPKS_SEARCH_TEXT, SET_JAW_2023_SPKS_TAPPED_ID,
} from '../action';

const initialState = {
  downloading: false,
  downloadingSpk: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_JAW_2023_SPKS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_JAW_2023_SPKS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_JAW_2023_SPK:
      return { ...state, downloadingSpk: action.status };
    case SET_JAW_2023_SPKS_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_JAW_2023_SPKS_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_JAW_2023_SPKS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_JAW_2023_SPKS_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_JAW_2023_SPKS_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SAVING_JAW_2023_2022_SPKS:
      return { ...state, saving: action.status };
    default: return state;
  }
};
