import {
  buildQueryParams, sendDeleteRequest, sendGetFileRequest, sendGetRequest,
  sendPostRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_VIEW_WASTE_COLLECTION, REST_URL_WASTE_COLLECTIONS, REST_URL_SAVE_FILE,
  REST_URL_DEALER_CODES, REST_URL_ADD_EDIT_WASTE_DEALER_CODE, REST_URL_DELETE_WASTE_DEALER_CODE,
  REST_URL_VIEW_WASTE_DEALER_CODE,
} from './constant';

export * from '../../helper';

export const downloadWasteCollections = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_WASTE_COLLECTIONS
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_WASTE_COLLECTIONS, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadWasteCollection = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_WASTE_COLLECTION
    .replace(/\{id\}/, id), token);
  return response;
};

export const saveWasteCollections = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_WASTE_COLLECTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadWasteDealerCodes = async (
  pageNumber, pageSize, orderBy, searchText, filterString,
  token,
) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DEALER_CODES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_DEALER_CODES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);

  return transformQueryResult(response);
};

export const downloadWasteDealerCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_WASTE_DEALER_CODE
    .replace(/\{id\}/, id), token);

  return response;
};

export const addWasteDealerCode = async (dealerId, token) => {
  const body = { dealerId };
  await sendPostRequest(REST_URL_ADD_EDIT_WASTE_DEALER_CODE, body, token);
};

export const deleteWasteDealerCode = async (id, token) => {
  await sendDeleteRequest(REST_URL_DELETE_WASTE_DEALER_CODE.replace(/\{id\}/, id), token);
};
