import {
  DOWNLOADING_CHALLENGES, ADDING_EDITING_CHALLENGE, DOWNLOADING_DELETING_CHALLENGE,
  SET_CHALLENGE_SELECTED_PAGE_SIZE, SET_CHALLENGE_TAPPED_ID, SET_CHALLENGE_SEARCH_TEXT,
  SET_CHALLENGE_SELECTED_ORDER_BY, SET_CHALLENGE_SELECTED_CATEGORY,
  SET_CHALLENGE_SELECTED_VISIBILITY_STATUS, SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU,
  SET_CHALLENGE_SELECTED_SCAN_POLICY, SET_CHALLENGE_SELECTED_IS_PROMOTED,
  SET_CHALLENGE_SCAN_LIMIT_MODE, SET_CHALLENGE_SCAN_LIMIT_TIMEZONE,
  COPYING_CHALLENGE_DEEP_LINK, SET_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,

  SET_CHALLENGE_SELECTED_VOUCHER,
  SET_CHALLENGE_SELECTED_COMPLETE_REWARD,
  SET_CHALLENGE_SELECTED_BYPASS_MAX_PURCHASE,
  SET_CHALLENGE_SELECTED_BYPASS_ACHIEVEMENT,
  SET_CHALLENGE_SELECTED_ENABLE_BUDGET,
} from '../action';
import { INITIAL_ORDER_BY_CHALLENGES } from '../../constant';

const initialState = {
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  selectedCategory: '',
  selectedScanPolicy: '',
  selectedVisibilityStatus: '',
  selectedScanLimitMode: '',
  selectedScanLimitTimezone: '',
  orderBy: INITIAL_ORDER_BY_CHALLENGES,
  selectedAddMediaMenu: null,
  selectedIsPromoted: '',
  filterString: '',

  selectedVoucher: null,
  selectedBypassMaxPurchase: null,
  selectedBypassAchievement: null,
  selectedCompleteReward: null,
  selectedEnableBudget: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CHALLENGES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_CHALLENGE:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_CHALLENGE:
      return { ...state, downloadingDeleting: action.status };
    case COPYING_CHALLENGE_DEEP_LINK:
      return { ...state, copying: action.status };
    case SET_CHALLENGE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CHALLENGE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CHALLENGE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CHALLENGE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CHALLENGE_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.option };
    case SET_CHALLENGE_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.option };
    case SET_CHALLENGE_SELECTED_ADD_MEDIA_MENU:
      return { ...state, selectedAddMediaMenu: action.text };
    case SET_CHALLENGE_SELECTED_SCAN_POLICY:
      return { ...state, selectedScanPolicy: action.option };
    case SET_CHALLENGE_SELECTED_IS_PROMOTED:
      return { ...state, selectedIsPromoted: action.option };
    case SET_CHALLENGE_SCAN_LIMIT_MODE:
      return { ...state, selectedScanLimitMode: action.option };
    case SET_CHALLENGE_SCAN_LIMIT_TIMEZONE:
      return { ...state, selectedScanLimitTimezone: action.option };
    case SET_CHALLENGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };

    case SET_CHALLENGE_SELECTED_VOUCHER:
      return { ...state, selectedVoucher: action.value };
    case SET_CHALLENGE_SELECTED_COMPLETE_REWARD:
      return { ...state, selectedCompleteReward: action.value };
    case SET_CHALLENGE_SELECTED_BYPASS_MAX_PURCHASE:
      return { ...state, selectedBypassMaxPurchase: action.value };
    case SET_CHALLENGE_SELECTED_BYPASS_ACHIEVEMENT:
      return { ...state, selectedBypassAchievement: action.value };
    case SET_CHALLENGE_SELECTED_ENABLE_BUDGET:
      return { ...state, selectedEnableBudget: action.option };
    default: return state;
  }
};
