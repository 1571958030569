import { reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  addingEditingTestDriveEventOrgaizer, clearTestDriveEventOrganizers,
  setAlertErrorMessage, setFunctionalPageMode, setTestDriveEventOrganizerSelectedEvent,
  setTestDriveEventOrganizerSelectedUser,
} from '../simple-action';
import { addTestDriveEventOrganizer, editTestDriveEventOrganizer } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_TEST_DRIVE_EVENT_ORGANIZER } from '../../../constant';
import downloadTestDriveEventOrganizersAsync from './downloadTestDriveEventOrganizersAsync';

const getValue = (selectedOption, found) => {
  if (isEmpty(selectedOption)) return found;
  return selectedOption.value;
};

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingTestDriveEventOrgaizer(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedEvent, selectedUser,
    } = getState().uiEventOrganizer;

    if (tappedId) {
      const { data } = getState().eventOrganizers;
      const found = data[tappedId];

      const editUser = getValue(selectedUser, found.profile.id);
      const editEvent = getValue(selectedEvent, found.event.id);

      await editTestDriveEventOrganizer(tappedId, editUser, editEvent, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addTestDriveEventOrganizer(selectedUser.value, selectedEvent.value, token);
    }

    dispatch(setTestDriveEventOrganizerSelectedUser(''));
    dispatch(setTestDriveEventOrganizerSelectedEvent(''));
    dispatch(reset(RXFORM_TEST_DRIVE_EVENT_ORGANIZER));
    dispatch(clearTestDriveEventOrganizers());
    dispatch(downloadTestDriveEventOrganizersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingTestDriveEventOrgaizer(false));
  }
};
