import { downloadTransactionAmountByPartName } from '../../../helper';
import { downloadingTransactionAmountByPartName, setTransactionAmountByPartName } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingTransactionAmountByPartName(true));
    const bookingCreated = await downloadTransactionAmountByPartName(token, startDate, endDate);

    dispatch(setTransactionAmountByPartName(bookingCreated));
  } finally {
    dispatch(downloadingTransactionAmountByPartName(false));
  }
};
