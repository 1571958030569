import { claimVoucherTokenByCode } from '../../../helper';
import {
  setVoucherTokenByCode, claimingVoucherTokenByCode,
  clearVoucherTokenByCode,
} from '../simple-action';

export default (notes, code) => async (dispatch, getState) => {
  try {
    dispatch(claimingVoucherTokenByCode(true));

    const { token } = getState().authentication;
    const merchantId = getState().voucherTokenByCode?.data?.voucher?.merchant?.id;

    const voucher = await claimVoucherTokenByCode(code, notes, merchantId, token);

    dispatch(clearVoucherTokenByCode());
    dispatch(setVoucherTokenByCode(voucher));
  } finally {
    dispatch(claimingVoucherTokenByCode(false));
  }
};
