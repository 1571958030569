import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_EV_CHARGING_URLS,
  MENUID_CAR_SERVICE_EV_CHARGING_URL,
  PAGE_MODE_TABLE,
  RXFORM_CAR_SERVICE_EV_CHARGING_URL,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import {
  addEditEvChargingUrlAsync,
  clearEvChargingUrls,
  downloadEvChargingUrlsAsync,
  downloadDeleteEvChargingUrlAsync,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setEvChargingUrlAdvancedFilterDialogSelectedFilterString,
  setEvChargingUrlSearchText,
  setEvChargingUrlSelectedOrderBy,
  setEvChargingUrlSelectedPageSize,
  setEvChargingUrlTappedId,
  setAlertConfirmationMessage,
} from '../../redux/action';
import GlobalLocalizedString from '../../../../localization';
import EvChargingUrlPage from './ev-charging-url.presentation';

const getInitialValues = (state) => {
  const { evChargingUrls, uiFunctionalPage, uiEvChargingUrl } = state;
  const { data } = evChargingUrls;
  const { downloadingDeleting, tappedId } = uiEvChargingUrl;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      code: found.code ? found.code : '',
      url: found.url ? found.url : '',
      notes: found.notes ? found.notes : '',
    })
    : {};
  return result;
};

const mapStateToProps = (state) => ({
  addingEditing: state.uiEvChargingUrl.addingEditing,
  downloading: state.uiEvChargingUrl.downloading,
  downloadingDeleting: state.uiEvChargingUrl.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiEvChargingUrl.tappedId,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setEvChargingUrlAdvancedFilterDialogSelectedFilterString(''));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_SERVICE_EV_CHARGING_URL));
    dispatch(setEvChargingUrlSearchText(''));
    dispatch(setEvChargingUrlAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEvChargingUrls());
    dispatch(setEvChargingUrlSelectedPageSize(20));
    dispatch(setEvChargingUrlSelectedOrderBy(INITIAL_ORDER_BY_EV_CHARGING_URLS));
    dispatch(downloadEvChargingUrlsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setEvChargingUrlAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearEvChargingUrls());
    dispatch(downloadEvChargingUrlsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CAR_SERVICE_EV_CHARGING_URL));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEvChargingUrlsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEvChargingUrlSelectedPageSize(pageSize));
    dispatch(downloadEvChargingUrlsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteEvChargingUrlAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setEvChargingUrlTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setEvChargingUrlTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setEvChargingUrlTappedId(id));
  },
  onSavePressed: async ({
    code, url, notes,
  }) => {
    try {
      await dispatch(addEditEvChargingUrlAsync(code, url, notes));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setEvChargingUrlSelectedPageSize(pageSize));
    dispatch(clearEvChargingUrls());
    dispatch(downloadEvChargingUrlsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearEvChargingUrls());
    dispatch(setEvChargingUrlAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEvChargingUrlSelectedOrderBy(INITIAL_ORDER_BY_EV_CHARGING_URLS));
    dispatch(downloadEvChargingUrlsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEvChargingUrlSearchText(text));
      dispatch(clearEvChargingUrls());
      await dispatch(downloadEvChargingUrlsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEvChargingUrlSelectedOrderBy(orderBy));
    dispatch(clearEvChargingUrls());
    dispatch(downloadEvChargingUrlsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSubmitPressed: async ({
    code, url, notes,
  }) => {
    await dispatch(addEditEvChargingUrlAsync(code, url, notes));
  },
  onViewPressed: (id) => {
    dispatch(setEvChargingUrlTappedId(id));
    dispatch(downloadDeleteEvChargingUrlAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EvChargingUrlPage);
