import {
  addWasteDealerCode, clearWasteDealerCodes, downloadingDeletingWasteDealerCode,
  setAlertErrorMessage, setFunctionalPageMode, setWasteDealerCodeTappedId,
} from '../simple-action';
import { downloadWasteDealerCode, deleteWasteDealerCode } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadWasteDealerCodesAsync from './downloadWasteDealerCodesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingWasteDealerCode(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWasteDealerCode;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteWasteDealerCode(tappedId, token);
      dispatch(setWasteDealerCodeTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearWasteDealerCodes());
      dispatch(downloadWasteDealerCodesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const wasteDealerCode = await downloadWasteDealerCode(tappedId, token);
      dispatch(addWasteDealerCode(wasteDealerCode));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingWasteDealerCode(false));
  }
};
