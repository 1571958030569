import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FunctionalPage } from '../../../../component';
import {
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_CREATED_DATE, RXFIELD_POINT_TRANSACTION_AMOUNT,
  RXFIELD_POINT_TRANSACTION_EFFECTIVE_DATE, RXFIELD_POINT_TRANSACTION_LABEL,
  RXFIELD_POINT_TRANSACTION_MERCHANT_ID, RXFIELD_POINT_TRANSACTION_MERCHANT_NAME,
  RXFIELD_POINT_TRANSACTION_MERCHANT_REFERENCE_ID, RXFIELD_POINT_TRANSACTION_REFERENCE_ID,
  RXFIELD_POINT_TRANSACTION_TAGS, RXFIELD_POINT_TRANSACTION_TRANSACTION_NUMBER,
  RXFIELD_POINT_TRANSACTION_TYPE, RXFIELD_POINT_TRANSACTION_USER, RXFORM_POINT_TRANSACTION,
  RXSTATE_POINT_TRANSACTIONS, RXSTATE_POINT_TRANSACTION_PAGE, TRANSACTION_TYPE_VALUES,
  STATUS_DISABLED, COLOR_DISABLED_ROW, COLOR_BACKGROUND, DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_POINT_TRANSACTION_ITEMS, RXFIELD_POINT_TRANSACTION_USER_ID,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormEditableTableField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const renderDialogContent = (initialValues) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_TRANSACTION_NUMBER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderTransactionNumber}
            label={LocalizedString.pointTransactionPage.placeholderTransactionNumber}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderUserId}
            label={LocalizedString.pointTransactionPage.placeholderUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_USER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderUserName}
            label={LocalizedString.pointTransactionPage.placeholderUserName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_TYPE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderType}
            label={LocalizedString.pointTransactionPage.placeholderType}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderLabel}
            label={LocalizedString.pointTransactionPage.placeholderLabel}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_TAGS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderTags}
            label={LocalizedString.pointTransactionPage.placeholderTags}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_EFFECTIVE_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderEffectiveDate}
            label={LocalizedString.pointTransactionPage.placeholderEffectiveDate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderAmount}
            label={LocalizedString.pointTransactionPage.placeholderAmount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderReferenceId}
            label={LocalizedString.pointTransactionPage.placeholderReferenceId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_MERCHANT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderMerchantId}
            label={LocalizedString.pointTransactionPage.placeholderMerchantId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderMerchantName}
            label={LocalizedString.pointTransactionPage.placeholderMerchantName}
            multiline
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_TRANSACTION_MERCHANT_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointTransactionPage.placeholderMerchantReferenceID}
            label={LocalizedString.pointTransactionPage.placeholderMerchantReferenceID}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
    {!isEmpty(initialValues.items) && (
    <Grid item>
      <Field
        name={RXFIELD_POINT_TRANSACTION_ITEMS}
        component={renderReduxFormEditableTableField}
        label={LocalizedString.pointTransactionPage.labelTransactionItems}
        defaultValue={initialValues.items}
        disabled
        tableColumns={[
          {
            title: LocalizedString.pointTransactionPage.labelNo, field: 'no', sorting: false, width: 40,
          },
          { title: LocalizedString.pointTransactionPage.labelProductName, field: 'productName', sorting: false },
          { title: LocalizedString.pointTransactionPage.labelProductCode, field: 'productCode', sorting: false },
          { title: LocalizedString.pointTransactionPage.labelQuantity, field: 'quantity', sorting: false },
          { title: LocalizedString.pointTransactionPage.labelIndividualPrice, field: 'individualPrice', sorting: false },
          { title: LocalizedString.pointTransactionPage.labelTotalPrice, field: 'totalPrice', sorting: false },
        ]}
      />
    </Grid>
    )}
  </Grid>
);

const PointTransactionPage = ({
  users, downloading, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  initialValues,
}) => (
  <FunctionalPage
    data={RXSTATE_POINT_TRANSACTIONS}
    uiPage={RXSTATE_POINT_TRANSACTION_PAGE}
    filterColumns={[
      {
        title: LocalizedString.pointTransactionPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointTransactionPage.placeholderEffectiveDate,
        field: RXFIELD_POINT_TRANSACTION_EFFECTIVE_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointTransactionPage.placeholderMerchantName,
        field: RXFIELD_POINT_TRANSACTION_MERCHANT_NAME,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointTransactionPage.placeholderMerchantReferenceID,
        field: RXFIELD_POINT_TRANSACTION_MERCHANT_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointTransactionPage.placeholderTransactionNumber,
        field: RXFIELD_POINT_TRANSACTION_TRANSACTION_NUMBER,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointTransactionPage.placeholderUserName,
        field: RXFIELD_POINT_TRANSACTION_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.pointTransactionPage.placeholderType,
        field: RXFIELD_POINT_TRANSACTION_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: TRANSACTION_TYPE_VALUES,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.pointTransactionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.pointTransactionPage.labelTransactionNumber, field: 'transactionNumber', sorting: !downloading },
      { title: LocalizedString.pointTransactionPage.labelUserName, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.pointTransactionPage.labelAmount, field: 'amount', sorting: !downloading },
      { title: LocalizedString.pointTransactionPage.labelLabel, field: 'label', sorting: !downloading },
      { title: LocalizedString.pointTransactionPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
      {
        title: LocalizedString.pointTransactionPage.labelEffectiveDate,
        field: 'effectiveDate',
        sorting: !downloading,
        render: ({ effectiveDate }) => (effectiveDate
          ? toMoment(effectiveDate).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.pointTransactionPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
    rowStyle={({ status, effectiveDate }) => {
      const currentTime = toMoment(moment().format()).format(DATE_TIME_FORMAT_WITHOUT_PIPE);
      const formattedEffectiveDate = toMoment(effectiveDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE);
      const isFutureDate = toMoment(formattedEffectiveDate).isAfter(currentTime);
      return ({
        backgroundColor: isFutureDate || status === STATUS_DISABLED
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      });
    }}
  >
    {renderDialogContent(initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_POINT_TRANSACTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PointTransactionPage);

PointTransactionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
