import { downloadGiiasMedanTransaction } from '../../../helper';
import { addGiiasMedan2022Transaction, downloadingGiiasMedan2022Transaction } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiiasMedan2022Transaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiiasMedanTransaction;

    const giias2022Transaction = await downloadGiiasMedanTransaction(tappedId, token);
    dispatch(addGiiasMedan2022Transaction(giias2022Transaction));
  } finally {
    dispatch(downloadingGiiasMedan2022Transaction(false));
  }
};
