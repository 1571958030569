import {
  REST_URL_COUPONS, REST_URL_VIEW_DELETE_COUPON, REST_URL_COUPON_SUBMISSIONS,
  REST_URL_VIEW_DELETE_COUPON_SUBMISSION, REST_URL_COUPON_TRANSACTIONS,
  REST_URL_VIEW_DELETE_COUPON_TRANSACTION, REST_URL_COUPON_BALANCE, REST_URL_COUPON_ACHIEVEMENT,
  REST_URL_VIEW_COUPON_PURCHASE_REQUEST,
  REST_URL_COUPON_PURCHASE_REQUESTS, REST_URL_VOID_COUPON_REQUESTS,
  REST_URL_VIEW_VOID_COUPON_REQUEST,
  REST_URL_ADD_VOID_COUPON_REQUEST, REST_URL_COUPON_LEADER_BOARD,
  REST_URL_REPROCESS_COUPON_SUBMISSION, COUPON_SUBMISSION_REQUEST_STATUS_ERROR,
  REST_URL_ADD_COUPON_SUBMISSION,
} from './constant';
import {
  buildQueryParams, localDateToUtc, sendGetRequest, sendPostRequest, transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadCoupons = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_COUPONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const responseDataWithHideContextMenu = response.data.map((x) => ({
    ...x,
    hideContextMenu: x.isVoided !== false,
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadCoupon = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_COUPON.replace(/\{id\}/, id), token);
  return response;
};

export const downloadCouponSubmissions = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_COUPON_SUBMISSIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const responseDataWithHideContextMenu = response.data.map((x) => ({
    ...x,
    hideContextMenu: x.requestStatus !== COUPON_SUBMISSION_REQUEST_STATUS_ERROR,
  }));
  return transformQueryResult({ ...response, data: responseDataWithHideContextMenu });
};

export const downloadCouponSubmission = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_COUPON_SUBMISSION.replace(/\{id\}/, id),
    token);
  return response;
};

export const reprocessCouponSubmission = async (id, token) => {
  await sendPostRequest(REST_URL_REPROCESS_COUPON_SUBMISSION.replace(/{id}/, id), '', token);
};

export const addCouponSubmission = async (merchantReferenceId, tags, amount, validUntil, label,
  userId, callbackUrl, sendNotification, token) => {
  const body = {
    merchantReferenceId,
    tags,
    amount,
    validUntil,
    label,
    userId,
    callbackUrl,
    sendNotification,
  };
  await sendPostRequest(REST_URL_ADD_COUPON_SUBMISSION, body, token);
};

export const downloadCouponTransactions = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_COUPON_TRANSACTIONS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCouponTransaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_COUPON_TRANSACTION.replace(/\{id\}/, id),
    token);
  return response;
};

export const downloadCouponAchievement = async (id, token) => {
  const response = await sendGetRequest(REST_URL_COUPON_ACHIEVEMENT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadCouponBalance = async (id, token) => {
  const response = await sendGetRequest(REST_URL_COUPON_BALANCE.replace(/\{id\}/, id), token);
  return response;
};

export const downloadCouponLeaderBoard = async (startRank, count, startPeriod, endPeriod,
  token) => {
  const START_PERIOD_KEY = startPeriod ? `&startPeriod=${localDateToUtc(startPeriod)}` : '';
  const END_PERIOD_KEY = endPeriod ? `&endPeriod=${localDateToUtc(endPeriod)}` : '';
  const url = REST_URL_COUPON_LEADER_BOARD.replace(/\{startRank\}/, startRank).replace(/\{count\}/, count)
    .concat(START_PERIOD_KEY).concat(END_PERIOD_KEY);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadCouponPurchaseRequests = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_COUPON_PURCHASE_REQUESTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCouponPurchaseRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_COUPON_PURCHASE_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const downloadVoidCouponRequests = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_VOID_COUPON_REQUESTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadVoidCouponRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_VOID_COUPON_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const addVoidCouponRequest = async (reason, merchantReferenceId, callbackUrl, pointId,
  sendNotification = false, voidingCoupon, token) => {
  const body = {
    reason,
    merchantReferenceId,
    callbackUrl,
    pointId,
  };
  const newBody = voidingCoupon ? { ...body, sendNotification } : body;
  await sendPostRequest(REST_URL_ADD_VOID_COUPON_REQUEST, newBody, token);
};
