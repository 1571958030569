import {
  downloadingDeletingDailyCheckin,
  addDailyCheckin,
} from '../simple-action';
import { downloadDailyCheckin } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiDailyCheckin } = getState();

  const { token } = authentication;
  const { tappedId } = uiDailyCheckin;

  try {
    dispatch(downloadingDeletingDailyCheckin(true));

    const dailyCheckin = await downloadDailyCheckin(
      tappedId,
      token,
    );

    dispatch(addDailyCheckin(dailyCheckin));
  } finally {
    dispatch(downloadingDeletingDailyCheckin(false));
  }
};
