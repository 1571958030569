import {
  GARUDA_FLAG, TANAMERA_FLAG, VOUCHER_TAGS,
} from '../../../constant';
import { downloadVoucher, transformApplicableBranches } from '../../../helper';
import {
  addVoucher, downloadingDeletingVoucher, setVoucherTableDataApplicableBranches,
  setVoucherSelectedGarudaFlag, setVoucherSelectedTags, setVoucherSelectedTanameraFlag,
} from '../simple-action';
import { getDeepLinkScheme } from '../../../../configuration/helper';

const DEEP_LINK_PATH = 'app/voucher/detail/';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingVoucher(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoucher;

    const voucher = await downloadVoucher(tappedId, token);

    const result = await getDeepLinkScheme(voucher, DEEP_LINK_PATH, tappedId, token);

    dispatch(addVoucher(result));

    dispatch(setVoucherTableDataApplicableBranches(transformApplicableBranches(
      result.applicableBranches,
    )));
    dispatch(setVoucherSelectedTags(result.tags
      ? { label: result.tags, value: result.tags } : VOUCHER_TAGS[0]));
    dispatch(setVoucherSelectedTanameraFlag(result.tanameraFlag
      ? { label: result.tanameraFlag, value: result.tanameraFlag } : TANAMERA_FLAG[0]));
    dispatch(setVoucherSelectedGarudaFlag(result.garudaFlag
      ? { label: result.garudaFlag, value: result.garudaFlag } : GARUDA_FLAG[0]));
  } finally {
    dispatch(downloadingDeletingVoucher(false));
  }
};
