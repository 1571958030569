import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch,
} from '../../helper';

import {
  INITIAL_ORDER_BY_GIIAS_2022_TRANSACTION,
  MENUID_GIIAS_2022_TRANSACTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import Giias2022TransactionPage from './giias-2022-transaction.presentation';
import {
  clearGiias2022Transactions, setGiias2022TransactionAdvancedFilterString,
  setGiias2022TransactionSearchText, setGiias2022TransactionSelectedOrderBy,
  setGiias2022TransactionSelectedPageSize, setGiias2022TransactionTappedId,
} from '../../redux/action/simple-action';
import { downloadGiias2022TransactionAsync, downloadGiias2022TransactionsAsync, saveGiias2022TransactionsAsync } from '../../redux/action';


const getInitialValues = (state) => {
  const { giias2022Transactions, uiFunctionalPage, uiGiias2022Transaction } = state;
  const { data } = giias2022Transactions;
  const { downloadingDeleting, tappedId } = uiGiias2022Transaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiGiias2022Transaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_2022_TRANSACTION));
    dispatch(setGiias2022TransactionAdvancedFilterString(''));
    dispatch(setGiias2022TransactionSearchText(''));
    dispatch(clearGiias2022Transactions());
    dispatch(setGiias2022TransactionSelectedPageSize(20));
    dispatch(
      setGiias2022TransactionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_2022_TRANSACTION),
    );
    dispatch(downloadGiias2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiias2022TransactionSelectedOrderBy(orderBy));
    dispatch(clearGiias2022Transactions());
    dispatch(downloadGiias2022TransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiias2022TransactionTappedId(id));
    dispatch(downloadGiias2022TransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setGiias2022TransactionSearchText(text));
    dispatch(clearGiias2022Transactions());
    dispatch(downloadGiias2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiias2022TransactionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiias2022TransactionSelectedPageSize(pageSize));
    dispatch(downloadGiias2022TransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiias2022TransactionSelectedPageSize(pageSize));
    dispatch(clearGiias2022Transactions());
    dispatch(downloadGiias2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGiias2022Transactions());
    dispatch(setGiias2022TransactionAdvancedFilterString(''));
    dispatch(
      setGiias2022TransactionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_2022_TRANSACTION),
    );
    dispatch(downloadGiias2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id');

    dispatch(setGiias2022TransactionAdvancedFilterString(text));
    dispatch(clearGiias2022Transactions());
    dispatch(downloadGiias2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveGiias2022TransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Giias2022TransactionPage);
