import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_GIIAS_SEMARANG_PRIZE_REDEMPTIONS, REST_URL_GIIAS_SEMARANG_SCANNED_CODES,
  REST_URL_GIIAS_SEMARANG_TRANSACTIONS, REST_URL_VIEW_GIIAS_SEMARANG_TRANSACTION,
  REST_URL_GIIAS_SEMARANG_VIEW_DELETE_SCANNED_CODE, REST_URL_SAVE_FILE,
  REST_URL_VIEW_GIIAS_SEMARANG_PRIZE_REDEMPTION,
} from './constant';

export * from '../../helper';

export const downloadGiiasSemarangScannedCodes = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_GIIAS_SEMARANG_SCANNED_CODES
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_GIIAS_SEMARANG_SCANNED_CODES, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiiasSemarangScannedCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_GIIAS_SEMARANG_VIEW_DELETE_SCANNED_CODE
    .replace(/\{id\}/, id), token);
  return response;
};

export const saveGiiasSemarang2022ScannedCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_SEMARANG_SCANNED_CODES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadGiiasSemarangPrizeRedemptions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_GIIAS_SEMARANG_PRIZE_REDEMPTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiiasSemarangPrizeRedemption = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GIIAS_SEMARANG_PRIZE_REDEMPTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveGiiasSemarangPrizeRedemptions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_SEMARANG_PRIZE_REDEMPTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadGiiasSemarangTransactions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_GIIAS_SEMARANG_TRANSACTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiiasSemarangTransaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GIIAS_SEMARANG_TRANSACTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveGiiasSemarangTransactions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const downloadUrl = REST_URL_GIIAS_SEMARANG_TRANSACTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};
