import { downloadCarServiceReservationInvoice } from '../../../helper';
import { downloadingCarServiceReservationInvoice } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    const { tappedId } = getState().uiCarServiceReservation;

    dispatch(downloadingCarServiceReservationInvoice(true));

    const response = await downloadCarServiceReservationInvoice(tappedId);

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'hyundai-service-reservation.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(downloadingCarServiceReservationInvoice(false));
  }
};
