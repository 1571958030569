import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, makeStyles, CircularProgress } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_DISABLED_ROW, FILTER_TYPE_DROPDOWN,
  RXFIELD_GAMEQR_CATEGORY_STATUS, RXFORM_COMPLETED_CHALLENGE,
  RXSTATE_COMPLETED_CHALLENGES, RXSTATE_COMPLETED_CHALLENGE_PAGE, STATUS_DISABLED,
  DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_COMPLETED_CHALLENGE_CHALLENGE,
  RXFIELD_COMPLETED_CHALLENGE_USER, REWARD_TYPES, PAGE_MODE_TABLE,
  RXFIELD_COMPLETED_CHALLENGE_FULL_NAME, RXFIELD_COMPLETED_CHALLENGE_CHALLENGE_ID,
  RXFIELD_COMPLETED_CHALLENGE_CHALLENGE_NAME, RXFIELD_COMPLETED_CHALLENGE_USER_ID,
  RXFIELD_COMPLETED_CHALLENGE_REWARD_TYPE, RXFIELD_COMPLETED_CHALLENGE_POINT_AMOUNT,
  COMPLETED_REWARD_TYPE_POINT, COMPLETED_REWARD_TYPE_COUPON, COMPLETED_REWARD_TYPE_VOUCHER,
  RXFIELD_COMPLETED_CHALLENGE_COUPON_AMOUNT, RXFIELD_COMPLETED_CHALLENGE_VOUCHER_NAME,
  RXFIELD_COMPLETED_CHALLENGE_SCANNED_CODES,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormEditableTableField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../../../type';
import { toMoment } from '../../helper';
import { SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: 'relative',
    paddingTop: 10,
  },
}));

const renderDialogContent = (
  initialValues, classes, pageMode, downloadingDeleting,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_CHALLENGE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelChallengeId}
          label={LocalizedString.completedChallengePage.labelChallengeId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_CHALLENGE_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelChallengeName}
          label={LocalizedString.completedChallengePage.labelChallengeName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelUserId}
          label={LocalizedString.completedChallengePage.labelUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_FULL_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelFullName}
          label={LocalizedString.completedChallengePage.labelFullName}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_REWARD_TYPE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelRewardType}
          label={LocalizedString.completedChallengePage.labelRewardType}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_POINT_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelPointAmount}
          label={LocalizedString.completedChallengePage.labelPointAmount}
          hidden={initialValues.completedRewardType !== COMPLETED_REWARD_TYPE_POINT}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_COUPON_AMOUNT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelCouponAmount}
          label={LocalizedString.completedChallengePage.labelCouponAmount}
          hidden={initialValues.completedRewardType !== COMPLETED_REWARD_TYPE_COUPON}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_COMPLETED_CHALLENGE_VOUCHER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.completedChallengePage.labelVoucherName}
          label={LocalizedString.completedChallengePage.labelVoucherName}
          hidden={initialValues.completedRewardType !== COMPLETED_REWARD_TYPE_VOUCHER}
          disabled
        />
      </Grid>
    </Grid>
    {downloadingDeleting ? (<CircularProgress color="inherit" />)
      : (
        <Grid item sm={12} className={classes.tableContainer}>
          <Field
            label={LocalizedString.completedChallengePage.labelScannedCodes}
            name={RXFIELD_COMPLETED_CHALLENGE_SCANNED_CODES}
            component={renderReduxFormEditableTableField}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
            loading={downloadingDeleting}
            currentPage={1}
            totalCount={initialValues.scannedCodes.length}
            defaultValue={initialValues.scannedCodes}
            tableColumns={[
              { title: LocalizedString.completedChallengePage.labelScanDate, field: 'createdDate', sorting: false },
              { title: LocalizedString.completedChallengePage.labelScannedCodesLabel, field: 'label', sorting: false },
              { title: LocalizedString.completedChallengePage.labelScanedCodesPoint, field: 'points', sorting: false },
            ]}
          />
        </Grid>
      ) }
  </Grid>

);

const CompletedChallengePage = ({
  initialValues, downloading, downloadingDeleting,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged,
  onSortPressed, onViewPressed, challenges, loadingChallenge, onChangeChallengeText,
  pageMode, users, loadingUsers, onChangeUserText, onAdvancedFilterPressed,
  totalCurrentPage,
}) => {
  const classes = useStyles();
  return (

    <FunctionalPage
      data={RXSTATE_COMPLETED_CHALLENGES}
      uiPage={RXSTATE_COMPLETED_CHALLENGE_PAGE}
      filterColumns={[
        {
          title: LocalizedString.completedChallengePage.labelChallenge,
          field: RXFIELD_COMPLETED_CHALLENGE_CHALLENGE,
          type: FILTER_TYPE_DROPDOWN,
          data: challenges,
          loading: loadingChallenge,
          onChangeFilterText: onChangeChallengeText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.completedChallengePage.labelRewardType,
          field: RXFIELD_GAMEQR_CATEGORY_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: REWARD_TYPES,
        },
        {
          title: LocalizedString.completedChallengePage.labelUser,
          field: RXFIELD_COMPLETED_CHALLENGE_USER,
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUsers,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.completedChallengePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.completedChallengePage.labelChallenge, field: 'challenge.title', sorting: !downloading },
        { title: LocalizedString.completedChallengePage.labelRewardType, field: 'completedRewardType', sorting: !downloading },
        { title: LocalizedString.completedChallengePage.labelUser, field: 'user.fullName', sorting: !downloading },
        {
          title: LocalizedString.completedChallengePage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onCancelPressed={onCancelPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.completedChallengePage.title}
      disableCreate
      disableDelete
      disableEdit
      useFullWidth
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
    >
      {renderDialogContent(
        initialValues, classes, pageMode, downloadingDeleting, totalCurrentPage,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_COMPLETED_CHALLENGE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompletedChallengePage);

CompletedChallengePage.propTypes = {
  challenges: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,

  initialValues: FormInitialValueShape.isRequired,

  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingChallenge: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeChallengeText: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  totalCurrentPage: PropTypes.number.isRequired,
};
