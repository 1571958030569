import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, transformDropdownData,
} from '../../helper';
import {
  clearEvents, setEventAdvancedFilterDialogSelectedFilterString, setEventSearchText,
  downloadEventsAsync, setAlertErrorMessage,
  clearUsers, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  clearTestDriveVoucherRewards, setTestDriveVoucherRewardSearchText,
  setTestDriveVoucherRewardSelectedPageSize, setTestDriveVoucherRewardTappedId,
  setTestDriveVoucherRewardAdvancedFilterString, setTestDriveVoucherRewardSelectedOrderBy,
  downloadTestDriveVoucherRewardsAsync,
  downloadTestDriveVoucherRewardAsync,
  setActiveSideMenuItem,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE, INITIAL_ORDER_BY_VOUCHER_REWARD, MENUID_TEST_DRIVE_VOUCHER_REWARD,
} from '../../constant';
import { downloadUsersAsync } from '../../../../redux/action';
import VoucherRewardPage from './voucher-reward.presentation';
import {
  clearVouchers, downloadVouchersAsync, setVoucherSearchText,
  setVoucherAdvancedFilterDialogSelectedFilterString,
} from '../../../voucher';

const getInitialValues = (state) => {
  const { voucherRewards, uiFunctionalPage, uiVoucherReward } = state;
  const { data } = voucherRewards;
  const { downloadingDeleting, tappedId } = uiVoucherReward;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    eventId: found.event?.id,
    eventName: found.event?.name,
    userId: found.profile?.id,
    userFullname: found.profile?.fullName,
    voucherName: found.voucherName,
    voucherId: found.voucherId,
    note: found.note,
  }) : {};
  return result;
};


const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiVoucherReward.downloading,
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingVoucher: state.uiVoucher.downloading,
  events: transformDropdownData(state.events.data),
  loadingEvents: state.uiEvent.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_TEST_DRIVE_VOUCHER_REWARD));
    dispatch(setTestDriveVoucherRewardAdvancedFilterString(''));
    dispatch(setTestDriveVoucherRewardSearchText(''));
    dispatch(clearTestDriveVoucherRewards());
    dispatch(setTestDriveVoucherRewardSelectedPageSize(20));
    dispatch(setTestDriveVoucherRewardSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_REWARD));
    dispatch(downloadTestDriveVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestDriveVoucherRewardSelectedOrderBy(orderBy));
    dispatch(clearTestDriveVoucherRewards());
    dispatch(downloadTestDriveVoucherRewardsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setTestDriveVoucherRewardTappedId(id));
    dispatch(downloadTestDriveVoucherRewardAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setTestDriveVoucherRewardSearchText(text));
    dispatch(clearTestDriveVoucherRewards());
    dispatch(downloadTestDriveVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestDriveVoucherRewardsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestDriveVoucherRewardSelectedPageSize(pageSize));
    dispatch(downloadTestDriveVoucherRewardsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setTestDriveVoucherRewardSelectedPageSize(pageSize));
    dispatch(clearTestDriveVoucherRewards());
    dispatch(downloadTestDriveVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestDriveVoucherRewards());
    dispatch(setTestDriveVoucherRewardAdvancedFilterString(''));
    dispatch(setTestDriveVoucherRewardSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_REWARD));
    dispatch(downloadTestDriveVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('eventName', 'event.id')
      .replace('userFullname', 'profile.id')
      .replace('voucherName', 'voucherId');

    dispatch(setTestDriveVoucherRewardAdvancedFilterString(text));
    dispatch(clearTestDriveVoucherRewards());
    dispatch(downloadTestDriveVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setEventSearchText(text));
      dispatch(clearEvents());
      await dispatch(downloadEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherRewardPage);
