import { downloadInvoice } from '../../../helper';
import {
  addInvoice, downloadingDeletingInvoice,
} from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingInvoice(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiInvoice;


    const invoice = await downloadInvoice(tappedId, token);

    dispatch(addInvoice(invoice));
  } finally {
    dispatch(downloadingDeletingInvoice(false));
  }
};
