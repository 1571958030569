import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_DEVICE_EXCEPTION_DEVICE_ID,
  RXFIELD_DEVICE_EXCEPTION_MAX_REGISTRATION, RXFIELD_DEVICE_EXCEPTION_REGISTERED_ACCOUNTS,
  RXSTATE_DEVICE_EXCEPTIONS, RXSTATE_DEVICE_EXCEPTION_PAGE, RXFIELD_DEVICE_EXCEPTION_LABEL,
  PAGE_MODE_TABLE,
  RXFORM_DEVICE_EXCEPTION,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateDeviceException } from '../../validation';

const renderDialogContent = (addingEditing, downloadingDeleting, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_DEVICE_EXCEPTION_DEVICE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.deviceExceptionScreen.placeholderDeviceID}
          label={LocalizedString.deviceExceptionScreen.placeholderDeviceID}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Field
        name={RXFIELD_DEVICE_EXCEPTION_LABEL}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.deviceExceptionScreen.placeholderLabel}
        label={LocalizedString.deviceExceptionScreen.placeholderLabel}
        disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
      />
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_DEVICE_EXCEPTION_MAX_REGISTRATION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.deviceExceptionScreen.placeholderMaxRegistration}
          label={LocalizedString.deviceExceptionScreen.placeholderMaxRegistration}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          type="number"
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_DEVICE_EXCEPTION_REGISTERED_ACCOUNTS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.deviceExceptionScreen.placeholderRegisteredAccounts}
          label={LocalizedString.deviceExceptionScreen.placeholderRegisteredAccounts}
          disabled
          hidden={pageMode === PAGE_MODE_TABLE}
        />
      </Grid>
    </Grid>
  </Grid>
);

const DeviceExceptionPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onEditPressed,
  onRefresh, onResetAdvancedFilterPressed, onSavePressed, downloadingDeleting,
  onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_DEVICE_EXCEPTIONS}
    uiPage={RXSTATE_DEVICE_EXCEPTION_PAGE}
    tableColumns={[
      {
        title: LocalizedString.deviceExceptionScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.deviceExceptionScreen.labelLabel, field: 'label', sorting: !downloading },
      { title: LocalizedString.deviceExceptionScreen.labelDeviceID, field: 'deviceId', sorting: !downloading },
      { title: LocalizedString.deviceExceptionScreen.labelMaxRegistration, field: 'maxRegistration', sorting: !downloading },
      { title: LocalizedString.deviceExceptionScreen.labelRegisteredAccounts, field: 'registeredAccounts', sorting: false },
      { title: LocalizedString.deviceExceptionScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={
      LocalizedString.deviceExceptionScreen.buttonCaptionCreateNewDeviceException
    }
    deleteButtonCaption={LocalizedString.deviceExceptionScreen.buttonCaptionDeleteDeviceException}
    editButtonCaption={LocalizedString.deviceExceptionScreen.buttonCaptionEditDeviceException}
    title={LocalizedString.deviceExceptionScreen.title}
    useFullWidth
  >
    {renderDialogContent(addingEditing, downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_DEVICE_EXCEPTION,
  validate: rxformValidateDeviceException,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DeviceExceptionPage);

DeviceExceptionPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
