import { downloadVoucherTokenByCode } from '../../../helper';
import {
  setVoucherTokenByCode, downloadingVoucherTokenByCode,
  clearVoucherTokenByCode,
} from '../simple-action';

export default (code) => async (dispatch, getState) => {
  try {
    dispatch(downloadingVoucherTokenByCode(true));

    const { token } = getState().authentication;

    const voucher = await downloadVoucherTokenByCode(code, token);

    dispatch(clearVoucherTokenByCode());
    dispatch(setVoucherTokenByCode(voucher));
  } finally {
    dispatch(downloadingVoucherTokenByCode(false));
  }
};
