import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_CREATED_DATE, RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_DESCRIPTION,
  RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER, RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER_FULLNAME,
  RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER_ID, RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER,
  RXFORM_GIIAS_2022_PRIZE_REDEMPTION, RXSTATE_GIIAS_2022_PRIZE_REDEMPTION,
  RXSTATE_GIIAS_2022_PRIZE_REDEMPTIONS,
  RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_POINT,
  RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER_ID,
  RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER_TITLE,
  RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_PRIZE_TAG,
} from '../../constant';


const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022PrizeRedemptionPage.placeholderUserId}
          label={LocalizedString.giias2022PrizeRedemptionPage.labelUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER_FULLNAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022PrizeRedemptionPage.placeholderUserFullname}
          label={LocalizedString.giias2022PrizeRedemptionPage.labelUserFullname}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_POINT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022PrizeRedemptionPage.placeholderPoint}
          label={LocalizedString.giias2022PrizeRedemptionPage.labelPoint}
          disabled
        />
      </Grid>
      <Grid item sm={12}>
        <Field
          name={RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.giias2022PrizeRedemptionPage.placeholderDescription}
          placeholder={LocalizedString.giias2022PrizeRedemptionPage.placeholderDescription}
          disabled
          multiline
        />
      </Grid>

    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022PrizeRedemptionPage.placeholderVoucherId}
          label={LocalizedString.giias2022PrizeRedemptionPage.labelVoucherId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER_TITLE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022PrizeRedemptionPage.placeholderVoucherTitle}
          label={LocalizedString.giias2022PrizeRedemptionPage.labelVoucherTitle}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_PRIZE_TAG}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.giias2022PrizeRedemptionPage.placeholderPrizeTag}
          label={LocalizedString.giias2022PrizeRedemptionPage.labelPrizeTag}
          disabled
        />
      </Grid>

    </Grid>
  </Grid>
);

const Giias2022PrizeRedemptionPage = ({
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText,
  onChangeVoucherText, loadingVoucher, vouchers,
  onDownloadPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_GIIAS_2022_PRIZE_REDEMPTIONS}
    uiPage={RXSTATE_GIIAS_2022_PRIZE_REDEMPTION}
    tableColumns={[
      {
        title: LocalizedString.giias2022PrizeRedemptionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.giias2022PrizeRedemptionPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.giias2022PrizeRedemptionPage.labelVoucher, field: 'voucherTitle', sorting: !downloading },
      { title: LocalizedString.giias2022PrizeRedemptionPage.labelPoint, field: 'point', sorting: !downloading },
      {
        title: LocalizedString.giias2022PrizeRedemptionPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.giias2022PrizeRedemptionPage.labelCreatedDate,
        field: RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
      },
      {
        title: LocalizedString.giias2022PrizeRedemptionPage.labelUser,
        field: RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.giias2022PrizeRedemptionPage.labelVoucher,
        field: RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER,
        type: FILTER_TYPE_DROPDOWN,
        data: vouchers,
        loading: loadingVoucher,
        onChangeFilterText: onChangeVoucherText,
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.giias2022PrizeRedemptionPage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onDownloadPressed={onDownloadPressed}
    enableSave
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_GIIAS_2022_PRIZE_REDEMPTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Giias2022PrizeRedemptionPage);

Giias2022PrizeRedemptionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
