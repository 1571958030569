import { connect } from 'react-redux';
import { reset, change, formValueSelector } from 'redux-form';
import {
  INITIAL_ORDER_BY_GARUDA_AIRPORTS, MENUID_VOUCHER_GARUDA_AIRPORT, PAGE_MODE_TABLE,
  RXFORM_GARUDA_AIRPORT, RXFIELD_MAP_TOKEN_DIALOG_AIRPORT,
  RXFIELD_MAP_TOKEN_DIALOG_TOKEN_REMARK, RXFORM_MAP_TOKENS_DIALOG, RXFIELD_VOUCHER_TOKEN_REMARK,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData, debounceSearch,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  addEditGarudaAirportAsync, clearGarudaAirports,
  downloadDeleteGarudaAirportAsync, downloadGarudaAirportsAsync,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setGarudaAirportSearchText, setGarudaAirportSelectedOrderBy,
  setGarudaAirportSelectedPageSize, setGarudaAirportTappedId,
  viewMapVoucherTokenDialog, setMapTokenSelectedAirport,
  setMapTokenAirportSearchText, clearMapTokenAirports,
  downloadMapTokenAirportsAsync, countVoucherTokenAsync,
  setVoucherTokenCount, enableMapTokenButton,
  mapTokenToAirportAsync,
} from '../../redux/action';
import GarudaAirportPage from './garuda-airport.presentation';
import LocalizedString from '../../localization';

const selector = formValueSelector(RXFORM_MAP_TOKENS_DIALOG);

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiGarudaAirport, garudaAirports } = state;
  const { data } = garudaAirports;
  const { downloadingDeleting, tappedId } = uiGarudaAirport;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    name: found.name ?? '',
    description: found.description ?? '',
  }) : {
    name: '',
    description: '',
    image: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiGarudaAirport.addingEditing,
  downloading: state.uiGarudaAirport.downloading,
  downloadingDeleting: state.uiGarudaAirport.downloadingDeleting,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  viewModal: state.uiGarudaAirport.viewModal,
  airports: transformDropdownData(state.mapTokenAirports.data),
  loadingAirports: state.uiGarudaAirport.downloadingAirports,
  tokenCount: state.uiGarudaAirport.tokenCount,
  countingVoucherToken: state.uiGarudaAirport.countingVoucherToken,
  tokenRemark: selector(state, RXFIELD_MAP_TOKEN_DIALOG_TOKEN_REMARK) ?? '',
  airport: selector(state, RXFIELD_MAP_TOKEN_DIALOG_AIRPORT) ?? {},
  enableMapTokenButton: state.uiGarudaAirport.enableMapTokenButton,
  mapping: state.uiGarudaAirport.mapping,
});

const searchAirportDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearMapTokenAirports());
    dispatch(downloadMapTokenAirportsAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_GARUDA_AIRPORT));
    dispatch(setGarudaAirportSearchText(''));
    dispatch(clearGarudaAirports());
    dispatch(setGarudaAirportSelectedPageSize(20));
    dispatch(setGarudaAirportSelectedOrderBy(INITIAL_ORDER_BY_GARUDA_AIRPORTS));
    dispatch(downloadGarudaAirportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });

    dispatch(viewMapVoucherTokenDialog(false));
    dispatch(enableMapTokenButton(false));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGarudaAirportsAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGarudaAirportSelectedPageSize(pageSize));
    dispatch(downloadGarudaAirportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_GARUDA_AIRPORT));
  },
  onCreatePressed: () => {
    dispatch(setGarudaAirportTappedId(''));
  },
  onEditPressed: (id) => {
    dispatch(setGarudaAirportTappedId(id));
    dispatch(downloadDeleteGarudaAirportAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setGarudaAirportSelectedPageSize(pageSize));
    dispatch(clearGarudaAirports());
    dispatch(downloadGarudaAirportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSavePressed: ({ name, description }) => {
    dispatch(addEditGarudaAirportAsync(name, description))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setGarudaAirportSearchText(text));
    dispatch(clearGarudaAirports());
    dispatch(downloadGarudaAirportsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setGarudaAirportSelectedOrderBy(orderBy));
    dispatch(clearGarudaAirports());
    dispatch(downloadGarudaAirportsAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSubmitPressed: ({ name, description }) => {
    dispatch(addEditGarudaAirportAsync(name, description))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setGarudaAirportTappedId(id));
    dispatch(downloadDeleteGarudaAirportAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onDeletePressed: (id) => {
    dispatch(setGarudaAirportTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onConfirmDeletePressed: (reason, message) => {
    if (message.includes(LocalizedString.voucherGarudaAirportPage.labelTokensWereMapped)) {
      dispatch(enableMapTokenButton(false));
      dispatch(setMapTokenSelectedAirport(''));
      dispatch(setMapTokenAirportSearchText(''));
      dispatch(reset(RXFORM_MAP_TOKENS_DIALOG));
    } else {
      dispatch(downloadDeleteGarudaAirportAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onOpenMapTokenPressed: () => {
    dispatch(setVoucherTokenCount(0));
    dispatch(viewMapVoucherTokenDialog(true));
    dispatch(setMapTokenAirportSearchText(''));
    dispatch(clearMapTokenAirports());
    dispatch(downloadMapTokenAirportsAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
  onMapTokenCancelPressed: () => {
    dispatch(viewMapVoucherTokenDialog(false));
    dispatch(setMapTokenSelectedAirport(''));
    dispatch(setMapTokenAirportSearchText(''));
    dispatch(setVoucherTokenCount(0));
    dispatch(reset(RXFORM_MAP_TOKENS_DIALOG));
    dispatch(enableMapTokenButton(false));
  },
  onChangeAirportText: (text) => {
    dispatch(setMapTokenAirportSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchAirportDebounce(dispatch, true);
    }
  },
  onAirportOptionSelected: (option) => {
    if (option) {
      dispatch(setMapTokenSelectedAirport(option.value));
      dispatch(change(
        RXFORM_MAP_TOKENS_DIALOG,
        RXFIELD_MAP_TOKEN_DIALOG_AIRPORT, option,
      ));
    } else {
      dispatch(setMapTokenSelectedAirport(''));
      dispatch(change(
        RXFORM_MAP_TOKENS_DIALOG,
        RXFIELD_MAP_TOKEN_DIALOG_AIRPORT, '',
      ));
    }
  },
  onCountPressed: ({ tokenRemark }) => {
    dispatch(countVoucherTokenAsync(tokenRemark))
      .catch((error) => setAlertErrorMessage(error));
    dispatch(enableMapTokenButton(true));
  },
  onMapTokenPressed: ({ tokenRemark }) => {
    dispatch(mapTokenToAirportAsync(tokenRemark))
      .catch((error) => setAlertErrorMessage(error));
  },
  onTokenRemarkTextChange: (e) => {
    dispatch(change(RXFORM_MAP_TOKENS_DIALOG, RXFIELD_VOUCHER_TOKEN_REMARK, e.target.value));
    dispatch(setVoucherTokenCount(0));
    dispatch(enableMapTokenButton(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GarudaAirportPage);
