
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    genuineAccessoryMenu: {
      title: 'Genuine Accessory',
    },
    accessoryPage: {
      title: 'Accessory',

      labelNo: 'No',
      labelProductCode: 'Product Code',
      labelProductName: 'Product Name',
      labelVehicleModelCode: 'Vehicle Model Code',
      labelVehicleModelName: 'Vehicle Model Name',
      labelPrice: 'Price',

      placeholderProductCode: 'Product Code',
      placeholderProductName: 'Product Name',
      placeholderDescription: 'Description',
      placeholderVehicleModel: 'Vehicle Model',
      placeholderVehicleModelCode: 'Vehicle Model Code',
      placeholderVehicleModelName: 'Vehicle Model Name',
      placeholderVariants: 'Variants',
      placeholderVariantName: 'Variant Name',
      placeholderVariantCode: 'Variant Code',
      placeholderVariantId: 'Variant ID',
      placeholderImage: 'Image',
      placeholderOrder: 'Order',
      placeholderPrice: 'Price',
      placeholderPriceRange: 'Price Range',
      placeholderStatus: 'Status',
      placeholderActive: 'Active',
      placeholderInactive: 'Inactive',
      placeholderWidth: 'Width',
      placeholderHeight: 'Height',
      placeholderWeight: 'Weight',
      placeholderLength: 'Length',
      placeholderMinOrderQty: 'Minimum Order Quantity',

      buttonCaptionEditAccessory: 'Edit Accessory',
      buttonCaptionSyncFromDMS: 'Sync From DMS',
    },
    invoicePage: {
      title: 'Invoice',

      labelNo: 'No',
      labelInvoiceNo: 'Invoice No',
      labelOrderId: 'Order ID',
      labelUserFullName: 'User Full Name',
      labelTransactionId: 'Transaction ID',
      labelTransactionStatus: 'Transaction Status',
      labelItems: 'Items',
      labelItem: 'Item',
      labelGrandTotal: 'Grand Total',
      labelCreatedDate: 'Created Date',
      labelAccessory: 'Accessory',
      labelDealer: 'Dealer',
      labelMemberId: 'Member ID',
      labelEmail: 'Email',
      labelPaymentMethod: 'Payment Method',
      labelWaitingForPayment: 'Waiting for Payment',
      labelPaymentDate: 'Payment Date',
      labelVehicleModel: 'Vehicle Model',
      labelPending: 'Pending',
      labelSuccess: 'Success',
      labelError: 'Error',
      labelCancelled: 'Cancelled',
      labelAddress: 'Address',

      placeholderUser: 'User',
      placeholderTransactionStatus: 'Transaction Status',
      placeholderTransactionStatusNotes: 'Transaction Status Notes',
      placeholderInvoiceNo: 'Invoice No',
      placeholderOrderId: 'Order ID',
      placeholderFinishDate: 'Finish Date',
      placeholderUserId: 'User ID',
      placeholderUserFullName: 'User Full Name',
      placeholderTransactionId: 'Transaction ID',
      placeholderTax: 'Tax',
      placeholderGrandTotal: 'Grand Total',
      placeholderDealer: 'Dealer',
      placeholderDealerId: 'Dealer ID',
      placeholderDealerCode: 'Dealer Code',
      placeholderType: 'Type',
      placeholderName: 'Name',
      placeholderAddress: 'Address',
      placeholderPostalCode: 'Postal Code',
      placeholderCity: 'City',
      placeholderRegion: 'Region',
      placeholderRegionCode: 'Region Code',
      placeholderProvince: 'Province',
      placeholderTimeZone: 'Time Zone',
      placeholderChargingStation: 'Charging Station',
      placeholderEmail: 'Email',
      placeholderPhone: 'Phone',
      placeholderOpenTime: 'Open Time',
      placeholderCloseTime: 'Close Time',
      placeholderDealerStatus: 'Dealer Status',
      placeholderDealerTypeCode: 'Dealer Type Code',
      placeholderDealerType: 'Dealer Type',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderItems: 'Items',
      placeholderPayment: 'Payment',
      placeholderPaymentId: 'Payment ID',
      placeholderPaymentStatus: 'Payment Status',
      placeholderTotal: 'Total',
      placeholderPaymentRawCallback: 'Payment Raw Callback',
      placeholderPaymentRawResponse: 'Payment Raw Response',
      placeholderPaymentDate: 'Payment Date',
      placeholderPaymentDueDate: 'Payment Due Date',
      placeholderPaymentMethod: 'Payment Method',
      placeholderVaNumber: 'VA Number',
      placeholderUrl: 'URL',
      placeholderPickUpAndDeliveryStatus: 'Pick Up and Delivery Status',
      placeholderItemType: 'Item Type',
      placeholderAccessoryId: 'Accessory ID',
      placeholderVariantId: 'Variant ID',
      placeholderVariantCode: 'Variant Code',
      placeholderPrice: 'Price',
      placeholderDescription: 'Description',
      placeholderImage: 'Image',
      placeholderPickupTime: 'Pickup Time',
      placeholderQuantity: 'Quantity',
      placeholderUnitPrice: 'Unit Price',
      placeholderNotes: 'Notes',
      placeholderMemberId: 'Member ID',
      placeholderDealerAddress: 'Dealer Address',
      placeholderContactPersonName: 'Contact Person Name',
      placeholderContactPersonPhone: 'Contact Person Phone',

      buttonCaptionOpenInGoogleMap: 'Open in Google Maps',
    },
    accessoryTransactionPage: {
      title: 'Transaction',

      labelNo: 'No',
      labelTransactionID: 'Transaction ID',
      labelMemberID: 'Member ID',
      labelUserFullName: 'User Full Name',
      labelEmail: 'Email',
      labelAddress: 'Address',
      labelItems: 'Items',
      labelPaymentMethod: 'Payment Method',
      labelItem: 'Item',
      labelAccessory: 'Accessory',
      labelDealer: 'Dealer',
      labelItemName: 'Item Name',
      labelItemCount: 'Items',
      labelPaymentDate: 'Payment Date',
      labelPaymentStatus: 'Payment Status',
      labelTransactionStatus: 'Transaction Status',
      labelCreatedDate: 'Created Date',
      labelAccessoryItemType: 'Item Type',
      labelAccessoryItemID: 'Accessory ID',
      labelAccessoryItemVariantID: 'Variant ID',
      labelAccessoryItemVariantCode: 'Variant Code',
      labelAccessoryItemVariantName: 'Name',
      labelAccessoryItemVariantPrice: 'Price',
      labelAccessoryItemVariantDescription: 'Description',
      labelAccessoryItemVariantImage: 'Image',
      labelAcccessoryItemOrderId: 'Order ID',
      labelAcccessoryItemPickupTime: 'Pickup Time',
      labelAcccessoryItemQuantity: 'Quantity',
      labelAcccessoryItemUnitPrice: 'Unit Price',
      labelAcccessoryItemTax: 'Tax',
      labelAcccessoryItemNotes: 'Notes',
      labelDealerItemDealerID: 'Dealer ID',
      labelDealerItemDealerCode: 'Dealer Code',
      labelDealerItemType: 'Type',
      labelDealerItemDealerName: 'Name',
      labelDealerItemDealerAddress: 'Address',
      labelDealerItemDealerPostalCode: 'Postal Code',
      labelDealerItemDealerCity: 'City',
      labelDealerItemDealerRegion: 'Region',
      labelDealerItemDealerRegionCode: 'Region Code',
      labelDealerItemDealerProvince: 'Province',
      labelDealerItemDealerTimezone: 'Time Zone',
      labelDealerItemDealerChargingStation: 'Charging Station',
      labelDealerItemDealerEmail: 'Email',
      labelDealerItemDealerPhone: 'Phone',
      labelDealerItemDealerOpenTime: 'Open Time',
      labelDealerItemDealerCloseTime: 'Close Time',
      labelDealerItemDealerStatus: 'Dealer Status',
      labelDealerItemDealerTypeCode: 'Dealer Type Code',
      labelDealerItemDealerType: 'Dealer Type',
      labelDealerItemDealerLat: 'Latitude ',
      labelDealerItemDealerLong: 'Longitude',
      labelTransactionTotal: 'Transaction Total',

      placeholderUser: 'User',
      placeholderEmail: 'Email',
      placeholderAddress: 'Address',
      placeholderTransactionStatus: 'Transaction Status',
      placeholderPaymentStatus: 'Payment Status',
      placeholderPaymentDate: 'Payment Date',
      placeholderPaymentMethod: 'Payment Method',
      placeholderUserID: 'User ID',
      placeholderUserFullName: 'User Full Name',
      placeholderTransactionID: 'Transaction ID',
      placeholderMemberID: 'Member ID',
      placeholderTransactionStatusNotes: 'Transaction Notes',
      placeholderPaymentRawResponse: 'Payment Raw Response',
      placeholderPaymentRawCallback: 'Payment Raw Callback',
      placeholderPaymentDueDate: 'Payment Due Date',
      placeholderVANumber: 'VA Number',
      placeholderURL: 'URL',
      placeholderTax: 'Tax',
      placeholderSubTotal: 'Sub Total',
      placeholderGrandTotal: 'Grand Total',

      buttonCaptionOpenInGoogleMap: 'Open in Google Maps',
    },
  },
  id: {
    genuineAccessoryMenu: {
      title: 'Aksesori Asli',
    },
    accessoryPage: {
      title: 'Aksesori',

      labelNo: 'No',
      labelProductCode: 'Kode Produk',
      labelProductName: 'Nama Produk',
      labelVehicleModelCode: 'Kode Model Kendaraan',
      labelVehicleModelName: 'Nama Model Kendaraan',
      labelPrice: 'Harga',

      placeholderProductCode: 'Kode Produk',
      placeholderProductName: 'Nama Produk',
      placeholderDescription: 'Deskripsi',
      placeholderVehicleModel: 'Model Kendaraan',
      placeholderVehicleModelCode: 'Kode Model Kendaraan',
      placeholderVehicleModelName: 'Nama Model Kendaraan',
      placeholderVariants: 'Varian',
      placeholderVariantName: 'Nama Varian',
      placeholderVariantCode: 'Kode Varian',
      placeholderVariantId: 'ID Varian',
      placeholderImage: 'Gambar',
      placeholderOrder: 'Urutan',
      placeholderPrice: 'Harga',
      placeholderStatus: 'Status',
      placeholderPriceRange: 'Perkiraan Harga',
      placeholderActive: 'Aktif',
      placeholderInactive: 'Tidak Aktif',
      placeholderWidth: 'Lebar',
      placeholderHeight: 'Tinggi',
      placeholderWeight: 'Berat',
      placeholderLength: 'Panjang',
      placeholderMinOrderQty: 'Kuantitas Minimum Order',

      buttonCaptionEditAccessory: 'Ubah Aksesori',
      buttonCaptionSyncFromDMS: 'Sinkronkan dari DMS',
    },
    invoicePage: {
      title: 'Faktur',

      labelNo: 'No',
      labelInvoiceNo: 'No Faktur',
      labelOrderId: 'ID Pemesanan',
      labelUserFullName: 'Nama Lengkap Pengguna',
      labelTransactionId: 'ID Transaksi',
      labelTransactionStatus: 'Status Transaksi',
      labelItems: 'Items',
      labelItem: 'Item',
      labelGrandTotal: 'Total Akhir',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelAccessory: 'Accessory',
      labelDealer: 'Dealer',
      labelMemberId: 'ID Anggota',
      labelEmail: 'Email',
      labelPaymentMethod: 'Metode Pembayaran',
      labelWaitingForPayment: 'Menunggu Pembayaran',
      labelPaymentDate: 'Tanggal Pembayaran',
      labelVehicleModel: 'Model Kendaraan',
      labelPending: 'Pending',
      labelSuccess: 'Sukses',
      labelError: 'Error',
      labelCancelled: 'Dibatalkan',
      labelAddress: 'Alamat',

      placeholderUser: 'Pengguna',
      placeholderTransactionStatus: 'Status Transaksi',
      placeholderTransactionStatusNotes: 'Catatan Status Transaksi',
      placeholderInvoiceNo: 'No Faktur',
      placeholderOrderId: 'ID Pemesanan',
      placeholderFinishDate: 'Tanggal Selesai',
      placeholderUserId: 'ID Pengguna',
      placeholderUserFullName: 'Nama Lengkap Pengguna',
      placeholderTransactionId: 'ID Transaksi',
      placeholderTax: 'Pajak',
      placeholderGrandTotal: 'Total Akhir',
      placeholderDealer: 'Dealer',
      placeholderDealerId: 'ID Dealer',
      placeholderDealerCode: 'Kode Dealer',
      placeholderType: 'Tipe',
      placeholderName: 'Nama',
      placeholderAddress: 'Alamat',
      placeholderPostalCode: 'Kode Pos',
      placeholderCity: 'Kota',
      placeholderRegion: 'Wilayah',
      placeholderRegionCode: 'Kode Wilayah',
      placeholderProvince: 'Provinsi',
      placeholderTimeZone: 'Zona Waktu',
      placeholderChargingStation: 'Charging Station',
      placeholderEmail: 'Email',
      placeholderPhone: 'Telepon',
      placeholderOpenTime: 'Jam Buka',
      placeholderCloseTime: 'Jam Tutup',
      placeholderDealerStatus: 'Status Dealer',
      placeholderDealerTypeCode: 'Tipe Kode Dealer',
      placeholderDealerType: 'Tipe Dealer',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderItems: 'Barang',
      placeholderPayment: 'Pembayaran',
      placeholderPaymentId: 'ID Pembayaran',
      placeholderPaymentStatus: 'Status Pembayaran',
      placeholderTotal: 'Total',
      placeholderPaymentRawCallback: 'Raw Callback Pembayaran',
      placeholderPaymentRawResponse: 'Raw Response Pembayaran',
      placeholderPaymentDate: 'Tanggal Pembayaran',
      placeholderPaymentDueDate: 'Jatuh Tempo Pembayaran',
      placeholderPaymentMethod: 'Metode Pembayaran',
      placeholderVaNumber: 'VA Number',
      placeholderUrl: 'URL',
      placeholderPickUpAndDeliveryStatus: 'Status Pengambilan dan Pengiriman',
      placeholderItemType: 'Tipe Item',
      placeholderAccessoryId: 'ID Aksesoris',
      placeholderVariantId: 'ID Varian',
      placeholderVariantCode: 'Kode Varian',
      placeholderPrice: 'Harga',
      placeholderDescription: 'Deskripsi',
      placeholderImage: 'Gambar',
      placeholderPickupTime: 'Waktu Pickup',
      placeholderQuantity: 'Jumlah',
      placeholderUnitPrice: 'Harga Unit',
      placeholderNotes: 'Catatan',
      placeholderMemberId: 'ID Anggota',
      placeholderDealerAddress: 'Alamat Dealer',
      placeholderContactPersonName: 'Nama Kontak',
      placeholderContactPersonPhone: 'Telpon Kontak',

      buttonCaptionOpenInGoogleMap: 'Buka di Google Maps',
    },
    accessoryTransactionPage: {
      title: 'Transaksi',

      labelNo: 'No',
      labelTransactionID: 'ID Transaksi',
      labelMemberID: 'ID Member',
      labelUserFullName: 'Nama Pengguna',
      labelEmail: 'Email',
      labelAddress: 'Alamat',
      labelItems: 'Items',
      labelPaymentMethod: 'Metode Pembayaran',
      labelItem: 'Barang',
      labelAccessory: 'Aksesoris',
      labelDealer: 'Dealer',
      labelItemName: 'Nama Barang',
      labelItemCount: 'Jumlah Barang',
      labelPaymentDate: 'Tanggal Pembayaran',
      labelPaymentStatus: 'Status Pembayaran',
      labelTransactionStatus: 'Status Transaksi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelAccessoryItemType: 'Tipe Barang',
      labelAccessoryItemID: 'ID Aksesoris',
      labelAccessoryItemVariantID: 'ID Varian',
      labelAccessoryItemVariantCode: 'Kode Variant',
      labelAccessoryItemVariantName: 'Nama',
      labelAccessoryItemVariantPrice: 'Harga',
      labelAccessoryItemVariantDescription: 'Deskripsi',
      labelAccessoryItemVariantImage: 'Gambar',
      labelAcccessoryItemOrderId: 'ID Pemesanan',
      labelAcccessoryItemPickupTime: 'Waktu Pickup',
      labelAcccessoryItemQuantity: 'Jumlah',
      labelAcccessoryItemUnitPrice: 'Harga Unit',
      labelAcccessoryItemTax: 'Pajak',
      labelAcccessoryItemNotes: 'Catatan',
      labelDealerItemDealerID: 'ID Dealer',
      labelDealerItemDealerCode: 'Kode Dealer',
      labelDealerItemType: 'Tipe',
      labelDealerItemDealerName: 'Nama',
      labelDealerItemDealerAddress: 'Alamat',
      labelDealerItemDealerPostalCode: 'Kode Pos',
      labelDealerItemDealerCity: 'Kota',
      labelDealerItemDealerRegion: 'Daerah',
      labelDealerItemDealerRegionCode: 'Kode Daerah',
      labelDealerItemDealerProvince: 'Provinsi',
      labelDealerItemDealerTimezone: 'Zona Waktu',
      labelDealerItemDealerChargingStation: 'Stasiun Pengisian',
      labelDealerItemDealerEmail: 'Email',
      labelDealerItemDealerPhone: 'Telefon',
      labelDealerItemDealerOpenTime: 'Jam Buka',
      labelDealerItemDealerCloseTime: 'Jam Tutup',
      labelDealerItemDealerStatus: 'Status Dealer',
      labelDealerItemDealerTypeCode: 'Kode Tipe Dealer',
      labelDealerItemDealerType: 'Tipe Dealer',
      labelDealerItemDealerLat: 'Latitude ',
      labelDealerItemDealerLong: 'Longitude',
      labelTransactionTotal: 'Total Transaksi',

      placeholderUser: 'Nama Pengguna',
      placeholderEmail: 'Email',
      placeholderAddress: 'Address',
      placeholderTransactionStatus: 'Status Transaksi',
      placeholderPaymentStatus: 'Status Pembayaran',
      placeholderPaymentDate: 'Tanggal Pembayaran',
      placeholderPaymentMethod: 'Metode Pembayaran',
      placeholderUserID: 'ID Pengguna',
      placeholderUserFullName: 'Nama Pengguna',
      placeholderTransactionID: 'ID Transaksi',
      placeholderMemberID: 'ID Member',
      placeholderTransactionStatusNotes: 'Catatan Transaksi',
      placeholderPaymentRawResponse: 'Raw Response Pembayaran',
      placeholderPaymentRawCallback: 'Raw Callback Pembayaran',
      placeholderPaymentDueDate: 'Jatuh Tempo Pembayaran',
      placeholderVANumber: 'Nomor VA',
      placeholderURL: 'URL',
      placeholderTax: 'Pajak',
      placeholderSubTotal: 'Sub Total',
      placeholderGrandTotal: 'Grand Total',

      buttonCaptionOpenInGoogleMap: 'Buka di Google Maps',
    },
  },
});

export default LocalizedString;
