import { downloadingCarOwnerships, setCarOwnership } from '../simple-action';
import { downloadCarOwnerships, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, carOwnerships, uiCarOwnership } = getState();
  const { token } = authentication;
  const { meta } = carOwnerships;
  const { selectedPageSize, searchBarText, orderBy } = uiCarOwnership;
  const { filterString } = getState().uiFunctionalPage;

  try {
    dispatch(downloadingCarOwnerships(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCarOwnerships(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setCarOwnership(list));
    }
  } finally {
    dispatch(downloadingCarOwnerships(false));
  }
};
