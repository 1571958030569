import { clearSalesConsultants, downloadingSalesConsultants, setSalesConsultants } from '../simple-action';
import { downloadSalesConsultants } from '../../../helper';

export default (reload = false) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { salesConsultantSearchString } = getState().uiTestDriveEoReservation;

  try {
    dispatch(downloadingSalesConsultants(true));
    const salesConsultants = await downloadSalesConsultants(salesConsultantSearchString, token);
    if (reload) { dispatch(clearSalesConsultants()); }
    dispatch(setSalesConsultants(salesConsultants));
  } finally {
    dispatch(downloadingSalesConsultants(false));
  }
};
