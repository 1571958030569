import { addPointPurchaseRequest, downloadingPointPurchaseRequest } from '../simple-action';
import { downloadPointPurchaseRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingPointPurchaseRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPointPurchaseRequest;

    const purchaseRequest = await downloadPointPurchaseRequest(tappedId, token);
    dispatch(addPointPurchaseRequest(purchaseRequest));
  } finally {
    dispatch(downloadingPointPurchaseRequest(false));
  }
};
