import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, makeStyles,
} from '@material-ui/core';
import { reduxForm } from 'redux-form';
import { COLOR_DANGEROUS, RXFORM_CUSTOM_FIELD_DIALOG } from '../constant';
import LocalizedString from '../localization';
import AccentButton from './accent-button';

const useStyles = makeStyles(() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  centerTitle: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
}));

const CustomFieldDialog = ({
  loadingPositiveButton, positiveButtonDisabled, visibility,
  handleSubmit, onCancelPressed, onClosePressed, onPositiveButtonPressed,
  children, positiveButtonCaption, title, customDialog, titleComponent,
  subtitleComponent, iconComponent,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={visibility}
      fullWidth
      maxWidth="sm"
      onClose={onClosePressed}
      classes={{ paper: classes.paper }}
    >
      {!customDialog ? (
        <div className={classes.headerContainer}>
          <DialogTitle>{title}</DialogTitle>
        </div>
      ) : null}

      {customDialog && iconComponent ? (
        <div className={`${classes.headerContainer} ${classes.centerTitle}`}>
          {iconComponent}
        </div>
      ) : null}

      {customDialog && titleComponent ? (
        <div className={`${classes.headerContainer} ${classes.centerTitle}`}>
          {titleComponent}
        </div>
      ) : null}

      {customDialog && subtitleComponent ? (
        <div className={`${classes.headerContainer} ${classes.centerTitle}`}>
          {subtitleComponent}
        </div>
      ) : null}

      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions className={classes.buttonContainer}>
        <AccentButton
          variant="text"
          caption={LocalizedString.common.buttonCaptionCancel}
          onClick={() => {
            onClosePressed();
            onCancelPressed();
          }}
          className={classes.redButton}
        />
        <AccentButton
          caption={positiveButtonCaption}
          onClick={handleSubmit(async (values) => {
            await onPositiveButtonPressed(values);
            onClosePressed();
          })}
          disabled={positiveButtonDisabled}
          loading={loadingPositiveButton}
        />
      </DialogActions>
    </Dialog>
  );
};
export default reduxForm({
  form: RXFORM_CUSTOM_FIELD_DIALOG,
  enableReinitialize: true,
})(CustomFieldDialog);

CustomFieldDialog.propTypes = {
  loadingPositiveButton: PropTypes.bool,
  positiveButtonDisabled: PropTypes.bool,
  customDialog: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onClosePressed: PropTypes.func.isRequired,
  onPositiveButtonPressed: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  titleComponent: PropTypes.node,
  subtitleComponent: PropTypes.node,
  iconComponent: PropTypes.node,
  positiveButtonCaption: PropTypes.string,
  title: PropTypes.string.isRequired,
};

CustomFieldDialog.defaultProps = {
  loadingPositiveButton: false,
  positiveButtonDisabled: false,
  customDialog: false,
  positiveButtonCaption: LocalizedString.common.buttonCaptionOK,
  titleComponent: null,
  subtitleComponent: null,
  iconComponent: null,
};
