import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_IIMS_2023_SCANNED_CODES = 'DOWNLOADING_IIMS_2023_SCANNED_CODES';
export const downloadingIims2023ScannedCodes = makeActionCreator(DOWNLOADING_IIMS_2023_SCANNED_CODES, 'status');

export const DOWNLOADING_IIMS_2023_SCANNED_CODE = 'DOWNLOADING_IIMS_2023_SCANNED_CODE';
export const downloadingIims2023ScannedCode = makeActionCreator(DOWNLOADING_IIMS_2023_SCANNED_CODE, 'status');

export const SET_IIMS_2023_SCANNED_CODES = 'SET_IIMS_2023_SCANNED_CODES';
export const setIims2023ScannedCodes = makeActionCreator(SET_IIMS_2023_SCANNED_CODES, 'data');

export const SET_IIMS_2023_SCANNED_CODE = 'SET_IIMS_2023_SCANNED_CODE';
export const setIims2023ScannedCode = makeActionCreator(SET_IIMS_2023_SCANNED_CODE, 'data');

export const CLEAR_IIMS_2023_SCANNED_CODES = 'CLEAR_IIMS_2023_SCANNED_CODES';
export const clearIims2023ScannedCodes = makeActionCreator(CLEAR_IIMS_2023_SCANNED_CODES, 'data');

export const SET_IIMS_2023_SCANNED_CODES_PAGE_SIZE = 'SET_IIMS_2023_SCANNED_CODES_PAGE_SIZE';
export const setIims2023ScannedCodesPageSize = makeActionCreator(SET_IIMS_2023_SCANNED_CODES_PAGE_SIZE, 'size');

export const SET_IIMS_2023_SCANNED_CODES_ORDER_BY = 'SET_IIMS_2023_SCANNED_CODES_ORDER_BY';
export const setIims2023ScannedCodesOrderBy = makeActionCreator(SET_IIMS_2023_SCANNED_CODES_ORDER_BY, 'order');

export const SET_IIMS_2023_SCANNED_CODES_SEARCH_TEXT = 'SET_IIMS_2023_SCANNED_CODES_SEARCH_TEXT';
export const setIims2023ScannedCodesSearchText = makeActionCreator(SET_IIMS_2023_SCANNED_CODES_SEARCH_TEXT, 'text');

export const SET_IIMS_2023_SCANNED_CODES_TAPPED_ID = 'SET_IIMS_2023_SCANNED_CODES_TAPPED_ID';
export const setIims2023ScannedCodesTappedId = makeActionCreator(SET_IIMS_2023_SCANNED_CODES_TAPPED_ID, 'id');

export const SET_IIMS_2023_SCANNED_CODES_FILTER_STRING = 'SET_IIMS_2023_SCANNED_CODES_FILTER_STRING';
export const setIims2023ScannedCodesFilterString = makeActionCreator(SET_IIMS_2023_SCANNED_CODES_FILTER_STRING, 'text');

export const SAVING_IIMS_2023_2022_SCANNED_CODES = 'SAVING_IIMS_2023_2022_TSCANNED_CODE';
export const savingIims2023ScannedCodes = makeActionCreator(SAVING_IIMS_2023_2022_SCANNED_CODES, 'status');


export const DOWNLOADING_IIMS_2023_PRIZE_REDEMPTIONS = 'DOWNLOADING_IIMS_2023_PRIZE_REDEMPTIONS';
export const downloadingIims2023PrizeRedemptions = makeActionCreator(DOWNLOADING_IIMS_2023_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_IIMS_2023_PRIZE_REDEMPTION = 'DOWNLOADING_IIMS_2023_PRIZE_REDEMPTION';
export const downloadingIims2023PrizeRedemption = makeActionCreator(DOWNLOADING_IIMS_2023_PRIZE_REDEMPTION, 'status');

export const ADD_IIMS_2023_PRIZE_REDEMPTION = 'ADD_IIMS_2023_PRIZE_REDEMPTION';
export const addIims2023PrizeRedemption = makeActionCreator(ADD_IIMS_2023_PRIZE_REDEMPTION, 'data');

export const SET_IIMS_2023_PRIZE_REDEMPTIONS = 'SET_IIMS_2023_PRIZE_REDEMPTIONS';
export const setIims2023PrizeRedemptions = makeActionCreator(SET_IIMS_2023_PRIZE_REDEMPTIONS, 'data');

export const CLEAR_IIMS_2023_PRIZE_REDEMPTIONS = 'CLEAR_IIMS_2023_PRIZE_REDEMPTIONS';
export const clearIims2023PrizeRedemptions = makeActionCreator(
  CLEAR_IIMS_2023_PRIZE_REDEMPTIONS,
);

export const SET_IIMS_2023_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_IIMS_2023_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE';
export const setIims2023PrizeRedemptionSelectedPageSize = makeActionCreator(SET_IIMS_2023_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_IIMS_2023_PRIZE_REDEMPTION_SELECTED_ORDER_BY = 'SET_IIMS_2023_PRIZE_REDEMPTION_SELECTED_ORDER_BY';
export const setIims2023PrizeRedemptionSelectedOrderBy = makeActionCreator(SET_IIMS_2023_PRIZE_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_IIMS_2023_PRIZE_REDEMPTION_TAPPED_ID = 'SET_IIMS_2023_PRIZE_REDEMPTION_TAPPED_ID';
export const setIims2023PrizeRedemptionTappedId = makeActionCreator(SET_IIMS_2023_PRIZE_REDEMPTION_TAPPED_ID, 'id');

export const SET_IIMS_2023_PRIZE_REDEMPTION_SEARCH_TEXT = 'SET_IIMS_2023_PRIZE_REDEMPTION_SEARCH_TEXT';
export const setIims2023PrizeRedemptionSearchText = makeActionCreator(SET_IIMS_2023_PRIZE_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_IIMS_2023_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING = 'SET_IIMS_2023_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING';
export const setIims2023PrizeRedemptionAdvancedFilterString = makeActionCreator(SET_IIMS_2023_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_IIMS_2023_2022_PRIZE_REDEMPTIONS = 'SAVING_IIMS_2023_2022_PRIZE_REDEMPTIONS';
export const savingIims2023PrizeRedemptions = makeActionCreator(SAVING_IIMS_2023_2022_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_IIMS_2023_2022_TRANSACTIONS = 'DOWNLOADING_IIMS_2023_2022_TRANSACTIONS';
export const downloadingIims2023Transactions = makeActionCreator(DOWNLOADING_IIMS_2023_2022_TRANSACTIONS, 'status');

export const DOWNLOADING_IIMS_2023_2022_TRANSACTION = 'DOWNLOADING_IIMS_2023_2022_TRANSACTION';
export const downloadingIims2023Transaction = makeActionCreator(DOWNLOADING_IIMS_2023_2022_TRANSACTION, 'status');

export const SAVING_IIMS_2023_2022_TRANSACTIONS = 'SAVING_IIMS_2023_2022_TRANSACTIONS';
export const savingIims2023Transactions = makeActionCreator(SAVING_IIMS_2023_2022_TRANSACTIONS, 'status');

export const ADD_IIMS_2023_2022_TRANSACTION = 'ADD_IIMS_2023_2022_TRANSACTION';
export const addIims2023Transaction = makeActionCreator(ADD_IIMS_2023_2022_TRANSACTION, 'data');

export const SET_IIMS_2023_2022_TRANSACTIONS = 'SET_IIMS_2023_2022_TRANSACTIONS';
export const setIims2023Transactions = makeActionCreator(SET_IIMS_2023_2022_TRANSACTIONS, 'data');

export const CLEAR_IIMS_2023_2022_TRANSACTIONS = 'CLEAR_IIMS_2023_2022_TRANSACTIONS';
export const clearIims2023Transactions = makeActionCreator(
  CLEAR_IIMS_2023_2022_TRANSACTIONS,
);

export const SET_IIMS_2023_2022_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_IIMS_2023_2022_TRANSACTION_SELECTED_PAGE_SIZE';
export const setIims2023TransactionSelectedPageSize = makeActionCreator(SET_IIMS_2023_2022_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_IIMS_2023_2022_TRANSACTION_SELECTED_ORDER_BY = 'SET_IIMS_2023_2022_TRANSACTION_SELECTED_ORDER_BY';
export const setIims2023TransactionSelectedOrderBy = makeActionCreator(SET_IIMS_2023_2022_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_IIMS_2023_2022_TRANSACTION_TAPPED_ID = 'SET_IIMS_2023_2022_TRANSACTION_TAPPED_ID';
export const setIims2023TransactionTappedId = makeActionCreator(SET_IIMS_2023_2022_TRANSACTION_TAPPED_ID, 'id');

export const SET_IIMS_2023_2022_TRANSACTION_SEARCH_TEXT = 'SET_IIMS_2023_2022_TRANSACTION_SEARCH_TEXT';
export const setIims2023TransactionSearchText = makeActionCreator(SET_IIMS_2023_2022_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_IIMS_2023_2022_TRANSACTION_ADVANCED_FILTER_STRING = 'SET_IIMS_2023_2022_TRANSACTION_ADVANCED_FILTER_STRING';
export const setIims2023TransactionAdvancedFilterString = makeActionCreator(SET_IIMS_2023_2022_TRANSACTION_ADVANCED_FILTER_STRING, 'text');


export const DOWNLOADING_IIMS_2023_SURVEYS = 'DOWNLOADING_IIMS_2023_SURVEYS';
export const downloadingIims2023Surveys = makeActionCreator(DOWNLOADING_IIMS_2023_SURVEYS, 'status');

export const DOWNLOADING_IIMS_2023_SURVEY = 'DOWNLOADING_IIMS_2023_SURVEY';
export const downloadingIims2023Survey = makeActionCreator(DOWNLOADING_IIMS_2023_SURVEY, 'status');

export const SET_IIMS_2023_SURVEYS = 'SET_IIMS_2023_SURVEYS';
export const setIims2023Surveys = makeActionCreator(SET_IIMS_2023_SURVEYS, 'data');

export const SET_IIMS_2023_SURVEY = 'SET_IIMS_2023_SURVEY';
export const setIims2023Survey = makeActionCreator(SET_IIMS_2023_SURVEY, 'data');

export const CLEAR_IIMS_2023_SURVEYS = 'CLEAR_IIMS_2023_SURVEYS';
export const clearIims2023Surveys = makeActionCreator(CLEAR_IIMS_2023_SURVEYS, 'data');

export const SET_IIMS_2023_SURVEYS_PAGE_SIZE = 'SET_IIMS_2023_SURVEYS_PAGE_SIZE';
export const setIims2023SurveysPageSize = makeActionCreator(SET_IIMS_2023_SURVEYS_PAGE_SIZE, 'size');

export const SET_IIMS_2023_SURVEYS_ORDER_BY = 'SET_IIMS_2023_SURVEYS_ORDER_BY';
export const setIims2023SurveysOrderBy = makeActionCreator(SET_IIMS_2023_SURVEYS_ORDER_BY, 'order');

export const SET_IIMS_2023_SURVEYS_SEARCH_TEXT = 'SET_IIMS_2023_SURVEYS_SEARCH_TEXT';
export const setIims2023SurveysSearchText = makeActionCreator(SET_IIMS_2023_SURVEYS_SEARCH_TEXT, 'text');

export const SET_IIMS_2023_SURVEYS_TAPPED_ID = 'SET_IIMS_2023_SURVEYS_TAPPED_ID';
export const setIims2023SurveysTappedId = makeActionCreator(SET_IIMS_2023_SURVEYS_TAPPED_ID, 'id');

export const SET_IIMS_2023_SURVEYS_FILTER_STRING = 'SET_IIMS_2023_SURVEYS_FILTER_STRING';
export const setIims2023SurveysFilterString = makeActionCreator(SET_IIMS_2023_SURVEYS_FILTER_STRING, 'text');

export const SAVING_IIMS_2023_2022_SURVEYS = 'SAVING_IIMS_2023_2022_TSURVEY';
export const savingIims2023Surveys = makeActionCreator(SAVING_IIMS_2023_2022_SURVEYS, 'status');

export const DOWNLOADING_IIMS_2023_SPKS = 'DOWNLOADING_IIMS_2023_SPKS';
export const downloadingIims2023Spks = makeActionCreator(DOWNLOADING_IIMS_2023_SPKS, 'status');

export const DOWNLOADING_IIMS_2023_SPK = 'DOWNLOADING_IIMS_2023_SPK';
export const downloadingIims2023Spk = makeActionCreator(DOWNLOADING_IIMS_2023_SPK, 'status');

export const SET_IIMS_2023_SPKS = 'SET_IIMS_2023_SPKS';
export const setIims2023Spks = makeActionCreator(SET_IIMS_2023_SPKS, 'data');

export const SET_IIMS_2023_SPK = 'SET_IIMS_2023_SPK';
export const setIims2023Spk = makeActionCreator(SET_IIMS_2023_SPK, 'data');

export const CLEAR_IIMS_2023_SPKS = 'CLEAR_IIMS_2023_SPKS';
export const clearIims2023Spks = makeActionCreator(CLEAR_IIMS_2023_SPKS, 'data');

export const SET_IIMS_2023_SPKS_PAGE_SIZE = 'SET_IIMS_2023_SPKS_PAGE_SIZE';
export const setIims2023SpksPageSize = makeActionCreator(SET_IIMS_2023_SPKS_PAGE_SIZE, 'size');

export const SET_IIMS_2023_SPKS_ORDER_BY = 'SET_IIMS_2023_SPKS_ORDER_BY';
export const setIims2023SpksOrderBy = makeActionCreator(SET_IIMS_2023_SPKS_ORDER_BY, 'order');

export const SET_IIMS_2023_SPKS_SEARCH_TEXT = 'SET_IIMS_2023_SPKS_SEARCH_TEXT';
export const setIims2023SpksSearchText = makeActionCreator(SET_IIMS_2023_SPKS_SEARCH_TEXT, 'text');

export const SET_IIMS_2023_SPKS_TAPPED_ID = 'SET_IIMS_2023_SPKS_TAPPED_ID';
export const setIims2023SpksTappedId = makeActionCreator(SET_IIMS_2023_SPKS_TAPPED_ID, 'id');

export const SET_IIMS_2023_SPKS_FILTER_STRING = 'SET_IIMS_2023_SPKS_FILTER_STRING';
export const setIims2023SpksFilterString = makeActionCreator(SET_IIMS_2023_SPKS_FILTER_STRING, 'text');

export const SAVING_IIMS_2023_2022_SPKS = 'SAVING_IIMS_2023_2022_TSPK';
export const savingIims2023Spks = makeActionCreator(SAVING_IIMS_2023_2022_SPKS, 'status');
