import moment from 'moment-timezone';
import { REVERSED_ISO_FULL_DATE_TIME_FORMAT } from '../../../constant';
import {
  clearVoucherTokens, revokingSelectedVoucherTokens, setAlertInfoMessage, setAlertErrorMessage,
} from '../simple-action';
import { localDateToUtc, revokeVoucherToken } from '../../../helper';
import downloadVoucherTokensAsync from './downloadVoucherTokensAsync';
import LocalizedString from '../../../../../localization';

export default () => async (dispatch, getState) => {
  try {
    dispatch(revokingSelectedVoucherTokens(true));

    const { token } = getState().authentication;
    const { selectedRows } = getState().uiVoucherToken;

    let successful = 0;

    const today = localDateToUtc(moment().format(), REVERSED_ISO_FULL_DATE_TIME_FORMAT);

    for (let i = 0; i < selectedRows.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await revokeVoucherToken(selectedRows[i].id, `Bulk Revoke (${today})`, token);
      successful += 1;
    }

    if (successful < selectedRows.length) {
      dispatch(setAlertInfoMessage(LocalizedString.common.errMsgFailedActions.replace(/\{number\}/, selectedRows.length - successful).replace(/\{total\}/, selectedRows.length)));
    }

    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(revokingSelectedVoucherTokens(false));
  }
};
