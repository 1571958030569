import { downloadJaw2023ScannedCodes, transformSearchText } from '../../../helper';
import { downloadingJaw2023ScannedCodes, setJaw2023ScannedCodes } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, jaw2023ScannedCodes, uiJaw2023ScannedCode } = getState();

  const { token } = authentication;
  const { meta } = jaw2023ScannedCodes;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiJaw2023ScannedCode;

  try {
    dispatch(downloadingJaw2023ScannedCodes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const scannedCodes = await downloadJaw2023ScannedCodes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    dispatch(setJaw2023ScannedCodes(scannedCodes));
  } finally {
    dispatch(downloadingJaw2023ScannedCodes(false));
  }
};
