import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_COUPON_VOID_REQUEST_COUPON, RXFIELD_COUPON_VOID_REQUEST_REASON,
  RXFIELD_COUPON_VOID_REQUEST_MERCHANT_REFERENCE_ID,
  RXFIELD_VOID_COUPON_DIALOG_REASON,
  RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID,
  RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION,
  RXFIELD_COUPON_SUBMISSION_USER, RXFIELD_COUPON_SUBMISSION_AMOUNT,
  RXFIELD_COUPON_SUBMISSION_LABEL, RXFIELD_COUPON_SUBMISSION_VALID_UNTIL,
  RXFIELD_COUPON_SUBMISSION_MERCHANT_REFERENCE_ID,
  RXFIELD_COUPON_SUBMISSION_SEND_NOTIFICATION,
} from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateVoidCouponRequest = (values) => {
  const requiredFields = [
    RXFIELD_COUPON_VOID_REQUEST_REASON,
    RXFIELD_COUPON_VOID_REQUEST_COUPON,
    RXFIELD_COUPON_VOID_REQUEST_MERCHANT_REFERENCE_ID,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
export const rxformValidateVoidCouponDialog = (values) => {
  const requiredFields = [
    RXFIELD_VOID_COUPON_DIALOG_REASON,
    RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID,
    RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxFormValidateCouponSubmission = (values) => {
  const requiredFields = [
    RXFIELD_COUPON_SUBMISSION_USER,
    RXFIELD_COUPON_SUBMISSION_AMOUNT,
    RXFIELD_COUPON_SUBMISSION_LABEL,
    RXFIELD_COUPON_SUBMISSION_VALID_UNTIL,
    RXFIELD_COUPON_SUBMISSION_MERCHANT_REFERENCE_ID,
    RXFIELD_COUPON_SUBMISSION_SEND_NOTIFICATION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
