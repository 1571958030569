
import {
  DOWNLOADING_VOUCHER_TOKEN_BY_CODE, CLAIMING_VOUCHER_TOKEN_BY_CODE,
} from '../action';

const initialState = {
  downloading: false,
  claiming: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_VOUCHER_TOKEN_BY_CODE:
      return { ...state, downloading: action.status };
    case CLAIMING_VOUCHER_TOKEN_BY_CODE:
      return { ...state, claiming: action.status };
    default: return state;
  }
};
