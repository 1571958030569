import { setResyncUsers, resyncingUsers } from '../simple-action';
import { resyncUsers } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingUsers(true));

    const { token } = getState().authentication;

    const data = await resyncUsers(token);

    dispatch(setResyncUsers({}));
    dispatch(setResyncUsers(data));
  } finally {
    dispatch(resyncingUsers(false));
  }
};
