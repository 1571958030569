import { downloadingGarudaAirports, setGarudaAirports } from '../simple-action';
import { downloadGarudaAirports, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, garudaAirports, uiGarudaAirport } = getState();
  const { token } = authentication;
  const { meta } = garudaAirports;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiGarudaAirport;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingGarudaAirports(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const result = await downloadGarudaAirports(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      token,
    );
    dispatch(setGarudaAirports(result));
  } finally {
    dispatch(downloadingGarudaAirports(false));
  }
};
