import { INITIAL_ORDER_BY_WASTE_COLLECTIONS } from '../../constant';
import {
  DOWNLOADING_DELETING_WASTE_COLLECTION,
  DOWNLOADING_WASTE_COLLECTIONS,
  SAVING_WASTE_COLLECTIONS,
  SET_WASTE_COLLECTIONS_ADVANCED_FILTER_STRING,
  SET_WASTE_COLLECTIONS_ORDER_BY, SET_WASTE_COLLECTIONS_SEARCH_TEXT,
  SET_WASTE_COLLECTIONS_SELECTED_PAGE_SIZE, SET_WASTE_COLLECTIONS_TAPPED_ID,
} from '../action';


const initialState = {
  downloading: false,
  downloadingDeleting: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_WASTE_COLLECTIONS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WASTE_COLLECTIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_WASTE_COLLECTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_WASTE_COLLECTIONS_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WASTE_COLLECTIONS_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WASTE_COLLECTIONS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WASTE_COLLECTIONS_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WASTE_COLLECTIONS_ADVANCED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SAVING_WASTE_COLLECTIONS:
      return { ...state, saving: action.status };
    default: return state;
  }
};
