import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  RXFORM_TEST_DRIVE_EVENT_ORGANIZER, RXSTATE_EVENT_ORGANIZERS, RXSTATE_EVENT_ORGANIZER_PAGE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_EVENT_ORGANIZER_USER_ID,
  RXFIELD_EVENT_ORGANIZER_EVENT_ID,
  RXFIELD_EVENT_ORGANIZER_USER_FULLNAME,
  RXFIELD_EVENT_ORGANIZER_EVENT_NAME,
  RXFIELD_EVENT_ORGANIZER_CREATED_DATE,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  PAGE_MODE_VIEW,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import { rxformValidateEventOrganizer } from '../../validation';

const renderDialogContent = (initialValues, addingEditing, downloadingDeleting, pageMode,
  users, loadingUsers, onChangeUserText, onUserOptionSelected,
  events, loadingEvents, onChangeEventText, onEventOptionSelected) => (
    <div>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_ORGANIZER_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventOrganizerPage.labelUserId}
              label={LocalizedString.eventOrganizerPage.labelUserId}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_ORGANIZER_USER_FULLNAME}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.eventOrganizerPage.labelUserFullname}
              label={LocalizedString.eventOrganizerPage.labelUserFullname}
              data={users}
              value={initialValues.user}
              loading={loadingUsers}
              onChangeText={onChangeUserText}
              onOptionSelected={onUserOptionSelected}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_ORGANIZER_EVENT_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventOrganizerPage.labelEventId}
              label={LocalizedString.eventOrganizerPage.labelEventId}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_ORGANIZER_EVENT_NAME}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.eventOrganizerPage.labelEventName}
              label={LocalizedString.eventOrganizerPage.labelEventName}
              data={events}
              value={initialValues.event}
              loading={loadingEvents}
              onChangeText={onChangeEventText}
              onOptionSelected={onEventOptionSelected}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
);

const EventOrganizerPage = ({
  addingEditing, pageMode, downloadingDeleting,
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed, onCreatePressed,
  onSubmitPressed, onDeletePressed, onConfirmDeletePressed,
  onEditPressed, onSavePressed, onCancelPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText, onUserOptionSelected,
  events, loadingEvents, onChangeEventText, onEventOptionSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_EVENT_ORGANIZERS}
    uiPage={RXSTATE_EVENT_ORGANIZER_PAGE}
    tableColumns={[
      {
        title: LocalizedString.eventOrganizerPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.eventOrganizerPage.labelUser, field: 'profile.fullName', sorting: !downloading },
      { title: LocalizedString.eventOrganizerPage.labelEventName, field: 'event.name', sorting: !downloading },
      {
        title: LocalizedString.eventOrganizerPage.labelCreatedDate,
        field: 'createdDate',
        render: ({ createdDate }) => toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
        sorting: !downloading,
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.eventOrganizerPage.labelCreatedDate,
        field: RXFIELD_EVENT_ORGANIZER_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
      },
      {
        title: LocalizedString.eventOrganizerPage.labelUserFullname,
        field: RXFIELD_EVENT_ORGANIZER_USER_FULLNAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.eventOrganizerPage.labelEventName,
        field: RXFIELD_EVENT_ORGANIZER_EVENT_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: events,
        loading: loadingEvents,
        onChangeFilterText: onChangeEventText,
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCreatePressed={onCreatePressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.eventOrganizerPage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    createNewButtonCaption={LocalizedString.eventOrganizerPage.buttonCaptionCreateEventOrganizer}
    deleteButtonCaption={LocalizedString.eventOrganizerPage.buttonCaptionDeleteEventOrganizer}
    editButtonCaption={LocalizedString.eventOrganizerPage.buttonCaptionEditEventOrganizer}
    useFullWidth
  >
    {renderDialogContent(initialValues, addingEditing, downloadingDeleting, pageMode,
      users, loadingUsers, onChangeUserText, onUserOptionSelected,
      events, loadingEvents, onChangeEventText, onEventOptionSelected)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TEST_DRIVE_EVENT_ORGANIZER,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidateEventOrganizer,
})(EventOrganizerPage);

EventOrganizerPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loadingEvents: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEventOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
