import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  Grid, makeStyles, Typography, Divider,
} from '@material-ui/core';
import {
  BasePage, TotalCountSection, AccentButton, PieChart, SimpleBarChart,
} from '../../component';
import {
  COLOR_SECONDARY, RXFORM_DASHBOARD_SERVICE_PROMO,
  RXFIELD_DASHBOARD_SERVICE_PROMO_START_DATE,
  RXFIELD_DASHBOARD_SERVICE_PROMO_END_DATE,
  PICKER_MODE_DATE, PICKER_DATE_FORMAT,
  COLOR_BODY_TEXT_LIGHTER, COLOR_PRIMARY,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormDateTimePickerField } from '../../../../redux-form-rendererer';
import { rxformValidateServicePromo } from '../../validation';
import { PacketSummaryShape } from '../../../packet/type';
import { toCurrency } from '../../helper';

const useStyles = makeStyles(() => ({
  filterItem: {
    marginTop: 24,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  bodyContainer: {
    marginTop: 20,
  },
  caption: {
    color: COLOR_BODY_TEXT_LIGHTER,
    textAlign: 'center',
  },
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
}));

const ServicePromoPage = ({
  onAppear, downloadingBookingCreated, bookingCreated,
  downloadingBookingProceedByDealer, bookingProceedByDealer,
  downloadingBookingWaitForPayment, bookingWaitForPayment,
  downloadingBookingCompleted, bookingCompleted,
  downloadingBookingCanceled, bookingCanceled,
  downloadingTransactionReceived, transactionReceived,
  downloadingTransactionPending, transactionPending,
  downloadingTransactionPaid, transactionPaid,
  downloadingTransactionCanceled, transactionCanceled,
  downloadingTransactionAmount, transactionAmount,
  onChangeDate, handleSubmit, onApplyPressed,
  bookingCreatedByRepairSelection, downloadingBookingCreatedByRepairSelection,
  bookingCreatedByDeliveryMethod, downloadingBookingCreatedByDeliveryMethod,
  bookingCreatedByServiceInterval, downloadingBookingCreatedByServiceInterval,
  bookingCreatedByVehicleModel, downloadingBookingCreatedByVehicleModel,
  transactionCountByPartName, downloadingTransactionCountByPartName,
  transactionAmountByPartName, downloadingTransactionAmountByPartName,
  remaningStockByPartName, downloadingRemaningStockByPartName,
  transactionCreatedByVehicleModel, downloadingTransactionCreatedByVehicleModel,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  const downloading = downloadingBookingProceedByDealer
  || downloadingBookingWaitForPayment
  || downloadingBookingCompleted
  || downloadingBookingCanceled
  || downloadingBookingCreatedByRepairSelection
  || downloadingBookingCreatedByDeliveryMethod
  || downloadingBookingCreatedByServiceInterval
  || downloadingBookingCreatedByVehicleModel
  || downloadingTransactionReceived
  || downloadingTransactionPending
  || downloadingTransactionPaid
  || downloadingTransactionCanceled
  || downloadingTransactionAmount
  || downloadingTransactionCountByPartName
  || downloadingTransactionAmountByPartName
  || downloadingRemaningStockByPartName
  || downloadingTransactionCreatedByVehicleModel;

  return (
    <BasePage>
      <Grid container justify="flex-end">
        <Typography variant="body1" className={`${classes.caption} ${classes.filterItem}`}>
          {LocalizedString.servicePromoPage.labelPeriod}
        </Typography>
        <Grid item sm md lg={3}>
          <Field
            name={RXFIELD_DASHBOARD_SERVICE_PROMO_START_DATE}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.servicePromoPage.labelStartDate}
            disabled={downloading}
            pickerMode={PICKER_MODE_DATE}
            onChangeDate={(e) => onChangeDate(e, RXFIELD_DASHBOARD_SERVICE_PROMO_START_DATE)}
            dateFormat={PICKER_DATE_FORMAT}
            disableFuture
          />
        </Grid>
        <Typography variant="body1" className={`${classes.caption} ${classes.filterItem}`}>
          -
        </Typography>
        <Grid item sm md lg={3}>
          <Field
            name={RXFIELD_DASHBOARD_SERVICE_PROMO_END_DATE}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.servicePromoPage.labelEndDate}
            disabled={downloading}
            pickerMode={PICKER_MODE_DATE}
            onChangeDate={(e) => onChangeDate(e, RXFIELD_DASHBOARD_SERVICE_PROMO_END_DATE)}
            dateFormat={PICKER_DATE_FORMAT}
            disableFuture
          />
        </Grid>
        <Grid item className={classes.filterItem}>
          <AccentButton
            onClick={handleSubmit((values) => onApplyPressed(values))}
            caption={LocalizedString.servicePromoPage.labelApply}
          />
        </Grid>
      </Grid>

      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>
          {LocalizedString.servicePromoPage.labelBookingInformation}
        </Typography>
      </div>

      <div className={classes.bodyContainer}>
        <TotalCountSection
          data={[
            {
              downloading: downloadingBookingCreated,
              count: toCurrency(bookingCreated),
              icon: null,
              label: LocalizedString.servicePromoPage.labelBookingCreated,
            },
            {
              downloading: downloadingBookingProceedByDealer,
              count: toCurrency(bookingProceedByDealer),
              icon: null,
              label: LocalizedString.servicePromoPage.labelBookingProceedByDealer,
            },
            {
              downloading: downloadingBookingWaitForPayment,
              count: toCurrency(bookingWaitForPayment),
              icon: null,
              label: LocalizedString.servicePromoPage.labelBookingWaitForPayment,
            },
            {
              downloading: downloadingBookingCompleted,
              count: toCurrency(bookingCompleted),
              icon: null,
              label: LocalizedString.servicePromoPage.labelBookingCompleted,
            },
            {
              downloading: downloadingBookingCanceled,
              count: toCurrency(bookingCanceled),
              icon: null,
              label: LocalizedString.servicePromoPage.labelBookingCanceled,
            },
          ]}
          flipDataPosition
        />
        <div className={classes.bodyContainer} />
        <Grid container spacing={3}>
          <Grid item xs sm md>
            <SimpleBarChart
              data={bookingCreatedByRepairSelection}
              loading={downloadingBookingCreatedByRepairSelection}
              argumentField="Key"
              title={LocalizedString.servicePromoPage.labelBookingCreatedByRepairSelection}
              valueField="Value"
              useCustomTitle
              rotated
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs sm md>
            <SimpleBarChart
              data={bookingCreatedByDeliveryMethod}
              loading={downloadingBookingCreatedByDeliveryMethod}
              argumentField="Key"
              title={LocalizedString.servicePromoPage.labelBookingCreatedByDeliveryMethod}
              valueField="Value"
              useCustomTitle
              rotated
            />
          </Grid>
        </Grid>
        <div className={classes.bodyContainer} />
        <Grid container>
          <Grid item xs sm md>
            <SimpleBarChart
              data={bookingCreatedByServiceInterval}
              loading={downloadingBookingCreatedByServiceInterval}
              argumentField="Key"
              title={LocalizedString.servicePromoPage.labelBookingCreatedByServiceInterval}
              valueField="Value"
              useCustomTitle
              rotated
            />
          </Grid>
        </Grid>
        <div className={classes.bodyContainer} />
        <Grid container spacing={3}>
          <Grid item xs sm md>
            <PieChart
              data={bookingCreatedByVehicleModel}
              loading={downloadingBookingCreatedByVehicleModel}
              argumentField="name"
              title={LocalizedString.servicePromoPage.labelBookingCreatedByVehicleModel}
              valueField="count"
              useCustomTitle
            />
          </Grid>
        </Grid>
        <div className={classes.bodyContainer} />
      </div>
      <div className={classes.bodyContainer} />
      <Divider className={classes.divider} />
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>
          {LocalizedString.servicePromoPage.labelTransactionInformation}
        </Typography>
      </div>
      <div className={classes.bodyContainer}>
        <TotalCountSection
          data={[
            {
              downloading: downloadingTransactionReceived,
              count: toCurrency(transactionReceived),
              icon: null,
              label: LocalizedString.servicePromoPage.labelTransactionReceived,
            },
            {
              downloading: downloadingTransactionPending,
              count: toCurrency(transactionPending),
              icon: null,
              label: LocalizedString.servicePromoPage.labelTransactionPending,
            },
            {
              downloading: downloadingTransactionPaid,
              count: toCurrency(transactionPaid),
              icon: null,
              label: LocalizedString.servicePromoPage.labelTransactionCompleted,
            },
            {
              downloading: downloadingTransactionCanceled,
              count: toCurrency(transactionCanceled),
              icon: null,
              label: LocalizedString.servicePromoPage.labelTransactionCanceled,
            },
          ]}
          flipDataPosition
        />
        <div className={classes.bodyContainer} />
        <TotalCountSection
          data={[
            {
              downloading: downloadingTransactionAmount,
              count: transactionAmount,
              icon: null,
              label: LocalizedString.servicePromoPage.labelTransactionAmount,
            },
          ]}
          flipDataPosition
        />
        <Grid container spacing={3}>
          <Grid item xs sm md>
            <SimpleBarChart
              data={transactionCountByPartName}
              loading={downloadingTransactionCountByPartName}
              argumentField="Key"
              title={LocalizedString.servicePromoPage.labelTransactionCount}
              valueField="Value"
              useCustomTitle
              rotated
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs sm md>
            <SimpleBarChart
              data={transactionAmountByPartName}
              loading={downloadingTransactionAmountByPartName}
              argumentField="Key"
              title={LocalizedString.servicePromoPage.labelTransactionAmount}
              valueField="Value"
              useCustomTitle
              rotated
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs sm md>
            <SimpleBarChart
              data={remaningStockByPartName}
              loading={downloadingRemaningStockByPartName}
              argumentField="Key"
              title={LocalizedString.servicePromoPage.labelRemaningStock}
              valueField="Value"
              useCustomTitle
              rotated
              helperText={LocalizedString.servicePromoPage.labelRealTimeStock}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs sm md>
            <PieChart
              data={transactionCreatedByVehicleModel}
              loading={downloadingTransactionCreatedByVehicleModel}
              argumentField="name"
              title={LocalizedString.servicePromoPage.labelTransactionCreatedByVehicleModel}
              valueField="count"
              useCustomTitle
            />
          </Grid>
        </Grid>
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_DASHBOARD_SERVICE_PROMO,
  validate: rxformValidateServicePromo,
})(ServicePromoPage);

ServicePromoPage.propTypes = {
  bookingCreatedByRepairSelection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  bookingCreatedByDeliveryMethod: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  bookingCreatedByVehicleModel: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  transactionCountByPartName: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  transactionAmountByPartName: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  remaningStockByPartName: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  transactionCreatedByVehicleModel: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  bookingCreatedByServiceInterval: PropTypes.arrayOf(PacketSummaryShape).isRequired,

  bookingCreated: PropTypes.number.isRequired,
  bookingProceedByDealer: PropTypes.number.isRequired,
  bookingWaitForPayment: PropTypes.number.isRequired,
  bookingCompleted: PropTypes.number.isRequired,
  bookingCanceled: PropTypes.number.isRequired,
  transactionReceived: PropTypes.number.isRequired,
  transactionPending: PropTypes.number.isRequired,
  transactionPaid: PropTypes.number.isRequired,
  transactionCanceled: PropTypes.number.isRequired,
  transactionAmount: PropTypes.string.isRequired,

  downloadingBookingCreated: PropTypes.bool.isRequired,
  downloadingBookingProceedByDealer: PropTypes.bool.isRequired,
  downloadingBookingWaitForPayment: PropTypes.bool.isRequired,
  downloadingBookingCompleted: PropTypes.bool.isRequired,
  downloadingBookingCanceled: PropTypes.bool.isRequired,
  downloadingBookingCreatedByRepairSelection: PropTypes.bool.isRequired,
  downloadingBookingCreatedByDeliveryMethod: PropTypes.bool.isRequired,
  downloadingBookingCreatedByServiceInterval: PropTypes.bool.isRequired,
  downloadingBookingCreatedByVehicleModel: PropTypes.bool.isRequired,
  downloadingTransactionReceived: PropTypes.bool.isRequired,
  downloadingTransactionPending: PropTypes.bool.isRequired,
  downloadingTransactionPaid: PropTypes.bool.isRequired,
  downloadingTransactionCanceled: PropTypes.bool.isRequired,
  downloadingTransactionAmount: PropTypes.bool.isRequired,
  downloadingTransactionCountByPartName: PropTypes.bool.isRequired,
  downloadingTransactionAmountByPartName: PropTypes.bool.isRequired,
  downloadingRemaningStockByPartName: PropTypes.bool.isRequired,
  downloadingTransactionCreatedByVehicleModel: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onApplyPressed: PropTypes.func.isRequired,
};
