import {
  downloadingAttendanceSummaryTodayClockOutStatus, setAttendanceSummaryTodayClockOutStatus,
} from '../simple-action';
import { downloadAttendanceSummaryTodayClockOutStatus } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTodayClockOutStatus(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryTodayClockOutStatus(token);

    dispatch(setAttendanceSummaryTodayClockOutStatus(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTodayClockOutStatus(false));
  }
};
