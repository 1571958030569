import { SET_POINT_BALANCE, CLEAR_POINT_BALANCE } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_POINT_BALANCE: {
      return action.data;
    }
    case CLEAR_POINT_BALANCE:
      return state;
    default:
      return state;
  }
};
