import { reset } from 'redux-form';
import {
  addingEditingEvent, clearEvents, setAlertErrorMessage, setFunctionalPageMode,
  setEventSelectedVoucher,
} from '../simple-action';
import { addEvent, editEvent, localDateToUtc } from '../../../helper';
import { RXFORM_EVENT, PAGE_MODE_TABLE, REVERSED_ISO_DATE_TIME_FORMAT } from '../../../constant';
import downloadEventsAsync from './downloadEventsAsync';

export default (name, description, location, visibility, startTime, endTime, url1,
  url2, surveyUrl) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingEvent(true));

    const { token } = getState().authentication;
    const {
      tappedId, url1ExtensionBrowser, url2ExtensionBrowser,
      surveyUrlExtensionBrowser, selectedVoucher, selectedCityId,
    } = getState().uiEvent;
    const { data } = getState().events;
    const { timeZone } = getState().cities.data[selectedCityId].province;

    const found = data[tappedId];
    const url1Default = url1 ?? '';
    const url2Default = url2 ?? '';
    const surveyUrlDefault = surveyUrl ?? '';
    const newStartTime = localDateToUtc(startTime, REVERSED_ISO_DATE_TIME_FORMAT, timeZone);
    const newEndTime = localDateToUtc(endTime, REVERSED_ISO_DATE_TIME_FORMAT, timeZone);
    const url1ExtBrowser = url1 ? url1ExtensionBrowser : false;
    const url2ExtBrowser = url2 ? url2ExtensionBrowser : false;
    const surveyUrlExtBrowser = surveyUrl ? surveyUrlExtensionBrowser : false;
    const voucher = [selectedVoucher?.value || found.voucherRewards[0]?.voucherId];
    const city = selectedCityId || found.city?.id;

    if (tappedId) {
      await editEvent(tappedId, name, description, location, visibility,
        newStartTime, newEndTime, url1Default, url2Default,
        url1ExtBrowser, url2ExtBrowser, city,
        surveyUrlDefault, surveyUrlExtBrowser, voucher, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addEvent(name, description, location, visibility,
        newStartTime, newEndTime, url1Default, url2Default,
        url1ExtBrowser, url2ExtBrowser, city,
        surveyUrlDefault, surveyUrlExtBrowser, voucher, token);
    }

    dispatch(reset(RXFORM_EVENT));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setEventSelectedVoucher(null));
  } finally {
    dispatch(addingEditingEvent(false));
  }
};
