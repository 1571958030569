import { reset } from 'redux-form';
import {
  addingEditingCompanyMData, clearCompaniesMData, setAlertErrorMessage,
  setCompanyBannerImage,
  setCompanyLogoImage,
  setFunctionalPageMode,
} from '../simple-action';
import { addCompanyMData, editCompanyMData, transformLocalImagePath } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_COMPANY } from '../../../constant';
import downloadMDataCompaniesAsync from './downloadMDataCompaniesAsync';

export default (name, description, latitude, longitude, googleMapUrl, address, postalCode, phone,
  tags, email, website, logo, banner, appStoreUrl, playStoreUrl) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCompanyMData(true));

    const { token } = getState().authentication;
    const {
      marker, selectedCityId, tappedId, clearLogo,
    } = getState().uiCompany;
    const { data } = getState().companiesMdata;

    const found = data[tappedId];
    const city = selectedCityId || found.city.id;
    const lat = Object.prototype.hasOwnProperty.call(marker, 'lat') ? marker.lat : latitude;
    const lng = Object.prototype.hasOwnProperty.call(marker, 'lng') ? marker.lng : latitude;

    if (tappedId) {
      await editCompanyMData(tappedId, name, description, lat, lng, googleMapUrl, address,
        postalCode, phone, tags, email, website, city, transformLocalImagePath(logo),
        transformLocalImagePath(banner), clearLogo, appStoreUrl, playStoreUrl, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addCompanyMData(name, description, lat, lng, googleMapUrl, address, postalCode,
        phone, tags, email, website, city, transformLocalImagePath(logo),
        transformLocalImagePath(banner), appStoreUrl, playStoreUrl, token);
    }

    dispatch(reset(RXFORM_COMPANY));
    dispatch(setCompanyLogoImage(''));
    dispatch(setCompanyBannerImage(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingCompanyMData(false));
  }
};
