/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  CircularProgress, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { AutocompleteTextField, FunctionalPage } from '../../component';
import {
  approval, DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  REGEX_MATCH_STRING_INSIDE_CURLY_BRACES, REWARD_TYPE_VOUCHER, REWARD_TYPE_COIN,
  REWARD_TYPE_COUPON, rewards, RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE,
  RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT, RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT,
  RXFIELD_CREATED_DATE, RXFIELD_PROMO_CODE_BATCH_END_TIME, RXFIELD_PROMO_CODE_BATCH_MERCHANT,
  RXFIELD_PROMO_CODE_BATCH_MERCHANT_TAG, RXFIELD_PROMO_CODE_BATCH_MESSAGE_TITLE,
  RXFIELD_PROMO_CODE_BATCH_MESSAGE_BODY, RXFIELD_PROMO_CODE_BATCH_PREFIX,
  RXFIELD_PROMO_CODE_BATCH_PROGRESS, RXFIELD_PROMO_CODE_BATCH_PROGRESS_NOTES,
  RXFIELD_PROMO_CODE_BATCH_QUANTITY, RXFIELD_PROMO_CODE_BATCH_RANDOM_CODE_LENGTH,
  RXFIELD_PROMO_CODE_BATCH_REWARD, RXFIELD_PROMO_CODE_BATCH_START_TIME,
  RXFIELD_PROMO_CODE_BATCH_TASK_STATUS, RXFIELD_PROMO_CODE_BATCH_TASK_STATUS_NOTES,
  RXFIELD_PROMO_CODE_BATCH_MAX_REDEMPTION_COUNT, RXFIELD_PROMO_CODE_BATCH_VALID_FROM,
  RXFIELD_PROMO_CODE_BATCH_VALID_UNTIL, RXFIELD_PROMO_CODE_BATCH_VOUCHERS, RXFORM_PROMO_CODE_BATCH,
  RXSTATE_PROMO_CODE_BATCH_PAGE, RXSTATE_PROMO_CODE_BATCHES, TASK_STATUS_PROCESSING,
  TASK_STATUS_QUEUING, TASK_STATUSES, RXFIELD_PROMO_CODE_BATCH_MIN_SIGN_UP_DATE,
  RXFIELD_PROMO_CODE_BATCH_MAX_SIGN_UP_DATE, PROMO_CODE_USER_TYPE_OPTIONS,
  RXFIELD_PROMO_CODE_BATCH_USER_TYPE,
} from '../../constant';
import { toMoment } from '../../helper';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormAutocompleteCheckboxField,
  renderReduxFormAutocompleteTableField, renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { rxformValidatePromoCodeBatch } from '../../validation';

const useStyles = makeStyles(() => ({
  italicText: {
    fontStyle: 'italic',
  },
  captionText: {
    marginLeft: 10,
  },
}));

const renderAdditionalPlaceholder = (classes) => {
  const additionalUrlPlaceholder = LocalizedString.promoCodeBatchPage.msgNotificationBodyFormat;
  const additionalPlaceholderHtmlComponent = `<p><i>${additionalUrlPlaceholder.match(REGEX_MATCH_STRING_INSIDE_CURLY_BRACES) ? additionalUrlPlaceholder.replace(/{(.*?)}/g, '<b>{$1}</b>') : additionalUrlPlaceholder}</i></p>`;

  return (
    <div className={classes.captionText}>
      <Typography variant="body2" className={classes.italicText}>
        {GlobalLocalizedString.common.labelYouCanUsePlaceholdersFieldsAbove}
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: additionalPlaceholderHtmlComponent }} />
    </div>
  );
};

const renderDialogContent = (initialValues, tappedPromoCodeBatch, airports, merchants,
  selectedUserTypes, tableDataVouchers, vouchers, addingEditing, addingVouchers,
  downloadingDeleting, isSelectedVoucherGarudaLounge, loadingAirport, loadingMerchant,
  loadingVoucher, onAddVoucherPressed, onAirportOptionSelected,
  onBypassAchievementValidationOptionSelected, onBypassMaxPurchaseOptionSelected,
  onChangeAirportText, onChangeMerchantText, onChangeVoucherText, onDeleteVoucherPressed,
  onMerchantOptionSelected, onRewardTypeOptionSelected, onUserTypeSelected,
  onVoucherOptionSelected, selectedAirport, selectedVoucher, garudaCompanyNameConfigValue,
  pageMode, selectedRewardType, classes) => {
  const isNewUserSelected = selectedUserTypes.some(
    (x) => (x.label === PROMO_CODE_USER_TYPE_OPTIONS[0].label)
    || (x.label === PROMO_CODE_USER_TYPE_OPTIONS[1].label)
    || (x.label === PROMO_CODE_USER_TYPE_OPTIONS[2].label),
  );

  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_PREFIX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderPrefix}
            label={LocalizedString.promoCodeBatchPage.placeholderPrefix}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_QUANTITY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderQuantity}
            label={LocalizedString.promoCodeBatchPage.placeholderQuantity}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_RANDOM_CODE_LENGTH}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholdeRandomCodeLength}
            label={LocalizedString.promoCodeBatchPage.placeholdeRandomCodeLength}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_REWARD}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderReward}
            label={LocalizedString.promoCodeBatchPage.placeholderReward}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={rewards}
            value={initialValues.rewardType}
            onOptionSelected={onRewardTypeOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
            <Field
              name={RXFIELD_PROMO_CODE_BATCH_VOUCHERS}
              component={renderReduxFormAutocompleteTableField}
              label={LocalizedString.promoCodeBatchPage.placeholderVoucher}
              currentTableData={tableDataVouchers}
              defaultValue={initialValues.vouchers}
              options={vouchers}
              adding={addingVouchers}
              disableAdd={pageMode === PAGE_MODE_VIEW}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={selectedRewardType !== REWARD_TYPE_VOUCHER}
              loadingOptions={loadingVoucher}
              onAddPressed={(defaultValue, newInitialValue, selectedOption,
                currentData) => onAddVoucherPressed(defaultValue, newInitialValue, selectedOption,
                currentData, selectedAirport, garudaCompanyNameConfigValue)}
              onChangeOptionText={onChangeVoucherText}
              onDeletePressed={onDeleteVoucherPressed}
              onOptionSelected={(option) => onVoucherOptionSelected(option, selectedVoucher,
                garudaCompanyNameConfigValue, isSelectedVoucherGarudaLounge)}
              selectedOption={selectedVoucher}
              optionPlaceholder={LocalizedString.promoCodeBatchPage.placeholderVoucher}
              currentTappedData={tappedPromoCodeBatch}
              onBlur={(e) => e.preventDefault()}
              required
              renderCustomFields={() => isSelectedVoucherGarudaLounge && (
                <Grid item sm={10}>
                  <AutocompleteTextField
                    options={airports}
                    loading={loadingAirport}
                    placeholder={LocalizedString.promoCodePage.placeholderAirport}
                    label={LocalizedString.promoCodePage.placeholderAirport}
                    onChangeText={onChangeAirportText}
                    onOptionSelected={onAirportOptionSelected}
                    value={selectedAirport}
                    disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                  />
                </Grid>
              )}
            />
          )}
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderBypassMaxPurchase}
            label={LocalizedString.promoCodeBatchPage.placeholderBypassMaxPurchase}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassMaxPurchase}
            onOptionSelected={onBypassMaxPurchaseOptionSelected}
            required
            hidden={selectedRewardType !== REWARD_TYPE_VOUCHER}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderBypassAchievementValidation}
            label={LocalizedString.promoCodeBatchPage.placeholderBypassAchievementValidation}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassAchievementValidation}
            onOptionSelected={onBypassAchievementValidationOptionSelected}
            required
            hidden={selectedRewardType !== REWARD_TYPE_VOUCHER}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderCoinsAmount}
            label={LocalizedString.promoCodeBatchPage.placeholderCoinsAmount}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
            hidden={selectedRewardType !== REWARD_TYPE_COIN}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderCouponsAmount}
            label={LocalizedString.promoCodeBatchPage.placeholderCouponsAmount}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
            hidden={selectedRewardType !== REWARD_TYPE_COUPON}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_MAX_REDEMPTION_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderMaxRedemptionCount}
            label={LocalizedString.promoCodeBatchPage.placeholderMaxRedemptionCount}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_USER_TYPE}
            component={renderReduxFormAutocompleteCheckboxField}
            label={LocalizedString.promoCodeBatchPage.placeholderUserType}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={PROMO_CODE_USER_TYPE_OPTIONS}
            loading={downloadingDeleting}
            onOptionSelected={onUserTypeSelected}
            onBlur={(e) => e.preventDefault()}
            onChangeText={() => {}}
          />
        </Grid>

        { isNewUserSelected ? (
          <>
            <Grid item>
              <Field
                name={RXFIELD_PROMO_CODE_BATCH_MIN_SIGN_UP_DATE}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.promoCodeBatchPage.placeholderMinSignupDate}
                placeholder={LocalizedString.promoCodeBatchPage.placeholderMinSignupDate}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                hidden={!isNewUserSelected}
                required={isNewUserSelected}
              />
            </Grid>

            <Grid item>
              <Field
                name={RXFIELD_PROMO_CODE_BATCH_MAX_SIGN_UP_DATE}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.promoCodeBatchPage.placeholderMaxSignupDate}
                placeholder={LocalizedString.promoCodeBatchPage.placeholderMaxSignupDate}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                hidden={!isNewUserSelected}
                required={isNewUserSelected}
              />
            </Grid>
          </>
        ) : (<></>)}
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_PROGRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderProgress}
            label={LocalizedString.promoCodeBatchPage.placeholderProgress}
            disabled
            type="number"
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_PROGRESS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderProgressNotes}
            label={LocalizedString.promoCodeBatchPage.placeholderProgressNotes}
            disabled
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_TASK_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderTaskStatus}
            label={LocalizedString.promoCodeBatchPage.placeholderTaskStatus}
            disabled
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_TASK_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderTaskStatusNotes}
            label={LocalizedString.promoCodeBatchPage.placeholderTaskStatusNotes}
            disabled
            multiline
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_START_TIME}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.promoCodeBatchPage.placeholderStartTime}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderStartTime}
            disabled
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_END_TIME}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.promoCodeBatchPage.placeholderEndTime}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderEndTime}
            disabled
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.promoCodeBatchPage.placeholderValidFrom}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderValidFrom}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.promoCodeBatchPage.placeholderValidUntil}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderValidUntil}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_MERCHANT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderMerchant}
            label={LocalizedString.promoCodeBatchPage.placeholderMerchant}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={merchants}
            value={initialValues.merchant}
            loading={loadingMerchant}
            onChangeText={onChangeMerchantText}
            onOptionSelected={onMerchantOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_MERCHANT_TAG}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderMerchantTag}
            label={LocalizedString.promoCodeBatchPage.placeholderMerchantTag}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_MESSAGE_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderMessageTitle}
            label={LocalizedString.promoCodeBatchPage.placeholderMessageTitle}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
          {renderAdditionalPlaceholder(classes)}
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_BATCH_MESSAGE_BODY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodeBatchPage.placeholderMessageBody}
            label={LocalizedString.promoCodeBatchPage.placeholderMessageBody}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
            multiline
          />
          {renderAdditionalPlaceholder(classes)}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PromoCodeBatchPage = ({
  initialValues, tappedPromoCodeBatch, airports, merchants, selectedUserTypes,
  tableDataVouchers, vouchers,
  addingEditing, addingVouchers, cancelling, downloading, downloadingDeleting,
  isSelectedVoucherGarudaLounge, loadingAirport, loadingMerchant, loadingVoucher,
  handleSubmit, onAdvancedFilterPressed, onAddVoucherPressed, onAirportOptionSelected,
  onAppear, onApplyAdvancedFilterPressed, onBypassAchievementValidationOptionSelected,
  onBypassMaxPurchaseOptionSelected, onCancelPressed, onChangeAirportText, onChangeMerchantText,
  onChangePage, onChangePageSize, onChangeVoucherText, onConfirmContextMenuPressed,
  onContextMenuPressed, onCreatePressed, onDeleteVoucherPressed, onMerchantOptionSelected,
  onRefresh, onResetAdvancedFilterPressed, onRewardTypeOptionSelected, onSearchBarTextChanged,
  onSortPressed, onSubmitPressed, onViewPressed, onVoucherOptionSelected,
  selectedAirport, selectedVoucher, onUserTypeSelected,
  currentPromoCodeBatchTaskStatus, garudaCompanyNameConfigValue, pageMode, selectedRewardType,
  tappedId,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_PROMO_CODE_BATCHES}
      uiPage={RXSTATE_PROMO_CODE_BATCH_PAGE}
      moreMenus={[
        {
          caption: currentPromoCodeBatchTaskStatus === TASK_STATUS_PROCESSING
          || currentPromoCodeBatchTaskStatus === TASK_STATUS_QUEUING
            ? LocalizedString.promoCodeBatchPage.buttonCaptionCancelTask : '',
          disabled: cancelling,
          onPress: currentPromoCodeBatchTaskStatus === TASK_STATUS_PROCESSING
          || currentPromoCodeBatchTaskStatus === TASK_STATUS_QUEUING
            ? () => onContextMenuPressed(tappedId,
              LocalizedString.promoCodeBatchPage.msgCancelConfirmation) : () => {},
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.promoCodeBatchPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelPrefix,
          field: RXFIELD_PROMO_CODE_BATCH_PREFIX,
          sorting: !downloading,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelQuantity,
          field: RXFIELD_PROMO_CODE_BATCH_QUANTITY,
          sorting: !downloading,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelCodeLength,
          field: RXFIELD_PROMO_CODE_BATCH_RANDOM_CODE_LENGTH,
          sorting: !downloading,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelRewardType,
          field: RXFIELD_PROMO_CODE_BATCH_REWARD,
          sorting: false,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelStartTime,
          field: RXFIELD_PROMO_CODE_BATCH_START_TIME,
          sorting: !downloading,
          render: ({ startTime }) => (startTime
            ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelProgress,
          field: RXFIELD_PROMO_CODE_BATCH_PROGRESS,
          sorting: !downloading,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelNotes,
          field: RXFIELD_PROMO_CODE_BATCH_PROGRESS_NOTES,
          sorting: !downloading,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelTaskStatus,
          field: RXFIELD_PROMO_CODE_BATCH_TASK_STATUS,
          sorting: !downloading,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelEndTime,
          field: RXFIELD_PROMO_CODE_BATCH_END_TIME,
          sorting: !downloading,
          render: ({ endTime }) => (endTime
            ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: GlobalLocalizedString.common.placeholderCreatedDate,
          field: RXFIELD_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelTaskStatus,
          field: RXFIELD_PROMO_CODE_BATCH_TASK_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: TASK_STATUSES,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelRewardType,
          field: RXFIELD_PROMO_CODE_BATCH_REWARD,
          type: FILTER_TYPE_DROPDOWN,
          data: rewards,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.promoCodeBatchPage.labelMerchant,
          field: RXFIELD_PROMO_CODE_BATCH_MERCHANT,
          type: FILTER_TYPE_DROPDOWN,
          data: merchants,
          loading: loadingMerchant,
          onChangeFilterText: onChangeMerchantText,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      createNewButtonCaption={LocalizedString.promoCodeBatchPage
        .buttonCaptionCreateNewPromoCodeBatch}
      title={LocalizedString.promoCodeBatchPage.title}
      disableEdit
      disableDelete
      useFullWidth
    >
      {renderDialogContent(initialValues, tappedPromoCodeBatch, airports, merchants,
        selectedUserTypes, tableDataVouchers, vouchers, addingEditing, addingVouchers,
        downloadingDeleting, isSelectedVoucherGarudaLounge, loadingAirport, loadingMerchant,
        loadingVoucher, onAddVoucherPressed, onAirportOptionSelected,
        onBypassAchievementValidationOptionSelected, onBypassMaxPurchaseOptionSelected,
        onChangeAirportText, onChangeMerchantText, onChangeVoucherText, onDeleteVoucherPressed,
        onMerchantOptionSelected, onRewardTypeOptionSelected, onUserTypeSelected,
        onVoucherOptionSelected, selectedAirport, selectedVoucher, garudaCompanyNameConfigValue,
        pageMode, selectedRewardType, classes)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_PROMO_CODE_BATCH,
  validate: rxformValidatePromoCodeBatch,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PromoCodeBatchPage);

PromoCodeBatchPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  tappedPromoCodeBatch: FormInitialValueShape,
  airports: PropTypes.arrayOf(SimpleDataShape).isRequired,
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  tableDataVouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedUserTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  addingVouchers: PropTypes.bool.isRequired,
  cancelling: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  isSelectedVoucherGarudaLounge: PropTypes.bool.isRequired,
  loadingAirport: PropTypes.bool.isRequired,
  loadingMerchant: PropTypes.bool.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddVoucherPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAirportOptionSelected: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onBypassAchievementValidationOptionSelected: PropTypes.func.isRequired,
  onBypassMaxPurchaseOptionSelected: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeAirportText: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteVoucherPressed: PropTypes.func.isRequired,
  onMerchantOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onRewardTypeOptionSelected: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onUserTypeSelected: PropTypes.func.isRequired,
  selectedAirport: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  selectedVoucher: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  currentPromoCodeBatchTaskStatus: PropTypes.string.isRequired,
  garudaCompanyNameConfigValue: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedRewardType: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};

PromoCodeBatchPage.defaultProps = {
  tappedPromoCodeBatch: {},
  selectedAirport: null,
  selectedVoucher: null,
};
