import {
  DOWNLOADING_COMPANIES, DOWNLOADING_DIVISIONS, DOWNLOADING_DEPARTMENTS,
  DOWNLOADING_TOTAL_PERSON_COUNT, SET_COMPANY_SEARCH_TEXT, SET_DIVISION_SEARCH_TEXT,
  SET_DEPARTMENT_SEARCH_TEXT, SET_NOTIFICATION_DIALOG_VISIBILITY, SET_NOTIFICATION_SELECTED_BRANCH,
  SET_NOTIFICATION_SELECTED_COMPANY, SET_TOTAL_PERSON_COUNT, SENDING_NOTIFICATIONS,
  ADD_NOTIFICATION_DIALOG_FILTER_FORM, CLEAR_NOTIFICATION_DIALOG_FILTER_FORM,
  SET_NOTIFICATION_SELECTED_CITY, SET_NOTIFICATION_SELECTED_SHELL_MEMBERSHIP,
  SET_NOTIFICATION_SELECTED_USER_TIER,
} from '../action';

const initialState = {
  downloadingCompanies: false,
  downloadingDivisions: false,
  downloadingDepartments: false,
  downloadingTotalPersonCount: false,
  sendingNotifications: false,
  visibility: false,
  selectedBranch: '',
  selectedCompany: '',
  selectedCity: '',
  selectedUserTier: '',
  selectedShellMembership: '',
  companiesSearchText: '',
  departmentsSearchText: '',
  divisionsSearchText: '',
  totalPersonCount: 0,
  filterForm: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_COMPANIES:
      return { ...state, downloadingCompanies: action.status };
    case DOWNLOADING_DIVISIONS:
      return { ...state, downloadingDivisions: action.status };
    case DOWNLOADING_DEPARTMENTS:
      return { ...state, downloadingDepartments: action.status };
    case DOWNLOADING_TOTAL_PERSON_COUNT:
      return { ...state, downloadingTotalPersonCount: action.status };
    case SENDING_NOTIFICATIONS:
      return { ...state, sendingNotifications: action.status };
    case SET_COMPANY_SEARCH_TEXT:
      return { ...state, companiesSearchText: action.text };
    case SET_DEPARTMENT_SEARCH_TEXT:
      return { ...state, departmentsSearchText: action.text };
    case SET_DIVISION_SEARCH_TEXT:
      return { ...state, divisionsSearchText: action.text };
    case SET_NOTIFICATION_DIALOG_VISIBILITY:
      return { ...state, visibility: action.status };
    case SET_NOTIFICATION_SELECTED_BRANCH:
      return { ...state, selectedBranch: action.option };
    case SET_NOTIFICATION_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.option };
    case SET_NOTIFICATION_SELECTED_CITY:
      return { ...state, selectedCity: action.option };
    case SET_NOTIFICATION_SELECTED_SHELL_MEMBERSHIP:
      return { ...state, selectedShellMembership: action.option };
    case SET_NOTIFICATION_SELECTED_USER_TIER:
      return { ...state, selectedUserTier: action.option };
    case SET_TOTAL_PERSON_COUNT:
      return { ...state, totalPersonCount: action.data };
    case ADD_NOTIFICATION_DIALOG_FILTER_FORM:
      return {
        ...state,
        filterForm: { ...state.filterForm, [action.field]: action.value },
      };
    case CLEAR_NOTIFICATION_DIALOG_FILTER_FORM:
      return { ...state, filterForm: {} };
    default: return state;
  }
};
