import { downloadingTestDriveUniqueCode, setTestDriveUniqueCodes } from '../simple-action';
import { downloadTestDriveUniqueCodes, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, uniqueCodes, uiUniqueCode } = getState();
  const { token } = authentication;
  const { meta } = uniqueCodes;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiUniqueCode;

  try {
    dispatch(downloadingTestDriveUniqueCode(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadTestDriveUniqueCodes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setTestDriveUniqueCodes(list));
    }
  } finally {
    dispatch(downloadingTestDriveUniqueCode(false));
  }
};
