import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_PARTNER_VOUCHER_TOKENS = '/voucher/api/v1/PartnerAdmin/VoucherToken?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_PARTNER_VOUCHER_TOKEN = '/voucher/api/v1/PartnerAdmin/VoucherToken/{id}';
export const REST_URL_PARTNER_VOUCHER_TOKEN_DOWNLOAD = '/voucher/api/v1/PartnerAdmin/VoucherToken/Download';
export const REST_URL_PARTNER_VOUCHER_TOKEN_BY_CODE = '/voucher/api/v1/PartnerAdmin/VoucherTokenByCode/{code}';
export const REST_URL_PARTNER_CLAIM_VOUCHER_TOKEN_BY_CODE = '/voucher/api/v1/PartnerAdmin/Claim';

export const ROUTE_NAME_PARTNER_ADMIN = '/partner-admin';
export const ROUTE_NAME_PARTNER_ADMIN_VOUCHER_TOKEN = '/partner-voucher-token';
export const ROUTE_NAME_PARTNER_ADMIN_VOUCHER_CLAIM = '/voucher-claim';

export const RXFORM_VOUCHER_TOKEN = 'voucnerToken';
export const RXFORM_VOUCHER_CLAIM = 'voucnerClaim';

export const RXFIELD_PARTNER_ADMIN_CODE = 'code';
export const RXFIELD_PARTNER_ADMIN_VALID_FROM = 'validFrom';
export const RXFIELD_PARTNER_ADMIN_VALID_UNTIL = 'validUntil';
export const RXFIELD_PARTNER_ADMIN_IS_PURCHASED = 'isPurchased';
export const RXFIELD_PARTNER_ADMIN_PURCHASED_ON = 'purchasedOn';
export const RXFIELD_PARTNER_ADMIN_PURCHASED_BY = 'purchasedBy';
export const RXFIELD_PARTNER_ADMIN_PURCHASED_BY_ID = 'purchasedById';
export const RXFIELD_PARTNER_ADMIN_IS_REVOKED = 'isRevoked';
export const RXFIELD_PARTNER_ADMIN_REVOKED_ON = 'revokedOn';
export const RXFIELD_PARTNER_ADMIN_REVOKED_BY = 'revokedBy';
export const RXFIELD_PARTNER_ADMIN_REVOKED_BY_ID = 'revokedById';
export const RXFIELD_PARTNER_ADMIN_REVOCATION_NOTES = 'revocationNotes';
export const RXFIELD_PARTNER_ADMIN_VOUCHER = 'selectedVoucher';
export const RXFIELD_PARTNER_ADMIN_MERCHANT = 'merchant';
export const RXFIELD_PARTNER_ADMIN_IS_CLAIMED = 'isClaimed';
export const RXFIELD_PARTNER_ADMIN_CLAIMED_ON = 'claimedOn';
export const RXFIELD_PARTNER_ADMIN_CLAIMED_BY = 'claimedBy';
export const RXFIELD_PARTNER_ADMIN_CLAIMED_BY_ID = 'claimedById';
export const RXFIELD_PARTNER_ADMIN_CLAIM_NOTES = 'claimNotes';
export const RXFIELD_PARTNER_ADMIN_CREATED_DATE = 'createdDate';
export const RXFIELD_PARTNER_ADMIN_VOUCHER_ID = 'voucherId';
export const RXFIELD_PARTNER_ADMIN_VOUCHER_IMAGE = 'voucherImage';
export const RXFIELD_PARTNER_ADMIN_VOUCHER_NAME = 'voucherName';
export const RXFIELD_PARTNER_ADMIN_MERCHANT_NAME = 'merchantName';
export const RXFIELD_VOUCHER_CLAIM_VOUCHER_CODE = 'voucherCode';
export const RXFIELD_VOUCHER_CLAIM_CLAIM_NOTES = 'notes';

export const RXSTATE_PARTNER_ADMIN_VOUCHER_TOKENS = 'partnerVoucherTokens';
export const RXSTATE_PARTNER_ADMIN_VOUCHER_TOKEN_PAGE = 'uiPartnerVoucherToken';

export const INITIAL_ORDER_BY_PARTNER_ADMIN_VOUCHER_TOKEN = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_PARTNER_ADMIN_MAIN = 'MENUID_PARTNER_ADMIN_MAIN';
export const MENUID_PARTNER_ADMIN_VOUCHER_TOKEN = 'MENUID_PARTNER_ADMIN_VOUCHER_TOKEN';
export const MENUID_PARTNER_ADMIN_VOUCHER_CLAIM = 'MENUID_PARTNER_ADMIN_VOUCHER_CLAIM';
