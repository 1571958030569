import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_COUPONS = 'DOWNLOADING_COUPONS';
export const downloadingCoupons = makeActionCreator(DOWNLOADING_COUPONS, 'status');

export const SET_COUPONS = 'SET_COUPONS';
export const setCoupons = makeActionCreator(SET_COUPONS, 'data');

export const ADD_COUPON = 'ADD_COUPON';
export const addCoupon = makeActionCreator(ADD_COUPON, 'data');

export const CLEAR_COUPONS = 'CLEAR_COUPONS';
export const clearCoupons = makeActionCreator(CLEAR_COUPONS);

export const DOWNLOADING_DELETING_COUPON = 'DOWNLOADING_DELETING_COUPON';
export const downloadingDeletingCoupon = makeActionCreator(DOWNLOADING_DELETING_COUPON, 'status');

export const SET_COUPON_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_COUPON_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCouponAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_COUPON_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const SET_COUPON_SELECTED_PAGE_SIZE = 'SET_COUPON_SELECTED_PAGE_SIZE';
export const setCouponSelectedPageSize = makeActionCreator(SET_COUPON_SELECTED_PAGE_SIZE, 'size');

export const SET_COUPON_SELECTED_ORDER_BY = 'SET_COUPON_SELECTED_ORDER_BY';
export const setCouponSelectedOrderBy = makeActionCreator(SET_COUPON_SELECTED_ORDER_BY, 'order');

export const SET_COUPON_TAPPED_ID = 'SET_COUPON_TAPPED_ID';
export const setCouponTappedId = makeActionCreator(SET_COUPON_TAPPED_ID, 'id');

export const SET_COUPON_SEARCH_TEXT = 'SET_COUPON_SEARCH_TEXT';
export const setCouponSearchText = makeActionCreator(SET_COUPON_SEARCH_TEXT, 'text');


export const DOWNLOADING_COUPON_SUBMISSIONS = 'DOWNLOADING_COUPON_SUBMISSIONS';
export const downloadingCouponSubmissions = makeActionCreator(DOWNLOADING_COUPON_SUBMISSIONS, 'status');

export const SET_COUPON_SUBMISSIONS = 'SET_COUPON_SUBMISSIONS';
export const setCouponSubmissions = makeActionCreator(SET_COUPON_SUBMISSIONS, 'data');

export const ADD_COUPON_SUBMISSION = 'ADD_COUPON_SUBMISSION';
export const addCouponSubmission = makeActionCreator(ADD_COUPON_SUBMISSION, 'data');

export const CLEAR_COUPON_SUBMISSIONS = 'CLEAR_COUPON_SUBMISSIONS';
export const clearCouponSubmissions = makeActionCreator(CLEAR_COUPON_SUBMISSIONS);

export const DOWNLOADING_DELETING_COUPON_SUBMISSION = 'DOWNLOADING_DELETING_COUPON_SUBMISSION';
export const downloadingDeletingCouponSubmission = makeActionCreator(DOWNLOADING_DELETING_COUPON_SUBMISSION, 'status');

export const REPROCESSING_COUPON_SUBMISSION = 'REPROCESSING_COUPON_SUBMISSION';
export const reprocessingCouponSubmission = makeActionCreator(REPROCESSING_COUPON_SUBMISSION, 'status');

export const ADDING_COUPON_SUBMISSION = 'ADDING_COUPON_SUBMISSION';
export const addingCouponSubmission = makeActionCreator(ADDING_COUPON_SUBMISSION, 'status');

export const SET_COUPON_SUBMISSION_SELECTED_USER = 'SET_COUPON_SUBMISSION_SELECTED_USER';
export const setCouponSubmissionSelectedUser = makeActionCreator(SET_COUPON_SUBMISSION_SELECTED_USER, 'value');

export const SET_COUPON_SUBMISSION_SELECTED_SEND_NOTIFICATION = 'SET_COUPON_SUBMISSION_SELECTED_SEND_NOTIFICATION';
export const setCouponSubmissionSelectedSendNotification = makeActionCreator(SET_COUPON_SUBMISSION_SELECTED_SEND_NOTIFICATION, 'value');


export const SET_COUPON_SUBMISSION_SELECTED_PAGE_SIZE = 'SET_COUPON_SUBMISSION_SELECTED_PAGE_SIZE';
export const setCouponSubmissionSelectedPageSize = makeActionCreator(SET_COUPON_SUBMISSION_SELECTED_PAGE_SIZE, 'size');

export const SET_COUPON_SUBMISSION_SELECTED_ORDER_BY = 'SET_COUPON_SUBMISSION_SELECTED_ORDER_BY';
export const setCouponSubmissionSelectedOrderBy = makeActionCreator(SET_COUPON_SUBMISSION_SELECTED_ORDER_BY, 'order');

export const SET_COUPON_SUBMISSION_TAPPED_ID = 'SET_COUPON_SUBMISSION_TAPPED_ID';
export const setCouponSubmissionTappedId = makeActionCreator(SET_COUPON_SUBMISSION_TAPPED_ID, 'id');

export const SET_COUPON_SUBMISSION_SEARCH_TEXT = 'SET_COUPON_SUBMISSION_SEARCH_TEXT';
export const setCouponSubmissionSearchText = makeActionCreator(SET_COUPON_SUBMISSION_SEARCH_TEXT, 'text');


export const DOWNLOADING_COUPON_TRANSACTIONS = 'DOWNLOADING_COUPON_TRANSACTIONS';
export const downloadingCouponTransactions = makeActionCreator(DOWNLOADING_COUPON_TRANSACTIONS, 'status');

export const SET_COUPON_TRANSACTIONS = 'SET_COUPON_TRANSACTIONS';
export const setCouponTransactions = makeActionCreator(SET_COUPON_TRANSACTIONS, 'data');

export const ADD_COUPON_TRANSACTION = 'ADD_COUPON_TRANSACTION';
export const addCouponTransaction = makeActionCreator(ADD_COUPON_TRANSACTION, 'data');

export const CLEAR_COUPON_TRANSACTIONS = 'CLEAR_COUPON_TRANSACTIONS';
export const clearCouponTransactions = makeActionCreator(CLEAR_COUPON_TRANSACTIONS);

export const DOWNLOADING_DELETING_COUPON_TRANSACTION = 'DOWNLOADING_DELETING_COUPON_TRANSACTION';
export const downloadingDeletingCouponTransaction = makeActionCreator(DOWNLOADING_DELETING_COUPON_TRANSACTION, 'status');


export const SET_COUPON_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_COUPON_TRANSACTION_SELECTED_PAGE_SIZE';
export const setCouponTransactionSelectedPageSize = makeActionCreator(SET_COUPON_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_COUPON_TRANSACTION_SELECTED_ORDER_BY = 'SET_COUPON_TRANSACTION_SELECTED_ORDER_BY';
export const setCouponTransactionSelectedOrderBy = makeActionCreator(SET_COUPON_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_COUPON_TRANSACTION_TAPPED_ID = 'SET_COUPON_TRANSACTION_TAPPED_ID';
export const setCouponTransactionTappedId = makeActionCreator(SET_COUPON_TRANSACTION_TAPPED_ID, 'id');

export const SET_COUPON_TRANSACTION_SEARCH_TEXT = 'SET_COUPON_TRANSACTION_SEARCH_TEXT';
export const setCouponTransactionSearchText = makeActionCreator(SET_COUPON_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_COUPON_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_COUPON_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCouponTransactionAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_COUPON_TRANSACTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_COUPON_ACHIEVEMENT = 'DOWNLOADING_COUPON_ACHIEVEMENT';
export const downloadingCouponAchievement = makeActionCreator(DOWNLOADING_COUPON_ACHIEVEMENT, 'status');

export const SET_COUPON_ACHIEVEMENT = 'SET_COUPON_ACHIEVEMENT';
export const setCouponAchievement = makeActionCreator(SET_COUPON_ACHIEVEMENT, 'data');

export const CLEAR_COUPON_ACHIEVEMENT = 'CLEAR_COUPON_ACHIEVEMENT';
export const clearCouponAchievement = makeActionCreator(CLEAR_COUPON_ACHIEVEMENT);


export const DOWNLOADING_COUPON_BALANCE = 'DOWNLOADING_COUPON_BALANCE';
export const downloadingCouponBalance = makeActionCreator(DOWNLOADING_COUPON_BALANCE, 'status');

export const SET_COUPON_BALANCE = 'SET_COUPON_BALANCE';
export const setCouponBalance = makeActionCreator(SET_COUPON_BALANCE, 'data');

export const CLEAR_COUPON_BALANCE = 'CLEAR_COUPON_BALANCE';
export const clearCouponBalance = makeActionCreator(CLEAR_COUPON_BALANCE);


export const SET_COUPON_ACHIEVEMENT_TAPPED_ID = 'SET_COUPON_ACHIEVEMENT_TAPPED_ID';
export const setCouponAchievementTappedId = makeActionCreator(SET_COUPON_ACHIEVEMENT_TAPPED_ID, 'id');


export const DOWNLOADING_COUPON_LEADER_BOARD = 'DOWNLOADING_COUPON_LEADER_BOARD';
export const downloadingCouponLeaderBoard = makeActionCreator(DOWNLOADING_COUPON_LEADER_BOARD, 'status');

export const SET_COUPON_LEADER_BOARD = 'SET_COUPON_LEADER_BOARD';
export const setCouponLeaderBoard = makeActionCreator(SET_COUPON_LEADER_BOARD, 'data');

export const CLEAR_COUPON_LEADER_BOARD = 'CLEAR_COUPON_LEADER_BOARD';
export const clearCouponLeaderBoard = makeActionCreator(CLEAR_COUPON_LEADER_BOARD);


export const DOWNLOADING_COUPON_PURCHASE_REQUESTS = 'DOWNLOADING_COUPON_PURCHASE_REQUESTS';
export const downloadingCouponPurchaseRequests = makeActionCreator(DOWNLOADING_COUPON_PURCHASE_REQUESTS, 'status');

export const SET_COUPON_PURCHASE_REQUESTS = 'SET_COUPON_PURCHASE_REQUESTS';
export const setCouponPurchaseRequests = makeActionCreator(SET_COUPON_PURCHASE_REQUESTS, 'data');

export const ADD_COUPON_PURCHASE_REQUEST = 'ADD_COUPON_PURCHASE_REQUEST';
export const addCouponPurchaseRequest = makeActionCreator(ADD_COUPON_PURCHASE_REQUEST, 'data');

export const CLEAR_COUPON_PURCHASE_REQUESTS = 'CLEAR_COUPON_PURCHASE_REQUESTS';
export const clearCouponPurchaseRequests = makeActionCreator(CLEAR_COUPON_PURCHASE_REQUESTS);

export const DOWNLOADING_COUPON_PURCHASE_REQUEST = 'DOWNLOADING_COUPON_PURCHASE_REQUEST';
export const downloadingCouponPurchaseRequest = makeActionCreator(DOWNLOADING_COUPON_PURCHASE_REQUEST, 'status');


export const SET_COUPON_PURCHASE_REQUEST_SELECTED_PAGE_SIZE = 'SET_COUPON_PURCHASE_REQUEST_SELECTED_PAGE_SIZE';
export const setCouponPurchaseRequestSelectedPageSize = makeActionCreator(SET_COUPON_PURCHASE_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_COUPON_PURCHASE_REQUEST_SELECTED_ORDER_BY = 'SET_COUPON_PURCHASE_REQUEST_SELECTED_ORDER_BY';
export const setCouponPurchaseRequestSelectedOrderBy = makeActionCreator(SET_COUPON_PURCHASE_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_COUPON_PURCHASE_REQUEST_TAPPED_ID = 'SET_COUPON_PURCHASE_REQUEST_TAPPED_ID';
export const setCouponPurchaseRequestTappedId = makeActionCreator(SET_COUPON_PURCHASE_REQUEST_TAPPED_ID, 'id');

export const SET_COUPON_PURCHASE_REQUEST_SEARCH_TEXT = 'SET_COUPON_PURCHASE_REQUEST_SEARCH_TEXT';
export const setCouponPurchaseRequestSearchText = makeActionCreator(SET_COUPON_PURCHASE_REQUEST_SEARCH_TEXT, 'text');


export const DOWNLOADING_VOID_COUPON_REQUESTS = 'DOWNLOADING_VOID_COUPON_REQUESTS';
export const downloadingVoidCouponRequests = makeActionCreator(DOWNLOADING_VOID_COUPON_REQUESTS, 'status');

export const SET_VOID_COUPON_REQUESTS = 'SET_VOID_COUPON_REQUESTS';
export const setVoidCouponRequests = makeActionCreator(SET_VOID_COUPON_REQUESTS, 'data');

export const ADD_VOID_COUPON_REQUEST = 'ADD_VOID_COUPON_REQUEST';
export const addVoidCouponRequest = makeActionCreator(ADD_VOID_COUPON_REQUEST, 'data');

export const CLEAR_VOID_COUPON_REQUESTS = 'CLEAR_VOID_COUPON_REQUESTS';
export const clearVoidCouponRequests = makeActionCreator(CLEAR_VOID_COUPON_REQUESTS);

export const DOWNLOADING_VOID_COUPON_REQUEST = 'DOWNLOADING_VOID_COUPON_REQUEST';
export const downloadingVoidCouponRequest = makeActionCreator(DOWNLOADING_VOID_COUPON_REQUEST, 'status');

export const ADDING_VOID_COUPON_REQUEST = 'ADDING_VOID_COUPON_REQUEST';
export const addingVoidCouponRequest = makeActionCreator(ADDING_VOID_COUPON_REQUEST, 'status');


export const SET_VOID_COUPON_REQUEST_SELECTED_PAGE_SIZE = 'SET_VOID_COUPON_REQUEST_SELECTED_PAGE_SIZE';
export const setVoidCouponRequestSelectedPageSize = makeActionCreator(SET_VOID_COUPON_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_VOID_COUPON_REQUEST_SELECTED_ORDER_BY = 'SET_VOID_COUPON_REQUEST_SELECTED_ORDER_BY';
export const setVoidCouponRequestSelectedOrderBy = makeActionCreator(SET_VOID_COUPON_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_VOID_COUPON_REQUEST_TAPPED_ID = 'SET_VOID_COUPON_REQUEST_TAPPED_ID';
export const setVoidCouponRequestTappedId = makeActionCreator(SET_VOID_COUPON_REQUEST_TAPPED_ID, 'id');

export const SET_VOID_COUPON_REQUEST_SEARCH_TEXT = 'SET_VOID_COUPON_REQUEST_SEARCH_TEXT';
export const setVoidCouponRequestSearchText = makeActionCreator(SET_VOID_COUPON_REQUEST_SEARCH_TEXT, 'text');

export const SET_VOID_COUPON_REQUEST_SELECTED_COUPON = 'SET_VOID_COUPON_REQUEST_SELECTED_COUPON';
export const setVoidCouponRequestSelectedCoupon = makeActionCreator(SET_VOID_COUPON_REQUEST_SELECTED_COUPON, 'option');

export const SET_VOID_COUPON_DIALOG = 'SET_VOID_COUPON_DIALOG';
export const showVoidCouponDialog = makeActionCreator(SET_VOID_COUPON_DIALOG, 'value');

export const SET_VOIDING_COUPON = 'SET_VOIDING_COUPON';
export const setVoidingCoupon = makeActionCreator(SET_VOIDING_COUPON, 'status');

export const SET_VOID_COUPON_DIALOG_SELECTED_SEND_NOTIFICATION = 'SET_VOID_COUPON_DIALOG_SELECTED_SEND_NOTIFICATION';
export const setVoidCouponDialogSelectedSendNotification = makeActionCreator(SET_VOID_COUPON_DIALOG_SELECTED_SEND_NOTIFICATION, 'value');
