import { downloadIims2023Spk } from '../../../helper';
import { downloadingIims2023Spk, setIims2023Spk, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiIims2023Spk } = getState();

  const { token } = authentication;
  const { tappedId } = uiIims2023Spk;

  try {
    dispatch(downloadingIims2023Spk(true));

    const scannedCode = await downloadIims2023Spk(
      tappedId,
      token,
    );

    dispatch(setIims2023Spk(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingIims2023Spk(false));
  }
};
