import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_GAME_ACTIVITY_REGISTRATION_REFERRALS = 'DOWNLOADING_GAME_ACTIVITY_REGISTRATION_REFERRALS';
export const downloadingGameActivityRegistrationReferrals = makeActionCreator(DOWNLOADING_GAME_ACTIVITY_REGISTRATION_REFERRALS, 'status');

export const SAVING_GAME_ACTIVITY_REGISTRATION_REFERRALS = 'SAVING_GAME_ACTIVITY_REGISTRATION_REFERRALS';
export const savingGameActivityRegistrationReferrals = makeActionCreator(SAVING_GAME_ACTIVITY_REGISTRATION_REFERRALS, 'status');

export const SET_GAME_ACTIVITY_REGISTRATION_REFERRALS = 'SET_GAME_ACTIVITY_REGISTRATION_REFERRALS';
export const setGameActivityRegistrationReferrals = makeActionCreator(SET_GAME_ACTIVITY_REGISTRATION_REFERRALS, 'data');

export const ADD_GAME_ACTIVITY_REGISTRATION_REFERRAL = 'ADD_GAME_ACTIVITY_REGISTRATION_REFERRAL';
export const addGameActivityRegistrationReferral = makeActionCreator(ADD_GAME_ACTIVITY_REGISTRATION_REFERRAL, 'data');

export const CLEAR_GAME_ACTIVITY_REGISTRATION_REFERRALS = 'CLEAR_GAME_ACTIVITY_REGISTRATION_REFERRALS';
export const clearGameActivityRegistrationReferrals = makeActionCreator(
  CLEAR_GAME_ACTIVITY_REGISTRATION_REFERRALS,
);

export const DOWNLOADING_DELETING_GAME_ACTIVITY_REGISTRATION_REFERRAL = 'DOWNLOADING_DELETING_GAME_ACTIVITY_REGISTRATION_REFERRAL';
export const downloadingDeletingGameActivityRegistrationReferral = makeActionCreator(DOWNLOADING_DELETING_GAME_ACTIVITY_REGISTRATION_REFERRAL, 'status');

export const SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE = 'SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE';
export const setGameActivityRegistrationReferralSelectedPageSize = makeActionCreator(SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE, 'size');

export const SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_ORDER_BY = 'SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_ORDER_BY';
export const setGameActivityRegistrationReferralSelectedOrderBy = makeActionCreator(SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_ORDER_BY, 'order');

export const SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_TAPPED_ID = 'SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_TAPPED_ID';
export const setGameActivityRegistrationReferralTappedId = makeActionCreator(SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_TAPPED_ID, 'id');

export const SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SEARCH_TEXT = 'SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SEARCH_TEXT';
export const setGameActivityRegistrationReferralSearchText = makeActionCreator(SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SEARCH_TEXT, 'text');

export const SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setGameActivityRegistrationReferralAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const DOWNLOADING_RECURRANCE_REFERRAL_REWARDS = 'DOWNLOADING_RECURRANCE_REFERRAL_REWARDS';
export const downloadingRecurrenceReferralRewards = makeActionCreator(DOWNLOADING_RECURRANCE_REFERRAL_REWARDS, 'status');

export const SET_RECURRANCE_REFERRAL_REWARDS = 'SET_RECURRANCE_REFERRAL_REWARDS';
export const setRecurrenceReferralRewards = makeActionCreator(SET_RECURRANCE_REFERRAL_REWARDS, 'data');

export const ADD_RECURRANCE_REFERRAL_REWARD = 'ADD_RECURRANCE_REFERRAL_REWARD';
export const addRecurrenceReferralReward = makeActionCreator(ADD_RECURRANCE_REFERRAL_REWARD, 'data');

export const CLEAR_RECURRANCE_REFERRAL_REWARDS = 'CLEAR_RECURRANCE_REFERRAL_REWARDS';
export const clearRecurrenceReferralRewards = makeActionCreator(
  CLEAR_RECURRANCE_REFERRAL_REWARDS,
);

export const DOWNLOADING_DELETING_RECURRANCE_REFERRAL_REWARD = 'DOWNLOADING_DELETING_RECURRANCE_REFERRAL_REWARD';
export const downloadingDeletingRecurrenceReferralReward = makeActionCreator(DOWNLOADING_DELETING_RECURRANCE_REFERRAL_REWARD, 'status');

export const SET_RECURRANCE_REFERRAL_REWARD_SELECTED_PAGE_SIZE = 'SET_RECURRANCE_REFERRAL_REWARD_SELECTED_PAGE_SIZE';
export const setRecurrenceReferralRewardSelectedPageSize = makeActionCreator(SET_RECURRANCE_REFERRAL_REWARD_SELECTED_PAGE_SIZE, 'size');

export const SET_RECURRANCE_REFERRAL_REWARD_SELECTED_ORDER_BY = 'SET_RECURRANCE_REFERRAL_REWARD_SELECTED_ORDER_BY';
export const setRecurrenceReferralRewardSelectedOrderBy = makeActionCreator(SET_RECURRANCE_REFERRAL_REWARD_SELECTED_ORDER_BY, 'order');

export const SET_RECURRANCE_REFERRAL_REWARD_TAPPED_ID = 'SET_RECURRANCE_REFERRAL_REWARD_TAPPED_ID';
export const setRecurrenceReferralRewardTappedId = makeActionCreator(SET_RECURRANCE_REFERRAL_REWARD_TAPPED_ID, 'id');

export const SET_RECURRANCE_REFERRAL_REWARD_SEARCH_TEXT = 'SET_RECURRANCE_REFERRAL_REWARD_SEARCH_TEXT';
export const setRecurrenceReferralRewardSearchText = makeActionCreator(SET_RECURRANCE_REFERRAL_REWARD_SEARCH_TEXT, 'text');

export const SET_RECURRANCE_REFERRAL_REWARD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_RECURRANCE_REFERRAL_REWARD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setRecurrenceReferralRewardAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_RECURRANCE_REFERRAL_REWARD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_DAILY_CHECKINS = 'DOWNLOADING_DAILY_CHECKINS';
export const downloadingDailyCheckins = makeActionCreator(DOWNLOADING_DAILY_CHECKINS, 'status');

export const SET_DAILY_CHECKINS = 'SET_DAILY_CHECKINS';
export const setDailyCheckins = makeActionCreator(SET_DAILY_CHECKINS, 'data');

export const ADD_DAILY_CHECKIN = 'ADD_DAILY_CHECKIN';
export const addDailyCheckin = makeActionCreator(ADD_DAILY_CHECKIN, 'data');

export const CLEAR_DAILY_CHECKINS = 'CLEAR_DAILY_CHECKINS';
export const clearDailyCheckins = makeActionCreator(
  CLEAR_DAILY_CHECKINS,
);

export const DOWNLOADING_DELETING_DAILY_CHECKIN = 'DOWNLOADING_DELETING_DAILY_CHECKIN';
export const downloadingDeletingDailyCheckin = makeActionCreator(DOWNLOADING_DELETING_DAILY_CHECKIN, 'status');

export const SET_DAILY_CHECKIN_SELECTED_PAGE_SIZE = 'SET_DAILY_CHECKIN_SELECTED_PAGE_SIZE';
export const setDailyCheckinSelectedPageSize = makeActionCreator(SET_DAILY_CHECKIN_SELECTED_PAGE_SIZE, 'size');

export const SET_DAILY_CHECKIN_SELECTED_ORDER_BY = 'SET_DAILY_CHECKIN_SELECTED_ORDER_BY';
export const setDailyCheckinSelectedOrderBy = makeActionCreator(SET_DAILY_CHECKIN_SELECTED_ORDER_BY, 'order');

export const SET_DAILY_CHECKIN_TAPPED_ID = 'SET_DAILY_CHECKIN_TAPPED_ID';
export const setDailyCheckinTappedId = makeActionCreator(SET_DAILY_CHECKIN_TAPPED_ID, 'id');

export const SET_DAILY_CHECKIN_SEARCH_TEXT = 'SET_DAILY_CHECKIN_SEARCH_TEXT';
export const setDailyCheckinSearchText = makeActionCreator(SET_DAILY_CHECKIN_SEARCH_TEXT, 'text');

export const SET_DAILY_CHECKIN_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_DAILY_CHECKIN_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setDailyCheckinAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_DAILY_CHECKIN_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_COMPLETED_PROFILES = 'DOWNLOADING_COMPLETED_PROFILES';
export const downloadingCompletedProfiles = makeActionCreator(DOWNLOADING_COMPLETED_PROFILES, 'status');

export const SET_COMPLETED_PROFILES = 'SET_COMPLETED_PROFILES';
export const setCompletedProfiles = makeActionCreator(SET_COMPLETED_PROFILES, 'data');

export const ADD_COMPLETED_PROFILE = 'ADD_COMPLETED_PROFILE';
export const addCompletedProfile = makeActionCreator(ADD_COMPLETED_PROFILE, 'data');

export const CLEAR_COMPLETED_PROFILES = 'CLEAR_COMPLETED_PROFILES';
export const clearCompletedProfiles = makeActionCreator(
  CLEAR_COMPLETED_PROFILES,
);

export const DOWNLOADING_DELETING_COMPLETED_PROFILE = 'DOWNLOADING_DELETING_COMPLETED_PROFILE';
export const downloadingDeletingCompletedProfile = makeActionCreator(DOWNLOADING_DELETING_COMPLETED_PROFILE, 'status');

export const SET_COMPLETED_PROFILE_SELECTED_PAGE_SIZE = 'SET_COMPLETED_PROFILE_SELECTED_PAGE_SIZE';
export const setCompletedProfileSelectedPageSize = makeActionCreator(SET_COMPLETED_PROFILE_SELECTED_PAGE_SIZE, 'size');

export const SET_COMPLETED_PROFILE_SELECTED_ORDER_BY = 'SET_COMPLETED_PROFILE_SELECTED_ORDER_BY';
export const setCompletedProfileSelectedOrderBy = makeActionCreator(SET_COMPLETED_PROFILE_SELECTED_ORDER_BY, 'order');

export const SET_COMPLETED_PROFILE_TAPPED_ID = 'SET_COMPLETED_PROFILE_TAPPED_ID';
export const setCompletedProfileTappedId = makeActionCreator(SET_COMPLETED_PROFILE_TAPPED_ID, 'id');

export const SET_COMPLETED_PROFILE_SEARCH_TEXT = 'SET_COMPLETED_PROFILE_SEARCH_TEXT';
export const setCompletedProfileSearchText = makeActionCreator(SET_COMPLETED_PROFILE_SEARCH_TEXT, 'text');

export const SET_COMPLETED_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_COMPLETED_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCompletedProfileAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_COMPLETED_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_NEW_USER_NOTIFICATIONS = 'DOWNLOADING_NEW_USER_NOTIFICATIONS';
export const downloadingNewUserNotifications = makeActionCreator(DOWNLOADING_NEW_USER_NOTIFICATIONS, 'status');

export const SET_NEW_USER_NOTIFICATIONS = 'SET_NEW_USER_NOTIFICATIONS';
export const setNewUserNotifications = makeActionCreator(SET_NEW_USER_NOTIFICATIONS, 'data');

export const ADD_NEW_USER_NOTIFICATION = 'ADD_NEW_USER_NOTIFICATION';
export const addNewUserNotification = makeActionCreator(ADD_NEW_USER_NOTIFICATION, 'data');

export const CLEAR_NEW_USER_NOTIFICATIONS = 'CLEAR_NEW_USER_NOTIFICATIONS';
export const clearNewUserNotifications = makeActionCreator(
  CLEAR_NEW_USER_NOTIFICATIONS,
);

export const DOWNLOADING_DELETING_NEW_USER_NOTIFICATION = 'DOWNLOADING_DELETING_NEW_USER_NOTIFICATION';
export const downloadingDeletingNewUserNotification = makeActionCreator(DOWNLOADING_DELETING_NEW_USER_NOTIFICATION, 'status');

export const SET_NEW_USER_NOTIFICATION_SELECTED_PAGE_SIZE = 'SET_NEW_USER_NOTIFICATION_SELECTED_PAGE_SIZE';
export const setNewUserNotificationSelectedPageSize = makeActionCreator(SET_NEW_USER_NOTIFICATION_SELECTED_PAGE_SIZE, 'size');

export const SET_NEW_USER_NOTIFICATION_SELECTED_ORDER_BY = 'SET_NEW_USER_NOTIFICATION_SELECTED_ORDER_BY';
export const setNewUserNotificationSelectedOrderBy = makeActionCreator(SET_NEW_USER_NOTIFICATION_SELECTED_ORDER_BY, 'order');

export const SET_NEW_USER_NOTIFICATION_TAPPED_ID = 'SET_NEW_USER_NOTIFICATION_TAPPED_ID';
export const setNewUserNotificationTappedId = makeActionCreator(SET_NEW_USER_NOTIFICATION_TAPPED_ID, 'id');

export const SET_NEW_USER_NOTIFICATION_SEARCH_TEXT = 'SET_NEW_USER_NOTIFICATION_SEARCH_TEXT';
export const setNewUserNotificationSearchText = makeActionCreator(SET_NEW_USER_NOTIFICATION_SEARCH_TEXT, 'text');

export const SET_NEW_USER_NOTIFICATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_NEW_USER_NOTIFICATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setNewUserNotificationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_NEW_USER_NOTIFICATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
