import { downloadIims2023ScannedCode } from '../../../helper';
import { downloadingIims2023ScannedCode, setIims2023ScannedCode, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiIims2023ScannedCode } = getState();

  const { token } = authentication;
  const { tappedId } = uiIims2023ScannedCode;

  try {
    dispatch(downloadingIims2023ScannedCode(true));

    const scannedCode = await downloadIims2023ScannedCode(
      tappedId,
      token,
    );

    dispatch(setIims2023ScannedCode(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingIims2023ScannedCode(false));
  }
};
