import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, List, ListItem,
  Typography, Divider, CircularProgress,
  Button,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  CloudDownload,
} from '@material-ui/icons';
import {
  FunctionalPage, SectionTitle, AccentButton,
} from '../../component';
import {
  RXFIELD_INVOICE_INVOICE_NO, RXFIELD_INVOICE_ORDER_ID,
  RXFIELD_INVOICE_USER_FULL_NAME, RXFIELD_INVOICE_TRANSACTION_ID,
  RXFIELD_INVOICE_TRANSACTION_STATUS, RXFIELD_INVOICE_TAX,
  RXFIELD_INVOICE_TOTAL_WITH_TAX,
  RXFIELD_INVOICE_DEALER_NAME, RXFIELD_INVOICE_DEALER_ADDRESS,
  RXFIELD_INVOICE_DEALER_POSTAL_CODE, RXFIELD_INVOICE_DEALER_CITY,
  RXFIELD_INVOICE_DEALER_REGION,
  RXFIELD_INVOICE_DEALER_PROVINCE, RXFIELD_INVOICE_DEALER_TIME_ZONE,
  RXFIELD_INVOICE_DEALER_EMAIL,
  RXFIELD_INVOICE_DEALER_PHONE, RXFIELD_INVOICE_DEALER_STATUS,
  RXFIELD_INVOICE_DEALER_TYPE_DEALER,
  RXFIELD_INVOICE_FINISH_DATE,
  RXFIELD_INVOICE_PAYMENT_STATUS,
  RXFIELD_INVOICE_PAYMENT_TOTAL, RXFIELD_INVOICE_PAYMENT_DATE, RXFIELD_INVOICE_PAYMENT_METHOD,
  RXFIELD_INVOICE_PAYMENT_VA_NUMBER,
  RXFIELD_INVOICE_PICKUP_AND_DELIVERY_STATUS,
  RXFORM_INVOICE, RXSTATE_INVOICES, RXSTATE_INVOICE_PAGE, STATUS_DISABLED,
  COLOR_DISABLED_ROW, COLOR_BACKGROUND, COLOR_PRIMARY,
  FILTER_TYPE_DROPDOWN, TRANSACTION_STATUSES,
  PAGE_MODE_VIEW,
  RXFIELD_ACCESSORY_TRANSACTION_ITEMS,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_INVOICE_MEMBER_ID,
  FILTER_TYPE_TEXT, RXFIELD_INVOICE_EMAIL,
  RXFIELD_INVOICE_ADDRESS,
  FILTER_TYPE_DATE_RANGE,
  PAYMENT_METHODS,
  PICKER_MODE_DATE,
  RXFIELD_INVOICE_CONTACT_PERSON_NAME,
  RXFIELD_INVOICE_CONTACT_PERSON_PHONE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  toCurrency, toMoment, transformPaymentMethod, transformTransactionStatus,
  transformUserAddress,
} from '../../helper';
import InvoiceAccessorySection from '../../component/invoice/invoice-accessory-section';
import {
  PAYMENT_STATUS_DROPDOWN_SELECTION,
} from '../../../car-service';
import DealerHeader from './dealer-header';

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: 'relative',
    paddingTop: 10,
  },
  button: {
    marginLeft: 10,
    flexDirection: 'row',
  },
  buttonText: {
    color: COLOR_PRIMARY,
    marginLeft: 8,
  },
  saveButtonWrapper: {
    marginLeft: 10,
  },
  saveButtonLabel: {
    width: '0%',
  },
  buttonContainer: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
  accessorySection: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelItemContainer: {
    marginBottom: 10,
    marginLeft: 10,
  },
}));

// eslint-disable-next-line react/prop-types
const renderList = ({ defaultValue: items }, classes) => (
  <List style={{ margin: 0, padding: 0 }}>
    {items.map((item, index) => (
      <ListItem key={item.id} className={classes.accessorySection}>
        <Grid container spacing={3} className={classes.labelItemContainer}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {`${LocalizedString.invoicePage.labelItem} ${index + 1}`}
          </Typography>
        </Grid>
        <InvoiceAccessorySection item={item} />
        {index !== items.length - 1 && <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />}
      </ListItem>
    ))}
  </List>
);

const renderDealerSection = (initialValues, downloadingDeleting, onOpenGoogleMaps, classes) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderName}
          label={LocalizedString.invoicePage.placeholderName}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_ADDRESS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderDealerAddress}
          label={LocalizedString.invoicePage.placeholderDealerAddress}
          disabled
          loading={downloadingDeleting}
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_POSTAL_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderPostalCode}
          label={LocalizedString.invoicePage.placeholderPostalCode}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_CITY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderCity}
          label={LocalizedString.invoicePage.placeholderCity}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_REGION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderRegion}
          label={LocalizedString.invoicePage.placeholderRegion}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_PROVINCE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderProvince}
          label={LocalizedString.invoicePage.placeholderProvince}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_TIME_ZONE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderTimeZone}
          label={LocalizedString.invoicePage.placeholderTimeZone}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_EMAIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderEmail}
          label={LocalizedString.invoicePage.placeholderEmail}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_PHONE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderPhone}
          label={LocalizedString.invoicePage.placeholderPhone}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_STATUS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderDealerStatus}
          label={LocalizedString.invoicePage.placeholderDealerStatus}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_INVOICE_DEALER_TYPE_DEALER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.invoicePage.placeholderDealerType}
          label={LocalizedString.invoicePage.placeholderDealerType}
          disabled
          loading={downloadingDeleting}
        />
      </Grid>
      <Grid item>
        {initialValues.dealerLatitude && initialValues.dealerLongitude && (
          <Button
            variant="text"
            onClick={() => onOpenGoogleMaps(initialValues.dealerLatitude,
              initialValues.dealerLongitude)}
            className={classes.buttonText}
          >
            {LocalizedString.invoicePage.buttonCaptionOpenInGoogleMap}
          </Button>
        )}
      </Grid>
    </Grid>
  </Grid>
);

const renderPaymentSection = (downloadingDeleting) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_PAYMENT_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderPaymentStatus}
            label={LocalizedString.invoicePage.placeholderPaymentStatus}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_TAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderTax}
            label={LocalizedString.invoicePage.placeholderTax}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_PAYMENT_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderTotal}
            label={LocalizedString.invoicePage.placeholderTotal}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_PAYMENT_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderPaymentDate}
            label={LocalizedString.invoicePage.placeholderPaymentDate}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_PAYMENT_METHOD}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderPaymentMethod}
            label={LocalizedString.invoicePage.placeholderPaymentMethod}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_PAYMENT_VA_NUMBER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderVaNumber}
            label={LocalizedString.invoicePage.placeholderVaNumber}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const renderDialogContent = (
  initialValues, classes, addingEditing, downloadingDeleting, onOpenGoogleMaps,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_MEMBER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderMemberId}
            label={LocalizedString.invoicePage.placeholderMemberId}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_USER_FULL_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderUserFullName}
            label={LocalizedString.invoicePage.placeholderUserFullName}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderEmail}
            label={LocalizedString.invoicePage.placeholderEmail}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderAddress}
            label={LocalizedString.invoicePage.placeholderAddress}
            disabled
            loading={downloadingDeleting}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_CONTACT_PERSON_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderContactPersonName}
            label={LocalizedString.invoicePage.placeholderContactPersonName}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_CONTACT_PERSON_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderContactPersonPhone}
            label={LocalizedString.invoicePage.placeholderContactPersonPhone}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_INVOICE_NO}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderInvoiceNo}
            label={LocalizedString.invoicePage.placeholderInvoiceNo}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_ORDER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderOrderId}
            label={LocalizedString.invoicePage.placeholderOrderId}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_FINISH_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderFinishDate}
            label={LocalizedString.invoicePage.placeholderFinishDate}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_TRANSACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderTransactionId}
            label={LocalizedString.invoicePage.placeholderTransactionId}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_TRANSACTION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderTransactionStatus}
            label={LocalizedString.invoicePage.placeholderTransactionStatus}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_PICKUP_AND_DELIVERY_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderPickUpAndDeliveryStatus}
            label={LocalizedString.invoicePage.placeholderPickUpAndDeliveryStatus}
            disabled
            loading={downloadingDeleting}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_INVOICE_TOTAL_WITH_TAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.invoicePage.placeholderGrandTotal}
            label={LocalizedString.invoicePage.placeholderGrandTotal}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.invoicePage.placeholderDealer} />

    {renderDealerSection(initialValues, downloadingDeleting, onOpenGoogleMaps, classes)}

    <SectionTitle title={LocalizedString.invoicePage.placeholderPayment} />

    {renderPaymentSection(downloadingDeleting)}

    <SectionTitle title={LocalizedString.invoicePage.placeholderItems} />
    {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
      <Field
        name={RXFIELD_ACCESSORY_TRANSACTION_ITEMS}
        component={(data) => renderList(data, classes)}
        placeholder={LocalizedString.invoicePage.labelItems}
        label={LocalizedString.invoicePage.labelItems}
        defaultValue={initialValues?.items}
        disabled
        loading={downloadingDeleting}
      />
    )}
  </Grid>
);

const InvoicePage = ({
  downloading, handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh, onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues, onAdvancedFilterPressed,
  addingEditing, downloadingDeleting, onCancelPressed,
  onApplyAdvancedFilterPressed, pageMode,
  onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText,
  dealers, loadingDealers, onChangeDealerText,
  onOpenGoogleMaps, sendingPdf, onDownloadPdf, onDownloadCsvPressed,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_INVOICES}
      uiPage={RXSTATE_INVOICE_PAGE}
      filterColumns={[
        {
          title: LocalizedString.invoicePage.placeholderUser,
          field: 'users',
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUsers,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.invoicePage.placeholderTransactionStatus,
          field: 'transactionStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: TRANSACTION_STATUSES,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.invoicePage.labelTransactionId,
          field: 'transactionId',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.invoicePage.labelInvoiceNo,
          field: 'invoiceNo',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.invoicePage.labelDealer,
          field: 'dealer',
          type: FILTER_TYPE_DROPDOWN,
          data: dealers,
          loading: loadingDealers,
          onChangeFilterText: onChangeDealerText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.invoicePage.labelPaymentDate,
          field: 'paymentDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE,
        },
        {
          title: LocalizedString.invoicePage.labelPaymentMethod,
          field: 'paymentMethod',
          type: FILTER_TYPE_DROPDOWN,
          data: PAYMENT_METHODS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.invoicePage.placeholderPaymentStatus,
          field: 'paymentStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: PAYMENT_STATUS_DROPDOWN_SELECTION,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.invoicePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.invoicePage.labelInvoiceNo, field: 'invoiceNo', sorting: !downloading },
        { title: LocalizedString.invoicePage.labelOrderId, field: 'orderId', sorting: !downloading },
        { title: LocalizedString.invoicePage.labelMemberId, field: 'user.userCode', sorting: !downloading },
        { title: LocalizedString.invoicePage.labelUserFullName, field: 'user.fullName', sorting: !downloading },
        { title: LocalizedString.invoicePage.labelEmail, field: 'user.email', sorting: false },
        {
          title: LocalizedString.invoicePage.labelAddress,
          field: 'user.homeAddress',
          sorting: false,
          render: ({ user }) => transformUserAddress(user),
        },
        { title: LocalizedString.invoicePage.labelTransactionId, field: 'transactionId', sorting: !downloading },
        {
          title: LocalizedString.invoicePage.labelTransactionStatus,
          field: 'transactionStatus',
          sorting: !downloading,
          render: ({ transactionStatus }) => (transactionStatus
            ? transformTransactionStatus(transactionStatus) : null),
        },
        { title: LocalizedString.invoicePage.labelItems, field: 'items.length', sorting: false },
        {
          title: LocalizedString.invoicePage.labelPaymentMethod,
          field: 'payment.paymentMethod',
          sorting: false,
          render: ({ payment }) => (payment?.paymentMethod
            ? transformPaymentMethod(payment?.paymentMethod) : null),
        },
        {
          title: LocalizedString.invoicePage.labelGrandTotal, field: 'totalWithTax', sorting: !downloading, render: ({ totalWithTax }) => (`Rp${toCurrency(totalWithTax)}`),
        },
        {
          title: LocalizedString.invoicePage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.invoicePage.title}
      useFullWidth
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
      onCancelPressed={onCancelPressed}
      disableCreate
      disableDelete
      disableEdit
      enableSave
      onDownloadPressed={onDownloadCsvPressed}
    >

      {pageMode === PAGE_MODE_VIEW && (
      <div className={classes.buttonContainer}>
        <AccentButton
          variant="outlined"
          startIcon={<CloudDownload />}
          caption=""
          disabled={downloadingDeleting || sendingPdf}
          loading={sendingPdf}
          onClick={onDownloadPdf}
          classes={{
            startIcon: classes.saveButtonWrapper,
            label: classes.saveButtonLabel,
            root: classes.saveButtonWrapper,
          }}
        />
      </div>
      )}
      <DealerHeader
        dealerDetail={initialValues.dealer}
        paymentStatus={initialValues.paymentStatus}
        bookingCode={initialValues.transactionId}
        downloading={downloadingDeleting}
      />
      <Divider className={classes.divider} />

      {renderDialogContent(
        initialValues, classes, addingEditing, downloadingDeleting, onOpenGoogleMaps,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_INVOICE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InvoicePage);

InvoicePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dealers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingDealers: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  sendingPdf: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPdf: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  onOpenGoogleMaps: PropTypes.func.isRequired,
  onChangeDealerText: PropTypes.func.isRequired,
  onDownloadCsvPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
