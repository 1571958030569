import { transformSearchText } from '../../../../../helper';
import { downloadGiiasSemarangPrizeRedemptions } from '../../../helper';
import { downloadingGiiasSemarangPrizeRedemptions, setGiiasSemarangPrizeRedemptions } from '../simple-action';


export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, giiasSemarangPrizeRedemptions, uiGiiasSemarangPrizeRedemption,
  } = getState();
  const { token } = authentication;
  const { meta } = giiasSemarangPrizeRedemptions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGiiasSemarangPrizeRedemption;


  try {
    dispatch(downloadingGiiasSemarangPrizeRedemptions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadGiiasSemarangPrizeRedemptions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setGiiasSemarangPrizeRedemptions(list));
    }
  } finally {
    dispatch(downloadingGiiasSemarangPrizeRedemptions(false));
  }
};
