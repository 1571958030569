import { transformSearchText } from '../../../../../helper';
import { downloadGiiasMedanTransactions } from '../../../helper';
import { downloadingGiiasMedan2022Transactions, setGiiasMedan2022Transactions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, giiasMedanTransactions, uiGiiasMedanTransaction } = getState();
  const { token } = authentication;
  const { meta } = giiasMedanTransactions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGiiasMedanTransaction;


  try {
    dispatch(downloadingGiiasMedan2022Transactions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadGiiasMedanTransactions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setGiiasMedan2022Transactions(list));
    }
  } finally {
    dispatch(downloadingGiiasMedan2022Transactions(false));
  }
};
