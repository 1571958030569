import { addingEditingPromoCodeBatch, clearPromoCodeBatches, setAlertErrorMessage } from '../simple-action';
import { cancelPromoCodeBatch } from '../../../helper';
import downloadPromoCodeBatchesAsync from './downloadPromoCodeBatchesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPromoCodeBatch(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPromoCodeBatch;

    await cancelPromoCodeBatch(tappedId, token);

    dispatch(clearPromoCodeBatches());
    dispatch(downloadPromoCodeBatchesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingPromoCodeBatch(false));
  }
};
