import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_FAQS = 'DOWNLOADING_FAQS';
export const downloadingFAQs = makeActionCreator(DOWNLOADING_FAQS, 'status');

export const SET_FAQS = 'SET_FAQS';
export const setFAQs = makeActionCreator(SET_FAQS, 'data');

export const ADD_FAQ = 'ADD_FAQ';
export const addFAQ = makeActionCreator(ADD_FAQ, 'data');

export const CLEAR_FAQS = 'CLEAR_FAQS';
export const clearFAQs = makeActionCreator(CLEAR_FAQS);

export const ADDING_EDITING_FAQ = 'ADDING_EDITING_FAQ';
export const addingEditingFAQ = makeActionCreator(ADDING_EDITING_FAQ, 'status');

export const DOWNLOADING_DELETING_FAQ = 'DOWNLOADING_DELETING_FAQ';
export const downloadingDeletingFAQ = makeActionCreator(DOWNLOADING_DELETING_FAQ, 'status');


export const SET_FAQ_SELECTED_PAGE_SIZE = 'SET_FAQ_SELECTED_PAGE_SIZE';
export const setFAQSelectedPageSize = makeActionCreator(SET_FAQ_SELECTED_PAGE_SIZE, 'size');

export const SET_FAQ_SELECTED_ORDER_BY = 'SET_FAQ_SELECTED_ORDER_BY';
export const setFAQSelectedOrderBy = makeActionCreator(SET_FAQ_SELECTED_ORDER_BY, 'order');

export const SET_FAQ_TAPPED_ID = 'SET_FAQ_TAPPED_ID';
export const setFAQTappedId = makeActionCreator(SET_FAQ_TAPPED_ID, 'id');

export const SET_FAQ_SEARCH_TEXT = 'SET_FAQ_SEARCH_TEXT';
export const setFAQSearchText = makeActionCreator(SET_FAQ_SEARCH_TEXT, 'text');

export const SET_FAQS_SELECTED_VISIBILITY_STATUS = 'SET_FAQS_SELECTED_VISIBILITY_STATUS';
export const setFAQsSelectedVisibilityStatus = makeActionCreator(SET_FAQS_SELECTED_VISIBILITY_STATUS, 'option');

export const SET_FAQ_SELECTED_CATEGORY_ID = 'SET_FAQ_SELECTED_CATEGORY_ID';
export const setFAQSelectedCategoryId = makeActionCreator(SET_FAQ_SELECTED_CATEGORY_ID, 'id');

export const SET_FAQ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_FAQ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setFaqAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_FAQ_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

//--------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------

export const DOWNLOADING_FAQ_CATEGORIES = 'DOWNLOADING_FAQ_CATEGORIES';
export const downloadingFAQCategories = makeActionCreator(DOWNLOADING_FAQ_CATEGORIES, 'status');

export const SET_FAQ_CATEGORIES = 'SET_FAQ_CATEGORIES';
export const setFAQCategories = makeActionCreator(SET_FAQ_CATEGORIES, 'data');

export const ADD_FAQ_CATEGORY = 'ADD_FAQ_CATEGORY';
export const addFAQCategory = makeActionCreator(ADD_FAQ_CATEGORY, 'data');

export const CLEAR_FAQ_CATEGORIES = 'CLEAR_FAQ_CATEGORIES';
export const clearFAQCategories = makeActionCreator(CLEAR_FAQ_CATEGORIES);

export const ADDING_EDITING_FAQ_CATEGORY = 'ADDING_EDITING_FAQ_CATEGORY';
export const addingEditingFAQCategory = makeActionCreator(ADDING_EDITING_FAQ_CATEGORY, 'status');

export const DOWNLOADING_DELETING_FAQ_CATEGORY = 'DOWNLOADING_DELETING_FAQ_CATEGORY';
export const downloadingDeletingFAQCategory = makeActionCreator(DOWNLOADING_DELETING_FAQ_CATEGORY, 'status');


export const SET_FAQ_CATEGORY_SELECTED_PAGE_SIZE = 'SET_FAQ_CATEGORY_SELECTED_PAGE_SIZE';
export const setFAQCategorySelectedPageSize = makeActionCreator(SET_FAQ_CATEGORY_SELECTED_PAGE_SIZE, 'size');

export const SET_FAQ_CATEGORY_SELECTED_ORDER_BY = 'SET_FAQ_CATEGORY_SELECTED_ORDER_BY';
export const setFAQCategorySelectedOrderBy = makeActionCreator(SET_FAQ_CATEGORY_SELECTED_ORDER_BY, 'order');

export const SET_FAQ_CATEGORY_TAPPED_ID = 'SET_FAQ_CATEGORY_TAPPED_ID';
export const setFAQCategoryTappedId = makeActionCreator(SET_FAQ_CATEGORY_TAPPED_ID, 'id');

export const SET_FAQ_CATEGORY_SEARCH_TEXT = 'SET_FAQ_CATEGORY_SEARCH_TEXT';
export const setFAQCategorySearchText = makeActionCreator(SET_FAQ_CATEGORY_SEARCH_TEXT, 'text');
