import { downloadVoucherToken, downloadMyConfigItems } from '../../../helper';
import { GARUDA_COMPANY_NAME } from '../../../constant';
import {
  addVoucherToken, downloadingVoucherToken, setVoucherTokenIsGarudaCompanyName,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingVoucherToken(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoucherToken;

    const tokenRequest = await downloadVoucherToken(tappedId, token);
    const list = await downloadMyConfigItems(token);

    const found = Object.values(list).find((item) => item.value
    === tokenRequest.voucher.merchant.name);

    if (found?.name === GARUDA_COMPANY_NAME) {
      dispatch(setVoucherTokenIsGarudaCompanyName(true));
    } else {
      dispatch(setVoucherTokenIsGarudaCompanyName(false));
    }


    dispatch(addVoucherToken(tokenRequest));
  } finally {
    dispatch(downloadingVoucherToken(false));
  }
};
