import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { AccentButton, FunctionalPage, SectionTitle } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  RXFORM_TEST_DRIVE_EO_RESERVATION, RXSTATE_TEST_DRIVE_EO_RESERVATIONS,
  RXSTATE_TEST_DRIVE_EO_RESERVATION_PAGE,
  RESERVATION_TYPE_EVENT, DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_TEST_DRIVE_EO_RESERVATION_TYPE,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_USER_ID, RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_NAME,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_ID, RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_LOCATION,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_ACTIVITY_STATUS,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_RECEIVED_NEWS_UPDATE,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_SIM_IMAGE, RXFIELD_TEST_DRIVE_EO_RESERVATION_SURVEY_IMAGE,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_USER_FULLNAME, RXFIELD_TEST_DRIVE_EO_RESERVATION_VEHICLE_MODEL,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_RESERVED_DATE, RXFIELD_TEST_DRIVE_EO_RESERVATION_COMPLETED_TIME,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_REFERENCE_ID, RXFIELD_TEST_DRIVE_EO_RESERVATION_TESTDRIVE_IMAGE,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_OTHER_IMAGE, RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_CODE,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_NAME, RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_CITY,
  FILTER_TYPE_SWITCH, ACTIVITY_STATUS_DROPDOWN, RESERVATION_TYPE_DROPDOWN,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, FILTER_TYPE_DATE_RANGE,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_FIRST_NAME,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_EMAIL, RXFIELD_TEST_DRIVE_EO_RESERVATION_LAST_NAME,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_PHONE,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_GENDER,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  PAGE_MODE_VIEW,
  PAGE_MODE_EDIT,
  RXFIELD_TEST_DRIVE_EO_RESERVATION_SALES_ID,
} from '../../constant';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormImageInputField, renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';


const useStyles = makeStyles(() => ({
  multipleFabContainer: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fabContainer: {
    marginRight: 10,
    borderColor: 'black',
    borderWidth: 1,
  },
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
  vehicleInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  vehicleInfoSection: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    margin: '0px 0px 20px 10px',
  },
  vehicleInfoButton: {
    color: COLOR_PRIMARY,
    margin: '0px 0px 20px 10px',
  },
  redButton: { marginRight: 16, color: 'red', borderColor: 'red' },
}));


const renderCheckboxAutoComplete = (options, loading, onChangeDropdown, onChangeText,
  onOptionSelected, fieldName, label, placeholder, classes) => (
    <div className={classes.dropdownContainer} key={fieldName}>
      <Field
        name={fieldName}
        component={renderReduxFormAutocompleteCheckboxField}
        placeholder={placeholder}
        label={label}
        data={options}
        loading={loading}
        onChangeText={onChangeText}
        onOptionSelected={(e) => {
          onChangeDropdown(e, fieldName);
          onOptionSelected(e);
        }}
        onBlur={(e) => e.preventDefault()}
      />
    </div>
);

const renderDialogContent = (
  isEventReservationType, initialValues, pageMode, classes, salesConsultantsData, downloadingSalesConsultant, onChangeDropdown, onChangeCityText, onCityOptionSelected,
) => (
  <div>
    {pageMode === PAGE_MODE_VIEW && (
      <>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_TYPE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelReservationType}
                label={LocalizedString.reservationPage.labelReservationType}
                disabled
              />
            </Grid>
            { isEventReservationType ? (
              <>
                <Grid item>
                  <Field
                    name={RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_ID}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.reservationPage.labelEventId}
                    label={LocalizedString.reservationPage.labelEventId}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_NAME}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.reservationPage.labelEventName}
                    label={LocalizedString.reservationPage.labelEventName}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_LOCATION}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.reservationPage.labelEventLocation}
                    label={LocalizedString.reservationPage.labelEventLocation}
                    disabled
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Field
                    name={RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_CODE}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.reservationPage.labelDealerCode}
                    label={LocalizedString.reservationPage.labelDealerCode}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_NAME}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.reservationPage.labelDealerName}
                    label={LocalizedString.reservationPage.labelDealerName}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_CITY}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.reservationPage.labelDealerCity}
                    label={LocalizedString.reservationPage.labelDealerCity}
                    disabled
                  />
                </Grid>
              </>
            ) }
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_ACTIVITY_STATUS}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelActivityStatus}
                label={LocalizedString.reservationPage.labelActivityStatus}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_RECEIVED_NEWS_UPDATE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelReceivedNewsUpdate}
                label={LocalizedString.reservationPage.labelReceivedNewsUpdate}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_USER_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelUserId}
                label={LocalizedString.reservationPage.labelUserId}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_USER_FULLNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelUserFullname}
                label={LocalizedString.reservationPage.labelUserFullname}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_VEHICLE_MODEL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelVehicleModel}
                label={LocalizedString.reservationPage.labelVehicleModel}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_RESERVED_DATE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelReservedDate}
                label={LocalizedString.reservationPage.labelReservedDate}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_COMPLETED_TIME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelCompletedTime}
                label={LocalizedString.reservationPage.labelCompletedTime}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_REFERENCE_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelReferenceId}
                label={LocalizedString.reservationPage.labelReferenceId}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <SectionTitle title={LocalizedString.reservationPage.labelUserInfo} />

        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_FIRST_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.placeholderFirstName}
                label={LocalizedString.reservationPage.placeholderFirstName}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_LAST_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.placeholderLastName}
                label={LocalizedString.reservationPage.placeholderLastName}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_GENDER}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.placeholderGender}
                label={LocalizedString.reservationPage.placeholderGender}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_EMAIL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.placeholderEmail}
                label={LocalizedString.reservationPage.placeholderEmail}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_PHONE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.placeholderPhone}
                label={LocalizedString.reservationPage.placeholderPhone}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_SIM_IMAGE}
                component={renderReduxFormImageInputField}
                placeholder={LocalizedString.reservationPage.labelSimImage}
                label={LocalizedString.reservationPage.labelSimImage}
                defaultValue={initialValues.simImage}
                fullImageWidth="100%"
                useFullWidthImage
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_SURVEY_IMAGE}
                component={renderReduxFormImageInputField}
                placeholder={LocalizedString.reservationPage.labelSurveyImage}
                label={LocalizedString.reservationPage.labelSurveyImage}
                defaultValue={initialValues.surveyImage}
                fullImageWidth="100%"
                useFullWidthImage
                disabled
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_TESTDRIVE_IMAGE}
                component={renderReduxFormImageInputField}
                placeholder={LocalizedString.reservationPage.labelTestdriveImage}
                label={LocalizedString.reservationPage.labelTestdriveImage}
                defaultValue={initialValues.testDriveImage}
                fullImageWidth="100%"
                useFullWidthImage
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_TEST_DRIVE_EO_RESERVATION_OTHER_IMAGE}
                component={renderReduxFormImageInputField}
                placeholder={LocalizedString.reservationPage.labelOtherImage}
                label={LocalizedString.reservationPage.labelOtherImage}
                defaultValue={initialValues.otherImage}
                fullImageWidth="100%"
                useFullWidthImage
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    )}
    {pageMode === PAGE_MODE_EDIT && (
      <>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EO_RESERVATION_SALES_ID}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder="Sales Consultant"
            label="Sales Consultant"
            data={salesConsultantsData}
            // value={defaultCompanyValue}
            loading={downloadingSalesConsultant}
            onChangeText={onChangeCityText}
            onOptionSelected={onCityOptionSelected}
          />
        </Grid>
      </>
    )}
  </div>
);

const ReservationPage = ({
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  isEventReservationType, initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText,
  dealers, loadingDealers, onChangeDealersText,
  events, loadingEvents, onChangeEventText, approving, onApprovePressed,
  onGoingPressed, onRejectPressed, onCompletedPressed, onEditPressed, onSavePressed, pageMode,
  salesConsultantsData, downloadingSalesConsultant, onChangeDropdown, onChangeCityText, onCityOptionSelected,
}) => {
  const classes = useStyles();

  const renderCustomFabComponent = () => initialValues.activityStatus === 'Reservation'
    && (
      <div className={classes.multipleFabContainer}>
        <div className={classes.fabContainer}>
          <AccentButton
            loading={approving}
            disableElevation
            caption="REJECT"
            variant="outlined"
            onClick={() => onRejectPressed(
              initialValues,
            )}
            style={classes.redButton}
          />
          <AccentButton
            loading={approving}
            disableElevation
            caption="APPROVE"
            onClick={() => onApprovePressed(
              initialValues,
            )}
          />
        </div>
      </div>
    );

  return (
    <FunctionalPage
      data={RXSTATE_TEST_DRIVE_EO_RESERVATIONS}
      uiPage={RXSTATE_TEST_DRIVE_EO_RESERVATION_PAGE}
      tableColumns={[
        {
          title: LocalizedString.reservationPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.reservationPage.labelEventOrDealer,
          field: '',
          sorting: false,
          render: ({ reservationType, event, dealer }) => (
            reservationType === RESERVATION_TYPE_EVENT ? event?.name : dealer?.name
          ),
        },
        { title: LocalizedString.reservationPage.labelReservationType, field: 'reservationType', sorting: !downloading },
        {
          title: LocalizedString.reservationPage.labelUserFullname,
          field: '',
          sorting: !downloading,
          render: ({
            isForOther, profile, firstNameForOther, lastNameForOther,
          }) => (!isForOther
            ? profile.fullName : `${firstNameForOther} ${lastNameForOther}`),
        },
        { title: LocalizedString.reservationPage.labelVehicleModel, field: 'testVehicleModel', sorting: !downloading },
        { title: LocalizedString.reservationPage.labelReservationStatus, field: 'activityStatus', sorting: !downloading },
        { title: LocalizedString.reservationPage.labelQueue, field: 'queueNumbers', sorting: !downloading },
        {
          title: LocalizedString.reservationPage.labelReservationDate,
          field: 'reservedDate',
          render: ({ reservedDate }) => toMoment(reservedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
          sorting: !downloading,
        },
        { title: 'Sales Consultant', field: 'salesConsultantName', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.reservationPage.labelEventName,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: events,
          loading: loadingEvents,
          onChangeFilterText: onChangeEventText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.reservationPage.labelDealerName,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: dealers,
          loading: loadingDealers,
          onChangeFilterText: onChangeDealersText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.reservationPage.labelReservationType,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: RESERVATION_TYPE_DROPDOWN,
        },
        {
          title: LocalizedString.reservationPage.labelVehicleModel,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_VEHICLE_MODEL,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.reservationPage.labelUserFullname,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_USER_FULLNAME,
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUsers,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.reservationPage.labelReservedDate,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_RESERVED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
        },
        {
          title: LocalizedString.reservationPage.labelReferenceId,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_REFERENCE_ID,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.reservationPage.labelActivityStatus,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_ACTIVITY_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: ACTIVITY_STATUS_DROPDOWN,
        },
        {
          title: LocalizedString.reservationPage.labelReceivedNewsUpdate,
          field: RXFIELD_TEST_DRIVE_EO_RESERVATION_RECEIVED_NEWS_UPDATE,
          type: FILTER_TYPE_SWITCH,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      onGoingPressed={onGoingPressed}
      onCompletedPressed={onCompletedPressed}
      onEditPressed={onEditPressed}
      onSavePressed={onSavePressed}
      title={LocalizedString.reservationPage.title}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      renderCustomFabComponent={() => renderCustomFabComponent()}
      disableCreate
      disableDelete
      // disableEdit
      useFullWidth
      showOnGoingAction
      showOnCompleteAction
    >
      {renderDialogContent(isEventReservationType, initialValues, pageMode, classes, salesConsultantsData, downloadingSalesConsultant, onChangeDropdown, onChangeCityText, onCityOptionSelected)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_TEST_DRIVE_EO_RESERVATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ReservationPage);

ReservationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  dealers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingEvents: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  loadingDealers: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  isEventReservationType: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeDealersText: PropTypes.func.isRequired,
  onApprovePressed: PropTypes.func.isRequired,
  onRejectPressed: PropTypes.func.isRequired,
  approving: PropTypes.bool.isRequired,
  onGoingPressed: PropTypes.func.isRequired,
  onCompletedPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onCityOptionSelected: PropTypes.func.isRequired,
  salesConsultantsData: PropTypes.object.isRequired,
  downloadingSalesConsultant: PropTypes.bool.isRequired,
};
