import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper, withStyles, CircularProgress,
} from '@material-ui/core';
import {
  Chart, ArgumentAxis, ValueAxis, BarSeries, Title, Legend, Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import {
  Stack, Animation, EventTracker,
} from '@devexpress/dx-react-chart';
import classNames from 'clsx';
import { ChartBartItemShape } from '../type';

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return (Target) => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const LegendRoot = setStyle({
  display: 'flex',
  margin: 'auto',
  flexDirection: 'row',
})(Legend.Root);

const LegendLabel = setStyle({
  whiteSpace: 'nowrap',
})(Legend.Label);

const TooltipTitle = setStyle({
  fontWeight: 'bold',
  paddingBottom: 0,
})(Tooltip.Content);

const TooltipBody = setStyle({
  paddingTop: 0,
})(Tooltip.Content);


const AttendanceTrendChart = ({
  data, downloading, title, onTooltipTargetItemChange, targetItem,
  chartBars,
}) => {
  const TooltipContent = () => (
    <div>
      <div>
        <TooltipTitle
          text={targetItem.series}
        />
      </div>
      <div>
        <TooltipBody
          text={data[targetItem.point][targetItem.series]}
        />
      </div>
    </div>
  );

  return (downloading ? (
    <CircularProgress color="inherit" size={20} />
  ) : (
    <Paper>
      <Chart
        data={data}
      >
        <ArgumentAxis />
        <ValueAxis />

        {chartBars.map((item) => (
          <BarSeries
            name={item.name}
            valueField={item.value}
            argumentField={item.argument}
            color={item.barColor}
          />
        ))}

        <Animation />
        <Legend position="bottom" rootComponent={LegendRoot} labelComponent={LegendLabel} />
        <Title text={title} />
        <Stack />
        <EventTracker />
        <Tooltip
          targetItem={targetItem}
          onTargetItemChange={onTooltipTargetItemChange}
          contentComponent={TooltipContent}
        />
      </Chart>
    </Paper>
  ));
};

export default AttendanceTrendChart;

AttendanceTrendChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  downloading: PropTypes.bool,
  targetItem: PropTypes.object,
  chartBars: PropTypes.arrayOf(ChartBartItemShape).isRequired,

  onTooltipTargetItemChange: PropTypes.func.isRequired,
};

AttendanceTrendChart.defaultProps = {
  downloading: false,
  targetItem: null,
};
