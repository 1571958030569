import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    gameActivityMenu: {
      title: 'Game Activity',
    },
    registrationReferralPage: {
      title: 'Registration Referral',

      labelNo: 'No',
      labelRegisteredUser: 'Registered User',
      labelReferrer: 'Referrer',
      labelPoint: 'Point',
      labelCreatedDate: 'Created Date',
      labelRegisteredUserID: 'Registered User ID',
      labelRegisteredUserName: 'Registered User Name',
      labelReferrerUserID: 'Referrer User ID',
      labelReferrerUserName: 'Referrer User Name',

      placeholderRegisteredUserID: 'Registered User ID',
      placeholderRegisteredUserName: 'Registered User Name',
      placeholderPoint: 'Point',
      placeholderReferrerUserID: 'Referrer User ID',
      placeholderReferrerUserName: 'Referrer User Name',
    },
    recurrenceReferralRewardPage: {
      title: 'Recurrence Referral Reward',

      labelNo: 'No',
      labelUser: 'User',
      labelUserID: 'User ID',
      labelUserName: 'User Name',
      labelRewardStatus: 'Reward Status',
      labelRewardStatusNotes: 'Reward Status Notes',
      labelVoucherRewards: 'Voucher Rewards',
      labelCreatedDate: 'Created Date',
      labelRewards: 'Rewards',
      labelVoucherID: 'Voucher ID',
      labelVoucherName: 'Voucher Name',
      labelStatus: 'Status',
      labelNotes: 'Notes',
      labelRegistrationReferrals: 'Registration Referrals',
      labelID: 'ID',
      labelRegisteredUserID: 'Registered User ID',
      labelRegisteredUserName: 'Registered User Name',
    },
    dailyCheckinPage: {
      title: 'Daily Check-in',

      labelUserName: 'User Name',
      labelPoint: 'Point',
      labelCreatedDate: 'Created Date',

      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderPoint: 'Point',
      placeholderCreatedDate: 'Created Date',
    },
    completedProfilePage: {
      title: 'Completed Profile',

      labelNo: 'No',
      labelUserName: 'User Name',
      labelPoint: 'Point',
      labelCreatedDate: 'Created Date',

      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderPoint: 'Point',
      placeholderCreatedDate: 'Created Date',
    },
    newUserNotificationPage: {
      title: 'New User Notification',

      labelCreatedDate: 'Created Date',
      labelScheduledNotification: 'Scheduled Notification',
      labelUser: 'User',
      labelIsSent: 'Is Sent',
      labelNo: 'No',
      labelFullName: 'Full Name',
      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelSentOn: 'Sent On',
      labelNotes: 'Notes',

      placeholderUserID: 'User ID',
    },
  },
  id: {
    gameActivityMenu: {
      title: 'Aktivitas Permainan',
    },
    registrationReferralPage: {
      title: 'Referensi Pendaftaran',

      labelNo: 'No',
      labelRegisteredUser: 'Pengguna Terdaftar',
      labelReferrer: 'Perujuk',
      labelPoint: 'Poin',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelRegisteredUserID: 'ID Pengguna',
      labelRegisteredUserName: 'Nama Pengguna',
      labelReferrerUserID: 'ID Perujuk',
      labelReferrerUserName: 'Nama Perujuk',

      placeholderRegisteredUserID: 'ID Pengguna Terdaftar',
      placeholderRegisteredUserName: 'Nama Pengguna Terdaftar',
      placeholderPoint: 'Poin',
      placeholderReferrerUserID: 'ID Perujuk',
    },
    recurrenceReferralRewardPage: {
      title: 'Hadiah Rujukan Berulang',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelUserID: 'ID Pengguna',
      labelUserName: 'Nama Pengguna',
      labelRewardStatus: 'Status Penghargaan',
      labelRewardStatusNotes: 'Catatan Status Penghargaan',
      labelVoucherRewards: 'Penghargaan Voucher',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelRewards: 'Penghargaan',
      labelVoucherID: 'ID Voucher',
      labelVoucherName: 'Nama Voucher',
      labelStatus: 'Status',
      labelNotes: 'Catatan',
      labelRegistrationReferrals: 'Referensi Pendaftaran',
      labelID: 'ID',
      labelRegisteredUserID: 'ID User Terdaftar',
      labelRegisteredUserName: 'Nama User Terdaftar',
    },
    dailyCheckinPage: {
      title: 'Check-in Harian',

      labelUserName: 'Nama Pengguna',
      labelPoint: 'Poin',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderPoint: 'Poin',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    completedProfilePage: {
      title: 'Profil Lengkap',

      labelNo: 'No',
      labelUserName: 'Nama Pengguna',
      labelPoint: 'Poin',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderPoint: 'Poin',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    newUserNotificationPage: {
      title: 'Notifikasi Pengguna Baru',

      labelCreatedDate: 'Tanggal Pembuatan',
      labelScheduledNotification: 'Notifikasi Terjadwal',
      labelUser: 'Pengguna',
      labelIsSent: 'Terkirim',
      labelNo: 'No',
      labelFullName: 'Nama Lengkap',
      labelEmail: 'Email',
      labelPhone: 'Nomor HP',
      labelSentOn: 'Terkirim Pada',
      labelNotes: 'Catatan',

      placeholderUserID: 'ID Pengguna',
    },
  },
});

export default LocalizedString;
