import { ADD_GIIAS_SURABAYA_2022_TRANSACTION, CLEAR_GIIAS_SURABAYA_2022_TRANSACTIONS, SET_GIIAS_SURABAYA_2022_TRANSACTIONS } from '../action/simple-action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GIIAS_SURABAYA_2022_TRANSACTIONS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_GIIAS_SURABAYA_2022_TRANSACTION: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_GIIAS_SURABAYA_2022_TRANSACTIONS:
      return initialState;
    default:
      return state;
  }
};
