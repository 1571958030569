import PropTypes from 'prop-types';

export * from '../../type';

export const LaborPartAccessoriesShape = PropTypes.shape({
  name: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const ReservationSummary = PropTypes.shape({
  labors: PropTypes.arrayOf(LaborPartAccessoriesShape),
  parts: PropTypes.arrayOf(LaborPartAccessoriesShape),
  accessories: PropTypes.arrayOf(LaborPartAccessoriesShape),
  subTotal: PropTypes.number,
  tax: PropTypes.number,
  deliveryCharge: PropTypes.number,
  grandTotal: PropTypes.number,
  totalTimeHours: PropTypes.number,
  notes: PropTypes.string,
});
