import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_INVOICES, MENUID_GENUINE_ACCESSORY_INVOICE, PAGE_MODE_TABLE,
  RXFORM_INVOICE, DATE_TIME_FORMAT_WITHOUT_PIPE, REST_BASE_URL,
  REST_URL_IMAGE_STORAGE, REST_URL_GOOGLE_MAP, PAYMENT_STATUS_WAITING,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData,
  sortAsc, toMoment, getTimeSymbol, transformPaymentMethod,
  debounceSearch, toCurrency,
  transformDropdownData, transformUserAddress, transformTransactionStatus,
} from '../../helper';
import {
  downloadInvoicesAycnc, clearInvoices,
  setActiveSideMenuItem, setAlertErrorMessage,
  setInvoiceSearchText, setInvoiceSelectedOrderBy,
  setInvoiceSelectedPageSize, setInvoiceTappedId,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  clearUsers, downloadInvoiceAsync, saveInvoiceAsync,
  setInvoicesFilterString,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import GlobalLocalizedString from '../../../../localization';
import InvoicePage from './invoice.presentation';
import {
  clearDealers, downloadDealersAsync,
  setDealerAdvancedFilterDialogSelectedFilterString, setDealerSearchText,
} from '../../../car-service/redux/action';

import LocalizedString from '../../localization';

const getPaymentStatus = (status) => (status === PAYMENT_STATUS_WAITING
  ? LocalizedString.invoicePage.labelWaitingForPayment : status);

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiInvoice, invoices } = state;
  const { data } = invoices;
  const { downloadingDeleting, tappedId, sendingPdf } = uiInvoice;
  const { pageMode } = uiFunctionalPage;
  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    contactPersonName: found.user?.homeAddress?.contactName ? found.user.homeAddress.contactName : '-',
    contactPersonPhone: found.user?.homeAddress?.contactPhone ? found.user.homeAddress.contactPhone : '-',
    email: found.user.email ? found.user.email : '-',
    address: transformUserAddress(found.user),
    memberId: found.user.userCode ? found.user.userCode : '-',
    invoiceNo: found.invoiceNo ? found.invoiceNo : '-',
    userFullName: found.user.fullName ? found.user.fullName : '-',
    finishDate: found.finishDate
      ? `${toMoment(found.finishDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)} ${getTimeSymbol(found.dealer.timeZone)}`
      : '-',
    tax: found.tax ? `Rp${toCurrency(found.tax)}` : '-',
    totalWithTax: found.totalWithTax ? `Rp${toCurrency(found.totalWithTax)}` : '-',
    dealerId: found.dealer.id ? found.dealer.id : '-',
    dealerCode: found.dealer.dealerCode ? found.dealer.dealerCode : '-',
    dealerType: found.dealer.type ? found.dealer.type : '-',
    dealerName: found.dealer.name ? found.dealer.name : '-',
    dealerAddress: found.dealer.address ? found.dealer.address : '-',
    dealerPostalCode: found.dealer.postalCode ? found.dealer.postalCode : '-',
    dealerCity: found.dealer.city ? found.dealer.city : '-',
    dealerRegion: found.dealer.region ? found.dealer.region : '-',
    dealerRegionCode: found.dealer.regionCode ? found.dealer.regionCode : '-',
    dealerProvince: found.dealer.province ? found.dealer.province : '-',
    dealerTimeZone: found.dealer.timeZone ? found.dealer.timeZone : '-',
    dealerChargingStation: found.dealer.hasChargingStation === true
      ? GlobalLocalizedString.common.labelYes
      : GlobalLocalizedString.common.labelNo,
    dealerEmail: found.dealer.email ? found.dealer.email : '-',
    dealerPhone: found.dealer.phone ? found.dealer.phone : '-',
    dealerOpenTime: found.dealer.openTime ? `${found.dealer.openTime} ${getTimeSymbol(found.dealer.timeZone)}` : '-',
    dealerCloseTime: found.dealer.closeTime ? `${found.dealer.closeTime} ${getTimeSymbol(found.dealer.timeZone)}` : '-',
    dealerStatus: found.dealer.dealerStatus ? found.dealer.dealerStatus : '-',
    dealerTypeCode: found.dealer.dealerTypeCode ? found.dealer.dealerTypeCode : '-',
    dealerTypeDealer: found.dealer.dealerType ? found.dealer.dealerType : '-',
    dealerLatitude: found.dealer.latitude ? found.dealer.latitude : '-',
    dealerLongitude: found.dealer.longitude ? found.dealer.longitude : '-',
    paymentId: found.payment.id ? found.payment.id : '-',
    paymentStatus: found?.payment?.paymentStatus ? getPaymentStatus(found.payment.paymentStatus) : '-',
    paymentTotal: found.payment.total ? `Rp${toCurrency(found.payment.total)}` : '-',
    paymentRawCallback: found.payment.paymentRawCallback ? found.payment.paymentRawCallback : '-',
    paymentRawResponse: found.payment.paymentRawResponse ? found.payment.paymentRawResponse : '-',
    paymentDate: found.payment.createdDate
      ? `${toMoment(found.payment.createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)} ${getTimeSymbol(found.dealer.timeZone)}`
      : '-',
    paymentDueDate: found.payment.validUntil
      ? `${toMoment(found.payment.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE)} ${getTimeSymbol(found.dealer.timeZone)}`
      : '-',
    paymentMethod: found.payment.paymentMethod ? transformPaymentMethod(found.payment.paymentMethod) : '-',
    paymentVaNumber: found.payment.vaNumber ? found.payment.vaNumber : '-',
    paymentUrl: found.payment.paymentUrl ? found.payment.paymentUrl : '-',
    items:
            found.items?.length > 0 && !downloadingDeleting && !sendingPdf
              ? found?.items.sort((a, b) => sortAsc(a.orderId, b.orderId))
              .map((el) => ({
                ...el,
                pickupTime: el?.pickupTime
                  ? `${toMoment(el?.pickupTime)
                    .format(DATE_TIME_FORMAT_WITHOUT_PIPE)} ${getTimeSymbol(found.dealer.timeZone)}`
                  : '-',
                unitPrice: el?.unitPrice ? `Rp${toCurrency(el.unitPrice)}` : '-',
                tax: el?.tax ? `Rp${toCurrency(el.tax)}` : '-',
                subTotalWithTax: el?.subTotalWithTax ? `Rp${toCurrency(el.subTotalWithTax)}` : '-',
                notes: el?.notes ? el?.notes : '-',
                accessory: {
                  ...el.accessory,
                  price: el?.accessory?.price ? `Rp${toCurrency(el.accessory.price)}` : '-',
                  image: el?.accessory?.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${el.accessory.image}` : '-',
                },
                pickupLocation: {
                  ...el.pickupLocation,
                  hasChargingStation: el?.pickupLocation?.hasChargingStation === true
                    ? GlobalLocalizedString.common.labelYes
                    : GlobalLocalizedString.common.labelNo,
                  openTime: el?.pickupLocation?.openTime
                    ? `${el.pickupLocation.openTime} ${getTimeSymbol(found.dealer.timeZone)}` : '-',
                  closeTime: el?.pickupLocation?.closeTime
                    ? `${el.pickupLocation.closeTime} ${getTimeSymbol(found.dealer.timeZone)}` : '-',
                  longitude: el?.pickupLocation?.longitude ? el.pickupLocation.longitude.toString() : '-',
                  latitude: el?.pickupLocation?.latitude ? el.pickupLocation.latitude.toString() : '-',
                },
                quantity: el?.quantity ? el.quantity.toString() : '-',
              }))
              : [],
    itemsTransaction: found.items.length > 0
      ? found.items.sort((a, b) => sortAsc(a.orderId, b.orderId)).map((x) => (
        x.items
      )) : [],
    dealer: found?.dealer ? found.dealer : {},
    transactionId: found?.transactionId ? found?.transactionId : '-',
    transactionStatus: transformTransactionStatus(found.transactionStatus),
  }) : {};
  return result;
};

const searchDealerDebounce = debounceSearch((dispatch) => {
  dispatch(
    setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'),
  );
  dispatch(clearDealers());
  dispatch(downloadDealersAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});

const mapStateToProps = (state) => ({
  downloading: state.uiInvoice.downloading,
  saving: state.uiInvoice.saving,
  downloadingDeleting: state.uiInvoice.downloadingDeleting,
  addingEditing: state.uiInvoice.addingEditing,
  sendingPdf: state.uiInvoice.sendingPdf,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  dealers: transformDropdownData(state.dealers.data),
  loadingDealers: state.uiDealer.downloading,
  loadingUsers: state.uiUser.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setDealerSearchText(''));
    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GENUINE_ACCESSORY_INVOICE));
    dispatch(setInvoiceSearchText(''));
    dispatch(clearInvoices());
    dispatch(setInvoiceSelectedPageSize(20));
    dispatch(setInvoiceSelectedOrderBy(INITIAL_ORDER_BY_INVOICES));
    dispatch(downloadInvoicesAycnc(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/users/, 'user.id')
      .replace(/dealer/, 'dealer.id')
      .replace(/paymentMethod/, 'payment.paymentMethod')
      .replace(/paymentStatus/, 'payment.paymentStatus')
      .replaceAll('paymentDate', 'payment.createdDate')
      .replace('transactionStatus=', 'transactionStatus><');

    dispatch(setInvoicesFilterString(text));
    dispatch(clearInvoices());
    dispatch(downloadInvoicesAycnc(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadInvoicesAycnc(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setInvoiceSelectedPageSize(pageSize));
    dispatch(downloadInvoicesAycnc(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDealerText: async (text) => {
    dispatch(setDealerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDealerDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setInvoicesFilterString(''));
    dispatch(setInvoiceSelectedPageSize(pageSize));
    dispatch(clearInvoices());
    dispatch(downloadInvoicesAycnc(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setInvoicesFilterString(''));
    dispatch(clearInvoices());
    dispatch(setInvoiceSelectedOrderBy(INITIAL_ORDER_BY_INVOICES));
    dispatch(downloadInvoicesAycnc(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setInvoiceSearchText(text));
    dispatch(clearInvoices());
    dispatch(downloadInvoicesAycnc(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setInvoiceSelectedOrderBy(orderBy));
    dispatch(clearInvoices());
    dispatch(downloadInvoicesAycnc())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setInvoiceTappedId(id));
    dispatch(downloadInvoiceAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_INVOICE));
  },
  onOpenGoogleMaps: (lat, long) => {
    const url = REST_URL_GOOGLE_MAP.replace(/\{long\}/, long).replace(/\{lat\}/, lat);
    window.open(url);
  },
  onDownloadPdf: () => {
    dispatch(saveInvoiceAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onDownloadCsvPressed: () => {
    const saveAsCsv = true;
    dispatch(saveInvoiceAsync(saveAsCsv))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage);
