import { downloadBookingCreatedByServiceInterval } from '../../../helper';
import { downloadingBookingCreatedByServiceInterval, setBookingCreatedByServiceInterval } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingBookingCreatedByServiceInterval(true));
    const bookingCreatedByServiceInterval = await downloadBookingCreatedByServiceInterval(token,
      startDate, endDate);

    dispatch(setBookingCreatedByServiceInterval(bookingCreatedByServiceInterval));
  } finally {
    dispatch(downloadingBookingCreatedByServiceInterval(false));
  }
};
