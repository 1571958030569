import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_GIIAS_SCANNED_CODES = '/giias2022/api/v1/ScannedCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_GIIAS_VIEW_DELETE_SCANNED_CODE = '/giias2022/api/v1/ScannedCode/{id}';
export const REST_URL_GIIAS_2022_PRIZE_REDEMPTIONS = '/giias2022/api/v1/PrizeRedemption?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_GIIAS_2022_PRIZE_REDEMPTION = '/giias2022/api/v1/PrizeRedemption/{id}';
export const REST_URL_GIIAS_2022_TRANSACTIONS = '/giias2022/api/v1/Transaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_GIIAS_2022_TRANSACTION = '/giias2022/api/v1/Transaction/{id}';

export const ROUTE_NAME_GIIAS_2022 = '/giias-2022';
export const ROUTE_NAME_GIIAS_SCANNED_CODE = '/giias-scanned-code';
export const ROUTE_NAME_GIIAS_2022_PRIZE_REDEMPTION = '/giias-2022-prize-redemption';
export const ROUTE_NAME_GIIAS_2022_TTRANSACTION = '/giias-2022-trasnsaction';

export const MENUID_GIIAS_2022 = 'MENUID_GIIAS_2022';
export const MENUID_GIIAS_SCANNED_CODE = 'MENUID_GIIAS_SCANNED_CODE';
export const MENUID_GIIAS_2022_PRIZE_REDEMPTION = 'MENUID_GIIAS_2022_PRIZE_REDEMPTION';
export const MENUID_GIIAS_2022_TRANSACTION = 'MENUID_GIIAS_2022_TRANSACTION';

export const RXSTATE_SCANNED_CODES = 'giiasScannedCodes';
export const RXSTATE_SCANNED_CODES_PAGE = 'uiGiiasScannedCode';
export const RXSTATE_GIIAS_2022_PRIZE_REDEMPTIONS = 'giias2022PrizeRedemptions';
export const RXSTATE_GIIAS_2022_PRIZE_REDEMPTION = 'uiGiias2022PrizeRedemption';
export const RXSTATE_GIIAS_2022_PRIZE_TRANSACTIONS = 'giias2022Transactions';
export const RXSTATE_GIIAS_2022_PRIZE_TRANSACTION = 'uiGiias2022Transaction';

export const INITIAL_ORDER_BY_GIIAS_SCANNED_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_GIIAS_2022_PRIZE_REDEMPTION = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_GIIAS_2022_TRANSACTION = `createdDate ${ORDER_SORT_DESCENDING}`;

export const RXFIELD_GIIAS_SCAN_CODE_TIME = 'createdDate';
export const RXFIELD_GIIAS_SCAN_CODE_USERNAME = 'scanCodeUsername';

export const RXFORM_GIIAS_SCANNED_CODE = 'scanedCodePage';
export const RXFORM_GIIAS_2022_PRIZE_REDEMPTION = 'giias2022PrizeRedemptionPage';
export const RXFORM_GIIAS_2022_TRANSACTION = 'giias2022TransactionPage';

export const RXFIELD_GIIAS_CHALLANGE_CODE_LABEL = 'challengeCodeLabel';
export const RXFIELD_GIIAS_CHALLANGE_USER_NAME = 'user.fullName';
export const RXFIELD_GIIAS_CHALLANGE_USER_ID = 'user.id';
export const RXFIELD_GIIAS_CHALLANGE_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_DESCRIPTION = 'description';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_POINT = 'point';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_PRIZE_TAG = 'prizeTag';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER = 'user';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER_FULLNAME = 'fullName';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_USER_ID = 'userId';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER = 'voucher';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER_ID = 'voucherId';
export const RXFIELD_GIIAS_2022_PRIZE_REDEMPTION_VOUCHER_TITLE = 'voucherTitle';
export const RXFIELD_GIIAS_2022_TRANSACTION_AMOUNT = 'amount';
export const RXFIELD_GIIAS_2022_TRANSACTION_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_2022_TRANSACTION_LABEL = 'label';
export const RXFIELD_GIIAS_2022_TRANSACTION_REFERENCE_ID = 'referenceId';
export const RXFIELD_GIIAS_2022_TRANSACTION_REMAINING_BALANCE = 'remainingBalance';
export const RXFIELD_GIIAS_2022_TRANSACTION_TYPE = 'type';
export const RXFIELD_GIIAS_2022_TRANSACTION_USER = 'user';
export const RXFIELD_GIIAS_2022_TRANSACTION_USER_FULLNAME = 'fullName';
export const RXFIELD_GIIAS_2022_TRANSACTION_USER_ID = 'userId';
