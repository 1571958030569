import {
  addRegistrationAttempt, downloadingDeletingRegistrationAttempt, setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';
import { downloadRegistrationAttempt } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingRegistrationAttempt(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistrationAttempt;

    const registrationAttempt = await downloadRegistrationAttempt(tappedId, token);
    dispatch(addRegistrationAttempt(registrationAttempt));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingRegistrationAttempt(false));
  }
};
