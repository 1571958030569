import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  INITIAL_ORDER_BY_GIIAS_SURABAYA_PRIZE_REDEMPTION,
  MENUID_GIIAS_SURABAYA_PRIZE_REDEMPTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import GiiasSurabayaPrizeRedemptionPage from './giias-surabaya-prize-redemption.presentation';
import {
  clearVouchers, setVoucherAdvancedFilterDialogSelectedFilterString, setVoucherSearchText,
  downloadVouchersAsync,
} from '../../../voucher';
import {
  clearGiiasSurabayaPrizeRedemptions, setGiiasSurabayaPrizeRedemptionAdvancedFilterString,
  setGiiasSurabayaPrizeRedemptionSearchText, setGiiasSurabayaPrizeRedemptionSelectedOrderBy,
  setGiiasSurabayaPrizeRedemptionSelectedPageSize, setGiiasSurabayaPrizeRedemptionTappedId,
  downloadGiiasSurabayaPrizeRedemptionAsync, downloadGiiasSurabayaPrizeRedemptionsAsync,
  saveGiiasSurabayaPrizeRedemptionsAsync,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { giiasSurabayaPrizeRedemptions, uiFunctionalPage, uiGiiasSurabayaPrizeRedemption } = state;
  const { data } = giiasSurabayaPrizeRedemptions;
  const { downloadingDeleting, tappedId } = uiGiiasSurabayaPrizeRedemption;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiGiiasSurabayaPrizeRedemption.downloading,
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingVoucher: state.uiVoucher.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_SURABAYA_PRIZE_REDEMPTION));
    dispatch(setGiiasSurabayaPrizeRedemptionAdvancedFilterString(''));
    dispatch(setGiiasSurabayaPrizeRedemptionSearchText(''));
    dispatch(clearGiiasSurabayaPrizeRedemptions());
    dispatch(setGiiasSurabayaPrizeRedemptionSelectedPageSize(20));
    dispatch(setGiiasSurabayaPrizeRedemptionSelectedOrderBy(
      INITIAL_ORDER_BY_GIIAS_SURABAYA_PRIZE_REDEMPTION,
    ));
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiiasSurabayaPrizeRedemptionSelectedOrderBy(orderBy));
    dispatch(clearGiiasSurabayaPrizeRedemptions());
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiiasSurabayaPrizeRedemptionTappedId(id));
    dispatch(downloadGiiasSurabayaPrizeRedemptionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setGiiasSurabayaPrizeRedemptionSearchText(text));
    dispatch(clearGiiasSurabayaPrizeRedemptions());
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiiasSurabayaPrizeRedemptionSelectedPageSize(pageSize));
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiiasSurabayaPrizeRedemptionSelectedPageSize(pageSize));
    dispatch(clearGiiasSurabayaPrizeRedemptions());
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGiiasSurabayaPrizeRedemptions());
    dispatch(setGiiasSurabayaPrizeRedemptionAdvancedFilterString(''));
    dispatch(setGiiasSurabayaPrizeRedemptionSelectedOrderBy(
      INITIAL_ORDER_BY_GIIAS_SURABAYA_PRIZE_REDEMPTION,
    ));
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id')
      .replace('voucher', 'voucherId');

    dispatch(setGiiasSurabayaPrizeRedemptionAdvancedFilterString(text));
    dispatch(clearGiiasSurabayaPrizeRedemptions());
    dispatch(downloadGiiasSurabayaPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveGiiasSurabayaPrizeRedemptionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GiiasSurabayaPrizeRedemptionPage);
