import { connect } from 'react-redux';
import {
  clearFunctionalPageAdvancedFilterForm, setAdvancedFilterDialogSelectedFilterString,
  setAdvancedFilterDialogVisibility, setDialogVisibility, setFunctionalPageMode,
  setFunctionalPageTappedData,
  setFunctionalPageTappedId, setNotificationDialogVisibility, setTotalPersonCount,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, DIALOG_TYPE_ADD, PAGE_MODE_EDIT, PAGE_MODE_TABLE, PAGE_MODE_VIEW,
} from '../../constant';
import { toMoment } from '../../helper';
import FunctionalPage from './functional-page.presentation';

const getData = (pageState, pageUiState) => {
  const { data, meta } = pageState;
  const { selectedPageSize } = pageUiState;

  return Object.values(data).map((item, i) => ({
    ...item,
    no: i + 1 + ((meta.currentPage - 1) * selectedPageSize),
    createdDate: toMoment(item.createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
    lastModifiedDate: toMoment(item.lastModifiedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
  }));
};

const getPermission = (state, props) => (!!(state.currentUser.permissions[props]));

const mapStateToProps = (state, ownProps) => {
  const pageState = state[ownProps.data];
  const pageUiState = state[ownProps.uiPage];

  return ({
    data: getData(pageState, pageUiState),
    downloading: pageUiState.downloading,
    currentPage: pageState.meta.currentPage,
    totalCount: pageState.meta.totalCount,
    selectedPageSize: pageUiState.selectedPageSize,
    searchBarText: pageUiState.searchBarText,
    orderBy: pageUiState.orderBy,
    addingEditing: pageUiState.addingEditing,
    downloadingDeleting: pageUiState.addingEditing,
    saving: pageUiState.saving,
    sendingNotification: pageUiState.sendingNotification,
    cropping: state.cropImage.cropping,
    drawerVisibility: state.uiBasePage.visibility,
    pageMode: state.uiFunctionalPage.pageMode,
    tappedId: state.uiFunctionalPage.tappedId,
    tappedData: state.uiFunctionalPage.tappedData,
    filterString: state.uiFunctionalPage.filterString || '',
    createPermission: getPermission(state, ownProps.createPermissionName),
    deletePermission: getPermission(state, ownProps.deletePermissionName),
    editPermission: getPermission(state, ownProps.editPermissionName),
    sendNotificationPermission: getPermission(state, ownProps.sendNotificationPermissionName),
  });
};

const mapDispatchToProps = (dispatch) => ({
  onCreateNewPressed: (title) => {
    dispatch(setDialogVisibility(true, DIALOG_TYPE_ADD, title));
  },
  onFunctionalPageAdvancedFilterPressed: () => {
    dispatch(setAdvancedFilterDialogVisibility(true));
  },
  onFunctionalPageAppear: () => {
    dispatch(setDialogVisibility(false, DIALOG_TYPE_ADD, ''));
    dispatch(setAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearFunctionalPageAdvancedFilterForm());
  },
  onFunctionalPageBackPressed: () => {
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onFunctionalPageEditPressed: () => {
    dispatch(setFunctionalPageMode(PAGE_MODE_EDIT));
  },
  onFunctionalPageViewPressed: (id) => {
    dispatch(setFunctionalPageTappedId(id));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  },
  onMorePressed: (id, data) => {
    dispatch(setFunctionalPageTappedId(id));
    dispatch(setFunctionalPageTappedData(data));
  },
  onSendNotificationPressed: () => {
    dispatch(setNotificationDialogVisibility(true));
    dispatch(setTotalPersonCount(0));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalPage);
