import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { Field } from 'redux-form';
import {
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_NAME,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_VARIANT_CODE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_PRICE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DESCRIPTION,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_IMAGE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_ORDER_ID,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_PICKUP_TIME,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_QUANTITY,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_UNIT_PRICE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_TAX,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_GRAND_TOTAL,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_NOTES,
} from '../../constant';

import LocalizedString from '../../localization';
import {
  renderReduxFormImageInputField,
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { AccessoryTransactionShape } from '../../type';

const TransactionAccessorySection = ({ item }) => (
  <Grid>
    <Grid item sm md>
      <Typography variant="subtitle2" style={{ marginLeft: 10, marginBottom: 10, fontWeight: 'bold' }}>
        {LocalizedString.accessoryTransactionPage.labelAccessory}
      </Typography>
    </Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_VARIANT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                      LocalizedString.accessoryTransactionPage.labelAccessoryItemVariantCode
                    }
            label={LocalizedString.accessoryTransactionPage.labelAccessoryItemVariantCode}
            defaultValue={item.accessory.variantCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.labelItemName}
            label={LocalizedString.accessoryTransactionPage.labelItemName}
            defaultValue={item.accessory.name}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_PRICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelAccessoryItemVariantPrice
                      }
            label={
                        LocalizedString.accessoryTransactionPage.labelAccessoryItemVariantPrice
                      }
            defaultValue={item.accessory.price}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage
                          .labelAccessoryItemVariantDescription
                      }
            label={
                        LocalizedString.accessoryTransactionPage
                          .labelAccessoryItemVariantDescription
                      }
            defaultValue={item.accessory.description}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_IMAGE}
            component={renderReduxFormImageInputField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelAccessoryItemVariantImage
                      }
            label={
                        LocalizedString.accessoryTransactionPage.labelAccessoryItemVariantImage
                      }
            defaultValue={item.accessory.image}
            fullImageWidth="100%"
            useFullWidthImage
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_ORDER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelAcccessoryItemOrderId
                      }
            label={LocalizedString.accessoryTransactionPage.labelAcccessoryItemOrderId}
            defaultValue={item.orderId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_PICKUP_TIME}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelAcccessoryItemPickupTime
                      }
            label={LocalizedString.accessoryTransactionPage.labelAcccessoryItemPickupTime}
            defaultValue={item.pickupTime}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_QUANTITY}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelAcccessoryItemQuantity
                      }
            label={LocalizedString.accessoryTransactionPage.labelAcccessoryItemQuantity}
            defaultValue={item.quantity}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_UNIT_PRICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelAcccessoryItemUnitPrice
                      }
            label={LocalizedString.accessoryTransactionPage.labelAcccessoryItemUnitPrice}
            defaultValue={item.unitPrice}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_TAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.labelAcccessoryItemTax}
            label={LocalizedString.accessoryTransactionPage.labelAcccessoryItemTax}
            defaultValue={item.tax}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_GRAND_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderGrandTotal}
            label={LocalizedString.accessoryTransactionPage.placeholderGrandTotal}
            defaultValue={item.subTotalWithTax}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelAcccessoryItemNotes
                      }
            label={LocalizedString.accessoryTransactionPage.labelAcccessoryItemNotes}
            defaultValue={item.notes}
            disabled
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default TransactionAccessorySection;

TransactionAccessorySection.propTypes = {
  item: AccessoryTransactionShape.isRequired,
};
