import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_GIIAS_MEDAN_SCANNED_CODES, MENUID_GIIAS_MEDAN_SCANNED_CODE, PAGE_MODE_TABLE } from '../../constant';
import { transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  clearGiiasMedanScannedCodes, downloadDeleteGiiasMedanScannedCodeAsync,
  downloadGiiasMedanScannedCodesAsync, saveGiiasMedan2022ScannedCodesAsync,
  setAlertErrorMessage, setGiiasMedanScannedCodesOrderBy, setGiiasMedanScannedCodesPageSize,
  setGiiasMedanScannedCodesSearchText, setGiiasMedanScannedCodesFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setGiiasMedanScannedCodesTappedId, setActiveSideMenuItem,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import GiiasMedanScannedCode from './giias-medan-scanned-code.presentation';

const getInitialValues = (state) => {
  const {
    giiasMedanScannedCodes, uiFunctionalPage, uiGiiasMedanScannedCode,
  } = state;
  const { data } = giiasMedanScannedCodes;
  const { downloadingScannedCode, tappedId } = uiGiiasMedanScannedCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingScannedCode
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    user: found.user
      ? { fullName: found.user.fullName, id: found.user.id }
      : { fullName: '', id: '' },
  };

  return result;
};

const mapStateToProps = (state) => ({
  scannedCodes: state.giiasMedanScannedCodes.data,
  downloading: state.uiGiiasMedanScannedCode.downloading,
  downloadingScannedCode: state.uiGiiasMedanScannedCode.downloadingScannedCode,
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_MEDAN_SCANNED_CODE));
    dispatch(setGiiasMedanScannedCodesSearchText(''));
    dispatch(setGiiasMedanScannedCodesFilterString(''));
    dispatch(clearGiiasMedanScannedCodes());
    dispatch(setGiiasMedanScannedCodesPageSize(20));
    dispatch(setGiiasMedanScannedCodesOrderBy(INITIAL_ORDER_BY_GIIAS_MEDAN_SCANNED_CODES));

    dispatch(downloadGiiasMedanScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiiasMedanScannedCodesPageSize(pageSize));
    dispatch(clearGiiasMedanScannedCodes());

    dispatch(downloadGiiasMedanScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiiasMedanScannedCodesOrderBy(orderBy));
    dispatch(clearGiiasMedanScannedCodes());
    dispatch(downloadGiiasMedanScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiiasMedanScannedCodesAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiiasMedanScannedCodesPageSize(pageSize));
    dispatch(downloadGiiasMedanScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setGiiasMedanScannedCodesSearchText(text));
      dispatch(clearGiiasMedanScannedCodes());
      await dispatch(downloadGiiasMedanScannedCodesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/scanCodeUsername/, 'user.id');

    dispatch(setGiiasMedanScannedCodesFilterString(text));
    dispatch(clearGiiasMedanScannedCodes());
    dispatch(downloadGiiasMedanScannedCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setGiiasMedanScannedCodesFilterString(''));
    dispatch(clearGiiasMedanScannedCodes());
    dispatch(setGiiasMedanScannedCodesOrderBy(INITIAL_ORDER_BY_GIIAS_MEDAN_SCANNED_CODES));
    dispatch(downloadGiiasMedanScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiiasMedanScannedCodesTappedId(id));
    dispatch(downloadDeleteGiiasMedanScannedCodeAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDownloadPressed: () => {
    dispatch(saveGiiasMedan2022ScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GiiasMedanScannedCode);
