import { downloadVoucherToken } from '../../../helper';
import {
  addVoucherToken, downloadingVoucherToken,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingVoucherToken(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPartnerVoucherToken;

    const tokenRequest = await downloadVoucherToken(tappedId, token);

    dispatch(addVoucherToken(tokenRequest));
  } finally {
    dispatch(downloadingVoucherToken(false));
  }
};
