import { downloadingTestDriveEventOrganizer, setTestDriveEventOrganizers } from '../simple-action';
import { downloadTestDriveEventOrganizers, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, eventOrganizers, uiEventOrganizer } = getState();
  const { token } = authentication;
  const { meta } = eventOrganizers;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiEventOrganizer;

  try {
    dispatch(downloadingTestDriveEventOrganizer(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadTestDriveEventOrganizers(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setTestDriveEventOrganizers(list));
    }
  } finally {
    dispatch(downloadingTestDriveEventOrganizer(false));
  }
};
