import {
  DOWNLOADING_NEWS, ADDING_EDITING_NEWS, DOWNLOADING_DELETING_NEWS,
  SET_NEWS_SELECTED_PAGE_SIZE, SET_NEWS_TAPPED_ID, SET_NEWS_SEARCH_TEXT,
  SET_NEWS_SELECTED_ORDER_BY, SET_NEWS_SELECTED_CATEGORY, SET_NEWS_SELECTED_VISIBILITY_STATUS,
  SET_NEWS_SELECTED_ADD_MEDIA_MENU, SENDING_NEWS_NOTIFICATION, SET_NEWS_SHORT_DESCRIPTION_LENGTH,
  SET_NEWS_SELECTED_IS_HEADLINE, COPYING_NEWS_DEEP_LINK,
} from '../action';
import { INITIAL_ORDER_BY_NEWS } from '../../constant';

const initialState = {
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  sendingNotification: false,
  selectedPageSize: 20,
  shortDescriptionLength: 0,
  tappedId: '',
  selectedCategory: '',
  selectedVisibilityStatus: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_NEWS,
  selectedAddMediaMenu: null,
  selectedIsHeadline: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_NEWS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_NEWS:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_NEWS:
      return { ...state, downloadingDeleting: action.status };
    case SET_NEWS_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_NEWS_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_NEWS_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_NEWS_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_NEWS_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.option };
    case SET_NEWS_SELECTED_VISIBILITY_STATUS:
      return { ...state, selectedVisibilityStatus: action.option };
    case SET_NEWS_SELECTED_ADD_MEDIA_MENU:
      return { ...state, selectedAddMediaMenu: action.text };
    case SENDING_NEWS_NOTIFICATION:
      return { ...state, sendingNotification: action.status };
    case SET_NEWS_SHORT_DESCRIPTION_LENGTH:
      return { ...state, shortDescriptionLength: action.number };
    case SET_NEWS_SELECTED_IS_HEADLINE:
      return { ...state, selectedIsHeadline: action.option };
    case COPYING_NEWS_DEEP_LINK:
      return { ...state, copying: action.status };
    default: return state;
  }
};
