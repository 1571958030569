import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import LocalizedString from '../../localization';
import {
  RXFORM_VOUCHER_CLAIM, COLOR_SECONDARY,
  RXFIELD_VOUCHER_CLAIM_VOUCHER_CODE, COLOR_PRIMARY,
  DATE_TIME_FULL_FORMAT_WITHOUT_PIPE, RXFIELD_VOUCHER_CLAIM_CLAIM_NOTES,
  COLOR_TEXT_ON_PRIMARY, DATE_FORMAT,
} from '../../constant';
import { BasePage, AccentButton, VerticalSpacer } from '../../component';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { VoucherTokenShape } from '../../type';
import { toMoment } from '../../helper';
import { rxFormValidateClaimVoucher } from '../../validation';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  bodyContainer: {
    padding: '13px 35px 13px 35px',
    borderRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    justifyContent: 'space-between',
    marginBottom: 80,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    background: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    height: 40,
    minWidth: 100,
  },
  fieldContainer: {
    maxWidth: 400,
  },
  fieldName: {
    fontWeight: 'bold',
  },
  img: {
    objectFit: 'cover',
    width: 420,
    height: 420,
  },
  noteFieldContainer: {
    marginLeft: -20,
  },
}));

const getValidDate = (voucherToken) => {
  const validFrom = toMoment(voucherToken.validFrom).format(DATE_FORMAT);
  const validUntil = toMoment(voucherToken.validUntil).format(DATE_FORMAT);
  return `${validFrom} - ${validUntil}`;
};

const renderClaimedSection = (voucherToken) => voucherToken.isClaimed && (
  <div>
    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelIsClaimed}`}
        </Typography>
      </Grid>

      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${voucherToken.isClaimed}`}
        </Typography>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelClaimedBy}`}
        </Typography>
      </Grid>

      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${voucherToken.claimedBy.fullName}`}
        </Typography>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelClaimedOn}`}
        </Typography>
      </Grid>

      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${toMoment(voucherToken.claimedOn).format(DATE_TIME_FULL_FORMAT_WITHOUT_PIPE)}`}
        </Typography>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelClaimNotes}`}
        </Typography>
      </Grid>

      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${voucherToken.claimNotes}`}
        </Typography>
      </Grid>
    </Grid>
  </div>
);

const renderRevokedSection = (voucherToken) => voucherToken.isRevoked && (
<div>
  <Grid container>
    <Grid item sm={2} md={2}>
      <Typography variant="body1">
        {`${LocalizedString.voucherClaimPage.labelIsRevoked}`}
      </Typography>
    </Grid>
    <Grid item sm={10} md={10}>
      <Typography variant="body1">
        {`: ${voucherToken.isRevoked}`}
      </Typography>
    </Grid>

  </Grid>
  <Grid container>
    <Grid item sm={2} md={2}>
      <Typography variant="body1">
        {`${LocalizedString.voucherClaimPage.labelRevokedBy}`}
      </Typography>
    </Grid>
    <Grid item sm={10} md={10}>
      <Typography variant="body1">
        {`: ${voucherToken.claimedBy.fullName}`}
      </Typography>
    </Grid>
  </Grid>
  <Grid container>
    <Grid item sm={2} md={2}>
      <Typography variant="body1">
        {`${LocalizedString.voucherClaimPage.labelRevokedOn}`}
      </Typography>
    </Grid>
    <Grid item sm={10} md={10}>
      <Typography variant="body1">
        {`: ${toMoment(voucherToken.claimedOn).format(DATE_TIME_FULL_FORMAT_WITHOUT_PIPE)}`}
      </Typography>
    </Grid>
  </Grid>
  <Grid container>
    <Grid item sm={2} md={2}>
      <Typography variant="body1">
        {`${LocalizedString.voucherClaimPage.labelRevocationNotes}`}
      </Typography>
    </Grid>
    <Grid item sm={10} md={10}>
      <Typography variant="body1">
        {`: ${voucherToken.revocationNotes}`}
      </Typography>
    </Grid>
  </Grid>
</div>
);

const renderValues = (
  voucherToken, voucherCode, classes,
) => !isEmpty(voucherToken) && voucherToken.code.toLowerCase() === voucherCode.toLowerCase() && (
  <div>
    <Grid container spacing={3}>
      <Grid item sm={6} md={6}>
        <img alt="" src={voucherToken.voucher.image} className={classes.img} />
      </Grid>
      <Grid item sm={6} md={6} />
    </Grid>
    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelVoucherName}`}
        </Typography>
      </Grid>
      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${voucherToken.voucher.title}`}
        </Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelMerchant}`}
        </Typography>
      </Grid>
      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${voucherToken.voucher.merchant.name}`}
        </Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelValidDate}`}
        </Typography>
      </Grid>
      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${getValidDate(voucherToken)}`}
        </Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelCustomer}`}
        </Typography>
      </Grid>
      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${voucherToken.purchasedBy?.fullName || ''}`}
        </Typography>
      </Grid>

    </Grid>
    <Grid container>
      <Grid item sm={2} md={2}>
        <Typography variant="body1">
          {`${LocalizedString.voucherClaimPage.labelRedeemedOn}`}
        </Typography>
      </Grid>
      <Grid item sm={10} md={10}>
        <Typography variant="body1">
          {`: ${voucherToken.purchasedOn ? toMoment(voucherToken.purchasedOn).format(DATE_TIME_FULL_FORMAT_WITHOUT_PIPE) : ''}`}
        </Typography>
      </Grid>

    </Grid>
    {renderClaimedSection(voucherToken)}
    {renderRevokedSection(voucherToken)}
  </div>
);

const renderNotesSection = (
  voucherToken, classes, handleSubmit, onClaimVoucherPressed,
  claiming, voucherCode,
) => !isEmpty(voucherToken) && voucherToken.code.toLowerCase() === voucherCode.toLowerCase() && (
  <div>
    <Typography variant="body1" className={classes.fieldName}>
      {LocalizedString.voucherClaimPage.labelNotes}
    </Typography>
    <Grid container spacing={3} className={classes.noteFieldContainer}>
      <Grid item sm md>
        <Field
          name={RXFIELD_VOUCHER_CLAIM_CLAIM_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherClaimPage.labelNotes}
          label={LocalizedString.voucherClaimPage.labelNotes}
          multiline
          disabled={voucherToken.isClaimed || voucherToken.isRevoked}
          required
        />
      </Grid>
    </Grid>
    <VerticalSpacer height={16} />
    <AccentButton
      caption={LocalizedString.voucherClaimPage.buttonCaptionClaimVoucher}
      loading={claiming}
      disabled={voucherToken.isClaimed || voucherToken.isRevoked || claiming}
      onClick={handleSubmit(onClaimVoucherPressed)}
      className={classes.button}
    />
  </div>
);

const VoucherClaimPage = ({
  onSearchPressed, handleSubmit, voucherToken, downloading,
  onClaimVoucherPressed, claiming, voucherCode,
}) => {
  const classes = useStyles();
  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>{LocalizedString.voucherClaimPage.title}</Typography>
      </div>


      <Paper elevation={3} className={classes.bodyContainer}>
        <Grid container spacing={3} className={classes.topContainer}>
          <Typography variant="body1">
            {LocalizedString.voucherClaimPage.labelVoucherCode}
          </Typography>
          <Grid item sm md className={classes.fieldContainer}>
            <Field
              name={RXFIELD_VOUCHER_CLAIM_VOUCHER_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherClaimPage.labelSearch}
              label={LocalizedString.voucherClaimPage.labelSearch}
            />
          </Grid>
          <AccentButton
            caption={LocalizedString.voucherClaimPage.buttonCaptionSearch}
            loading={downloading}
            disabled={downloading}
            onClick={handleSubmit(onSearchPressed)}
            className={classes.button}
          />
        </Grid>
        <VerticalSpacer height={32} />
        {renderValues(voucherToken, voucherCode, classes)}
        <VerticalSpacer height={32} />
        {renderNotesSection(
          voucherToken, classes, handleSubmit, onClaimVoucherPressed, claiming,
          voucherCode,
        )}
      </Paper>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_VOUCHER_CLAIM,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxFormValidateClaimVoucher,
})(VoucherClaimPage);

VoucherClaimPage.propTypes = {
  voucherToken: VoucherTokenShape,

  voucherCode: PropTypes.string.isRequired,

  downloading: PropTypes.bool.isRequired,
  claiming: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onSearchPressed: PropTypes.func.isRequired,
  onClaimVoucherPressed: PropTypes.func.isRequired,
};
VoucherClaimPage.defaultProps = {
  voucherToken: {},
};
