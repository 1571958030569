import { transformSearchText } from '../../../../../helper';
import { downloadHyundaiChallengeTransactions } from '../../../helper';
import { downloadingHyundaiChallengeTransactions, setHyundaiChallengeTransactions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, hyundaiChallengeTransactions, uiHyundaiChallengeTransaction,
  } = getState();
  const { token } = authentication;
  const { meta } = hyundaiChallengeTransactions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiHyundaiChallengeTransaction;


  try {
    dispatch(downloadingHyundaiChallengeTransactions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadHyundaiChallengeTransactions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setHyundaiChallengeTransactions(list));
    }
  } finally {
    dispatch(downloadingHyundaiChallengeTransactions(false));
  }
};
