import { SET_REMAINING_STOCK_BY_PART_NAME, CLEAR_REMAINING_STOCK_BY_PART_NAME } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_REMAINING_STOCK_BY_PART_NAME: {
      return action.data;
    }
    case CLEAR_REMAINING_STOCK_BY_PART_NAME:
      return state;
    default:
      return state;
  }
};
