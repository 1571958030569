import {
  addCarOwnership, downloadingDeletingCarOwnership, setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';
import { downloadCarOwnership } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCarOwnership(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCarOwnership;

    const carOwnership = await downloadCarOwnership(tappedId, token);

    dispatch(addCarOwnership(carOwnership));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingCarOwnership(false));
  }
};
