import LocalizedStrings from 'react-localization';
import { PASSWORD_MIN_LENGTH } from './constant';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'ERROR',
      alertTitleWarning: 'WARNING',
      alertTitleConfirmation: 'CONFIRMATION',

      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'CANCEL',
      buttonCaptionDetail: 'DETAIL',
      buttonCaptionYes: 'YES',
      buttonCaptionNo: 'NO',
      buttonCaptionSave: 'SAVE',
      buttonCaptionEdit: 'EDIT',
      buttonCaptionSubmit: 'SUBMIT',
      buttonCaptionNext: 'NEXT',
      buttonCaptionPrev: 'PREV',
      buttonCaptionBack: 'BACK',
      buttonCaptionAdd: 'ADD',
      buttonCaptionRemove: 'REMOVE',
      buttonCaptionApply: 'APPLY',
      buttonCaptionView: 'View',
      buttonCaptionSend: 'SEND',
      buttonCaptionSendNotification: 'Send Notification',
      buttonCaptionCount: 'Count',
      buttonCaptionAdvancedFilter: 'Advanced Filter',
      buttonCaptionReset: 'Reset',
      buttonCaptionTakePicture: 'Take Picture',
      buttonCaptionEnable: 'Enable',
      buttonCaptionDisable: 'Disable',
      buttonCaptionResync: 'Resync',

      errMsgEmptyRequiredFields: 'Required fields cannot be empty',
      errMsgInvalidEmailFormat: 'The email address is invalid',
      errMsgInvalidPhoneNumberFormat: 'The phone number format is invalid',
      errMsgMinPasswordLength: `Minimum password length is ${PASSWORD_MIN_LENGTH} characters`,
      errMsgPasswordDoesNotMatch: 'The password does not match',
      errMsgCannotOpenUrl: 'Cannot open the URL',
      errMsgRequired: 'Required',
      errMsgDuplicatedData: 'Duplicated data found. Please select another option',
      errMsgStartEndDate: 'End Date must be greater than or equal to Start Date',
      errMsgRegStartEndDate: 'Registration End Date must be greater than or equal to Registration Start Date',
      errMsgEndRegEndDate: 'Event End Date must be greater than or equal to Registration End Date',
      errMsgInvalidYoutubeUrl: 'Link can only be filled with Youtube URL format',
      errMsgStartEndWorkingHour: 'End Working Hour must be different from Start Working Hour',
      errMsgMinMaxNum: 'Maximal number must be greater than or equal to Minimal number',
      errMsgNoDataYet: 'No Data Yet',
      errMsgNoDataFound: 'No data found',
      errMsgStartEndTime: 'End Time must be greater than or equal to Start Time',
      errMsgMinMaxValue: 'Max Value must be greater than or equal to Min Value',
      errMsgMustaNumber: 'Field must be a number',
      errMsgEarliestClockInTime: 'Earliest Clock In must be lesser than or equal to Start Working Hour',
      errMsgAutoClockOut: 'Auto Clock Out must be greater than or equal to End Working Hour',
      errMsgFileUploadedTooLarge: 'The file uploaded was too large',
      errMsgResourceNotFound: 'The resource was not found',
      errMsgResourceNotAvailable: 'The resource was not available',
      errMsgTimeoutContactingService: 'Timeout while contacting service',
      errMsgFailedActions: '{number} of {total} records were failed',
      errMsgDifferentRetypePassword: 'Password Doesn\'t Match',

      labelWelcome: 'Welcome',
      labelAuthentication: 'Authentication',
      labelPersonalization: 'Personalization',
      labelMasterData: 'Master Data',
      labelReport: 'Report',
      labelHi: 'Hi, {name}',
      labelRefreshData: 'Refresh Data',
      labelEdit: 'Edit',
      labelOnGoing: 'Mark as Ongoing',
      labelCompleted: 'Mark as Completed',
      labelCancelled: 'Mark as Cancelled',
      labelDelete: 'Delete',
      labelBasicInformation: 'Basic Information',
      labelChoices: 'Choices',
      labelChosen: 'Chosen',
      labelSelected: 'Selected',
      labelYes: 'Yes',
      labelNo: 'No',
      labelTrue: 'True',
      labelFalse: 'False',
      labelLegend: 'Legend',
      labelCancel: 'Cancel',
      labelFilter: 'Filter',
      labelSearch: 'Search',
      labelPerson: 'Person',
      labelCity: 'City',
      labelCompanyName: 'Company Name',
      labelSendNotification: 'Send Notification',
      labelSelectedOrAllUser: 'Selected or All User',
      labelNewsManagement: 'News',
      labelAdvancedFilter: 'Advanced Filter',
      labelMin: 'Min',
      labelMax: 'Max',
      labelCharacter: 'Character(s)',
      labelCameraSource: 'Camera Source',
      labelYouCanUsePlaceholdersFieldsAbove: '* You can use these placeholders in the fields above',
      labelAdditionalUrlPlaceholders: '{fullName}: Full name of the recipient<br/>{profileId}: The ID of the recipient',
      labelComments: 'Comments',
      labelViewCommentReply: 'View {replyCount} replies',
      labelHideCommentReply: 'Hide {replyCount} replies',
      labelMoreComments: 'More comments',
      labelMoreReplies: 'More replies',
      labelNoComments: 'No Comments',
      labelReason: 'Reason',
      labelShellMembership: 'Shell Membership',
      labelUserTier: 'User Tier',
      labelRemoveAllItems: 'Remove all selected items',
      labelSettings: 'Settings',
      labelLogout: 'Logout',
      labelChangePassword: 'Change Password',
      labelLogoutDevices: 'Logout from other devices',
      labelNewPasswordSaved: 'New password successfully saved',
      labelSucces: 'Success',
      labelWaitingForPayment: 'Waiting for Payment',
      labelPaid: 'Paid',
      labelCancelledAndError: 'Cancelled',
      labelComplete: 'Complete',

      placeholderId: 'ID',
      placeholderCreatedBy: 'Created By',
      placeholderCreatedDate: 'Created Date',
      placeholderLastModifiedBy: 'Last Modified By',
      placeholderLastModifiedDate: 'Last Modified Date',
      placeholderStatus: 'Status',
      placeholderTitle: 'Title',
      placeholderBody: 'Body',
      placeholderUrl: 'URL',
      placeholderSendTo: 'Send to',
      placeholderReason: 'Reason',
      placeholderSearchCompany: 'Search Company',
      placeholderSearchCity: 'Search City',
      placeholderType: 'Type',
      placeholderOldPassword: 'Old Password',
      placeholderNewPassword: 'New Password',
      placeholderRetypeNewPassword: 'Retype New Password',

      msgLogoutConfirmation: 'Are you sure want to log out?',
      msgDeleteConfirmation: 'Are you sure want to delete this item?',
      msgSomethingWentWrong: 'Something went wrong',
      msgCancelConfirmation: 'Are you sure want to cancel this item?',
      msgDisableConfirmation: 'Are you sure want to disable this item?',
      msgEnableConfirmation: 'Are you sure want to enable this item?',
      msgResyncConfirmation: 'Are you sure want to resync this item?',
      msgDeleteAllConfirmation: 'Are you sure want to delete all selected items?',

      alertMessageUnsavedChanges: 'You have unsaved changes!',
      alertMessageSaveChanges: 'Please save/reset your changes first',
    },
    loginScreen: {
      title: 'Sign In',

      buttonCaptionLogin: 'Sign In',

      labelAdminDashboard: 'Admin Dashboard',
      labelUsername: 'Username',
      labelPassword: 'Password',

      placeholderUsername: 'ex: yourname@company.com',

      errEmptyUsernamePassword: 'Email and password cannot be empty',
      errMsgInvalidSignIn: 'Email can\'t be found',
      errMsgNoPermissionToLogin: 'You don\'t have permission to login',
    },
    welcomeScreen: {
      title: 'Welcome',
    },
    profileScreen: {
      title: 'Profile',

      labelNo: 'No',
      labelFullName: 'Full Name',
      labelEmail: 'Email',
      labelCreatedDate: 'Created Date',
      labelCompanyInformation: 'Company Information',
      labelAdditionalInformation: 'Additional Information',
      labelSendNotificationTo: 'You are going to send notification to user',
      labelWorkingHour: 'Custom Working Hour',
      labelCompany: 'Company',
      labelBranch: 'Branch',
      labelStatus: 'Status',
      labelAddress: 'Address',
      labelHomeAddress: 'Home Address',
      labelWorkAddress: 'Work Address',
      labelPhone: 'Phone',
      labelTags: 'Tags',
      labelUserCode: 'User Code',
      labelShellMembership: 'Shell Membership',
      labelCity: 'City',
      labelUserTier: 'User Tier',

      buttonCaptionCreateProfile: 'Create Profile',
      buttonCaptionEditProfile: 'Edit Profile',
      buttonCaptionSendNotification: 'Send Notification',

      placeholderUsername: 'Username',
      placeholderFullName: 'Full Name',
      placeholderEmail: 'Email',
      placeholderDateOfBirth: 'Date of Birth',
      placeholderGender: 'Gender',
      placeholderPhone: 'Phone',
      placeholderEmployeeId: 'Employee ID',
      placeholderJobTitle: 'Job Title',
      placeholderDepartment: 'Department',
      placeholderDivision: 'Division',
      placeholderCompanyName: 'Company Name',
      placeholderBranch: 'Branch',
      placeholderManager: 'Manager',
      placeholderProfilePicture: 'Profile Picture',
      placeholderRole: 'Role',
      placeholderStartWorkingHour: 'Start Working Hour',
      placeholderEndWorkingHour: 'End Working Hour',
      placeholderStartBeforeMin: 'Start Margin Before Minutes',
      placeholderStartAfterMin: 'Start Margin After Minutes',
      placeholderEndBeforeMin: 'End Margin Before Minutes',
      placeholderEndAfterMin: 'End Margin After Minutes',
      placeholderWorkingHourTimeZone: 'Working Hour TimeZone',
      placeholderEarliestClockIn: 'Earliest Clock In',
      placeholderAutoClockOut: 'Auto Clock Out',
      placeholderUserCode: 'User Code',
      placeholderTags: 'Tags',
      placeholderAddressId: 'ID',
      placeholderAddressContactName: 'Contact Name',
      placeholderAddressContactPhone: 'Contact Phone',
      placeholderAddressAddressLine1: 'Address Line 1',
      placeholderAddressAddressLine2: 'Address Line 2',
      placeholderAddressRegion: 'Region',
      placeholderAddressPostalCode: 'Postal Code',
      placeholderCompany: 'Company',
      placeholderPassword: 'Password',
      placeholderRePassword: 'Re Enter Password',
      placeholderShellMembershipId: 'Shell Membership ID',
      placeholderMerchantTags: 'Merchant Tags',
    },
    provinceScreen: {
      title: 'Province',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelTimeZone: 'Time Zone',
      labelStatus: 'Status',

      buttonCaptionCreateNewProvince: 'Create New Province',
      buttonCaptionEditProvince: 'Edit Province',
      buttonCaptionDeleteProvince: 'Delete Province',

      placeholderProvinceName: 'Province Name',
      placeholderProvinceTimeZone: 'Province Time Zone',
      placeholderProvinceCode: 'Province Code',
    },
    cityScreen: {
      title: 'City',

      labelNo: 'No',
      labelName: 'Name',
      labelProvince: 'Province',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelTimeZone: 'Time Zone',
      labelStatus: 'Status',

      buttonCaptionCreateNewCity: 'Create New City',
      buttonCaptionEditCity: 'Edit City',
      buttonCaptionDeleteCity: 'Delete City',

      placeholderCityName: 'City Name',
      placeholderProvinceName: 'Province Name',
      placeholderCityCode: 'City Code',
    },
    companyScreen: {
      title: 'Company',

      labelNo: 'No',
      labelName: 'Name',
      labelAddress: 'Address',
      labelCity: 'City',
      labelProvince: 'Province',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelTimeZone: 'Time Zone',
      labelStatus: 'Status',
      labelGoogleMap: 'Google Map',

      buttonCaptionCreateNewCompany: 'Create New Company',
      buttonCaptionEditCompany: 'Edit Company',
      buttonCaptionDeleteCompany: 'Delete Company',

      placeholderCompanyName: 'Name',
      placeholderCityName: 'City',
      placeholderAddress: 'Address',
      placeholderDescription: 'Description',
      placeholderTag: 'Tag',
      placeholderEmail: 'Email',
      placeholderPhone: 'Phone',
      placeholderLogo: 'Logo',
      placeholderBanner: 'Banner',
      placeholderWebsite: 'Website',
      placeholderPostalCode: 'Postal Code',
      placeholderGoogleMapUrl: 'Google Map URL',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderAppStoreUrl: 'AppStore URL',
      placeholderPlayStoreUrl: 'PlayStore URL',
    },
    branchScreen: {
      title: 'Branch',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelAddress: 'Address',
      labelGoogleMap: 'Google Map',
      labelBorderArea: 'Border Area',
      labelLongitude: 'Longitude',
      labelLatitude: 'Latitude',
      labelTimeZone: 'Time Zone',
      labelProvince: 'Province',
      labelStatus: 'Status',
      labelCompany: 'Company',

      buttonCaptionCreateNewBranch: 'Create New Branch',
      buttonCaptionEditBranch: 'Edit Branch',
      buttonCaptionDeleteBranch: 'Delete Branch',

      placeholderBranchName: 'Name',
      placeholderCityName: 'City',
      placeholderAddress: 'Address',
      placeholderDescription: 'Description',
      placeholderPhone: 'Phone',
      placeholderEmail: 'Email',
      placeholderPostalCode: 'Postal Code',
      placeholderGoogleMapUrl: 'Google Map URL',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderCompanyName: 'Company Name',
    },
    permissionScreen: {
      title: 'Permission',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelDescription: 'Description',

      buttonCaptionCreateNewPermission: 'Create New Permission',
      buttonCaptionDeletePermission: 'Delete Permission',
      buttonCaptionEditPermission: 'Edit Permission',

      placeholderPermissionName: 'Permission Name',
      placeholderDescription: 'Description',
    },
    roleScreen: {
      title: 'Role',

      labelNo: 'No',
      labelName: 'Name',
      labelPermission: 'Permission',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelDescription: 'Description',

      buttonCaptionCreateNewRole: 'Create New Role',
      buttonCaptionEditRole: 'Edit Role',
      buttonCaptionDeleteRole: 'Delete Role',

      placeholderRoleName: 'Role Name',
      placeholderPermissionName: 'Permission Name',
      placeholderDescription: 'Description',
    },
    userScreen: {
      title: 'User',

      labelNo: 'No',
      labelUsername: 'Username',
      labelFullName: 'Full Name',
      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelRoles: 'Roles',
      labelUserType: 'Type',
      labelLastActivity: 'Last Activity',
      labelValidUntil: 'Valid Until',
      labelAppVersion: 'App Version',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelTimezone: 'Timezone',
      labelReferrer: 'Referrer',
      labelDeviceId: 'Device ID',
      labelCreatedDate: 'Created Date',

      buttonCaptionEditUser: 'Edit User',
      buttonCaptionDisable: 'Disable',
      buttonCaptionSetPassword: 'Set Password',
      buttonCaptionCreateNewIntegrationUser: 'Create New Integration User',

      placeholderUserUsername: 'Username',
      placeholderUserFullName: 'Full Name',
      placeholderUserUserCode: 'User Code',
      placeholderUserEmail: 'Email',
      placeholderUserPhone: 'Phone',
      placeholderUserRoles: 'Roles',
      placeholderActiveSessions: 'Active Sessions',
      placeholderTopActiveSessions: 'Top {pageSize} Active Sessions',
      placeholderReferrerId: 'Referrer ID',
      placeholderReferrerName: 'Referrer Name',

      msgDisableRowConfirmation: 'Are you sure want to disable this row?',
    },
    apiKeyScreen: {
      title: 'API Key',

      labelNo: 'No',
      labelToken: 'Token',
      labelScope: 'Scope',
      labelConsumer: 'Consumer',
      labelOwner: 'Owner',
      labelCreatedDate: 'Created Date',
      labelCopied: 'Copied!',

      buttonCaptionCreateNewApiKey: 'Create New API Key',
      buttonCaptionDeleteApiKey: 'Delete API Key',
      buttonCaptionEditApiKey: 'Edit API Key',

      placeholderToken: 'Token',
      placeholderScope: 'Scope',
      placeholderConsumer: 'Consumer App Name',
      placeholderOwner: 'Owner App Name',
    },
    tokenScreen: {
      title: 'Token',

      labelNo: 'No',
      labelToken: 'Token',
      labelUsername: 'Username',
      labelValidUntil: 'Valid Until',
      labelLastActivity: 'Last Activity',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelAppVersion: 'App Version',
      labelCreatedDate: 'Created Date',
      labelDeviceManufacturer: 'Device Manufacturer',
      labelDeviceModel: 'Device Model',
      labelOSName: 'OS Name',
      labelOSVersion: 'OS Version',
      labelTimeZone: 'Time Zone',
      labelAdditionalUrlPlaceholders: '<br/>{appVersion}: The application version of the recipient<br/>{latestAppVersion}: The latest application version<br/>{deviceId}: The device ID of the recipient<br/>{deviceManufacturer}: The device manufacturer of the recipient<br/>{deviceModel}: The device model of the recipient<br/>{osName}: The operating system used on the recipient\'s device<br/>{osVersion}: The operating system version used on the recipient\'s device<br/>{timeZone}: The timezone on the recipient\'s device',
      labelSendNotificationTo: 'You are going to send notification to',
      labelDeviceId: 'Device ID',

      buttonCaptionDisable: 'Disable',

      placeholdreToken: 'Token',
      placeholderUsername: 'Username',
      placeholderValidUntil: 'Valid Until',
      placeholderLastActivity: 'Last Activity',
      placeholderDevice: 'Device',
      placeholderOS: 'OS',
      placeholderAppVersion: 'App Version',
      placeholderCreatedDate: 'Created Date',
      placeholderFullName: 'Full Name',
      placeholderTimezone: 'TimeZone',
      placeholderLanguage: 'Language',
      placeholderToken: 'Token',
      placeholderFCMToken: 'FCM Token',
      placeholderDeviceId: 'Device ID',
    },
    applicationScreen: {
      title: 'Application',

      labelNo: 'No',
      labelName: 'Name',
      labelOrder: 'Order',
      labelUrl: 'URL',
      labelIcon: 'Icon',

      buttonCaptionCreateNewApplication: 'Create New Application',
      buttonCaptionEditApplication: 'Edit Application',
      buttonCaptionDeleteApplication: 'Delete Application',

      placeholderName: 'Application Name',
      placeholderDescription: 'Description',
      placeholderUrl: 'URL',
      placeholderAndroidAppId: 'Android App ID',
      placeholderIosAppId: 'iOS App ID',
      placeholderDownloadUrl: 'Download URL',
      placeholderOrder: 'Order',
      placeholderPermission: 'Permission',
      placeholderIcon: 'Icon',
      placeholderMinAppVersion: 'Minimum App Version',
      placeholderMaxAppVersion: 'Maximum App Version',
    },
    summaryScreen: {
      title: 'Summary',

      labelTotalUser: 'Total Users',
      labelActiveUser: 'Active Users',
      labelActiveDevice: 'Active Devices',
      labelOnlineUsers: 'Online Users',
      labelOsDistribution: 'OS Distribution',
      labelAccessUsing: 'Access Using',
      labelAndroidVersionDistribution: 'Android Version Distribution',
      labelAndroidDeviceModelDistribution: 'Android Device Model Distribution',
      labelIosVersionDistribution: 'iOS Version Distribution',
      labelIosDeviceModelDistribution: 'iOS Device Model Distribution',
      labelBrowserDistribution: 'Browser Distribution',
      labelTimezoneDistribution: 'Timezone Distribution',
      labelAppVersionDistribution: 'App Version Distribution',
      labelDeviceManufacturerDistribution: 'Device Manufacturer Distribution',
      labelPieChartOther: 'Other',
    },
    serviceScreen: {
      title: 'Service',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',

      placeholderServiceName: 'Name',
      placeholderServiceDescription: 'Description',

      buttonCaptionCreateNewService: 'Create New Service',
      buttonCaptionDeleteService: 'Delete Service',
      buttonCaptionEditService: 'Edit Service',
    },
    scopeScreen: {
      title: 'Scope',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',
      labelService: 'Service',

      placeholderScopeName: 'Name',
      placeholderScopeDescription: 'Description',
      placeholderServiceName: 'Service',

      buttonCaptionCreateNewScope: 'Create New Scope',
      buttonCaptionDeleteScope: 'Delete Scope',
      buttonCaptionEditScope: 'Edit Scope',
    },
    bannerScreen: {
      title: 'Banner',

      labelNo: 'No',
      labelTitle: 'Title',
      labelOrder: 'Order',
      labelAction: 'Action',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelViewCount: 'View Count',
      labelMedia: 'Media',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelCharacter: 'Character(s)',
      labelCompany: 'Company',
      labelNone: 'None',

      buttonCaptionCreateNewBanner: 'Create New Banner',
      buttonCaptionEditBanner: 'Edit Banner',
      buttonCaptionDeleteBanner: 'Delete Banner',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',

      placeholderTitle: 'Title',
      placeholderBody: 'Body',
      placeholderUrl: 'URL',
      placeholderAction: 'Action',
      placeholderViewCount: 'View Count',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderShortURL: 'Short URL',
      placeholderReactionId: 'Reaction ID',
      placeholderDiscussionId: 'Discussion ID',
      placeholderAndroidAppId: 'Android App ID',
      placeholderIosAppId: 'iOS App ID',
      placeholderDownloadUrl: 'Download URL',
      placeholderOrder: 'Order',
      placeholderMedia: 'Media',
      placeholderShortDescription: 'Short Description',
      placeholderTags: 'Tags',
      placeholderCompany: 'Company',
    },
    districtScreen: {
      title: 'District',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelStatus: 'Status',

      buttonCaptionCreateNewDistrict: 'Create New District',
      buttonCaptionEditDistrict: 'Edit District',
      buttonCaptionDeleteDistrict: 'Delete District',

      placeholderDistrictName: 'District Name',
      placeholderCityName: 'City Name',
      placeholderDistrictCode: 'District Code',
    },
    subdistrictScreen: {
      title: 'Subdistrict',

      labelNo: 'No',
      labelName: 'Name',
      labelDistrict: 'District',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelStatus: 'Status',

      buttonCaptionCreateNewSubdistrict: 'Create New Subdistrict',
      buttonCaptionEditSubdistrict: 'Edit Subdistrict',
      buttonCaptionDeleteSubdistrict: 'Delete Subdistrict',

      placeholderSubdistrictName: 'Subdistrict Name',
      placeholderDistrictName: 'District Name',
      placeholderSubdistrictCode: 'Subdistrict Code',
    },
    deviceExceptionScreen: {
      title: 'Device Exception',

      labelNo: 'No',
      labelDeviceID: 'Device ID',
      labelMaxRegistration: 'Maximal Registration',
      labelRegisteredAccounts: 'Registered Accounts',
      labelCreatedDate: 'Created Date',
      labelLabel: 'Label',

      buttonCaptionCreateNewDeviceException: 'Create New Device Exception',
      buttonCaptionDeleteDeviceException: 'Delete Device Exception',
      buttonCaptionEditDeviceException: 'Edit Device Exception',

      placeholderDeviceID: 'Device ID',
      placeholderMaxRegistration: 'Maximal Registration',
      placeholderRegisteredAccounts: 'Registered Accounts',
      placeholderLabel: 'Label',
    },
    registrationAttemptScreen: {
      title: 'Registration Attempt',

      labelNo: 'No',

      labelUserName: 'User Name',
      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelRegisterUsing: 'Register Using',
      labelVerified: 'Verified',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelAppVersion: 'App Version',
      labelCreatedDate: 'Created Date',
      labelValidUntil: 'Valid Until',
      labelReferralCode: 'Referral Code',
      labelDeviceID: 'Device ID',

      placeholderUserName: 'Username',
      placeholderRegisterUsing: 'Register Using',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Phone',
      placeholderResendPINCount: 'Resend PIN Count',
      placeholderReferralCode: 'Referral Code',
      placeholderInvalidPINCount: 'Invalid PIN Count',
      placeholderDeviceID: 'Device ID',
      placeholderValidUntil: 'Valid Until',
      placeholderDevice: 'Device',
      placeholderVerified: 'Verified',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Verified On',
      placeholderAppVersion: 'App Version',
      placeholderLanguage: 'Language',
    },
    passwordResetAttemptScreen: {
      title: 'Password Reset Attempt',

      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelPIN: 'PIN',
      labelResetUsing: 'Reset Using',
      labelDone: 'Done',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelAppVersion: 'App Version',
      labelCreatedDate: 'Created Date',
      labelValidUntil: 'Valid Until',
      labelDeviceID: 'Device ID',

      placeholderResetUsing: 'Register Using',
      placeholderResendPINCount: 'Resend PIN Count',
      placeholderInvalidPINCount: 'Invalid PIN Count',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Phone',
      placeholderDeviceID: 'Device ID',
      placeholderValidUntil: 'Valid Until',
      placeholderDevice: 'Device',
      placeholderVerified: 'Verified',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Verified On',
      placeholderAppVersion: 'App Version',
      placeholderDone: 'Done',
      placeholderLanguage: 'Language',
    },
  },
  id: {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'GALAT',
      alertTitleWarning: 'PERINGATAN',
      alertTitleConfirmation: 'KONFIRMASI',

      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'BATAL',
      buttonCaptionDetail: 'DETAIL',
      buttonCaptionYes: 'YA',
      buttonCaptionNo: 'TIDAK',
      buttonCaptionSave: 'SIMPAN',
      buttonCaptionEdit: 'UBAH',
      buttonCaptionSubmit: 'KIRIM',
      buttonCaptionNext: 'LANJUT',
      buttonCaptionPrev: 'SEBELUMNNYA',
      buttonCaptionBack: 'KEMBALI',
      buttonCaptionAdd: 'TAMBAH',
      buttonCaptionRemove: 'HAPUS',
      buttonCaptionApply: 'TERAPKAN',
      buttonCaptionView: 'Lihat',
      buttonCaptionSend: 'KIRIM',
      buttonCaptionSendNotification: 'Kirim Pemberitahuan',
      buttonCaptionCount: 'Hitung',
      buttonCaptionAdvancedFilter: 'Penyaring Lanjutan',
      buttonCaptionReset: 'Atur Ulang',
      buttonCaptionTakePicture: 'Ambil Foto',
      buttonCaptionEnable: 'Aktifkan',
      buttonCaptionDisable: 'Nonaktifkan',
      buttonCaptionResync: 'Sinkronisasi Ulang',

      errMsgEmptyRequiredFields: 'Tidak boleh ada field yang kosong',
      errMsgInvalidEmailFormat: 'Format email yang Anda masukkan salah',
      errMsgInvalidPhoneNumberFormat: 'Format nomor telepon yang Anda masukkan salah',
      errMsgMinPasswordLength: `Minimal panjang password adalah ${PASSWORD_MIN_LENGTH} karakter`,
      errMsgPasswordDoesNotMatch: 'Kedua kata sandi tidak cocok',
      errMsgCannotOpenUrl: 'Tidak bisa membuka URL',
      errMsgRequired: 'Harus diisi',
      errMsgDuplicatedData: 'Duplikasi data ditemukan. Silakan pilih opsi lain',
      errMsgStartEndDate: 'Tanggal Berakhir harus lebih besar dari atau sama dengan Tanggal Dimulai',
      errMsgRegStartEndDate: 'Tanggal Registrasi Berakhir harus lebih besar dari atau sama dengan Tanggal Registrasi Dimulai',
      errMsgEndRegEndDate: 'Tanggal Berakhir harus lebih besar dari atau sama dengan Tanggal Registrasi Berakhir',
      errMsgInvalidYoutubeUrl: 'Tautan hanya dapat diisi dengan format URL Youtube',
      errMsgStartEndWorkingHour: 'Jam Selesai Kerja tidak boleh sama dengan Jam Mulai Kerja',
      errMsgMinMaxNum: 'Angka Maksimal harus lebih besar dari atau sama dengan angka Minimal',
      errMsgNoDataYet: 'Belum Ada Data',
      errMsgNoDataFound: 'Tidak ada data yang ditemukan',
      errMsgStartEndTime: 'Waktu Berakhir harus lebih besar dari atau sama dengan Waktu Dimulai',
      errMsgMinMaxValue: 'Nilai  Maks. harus lebih besar atau sama dengan Nilai Min.',
      errMsgMustaNumber: 'Harus berupa angka',
      errMsgEarliestClockInTime: 'Jam Mulai Kerja Paling Awal harus lebih kecil atau sama dengan Jam Mulai Kerja',
      errMsgAutoClockOut: 'Jam Selesai Kerja Otomatis harus lebih besar atau sama dengan Jam Selesai Kerja',
      errMsgFileUploadedTooLarge: 'Ukuran file yang diunggah terlalu besar',
      errMsgResourceNotFound: 'Sumber tidak ditemukan',
      errMsgResourceNotAvailable: 'Sumber tidak tersedia',
      errMsgTimeoutContactingService: 'Waktu habis ketika menghubungi layanan',
      errMsgFailedActions: '{number} dari {total} data gagal dihapus',
      errMsgDifferentRetypePassword: 'Kata Sandi Tidak Sesuai',

      labelWelcome: 'Selamat Datang',
      labelAuthentication: 'Autentikasi',
      labelPersonalization: 'Personalisi',
      labelMasterData: 'Data Master',
      labelReport: 'Laporan',
      labelHi: 'Halo, {name}',
      labelRefreshData: 'Perbarui Data',
      labelEdit: 'Ubah',
      labelOnGoing: 'Tandai sebagai Ongoing',
      labelCompleted: 'Tandai sebagai Completed',
      labelCancelled: 'Batalkan Test Drive',
      labelDelete: 'Hapus',
      labelBasicInformation: 'Informasi Dasar',
      labelChoices: 'Pilihan',
      labelChosen: 'Terpilih',
      labelSelected: 'Dipilih',
      labelYes: 'Iya',
      labelNo: 'Tidak',
      labelTrue: 'Benar',
      labelFalse: 'Salah',
      labelLegend: 'Legenda',
      labelCancel: 'Batalkan',
      labelFilter: 'Saring',
      labelSearch: 'Cari',
      labelPerson: 'Orang',
      labelCity: 'Kota',
      labelCompanyName: 'Nama Perusahaan',
      labelSendNotification: 'Kirim Pemberitahuan',
      labelSelectedOrAllUser: 'Terpilih atau Semua Pengguna',
      labelNewsManagement: 'Manajemen Berita',
      labelAdvancedFilter: 'Penyaring Lanjutan',
      labelMin: 'Min',
      labelMax: 'Maks',
      labelCharacter: 'Karakter',
      labelCameraSource: 'Sumber Kamera',
      labelYouCanUsePlaceholdersFieldsAbove: '* Anda dapat menggunakan placeholder berikut pada baris di atas',
      labelAdditionalUrlPlaceholders: '{fullName}: Nama lengkap penerima<br/>{profileId}: ID penerima',
      labelComments: 'Komentar',
      labelViewCommentReply: 'Lihat {replyCount} balasan',
      labelHideCommentReply: 'Sembunyikan {replyCount} balasan',
      labelMoreComments: 'Lebih banyak komentar',
      labelMoreReplies: 'Lebih banyak balasan',
      labelNoComments: 'Tidak ada komentar',
      labelReason: 'Alasan',
      labelShellMembership: 'Keanggotaan Shell',
      labelUserTier: 'Tingkatan Pengguna',
      labelRemoveAllItems: 'Hapus semua data yang dipilih',
      labelSettings: 'Pengaturan',
      labelLogout: 'Keluar',
      labelChangePassword: 'Ubah Kata Sandi',
      labelLogoutDevices: 'Keluar dari perangkat lain',
      labelNewPasswordSaved: 'Kata Sandi baru berhasil disimpan',
      labelSucces: 'Sukses',
      labelWaitingForPayment: 'Menunggu Pembayaran',
      labelPaid: 'Dibayar',
      labelCancelledAndError: 'Dibatalkan',
      labelComplete: 'Selesai',

      placeholderId: 'ID',
      placeholderCreatedBy: 'Dibuat Oleh',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderLastModifiedBy: 'Terakhir Diubah Oleh',
      placeholderLastModifiedDate: 'Tanggal Perubahan Terakhir',
      placeholderStatus: 'Status',
      placeholderTitle: 'Judul',
      placeholderBody: 'Isi',
      placeholderUrl: 'URL',
      placeholderSendTo: 'Kirim kepada',
      placeholderReason: 'Alasan',
      placeholderSearchCompany: 'Cari Perusahaan',
      placeholderSearchCity: 'Cari Kota',
      placeholderType: 'Tipe',
      placeholderOldPassword: 'Kata Sandi Lama',
      placeholderNewPassword: 'Kata Sandi Baru',
      placeholderRetypeNewPassword: 'Ketik Ulang Kata Sandi Baru',

      msgLogoutConfirmation: 'Apa Anda yakin ingin keluar?',
      msgDeleteConfirmation: 'Apa Anda yakin ingin menghapus pilihan ini?',
      msgSomethingWentWrong: 'Terjadi kesalahan',
      msgCancelConfirmation: 'Apa Anda yakin ingin membatalkan pilihan ini?',
      msgDisableConfirmation: 'Apa Anda yakin ingin menonaktifkan pilihan ini?',
      msgEnableConfirmation: 'Apa Anda yakin ingin mengaktifkan pilihan ini?',
      msgResyncConfirmation: 'Apa Anda yakin ingin mensinkronisasi ulang pilihan ini?',
      msgDeleteAllConfirmation: 'Apa Anda yakin ingin menghapus semua pilihan ini?',

      alertMessageUnsavedChanges: 'Anda memiliki perubahan yang belum disimpan!',
      alertMessageSaveChanges: 'Mohon simpan/reset perubahan yang anda buat terlebih dahulu',
    },
    loginScreen: {
      title: 'Masuk',

      buttonCaptionLogin: 'Masuk',

      labelAdminDashboard: 'Dasbor Admin',
      labelUsername: 'Nama pengguna',
      labelPassword: 'Kata sandi',

      placeholderUsername: 'contoh: yourname@company.com',

      errEmptyUsernamePassword: 'Email dan kata sandi tidak dapat kosong',
      errMsgInvalidSignIn: 'Email tidak dapat ditemukan',
      errMsgNoPermissionToLogin: 'Anda tidak memiliki izin untuk masuk',
    },
    welcomeScreen: {
      title: 'Selamat Datang',
    },
    profileScreen: {
      title: 'Profil',

      labelNo: 'No',
      labelFullName: 'Nama Lengkap',
      labelEmail: 'Email',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelCompanyInformation: 'Informasi Perusahaan',
      labelAdditionalInformation: 'Informasi Tambahan',
      labelSendNotificationTo: 'Anda akan mengirim pemberitahuan kepada pengguna',
      labelWorkingHour: 'Penyesuaian Jam Kerja',
      labelCompany: 'Perusahaan',
      labelBranch: 'Cabang',
      labelStatus: 'Status',
      labelAddress: 'Alamat',
      labelHomeAddress: 'Alamat Rumah',
      labelWorkAddress: 'Alamat Kantor',
      labelPhone: 'Nomor HP',
      labelTags: 'Penanda',
      labelUserCode: 'Kode Pengguna',
      labelShellMembership: 'Keanggotaan Shell',
      labelCity: 'Kota',
      labelUserTier: 'Tingkatan Pengguna',

      buttonCaptionCreateProfile: 'Buat Profil',
      buttonCaptionEditProfile: 'Ubah Profil',
      buttonCaptionSendNotification: 'Kirim Pemberitahuan',

      placeholderUsername: 'Nama Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderEmail: 'Email',
      placeholderDateOfBirth: 'Tanggal Lahir',
      placeholderGender: 'Jenis Kelamin',
      placeholderPhone: 'Telepon',
      placeholderEmployeeId: 'ID Pegawai',
      placeholderJobTitle: 'Jabatan',
      placeholderDepartment: 'Departemen',
      placeholderDivision: 'Divisi',
      placeholderCompanyName: 'Nama Perusahaan',
      placeholderBranch: 'Cabang',
      placeholderManager: 'Manajer',
      placeholderProfilePicture: 'Foto Profil',
      placeholderRole: 'Peran',
      placeholderStartWorkingHour: 'Jam Mulai Kerja',
      placeholderEndWorkingHour: 'Jam Selesai Kerja',
      placeholderStartBeforeMin: 'Margin Mulai Sebelum Menit',
      placeholderStartAfterMin: 'Margin Mulai Sesudah Menit',
      placeholderEndBeforeMin: 'Margin Selesai Sebelum Menit',
      placeholderEndAfterMin: 'Margin Selesai Sesudah Menit',
      placeholderWorkingHourTimeZone: 'Zona Waktu Jam Kerja',
      placeholderEarliestClockIn: 'Jam Mulai Kerja Paling Awal',
      placeholderAutoClockOut: 'Jam Selesai Kerja Otomatis',
      placeholderUserCode: 'Kode Pengguna',
      placeholderTags: 'Penanda',
      placeholderAddressId: 'ID',
      placeholderAddressContactName: 'Nama Kontak',
      placeholderAddressContactPhone: 'Nomor Kontak',
      placeholderAddressAddressLine1: 'Alamat Baris 1',
      placeholderAddressAddressLine2: 'Alamat Baris 2',
      placeholderAddressRegion: 'Wilayah',
      placeholderAddressPostalCode: 'Kode Pos',
      placeholderCompany: 'Perusahaan',
      placeholderPassword: 'Kata Sandi',
      placeholderRePassword: 'Ulangi Kata Sandi',
      placeholderShellMembershipId: 'ID Keanggotaan Shell',
      placeholderMerchantTags: 'Penanda Penjual',
    },
    provinceScreen: {
      title: 'Provinsi',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelTimeZone: 'Zona Waktu',
      labelStatus: 'Status',

      buttonCaptionCreateNewProvince: 'Buat Provinsi Baru',
      buttonCaptionEditProvince: 'Ubah Provinsi',
      buttonCaptionDeleteProvince: 'Hapus Provinsi',

      placeholderProvinceName: 'Nama Provinsi',
      placeholderProvinceTimeZone: 'Zona Waktu Provinsi',
      placeholderProvinceCode: 'Kode Provinsi',
    },
    cityScreen: {
      title: 'Kota',

      labelNo: 'No',
      labelName: 'Nama',
      labelProvince: 'Provinsi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelTimeZone: 'Zona Waktu',
      labelStatus: 'Status',

      buttonCaptionCreateNewCity: 'Buat Kota Baru',
      buttonCaptionEditCity: 'Ubah Kota',
      buttonCaptionDeleteCity: 'Hapus Kota',

      placeholderCityName: 'Nama Kota',
      placeholderProvinceName: 'Nama Provinsi',
      placeholderCityCode: 'Kode Kota',
    },
    companyScreen: {
      title: 'Perusahaan',

      labelNo: 'No',
      labelName: 'Nama',
      labelAddress: 'Alamat',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelTimeZone: 'Zona Waktu',
      labelStatus: 'Status',
      labelGoogleMap: 'Peta Google',

      buttonCaptionCreateNewCompany: 'Buat Perusahaan Baru',
      buttonCaptionEditCompany: 'Ubah Perusahaan',
      buttonCaptionDeleteCompany: 'Hapus Perusahaan',

      placeholderCompanyName: 'Nama',
      placeholderCityName: 'Kota',
      placeholderAddress: 'Alamat',
      placeholderDescription: 'Deskripsi',
      placeholderTag: 'Tanda',
      placeholderEmail: 'Email',
      placeholderPhone: 'Telepon',
      placeholderLogo: 'Logo',
      placeholderBanner: 'Spanduk',
      placeholderWebsite: 'Situs',
      placeholderPostalCode: 'Kode Pos',
      placeholderGoogleMapUrl: 'Google Map URL',
      placeholderLatitude: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
      placeholderAppStoreUrl: 'URL AppStore',
      placeholderPlayStoreUrl: 'URL PlayStore',
    },
    branchScreen: {
      title: 'Cabang',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelAddress: 'Alamat',
      labelGoogleMap: 'Peta Google',
      labelBorderArea: 'Batas Wilayah',
      labelLongitude: 'Garis Bujur',
      labelLatitude: 'Garis Lintang',
      labelTimeZone: 'Zona Waktu',
      labelProvince: 'Provinsi',
      labelStatus: 'Status',
      labelCompany: 'Perusahaan',

      buttonCaptionCreateNewBranch: 'Buat Cabang Baru',
      buttonCaptionEditBranch: 'Ubah Cabang',
      buttonCaptionDeleteBranch: 'Hapus Cabang',

      placeholderBranchName: 'Cabang',
      placeholderCityName: 'Kota',
      placeholderAddress: 'Alamat',
      placeholderDescription: 'Deskripsi',
      placeholderPhone: 'Telepon',
      placeholderEmail: 'Email',
      placeholderPostalCode: 'Kode Pos',
      placeholderGoogleMapUrl: 'URL Peta Google',
      placeholderLatitude: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
      placeholderCompanyName: 'Nama Perusahaan',
    },
    permissionScreen: {
      title: 'Izin',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewPermission: 'Buat Izin Baru',
      buttonCaptionDeletePermission: 'Hapus Izin',
      buttonCaptionEditPermission: 'Edit izin',

      placeholderPermissionName: 'Nama Izin',
      placeholderDescription: 'Deskripsi',
    },
    roleScreen: {
      title: 'Peran',

      labelNo: 'No',
      labelName: 'Nama',
      labelPermission: 'Izin',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewRole: 'Buat Peran Baru',
      buttonCaptionEditRole: 'Ubah Peran',
      buttonCaptionDeleteRole: 'Hapus Peran',

      placeholderRoleName: 'Nama Peran',
      placeholderPermissionName: 'Nama Izin',
      placeholderDescription: 'Deskripsi',
    },
    userScreen: {
      title: 'Pengguna',

      labelNo: 'No',
      labelUsername: 'Nama Pengguna',
      labelFullName: 'Nama Lengkap',
      labelEmail: 'Email',
      labelPhone: 'Telepon',
      labelRoles: 'Peran',
      labelUserType: 'Tipe',
      labelLastActivity: 'Aktivitas Terakhir',
      labelValidUntil: 'Berlaku Sampai',
      labelAppVersion: 'Versi App',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelTimezone: 'Zona Waktu',
      labelReferrer: 'Perujuk',
      labelDeviceId: 'ID Perangkat',
      labelCreatedDate: 'Tanggal Pembuatan',

      buttonCaptionEditUser: 'Ubah Pengguna',
      buttonCaptionDisable: 'Nonaktifkan',
      buttonCaptionSetPassword: 'Tetapkan Kata Sandi',
      buttonCaptionCreateNewIntegrationUser: 'Buat Pengguna Integrasi Baru',

      placeholderUserUsername: 'Nama Pengguna',
      placeholderUserFullName: 'Nama Lengkap',
      placeholderUserUserCode: 'Kode Pengguna',
      placeholderUserEmail: 'Email',
      placeholderUserPhone: 'Telepon',
      placeholderUserRoles: 'Peran',
      placeholderActiveSessions: 'Sesi Aktif',
      placeholderTopActiveSessions: '{pageSize} Sesi Aktif Terbaru',
      placeholderReferrerId: 'ID Perujuk',
      placeholderReferrerName: 'Nama Perujuk',

      msgDisableRowConfirmation: 'Apa Anda yakin ingin menonaktifkan baris ini?',
    },
    apiKeyScreen: {
      title: 'Kunci API',

      labelNo: 'No',
      labelToken: 'Token',
      labelScope: 'Cakupan',
      labelConsumer: 'Pengguna',
      labelOwner: 'Pemilik',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelCopied: 'Disalin!',

      buttonCaptionCreateNewApiKey: 'Buat Kunci API Baru',
      buttonCaptionDeleteApiKey: 'Hapus Kunci API',
      buttonCaptionEditApiKey: 'Ubah API Key',

      placeholderToken: 'Token',
      placeholderScope: 'Cakupan',
      placeholderConsumer: 'Nama Pengguna App',
      placeholderOwner: 'Nama Pemilik App',
    },
    tokenScreen: {
      title: 'Token',

      labelNo: 'No',
      labelToken: 'Token',
      labelUsername: 'Nama Pengguna',
      labelValidUntil: 'Berlaku Sampai',
      labelLastActivity: 'Aktivitas Terakhir',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelAppVersion: 'Versi App',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDeviceManufacturer: 'Produsen Perangkat',
      labelDeviceModel: 'Model Perangkat',
      labelOSName: 'Nama OS',
      labelOSVersion: 'Versi OS',
      labelTimeZone: 'Zona Waktu',
      labelAdditionalUrlPlaceholders: '<br/>{appVersion}: Versi aplikasi penerima<br/>{latestAppVersion}: Versi aplikasi terbaru<br/>{deviceId}: ID perangkat penerima<br/>{deviceManufacturer}: Produsen perangkat penerima<br/>{deviceModel}: Model perangkat penerima<br/>{osName}: Sistem operasi yang digunakan di perangkat penerima<br/>{osVersion}: Versi sistem operasi yang digunakan di perangkat penerima<br/>{timeZone}: Zona waktu perangkat penerima',
      labelSendNotificationTo: 'Anda akan mengirim notifikasi ke',
      labelDeviceId: 'ID Perangkat',

      buttonCaptionDisable: 'Nonaktifkan',

      placeholdreToken: 'Token',
      placeholderUsername: 'Nama Pengguna',
      placeholderValidUntil: 'berlaku Sampai',
      placeholderLastActivity: 'Aktivitas Terakhir',
      placeholderDevice: 'Perangkat',
      placeholderOS: 'OS',
      placeholderAppVersion: 'Versi App',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderFullName: 'Nama Lengkap',
      placeholderTimezone: 'Zona Waktu',
      placeholderLanguage: 'Bahasa',
      placeholderToken: 'Token',
      placeholderFCMToken: 'Token FCM',
      placeholderDeviceId: 'ID Perangkat',
    },
    applicationScreen: {
      title: 'Aplikasi',

      labelNo: 'No',
      labelName: 'Nama',
      labelOrder: 'Urutan',
      labelUrl: 'URL',
      labelIcon: 'Ikon',

      buttonCaptionCreateNewApplication: 'Buat Aplikasi Baru',
      buttonCaptionEditApplication: 'Ubah Aplikasi',
      buttonCaptionDeleteApplication: 'Hapus Aplikasi',

      placeholderName: 'Nama Aplikasi',
      placeholderDescription: 'Deskripsi',
      placeholderUrl: 'URL',
      placeholderAndroidAppId: 'ID App Android',
      placeholderIosAppId: 'ID iOS App',
      placeholderDownloadUrl: 'URL Pengunduhan',
      placeholderOrder: 'Urutan',
      placeholderPermission: 'Izin',
      placeholderIcon: 'Ikon',
      placeholderMinAppVersion: 'Versi Minimal Aplikasi',
      placeholderMaxAppVersion: 'Versi Maksimal Aplikasi',
    },
    summaryScreen: {
      title: 'Rangkuman',

      labelTotalUser: 'Total Pengguna',
      labelActiveUser: 'Pengguna Aktif',
      labelActiveDevice: 'Perangkat Aktif',
      labelOnlineUsers: 'Pengguna Online',
      labelOsDistribution: 'Distribusi Sistem Operasi',
      labelAccessUsing: 'Akses Melalui',
      labelAndroidVersionDistribution: 'Distribusi Versi Android',
      labelAndroidDeviceModelDistribution: 'Model Distribusi Perangkat Android',
      labelIosVersionDistribution: 'Distribusi Versi iOS',
      labelIosDeviceModelDistribution: 'Model Distribusi Perangkat iOS',
      labelBrowserDistribution: 'Distribusi Browser',
      labelTimezoneDistribution: 'Distribusi Zona Waktu',
      labelAppVersionDistribution: 'Distribusi Versi App',
      labelDeviceManufacturerDistribution: 'Distribusi Perangkat Manufaktur',
      labelPieChartOther: 'Lainnya',
    },
    serviceScreen: {
      title: 'Layanan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDescription: 'Deskripsi',

      placeholderServiceName: 'Nama',
      placeholderServiceDescription: 'Deskripsi',

      buttonCaptionCreateNewService: 'Buat Layanan Baru',
      buttonCaptionDeleteService: 'Hapus Layanan',
      buttonCaptionEditService: 'Ubah Layanan',
    },
    scopeScreen: {
      title: 'Jangkauan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDescription: 'Deskripsi',
      labelService: 'Layanan',

      placeholderScopeName: 'Nama',
      placeholderScopeDescription: 'Deskripsi',
      placeholderServiceName: 'Layanan',

      buttonCaptionCreateNewScope: 'Buat Jangkauan Baru',
      buttonCaptionDeleteScope: 'Hapus Jangkauan',
      buttonCaptionEditScope: 'Edit Scope',
    },
    bannerScreen: {
      title: 'Spanduk',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelOrder: 'Urutan',
      labelAction: 'Aksi',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelViewCount: 'Jumlah Tampilan',
      labelMedia: 'Media',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelCharacter: 'Karakter',
      labelCompany: 'Perusahaan',
      labelNone: 'Tidak Ada',

      buttonCaptionCreateNewBanner: 'Buat Spanduk Baru',
      buttonCaptionEditBanner: 'Ubah Spanduk',
      buttonCaptionDeleteBanner: 'Hapus Spanduk',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',

      placeholderTitle: 'Judul',
      placeholderBody: 'Badan',
      placeholderUrl: 'URL',
      placeholderAction: 'Aksi',
      placeholderViewCount: 'Jumlah Tampilan',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderShortURL: 'Tautan Pendek',
      placeholderReactionId: 'ID Reaksi',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderAndroidAppId: 'ID App Android',
      placeholderIosAppId: 'ID App iOS',
      placeholderDownloadUrl: 'URL Pengunduh',
      placeholderOrder: 'Urutan',
      placeholderMedia: 'Media',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderTags: 'Tag',
      placeholderCompany: 'Perusahaan',
    },
    districtScreen: {
      title: 'Kecamatan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelStatus: 'Status',

      buttonCaptionCreateNewDistrict: 'Buat Kecamatan Baru',
      buttonCaptionEditDistrict: 'Ubah Kecamatan',
      buttonCaptionDeleteDistrict: 'Hapus Kecamatan',

      placeholderDistrictName: 'Nama Kecamatan',
      placeholderCityName: 'Nama Kota',
      placeholderDistrictCode: 'Kode Kecamatan',
    },
    subdistrictScreen: {
      title: 'Kelurahan',

      labelNo: 'No',
      labelName: 'Nama',
      labelDistrict: 'Kecamatan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelStatus: 'Status',

      buttonCaptionCreateNewSubdistrict: 'Buat Kelurahan Baru',
      buttonCaptionEditSubdistrict: 'Ubah Kelurahan',
      buttonCaptionDeleteSubdistrict: 'Hapus Kelurahan',

      placeholderSubdistrictName: 'Nama Kelurahan',
      placeholderDistrictName: 'Nama Kecamatan',
      placeholderSubdistrictCode: 'Kode Kelurahan',
    },
    deviceExceptionScreen: {
      title: 'Pengecualian Perangkat',

      labelNo: 'No',
      labelDeviceID: 'ID Perangkat',
      labelMaxRegistration: 'Registrasi Maksimal',
      labelRegisteredAccounts: 'Akun Terdaftar',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLabel: 'Label',

      buttonCaptionCreateNewDeviceException: 'Buat Pengecualian Perangkat Baru',
      buttonCaptionDeleteDeviceException: 'Hapus Pengecualian Perangkat',
      buttonCaptionEditDeviceException: 'Ubah Pengecualian Perangkat',

      placeholderDeviceID: 'ID Perangkat',
      placeholderMaxRegistration: 'Registrasi Maksimal',
      placeholderRegisteredAccounts: 'Akun Terdaftar',
      placeholderLabel: 'Label',
    },
    registrationAttemptScreen: {
      title: 'Percobaan Pendaftaran',

      labelNo: 'No',
      labelUserName: 'Nama User',
      labelEmail: 'Email',
      labelPhone: 'Telepon',
      labelRegisterUsing: 'Mendaftar Menggunakan',
      labelVerified: 'Diverifikasi',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelAppVersion: 'Versi App',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelValidUntil: 'Berlaku Sampai',
      labelReferralCode: 'Kode Referral',
      labelDeviceID: 'ID Perangkat',

      placeholderUserName: 'Nama User',
      placeholderRegisterUsing: 'Mendaftar Menggunakan',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Telepon',
      placeholderResendPINCount: 'Jumlah Kirim Ulang PIN',
      placeholderReferralCode: 'Kode Referral',
      placeholderInvalidPINCount: 'Jumlah PIN tidak valid',
      placeholderDeviceID: 'ID Perangkat',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderDevice: 'Perangkat',
      placeholderVerified: 'Diverifikasi',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Diverifikasi Pada',
      placeholderAppVersion: 'Versi App',
      placeholderLanguage: 'Bahasa',
    },
    passwordResetAttemptScreen: {
      title: 'Percobaan Atur Ulang Kata Sandi',

      labelEmail: 'Email',
      labelPhone: 'Telepon',
      labelPIN: 'PIN',
      labelResetUsing: 'Atur Ulang Menggunakan',
      labelDone: 'Selesai',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelAppVersion: 'Versi App',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelValidUntil: 'Berlaku Sampai',
      labelDeviceID: 'ID Perangkat',

      placeholderResetUsing: 'Atur Ulang Menggunakan',
      placeholderResendPINCount: 'Jumlah Kirim Ulang PIN',
      placeholderInvalidPINCount: 'Jumlah PIN tidak valid',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Telepon',
      placeholderDeviceID: 'ID Perangkat',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderDevice: 'Perangkat',
      placeholderVerified: 'Diverifikasi',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Diverifikasi Pada',
      placeholderAppVersion: 'Versi App',
      placeholderDone: 'Selesai',
      placeholderLanguage: 'Bahasa',
    },
  },
});

export default LocalizedString;
