import {
  clearPermissions, deletingSelectedPermissions, setAlertInfoMessage, setAlertErrorMessage,
} from '../simple-action';
import { deletePermission } from '../../../helper';
import downloadPermissionsAsync from './downloadPermissionsAsync';
import LocalizedString from '../../../localization';

export default () => async (dispatch, getState) => {
  try {
    dispatch(deletingSelectedPermissions(true));

    const { token } = getState().authentication;
    const { selectedRows } = getState().uiPermission;

    let successful = 0;

    for (let i = 0; i < selectedRows.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await deletePermission(selectedRows[i].id, token);
      successful += 1;
    }

    if (successful < selectedRows.length) {
      dispatch(setAlertInfoMessage(LocalizedString.common.errMsgFailedActions.replace(/\{number\}/, selectedRows.length - successful).replace(/\{total\}/, selectedRows.length)));
    }

    dispatch(clearPermissions());
    dispatch(downloadPermissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(deletingSelectedPermissions(false));
  }
};
