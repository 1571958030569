import { downloadingGiftVoucherRedemptions, setGiftVoucherRedemptions } from '../simple-action';
import { downloadGiftVoucherRedemptions, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, giftVoucherRedemptions, uiGiftVoucherRedemption,
  } = getState();
  const { token } = authentication;
  const { meta } = giftVoucherRedemptions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGiftVoucherRedemption;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingGiftVoucherRedemptions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const result = await downloadGiftVoucherRedemptions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );
    dispatch(setGiftVoucherRedemptions(result));
  } finally {
    dispatch(downloadingGiftVoucherRedemptions(false));
  }
};
