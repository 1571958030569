import {
  RESYNCING_CONFIGS, RESYNCING_DB_USERS, RESYNCING_MASTER_DATA, RESYNCING_USERS,
  SET_SELECTED_RESYNC_ACTION, SET_SELECTED_MASTER_DATA_RESYNC_ACTION,
  CLEAR_SELECTED_MASTER_DATA_RESYNC_ACTION,
  SET_SELECTED_CHANNEL_RESYNC_ACTION,
} from '../action';

const initialState = {
  loadingCongfigs: false,
  loadingDBUsers: false,
  loadingMasterData: false,
  loadingUsers: false,
  selectedResyncAction: '',
  selectedMasterData: [],
  selectedChannel: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESYNCING_CONFIGS:
      return { ...state, loadingCongfigs: action.status };
    case RESYNCING_DB_USERS:
      return { ...state, loadingDBUsers: action.status };
    case RESYNCING_MASTER_DATA:
      return { ...state, loadingMasterData: action.status };
    case RESYNCING_USERS:
      return { ...state, loadingUsers: action.status };
    case SET_SELECTED_RESYNC_ACTION:
      return { ...state, selectedResyncAction: action.option };
    case SET_SELECTED_MASTER_DATA_RESYNC_ACTION:
      return { ...state, selectedMasterData: action.option };
    case CLEAR_SELECTED_MASTER_DATA_RESYNC_ACTION:
      return { ...state, selectedMasterData: [] };
    case SET_SELECTED_CHANNEL_RESYNC_ACTION:
      return { ...state, selectedChannel: action.option };
    default: return state;
  }
};
