import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT,
  RXFORM_AUTH_PASSWORD_RESET_ATTEMPT, RXSTATE_AUTH_PASSWORD_RESET_ATTEMPTS,
  RXSTATE_AUTH_PASSWORD_RESET_ATTEMPT_PAGE,
  FILTER_TYPE_TEXT,
  FILTER_TYPE_DROPDOWN,
  RESET_USING_DROPDOWN_SELECTION,
  FILTER_TYPE_SWITCH,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_EMAIL,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_PHONE,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_RESET_USING,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_DEVICE,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_DEVICE_ID,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_OS,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_APP_VERSION,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_LANGUAGE,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_PIN,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_RESEND_PIN_COUNT,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_INVALID_PIN_COUNT,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_VALID_UNTIL,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_VERIFIED,
  RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_VERIFIED_ON,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderEmail}
            label={LocalizedString.passwordResetAttemptScreen.labelEmail}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderPhone}
            label={LocalizedString.passwordResetAttemptScreen.labelPhone}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_DEVICE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderDeviceID}
            label={LocalizedString.passwordResetAttemptScreen.labelDeviceID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_DEVICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderDevice}
            label={LocalizedString.passwordResetAttemptScreen.labelDevice}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_OS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderOS}
            label={LocalizedString.passwordResetAttemptScreen.labelOS}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_APP_VERSION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderAppVersion}
            label={LocalizedString.passwordResetAttemptScreen.labelAppVersion}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_LANGUAGE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderLanguage}
            label={LocalizedString.passwordResetAttemptScreen.placeholderLanguage}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_RESET_USING}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderResetUsing}
            label={LocalizedString.passwordResetAttemptScreen.labelResetUsing}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_PIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderPIN}
            label={LocalizedString.passwordResetAttemptScreen.labelPIN}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_RESEND_PIN_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderResendPINCount}
            label={LocalizedString.passwordResetAttemptScreen.placeholderResendPINCount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_INVALID_PIN_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderInvalidPINCount}
            label={LocalizedString.passwordResetAttemptScreen.placeholderInvalidPINCount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_VALID_UNTIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderValidUntil}
            label={LocalizedString.passwordResetAttemptScreen.labelValidUntil}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_VERIFIED}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderVerified}
            label={LocalizedString.passwordResetAttemptScreen.placeholderVerified}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_PASSWORD_RESET_ATTEMPT_VERIFIED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.passwordResetAttemptScreen.placeholderVerifiedOn}
            label={LocalizedString.passwordResetAttemptScreen.placeholderVerifiedOn}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const AuthPasswordResetAttempPage = ({
  onAppear, downloading, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_AUTH_PASSWORD_RESET_ATTEMPTS}
    uiPage={RXSTATE_AUTH_PASSWORD_RESET_ATTEMPT_PAGE}
    tableColumns={[
      {
        title: LocalizedString.registrationAttemptScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.passwordResetAttemptScreen.labelEmail, field: 'email', sorting: !downloading },
      { title: LocalizedString.passwordResetAttemptScreen.labelPhone, field: 'phone', sorting: !downloading },
      { title: LocalizedString.passwordResetAttemptScreen.labelResetUsing, field: 'resetUsing', sorting: !downloading },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelDone,
        field: 'done',
        render: ({ done }) => (
          done === true
            ? LocalizedString.common.labelTrue
            : LocalizedString.common.labelFalse
        ),
        sorting: !downloading,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelDevice,
        field: 'device',
        sorting: false,
        render: ({ manufacturer, model }) => `${manufacturer} ${model}`,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelOS,
        field: 'os',
        sorting: false,
        render: ({ osName, osVersion }) => `${osName} ${osVersion}`,
      },
      { title: LocalizedString.passwordResetAttemptScreen.labelAppVersion, field: 'appVersion', sorting: !downloading },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.passwordResetAttemptScreen.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelValidUntil,
        field: 'validUntil',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelEmail,
        field: 'email',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelPhone,
        field: 'phone',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelResetUsing,
        field: 'resetUsing',
        type: FILTER_TYPE_DROPDOWN,
        data: RESET_USING_DROPDOWN_SELECTION,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelDone,
        field: 'done',
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.passwordResetAttemptScreen.labelDeviceID,
        field: 'deviceId',
        type: FILTER_TYPE_TEXT,
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.passwordResetAttemptScreen.title}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_AUTH_PASSWORD_RESET_ATTEMPT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AuthPasswordResetAttempPage);

AuthPasswordResetAttempPage.propTypes = {
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
};
