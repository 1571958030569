import { downloadGiiasSurabayaScannedCode } from '../../../helper';
import { downloadingGiiasSurabayaScannedCode, setGiiasSurabayaScannedCode, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiGiiasSurabayaScannedCode } = getState();

  const { token } = authentication;
  const { tappedId } = uiGiiasSurabayaScannedCode;

  try {
    dispatch(downloadingGiiasSurabayaScannedCode(true));

    const scannedCode = await downloadGiiasSurabayaScannedCode(
      tappedId,
      token,
    );

    dispatch(setGiiasSurabayaScannedCode(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingGiiasSurabayaScannedCode(false));
  }
};
