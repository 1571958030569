import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  addingPromoCodeBatchVouchers, addPromoCodeBatch, clearCompaniesMData, clearPromoCodeBatches,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData, setCompanySearchTextMData,
  setPromoCodeBatchAdvancedFilterDialogSelectedFilterString, setPromoCodeBatchSearchText,
  setPromoCodeBatchSelectedBypassAchievement, setPromoCodeBatchSelectedBypassMaxPurchase,
  setPromoCodeBatchSelectedPageSize, setPromoCodeBatchSelectedOrderBy,
  setPromoCodeBatchSelectedMerchant, setPromoCodeBatchSelectedRewardType, setPromoCodeBatchTappedId,
  setPromoCodeBatchSelectedVouchers, setPromoCodeBatchTableDataVouchers,
  addPromoCodeBatchAsync, cancelPromoCodeBatchAsync, downloadPromoCodeBatchAsync,
  downloadPromoCodeBatchesAsync,
  setPromoCodeBatchUserTypes,
  setPromoCodeBatchSelectedAirport,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_PROMO_CODE_BATCHES,
  MENUID_PROMO_CODE_PROMO_CODE_BATCH, PAGE_MODE_TABLE, REWARD_TYPE_COIN, REWARD_TYPE_COUPON,
  RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT, RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT,
  RXFIELD_PROMO_CODE_BATCH_MERCHANT, RXFIELD_PROMO_CODE_BATCH_REWARD,
  RXFIELD_PROMO_CODE_BATCH_VOUCHERS, RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE, RXFORM_PROMO_CODE_BATCH,
  RXFIELD_PROMO_CODE_BATCH_USER_TYPE,
} from '../../constant';
import {
  debounceSearch, localDateToUtc, toMoment, transformDropdownData, transformInitialValues,
  transformVouchers, getUserType, transformVoucherDropdownData,
} from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { downloadMDataCompaniesAsync, downloadMyConfigItemsAsync } from '../../../../redux/action';
import {
  clearGarudaAirports, clearVouchers, downloadGarudaAirportsAsync, downloadVouchersAsync,
  setGarudaAirportSearchText, setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText,
} from '../../../voucher';
import PromoCodeBatchPage from './promo-code-batch.presentation';
import { getGarudaCompanyNameConfigValue, isSelectedVoucherGarudaLounge } from '../../../voucher/helper';

const getInitialValues = (state) => {
  const { promoCodeBatches, uiFunctionalPage, uiPromoCodeBatch } = state;
  const { data } = promoCodeBatches;
  const {
    addingVouchers, downloadingDeleting, tableDataVouchers, tappedId,
  } = uiPromoCodeBatch;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting && !addingVouchers
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    bypassMaxPurchaseValidation: found.bypassMaxPurchaseValidation
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    bypassAchievementValidation: found.bypassAchievementValidation
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    merchant: found.merchantId
      ? { label: found.merchantName, value: found.merchantId }
      : { label: '', value: '' },
    vouchers: transformVouchers(tableDataVouchers),
    validFrom: found.validFrom
      ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    validUntil: found.validUntil
      ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    userType: getUserType(found),
    minSignUpTime: found.minSignUpTime
      ? toMoment(found.minSignUpTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    maxSignUpTime: found.maxSignUpTime
      ? toMoment(found.maxSignUpTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
  }) : {
    bypassMaxPurchaseValidation: { label: '', value: '' },
    bypassAchievementValidation: { label: '', value: '' },
    merchant: { label: '', value: '' },
    vouchers: tableDataVouchers.length > 0 ? tableDataVouchers : [],
    userType: [],
  };
  return result;
};

const mapStateToProps = (state) => {
  const { selectedVouchers: selectedVoucher } = state.uiPromoCodeBatch;

  return ({
    airports: transformDropdownData(state.garudaAirports.data),
    merchants: transformDropdownData(state.companiesMdata.data),
    vouchers: transformVoucherDropdownData(state.vouchers.data) || [],
    addingEditing: state.uiPromoCodeBatch.addingEditing,
    addingVouchers: state.uiPromoCodeBatch.addingVouchers,
    downloading: state.uiPromoCodeBatch.downloading,
    downloadingDeleting: state.uiPromoCodeBatch.downloadingDeleting,
    cancelling: state.uiPromoCodeBatch.cancelling,
    isSelectedVoucherGarudaLounge: isSelectedVoucherGarudaLounge(selectedVoucher, state),
    loadingAirport: state.uiGarudaAirport.downloading,
    loadingMerchant: state.uiCompany.downloading,
    loadingVoucher: state.uiVoucher.downloading,
    currentPromoCodeBatchTaskStatus: state.promoCodeBatches.data[state.uiFunctionalPage.tappedId]
      ? state.promoCodeBatches.data[state.uiFunctionalPage.tappedId].taskStatus : '',
    garudaCompanyNameConfigValue: getGarudaCompanyNameConfigValue(state),
    pageMode: state.uiFunctionalPage.pageMode,
    tappedId: state.uiFunctionalPage.tappedId || '',
    selectedRewardType: state.uiPromoCodeBatch.selectedRewardType?.value || '',
    selectedAirport: state.uiPromoCodeBatch.selectedAirport,
    selectedVoucher,
    selectedUserTypes: state.uiPromoCodeBatch.selectedUserTypes,
    tableDataVouchers: state.uiPromoCodeBatch.tableDataVouchers,
    tappedPromoCodeBatch: state.promoCodeBatches.data[state.uiPromoCodeBatch.tappedId],
    initialValues: getInitialValues(state),
  });
};

const searchAirportsDebounce = debounceSearch((dispatch) => {
  dispatch(clearGarudaAirports());
  dispatch(downloadGarudaAirportsAsync(1))
    .catch((error) => dispatch(setAlertErrorMessage(error)));
});

const searchVoucherDebounce = debounceSearch(
  async (dispatch) => {
    try {
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAddVoucherPressed: async (defaultValue, newInitialValue, selectedOption,
    currentData, selectedAirport, garudaCompanyNameConfigValue) => {
    const isCurrSelectedVoucherGarudaLounge = isSelectedVoucherGarudaLounge(selectedOption,
      null, garudaCompanyNameConfigValue);

    if (isCurrSelectedVoucherGarudaLounge && !selectedAirport) {
      dispatch(setAlertErrorMessage(LocalizedString.promoCodeBatchPage.errMsgPleaseFillAiport));
    } else if (selectedOption) {
      try {
        dispatch(addingPromoCodeBatchVouchers(true));

        const newVoucher = isCurrSelectedVoucherGarudaLounge ? { ...selectedOption, label: `${selectedOption.label} (${selectedAirport.label})`, airport: selectedAirport } : selectedOption;

        if (Object.prototype.hasOwnProperty.call(currentData, 'voucherId')) {
          const result = {
            ...currentData,
            vouchers: defaultValue.length > 0
              ? [...defaultValue, newVoucher] : [newVoucher],
          };
          await dispatch(addPromoCodeBatch(result));
          dispatch(setPromoCodeBatchTableDataVouchers(result.vouchers));
          dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_VOUCHERS,
            result.vouchers));
        } else {
          const newData = newInitialValue.length > 0
            ? [...newInitialValue, newVoucher]
            : [newVoucher];
          await dispatch(setPromoCodeBatchTableDataVouchers(newData));
          dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_VOUCHERS, newData));
        }
        dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
        dispatch(setVoucherSearchText(''));
        dispatch(clearVouchers());
        dispatch(downloadVouchersAsync(1))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      } finally {
        dispatch(setPromoCodeBatchSelectedVouchers(null));
        dispatch(addingPromoCodeBatchVouchers(false));
      }
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAirportOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeBatchSelectedAirport(option));
    } else {
      dispatch(setPromoCodeBatchSelectedAirport(''));
    }
  },
  onAppear: () => {
    dispatch(downloadMyConfigItemsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setActiveSideMenuItem(MENUID_PROMO_CODE_PROMO_CODE_BATCH));
    dispatch(setPromoCodeBatchAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setPromoCodeBatchSearchText(''));
    dispatch(clearPromoCodeBatches());
    dispatch(setPromoCodeBatchSelectedPageSize(20));
    dispatch(setPromoCodeBatchSelectedOrderBy(INITIAL_ORDER_BY_PROMO_CODE_BATCHES));
    dispatch(downloadPromoCodeBatchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/merchant/, 'merchantId');
    dispatch(setPromoCodeBatchAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPromoCodeBatches());
    dispatch(downloadPromoCodeBatchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onBypassAchievementValidationOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeBatchSelectedBypassAchievement(option));
      dispatch(change(RXFORM_PROMO_CODE_BATCH,
        RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION, option));
    } else {
      dispatch(setPromoCodeBatchSelectedBypassAchievement(''));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION, ''));
    }
  },
  onBypassMaxPurchaseOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeBatchSelectedBypassMaxPurchase(option));
      dispatch(change(RXFORM_PROMO_CODE_BATCH,
        RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE, option));
    } else {
      dispatch(setPromoCodeBatchSelectedBypassMaxPurchase(''));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE, ''));
    }
  },
  onCancelPressed: () => {
    dispatch(setPromoCodeBatchUserTypes([]));
    dispatch(reset(RXFORM_PROMO_CODE_BATCH));
  },
  onChangeAirportText: async (text) => {
    dispatch(setGarudaAirportSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchAirportsDebounce(dispatch);
    }
  },
  onChangeMerchantText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPromoCodeBatchesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPromoCodeBatchSelectedPageSize(pageSize));
    dispatch(downloadPromoCodeBatchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeVoucherText: async (text) => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchVoucherDebounce(dispatch, true);
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.promoCodeBatchPage.msgCancelConfirmation:
        dispatch(cancelPromoCodeBatchAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default: break;
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setPromoCodeBatchTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setPromoCodeBatchTappedId(''));
    dispatch(setPromoCodeBatchSelectedMerchant(null));
    dispatch(setPromoCodeBatchSelectedVouchers(null));
    dispatch(setPromoCodeBatchTableDataVouchers([]));
    dispatch(setPromoCodeBatchSelectedRewardType(null));
    dispatch(setPromoCodeBatchSelectedBypassMaxPurchase(null));
    dispatch(setPromoCodeBatchSelectedBypassAchievement(null));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setPromoCodeBatchUserTypes([]));
    dispatch(setCompanySearchTextMData(''));
    dispatch(setVoucherSearchText(''));
    dispatch(clearCompaniesMData());
    dispatch(clearVouchers());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeleteVoucherPressed: (newValue, currentData) => {
    if (Object.prototype.hasOwnProperty.call(currentData, 'voucherId')) {
      const result = { ...currentData, vouchers: newValue };
      dispatch(addPromoCodeBatch(result));
      dispatch(setPromoCodeBatchTableDataVouchers(result.vouchers));
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(''));
      dispatch(clearVouchers());
      dispatch(downloadVouchersAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setPromoCodeBatchTableDataVouchers(newValue));
    }
    dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_VOUCHERS, newValue));
  },
  onMerchantOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeBatchSelectedMerchant(option));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_MERCHANT, option));
    } else {
      dispatch(setPromoCodeBatchSelectedMerchant(''));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_MERCHANT, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPromoCodeBatchSelectedPageSize(pageSize));
    dispatch(clearPromoCodeBatches());
    dispatch(downloadPromoCodeBatchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setPromoCodeBatchAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearPromoCodeBatches());
    dispatch(setPromoCodeBatchSelectedOrderBy(INITIAL_ORDER_BY_PROMO_CODE_BATCHES));
    dispatch(downloadPromoCodeBatchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRewardTypeOptionSelected: async (option) => {
    if (option) {
      const rewardTypeCoin = option.value === REWARD_TYPE_COIN;
      const rewardTypeCoupon = option.value === REWARD_TYPE_COUPON;
      dispatch(setPromoCodeBatchSelectedRewardType(option));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_REWARD, option));
      if (rewardTypeCoin || rewardTypeCoupon) {
        dispatch(setPromoCodeBatchTableDataVouchers([]));
        dispatch(setPromoCodeBatchSelectedVouchers(null));
        dispatch(setPromoCodeBatchSelectedBypassMaxPurchase(null));
        dispatch(setPromoCodeBatchSelectedBypassAchievement(null));
        dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_VOUCHERS, null));
        dispatch(change(RXFORM_PROMO_CODE_BATCH,
          RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE, null));
        dispatch(change(RXFORM_PROMO_CODE_BATCH,
          RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION, null));

        try {
          dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
          dispatch(setVoucherSearchText(''));
          dispatch(clearVouchers());
          await dispatch(downloadVouchersAsync(1));
        } catch (error) {
          dispatch(setAlertErrorMessage(error));
        }

        if (rewardTypeCoin) {
          dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT, '0'));
        }
        if (rewardTypeCoupon) {
          dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT, '0'));
        }
      } else {
        dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT, '0'));
        dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT, '0'));
      }
    } else {
      dispatch(setPromoCodeBatchSelectedRewardType(''));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_REWARD, ''));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPromoCodeBatchSearchText(text));
      dispatch(clearPromoCodeBatches());
      await dispatch(downloadPromoCodeBatchesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setPromoCodeBatchSelectedOrderBy(orderBy));
    dispatch(clearPromoCodeBatches());
    dispatch(downloadPromoCodeBatchesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    prefix, quantity, randomCodeLength, validFrom, validUntil, merchantTag, maxRedemptionCount,
    notificationMessageTitle, notificationMessageBody, pointAmount, couponAmount, userType,
    minSignUpTime, maxSignUpTime,
  }) => {
    await dispatch(addPromoCodeBatchAsync(prefix, quantity, randomCodeLength,
      localDateToUtc(validFrom), localDateToUtc(validUntil), merchantTag, maxRedemptionCount,
      notificationMessageTitle, notificationMessageBody, pointAmount, couponAmount, userType,
      localDateToUtc(minSignUpTime), localDateToUtc(maxSignUpTime)));
  },
  onUserTypeSelected: (option) => {
    if (option.length > 0) {
      dispatch(setPromoCodeBatchUserTypes(option));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_USER_TYPE, option));
    } else {
      dispatch(setPromoCodeBatchUserTypes([]));
      dispatch(change(RXFORM_PROMO_CODE_BATCH, RXFIELD_PROMO_CODE_BATCH_USER_TYPE, []));
    }
  },
  onViewPressed: (id) => {
    dispatch(setPromoCodeBatchTappedId(id));
    dispatch(downloadPromoCodeBatchAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onVoucherOptionSelected: (option, prevSelectedVoucher, garudaCompanyNameConfigValue,
    isPrevSelectedVoucherGarudaLounge) => {
    const isCurrSelectedVoucherGarudaLounge = option
      ? isSelectedVoucherGarudaLounge(option, null, garudaCompanyNameConfigValue) : false;

    if (option) {
      dispatch(setPromoCodeBatchSelectedVouchers(option));

      if (isCurrSelectedVoucherGarudaLounge) {
        dispatch(setGarudaAirportSearchText(''));
        dispatch(clearGarudaAirports());
        dispatch(downloadGarudaAirportsAsync(1))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
    } else {
      dispatch(setPromoCodeBatchSelectedVouchers(null));
    }

    if ((isPrevSelectedVoucherGarudaLounge && !option)
    || (isPrevSelectedVoucherGarudaLounge && option.value !== prevSelectedVoucher.value)
    || (!isPrevSelectedVoucherGarudaLounge && isCurrSelectedVoucherGarudaLounge)) {
      dispatch(setPromoCodeBatchSelectedAirport(''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeBatchPage);
