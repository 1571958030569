import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, CircularProgress, makeStyles, Avatar, TextField,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  AccentButton,
  FunctionalPage, ImageInputField, NumberTextFieldWithoutArrowDial, SectionTitle,
} from '../../component';
import {
  COLOR_DISABLED_ROW, COLOR_BACKGROUND, PAGE_MODE_VIEW, PAGE_MODE_EDIT,
  RXFORM_ACCESSORY, RXSTATE_ACCESSORY, RXSTATE_ACCESSORY_PAGE, STATUS_DISABLED,
  RXFIELD_ACCESSORY_DESCRIPTION, approval,
  RXFIELD_ACCESSORY_PRODUCT_CODE, RXFIELD_ACCESSORY_PRODUCT_NAME,
  RXFIELD_ACCESSORY_VEHICLE_CODE, RXFIELD_ACCESSORY_VEHICLE_MODEL,
  RXFIELD_ACCESSORY_VARIANT, RXFIELD_ACCESSORY_ACTIVE,
  RXFIELD_ACCESSORY_MIN_ORDER_QTY, RXFIELD_ACCESSORY_WIDTH,
  RXFIELD_ACCESSORY_HEIGHT, RXFIELD_ACCESSORY_WEIGHT,
  RXFIELD_ACCESSORY_LENGTH, IMAGE_SOURCE_URI_PREFIX,
  FILTER_TYPE_DROPDOWN, status,
  FILTER_TYPE_NUMBER_RANGE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormEditableTableField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxFormValidateAccessory } from '../../validation';
import { toCurrency } from '../../helper';

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: 'relative',
    paddingTop: 10,
  },
  button: {
    marginLeft: 10,
    flexDirection: 'row',
  },
}));

const renderDialogContent = (
  initialValues, classes, addingEditing, downloadingDeleting, pageMode,
  totalCurrentPage, tokenTotalCount, onActiveOptionSelected,
  onAddVariantPressed,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_PRODUCT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderProductCode}
            label={LocalizedString.accessoryPage.placeholderProductCode}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_PRODUCT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderProductName}
            label={LocalizedString.accessoryPage.placeholderProductName}
            multiline
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderDescription}
            label={LocalizedString.accessoryPage.placeholderDescription}
            disabled
            loading={downloadingDeleting}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_VEHICLE_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderVehicleModelCode}
            label={LocalizedString.accessoryPage.placeholderVehicleModelCode}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_VEHICLE_MODEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderVehicleModelName}
            label={LocalizedString.accessoryPage.placeholderVehicleModelName}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_ACTIVE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.accessoryPage.placeholderActive}
            label={LocalizedString.accessoryPage.placeholderActive}
            disabled
            data={approval}
            value={initialValues.isActive}
            onOptionSelected={onActiveOptionSelected}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_MIN_ORDER_QTY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderMinOrderQty}
            label={LocalizedString.accessoryPage.placeholderMinOrderQty}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_WIDTH}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderWidth}
            label={LocalizedString.accessoryPage.placeholderWidth}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_HEIGHT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderHeight}
            label={LocalizedString.accessoryPage.placeholderHeight}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_WEIGHT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderWeight}
            label={LocalizedString.accessoryPage.placeholderWeight}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_LENGTH}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryPage.placeholderLength}
            label={LocalizedString.accessoryPage.placeholderLength}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.accessoryPage.placeholderVariants} />
    {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
      <Grid item sm={12} className={classes.tableContainer}>
        <Field
          name={RXFIELD_ACCESSORY_VARIANT}
          component={renderReduxFormEditableTableField}
          label={LocalizedString.accessoryPage.placeholderVariants}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          loading={downloadingDeleting}
          currentPage={totalCurrentPage}
          totalCount={tokenTotalCount}
          defaultValue={initialValues.variants}
          onAddPressed={onAddVariantPressed}
          disableDelete
          disableToolbar
          tableColumns={[
            {
              title: LocalizedString.accessoryPage.placeholderOrder,
              field: 'order',
              sorting: false,
              width: 40,
              // eslint-disable-next-line react/prop-types
              editComponent: ({ value, onChange }) => (
                <NumberTextFieldWithoutArrowDial
                  placeholder={LocalizedString.accessoryPage.placeholderOrder}
                  label={LocalizedString.accessoryPage.placeholderOrder}
                  variant="outlined"
                  type="number"
                  defaultValue={value}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                />
              ),
            },
            {
              title: LocalizedString.accessoryPage.placeholderVariantId,
              field: 'variantId',
              sorting: false,
              hidden: pageMode === PAGE_MODE_EDIT,
            },
            {
              title: LocalizedString.accessoryPage.placeholderVariantName,
              field: 'name',
              sorting: false,
              // eslint-disable-next-line react/prop-types
              editComponent: ({ value }) => (
                <TextField
                  placeholder={LocalizedString.accessoryPage.placeholderVariantName}
                  label={LocalizedString.accessoryPage.placeholderVariantName}
                  defaultValue={value}
                  fullWidth
                  disabled
                />
              ),
            },
            {
              title: LocalizedString.accessoryPage.placeholderVariantCode,
              field: 'variantCode',
              sorting: false,
              hidden: pageMode === PAGE_MODE_EDIT,
            },
            {
              title: LocalizedString.accessoryPage.placeholderPrice,
              field: 'price',
              sorting: false,
              render: ({ price }) => toCurrency(price),
              hidden: pageMode === PAGE_MODE_EDIT,
            },
            {
              title: LocalizedString.accessoryPage.placeholderDescription,
              field: 'description',
              sorting: false,
              // eslint-disable-next-line react/prop-types
              render: ({ description }) => (
                <TextField
                  placeholder={LocalizedString.accessoryPage.placeholderDescription}
                  label={LocalizedString.accessoryPage.placeholderDescription}
                  variant="outlined"
                  defaultValue={description}
                  disabled
                  fullWidth
                  multiline
                />
              ),
              // eslint-disable-next-line react/prop-types
              editComponent: ({ value, onChange }) => (
                <TextField
                  placeholder={LocalizedString.accessoryPage.placeholderDescription}
                  label={LocalizedString.accessoryPage.placeholderDescription}
                  variant="outlined"
                  defaultValue={value}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                  multiline
                />
              ),
            },
            {
              title: LocalizedString.accessoryPage.placeholderImage,
              field: 'image',
              sorting: false,
              render: (prop) => {
                if (prop) {
                  if (prop.image.content) {
                    return (
                      <Avatar
                        variant="square"
                        src={IMAGE_SOURCE_URI_PREFIX.concat(prop.image.content)}
                      />
                    );
                  }
                  if (prop.image) {
                    return (
                      <Avatar
                        variant="square"
                        src={prop.image}
                      />
                    );
                  } return null;
                }
                return null;
              },
              // eslint-disable-next-line react/prop-types
              editComponent: ({ onChange }) => (
                <ImageInputField
                  useCropper={false}
                  onImageSelected={(path) => onChange({ content: path })}
                />
              ),
            },
            {
              title: LocalizedString.accessoryPage.placeholderStatus,
              field: 'status',
              sorting: false,
              render: ({ status: statusVariants }) => (statusVariants === 'Enabled'
                ? LocalizedString.accessoryPage.placeholderActive
                : LocalizedString.accessoryPage.placeholderInactive),
              hidden: pageMode === PAGE_MODE_EDIT,
            },
          ]}
        />
      </Grid>
    )}
  </Grid>
);

const AccessoryPage = ({
  downloading, handleSubmit, syncing, onAppear, onChangePage,
  onChangePageSize, onRefresh, onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues,
  addingEditing, downloadingDeleting, pageMode, onSavePressed,
  onEditPressed, onCancelPressed, totalCurrentPage, totalCount,
  onActiveOptionSelected, onAddVariantPressed, onApplyAdvancedFilterPressed,
  onResetAdvancedFilterPressed, onDownloadPressed, onSyncFromDMSPressed,
  onAdvancedFilterPressed, vehicleModels, loadingVehicleModel,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_ACCESSORY}
      uiPage={RXSTATE_ACCESSORY_PAGE}
      filterColumns={[
        {
          title: LocalizedString.accessoryPage.placeholderPriceRange,
          field: 'defaultPrice',
          type: FILTER_TYPE_NUMBER_RANGE,
        },
        {
          title: LocalizedString.accessoryPage.placeholderVehicleModel,
          field: 'ltmCode',
          type: FILTER_TYPE_DROPDOWN,
          data: vehicleModels,
          loading: loadingVehicleModel,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.accessoryPage.placeholderStatus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.accessoryPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.accessoryPage.labelProductCode, field: 'productCode', sorting: !downloading },
        { title: LocalizedString.accessoryPage.labelProductName, field: 'productName', sorting: !downloading },
        { title: LocalizedString.accessoryPage.labelVehicleModelCode, field: 'ltmCode', sorting: !downloading },
        { title: LocalizedString.accessoryPage.labelVehicleModelName, field: 'ltmName', sorting: !downloading },
        {
          title: LocalizedString.accessoryPage.labelPrice,
          field: 'defaultPrice',
          sorting: !downloading,
          render: ({ defaultPrice }) => (`Rp${toCurrency(defaultPrice)}`),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.accessoryPage.title}
      editButtonCaption={LocalizedString.accessoryPage.buttonCaptionEditAccessory}
      onEditPressed={onEditPressed}
      onSavePressed={onSavePressed}
      useFullWidth
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
      onCancelPressed={onCancelPressed}
      disableCreate
      disableDelete
      enableSave
      onDownloadPressed={onDownloadPressed}
      renderCustomUpperButton={() => (
        <AccentButton
          onClick={onSyncFromDMSPressed}
          caption={LocalizedString.accessoryPage.buttonCaptionSyncFromDMS}
          variant="outlined"
          style={classes.button}
          loading={syncing}
        />
      )}
    >
      {renderDialogContent(
        initialValues, classes, addingEditing, downloadingDeleting, pageMode,
        totalCurrentPage, totalCount, onActiveOptionSelected,
        onAddVariantPressed,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_ACCESSORY,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxFormValidateAccessory,
})(AccessoryPage);

AccessoryPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onActiveOptionSelected: PropTypes.func.isRequired,
  onAddVariantPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  totalCurrentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageMode: PropTypes.string.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  syncing: PropTypes.bool.isRequired,
  onSyncFromDMSPressed: PropTypes.func.isRequired,
  vehicleModels: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingVehicleModel: PropTypes.bool.isRequired,
};
