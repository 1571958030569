import {
  downloadingDeletingAuthPasswordResetAttempt,
  addAuthPasswordResetAttempt,
} from '../simple-action';
import { downloadAuthPasswordResetAttempt } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiAuthPasswordResetAttempt } = getState();

  const { token } = authentication;
  const { tappedId } = uiAuthPasswordResetAttempt;

  try {
    dispatch(downloadingDeletingAuthPasswordResetAttempt(true));

    const passwordResetAttempt = await downloadAuthPasswordResetAttempt(
      tappedId,
      token,
    );

    dispatch(addAuthPasswordResetAttempt(passwordResetAttempt));
  } finally {
    dispatch(downloadingDeletingAuthPasswordResetAttempt(false));
  }
};
