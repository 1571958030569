import { downloadingCompletedChallenges, setCompletedChallenge } from '../simple-action';
import { downloadCompletedChallenges, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, completedChallenges, uiCompletedChallenge } = getState();
  const { token } = authentication;
  const { meta } = completedChallenges;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiCompletedChallenge;

  try {
    dispatch(downloadingCompletedChallenges(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCompletedChallenges(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setCompletedChallenge(list));
    }
  } finally {
    dispatch(downloadingCompletedChallenges(false));
  }
};
