import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch,
} from '../../helper';
import {
  INITIAL_ORDER_BY_GIIAS_SURABAYA_TRANSACTION,
  MENUID_GIIAS_SURABAYA_TRANSACTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import Giias2022TransactionPage from './giias-surabaya-transaction.presentation';
import {
  clearGiiasSurabaya2022Transactions, setGiiasSurabaya2022TransactionAdvancedFilterString,
  setGiiasSurabaya2022TransactionSearchText, setGiiasSurabaya2022TransactionSelectedOrderBy,
  setGiiasSurabaya2022TransactionSelectedPageSize, setGiiasSurabaya2022TransactionTappedId,
  downloadGiiasSurabaya2022TransactionAsync,
  downloadGiiasSurabaya2022TransactionsAsync,
  saveGiiasSurabaya2022TransactionsAsync,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { giiasSurabayaTransactions, uiFunctionalPage, uiGiiasSurabayaTransaction } = state;
  const { data } = giiasSurabayaTransactions;
  const { downloadingDeleting, tappedId } = uiGiiasSurabayaTransaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiGiiasSurabayaTransaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_SURABAYA_TRANSACTION));
    dispatch(setGiiasSurabaya2022TransactionAdvancedFilterString(''));
    dispatch(setGiiasSurabaya2022TransactionSearchText(''));
    dispatch(clearGiiasSurabaya2022Transactions());
    dispatch(setGiiasSurabaya2022TransactionSelectedPageSize(20));
    dispatch(
      setGiiasSurabaya2022TransactionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_SURABAYA_TRANSACTION),
    );
    dispatch(downloadGiiasSurabaya2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiiasSurabaya2022TransactionSelectedOrderBy(orderBy));
    dispatch(clearGiiasSurabaya2022Transactions());
    dispatch(downloadGiiasSurabaya2022TransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiiasSurabaya2022TransactionTappedId(id));
    dispatch(downloadGiiasSurabaya2022TransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setGiiasSurabaya2022TransactionSearchText(text));
    dispatch(clearGiiasSurabaya2022Transactions());
    dispatch(downloadGiiasSurabaya2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiiasSurabaya2022TransactionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiiasSurabaya2022TransactionSelectedPageSize(pageSize));
    dispatch(downloadGiiasSurabaya2022TransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiiasSurabaya2022TransactionSelectedPageSize(pageSize));
    dispatch(clearGiiasSurabaya2022Transactions());
    dispatch(downloadGiiasSurabaya2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGiiasSurabaya2022Transactions());
    dispatch(setGiiasSurabaya2022TransactionAdvancedFilterString(''));
    dispatch(
      setGiiasSurabaya2022TransactionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_SURABAYA_TRANSACTION),
    );
    dispatch(downloadGiiasSurabaya2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id');

    dispatch(setGiiasSurabaya2022TransactionAdvancedFilterString(text));
    dispatch(clearGiiasSurabaya2022Transactions());
    dispatch(downloadGiiasSurabaya2022TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveGiiasSurabaya2022TransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Giias2022TransactionPage);
