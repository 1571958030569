import { downloadTestDriveEoTestVehicles, transformSearchText } from '../../../helper';
import { downloadingTestDriveEoTestVehicles, setTestDriveEoTestVehicles } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, testDriveEoTestVehicles, uiTestDriveEoTestVehicle } = getState();
  const { token } = authentication;
  const { meta } = testDriveEoTestVehicles;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiTestDriveEoTestVehicle;

  try {
    dispatch(downloadingTestDriveEoTestVehicles(true));
    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadTestDriveEoTestVehicles(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setTestDriveEoTestVehicles(list));
    }
  } finally {
    dispatch(downloadingTestDriveEoTestVehicles(false));
  }
};
