import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT,
  RXFORM_AUTH_REGISTRATION_ATTEMPT, RXSTATE_AUTH_REGISTRATION_ATTEMPTS,
  RXSTATE_AUTH_REGISTRATION_ATTEMPT_PAGE,
  FILTER_TYPE_TEXT,
  FILTER_TYPE_DROPDOWN,
  REGISTER_USING_DROPDOWN_SELECTION,
  FILTER_TYPE_SWITCH,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_USERNAME,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_EMAIL,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_PHONE,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_REFERRAL_CODE,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_DEVICE,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_DEVICE_ID,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_OS,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_APP_VERSION,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_LANGUAGE,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_REGISTER_USING,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_PIN,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_RESEND_PIN_COUNT,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_INVALID_PIN_COUNT,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_VALID_UNTIL,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_VERIFIED,
  RXFIELD_AUTH_REGISTRATION_ATTEMPT_VERIFIED_ON,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_USERNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderUserName}
            label={LocalizedString.registrationAttemptScreen.placeholderUserName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderEmail}
            label={LocalizedString.registrationAttemptScreen.placeholderEmail}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderPhone}
            label={LocalizedString.registrationAttemptScreen.placeholderPhone}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_REFERRAL_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderReferralCode}
            label={LocalizedString.registrationAttemptScreen.placeholderReferralCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_DEVICE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderDeviceID}
            label={LocalizedString.registrationAttemptScreen.placeholderDeviceID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_DEVICE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderDevice}
            label={LocalizedString.registrationAttemptScreen.placeholderDevice}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_OS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderOS}
            label={LocalizedString.registrationAttemptScreen.placeholderOS}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_APP_VERSION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderAppVersion}
            label={LocalizedString.registrationAttemptScreen.placeholderAppVersion}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_LANGUAGE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderLanguage}
            label={LocalizedString.registrationAttemptScreen.placeholderLanguage}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_REGISTER_USING}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderRegisterUsing}
            label={LocalizedString.registrationAttemptScreen.placeholderRegisterUsing}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_PIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderPIN}
            label={LocalizedString.registrationAttemptScreen.placeholderPIN}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_RESEND_PIN_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderResendPINCount}
            label={LocalizedString.registrationAttemptScreen.placeholderResendPINCount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_INVALID_PIN_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderInvalidPINCount}
            label={LocalizedString.registrationAttemptScreen.placeholderInvalidPINCount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_VALID_UNTIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderValidUntil}
            label={LocalizedString.registrationAttemptScreen.placeholderValidUntil}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_VERIFIED}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderVerified}
            label={LocalizedString.registrationAttemptScreen.placeholderVerified}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_AUTH_REGISTRATION_ATTEMPT_VERIFIED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.registrationAttemptScreen.placeholderVerifiedOn}
            label={LocalizedString.registrationAttemptScreen.placeholderVerifiedOn}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const AuthRegistrationAttemptPage = ({
  onAppear, downloading, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_AUTH_REGISTRATION_ATTEMPTS}
    uiPage={RXSTATE_AUTH_REGISTRATION_ATTEMPT_PAGE}
    tableColumns={[
      {
        title: LocalizedString.registrationAttemptScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.registrationAttemptScreen.labelUserName, field: 'username', sorting: !downloading },
      { title: LocalizedString.registrationAttemptScreen.labelEmail, field: 'email', sorting: !downloading },
      { title: LocalizedString.registrationAttemptScreen.labelPhone, field: 'phone', sorting: !downloading },
      { title: LocalizedString.registrationAttemptScreen.labelRegisterUsing, field: 'registerUsing', sorting: !downloading },
      {
        title: LocalizedString.registrationAttemptScreen.labelVerified,
        field: 'verified',
        render: ({ verified }) => (
          verified === true
            ? LocalizedString.common.labelYes
            : LocalizedString.common.labelNo
        ),
        sorting: !downloading,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelDevice,
        field: 'device',
        sorting: false,
        render: ({ manufacturer, model }) => `${manufacturer} ${model}`,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelOS,
        field: 'os',
        sorting: false,
        render: ({ osName, osVersion }) => `${osName} ${osVersion}`,
      },
      { title: LocalizedString.registrationAttemptScreen.labelAppVersion, field: 'appVersion', sorting: !downloading },
      {
        title: LocalizedString.registrationAttemptScreen.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.registrationAttemptScreen.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelValidUntil,
        field: 'validUntil',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelUserName,
        field: 'username',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelEmail,
        field: 'email',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelPhone,
        field: 'phone',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelRegisterUsing,
        field: 'registerUsing',
        type: FILTER_TYPE_DROPDOWN,
        data: REGISTER_USING_DROPDOWN_SELECTION,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelVerified,
        field: 'verified',
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelReferralCode,
        field: 'referralCode',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.registrationAttemptScreen.labelDeviceID,
        field: 'deviceId',
        type: FILTER_TYPE_TEXT,
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.registrationAttemptScreen.title}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_AUTH_REGISTRATION_ATTEMPT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AuthRegistrationAttemptPage);

AuthRegistrationAttemptPage.propTypes = {
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
};
