import { downloadingChargingStationCities, setChargingStationCities } from '../simple-action';
import { downloadChargingStationCities } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingChargingStationCities(true));

    const list = await downloadChargingStationCities(token);
    if (list) {
      dispatch(setChargingStationCities(list));
    }
  } finally {
    dispatch(downloadingChargingStationCities(false));
  }
};
