import {
  downloadAuthPasswordResetAttempts,
  transformSearchText,
} from '../../../helper';
import {
  downloadingAuthPasswordResetAttempts,
  setAuthPasswordResetAttempts,
} from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication,
    authPasswordResetAttempts,
    uiAuthPasswordResetAttempt,
  } = getState();
  const { token } = authentication;
  const { meta } = authPasswordResetAttempts;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiAuthPasswordResetAttempt;

  try {
    dispatch(downloadingAuthPasswordResetAttempts(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAuthPasswordResetAttempts(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setAuthPasswordResetAttempts(list));
    }
  } finally {
    dispatch(downloadingAuthPasswordResetAttempts(false));
  }
};
