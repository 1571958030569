import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_GIIAS_SURABAYA_SCANNED_CODES = '/giiassby2022/api/v1/ScannedCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_GIIAS_SURABAYA_VIEW_DELETE_SCANNED_CODE = '/giiassby2022/api/v1/ScannedCode/{id}';
export const REST_URL_GIIAS_SURABAYA_PRIZE_REDEMPTIONS = '/giiassby2022/api/v1/PrizeRedemption?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_GIIAS_SURABAYA_PRIZE_REDEMPTION = '/giiassby2022/api/v1/PrizeRedemption/{id}';
export const REST_URL_GIIAS_SURABAYA_TRANSACTIONS = '/giiassby2022/api/v1/Transaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_GIIAS_SURABAYA_TRANSACTION = '/giiassby2022/api/v1/Transaction/{id}';

export const ROUTE_NAME_GIIAS_SURABAYA_2022 = '/giias-surabaya-2022';
export const ROUTE_NAME_GIIAS_SURABAYA_SCANNED_CODE = '/giias-surabaya-scanned-code';
export const ROUTE_NAME_GIIAS_SURABAYA_TRANSACTION = '/giias-surabaya-transaction';
export const ROUTE_NAME_GIIAS_SURABAYA_PRIZE_REDEMPTION = '/giias-surabaya-prize-redemption';

export const MENUID_GIIAS_SURABAYA_2022 = 'MENUID_GIIAS_SURABAYA_2022';
export const MENUID_GIIAS_SURABAYA_SCANNED_CODE = 'MENUID_GIIAS_SURABAYA_SCANNED_CODE';
export const MENUID_GIIAS_SURABAYA_PRIZE_REDEMPTION = 'MENUID_GIIAS_SURABAYA_PRIZE_REDEMPTION';
export const MENUID_GIIAS_SURABAYA_TRANSACTION = 'MENUID_GIIAS_SURABAYA_TRANSACTION';

export const RXSTATE_GIIAS_SURABAYA_SCANNED_CODES = 'giiasSurabayaScannedCodes';
export const RXSTATE_GIIAS_SURABAYA_SCANNED_CODE_PAGE = 'uiGiiasSurabayaScannedCode';
export const RXSTATE_GIIAS_SURABAYA_PRIZE_REDEMPTIONS = 'giiasSurabayaPrizeRedemptions';
export const RXSTATE_GIIAS_SURABAYA_PRIZE_REDEMPTION = 'uiGiiasSurabayaPrizeRedemption';

export const INITIAL_ORDER_BY_GIIAS_SURABAYA_SCANNED_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_GIIAS_SURABAYA_PRIZE_REDEMPTION = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_GIIAS_SURABAYA_TRANSACTION = `createdDate ${ORDER_SORT_DESCENDING}`;

export const RXFIELD_GIIAS_SURABAYA_SCANNED_CODE_TIME = 'createdDate';
export const RXFIELD_GIIAS_SURABAYA_SCANNED_CODE_USERNAME = 'scanCodeUsername';
export const RXFIELD_GIIAS_SURABAYA_SCANNED_CODE_CHALLANGE_CODE_LABEL = 'challengeCodeLabel';
export const RXFIELD_GIIAS_SURABAYA_SCANNED_CODE_CHALLANGE_USER_NAME = 'user.fullName';
export const RXFIELD_GIIAS_SURABAYA_SCANNED_CODE_CHALLANGE_USER_ID = 'user.id';
export const RXFIELD_GIIAS_SURABAYA_CHALLANGE_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_DESCRIPTION = 'description';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_POINT = 'point';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_PRIZE_TAG = 'prizeTag';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_USER = 'user';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_USER_FULLNAME = 'fullName';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_USER_ID = 'userId';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_VOUCHER = 'voucher';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_VOUCHER_ID = 'voucherId';
export const RXFIELD_GIIAS_SURABAYA_PRIZE_REDEMPTION_VOUCHER_TITLE = 'voucherTitle';
export const RXSTATE_GIIAS_SURABAYA_PRIZE_TRANSACTIONS = 'giiasSurabayaTransactions';
export const RXSTATE_GIIAS_SURABAYA_PRIZE_TRANSACTION = 'uiGiiasSurabayaTransaction';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_AMOUNT = 'amount';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_LABEL = 'label';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_REFERENCE_ID = 'referenceId';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_REMAINING_BALANCE = 'remainingBalance';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_TYPE = 'type';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_USER = 'user';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_USER_FULLNAME = 'fullName';
export const RXFIELD_GIIAS_SURABAYA_TRANSACTION_USER_ID = 'userId';

export const RXFORM_GIIAS_SURABAYA_SCANNED_CODE = 'giassSurabayaScannedCodePage';
export const RXFORM_GIIAS_SURABAYA_PRIZE_REDEMPTION = 'giassSurabayaPrizeRedemptionPage';
export const RXFORM_GIIAS_SURABAYA_TRANSACTION = 'giassSurabayaTransactionPage';
