import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import {
  INITIAL_ORDER_BY_POINT_TRANSACTIONS, INITIAL_ORDER_BY_PROFILES, MENUID_POINT_ACHIEVEMENT,
  REGEX_MATCH_STRING_WITH_SPACES,
} from '../../constant';
import {
  debounceSearch, toCurrency, toMoment, toUtcMoment, transformInitialValues,
  transformUserDropdownData,
} from '../../helper';
import {
  clearPointAchievement, clearPointBalance, clearPointTransactions, clearProfiles,
  setPointAchievementTappedId, setActiveSideMenuItem, setAlertErrorMessage,
  setPointTransactionAdvancedFilterDialogSelectedFilterString, setPointTransactionSearchText,
  setPointTransactionSelectedOrderBy, setPointTransactionSelectedPageSize,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  setProfileSelectedPageSize, setProfileSelectedOrderBy,
  downloadPointAchievementAsync, downloadPointBalanceAsync, downloadPointTransactionsAsync,
} from '../../redux/action';
import { downloadProfileAsync, downloadProfilesAsync } from '../../../../redux/action';
import AchievementPage from './achievement.presentation';

const getInitialValues = (state) => {
  const {
    pointTransactions, profiles, uiPointAchievement, uiPointTransaction, uiProfile,
  } = state;
  const { data } = profiles;
  const { tappedId } = uiPointAchievement;
  const { data: pointTransactionsData, meta } = pointTransactions;
  const { downloading, selectedPageSize } = uiPointTransaction;
  const { downloadingDeleting } = uiProfile;

  const found = tappedId ? data[tappedId] : {};
  const initVal = !isEmpty(found) && !downloadingDeleting ? transformInitialValues(found, {
    transactionHistory: Object.values(pointTransactionsData).length > 0 && !downloading
      ? Object.values(pointTransactionsData).map((x, i) => ({
        ...x,
        no: i + 1 + ((meta.currentPage - 1) * selectedPageSize),
        fullName: x.user.fullName,
      })) : [],
  }) : { transactionHistory: [] };
  return initVal;
};

const mapStateToProps = (state) => ({
  downloadingPointAchievement: state.uiPointAchievement.downloadingPointAchievement,
  downloadingPointBalance: state.uiPointAchievement.downloadingPointBalance,
  downloadingTransactionHistory: state.uiPointTransaction.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  downloadingProfile: state.uiProfile.downloadingDeleting,
  profiles: transformUserDropdownData(state.profiles.data),
  currentTransactionHistoryPage: state.pointTransactions.meta.currentPage,
  totalTransactionHistoryCount: state.pointTransactions.meta.totalCount,
  achievement: !isEmpty(state.pointAchievement) ? toCurrency(state.pointAchievement.amount) : 0,
  balance: !isEmpty(state.pointBalance) ? toCurrency(state.pointBalance.balance) : 0,
  rank: !isEmpty(state.pointAchievement) > 0 ? toCurrency(state.pointAchievement.rank) : 0,
  tappedId: state.uiPointAchievement.tappedId,
  initialValues: getInitialValues(state),
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_ACHIEVEMENT));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedPageSize(20));
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: (text) => {
    if (text.match(REGEX_MATCH_STRING_WITH_SPACES) || !text) {
      dispatch(setProfileSearchText(text));
      if (text.length >= 3 || text.length === 0) {
        searchUserDebounce(dispatch, true);
      }
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      const { value } = option;
      const currentUtcTime = toUtcMoment(toMoment(), moment.tz.guess());

      dispatch(setPointAchievementTappedId(value));
      dispatch(downloadProfileAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
      dispatch(setPointTransactionAdvancedFilterDialogSelectedFilterString(`user.id=${value}|effectiveDate<=${currentUtcTime}`));
      dispatch(setPointTransactionSearchText(''));
      dispatch(clearPointAchievement());
      dispatch(clearPointBalance());
      dispatch(clearPointTransactions());
      dispatch(setPointTransactionSelectedPageSize(50));
      dispatch(setPointTransactionSelectedOrderBy(INITIAL_ORDER_BY_POINT_TRANSACTIONS));
      dispatch(downloadPointAchievementAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
      dispatch(downloadPointBalanceAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
      dispatch(downloadPointTransactionsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AchievementPage);
