import {
  addCarViewingEvent, clearCarViewingEvents, downloadingDeletingCarViewingEvent,
  setFunctionalPageMode, setCarViewingEventTappedId, setAlertErrorMessage,
} from '../simple-action';
import { downloadCarViewingEvent, deleteCarViewingEvent } from '../../../helper';
import { PAGE_MODE_TABLE, PAGE_MODE_VIEW } from '../../../constant';
import downloadCarViewingEventsAsync from './downloadCarViewingEventsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCarViewingEvent(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCarViewingEvent;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteCarViewingEvent(tappedId, token);
      dispatch(setCarViewingEventTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCarViewingEvents());
      dispatch(downloadCarViewingEventsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const faqCategory = await downloadCarViewingEvent(tappedId, token);
      dispatch(addCarViewingEvent(faqCategory));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingCarViewingEvent(false));
  }
};
