import { sendGetRequest, convertArrToObj } from '../../helper';
import {
  REST_URL_BOOKING_CREATED,
  REST_URL_BOOKING_PROCEED_BY_DEALER,
  REST_URL_BOOKING_WAITING_FOR_PAYMENT,
  REST_URL_BOOKING_COMPLETED,
  REST_URL_BOOKING_CANCELED,
  REST_URL_BOOKING_CREATED_BY_REPAIR_SELECTION,
  REST_URL_BOOKING_CREATED_BY_DELIVERY_METHOD,
  REST_URL_BOOKING_CREATED_BY_SERVICE_INTERVAL,
  REST_URL_BOOKING_CREATED_BY_VEHICLE_MODEL,
  REST_URL_TRANSACTION_RECEIVED,
  REST_URL_TRANSACTION_PENDING,
  REST_URL_TRANSACTION_PAID,
  REST_URL_TRANSACTION_CANCELED,
  REST_URL_TRANSACTION_AMOUNT,
  REST_URL_TRANSACTION_COUNT_BY_PART_NAME,
  REST_URL_TRANSACTION_AMOUNT_BY_PART_NAME,
  REST_URL_REMAINING_STOCK_BY_PART_NAME,
  REST_URL_TRANSACTION_CREATED_BY_VEHICLE_MODEL,
} from './constant';

export * from '../../helper';

export const downloadBookingCreated = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_CREATED
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadBookingProceedByDealer = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_PROCEED_BY_DEALER
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadBookingWaitForPayment = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_WAITING_FOR_PAYMENT
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadBookingCompleted = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_COMPLETED
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadBookingCanceled = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_CANCELED
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadBookingCreatedByRepairSelection = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_CREATED_BY_REPAIR_SELECTION
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};

export const downloadBookingCreatedByDeliveryMethod = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_CREATED_BY_DELIVERY_METHOD
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};

export const downloadBookingCreatedByServiceInterval = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_CREATED_BY_SERVICE_INTERVAL
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};

export const downloadBookingCreatedByVehicleModel = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_BOOKING_CREATED_BY_VEHICLE_MODEL
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};

export const downloadTransactionReceived = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_RECEIVED
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadTransactionPending = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_PENDING
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadTransactionPaid = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_PAID
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadTransactionCanceled = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_CANCELED
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadTransactionAmount = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_AMOUNT
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadTransactionCountByPartName = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_COUNT_BY_PART_NAME
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};

export const downloadTransactionAmountByPartName = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_AMOUNT_BY_PART_NAME
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};

export const downloadRemaningStockByPartName = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_REMAINING_STOCK_BY_PART_NAME
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};

export const downloadTransactionCreatedByVehicleModel = async (
  token, startDate, endDate,
) => {
  const url = REST_URL_TRANSACTION_CREATED_BY_VEHICLE_MODEL
    .replace(/\{startDate\}/, startDate)
    .replace(/\{endDate\}/, endDate);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, 'name');
};
