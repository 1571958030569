import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_CAR_VIEWING_EVENTS, MENUID_CAR_VIEWING_EVENT, PAGE_MODE_TABLE,
  RXFORM_CAR_VIEWING_EVENT, REST_URL_IMAGE_STORAGE, REST_BASE_URL, RXFIELD_CAR_VIEWING_EVENT_IMAGE,
} from '../../constant';
import { transformDropdownData, transformInitialValues, debounceSearch } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearCarViewingEvents, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setCarViewingEventSearchText, setCarViewingEventSelectedPageSize,
  setCarViewingEventTappedId, setCarViewingEventSelectedOrderBy,
  addEditCarViewingEventAsync, downloadDeleteCarViewingEventAsync, downloadCarViewingEventsAsync,
  setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText, clearCities,
  downloadCitiesAsync, setCarViewingEventSelectedCityId, setCarViewingEventSelectedVoucherRewardId,
  setCarViewingEventAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  downloadVouchersAsync,
  setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText, clearVouchers,
} from '../../../voucher';
import CarViewingEventPage from './car-viewing-event.presentation';

const getInitialValues = (state) => {
  const { carViewingEvents, uiFunctionalPage, uiCarViewingEvent } = state;
  const { data } = carViewingEvents;
  const { downloadingDeleting, tappedId } = uiCarViewingEvent;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    city: !isEmpty(found.city) ? { label: found.city.name, value: found.city.id } : null,
    image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
    voucherRewards: found.voucherRewards ? {
      label: found.voucherRewards[0].voucherName,
      value: found.voucherRewards[0].voucherId,
    } : null,
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiCarViewingEvent.addingEditing,
  downloading: state.uiCarViewingEvent.downloading,
  initialValues: getInitialValues(state),
  cities: transformDropdownData(state.cities.data),
  loadingCities: state.uiCity.downloading,
  vouchers: Object.values(state.vouchers.data).map((item) => ({
    label: item.title,
    value: item.id,
  })),
  loadingVouchers: state.uiVoucher.downloading,
});

const searchCityDebounce = debounceSearch((dispatch) => {
  dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
  dispatch(clearCities());
  dispatch(downloadCitiesAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});
const searchVoucherDebounce = debounceSearch((dispatch) => {
  dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
  dispatch(clearVouchers());
  dispatch(downloadVouchersAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_VIEWING_EVENT));
    dispatch(setCarViewingEventSearchText(''));
    dispatch(clearCarViewingEvents());
    dispatch(setCarViewingEventSelectedPageSize(20));
    dispatch(setCarViewingEventSelectedOrderBy(INITIAL_ORDER_BY_CAR_VIEWING_EVENTS));
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CAR_VIEWING_EVENT));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCarViewingEventsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCarViewingEventSelectedPageSize(pageSize));
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteCarViewingEventAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCreatePressed: () => {
    dispatch(setCarViewingEventTappedId(''));
    dispatch(reset(RXFORM_CAR_VIEWING_EVENT));

    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDeletePressed: (id) => {
    dispatch(setCarViewingEventTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setCarViewingEventTappedId(id));
    dispatch(setCarViewingEventSelectedCityId(''));
    dispatch(setCarViewingEventSelectedVoucherRewardId(''));
  },
  onRefresh: (pageSize) => {
    dispatch(setCarViewingEventSelectedPageSize(pageSize));
    dispatch(clearCarViewingEvents());
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSavePressed: async ({
    name, description, location, startDate, endDate,
    openTime, closeTime, visibility, city,
    voucherRewards, image, quotaPerSlot,
  }) => {
    try {
      await dispatch(addEditCarViewingEventAsync(name, description, location, startDate, endDate,
        openTime, closeTime, visibility, city, voucherRewards, image, quotaPerSlot));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCarViewingEventSearchText(text));
      dispatch(clearCarViewingEvents());
      await dispatch(downloadCarViewingEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCarViewingEventSelectedOrderBy(orderBy));
    dispatch(clearCarViewingEvents());
    dispatch(downloadCarViewingEventsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSubmitPressed: async ({
    name, description, location, startDate, endDate,
    openTime, closeTime, visibility, city,
    voucherRewards, image, quotaPerSlot,
  }) => {
    await dispatch(addEditCarViewingEventAsync(name, description, location, startDate, endDate,
      openTime, closeTime, visibility, city, voucherRewards, image, quotaPerSlot));
  },
  onViewPressed: (id) => {
    dispatch(setCarViewingEventTappedId(id));
  },
  onChangeVoucherText: async (text) => {
    dispatch(setVoucherSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchVoucherDebounce(dispatch);
    }
  },
  onChangeCityText: async (text) => {
    dispatch(setCitySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchCityDebounce(dispatch);
    }
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_CAR_VIEWING_EVENT, RXFIELD_CAR_VIEWING_EVENT_IMAGE, image));
  },
  onCitySelected: (option) => {
    if (option) {
      dispatch(setCarViewingEventSelectedCityId(option.value));
    } else {
      dispatch(setCarViewingEventSelectedCityId(''));
    }
  },
  onVoucherRewardsSelected: (option) => {
    if (option) {
      dispatch(setCarViewingEventSelectedVoucherRewardId(option.value));
    } else {
      dispatch(setCarViewingEventSelectedVoucherRewardId(''));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('city', 'city.id');
    dispatch(setCarViewingEventAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCarViewingEvents());
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_CAR_VIEWING_EVENT));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCarViewingEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCarViewingEventSelectedOrderBy(INITIAL_ORDER_BY_CAR_VIEWING_EVENTS));
    dispatch(clearCarViewingEvents());
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarViewingEventPage);
