import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_GIIAS_SURABAYA_PRIZE_REDEMPTIONS,
  REST_URL_GIIAS_SURABAYA_SCANNED_CODES, REST_URL_GIIAS_SURABAYA_TRANSACTIONS,
  REST_URL_GIIAS_SURABAYA_VIEW_DELETE_SCANNED_CODE,
  REST_URL_SAVE_FILE,
  REST_URL_VIEW_GIIAS_SURABAYA_PRIZE_REDEMPTION,
  REST_URL_VIEW_GIIAS_SURABAYA_TRANSACTION,
} from './constant';

export * from '../../helper';

export const downloadGiiasSurabayaScannedCodes = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_GIIAS_SURABAYA_SCANNED_CODES
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_GIIAS_SURABAYA_SCANNED_CODES, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiiasSurabayaScannedCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_GIIAS_SURABAYA_VIEW_DELETE_SCANNED_CODE
    .replace(/\{id\}/, id), token);
  return response;
};

export const saveGiiasSurabaya2022ScannedCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_SURABAYA_SCANNED_CODES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadGiiasSurabayaPrizeRedemptions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_GIIAS_SURABAYA_PRIZE_REDEMPTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiiasSurabayaPrizeRedemption = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GIIAS_SURABAYA_PRIZE_REDEMPTION.replace(/\{id\}/, id), token);
  return response;
};

export const downloadGiiasSurabayaTransactions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_GIIAS_SURABAYA_TRANSACTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiiasSurabayaTransaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GIIAS_SURABAYA_TRANSACTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveGiiasSurabayaTransactions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_SURABAYA_TRANSACTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const saveGiiasSurabayaPrizeRedemptions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_SURABAYA_PRIZE_REDEMPTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};
