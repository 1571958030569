
import { INITIAL_ORDER_BY_PROMO_CODES } from '../../constant';
import {
  DOWNLOADING_PROMO_CODES,
  DOWNLOADING_DELETING_PROMO_CODE,
  ADDING_EDITING_PROMO_CODE,
  ADDING_PROMO_CODE_VOUCHERS,
  SAVING_PROMO_CODES,
  SET_PROMO_CODE_SELECTED_AIRPORT,
  SET_PROMO_CODE_SEARCH_TEXT,
  SET_PROMO_CODE_SELECTED_ORDER_BY,
  SET_PROMO_CODE_SELECTED_PAGE_SIZE,
  SET_PROMO_CODE_TAPPED_ID,
  SET_PROMO_CODE_SELECTED_MERCHANT,
  SET_PROMO_CODE_SELECTED_REWARD,
  SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE,
  SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT,
  SET_PROMO_CODE_TABLE_DATA_VOUCHERS,
  SET_PROMO_CODE_SELECTED_VOUCHERS,
  SET_PROMO_CODE_USER_TYPES,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  addingEditing: false,
  addingVouchers: false,
  saving: false,
  selectedAirport: {},
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PROMO_CODES,
  selectedPageSize: 20,
  selectedMerchant: null,
  selectedVoucherBypassMaxPurchase: null,
  selectedVoucherBypassAchievement: null,
  selectedReward: null,
  selectedVouchers: null,
  tableDataVouchers: [],
  selectedUserTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PROMO_CODES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_PROMO_CODE:
      return { ...state, downloadingDeleting: action.status };
    case ADDING_EDITING_PROMO_CODE:
      return { ...state, addingEditing: action.status };
    case ADDING_PROMO_CODE_VOUCHERS:
      return { ...state, addingVouchers: action.status };
    case SET_PROMO_CODE_SELECTED_AIRPORT:
      return { ...state, selectedAirport: action.option };
    case SET_PROMO_CODE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PROMO_CODE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PROMO_CODE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PROMO_CODE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PROMO_CODE_SELECTED_MERCHANT:
      return { ...state, selectedMerchant: action.value };
    case SET_PROMO_CODE_SELECTED_REWARD:
      return { ...state, selectedReward: action.value };
    case SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE:
      return { ...state, selectedVoucherBypassMaxPurchase: action.value };
    case SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT:
      return { ...state, selectedVoucherBypassAchievement: action.value };
    case SET_PROMO_CODE_TABLE_DATA_VOUCHERS:
      return { ...state, tableDataVouchers: action.data };
    case SET_PROMO_CODE_SELECTED_VOUCHERS:
      return { ...state, selectedVouchers: action.option };
    case SET_PROMO_CODE_USER_TYPES:
      return { ...state, selectedUserTypes: action.option };
    case SAVING_PROMO_CODES:
      return { ...state, saving: action.status };
    default: return state;
  }
};
