import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_HYUNDAI_CHALLENGE_SCANNED_CODES = '/hyundaichallenge2023/api/v1/ScannedCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_HYUNDAI_CHALLENGE_VIEW_DELETE_SCANNED_CODE = '/hyundaichallenge2023/api/v1/ScannedCode/{id}';
export const REST_URL_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS = '/hyundaichallenge2023/api/v1/PrizeRedemption?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = '/hyundaichallenge2023/api/v1/PrizeRedemption/{id}';
export const REST_URL_HYUNDAI_CHALLENGE_TRANSACTIONS = '/hyundaichallenge2023/api/v1/Transaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_HYUNDAI_CHALLENGE_TRANSACTION = '/hyundaichallenge2023/api/v1/Transaction/{id}';

export const ROUTE_NAME_HYUNDAI_CHALLENGE = '/hyundai-challenge';
export const ROUTE_NAME_HYUNDAI_CHALLENGE_SCANNED_CODE = '/hyundai-chellenge-scanned-code';
export const ROUTE_NAME_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = '/hyundai-chellenge-prize-redemption';
export const ROUTE_NAME_HYUNDAI_CHALLENGE_TRANSACTION = '/hyundai-chellenge-transaction';

export const MENUID_HYUNDAI_CHALLENGE = 'MENUID_HYUNDAI_CHALLENGE';
export const MENUID_HYUNDAI_CHALLENGE_SCANNED_CODE = 'MENUID_HYUNDAI_CHALLENGE_SCANNED_CODE';
export const MENUID_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = 'MENUID_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION';
export const MENUID_HYUNDAI_CHALLENGE_TRANSACTION = 'MENUID_HYUNDAI_CHALLENGE_TRANSACTION';

export const RXSTATE_HYUNDAI_CHALLENGE_SCANNED_CODES = 'hyundaiChallengeScannedCodes';
export const RXSTATE_HYUNDAI_CHALLENGE_SCANNED_CODE_PAGE = 'uiHyundaiChallengeScannedCode';
export const RXSTATE_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS = 'hyundaiChallengePrizeRedemptions';
export const RXSTATE_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = 'uiHyundaiChallengePrizeRedemption';
export const RXSTATE_HYUNDAI_CHALLENGE_TRANSACTIONS = 'hyundaiChallengeTransactions';
export const RXSTATE_HYUNDAI_CHALLENGE_TRANSACTION = 'uiHyundaiChallengeTransaction';

export const INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_SCANNED_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_TRANSACTION = `createdDate ${ORDER_SORT_DESCENDING}`;

export const RXFIELD_HYUNDAI_CHALLENGE_SCANNED_CODE_TIME = 'createdDate';
export const RXFIELD_HYUNDAI_CHALLENGE_SCANNED_CODE_USERNAME = 'scanCodeUsername';
export const RXFIELD_HYUNDAI_CHALLENGE_SCANNED_CODE_CHALLENGE_CODE_LABEL = 'challengeCodeLabel';
export const RXFIELD_HYUNDAI_CHALLENGE_SCANNED_CODE_CHALLENGE_USER_NAME = 'user.fullName';
export const RXFIELD_HYUNDAI_CHALLENGE_SCANNED_CODE_CHALLENGE_USER_ID = 'user.id';
export const RXFIELD_HYUNDAI_CHALLENGE_CHALLENGE_CREATED_DATE = 'createdDate';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_CREATED_DATE = 'createdDate';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_DESCRIPTION = 'description';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_POINT = 'point';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_PRIZE_TAG = 'prizeTag';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_USER = 'user';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_USER_FULLNAME = 'fullName';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_USER_ID = 'userId';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_VOUCHER = 'voucher';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_VOUCHER_ID = 'voucherId';
export const RXFIELD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_VOUCHER_TITLE = 'voucherTitle';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_AMOUNT = 'amount';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_CREATED_DATE = 'createdDate';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_LABEL = 'label';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_REFERENCE_ID = 'referenceId';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_REMAINING_BALANCE = 'remainingBalance';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_TYPE = 'type';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_USER = 'user';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_USER_FULLNAME = 'fullName';
export const RXFIELD_HYUNDAI_CHALLENGE_TRANSACTION_USER_ID = 'userId';

export const RXFORM_HYUNDAI_CHALLENGE_SCANNED_CODE = 'hyundaiChallengeScannedCodePage';
export const RXFORM_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = 'hyundaiChallengePrizeRedemptionPage';
export const RXFORM_HYUNDAI_CHALLENGE_TRANSACTION = 'hyundaiChallengeTransactionPage';
