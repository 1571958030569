import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_TEST_DRIVE_RESERVATION = 'DOWNLOADING_TEST_DRIVE_RESERVATION';
export const downloadingTestDriveReservation = makeActionCreator(DOWNLOADING_TEST_DRIVE_RESERVATION, 'status');

export const DOWNLOADING_DELETING_TEST_DRIVE_RESERVATION = 'DOWNLOADING_DELETING_TEST_DRIVE_RESERVATION';
export const downloadingDeletingTestDriveReservation = makeActionCreator(DOWNLOADING_DELETING_TEST_DRIVE_RESERVATION, 'status');

export const ADD_TEST_DRIVE_RESERVATION = 'ADD_TEST_DRIVE_RESERVATION';
export const addTestDriveReservation = makeActionCreator(ADD_TEST_DRIVE_RESERVATION, 'data');

export const SET_TEST_DRIVE_RESERVATIONS = 'SET_TEST_DRIVE_RESERVATIONS';
export const setTestDriveReservations = makeActionCreator(SET_TEST_DRIVE_RESERVATIONS, 'data');

export const CLEAR_TEST_DRIVE_RESERVATIONS = 'CLEAR_TEST_DRIVE_RESERVATIONS';
export const clearTestDriveReservations = makeActionCreator(CLEAR_TEST_DRIVE_RESERVATIONS);

export const SET_TEST_DRIVE_RESERVATION_SELECTED_PAGE_SIZE = 'SET_TEST_DRIVE_RESERVATION_SELECTED_PAGE_SIZE';
export const setTestDriveReservationSelectedPageSize = makeActionCreator(SET_TEST_DRIVE_RESERVATION_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_DRIVE_RESERVATION_SELECTED_ORDER_BY = 'SET_TEST_DRIVE_RESERVATION_SELECTED_ORDER_BY';
export const setTestDriveReservationSelectedOrderBy = makeActionCreator(SET_TEST_DRIVE_RESERVATION_SELECTED_ORDER_BY, 'order');

export const SET_TEST_DRIVE_RESERVATION_TAPPED_ID = 'SET_TEST_DRIVE_RESERVATION_TAPPED_ID';
export const setTestDriveReservationTappedId = makeActionCreator(SET_TEST_DRIVE_RESERVATION_TAPPED_ID, 'id');

export const SET_TEST_DRIVE_RESERVATION_SEARCH_TEXT = 'SET_TEST_DRIVE_RESERVATION_SEARCH_TEXT';
export const setTestDriveReservationSearchText = makeActionCreator(SET_TEST_DRIVE_RESERVATION_SEARCH_TEXT, 'text');

export const SET_TEST_DRIVE_RESERVATION_ADVANCED_FILTER_STRING = 'SET_TEST_DRIVE_RESERVATION_ADVANCED_FILTER_STRING';
export const setTestDriveReservationAdvancedFilterString = makeActionCreator(SET_TEST_DRIVE_RESERVATION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_TEST_DRIVE_RESERVATION = 'SAVING_TEST_DRIVE_RESERVATION';
export const savingTestDriveReservation = makeActionCreator(SAVING_TEST_DRIVE_RESERVATION, 'status');


export const DOWNLOADING_EVENTS = 'DOWNLOADING_EVENTS';
export const downloadingEvents = makeActionCreator(DOWNLOADING_EVENTS, 'status');

export const SET_EVENTS = 'SET_EVENTS';
export const setEvents = makeActionCreator(SET_EVENTS, 'data');

export const ADD_EVENT = 'ADD_EVENT';
export const addEvent = makeActionCreator(ADD_EVENT, 'data');

export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const clearEvents = makeActionCreator(CLEAR_EVENTS);

export const DOWNLOADING_DELETING_EVENT = 'DOWNLOADING_DELETING_EVENT';
export const downloadingDeletingEvent = makeActionCreator(DOWNLOADING_DELETING_EVENT, 'status');

export const ADDING_EDITING_EVENT = 'ADDING_EDITING_EVENT';
export const addingEditingEvent = makeActionCreator(ADDING_EDITING_EVENT, 'status');


export const SET_EVENT_SELECTED_PAGE_SIZE = 'SET_EVENT_SELECTED_PAGE_SIZE';
export const setEventSelectedPageSize = makeActionCreator(SET_EVENT_SELECTED_PAGE_SIZE, 'size');

export const SET_EVENT_SELECTED_ORDER_BY = 'SET_EVENT_SELECTED_ORDER_BY';
export const setEventSelectedOrderBy = makeActionCreator(SET_EVENT_SELECTED_ORDER_BY, 'order');

export const SET_EVENT_TAPPED_ID = 'SET_EVENT_TAPPED_ID';
export const setEventTappedId = makeActionCreator(SET_EVENT_TAPPED_ID, 'id');

export const SET_EVENT_SEARCH_TEXT = 'SET_EVENT_SEARCH_TEXT';
export const setEventSearchText = makeActionCreator(SET_EVENT_SEARCH_TEXT, 'text');

export const SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEventAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_EVENT_VISIBILITY = 'SET_EVENT_VISIBILITY';
export const setEventVisibility = makeActionCreator(SET_EVENT_VISIBILITY, 'option');

export const SET_EVENT_URL1_EXTENSION_BROWSER = 'SET_EVENT_URL1_EXTENSION_BROWSER';
export const setEventUrl1ExtensionBrowser = makeActionCreator(SET_EVENT_URL1_EXTENSION_BROWSER, 'option');

export const SET_EVENT_URL2_EXTENSION_BROWSER = 'SET_EVENT_URL2_EXTENSION_BROWSER';
export const setEventUrl2ExtensionBrowser = makeActionCreator(SET_EVENT_URL2_EXTENSION_BROWSER, 'option');

export const SET_EVENT_SELECTED_VOUCHER = 'SET_EVENT_SELECTED_VOUCHER';
export const setEventSelectedVoucher = makeActionCreator(SET_EVENT_SELECTED_VOUCHER, 'value');

export const SET_EVENT_SELECTED_PROVINCE_ID = 'SET_EVENT_SELECTED_PROVINCE_ID';
export const setEventSelectedProvinceId = makeActionCreator(SET_EVENT_SELECTED_PROVINCE_ID, 'id');

export const SET_EVENT_SELECTED_CITY_ID = 'SET_EVENT_SELECTED_CITY_ID';
export const setEventSelectedCityId = makeActionCreator(SET_EVENT_SELECTED_CITY_ID, 'id');

export const SET_EVENT_SURVEY_URL_EXTENSION_BROWSER = 'SET_EVENT_SURVEY_URL_EXTENSION_BROWSER';
export const setEventSurveyUrlExtensionBrowser = makeActionCreator(SET_EVENT_SURVEY_URL_EXTENSION_BROWSER, 'option');

export const SET_EVENT_MAX_TEST_DRIVE_TEXT = 'SET_EVENT_MAX_TEST_DRIVE_TEXT';
export const setEventMaxTestDriveText = makeActionCreator(SET_EVENT_MAX_TEST_DRIVE_TEXT, 'text');

export const COPYING_EVENT_CODE = 'COPYING_EVENT_CODE';
export const copyingEventCode = makeActionCreator(COPYING_EVENT_CODE, 'status');


export const ADD_TEST_DRIVE_UNIQUE_CODE = 'ADD_TEST_DRIVE_UNIQUE_CODE';
export const addTestDriveUniqueCode = makeActionCreator(ADD_TEST_DRIVE_UNIQUE_CODE, 'data');

export const CLEAR_TEST_DRIVE_UNIQUE_CODES = 'CLEAR_TEST_DRIVE_UNIQUE_CODES';
export const clearTestDriveUniqueCodes = makeActionCreator(CLEAR_TEST_DRIVE_UNIQUE_CODES);

export const SET_TEST_DRIVE_UNIQUE_CODES = 'SET_TEST_DRIVE_UNIQUE_CODES';
export const setTestDriveUniqueCodes = makeActionCreator(SET_TEST_DRIVE_UNIQUE_CODES, 'data');

export const DOWNLOADING_TEST_DRIVE_UNIQUE_CODE = 'DOWNLOADING_TEST_DRIVE_UNIQUE_CODE';
export const downloadingTestDriveUniqueCode = makeActionCreator(DOWNLOADING_TEST_DRIVE_UNIQUE_CODE, 'status');

export const SET_TEST_DRIVE_UNIQUE_CODE_SELECTED_PAGE_SIZE = 'SET_TEST_DRIVE_UNIQUE_CODE_SELECTED_PAGE_SIZE';
export const setTestDriveUniqueCodeSelectedPageSize = makeActionCreator(SET_TEST_DRIVE_UNIQUE_CODE_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_DRIVE_UNIQUE_CODE_TAPPED_ID = 'SET_TEST_DRIVE_UNIQUE_CODE_TAPPED_ID';
export const setTestDriveUniqueCodeTappedId = makeActionCreator(SET_TEST_DRIVE_UNIQUE_CODE_TAPPED_ID, 'id');

export const SET_TEST_DRIVE_UNIQUE_CODE_SEARCH_TEXT = 'SET_TEST_DRIVE_UNIQUE_CODE_SEARCH_TEXT';
export const setTestDriveUniqueCodeSearchText = makeActionCreator(SET_TEST_DRIVE_UNIQUE_CODE_SEARCH_TEXT, 'text');

export const SET_TEST_DRIVE_UNIQUE_CODE_SELECTED_ORDER_BY = 'SET_TEST_DRIVE_UNIQUE_CODE_SELECTED_ORDER_BY';
export const setTestDriveUniqueCodeSelectedOrderBy = makeActionCreator(SET_TEST_DRIVE_UNIQUE_CODE_SELECTED_ORDER_BY, 'order');

export const DOWNLOADING_DELETING_TEST_DRIVE_UNIQUE_CODE = 'DOWNLOADING_DELETING_TEST_DRIVE_UNIQUE_CODE';
export const downloadingDeletingTestDriveUniqueCode = makeActionCreator(DOWNLOADING_DELETING_TEST_DRIVE_UNIQUE_CODE, 'status');

export const SET_TEST_DRIVE_UNIQUE_CODE_ADVANCED_FILTER_STRING = 'SET_TEST_DRIVE_UNIQUE_CODE_ADVANCED_FILTER_STRING';
export const setTestDriveUniqueCodeAdvancedFilterString = makeActionCreator(SET_TEST_DRIVE_UNIQUE_CODE_ADVANCED_FILTER_STRING, 'text');

export const SAVING_TEST_DRIVE_UNIQUE_CODE = 'SAVING_TEST_DRIVE_UNIQUE_CODE';
export const savingTestDriveUniqueCode = makeActionCreator(SAVING_TEST_DRIVE_UNIQUE_CODE, 'status');


export const DOWNLOADING_TEST_VEHICLES = 'DOWNLOADING_TEST_VEHICLES';
export const downloadingTestVehicles = makeActionCreator(DOWNLOADING_TEST_VEHICLES, 'status');

export const SET_TEST_VEHICLES = 'SET_TEST_VEHICLES';
export const setTestVehicles = makeActionCreator(SET_TEST_VEHICLES, 'data');

export const ADD_TEST_VEHICLE = 'ADD_TEST_VEHICLE';
export const addTestVehicle = makeActionCreator(ADD_TEST_VEHICLE, 'data');

export const CLEAR_TEST_VEHICLES = 'CLEAR_TEST_VEHICLES';
export const clearTestVehicles = makeActionCreator(CLEAR_TEST_VEHICLES);

export const DOWNLOADING_DELETING_TEST_VEHICLE = 'DOWNLOADING_DELETING_TEST_VEHICLE';
export const downloadingDeletingTestVehicle = makeActionCreator(DOWNLOADING_DELETING_TEST_VEHICLE, 'status');

export const ADDING_EDITING_TEST_VEHICLE = 'ADDING_EDITING_TEST_VEHICLE';
export const addingEditingTestVehicle = makeActionCreator(ADDING_EDITING_TEST_VEHICLE, 'status');


export const SET_TEST_VEHICLE_SELECTED_PAGE_SIZE = 'SET_TEST_VEHICLE_SELECTED_PAGE_SIZE';
export const setTestVehicleSelectedPageSize = makeActionCreator(SET_TEST_VEHICLE_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_VEHICLE_SELECTED_ORDER_BY = 'SET_TEST_VEHICLE_SELECTED_ORDER_BY';
export const setTestVehicleSelectedOrderBy = makeActionCreator(SET_TEST_VEHICLE_SELECTED_ORDER_BY, 'order');

export const SET_TEST_VEHICLE_TAPPED_ID = 'SET_TEST_VEHICLE_TAPPED_ID';
export const setTestVehicleTappedId = makeActionCreator(SET_TEST_VEHICLE_TAPPED_ID, 'id');

export const SET_TEST_VEHICLE_SEARCH_TEXT = 'SET_TEST_VEHICLE_SEARCH_TEXT';
export const setTestVehicleSearchText = makeActionCreator(SET_TEST_VEHICLE_SEARCH_TEXT, 'text');

export const SET_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setTestVehicleAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_TEST_VEHICLE_SELECTED_EVENT = 'SET_TEST_VEHICLE_SELECTED_EVENT';
export const setTestVehicleEvent = makeActionCreator(SET_TEST_VEHICLE_SELECTED_EVENT, 'option');

export const SET_TEST_VEHICLE_SELECTED_MODEL = 'SET_TEST_VEHICLE_SELECTED_MODEL';
export const setTestVehicleModel = makeActionCreator(SET_TEST_VEHICLE_SELECTED_MODEL, 'option');


export const DOWNLOADING_TEST_DRIVE_VOUCHER_REWARDS = 'DOWNLOADING_TEST_DRIVE_VOUCHER_REWARDS';
export const downloadingTestDriveVoucherRewards = makeActionCreator(DOWNLOADING_TEST_DRIVE_VOUCHER_REWARDS, 'status');

export const DOWNLOADING_TEST_DRIVE_VOUCHER_REWARD = 'DOWNLOADING_TEST_DRIVE_VOUCHER_REWARD';
export const downloadingTestDriveVoucherReward = makeActionCreator(DOWNLOADING_TEST_DRIVE_VOUCHER_REWARD, 'status');

export const ADD_TEST_DRIVE_VOUCHER_REWARD = 'ADD_TEST_DRIVE_VOUCHER_REWARD';
export const addTestDriveVoucherReward = makeActionCreator(ADD_TEST_DRIVE_VOUCHER_REWARD, 'data');

export const SET_TEST_DRIVE_VOUCHER_REWARDS = 'SET_TEST_DRIVE_VOUCHER_REWARDS';
export const setTestDriveVoucherRewards = makeActionCreator(SET_TEST_DRIVE_VOUCHER_REWARDS, 'data');

export const CLEAR_TEST_DRIVE_VOUCHER_REWARDS = 'CLEAR_TEST_DRIVE_VOUCHER_REWARDS';
export const clearTestDriveVoucherRewards = makeActionCreator(CLEAR_TEST_DRIVE_VOUCHER_REWARDS);


export const SET_TEST_DRIVE_VOUCHER_REWARD_SELECTED_PAGE_SIZE = 'SET_TEST_DRIVE_VOUCHER_REWARD_SELECTED_PAGE_SIZE';
export const setTestDriveVoucherRewardSelectedPageSize = makeActionCreator(SET_TEST_DRIVE_VOUCHER_REWARD_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_DRIVE_VOUCHER_REWARD_SELECTED_ORDER_BY = 'SET_TEST_DRIVE_VOUCHER_REWARD_SELECTED_ORDER_BY';
export const setTestDriveVoucherRewardSelectedOrderBy = makeActionCreator(SET_TEST_DRIVE_VOUCHER_REWARD_SELECTED_ORDER_BY, 'order');

export const SET_TEST_DRIVE_VOUCHER_REWARD_TAPPED_ID = 'SET_TEST_DRIVE_VOUCHER_REWARD_TAPPED_ID';
export const setTestDriveVoucherRewardTappedId = makeActionCreator(SET_TEST_DRIVE_VOUCHER_REWARD_TAPPED_ID, 'id');

export const SET_TEST_DRIVE_VOUCHER_REWARD_SEARCH_TEXT = 'SET_TEST_DRIVE_VOUCHER_REWARD_SEARCH_TEXT';
export const setTestDriveVoucherRewardSearchText = makeActionCreator(SET_TEST_DRIVE_VOUCHER_REWARD_SEARCH_TEXT, 'text');

export const SET_TEST_DRIVE_VOUCHER_REWARD_ADVANCED_FILTER_STRING = 'SET_TEST_DRIVE_VOUCHER_REWARD_ADVANCED_FILTER_STRING';
export const setTestDriveVoucherRewardAdvancedFilterString = makeActionCreator(SET_TEST_DRIVE_VOUCHER_REWARD_ADVANCED_FILTER_STRING, 'text');


export const DOWNLOADING_TEST_DRIVE_EVENT_ORGANIZER = 'DOWNLOADING_TEST_DRIVE_EVENT_ORGANIZER';
export const downloadingTestDriveEventOrganizer = makeActionCreator(DOWNLOADING_TEST_DRIVE_EVENT_ORGANIZER, 'status');

export const SET_TEST_DRIVE_EVENT_ORGANIZERS = 'SET_TEST_DRIVE_EVENT_ORGANIZERS';
export const setTestDriveEventOrganizers = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZERS, 'data');

export const ADD_TEST_DRIVE_EVENT_ORGANIZER = 'ADD_TEST_DRIVE_EVENT_ORGANIZER';
export const addTestDriveEventOrganizer = makeActionCreator(ADD_TEST_DRIVE_EVENT_ORGANIZER, 'data');

export const CLEAR_TEST_DRIVE_EVENT_ORGANIZERS = 'CLEAR_TEST_DRIVE_EVENT_ORGANIZERS';
export const clearTestDriveEventOrganizers = makeActionCreator(CLEAR_TEST_DRIVE_EVENT_ORGANIZERS);

export const DOWNLOADING_DELETING_TEST_DRIVE_EVENT_ORGANIZER = 'DOWNLOADING_DELETING_TEST_DRIVE_EVENT_ORGANIZER';
export const downloadingDeletingTestDriveEventOrganizer = makeActionCreator(DOWNLOADING_DELETING_TEST_DRIVE_EVENT_ORGANIZER, 'status');

export const ADDING_EDITING_TEST_DRIVE_EVENT_ORGANIZER = 'ADDING_EDITING_TEST_DRIVE_EVENT_ORGANIZER';
export const addingEditingTestDriveEventOrgaizer = makeActionCreator(ADDING_EDITING_TEST_DRIVE_EVENT_ORGANIZER, 'status');

export const SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_PAGE_SIZE = 'SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_PAGE_SIZE';
export const setTestDriveEventOrganizerSelectedPageSize = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_DRIVE_EVENT_ORGANIZER_TAPPED_ID = 'SET_TEST_DRIVE_EVENT_ORGANIZER_TAPPED_ID';
export const setTestDriveEventOrganizerTappedId = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZER_TAPPED_ID, 'id');

export const SET_TEST_DRIVE_EVENT_ORGANIZER_SEARCH_TEXT = 'SET_TEST_DRIVE_EVENT_ORGANIZER_SEARCH_TEXT';
export const setTestDriveEventOrganizerSearchText = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZER_SEARCH_TEXT, 'text');

export const SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_ORDER_BY = 'SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_ORDER_BY';
export const setTestDriveEventOrganizerSelectedOrderBy = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_ORDER_BY, 'order');

export const SET_TEST_DRIVE_EVENT_ORGANIZER_ADVANCED_FILTER_STRING = 'SET_TEST_DRIVE_EVENT_ORGANIZER_ADVANCED_FILTER_STRING';
export const setTestDriveEventOrganizerAdvancedFilterString = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZER_ADVANCED_FILTER_STRING, 'text');

export const SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_USER = 'SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_USER';
export const setTestDriveEventOrganizerSelectedUser = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_USER, 'option');

export const SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_EVENT = 'SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_EVENT';
export const setTestDriveEventOrganizerSelectedEvent = makeActionCreator(SET_TEST_DRIVE_EVENT_ORGANIZER_SELECTED_EVENT, 'option');
