
import { PAGE_MODE_VIEW } from '../../../constant';
import { setFunctionalPageMode, downloadingHyundaiChallengeScannedCode, setHyundaiChallengeScannedCode } from '../simple-action';
import { downloadHyundaiChallengeScannedCode } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiHyundaiChallengeScannedCode } = getState();

  const { token } = authentication;
  const { tappedId } = uiHyundaiChallengeScannedCode;

  try {
    dispatch(downloadingHyundaiChallengeScannedCode(true));

    const scannedCode = await downloadHyundaiChallengeScannedCode(
      tappedId,
      token,
    );

    dispatch(setHyundaiChallengeScannedCode(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingHyundaiChallengeScannedCode(false));
  }
};
