import { transformSearchText } from '../../../../../helper';
import { downloadCarViewingVoucherRewards } from '../../../helper';
import { downloadingCarViewingVoucherRewards, setCarViewingVoucherRewards } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, carViewingVoucherRewards, uiCarViewingVoucherReward } = getState();
  const { token } = authentication;
  const { meta } = carViewingVoucherRewards;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiCarViewingVoucherReward;


  try {
    dispatch(downloadingCarViewingVoucherRewards(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCarViewingVoucherRewards(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setCarViewingVoucherRewards(list));
    }
  } finally {
    dispatch(downloadingCarViewingVoucherRewards(false));
  }
};
