import { addTestDriveEoEvent, downloadingDeletingTestDriveEoEvent } from '../simple-action';
import { downloadTestDriveEoEvent } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingTestDriveEoEvent(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTestDriveEoEvent;

    const result = await downloadTestDriveEoEvent(tappedId, token);

    dispatch(addTestDriveEoEvent(result));
  } finally {
    dispatch(downloadingDeletingTestDriveEoEvent(false));
  }
};
