import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

// === DOWNLOADING ===

export const DOWNLOADING_BOOKING_CREATED = 'DOWNLOADING_BOOKING_CREATED';
export const downloadingBookingCreated = makeActionCreator(DOWNLOADING_BOOKING_CREATED, 'status');

export const DOWNLOADING_BOOKING_PROCEED_BY_DEALER = 'DOWNLOADING_BOOKING_PROCEED_BY_DEALER';
export const downloadingBookingProceedByDealer = makeActionCreator(DOWNLOADING_BOOKING_PROCEED_BY_DEALER, 'status');

export const DOWNLOADING_BOOKING_WAIT_FOR_PAYMENT = 'DOWNLOADING_BOOKING_WAIT_FOR_PAYMENT';
export const downloadingBookingWaitForPayment = makeActionCreator(DOWNLOADING_BOOKING_WAIT_FOR_PAYMENT, 'status');

export const DOWNLOADING_BOOKING_COMPLETED = 'DOWNLOADING_BOOKING_COMPLETED';
export const downloadingBookingCompleted = makeActionCreator(DOWNLOADING_BOOKING_COMPLETED, 'status');

export const DOWNLOADING_BOOKING_CANCELED = 'DOWNLOADING_BOOKING_CANCELED';
export const downloadingBookingCanceled = makeActionCreator(DOWNLOADING_BOOKING_CANCELED, 'status');

export const DOWNLOADING_BOOKING_CREATED_BY_REPAIR_SELECTION = 'DOWNLOADING_BOOKING_CREATED_BY_REPAIR_SELECTION';
export const downloadingBookingCreatedByRepairSelection = makeActionCreator(DOWNLOADING_BOOKING_CREATED_BY_REPAIR_SELECTION, 'status');

export const DOWNLOADING_BOOKING_CREATED_BY_DELIVERY_METHOD = 'DOWNLOADING_BOOKING_CREATED_BY_DELIVERY_METHOD';
export const downloadingBookingCreatedByDeliveryMethod = makeActionCreator(DOWNLOADING_BOOKING_CREATED_BY_DELIVERY_METHOD, 'status');

export const DOWNLOADING_BOOKING_CREATED_BY_SERVICE_INTERVAL = 'DOWNLOADING_BOOKING_CREATED_BY_SERVICE_INTERVAL';
export const downloadingBookingCreatedByServiceInterval = makeActionCreator(DOWNLOADING_BOOKING_CREATED_BY_SERVICE_INTERVAL, 'status');

export const DOWNLOADING_BOOKING_CREATED_BY_VEHICLE_MODEL = 'DOWNLOADING_BOOKING_CREATED_BY_VEHICLE_MODEL';
export const downloadingBookingCreatedByVehicleModel = makeActionCreator(DOWNLOADING_BOOKING_CREATED_BY_VEHICLE_MODEL, 'status');

export const DOWNLOADING_TRANSACTION_RECEIVED = 'DOWNLOADING_TRANSACTION_RECEIVED';
export const downloadingTransactionReceived = makeActionCreator(DOWNLOADING_TRANSACTION_RECEIVED, 'status');

export const DOWNLOADING_TRANSACTION_PENDING = 'DOWNLOADING_TRANSACTION_PENDING';
export const downloadingTransactionPending = makeActionCreator(DOWNLOADING_TRANSACTION_PENDING, 'status');

export const DOWNLOADING_TRANSACTION_PAID = 'DOWNLOADING_TRANSACTION_PAID';
export const downloadingTransactionPaid = makeActionCreator(DOWNLOADING_TRANSACTION_PAID, 'status');

export const DOWNLOADING_TRANSACTION_CANCELED = 'DOWNLOADING_TRANSACTION_CANCELED';
export const downloadingTransactionCanceled = makeActionCreator(DOWNLOADING_TRANSACTION_CANCELED, 'status');

export const DOWNLOADING_TRANSACTION_AMOUNT = 'DOWNLOADING_TRANSACTION_AMOUNT';
export const downloadingTransactionAmount = makeActionCreator(DOWNLOADING_TRANSACTION_AMOUNT, 'status');

export const DOWNLOADING_TRANSACTION_COUNT_BY_PART_NAME = 'DOWNLOADING_TRANSACTION_COUNT_BY_PART_NAME';
export const downloadingTransactionCountByPartName = makeActionCreator(DOWNLOADING_TRANSACTION_COUNT_BY_PART_NAME, 'status');

export const DOWNLOADING_TRANSACTION_AMOUNT_BY_PART_NAME = 'DOWNLOADING_TRANSACTION_AMOUNT_BY_PART_NAME';
export const downloadingTransactionAmountByPartName = makeActionCreator(DOWNLOADING_TRANSACTION_AMOUNT_BY_PART_NAME, 'status');

export const DOWNLOADING_REMAINING_STOCK_BY_PART_NAME = 'DOWNLOADING_REMAINING_STOCK_BY_PART_NAME';
export const downloadingRemaningStockByPartName = makeActionCreator(DOWNLOADING_REMAINING_STOCK_BY_PART_NAME, 'status');

export const DOWNLOADING_TRANSACTION_CREATED_BY_VEHICLE_MODEL = 'DOWNLOADING_TRANSACTION_CREATED_BY_VEHICLE_MODEL';
export const downloadingTransactionCreatedByVehicleModel = makeActionCreator(DOWNLOADING_TRANSACTION_CREATED_BY_VEHICLE_MODEL, 'status');

// === CLEAR ===

export const CLEAR_BOOKING_CREATED = 'CLEAR_BOOKING_CREATED';
export const clearBookingCreated = makeActionCreator(CLEAR_BOOKING_CREATED, 'status');

export const CLEAR_BOOKING_PROCEED_BY_DEALER = 'CLEAR_BOOKING_PROCEED_BY_DEALER';
export const clearBookingProceedByDealer = makeActionCreator(CLEAR_BOOKING_PROCEED_BY_DEALER, 'status');

export const CLEAR_BOOKING_WAIT_FOR_PAYMENT = 'CLEAR_BOOKING_WAIT_FOR_PAYMENT';
export const clearBookingWaitForPayment = makeActionCreator(CLEAR_BOOKING_WAIT_FOR_PAYMENT, 'status');

export const CLEAR_BOOKING_COMPLETED = 'CLEAR_BOOKING_COMPLETED';
export const clearBookingCompleted = makeActionCreator(CLEAR_BOOKING_COMPLETED, 'status');

export const CLEAR_BOOKING_CANCELED = 'CLEAR_BOOKING_CANCELED';
export const clearBookingCanceled = makeActionCreator(CLEAR_BOOKING_CANCELED, 'status');

export const CLEAR_BOOKING_CREATED_BY_REPAIR_SELECTION = 'CLEAR_BOOKING_CREATED_BY_REPAIR_SELECTION';
export const clearBookingCreatedByRepairSelection = makeActionCreator(CLEAR_BOOKING_CREATED_BY_REPAIR_SELECTION, 'status');

export const CLEAR_BOOKING_CREATED_BY_DELIVERY_METHOD = 'CLEAR_BOOKING_CREATED_BY_DELIVERY_METHOD';
export const clearBookingCreatedByDeliveryMethod = makeActionCreator(CLEAR_BOOKING_CREATED_BY_DELIVERY_METHOD, 'status');

export const CLEAR_BOOKING_CREATED_BY_SERVICE_INTERVAL = 'CLEAR_BOOKING_CREATED_BY_SERVICE_INTERVAL';
export const clearBookingCreatedByServiceInterval = makeActionCreator(CLEAR_BOOKING_CREATED_BY_SERVICE_INTERVAL, 'status');

export const CLEAR_BOOKING_CREATED_BY_VEHICLE_MODEL = 'CLEAR_BOOKING_CREATED_BY_VEHICLE_MODEL';
export const clearBookingCreatedByVehicleModel = makeActionCreator(CLEAR_BOOKING_CREATED_BY_VEHICLE_MODEL, 'status');

export const CLEAR_TRANSACTION_RECEIVED = 'CLEAR_TRANSACTION_RECEIVED';
export const clearTransactionReceived = makeActionCreator(CLEAR_TRANSACTION_RECEIVED, 'status');

export const CLEAR_TRANSACTION_PENDING = 'CLEAR_TRANSACTION_PENDING';
export const clearTransactionPending = makeActionCreator(CLEAR_TRANSACTION_PENDING, 'status');

export const CLEAR_TRANSACTION_PAID = 'CLEAR_TRANSACTION_PAID';
export const clearTransactionPaid = makeActionCreator(CLEAR_TRANSACTION_PAID, 'status');

export const CLEAR_TRANSACTION_CANCELED = 'CLEAR_TRANSACTION_CANCELED';
export const clearTransactionCanceled = makeActionCreator(CLEAR_TRANSACTION_CANCELED, 'status');

export const CLEAR_TRANSACTION_AMOUNT = 'CLEAR_TRANSACTION_AMOUNT';
export const clearTransactionAmount = makeActionCreator(CLEAR_TRANSACTION_AMOUNT, 'status');

export const CLEAR_TRANSACTION_COUNT_BY_PART_NAME = 'CLEAR_TRANSACTION_COUNT_BY_PART_NAME';
export const clearTransactionCountByPartName = makeActionCreator(CLEAR_TRANSACTION_COUNT_BY_PART_NAME, 'status');

export const CLEAR_TRANSACTION_AMOUNT_BY_PART_NAME = 'CLEAR_TRANSACTION_AMOUNT_BY_PART_NAME';
export const clearTransactionAmountByPartName = makeActionCreator(CLEAR_TRANSACTION_AMOUNT_BY_PART_NAME, 'status');

export const CLEAR_REMAINING_STOCK_BY_PART_NAME = 'CLEAR_REMAINING_STOCK_BY_PART_NAME';
export const clearRemaningStockByPartName = makeActionCreator(CLEAR_REMAINING_STOCK_BY_PART_NAME, 'status');

export const CLEAR_TRANSACTION_CREATED_BY_VEHICLE_MODEL = 'CLEAR_TRANSACTION_CREATED_BY_VEHICLE_MODEL';
export const clearTransactionCreatedByVehicleModel = makeActionCreator(CLEAR_TRANSACTION_CREATED_BY_VEHICLE_MODEL, 'status');

// === SET ===

export const SET_BOOKING_CREATED = 'SET_BOOKING_CREATED';
export const setBookingCreated = makeActionCreator(SET_BOOKING_CREATED, 'count');

export const SET_BOOKING_PROCEED_BY_DEALER = 'SET_BOOKING_PROCEED_BY_DEALER';
export const setBookingProceedByDealer = makeActionCreator(SET_BOOKING_PROCEED_BY_DEALER, 'count');

export const SET_BOOKING_WAIT_FOR_PAYMENT = 'SET_BOOKING_WAIT_FOR_PAYMENT';
export const setBookingWaitForPayment = makeActionCreator(SET_BOOKING_WAIT_FOR_PAYMENT, 'count');

export const SET_BOOKING_COMPLETED = 'SET_BOOKING_COMPLETED';
export const setBookingCompleted = makeActionCreator(SET_BOOKING_COMPLETED, 'count');

export const SET_BOOKING_CANCELED = 'SET_BOOKING_CANCELED';
export const setBookingCanceled = makeActionCreator(SET_BOOKING_CANCELED, 'count');

export const SET_BOOKING_CREATED_BY_REPAIR_SELECTION = 'SET_BOOKING_CREATED_BY_REPAIR_SELECTION';
export const setBookingCreatedByRepairSelection = makeActionCreator(SET_BOOKING_CREATED_BY_REPAIR_SELECTION, 'data');

export const SET_BOOKING_CREATED_BY_DELIVERY_METHOD = 'SET_BOOKING_CREATED_BY_DELIVERY_METHOD';
export const setBookingCreatedByDeliveryMethod = makeActionCreator(SET_BOOKING_CREATED_BY_DELIVERY_METHOD, 'data');

export const SET_BOOKING_CREATED_BY_SERVICE_INTERVAL = 'SET_BOOKING_CREATED_BY_SERVICE_INTERVAL';
export const setBookingCreatedByServiceInterval = makeActionCreator(SET_BOOKING_CREATED_BY_SERVICE_INTERVAL, 'data');

export const SET_BOOKING_CREATED_BY_VEHICLE_MODEL = 'SET_BOOKING_CREATED_BY_VEHICLE_MODEL';
export const setBookingCreatedByVehicleModel = makeActionCreator(SET_BOOKING_CREATED_BY_VEHICLE_MODEL, 'data');

export const SET_TRANSACTION_RECEIVED = 'SET_TRANSACTION_RECEIVED';
export const setTransactionReceived = makeActionCreator(SET_TRANSACTION_RECEIVED, 'count');

export const SET_TRANSACTION_PENDING = 'SET_TRANSACTION_PENDING';
export const setTransactionPending = makeActionCreator(SET_TRANSACTION_PENDING, 'count');

export const SET_TRANSACTION_PAID = 'SET_TRANSACTION_PAID';
export const setTransactionPaid = makeActionCreator(SET_TRANSACTION_PAID, 'count');

export const SET_TRANSACTION_CANCELED = 'SET_TRANSACTION_CANCELED';
export const setTransactionCanceled = makeActionCreator(SET_TRANSACTION_CANCELED, 'count');

export const SET_TRANSACTION_AMOUNT = 'SET_TRANSACTION_AMOUNT';
export const setTransactionAmount = makeActionCreator(SET_TRANSACTION_AMOUNT, 'count');

export const SET_TRANSACTION_COUNT_BY_PART_NAME = 'SET_TRANSACTION_COUNT_BY_PART_NAME';
export const setTransactionCountByPartName = makeActionCreator(SET_TRANSACTION_COUNT_BY_PART_NAME, 'data');

export const SET_TRANSACTION_AMOUNT_BY_PART_NAME = 'SET_TRANSACTION_AMOUNT_BY_PART_NAME';
export const setTransactionAmountByPartName = makeActionCreator(SET_TRANSACTION_AMOUNT_BY_PART_NAME, 'data');

export const SET_REMAINING_STOCK_BY_PART_NAME = 'SET_REMAINING_STOCK_BY_PART_NAME';
export const setRemaningStockByPartName = makeActionCreator(SET_REMAINING_STOCK_BY_PART_NAME, 'data');

export const SET_TRANSACTION_CREATED_BY_VEHICLE_MODEL = 'SET_TRANSACTION_CREATED_BY_VEHICLE_MODEL';
export const setTransactionCreatedByVehicleModel = makeActionCreator(SET_TRANSACTION_CREATED_BY_VEHICLE_MODEL, 'data');
