import { connect } from 'react-redux';
// import * as Action from '../../redux/action';
import TvScreen from './tv.presentation';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    // dispatch(Action.checkAuthenticationStatusAsync(ownProps.history.push));
  },
});

export default connect(null, mapDispatchToProps)(TvScreen);
