import { downloadJaw2023ScannedCode } from '../../../helper';
import { downloadingJaw2023ScannedCode, setJaw2023ScannedCode, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiJaw2023ScannedCode } = getState();

  const { token } = authentication;
  const { tappedId } = uiJaw2023ScannedCode;

  try {
    dispatch(downloadingJaw2023ScannedCode(true));

    const scannedCode = await downloadJaw2023ScannedCode(
      tappedId,
      token,
    );

    dispatch(setJaw2023ScannedCode(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingJaw2023ScannedCode(false));
  }
};
