import {
  DOWNLOADING_REGISTRATIONS, DOWNLOADING_DELETING_REGISTRATION, SET_REGISTRATION_SELECTED_PAGE_SIZE,
  SET_REGISTRATION_TAPPED_ID, SET_REGISTRATION_SEARCH_TEXT, SET_REGISTRATION_SELECTED_ORDER_BY,
  APPROVING_REGISTRATION, REJECTING_REGISTRATION, ADDING_REGISTRATION_NOTES,
  REFRESHING_VEHICLE_INFO, CHANGING_REGISTRATION_VIN, APPROVING_RESERVATION,
} from '../action';
import { INITIAL_ORDER_BY_REGISTRATIONS } from '../../constant';

const initialState = {
  approving: false,
  changingVin: false,
  downloadingDeleting: false,
  downloading: false,
  rejecting: false,
  addingRegistrationNote: false,
  refreshingVehicleInfo: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_REGISTRATIONS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_REGISTRATIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_REGISTRATION:
      return { ...state, downloadingDeleting: action.status };
    case SET_REGISTRATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_REGISTRATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_REGISTRATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_REGISTRATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case APPROVING_REGISTRATION:
      return { ...state, approving: action.status };
    case REJECTING_REGISTRATION:
      return { ...state, rejecting: action.status };
    case ADDING_REGISTRATION_NOTES:
      return { ...state, addingRegistrationNote: action.status };
    case REFRESHING_VEHICLE_INFO:
      return { ...state, refreshingVehicleInfo: action.status };
    case CHANGING_REGISTRATION_VIN:
      return { ...state, changingVin: action.status };
    case APPROVING_RESERVATION:
      return { ...state, approving: action.status };
    default: return state;
  }
};
