
import { INITIAL_ORDER_BY_INVOICES } from '../../constant';
import {
  DOWNLOADING_INVOICES, SET_INVOICE_SEARCH_TEXT,
  DOWNLOADING_DELETING_INVOICE,
  SET_INVOICE_SELECTED_ORDER_BY,
  SET_INVOICE_SELECTED_PAGE_SIZE,
  SET_INVOICE_TAPPED_ID,
  ADDING_EDITING_INVOICE,
  SET_INVOICE_IS_ACTIVE,
  SET_INVOICE_SENDING_PDF,
  SET_INVOICES_FILTER_STRING,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  sendingPdf: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_INVOICES,
  isActive: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_INVOICES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_INVOICE:
      return { ...state, downloadingDeleting: action.status };
    case ADDING_EDITING_INVOICE:
      return { ...state, addingEditing: action.status };
    case SET_INVOICE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_INVOICE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_INVOICE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_INVOICE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_INVOICE_IS_ACTIVE:
      return { ...state, isActive: action.status };
    case SET_INVOICE_SENDING_PDF:
      return { ...state, sendingPdf: action.status };
    case SET_INVOICES_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
