import {
  retryingDMSUserSubmission, setAlertErrorMessage, setDMSUserSubmissionSelectedOrderBy,
  setDMSUserSubmissionSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_DMS_USER_SUBMISSIONS } from '../../../constant';
import { retryDMSUserSubmission } from '../../../helper';
import downloadDMSUserSubmissionsAsync from './downloadDMSUserSubmissionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(retryingDMSUserSubmission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiDMSUserSubmission;

    await retryDMSUserSubmission(tappedId, token);

    dispatch(setDMSUserSubmissionSelectedPageSize(20));
    dispatch(setDMSUserSubmissionSelectedOrderBy(INITIAL_ORDER_BY_DMS_USER_SUBMISSIONS));
    dispatch(downloadDMSUserSubmissionsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(retryingDMSUserSubmission(false));
  }
};
