import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, IconButton, InputAdornment, Snackbar,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FunctionalPage } from '../../component';
import {
  COLOR_PRIMARY, DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME, RXFIELD_TEST_DRIVE_EO_EVENT_CITY,
  RXFIELD_TEST_DRIVE_EO_EVENT_DESCRIPTION, RXFIELD_TEST_DRIVE_EO_EVENT_END_TIME,
  RXFIELD_TEST_DRIVE_EO_EVENT_EVENT_CODE, RXFIELD_TEST_DRIVE_EO_EVENT_LOCATION,
  RXFIELD_TEST_DRIVE_EO_EVENT_NAME, RXFIELD_TEST_DRIVE_EO_EVENT_PROVINCE,
  RXFIELD_TEST_DRIVE_EO_EVENT_START_TIME, RXFIELD_TEST_DRIVE_EO_EVENT_SURVEY_URL,
  RXFIELD_TEST_DRIVE_EO_EVENT_SURVEY_URL_EXTERNAL_BROWSER, RXFIELD_TEST_DRIVE_EO_EVENT_URL1,
  RXFIELD_TEST_DRIVE_EO_EVENT_URL1_EXTERNAL_BROWSER, RXFIELD_TEST_DRIVE_EO_EVENT_URL2,
  RXFIELD_TEST_DRIVE_EO_EVENT_URL2_EXTERNAL_BROWSER, RXFIELD_TEST_DRIVE_EO_EVENT_VISIBILITY,
  RXFIELD_TEST_DRIVE_EO_EVENT_VOUCHER_REWARDS, RXSTATE_TEST_DRIVE_EO_EVENTS,
  RXSTATE_TEST_DRIVE_EO_EVENT_PAGE, RXFORM_TEST_DRIVE_EO_EVENT, snackbarDuration,
  visibilityStatuses,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';

const renderDialogContent = (initialValues, copying, onCopyPressed) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_NAME}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderName}
          placeholder={LocalizedString.eventPage.placeholderName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_EVENT_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.eventPage.placeholderEventCode}
          label={LocalizedString.eventPage.placeholderEventCode}
          disabled
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                  <CopyToClipboard text={initialValues.eventCode}>
                    <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                  </CopyToClipboard>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderDescription}
          placeholder={LocalizedString.eventPage.placeholderDescription}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_PROVINCE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderProvince}
          placeholder={LocalizedString.eventPage.placeholderProvince}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_CITY}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderCity}
          placeholder={LocalizedString.eventPage.placeholderCity}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_LOCATION}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderLocation}
          placeholder={LocalizedString.eventPage.placeholderLocation}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_SURVEY_URL}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderSurveyURL}
          placeholder={LocalizedString.eventPage.placeholderSurveyURL}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_SURVEY_URL_EXTERNAL_BROWSER}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderSurveyURLExternalBrowser}
          placeholder={LocalizedString.eventPage.placeholderSurveyURLExternalBrowser}
          disabled
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_START_TIME}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.eventPage.placeholderStartTime}
          placeholder={LocalizedString.eventPage.placeholderStartTime}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_END_TIME}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.eventPage.placeholderEndTime}
          placeholder={LocalizedString.eventPage.placeholderEndTime}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_VISIBILITY}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderVisibility}
          placeholder={LocalizedString.eventPage.placeholderVisibility}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_VOUCHER_REWARDS}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderVoucherReward}
          placeholder={LocalizedString.eventPage.placeholderVoucherReward}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_URL1}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderURL1}
          placeholder={LocalizedString.eventPage.placeholderURL1}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_URL1_EXTERNAL_BROWSER}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderURL1ExternalBrowser}
          placeholder={LocalizedString.eventPage.placeholderURL1ExternalBrowser}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_URL2}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderURL2}
          placeholder={LocalizedString.eventPage.placeholderURL2}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_EVENT_URL2_EXTERNAL_BROWSER}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.eventPage.placeholderURL2ExternalBrowser}
          placeholder={LocalizedString.eventPage.placeholderURL2ExternalBrowser}
          disabled
        />
      </Grid>
    </Grid>

    <Snackbar
      open={copying}
      autoHideDuration={snackbarDuration}
      message={LocalizedString.eventPage.labelCopied}
      onClose={() => onCopyPressed(copying)}
    />
  </Grid>
);

const EventPage = ({
  initialValues, cities, provinces,
  copying, downloading, loadingCity, loadingProvince,
  handleSubmit, onAppear,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onChangeCityText, onChangePage,
  onChangePageSize, onChangeProvinceText, onCopyPressed, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_TEST_DRIVE_EO_EVENTS}
    uiPage={RXSTATE_TEST_DRIVE_EO_EVENT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.eventPage.placeholderStartTime,
        field: RXFIELD_TEST_DRIVE_EO_EVENT_START_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.eventPage.placeholderEndTime,
        field: RXFIELD_TEST_DRIVE_EO_EVENT_END_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.eventPage.placeholderVisibility,
        field: RXFIELD_TEST_DRIVE_EO_EVENT_VISIBILITY,
        type: FILTER_TYPE_DROPDOWN,
        data: visibilityStatuses,
      },
      {
        title: LocalizedString.eventPage.placeholderCity,
        field: RXFIELD_TEST_DRIVE_EO_EVENT_CITY,
        type: FILTER_TYPE_DROPDOWN,
        data: cities,
        loading: loadingCity,
        onChangeFilterText: onChangeCityText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.eventPage.placeholderProvince,
        field: RXFIELD_TEST_DRIVE_EO_EVENT_PROVINCE,
        type: FILTER_TYPE_DROPDOWN,
        data: provinces,
        loading: loadingProvince,
        onChangeFilterText: onChangeProvinceText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.eventPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.eventPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.eventPage.labelLocation, field: 'location', sorting: !downloading },
      {
        title: LocalizedString.eventPage.labelStartTime,
        field: 'startTime',
        sorting: !downloading,
        render: ({ city, startTime }) => (startTime
          ? toMoment(startTime, city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
          : null),
      },
      {
        title: LocalizedString.eventPage.labelEndTime,
        field: 'endTime',
        sorting: !downloading,
        render: ({ city, endTime }) => (endTime
          ? toMoment(endTime, city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.eventPage.labelVisibility, field: 'visibility', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.eventPage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues, copying, onCopyPressed)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TEST_DRIVE_EO_EVENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventPage);

EventPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  provinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  loadingProvince: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeProvinceText: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
