import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES, MENUID_JAW_2023_SCANNED_CODE, PAGE_MODE_TABLE } from '../../constant';
import { transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  clearJaw2023ScannedCodes, downloadDeleteJaw2023ScannedCodeAsync,
  downloadJaw2023ScannedCodesAsync, saveJaw2023ScannedCodesAsync,
  setAlertErrorMessage, setJaw2023ScannedCodesOrderBy, setJaw2023ScannedCodesPageSize,
  setJaw2023ScannedCodesSearchText, setJaw2023ScannedCodesFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setJaw2023ScannedCodesTappedId, setActiveSideMenuItem,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import Jaw2023ScannedCode from './jaw-2023-scanned-code.presentation';

const getInitialValues = (state) => {
  const {
    jaw2023ScannedCodes, uiFunctionalPage, uiJaw2023ScannedCode,
  } = state;
  const { data } = jaw2023ScannedCodes;
  const { downloadingScannedCode, tappedId } = uiJaw2023ScannedCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingScannedCode
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    user: found.user
      ? { fullName: found.user.fullName, id: found.user.id }
      : { fullName: '', id: '' },
  };

  return result;
};

const mapStateToProps = (state) => ({
  scannedCodes: state.jaw2023ScannedCodes.data,
  downloading: state.uiJaw2023ScannedCode.downloading,
  downloadingScannedCode: state.uiJaw2023ScannedCode.downloadingScannedCode,
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_JAW_2023_SCANNED_CODE));
    dispatch(setJaw2023ScannedCodesSearchText(''));
    dispatch(setJaw2023ScannedCodesFilterString(''));
    dispatch(clearJaw2023ScannedCodes());
    dispatch(setJaw2023ScannedCodesPageSize(20));
    dispatch(setJaw2023ScannedCodesOrderBy(INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES));

    dispatch(downloadJaw2023ScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setJaw2023ScannedCodesPageSize(pageSize));
    dispatch(clearJaw2023ScannedCodes());

    dispatch(downloadJaw2023ScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setJaw2023ScannedCodesOrderBy(orderBy));
    dispatch(clearJaw2023ScannedCodes());
    dispatch(downloadJaw2023ScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadJaw2023ScannedCodesAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setJaw2023ScannedCodesPageSize(pageSize));
    dispatch(downloadJaw2023ScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setJaw2023ScannedCodesSearchText(text));
      dispatch(clearJaw2023ScannedCodes());
      await dispatch(downloadJaw2023ScannedCodesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/scanCodeUsername/, 'user.id');

    dispatch(setJaw2023ScannedCodesFilterString(text));
    dispatch(clearJaw2023ScannedCodes());
    dispatch(downloadJaw2023ScannedCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setJaw2023ScannedCodesFilterString(''));
    dispatch(clearJaw2023ScannedCodes());
    dispatch(setJaw2023ScannedCodesOrderBy(INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES));
    dispatch(downloadJaw2023ScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setJaw2023ScannedCodesTappedId(id));
    dispatch(downloadDeleteJaw2023ScannedCodeAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDownloadPressed: () => {
    dispatch(saveJaw2023ScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Jaw2023ScannedCode);
