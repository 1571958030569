import { downloadRemaningStockByPartName } from '../../../helper';
import { downloadingRemaningStockByPartName, setRemaningStockByPartName } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingRemaningStockByPartName(true));
    const bookingCreated = await downloadRemaningStockByPartName(token, startDate, endDate);

    dispatch(setRemaningStockByPartName(bookingCreated));
  } finally {
    dispatch(downloadingRemaningStockByPartName(false));
  }
};
