import {
  DOWNLOADING_VOUCHER_USAGES, SAVING_VOUCHER_USAGES,
  SET_VOUCHER_USAGE_SELECTED_PAGE_SIZE,
  SET_VOUCHER_USAGE_SEARCH_TEXT, SET_VOUCHER_USAGE_SELECTED_ORDER_BY,
  SET_VOUCHER_USAGE_SELECTED_MERCHANT_ID, SET_VOUCHER_USAGE_SELECTED_VOUCHER_CATEGORY_ID,
  SET_VOUCHER_USAGE_SELECTED_APPLICABLE_BRANCHES, SET_VOUCHER_USAGE_TABLE_DATA_APPLICABLE_BRANCHES,
  SET_VOUCHER_USAGE_SELECTED_PAYMENT_TYPE, SET_VOUCHER_USAGE_SELECTED_TAGS,
  SET_VOUCHER_USAGE_SELECTED_TANAMERA_FLAG, SET_VOUCHER_USAGE_SELECTED_GARUDA_FLAG,
  SET_VOUCHER_USAGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';
import { INITIAL_ORDER_BY_VOUCHERS } from '../../constant';

const initialState = {
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_VOUCHERS,
  selectedMerchantId: '',
  selectedVoucherCategoryId: '',
  tableDataApplicableBranches: [],
  filterString: '',
  selectedIsPromoted: '',
  selectedPaymentType: '',
  selectedTags: '',
  selectedTanameraFlag: '',
  selectedGarudaFlag: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_VOUCHER_USAGES:
      return { ...state, downloading: action.status };
    case SET_VOUCHER_USAGE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOUCHER_USAGE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOUCHER_USAGE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_VOUCHER_USAGE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_VOUCHER_USAGE_SELECTED_MERCHANT_ID:
      return { ...state, selectedMerchantId: action.id };
    case SET_VOUCHER_USAGE_SELECTED_VOUCHER_CATEGORY_ID:
      return { ...state, selectedVoucherCategoryId: action.id };
    case SET_VOUCHER_USAGE_SELECTED_APPLICABLE_BRANCHES:
      return { ...state, selectedApplicableBranches: action.option };
    case SET_VOUCHER_USAGE_TABLE_DATA_APPLICABLE_BRANCHES:
      return { ...state, tableDataApplicableBranches: action.data };
    case SET_VOUCHER_USAGE_SELECTED_PAYMENT_TYPE:
      return { ...state, selectedPaymentType: action.option };
    case SET_VOUCHER_USAGE_SELECTED_TAGS:
      return { ...state, selectedTags: action.option };
    case SET_VOUCHER_USAGE_SELECTED_TANAMERA_FLAG:
      return { ...state, selectedTanameraFlag: action.option };
    case SET_VOUCHER_USAGE_SELECTED_GARUDA_FLAG:
      return { ...state, selectedGarudaFlag: action.option };
    case SAVING_VOUCHER_USAGES:
      return { ...state, saving: action.status };
    default: return state;
  }
};
