import { downloadGiiasMedanScannedCode } from '../../../helper';
import { downloadingGiiasMedanScannedCode, setGiiasMedanScannedCode, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiGiiasMedanScannedCode } = getState();

  const { token } = authentication;
  const { tappedId } = uiGiiasMedanScannedCode;

  try {
    dispatch(downloadingGiiasMedanScannedCode(true));

    const scannedCode = await downloadGiiasMedanScannedCode(
      tappedId,
      token,
    );

    dispatch(setGiiasMedanScannedCode(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingGiiasMedanScannedCode(false));
  }
};
