import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_WASTE_COLLECTIONS = 'DOWNLOADING_WASTE_COLLECTIONS';
export const downloadingWasteCollections = makeActionCreator(DOWNLOADING_WASTE_COLLECTIONS, 'status');

export const DOWNLOADING_DELETING_WASTE_COLLECTION = 'DOWNLOADING_DELETING_WASTE_COLLECTION';
export const downloadingDeletingWasteCollection = makeActionCreator(DOWNLOADING_DELETING_WASTE_COLLECTION, 'status');

export const SET_WASTE_COLLECTIONS = 'SET_WASTE_COLLECTIONS';
export const setWasteCollections = makeActionCreator(SET_WASTE_COLLECTIONS, 'data');

export const ADD_WASTE_COLLECTION = 'ADD_WASTE_COLLECTION';
export const addWasteCollection = makeActionCreator(ADD_WASTE_COLLECTION, 'data');

export const CLEAR_WASTE_COLLECTIONS = 'CLEAR_WASTE_COLLECTIONS';
export const clearWasteCollections = makeActionCreator(CLEAR_WASTE_COLLECTIONS, 'data');


export const SET_WASTE_COLLECTIONS_SELECTED_PAGE_SIZE = 'SET_WASTE_COLLECTIONS_SELECTED_PAGE_SIZE';
export const setWasteCollectionSelectedPageSize = makeActionCreator(SET_WASTE_COLLECTIONS_SELECTED_PAGE_SIZE, 'size');

export const SET_WASTE_COLLECTIONS_ORDER_BY = 'SET_WASTE_COLLECTIONS_ORDER_BY';
export const setWasteCollectionSelectedOrderBy = makeActionCreator(SET_WASTE_COLLECTIONS_ORDER_BY, 'order');

export const SET_WASTE_COLLECTIONS_SEARCH_TEXT = 'SET_WASTE_COLLECTIONS_SEARCH_TEXT';
export const setWasteCollectionSearchText = makeActionCreator(SET_WASTE_COLLECTIONS_SEARCH_TEXT, 'text');

export const SET_WASTE_COLLECTIONS_TAPPED_ID = 'SET_WASTE_COLLECTIONS_TAPPED_ID';
export const setWasteCollectionsTappedId = makeActionCreator(SET_WASTE_COLLECTIONS_TAPPED_ID, 'id');

export const SET_WASTE_COLLECTIONS_ADVANCED_FILTER_STRING = 'SET_WASTE_COLLECTIONS_ADVANCED_FILTER_STRING';
export const setWasteCollectionsAdvancedFilterString = makeActionCreator(SET_WASTE_COLLECTIONS_ADVANCED_FILTER_STRING, 'text');


export const SAVING_WASTE_COLLECTIONS = 'SAVING_WASTE_COLLECTIONS';
export const savingWasteCollections = makeActionCreator(SAVING_WASTE_COLLECTIONS, 'status');

export const DOWNLOADING_WASTE_DEALER_CODES = 'DOWNLOADING_WASTE_DEALER_CODES';
export const downloadingWasteDealerCodes = makeActionCreator(DOWNLOADING_WASTE_DEALER_CODES, 'status');

export const SET_WASTE_DEALER_CODES = 'SET_WASTE_DEALER_CODES';
export const setWasteDealerCodes = makeActionCreator(SET_WASTE_DEALER_CODES, 'data');

export const ADD_WASTE_DEALER_CODE = 'ADD_WASTE_DEALER_CODE';
export const addWasteDealerCode = makeActionCreator(ADD_WASTE_DEALER_CODE, 'data');

export const CLEAR_WASTE_DEALER_CODES = 'CLEAR_WASTE_DEALER_CODES';
export const clearWasteDealerCodes = makeActionCreator(CLEAR_WASTE_DEALER_CODES, 'data');

export const DOWNLOADING_DELETING_WASTE_DEALER_CODE = 'DOWNLOADING_DELETING_WASTE_DEALER_CODE';
export const downloadingDeletingWasteDealerCode = makeActionCreator(DOWNLOADING_DELETING_WASTE_DEALER_CODE, 'status');

export const ADDING_WASTE_DEALER_CODE = 'ADDING_WASTE_DEALER_CODE';
export const addingWasteDealerCode = makeActionCreator(ADDING_WASTE_DEALER_CODE, 'status');


export const SET_WASTE_DEALER_CODES_SELECTED_PAGE_SIZE = 'SET_WASTE_DEALER_CODES_SELECTED_PAGE_SIZE';
export const setWasteDealerCodesSelectedPageSize = makeActionCreator(SET_WASTE_DEALER_CODES_SELECTED_PAGE_SIZE, 'size');

export const SET_WASTE_DEALER_CODES_ORDER_BY = 'SET_WASTE_DEALER_CODES_ORDER_BY';
export const setWasteDealerCodesSelectedOrderBy = makeActionCreator(SET_WASTE_DEALER_CODES_ORDER_BY, 'order');

export const SET_WASTE_DEALER_CODES_SEARCH_TEXT = 'SET_WASTE_DEALER_CODES_SEARCH_TEXT';
export const setWasteDealerCodesSearchText = makeActionCreator(SET_WASTE_DEALER_CODES_SEARCH_TEXT, 'text');

export const SET_WASTE_DEALER_CODE_TAPPED_ID = 'SET_WASTE_DEALER_CODE_TAPPED_ID';
export const setWasteDealerCodeTappedId = makeActionCreator(SET_WASTE_DEALER_CODE_TAPPED_ID, 'id');

export const SET_WASTE_DEALER_CODE_TAPPED_DEALER_ID = 'SET_WASTE_DEALER_CODE_TAPPED_DEALER_ID';
export const setWasteDealerCodeTappedDealerId = makeActionCreator(SET_WASTE_DEALER_CODE_TAPPED_DEALER_ID, 'id');

export const SET_WASTE_DEALER_CODES_ADVANCED_FILTER_STRING = 'SET_WASTE_DEALER_CODES_ADVANCED_FILTER_STRING';
export const setWasteDealerCodesAdvancedFilterString = makeActionCreator(SET_WASTE_DEALER_CODES_ADVANCED_FILTER_STRING, 'text');
