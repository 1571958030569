import { downloadingWasteDealerCodes, setWasteDealerCodes } from '../simple-action';
import { downloadWasteDealerCodes, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, wasteDealerCodes, uiWasteDealerCode } = getState();
  const { token } = authentication;
  const { meta } = wasteDealerCodes;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiWasteDealerCode;

  try {
    dispatch(downloadingWasteDealerCodes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadWasteDealerCodes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setWasteDealerCodes(list));
    }
  } finally {
    dispatch(downloadingWasteDealerCodes(false));
  }
};
