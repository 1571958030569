import {
  downloadingCarViewingReservations, setCarViewingReservations,
} from '../simple-action';
import { downloadCarViewingReservations, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, carViewingReservations, uiCarViewingReservation } = getState();
  const { token } = authentication;
  const { meta } = carViewingReservations;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiCarViewingReservation;


  try {
    dispatch(downloadingCarViewingReservations(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCarViewingReservations(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setCarViewingReservations(list));
    }
  } finally {
    dispatch(downloadingCarViewingReservations(false));
  }
};
