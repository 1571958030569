import { connect } from 'react-redux';
import { change, getFormValues, reset } from 'redux-form';
import {
  addBranch, clearBranches, clearCompaniesMData, clearCities, clearProvinces, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchMapCenter, setBranchMapMarker,
  setBranchMapPolygon, setBranchSearchText, setBranchSelectedCompanyId, setBranchSelectedPageSize,
  setBranchSelectedCityId, setBranchSelectedOrderBy, setBranchTappedId,
  setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData, setCompanySearchTextMData,
  setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText,
  addEditBranchAsync, downloadBranchesAsync, downloadCitiesAsync, downloadProvincesAsync,
  downloadMDataCompaniesAsync, enableDisableBranchAsync, resyncBranchAsync, saveBranchesAsync,
} from '../../redux/action';
import {
  DEFAULT_GOOGLE_MAP_URL, DEFAULT_MAP_DRAWER_CENTER_MARKER, DRAW_MODE_MARKER, GOOGLE_MAP_URL_PREFIX,
  INITIAL_ORDER_BY_BRANCHES, MENUID_MASTERDATA_BRANCH, PAGE_MODE_TABLE, RXFIELD_BRANCH_AREA,
  RXFIELD_BRANCH_CITY, RXFIELD_BRANCH_COMPANY,
  RXFIELD_BRANCH_GOOGLE_MAP_URL, RXFIELD_BRANCH_LATITUDE, RXFIELD_BRANCH_LONGITUDE,
  RXFORM_BRANCH, TIME_FORMAT, TIME_MODE_CLOCK_IN,
  TIME_MODE_CLOCK_OUT,
} from '../../constant';
import {
  convertClockInOutHoursToMinutes, transformDropdownData,
  transformInitialValues, toDecimal, toMoment, toNumber,
} from '../../helper';
import LocalizedString from '../../localization';
import BranchPage from './branch.presentation';

const getInitialValues = (state) => {
  const { branches, uiBranch, uiFunctionalPage } = state;
  const { downloadingDeleting, polygon, tappedId } = uiBranch;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? branches.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        city: { label: found.city?.name || '', value: found.city?.id || '' },
        company: { label: found.company?.name || '', value: found.company?.id || '' },
        area: polygon.length > 0 ? polygon.map((x, i) => ({ ...x, no: i + 1 })) : [],
        earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
          found.earliestClockInDriftTimeMinutes, found.startWorkingHour, TIME_MODE_CLOCK_IN,
        ),
        autoClockOutAfterMinutes: convertClockInOutHoursToMinutes(
          found.autoClockOutAfterMinutes, found.endWorkingHour, TIME_MODE_CLOCK_OUT,
        ),
      })
    : {
      name: '',
      city: { label: '', value: '' },
      company: { label: '', value: '' },
      description: '',
      phone: '',
      address: '',
      postalCode: '',
      googleMapUrl: '',
      longitude: '',
      latitude: '',
      startWorkingHour: toMoment().format(TIME_FORMAT),
      startWorkingHourMarginBeforeMinutes: '',
      startWorkingHourMarginAfterMinutes: '',
      endWorkingHour: toMoment().format(TIME_FORMAT),
      endWorkingHourMarginBeforeMinutes: '',
      endWorkingHourMarginAfterMinutes: '',
      area: polygon.length > 0 ? polygon.map((x, i) => ({ ...x, no: i + 1 })) : [],
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  center: state.uiBranch.center,
  marker: state.uiBranch.marker,
  polygon: state.uiBranch.polygon,
  cities: transformDropdownData(state.cities.data),
  companies: transformDropdownData(state.companiesMdata.data),
  provinces: transformDropdownData(state.provinces.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiBranch.addingEditing,
  downloading: state.uiBranch.downloading,
  loadingCity: state.uiCity.downloading,
  loadingCompany: state.uiCompany.downloading,
  loadingProvince: state.uiProvince.downloading,
  resyncing: state.uiBranch.resyncing,
  branches: state.branches.data,
  tappedData: state.branches.data[state.uiBranch.tappedId],
  addingPolygon: state.mapDrawer.addingPolygon,
  enablingDisabling: state.uiBranch.enablingDisabling,
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentBranchStatus: state.branches.data[state.uiFunctionalPage.tappedId]
    ? state.branches.data[state.uiFunctionalPage.tappedId].status : '',
  currentFormValues: getFormValues(RXFORM_BRANCH)(state) || {},
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setProvinceSearchText(''));
    dispatch(setCitySearchText(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearProvinces());
    dispatch(clearCities());
    dispatch(clearCompaniesMData());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_MASTERDATA_BRANCH));
    dispatch(setBranchSearchText(''));
    dispatch(clearBranches());
    dispatch(setBranchSelectedPageSize(20));
    dispatch(setBranchSelectedOrderBy(INITIAL_ORDER_BY_BRANCHES));
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/city/, 'city.id')
      .replace(/province/, 'city.province.id')
      .replace(/timeZone/, 'city.province.timeZone')
      .replace(/company/, 'company.id');
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_BRANCH));
  },
  onChangeCityText: async (text) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadBranchesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setBranchSelectedPageSize(pageSize));
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeLatitudeText: (e, currLng) => {
    const latitude = e.target.value ? toDecimal(e.target.value) : '';
    dispatch(setBranchMapCenter({ latitude }));
    dispatch(setBranchMapMarker({ latitude }));
    dispatch(setBranchMapPolygon([]));
    dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_LATITUDE, latitude));
    if (latitude && currLng) {
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_GOOGLE_MAP_URL, GOOGLE_MAP_URL_PREFIX
        .replace(/\{latitude\}/, e.target.value).replace(/\{longitude\}/, currLng)));
    } else {
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_GOOGLE_MAP_URL, ''));
    }
  },
  onChangeLongitudeText: (e, currLat) => {
    const longitude = e.target.value ? toDecimal(e.target.value) : '';
    dispatch(setBranchMapCenter({ longitude }));
    dispatch(setBranchMapMarker({ longitude }));
    dispatch(setBranchMapPolygon([]));
    dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_LONGITUDE, longitude));
    if (currLat && longitude) {
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_GOOGLE_MAP_URL, GOOGLE_MAP_URL_PREFIX
        .replace(/\{latitude\}/, currLat).replace(/\{longitude\}/, e.target.value)));
    } else {
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_GOOGLE_MAP_URL, ''));
    }
  },
  onChangeProvinceText: async (text) => {
    try {
      dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCityOptionSelected: (option) => {
    if (option) {
      dispatch(setBranchSelectedCityId(option.value));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_CITY, option));
    } else {
      dispatch(setBranchSelectedCityId(''));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_CITY, ''));
    }
  },
  onCompanyOptionSelected: (option) => {
    if (option) {
      dispatch(setBranchSelectedCompanyId(option.value));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_COMPANY, option));
    } else {
      dispatch(setBranchSelectedCompanyId(''));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_COMPANY, ''));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncBranchAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableBranchAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setBranchTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setBranchTappedId(''));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setBranchMapCenter(DEFAULT_MAP_DRAWER_CENTER_MARKER));
    dispatch(setBranchMapMarker(DEFAULT_MAP_DRAWER_CENTER_MARKER));
    dispatch(setBranchMapPolygon([]));
    dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_LATITUDE,
      DEFAULT_MAP_DRAWER_CENTER_MARKER.latitude));
    dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_LONGITUDE,
      DEFAULT_MAP_DRAWER_CENTER_MARKER.longitude));
    dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_GOOGLE_MAP_URL, DEFAULT_GOOGLE_MAP_URL));
  },
  onDownloadPressed: () => {
    dispatch(saveBranchesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDrawingCompleted: (data, drawingMode, tappedData) => {
    if (drawingMode === DRAW_MODE_MARKER) {
      dispatch(setBranchMapMarker(data));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_LATITUDE, data.latitude));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_LONGITUDE, data.longitude));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_GOOGLE_MAP_URL, data.url));
      if (tappedData) {
        dispatch(addBranch({ ...tappedData, area: [] }));
      }
    } else {
      const transformedDate = data.map((x, idx) => ({ ...x, order: idx + 1 }));
      dispatch(setBranchMapPolygon(transformedDate));
      dispatch(change(RXFORM_BRANCH, RXFIELD_BRANCH_AREA, transformedDate));
    }
  },
  onEditPressed: (id, data) => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCities());
    dispatch(clearCompaniesMData());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setBranchTappedId(id));
    const { latitude, longitude, area } = data[id];
    if (latitude && longitude) {
      dispatch(setBranchMapCenter({ latitude, longitude }));
      dispatch(setBranchMapMarker({ latitude, longitude }));
      dispatch(setBranchMapPolygon(area));
    } else {
      dispatch(setBranchMapCenter(null));
      dispatch(setBranchMapMarker(null));
      dispatch(setBranchMapPolygon(null));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setBranchSelectedPageSize(pageSize));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearBranches());
    dispatch(setBranchSelectedOrderBy(INITIAL_ORDER_BY_BRANCHES));
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description, latitude, longitude, googleMapUrl, address, postalCode, phone, email,
  }) => {
    try {
      await dispatch(addEditBranchAsync(
        name, description,
        toNumber(latitude), toNumber(longitude), googleMapUrl,
        address, postalCode, phone, email,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setBranchSearchText(text));
      dispatch(clearBranches());
      await dispatch(downloadBranchesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setBranchSelectedOrderBy(orderBy));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description, latitude, longitude, googleMapUrl, address, postalCode, phone, email,
  }) => {
    await dispatch(addEditBranchAsync(
      name, description,
      toNumber(latitude), toNumber(longitude), googleMapUrl,
      address, postalCode, phone, email,
    ));
  },
  onViewPressed: (id, data) => {
    dispatch(setBranchTappedId(id));
    const { latitude, longitude, area } = data[id];
    if (latitude && longitude) {
      dispatch(setBranchMapCenter({ latitude, longitude }));
      dispatch(setBranchMapMarker({ latitude, longitude }));
      dispatch(setBranchMapPolygon(area));
    } else {
      dispatch(setBranchMapCenter(null));
      dispatch(setBranchMapMarker(null));
      dispatch(setBranchMapPolygon(null));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchPage);
