import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_REGISTRATIONS = 'DOWNLOADING_REGISTRATIONS';
export const downloadingRegistrations = makeActionCreator(DOWNLOADING_REGISTRATIONS, 'status');

export const SET_REGISTRATIONS = 'SET_REGISTRATIONS';
export const setRegistration = makeActionCreator(SET_REGISTRATIONS, 'data');

export const ADD_REGISTRATION = 'ADD_REGISTRATION';
export const addRegistration = makeActionCreator(ADD_REGISTRATION, 'data');

export const CLEAR_REGISTRATIONS = 'CLEAR_REGISTRATIONS';
export const clearRegistrations = makeActionCreator(CLEAR_REGISTRATIONS);

export const DOWNLOADING_DELETING_REGISTRATION = 'DOWNLOADING_DELETING_REGISTRATION';
export const downloadingDeletingRegistration = makeActionCreator(DOWNLOADING_DELETING_REGISTRATION, 'status');

export const APPROVING_REGISTRATION = 'APPROVING_REGISTRATION';
export const approvingRegistration = makeActionCreator(APPROVING_REGISTRATION, 'status');

export const REJECTING_REGISTRATION = 'REJECTING_REGISTRATION';
export const rejectingRegistration = makeActionCreator(REJECTING_REGISTRATION, 'status');

export const ADDING_REGISTRATION_NOTES = 'ADDING_REGISTRATION_NOTES';
export const addingRegistrationNotes = makeActionCreator(ADDING_REGISTRATION_NOTES, 'status');

export const CHANGING_REGISTRATION_VIN = 'CHANGING_REGISTRATION_VIN';
export const changingRegistrationVin = makeActionCreator(CHANGING_REGISTRATION_VIN, 'status');


export const SET_REGISTRATION_SELECTED_PAGE_SIZE = 'SET_REGISTRATION_SELECTED_PAGE_SIZE';
export const setRegistrationSelectedPageSize = makeActionCreator(SET_REGISTRATION_SELECTED_PAGE_SIZE, 'size');

export const SET_REGISTRATION_SELECTED_ORDER_BY = 'SET_REGISTRATION_SELECTED_ORDER_BY';
export const setRegistrationSelectedOrderBy = makeActionCreator(SET_REGISTRATION_SELECTED_ORDER_BY, 'order');

export const SET_REGISTRATION_TAPPED_ID = 'SET_REGISTRATION_TAPPED_ID';
export const setRegistrationTappedId = makeActionCreator(SET_REGISTRATION_TAPPED_ID, 'id');

export const SET_REGISTRATION_SEARCH_TEXT = 'SET_REGISTRATION_SEARCH_TEXT';
export const setRegistrationSearchText = makeActionCreator(SET_REGISTRATION_SEARCH_TEXT, 'text');


export const DOWNLOADING_REGISTRATION_ATTEMPTS = 'DOWNLOADING_REGISTRATION_ATTEMPTS';
export const downloadingRegistrationAttempts = makeActionCreator(DOWNLOADING_REGISTRATION_ATTEMPTS, 'status');

export const SET_REGISTRATION_ATTEMPTS = 'SET_REGISTRATION_ATTEMPTS';
export const setRegistrationAttempt = makeActionCreator(SET_REGISTRATION_ATTEMPTS, 'data');

export const ADD_REGISTRATION_ATTEMPT = 'ADD_REGISTRATION_ATTEMPT';
export const addRegistrationAttempt = makeActionCreator(ADD_REGISTRATION_ATTEMPT, 'data');

export const CLEAR_REGISTRATION_ATTEMPTS = 'CLEAR_REGISTRATION_ATTEMPTS';
export const clearRegistrationAttempts = makeActionCreator(CLEAR_REGISTRATION_ATTEMPTS);

export const DOWNLOADING_DELETING_REGISTRATION_ATTEMPT = 'DOWNLOADING_DELETING_REGISTRATION_ATTEMPT';
export const downloadingDeletingRegistrationAttempt = makeActionCreator(DOWNLOADING_DELETING_REGISTRATION_ATTEMPT, 'status');

export const APPROVING_REGISTRATION_ATTEMPT = 'APPROVING_REGISTRATION_ATTEMPT';
export const approvingRegistrationAttempt = makeActionCreator(APPROVING_REGISTRATION_ATTEMPT, 'status');

export const REJECTING_REGISTRATION_ATTEMPT = 'REJECTING_REGISTRATION_ATTEMPT';
export const rejectingRegistrationAttempt = makeActionCreator(REJECTING_REGISTRATION_ATTEMPT, 'status');


export const SET_REGISTRATION_ATTEMPT_SELECTED_PAGE_SIZE = 'SET_REGISTRATION_ATTEMPT_SELECTED_PAGE_SIZE';
export const setRegistrationAttemptSelectedPageSize = makeActionCreator(SET_REGISTRATION_ATTEMPT_SELECTED_PAGE_SIZE, 'size');

export const SET_REGISTRATION_ATTEMPT_SELECTED_ORDER_BY = 'SET_REGISTRATION_ATTEMPT_SELECTED_ORDER_BY';
export const setRegistrationAttemptSelectedOrderBy = makeActionCreator(SET_REGISTRATION_ATTEMPT_SELECTED_ORDER_BY, 'order');

export const SET_REGISTRATION_ATTEMPT_TAPPED_ID = 'SET_REGISTRATION_ATTEMPT_TAPPED_ID';
export const setRegistrationAttemptTappedId = makeActionCreator(SET_REGISTRATION_ATTEMPT_TAPPED_ID, 'id');

export const SET_REGISTRATION_ATTEMPT_SEARCH_TEXT = 'SET_REGISTRATION_ATTEMPT_SEARCH_TEXT';
export const setRegistrationAttemptSearchText = makeActionCreator(SET_REGISTRATION_ATTEMPT_SEARCH_TEXT, 'text');

export const ADDING_REGISTRATION_ATTEMPT_NOTES = 'ADDING_REGISTRATION_ATTEMPT_NOTES';
export const addingRegistrationAttemptNotes = makeActionCreator(ADDING_REGISTRATION_ATTEMPT_NOTES, 'status');


export const DOWNLOADING_CAR_OWNERSHIP_REWARDS = 'DOWNLOADING_CAR_OWNERSHIP_REWARDS';
export const downloadingCarOwnershipRewards = makeActionCreator(DOWNLOADING_CAR_OWNERSHIP_REWARDS, 'status');

export const SET_CAR_OWNERSHIP_REWARDS = 'SET_CAR_OWNERSHIP_REWARDS';
export const setCarOwnershipReward = makeActionCreator(SET_CAR_OWNERSHIP_REWARDS, 'data');

export const ADD_CAR_OWNERSHIP_REWARD = 'ADD_CAR_OWNERSHIP_REWARD';
export const addCarOwnershipReward = makeActionCreator(ADD_CAR_OWNERSHIP_REWARD, 'data');

export const CLEAR_CAR_OWNERSHIP_REWARDS = 'CLEAR_CAR_OWNERSHIP_REWARDS';
export const clearCarOwnershipRewards = makeActionCreator(CLEAR_CAR_OWNERSHIP_REWARDS);

export const DOWNLOADING_DELETING_CAR_OWNERSHIP_REWARD = 'DOWNLOADING_DELETING_CAR_OWNERSHIP_REWARD';
export const downloadingDeletingCarOwnershipReward = makeActionCreator(DOWNLOADING_DELETING_CAR_OWNERSHIP_REWARD, 'status');


export const SET_CAR_OWNERSHIP_REWARD_SELECTED_PAGE_SIZE = 'SET_CAR_OWNERSHIP_REWARD_SELECTED_PAGE_SIZE';
export const setCarOwnershipRewardSelectedPageSize = makeActionCreator(SET_CAR_OWNERSHIP_REWARD_SELECTED_PAGE_SIZE, 'size');

export const SET_CAR_OWNERSHIP_REWARD_SELECTED_ORDER_BY = 'SET_CAR_OWNERSHIP_REWARD_SELECTED_ORDER_BY';
export const setCarOwnershipRewardSelectedOrderBy = makeActionCreator(SET_CAR_OWNERSHIP_REWARD_SELECTED_ORDER_BY, 'order');

export const SET_CAR_OWNERSHIP_REWARD_TAPPED_ID = 'SET_CAR_OWNERSHIP_REWARD_TAPPED_ID';
export const setCarOwnershipRewardTappedId = makeActionCreator(SET_CAR_OWNERSHIP_REWARD_TAPPED_ID, 'id');

export const SET_CAR_OWNERSHIP_REWARD_SEARCH_TEXT = 'SET_CAR_OWNERSHIP_REWARD_SEARCH_TEXT';
export const setCarOwnershipRewardSearchText = makeActionCreator(SET_CAR_OWNERSHIP_REWARD_SEARCH_TEXT, 'text');


export const DOWNLOADING_MEMBERSHIP_REWARDS = 'DOWNLOADING_MEMBERSHIP_REWARDS';
export const downloadingMembershipRewards = makeActionCreator(DOWNLOADING_MEMBERSHIP_REWARDS, 'status');

export const SET_MEMBERSHIP_REWARDS = 'SET_MEMBERSHIP_REWARDS';
export const setMembershipReward = makeActionCreator(SET_MEMBERSHIP_REWARDS, 'data');

export const ADD_MEMBERSHIP_REWARD = 'ADD_MEMBERSHIP_REWARD';
export const addMembershipReward = makeActionCreator(ADD_MEMBERSHIP_REWARD, 'data');

export const CLEAR_MEMBERSHIP_REWARDS = 'CLEAR_MEMBERSHIP_REWARDS';
export const clearMembershipRewards = makeActionCreator(CLEAR_MEMBERSHIP_REWARDS);

export const DOWNLOADING_DELETING_MEMBERSHIP_REWARD = 'DOWNLOADING_DELETING_MEMBERSHIP_REWARD';
export const downloadingDeletingMembershipReward = makeActionCreator(DOWNLOADING_DELETING_MEMBERSHIP_REWARD, 'status');


export const SET_MEMBERSHIP_REWARD_SELECTED_PAGE_SIZE = 'SET_MEMBERSHIP_REWARD_SELECTED_PAGE_SIZE';
export const setMembershipRewardSelectedPageSize = makeActionCreator(SET_MEMBERSHIP_REWARD_SELECTED_PAGE_SIZE, 'size');

export const SET_MEMBERSHIP_REWARD_SELECTED_ORDER_BY = 'SET_MEMBERSHIP_REWARD_SELECTED_ORDER_BY';
export const setMembershipRewardSelectedOrderBy = makeActionCreator(SET_MEMBERSHIP_REWARD_SELECTED_ORDER_BY, 'order');

export const SET_MEMBERSHIP_REWARD_TAPPED_ID = 'SET_MEMBERSHIP_REWARD_TAPPED_ID';
export const setMembershipRewardTappedId = makeActionCreator(SET_MEMBERSHIP_REWARD_TAPPED_ID, 'id');

export const SET_MEMBERSHIP_REWARD_SEARCH_TEXT = 'SET_MEMBERSHIP_REWARD_SEARCH_TEXT';
export const setMembershipRewardSearchText = makeActionCreator(SET_MEMBERSHIP_REWARD_SEARCH_TEXT, 'text');


export const DOWNLOADING_CAR_OWNERSHIPS = 'DOWNLOADING_CAR_OWNERSHIPS';
export const downloadingCarOwnerships = makeActionCreator(DOWNLOADING_CAR_OWNERSHIPS, 'status');

export const SET_CAR_OWNERSHIPS = 'SET_CAR_OWNERSHIPS';
export const setCarOwnership = makeActionCreator(SET_CAR_OWNERSHIPS, 'data');

export const ADD_CAR_OWNERSHIP = 'ADD_CAR_OWNERSHIP';
export const addCarOwnership = makeActionCreator(ADD_CAR_OWNERSHIP, 'data');

export const CLEAR_CAR_OWNERSHIPS = 'CLEAR_CAR_OWNERSHIPS';
export const clearCarOwnerships = makeActionCreator(CLEAR_CAR_OWNERSHIPS);

export const DOWNLOADING_DELETING_CAR_OWNERSHIP = 'DOWNLOADING_DELETING_CAR_OWNERSHIP';
export const downloadingDeletingCarOwnership = makeActionCreator(DOWNLOADING_DELETING_CAR_OWNERSHIP, 'status');


export const SET_CAR_OWNERSHIP_SELECTED_PAGE_SIZE = 'SET_CAR_OWNERSHIP_SELECTED_PAGE_SIZE';
export const setCarOwnershipSelectedPageSize = makeActionCreator(SET_CAR_OWNERSHIP_SELECTED_PAGE_SIZE, 'size');

export const SET_CAR_OWNERSHIP_SELECTED_ORDER_BY = 'SET_CAR_OWNERSHIP_SELECTED_ORDER_BY';
export const setCarOwnershipSelectedOrderBy = makeActionCreator(SET_CAR_OWNERSHIP_SELECTED_ORDER_BY, 'order');

export const SET_CAR_OWNERSHIP_TAPPED_ID = 'SET_CAR_OWNERSHIP_TAPPED_ID';
export const setCarOwnershipTappedId = makeActionCreator(SET_CAR_OWNERSHIP_TAPPED_ID, 'id');

export const SET_CAR_OWNERSHIP_SEARCH_TEXT = 'SET_CAR_OWNERSHIP_SEARCH_TEXT';
export const setCarOwnershipSearchText = makeActionCreator(SET_CAR_OWNERSHIP_SEARCH_TEXT, 'text');


export const REFRESHING_VEHICLE_INFO = 'REFRESHING_VEHICLE_INFO';
export const refreshingVehicleInfo = makeActionCreator(REFRESHING_VEHICLE_INFO, 'status');


export const SAVING_CAR_REGISTRATION = 'SAVING_CAR_REGISTRATION';
export const savingCarRegistration = makeActionCreator(SAVING_CAR_REGISTRATION, 'status');

export const SAVING_CAR_REGISTRATION_ATTEMPT = 'SAVING_CAR_REGISTRATION_ATTEMPT';
export const savingCarRegistrationAttempt = makeActionCreator(SAVING_CAR_REGISTRATION_ATTEMPT, 'status');


export const DOWNLOADING_DMS_USER_SUBMISSIONS = 'DOWNLOADING_DMS_USER_SUBMISSIONS';
export const downloadingDMSUserSubmissions = makeActionCreator(DOWNLOADING_DMS_USER_SUBMISSIONS, 'status');

export const DOWNLOADING_DELETING_DMS_USER_SUBMISSIONS = 'DOWNLOADING_DELETING_DMS_USER_SUBMISSIONS';
export const downloadingDeletingDMSUserSubmission = makeActionCreator(DOWNLOADING_DELETING_DMS_USER_SUBMISSIONS, 'status');

export const SET_DMS_USER_SUBMISSIONS = 'SET_DMS_USER_SUBMISSIONS';
export const setDMSUserSubmission = makeActionCreator(SET_DMS_USER_SUBMISSIONS, 'data');

export const ADD_DMS_USER_SUBMISSION = 'ADD_DMS_USER_SUBMISSION';
export const addDMSUserSubmission = makeActionCreator(ADD_DMS_USER_SUBMISSION, 'data');

export const CLEAR_DMS_USER_SUBMISSIONS = 'CLEAR_DMS_USER_SUBMISSIONS';
export const clearDMSUserSubmissions = makeActionCreator(CLEAR_DMS_USER_SUBMISSIONS);


export const SET_DMS_USER_SUBMISSION_SELECTED_PAGE_SIZE = 'SET_DMS_USER_SUBMISSION_SELECTED_PAGE_SIZE';
export const setDMSUserSubmissionSelectedPageSize = makeActionCreator(SET_DMS_USER_SUBMISSION_SELECTED_PAGE_SIZE, 'size');

export const SET_DMS_USER_SUBMISSION_SELECTED_ORDER_BY = 'SET_DMS_USER_SUBMISSION_SELECTED_ORDER_BY';
export const setDMSUserSubmissionSelectedOrderBy = makeActionCreator(SET_DMS_USER_SUBMISSION_SELECTED_ORDER_BY, 'order');

export const SET_DMS_USER_SUBMISSION_TAPPED_ID = 'SET_DMS_USER_SUBMISSION_TAPPED_ID';
export const setDMSUserSubmissionTappedId = makeActionCreator(SET_DMS_USER_SUBMISSION_TAPPED_ID, 'id');

export const SET_DMS_USER_SUBMISSION_SEARCH_TEXT = 'SET_DMS_USER_SUBMISSION_SEARCH_TEXT';
export const setDMSUserSubmissionSearchText = makeActionCreator(SET_DMS_USER_SUBMISSION_SEARCH_TEXT, 'text');

export const RETRYING_DMS_USER_SUBMISSION = 'RETRYING_DMS_USER_SUBMISSION';
export const retryingDMSUserSubmission = makeActionCreator(RETRYING_DMS_USER_SUBMISSION, 'status');

export const ADD_RESERVATION = 'ADD_RESERVATION';
export const addReservation = makeActionCreator(ADD_RESERVATION, 'data');

export const APPROVING_RESERVATION = 'APPROVING_RESERVATION';
export const approvingReservation = makeActionCreator(APPROVING_RESERVATION, 'status');
