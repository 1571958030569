import { downloadTransactionReceived } from '../../../helper';
import { downloadingTransactionReceived, setTransactionReceived } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingTransactionReceived(true));
    const bookingCreated = await downloadTransactionReceived(token, startDate, endDate);

    dispatch(setTransactionReceived(bookingCreated));
  } finally {
    dispatch(downloadingTransactionReceived(false));
  }
};
