import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_GAME_ACTIVITY_REGISTRATION_REFERRALS = '/gameactivity/api/v1/RegistrationReferral?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_GAME_ACTIVITY_REGISTRATION_REFERRAL = '/gameactivity/api/v1/RegistrationReferral/{id}';
export const REST_URL_RECURRANCE_REFERRAL_REWARDS = '/gameactivity/api/v1/RecurrenceRegistrationReferralReward?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_RECURRANCE_REFERRAL_REWARD = '/gameactivity/api/v1/RecurrenceRegistrationReferralReward/{id}';
export const REST_URL_DAILY_CHECKINS = '/gameactivity/api/v1/DailyCheckin?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DAILY_CHECKIN = '/gameactivity/api/v1/DailyCheckin/{id}';
export const REST_URL_COMPLETED_PROFILES = '/gameactivity/api/v1/CompletedProfile?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=profile.fullName&filterString=status=enabled';
export const REST_URL_VIEW_COMPLETED_PROFILE = '/gameactivity/api/v1/CompletedProfile/{id}';
export const REST_URL_NEW_USER_NOTIFICATIONS = '/gameactivity/api/v1/NewUserNotification?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_NEW_USER_NOTIFICATION = '/gameactivity/api/v1/NewUserNotification/{id}';

export const ROUTE_NAME_GAME_ACTIVITY = '/game-activity';
export const ROUTE_NAME_GAME_ACTIVITY_REGISTRATION_REFERRAL = '/registration-referral';
export const ROUTE_NAME_RECURRANCE_REFERRAL_REWARD = '/recurrence-referral-reward';
export const ROUTE_NAME_DAILY_CHECKIN = '/daily-checkin';
export const ROUTE_NAME_COMPLETED_PROFILE = '/completed-profile';
export const ROUTE_NAME_NEW_USER_NOTIFICATION = '/new-user-notification';

export const RXFORM_GAME_ACTIVITY_REGISTRATION_REFERRAL = 'gameActivityRegistrationReferralPage';
export const RXFORM_RECURRANCE_REFERRAL_REWARD = 'recurrenceReferralRewardPage';
export const RXFORM_DAILY_CHECKIN = 'dailyCheckinPage';
export const RXFORM_COMPLETED_PROFILE = 'completedProfilePage';
export const RXFORM_NEW_USER_NOTIFICATION = 'newUserNotificationPage';

export const RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER = 'registeredUser';
export const RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER_ID = 'registeredUserId';
export const RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER_NAME = 'registeredUserName';
export const RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_POINT = 'pointAmount';
export const RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER = 'referrer';
export const RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER_USER_ID = 'referrerUserId';
export const RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER_USER_NAME = 'referrerUserName';
export const RXFIELD_RECURRANCE_REFERRAL_REWARD_USER_ID = 'profile.id';
export const RXFIELD_RECURRANCE_REFERRAL_REWARD_USER_NAME = 'profile.fullName';
export const RXFIELD_RECURRANCE_REFERRAL_REWARD_REWARD_STATUS = 'rewardStatus';
export const RXFIELD_RECURRANCE_REFERRAL_REWARD_REWARD_STATUS_NOTES = 'rewardStatusNotes';
export const RXFIELD_RECURRANCE_REFERRAL_REWARD_VOUCHER_REWARDS = 'vourcherRewards';
export const RXFIELD_RECURRANCE_REFERRAL_REWARD_REGISTRATION_REFERRALS = 'registrationReferrals';
export const RXFIELD_DAILY_CHECKIN_USER_ID = 'profile.id';
export const RXFIELD_DAILY_CHECKIN_USER_NAME = 'profile.fullName';
export const RXFIELD_DAILY_CHECKIN_POINT = 'pointAmount';
export const RXFIELD_COMPLETED_PROFILE_USER_ID = 'profile.id';
export const RXFIELD_COMPLETED_PROFILE_USER_NAME = 'profile.fullName';
export const RXFIELD_COMPLETED_PROFILE_POINT = 'pointAmount';
export const RXFIELD_NEW_USER_NOTIFICATION_USER_ID = 'userId';
export const RXFIELD_NEW_USER_NOTIFICATION_FULL_NAME = 'userFullName';
export const RXFIELD_NEW_USER_NOTIFICATION_EMAIL = 'userEmail';
export const RXFIELD_NEW_USER_NOTIFICATION_PHONE = 'userPhone';
export const RXFIELD_NEW_USER_NOTIFICATION_SCHEDULED_NOTIFICATION = 'scheduledNotification';
export const RXFIELD_NEW_USER_NOTIFICATION_IS_SENT = 'notificationWasSent';
export const RXFIELD_NEW_USER_NOTIFICATION_SENT_ON = 'notificationSentOn';
export const RXFIELD_NEW_USER_NOTIFICATION_NOTES = 'notes';

export const RXSTATE_GAME_ACTIVITY_REGISTRATION_REFERRALS = 'gameActivityRegistrationReferrals';
export const RXSTATE_GAME_ACTIVITY_REGISTRATION_REFERRAL_PAGE = 'uiGameActivityRegistrationReferral';
export const RXSTATE_RECURRANCE_REFERRAL_REWARDS = 'recurrenceReferralRewards';
export const RXSTATE_RECURRANCE_REFERRAL_REWARD_PAGE = 'uiRecurrenceReferralReward';
export const RXSTATE_DAILY_CHECKINS = 'dailyCheckins';
export const RXSTATE_DAILY_CHECKIN_PAGE = 'uiDailyCheckin';
export const RXSTATE_COMPLETED_PROFILES = 'completedProfiles';
export const RXSTATE_COMPLETED_PROFILE_PAGE = 'uiCompletedProfile';
export const RXSTATE_NEW_USER_NOTIFICATIONS = 'newUserNotifications';
export const RXSTATE_NEW_USER_NOTIFICATION_PAGE = 'uiNewUserNotification';

export const INITIAL_ORDER_BY_GAME_ACTIVITY_REGISTRATION_REFERRALS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_RECURRANCE_REFERRAL_REWARDS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_DAILY_CHECKINS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_COMPLETED_PROFILES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_NEW_USER_NOTIFICATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_GAME_ACTIVITY = 'MENUID_GAME_ACTIVITY';
export const MENUID_GAME_ACTIVITY_REGISTRATION_REFERRAL = 'MENUID_GAME_ACTIVITY_REGISTRATION_REFERRAL';
export const MENUID_GAME_ACTIVITY_RECURRANCE_REFERRAL_REWARD = 'MENUID_GAME_ACTIVITY_RECURRANCE_REFERRAL_REWARD';
export const MENUID_GAME_ACTIVITY_DAILY_CHECKIN = 'MENUID_GAME_ACTIVITY_DAILY_CHECKIN';
export const MENUID_GAME_ACTIVITY_COMPLETED_PROFILE = 'MENUID_GAME_ACTIVITY_COMPLETED_PROFILE';
export const MENUID_GAME_ACTIVITY_NEW_USER_NOTIFICATION = 'MENUID_GAME_ACTIVITY_NEW_USER_NOTIFICATION';

export const REWARD_STATUS_DROPDOWN_SELECTION = [
  'Success', 'Error', 'PartialError',
];
