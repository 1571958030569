import {
  DOWNLOADING_REGISTRATION_ATTEMPTS, DOWNLOADING_DELETING_REGISTRATION_ATTEMPT,
  SET_REGISTRATION_ATTEMPT_SELECTED_PAGE_SIZE, SET_REGISTRATION_ATTEMPT_TAPPED_ID,
  SET_REGISTRATION_ATTEMPT_SEARCH_TEXT, SET_REGISTRATION_ATTEMPT_SELECTED_ORDER_BY,
  APPROVING_REGISTRATION_ATTEMPT, REJECTING_REGISTRATION_ATTEMPT, ADDING_REGISTRATION_ATTEMPT_NOTES,
  REFRESHING_VEHICLE_INFO,
} from '../action';
import { INITIAL_ORDER_BY_REGISTRATION_ATTEMPTS } from '../../constant';

const initialState = {
  approving: false,
  downloadingDeleting: false,
  downloading: false,
  rejecting: false,
  addingREGISTRATION_ATTEMPTNote: false,
  refreshingVehicleInfo: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_REGISTRATION_ATTEMPTS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_REGISTRATION_ATTEMPTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_REGISTRATION_ATTEMPT:
      return { ...state, downloadingDeleting: action.status };
    case SET_REGISTRATION_ATTEMPT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_REGISTRATION_ATTEMPT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_REGISTRATION_ATTEMPT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_REGISTRATION_ATTEMPT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case APPROVING_REGISTRATION_ATTEMPT:
      return { ...state, approving: action.status };
    case REJECTING_REGISTRATION_ATTEMPT:
      return { ...state, rejecting: action.status };
    case ADDING_REGISTRATION_ATTEMPT_NOTES:
      return { ...state, addingREGISTRATION_ATTEMPTNote: action.status };
    case REFRESHING_VEHICLE_INFO:
      return { ...state, refreshingVehicleInfo: action.status };
    default: return state;
  }
};
