import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogActions,
  Grid, makeStyles, Typography, DialogTitle,
} from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  RXFORM_MAP_TOKENS_DIALOG, COLOR_DANGEROUS, RXFIELD_MAP_TOKEN_DIALOG_AIRPORT,
  RXFIELD_MAP_TOKEN_DIALOG_TOKEN_REMARK, COLOR_BACKGROUND_SECONDARY, COLOR_PRIMARY,
  COLOR_BACKGROUND,
} from '../constant';
import {
} from '../helper';
import LocalizedString from '../localization';
import GlobalLocalizedString from '../../../localization';
import {
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
} from '../../../redux-form-rendererer';
import { AccentButton } from '../../../component';
import { SimpleDataShape } from '../type';

const useStyles = makeStyles(() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  horizontalSpacer: {
    width: 20,
  },
  boldAndItalic: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  italic: {
    fontStyle: 'italic',
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  space: {
    width: 5,
  },
  title: {
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  totalPersonCountContainer: {
    padding: '10px 20px 10px 20px',
    background: COLOR_BACKGROUND_SECONDARY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0px 20px 0px',
  },
  totalPersonCountWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  captionText: {
    marginLeft: 10,
  },
  outlinedButton: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
    background: COLOR_BACKGROUND,
  },
}));


const MapTokensDialog = ({
  view, onCancelPressed, airports, handleSubmit,
  loadingAirports, onChangeAirportText, onAirportOptionSelected,
  onCountPressed, onMapTokenPressed, tokenCount, countingVoucherToken,
  onTokenRemarkTextChange, airport, tokenRemark, enableMapTokenButton,
  mapping,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={view}
      fullWidth
      maxWidth="sm"
      onClose={onCancelPressed}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.headerContainer}>
        <DialogTitle>{LocalizedString.voucherGarudaAirportPage.labelMapTokenToAirport}</DialogTitle>
      </div>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_MAP_TOKEN_DIALOG_AIRPORT}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.voucherGarudaAirportPage.placeholderAirport}
                label={LocalizedString.voucherGarudaAirportPage.placeholderAirport}
                disabled={countingVoucherToken || mapping}
                data={airports}
                loading={loadingAirports}
                onChangeText={onChangeAirportText}
                onOptionSelected={onAirportOptionSelected}
                required
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_MAP_TOKEN_DIALOG_TOKEN_REMARK}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.voucherGarudaAirportPage.placeholderTokenRemark}
                label={LocalizedString.voucherGarudaAirportPage.placeholderTokenRemark}
                onChange={onTokenRemarkTextChange}
                disabled={countingVoucherToken || mapping}
                required
              />
            </Grid>
            <Grid item className={classes.totalPersonCountContainer}>
              <div className={classes.totalPersonCountWrapper}>
                <Typography variant="h6" className={classes.boldText}>{tokenCount}</Typography>
                <Typography variant="subtitle2" className={classes.captionText}>{LocalizedString.voucherGarudaAirportPage.labelTokens}</Typography>
              </div>

              <AccentButton
                variant="outlined"
                caption={LocalizedString.voucherGarudaAirportPage.buttonCaptionCount}
                onClick={handleSubmit((values) => onCountPressed(values))}
                loading={countingVoucherToken}
                disabled={isEmpty(airport) || !tokenRemark || mapping}
                className={classes.outlinedButton}
              />
            </Grid>
            <Grid item className={classes.noteContainer}>
              <Typography className={classes.boldAndItalic}>
                {LocalizedString.voucherGarudaAirportPage.labelNotes}
              </Typography>
              <div className={classes.space} />
              <Typography className={classes.italic}>
                {LocalizedString.voucherGarudaAirportPage.labelWillOnlyMaps}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.buttonContainer}>
        <AccentButton
          variant="text"
          caption={GlobalLocalizedString.common.buttonCaptionCancel}
          onClick={onCancelPressed}
          className={classes.redButton}
          disabled={countingVoucherToken || mapping}
        />
        <AccentButton
          caption={LocalizedString.voucherGarudaAirportPage.buttonCaptionMapToken}
          onClick={handleSubmit((values) => onMapTokenPressed(values))}
          disabled={countingVoucherToken || !enableMapTokenButton}
          loading={mapping}
        />
      </DialogActions>
    </Dialog>
  );
};
export default reduxForm({
  form: RXFORM_MAP_TOKENS_DIALOG,
  enableReinitialize: true,
})(MapTokensDialog);

MapTokensDialog.propTypes = {
  airports: PropTypes.arrayOf(SimpleDataShape).isRequired,
  airport: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]).isRequired,

  view: PropTypes.bool.isRequired,
  loadingAirports: PropTypes.bool.isRequired,
  countingVoucherToken: PropTypes.bool.isRequired,
  enableMapTokenButton: PropTypes.bool.isRequired,
  mapping: PropTypes.bool.isRequired,

  tokenCount: PropTypes.number.isRequired,

  tokenRemark: PropTypes.string.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onAirportOptionSelected: PropTypes.func.isRequired,
  onChangeAirportText: PropTypes.func.isRequired,
  onCountPressed: PropTypes.func.isRequired,
  onMapTokenPressed: PropTypes.func.isRequired,
  onTokenRemarkTextChange: PropTypes.func.isRequired,
};
