import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    dashboardMenu: {
      title: 'Dashboard',
    },
    servicePromoPage: {
      title: 'Service & Promo',

      labelBookingInformation: 'Booking Information',
      labelTransactionInformation: 'Transaction Information',
      labelBookingCreated: 'Booking Created',
      labelBookingProceedByDealer: 'Booking Proceed By Dealer',
      labelBookingWaitForPayment: 'Booking Wait For Payment',
      labelBookingCompleted: 'Booking Completed',
      labelBookingCanceled: 'Booking Canceled',
      labelTransactionReceived: 'Transaction Received',
      labelTransactionPending: 'Transaction Pending',
      labelTransactionCompleted: 'Transaction Completed',
      labelTransactionCanceled: 'Transaction Canceled',
      labelTransactionAmount: 'Transaction Amount',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelApply: 'Apply',
      labelBookingCreatedByRepairSelection: 'Booking Created by Repair Selection',
      labelBookingCreatedByDeliveryMethod: 'Booking Created by Delivery Method',
      labelBookingCreatedByServiceInterval: 'Booking Created by Service Interval',
      labelBookingCreatedByVehicleModel: 'Booking Created by Vehicle Model',
      labelTransactionCount: 'Transaction Count',
      labelRemaningStock: 'Remaning Stock*',
      labelTransactionCreatedByVehicleModel: 'Transaction Created by Vehicle Model',
      labelPeriod: 'Period',
      labelRealTimeStock: 'Real time stock',
    },
  },
  id: {
    dashboardMenu: {
      title: 'Dasbor',
    },
    servicePromoPage: {
      title: 'Servis & Promo',

      labelBookingInformation: 'Informasi Reservasi',
      labelTransactionInformation: 'Informasi Transaksi',
      labelBookingCreated: 'Reservasi Dibuat',
      labelBookingProceedByDealer: 'Reservasi Diproses Dealer',
      labelBookingWaitForPayment: 'Reservasi Menunggu Pembayaran',
      labelBookingCompleted: 'Reservasi Selesai',
      labelBookingCanceled: 'Reservasi Dibatalkan',
      labelTransactionReceived: 'Transaksi Diterima',
      labelTransactionPending: 'Transaksi Tertunda',
      labelTransactionCompleted: 'Transaksi Selesai',
      labelTransactionCanceled: 'Transaksi Dibatalkan',
      labelTransactionAmount: 'Jumlah Transaksi',
      labelStartDate: 'Tanggal Awal',
      labelEndDate: 'Tanggal Akhir',
      labelApply: 'Terapkan',
      labelBookingCreatedByRepairSelection: 'Reservasi Dibuat Berdasar Tipe Perbaikan',
      labelBookingCreatedByDeliveryMethod: 'Reservasi Dibuat Berdasar Metode Pengiriman',
      labelBookingCreatedByServiceInterval: 'Reservasi Dibuat Berdasar Interval Servis',
      labelBookingCreatedByVehicleModel: 'Reservasi Dibuat Berdasar Model Kendaraan',
      labelTransactionCount: 'Total Transaksi',
      labelRemaningStock: 'Sisa Stok*',
      labelTransactionCreatedByVehicleModel: 'Transaksi Dibuat Berdasar Model Kendaraan',
      labelPeriod: 'Periode',
      labelRealTimeStock: 'Stok waktu riil',
    },
  },
});

export default LocalizedString;
