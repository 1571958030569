import { downloadRedemptionHistories, transformSearchText } from '../../../helper';
import { downloadingRedemptionHistories, setRedemptionHistories } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, redemptionHistories, uiRedemptionHistory,
    uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = redemptionHistories;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiRedemptionHistory;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(downloadingRedemptionHistories(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadRedemptionHistories(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setRedemptionHistories(list));
    }
  } finally {
    dispatch(downloadingRedemptionHistories(false));
  }
};
