import {
  saveVoucherTokenImport, transformSearchText,
} from '../../../helper';
import { savingVoucherTokensImports } from '../simple-action';

export default () => async (dispatch, getState) => {
  const {
    authentication, tokenImports, uiTokenImport, uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = tokenImports;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiTokenImport;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(savingVoucherTokensImports(true));
    const response = await saveVoucherTokenImport(
      meta.currentPage,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'voucher-token-import.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(savingVoucherTokensImports(false));
  }
};
