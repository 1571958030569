import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT, PROCESSING_STATUSES,
  RXFIELD_PUSH_NOTIFICATION_BODY, RXFIELD_PUSH_NOTIFICATION_DATA, RXFIELD_PUSH_NOTIFICATION_FLAG,
  RXFIELD_PUSH_NOTIFICATION_NOTES, RXFIELD_PUSH_NOTIFICATION_PROCESSING_STATUS,
  RXFIELD_PUSH_NOTIFICATION_TITLE, RXFIELD_PUSH_NOTIFICATION_TO, RXFIELD_PUSH_NOTIFICATION_TOS,
  RXSTATE_PUSH_NOTIFICATION_PAGE, RXSTATE_PUSH_NOTIFICATIONS, RXFORM_PUSH_NOTIFICATION,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';

const PushNotificationPage = ({
  downloading, downloadingDeleting, resending,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onChangePage, onChangePageSize, onConfirmResendPressed, onRefresh, onResendPressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  tappedId,
}) => (
  <FunctionalPage
    data={RXSTATE_PUSH_NOTIFICATIONS}
    uiPage={RXSTATE_PUSH_NOTIFICATION_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.notificationMenu.buttonCaptionResend,
        disabled: downloadingDeleting || resending,
        onPress: () => onResendPressed(tappedId),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.pushNotificationScreen.labelProcessingStatus,
        field: 'processingStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: PROCESSING_STATUSES,
      },
      {
        title: LocalizedString.pushNotificationScreen.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.pushNotificationScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.pushNotificationScreen.labelTo,
        field: 'to',
        sorting: !downloading,
        render: ({ to }) => (to ? `${to.substr(0, 20)}...` : null),
      },
      { title: LocalizedString.pushNotificationScreen.labelTitle, field: 'title', sorting: !downloading },
      {
        title: LocalizedString.pushNotificationScreen.labelBody, field: 'body', sorting: !downloading, render: ({ body }) => (body ? `${body.substr(0, 20)}...` : null),
      },
      { title: LocalizedString.pushNotificationScreen.labelProcessingStatus, field: 'processingStatus', sorting: !downloading },
      {
        title: LocalizedString.pushNotificationScreen.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmResendPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.pushNotificationScreen.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
    usefullWidthDialog
  >
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderTitle}
            label={LocalizedString.pushNotificationScreen.placeholderTitle}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_FLAG}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderFlag}
            label={LocalizedString.pushNotificationScreen.placeholderFlag}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_BODY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderBody}
            label={LocalizedString.pushNotificationScreen.placeholderBody}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_DATA}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderData}
            label={LocalizedString.pushNotificationScreen.placeholderData}
            disabled
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_TO}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderTo}
            label={LocalizedString.pushNotificationScreen.placeholderTo}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_TOS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderTos}
            label={LocalizedString.pushNotificationScreen.placeholderTos}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_PROCESSING_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderProcessingStatus}
            label={LocalizedString.pushNotificationScreen.placeholderProcessingStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PUSH_NOTIFICATION_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pushNotificationScreen.placeholderNotes}
            label={LocalizedString.pushNotificationScreen.placeholderNotes}
            disabled
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PUSH_NOTIFICATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PushNotificationPage);

PushNotificationPage.propTypes = {
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  resending: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmResendPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onResendPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  tappedId: PropTypes.string.isRequired,
};
