import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_JAW_2023_SCANNED_CODES = 'DOWNLOADING_JAW_2023_SCANNED_CODES';
export const downloadingJaw2023ScannedCodes = makeActionCreator(DOWNLOADING_JAW_2023_SCANNED_CODES, 'status');

export const DOWNLOADING_JAW_2023_SCANNED_CODE = 'DOWNLOADING_JAW_2023_SCANNED_CODE';
export const downloadingJaw2023ScannedCode = makeActionCreator(DOWNLOADING_JAW_2023_SCANNED_CODE, 'status');

export const SET_JAW_2023_SCANNED_CODES = 'SET_JAW_2023_SCANNED_CODES';
export const setJaw2023ScannedCodes = makeActionCreator(SET_JAW_2023_SCANNED_CODES, 'data');

export const SET_JAW_2023_SCANNED_CODE = 'SET_JAW_2023_SCANNED_CODE';
export const setJaw2023ScannedCode = makeActionCreator(SET_JAW_2023_SCANNED_CODE, 'data');

export const CLEAR_JAW_2023_SCANNED_CODES = 'CLEAR_JAW_2023_SCANNED_CODES';
export const clearJaw2023ScannedCodes = makeActionCreator(CLEAR_JAW_2023_SCANNED_CODES, 'data');

export const SET_JAW_2023_SCANNED_CODES_PAGE_SIZE = 'SET_JAW_2023_SCANNED_CODES_PAGE_SIZE';
export const setJaw2023ScannedCodesPageSize = makeActionCreator(SET_JAW_2023_SCANNED_CODES_PAGE_SIZE, 'size');

export const SET_JAW_2023_SCANNED_CODES_ORDER_BY = 'SET_JAW_2023_SCANNED_CODES_ORDER_BY';
export const setJaw2023ScannedCodesOrderBy = makeActionCreator(SET_JAW_2023_SCANNED_CODES_ORDER_BY, 'order');

export const SET_JAW_2023_SCANNED_CODES_SEARCH_TEXT = 'SET_JAW_2023_SCANNED_CODES_SEARCH_TEXT';
export const setJaw2023ScannedCodesSearchText = makeActionCreator(SET_JAW_2023_SCANNED_CODES_SEARCH_TEXT, 'text');

export const SET_JAW_2023_SCANNED_CODES_TAPPED_ID = 'SET_JAW_2023_SCANNED_CODES_TAPPED_ID';
export const setJaw2023ScannedCodesTappedId = makeActionCreator(SET_JAW_2023_SCANNED_CODES_TAPPED_ID, 'id');

export const SET_JAW_2023_SCANNED_CODES_FILTER_STRING = 'SET_JAW_2023_SCANNED_CODES_FILTER_STRING';
export const setJaw2023ScannedCodesFilterString = makeActionCreator(SET_JAW_2023_SCANNED_CODES_FILTER_STRING, 'text');

export const SAVING_JAW_2023_2022_SCANNED_CODES = 'SAVING_JAW_2023_2022_TSCANNED_CODE';
export const savingJaw2023ScannedCodes = makeActionCreator(SAVING_JAW_2023_2022_SCANNED_CODES, 'status');


export const DOWNLOADING_JAW_2023_PRIZE_REDEMPTIONS = 'DOWNLOADING_JAW_2023_PRIZE_REDEMPTIONS';
export const downloadingJaw2023PrizeRedemptions = makeActionCreator(DOWNLOADING_JAW_2023_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_JAW_2023_PRIZE_REDEMPTION = 'DOWNLOADING_JAW_2023_PRIZE_REDEMPTION';
export const downloadingJaw2023PrizeRedemption = makeActionCreator(DOWNLOADING_JAW_2023_PRIZE_REDEMPTION, 'status');

export const ADD_JAW_2023_PRIZE_REDEMPTION = 'ADD_JAW_2023_PRIZE_REDEMPTION';
export const addJaw2023PrizeRedemption = makeActionCreator(ADD_JAW_2023_PRIZE_REDEMPTION, 'data');

export const SET_JAW_2023_PRIZE_REDEMPTIONS = 'SET_JAW_2023_PRIZE_REDEMPTIONS';
export const setJaw2023PrizeRedemptions = makeActionCreator(SET_JAW_2023_PRIZE_REDEMPTIONS, 'data');

export const CLEAR_JAW_2023_PRIZE_REDEMPTIONS = 'CLEAR_JAW_2023_PRIZE_REDEMPTIONS';
export const clearJaw2023PrizeRedemptions = makeActionCreator(
  CLEAR_JAW_2023_PRIZE_REDEMPTIONS,
);

export const SET_JAW_2023_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_JAW_2023_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE';
export const setJaw2023PrizeRedemptionSelectedPageSize = makeActionCreator(SET_JAW_2023_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_JAW_2023_PRIZE_REDEMPTION_SELECTED_ORDER_BY = 'SET_JAW_2023_PRIZE_REDEMPTION_SELECTED_ORDER_BY';
export const setJaw2023PrizeRedemptionSelectedOrderBy = makeActionCreator(SET_JAW_2023_PRIZE_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_JAW_2023_PRIZE_REDEMPTION_TAPPED_ID = 'SET_JAW_2023_PRIZE_REDEMPTION_TAPPED_ID';
export const setJaw2023PrizeRedemptionTappedId = makeActionCreator(SET_JAW_2023_PRIZE_REDEMPTION_TAPPED_ID, 'id');

export const SET_JAW_2023_PRIZE_REDEMPTION_SEARCH_TEXT = 'SET_JAW_2023_PRIZE_REDEMPTION_SEARCH_TEXT';
export const setJaw2023PrizeRedemptionSearchText = makeActionCreator(SET_JAW_2023_PRIZE_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_JAW_2023_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING = 'SET_JAW_2023_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING';
export const setJaw2023PrizeRedemptionAdvancedFilterString = makeActionCreator(SET_JAW_2023_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_JAW_2023_2022_PRIZE_REDEMPTIONS = 'SAVING_JAW_2023_2022_PRIZE_REDEMPTIONS';
export const savingJaw2023PrizeRedemptions = makeActionCreator(SAVING_JAW_2023_2022_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_JAW_2023_2022_TRANSACTIONS = 'DOWNLOADING_JAW_2023_2022_TRANSACTIONS';
export const downloadingJaw2023Transactions = makeActionCreator(DOWNLOADING_JAW_2023_2022_TRANSACTIONS, 'status');

export const DOWNLOADING_JAW_2023_2022_TRANSACTION = 'DOWNLOADING_JAW_2023_2022_TRANSACTION';
export const downloadingJaw2023Transaction = makeActionCreator(DOWNLOADING_JAW_2023_2022_TRANSACTION, 'status');

export const SAVING_JAW_2023_2022_TRANSACTIONS = 'SAVING_JAW_2023_2022_TRANSACTIONS';
export const savingJaw2023Transactions = makeActionCreator(SAVING_JAW_2023_2022_TRANSACTIONS, 'status');

export const ADD_JAW_2023_2022_TRANSACTION = 'ADD_JAW_2023_2022_TRANSACTION';
export const addJaw2023Transaction = makeActionCreator(ADD_JAW_2023_2022_TRANSACTION, 'data');

export const SET_JAW_2023_2022_TRANSACTIONS = 'SET_JAW_2023_2022_TRANSACTIONS';
export const setJaw2023Transactions = makeActionCreator(SET_JAW_2023_2022_TRANSACTIONS, 'data');

export const CLEAR_JAW_2023_2022_TRANSACTIONS = 'CLEAR_JAW_2023_2022_TRANSACTIONS';
export const clearJaw2023Transactions = makeActionCreator(
  CLEAR_JAW_2023_2022_TRANSACTIONS,
);

export const SET_JAW_2023_2022_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_JAW_2023_2022_TRANSACTION_SELECTED_PAGE_SIZE';
export const setJaw2023TransactionSelectedPageSize = makeActionCreator(SET_JAW_2023_2022_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_JAW_2023_2022_TRANSACTION_SELECTED_ORDER_BY = 'SET_JAW_2023_2022_TRANSACTION_SELECTED_ORDER_BY';
export const setJaw2023TransactionSelectedOrderBy = makeActionCreator(SET_JAW_2023_2022_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_JAW_2023_2022_TRANSACTION_TAPPED_ID = 'SET_JAW_2023_2022_TRANSACTION_TAPPED_ID';
export const setJaw2023TransactionTappedId = makeActionCreator(SET_JAW_2023_2022_TRANSACTION_TAPPED_ID, 'id');

export const SET_JAW_2023_2022_TRANSACTION_SEARCH_TEXT = 'SET_JAW_2023_2022_TRANSACTION_SEARCH_TEXT';
export const setJaw2023TransactionSearchText = makeActionCreator(SET_JAW_2023_2022_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_JAW_2023_2022_TRANSACTION_ADVANCED_FILTER_STRING = 'SET_JAW_2023_2022_TRANSACTION_ADVANCED_FILTER_STRING';
export const setJaw2023TransactionAdvancedFilterString = makeActionCreator(SET_JAW_2023_2022_TRANSACTION_ADVANCED_FILTER_STRING, 'text');


export const DOWNLOADING_JAW_2023_SURVEYS = 'DOWNLOADING_JAW_2023_SURVEYS';
export const downloadingJaw2023Surveys = makeActionCreator(DOWNLOADING_JAW_2023_SURVEYS, 'status');

export const DOWNLOADING_JAW_2023_SURVEY = 'DOWNLOADING_JAW_2023_SURVEY';
export const downloadingJaw2023Survey = makeActionCreator(DOWNLOADING_JAW_2023_SURVEY, 'status');

export const SET_JAW_2023_SURVEYS = 'SET_JAW_2023_SURVEYS';
export const setJaw2023Surveys = makeActionCreator(SET_JAW_2023_SURVEYS, 'data');

export const SET_JAW_2023_SURVEY = 'SET_JAW_2023_SURVEY';
export const setJaw2023Survey = makeActionCreator(SET_JAW_2023_SURVEY, 'data');

export const CLEAR_JAW_2023_SURVEYS = 'CLEAR_JAW_2023_SURVEYS';
export const clearJaw2023Surveys = makeActionCreator(CLEAR_JAW_2023_SURVEYS, 'data');

export const SET_JAW_2023_SURVEYS_PAGE_SIZE = 'SET_JAW_2023_SURVEYS_PAGE_SIZE';
export const setJaw2023SurveysPageSize = makeActionCreator(SET_JAW_2023_SURVEYS_PAGE_SIZE, 'size');

export const SET_JAW_2023_SURVEYS_ORDER_BY = 'SET_JAW_2023_SURVEYS_ORDER_BY';
export const setJaw2023SurveysOrderBy = makeActionCreator(SET_JAW_2023_SURVEYS_ORDER_BY, 'order');

export const SET_JAW_2023_SURVEYS_SEARCH_TEXT = 'SET_JAW_2023_SURVEYS_SEARCH_TEXT';
export const setJaw2023SurveysSearchText = makeActionCreator(SET_JAW_2023_SURVEYS_SEARCH_TEXT, 'text');

export const SET_JAW_2023_SURVEYS_TAPPED_ID = 'SET_JAW_2023_SURVEYS_TAPPED_ID';
export const setJaw2023SurveysTappedId = makeActionCreator(SET_JAW_2023_SURVEYS_TAPPED_ID, 'id');

export const SET_JAW_2023_SURVEYS_FILTER_STRING = 'SET_JAW_2023_SURVEYS_FILTER_STRING';
export const setJaw2023SurveysFilterString = makeActionCreator(SET_JAW_2023_SURVEYS_FILTER_STRING, 'text');

export const SAVING_JAW_2023_2022_SURVEYS = 'SAVING_JAW_2023_2022_TSURVEY';
export const savingJaw2023Surveys = makeActionCreator(SAVING_JAW_2023_2022_SURVEYS, 'status');

export const DOWNLOADING_JAW_2023_SPKS = 'DOWNLOADING_JAW_2023_SPKS';
export const downloadingJaw2023Spks = makeActionCreator(DOWNLOADING_JAW_2023_SPKS, 'status');

export const DOWNLOADING_JAW_2023_SPK = 'DOWNLOADING_JAW_2023_SPK';
export const downloadingJaw2023Spk = makeActionCreator(DOWNLOADING_JAW_2023_SPK, 'status');

export const SET_JAW_2023_SPKS = 'SET_JAW_2023_SPKS';
export const setJaw2023Spks = makeActionCreator(SET_JAW_2023_SPKS, 'data');

export const SET_JAW_2023_SPK = 'SET_JAW_2023_SPK';
export const setJaw2023Spk = makeActionCreator(SET_JAW_2023_SPK, 'data');

export const CLEAR_JAW_2023_SPKS = 'CLEAR_JAW_2023_SPKS';
export const clearJaw2023Spks = makeActionCreator(CLEAR_JAW_2023_SPKS, 'data');

export const SET_JAW_2023_SPKS_PAGE_SIZE = 'SET_JAW_2023_SPKS_PAGE_SIZE';
export const setJaw2023SpksPageSize = makeActionCreator(SET_JAW_2023_SPKS_PAGE_SIZE, 'size');

export const SET_JAW_2023_SPKS_ORDER_BY = 'SET_JAW_2023_SPKS_ORDER_BY';
export const setJaw2023SpksOrderBy = makeActionCreator(SET_JAW_2023_SPKS_ORDER_BY, 'order');

export const SET_JAW_2023_SPKS_SEARCH_TEXT = 'SET_JAW_2023_SPKS_SEARCH_TEXT';
export const setJaw2023SpksSearchText = makeActionCreator(SET_JAW_2023_SPKS_SEARCH_TEXT, 'text');

export const SET_JAW_2023_SPKS_TAPPED_ID = 'SET_JAW_2023_SPKS_TAPPED_ID';
export const setJaw2023SpksTappedId = makeActionCreator(SET_JAW_2023_SPKS_TAPPED_ID, 'id');

export const SET_JAW_2023_SPKS_FILTER_STRING = 'SET_JAW_2023_SPKS_FILTER_STRING';
export const setJaw2023SpksFilterString = makeActionCreator(SET_JAW_2023_SPKS_FILTER_STRING, 'text');

export const SAVING_JAW_2023_2022_SPKS = 'SAVING_JAW_2023_2022_TSPK';
export const savingJaw2023Spks = makeActionCreator(SAVING_JAW_2023_2022_SPKS, 'status');
