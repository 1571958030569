import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFORM_REGISTRATION_ATTEMPT, RXSTATE_REGISTRATION_ATTEMPTS, RXSTATE_REGISTRATION_ATTEMPT_PAGE,
  RXFIELD_REGISTRATION_ATTEMPT_USER_ID,
  RXFIELD_REGISTRATION_ATTEMPT_USER_NAME,
  RXFIELD_REGISTRATION_ATTEMPT_VIN,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_REGISTRATION_ATTEMPT_VIN}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationAttemptPage.placeholderVin}
          label={LocalizedString.registrationAttemptPage.placeholderVin}
          disabled
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_REGISTRATION_ATTEMPT_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationAttemptPage.placeholderUserId}
          label={LocalizedString.registrationAttemptPage.placeholderUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_REGISTRATION_ATTEMPT_USER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationAttemptPage.placeholderUserName}
          label={LocalizedString.registrationAttemptPage.placeholderUserName}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const RegistrationAttemptPage = ({
  users,
  downloading, loadingUsers, onDownloadPressed,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onChangePage, onChangePageSize, onChangeUserText, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_REGISTRATION_ATTEMPTS}
    uiPage={RXSTATE_REGISTRATION_ATTEMPT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.registrationAttemptPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.registrationAttemptPage.labelUserName,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.registrationAttemptPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.registrationAttemptPage.labelVin, field: 'vin', sorting: !downloading },
      { title: LocalizedString.registrationAttemptPage.labelUserName, field: 'user.fullName', sorting: !downloading },
      {
        title: LocalizedString.registrationAttemptPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.registrationAttemptPage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
    enableSave
    onDownloadPressed={onDownloadPressed}
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_REGISTRATION_ATTEMPT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RegistrationAttemptPage);

RegistrationAttemptPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
