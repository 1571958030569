import PropTypes from 'prop-types';

export * from '../../type';

const MerchantShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const VoucherShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  actionType: PropTypes.string,
  image: PropTypes.string,
  merchant: MerchantShape,
});

const UserShape = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
});

export const VoucherTokenShape = PropTypes.shape({
  code: PropTypes.string,
  validFrom: PropTypes.string,
  validUntil: PropTypes.string,
  voucher: VoucherShape,
  isPurchased: PropTypes.bool,
  purchasedPoint: PropTypes.number,
  purchasedOn: PropTypes.string,
  purchasedBy: UserShape,
  isClaimed: PropTypes.bool,
  claimedOn: PropTypes.string,
  claimNotes: PropTypes.string,
  claimedBy: UserShape,
  isRevoked: PropTypes.bool,
  revokedOn: PropTypes.string,
  revocationNotes: PropTypes.string,
  revokedBy: UserShape,
  id: PropTypes.string,
});
