import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import {
  CircularProgress, Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid,
  makeStyles, MenuItem, Paper, Select, Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import {
  AccentButton, BasePage, TooltipText, UnsavedChangesDialog,
} from '../../component';
import {
  BOOLEAN_DROPDOWN_DATA_LOWERCASE, COLOR_BACKGROUND, COLOR_DANGEROUS, COLOR_PRIMARY,
  COLOR_SECONDARY, CONFIG_VALUE_DATA_TYPE_BOOLEAN, CONFIG_VALUE_DATA_TYPE_ENUM, RXFORM_CONFIG_VALUE,
} from '../../constant';
import GlobalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../../../type';

const useStyles = makeStyles(() => ({
  title: {
    color: COLOR_SECONDARY,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 10,
  },
  select: {
    border: `2px solid ${COLOR_PRIMARY}`,
    borderRadius: '40px',
    width: 200,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    color: COLOR_PRIMARY,
    fontWeight: 'bold',
    '&:focus': {
      border: `2px solid ${COLOR_PRIMARY}`,
      borderRadius: '40px',
    },
  },
  selectIcon: {
    color: COLOR_PRIMARY,
    right: 15,
  },
  bodyContainer: {
    overflowY: 'auto',
    height: 'calc(100vh - 400px)',
  },
  bodyLoadingContainer: {
    padding: '15px 24px',
    overflowY: 'auto',
    height: 'calc(100vh - 400px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityIndicator: {
    color: COLOR_SECONDARY,
  },
  sectionTitleContainer: {
    marginTop: 20,
  },
  sectionTitle: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    fontSize: 16,
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '15px 24px',
    margin: '20px 0px',
  },
  saveButton: {
    borderRadius: 50,
    background: COLOR_PRIMARY,
    color: COLOR_BACKGROUND,
    paddingLeft: 40,
    paddingRight: 40,
  },
  resetButton: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
  expansionContainer: {
    marginBottom: 15,
  },
  expansionDetailContainer: {
    flexDirection: 'column',
  },
  sectionContainer: {
    padding: '15px 24px',
  },
  divider: {
    height: 2,
  },
}));

const renderField = (item, onDropdownOptionSelected, editingConfigItems) => {
  const pickerData = item.enumOptions ? item.enumOptions.split('|') : null;
  if (item.dataType === CONFIG_VALUE_DATA_TYPE_ENUM) {
    return (
      <Field
        name={item.id}
        label=""
        component={renderReduxFormSimpleDropdownField}
        data={pickerData}
        onOptionSelected={(option) => onDropdownOptionSelected(option, item.id)}
        disabled={editingConfigItems}
        helperText={item.needRestart
          ? LocalizedString.configValueScreen.helperLabelNeedRestart : null}
      />
    );
  }
  if (item.dataType === CONFIG_VALUE_DATA_TYPE_BOOLEAN) {
    return (
      <Field
        name={item.id}
        label=""
        component={renderReduxFormSimpleDropdownField}
        data={BOOLEAN_DROPDOWN_DATA_LOWERCASE}
        onOptionSelected={(option) => onDropdownOptionSelected(option, item.id)}
        disabled={editingConfigItems}
        helperText={item.needRestart
          ? LocalizedString.configValueScreen.helperLabelNeedRestart : null}
      />
    );
  }
  return (
    <Field
      name={item.id}
      label=""
      InputLabelProps={{ shrink: false }}
      component={renderReduxFormOutlinedTextField}
      disabled={editingConfigItems}
      helperText={item.needRestart
        ? LocalizedString.configValueScreen.helperLabelNeedRestart : ''}
      secureTextEntry={item.encrypted}
      multiline={item.multiLine}
      rows={3}
    />
  );
};

const renderGrid = (data, editingConfigItems, onDropdownOptionSelected,
  classes) => data.map((item) => (
    <Grid key={item.id} container spacing={3}>
      <Grid item sm={3} md={3} className={classes.fieldWrapper}>
        <Typography>{item.label}</Typography>
      </Grid>
      <Grid item sm={6} md={6}>
        {renderField(item, onDropdownOptionSelected, editingConfigItems)}
      </Grid>
      <Grid item sm={3} md={3} className={classes.fieldWrapper}>
        <TooltipText caption={item.description} />
      </Grid>
    </Grid>
));

const renderExpansionPanel = (data, isTheLastItem, editingConfigItems, onDropdownOptionSelected,
  classes) => {
  if (!data.sectionName) {
    return (
      <div key="empty-section-name">
        <div className={classes.sectionContainer}>
          {renderGrid(data.sectionData, editingConfigItems, onDropdownOptionSelected, classes)}
        </div>
        {!isTheLastItem && (<Divider className={classes.divider} />)}
      </div>
    );
  }
  return (
    <ExpansionPanel key={data.sectionName} className={classes.expansionContainer}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.sectionTitle}>{data.sectionName}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.expansionDetailContainer }}>
        {renderGrid(data.sectionData, editingConfigItems, onDropdownOptionSelected, classes)}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const ConfigValuePage = ({
  initialValues, formValues,
  allConfigGroupFilter, configData,
  displayUnsavedChangeDialog, downloading, editingConfigItems,
  handleSubmit, onAppear, onCloseDialog, onFilterValueSelected, onDropdownOptionSelected,
  onResetPressed, onSaveButtonPressed, onSideMenuPressed,
  selectedFilter,
}) => {
  const classes = useStyles();
  useEffect(() => onAppear(selectedFilter), [selectedFilter, onAppear]);
  const newValuesArray = formValues ? Object.values(formValues) : [];
  const initialValuesArray = initialValues ? Object.values(initialValues) : [];
  const valuesNotChanged = isEqual(newValuesArray, initialValuesArray);

  return (
    <BasePage
      onSideMenuPressed={(item, history) => onSideMenuPressed(item, history, selectedFilter)}
    >
      <div className={classes.container}>
        <UnsavedChangesDialog
          display={displayUnsavedChangeDialog}
          onClose={onCloseDialog}
        />

        <Typography variant="h4" className={classes.title}>
          {LocalizedString.configValueScreen.title}
        </Typography>

        <Paper className={classes.headerContainer} elevation={3}>
          <Grid container spacing={3}>
            <Grid item sm md>
              <Typography className={classes.boldText}>
                {LocalizedString.configValueScreen.labelFilterConfigGroup}
              </Typography>
            </Grid>
            <Grid item>
              <Select
                value={selectedFilter}
                onChange={(event) => onFilterValueSelected(event, valuesNotChanged)}
                autoWidth
                classes={{
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                disableUnderline
                IconComponent={ExpandMore}
              >
                {allConfigGroupFilter.map((filter) => (
                  <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Paper>

        {selectedFilter !== LocalizedString.configValueScreen.labelFilterBy && (
        <Paper
          classes={{ root: downloading ? classes.bodyLoadingContainer : classes.bodyContainer }}
          elevation={3}
        >
          {downloading
            ? (<CircularProgress className={classes.activityIndicator} />)
            : configData.map((data, i) => renderExpansionPanel(data, configData.length - 1 === i,
              editingConfigItems, onDropdownOptionSelected, classes))}
        </Paper>
        )}

        <Paper elevation={3} className={classes.footerContainer}>
          <AccentButton
            onClick={onResetPressed}
            variant="text"
            caption={GlobalizedString.common.buttonCaptionReset}
            className={classes.resetButton}
            disabled={selectedFilter === LocalizedString.configValueScreen.labelFilterBy
            || configData.length === 0 || valuesNotChanged || editingConfigItems}
          />
          <AccentButton
            onClick={handleSubmit(onSaveButtonPressed)}
            variant="contained"
            caption={GlobalizedString.common.buttonCaptionSave}
            className={classes.saveButton}
            disabled={selectedFilter === LocalizedString.configValueScreen.labelFilterBy
            || configData.length === 0 || valuesNotChanged || editingConfigItems}
          />
        </Paper>
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_CONFIG_VALUE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ConfigValuePage);

ConfigValuePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  formValues: FormInitialValueShape,
  allConfigGroupFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  configData: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayUnsavedChangeDialog: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  editingConfigItems: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onFilterValueSelected: PropTypes.func.isRequired,
  onDropdownOptionSelected: PropTypes.func.isRequired,
  onResetPressed: PropTypes.func.isRequired,
  onSaveButtonPressed: PropTypes.func.isRequired,
  onSideMenuPressed: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired,
};

ConfigValuePage.defaultProps = {
  formValues: {},
};
