import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  CircularProgress, Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import { AccentButton, BasePage } from '../../component';
import {
  COLOR_SECONDARY, RXFIELD_COUPON_LEADER_BOARD_COUNT, RXFIELD_COUPON_LEADER_BOARD_END_PERIOD,
  RXFIELD_COUPON_LEADER_BOARD_RESULT, RXFIELD_COUPON_LEADER_BOARD_START_PERIOD,
  RXFIELD_COUPON_LEADER_BOARD_START_RANK, RXFORM_COUPON_LEADER_BOARD,
} from '../../constant';
import { toCurrency } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  bodyContainer: {
    padding: '13px 35px 13px 35px',
    borderRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    justifyContent: 'space-between',
    marginBottom: 80,
  },
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  activityIndicatorContainer: {
    margin: 20,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const FIELD_TYPE_NUMBER = 'number';
const FIELD_TYPE_DATE_TIME = 'dateTime';

const renderField = (downloading, fieldName, fieldType, label, placeholder, classes) => {
  let field;
  switch (fieldType) {
    case FIELD_TYPE_NUMBER:
      field = (
        <Field
          name={fieldName}
          component={renderReduxFormOutlinedTextField}
          placeholder={placeholder}
          label={placeholder}
          disabled={downloading}
          type="number"
        />
      ); break;
    case FIELD_TYPE_DATE_TIME:
      field = (
        <Field
          name={fieldName}
          component={renderReduxFormDateTimePickerField}
          label={placeholder}
          placeholder={placeholder}
          disabled={downloading}
        />
      ); break;
    default: field = (<div />); break;
  }

  return (
    <Grid item container key={label} className={classes.columnContainer}>
      <Grid item sm md>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item sm md>
        {field}
      </Grid>
    </Grid>
  );
};

const LeaderBoardPage = ({
  initialValues,
  downloading,
  handleSubmit, onAppear, onApplyPressed, onCancelPressed, onResetPressed,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>{LocalizedString.leaderBoardPage.title}</Typography>
      </div>

      <Paper elevation={3} className={classes.bodyContainer}>
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item sm md>
            {renderField(downloading, RXFIELD_COUPON_LEADER_BOARD_START_RANK, FIELD_TYPE_NUMBER,
              LocalizedString.leaderBoardPage.labelStartRank,
              LocalizedString.leaderBoardPage.placeholderStartRank, classes)}

            {renderField(downloading, RXFIELD_COUPON_LEADER_BOARD_START_PERIOD,
              FIELD_TYPE_DATE_TIME,
              LocalizedString.leaderBoardPage.labelStartPeriod,
              LocalizedString.leaderBoardPage.placeholderStartPeriod, classes)}
          </Grid>

          <Grid item sm md>
            {renderField(downloading, RXFIELD_COUPON_LEADER_BOARD_COUNT, FIELD_TYPE_NUMBER,
              LocalizedString.leaderBoardPage.labelCount,
              LocalizedString.leaderBoardPage.placeholderCount, classes)}

            {renderField(downloading, RXFIELD_COUPON_LEADER_BOARD_END_PERIOD, FIELD_TYPE_DATE_TIME,
              LocalizedString.leaderBoardPage.labelEndPeriod,
              LocalizedString.leaderBoardPage.placeholderEndPeriod, classes)}
          </Grid>

          <Grid item container alignItems="center" justify="flex-end" spacing={3}>
            <Grid item>
              <AccentButton
                onClick={onCancelPressed}
                variant="text"
                caption={GlobalLocalizedString.common.buttonCaptionCancel}
                disabled={downloading}
              />
            </Grid>
            <Grid item>
              <AccentButton
                onClick={onResetPressed}
                variant="outlined"
                caption={GlobalLocalizedString.common.buttonCaptionReset}
                disabled={downloading}
              />
            </Grid>
            <Grid item>
              <AccentButton
                onClick={handleSubmit(onApplyPressed)}
                caption={GlobalLocalizedString.common.buttonCaptionApply}
                disabled={downloading}
              />
            </Grid>
          </Grid>
        </Grid>

        {downloading ? (
          <div className={classes.activityIndicatorContainer}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Field
            name={RXFIELD_COUPON_LEADER_BOARD_RESULT}
            component={renderReduxFormEditableTableField}
            label=""
            disabled
            disableToolbar
            loading={downloading}
            currentPage={0}
            totalCount={initialValues.result.length}
            defaultValue={initialValues.result}
            tableColumns={[
              {
                title: LocalizedString.leaderBoardPage.labelNo, field: 'no', sorting: false, width: 40,
              },
              { title: LocalizedString.leaderBoardPage.labelUser, field: 'user.fullName', sorting: false },
              {
                title: LocalizedString.leaderBoardPage.labelCoupons,
                field: 'amount',
                sorting: false,
                render: ({ amount }) => amount && toCurrency(amount),
              },
              { title: LocalizedString.leaderBoardPage.labelRank, field: 'rank', sorting: false },
            ]}
          />
        )}
      </Paper>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_COUPON_LEADER_BOARD,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LeaderBoardPage);

LeaderBoardPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onResetPressed: PropTypes.func.isRequired,
};
