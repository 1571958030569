
import { INITIAL_ORDER_BY_GAME_ACTIVITY_REGISTRATION_REFERRALS } from '../../constant';
import {
  DOWNLOADING_DELETING_GAME_ACTIVITY_REGISTRATION_REFERRAL,
  DOWNLOADING_GAME_ACTIVITY_REGISTRATION_REFERRALS,
  SAVING_GAME_ACTIVITY_REGISTRATION_REFERRALS,
  SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SEARCH_TEXT,
  SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_ORDER_BY,
  SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE,
  SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_TAPPED_ID,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  saving: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_GAME_ACTIVITY_REGISTRATION_REFERRALS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_GAME_ACTIVITY_REGISTRATION_REFERRALS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_GAME_ACTIVITY_REGISTRATION_REFERRAL:
      return { ...state, downloadingDeleting: action.status };
    case SAVING_GAME_ACTIVITY_REGISTRATION_REFERRALS:
      return { ...state, saving: action.status };
    case SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_GAME_ACTIVITY_REGISTRATION_REFERRAL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
