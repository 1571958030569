import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch,
} from '../../helper';

import {
  INITIAL_ORDER_BY_IIMS_2023_TRANSACTION,
  MENUID_IIMS_2023_TRANSACTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import Iims2023TransactionPage from './iims-2023-transaction.presentation';
import {
  clearIims2023Transactions, setIims2023TransactionAdvancedFilterString,
  setIims2023TransactionSearchText, setIims2023TransactionSelectedOrderBy,
  setIims2023TransactionSelectedPageSize, setIims2023TransactionTappedId,
  downloadIims2023TransactionAsync,
  downloadIims2023TransactionsAsync,
  saveIims2023TransactionsAsync,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { iims2023Transactions, uiFunctionalPage, uiIims2023Transaction } = state;
  const { data } = iims2023Transactions;
  const { downloadingDeleting, tappedId } = uiIims2023Transaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiIims2023Transaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_IIMS_2023_TRANSACTION));
    dispatch(setIims2023TransactionAdvancedFilterString(''));
    dispatch(setIims2023TransactionSearchText(''));
    dispatch(clearIims2023Transactions());
    dispatch(setIims2023TransactionSelectedPageSize(20));
    dispatch(
      setIims2023TransactionSelectedOrderBy(INITIAL_ORDER_BY_IIMS_2023_TRANSACTION),
    );
    dispatch(downloadIims2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setIims2023TransactionSelectedOrderBy(orderBy));
    dispatch(clearIims2023Transactions());
    dispatch(downloadIims2023TransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setIims2023TransactionTappedId(id));
    dispatch(downloadIims2023TransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setIims2023TransactionSearchText(text));
    dispatch(clearIims2023Transactions());
    dispatch(downloadIims2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadIims2023TransactionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setIims2023TransactionSelectedPageSize(pageSize));
    dispatch(downloadIims2023TransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setIims2023TransactionSelectedPageSize(pageSize));
    dispatch(clearIims2023Transactions());
    dispatch(downloadIims2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearIims2023Transactions());
    dispatch(setIims2023TransactionAdvancedFilterString(''));
    dispatch(
      setIims2023TransactionSelectedOrderBy(INITIAL_ORDER_BY_IIMS_2023_TRANSACTION),
    );
    dispatch(downloadIims2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id');

    dispatch(setIims2023TransactionAdvancedFilterString(text));
    dispatch(clearIims2023Transactions());
    dispatch(downloadIims2023TransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveIims2023TransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Iims2023TransactionPage);
