import React from 'react';
import {
  Avatar, CircularProgress, Grid, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  RXFORM_CATALOG, PAGE_MODE_VIEW, REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  RXSTATE_CATALOG, RXSTATE_CATALOG_PAGE,
  RXFIELD_CATALOG_TRIM, RXFIELD_CATALOG_YEAR, RXFIELD_CATALOG_MODEL,
  RXFIELD_CATALOG_ISDEFAULT, RXFIELD_CATALOG_COLOR, RXFIELD_CATALOG_DESCRIPTION,
  RXFIELD_CATALOG_MEDIA, IMAGE_SOURCE_URI_PREFIX, approval, RXFIELD_CATALOG_COLOR_CODE,
  RXFIELD_CATALOG_REFERENCE_ID,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_NUMBER,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateCar } from '../../validation';
import {
  FunctionalPage, ImageInputField, NumberTextFieldWithoutArrowDial,
  SectionTitle,
} from '../../component';
import LocalizedString from '../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField, renderReduxFormRichTextEditorField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';

const renderDialogContent = (initialValues, models, addingEditing, downloadingDeleting,
  loadingModels, onAddMediaPressed, onAddMediaMenuSelected, onModelOptionSelected,
  onChangeModelText, onChangeContentText, onDeleteMediaPressed, onDefaultOptionSelected,
  pageMode, selectedAddMediaMenu) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs sm md>
          <Grid item>
            <Field
              name={RXFIELD_CATALOG_TRIM}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.catalogPage.placeholderTrim}
              label={LocalizedString.catalogPage.labelTrim}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CATALOG_YEAR}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.catalogPage.placeholderYear}
              label={LocalizedString.catalogPage.labelYear}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CATALOG_COLOR}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.catalogPage.placeholderColor}
              label={LocalizedString.catalogPage.labelColor}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CATALOG_COLOR_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.catalogPage.placeholderColorCode}
              label={LocalizedString.catalogPage.labelColorCode}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        </Grid>

        <Grid item xs sm md>
          <Grid item>
            <Field
              name={RXFIELD_CATALOG_MODEL}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.catalogPage.placeholderModel}
              label={LocalizedString.catalogPage.labelModel}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={models}
              value={initialValues.model.label}
              loading={loadingModels}
              onChangeText={onChangeModelText}
              onOptionSelected={onModelOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CATALOG_ISDEFAULT}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.catalogPage.placeholderIsDefault}
              label={LocalizedString.catalogPage.placeholderIsDefault}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.isDefault}
              onOptionSelected={onDefaultOptionSelected}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CATALOG_REFERENCE_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.catalogPage.placeholderReferenceId}
              label={LocalizedString.catalogPage.placeholderReferenceId}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Field
          name={RXFIELD_CATALOG_DESCRIPTION}
          component={renderReduxFormRichTextEditorField}
          label={LocalizedString.catalogPage.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          onChangeText={onChangeContentText}
          loading={downloadingDeleting}
          required
        />
      </Grid>


      <SectionTitle title={LocalizedString.catalogPage.labelMedia} />

      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_CATALOG_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.catalogPage.labelMedia}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onAddPressed={onAddMediaPressed}
            onDeletePressed={onDeleteMediaPressed}
            defaultValue={initialValues.media}
            addMenuList={[
              {
                caption: LocalizedString.catalogPage.buttonCaptionYoutube,
                onPress: () => onAddMediaMenuSelected('Youtube'),
              },
              {
                caption: LocalizedString.catalogPage.buttonCaptionImage,
                onPress: () => onAddMediaMenuSelected('Image'),
              },
            ]}
            requiredTableColumnField={['order']}
            optionalTableColumnField={['content', 'link']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.catalogPage.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.catalogPage.labelOrder}
                    label={LocalizedString.catalogPage.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.catalogPage.labelYoutube,
                field: 'link',
                sorting: false,
                editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                      if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Youtube');
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.catalogPage.labelYoutube}
                    label={LocalizedString.catalogPage.labelYoutube}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.catalogPage.labelImage,
                field: 'content',
                sorting: false,
                editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                      if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Image');
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ onChange }) => (
                  <ImageInputField
                    useCropper={false}
                    onImageSelected={(path) => onChange({ path, type: 'Image' })}
                  />
                ),
              },
            ]}
            required
          />
        </Grid>
      )}
    </Grid>
);

const CatalogPage = ({
  initialValues, models, loadingModels, addingEditing, downloading,
  downloadingDeleting, handleSubmit, onAppear, onAddMediaPressed, onAddMediaMenuSelected,
  onCancelPressed, onChangeContentText, onChangeModelText, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDefaultOptionSelected, onDeleteMediaPressed,
  onDeletePressed, onRefresh, onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed, pageMode, onEditPressed, onModelOptionSelected, selectedAddMediaMenu,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed, onAdvancedFilterPressed,
}) => (
  <div>
    <FunctionalPage
      data={RXSTATE_CATALOG}
      uiPage={RXSTATE_CATALOG_PAGE}
      tableColumns={[
        {
          title: LocalizedString.catalogPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.catalogPage.labelTrim, field: 'trim', sorting: !downloading },
        { title: LocalizedString.catalogPage.labelModel, field: 'model.name', sorting: !downloading },
        { title: LocalizedString.catalogPage.labelColor, field: 'color', sorting: !downloading },
        { title: LocalizedString.catalogPage.labelYear, field: 'year', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.catalogPage.labelModel,
          field: RXFIELD_CATALOG_MODEL,
          type: FILTER_TYPE_DROPDOWN,
          data: models,
          loading: loadingModels,
          onChangeFilterText: onChangeModelText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.catalogPage.labelYear,
          field: RXFIELD_CATALOG_YEAR,
          type: FILTER_TYPE_NUMBER,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmDeletePressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      createNewButtonCaption={LocalizedString.catalogPage.buttonCaptionCreateNewCar}
      deleteButtonCaption={LocalizedString.catalogPage.buttonCaptionDeleteCar}
      editButtonCaption={LocalizedString.catalogPage.buttonCaptionEditCar}
      title={LocalizedString.catalogPage.title}
      useFullWidth
      useSendToMeAndUserNotification
    >
      {renderDialogContent(initialValues, models, addingEditing, downloadingDeleting,
        loadingModels, onAddMediaPressed, onAddMediaMenuSelected, onModelOptionSelected,
        onChangeModelText, onChangeContentText, onDeleteMediaPressed, onDefaultOptionSelected,
        pageMode, selectedAddMediaMenu)}
    </FunctionalPage>
  </div>
);


export default reduxForm({
  form: RXFORM_CATALOG,
  validate: rxformValidateCar,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CatalogPage);

CatalogPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  models: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingModels: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeContentText: PropTypes.func.isRequired,
  onChangeModelText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDefaultOptionSelected: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onModelOptionSelected: PropTypes.func.isRequired,
  selectedAddMediaMenu: PropTypes.string,
};

CatalogPage.defaultProps = {
  selectedAddMediaMenu: null,
};
