import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_PROMO_CODES, MENUID_PROMO_CODE, PAGE_MODE_TABLE,
  RXFIELD_PROMO_CODE_VOUCHER, RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_MERCHANT,
  RXFIELD_PROMO_CODE_REWARD, RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE,
  RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_PROMO_CODE_COUPONS_AMOUNT, RXFIELD_PROMO_CODE_COINS_AMOUNT,
  DATE_TIME_FORMAT_WITHOUT_PIPE, REWARD_TYPE_COIN,
  REWARD_TYPE_COUPON,
  RXFIELD_PROMO_CODE_USER_TYPE,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData, toMoment, localDateToUtc,
  debounceSearch, transformVouchers, getUserType, transformVoucherDropdownData,
} from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage, setAlertConfirmationMessage,
  setAdvancedFilterDialogSelectedFilterString,
  downloadPromoCodesAsync, addEditPromoCodeAsync, downloadDeletePromoCodeAsync,
  setPromoCodeSearchText, setPromoCodeSelectedOrderBy, setPromoCodeSelectedPageSize,
  setPromoCodeTappedId, setPromoCodeSelectedMerchant, setPromoCodeSelectedVouchers,
  setPromoCodeSelectedReward, setPromoCodeSelectedVoucherBypassMaxPurchase,
  setPromoCodeSelectedVoucherBypassAchievement,
  clearPromoCodes,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData, clearCompaniesMData,
  addingPromoCodeVouchers, addPromoCode, setPromoCodeTableDataVouchers, savePromoCodesAsync,
  setPromoCodeUserTypes,
  setPromoCodeSelectedAirport,
} from '../../redux/action';
import {
  downloadMDataCompaniesAsync, downloadMyConfigItemsAsync,
} from '../../../../redux/action';
import PromoCodePage from './promo-code.presentation';
import GlobalLocalizedString from '../../../../localization';
import {
  downloadVouchersAsync, setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText, clearVouchers, clearGarudaAirports, downloadGarudaAirportsAsync,
  setGarudaAirportSearchText,
} from '../../../voucher';
import { getGarudaCompanyNameConfigValue, isSelectedVoucherGarudaLounge } from '../../../voucher/helper';
import LocalizedString from '../../localization';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiPromoCode, promoCodes } = state;
  const { data } = promoCodes;
  const {
    downloadingDeleting, tappedId, tableDataVouchers, addingVouchers,
  } = uiPromoCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting && !addingVouchers
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    merchant: found.merchantId
      ? { label: found.merchantName, value: found.merchantId }
      : { label: '', value: '' },
    vouchers: transformVouchers(tableDataVouchers),
    rewardType: found.rewardType || '',
    couponAmount: found.couponAmount ? `${found.couponAmount}` : '',
    pointAmount: found.pointAmount ? `${found.pointAmount}` : '',
    validFrom: found.validFrom
      ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    validUntil: found.validUntil
      ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    userType: getUserType(found),
    minSignUpTime: found.minSignUpTime
      ? toMoment(found.minSignUpTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    maxSignUpTime: found.maxSignUpTime
      ? toMoment(found.maxSignUpTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
  }) : {
    vouchers: tableDataVouchers.length > 0 ? tableDataVouchers : [],
    merchant: { label: '', value: '' },
    bypassMaxPurchaseValidation: { label: '', value: '' },
    bypassAchievementValidation: { label: '', value: '' },
    code: '',
    rewardType: '',
    couponAmount: '',
    merchantTag: '',
    pointAmount: '',
    maxRedemptionCount: '',
    notificationMessageTitle: '',
    notificationMessageBody: '',
    validFrom: null,
    validUntil: null,
    userType: [],
  };
  return result;
};

const mapStateToProps = (state) => {
  const { selectedVouchers: selectedVoucher } = state.uiPromoCode;

  return ({
    downloading: state.uiPromoCode.downloading,
    downloadingDeleting: state.uiPromoCode.downloadingDeleting,
    addingEditing: state.uiPromoCode.addingEditing,
    addingVouchers: state.uiPromoCode.addingVouchers,
    selectedVoucher,
    tableDataVouchers: state.uiPromoCode.tableDataVouchers,
    selectedUserTypes: state.uiPromoCode.selectedUserTypes,
    tappedPromoCode: state.promoCodes.data[state.uiPromoCode.tappedId],
    airports: transformDropdownData(state.garudaAirports.data),
    loadingAirport: state.uiGarudaAirport.downloading,
    isSelectedVoucherGarudaLounge: isSelectedVoucherGarudaLounge(selectedVoucher, state),
    selectedAirport: state.uiPromoCode.selectedAirport,
    tappedId: state.uiFunctionalPage.tappedId || '',
    pageMode: state.uiFunctionalPage.pageMode,
    initialValues: getInitialValues(state),
    vouchers: transformVoucherDropdownData(state.vouchers.data) || [],
    loadingVouchers: state.uiVoucher.downloading,
    merchants: transformDropdownData(state.companiesMdata.data),
    loadingMerchants: state.uiCompany.downloading,
    selectedRewardType: state.uiPromoCode.selectedReward?.value || '',
    garudaCompanyNameConfigValue: getGarudaCompanyNameConfigValue(state),
  });
};

const searchVoucherDebounce = debounceSearch(
  async (dispatch) => {
    try {
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
);

const searchAirportsDebounce = debounceSearch((dispatch) => {
  dispatch(clearGarudaAirports());
  dispatch(downloadGarudaAirportsAsync(1))
    .catch((error) => dispatch(setAlertErrorMessage(error)));
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(downloadMyConfigItemsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setActiveSideMenuItem(MENUID_PROMO_CODE));
    dispatch(setPromoCodeSearchText(''));
    dispatch(clearPromoCodes());
    dispatch(setPromoCodeSelectedPageSize(20));
    dispatch(setPromoCodeSelectedOrderBy(INITIAL_ORDER_BY_PROMO_CODES));
    dispatch(downloadPromoCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPromoCodesAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPromoCodeSelectedPageSize(pageSize));
    dispatch(downloadPromoCodesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setPromoCodeSelectedPageSize(pageSize));
    dispatch(clearPromoCodes());
    dispatch(downloadPromoCodesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setPromoCodeSearchText(text));
    dispatch(clearPromoCodes());
    dispatch(downloadPromoCodesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setPromoCodeSelectedOrderBy(orderBy));
    dispatch(clearPromoCodes());
    dispatch(downloadPromoCodesAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setPromoCodeTappedId(id));
    dispatch(downloadDeletePromoCodeAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/voucher/, 'voucherId')
      .replace(/merchant/, 'merchantId');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPromoCodes());
    dispatch(downloadPromoCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPromoCodes());
    dispatch(setPromoCodeSelectedOrderBy(INITIAL_ORDER_BY_PROMO_CODES));
    dispatch(downloadPromoCodesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVoucherOptionSelected: (option, prevSelectedVoucher, garudaCompanyNameConfigValue,
    isPrevSelectedVoucherGarudaLounge) => {
    const isCurrSelectedVoucherGarudaLounge = option
      ? isSelectedVoucherGarudaLounge(option, null, garudaCompanyNameConfigValue) : false;

    if (option) {
      dispatch(setPromoCodeSelectedVouchers(option));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER, option));

      if (isCurrSelectedVoucherGarudaLounge) {
        dispatch(setGarudaAirportSearchText(''));
        dispatch(clearGarudaAirports());
        dispatch(downloadGarudaAirportsAsync(1))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
    } else {
      dispatch(setPromoCodeSelectedVouchers(null));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER, null));
    }

    if ((isPrevSelectedVoucherGarudaLounge && !option)
    || (isPrevSelectedVoucherGarudaLounge && option.value !== prevSelectedVoucher.value)
    || (!isPrevSelectedVoucherGarudaLounge && isCurrSelectedVoucherGarudaLounge)) {
      dispatch(setPromoCodeSelectedAirport(''));
    }
  },
  onChangeVoucherText: async (text) => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchVoucherDebounce(dispatch, true);
    }
  },
  onMerchantOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeSelectedMerchant(option));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_MERCHANT, option));
    } else {
      dispatch(setPromoCodeSelectedMerchant(''));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_MERCHANT, ''));
    }
  },
  onChangeMerchantText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRewardOptionSelected: async (option) => {
    if (option) {
      const rewardTypeCoin = option.value === REWARD_TYPE_COIN;
      const rewardTypeCoupon = option.value === REWARD_TYPE_COUPON;
      dispatch(setPromoCodeSelectedReward(option));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_REWARD, option));
      if (rewardTypeCoin || rewardTypeCoupon) {
        dispatch(setPromoCodeTableDataVouchers([]));
        dispatch(setPromoCodeSelectedVouchers(null));
        dispatch(setPromoCodeSelectedVoucherBypassMaxPurchase(null));
        dispatch(setPromoCodeSelectedVoucherBypassAchievement(null));
        dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER, null));
        dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE, null));
        dispatch(change(
          RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION, null,
        ));

        try {
          dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
          dispatch(setVoucherSearchText(''));
          dispatch(clearVouchers());
          await dispatch(downloadVouchersAsync(1));
        } catch (error) {
          dispatch(setAlertErrorMessage(error));
        }

        if (rewardTypeCoin) {
          dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_COUPONS_AMOUNT, '0'));
        }
        if (rewardTypeCoupon) {
          dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_COINS_AMOUNT, '0'));
        }
      } else {
        dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_COUPONS_AMOUNT, '0'));
        dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_COINS_AMOUNT, '0'));
      }
    } else {
      dispatch(setPromoCodeSelectedReward(''));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_REWARD, ''));
    }
  },
  onBypassMaxPurchaseOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeSelectedVoucherBypassMaxPurchase(option));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE, option));
    } else {
      dispatch(setPromoCodeSelectedVoucherBypassMaxPurchase(''));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE, ''));
    }
  },
  onBypassAchievementValidationOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeSelectedVoucherBypassAchievement(option));
      dispatch(change(
        RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION, option,
      ));
    } else {
      dispatch(setPromoCodeSelectedVoucherBypassAchievement(''));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION, ''));
    }
  },
  onSavePressed: async ({
    code,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    maxRedemptionCount,
    pointAmount,
    couponAmount,
    validFrom,
    validUntil,
    merchantTag,
    notificationMessageTitle,
    notificationMessageBody,
    userType,
    minSignUpTime,
    maxSignUpTime,
  }) => {
    try {
      await dispatch(addEditPromoCodeAsync(
        code,
        bypassMaxPurchaseValidation,
        bypassAchievementValidation,
        maxRedemptionCount,
        pointAmount,
        couponAmount,
        localDateToUtc(validFrom),
        localDateToUtc(validUntil),
        merchantTag,
        notificationMessageTitle,
        notificationMessageBody,
        userType,
        localDateToUtc(minSignUpTime),
        localDateToUtc(maxSignUpTime),
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSubmitPressed: async ({
    code,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    maxRedemptionCount,
    pointAmount,
    couponAmount,
    validFrom,
    validUntil,
    merchantTag,
    notificationMessageTitle,
    notificationMessageBody,
    userType,
    minSignUpTime,
    maxSignUpTime,
  }) => {
    await dispatch(addEditPromoCodeAsync(
      code,
      bypassMaxPurchaseValidation,
      bypassAchievementValidation,
      maxRedemptionCount,
      pointAmount,
      couponAmount,
      localDateToUtc(validFrom),
      localDateToUtc(validUntil),
      merchantTag,
      notificationMessageTitle,
      notificationMessageBody,
      userType,
      localDateToUtc(minSignUpTime),
      localDateToUtc(maxSignUpTime),
    ));
  },
  onEditPressed: (id) => {
    dispatch(setPromoCodeTappedId(id));
    dispatch(downloadDeletePromoCodeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setPromoCodeSelectedMerchant(null));
    dispatch(setPromoCodeSelectedVouchers(null));
    dispatch(setPromoCodeSelectedReward(null));
    dispatch(setPromoCodeSelectedVoucherBypassMaxPurchase(null));
    dispatch(setPromoCodeSelectedVoucherBypassAchievement(null));
    dispatch(setPromoCodeUserTypes([]));

    dispatch(reset(RXFORM_PROMO_CODE));
  },
  onDeletePressed: (id) => {
    dispatch(setPromoCodeTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeletePromoCodeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setPromoCodeTappedId(''));
    dispatch(setPromoCodeSelectedMerchant(null));
    dispatch(setPromoCodeSelectedReward(null));
    dispatch(setPromoCodeSelectedVoucherBypassMaxPurchase(null));
    dispatch(setPromoCodeSelectedVoucherBypassAchievement(null));
    dispatch(setPromoCodeSelectedVouchers(null));
    dispatch(setPromoCodeTableDataVouchers([]));
    dispatch(setPromoCodeUserTypes([]));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddVouchersPressed: async (defaultValue, newInitialValue, selectedOption,
    currentData, selectedAirport, garudaCompanyNameConfigValue) => {
    const isCurrSelectedVoucherGarudaLounge = isSelectedVoucherGarudaLounge(selectedOption,
      null, garudaCompanyNameConfigValue);

    if (isCurrSelectedVoucherGarudaLounge && !selectedAirport) {
      dispatch(setAlertErrorMessage(LocalizedString.promoCodePage.errMsgPleaseFillAiport));
    } else if (selectedOption) {
      try {
        dispatch(addingPromoCodeVouchers(true));

        const newVoucher = isCurrSelectedVoucherGarudaLounge ? { ...selectedOption, label: `${selectedOption.label} (${selectedAirport.label})`, airport: selectedAirport } : selectedOption;
        if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
          const result = {
            ...currentData,
            vouchers: defaultValue.length > 0
              ? [...defaultValue, newVoucher] : [newVoucher],
          };
          await dispatch(addPromoCode(result));
          dispatch(setPromoCodeTableDataVouchers(result.vouchers));
          dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER,
            result.vouchers));
        } else {
          const newData = newInitialValue.length > 0
            ? [...newInitialValue, newVoucher]
            : [newVoucher];
          await dispatch(setPromoCodeTableDataVouchers(newData));
          dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER, newData));
        }
        dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
        dispatch(setVoucherSearchText(''));
        dispatch(clearVouchers());
        dispatch(downloadVouchersAsync(1))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      } finally {
        dispatch(setPromoCodeSelectedVouchers(null));
        dispatch(addingPromoCodeVouchers(false));
      }
    }
  },
  onDeleteVouchersPressed: (newValue, currentData) => {
    if (Object.prototype.hasOwnProperty.call(currentData, 'voucherId')) {
      const result = { ...currentData, vouchers: newValue };
      dispatch(addPromoCode(result));
      dispatch(setPromoCodeTableDataVouchers(result.vouchers));
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(''));
      dispatch(clearVouchers());
      dispatch(downloadVouchersAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setPromoCodeTableDataVouchers(newValue));
    }
    dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_VOUCHER, newValue));
  },
  onDownloadPressed: () => {
    dispatch(savePromoCodesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onUserTypeSelected: (option) => {
    if (option.length > 0) {
      dispatch(setPromoCodeUserTypes(option));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_USER_TYPE, option));
    } else {
      dispatch(setPromoCodeUserTypes([]));
      dispatch(change(RXFORM_PROMO_CODE, RXFIELD_PROMO_CODE_USER_TYPE, []));
    }
  },
  onChangeAirportText: async (text) => {
    dispatch(setGarudaAirportSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchAirportsDebounce(dispatch);
    }
  },
  onAirportOptionSelected: (option) => {
    if (option) {
      dispatch(setPromoCodeSelectedAirport(option));
    } else {
      dispatch(setPromoCodeSelectedAirport(''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodePage);
