export * from '../../constant';

export const ROUTE_NAME_DASHBOARD_SERVICE_PROMO = '/service-promo';

export const MENUID_DASHBOARD_SERVICE_PROMO = 'MENUID_DASHBOARD_SERVICE_PROMO';

export const REST_URL_BOOKING_CREATED = '/carservice/api/v1/Summary/ReservationCreated?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_PROCEED_BY_DEALER = '/carservice/api/v1/Summary/ReservationProcessedByDealer?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_WAITING_FOR_PAYMENT = '/carservice/api/v1/Summary/ReservationWaitingForPayment?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_COMPLETED = '/carservice/api/v1/Summary/ReservationCompleted?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_CANCELED = '/carservice/api/v1/Summary/ReservationCancelled?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_CREATED_BY_REPAIR_SELECTION = '/carservice/api/v1/Summary/ReservationRepairType?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_CREATED_BY_DELIVERY_METHOD = '/carservice/api/v1/Summary/ReservationDeliveryMethod?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_CREATED_BY_SERVICE_INTERVAL = '/carservice/api/v1/Summary/ReservationIntervalSelection?startDate={startDate}&endDate={endDate}';
export const REST_URL_BOOKING_CREATED_BY_VEHICLE_MODEL = '/carservice/api/v1/Summary/VehicleModelReservation?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_RECEIVED = '/carservice/api/v1/Summary/TransactionReceived?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_PENDING = '/carservice/api/v1/Summary/TransactionPending?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_PAID = '/carservice/api/v1/Summary/TransactionPaid?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_CANCELED = '/carservice/api/v1/Summary/TransactionCancelled?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_AMOUNT = '/carservice/api/v1/Summary/TransactionAmount?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_COUNT_BY_PART_NAME = '/carservice/api/v1/Summary/VehicleModelTransactionCount?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_AMOUNT_BY_PART_NAME = '/carservice/api/v1/Summary/VehicleModelTransactionAmount?startDate={startDate}&endDate={endDate}';
export const REST_URL_REMAINING_STOCK_BY_PART_NAME = '/carservice/api/v1/Summary/VehicleModelRemainingStock?startDate={startDate}&endDate={endDate}';
export const REST_URL_TRANSACTION_CREATED_BY_VEHICLE_MODEL = '/carservice/api/v1/Summary/VehicleModelTransaction?startDate={startDate}&endDate={endDate}';

export const RXFORM_DASHBOARD_SERVICE_PROMO = 'dashboardServicePromoPage';

export const RXFIELD_DASHBOARD_SERVICE_PROMO_START_DATE = 'startDate';
export const RXFIELD_DASHBOARD_SERVICE_PROMO_END_DATE = 'endDate';
