import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE, INITIAL_ORDER_BY_AUDIT_TRAILS,
  MENUID_AUDIT_MANAGEMENT_AUDIT_TRAIL, PAGE_MODE_TABLE, RXFIELD_AUDIT_IS_MODIFIED,
  RXFORM_AUDIT_TRAIL,
} from '../../constant';
import {
  sortAsc, transformDropdownData, transformInitialValues, toMoment,
  transformUserDropdownData,
} from '../../../../helper';
import {
  clearAuditTrails, clearServices, clearUsers, copyingColumnValue, filteringAuditTrailColumns,
  setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setAuditTrailSearchText, setAuditTrailSelectedOrderBy, setAuditTrailSelectedPageSize,
  setAuditTrailSelectedModified, setAuditTrailTappedId, setServiceSearchText,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadAuditTrailAsync, downloadAuditTrailsAsync,
} from '../../redux/action';
import { downloadServicesAsync, downloadUsersAsync } from '../../../../redux/action';
import AuditTrailPage from './audit-trail.presentation';

const getInitialValues = (state) => {
  const {
    auditTrails, uiFunctionalPage, uiAuditTrail,
  } = state;
  const { data } = auditTrails;
  const {
    downloading, downloadingDeleting, tappedId, isModified,
  } = uiAuditTrail;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      columns: found.columns.length > 0 && !downloading
        ? found.columns.sort((a, b) => sortAsc(a.columnName, b.columnName))
          .filter((x) => (isModified ? x.isModified : x))
          .map((x, i) => ({
            ...x,
            no: i + 1,
          })) : [],
      eventTime: found.eventTime
      && toMoment(found.eventTime).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE),
      isModified,
    })
    : {
      eventType: '',
      serviceName: '',
      entityName: '',
      entityId: '',
      eventTime: '',
      groupId: '',
      columns: [],
      isModified: false,
    };
  return result;
};

const mapStateToProps = (state) => ({
  serviceNames: transformDropdownData(state.services.data),
  users: transformUserDropdownData(state.users.data),
  isModified: state.uiAuditTrail.isModified,
  dialogVisibility: state.uiFunctionalPage.visibility,
  downloading: state.uiAuditTrail.downloading,
  downloadingDeleting: state.uiAuditTrail.downloadingDeleting,
  initialValues: getInitialValues(state),
  loadingServiceName: state.uiService.downloading,
  loadingUser: state.uiUser.downloading,
  filtering: state.uiAuditTrail.filtering,
  copying: state.uiAuditTrail.copying,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearServices());
    dispatch(clearUsers());
    dispatch(downloadServicesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AUDIT_MANAGEMENT_AUDIT_TRAIL));
    dispatch(setAuditTrailSearchText(''));
    dispatch(clearAuditTrails());
    dispatch(setAuditTrailSelectedPageSize(20));
    dispatch(setAuditTrailSelectedOrderBy(INITIAL_ORDER_BY_AUDIT_TRAILS));
    dispatch(downloadAuditTrailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearAuditTrails());
    dispatch(downloadAuditTrailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_AUDIT_TRAIL));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAuditTrailsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAuditTrailSelectedPageSize(pageSize));
    dispatch(downloadAuditTrailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeServiceNameText: async (text) => {
    try {
      dispatch(setServiceSearchText(text));
      dispatch(clearServices());
      await dispatch(downloadServicesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCopyPressed: (status) => {
    dispatch(copyingColumnValue(!status));
  },
  onFinishModifiying: () => {
    dispatch(filteringAuditTrailColumns(false));
  },
  onIsModifiedOptionSelected: async (option) => {
    dispatch(setAuditTrailSelectedModified(option));
    dispatch(change(RXFORM_AUDIT_TRAIL, RXFIELD_AUDIT_IS_MODIFIED, option));
    dispatch(filteringAuditTrailColumns(true));
  },
  onRefresh: (pageSize) => {
    dispatch(setAuditTrailSelectedPageSize(pageSize));
    dispatch(clearAuditTrails());
    dispatch(downloadAuditTrailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearAuditTrails());
    dispatch(setAuditTrailSelectedOrderBy(INITIAL_ORDER_BY_AUDIT_TRAILS));
    dispatch(downloadAuditTrailsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAuditTrailSearchText(text));
      dispatch(clearAuditTrails());
      await dispatch(downloadAuditTrailsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAuditTrailSelectedOrderBy(orderBy));
    dispatch(clearAuditTrails());
    dispatch(downloadAuditTrailsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setAuditTrailTappedId(id));
    dispatch(setAuditTrailSelectedModified(false));
    dispatch(downloadAuditTrailAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrailPage);
