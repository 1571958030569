import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_COUPONS = '/coupon/api/v1/Point?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_COUPON = '/coupon/api/v1/Point/{id}';
export const REST_URL_COUPON_SUBMISSIONS = '/coupon/api/v1/PointSubmission?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_COUPON_SUBMISSION = '/coupon/api/v1/PointSubmission/{id}';
export const REST_URL_ADD_COUPON_SUBMISSION = '/coupon/api/v1/PointSubmission';
export const REST_URL_COUPON_PURCHASE_REQUESTS = '/coupon/api/v1/PurchaseRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_COUPON_PURCHASE_REQUEST = '/coupon/api/v1/PurchaseRequest/{id}';
export const REST_URL_COUPON_TRANSACTIONS = '/coupon/api/v1/Transaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_COUPON_TRANSACTION = '/coupon/api/v1/Transaction/{id}';
export const REST_URL_COUPON_ACHIEVEMENT = '/coupon/api/v1/Achievement/{id}';
export const REST_URL_COUPON_BALANCE = '/coupon/api/v1/Balance/{id}';
export const REST_URL_VOID_COUPON_REQUESTS = '/coupon/api/v1/VoidPointRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_VOID_COUPON_REQUEST = '/coupon/api/v1/VoidPointRequest/{id}';
export const REST_URL_ADD_VOID_COUPON_REQUEST = '/coupon/api/v1/VoidPointRequest';
export const REST_URL_COUPON_LEADER_BOARD = '/coupon/api/v1/LeaderBoard?startRank={startRank}&count={count}';
export const REST_URL_REPROCESS_COUPON_SUBMISSION = '/coupon/api/v1/ReprocessPointSubmission/{id}';

export const ROUTE_NAME_COUPON = '/coupon';
export const ROUTE_NAME_COUPON_COUPON = '/coupons';
export const ROUTE_NAME_COUPON_COUPON_SUBMISSION = '/coupon-submission';
export const ROUTE_NAME_COUPON_COUPON_TRANSACTION = '/coupon-transaction';
export const ROUTE_NAME_COUPON_ACHIEVEMENT = '/coupon-achievement';
export const ROUTE_NAME_COUPON_LEADER_BOARD = '/coupon-leader-board';
export const ROUTE_NAME_COUPON_PURCHASE_REQUEST = '/coupon-purchase-request';
export const ROUTE_NAME_COUPON_VOID_REQUEST = '/coupon-void-request';

export const RXFORM_COUPON = 'couponPage';
export const RXFORM_COUPON_SUBMISSION = 'couponSubmissionPage';
export const RXFORM_COUPON_TRANSACTION = 'couponTransactionPage';
export const RXFORM_COUPON_ACHIEVEMENT = 'couponAchievementPage';
export const RXFORM_COUPON_LEADER_BOARD = 'couponLeaderBoardPage';
export const RXFORM_COUPON_PURCHASE_REQUEST = 'couponPurchaseRequestPage';
export const RXFORM_COUPON_VOID_REQUEST = 'couponVoidRequestPage';

export const RXFIELD_COUPON_TAGS = 'tags';
export const RXFIELD_COUPON_AMOUNT = 'amount';
export const RXFIELD_COUPON_VALID_UNTIL = 'validUntil';
export const RXFIELD_COUPON_EXPIRED_COUPON_ALREADY_PROCESSED = 'expiredCouponAlreadyProcessed';
export const RXFIELD_COUPON_LABEL = 'label';
export const RXFIELD_COUPON_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_COUPON_MERCHANT_ID = 'merchantId';
export const RXFIELD_COUPON_MERCHANT_NAME = 'merchantName';
export const RXFIELD_COUPON_IS_VOIDED = 'isVoided';
export const RXFIELD_COUPON_VOIDED_ON = 'voidedOn';
export const RXFIELD_COUPON_VOID_REFERENCE_ID = 'voidReferenceId';
export const RXFIELD_COUPON_VOID_NOTES = 'voidNotes';
export const RXFIELD_COUPON_VOIDED_BY = 'voidedBy';
export const RXFIELD_COUPON_USER_ID = 'user.id';
export const RXFIELD_COUPON_USER_NAME = 'fullName';
export const RXFIELD_COUPON_SUBMISSION_TAGS = 'tags';
export const RXFIELD_COUPON_SUBMISSION_AMOUNT = 'amount';
export const RXFIELD_COUPON_SUBMISSION_VALID_UNTIL = 'validUntil';
export const RXFIELD_COUPON_SUBMISSION_LABEL = 'label';
export const RXFIELD_COUPON_SUBMISSION_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_COUPON_SUBMISSION_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_COUPON_SUBMISSION_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_COUPON_SUBMISSION_MERCHANT_ID = 'merchantId';
export const RXFIELD_COUPON_SUBMISSION_MERCHANT_NAME = 'merchantName';
export const RXFIELD_COUPON_SUBMISSION_CALLBACK_URL = 'callbackUrl';
export const RXFIELD_COUPON_SUBMISSION_USER_ID = 'user.id';
export const RXFIELD_COUPON_SUBMISSION_USER = 'fullName';
export const RXFIELD_COUPON_SUBMISSION_SEND_NOTIFICATION = 'sendNotification';
export const RXFIELD_COUPON_TRANSACTION_EFFECTIVE_DATE = 'effectiveDate';
export const RXFIELD_COUPON_TRANSACTION_TRANSACTION_NUMBER = 'transactionNumber';
export const RXFIELD_COUPON_TRANSACTION_TYPE = 'type';
export const RXFIELD_COUPON_TRANSACTION_REFERENCE_ID = 'referenceId';
export const RXFIELD_COUPON_TRANSACTION_TAGS = 'tags';
export const RXFIELD_COUPON_TRANSACTION_AMOUNT = 'amount';
export const RXFIELD_COUPON_TRANSACTION_VALID_UNTIL = 'validUntil';
export const RXFIELD_COUPON_TRANSACTION_LABEL = 'label';
export const RXFIELD_COUPON_TRANSACTION_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_COUPON_TRANSACTION_MERCHANT_ID = 'merchantId';
export const RXFIELD_COUPON_TRANSACTION_MERCHANT_NAME = 'merchantName';
export const RXFIELD_COUPON_TRANSACTION_USER = 'fullName';
export const RXFIELD_COUPON_TRANSACTION_USER_ID = 'user.id';
export const RXFIELD_COUPON_TRANSACTION_ITEMS = 'items';
export const RXFIELD_COUPON_ACHIEVEMENT_PROFILE_PICTURE = 'profilePicture';
export const RXFIELD_COUPON_ACHIEVEMENT_USERNAME = 'username';
export const RXFIELD_COUPON_ACHIEVEMENT_FULLNAME = 'fullName';
export const RXFIELD_COUPON_ACHIEVEMENT_EMAIL = 'email';
export const RXFIELD_COUPON_ACHIEVEMENT_PHONE = 'phone';
export const RXFIELD_COUPON_ACHIEVEMENT_TRANSACTION_HISTORY = 'transactionHistory';
export const RXFIELD_COUPON_ACHIEVEMENT_USER = 'user';
export const RXFIELD_COUPON_LEADER_BOARD_START_RANK = 'startRank';
export const RXFIELD_COUPON_LEADER_BOARD_COUNT = 'count';
export const RXFIELD_COUPON_LEADER_BOARD_START_PERIOD = 'startPeriod';
export const RXFIELD_COUPON_LEADER_BOARD_END_PERIOD = 'endPeriod';
export const RXFIELD_COUPON_LEADER_BOARD_RESULT = 'result';
export const RXFIELD_COUPON_PURCHASE_REQUEST_LABEL = 'label';
export const RXFIELD_COUPON_PURCHASE_REQUEST_AMOUNT = 'amount';
export const RXFIELD_COUPON_PURCHASE_REQUEST_MERCHANT_ID = 'merchantId';
export const RXFIELD_COUPON_PURCHASE_REQUEST_MERCHANT_NAME = 'merchantName';
export const RXFIELD_COUPON_PURCHASE_REQUEST_MERCHANT_REF_ID = 'merchantReferenceId';
export const RXFIELD_COUPON_PURCHASE_REQUEST_CALLBACK_URL = 'callbackUrl';
export const RXFIELD_COUPON_PURCHASE_REQUEST_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_COUPON_PURCHASE_REQUEST_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_COUPON_PURCHASE_REQUEST_USER_ID = 'id';
export const RXFIELD_COUPON_PURCHASE_REQUEST_FULL_NAME = 'fullName';
export const RXFIELD_COUPON_PURCHASE_REQUEST_TAGS = 'tags';
export const RXFIELD_COUPON_PURCHASE_REQUEST_PURCHASE_ITEMS = 'items';
export const RXFIELD_COUPON_VOID_REQUEST_REASON = 'reason';
export const RXFIELD_COUPON_VOID_REQUEST_MERCHANT_NAME = 'merchantName';
export const RXFIELD_COUPON_VOID_REQUEST_MERCHANT_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_COUPON_VOID_REQUEST_COUPON_NAME = 'point.label';
export const RXFIELD_COUPON_VOID_REQUEST_COUPON_AMOUNT = 'point.amount';
export const RXFIELD_COUPON_VOID_REQUEST_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_COUPON_VOID_REQUEST_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_COUPON_VOID_REQUEST_CALLBACK_URL = 'callbackUrl';
export const RXFIELD_COUPON_VOID_REQUEST_USER_ID = 'point.user.id';
export const RXFIELD_COUPON_VOID_REQUEST_USER_FULLNAME = 'point.user.fullName';
export const RXFIELD_COUPON_VOID_REQUEST_COUPON = 'coupon';
export const RXFIELD_COUPON_VOID_REQUEST_COUPON_ID = 'point.id';
export const RXFIELD_COUPON_VOID_REQUEST_USER = 'user';
export const RXFIELD_VOID_COUPON_DIALOG_REASON = 'reason';
export const RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID = 'merchantReferenceId';
export const RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION = 'sendNotification';

export const RXSTATE_COUPONS = 'coupons';
export const RXSTATE_COUPON_PAGE = 'uiCoupon';
export const RXSTATE_COUPON_SUBMISSIONS = 'couponSubmissions';
export const RXSTATE_COUPON_SUBMISSION_PAGE = 'uiCouponSubmission';
export const RXSTATE_COUPON_TRANSACTIONS = 'couponTransactions';
export const RXSTATE_COUPON_TRANSACTION_PAGE = 'uiCouponTransaction';
export const RXSTATE_COUPON_PURCHASE_REQUESTS = 'couponPurchaseRequests';
export const RXSTATE_COUPON_PURCHASE_REQUEST_PAGE = 'uiCouponPurchaseRequest';
export const RXSTATE_COUPON_VOID_REQUESTS = 'couponVoidRequests';
export const RXSTATE_COUPON_VOID_REQUEST_PAGE = 'uiCouponVoidRequest';

export const INITIAL_ORDER_BY_COUPONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_COUPON_SUBMISSIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_COUPON_TRANSACTIONS = `effectiveDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_COUPON_PURCHASE_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_COUPON_VOID_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_COUPON = 'MENUID_COUPON';
export const MENUID_COUPON_COUPON = 'MENUID_COUPON_COUPON';
export const MENUID_COUPON_COUPON_SUBMISSION = 'MENUID_COUPON_COUPON_SUBMISSION';
export const MENUID_COUPON_COUPON_TRANSACTION = 'MENUID_COUPON_COUPON_TRANSACTION';
export const MENUID_COUPON_ACHIEVEMENT = 'MENUID_COUPON_ACHIEVEMENT';
export const MENUID_COUPON_LEADER_BOARD = 'MENUID_COUPON_LEADER_BOARD';
export const MENUID_COUPON_PURCHASE_REQUEST = 'MENUID_COUPON_PURCHASE_REQUEST';
export const MENUID_COUPON_VOID_REQUEST = 'MENUID_COUPON_VOID_REQUEST';

export const SUBMISSION_STATUS_VALUES = [
  { label: 'Success', value: 'Success' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Error', value: 'Error' },
];

export const TRANSACTION_TYPE_VALUES = [
  { label: 'Top Up', value: 'TopUp' },
  { label: 'Purchase', value: 'Purchase' },
  { label: 'Void', value: 'Void' },
  { label: 'Expired Coupons', value: 'ExpiredCoupons' },
];

export const COUPON_SUBMISSION_REQUEST_STATUS_ERROR = 'Error';
