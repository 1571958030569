import { downloadCouponBalance } from '../../../helper';
import { downloadingCouponBalance, setCouponBalance } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCouponBalance(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCouponAchievement;

    const result = await downloadCouponBalance(tappedId, token);

    dispatch(setCouponBalance(result));
  } finally {
    dispatch(downloadingCouponBalance(false));
  }
};
