import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  RXFORM_TEST_DRIVE_UNIQUE_CODE, RXSTATE_UNIQUE_CODES, RXSTATE_UNIQUE_CODES_PAGE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_UNIQUE_CODE_CODE,
  RXFIELD_UNIQUE_CODE_USER_ID, RXFIELD_UNIQUE_CODE_USER_FULL_NAME,
  RXFIELD_UNIQUE_CODE_CREATED_DATE,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../../../type';

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_UNIQUE_CODE_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.uniqueCodePage.labelUserId}
          label={LocalizedString.uniqueCodePage.labelUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_UNIQUE_CODE_USER_FULL_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.uniqueCodePage.labelFullName}
          label={LocalizedString.uniqueCodePage.labelFullName}
          disabled
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_UNIQUE_CODE_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.uniqueCodePage.labelCode}
          label={LocalizedString.uniqueCodePage.labelCode}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const UniqueCodePage = ({
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  users, loadingUsers, onChangeUserText, onDownloadPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_UNIQUE_CODES}
    uiPage={RXSTATE_UNIQUE_CODES_PAGE}
    tableColumns={[
      {
        title: LocalizedString.uniqueCodePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.uniqueCodePage.labelFullName, field: 'profile.fullName', sorting: !downloading },
      { title: LocalizedString.uniqueCodePage.labelCode, field: 'code', sorting: !downloading },
      {
        title: LocalizedString.uniqueCodePage.labelCreatedDate,
        field: 'createdDate',
        render: ({ createdDate }) => toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
        sorting: !downloading,
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.uniqueCodePage.labelCreatedDate,
        field: RXFIELD_UNIQUE_CODE_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
      },
      {
        title: LocalizedString.uniqueCodePage.labelFullName,
        field: RXFIELD_UNIQUE_CODE_USER_FULL_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onDownloadPressed={onDownloadPressed}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.uniqueCodePage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    enableSave
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TEST_DRIVE_UNIQUE_CODE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(UniqueCodePage);

UniqueCodePage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
};
