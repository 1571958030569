import { downloadBookingCompleted } from '../../../helper';
import { downloadingBookingCompleted, setBookingCompleted } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingBookingCompleted(true));
    const bookingCreated = await downloadBookingCompleted(token, startDate, endDate);

    dispatch(setBookingCompleted(bookingCreated));
  } finally {
    dispatch(downloadingBookingCompleted(false));
  }
};
