import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_CATALOG = '/carcatalog/api/v1/Car?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_CAR = '/carcatalog/api/v1/Car';
export const REST_URL_VIEW_DELETE_CAR = '/carcatalog/api/v1/Car/{id}';
export const REST_URL_MODELS = '/carcatalog/api/v1/CarModel?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_MODEL = '/carcatalog/api/v1/CarModel';
export const REST_URL_VIEW_DELETE_MODEL = '/carcatalog/api/v1/CarModel/{id}';

export const ROUTE_NAME_CAR_CATALOG = '/car-catalog';
export const ROUTE_NAME_CATALOG = '/catalog';
export const ROUTE_NAME_MODEL = '/model';

export const RXFORM_CATALOG = 'catalogPage';
export const RXFORM_MODEL = 'modelPage';

export const RXFIELD_CATALOG_TRIM = 'trim';
export const RXFIELD_CATALOG_COLOR = 'color';
export const RXFIELD_CATALOG_COLOR_CODE = 'colorCode';
export const RXFIELD_CATALOG_YEAR = 'year';
export const RXFIELD_CATALOG_ISDEFAULT = 'isDefault';
export const RXFIELD_CATALOG_DESCRIPTION = 'description';
export const RXFIELD_CATALOG_MEDIA = 'media';
export const RXFIELD_CATALOG_MODEL = 'model';
export const RXFIELD_CATALOG_REFERENCE_ID = 'referenceId';
export const RXFIELD_MODEL_NAME = 'name';
export const RXFIELD_MODEL_DESCRIPTION = 'description';
export const RXFIELD_MODEL_BROCHURE_URL = 'brochureUrl';
export const RXFIELD_MODEL_IMAGE = 'image';
export const RXFIELD_MODEL_DISCONTINUED = 'discontinued';

export const RXSTATE_CATALOG = 'catalog';
export const RXSTATE_CATALOG_PAGE = 'uiCatalog';
export const RXSTATE_MODELS = 'models';
export const RXSTATE_MODEL_PAGE = 'uiModel';

export const INITIAL_ORDER_BY_CATALOG = `trim ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_MODELS = `name ${ORDER_SORT_ASCENDING}`;

export const MENUID_CAR_CATALOG = 'MENUID_CAR_CATALOG';
export const MENUID_CAR_CATALOG_CATALOG = 'MENUID_CAR_CATALOG_CATALOG';
export const MENUID_CAR_CATALOG_MODEL = 'MENUID_CAR_CATALOG_MODEL';
