import {
  addingRegistrationNotes, setAlertErrorMessage,
} from '../simple-action';
import { addRegistrationNotes } from '../../../helper';
import downloadRegistrationAsync from './downloadRegistrationAsync';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(addingRegistrationNotes(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistration;


    await addRegistrationNotes(tappedId, notes, token);

    dispatch(downloadRegistrationAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingRegistrationNotes(false));
  }
};
