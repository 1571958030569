import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import { FunctionalPage, SectionTitle } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE,
  RXFIELD_SERVICE_REQUEST_DEALER_ID, RXFIELD_SERVICE_REQUEST_DEALER_NAME,
  RXFIELD_SERVICE_REQUEST_REPAIR_TYPE, RXFIELD_SERVICE_REQUEST_REQUESTED_DATE,
  RXFIELD_SERVICE_REQUEST_USER_ID, RXFIELD_SERVICE_REQUEST_USER_NAME, RXFORM_SERVICE_REQUEST,
  RXSTATE_SERVICE_REQUESTS, RXSTATE_SERVICE_REQUEST_PAGE,
  RXFIELD_SERVICE_REQUEST_LICENSE_PLATE, RXFIELD_SERVICE_REQUEST_VIN,
  RXFIELD_SERVICE_REQUEST_MODEL, RXFIELD_SERVICE_REQUEST_ENGINE_NO,
  RXFIELD_SERVICE_REQUEST_TRIM, RXFIELD_SERVICE_REQUEST_TRANSMISSION,
  RXFIELD_SERVICE_REQUEST_MODEL_YEAR, FILTER_TYPE_TEXT,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../../../type';

const renderDialogContent = (downloadingDeleting, pageMode) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_USER_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderUserId}
            placeholder={LocalizedString.serviceRequestPage.placeholderUserId}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderUserName}
            placeholder={LocalizedString.serviceRequestPage.placeholderUserName}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_DEALER_ID}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderDealerId}
            placeholder={LocalizedString.serviceRequestPage.placeholderDealerId}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_DEALER_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderDealerName}
            placeholder={LocalizedString.serviceRequestPage.placeholderDealerName}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_REQUESTED_DATE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderRequestedDate}
            placeholder={LocalizedString.serviceRequestPage.placeholderRequestedDate}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_REPAIR_TYPE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderRepairType}
            placeholder={LocalizedString.serviceRequestPage.placeholderRepairType}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
    <SectionTitle title={LocalizedString.serviceRequestPage.labelVehicle} />
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_LICENSE_PLATE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderLicensePlate}
            placeholder={LocalizedString.serviceRequestPage.placeholderLicensePlate}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_MODEL}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderModel}
            placeholder={LocalizedString.serviceRequestPage.placeholderModel}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_TRIM}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderTrim}
            placeholder={LocalizedString.serviceRequestPage.placeholderTrim}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_MODEL_YEAR}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderModelYear}
            placeholder={LocalizedString.serviceRequestPage.placeholderModelYear}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_VIN}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderVin}
            placeholder={LocalizedString.serviceRequestPage.placeholderVin}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_ENGINE_NO}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderEngineNo}
            placeholder={LocalizedString.serviceRequestPage.placeholderEngineNo}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SERVICE_REQUEST_TRANSMISSION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.serviceRequestPage.placeholderTransmission}
            placeholder={LocalizedString.serviceRequestPage.placeholderTransmission}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const ServiceRequestPage = ({
  users, dealers, downloading, downloadingDeleting,
  handleSubmit, onAppear, loadingDealer, loadingUser, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onCancelPressed, onChangePage, onChangePageSize,
  onChangeDealerText, onChangeUserText, onConfirmDeletePressed, onDeletePressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onViewPressed, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_SERVICE_REQUESTS}
    uiPage={RXSTATE_SERVICE_REQUEST_PAGE}
    filterColumns={[
      {
        title: LocalizedString.serviceRequestPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.serviceRequestPage.placeholderRequestedDate,
        field: RXFIELD_SERVICE_REQUEST_REQUESTED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.serviceRequestPage.placeholderDealerName,
        field: RXFIELD_SERVICE_REQUEST_DEALER_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: dealers,
        loading: loadingDealer,
        onChangeFilterText: onChangeDealerText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.serviceRequestPage.placeholderUserName,
        field: RXFIELD_SERVICE_REQUEST_USER_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.serviceRequestPage.placeholderVin,
        field: 'vin',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.serviceRequestPage.placeholderLicensePlate,
        field: 'licensePlate',
        type: FILTER_TYPE_TEXT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.serviceRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.serviceRequestPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.serviceRequestPage.labelVehicle, field: 'vehicle.trim', sorting: !downloading },
      {
        title: LocalizedString.serviceRequestPage.labelRequestedDate,
        field: 'serviceDate',
        sorting: !downloading,
        render: ({ serviceDate }) => (serviceDate
          ? moment(serviceDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.serviceRequestPage.labelDealer, field: 'dealer.name', sorting: !downloading },
      { title: LocalizedString.serviceRequestPage.labelRepairType, field: 'repairType', sorting: !downloading },
      { title: LocalizedString.serviceRequestPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCancelPressed={onCancelPressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onDeletePressed={onDeletePressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.serviceRequestPage.title}
    disableCreate
    disableEdit
    useFullWidth
  >
    {renderDialogContent(downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_SERVICE_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ServiceRequestPage);

ServiceRequestPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  dealers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingDealer: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeDealerText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
