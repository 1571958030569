import { downloadTransactionCanceled } from '../../../helper';
import { downloadingTransactionCanceled, setTransactionCanceled } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingTransactionCanceled(true));
    const bookingCreated = await downloadTransactionCanceled(token, startDate, endDate);

    dispatch(setTransactionCanceled(bookingCreated));
  } finally {
    dispatch(downloadingTransactionCanceled(false));
  }
};
