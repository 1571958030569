
import downloadDeleteTokenImportsAsync from './downloadDeleteTokenImportsAsync';
import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteTokenImport, downloadTokenImport } from '../../../helper';
import {
  addTokenImport, clearTokenImports, downloadingDeletingTokenImport,
  setAlertErrorMessage, setFunctionalPageMode, setTokenImportTappedId,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingTokenImport(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTokenImport;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteTokenImport(tappedId, token);
      dispatch(setTokenImportTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearTokenImports());
      dispatch(downloadDeleteTokenImportsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const category = await downloadTokenImport(tappedId, token);
      dispatch(addTokenImport(category));
    }
  } finally {
    dispatch(downloadingDeletingTokenImport(false));
  }
};
