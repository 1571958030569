import {
  DOWNLOADING_CAR_SERVICE_RESERVATIONS,
  DOWNLOADING_DELETING_CAR_SERVICE_RESERVATION, SET_CAR_SERVICE_RESERVATION_SELECTED_PAGE_SIZE,
  SET_CAR_SERVICE_RESERVATION_TAPPED_ID, SET_CAR_SERVICE_RESERVATION_SEARCH_TEXT,
  SET_CAR_SERVICE_RESERVATION_SELECTED_ORDER_BY,
  SET_CAR_SERVICE_RESERVATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SAVING_CAR_SERVICE_RESERVATIONS,
  DOWNLOADING_CAR_SERVICE_RESERVATION_INVOICE,
  DOWNLOADING_CAR_SERVICE_RESERVATION_DEALER_DETAIL,
  SET_CAR_SERVICE_RESERVATION_DEALER_DETAIL,
  DOWNLOADING_CAR_SERVICE_RESERVATION_SUMMARY,
  SET_CAR_SERVICE_RESERVATION_SUMMARY,
} from '../action';
import { INITIAL_ORDER_BY_CAR_SERVICE_RESERVATIONS } from '../../constant';

const initialState = {
  downloadingSummary: false,
  downloadingDealerDetail: false,
  downloadingInvoice: false,
  downloadingDeleting: false,
  downloading: false,
  saving: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_CAR_SERVICE_RESERVATIONS,
  dealerDetail: {},
  reservationSummary: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CAR_SERVICE_RESERVATION_INVOICE:
      return { ...state, downloadingInvoice: action.status };
    case DOWNLOADING_CAR_SERVICE_RESERVATION_DEALER_DETAIL:
      return { ...state, downloadingDealerDetail: action.status };
    case DOWNLOADING_CAR_SERVICE_RESERVATION_SUMMARY:
      return { ...state, downloadingSummary: action.status };
    case SET_CAR_SERVICE_RESERVATION_SUMMARY:
      return { ...state, reservationSummary: action.data };
    case SET_CAR_SERVICE_RESERVATION_DEALER_DETAIL:
      return { ...state, dealerDetail: action.detail };
    case DOWNLOADING_CAR_SERVICE_RESERVATIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_CAR_SERVICE_RESERVATION:
      return { ...state, downloadingDeleting: action.status };
    case SAVING_CAR_SERVICE_RESERVATIONS:
      return { ...state, saving: action.status };
    case SET_CAR_SERVICE_RESERVATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CAR_SERVICE_RESERVATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CAR_SERVICE_RESERVATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CAR_SERVICE_RESERVATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CAR_SERVICE_RESERVATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
