import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_PARTNER_MERCHANTS = 'DOWNLOADING_PARTNER_MERCHANTS';
export const downloadingPartnerMerchants = makeActionCreator(DOWNLOADING_PARTNER_MERCHANTS, 'status');

export const SET_PARTNER_MERCHANTS = 'SET_PARTNER_MERCHANTS';
export const setPartnerMerchants = makeActionCreator(SET_PARTNER_MERCHANTS, 'data');

export const ADD_PARTNER_MERCHANT = 'ADD_PARTNER_MERCHANT';
export const addPartnerMerchant = makeActionCreator(ADD_PARTNER_MERCHANT, 'data');

export const CLEAR_PARTNER_MERCHANTS = 'CLEAR_PARTNER_MERCHANTS';
export const clearPartnerMerchants = makeActionCreator(CLEAR_PARTNER_MERCHANTS);

export const ADDING_EDITING_PARTNER_MERCHANT = 'ADDING_EDITING_PARTNER_MERCHANT';
export const addingEditingPartnerMerchant = makeActionCreator(ADDING_EDITING_PARTNER_MERCHANT, 'status');

export const DOWNLOADING_DELETING_PARTNER_MERCHANT = 'DOWNLOADING_DELETING_PARTNER_MERCHANT';
export const downloadingDeletingPartnerMerchant = makeActionCreator(DOWNLOADING_DELETING_PARTNER_MERCHANT, 'status');


export const SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE = 'SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE';
export const setPartnerMerchantSelectedPageSize = makeActionCreator(SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE, 'size');

export const SET_PARTNER_MERCHANT_SELECTED_ORDER_BY = 'SET_PARTNER_MERCHANT_SELECTED_ORDER_BY';
export const setPartnerMerchantSelectedOrderBy = makeActionCreator(SET_PARTNER_MERCHANT_SELECTED_ORDER_BY, 'order');

export const SET_PARTNER_MERCHANT_TAPPED_ID = 'SET_PARTNER_MERCHANT_TAPPED_ID';
export const setPartnerMerchantTappedId = makeActionCreator(SET_PARTNER_MERCHANT_TAPPED_ID, 'id');

export const SET_PARTNER_MERCHANT_SEARCH_TEXT = 'SET_PARTNER_MERCHANT_SEARCH_TEXT';
export const setPartnerMerchantSearchText = makeActionCreator(SET_PARTNER_MERCHANT_SEARCH_TEXT, 'text');

export const SET_PARTNER_MERCHANT_SELECTED_COMPANY = 'SET_PARTNER_MERCHANT_SELECTED_COMPANY';
export const setPartnerMerchantSelectedCompany = makeActionCreator(SET_PARTNER_MERCHANT_SELECTED_COMPANY, 'value');

export const COPYING_PARTNER_MERCHANT_DEEP_LINK = 'COPYING_PARTNER_MERCHANT_DEEP_LINK';
export const copyingPartnerMerchantDeepLink = makeActionCreator(COPYING_PARTNER_MERCHANT_DEEP_LINK, 'status');


export const DOWNLOADING_PROMO_CODES = 'DOWNLOADING_PROMO_CODES';
export const downloadingPromoCodes = makeActionCreator(DOWNLOADING_PROMO_CODES, 'status');

export const DOWNLOADING_DELETING_PROMO_CODE = 'DOWNLOADING_DELETING_PROMO_CODE';
export const downloadingDeletingPromoCode = makeActionCreator(DOWNLOADING_DELETING_PROMO_CODE, 'status');

export const ADDING_EDITING_PROMO_CODE = 'ADDING_EDITING_PROMO_CODE';
export const addingEditingPromoCode = makeActionCreator(ADDING_EDITING_PROMO_CODE, 'status');

export const SET_PROMO_CODES = 'SET_PROMO_CODES';
export const setPromoCodes = makeActionCreator(SET_PROMO_CODES, 'data');

export const ADD_PROMO_CODE = 'ADD_PROMO_CODE';
export const addPromoCode = makeActionCreator(ADD_PROMO_CODE, 'data');

export const CLEAR_PROMO_CODES = 'CLEAR_PROMO_CODES';
export const clearPromoCodes = makeActionCreator(CLEAR_PROMO_CODES);

export const ADDING_PROMO_CODE_VOUCHERS = 'ADDING_PROMO_CODE_VOUCHERS';
export const addingPromoCodeVouchers = makeActionCreator(ADDING_PROMO_CODE_VOUCHERS, 'status');

export const SET_PROMO_CODE_TABLE_DATA_VOUCHERS = 'SET_PROMO_CODE_TABLE_DATA_VOUCHERS';
export const setPromoCodeTableDataVouchers = makeActionCreator(SET_PROMO_CODE_TABLE_DATA_VOUCHERS, 'data');

export const SAVING_PROMO_CODES = 'SAVING_PROMO_CODES';
export const savingPromoCodes = makeActionCreator(SAVING_PROMO_CODES, 'status');


export const SET_PROMO_CODE_SEARCH_TEXT = 'SET_PROMO_CODE_SEARCH_TEXT';
export const setPromoCodeSearchText = makeActionCreator(SET_PROMO_CODE_SEARCH_TEXT, 'text');

export const SET_PROMO_CODE_SELECTED_ORDER_BY = 'SET_PROMO_CODE_SELECTED_ORDER_BY';
export const setPromoCodeSelectedOrderBy = makeActionCreator(SET_PROMO_CODE_SELECTED_ORDER_BY, 'order');

export const SET_PROMO_CODE_SELECTED_PAGE_SIZE = 'SET_PROMO_CODE_SELECTED_PAGE_SIZE';
export const setPromoCodeSelectedPageSize = makeActionCreator(SET_PROMO_CODE_SELECTED_PAGE_SIZE, 'size');

export const SET_PROMO_CODE_TAPPED_ID = 'SET_PROMO_CODE_TAPPED_ID';
export const setPromoCodeTappedId = makeActionCreator(SET_PROMO_CODE_TAPPED_ID, 'id');

export const SET_PROMO_CODE_SELECTED_MERCHANT = 'SET_PROMO_CODE_SELECTED_MERCHANT';
export const setPromoCodeSelectedMerchant = makeActionCreator(SET_PROMO_CODE_SELECTED_MERCHANT, 'value');

export const SET_PROMO_CODE_SELECTED_REWARD = 'SET_PROMO_CODE_SELECTED_REWARD';
export const setPromoCodeSelectedReward = makeActionCreator(SET_PROMO_CODE_SELECTED_REWARD, 'value');

export const SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE = 'SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE';
export const setPromoCodeSelectedVoucherBypassMaxPurchase = makeActionCreator(SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE, 'value');

export const SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT = 'SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT';
export const setPromoCodeSelectedVoucherBypassAchievement = makeActionCreator(SET_PROMO_CODE_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT, 'value');

export const SET_PROMO_CODE_SELECTED_VOUCHERS = 'SET_PROMO_CODE_SELECTED_VOUCHERS';
export const setPromoCodeSelectedVouchers = makeActionCreator(SET_PROMO_CODE_SELECTED_VOUCHERS, 'option');

export const SET_PROMO_CODE_USER_TYPES = 'SET_PROMO_CODE_USER_TYPES';
export const setPromoCodeUserTypes = makeActionCreator(SET_PROMO_CODE_USER_TYPES, 'option');

export const SET_PROMO_CODE_SELECTED_AIRPORT = 'SET_PROMO_CODE_SELECTED_AIRPORT';
export const setPromoCodeSelectedAirport = makeActionCreator(SET_PROMO_CODE_SELECTED_AIRPORT, 'option');


export const DOWNLOADING_REDEMPTION_HISTORIES = 'DOWNLOADING_REDEMPTION_HISTORIES';
export const downloadingRedemptionHistories = makeActionCreator(DOWNLOADING_REDEMPTION_HISTORIES, 'status');

export const DOWNLOADING_REDEMPTION_HISTORY = 'DOWNLOADING_REDEMPTION_HISTORY';
export const downloadingRedemptionHistory = makeActionCreator(DOWNLOADING_REDEMPTION_HISTORY, 'status');

export const SET_REDEMPTION_HISTORY_SEARCH_TEXT = 'SET_REDEMPTION_HISTORY_SEARCH_TEXT';
export const setRedemptionHistorySearchText = makeActionCreator(SET_REDEMPTION_HISTORY_SEARCH_TEXT, 'text');

export const SET_REDEMPTION_HISTORY_SELECTED_ORDER_BY = 'SET_REDEMPTION_HISTORY_SELECTED_ORDER_BY';
export const setRedemptionHistorySelectedOrderBy = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_ORDER_BY, 'order');

export const SET_REDEMPTION_HISTORY_SELECTED_PAGE_SIZE = 'SET_REDEMPTION_HISTORY_SELECTED_PAGE_SIZE';
export const setRedemptionHistorySelectedPageSize = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_PAGE_SIZE, 'size');

export const SET_REDEMPTION_HISTORY_TAPPED_ID = 'SET_REDEMPTION_HISTORY_TAPPED_ID';
export const setRedemptionHistoryTappedId = makeActionCreator(SET_REDEMPTION_HISTORY_TAPPED_ID, 'id');

export const SET_REDEMPTION_HISTORY_SELECTED_MERCHANT = 'SET_REDEMPTION_HISTORY_SELECTED_MERCHANT';
export const setRedemptionHistorySelectedMerchant = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_MERCHANT, 'value');

export const SET_REDEMPTION_HISTORY_SELECTED_USER = 'SET_REDEMPTION_HISTORY_SELECTED_USER';
export const setRedemptionHistorySelectedUser = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_USER, 'value');

export const SET_REDEMPTION_HISTORY_SELECTED_VOUCHER = 'SET_REDEMPTION_HISTORY_SELECTED_VOUCHER';
export const setRedemptionHistorySelectedVoucher = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_VOUCHER, 'value');

export const SET_REDEMPTION_HISTORY_SELECTED_REWARD = 'SET_REDEMPTION_HISTORY_SELECTED_REWARD';
export const setRedemptionHistorySelectedReward = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_REWARD, 'value');

export const SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE = 'SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE';
export const setRedemptionHistorySelectedVoucherBypassMaxPurchase = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE, 'value');

export const SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT = 'SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT';
export const setRedemptionHistorySelectedVoucherBypassAchievement = makeActionCreator(SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT, 'value');

export const SET_REDEMPTION_HISTORIES = 'SET_REDEMPTION_HISTORIES';
export const setRedemptionHistories = makeActionCreator(SET_REDEMPTION_HISTORIES, 'data');

export const ADD_REDEMPTION_HISTORY = 'ADD_REDEMPTION_HISTORY';
export const addRedemptionHistory = makeActionCreator(ADD_REDEMPTION_HISTORY, 'data');

export const CLEAR_REDEMPTION_HISTORIES = 'CLEAR_REDEMPTION_HISTORIES';
export const clearRedemptionHistories = makeActionCreator(CLEAR_REDEMPTION_HISTORIES);


export const DOWNLOADING_PROMO_CODE_BATCHES = 'DOWNLOADING_PROMO_CODE_BATCHES';
export const downloadingPromoCodeBatches = makeActionCreator(DOWNLOADING_PROMO_CODE_BATCHES, 'status');

export const SET_PROMO_CODE_BATCHES = 'SET_PROMO_CODE_BATCHES';
export const setPromoCodeBatch = makeActionCreator(SET_PROMO_CODE_BATCHES, 'data');

export const ADD_PROMO_CODE_BATCH = 'ADD_PROMO_CODE_BATCH';
export const addPromoCodeBatch = makeActionCreator(ADD_PROMO_CODE_BATCH, 'data');

export const CLEAR_PROMO_CODE_BATCHES = 'CLEAR_PROMO_CODE_BATCHES';
export const clearPromoCodeBatches = makeActionCreator(CLEAR_PROMO_CODE_BATCHES);

export const ADDING_EDITING_PROMO_CODE_BATCH = 'ADDING_EDITING_PROMO_CODE_BATCH';
export const addingEditingPromoCodeBatch = makeActionCreator(ADDING_EDITING_PROMO_CODE_BATCH, 'status');

export const DOWNLOADING_DELETING_PROMO_CODE_BATCH = 'DOWNLOADING_DELETING_PROMO_CODE_BATCH';
export const downloadingDeletingPromoCodeBatch = makeActionCreator(DOWNLOADING_DELETING_PROMO_CODE_BATCH, 'status');

export const CANCELLING_PROMO_CODE_BATCH = 'CANCELLING_PROMO_CODE_BATCH';
export const cancellingPromoCodeBatch = makeActionCreator(CANCELLING_PROMO_CODE_BATCH, 'status');

export const ADDING_PROMO_CODE_BATCH_VOUCHERS = 'ADDING_PROMO_CODE_BATCH_VOUCHERS';
export const addingPromoCodeBatchVouchers = makeActionCreator(ADDING_PROMO_CODE_BATCH_VOUCHERS, 'status');


export const SET_PROMO_CODE_BATCH_SELECTED_PAGE_SIZE = 'SET_PROMO_CODE_BATCH_SELECTED_PAGE_SIZE';
export const setPromoCodeBatchSelectedPageSize = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_PAGE_SIZE, 'size');

export const SET_PROMO_CODE_BATCH_SELECTED_ORDER_BY = 'SET_PROMO_CODE_BATCH_SELECTED_ORDER_BY';
export const setPromoCodeBatchSelectedOrderBy = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_ORDER_BY, 'order');

export const SET_PROMO_CODE_BATCH_TAPPED_ID = 'SET_PROMO_CODE_BATCH_TAPPED_ID';
export const setPromoCodeBatchTappedId = makeActionCreator(SET_PROMO_CODE_BATCH_TAPPED_ID, 'id');

export const SET_PROMO_CODE_BATCH_SEARCH_TEXT = 'SET_PROMO_CODE_BATCH_SEARCH_TEXT';
export const setPromoCodeBatchSearchText = makeActionCreator(SET_PROMO_CODE_BATCH_SEARCH_TEXT, 'text');

export const SET_PROMO_CODE_BATCH_SELECTED_MERCHANT = 'SET_PROMO_CODE_BATCH_SELECTED_MERCHANT';
export const setPromoCodeBatchSelectedMerchant = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_MERCHANT, 'option');

export const SET_PROMO_CODE_BATCH_SELECTED_REWARD_TYPE = 'SET_PROMO_CODE_BATCH_SELECTED_REWARD_TYPE';
export const setPromoCodeBatchSelectedRewardType = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_REWARD_TYPE, 'option');

export const SET_PROMO_CODE_BATCH_SELECTED_BYPASS_ACHIEVEMENT = 'SET_PROMO_CODE_BATCH_SELECTED_BYPASS_ACHIEVEMENT';
export const setPromoCodeBatchSelectedBypassAchievement = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_BYPASS_ACHIEVEMENT, 'option');

export const SET_PROMO_CODE_BATCH_SELECTED_BYPASS_MAX_PURCHASE = 'SET_PROMO_CODE_BATCH_SELECTED_BYPASS_MAX_PURCHASE';
export const setPromoCodeBatchSelectedBypassMaxPurchase = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_BYPASS_MAX_PURCHASE, 'option');

export const SET_PROMO_CODE_BATCH_SELECTED_VOUCHERS = 'SET_PROMO_CODE_BATCH_SELECTED_VOUCHERS';
export const setPromoCodeBatchSelectedVouchers = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_VOUCHERS, 'option');

export const SET_PROMO_CODE_BATCH_TABLE_DATA_VOUCHERS = 'SET_PROMO_CODE_BATCH_TABLE_DATA_VOUCHERS';
export const setPromoCodeBatchTableDataVouchers = makeActionCreator(SET_PROMO_CODE_BATCH_TABLE_DATA_VOUCHERS, 'data');

export const SET_PROMO_CODE_BATCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_PROMO_CODE_BATCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setPromoCodeBatchAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_PROMO_CODE_BATCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_PROMO_CODE_BATCH_USER_TYPES = 'SET_PROMO_CODE_BATCH_USER_TYPES';
export const setPromoCodeBatchUserTypes = makeActionCreator(SET_PROMO_CODE_BATCH_USER_TYPES, 'option');

export const SET_PROMO_CODE_BATCH_SELECTED_AIRPORT = 'SET_PROMO_CODE_BATCH_SELECTED_AIRPORT';
export const setPromoCodeBatchSelectedAirport = makeActionCreator(SET_PROMO_CODE_BATCH_SELECTED_AIRPORT, 'option');
