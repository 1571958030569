import { SET_SALES_CONSULTANTS, CLEAR_SALES_CONSULTANTS } from '../action';

const initialState = {
  data: {},
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SALES_CONSULTANTS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case CLEAR_SALES_CONSULTANTS:
      return initialState;
    default: return state;
  }
};
