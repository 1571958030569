import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { DialogContent, makeStyles, Modal } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  COLOR_DANGEROUS, PICKER_MODE_DATE, RXFIELD_CAR_OWNERSHIP_NOTES,
  RXFIELD_CAR_OWNERSHIP_VEHICLE_PRICE, RXFIELD_CAR_OWNERSHIP_WARRANTY_START_DATE,
  RXFORM_CAR_OWNERSHIP_APPROVAL_MODAL,
} from '../../constant';
import { AccentButton } from '../../component';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
}));

const AlertBox = ({
  message, handleSubmit, onConfirmPressed, onClosePressed,
}) => {
  const classes = useStyles();

  return (
    <Modal open={!!message} disableBackdropClick disableEscapeKeyDown>
      <DialogContent>
        <SweetAlert
          type="success"
          show={!!message}
          title={GlobalLocalizedString.common.alertTitleConfirmation}
          placeholder="test placeholder"
          onConfirm={handleSubmit((value) => {
            onConfirmPressed(value.notes, message, value.warantyStartDate, value.vehiclePrice);
            onClosePressed();
          })}
          openAnim={{ name: 'showSweetAlert', duration: 500 }}
          closeOnClickOutside={false}
          customButtons={(
            <div className={classes.buttonContainer}>
              <AccentButton
                onClick={onClosePressed}
                variant="text"
                className={classes.redButton}
                caption={GlobalLocalizedString.common.buttonCaptionNo}
              />
              <AccentButton
                onClick={handleSubmit((value) => {
                  onConfirmPressed(value.notes, message, value.warantyStartDate,
                    value.vehiclePrice);
                  onClosePressed();
                })}
                caption={GlobalLocalizedString.common.buttonCaptionYes}
              />
            </div>
       )}
        >
          {message}
          <Field
            name={RXFIELD_CAR_OWNERSHIP_NOTES}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.registrationPage.placeholderNotes}
            placeholder={LocalizedString.registrationPage.placeholderNotes}
            multiline
          />
          <Field
            name={RXFIELD_CAR_OWNERSHIP_WARRANTY_START_DATE}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.registrationPage.placeholderWarrantyStartDate}
            placeholder={LocalizedString.registrationPage.placeholderWarrantyStartDate}
            pickerMode={PICKER_MODE_DATE}
          />
          <Field
            name={RXFIELD_CAR_OWNERSHIP_VEHICLE_PRICE}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.registrationPage.placeholderVehiclePrice}
            placeholder={LocalizedString.registrationPage.placeholderVehiclePrice}
            type="number"
          />
        </SweetAlert>
      </DialogContent>
    </Modal>
  );
};

export default reduxForm({
  form: RXFORM_CAR_OWNERSHIP_APPROVAL_MODAL,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AlertBox);

AlertBox.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClosePressed: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  message: PropTypes.string,
};

AlertBox.defaultProps = {
  message: '',
};
