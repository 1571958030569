import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_SCANNED_CODES, MENUID_HYUNDAI_CHALLENGE_SCANNED_CODE, PAGE_MODE_TABLE } from '../../constant';
import { transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  setAlertErrorMessage, setActiveSideMenuItem,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setHyundaiChallengeScannedCodesSearchText, setHyundaiChallengeScannedCodesFilterString,
  clearHyundaiChallengeScannedCodes, setHyundaiChallengeScannedCodesPageSize,
  setHyundaiChallengeScannedCodesOrderBy,
  downloadHyundaiChallengeScannedCodesAsync,
  downloadDeleteHyundaiChallengeScannedCodeAsync,
  saveHyundaiChallengeScannedCodesAsync,
  setHyundaiChallengeScannedCodesTappedId,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import HyundaiChallengeScannedCode from './scanned-code.presentation';

const getInitialValues = (state) => {
  const {
    hyundaiChallengeScannedCodes, uiFunctionalPage, uiHyundaiChallengeScannedCode,
  } = state;
  const { data } = hyundaiChallengeScannedCodes;
  const { downloadingScannedCode, tappedId } = uiHyundaiChallengeScannedCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingScannedCode
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    user: found.user
      ? { fullName: found.user.fullName, id: found.user.id }
      : { fullName: '', id: '' },
  };

  return result;
};

const mapStateToProps = (state) => ({
  scannedCodes: state.hyundaiChallengeScannedCodes.data,
  downloading: state.uiHyundaiChallengeScannedCode.downloading,
  downloadingScannedCode: state.uiHyundaiChallengeScannedCode.downloadingScannedCode,
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_HYUNDAI_CHALLENGE_SCANNED_CODE));
    dispatch(setHyundaiChallengeScannedCodesSearchText(''));
    dispatch(setHyundaiChallengeScannedCodesFilterString(''));
    dispatch(clearHyundaiChallengeScannedCodes());
    dispatch(setHyundaiChallengeScannedCodesPageSize(20));
    dispatch(setHyundaiChallengeScannedCodesOrderBy(
      INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_SCANNED_CODES,
    ));

    dispatch(downloadHyundaiChallengeScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setHyundaiChallengeScannedCodesPageSize(pageSize));
    dispatch(clearHyundaiChallengeScannedCodes());

    dispatch(downloadHyundaiChallengeScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setHyundaiChallengeScannedCodesOrderBy(orderBy));
    dispatch(clearHyundaiChallengeScannedCodes());
    dispatch(downloadHyundaiChallengeScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadHyundaiChallengeScannedCodesAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setHyundaiChallengeScannedCodesPageSize(pageSize));
    dispatch(downloadHyundaiChallengeScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setHyundaiChallengeScannedCodesSearchText(text));
      dispatch(clearHyundaiChallengeScannedCodes());
      await dispatch(downloadHyundaiChallengeScannedCodesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/scanCodeUsername/, 'user.id');

    dispatch(setHyundaiChallengeScannedCodesFilterString(text));
    dispatch(clearHyundaiChallengeScannedCodes());
    dispatch(downloadHyundaiChallengeScannedCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setHyundaiChallengeScannedCodesFilterString(''));
    dispatch(clearHyundaiChallengeScannedCodes());
    dispatch(setHyundaiChallengeScannedCodesOrderBy(
      INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_SCANNED_CODES,
    ));
    dispatch(downloadHyundaiChallengeScannedCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setHyundaiChallengeScannedCodesTappedId(id));
    dispatch(downloadDeleteHyundaiChallengeScannedCodeAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDownloadPressed: () => {
    dispatch(saveHyundaiChallengeScannedCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HyundaiChallengeScannedCode);
