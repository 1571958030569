import { SET_TRANSACTION_AMOUNT_BY_PART_NAME, CLEAR_TRANSACTION_AMOUNT_BY_PART_NAME } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_TRANSACTION_AMOUNT_BY_PART_NAME: {
      return action.data;
    }
    case CLEAR_TRANSACTION_AMOUNT_BY_PART_NAME:
      return state;
    default:
      return state;
  }
};
