import { ORDER_SORT_DESCENDING, PAYMENT_STATUS_SUCCESS } from '../../constant';

export * from '../../constant';

export const REST_URL_SERVICE_REQUESTS = '/carservice/api/v1/ServiceBooking?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|dealer.name&filterString=';
export const REST_URL_VIEW_DELETE_SERVICE_REQUEST = '/carservice/api/v1/ServiceBooking/{id}';
export const REST_URL_DEALERS = '/carservice/api/v1/Dealer?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_CAR_SERVICE_RESERVATIONS = '/carservice/api/v1/ServiceReservation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|dealer.name|bookingCode|id&filterString=';
export const REST_URL_VIEW_CAR_SERVICE_RESERVATION = '/carservice/api/v1/ServiceReservation/{id}';
export const REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_INVOICE = '/carservice/api/v1/ServiceReservationInvoice/{reservationId}';
export const REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_DEALER_DETAIL = '/carservice/api/v1/Dealer/{dealerId}';
export const REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_SUMMARY = '/carservice/api/v1/ServiceReservationSummary/{reservationId}';
export const REST_URL_VIEW_DEALER = '/carservice/api/v1/Dealer/{id}';
export const REST_URL_CHARGING_STATIONS = '/carservice/api/v1/ChargingStation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_CHARGING_STATION = '/carservice/api/v1/ChargingStation/{id}';
export const REST_URL_DEALER_PROVINCES = '/carservice/api/v1/DealerProvince';
export const REST_URL_DEALER_CITIES = '/carservice/api/v1/DealerCity';
export const REST_URL_CHARGING_STATION_CITIES = '/carservice/api/v1/ChargingStationCity';
export const REST_URL_CHARGING_STATION_PROVINCES = '/carservice/api/v1/ChargingStationProvince';
export const REST_URL_EV_CHARGING_URLS = '/carservice/api/v1/EVChargingUrl?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_EV_CHARGING_URL = '/carservice/api/v1/EVChargingUrl/{id}';
export const REST_URL_ADD_EDIT_EV_CHARGING_URL = '/carservice/api/v1/EVChargingUrl';

export const ROUTE_NAME_CAR_SERVICE = '/car-service';
export const ROUTE_NAME_CAR_SERVICE_DEALER = '/car-service-dealer';
export const ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST = '/service-request';
export const ROUTE_NAME_CAR_SERVICE_RESERVATION = '/car-service-reservation';
export const ROUTE_NAME_CAR_SERVICE_CHARGING_STATION = '/charging-station';
export const ROUTE_NAME_CAR_SERVICE_EV_CHARGING_URL = '/ev-charging-url';

export const RXFORM_SERVICE_REQUEST = 'serviceRequestPage';
export const RXFORM_CAR_SERVICE_RESERVATION = 'carServiceReservationPage';
export const RXFORM_CAR_SERVICE_DEALER = 'carServiceDealerPage';
export const RXFORM_CAR_SERVICE_CHARGING_STATION = 'carServiceChargingStationPage';
export const RXFORM_CAR_SERVICE_EV_CHARGING_URL = 'carServiceEvChargingUrlPage';

export const RXFIELD_CAR_SERVICE_DEALER_DEALER_ID = 'dealerId';
export const RXFIELD_CAR_SERVICE_DEALER_DEALER_CODE = 'dealerCode';
export const RXFIELD_CAR_SERVICE_DEALER_NAME = 'name';
export const RXFIELD_CAR_SERVICE_DEALER_ADDRESS = 'address';
export const RXFIELD_CAR_SERVICE_DEALER_CITY = 'city';
export const RXFIELD_CAR_SERVICE_DEALER_REGION = 'region';
export const RXFIELD_CAR_SERVICE_DEALER_POSTAL_CODE = 'postalCode';
export const RXFIELD_CAR_SERVICE_DEALER_EMAIL = 'email';
export const RXFIELD_CAR_SERVICE_DEALER_PHONE = 'phone';
export const RXFIELD_CAR_SERVICE_DEALER_TYPE = 'type';
export const RXFIELD_CAR_SERVICE_DEALER_DEALER_STATUS = 'dealerStatus';
export const RXFIELD_CAR_SERVICE_DEALER_DEALER_TYPE = 'dealerType';
export const RXFIELD_CAR_SERVICE_DEALER_DEALER_TYPE_CODE = 'dealerTypeCode';
export const RXFIELD_CAR_SERVICE_DEALER_HAS_CHARGING_STATION = 'hasChargingStation';
export const RXFIELD_CAR_SERVICE_DEALER_LATITUDE = 'latitude';
export const RXFIELD_CAR_SERVICE_DEALER_LONGITUDE = 'longitude';

export const RXFIELD_SERVICE_REQUEST_USER_ID = 'user.id';
export const RXFIELD_SERVICE_REQUEST_USER_NAME = 'fullName';
export const RXFIELD_SERVICE_REQUEST_DEALER_ID = 'dealer.id';
export const RXFIELD_SERVICE_REQUEST_DEALER_NAME = 'name';
export const RXFIELD_SERVICE_REQUEST_REQUESTED_DATE = 'serviceDate';
export const RXFIELD_SERVICE_REQUEST_REPAIR_TYPE = 'repairType';
export const RXFIELD_SERVICE_REQUEST_LICENSE_PLATE = 'vehicle.licensePlate';
export const RXFIELD_SERVICE_REQUEST_VIN = 'vehicle.vin';
export const RXFIELD_SERVICE_REQUEST_MODEL = 'vehicle.model';
export const RXFIELD_SERVICE_REQUEST_ENGINE_NO = 'vehicle.engineNo';
export const RXFIELD_SERVICE_REQUEST_TRIM = 'vehicle.trim';
export const RXFIELD_SERVICE_REQUEST_TRANSMISSION = 'vehicle.transmission';
export const RXFIELD_SERVICE_REQUEST_MODEL_YEAR = 'vehicle.modelYear';

export const RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_NAME = 'customerName';
export const RXFIELD_CAR_SERVICE_RESERVATION_MEMBER_ID = 'user.userCode';
export const RXFIELD_CAR_SERVICE_RESERVATION_EMAIL = 'user.email';
export const RXFIELD_CAR_SERVICE_RESERVATION_ADDRESS = 'address';
export const RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_PHONE = 'user.phone';
export const RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_LOCATION = 'customerAddress';
export const RXFIELD_CAR_SERVICE_RESERVATION_CUSTOMER_TYPE = 'bodyAndPaintCustomerType';
export const RXFIELD_CAR_SERVICE_RESERVATION_CONTACT_PERSON = 'contactName';
export const RXFIELD_CAR_SERVICE_RESERVATION_CONTACT_PERSON_PHONE = 'contactPhone';
export const RXFIELD_CAR_SERVICE_RESERVATION_PICKUP_LOCATION = 'pickupLocation';
export const RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_LOCATION = 'deliveryLocation';
export const RXFIELD_CAR_SERVICE_RESERVATION_BOOKING_CREATED = 'createdDate';
export const RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_SCHEDULED = 'serviceDate';
export const RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_STATUS = 'serviceStatus';
export const RXFIELD_CAR_SERVICE_RESERVATION_VEHICLE_MODEL = 'vehicle.model';
export const RXFIELD_CAR_SERVICE_RESERVATION_VIN = 'vin';
export const RXFIELD_CAR_SERVICE_RESERVATION_ODOMETER = 'odometer';
export const RXFIELD_CAR_SERVICE_RESERVATION_REPAIR_SELECTION = 'repairType';
export const RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_INTERVAL = 'periodicService.description';
export const RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_FINISH_TIME = 'serviceFinishTime';
export const RXFIELD_CAR_SERVICE_RESERVATION_SERVICE_ARRIVAL_TIME = 'arrivalTime';
export const RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD = 'deliveryMethod';
export const RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_METHOD = 'paymentMethod';
export const RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_STATUS = 'paymentStatus';
export const RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_DATE = 'paymentDate';
export const RXFIELD_CAR_SERVICE_RESERVATION_PAYMENT_TOTAL = 'paymentTotal';
export const RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_1 = 'image01';
export const RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_2 = 'image02';
export const RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_3 = 'image03';
export const RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_4 = 'image04';
export const RXFIELD_CAR_SERVICE_RESERVATION_IMAGE_5 = 'image05';
export const RXFIELD_CAR_SERVICE_RESERVATION_CAR_SERVICE_RESERVATION_NOTE = 'reservationNotes';
export const RXFIELD_CAR_SERVICE_RESERVATION_DEALER_NAME = 'name';
export const RXFIELD_CAR_SERVICE_RESERVATION_USER_NAME = 'fullName';
export const RXFIELD_CAR_SERVICE_RESERVATION_BOOKING_CODE = 'bookingCode';
export const RXFIELD_CAR_SERVICE_RESERVATION_LABORS = 'reservationSummary.labors';
export const RXFIELD_CAR_SERVICE_RESERVATION_PARTS = 'reservationSummary.parts';
export const RXFIELD_CAR_SERVICE_RESERVATION_ACCESSORIES = 'reservationSummary.accessories';
export const RXFIELD_CAR_SERVICE_RESERVATION_ADDITIONAL = 'reservationSummary.additional';
export const RXFIELD_CAR_SERVICE_RESERVATION_VA_NUMBER = 'vaNumber';

export const RXFIELD_CAR_SERVICE_CHARGING_STATION_STATION_ID = 'chargingStationId';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_NAME = 'name';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_ADDRESS = 'address';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_CITY = 'city';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_REGION = 'region';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_POSTAL_CODE = 'postalCode';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_TYPE = 'cargerType';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_CATEGORY = 'category';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_LATITUDE = 'latitude';
export const RXFIELD_CAR_SERVICE_CHARGING_STATION_LONGITUDE = 'longitude';
export const RXFIELD_CAR_SERVICE_EV_CHARGING_URL_CODE = 'code';
export const RXFIELD_CAR_SERVICE_EV_CHARGING_URL_URL = 'url';
export const RXFIELD_CAR_SERVICE_EV_CHARGING_URL_NOTES = 'notes';

export const RXSTATE_CAR_SERVICE_DEALERS = 'dealers';
export const RXSTATE_CAR_SERVICE_DEALER_PAGE = 'uiDealer';
export const RXSTATE_SERVICE_REQUESTS = 'serviceRequests';
export const RXSTATE_SERVICE_REQUEST_PAGE = 'uiServiceRequest';
export const RXSTATE_CAR_SERVICE_RESERVATIONS = 'carServiceReservations';
export const RXSTATE_CAR_SERVICE_RESERVATION_PAGE = 'uiCarServiceReservation';
export const RXSTATE_CAR_SERVICE_CHARGING_STATIONS = 'chargingStations';
export const RXSTATE_CAR_SERVICE_CHARGING_STATION_PAGE = 'uiChargingStation';
export const RXSTATE_CAR_SERVICE_EV_CHARGING_URLS = 'evChargingUrls';
export const RXSTATE_CAR_SERVICE_EV_CHARGING_URL_PAGE = 'uiEvChargingUrl';

export const INITIAL_ORDER_BY_SERVICE_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_DEALERS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CAR_SERVICE_RESERVATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CHARGING_STATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_DEALER_PROVINCES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_DEALER_CITIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_EV_CHARGING_URLS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_CAR_SERVICE = 'MENUID_CAR_SERVICE';
export const MENUID_CAR_SERVICE_DEALER = 'MENUID_CAR_SERVICE_DEALER';
export const MENUID_CAR_SERVICE_SERVICE_REQUEST = 'MENUID_CAR_SERVICE_SERVICE_REQUEST';
export const MENUID_CAR_SERVICE_RESERVATION = 'MENUID_CAR_SERVICE_RESERVATION';
export const MENUID_CAR_SERVICE_CHARGING_STATION = 'MENUID_CAR_SERVICE_CHARGING_STATION';
export const MENUID_CAR_SERVICE_EV_CHARGING_URL = 'MENUID_CAR_SERVICE_EV_CHARGING_URL';

export const REPAIR_TYPE_GENERAL_REPAIR = 'GeneralRepair';
export const REPAIR_TYPE_MOBILE_SERVICE = 'MobileService';
export const REPAIR_TYPE_BODY_AND_PAINT = 'BodyAndPaint';
export const REPAIR_TYPE_GENERAL_REPAIR_LABEL = 'General Repair';
export const REPAIR_TYPE_MOBILE_SERVICE_LABEL = 'Mobile Service';
export const REPAIR_TYPE_BODY_AND_PAINT_LABEL = 'Body And Paint';
export const SERVICE_STATUS_CANCELLED = 'Cancelled';
export const SERVICE_STATUS_RESERVED = 'Reserved';
export const SERVICE_STATUS_FINISHED = 'Finished';
export const SERVICE_STATUS_DRAFT = 'Draft';
export const SERVICE_STATUS_WAITING_PAYMENT = 'WaitingPayment';
export const SERVICE_STATUS_WAITING_DMS = 'WaitingDMS';
export const SERVICE_STATUS_SCHEDULED = 'Scheduled';
export const SERVICE_STATUS_IN_PROGRESS = 'InProgress';
export const SERVICE_STATUS_IN_PREPARATION = 'InPreparation';
export const SERVICE_STATUS_CANCELLED_LABEL = 'Cancelled';
export const SERVICE_STATUS_RESERVED_LABEL = 'Reserved';
export const SERVICE_STATUS_FINISHED_LABEL = 'Finished';
export const SERVICE_STATUS_DRAFT_LABEL = 'Draft';
export const SERVICE_STATUS_WAITING_PAYMENT_LABEL = 'Waiting for Payment';
export const SERVICE_STATUS_WAITING_DMS_LABEL = 'Waiting for DMS';
export const SERVICE_STATUS_SCHEDULED_LABEL = 'Scheduled';
export const SERVICE_STATUS_IN_PROGRESS_LABEL = 'In Progress';
export const SERVICE_STATUS_IN_PREPARATION_LABEL = 'In Preparation';
export const DELIVERY_METHOD_DEALER = 'GoToDealer';
export const DELIVERY_METHOD_PICKUP = 'PickupOnly';
export const DELIVERY_METHOD_DELIVERY = 'DeliveryOnly';
export const DELIVERY_METHOD_PICKUP_DELIVERY = 'PickupAndDelivery';
export const DELIVERY_METHOD_DEALER_LABEL = 'Visit Dealer';
export const DELIVERY_METHOD_PICKUP_LABEL = 'Pickup Only';
export const DELIVERY_METHOD_DELIVERY_LABEL = 'Delivery Only';
export const DELIVERY_METHOD_PICKUP_DELIVERY_LABEL = 'Pickup & Delivery';
export const BANK_TRANSFER_METHOD_BCA_VA = 'BcaVa';
export const BANK_TRANSFER_METHOD_BNI_VA = 'BniVa';
export const BANK_TRANSFER_METHOD_MANDIRI_VA = 'MandiriVa';
export const BANK_TRANSFER_METHOD_PERMATA_VA = 'PermataVa';
export const GOPAY_PAYMENT_METHOD = 'GoPay';
export const BANK_TRANSFER_METHOD_BCA_VA_LABEL = 'BCA Virtual Account';
export const BANK_TRANSFER_METHOD_BNI_VA_LABEL = 'BNI Virtual Account';
export const BANK_TRANSFER_METHOD_MANDIRI_VA_LABEL = 'Mandiri Virtual Account';
export const BANK_TRANSFER_METHOD_PERMATA_VA_LABEL = 'Permata Virtual Account';
export const GOPAY_PAYMENT_METHOD_LABEL = 'GoPay';
export const PAYMENT_STATUS_PENDING = 'Pending';
export const PAYMENT_STATUS_ERROR = 'Error';
export const PAYMENT_STATUS_SUCCESS_LABEL = 'Success';
export const PAYMENT_STATUS_PENDING_LABEL = 'Pending';
export const PAYMENT_STATUS_ERROR_LABEL = 'Error';

export const REPAIR_TYPE_DROPDOWN_SELECTION = [
  {
    label: REPAIR_TYPE_GENERAL_REPAIR_LABEL,
    value: REPAIR_TYPE_GENERAL_REPAIR,
  },
  {
    label: REPAIR_TYPE_MOBILE_SERVICE_LABEL,
    value: REPAIR_TYPE_MOBILE_SERVICE,
  },
  {
    label: REPAIR_TYPE_BODY_AND_PAINT_LABEL,
    value: REPAIR_TYPE_BODY_AND_PAINT,
  },
];

export const SERVICE_STATUS_DROPDOWN_SELECTION = [
  { label: SERVICE_STATUS_DRAFT_LABEL, value: SERVICE_STATUS_DRAFT },
  { label: SERVICE_STATUS_WAITING_DMS_LABEL, value: SERVICE_STATUS_WAITING_DMS },
  { label: SERVICE_STATUS_RESERVED_LABEL, value: SERVICE_STATUS_RESERVED },
  { label: SERVICE_STATUS_WAITING_PAYMENT_LABEL, value: SERVICE_STATUS_WAITING_PAYMENT },
  { label: SERVICE_STATUS_SCHEDULED_LABEL, value: SERVICE_STATUS_SCHEDULED },
  { label: SERVICE_STATUS_CANCELLED_LABEL, value: SERVICE_STATUS_CANCELLED },
  { label: SERVICE_STATUS_IN_PROGRESS_LABEL, value: SERVICE_STATUS_IN_PROGRESS },
  { label: SERVICE_STATUS_FINISHED_LABEL, value: SERVICE_STATUS_FINISHED },
  { label: SERVICE_STATUS_IN_PREPARATION_LABEL, value: SERVICE_STATUS_IN_PREPARATION },
];

export const DELIVERY_METHOD_DROPDOWN_SELECTION = [
  { label: DELIVERY_METHOD_DEALER_LABEL, value: DELIVERY_METHOD_DEALER },
  { label: DELIVERY_METHOD_PICKUP_LABEL, value: DELIVERY_METHOD_PICKUP },
  { label: DELIVERY_METHOD_DELIVERY_LABEL, value: DELIVERY_METHOD_DELIVERY },
  { label: DELIVERY_METHOD_PICKUP_DELIVERY_LABEL, value: DELIVERY_METHOD_PICKUP_DELIVERY },
];

export const PAYMENT_METHOD_DROPDOWN_SELECTION = [
  {
    label: BANK_TRANSFER_METHOD_BCA_VA_LABEL,
    value: BANK_TRANSFER_METHOD_BCA_VA,
  },
  {
    label: BANK_TRANSFER_METHOD_BNI_VA_LABEL,
    value: BANK_TRANSFER_METHOD_BNI_VA,
  },
  {
    label: BANK_TRANSFER_METHOD_PERMATA_VA_LABEL,
    value: BANK_TRANSFER_METHOD_PERMATA_VA,
  },
  {
    label: BANK_TRANSFER_METHOD_MANDIRI_VA_LABEL,
    value: BANK_TRANSFER_METHOD_MANDIRI_VA,
  },
  {
    label: GOPAY_PAYMENT_METHOD_LABEL,
    value: GOPAY_PAYMENT_METHOD,
  },
];

export const PAYMENT_STATUS_DROPDOWN_SELECTION = [
  {
    label: PAYMENT_STATUS_PENDING_LABEL,
    value: PAYMENT_STATUS_PENDING,
  },
  {
    label: PAYMENT_STATUS_SUCCESS_LABEL,
    value: PAYMENT_STATUS_SUCCESS,
  },
  {
    label: PAYMENT_STATUS_ERROR_LABEL,
    value: PAYMENT_STATUS_ERROR,
  },
];
