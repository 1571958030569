import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { downloadUsersAsync } from '../../../voucher';
import {
  PAGE_MODE_TABLE,
  INITIAL_ORDER_BY_RECURRANCE_REFERRAL_REWARDS,
  MENUID_GAME_ACTIVITY_RECURRANCE_REFERRAL_REWARD,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFORM_RECURRANCE_REFERRAL_REWARD,
} from '../../constant';
import {
  transformInitialValues,
  transformUserDropdownData,
  toMoment,
  debounceSearch,
} from '../../helper';
import {
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setRecurrenceReferralRewardSearchText,
  clearRecurrenceReferralRewards,
  setRecurrenceReferralRewardSelectedPageSize,
  setRecurrenceReferralRewardSelectedOrderBy,
  setRecurrenceReferralRewardAdvancedFilterDialogSelectedFilterString,
  setRecurrenceReferralRewardTappedId,
  downloadRecurrenceReferralRewardsAsync,
  downloadRecurrenceReferralRewardAsync,
  setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  clearUsers,
} from '../../redux/action';
import RecurrenceReferralRewardPage from './recurrence-referral-reward.presentation';

const getInitialValues = (state) => {
  const {
    recurrenceReferralRewards,
    uiFunctionalPage,
    uiRecurrenceReferralReward,
  } = state;
  const { data } = recurrenceReferralRewards;
  const { downloadingDeleting, tappedId } = uiRecurrenceReferralReward;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      registrationReferrals:
            found?.registrationReferrals?.length > 0 && !downloadingDeleting
              ? found?.registrationReferrals.map((item) => ({
                ...item,
                createdDate: item?.createdDate
                  ? toMoment(item?.createdDate).format(
                    DATE_TIME_FORMAT_WITHOUT_PIPE,
                  )
                  : '',
              }))
              : [],
    })
    : {};
  return result;
};

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearUsers());
  dispatch(downloadUsersAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});

const mapStateToProps = (state) => ({
  downloading: state.uiRecurrenceReferralReward.downloading,
  downloadingDeleting: state.uiRecurrenceReferralReward.downloadingDeleting,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GAME_ACTIVITY_RECURRANCE_REFERRAL_REWARD));
    dispatch(setRecurrenceReferralRewardSearchText(''));
    dispatch(clearRecurrenceReferralRewards());
    dispatch(setRecurrenceReferralRewardSelectedPageSize(20));
    dispatch(
      setRecurrenceReferralRewardSelectedOrderBy(
        INITIAL_ORDER_BY_RECURRANCE_REFERRAL_REWARDS,
      ),
    );
    dispatch(downloadRecurrenceReferralRewardsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('createdDate', 'createdDate')
      .replace('registeredUser', 'profile.id')
      .replace('rewardStatus', 'rewardStatus');

    dispatch(
      setRecurrenceReferralRewardAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearRecurrenceReferralRewards());
    dispatch(downloadRecurrenceReferralRewardsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRecurrenceReferralRewardsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRecurrenceReferralRewardSelectedPageSize(pageSize));
    dispatch(downloadRecurrenceReferralRewardsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setRecurrenceReferralRewardSelectedPageSize(pageSize));
    dispatch(clearRecurrenceReferralRewards());
    dispatch(downloadRecurrenceReferralRewardsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearRecurrenceReferralRewards());
    dispatch(
      setRecurrenceReferralRewardAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(
      setRecurrenceReferralRewardSelectedOrderBy(
        INITIAL_ORDER_BY_RECURRANCE_REFERRAL_REWARDS,
      ),
    );
    dispatch(downloadRecurrenceReferralRewardsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setRecurrenceReferralRewardSearchText(text));
      dispatch(clearRecurrenceReferralRewards());
      await dispatch(downloadRecurrenceReferralRewardsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setRecurrenceReferralRewardSelectedOrderBy(orderBy));
    dispatch(clearRecurrenceReferralRewards());
    dispatch(downloadRecurrenceReferralRewardsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setRecurrenceReferralRewardTappedId(id));
    dispatch(downloadRecurrenceReferralRewardAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_RECURRANCE_REFERRAL_REWARD));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecurrenceReferralRewardPage);
