import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT, PICKER_DATE_TIME_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_DMS_USER_SUBMISSION_MODEL,
  RXFIELD_DMS_USER_SUBMISSION_REGISTRATION_ID, RXFIELD_DMS_USER_SUBMISSION_RETRY_COUNT,
  RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_DELAY, RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_STATUS,
  RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_STATUS_NOTES,
  RXFIELD_DMS_USER_SUBMISSION_SUBMITTED_ON, RXFIELD_DMS_USER_SUBMISSION_TIER,
  RXFIELD_DMS_USER_SUBMISSION_USER_ID,
  RXFIELD_DMS_USER_SUBMISSION_USER_NAME, RXFIELD_DMS_USER_SUBMISSION_VIN,
  RXFORM_DMS_USER_SUBMISSION,
  RXSTATE_DMS_USER_SUBMISSION,
  RXSTATE_DMS_USER_SUBMISSION_PAGE, SUBMISSION_STATUS,
} from '../../constant';
import LocalizedString from '../../localization';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderUserId}
            label={LocalizedString.DMSUserSubmissionPage.placeholderUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderUserName}
            label={LocalizedString.DMSUserSubmissionPage.placeholderUserName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_TIER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderTier}
            label={LocalizedString.DMSUserSubmissionPage.placeholderTier}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_REGISTRATION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderRegistrationID}
            label={LocalizedString.DMSUserSubmissionPage.placeholderRegistrationID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_VIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderVin}
            label={LocalizedString.DMSUserSubmissionPage.placeholderVin}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_MODEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderModel}
            label={LocalizedString.DMSUserSubmissionPage.placeholderModel}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderSubmissionStatus}
            label={LocalizedString.DMSUserSubmissionPage.placeholderSubmissionStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderSubmissionStatusNotes}
            label={LocalizedString.DMSUserSubmissionPage.placeholderSubmissionStatusNotes}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_SUBMITTED_ON}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderSubmittedOn}
            label={LocalizedString.DMSUserSubmissionPage.placeholderSubmittedOn}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_DELAY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderSubmissionDelaySecond}
            label={LocalizedString.DMSUserSubmissionPage.placeholderSubmissionDelaySecond}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DMS_USER_SUBMISSION_RETRY_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.DMSUserSubmissionPage.placeholderRetryCount}
            label={LocalizedString.DMSUserSubmissionPage.placeholderRetryCount}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);


const DMSUserSubmissionPage = ({
  initialValues, users,
  downloading, downloadingDeleting,
  loadingUser, handleSubmit, onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onChangePage, onChangePageSize, onChangeUserText,
  onRefresh, onRetryPressed, tappedId, onConfirmRetryPressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged,
  onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_DMS_USER_SUBMISSION}
    uiPage={RXSTATE_DMS_USER_SUBMISSION_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.DMSUserSubmissionPage.buttonCaptionRetry,
        disabled: false,
        onPress: () => onRetryPressed(tappedId),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.DMSUserSubmissionPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.DMSUserSubmissionPage.labelSubmittedOn,
        field: 'submittedOn',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.DMSUserSubmissionPage.labelUser,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.DMSUserSubmissionPage.labelVin,
        field: 'vin',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.DMSUserSubmissionPage.labelSubmissionStatus, field: 'submissionStatus', type: FILTER_TYPE_DROPDOWN, data: SUBMISSION_STATUS,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.DMSUserSubmissionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.DMSUserSubmissionPage.labelUser, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.DMSUserSubmissionPage.labelVehicleModel, field: 'registration.vehicle.model', sorting: !downloading },
      { title: LocalizedString.DMSUserSubmissionPage.labelVin, field: 'registration.vehicle.vin', sorting: !downloading },
      { title: LocalizedString.DMSUserSubmissionPage.labelSubmissionStatus, field: 'submissionStatus', sorting: !downloading },
      { title: LocalizedString.DMSUserSubmissionPage.labelSubmissionDelay, field: 'submissionDelaySecond', sorting: !downloading },
      { title: LocalizedString.DMSUserSubmissionPage.labelRetryCount, field: 'retryCount', sorting: !downloading },
      { title: LocalizedString.DMSUserSubmissionPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    disableCreate
    disableDelete
    disableEdit
    title={LocalizedString.DMSUserSubmissionPage.title}
    useFullWidth
    usefullWidthDialog
    onConfirmDeletePressed={onConfirmRetryPressed}

  >
    {renderDialogContent(
      initialValues, downloadingDeleting,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_DMS_USER_SUBMISSION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DMSUserSubmissionPage);

DMSUserSubmissionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onRetryPressed: PropTypes.func.isRequired,
  tappedId: PropTypes.string.isRequired,
  onConfirmRetryPressed: PropTypes.func.isRequired,
};
