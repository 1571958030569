import { DOWNLOADING_COUPON_ACHIEVEMENT, DOWNLOADING_COUPON_BALANCE, SET_COUPON_ACHIEVEMENT_TAPPED_ID } from '../action';

const initialState = {
  downloadingCouponAchievement: false,
  downloadingCouponBalance: false,
  tappedId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_COUPON_ACHIEVEMENT:
      return { ...state, downloadingCouponAchievement: action.status };
    case DOWNLOADING_COUPON_BALANCE:
      return { ...state, downloadingCouponBalance: action.status };
    case SET_COUPON_ACHIEVEMENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    default: return state;
  }
};
