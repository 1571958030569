import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES, MENUID_JAW_2023_SURVEY, PAGE_MODE_TABLE } from '../../constant';
import { transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  clearJaw2023Surveys, downloadDeleteJaw2023SurveyAsync,
  downloadJaw2023SurveysAsync, saveJaw2023SurveysAsync,
  setAlertErrorMessage, setJaw2023SurveysOrderBy, setJaw2023SurveysPageSize,
  setJaw2023SurveysSearchText, setJaw2023SurveysFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setJaw2023SurveysTappedId, setActiveSideMenuItem,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import Jaw2023Survey from './jaw-2023-survey.presentation';

const getInitialValues = (state) => {
  const {
    jaw2023Surveys, uiFunctionalPage, uiJaw2023Survey,
  } = state;
  const { data } = jaw2023Surveys;
  const { downloadingSurvey, tappedId } = uiJaw2023Survey;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingSurvey
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    user: found.user
      ? { fullName: found.user.fullName, id: found.user.id }
      : { fullName: '', id: '' },
  };

  return result;
};

const mapStateToProps = (state) => ({
  surveys: state.jaw2023Surveys.data,
  downloading: state.uiJaw2023Survey.downloading,
  downloadingSurvey: state.uiJaw2023Survey.downloadingSurvey,
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_JAW_2023_SURVEY));
    dispatch(setJaw2023SurveysSearchText(''));
    dispatch(setJaw2023SurveysFilterString(''));
    dispatch(clearJaw2023Surveys());
    dispatch(setJaw2023SurveysPageSize(20));
    dispatch(setJaw2023SurveysOrderBy(INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES));

    dispatch(downloadJaw2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setJaw2023SurveysPageSize(pageSize));
    dispatch(clearJaw2023Surveys());

    dispatch(downloadJaw2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setJaw2023SurveysOrderBy(orderBy));
    dispatch(clearJaw2023Surveys());
    dispatch(downloadJaw2023SurveysAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadJaw2023SurveysAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setJaw2023SurveysPageSize(pageSize));
    dispatch(downloadJaw2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setJaw2023SurveysSearchText(text));
      dispatch(clearJaw2023Surveys());
      await dispatch(downloadJaw2023SurveysAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/scanCodeUsername/, 'user.id');

    dispatch(setJaw2023SurveysFilterString(text));
    dispatch(clearJaw2023Surveys());
    dispatch(downloadJaw2023SurveysAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setJaw2023SurveysFilterString(''));
    dispatch(clearJaw2023Surveys());
    dispatch(setJaw2023SurveysOrderBy(INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES));
    dispatch(downloadJaw2023SurveysAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setJaw2023SurveysTappedId(id));
    dispatch(downloadDeleteJaw2023SurveyAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDownloadPressed: () => {
    dispatch(saveJaw2023SurveysAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Jaw2023Survey);
