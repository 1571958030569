import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import reducer from './redux/reducer';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PrivateRoute, NavigationPrompt } from './component';
import {
  ROUTE_NAME_LOGIN, ROUTE_NAME_WELCOME,
  ROUTE_NAME_SUMMARY, ROUTE_NAME_SERVICE, ROUTE_NAME_SCOPE, ROUTE_NAME_API_KEY, ROUTE_NAME_TOKEN,
  ROUTE_NAME_PERMISSION, ROUTE_NAME_ROLE, ROUTE_NAME_USER,
  ROUTE_NAME_APPLICATION, ROUTE_NAME_BANNER, ROUTE_NAME_PROFILE,
  ROUTE_NAME_PROVINCE, ROUTE_NAME_CITY, ROUTE_NAME_COMPANY, ROUTE_NAME_BRANCH, ROUTE_NAME_DISTRICT,
  ROUTE_NAME_SUBDISTRICT,
  ROUTE_NAME_REPORT,
  ROUTE_NAME_DEVICE_EXCEPTION,
  ROUTE_NAME_AUTH_REGISTRATION_ATTEMPT,
  ROUTE_NAME_AUTH_PASSWORD_RESET_ATTEMPT,
} from './constant';
import {
  SplashPage,
  TvScreen,
  LoginPage,
  WelcomePage,
  SummaryPage, ServicePage, ScopePage, ApiKeyPage, TokenPage, PermissionPage, RolePage, UserPage,
  ApplicationPage, BannerPage, ProfilePage,
  ProvincePage, CityPage, CompanyPage, BranchPage, DistrictPage, SubdistrictPage,
  ReportPage, DeviceExceptionPage, AuthRegistrationAttemptPage, AuthPasswordResetAttemptPage,
} from './page';
import {
  ConfigActionPage, ConfigGroupPage, ConfigItemPage, ConfigValuePage,
  ROUTE_NAME_CONFIG_ACTION, ROUTE_NAME_CONFIG_GROUP, ROUTE_NAME_CONFIG_ITEM,
  ROUTE_NAME_CONFIG_VALUE,
} from './module/configuration';
import {
  AdditionalFieldPage, AttendanceSummaryPage, HistoryPage,
  ROUTE_NAME_ADDITIONAL_FIELD, ROUTE_NAME_ATTENDANCE_SUMMARY, ROUTE_NAME_HISTORY,
} from './module/attendance';
import {
  FaqPage, FaqCategoryPage, ROUTE_NAME_FAQ, ROUTE_NAME_FAQ_CATEGORY,
} from './module/faq';
import {
  EmailPage, SmsPage, WhatsappPage, PushNotificationPage, NotificationTaskPage,
  ROUTE_NAME_EMAIL, ROUTE_NAME_SMS, ROUTE_NAME_WHATSAPP,
  ROUTE_NAME_PUSH_NOTIFICATION, ROUTE_NAME_NOTIFICATION_TASK,
} from './module/notification';
import {
  PacketPage, PacketTypePage, PickupLocationPage, PacketSummaryPage,
  ROUTE_NAME_PACKET, ROUTE_NAME_PACKET_TYPE, ROUTE_NAME_PICKUP_LOCATION, ROUTE_NAME_PACKET_SUMMARY,
} from './module/packet';
import {
  ActivityPage, ActivityTypePage, CustomerPage, IndustryPage,
  ROUTE_NAME_ACTIVITY, ROUTE_NAME_ACTIVITY_TYPE, ROUTE_NAME_CUSTOMER, ROUTE_NAME_INDUSTRY,
} from './module/activity';
import {
  CompanyMappingPage, InfoMappingPage, ROUTE_NAME_COMPANY_MAPPING, ROUTE_NAME_INFO_MAPPING,
} from './module/bcard';
import {
  ShortlinkPage, ROUTE_NAME_SHORTLINK_SCREEN,
} from './module/shortlink';
import {
  AuditTrailPage, ROUTE_NAME_AUDIT_TRAIL,
} from './module/audit';
import {
  PointAchievementPage, PointPage, PointSubmissionPage, PointTransactionPage,
  PointPurchaseRequestPage, PointVoidRequestPage, PointLeaderBoardPage,
  ROUTE_NAME_POINT_POINT, ROUTE_NAME_POINT_ACHIEVEMENT,
  ROUTE_NAME_POINT_POINT_SUBMISSION, ROUTE_NAME_POINT_POINT_TRANSACTION,
  ROUTE_NAME_POINT_PURCHASE_REQUEST, ROUTE_NAME_POINT_VOID_REQUEST, ROUTE_NAME_POINT_LEADER_BOARD,
} from './module/point';
import {
  TokenRequestPage, VoucherCategoryPage, VoucherPage, VoucherTokenPage, VoucherUsagePage,
  VoucherTokenImportPage, VoucherPurchaseRequestPage, PromotedMerchantPage,
  GarudaAirportPage, VoucherTokenAssignmentPage, GiftVoucherRedemptionPage,
  ROUTE_NAME_VOUCHER_CATEGORY, ROUTE_NAME_VOUCHER_TOKEN, ROUTE_NAME_VOUCHER_TOKEN_REQUEST,
  ROUTE_NAME_VOUCHER_VOUCHER, ROUTE_NAME_VOUCHER_TOKEN_IMPORT, ROUTE_NAME_VOUCHER_PURCHASE_REQUEST,
  ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT, ROUTE_NAME_VOUCHER_GARUDA_AIRPORT, ROUTE_NAME_VOUCHER_USAGE,
  ROUTE_NAME_VOUCHER_TOKEN_ASSIGNMENT, ROUTE_NAME_VOUCHER_GIFT_VOUCHER_REDEMPTION,
} from './module/voucher';
import {
  DiscussionCommentPage, ReportedCommentPage,
  ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT, ROUTE_NAME_DISCUSSION_REPORTED_COMMENT,
} from './module/discussion';
import {
  ForumCategoryPage, ForumPostPage, ROUTE_NAME_FORUM_CATEGORY, ROUTE_NAME_FORUM_POST,
} from './module/forum';
import {
  ChallengePage, GameQRCategoryPage, ROUTE_NAME_GAMEQR_CATEGORY, ROUTE_NAME_GAMEQR_CHALLENGE,
  ROUTE_NAME_GAMEQR_SCANNED_CODE, ScannedCodePage, ROUTE_NAME_GAMEQR_COMPLETED_CHALLENGE,
  CompletedChallangePage,
} from './module/game-qr';
import {
  CatalogPage, ModelPage, ROUTE_NAME_CATALOG, ROUTE_NAME_MODEL,
} from './module/car-catalog';
import {
  ROUTE_NAME_TEST_DRIVE_EVENT, ROUTE_NAME_TEST_DRIVE_RESERVATION,
  ROUTE_NAME_TEST_DRIVE_TEST_VEHICLE, ROUTE_NAME_TEST_DRIVE_VOUCHER_REWARD,
  ROUTE_NAME_TEST_DRIVE_UNIQUE_CODE, ROUTE_NAME_TEST_DRIVE_EVENT_ORGANIZER,
  EventPage, ReservationPage, TestVehiclePage, VoucherRewardPage, UniqueCodePage,
  EventOrganizerPage,
} from './module/test-drive';
import {
  ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST, ServiceRequestPage,
  ROUTE_NAME_CAR_SERVICE_RESERVATION, CarServiceReservationPage,
  ROUTE_NAME_CAR_SERVICE_DEALER, CarServiceDealerPage,
  ROUTE_NAME_CAR_SERVICE_CHARGING_STATION, CarServiceChargingStationPage,
  ROUTE_NAME_CAR_SERVICE_EV_CHARGING_URL, EvChargingUrlPage,
} from './module/car-service';
import {
  CarOwnershipPage, CarOwnershipRewardPage, MembershipRewardPage, RegistrationAttemptPage,
  RegistrationPage, DMSUserSubmissionPage,
  ROUTE_NAME_CAR_OWNERSHIP, ROUTE_NAME_CAR_OWNERSHIP_REWARD, ROUTE_NAME_MEMBERSHIP_REWARD,
  ROUTE_NAME_REGISTRATION, ROUTE_NAME_REGISTRATION_ATTEMPT, ROUTE_NAME_DMS_USER_SUBMISSION,
} from './module/car-registration';
import {
  NewsPage, NewsCategoryPage, ROUTE_NAME_NEWS, ROUTE_NAME_NEWS_CATEGORY,
} from './module/news';
import {
  PartnerMerchantPage, ROUTE_NAME_PARTNER_MERCHANT,
} from './module/partner';
import {
  CouponAchievementPage, CouponPage, CouponSubmissionPage, CouponTransactionPage,
  CouponPurchaseRequestPage, CouponVoidRequestPage, CouponLeaderBoardPage,
  ROUTE_NAME_COUPON_COUPON, ROUTE_NAME_COUPON_ACHIEVEMENT,
  ROUTE_NAME_COUPON_COUPON_SUBMISSION, ROUTE_NAME_COUPON_COUPON_TRANSACTION,
  ROUTE_NAME_COUPON_PURCHASE_REQUEST, ROUTE_NAME_COUPON_VOID_REQUEST,
  ROUTE_NAME_COUPON_LEADER_BOARD,
} from './module/coupon';
import {
  PartnerVoucherTokenPage, ROUTE_NAME_PARTNER_ADMIN_VOUCHER_TOKEN,
  VoucherClaimPage, ROUTE_NAME_PARTNER_ADMIN_VOUCHER_CLAIM,
} from './module/partner-admin';
import {
  ROUTE_NAME_PROMO_CODE_PROMO_CODE, ROUTE_NAME_PROMO_CODE_PROMO_CODE_BATCH,
  ROUTE_NAME_PROMO_CODE_REDEMPTION_HISTORY,
  PromoCodePage, PromoCodeBatchPage, RedemptionHistoryPage,
} from './module/promo-code';
import {
  ROUTE_NAME_CAR_VIEWING_EVENT, ROUTE_NAME_CAR_VIEWING_RESERVATION,
  ROUTE_NAME_CAR_VIEWING_VOUCHER_REWARD,
  CarViewingEventPage, CarViewingReservationPage, CarViewingVoucherRewardPage,
} from './module/car-viewing';
import {
  TestDriveEoEventPage, TestDriveEoTestVehiclePage, TestDriveEoReservationPage,
  ROUTE_NAME_TEST_DRIVE_EO_EVENT, ROUTE_NAME_TEST_DRIVE_EO_TEST_VEHICLE,
  ROUTE_NAME_TEST_DRIVE_EO_RESERVATION,
} from './module/test-drive-eo';
import {
  Giias2022PrizeRedemptionPage, GiiasScannedCodePage, Giias2022TransactionPage,
  ROUTE_NAME_GIIAS_SCANNED_CODE, ROUTE_NAME_GIIAS_2022_PRIZE_REDEMPTION,
  ROUTE_NAME_GIIAS_2022_TTRANSACTION,
} from './module/giias-2022';
import {
  GiiasSurabayaScannedCodePage, GiiasSurabayaTransactionPage,
  ROUTE_NAME_GIIAS_SURABAYA_SCANNED_CODE, ROUTE_NAME_GIIAS_SURABAYA_TRANSACTION,
  GiiasSurabayaPrizeRedemptionPage, ROUTE_NAME_GIIAS_SURABAYA_PRIZE_REDEMPTION,
} from './module/giias-surabaya-2022';
import {
  GiiasMedanPrizeRedemptionPage, GiiasMedanScannedCodePage, GiiasMedanTransactionPage,
  ROUTE_NAME_GIIAS_MEDAN_PRIZE_REDEMPTION, ROUTE_NAME_GIIAS_MEDAN_SCANNED_CODE,
  ROUTE_NAME_GIIAS_MEDAN_TRANSACTION,
} from './module/giias-medan-2022';
import {
  Iims2023PrizeRedemptionPage, Iims2023ScannedCodePage, Iims2023TransactionPage,
  ROUTE_NAME_IIMS_2023_PRIZE_REDEMPTION, ROUTE_NAME_IIMS_2023_SCANNED_CODE,
  ROUTE_NAME_IIMS_2023_TRANSACTION, Iims2023SurveyPage, ROUTE_NAME_IIMS_2023_SURVEY, ROUTE_NAME_IIMS_2023_SPK, Iims2023SpkPage,
} from './module/iims-2023';
import {
  Jaw2023PrizeRedemptionPage, Jaw2023ScannedCodePage, Jaw2023TransactionPage,
  ROUTE_NAME_JAW_2023_PRIZE_REDEMPTION, ROUTE_NAME_JAW_2023_SCANNED_CODE,
  ROUTE_NAME_JAW_2023_TRANSACTION, Jaw2023SurveyPage, ROUTE_NAME_JAW_2023_SURVEY, ROUTE_NAME_JAW_2023_SPK, Jaw2023SpkPage,
} from './module/jaw-2023';
import {
  GiiasSemarangPrizeRedemptionPage, GiiasSemarangScannedCodePage, GiiasSemarangTransactionPage,
  ROUTE_NAME_GIIAS_SEMARANG_PRIZE_REDEMPTION, ROUTE_NAME_GIIAS_SEMARANG_SCANNED_CODE,
  ROUTE_NAME_GIIAS_SEMARANG_TRANSACTION,
} from './module/giias-semarang-2022';
import {
  ROUTE_NAME_WASTE_COLLECTION, WasteCollectionPage,
  ROUTE_NAME_WASTE_DEALER_CODE, WasteDealerCodePage,
} from './module/e-waste';
import {
  AccessoryPage,
  InvoicePage,
  AccessoryTransactionPage,
  ROUTE_NAME_GENUINE_ACCESSORY_ACCESSORY,
  ROUTE_NAME_GENUINE_ACCESSORY_INVOICE,
  ROUTE_NAME_GENUINE_ACCESSORY_TRANSACTION,
} from './module/genuine-accessory';
import {
  GameActivityRegistrationReferralPage, ROUTE_NAME_GAME_ACTIVITY_REGISTRATION_REFERRAL,
  RecurrenceReferralRewardPage, ROUTE_NAME_RECURRANCE_REFERRAL_REWARD,
  ROUTE_NAME_NEW_USER_NOTIFICATION,
  DailyCheckinPage, ROUTE_NAME_DAILY_CHECKIN,
  CompletedProfilePage, ROUTE_NAME_COMPLETED_PROFILE,
  NewUserNotificationPage,
} from './module/game-activity';
import {
  ServicePromoPage, ROUTE_NAME_DASHBOARD_SERVICE_PROMO,
} from './module/dashboard';
import {
  HyundaiChallengePrizeRedemptionPage, ROUTE_NAME_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION,
  HyundaiChallengeScannedCodePage, ROUTE_NAME_HYUNDAI_CHALLENGE_SCANNED_CODE,
  HyundaiChallengeTransactionPage, ROUTE_NAME_HYUNDAI_CHALLENGE_TRANSACTION,
} from './module/hyundai-challenge';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'currentUser'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const IS_PRODUCTION = true;
const store = IS_PRODUCTION
  ? createStore(persistedReducer, applyMiddleware(thunk))
  : createStore(persistedReducer, applyMiddleware(thunk, logger));
const persistor = persistStore(store);

moment.tz.setDefault('UTC');

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <NavigationPrompt />
        <div>
          <Switch>
            <PrivateRoute path={ROUTE_NAME_WELCOME} component={WelcomePage} />
            <PrivateRoute path={ROUTE_NAME_REPORT} component={ReportPage} />
            <PrivateRoute path={ROUTE_NAME_PROFILE} component={ProfilePage} />
            <PrivateRoute path={ROUTE_NAME_PROVINCE} component={ProvincePage} />
            <PrivateRoute path={ROUTE_NAME_CITY} component={CityPage} />
            <PrivateRoute path={ROUTE_NAME_DISTRICT} component={DistrictPage} />
            <PrivateRoute path={ROUTE_NAME_SUBDISTRICT} component={SubdistrictPage} />
            <PrivateRoute path={ROUTE_NAME_COMPANY} component={CompanyPage} />
            <PrivateRoute path={ROUTE_NAME_BRANCH} component={BranchPage} />
            <PrivateRoute path={ROUTE_NAME_APPLICATION} component={ApplicationPage} />
            <PrivateRoute path={ROUTE_NAME_SUMMARY} component={SummaryPage} />
            <PrivateRoute path={ROUTE_NAME_SERVICE} component={ServicePage} />
            <PrivateRoute path={ROUTE_NAME_API_KEY} component={ApiKeyPage} />
            <PrivateRoute path={ROUTE_NAME_TOKEN} component={TokenPage} />
            <PrivateRoute path={ROUTE_NAME_PERMISSION} component={PermissionPage} />
            <PrivateRoute path={ROUTE_NAME_ROLE} component={RolePage} />
            <PrivateRoute path={ROUTE_NAME_USER} component={UserPage} />
            <PrivateRoute path={ROUTE_NAME_DEVICE_EXCEPTION} component={DeviceExceptionPage} />
            <PrivateRoute path={ROUTE_NAME_SERVICE} component={ServicePage} />
            <PrivateRoute path={ROUTE_NAME_SCOPE} component={ScopePage} />
            <PrivateRoute path={ROUTE_NAME_NEWS} component={NewsPage} />
            <PrivateRoute path={ROUTE_NAME_NEWS_CATEGORY} component={NewsCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_GROUP} component={ConfigGroupPage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_ITEM} component={ConfigItemPage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_VALUE} component={ConfigValuePage} />
            <PrivateRoute path={ROUTE_NAME_CONFIG_ACTION} component={ConfigActionPage} />
            <PrivateRoute path={ROUTE_NAME_ADDITIONAL_FIELD} component={AdditionalFieldPage} />
            <PrivateRoute path={ROUTE_NAME_ATTENDANCE_SUMMARY} component={AttendanceSummaryPage} />
            <PrivateRoute path={ROUTE_NAME_HISTORY} component={HistoryPage} />
            <PrivateRoute path={ROUTE_NAME_NOTIFICATION_TASK} component={NotificationTaskPage} />
            <PrivateRoute path={ROUTE_NAME_EMAIL} component={EmailPage} />
            <PrivateRoute path={ROUTE_NAME_SMS} component={SmsPage} />
            <PrivateRoute path={ROUTE_NAME_WHATSAPP} component={WhatsappPage} />
            <PrivateRoute path={ROUTE_NAME_PUSH_NOTIFICATION} component={PushNotificationPage} />
            <PrivateRoute path={ROUTE_NAME_FAQ} component={FaqPage} />
            <PrivateRoute path={ROUTE_NAME_FAQ_CATEGORY} component={FaqCategoryPage} />
            <PrivateRoute
              path={ROUTE_NAME_PARTNER_ADMIN_VOUCHER_TOKEN}
              component={PartnerVoucherTokenPage}
            />
            <PrivateRoute path={ROUTE_NAME_BANNER} component={BannerPage} />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_CATEGORY} component={VoucherCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_CATALOG} component={CatalogPage} />
            <PrivateRoute path={ROUTE_NAME_MODEL} component={ModelPage} />
            <PrivateRoute path={ROUTE_NAME_AUDIT_TRAIL} component={AuditTrailPage} />
            <PrivateRoute path={ROUTE_NAME_SHORTLINK_SCREEN} component={ShortlinkPage} />
            <PrivateRoute path={ROUTE_NAME_PICKUP_LOCATION} component={PickupLocationPage} />
            <PrivateRoute path={ROUTE_NAME_PACKET_TYPE} component={PacketTypePage} />
            <PrivateRoute path={ROUTE_NAME_PACKET} component={PacketPage} />
            <PrivateRoute path={ROUTE_NAME_PACKET_SUMMARY} component={PacketSummaryPage} />
            <PrivateRoute path={ROUTE_NAME_CUSTOMER} component={CustomerPage} />
            <PrivateRoute path={ROUTE_NAME_INDUSTRY} component={IndustryPage} />
            <PrivateRoute path={ROUTE_NAME_ACTIVITY} component={ActivityPage} />
            <PrivateRoute path={ROUTE_NAME_ACTIVITY_TYPE} component={ActivityTypePage} />
            <PrivateRoute path={ROUTE_NAME_COMPANY_MAPPING} component={CompanyMappingPage} />
            <PrivateRoute path={ROUTE_NAME_INFO_MAPPING} component={InfoMappingPage} />
            <PrivateRoute path={ROUTE_NAME_TEST_DRIVE_EVENT} component={EventPage} />
            <PrivateRoute path={ROUTE_NAME_TEST_DRIVE_TEST_VEHICLE} component={TestVehiclePage} />
            <PrivateRoute
              path={ROUTE_NAME_TEST_DRIVE_EVENT_ORGANIZER}
              component={EventOrganizerPage}
            />
            <PrivateRoute path={ROUTE_NAME_TEST_DRIVE_RESERVATION} component={ReservationPage} />
            <PrivateRoute path={ROUTE_NAME_TEST_DRIVE_UNIQUE_CODE} component={UniqueCodePage} />
            <PrivateRoute
              path={ROUTE_NAME_TEST_DRIVE_VOUCHER_REWARD}
              component={VoucherRewardPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_CAR_SERVICE_DEALER}
              component={CarServiceDealerPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_CAR_SERVICE_CHARGING_STATION}
              component={CarServiceChargingStationPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST}
              component={ServiceRequestPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_CAR_SERVICE_RESERVATION}
              component={CarServiceReservationPage}
            />
            <PrivateRoute path={ROUTE_NAME_FORUM_CATEGORY} component={ForumCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_FORUM_POST} component={ForumPostPage} />
            <PrivateRoute path={ROUTE_NAME_GAMEQR_CATEGORY} component={GameQRCategoryPage} />
            <PrivateRoute path={ROUTE_NAME_GAMEQR_CHALLENGE} component={ChallengePage} />
            <PrivateRoute
              path={ROUTE_NAME_GAMEQR_COMPLETED_CHALLENGE}
              component={CompletedChallangePage}
            />
            <PrivateRoute path={ROUTE_NAME_GAMEQR_SCANNED_CODE} component={ScannedCodePage} />
            <PrivateRoute path={ROUTE_NAME_POINT_POINT} component={PointPage} />
            <PrivateRoute
              path={ROUTE_NAME_POINT_PURCHASE_REQUEST}
              component={PointPurchaseRequestPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_POINT_POINT_SUBMISSION}
              component={PointSubmissionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_POINT_POINT_TRANSACTION}
              component={PointTransactionPage}
            />
            <PrivateRoute path={ROUTE_NAME_POINT_ACHIEVEMENT} component={PointAchievementPage} />
            <PrivateRoute path={ROUTE_NAME_POINT_LEADER_BOARD} component={PointLeaderBoardPage} />
            <PrivateRoute path={ROUTE_NAME_POINT_VOID_REQUEST} component={PointVoidRequestPage} />
            <PrivateRoute path={ROUTE_NAME_COUPON_COUPON} component={CouponPage} />
            <PrivateRoute
              path={ROUTE_NAME_COUPON_PURCHASE_REQUEST}
              component={CouponPurchaseRequestPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_COUPON_COUPON_SUBMISSION}
              component={CouponSubmissionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_COUPON_COUPON_TRANSACTION}
              component={CouponTransactionPage}
            />
            <PrivateRoute path={ROUTE_NAME_COUPON_ACHIEVEMENT} component={CouponAchievementPage} />
            <PrivateRoute path={ROUTE_NAME_COUPON_LEADER_BOARD} component={CouponLeaderBoardPage} />
            <PrivateRoute path={ROUTE_NAME_COUPON_VOID_REQUEST} component={CouponVoidRequestPage} />
            <PrivateRoute
              path={ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT}
              component={DiscussionCommentPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_DISCUSSION_REPORTED_COMMENT}
              component={ReportedCommentPage}
            />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_VOUCHER} component={VoucherPage} />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_TOKEN_REQUEST} component={TokenRequestPage} />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_TOKEN} component={VoucherTokenPage} />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_TOKEN_IMPORT}
              component={VoucherTokenImportPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_PURCHASE_REQUEST}
              component={VoucherPurchaseRequestPage}
            />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_GARUDA_AIRPORT} component={GarudaAirportPage} />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT}
              component={PromotedMerchantPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_TOKEN_ASSIGNMENT}
              component={VoucherTokenAssignmentPage}
            />
            <PrivateRoute path={ROUTE_NAME_PARTNER_MERCHANT} component={PartnerMerchantPage} />
            <PrivateRoute
              path={ROUTE_NAME_PARTNER_ADMIN_VOUCHER_CLAIM}
              component={VoucherClaimPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_REGISTRATION_ATTEMPT}
              component={RegistrationAttemptPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_CAR_OWNERSHIP_REWARD}
              component={CarOwnershipRewardPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_DMS_USER_SUBMISSION}
              component={DMSUserSubmissionPage}
            />
            <PrivateRoute path={ROUTE_NAME_MEMBERSHIP_REWARD} component={MembershipRewardPage} />
            <PrivateRoute path={ROUTE_NAME_CAR_OWNERSHIP} component={CarOwnershipPage} />
            <PrivateRoute
              path={ROUTE_NAME_VOUCHER_GIFT_VOUCHER_REDEMPTION}
              component={GiftVoucherRedemptionPage}
            />
            <PrivateRoute path={ROUTE_NAME_VOUCHER_USAGE} component={VoucherUsagePage} />
            <PrivateRoute path={ROUTE_NAME_PROMO_CODE_PROMO_CODE} component={PromoCodePage} />
            <PrivateRoute
              path={ROUTE_NAME_PROMO_CODE_PROMO_CODE_BATCH}
              component={PromoCodeBatchPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_PROMO_CODE_REDEMPTION_HISTORY}
              component={RedemptionHistoryPage}
            />
            <PrivateRoute path={ROUTE_NAME_CAR_VIEWING_EVENT} component={CarViewingEventPage} />
            <PrivateRoute
              path={ROUTE_NAME_CAR_VIEWING_RESERVATION}
              component={CarViewingReservationPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_CAR_VIEWING_VOUCHER_REWARD}
              component={CarViewingVoucherRewardPage}
            />
            <PrivateRoute path={ROUTE_NAME_TEST_DRIVE_EO_EVENT} component={TestDriveEoEventPage} />
            <PrivateRoute
              path={ROUTE_NAME_TEST_DRIVE_EO_TEST_VEHICLE}
              component={TestDriveEoTestVehiclePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_TEST_DRIVE_EO_RESERVATION}
              component={TestDriveEoReservationPage}
            />
            <PrivateRoute path={ROUTE_NAME_GIIAS_SCANNED_CODE} component={GiiasScannedCodePage} />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_2022_PRIZE_REDEMPTION}
              component={Giias2022PrizeRedemptionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_2022_TTRANSACTION}
              component={Giias2022TransactionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_SURABAYA_SCANNED_CODE}
              component={GiiasSurabayaScannedCodePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_SURABAYA_TRANSACTION}
              component={GiiasSurabayaTransactionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_SURABAYA_PRIZE_REDEMPTION}
              component={GiiasSurabayaPrizeRedemptionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_MEDAN_SCANNED_CODE}
              component={GiiasMedanScannedCodePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_MEDAN_PRIZE_REDEMPTION}
              component={GiiasMedanPrizeRedemptionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_MEDAN_TRANSACTION}
              component={GiiasMedanTransactionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_IIMS_2023_SCANNED_CODE}
              component={Iims2023ScannedCodePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_IIMS_2023_SURVEY}
              component={Iims2023SurveyPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_IIMS_2023_SPK}
              component={Iims2023SpkPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_IIMS_2023_PRIZE_REDEMPTION}
              component={Iims2023PrizeRedemptionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_IIMS_2023_TRANSACTION}
              component={Iims2023TransactionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_JAW_2023_SCANNED_CODE}
              component={Jaw2023ScannedCodePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_JAW_2023_SURVEY}
              component={Jaw2023SurveyPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_JAW_2023_SPK}
              component={Jaw2023SpkPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_JAW_2023_PRIZE_REDEMPTION}
              component={Jaw2023PrizeRedemptionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_JAW_2023_TRANSACTION}
              component={Jaw2023TransactionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_SEMARANG_SCANNED_CODE}
              component={GiiasSemarangScannedCodePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_SEMARANG_PRIZE_REDEMPTION}
              component={GiiasSemarangPrizeRedemptionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GIIAS_SEMARANG_TRANSACTION}
              component={GiiasSemarangTransactionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_WASTE_COLLECTION}
              component={WasteCollectionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_WASTE_DEALER_CODE}
              component={WasteDealerCodePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GENUINE_ACCESSORY_ACCESSORY}
              component={AccessoryPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GENUINE_ACCESSORY_INVOICE}
              component={InvoicePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GENUINE_ACCESSORY_TRANSACTION}
              component={AccessoryTransactionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_GAME_ACTIVITY_REGISTRATION_REFERRAL}
              component={GameActivityRegistrationReferralPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_RECURRANCE_REFERRAL_REWARD}
              component={RecurrenceReferralRewardPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_DAILY_CHECKIN}
              component={DailyCheckinPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_DASHBOARD_SERVICE_PROMO}
              component={ServicePromoPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_COMPLETED_PROFILE}
              component={CompletedProfilePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_AUTH_REGISTRATION_ATTEMPT}
              component={AuthRegistrationAttemptPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_AUTH_PASSWORD_RESET_ATTEMPT}
              component={AuthPasswordResetAttemptPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_NEW_USER_NOTIFICATION}
              component={NewUserNotificationPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_CAR_SERVICE_EV_CHARGING_URL}
              component={EvChargingUrlPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_HYUNDAI_CHALLENGE_SCANNED_CODE}
              component={HyundaiChallengeScannedCodePage}
            />
            <PrivateRoute
              path={ROUTE_NAME_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION}
              component={HyundaiChallengePrizeRedemptionPage}
            />
            <PrivateRoute
              path={ROUTE_NAME_HYUNDAI_CHALLENGE_TRANSACTION}
              component={HyundaiChallengeTransactionPage}
            />
            {/* all routes except these 3 routes below must be PrivateRoute */}
            <Route path={ROUTE_NAME_LOGIN} component={LoginPage} />
            <Route path={ROUTE_NAME_REGISTRATION} component={RegistrationPage} />
            <Route path="/tv" component={TvScreen} />
            <Route path="/" component={SplashPage} />
          </Switch>
        </div>
      </Router>
    </PersistGate>
  </Provider>
);
