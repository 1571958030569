import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import moment from 'moment';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_COUPON_SUBMISSIONS,
  MENUID_COUPON_COUPON_SUBMISSION, PAGE_MODE_TABLE, RXFORM_COUPON_SUBMISSION,
  RXFIELD_COUPON_SUBMISSION_USER, RXFIELD_COUPON_SUBMISSION_SEND_NOTIFICATION,
  REVERSED_ISO_FULL_DATE_TIME_FORMAT,
} from '../../constant';
import { transformInitialValues, transformUserDropdownData, toMoment } from '../../helper';
import {
  clearCouponSubmissions, clearUsers, setAdvancedFilterDialogSelectedFilterString,
  setActiveSideMenuItem, setAlertErrorMessage, setCouponSubmissionSearchText,
  setCouponSubmissionSelectedPageSize, setCouponSubmissionSelectedOrderBy,
  setCouponSubmissionTappedId, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadCouponSubmissionAsync, downloadCouponSubmissionsAsync, reprocessCouponSubmissionAsync,
  setAlertConfirmationMessage, addCouponSubmissionAsync, setCouponSubmissionSelectedUser,
  setCouponSubmissionSelectedSendNotification,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import CouponSubmissionPage from './coupon-submission.presentation';
import localizedString from '../../localization';

const getInitialValues = (state) => {
  const {
    couponSubmissions, uiFunctionalPage, uiCouponSubmission,
  } = state;
  const { data } = couponSubmissions;
  const { downloadingDeleting, tappedId } = uiCouponSubmission;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user.fullName,
    validUntil: toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    label: '',
    submissionStatus: '',
    submissionStatusNotes: null,
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    callbackUrl: '',
    fullName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  addingEditing: state.uiCouponSubmission.addingEditing,
  downloading: state.uiCouponSubmission.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
  reprocessing: state.uiCouponSubmission.reprocessing,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_COUPON_COUPON_SUBMISSION));
    dispatch(setCouponSubmissionSearchText(''));
    dispatch(clearCouponSubmissions());
    dispatch(setCouponSubmissionSelectedPageSize(20));
    dispatch(setCouponSubmissionSelectedOrderBy(INITIAL_ORDER_BY_COUPON_SUBMISSIONS));
    dispatch(downloadCouponSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCouponSubmissions());
    dispatch(downloadCouponSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COUPON_SUBMISSION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCouponSubmissionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCouponSubmissionSelectedPageSize(pageSize));
    dispatch(downloadCouponSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCreatePressed: () => {
    dispatch(setCouponSubmissionTappedId(''));
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setCouponSubmissionSelectedPageSize(pageSize));
    dispatch(clearCouponSubmissions());
    dispatch(downloadCouponSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCouponSubmissions());
    dispatch(setCouponSubmissionSelectedOrderBy(INITIAL_ORDER_BY_COUPON_SUBMISSIONS));
    dispatch(downloadCouponSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCouponSubmissionSearchText(text));
      dispatch(clearCouponSubmissions());
      await dispatch(downloadCouponSubmissionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSubmitPressed: async ({
    merchantReferenceId, tags, amount, validUntil, label,
    callbackUrl,
  }) => {
    const validDate = moment(validUntil).format(REVERSED_ISO_FULL_DATE_TIME_FORMAT);
    await dispatch(addCouponSubmissionAsync(merchantReferenceId, tags, amount, validDate, label,
      callbackUrl));
  },
  onSortPressed: (orderBy) => {
    dispatch(setCouponSubmissionSelectedOrderBy(orderBy));
    dispatch(clearCouponSubmissions());
    dispatch(downloadCouponSubmissionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSendNotificationSelected: (option) => {
    if (option) {
      dispatch(setCouponSubmissionSelectedSendNotification(option));
      dispatch(change(RXFORM_COUPON_SUBMISSION,
        RXFIELD_COUPON_SUBMISSION_SEND_NOTIFICATION, option));
    } else {
      dispatch(setCouponSubmissionSelectedSendNotification(''));
      dispatch(change(RXFORM_COUPON_SUBMISSION,
        RXFIELD_COUPON_SUBMISSION_SEND_NOTIFICATION, ''));
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setCouponSubmissionSelectedUser(option));
      dispatch(change(RXFORM_COUPON_SUBMISSION, RXFIELD_COUPON_SUBMISSION_USER, option));
    } else {
      dispatch(setCouponSubmissionSelectedUser(''));
      dispatch(change(RXFORM_COUPON_SUBMISSION, RXFIELD_COUPON_SUBMISSION_USER, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setCouponSubmissionTappedId(id));
    dispatch(downloadCouponSubmissionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRetryPressed: (id) => {
    dispatch(setCouponSubmissionTappedId(id));
    dispatch(setAlertConfirmationMessage(localizedString.couponSubmissionPage
      .msgRetryConfirmation));
  },
  onConfirmRetryPressed: () => {
    dispatch(reprocessCouponSubmissionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponSubmissionPage);
