import { downloadGiiasSurabayaScannedCodes, transformSearchText } from '../../../helper';
import { downloadingGiiasSurabayaScannedCodes, setGiiasSurabayaScannedCodes } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, giiasSurabayaScannedCodes, uiGiiasSurabayaScannedCode } = getState();

  const { token } = authentication;
  const { meta } = giiasSurabayaScannedCodes;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGiiasSurabayaScannedCode;

  try {
    dispatch(downloadingGiiasSurabayaScannedCodes(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const scannedCodes = await downloadGiiasSurabayaScannedCodes(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    dispatch(setGiiasSurabayaScannedCodes(scannedCodes));
  } finally {
    dispatch(downloadingGiiasSurabayaScannedCodes(false));
  }
};
