import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { ref, update } from 'firebase/database';
import {
  transformInitialValues, toMoment,
  transformUserDropdownData, transformDropdownData, sortAsc,
} from '../../helper';
import {
  downloadTestDriveEoReservationsAsync, setAlertErrorMessage,
  setTestDriveEoReservationSelectedOrderBy, clearTestDriveEoReservations,
  setTestDriveEoReservationTappedId, setTestDriveEoReservationSearchText,
  setTestDriveEoReservationSelectedPageSize, setTestDriveEoReservationAdvancedFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setTestDriveEoEventAdvancedFilterDialogSelectedFilterString,
  setTestDriveEoEventSearchText,
  clearTestDriveEoEvents,
  downloadTestDriveEoEventsAsync,
  clearSalesConsultants,
  downloadSalesConsultantsAsync,
  setSalesConsultantSearchString,
  setNotificationSelectedSalesConsultant,
  editSalesConsultantsAsync,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE, DATE_TIME_FORMAT_WITHOUT_PIPE, RESERVATION_TYPE_EVENT,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, INITIAL_ORDER_BY_TEST_DRIVE_EO_RESERVATIONS,
  SIMPLE_DATE_FORMAT,
} from '../../constant';
import { downloadUsersAsync } from '../../../../redux/action';
import GlobalLocalizedString from '../../../../localization';
import ReservationPage from './reservation.presentation';
import {
  downloadDealersAsync, setDealerAdvancedFilterDialogSelectedFilterString,
  clearDealers, setDealerSearchText, addNotificationDialogFilterForm,
} from '../../../car-service/redux/action';
import { approveReservationAsync } from '../../../car-registration/redux/action';
import { db } from '../../../../page/tv/firebase';


const getInitialValues = (state) => {
  const {
    testDriveEoReservations, uiFunctionalPage, uiTestDriveEoReservation, salesConsultants,
  } = state;
  const { data } = testDriveEoReservations;
  const { downloadingDeleting, tappedId, downloadingSalesConsultant } = uiTestDriveEoReservation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    eventId: found.event?.id,
    eventName: found.event?.name,
    eventLocation: found.event?.location,
    dealerCode: found.dealer?.dealerCode,
    dealerName: found.dealer?.name,
    dealerCity: found.dealer?.city,
    wantToReceiveNewsUpdate: found.wantToReceiveNewsUpdate
      ? GlobalLocalizedString.common.labelYes
      : GlobalLocalizedString.common.labelNo,
    userId: found.profile?.id,
    userFullname: found.profile?.fullName,
    reservedDate: found.reservedDate
      ? toMoment(found.reservedDate).format(SIMPLE_DATE_FORMAT)
      : null,
    completedTime: found.completedTime
      ? toMoment(found.completedTime, !isEmpty(found.event)
        ? found.event.city.province.timeZone : null).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
      : null,
    simImage: found.simImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.simImage}` : null,
    testDriveImage: found.testDriveImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.testDriveImage}` : null,
    surveyImage: found.surveyImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.surveyImage}` : null,
    otherImage: found.otherImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.otherImage}` : null,
    salesConsultantsData: transformDropdownData(salesConsultants.data).sort((a, b) => sortAsc(a.label, b.label)),
    downloadingSalesConsultant,
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiTestDriveEoReservation.downloading,
  initialValues: getInitialValues(state),
  isEventReservationType: state.testDriveEoReservations.data[state.uiFunctionalPage.tappedId]
    ?.reservationType === RESERVATION_TYPE_EVENT,
  events: transformDropdownData(state.testDriveEoEvents.data),
  loadingEvents: state.uiTestDriveEoEvent.downloadng,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
  dealers: Object.values(state.dealers.data).map((item) => ({
    label: item.name,
    value: item.dealerCode,
  })),
  loadingDealers: state.uiDealer.downloading,
  approving: state.uiRegistration.approving,
  salesConsultantsData: transformDropdownData(state.salesConsultants.data).sort((a, b) => sortAsc(a.label, b.label)),
  downloadingSalesConsultant: state.uiTestDriveEoReservation.downloadingSalesConsultant,
  selectedConsultant: state.uiTestDriveEoReservation.selectedConsultant,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: () => {
    let hadDefaultFilter = '';
    const query = ownProps.location.search;
    if (query) {
      const value = query.split('=');
      if (value.length) {
        const [key, val] = value;
        hadDefaultFilter = val;
      }
    }
    if (hadDefaultFilter) {
      const filterString = `|testVehicleModel=${hadDefaultFilter}`;
      const text = filterString
        .replace('eventName', 'event.id')
        .replace('dealerName', 'dealer.dealerCode')
        .replace('userFullname', 'profile.id');

      dispatch(setTestDriveEoReservationAdvancedFilterString(text));
      dispatch(clearTestDriveEoReservations());
    } else {
      dispatch(setTestDriveEoReservationAdvancedFilterString(''));
      dispatch(clearTestDriveEoReservations());
    }
    dispatch(downloadTestDriveEoReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestDriveEoReservationSelectedOrderBy(orderBy));
    dispatch(clearTestDriveEoReservations());
    dispatch(downloadTestDriveEoReservationsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setTestDriveEoReservationTappedId(id));
    // dispatch(downloadTestDriveEoReservationAsync())
    //   .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setTestDriveEoReservationSearchText(text));
    dispatch(clearTestDriveEoReservations());
    dispatch(downloadTestDriveEoReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestDriveEoReservationsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestDriveEoReservationSelectedPageSize(pageSize));
    dispatch(downloadTestDriveEoReservationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setTestDriveEoReservationSelectedPageSize(pageSize));
    dispatch(clearTestDriveEoReservations());
    dispatch(downloadTestDriveEoReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestDriveEoReservations());
    dispatch(setTestDriveEoReservationAdvancedFilterString(''));
    dispatch(setTestDriveEoReservationSelectedOrderBy(INITIAL_ORDER_BY_TEST_DRIVE_EO_RESERVATIONS));
    dispatch(downloadTestDriveEoReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    console.log('filterString', filterString);
    const text = filterString
      .replace('eventName', 'event.id')
      .replace('dealerName', 'dealer.dealerCode')
      .replace('userFullname', 'profile.id');

    dispatch(setTestDriveEoReservationAdvancedFilterString(text));
    dispatch(clearTestDriveEoReservations());
    dispatch(downloadTestDriveEoReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setTestDriveEoEventSearchText(''));
    dispatch(clearTestDriveEoEvents());
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDealerSearchText(''));
    dispatch(downloadDealersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setTestDriveEoEventSearchText(text));
      dispatch(clearTestDriveEoEvents());
      await dispatch(downloadTestDriveEoEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDealersText: async (text) => {
    try {
      dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setDealerSearchText(text));
      dispatch(clearDealers());
      await dispatch(downloadDealersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onApprovePressed: async (data) => {
    const body = { Id: data.id, ActivityStatus: 4 };
    dispatch(approveReservationAsync(body))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRejectPressed: async (data) => {
    const body = { Id: data.id, ActivityStatus: 5 };
    dispatch(approveReservationAsync(body))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onGoingPressed: async (id, data) => {
    if (data?.data.activityStatus === 'Queueing') {
      try {
        const body = { Id: id, ActivityStatus: 6 };
        dispatch(approveReservationAsync(body))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        const query = ref(db, `cars/${data?.data.testVehicleId}`);
        return update(query, { queue: data?.data.queueNumbers });
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(setAlertErrorMessage('Invalid action'));
    }
  },
  onCompletedPressed: async (id, data) => {
    if (data?.data.activityStatus !== 'Completed') {
      try {
        const body = { Id: id, ActivityStatus: 3 };
        dispatch(approveReservationAsync(body))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(setAlertErrorMessage('Invalid action'));
    }
  },
  onEditPressed: (id) => {
    dispatch(setSalesConsultantSearchString(''));
    dispatch(setTestDriveEoReservationTappedId(id));
    dispatch(downloadSalesConsultantsAsync())
      .catch((error) => setAlertErrorMessage(error));
  },
  onSalesConsultantOptionSelected: (val) => {
    dispatch(setSalesConsultantSearchString(''));
    dispatch(clearSalesConsultants());
    dispatch(downloadSalesConsultantsAsync(true))
      .catch((error) => setAlertErrorMessage(error));
  },
  onChangeSalesConsultantText: (text) => {
    if (text.length >= 3 || text.length === 0) {
      dispatch(setSalesConsultantSearchString(text));
      dispatch(clearSalesConsultants());
      dispatch(downloadSalesConsultantsAsync(true))
        .catch((error) => setAlertErrorMessage(error));
    }
  },
  onChangeDropdown: (e, field) => {
    if (e) {
      dispatch(addNotificationDialogFilterForm(field, e));
    } else {
      dispatch(addNotificationDialogFilterForm(field, ''));
    }
  },
  onChangeCityText: (text) => {
    dispatch(setSalesConsultantSearchString(text));
    if (text.length >= 3 || text.length === 0) {
      dispatch(downloadSalesConsultantsAsync(true));
    }
  },
  onCityOptionSelected: (option) => {
    if (option) {
      dispatch(setNotificationSelectedSalesConsultant(option));
    } else {
      dispatch(setNotificationSelectedSalesConsultant(''));
    }
  },
  onSavePressed: async () => {
    try {
      await dispatch(editSalesConsultantsAsync());
      dispatch(downloadTestDriveEoReservationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationPage);
