import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  transformInitialValues, transformDropdownData, toMoment, transformUserDropdownData,
} from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage, clearUsers, setUserSearchText,
  setCarViewingReservationSearchText, setCarViewingReservationSelectedPageSize,
  setCarViewingReservationSelectedOrderBy, setCarViewingReservationAdvancedFilterString,
  setCarViewingReservationTappedId, clearCarViewingReservations,
  downloadCarViewingReservationsAsync, downloadCarViewingReservationAsync,
  setCarViewingEventAdvancedFilterDialogSelectedFilterString, setCarViewingEventSearchText,
  clearCarViewingEvents, downloadCarViewingEventsAsync,
  setUserAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE, SIMPLE_DATE_FORMAT,
  MENUID_CAR_VIEWING_RESERVATION, INITIAL_ORDER_BY_CAR_VIEWING_RESERVATIONS,
  RXFORM_CAR_VIEWING_RESERVATION,
} from '../../constant';
import CarViewingReservationPage from './car-viewing-reservation-presentation';
import GlobalLocalizedString from '../../../../localization';
import { downloadUsersAsync } from '../../../../redux/action';

const getInitialValues = (state) => {
  const { carViewingReservations, uiFunctionalPage, uiCarViewingReservation } = state;
  const { data } = carViewingReservations;
  const { downloadingDeleting, tappedId } = uiCarViewingReservation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    eventId: found.event?.id,
    eventName: found.event?.name,
    reservedDate: found.reservedDate ? toMoment(found.reservedDate).format(SIMPLE_DATE_FORMAT) : '',
    additionalVisitor: found.additionalAttendances.length > 0
      ? GlobalLocalizedString.common.labelYes : GlobalLocalizedString.common.labelNo,
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiCarViewingReservation.downloading,
  initialValues: getInitialValues(state),
  events: transformDropdownData(state.carViewingEvents.data),
  loadingEvents: state.uiCarViewingEvent.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_VIEWING_RESERVATION));
    dispatch(setCarViewingReservationAdvancedFilterString(''));
    dispatch(setCarViewingReservationSearchText(''));
    dispatch(clearCarViewingReservations());
    dispatch(setCarViewingReservationSelectedPageSize(20));
    dispatch(setCarViewingReservationSelectedOrderBy(INITIAL_ORDER_BY_CAR_VIEWING_RESERVATIONS));
    dispatch(downloadCarViewingReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCarViewingEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCarViewingEventSearchText(''));
    dispatch(setUserSearchText(''));
    dispatch(clearCarViewingEvents());
    dispatch(clearUsers());
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('event', 'event.id').replace('profile', 'profile.id');
    dispatch(setCarViewingReservationAdvancedFilterString(text));
    dispatch(clearCarViewingReservations());
    dispatch(downloadCarViewingReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_CAR_VIEWING_RESERVATION));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCarViewingReservationAdvancedFilterString(''));
    dispatch(setCarViewingReservationSelectedOrderBy(INITIAL_ORDER_BY_CAR_VIEWING_RESERVATIONS));
    dispatch(clearCarViewingReservations());
    dispatch(downloadCarViewingReservationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCarViewingReservationsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCarViewingReservationSelectedPageSize(pageSize));
    dispatch(downloadCarViewingReservationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setCarViewingEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCarViewingEventSearchText(text));
      dispatch(clearCarViewingEvents());
      await dispatch(downloadCarViewingEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onViewPressed: (id) => {
    dispatch(setCarViewingReservationTappedId(id));
    dispatch(downloadCarViewingReservationAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCarViewingReservationSearchText(text));
      dispatch(clearCarViewingReservations());
      await dispatch(downloadCarViewingReservationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCarViewingReservationSelectedOrderBy(orderBy));
    dispatch(clearCarViewingReservations());
    dispatch(downloadCarViewingReservationsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setCarViewingReservationSelectedPageSize(pageSize));
    dispatch(clearCarViewingReservations());
    dispatch(downloadCarViewingReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarViewingReservationPage);
