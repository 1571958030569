import {
  refreshingVehicleInfo, addRegistration,
} from '../simple-action';
import { refreshVehicleInfo } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(refreshingVehicleInfo(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistration;


    const registration = await refreshVehicleInfo(tappedId, token);
    dispatch(addRegistration(registration));
  } finally {
    dispatch(refreshingVehicleInfo(false));
  }
};
