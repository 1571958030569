import {
  addCarServiceReservation, clearCarServiceReservations, downloadingDeletingCarServiceReservation,
  setAlertErrorMessage, setFunctionalPageMode, setCarServiceReservationTappedId,
} from '../simple-action';
import { downloadCarServiceReservation } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadCarServiceReservationsAsync from './downloadCarServiceReservationsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCarServiceReservation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCarServiceReservation;
    const { confirmation } = getState().alert;

    if (confirmation) {
      dispatch(setCarServiceReservationTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCarServiceReservations());
      dispatch(downloadCarServiceReservationsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadCarServiceReservation(tappedId, token);
      dispatch(addCarServiceReservation(result));
    }
  } finally {
    dispatch(downloadingDeletingCarServiceReservation(false));
  }
};
