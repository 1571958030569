import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_IIMS_2023_SCANNED_CODES = '/iims2023/api/v1/ScannedCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_IIMS_2023_VIEW_DELETE_SCANNED_CODE = '/iims2023/api/v1/ScannedCode/{id}';
export const REST_URL_IIMS_2023_PRIZE_REDEMPTIONS = '/iims2023/api/v1/PrizeRedemption?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_IIMS_2023_PRIZE_REDEMPTION = '/iims2023/api/v1/PrizeRedemption/{id}';
export const REST_URL_IIMS_2023_TRANSACTIONS = '/iims2023/api/v1/Transaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_IIMS_2023_TRANSACTION = '/iims2023/api/v1/Transaction/{id}';

export const ROUTE_NAME_IIMS_2023_2022 = '/iims-2023';
export const ROUTE_NAME_IIMS_2023_SCANNED_CODE = '/iims-2023-scanned-code';
export const ROUTE_NAME_IIMS_2023_PRIZE_REDEMPTION = '/iims-2023-prize-redemption';
export const ROUTE_NAME_IIMS_2023_TRANSACTION = '/iims-2023-transaction';

export const MENUID_IIMS_2023_2022 = 'MENUID_IIMS_2023_2022';
export const MENUID_IIMS_2023_SCANNED_CODE = 'MENUID_IIMS_2023_SCANNED_CODE';
export const MENUID_IIMS_2023_PRIZE_REDEMPTION = 'MENUID_IIMS_2023_PRIZE_REDEMPTION';
export const MENUID_IIMS_2023_TRANSACTION = 'MENUID_IIMS_2023_TRANSACTION';

export const RXSTATE_IIMS_2023_SCANNED_CODES = 'iims2023ScannedCodes';
export const RXSTATE_IIMS_2023_SCANNED_CODE_PAGE = 'uiIims2023ScannedCode';
export const RXSTATE_IIMS_2023_PRIZE_REDEMPTIONS = 'iims2023PrizeRedemptions';
export const RXSTATE_IIMS_2023_PRIZE_REDEMPTION = 'uiIims2023PrizeRedemption';
export const RXSTATE_IIMS_2023_TRANSACTIONS = 'iims2023Transactions';
export const RXSTATE_IIMS_2023_TRANSACTION = 'uiIims2023Transaction';

export const INITIAL_ORDER_BY_IIMS_2023_SCANNED_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_IIMS_2023_PRIZE_REDEMPTION = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_IIMS_2023_TRANSACTION = `createdDate ${ORDER_SORT_DESCENDING}`;

export const RXFIELD_IIMS_2023_SCANNED_CODE_TIME = 'createdDate';
export const RXFIELD_IIMS_2023_SCANNED_CODE_USERNAME = 'scanCodeUsername';
export const RXFIELD_IIMS_2023_SCANNED_CODE_CHALLANGE_CODE_LABEL = 'challengeCodeLabel';
export const RXFIELD_IIMS_2023_SCANNED_CODE_CHALLANGE_USER_NAME = 'user.fullName';
export const RXFIELD_IIMS_2023_SCANNED_CODE_CHALLANGE_USER_ID = 'user.id';
export const RXFIELD_IIMS_2023_CHALLANGE_CREATED_DATE = 'createdDate';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_CREATED_DATE = 'createdDate';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_DESCRIPTION = 'description';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_POINT = 'point';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_PRIZE_TAG = 'prizeTag';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_USER = 'user';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_USER_FULLNAME = 'fullName';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_USER_ID = 'userId';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_VOUCHER = 'voucher';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_VOUCHER_ID = 'voucherId';
export const RXFIELD_IIMS_2023_PRIZE_REDEMPTION_VOUCHER_TITLE = 'voucherTitle';
export const RXFIELD_IIMS_2023_TRANSACTION_AMOUNT = 'amount';
export const RXFIELD_IIMS_2023_TRANSACTION_CREATED_DATE = 'createdDate';
export const RXFIELD_IIMS_2023_TRANSACTION_LABEL = 'label';
export const RXFIELD_IIMS_2023_TRANSACTION_REFERENCE_ID = 'referenceId';
export const RXFIELD_IIMS_2023_TRANSACTION_REMAINING_BALANCE = 'remainingBalance';
export const RXFIELD_IIMS_2023_TRANSACTION_TYPE = 'type';
export const RXFIELD_IIMS_2023_TRANSACTION_USER = 'user';
export const RXFIELD_IIMS_2023_TRANSACTION_USER_FULLNAME = 'fullName';
export const RXFIELD_IIMS_2023_TRANSACTION_USER_ID = 'userId';

export const RXFORM_IIMS_2023_SCANNED_CODE = 'iims2023ScannedCodePage';
export const RXFORM_IIMS_2023_PRIZE_REDEMPTION = 'iims2023PrizeRedemptionPage';
export const RXFORM_IIMS_2023_TRANSACTION = 'iims2023TransactionPage';

export const REST_URL_IIMS_2023_SURVEYS = '/testdrive/api/v1/ResponseForEo?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_IIMS_2023_VIEW_DELETE_SURVEY = '/testdrive/api/v1/ResponseForEo/{id}';
export const ROUTE_NAME_IIMS_2023_SURVEY = '/testdrive-survey';
export const MENUID_IIMS_2023_SURVEY = 'MENUID_IIMS_2023_SURVEY';
export const RXSTATE_IIMS_2023_SURVEYS = 'iims2023Surveys';
export const RXSTATE_IIMS_2023_SURVEY_PAGE = 'uiIims2023Survey';
export const INITIAL_ORDER_BY_IIMS_2023_SURVEYS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const RXFIELD_IIMS_2023_SURVEY_TIME = 'createdDate';
export const RXFIELD_IIMS_2023_SURVEY_USERNAME = 'scanCodeUsername';
export const RXFIELD_IIMS_2023_SURVEY_CHALLANGE_CODE_LABEL = 'challengeCodeLabel';
export const RXFIELD_IIMS_2023_SURVEY_CHALLANGE_USER_NAME = 'user.fullName';
export const RXFIELD_IIMS_2023_SURVEY_CHALLANGE_USER_ID = 'user.id';
export const RXFORM_IIMS_2023_SURVEY = 'iims2023SurveyPage';


export const REST_URL_IIMS_2023_SPKS = '/iims2023/api/v1/Spk?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_IIMS_2023_VIEW_DELETE_SPK = '/iims2023/api/v1/Spk/{id}';
export const ROUTE_NAME_IIMS_2023_SPK = '/iims-2023-spk';
export const MENUID_IIMS_2023_SPK = 'MENUID_IIMS_2023_SPK';
export const RXSTATE_IIMS_2023_SPKS = 'iims2023Spks';
export const RXSTATE_IIMS_2023_SPK_PAGE = 'uiIims2023Spk';
export const INITIAL_ORDER_BY_IIMS_2023_SPKS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const RXFIELD_IIMS_2023_SPK_TIME = 'createdDate';
export const RXFIELD_IIMS_2023_SPK_USERNAME = 'scanCodeUsername';
export const RXFIELD_IIMS_2023_SPK_CHALLANGE_CODE_LABEL = 'challengeCodeLabel';
export const RXFIELD_IIMS_2023_SPK_CHALLANGE_USER_NAME = 'user.fullName';
export const RXFIELD_IIMS_2023_SPK_CHALLANGE_USER_ID = 'user.id';
export const RXFORM_IIMS_2023_SPK = 'iims2023SpkPage';
