import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_GIIAS_SCANNED_CODES = 'DOWNLOADING_GIIAS_SCANNED_CODES';
export const downloadingGiiasScannedCodes = makeActionCreator(DOWNLOADING_GIIAS_SCANNED_CODES, 'status');

export const DOWNLOADING_GIIAS_SCANNED_CODE = 'DOWNLOADING_GIIAS_SCANNED_CODE';
export const downloadingGiiasScannedCode = makeActionCreator(DOWNLOADING_GIIAS_SCANNED_CODE, 'status');

export const SET_GIIAS_SCANNED_CODES = 'SET_GIIAS_SCANNED_CODES';
export const setGiiasScannedCodes = makeActionCreator(SET_GIIAS_SCANNED_CODES, 'data');

export const SET_GIIAS_SCANNED_CODE = 'SET_GIIAS_SCANNED_CODE';
export const setGiiasScannedCode = makeActionCreator(SET_GIIAS_SCANNED_CODE, 'data');

export const CLEAR_GIIAS_SCANNED_CODES = 'CLEAR_GIIAS_SCANNED_CODES';
export const clearGiiasScannedCodes = makeActionCreator(CLEAR_GIIAS_SCANNED_CODES, 'data');

export const SET_GIIAS_SCANNED_CODES_PAGE_SIZE = 'SET_GIIAS_SCANNED_CODES_PAGE_SIZE';
export const setGiiasScannedCodesPageSize = makeActionCreator(SET_GIIAS_SCANNED_CODES_PAGE_SIZE, 'size');

export const SET_GIIAS_SCANNED_CODES_ORDER_BY = 'SET_GIIAS_SCANNED_CODES_ORDER_BY';
export const setGiiasScannedCodesOrderBy = makeActionCreator(SET_GIIAS_SCANNED_CODES_ORDER_BY, 'order');

export const SET_GIIAS_SCANNED_CODES_SEARCH_TEXT = 'SET_GIIAS_SCANNED_CODES_SEARCH_TEXT';
export const setGiiasScannedCodesSearchText = makeActionCreator(SET_GIIAS_SCANNED_CODES_SEARCH_TEXT, 'text');

export const SET_GIIAS_SCANNED_CODES_TAPPED_ID = 'SET_GIIAS_SCANNED_CODES_TAPPED_ID';
export const setGiiasScannedCodesTappedId = makeActionCreator(SET_GIIAS_SCANNED_CODES_TAPPED_ID, 'id');

export const SET_GIIAS_SCANNED_CODES_FILTER_STRING = 'SET_GIIAS_SCANNED_CODES_FILTER_STRING';
export const setGiiasScannedCodesFilterString = makeActionCreator(SET_GIIAS_SCANNED_CODES_FILTER_STRING, 'text');


export const DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTIONS = 'DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTIONS';
export const downloadingGiias2022PrizeRedemptions = makeActionCreator(DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTION = 'DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTION';
export const downloadingGiias2022PrizeRedemption = makeActionCreator(DOWNLOADING_GIIAS_2022_PRIZE_REDEMPTION, 'status');

export const ADD_GIIAS_2022_PRIZE_REDEMPTION = 'ADD_GIIAS_2022_PRIZE_REDEMPTION';
export const addGiias2022PrizeRedemption = makeActionCreator(ADD_GIIAS_2022_PRIZE_REDEMPTION, 'data');

export const SET_GIIAS_2022_PRIZE_REDEMPTIONS = 'SET_GIIAS_2022_PRIZE_REDEMPTIONS';
export const setGiias2022PrizeRedemptions = makeActionCreator(SET_GIIAS_2022_PRIZE_REDEMPTIONS, 'data');

export const CLEAR_GIIAS_2022_PRIZE_REDEMPTIONS = 'CLEAR_GIIAS_2022_PRIZE_REDEMPTIONS';
export const clearGiias2022PrizeRedemptions = makeActionCreator(CLEAR_GIIAS_2022_PRIZE_REDEMPTIONS);

export const SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE';
export const setGiias2022PrizeRedemptionSelectedPageSize = makeActionCreator(SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_ORDER_BY = 'SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_ORDER_BY';
export const setGiias2022PrizeRedemptionSelectedOrderBy = makeActionCreator(SET_GIIAS_2022_PRIZE_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_2022_PRIZE_REDEMPTION_TAPPED_ID = 'SET_GIIAS_2022_PRIZE_REDEMPTION_TAPPED_ID';
export const setGiias2022PrizeRedemptionTappedId = makeActionCreator(SET_GIIAS_2022_PRIZE_REDEMPTION_TAPPED_ID, 'id');

export const SET_GIIAS_2022_PRIZE_REDEMPTION_SEARCH_TEXT = 'SET_GIIAS_2022_PRIZE_REDEMPTION_SEARCH_TEXT';
export const setGiias2022PrizeRedemptionSearchText = makeActionCreator(SET_GIIAS_2022_PRIZE_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_2022_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_2022_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING';
export const setGiias2022PrizeRedemptionAdvancedFilterString = makeActionCreator(SET_GIIAS_2022_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING, 'text');


export const DOWNLOADING_GIIAS_2022_TRANSACTIONS = 'DOWNLOADING_GIIAS_2022_TRANSACTIONS';
export const downloadingGiias2022Transactions = makeActionCreator(DOWNLOADING_GIIAS_2022_TRANSACTIONS, 'status');

export const DOWNLOADING_GIIAS_2022_TRANSACTION = 'DOWNLOADING_GIIAS_2022_TRANSACTION';
export const downloadingGiias2022Transaction = makeActionCreator(DOWNLOADING_GIIAS_2022_TRANSACTION, 'status');

export const SAVING_GIIAS_2022_TRANSACTIONS = 'SAVING_GIIAS_2022_TRANSACTIONS';
export const savingGiias2022Transactions = makeActionCreator(SAVING_GIIAS_2022_TRANSACTIONS, 'status');

export const SAVING_GIIAS_2022_SCANNED_CODES = 'SAVING_GIIAS_2022_TSCANNED_CODE';
export const savingGiias2022ScannedCodes = makeActionCreator(SAVING_GIIAS_2022_SCANNED_CODES, 'status');

export const ADD_GIIAS_2022_TRANSACTION = 'ADD_GIIAS_2022_TRANSACTION';
export const addGiias2022Transaction = makeActionCreator(ADD_GIIAS_2022_TRANSACTION, 'data');

export const SET_GIIAS_2022_TRANSACTIONS = 'SET_GIIAS_2022_TRANSACTIONS';
export const setGiias2022Transactions = makeActionCreator(SET_GIIAS_2022_TRANSACTIONS, 'data');

export const CLEAR_GIIAS_2022_TRANSACTIONS = 'CLEAR_GIIAS_2022_TRANSACTIONS';
export const clearGiias2022Transactions = makeActionCreator(CLEAR_GIIAS_2022_TRANSACTIONS);

export const SET_GIIAS_2022_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_2022_TRANSACTION_SELECTED_PAGE_SIZE';
export const setGiias2022TransactionSelectedPageSize = makeActionCreator(SET_GIIAS_2022_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_2022_TRANSACTION_SELECTED_ORDER_BY = 'SET_GIIAS_2022_TRANSACTION_SELECTED_ORDER_BY';
export const setGiias2022TransactionSelectedOrderBy = makeActionCreator(SET_GIIAS_2022_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_2022_TRANSACTION_TAPPED_ID = 'SET_GIIAS_2022_TRANSACTION_TAPPED_ID';
export const setGiias2022TransactionTappedId = makeActionCreator(SET_GIIAS_2022_TRANSACTION_TAPPED_ID, 'id');

export const SET_GIIAS_2022_TRANSACTION_SEARCH_TEXT = 'SET_GIIAS_2022_TRANSACTION_SEARCH_TEXT';
export const setGiias2022TransactionSearchText = makeActionCreator(SET_GIIAS_2022_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_2022_TRANSACTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_2022_TRANSACTION_ADVANCED_FILTER_STRING';
export const setGiias2022TransactionAdvancedFilterString = makeActionCreator(SET_GIIAS_2022_TRANSACTION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_GIIAS_2022_PRIZE_REDEMPTIONS = 'SAVING_GIIAS_2022_PRIZE_REDEMPTIONS';
export const savingGiias2022PrizeRedemptions = makeActionCreator(SAVING_GIIAS_2022_PRIZE_REDEMPTIONS, 'status');
