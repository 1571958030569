import { downloadingDealerProvinces, setDealerProvinces } from '../simple-action';
import { downloadDealerProvinces } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingDealerProvinces(true));

    const list = await downloadDealerProvinces(token);
    if (list) {
      dispatch(setDealerProvinces(list));
    }
  } finally {
    dispatch(downloadingDealerProvinces(false));
  }
};
