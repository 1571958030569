import { makeActionCreator } from '../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY = 'DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY';
export const downloadingAttendanceSummaryTotalClockInToday = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY, 'status');

export const SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY = 'SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY';
export const setAttendanceSummaryTotalClockInToday = makeActionCreator(SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY = 'CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY';
export const clearAttendanceSummaryTotalClockInToday = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_IN_TODAY,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY = 'DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY';
export const downloadingAttendanceSummaryTotalClockOutToday = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY, 'status');

export const SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY = 'SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY';
export const setAttendanceSummaryTotalClockOutToday = makeActionCreator(SET_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY = 'CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY';
export const clearAttendanceSummaryTotalClockOutToday = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_TOTAL_CLOCK_OUT_TODAY,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS = 'DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS';
export const downloadingAttendanceSummaryTodayClockInStatus = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS, 'status');

export const SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS = 'SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS';
export const setAttendanceSummaryTodayClockInStatus = makeActionCreator(SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS = 'CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS';
export const clearAttendanceSummaryTodayClockInStatus = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_STATUS,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS = 'DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS';
export const downloadingAttendanceSummaryTodayClockOutStatus = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS, 'status');

export const SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS = 'SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS';
export const setAttendanceSummaryTodayClockOutStatus = makeActionCreator(SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS = 'CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS';
export const clearAttendanceSummaryTodayClockOutStatus = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_STATUS,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS = 'DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS';
export const downloadingAttendanceSummaryTodayClockInLocationStatus = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS, 'status');

export const SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS = 'SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS';
export const setAttendanceSummaryTodayClockInLocationStatus = makeActionCreator(SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS = 'CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS';
export const clearAttendanceSummaryTodayClockInLocationStatus = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_IN_LOCATION_STATUS,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS = 'DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS';
export const downloadingAttendanceSummaryTodayClockOutLocationStatus = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS, 'status');

export const SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS = 'SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS';
export const setAttendanceSummaryTodayClockOutLocationStatus = makeActionCreator(SET_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS = 'CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS';
export const clearAttendanceSummaryTodayClockOutLocationStatus = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_TODAY_CLOCK_OUT_LOCATION_STATUS,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND = 'DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND';
export const downloadingAttendanceSummaryClockInStatusTrend = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND, 'status');

export const SET_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND = 'SET_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND';
export const setAttendanceSummaryClockInStatusTrend = makeActionCreator(SET_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND = 'CLEAR_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND';
export const clearAttendanceSummaryClockInStatusTrend = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_CLOCK_IN_STATUS_TREND,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND = 'DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND';
export const downloadingAttendanceSummaryClockOutStatusTrend = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND, 'status');

export const SET_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND = 'SET_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND';
export const setAttendanceSummaryClockOutStatusTrend = makeActionCreator(SET_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND = 'CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND';
export const clearAttendanceSummaryClockOutStatusTrend = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_STATUS_TREND,
);


export const SET_ATTENDANCE_CLOCK_IN_STATUS_TREND_TOOLTIP_TARGET = 'SET_ATTENDANCE_CLOCK_IN_STATUS_TREND_TOOLTIP_TARGET';
export const setAttendanceClockInStatusTrendTooltipTarget = makeActionCreator(SET_ATTENDANCE_CLOCK_IN_STATUS_TREND_TOOLTIP_TARGET, 'targetItem');

export const SET_ATTENDANCE_CLOCK_OUT_STATUS_TREND_TOOLTIP_TARGET = 'SET_ATTENDANCE_CLOCK_OUT_STATUS_TREND_TOOLTIP_TARGET';
export const setAttendanceClockOutStatusTrendTooltipTarget = makeActionCreator(SET_ATTENDANCE_CLOCK_OUT_STATUS_TREND_TOOLTIP_TARGET, 'targetItem');


export const DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND = 'DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND';
export const downloadingAttendanceSummaryClockInLocationTrend = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND, 'status');

export const SET_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND = 'SET_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND';
export const setAttendanceSummaryClockInLocationTrend = makeActionCreator(SET_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND = 'CLEAR_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND';
export const clearAttendanceSummaryClockInLocationTrend = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_CLOCK_IN_LOCATION_TREND,
);


export const DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND = 'DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND';
export const downloadingAttendanceSummaryClockOutLocationTrend = makeActionCreator(DOWNLOADING_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND, 'status');

export const SET_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND = 'SET_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND';
export const setAttendanceSummaryClockOutLocationTrend = makeActionCreator(SET_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND, 'data');

export const CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND = 'CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND';
export const clearAttendanceSummaryClockOutLocationTrend = makeActionCreator(
  CLEAR_ATTENDANCE_SUMMARY_CLOCK_OUT_LOCATION_TREND,
);


export const SET_ATTENDANCE_CLOCK_IN_LOCATION_TREND_TOOLTIP_TARGET = 'SET_ATTENDANCE_CLOCK_IN_LOCATION_TREND_TOOLTIP_TARGET';
export const setAttendanceClockInLocationTrendTooltipTarget = makeActionCreator(SET_ATTENDANCE_CLOCK_IN_LOCATION_TREND_TOOLTIP_TARGET, 'targetItem');

export const SET_ATTENDANCE_CLOCK_OUT_LOCATION_TREND_TOOLTIP_TARGET = 'SET_ATTENDANCE_CLOCK_OUT_LOCATION_TREND_TOOLTIP_TARGET';
export const setAttendanceClockOutLocationTrendTooltipTarget = makeActionCreator(SET_ATTENDANCE_CLOCK_OUT_LOCATION_TREND_TOOLTIP_TARGET, 'targetItem');


export const DOWNLOADING_HISTORIES = 'DOWNLOADING_HISTORIES';
export const downloadingHistories = makeActionCreator(DOWNLOADING_HISTORIES, 'status');

export const SET_HISTORIES = 'SET_HISTORIES';
export const setHistories = makeActionCreator(SET_HISTORIES, 'data');

export const ADD_HISTORY = 'ADD_HISTORY';
export const addHistory = makeActionCreator(ADD_HISTORY, 'data');

export const CLEAR_HISTORIES = 'CLEAR_HISTORIES';
export const clearHistories = makeActionCreator(CLEAR_HISTORIES);

export const SAVING_HISTORIES = 'SAVING_HISTORIES';
export const savingHistories = makeActionCreator(SAVING_HISTORIES, 'status');

export const DOWNLOADING_EMPLOYEES = 'DOWNLOADING_EMPLOYEES';
export const downloadingEmployees = makeActionCreator(DOWNLOADING_EMPLOYEES, 'status');

export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const setEmployees = makeActionCreator(SET_EMPLOYEES, 'data');

export const CLEAR_EMPLOYEES = 'CLEAR_EMPLOYEES';
export const clearEmployees = makeActionCreator(CLEAR_EMPLOYEES);


export const SET_HISTORY_SELECTED_PAGE_SIZE = 'SET_HISTORY_SELECTED_PAGE_SIZE';
export const setHistorySelectedPageSize = makeActionCreator(SET_HISTORY_SELECTED_PAGE_SIZE, 'size');

export const SET_HISTORY_SELECTED_ORDER_BY = 'SET_HISTORY_SELECTED_ORDER_BY';
export const setHistorySelectedOrderBy = makeActionCreator(SET_HISTORY_SELECTED_ORDER_BY, 'order');

export const SET_HISTORY_TAPPED_ID = 'SET_HISTORY_TAPPED_ID';
export const setHistoryTappedId = makeActionCreator(SET_HISTORY_TAPPED_ID, 'id');

export const SET_HISTORY_SEARCH_TEXT = 'SET_HISTORY_SEARCH_TEXT';
export const setHistorySearchText = makeActionCreator(SET_HISTORY_SEARCH_TEXT, 'text');

export const SET_HISTORY_SELECTED_COMPANY = 'SET_HISTORY_SELECTED_COMPANY';
export const setHistorySelectedCompany = makeActionCreator(SET_HISTORY_SELECTED_COMPANY, 'option');

export const SET_HISTORY_SELECTED_DEPARTMENT = 'SET_HISTORY_SELECTED_DEPARTMENT';
export const setHistorySelectedDepartment = makeActionCreator(SET_HISTORY_SELECTED_DEPARTMENT, 'option');

export const SET_HISTORY_SELECTED_DIVISION = 'SET_HISTORY_SELECTED_DIVISION';
export const setHistorySelectedDivision = makeActionCreator(SET_HISTORY_SELECTED_DIVISION, 'option');

export const SET_HISTORY_SELECTED_BRANCH = 'SET_HISTORY_SELECTED_BRANCH';
export const setHistorySelectedBranch = makeActionCreator(SET_HISTORY_SELECTED_BRANCH, 'option');

export const SET_HISTORY_SELECTED_EMPLOYEE = 'SET_HISTORY_SELECTED_EMPLOYEE';
export const setHistorySelectedEmployee = makeActionCreator(SET_HISTORY_SELECTED_EMPLOYEE, 'option');

export const SET_HISTORY_EMPLOYEE_SEARCH_TEXT = 'SET_HISTORY_EMPLOYEE_SEARCH_TEXT';
export const setHistoryEmployeeSearchText = makeActionCreator(SET_HISTORY_EMPLOYEE_SEARCH_TEXT, 'text');


export const DOWNLOADING_ADDITIONAL_FIELDS = 'DOWNLOADING_ADDITIONAL_FIELDS';
export const downloadingAdditionalFields = makeActionCreator(DOWNLOADING_ADDITIONAL_FIELDS, 'status');

export const SET_ADDITIONAL_FIELDS = 'SET_ADDITIONAL_FIELDS';
export const setAdditionalFields = makeActionCreator(SET_ADDITIONAL_FIELDS, 'data');

export const ADD_ADDITIONAL_FIELD = 'ADD_ADDITIONAL_FIELD';
export const addAdditionalField = makeActionCreator(ADD_ADDITIONAL_FIELD, 'data');

export const CLEAR_ADDITIONAL_FIELDS = 'CLEAR_ADDITIONAL_FIELDS';
export const clearAdditionalFields = makeActionCreator(CLEAR_ADDITIONAL_FIELDS);

export const ADDING_EDITING_ADDITIONAL_FIELD = 'ADDING_EDITING_ADDITIONAL_FIELD';
export const addingEditingAdditionalField = makeActionCreator(ADDING_EDITING_ADDITIONAL_FIELD, 'status');

export const DOWNLOADING_DELETING_ADDITIONAL_FIELD = 'DOWNLOADING_DELETING_ADDITIONAL_FIELD';
export const downloadingDeletingAdditionalField = makeActionCreator(DOWNLOADING_DELETING_ADDITIONAL_FIELD, 'status');

export const ENABLING_DISABLING_ADDITIONAL_FIELD = 'ENABLING_DISABLING_ADDITIONAL_FIELD';
export const enablingDisablingAdditionalField = makeActionCreator(ENABLING_DISABLING_ADDITIONAL_FIELD, 'status');

export const DOWNLOADING_ADDITIONAL_FIELD_PARENTS = 'DOWNLOADING_ADDITIONAL_FIELD_PARENTS';
export const downloadingAdditionalFieldParents = makeActionCreator(DOWNLOADING_ADDITIONAL_FIELD_PARENTS, 'status');

export const SET_ADDITIONAL_FIELD_PARENTS = 'SET_ADDITIONAL_FIELD_PARENTS';
export const setAdditionalFieldParents = makeActionCreator(SET_ADDITIONAL_FIELD_PARENTS, 'data');

export const CLEAR_ADDITIONAL_FIELD_PARENTS = 'CLEAR_ADDITIONAL_FIELD_PARENTS';
export const clearAdditionalFieldParents = makeActionCreator(CLEAR_ADDITIONAL_FIELD_PARENTS);


export const SET_ADDITIONAL_FIELD_SELECTED_PAGE_SIZE = 'SET_ADDITIONAL_FIELD_SELECTED_PAGE_SIZE';
export const setAdditionalFieldSelectedPageSize = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_PAGE_SIZE, 'size');

export const SET_ADDITIONAL_FIELD_SELECTED_ORDER_BY = 'SET_ADDITIONAL_FIELD_SELECTED_ORDER_BY';
export const setAdditionalFieldSelectedOrderBy = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_ORDER_BY, 'order');

export const SET_ADDITIONAL_FIELD_TAPPED_ID = 'SET_ADDITIONAL_FIELD_TAPPED_ID';
export const setAdditionalFieldTappedId = makeActionCreator(SET_ADDITIONAL_FIELD_TAPPED_ID, 'id');

export const SET_ADDITIONAL_FIELD_SEARCH_TEXT = 'SET_ADDITIONAL_FIELD_SEARCH_TEXT';
export const setAdditionalFieldSearchText = makeActionCreator(SET_ADDITIONAL_FIELD_SEARCH_TEXT, 'text');

export const SET_ADDITIONAL_FIELD_PARENT_SEARCH_TEXT = 'SET_ADDITIONAL_FIELD_PARENT_SEARCH_TEXT';
export const setAdditionalFieldParentSearchText = makeActionCreator(SET_ADDITIONAL_FIELD_PARENT_SEARCH_TEXT, 'text');

export const SET_ADDITIONAL_FIELD_SELECTED_APPLIED_ON = 'SET_ADDITIONAL_FIELD_SELECTED_APPLIED_ON';
export const setAdditionalFieldSelectedAppliedOn = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_APPLIED_ON, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_FIELD_TYPE = 'SET_ADDITIONAL_FIELD_SELECTED_FIELD_TYPE';
export const setAdditionalFieldSelectedFieldType = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_FIELD_TYPE, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_OPTION_MODE = 'SET_ADDITIONAL_FIELD_SELECTED_OPTION_MODE';
export const setAdditionalFieldSelectedOptionMode = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_OPTION_MODE, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_OPTION_VALUE_SEPARATOR = 'SET_ADDITIONAL_FIELD_SELECTED_OPTION_VALUE_SEPARATOR';
export const setAdditionalFieldSelectedOptionValueSeparator = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_OPTION_VALUE_SEPARATOR, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_PARENT = 'SET_ADDITIONAL_FIELD_SELECTED_PARENT';
export const setAdditionalFieldSelectedParent = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_PARENT, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_PICKER_MODE = 'SET_ADDITIONAL_FIELD_SELECTED_PICKER_MODE';
export const setAdditionalFieldSelectedPickerMode = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_PICKER_MODE, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_TEXT_MULTILINE = 'SET_ADDITIONAL_FIELD_SELECTED_TEXT_MULTILINE';
export const setAdditionalFieldSelectedTextMultiline = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_TEXT_MULTILINE, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_TEXT_KEYBOARD_TYPE = 'SET_ADDITIONAL_FIELD_SELECTED_TEXT_KEYBOARD_TYPE';
export const setAdditionalFieldSelectedTextKeyboardType = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_TEXT_KEYBOARD_TYPE, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_LOGICAL_OPERATOR = 'SET_ADDITIONAL_FIELD_SELECTED_LOGICAL_OPERATOR';
export const setAdditionalFieldSelectedLogicalOperator = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_LOGICAL_OPERATOR, 'option');

export const SET_ADDITIONAL_FIELD_SELECTED_MUST_USE_CAMERA = 'SET_ADDITIONAL_FIELD_SELECTED_MUST_USE_CAMERA';
export const setAdditionalFieldSelectedMustUseCamera = makeActionCreator(SET_ADDITIONAL_FIELD_SELECTED_MUST_USE_CAMERA, 'option');


export const SET_HISTORY_PROFILE_DETAIL = 'SET_HISTORY_PROFILE_DETAIL';
export const setHistoryProfileDetail = makeActionCreator(SET_HISTORY_PROFILE_DETAIL, 'data');

export const CLEAR_HISTORY_PROFILE_DETAIL = 'CLEAR_HISTORY_PROFILE_DETAIL';
export const clearHistoryProfileDetail = makeActionCreator(CLEAR_HISTORY_PROFILE_DETAIL);
