import { downloadBookingCreatedByRepairSelection } from '../../../helper';
import { downloadingBookingCreatedByRepairSelection, setBookingCreatedByRepairSelection } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingBookingCreatedByRepairSelection(true));
    const bookingCreatedByRepairSelection = await downloadBookingCreatedByRepairSelection(token,
      startDate, endDate);

    dispatch(setBookingCreatedByRepairSelection(bookingCreatedByRepairSelection));
  } finally {
    dispatch(downloadingBookingCreatedByRepairSelection(false));
  }
};
