
import { INITIAL_ORDER_BY_REDEMPTION_HISTORIES } from '../../constant';
import {
  DOWNLOADING_REDEMPTION_HISTORIES,
  DOWNLOADING_REDEMPTION_HISTORY,

  SET_REDEMPTION_HISTORY_SEARCH_TEXT,
  SET_REDEMPTION_HISTORY_SELECTED_ORDER_BY,
  SET_REDEMPTION_HISTORY_SELECTED_PAGE_SIZE,
  SET_REDEMPTION_HISTORY_TAPPED_ID,

  SET_REDEMPTION_HISTORY_SELECTED_MERCHANT,
  SET_REDEMPTION_HISTORY_SELECTED_VOUCHER,
  SET_REDEMPTION_HISTORY_SELECTED_REWARD,
  SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE,
  SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT,
  SET_REDEMPTION_HISTORY_SELECTED_USER,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  addingEditing: false,

  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_REDEMPTION_HISTORIES,

  selectedPageSize: 20,

  selectedMerchant: null,
  selectedUser: null,
  selectedVoucher: null,
  selectedVoucherBypassMaxPurchase: null,
  selectedVoucherBypassAchievement: null,
  selectedReward: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_REDEMPTION_HISTORIES:
      return { ...state, downloading: action.status };
    case DOWNLOADING_REDEMPTION_HISTORY:
      return { ...state, downloadingDeleting: action.status };

    case SET_REDEMPTION_HISTORY_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_REDEMPTION_HISTORY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_REDEMPTION_HISTORY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_REDEMPTION_HISTORY_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };

    case SET_REDEMPTION_HISTORY_SELECTED_MERCHANT:
      return { ...state, selectedMerchant: action.value };
    case SET_REDEMPTION_HISTORY_SELECTED_USER:
      return { ...state, selectedUser: action.value };
    case SET_REDEMPTION_HISTORY_SELECTED_VOUCHER:
      return { ...state, selectedVoucher: action.value };
    case SET_REDEMPTION_HISTORY_SELECTED_REWARD:
      return { ...state, selectedReward: action.value };
    case SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_MAX_PURCHASE:
      return { ...state, selectedVoucherBypassMaxPurchase: action.value };
    case SET_REDEMPTION_HISTORY_SELECTED_VOUCHER_BYPASS_ACHIEVEMENT:
      return { ...state, selectedVoucherBypassAchievement: action.value };
    default: return state;
  }
};
