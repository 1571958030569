import { SET_BOOKING_CREATED_BY_SERVICE_INTERVAL, CLEAR_BOOKING_CREATED_BY_SERVICE_INTERVAL } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_BOOKING_CREATED_BY_SERVICE_INTERVAL: {
      return action.data;
    }
    case CLEAR_BOOKING_CREATED_BY_SERVICE_INTERVAL:
      return state;
    default:
      return state;
  }
};
