import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  PAGE_MODE_TABLE,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  MENUID_AUTHENTICATION_PASSWORD_RESET_ATTEMPT,
  INITIAL_ORDER_BY_AUTH_PASSWORD_RESET_ATTEMPTS,
  RXFORM_AUTH_PASSWORD_RESET_ATTEMPT,
} from '../../constant';
import {
  transformInitialValues,
  toMoment,
} from '../../helper';
import {
  clearAuthPasswordResetAttempts,
  downloadAuthPasswordResetAttemptAsync,
  downloadAuthPasswordResetAttemptsAsync,
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setAuthPasswordResetAttemptAdvancedFilterDialogSelectedFilterString,
  setAuthPasswordResetAttemptSearchText,
  setAuthPasswordResetAttemptSelectedOrderBy,
  setAuthPasswordResetAttemptSelectedPageSize,
  setAuthPasswordResetAttemptTappedId,
} from '../../redux/action';
import GlobalLocalizedString from '../../localization';
import AuthPasswordResetAttemptPage from './password-reset-attempt.presentation';

const getInitialValues = (state) => {
  const {
    authPasswordResetAttempts,
    uiFunctionalPage,
    uiAuthPasswordResetAttempt,
  } = state;
  const { data } = authPasswordResetAttempts;
  const { downloadingDeleting, tappedId } = uiAuthPasswordResetAttempt;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      device: `${found?.manufacturer} ${found?.model}`,
      os: `${found?.osName} ${found?.osVersion}`,
      validUntil: toMoment(found?.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      done:
            found.done === true
              ? GlobalLocalizedString.common.labelTrue
              : GlobalLocalizedString.common.labelFalse,
      verifiedOn: toMoment(found?.verifiedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
    })
    : {};
  return result;
};


const mapStateToProps = (state) => ({
  downloading: state.uiAuthPasswordResetAttempt.downloading,
  downloadingDeleting: state.uiAuthPasswordResetAttempt.downloadingDeleting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setAuthPasswordResetAttemptAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_PASSWORD_RESET_ATTEMPT));
    dispatch(setAuthPasswordResetAttemptSearchText(''));
    dispatch(clearAuthPasswordResetAttempts());
    dispatch(setAuthPasswordResetAttemptSelectedPageSize(20));
    dispatch(
      setAuthPasswordResetAttemptSelectedOrderBy(
        INITIAL_ORDER_BY_AUTH_PASSWORD_RESET_ATTEMPTS,
      ),
    );
    dispatch(downloadAuthPasswordResetAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('createdDate', 'createdDate')
      .replace('validUntil', 'validUntil')
      .replace('email', 'email')
      .replace('phone', 'phone')
      .replace('resetUsing', 'resetUsing')
      .replace('done', 'done')
      .replace('deviceId', 'deviceId');

    dispatch(
      setAuthPasswordResetAttemptAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearAuthPasswordResetAttempts());
    dispatch(downloadAuthPasswordResetAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAuthPasswordResetAttemptsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAuthPasswordResetAttemptSelectedPageSize(pageSize));
    dispatch(downloadAuthPasswordResetAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setAuthPasswordResetAttemptSelectedPageSize(pageSize));
    dispatch(clearAuthPasswordResetAttempts());
    dispatch(downloadAuthPasswordResetAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearAuthPasswordResetAttempts());
    dispatch(
      setAuthPasswordResetAttemptAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(
      setAuthPasswordResetAttemptSelectedOrderBy(
        INITIAL_ORDER_BY_AUTH_PASSWORD_RESET_ATTEMPTS,
      ),
    );
    dispatch(downloadAuthPasswordResetAttemptsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAuthPasswordResetAttemptSearchText(text));
      dispatch(clearAuthPasswordResetAttempts());
      await dispatch(downloadAuthPasswordResetAttemptsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAuthPasswordResetAttemptSelectedOrderBy(orderBy));
    dispatch(clearAuthPasswordResetAttempts());
    dispatch(downloadAuthPasswordResetAttemptsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setAuthPasswordResetAttemptTappedId(id));
    dispatch(downloadAuthPasswordResetAttemptAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_AUTH_PASSWORD_RESET_ATTEMPT));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthPasswordResetAttemptPage);
