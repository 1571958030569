import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODES = 'DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODES';
export const downloadingGiiasSemarangScannedCodes = makeActionCreator(DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODES, 'status');

export const DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODE = 'DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODE';
export const downloadingGiiasSemarangScannedCode = makeActionCreator(DOWNLOADING_GIIAS_SEMARANG_SCANNED_CODE, 'status');

export const SET_GIIAS_SEMARANG_SCANNED_CODES = 'SET_GIIAS_SEMARANG_SCANNED_CODES';
export const setGiiasSemarangScannedCodes = makeActionCreator(SET_GIIAS_SEMARANG_SCANNED_CODES, 'data');

export const SET_GIIAS_SEMARANG_SCANNED_CODE = 'SET_GIIAS_SEMARANG_SCANNED_CODE';
export const setGiiasSemarangScannedCode = makeActionCreator(SET_GIIAS_SEMARANG_SCANNED_CODE, 'data');

export const CLEAR_GIIAS_SEMARANG_SCANNED_CODES = 'CLEAR_GIIAS_SEMARANG_SCANNED_CODES';
export const clearGiiasSemarangScannedCodes = makeActionCreator(CLEAR_GIIAS_SEMARANG_SCANNED_CODES, 'data');


export const SET_GIIAS_SEMARANG_SCANNED_CODES_PAGE_SIZE = 'SET_GIIAS_SEMARANG_SCANNED_CODES_PAGE_SIZE';
export const setGiiasSemarangScannedCodesPageSize = makeActionCreator(SET_GIIAS_SEMARANG_SCANNED_CODES_PAGE_SIZE, 'size');

export const SET_GIIAS_SEMARANG_SCANNED_CODES_ORDER_BY = 'SET_GIIAS_SEMARANG_SCANNED_CODES_ORDER_BY';
export const setGiiasSemarangScannedCodesOrderBy = makeActionCreator(SET_GIIAS_SEMARANG_SCANNED_CODES_ORDER_BY, 'order');

export const SET_GIIAS_SEMARANG_SCANNED_CODES_SEARCH_TEXT = 'SET_GIIAS_SEMARANG_SCANNED_CODES_SEARCH_TEXT';
export const setGiiasSemarangScannedCodesSearchText = makeActionCreator(SET_GIIAS_SEMARANG_SCANNED_CODES_SEARCH_TEXT, 'text');

export const SET_GIIAS_SEMARANG_SCANNED_CODES_TAPPED_ID = 'SET_GIIAS_SEMARANG_SCANNED_CODES_TAPPED_ID';
export const setGiiasSemarangScannedCodesTappedId = makeActionCreator(SET_GIIAS_SEMARANG_SCANNED_CODES_TAPPED_ID, 'id');

export const SET_GIIAS_SEMARANG_SCANNED_CODES_FILTER_STRING = 'SET_GIIAS_SEMARANG_SCANNED_CODES_FILTER_STRING';
export const setGiiasSemarangScannedCodesFilterString = makeActionCreator(SET_GIIAS_SEMARANG_SCANNED_CODES_FILTER_STRING, 'text');

export const SAVING_GIIAS_SEMARANG_2022_SCANNED_CODES = 'SAVING_GIIAS_SEMARANG_2022_TSCANNED_CODE';
export const savingGiiasSemarang2022ScannedCodes = makeActionCreator(SAVING_GIIAS_SEMARANG_2022_SCANNED_CODES, 'status');


export const DOWNLOADING_GIIAS_SEMARANG_PRIZE_REDEMPTIONS = 'DOWNLOADING_GIIAS_SEMARANG_PRIZE_REDEMPTIONS';
export const downloadingGiiasSemarangPrizeRedemptions = makeActionCreator(DOWNLOADING_GIIAS_SEMARANG_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_GIIAS_SEMARANG_PRIZE_REDEMPTION = 'DOWNLOADING_GIIAS_SEMARANG_PRIZE_REDEMPTION';
export const downloadingGiiasSemarangPrizeRedemption = makeActionCreator(DOWNLOADING_GIIAS_SEMARANG_PRIZE_REDEMPTION, 'status');

export const ADD_GIIAS_SEMARANG_PRIZE_REDEMPTION = 'ADD_GIIAS_SEMARANG_PRIZE_REDEMPTION';
export const addGiiasSemarangPrizeRedemption = makeActionCreator(ADD_GIIAS_SEMARANG_PRIZE_REDEMPTION, 'data');

export const SET_GIIAS_SEMARANG_PRIZE_REDEMPTIONS = 'SET_GIIAS_SEMARANG_PRIZE_REDEMPTIONS';
export const setGiiasSemarangPrizeRedemptions = makeActionCreator(SET_GIIAS_SEMARANG_PRIZE_REDEMPTIONS, 'data');

export const CLEAR_GIIAS_SEMARANG_PRIZE_REDEMPTIONS = 'CLEAR_GIIAS_SEMARANG_PRIZE_REDEMPTIONS';
export const clearGiiasSemarangPrizeRedemptions = makeActionCreator(
  CLEAR_GIIAS_SEMARANG_PRIZE_REDEMPTIONS,
);


export const SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE';
export const setGiiasSemarangPrizeRedemptionSelectedPageSize = makeActionCreator(SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SELECTED_ORDER_BY = 'SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SELECTED_ORDER_BY';
export const setGiiasSemarangPrizeRedemptionSelectedOrderBy = makeActionCreator(SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_TAPPED_ID = 'SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_TAPPED_ID';
export const setGiiasSemarangPrizeRedemptionTappedId = makeActionCreator(SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_TAPPED_ID, 'id');

export const SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SEARCH_TEXT = 'SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SEARCH_TEXT';
export const setGiiasSemarangPrizeRedemptionSearchText = makeActionCreator(SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING';
export const setGiiasSemarangPrizeRedemptionAdvancedFilterString = makeActionCreator(SET_GIIAS_SEMARANG_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_GIIAS_SEMARANG_2022_PRIZE_REDEMPTIONS = 'SAVING_GIIAS_SEMARANG_2022_PRIZE_REDEMPTIONS';
export const savingGiiasSemarang2022PrizeRedemptions = makeActionCreator(SAVING_GIIAS_SEMARANG_2022_PRIZE_REDEMPTIONS, 'status');


export const DOWNLOADING_GIIAS_SEMARANG_2022_TRANSACTIONS = 'DOWNLOADING_GIIAS_SEMARANG_2022_TRANSACTIONS';
export const downloadingGiiasSemarang2022Transactions = makeActionCreator(DOWNLOADING_GIIAS_SEMARANG_2022_TRANSACTIONS, 'status');

export const DOWNLOADING_GIIAS_SEMARANG_2022_TRANSACTION = 'DOWNLOADING_GIIAS_SEMARANG_2022_TRANSACTION';
export const downloadingGiiasSemarang2022Transaction = makeActionCreator(DOWNLOADING_GIIAS_SEMARANG_2022_TRANSACTION, 'status');

export const SAVING_GIIAS_SEMARANG_2022_TRANSACTIONS = 'SAVING_GIIAS_SEMARANG_2022_TRANSACTIONS';
export const savingGiiasSemarang2022Transactions = makeActionCreator(SAVING_GIIAS_SEMARANG_2022_TRANSACTIONS, 'status');

export const ADD_GIIAS_SEMARANG_2022_TRANSACTION = 'ADD_GIIAS_SEMARANG_2022_TRANSACTION';
export const addGiiasSemarang2022Transaction = makeActionCreator(ADD_GIIAS_SEMARANG_2022_TRANSACTION, 'data');

export const SET_GIIAS_SEMARANG_2022_TRANSACTIONS = 'SET_GIIAS_SEMARANG_2022_TRANSACTIONS';
export const setGiiasSemarang2022Transactions = makeActionCreator(SET_GIIAS_SEMARANG_2022_TRANSACTIONS, 'data');

export const CLEAR_GIIAS_SEMARANG_2022_TRANSACTIONS = 'CLEAR_GIIAS_SEMARANG_2022_TRANSACTIONS';
export const clearGiiasSemarang2022Transactions = makeActionCreator(
  CLEAR_GIIAS_SEMARANG_2022_TRANSACTIONS,
);


export const SET_GIIAS_SEMARANG_2022_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_SEMARANG_2022_TRANSACTION_SELECTED_PAGE_SIZE';
export const setGiiasSemarang2022TransactionSelectedPageSize = makeActionCreator(SET_GIIAS_SEMARANG_2022_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_SEMARANG_2022_TRANSACTION_SELECTED_ORDER_BY = 'SET_GIIAS_SEMARANG_2022_TRANSACTION_SELECTED_ORDER_BY';
export const setGiiasSemarang2022TransactionSelectedOrderBy = makeActionCreator(SET_GIIAS_SEMARANG_2022_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_SEMARANG_2022_TRANSACTION_TAPPED_ID = 'SET_GIIAS_SEMARANG_2022_TRANSACTION_TAPPED_ID';
export const setGiiasSemarang2022TransactionTappedId = makeActionCreator(SET_GIIAS_SEMARANG_2022_TRANSACTION_TAPPED_ID, 'id');

export const SET_GIIAS_SEMARANG_2022_TRANSACTION_SEARCH_TEXT = 'SET_GIIAS_SEMARANG_2022_TRANSACTION_SEARCH_TEXT';
export const setGiiasSemarang2022TransactionSearchText = makeActionCreator(SET_GIIAS_SEMARANG_2022_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_SEMARANG_2022_TRANSACTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_SEMARANG_2022_TRANSACTION_ADVANCED_FILTER_STRING';
export const setGiiasSemarang2022TransactionAdvancedFilterString = makeActionCreator(SET_GIIAS_SEMARANG_2022_TRANSACTION_ADVANCED_FILTER_STRING, 'text');
