import { transformSearchText } from '../../../../../helper';
import { downloadGiias2022Transactions } from '../../../helper';
import { downloadingGiias2022Transactions, setGiias2022Transactions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, giias2022Transactions, uiGiias2022Transaction } = getState();
  const { token } = authentication;
  const { meta } = giias2022Transactions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGiias2022Transaction;


  try {
    dispatch(downloadingGiias2022Transactions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadGiias2022Transactions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setGiias2022Transactions(list));
    }
  } finally {
    dispatch(downloadingGiias2022Transactions(false));
  }
};
