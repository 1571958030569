import { connect } from 'react-redux';
import { reset } from 'redux-form';
import moment from 'moment';
import {
  clearCities, clearProvinces, clearRegistrations, clearUsers, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage, setAlertInputMessage,
  setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText,
  setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText,
  setRegistrationSearchText, setRegistrationSelectedPageSize, setRegistrationSelectedOrderBy,
  setRegistrationTappedId, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  approveRegistrationAsync, downloadRegistrationAsync, downloadRegistrationsAsync,
  rejectRegistrationAsync, addRegistrationNotesAsync, setAlertConfirmationMessage,
  refreshVehicleInfoAsync, saveCarRegistrationsAsync, changeRegistrationVinAsync,
} from '../../redux/action';
import {
  downloadCitiesAsync, downloadProvincesAsync, downloadUsersAsync,
} from '../../../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_REGISTRATIONS,
  MENUID_CAR_REGISTRATION_REGISTRATION, PAGE_MODE_TABLE,
  RXFIELD_REGISTRATION_VEHICLE_SOURCE, RXFORM_REGISTRATION, SIMPLE_DATE_FORMAT,
  REGISTRATION_VEHICLE_SOURCE_MANUAL,
  RXFORM_CAR_OWNERSHIP_APPROVAL_MODAL,
} from '../../constant';
import {
  toCurrency, toMoment, transformInitialValues, transformDropdownData, transformImageUrlPath,
  transformUserDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import RegistrationPage from './registration.presentation';

const transformDate = (date) => (date ? toMoment(date).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
  : null);

const getInitialValues = (state) => {
  const { registrations, uiFunctionalPage, uiRegistration } = state;
  const { data } = registrations;
  const { downloadingDeleting, tappedId } = uiRegistration;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    vehicle: {
      ...found.vehicle,
      isPersonalAccount: found.vehicle.isPersonalAccount ? GlobalLocalizedString.common.labelYes
        : GlobalLocalizedString.common.labelNo,
      price: found.vehicle.price ? toCurrency(found.vehicle.price) : 0,
      warantyStartDate: transformDate(found.vehicle.warantyStartDate),
      warantyEndDate: transformDate(found.vehicle.warantyEndDate),
      stnkValidUntil: found.vehicle.stnkValidUntil
        ? moment(found.vehicle.stnkValidUntil).format(SIMPLE_DATE_FORMAT) : null,
      stnkImage: transformImageUrlPath(found.vehicle.stnkImage),
    },
    approvedOn: transformDate(found.approvedOn),
    rejectedOn: transformDate(found.rejectedOn),
    dateOfBirth: found.dateOfBirth
      ? toMoment(found.dateOfBirth).format(SIMPLE_DATE_FORMAT) : null,
    stnkImage: transformImageUrlPath(found.stnkImage),
    stnkValidUntil: found.stnkValidUntil
      ? moment(found.stnkValidUntil).format(SIMPLE_DATE_FORMAT) : null,
    ownershipProofImage: transformImageUrlPath(found.ownershipProofImage),
    nikImage: transformImageUrlPath(found.nikImage),
    other1Image: transformImageUrlPath(found.other1Image),
    other2Image: transformImageUrlPath(found.other2Image),
    city: found.userDistrict ? {
      province: found.userDistrict.city?.province || { name: '' },
      name: found.userDistrict.city?.name || '',
    } : {
      province: found.city?.province || { name: '' },
      name: found.city?.name || '',
    },
    district: found.userDistrict?.name || found.district,
    [RXFIELD_REGISTRATION_VEHICLE_SOURCE]: found?.vehicleSource || '',
    colorCode: found.exteriorColorCode,
  }) : {
    vehicle: { stnkImage: '' },
    stnkImage: '',
    stnkValidUntil: '',
    ownershipProofImage: '',
    nikImage: '',
    other1Image: '',
    other2Image: '',
    city: {
      province: { name: '' },
      name: '',
    },
    district: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  cities: transformDropdownData(state.cities.data),
  provinces: transformDropdownData(state.provinces.data),
  users: transformUserDropdownData(state.users.data),
  approving: state.uiRegistration.approving,
  changingVin: state.uiRegistration.changingVin,
  downloading: state.uiRegistration.downloading,
  downloadingDeleting: state.uiRegistration.downloadingDeleting,
  rejecting: state.uiRegistration.rejecting,
  addingRegistrationNote: state.uiRegistration.addingRegistrationNote,
  refreshingVehicleInfo: state.uiRegistration.refreshingVehicleInfo,
  loadingCity: state.uiCity.downloading,
  loadingProvince: state.uiProvince.downloading,
  loadingUser: state.uiUser.downloading,
  userPermissions: state.currentUser.permissions,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setCitySearchText(''));
    dispatch(setProvinceSearchText(''));
    dispatch(clearUsers());
    dispatch(clearCities());
    dispatch(clearProvinces());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddNoteButtonPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.registrationPage.msgAddNotesConfirmation,
      LocalizedString.registrationPage.placeholderNotes));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_REGISTRATION_REGISTRATION));
    dispatch(setRegistrationSearchText(''));
    dispatch(clearRegistrations());
    dispatch(setRegistrationSelectedPageSize(20));
    dispatch(setRegistrationSelectedOrderBy(INITIAL_ORDER_BY_REGISTRATIONS));
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id').replace(/city/, 'city.id')
      .replace(/province/, 'city.province.id').replace(/licensePlate/, 'vehicle.licensePlate')
      .replace('vin', 'vehicle.vin');

    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearRegistrations());
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApprovePressed: (vehicleSource, setmanualAlertBoxMessage) => {
    if (vehicleSource === REGISTRATION_VEHICLE_SOURCE_MANUAL) {
      setmanualAlertBoxMessage(LocalizedString.registrationPage.msgApproveConfirmation);
    } else {
      dispatch(setAlertInputMessage(LocalizedString.registrationPage.msgApproveConfirmation,
        LocalizedString.registrationPage.placeholderNotes));
    }
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_REGISTRATION));
  },
  onChangeCityText: async (text) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRegistrationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRegistrationSelectedPageSize(pageSize));
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProvinceText: async (text) => {
    try {
      dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeVinPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.registrationPage.msgChangeVinConfirmation,
      LocalizedString.registrationPage.placeholderVin));
  },
  onCloseApprovalModalPressed: () => {
    dispatch(reset(RXFORM_CAR_OWNERSHIP_APPROVAL_MODAL));
  },
  onConfirmPressed: (reason, message, warrantyStartDate = null, vehiclePrice = null) => {
    switch (message) {
      case LocalizedString.registrationPage.msgApproveConfirmation:
        dispatch(approveRegistrationAsync(reason, warrantyStartDate, vehiclePrice))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.registrationPage.msgRejectConfirmation:
        dispatch(rejectRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.registrationPage.msgAddNotesConfirmation:
        dispatch(addRegistrationNotesAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.registrationPage.msgRefreshVehicleInfoConfirmation:
        dispatch(refreshVehicleInfoAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.registrationPage.msgChangeVinConfirmation:
        dispatch(changeRegistrationVinAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;

      default: break;
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setRegistrationSelectedPageSize(pageSize));
    dispatch(clearRegistrations());
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefreshVehiclePressed: () => {
    dispatch(setAlertConfirmationMessage(
      LocalizedString.registrationPage.msgRefreshVehicleInfoConfirmation,
    ));
  },
  onRejectPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.registrationPage.msgRejectConfirmation,
      LocalizedString.registrationPage.placeholderNotes));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearRegistrations());
    dispatch(setRegistrationSelectedOrderBy(INITIAL_ORDER_BY_REGISTRATIONS));
    dispatch(downloadRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setRegistrationSearchText(text));
      dispatch(clearRegistrations());
      await dispatch(downloadRegistrationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setRegistrationSelectedOrderBy(orderBy));
    dispatch(clearRegistrations());
    dispatch(downloadRegistrationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setRegistrationTappedId(id));
    dispatch(downloadRegistrationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveCarRegistrationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
