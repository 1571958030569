import LocalizedString from './localization';
import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_REGISTRATIONS = '/mycar/api/v1/Registration?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=user.fullName|user.userCode|vehicle.model|vehicle.licensePlate&filterString=status=enabled';
export const REST_URL_VIEW_REGISTRATION = '/mycar/api/v1/Registration/{id}';
export const REST_URL_APPROVE_REJECT_REGISTRATION = '/mycar/api/v1/RegistrationApproval';
export const REST_URL_ADD_REGISTRATION_NOTES = '/mycar/api/v1/RegistrationNotes';
export const REST_URL_CHANGE_REGISTRATION_VIN = '/mycar/api/v1/Registration/ChangeVin';
export const REST_URL_CAR_OWNERSHIP_REWARDS = '/mycar/api/v1/CarOwnershipReward?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_CAR_OWNERSHIP_REWARD = '/mycar/api/v1/CarOwnershipReward/{id}';
export const REST_URL_MEMBERSHIP_REWARDS = '/mycar/api/v1/MembershipReward?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_MEMBERSHIP_REWARD = '/mycar/api/v1/MembershipReward/{id}';
export const REST_URL_CAR_OWNERSHIPS = '/mycar/api/v1/Car?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_CAR_OWNERSHIP = '/mycar/api/v1/Car/{id}';
export const REST_URL_REFRESH_VEHICLE_INFO = '/mycar/api/v1/RegistrationRefreshVehicle';
export const REST_URL_REGISTRATION_ATTEMPTS = '/mycar/api/v1/VinRegistrationAttempt?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_REGISTRATION_ATTEMPT = '/mycar/api/v1/VinRegistrationAttempt/{id}';
export const REST_URL_DMS_USER_SUBMISSIONS = '/mycar/api/v1/DmsUserSubmissionTask?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=registration.vehicle.model|registration.vehicle.vin&filterString=status=enabled';
export const REST_URL_VIEW_DMS_USER_SUBMISSION = '/mycar/api/v1/DmsUserSubmissionTask/{id}';
export const REST_URL_RETRY_DMS_USER_SUBMISSION = '/mycar/api/v1/DmsUserSubmissionTaskRetry/{id}';

export const ROUTE_NAME_CAR_REGISTRATION = '/car-registration';
export const ROUTE_NAME_REGISTRATION = '/registration';
export const ROUTE_NAME_CAR_OWNERSHIP_REWARD = '/car-ownership-reward';
export const ROUTE_NAME_MEMBERSHIP_REWARD = '/membership-reward';
export const ROUTE_NAME_CAR_OWNERSHIP = '/car-ownership';
export const ROUTE_NAME_REGISTRATION_ATTEMPT = '/registration-attempt';
export const ROUTE_NAME_DMS_USER_SUBMISSION = '/dms-user-submission';

export const RXFORM_REGISTRATION = 'registrationPage';
export const RXFORM_CAR_OWNERSHIP_REWARD = 'carOwnershipRewardPage';
export const RXFORM_MEMBERSHIP_REWARD = 'membershipRewardPage';
export const RXFORM_CAR_OWNERSHIP = 'carOwnershipPage';
export const RXFORM_CAR_OWNERSHIP_APPROVAL_MODAL = 'carOwnershipApprovalModal';
export const RXFORM_REGISTRATION_ATTEMPT = 'registrationAttemptPage';
export const RXFORM_DMS_USER_SUBMISSION = 'DMSUserSubmissionPage';

export const RXFIELD_REGISTRATION_VEHICLE_VIN = 'vehicle.vin';
export const RXFIELD_REGISTRATION_VEHICLE_MODEL = 'vehicle.model';
export const RXFIELD_REGISTRATION_VEHICLE_TRIM = 'vehicle.trim';
export const RXFIELD_REGISTRATION_VEHICLE_COLOR = 'vehicle.color';
export const RXFIELD_REGISTRATION_VEHICLE_COLOR_CODE = 'vehicle.exteriorColorCode';
export const RXFIELD_REGISTRATION_VEHICLE_LICENSE_PLATE = 'vehicle.licensePlate';
export const RXFIELD_REGISTRATION_VEHICLE_TRANSMISSION = 'vehicle.transmission';
export const RXFIELD_REGISTRATION_VEHICLE_STNK = 'vehicle.stnk';
export const RXFIELD_REGISTRATION_VEHICLE_PHONE = 'vehicle.phone';
export const RXFIELD_REGISTRATION_VEHICLE_WARRANTY_START_DATE = 'vehicle.warantyStartDate';
export const RXFIELD_REGISTRATION_VEHICLE_WARRANTY_END_DATE = 'vehicle.warantyEndDate';
export const RXFIELD_REGISTRATION_VEHICLE_WARRANTY_NOTE = 'vehicle.warantyNote';
export const RXFIELD_REGISTRATION_VEHICLE_BLUE_LINK_TYPE = 'vehicle.blueLinkType';
export const RXFIELD_REGISTRATION_VEHICLE_BLUE_LINK_TYPE_CODE = 'vehicle.blueLinkTypeCode';
export const RXFIELD_REGISTRATION_VEHICLE_BLUE_LINK_TYPE_SUB = 'vehicle.blueLinkTypeSub';
export const RXFIELD_REGISTRATION_VEHICLE_BLUE_LINK_TYPE_SUB_CODE = 'vehicle.blueLinkTypeSubCode';
export const RXFIELD_REGISTRATION_VEHICLE_PERSON_ID = 'vehicle.personId';
export const RXFIELD_REGISTRATION_VEHICLE_IS_PERSONAL_ACCOUNT = 'vehicle.isPersonalAccount';
export const RXFIELD_REGISTRATION_VEHICLE_NIK = 'vehicle.nik';
export const RXFIELD_REGISTRATION_VEHICLE_OWNER_NAME = 'vehicle.ownerName';
export const RXFIELD_REGISTRATION_VEHICLE_INVOICE_NO = 'vehicle.fakturNo';
export const RXFIELD_REGISTRATION_VEHICLE_ENGINE_NO = 'vehicle.engineNo';
export const RXFIELD_REGISTRATION_VEHICLE_OWNER_EMAIL = 'vehicle.ownerEmail';
export const RXFIELD_REGISTRATION_VEHICLE_DEALER_CODE = 'vehicle.dealerCode';
export const RXFIELD_REGISTRATION_VEHICLE_DEALER_NAME = 'vehicle.dealerName';
export const RXFIELD_REGISTRATION_VEHICLE_DEALER_ADDRESS = 'vehicle.dealerAddress';
export const RXFIELD_REGISTRATION_VEHICLE_MODEL_YEAR = 'vehicle.modelYear';
export const RXFIELD_REGISTRATION_VEHICLE_PRICE = 'vehicle.price';
export const RXFIELD_REGISTRATION_VEHICLE_STNK_NAME = 'vehicle.stnkName';
export const RXFIELD_REGISTRATION_VEHICLE_STNK_VALID_UNTIL = 'vehicle.stnkValidUntil';
export const RXFIELD_REGISTRATION_VEHICLE_STNK_IMAGE = 'vehicle.stnkImage';
export const RXFIELD_REGISTRATION_VEHICLE_STNK_NUMBER = 'vehicle.stnkNumber';
export const RXFIELD_REGISTRATION_USER_ID = 'user.id';
export const RXFIELD_REGISTRATION_USER_NAME = 'user.fullName';
export const RXFIELD_REGISTRATION_USER_CODE = 'user.userCode';
export const RXFIELD_REGISTRATION_FULL_NAME = 'fullName';
export const RXFIELD_REGISTRATION_NIK = 'nik';
export const RXFIELD_REGISTRATION_SALUTATION = 'salutation';
export const RXFIELD_REGISTRATION_PHONE = 'phone';
export const RXFIELD_REGISTRATION_EMAIL = 'email';
export const RXFIELD_REGISTRATION_STREET_ADDRESS = 'streetAddress';
export const RXFIELD_REGISTRATION_DISTRICT = 'district';
export const RXFIELD_REGISTRATION_CITY = 'city.name';
export const RXFIELD_REGISTRATION_PROVINCE = 'city.province.name';
export const RXFIELD_REGISTRATION_REGISTRATION_STATUS = 'registrationStatus';
export const RXFIELD_REGISTRATION_REGISTRATION_NOTES = 'registrationNotes';
export const RXFIELD_REGISTRATION_APPROVED_ON = 'approvedOn';
export const RXFIELD_REGISTRATION_REJECTED_ON = 'rejectedOn';
export const RXFIELD_REGISTRATION_IDENTITY_STATUS = 'identityStatus';
export const RXFIELD_REGISTRATION_VEHICLE_SOURCE = 'vehicleSource';
export const RXFIELD_REGISTRATION_NOTES = 'notes';
export const RXFIELD_REGISTRATION_TIER = 'user.tags';
export const RXFIELD_REGISTRATION_DATE_OF_BIRTH = 'dateOfBirth';
export const RXFIELD_REGISTRATION_MODEL = 'model';
export const RXFIELD_REGISTRATION_TRIM = 'trim';
export const RXFIELD_REGISTRATION_COLOR = 'color';
export const RXFIELD_REGISTRATION_COLOR_CODE = 'colorCode';
export const RXFIELD_REGISTRATION_YEAR = 'modelYear';
export const RXFIELD_REGISTRATION_NIK_IMAGE = 'nikImage';
export const RXFIELD_REGISTRATION_OTHER_IMAGE_1 = 'other1Image';
export const RXFIELD_REGISTRATION_OTHER_IMAGE_2 = 'other2Image';
export const RXFIELD_REGISTRATION_LICENSE_PLATE = 'licensePlate';
export const RXFIELD_REGISTRATION_STNK_NAME = 'stnkName';
export const RXFIELD_REGISTRATION_STNK_NUMBER = 'stnkNumber';
export const RXFIELD_REGISTRATION_STNK_VALID_UNTIL = 'stnkValidUntil';
export const RXFIELD_REGISTRATION_STNK_IMAGE = 'stnkImage';
export const RXFIELD_REGISTRATION_OWNERSHIP_PROOF_IMAGE = 'ownershipProofImage';
export const RXFIELD_CAR_OWNERSHIP_REWARD_VIN = 'vin';
export const RXFIELD_CAR_OWNERSHIP_REWARD_MODEL = 'registration.vehicle.model';
export const RXFIELD_CAR_OWNERSHIP_REWARD_TRIM = 'registration.vehicle.trim';
export const RXFIELD_CAR_OWNERSHIP_REWARD_COLOR = 'registration.vehicle.color';
export const RXFIELD_CAR_OWNERSHIP_REWARD_LICENSE_PLATE = 'registration.vehicle.licensePlate';
export const RXFIELD_CAR_OWNERSHIP_REWARD_ENGINE_NO = 'registration.vehicle.engineNo';
export const RXFIELD_CAR_OWNERSHIP_REWARD_TIER = 'tier';
export const RXFIELD_CAR_OWNERSHIP_REWARD_REWARD_AMOUNT = 'rewardAmount';
export const RXFIELD_CAR_OWNERSHIP_REWARD_REGISTRATION_ID = 'registration.id';
export const RXFIELD_CAR_OWNERSHIP_REWARD_REGISTRATION_APPROVED_ON = 'registration.approvedOn';
export const RXFIELD_CAR_OWNERSHIP_REWARD_SCHEDULED_REWARD = 'items';
export const RXFIELD_CAR_OWNERSHIP_REWARD_ALLOCATION_STATUS = 'rewardAllocationStatus';
export const RXFIELD_CAR_OWNERSHIP_REWARD_NOTES = 'rewardAllocationStatusNotes';
export const RXFIELD_MEMBERSHIP_REWARD_USER_ID = 'profile.id';
export const RXFIELD_MEMBERSHIP_REWARD_USER_NAME = 'profile.fullName';
export const RXFIELD_MEMBERSHIP_REWARD_ALLOCATION_STATUS = 'rewardAllocationStatus';
export const RXFIELD_MEMBERSHIP_REWARD_NOTES = 'statusNotes';
export const RXFIELD_MEMBERSHIP_REWARD_STOPPED_AT = 'stoppedAt';
export const RXFIELD_MEMBERSHIP_REWARD_TIER = 'tier';
export const RXFIELD_MEMBERSHIP_REWARD_REWARD_AMOUNT = 'rewardAmount';
export const RXFIELD_MEMBERSHIP_REWARD_SCHEDULED_REWARD = 'items';
export const RXFIELD_CAR_OWNERSHIP_VIN = 'vin';
export const RXFIELD_CAR_OWNERSHIP_MODEL = 'model';
export const RXFIELD_CAR_OWNERSHIP_TRIM = 'trim';
export const RXFIELD_CAR_OWNERSHIP_COLOR = 'color';
export const RXFIELD_CAR_OWNERSHIP_COLOR_CODE = 'colorCode';
export const RXFIELD_CAR_OWNERSHIP_LICENSE_PLATE = 'licensePlate';
export const RXFIELD_CAR_OWNERSHIP_MODEL_YEAR = 'modelYear';
export const RXFIELD_CAR_OWNERSHIP_TRANSMISSION = 'transmission';
export const RXFIELD_CAR_OWNERSHIP_STNK = 'stnk';
export const RXFIELD_CAR_OWNERSHIP_ENGINE_NO = 'engineNo';
export const RXFIELD_CAR_OWNERSHIP_WARRANTY_START_DATE = 'warantyStartDate';
export const RXFIELD_CAR_OWNERSHIP_WARRANTY_END_DATE = 'warantyEndDate';
export const RXFIELD_CAR_OWNERSHIP_WARRANTY_NOTE = 'warantyNote';
export const RXFIELD_CAR_OWNERSHIP_REGISTRATION_ID = 'registration.id';
export const RXFIELD_CAR_OWNERSHIP_REGISTRATION_APPROVED_ON = 'registration.approvedOn';
export const RXFIELD_CAR_OWNERSHIP_USER_ID = 'user.id';
export const RXFIELD_CAR_OWNERSHIP_USER_NAME = 'user.fullName';
export const RXFIELD_CAR_OWNERSHIP_USER_APPROVED_ON = 'user.approvedOn';
export const RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STATUS = 'ownershipStatus';
export const RXFIELD_CAR_OWNERSHIP_INACTIVATED_ON = 'inactivatedOn';
export const RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_NUMBER = 'stnkNumber';
export const RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_NAME = 'stnkName';
export const RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_VALID_UNTIL = 'stnkValidUntil';
export const RXFIELD_CAR_OWNERSHIP_OWNERSHIP_PKB_FIRST_REMINDER_SENT_ON = 'firstPkbReminderSentOn';
export const RXFIELD_CAR_OWNERSHIP_OWNERSHIP_PKB_SECOND_REMINDER_SENT_ON = 'secondPkbReminderSentOn';
export const RXFIELD_CAR_OWNERSHIP_OWNERSHIP_STNK_IMAGE = 'stnkImage';
export const RXFIELD_CAR_OWNERSHIP_NOTES = 'notes';
export const RXFIELD_CAR_OWNERSHIP_VEHICLE_PRICE = 'vehiclePrice';
export const RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE = 'blueLinkType';
export const RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_CODE = 'blueLinkTypeCode';
export const RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_SUB = 'blueLinkTypeSub';
export const RXFIELD_CAR_OWNERSHIP_VEHICLE_BLUE_LINK_TYPE_SUB_CODE = 'blueLinkTypeSubCode';
export const RXFIELD_REGISTRATION_ATTEMPT_VIN = 'vin';
export const RXFIELD_REGISTRATION_ATTEMPT_USER_ID = 'user.id';
export const RXFIELD_REGISTRATION_ATTEMPT_USER_NAME = 'user.fullName';
export const RXFIELD_DMS_USER_SUBMISSION_USER_ID = 'user.id';
export const RXFIELD_DMS_USER_SUBMISSION_USER_NAME = 'user.fullName';
export const RXFIELD_DMS_USER_SUBMISSION_VIN = 'registration.vehicle.vin';
export const RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_STATUS = 'submissionStatus';
export const RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_STATUS_NOTES = 'submissionStatusNotes';
export const RXFIELD_DMS_USER_SUBMISSION_SUBMITTED_ON = 'submittedOn';
export const RXFIELD_DMS_USER_SUBMISSION_SUBMISSION_DELAY = 'submissionDelaySecond';
export const RXFIELD_DMS_USER_SUBMISSION_RETRY_COUNT = 'retryCount';
export const RXFIELD_DMS_USER_SUBMISSION_MODEL = 'registration.vehicle.model';
export const RXFIELD_DMS_USER_SUBMISSION_FULL_NAME = 'fullName';
export const RXFIELD_DMS_USER_SUBMISSION_REGISTRATION_ID = 'registration.id';
export const RXFIELD_DMS_USER_SUBMISSION_TIER = 'tier';


export const RXSTATE_REGISTRATIONS = 'registrations';
export const RXSTATE_REGISTRATION_PAGE = 'uiRegistration';
export const RXSTATE_DMS_USER_SUBMISSION = 'dmsUserSubmissions';
export const RXSTATE_DMS_USER_SUBMISSION_PAGE = 'uiDMSUserSubmission';
export const RXSTATE_CAR_OWNERSHIP_REWARDS = 'carOwnershipRewards';
export const RXSTATE_CAR_OWNERSHIP_REWARD_PAGE = 'uiCarOwnershipReward';
export const RXSTATE_MEMBERSHIP_REWARDS = 'membershipRewards';
export const RXSTATE_MEMBERSHIP_REWARD_PAGE = 'uiMembershipReward';
export const RXSTATE_CAR_OWNERSHIPS = 'carOwnerships';
export const RXSTATE_CAR_OWNERSHIP_PAGE = 'uiCarOwnership';
export const RXSTATE_REGISTRATION_ATTEMPTS = 'registrationAttempts';
export const RXSTATE_REGISTRATION_ATTEMPT_PAGE = 'uiRegistrationAttempt';

export const INITIAL_ORDER_BY_REGISTRATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CAR_OWNERSHIP_REWARDS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_MEMBERSHIP_REWARDS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CAR_OWNERSHIPS = `registration.approvedOn ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_REGISTRATION_ATTEMPTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_DMS_USER_SUBMISSIONS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_CAR_REGISTRATION = 'MENUID_CAR_REGISTRATION';
export const MENUID_CAR_REGISTRATION_REGISTRATION = 'MENUID_CAR_REGISTRATION_REGISTRATION';
export const MENUID_CAR_REGISTRATION_CAR_OWNERSHIP_REWARD = 'MENUID_CAR_REGISTRATION_CAR_OWNERSHIP_REWARD';
export const MENUID_CAR_REGISTRATION_MEMBERSHIP_REWARD = 'MENUID_CAR_REGISTRATION_MEMBERSHIP_REWARD';
export const MENUID_CAR_REGISTRATION_CAR_OWNERSHIP = 'MENUID_CAR_REGISTRATION_CAR_OWNERSHIP';
export const MENUID_CAR_REGISTRATION_REGISTRATION_ATTEMPT = 'MENUID_CAR_REGISTRATION_REGISTRATION_ATTEMPT';
export const MENUID_CAR_REGISTRATION_DMS_USER_SUBMISSION = 'MENUID_CAR_REGISTRATION_DMS_USER_SUBMISSION';

export const APPROVAL_STATUS_APPROVED = 'Approve';
export const APPROVAL_STATUS_REJECTED = 'Reject';

export const REGISTRATION_STATUS_PENDING = 'Pending';
export const REGISTRATION_STATUS_APPROVED = 'Approved';
export const REGISTRATION_STATUS_REJECTED = 'Rejected';

export const REGISTRATION_IDENTITY_STATUS_MATCHED = 'Matched';
export const REGISTRATION_IDENTITY_STATUS_PARTIAL_MATCH = 'PartialMatch';
export const REGISTRATION_IDENTITY_STATUS_NOT_MATCHED = 'NotMatched';

export const CAR_OWNERSHIP_REWARD_TIER_BLUE = 'Blue';
export const CAR_OWNERSHIP_REWARD_TIER_BLUE_PLUS = 'Blue+';

export const REWARD_ALLOCATION_STATUS_IN_PROGRESS = 'InProgress';
export const REWARD_ALLOCATION_STATUS_COMPLETED = 'Completed';
export const REWARD_ALLOCATION_STATUS_STOPPED = 'Stopped';

export const SUBMISSION_STATUS_PENDING = 'Pending';
export const SUBMISSION_STATUS_PROCESSING = 'Processing';
export const SUBMISSION_STATUS_SUCCESS = 'Success';
export const SUBMISSION_STATUS_ERROR = 'Error';
export const SUBMISSION_STATUS_CANCELLED = 'Cancelled';

export const REGISTRATION_STATUS = [REGISTRATION_STATUS_APPROVED,
  REGISTRATION_STATUS_REJECTED, REGISTRATION_STATUS_PENDING];

export const REGISTRATION_IDENTITY_STATUS = [
  REGISTRATION_IDENTITY_STATUS_MATCHED,
  REGISTRATION_IDENTITY_STATUS_PARTIAL_MATCH,
  REGISTRATION_IDENTITY_STATUS_NOT_MATCHED];

export const CAR_OWNERSHIP_REWARD_TIER = [CAR_OWNERSHIP_REWARD_TIER_BLUE,
  CAR_OWNERSHIP_REWARD_TIER_BLUE_PLUS];

export const CAR_OWNERSHIP_REWARD_ALLOCATION_STATUS = [
  REWARD_ALLOCATION_STATUS_IN_PROGRESS,
  REWARD_ALLOCATION_STATUS_COMPLETED];

export const MEMBERSHIP_REWARD_ALLOCATION_STATUS = [
  REWARD_ALLOCATION_STATUS_IN_PROGRESS,
  REWARD_ALLOCATION_STATUS_COMPLETED,
  REWARD_ALLOCATION_STATUS_STOPPED];

export const SUBMISSION_STATUS = [SUBMISSION_STATUS_PENDING,
  SUBMISSION_STATUS_PROCESSING, SUBMISSION_STATUS_SUCCESS, SUBMISSION_STATUS_ERROR,
  SUBMISSION_STATUS_CANCELLED];

export const OWNERSHIP_STATUS_ACTIVE = 'Active';
export const OWNERSHIP_STATUS_INACTIVE = 'InActive';

export const OWNERSHIP_STATUS = [
  { label: LocalizedString.carOwnershipPage.labelActive, value: OWNERSHIP_STATUS_ACTIVE },
  { label: LocalizedString.carOwnershipPage.labelInactive, value: OWNERSHIP_STATUS_INACTIVE },
];

export const REGISTRATION_VEHICLE_SOURCE_MANUAL = 'Manual';

export const REGISTRATION_VEHICLE_SOURCE = [
  { label: 'DMS', value: 'Dms' },
  { label: 'Manual', value: 'Manual' },
];

export const REST_URL_APPROVE_RESERVATION = '/testdrive/api/v1/UpdateReservationStatus';
