import { reset } from 'redux-form';
import { RXFORM_POINT_SUBMISSION } from '../../../constant';
import { addPointSubmission, localDateToUtc } from '../../../helper';
import {
  addingEditingPointSubmissions, clearPointSubmissions, setAlertErrorMessage,
} from '../simple-action';
import downloadPointSubmissionsAsync from './downloadPointSubmissionsAsync';

export default (
  merchantReferenceId, tags, amount, validUntil,
  label, callbackUrl, sendNotification,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingPointSubmissions(true));

    const { token } = getState().authentication;
    const { selectedUser } = getState().uiPointSubmission;

    await addPointSubmission(
      merchantReferenceId, tags, amount, localDateToUtc(validUntil),
      label, selectedUser.value, callbackUrl, sendNotification.value, token,
    );

    dispatch(reset(RXFORM_POINT_SUBMISSION));
    dispatch(clearPointSubmissions());
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingPointSubmissions(false));
  }
};
