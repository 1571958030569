
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    voucherMenu: {
      title: 'Voucher',
    },
    voucherCategoryScreen: {
      title: 'Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',

      buttonCaptionCreate: 'Create New Voucher Category',
      buttonCaptionEdit: 'Edit Voucher Category',
      buttonCaptionDelete: 'Delete Voucher Category',

      placeholderImage: 'Image',
    },
    voucherPage: {
      title: 'Voucher',

      labelNo: 'No',
      labelTitle: 'Title',
      labelPoint: 'Point',
      labelVisibility: 'Visibility',
      labelMerchantName: 'Merchant Name',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelApplicableMode: 'Applicable Mode',
      labelAllBranches: 'All Branches',
      labelSpecificBranches: 'Specific Branch(es)',
      labelBranch: 'Branch',
      labelCopied: 'Copied!',
      labelCoin: 'Coin',
      labelCoupon: 'Coupon',
      labelNone: 'None',

      buttonCaptionCreateNewVoucher: 'Create New Voucher',
      buttonCaptionEditVoucher: 'Edit Voucher',

      placeholderTitle: 'Title',
      placeholderCode: 'Code',
      placeholderType: 'Type',
      placeholderPoint: 'Point',
      placeholderMaxPurchasePerUser: 'Max. Purchase per User',
      placeholderMinAchievementPoinToPurchase: 'Min. Point Achievement to Purchase',
      placeholderMaxAchievementPoinToPurchase: 'Max. Point Achievement to Purchase',
      placeholderActionType: 'Action Type',
      placeholderVisibility: 'Visibility',
      placeholderMerchant: 'Merchant',
      placeholderApplicableAreaMode: 'Applicable Area Mode',
      placeholderApplicableBranches: 'Applicable Branches',
      placeholderCategory: 'Category',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderImage: 'Image',
      placeholderDescription: 'Description',
      placeholderDescriptionFormat: 'Description Format',
      placeholderTermsAndConditions: 'Terms And Conditions',
      placeholderTermsAndConditionsFormat: 'Terms And Conditions Format',
      placeholderStatus: 'Status',
      placeholderBranch: 'Branch',
      placeholderDeepLink: 'Deep Link',
      placeholderPromoted: 'Promoted',
      placeholderOrder: 'Order',
      placeholderTags: 'Tags',
      placeholderManualClaimMessage: 'Manual Claim Message',
      placeholderPaymentType: 'Payment Type',
      placeholderTanameraFlag: 'Tanamera Flag',
      placeholderGarudaFlag: 'Garuda Flag',
      placeholderMonetaryValue: 'Monetary Value',
    },
    tokenRequestPage: {
      title: 'Token Request',

      labelNo: 'No',
      labelTitle: 'Title',
      labelStartTime: 'Start Time',
      labelProgress: 'Progress',
      labelNotes: 'Notes',
      labelStatus: 'Status',
      labelTaskStatus: 'Task Status',
      labelEndTime: 'End Time',

      placeholderVoucher: 'Voucher',
      placeholderQuantity: 'Quantity',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderTaskStatus: 'Task Status',
      placeholderProgress: 'Progress',
      placeholderProgressNotes: 'Progress Notes',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderCreatedDate: 'Created Date',
      placeholderMerchant: 'Merchant',
      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherName: 'Voucher Name',

      buttonCaptionCreate: 'Create New Token Request',
      buttonCaptionCancel: 'Cancel Token Request',
    },
    voucherTokenPage: {
      title: 'Token',

      labelNo: 'No',
      labelCode: 'Code',
      labelVoucherName: 'Voucher Name',
      labelMerchantName: 'Merchant Name',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelPurchased: 'Purchased',
      labelRevoked: 'Revoked',
      labelClaimed: 'Claimed',
      labelRevokeAllItems: 'Revoke all selected vouchers',
      labelMoveAllItems: 'Move all selected vouchers',

      placeholderCode: 'Code',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderPurchased: 'Purchased',
      placeholderPurchasedOn: 'Purchased On',
      placeholderPurchasedBy: 'Purchased By',
      placeholderPurchasedById: 'Purchased By ID',
      placeholderRevoked: 'Revoked',
      placeholderRevokedOn: 'Revoked On',
      placeholderRevokedBy: 'Revoked By',
      placeholderRevokedById: 'Revoked by ID',
      placeholderRevocationNotes: 'Revocation Notes',
      placeholderRemark: 'Remark',
      placeholderVoucherName: 'Voucher Name',
      placeholderMerchantName: 'Merchant Name',
      placeholderClaimed: 'Claimed',
      placeholderClaimedOn: 'Claimed On',
      placeholderClaimedBy: 'Claimed By',
      placeholderClaimedById: 'Claimed By ID',
      placeholderClaimNotes: 'Claim Notes',
      placeholderCreatedDate: 'Created Date',
      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherImage: 'Voucher Image',
      placeholderGarudaAirport: 'Garuda Airport',
      placeholderPurchasedPoint: 'Purchased Point',
      placeholderPurchasedNotes: 'Purchased Notes',
      placeholderPurchasedDate: 'Purchased Date',
      placeholderClaimedDate: 'Claimed Date',
      placeholderRevokedDate: 'Revoked Date',

      buttonCaptionRevoke: 'Revoke',
      buttonCaptionMove: 'Move',

      msgRevokeConfirmation: 'Are you sure you want to revoke this voucher?',
      msgMoveAllConfirmation: 'Are you sure want to move all selected items?',
      msgRevokeAllConfirmation: 'Are you sure want to revoke all selected items?',
    },
    tokenImportPage: {
      title: 'Token Import',

      labelNo: 'No',
      labelCode: 'Code',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelVoucher: 'Voucher',
      labelMerchant: 'Merchant',
      labelStatus: 'Status',
      labelCsvFile: 'CSV File',

      buttonCaptionEditTokenImport: 'Edit Token Import',
      buttonCaptionDeleteTokenImport: 'Delete Token Import',
      buttonCaptionImportToken: 'Import Token',
      buttonCaptionRetry: 'Retry',
      buttonCaptionImport: 'Import',
      buttonCaptionDeleteFilteredData: 'Delete Filtered Data',

      placeholderCode: 'Code',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderVoucherName: 'Voucher Name',
      placeholderMerchantName: 'Merchant Name',
      placeholderImportStatus: 'Import Status',
      placeholderStatusNotes: 'Status Notes',
      placeholderCreatedDate: 'Created Date',
      placeholderVoucherId: 'Voucher ID',

      msgRetryImportConfirmation: 'Are you sure want to retry import this item?',
      msgDeleteFilteredDataConfirmation: 'Are you sure you want to delete the filtered data?',
    },
    voucherPurchaseRequestPage: {
      title: 'Purchase Request',

      labelNo: 'No',
      labelUser: 'User',
      labelVoucherName: 'Voucher Name',
      labelStatus: 'Status',
      labelCreatedDate: 'Created Date',

      placeholderUser: 'User',
      placeholderVoucherTokenId: 'Voucher Token ID',
      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherName: 'Voucher Name',
      placeholderPaymentId: 'Payment ID',
      placeholderVoucherImage: 'Voucher Image',
      placeholderRequestStatus: 'Request Status',
      placeholderRequestStatusNotes: 'Request Status Notes',
      placeholderCreatedDate: 'Created Date',
    },
    promotedMerchantPage: {
      title: 'Promoted Merchant',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelPromoted: 'Promoted',
      labelOrder: 'Order',
      labelStatus: 'Status',

      placeholderName: 'Name',
      placeholderPromoted: 'Promoted',
      placeholderAddress: 'Address',
      placeholderOrder: 'Order',
      placeholderCity: 'City',
      placeholderCompanyLogo: 'Company Logo',
      placeholderWebsite: 'Website',
      placeholderCompanyBanner: 'Company Banner',
      placeholderPlayStoreUrl: 'PlayStore URL',
      placeholderAppStoreUrl: 'AppStore URL',

      buttonCaptionEditPromotedMerchant: 'Edit Promoted Merchant',
    },
    voucherGarudaAirportPage: {
      title: 'Airport',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      labelCity: 'City',
      labelPromoted: 'Promoted',
      labelOrder: 'Order',
      labelStatus: 'Status',
      labelTokens: 'Tokens',
      labelNotes: 'Notes:',
      labelWillOnlyMaps: 'This will only maps active and available tokens (tokens that have not been purchased or revoked and still within their valid dates)',
      labelTokensWereMapped: 'tokens were mapped to',
      labelMapTokenToAirport: 'Map Token to Airport',

      placeholderName: 'Name',
      placeholderPromoted: 'Promoted',
      placeholderAddress: 'Address',
      placeholderOrder: 'Order',
      placeholderCity: 'City',
      placeholderCompanyLogo: 'Company Logo',
      placeholderWebsite: 'Website',
      placeholderCompanyBanner: 'Company Banner',
      placeholderPlayStoreUrl: 'PlayStore URL',
      placeholderAppStoreUrl: 'AppStore URL',
      placeholderAirport: 'Airport',
      placeholderTokenRemark: 'Token Remark',

      buttonCaptionCreateNewGarudaAirport: 'Create New Airport',
      buttonCaptionEditGarudaAirport: 'Edit Airport',
      buttonCaptionDeleteGarudaAirport: 'Delete Airport',
      buttonCaptionMapVoucherTokens: 'Map Voucher Tokens',
      buttonCaptionCount: 'Count',
      buttonCaptionMapToken: 'Map Token',
    },
    voucherTokenAssignment: {
      title: 'Gift Voucher',

      labelNo: 'No',
      labelVoucher: 'Voucher',
      labelAssignedTo: 'Assigned To',
      labelAssignedBy: 'Assigned By',
      labelAssignedMode: 'Assignment Mode',
      labelBypassPayment: 'Bypass Payment',
      labelBypassMaxPurchase: 'Bypass Max Purchase',
      labelBypassAchievement: 'Bypass Achievement',
      labelCreatedDate: 'Created Date',
      labelAssignmentMethod: 'Assignment Method',
      labelStatus: 'Status',

      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherName: 'Voucher Name',
      placeholderVoucherTokenId: 'Voucher Token ID',
      placeholderAssignmentMode: 'Assignment Mode',
      placeholderCode: 'Code',
      placeholderVoucherCodeValidFrom: 'Voucher Code Valid From',
      placeholderVoucherCodeValidUntil: 'Voucher Code Valid Until',
      placeholderBypassPayment: 'Bypass Payment',
      placeholderBypassMaxPurchase: 'Bypass Max Purchase',
      placeholderByPassAchievement: 'Bypass Achievement',
      placeholderNotes: 'Notes',
      placeholderAssignedById: 'Assigned By ID',
      placeholderAssignedByName: 'Assigned By Name',
      placeholderAssignedToId: 'Assigned To ID',
      placeholderAssignedToName: 'Assigned To Name',
      placeholderImage: 'Image',
      placeholderUser: 'User',
      placeholderAssignmentMethod: 'Assignment Method',
      placeholderAssignmentValidFrom: 'Assignment Valid From',
      placeholderAssignmentValidUntil: 'Assignment Valid Until',
      placeholderRedemptionCode: 'Redemption Code',
      placeholderNotifMessageTitle: 'Notification Message Title',
      placeholderNotifMessageBody: 'Notification Message Body',
      placeholderAirport: 'Airport',

      additionalPlaceholderNotifMessageBody: '* Override default Notification Body. You can use these placeholders:<br/>- {fullName}: Recipient\'s Full Name<br/>- {voucherTitle}: The Voucher Name<br>- {merchantName}: The Merchant Name<br/>- {tokenValidUntil}: The Voucher Token expiraton date (in UTC timezone)',

      buttonCaptionCreate: 'Create New Gift Voucher',
      buttonCaptionEdit: 'Edit Gift Voucher',
    },
    giftVoucherRedemptionPage: {
      title: 'Gift Voucher Redemption',

      labelNo: 'No',
      labelVoucherName: 'Voucher Name',
      labelMerchant: 'Merchant',
      labelRedeemedBy: 'Redeemed By',
      labelCreatedDate: 'Created Date',

      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherName: 'Voucher Name',
      placeholderMerchantName: 'Merchant Name',
      placeholderRedeemedById: 'Redeemed By ID',
      placeholderRedeemedByName: 'Redeemed By Name',
      placeholderVoucherTokenId: 'Voucher Token ID',
      placeholderGiftVoucherId: 'Gift Voucher ID',
      placeholderCreatedDate: 'Created Date',
      placeholderRedeemedBy: 'Redeemed By',
    },
    voucherUsagePage: {
      title: 'Voucher Usage',

      labelNo: 'No',
      labelTitle: 'Title',
      labelMerchantName: 'Merchant Name',
      labelTokenCount: 'Token Count',
      labelAvailable: 'Available',
      labelPurchased: 'Purchased',
      labelClaimed: 'Claimed',
      labelRevoked: 'Revoked',
      labelExpired: 'Expired',
      labelAllBranches: 'All Branches',
      labelSpecificBranches: 'Specific Branch(es)',

      placeholderType: 'Type',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderActionType: 'Action Type',
      placeholderVisibility: 'Visibility',
      placeholderMerchant: 'Merchant',
      placeholderApplicableAreaMode: 'Applicable Area Mode',
      placeholderCategory: 'Category',
    },
  },
  id: {
    voucherMenu: {
      title: 'Voucher',
    },
    voucherCategoryScreen: {
      title: 'Kategori',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      buttonCaptionCreate: 'Buat Kategori Voucher',
      buttonCaptionEdit: 'Ubah Kategori Voucher',
      buttonCaptionDelete: 'Hapus Kategori Voucher',

      placeholderImage: 'Gambar',
    },
    voucherPage: {
      title: 'Voucher',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelPoint: 'Poin',
      labelVisibility: 'Terlihat',
      labelMerchantName: 'Nama Penjual',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelApplicableMode: 'Mode Penerapan',
      labelAllBranches: 'Semua Cabang',
      labelSpecificBranches: 'Cabang Tertentu',
      labelBranch: 'Cabang',
      labelCopied: 'Disalin!',
      labelCoin: 'Koin',
      labelCoupon: 'Kupon',
      labelNone: 'Tidak Ada',

      buttonCaptionCreateNewVoucher: 'Buat Voucher Baru',
      buttonCaptionEditVoucher: 'Ubah Voucher',

      placeholderTitle: 'Judul',
      placeholderCode: 'Kode',
      placeholderType: 'Tipe',
      placeholderPoint: 'Poin',
      placeholderMaxPurchasePerUser: 'Maks. Pembelian per Pengguna',
      placeholderMinAchievementPoinToPurchase: 'Min. Pencapaian Poin untuk Pembelian',
      placeholderMaxAchievementPoinToPurchase: 'Maks. Pencapaian Poin untuk Pembelian',
      placeholderActionType: 'Tipe Aksi',
      placeholderVisibility: 'Dapat Dilihat',
      placeholderMerchant: 'Penjual',
      placeholderApplicableAreaMode: 'Mode Area Penerapan',
      placeholderApplicableBranches: 'Cabang Penerapan',
      placeholderCategory: 'Kategori',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderImage: 'Gambar',
      placeholderDescription: 'Deskripsi',
      placeholderDescriptionFormat: 'Format Deskripsi',
      placeholderTermsAndConditions: 'Syarat dan Ketentuan',
      placeholderTermsAndConditionsFormat: 'Format Syarat dan Ketentuan',
      placeholderStatus: 'Status',
      placeholderBranch: 'Cabang',
      placeholderDeepLink: 'Tautan Dalam',
      placeholderPromoted: 'Dipromosikan',
      placeholderOrder: 'Urutan',
      placeholderTags: 'Penanda',
      placeholderManualClaimMessage: 'Pesan Klaim Manual',
      placeholderPaymentType: 'Tipe Pembayaran',
      placeholderTanameraFlag: 'Penanda Tanamera',
      placeholderGarudaFlag: 'Penanda Garuda',
      placeholderMonetaryValue: 'Nilai Moneter',
    },
    tokenRequestPage: {
      title: 'Permintaan Token',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelStartTime: 'Waktu Mulai',
      labelProgress: 'Kemajuan',
      labelNotes: 'Catatan',
      labelStatus: 'Status',
      labelTaskStatus: 'Status Tugas',
      labelEndTime: 'Waktu Selesai',

      placeholderVoucher: 'Voucher',
      placeholderQuantity: 'Jumlah',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderTaskStatus: 'Status Tugas',
      placeholderProgress: 'Kemajuan',
      placeholderProgressNotes: 'Catatan Kemajuan',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderMerchant: 'Penjual',
      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherName: 'Nama Voucher',

      buttonCaptionCreate: 'Buat Permintaan Token Baru',
      buttonCaptionCancel: 'Batalkan Permintaan Token',
    },
    voucherTokenPage: {
      title: 'Token',

      labelNo: 'No',
      labelCode: 'Kode',
      labelVoucherName: 'Nama Voucher',
      labelMerchantName: 'Nama Penjual',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelPurchased: 'Dibeli',
      labelRevoked: 'Dicabut',
      labelClaimed: 'Diklaim',
      labelRevokeAllItems: 'Cabut semua voucher yang dipilih',
      labelMoveAllItems: 'Pindahkan semua voucher yang dipilih',

      placeholderCode: 'Kode',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderPurchased: 'Dibeli',
      placeholderPurchasedOn: 'Dibeli Pada',
      placeholderPurchasedBy: 'Dibeli Oleh',
      placeholderPurchasedById: 'Dibeli Oleh ID',
      placeholderRevoked: 'Dicabut',
      placeholderRevokedOn: 'Dicabut Pada',
      placeholderRevokedBy: 'Dicabut Oleh',
      placeholderRevokedById: 'Dicabut Oleh ID',
      placeholderRevocationNotes: 'Catatan Pencabutan',
      placeholderRemark: 'Komentar',
      placeholderVoucherName: 'Nama Voucher',
      placeholderMerchantName: 'Nama Penjual',
      placeholderClaimed: 'Diklaim',
      placeholderClaimedOn: 'Diklaim Pada',
      placeholderClaimedBy: 'Diklaim Oleh',
      placeholderClaimedById: 'Diklaim Oleh ID',
      placeholderClaimNotes: 'Catatan Klaim',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherImage: 'Gambar Voucher',
      placeholderGarudaAirport: 'Bandara Garuda',
      placeholderPurchasedPoint: 'Poin Dibeli',
      placeholderPurchasedNotes: 'Catatan Pembelian',
      placeholderPurchasedDate: 'Tanggal Pembelian',
      placeholderClaimedDate: 'Tanggal Diklaim',
      placeholderRevokedDate: 'Tanggal Dicabut',

      buttonCaptionRevoke: 'Cabut',
      buttonCaptionMove: 'Pindahkan',

      msgRevokeConfirmation: 'Apa Anda yakin ingin mencabut voucher ini?',
      msgMoveAllConfirmation: 'Apa Anda yakin ingin memindahkan semua voucher yang dipilih?',
      msgRevokeAllConfirmation: 'Apa Anda yakin ingin mencabut semua voucher yang dipilih?',
    },
    tokenImportPage: {
      title: 'Impor Token',

      labelNo: 'No',
      labelCode: 'Kode',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelVoucher: 'Voucher',
      labelMerchant: 'Penjual',
      labelStatus: 'Status',
      labelCsvFile: 'File CSV',

      buttonCaptionEditTokenImport: 'Ubah Impor Token',
      buttonCaptionDeleteTokenImport: 'Hapus Impor Token',
      buttonCaptionImportToken: 'Impor Token',
      buttonCaptionRetry: 'Coba Ulang',
      buttonCaptionImport: 'Impor',
      buttonCaptionDeleteFilteredData: 'Hapus Data Tersaring',

      placeholderCode: 'Kode',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderVoucherName: 'Nama Voucher',
      placeholderMerchantName: 'Nama Penjual',
      placeholderImportStatus: 'Status Impor',
      placeholderStatusNotes: 'Catatan Status',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderVoucherId: 'ID Voucher',

      msgRetryImportConfirmation: 'Apakah Anda yakin ingin mencoba impor ulang pilihan ini?',
      msgDeleteFilteredDataConfirmation: 'Apakah Anda yakin untuk menghapus data yang tersaring?',
    },
    voucherPurchaseRequestPage: {
      title: 'Permintaan Pembelian',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelVoucherName: 'Nama Voucher',
      labelStatus: 'Status',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUser: 'Pengguna',
      placeholderVoucherTokenId: 'ID Token Voucher',
      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherName: 'Nama Voucher',
      placeholderPaymentId: 'ID Pembayaran',
      placeholderVoucherImage: 'Gambar Voucher',
      placeholderRequestStatus: 'Status Permintaan',
      placeholderRequestStatusNotes: 'Catatan Status Permintaan',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    promotedMerchantPage: {
      title: 'Penjual yang Dipromosikan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelPromoted: 'Dipromosikan',
      labelOrder: 'Urutan',
      labelStatus: 'Status',

      placeholderName: 'Nama',
      placeholderPromoted: 'Dipromosikan',
      placeholderAddress: 'Alamat',
      placeholderOrder: 'Urutan',
      placeholderCity: 'Kota',
      placeholderCompanyLogo: 'Logo Perusahaan',
      placeholderWebsite: 'Situs Web',
      placeholderCompanyBanner: 'Spanduk Perusahaan',
      placeholderPlayStoreUrl: 'URL PlayStore',
      placeholderAppStoreUrl: 'URL AppStore',

      buttonCaptionEditPromotedMerchant: 'Ubah Penjual yang Dipromosikan',
    },
    voucherGarudaAirportPage: {
      title: 'Bandara',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',
      labelCity: 'Kota',
      labelPromoted: 'Dipromosikan',
      labelOrder: 'Urutan',
      labelStatus: 'Status',
      labelTokens: 'Jumlah Token',
      labelNotes: 'Notes:',
      labelWillOnlyMaps: 'Hanya akan memetakan token yang aktif dan tersedia (token yang belum dibeli atau ditarik dan masih dalam masa berlaku)',
      labelTokensWereMapped: 'token telah dipetakan ke',
      labelMapTokenToAirport: 'Petakan Token ke Bandara',

      placeholderName: 'Nama',
      placeholderPromoted: 'Dipromosikan',
      placeholderAddress: 'Alamat',
      placeholderOrder: 'Urutan',
      placeholderCity: 'Kota',
      placeholderCompanyLogo: 'Logo Perusahaan',
      placeholderWebsite: 'Situs Web',
      placeholderCompanyBanner: 'Spanduk Perusahaan',
      placeholderPlayStoreUrl: 'URL PlayStore',
      placeholderAppStoreUrl: 'URL AppStore',
      placeholderAirport: 'Bandara',
      placeholderTokenRemark: 'Remark Token',

      buttonCaptionCreateNewGarudaAirport: 'Buat Bandara Baru',
      buttonCaptionEditGarudaAirport: 'Ubah Bandara',
      buttonCaptionDeleteGarudaAirport: 'Hapus Bandara',
      buttonCaptionMapVoucherTokens: 'Petakan Token Voucher',
      buttonCaptionCount: 'Hitung',
      buttonCaptionMapToken: 'Petakan Token',
    },
    voucherTokenAssignment: {
      title: 'Pemberian Voucher',

      labelNo: 'No',
      labelVoucher: 'Voucher',
      labelAssignedTo: 'Diberikan Kepada',
      labelAssignedBy: 'Diberikan Oleh',
      labelAssignedMode: 'Mode Penyerahan',
      labelBypassPayment: 'Bypass Pembayaran',
      labelBypassMaxPurchase: 'Bypass Maksimal Pembelian',
      labelBypassAchievement: 'Bypass Pencapaian',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelAssignmentMethod: 'Metode Penyerahan',
      labelStatus: 'Status',

      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherName: 'Nama Voucher',
      placeholderVoucherTokenId: 'ID Token Voucher',
      placeholderAssignmentMode: 'Mode Penyerahan',
      placeholderCode: 'Kode',
      placeholderVoucherCodeValidFrom: 'Kode Voucher Berlaku Dari',
      placeholderVoucherCodeValidUntil: 'Kode Voucher Berlaku Sampai',
      placeholderBypassPayment: 'Bypass Pembayaran',
      placeholderBypassMaxPurchase: 'Bypass Maksimal Pembelian',
      placeholderByPassAchievement: 'Bypass Pencapaian',
      placeholderNotes: 'Catatan',
      placeholderAssignedById: 'Diserahkan Oleh ID',
      placeholderAssignedByName: 'Diserahkan Oleh Nama',
      placeholderAssignedToId: 'Diserahkan Kepada ID',
      placeholderAssignedToName: 'Diserahkan Kepada Nama',
      placeholderImage: 'Gambar',
      placeholderUser: 'Pengguna',
      placeholderAssignmentMethod: 'Metode Penyerahan',
      placeholderAssignmentValidFrom: 'Penyerahan Berlaku Dari',
      placeholderAssignmentValidUntil: 'Penyerahan Berkaku Sampai',
      placeholderRedemptionCode: 'Kode Penukaran',
      placeholderNotifMessageTitle: 'Judul Notifikasi',
      placeholderNotifMessageBody: 'Isi Pesan Notifikasi',
      placeholderAirport: 'Bandara',

      additionalPlaceholderNotifMessageBody: '* Untuk mengganti isi pesan Notifikasi, anda dapat menggunakan placeholder ini:<br/>- {fullName}: Nama Lengkap Penerima<br/>- {voucherTitle}: Nama Voucher<br>- {merchantName}: Nama Merchant<br/>- {tokenValidUntil}: Masa berlaku token Voucher (zona waktu UTC)',

      buttonCaptionCreate: 'Buat Penyerahan Voucher',
      buttonCaptionEdit: 'Ubah Penyerahan Voucher',
    },
    giftVoucherRedemptionPage: {
      title: 'Tebus Voucher Hadiah',

      labelNo: 'No',
      labelVoucherName: 'Nama Voucher',
      labelMerchant: 'Penjuak',
      labelRedeemedBy: 'Ditebus Oleh',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherName: 'Nama Voucher',
      placeholderMerchantName: 'Nama Penjual',
      placeholderRedeemedById: 'ID Penebus',
      placeholderRedeemedByName: 'Nama Penebus',
      placeholderVoucherTokenId: 'ID Token Voucher',
      placeholderGiftVoucherId: 'ID Voucher Hadiah',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderRedeemedBy: 'Ditebus Oleh',
    },
    voucherUsagePage: {
      title: 'Penggunaan Voucher',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelMerchantName: 'Nama Penjual',
      labelTokenCount: 'Jumlah Token',
      labelAvailable: 'Tersedia',
      labelPurchased: 'Dibeli',
      labelClaimed: 'Diklaim',
      labelRevoked: 'Dicabut',
      labelExpire: 'Berakhir',
      labelAllBranches: 'Semua Cabang',
      labelSpecificBranches: 'Cabang Tertentu',

      placeholderType: 'Tipe',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderActionType: 'Tipe Aksi',
      placeholderVisibility: 'Dapat Dilihat',
      placeholderMerchant: 'Penjual',
      placeholderApplicableAreaMode: 'Mode Area Penerapan',
      placeholderCategory: 'Kategori',
    },
  },
});

export default LocalizedString;
