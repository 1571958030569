import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_HYUNDAI_CHALLENGE_SCANNED_CODES = 'DOWNLOADING_HYUNDAI_CHALLENGE_SCANNED_CODES';
export const downloadingHyundaiChallengeScannedCodes = makeActionCreator(DOWNLOADING_HYUNDAI_CHALLENGE_SCANNED_CODES, 'status');

export const DOWNLOADING_HYUNDAI_CHALLENGE_SCANNED_CODE = 'DOWNLOADING_HYUNDAI_CHALLENGE_SCANNED_CODE';
export const downloadingHyundaiChallengeScannedCode = makeActionCreator(DOWNLOADING_HYUNDAI_CHALLENGE_SCANNED_CODE, 'status');

export const SET_HYUNDAI_CHALLENGE_SCANNED_CODES = 'SET_HYUNDAI_CHALLENGE_SCANNED_CODES';
export const setHyundaiChallengeScannedCodes = makeActionCreator(SET_HYUNDAI_CHALLENGE_SCANNED_CODES, 'data');

export const SET_HYUNDAI_CHALLENGE_SCANNED_CODE = 'SET_HYUNDAI_CHALLENGE_SCANNED_CODE';
export const setHyundaiChallengeScannedCode = makeActionCreator(SET_HYUNDAI_CHALLENGE_SCANNED_CODE, 'data');

export const CLEAR_HYUNDAI_CHALLENGE_SCANNED_CODES = 'CLEAR_HYUNDAI_CHALLENGE_SCANNED_CODES';
export const clearHyundaiChallengeScannedCodes = makeActionCreator(CLEAR_HYUNDAI_CHALLENGE_SCANNED_CODES, 'data');

export const SET_HYUNDAI_CHALLENGE_SCANNED_CODES_PAGE_SIZE = 'SET_HYUNDAI_CHALLENGE_SCANNED_CODES_PAGE_SIZE';
export const setHyundaiChallengeScannedCodesPageSize = makeActionCreator(SET_HYUNDAI_CHALLENGE_SCANNED_CODES_PAGE_SIZE, 'size');

export const SET_HYUNDAI_CHALLENGE_SCANNED_CODES_ORDER_BY = 'SET_HYUNDAI_CHALLENGE_SCANNED_CODES_ORDER_BY';
export const setHyundaiChallengeScannedCodesOrderBy = makeActionCreator(SET_HYUNDAI_CHALLENGE_SCANNED_CODES_ORDER_BY, 'order');

export const SET_HYUNDAI_CHALLENGE_SCANNED_CODES_SEARCH_TEXT = 'SET_HYUNDAI_CHALLENGE_SCANNED_CODES_SEARCH_TEXT';
export const setHyundaiChallengeScannedCodesSearchText = makeActionCreator(SET_HYUNDAI_CHALLENGE_SCANNED_CODES_SEARCH_TEXT, 'text');

export const SET_HYUNDAI_CHALLENGE_SCANNED_CODES_TAPPED_ID = 'SET_HYUNDAI_CHALLENGE_SCANNED_CODES_TAPPED_ID';
export const setHyundaiChallengeScannedCodesTappedId = makeActionCreator(SET_HYUNDAI_CHALLENGE_SCANNED_CODES_TAPPED_ID, 'id');

export const SET_HYUNDAI_CHALLENGE_SCANNED_CODES_FILTER_STRING = 'SET_HYUNDAI_CHALLENGE_SCANNED_CODES_FILTER_STRING';
export const setHyundaiChallengeScannedCodesFilterString = makeActionCreator(SET_HYUNDAI_CHALLENGE_SCANNED_CODES_FILTER_STRING, 'text');

export const SAVING_HYUNDAI_CHALLENGE_SCANNED_CODES = 'SAVING_HYUNDAI_CHALLENGE_TSCANNED_CODE';
export const savingHyundaiChallengeScannedCodes = makeActionCreator(SAVING_HYUNDAI_CHALLENGE_SCANNED_CODES, 'status');


export const DOWNLOADING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS = 'DOWNLOADING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS';
export const downloadingHyundaiChallengePrizeRedemptions = makeActionCreator(DOWNLOADING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = 'DOWNLOADING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION';
export const downloadingHyundaiChallengePrizeRedemption = makeActionCreator(DOWNLOADING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION, 'status');

export const ADD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION = 'ADD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION';
export const addHyundaiChallengePrizeRedemption = makeActionCreator(ADD_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION, 'data');

export const SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS = 'SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS';
export const setHyundaiChallengePrizeRedemptions = makeActionCreator(SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS, 'data');

export const CLEAR_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS = 'CLEAR_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS';
export const clearHyundaiChallengePrizeRedemptions = makeActionCreator(
  CLEAR_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS,
);

export const SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE';
export const setHyundaiChallengePrizeRedemptionSelectedPageSize = makeActionCreator(SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SELECTED_ORDER_BY = 'SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SELECTED_ORDER_BY';
export const setHyundaiChallengePrizeRedemptionSelectedOrderBy = makeActionCreator(SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_TAPPED_ID = 'SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_TAPPED_ID';
export const setHyundaiChallengePrizeRedemptionTappedId = makeActionCreator(SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_TAPPED_ID, 'id');

export const SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SEARCH_TEXT = 'SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SEARCH_TEXT';
export const setHyundaiChallengePrizeRedemptionSearchText = makeActionCreator(SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING = 'SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING';
export const setHyundaiChallengePrizeRedemptionAdvancedFilterString = makeActionCreator(SET_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS = 'SAVING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS';
export const savingHyundaiChallengePrizeRedemptions = makeActionCreator(SAVING_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTIONS = 'DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTIONS';
export const downloadingHyundaiChallengeTransactions = makeActionCreator(DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTIONS, 'status');

export const DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTION = 'DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTION';
export const downloadingHyundaiChallengeTransaction = makeActionCreator(DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTION, 'status');

export const SAVING_HYUNDAI_CHALLENGE_TRANSACTIONS = 'SAVING_HYUNDAI_CHALLENGE_TRANSACTIONS';
export const savingHyundaiChallengeTransactions = makeActionCreator(SAVING_HYUNDAI_CHALLENGE_TRANSACTIONS, 'status');

export const ADD_HYUNDAI_CHALLENGE_TRANSACTION = 'ADD_HYUNDAI_CHALLENGE_TRANSACTION';
export const addHyundaiChallengeTransaction = makeActionCreator(ADD_HYUNDAI_CHALLENGE_TRANSACTION, 'data');

export const SET_HYUNDAI_CHALLENGE_TRANSACTIONS = 'SET_HYUNDAI_CHALLENGE_TRANSACTIONS';
export const setHyundaiChallengeTransactions = makeActionCreator(SET_HYUNDAI_CHALLENGE_TRANSACTIONS, 'data');

export const CLEAR_HYUNDAI_CHALLENGE_TRANSACTIONS = 'CLEAR_HYUNDAI_CHALLENGE_TRANSACTIONS';
export const clearHyundaiChallengeTransactions = makeActionCreator(
  CLEAR_HYUNDAI_CHALLENGE_TRANSACTIONS,
);

export const SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_PAGE_SIZE';
export const setHyundaiChallengeTransactionSelectedPageSize = makeActionCreator(SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_ORDER_BY = 'SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_ORDER_BY';
export const setHyundaiChallengeTransactionSelectedOrderBy = makeActionCreator(SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_HYUNDAI_CHALLENGE_TRANSACTION_TAPPED_ID = 'SET_HYUNDAI_CHALLENGE_TRANSACTION_TAPPED_ID';
export const setHyundaiChallengeTransactionTappedId = makeActionCreator(SET_HYUNDAI_CHALLENGE_TRANSACTION_TAPPED_ID, 'id');

export const SET_HYUNDAI_CHALLENGE_TRANSACTION_SEARCH_TEXT = 'SET_HYUNDAI_CHALLENGE_TRANSACTION_SEARCH_TEXT';
export const setHyundaiChallengeTransactionSearchText = makeActionCreator(SET_HYUNDAI_CHALLENGE_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_HYUNDAI_CHALLENGE_TRANSACTION_ADVANCED_FILTER_STRING = 'SET_HYUNDAI_CHALLENGE_TRANSACTION_ADVANCED_FILTER_STRING';
export const setHyundaiChallengeTransactionAdvancedFilterString = makeActionCreator(SET_HYUNDAI_CHALLENGE_TRANSACTION_ADVANCED_FILTER_STRING, 'text');
