import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  status,
  FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT,
  FILTER_TYPE_SWITCH,
  RXSTATE_CAR_SERVICE_DEALER_PAGE,
  RXSTATE_CAR_SERVICE_DEALERS,
  RXFIELD_CAR_SERVICE_DEALER_DEALER_ID,
  RXFIELD_CAR_SERVICE_DEALER_DEALER_CODE,
  RXFIELD_CAR_SERVICE_DEALER_NAME,
  RXFIELD_CAR_SERVICE_DEALER_ADDRESS,
  RXFIELD_CAR_SERVICE_DEALER_CITY,
  RXFIELD_CAR_SERVICE_DEALER_REGION,
  RXFIELD_CAR_SERVICE_DEALER_POSTAL_CODE,
  RXFIELD_CAR_SERVICE_DEALER_EMAIL,
  RXFIELD_CAR_SERVICE_DEALER_PHONE,
  RXFIELD_CAR_SERVICE_DEALER_TYPE,
  RXFIELD_CAR_SERVICE_DEALER_DEALER_STATUS,
  RXFIELD_CAR_SERVICE_DEALER_DEALER_TYPE,
  RXFIELD_CAR_SERVICE_DEALER_DEALER_TYPE_CODE,
  RXFIELD_CAR_SERVICE_DEALER_HAS_CHARGING_STATION,
  RXFIELD_CAR_SERVICE_DEALER_LATITUDE,
  RXFIELD_CAR_SERVICE_DEALER_LONGITUDE,
  RXFORM_CAR_SERVICE_DEALER,
  STATUS_DISABLED, COLOR_DISABLED_ROW, COLOR_BACKGROUND, COLOR_PRIMARY,
} from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  buttonText: {
    color: COLOR_PRIMARY,
    marginLeft: 8,
  },
}));

const renderDialogContent = (initialValues, onOpenGoogleMaps, classes) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_DEALER_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderID}
          placeholder={LocalizedString.dealerPage.placeholderID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_DEALER_CODE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.labelCode}
          placeholder={LocalizedString.dealerPage.placeholderCode}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_NAME}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.labelName}
          placeholder={LocalizedString.dealerPage.placeholderName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_ADDRESS}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderAddress}
          placeholder={LocalizedString.dealerPage.placeholderAddress}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_CITY}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.labelCity}
          placeholder={LocalizedString.dealerPage.placeholderCity}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_REGION}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.labelRegion}
          placeholder={LocalizedString.dealerPage.placeholderRegion}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_POSTAL_CODE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderPostalCode}
          placeholder={LocalizedString.dealerPage.placeholderPostalCode}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_EMAIL}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderEmail}
          placeholder={LocalizedString.dealerPage.placeholderEmail}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_PHONE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderPhone}
          placeholder={LocalizedString.dealerPage.placeholderPhone}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_TYPE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderType}
          placeholder={LocalizedString.dealerPage.placeholderType}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_DEALER_STATUS}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.labelDealerStatus}
          placeholder={LocalizedString.dealerPage.placeholderDealerStatus}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_DEALER_TYPE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.labelDealerType}
          placeholder={LocalizedString.dealerPage.placeholderDealerType}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_DEALER_TYPE_CODE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderDealerTypeCode}
          placeholder={LocalizedString.dealerPage.placeholderDealerTypeCode}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_HAS_CHARGING_STATION}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.labelChargingStation}
          placeholder={LocalizedString.dealerPage.placeholderChargingStation}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_LATITUDE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderLat}
          placeholder={LocalizedString.dealerPage.placeholderLat}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_DEALER_LONGITUDE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.dealerPage.placeholderLong}
          placeholder={LocalizedString.dealerPage.placeholderLong}
          disabled
        />
      </Grid>
      <Grid item>
        {initialValues.latitude && initialValues.longitude && (
          <Button
            variant="text"
            onClick={() => onOpenGoogleMaps(initialValues.latitude, initialValues.longitude)}
            className={classes.buttonText}
          >
            {LocalizedString.dealerPage.buttonCaptionOpenMap}
          </Button>
        )}
      </Grid>
    </Grid>
  </Grid>
);

const CarServiceDealerPage = ({
  downloading,
  onAppear,
  onApplyAdvancedFilterPressed,
  onAdvancedFilterPressed,
  onChangePage,
  onChangePageSize,
  onRefresh,
  onResetAdvancedFilterPressed,
  onSearchBarTextChanged,
  onSortPressed,
  onViewPressed,
  initialValues,
  onOpenGoogleMaps,
  dealerProvinces,
  dealerCities,
  loadingDealerProvince,
  loadingDealerCity,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_CAR_SERVICE_DEALERS}
      uiPage={RXSTATE_CAR_SERVICE_DEALER_PAGE}
      filterColumns={[
        {
          title: LocalizedString.dealerPage.placeholderType,
          field: 'type',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.dealerPage.placeholderProvince,
          field: 'dealerProvince',
          type: FILTER_TYPE_DROPDOWN,
          data: dealerProvinces,
          loading: loadingDealerProvince,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.dealerPage.placeholderCity,
          field: 'dealerCity',
          type: FILTER_TYPE_DROPDOWN,
          data: dealerCities,
          loading: loadingDealerCity,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.dealerPage.placeholderRegion,
          field: 'region',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.dealerPage.placeholderDealerStatus,
          field: 'dealerStatus',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.dealerPage.placeholderDealerType,
          field: 'dealerType',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.dealerPage.labelStatus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.dealerPage.placeholderChargingStation,
          field: 'hasChargingStation',
          type: FILTER_TYPE_SWITCH,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.dealerPage.labelNo,
          field: 'no',
          sorting: false,
          width: 40,
          customFilterAndSearch: (term, rowData) => rowData,
        },
        {
          title: LocalizedString.dealerPage.labelCode,
          field: 'dealerCode',
          sorting: !downloading,
        },
        {
          title: LocalizedString.dealerPage.labelName,
          field: 'name',
          sorting: !downloading,
        },
        {
          title: LocalizedString.dealerPage.labelCity,
          field: 'city',
          sorting: !downloading,
        },
        {
          title: LocalizedString.dealerPage.labelProvince,
          field: 'province',
          sorting: !downloading,
        },
        {
          title: LocalizedString.dealerPage.labelRegion,
          field: 'region',
          sorting: !downloading,
        },
        {
          title: LocalizedString.dealerPage.labelDealerType,
          field: 'dealerType',
          sorting: !downloading,
        },
        {
          title: LocalizedString.dealerPage.labelChargingStation,
          field: 'hasChargingStation',
          sorting: !downloading,
          render: ({ hasChargingStation }) => (hasChargingStation === true
            ? GlobalLocalizedString.common.labelYes
            : GlobalLocalizedString.common.labelNo),
        },
        {
          title: LocalizedString.dealerPage.labelStatus,
          field: 'dealerStatus',
          sorting: !downloading,
        },
      ]}
      onAppear={onAppear}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.dealerPage.title}
      disableCreate
      disableEdit
      disableDelete
      useFullWidth
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
    >
      {renderDialogContent(initialValues, onOpenGoogleMaps, classes)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_CAR_SERVICE_DEALER,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CarServiceDealerPage);

CarServiceDealerPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  dealerProvinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  dealerCities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingDealerProvince: PropTypes.bool.isRequired,
  loadingDealerCity: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onOpenGoogleMaps: PropTypes.func.isRequired,
};
