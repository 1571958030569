import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_PARTNER_MERCHANTS = '/merchant/api/v1/Merchant?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_PARTNER_MERCHANT = '/merchant/api/v1/Merchant';
export const REST_URL_VIEW_DELETE_PARTNER_MERCHANT = '/merchant/api/v1/Merchant/{id}';

export const ROUTE_NAME_PARTNER = '/partner';
export const ROUTE_NAME_PARTNER_MERCHANT = '/partner-merchant';

export const RXFORM_PARTNER_MERCHANT = 'partnerMerchant';

export const RXFIELD_PARTNER_MERCHANT_NAME = 'name';
export const RXFIELD_PARTNER_MERCHANT_ADDRESS = 'address';
export const RXFIELD_PARTNER_MERCHANT_SHORT_NAME = 'shortName';
export const RXFIELD_PARTNER_MERCHANT_CITY = 'city';
export const RXFIELD_PARTNER_MERCHANT_COMPANY_LOGO = 'logo';
export const RXFIELD_PARTNER_MERCHANT_COMPANY_BANNER = 'banner';
export const RXFIELD_PARTNER_MERCHANT_WEBSITE = 'website';
export const RXFIELD_PARTNER_MERCHANT_STATUS = 'status';
export const RXFIELD_PARTNER_MERCHANT_PLAY_STORE_URL = 'playStoreUrl';
export const RXFIELD_PARTNER_MERCHANT_APP_STORE_URL = 'appStoreUrl';
export const RXFIELD_PARTNER_MERCHANT_COMPANY = 'company';
export const RXFIELD_PARTNER_MERCHANT_DESCRIPTION = 'description';
export const RXFIELD_PARTNER_MERCHANT_TERMS_AND_CONDITIONS = 'termsAndConditions';
export const RXFIELD_PARTNER_MERCHANT_TAG_LINE = 'tagLine';
export const RXFIELD_PARTNER_MERCHANT_DEEP_LINK = 'deepLink';

export const RXSTATE_PARTNER_MERCHANTS = 'partnerMerchants';
export const RXSTATE_PARTNER_MERCHANT_PAGE = 'uiPartnerMerchant';

export const INITIAL_ORDER_BY_PARTNER_MERCHANTS = `company.name ${ORDER_SORT_ASCENDING}`;

export const MENUID_PARTNER = 'MENUID_PARTNER';
export const MENUID_PARTNER_MERCHANT = 'MENUID_PARTNER_MERCHANT';
