import {
  DOWNLOADING_POINT_SUBMISSIONS, DOWNLOADING_DELETING_POINT_SUBMISSION,
  SET_POINT_SUBMISSION_SELECTED_PAGE_SIZE, SET_POINT_SUBMISSION_TAPPED_ID,
  SET_POINT_SUBMISSION_SEARCH_TEXT, SET_POINT_SUBMISSION_SELECTED_ORDER_BY,
  REPROCESSING_POINT_SUBMISSION, SET_POINT_SUBMISSION_SELECTED_USER,
  ADDING_EDITING_POINT_SUBMISSIONS, SET_POINT_SUBMISSION_SEND_NOTIFICATION_OPTION,
} from '../action';
import { INITIAL_ORDER_BY_POINT_SUBMISSIONS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  addingEditing: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  selectedUser: '',
  orderBy: INITIAL_ORDER_BY_POINT_SUBMISSIONS,
  reprocessing: false,
  sendNotification: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_POINT_SUBMISSIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_POINT_SUBMISSION:
      return { ...state, downloadingDeleting: action.status };
    case ADDING_EDITING_POINT_SUBMISSIONS:
      return { ...state, addingEditing: action.status };
    case SET_POINT_SUBMISSION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_POINT_SUBMISSION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_POINT_SUBMISSION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_POINT_SUBMISSION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_POINT_SUBMISSION_SELECTED_USER:
      return { ...state, selectedUser: action.user };
    case REPROCESSING_POINT_SUBMISSION:
      return { ...state, reprocessing: action.status };
    case SET_POINT_SUBMISSION_SEND_NOTIFICATION_OPTION:
      return { ...state, sendNotification: action.status };
    default: return state;
  }
};
