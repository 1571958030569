import {
  buildQueryParams, sendGetRequest, transformQueryResult, sendPostRequest,
  sendPutRequest, sendDeleteRequest,
} from '../../helper';
import {
  REST_URL_FAQS, REST_URL_ADD_EDIT_FAQ, REST_URL_VIEW_DELETE_FAQ,
  REST_URL_FAQ_CATEGORIES, REST_URL_ADD_EDIT_FAQ_CATEGORY, REST_URL_VIEW_DELETE_FAQ_CATEGORY,
} from './constant';

export * from '../../helper';

export const downloadFAQs = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_FAQS.concat(filterString), pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addFAQ = async (
  question, shortAnswer, detailedAnswer, order, visibilityStatus, categoryId, token,
) => {
  const body = {
    question, shortAnswer, detailedAnswer, order, visibilityStatus, categoryId,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_FAQ, body, token);
};

export const editFAQ = async (
  id, question, shortAnswer, detailedAnswer, order, visibilityStatus, categoryId, token,
) => {
  const body = {
    id, question, shortAnswer, detailedAnswer, order, visibilityStatus, categoryId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_FAQ, body, token);
};

export const downloadFAQ = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_FAQ.replace(/\{id\}/, id), token);
  return response;
};

export const deleteFAQ = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_FAQ.replace(/\{id\}/, id), token);
};


export const downloadFAQCategories = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_FAQ_CATEGORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addFAQCategory = async (name, description, token) => {
  const body = { name, description };
  await sendPostRequest(REST_URL_ADD_EDIT_FAQ_CATEGORY, body, token);
};

export const editFAQCategory = async (id, name, description, token) => {
  const body = { id, name, description };
  await sendPutRequest(REST_URL_ADD_EDIT_FAQ_CATEGORY, body, token);
};

export const downloadFAQCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_FAQ_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteFAQCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_FAQ_CATEGORY.replace(/\{id\}/, id), token);
};
