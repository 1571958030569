import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CircularProgress, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import {
  COLOR_LIGHT_GREEN, COLOR_SECONDARY, COLOR_BODY_TEXT_LIGHTER,
  PAYMENT_STATUS_SUCCESS,
} from '../../constant';
import { AccentButton } from '../../component';
import { downloadCarServiceReservationInvoiceAsync, setAlertErrorMessage } from '../../redux/action';
import { DealerDetailShape } from '../../type';
import { getPaymentStatus } from '../../helper';

const mapStateToProps = (state) => {
  const found = state.carServiceReservations.data[state.uiCarServiceReservation.tappedId];
  return {
    downloadingInvoice: state.uiCarServiceReservation.downloadingInvoice,
    downloadingDealerDetail: state.uiCarServiceReservation.downloadingDealerDetail,
    dealerDetail: state.uiCarServiceReservation.dealerDetail,
    downloadingDeleting: state.uiCarServiceReservation.downloadingDeleting,
    paymentStatus: found?.payment?.paymentStatus || '',
    bookingCode: found?.bookingCode || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDownloadInvoicePressed: () => {
    dispatch(downloadCarServiceReservationInvoiceAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${COLOR_BODY_TEXT_LIGHTER}`,
    padding: '12px',
    marginTop: 24,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  activityIndicator: {
    display: 'flex',
    justifyContent: 'center',
    margin: '11px 0px 11px 0px',
  },
  paymentStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 24px 4px 24px',
    border: `2px solid ${COLOR_BODY_TEXT_LIGHTER}`,
    borderRadius: 50,
  },
  successContainer: {
    border: `2px solid ${COLOR_SECONDARY}`,
    backgroundColor: COLOR_LIGHT_GREEN,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 24px 4px 24px',
    borderRadius: 50,
  },
  img: {
    objectFit: 'scale-down',
    width: 60,
    height: 20,
    marginRight: 12,
  },
  dealerNameText: {
    fontWeight: 'bold',
    margin: '0px 0px 4px 0px',
  },
  bookingCodeText: {
    fontWeight: 'bold',
    margin: '0px 0px 4px 0px',
    textAlign: 'center',
  },
  saveButtonWrapper: {
    marginLeft: 10,
  },
  saveButtonLabel: {
    width: '0%',
  },
}));

const logo = require('../../../../asset/hyundai-logo.png');

const Header = ({
  onDownloadInvoicePressed, downloadingDeleting,
  downloadingInvoice, downloadingDealerDetail, dealerDetail,
  bookingCode, paymentStatus,
}) => {
  const classes = useStyles();

  const isPaymentStatusSuccess = paymentStatus === PAYMENT_STATUS_SUCCESS;

  return (
    <Grid item sm md>
      {isPaymentStatusSuccess && (
      <div className={classes.flexEnd}>
        <AccentButton
          variant="outlined"
          startIcon={<CloudDownload />}
          caption=""
          onClick={onDownloadInvoicePressed}
          loading={downloadingInvoice}
          disabled={downloadingDeleting}
          classes={{
            startIcon: classes.saveButtonWrapper,
            label: classes.saveButtonLabel,
            root: classes.saveButtonWrapper,
          }}
        />
      </div>
      )}
      <Grid item sm md>
        {downloadingDealerDetail ? (
          <div className={classes.activityIndicator}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div className={classes.headerContainer}>
            <div className={classes.row}>
              <div>
                <img alt="" src={logo} className={classes.img} />
              </div>
              <div>
                <Typography variant="h6" className={classes.dealerNameText}>{dealerDetail?.name}</Typography>
                <Typography variant="body1">
                  {`${dealerDetail?.address}, ${dealerDetail?.city}, ${dealerDetail?.province}, ${dealerDetail?.postalCode}`}
                </Typography>
              </div>
            </div>
            <div className={classes.rightContainer}>
              <Typography variant="h6" className={classes.bookingCodeText}>{bookingCode}</Typography>
              {paymentStatus ? (
                <div className={isPaymentStatusSuccess
                  ? classes.successContainer : classes.paymentStatusContainer}
                >
                  <Typography variant="body1">{getPaymentStatus(paymentStatus)}</Typography>
                </div>
              ) : <></>}
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

Header.propTypes = {
  onDownloadInvoicePressed: PropTypes.func.isRequired,
  downloadingDealerDetail: PropTypes.bool,
  downloadingInvoice: PropTypes.bool,
  downloadingDeleting: PropTypes.bool,
  bookingCode: PropTypes.string,
  paymentStatus: PropTypes.string,
  dealerDetail: DealerDetailShape,
};

Header.defaultProps = {
  dealerDetail: {},
  downloadingDealerDetail: false,
  downloadingInvoice: false,
  downloadingDeleting: false,
  bookingCode: '',
  paymentStatus: '',
};
