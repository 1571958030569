import { reset } from 'redux-form';
import {
  addingEditingEvChargingUrl, clearEvChargingUrls, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { RXFORM_CAR_SERVICE_EV_CHARGING_URL, PAGE_MODE_TABLE } from '../../../constant';
import { addEvChargingUrl, editEvChargingUrl } from '../../../helper';
import downloadEvChargingUrlsAsync from './downloadEvChargingUrlsAsync';

export default (code, url, notes) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingEvChargingUrl(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiEvChargingUrl;

    if (tappedId) {
      await editEvChargingUrl(tappedId, code, url, notes, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addEvChargingUrl(code, url, notes, token);
    }

    dispatch(reset(RXFORM_CAR_SERVICE_EV_CHARGING_URL));
    dispatch(clearEvChargingUrls());
    dispatch(downloadEvChargingUrlsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingEvChargingUrl(false));
  }
};
