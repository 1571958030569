import { SET_COUPON_BALANCE, CLEAR_COUPON_BALANCE } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_COUPON_BALANCE: {
      return action.data;
    }
    case CLEAR_COUPON_BALANCE:
      return state;
    default:
      return state;
  }
};
