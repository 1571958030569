import {
  addCarViewingReservation, downloadingCarViewingReservation,
} from '../simple-action';
import { downloadCarViewingReservation } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCarViewingReservation(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCarViewingReservation;

    const reservation = await downloadCarViewingReservation(tappedId, token);
    dispatch(addCarViewingReservation(reservation));
  } finally {
    dispatch(downloadingCarViewingReservation(false));
  }
};
