import { SET_MY_CONFIG_ITEMS, CLEAR_MY_CONFIG_ITEMS } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_MY_CONFIG_ITEMS: {
      return action.data;
    }
    case CLEAR_MY_CONFIG_ITEMS:
      return state;
    default:
      return state;
  }
};
