import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_TEST_DRIVE_EVENTS,
  INITIAL_ORDER_BY_TEST_VEHICLES,
  MENUID_TEST_DRIVE_TEST_VEHICLE,
  PAGE_MODE_TABLE, RXFIELD_TEST_VEHICLE_EVENT,
  RXFIELD_TEST_VEHICLE_MODEL, RXFORM_TEST_DRIVE_TEST_VEHICLE,
} from '../../constant';
import { transformDropdownData, transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearTestVehicles, clearEvents,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setEventAdvancedFilterDialogSelectedFilterString, setEventSearchText,
  setTestVehicleAdvancedFilterDialogSelectedFilterString, setTestVehicleSearchText,
  setTestVehicleSelectedOrderBy, setTestVehicleSelectedPageSize, setTestVehicleTappedId,
  downloadEventsAsync, downloadTestVehiclesAsync, downloadDeleteTestVehicleAsync,
  addEditTestVehicleAsync, setTestVehicleEvent, setTestVehicleModel,
} from '../../redux/action';
import {
  clearModels, setModelAdvancedFilterDialogSelectedFilterString, setModelSearchText,
  downloadModelsAsync,
} from '../../../car-catalog/redux/action';
import TestVehiclePage from './test-vehicle.presentation';

const getInitialValues = (state) => {
  const {
    testVehicles, uiFunctionalPage, uiTestVehicle,
  } = state;
  const { data } = testVehicles;
  const { downloadingDeleting, tappedId } = uiTestVehicle;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      event: found.event?.name || '',
      model: found.modelName || '',
    }) : ({});
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiTestVehicle.downloading,
  addingEditing: state.uiTestVehicle.addingEditing,
  downloadingDeleting: state.uiTestVehicle.downloadingDeleting,
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiTestVehicle.tappedId,
  carModels: transformDropdownData(state.models.data),
  loadingCarModels: state.uiModel.downloading,
  events: transformDropdownData(state.events.data),
  loadingEvent: state.uiEvent.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setModelSearchText(''));
    dispatch(clearModels());
    dispatch(downloadModelsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('event', 'event.id').replace('model', 'modelId');
    dispatch(setTestVehicleAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearTestVehicles());
    dispatch(downloadTestVehiclesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_TEST_DRIVE_TEST_VEHICLE));
    dispatch(setTestVehicleSearchText(''));
    dispatch(setTestVehicleAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearTestVehicles());
    dispatch(setTestVehicleSelectedPageSize(20));
    dispatch(setTestVehicleSelectedOrderBy(INITIAL_ORDER_BY_TEST_VEHICLES));
    dispatch(downloadTestVehiclesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_TEST_DRIVE_TEST_VEHICLE));
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setEventSearchText(text));
      dispatch(clearEvents());
      await dispatch(downloadEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onEventOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_TEST_DRIVE_TEST_VEHICLE,
        RXFIELD_TEST_VEHICLE_EVENT, option));
      dispatch(setTestVehicleEvent(option));
    } else {
      dispatch(change(RXFORM_TEST_DRIVE_TEST_VEHICLE,
        RXFIELD_TEST_VEHICLE_EVENT, ''));
      dispatch(setTestVehicleEvent(''));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestVehiclesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestVehicleSelectedPageSize(pageSize));
    dispatch(downloadTestVehiclesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeCarModelText: async (text) => {
    try {
      dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setModelSearchText(text));
      dispatch(clearModels());
      await dispatch(downloadModelsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCarModelOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_TEST_DRIVE_TEST_VEHICLE,
        RXFIELD_TEST_VEHICLE_MODEL, option));
      dispatch(setTestVehicleModel(option));
    } else {
      dispatch(change(RXFORM_TEST_DRIVE_TEST_VEHICLE,
        RXFIELD_TEST_VEHICLE_MODEL, ''));
      dispatch(setTestVehicleModel(''));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteTestVehicleAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setTestVehicleTappedId(''));
    dispatch(setTestVehicleSearchText(''));
    dispatch(setTestVehicleEvent(''));
    dispatch(setTestVehicleModel(''));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setModelSearchText(''));
    dispatch(clearModels());
    dispatch(downloadModelsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setTestVehicleTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setTestVehicleTappedId(id));
    dispatch(setTestVehicleEvent(''));
    dispatch(setTestVehicleModel(''));
    dispatch(downloadDeleteTestVehicleAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setModelSearchText(''));
    dispatch(clearModels());
    dispatch(downloadModelsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setTestVehicleSelectedPageSize(pageSize));
    dispatch(clearTestVehicles());
    dispatch(downloadTestVehiclesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestVehicles());
    dispatch(setTestVehicleAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setTestVehicleSelectedOrderBy(INITIAL_ORDER_BY_TEST_DRIVE_EVENTS));
    dispatch(downloadTestVehiclesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    dailyQuota,
  }) => {
    try {
      await dispatch(addEditTestVehicleAsync(dailyQuota));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setTestVehicleSearchText(text));
      dispatch(clearTestVehicles());
      await dispatch(downloadTestVehiclesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestVehicleSelectedOrderBy(orderBy));
    dispatch(clearTestVehicles());
    dispatch(downloadTestVehiclesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    dailyQuota,
  }) => {
    await dispatch(addEditTestVehicleAsync(dailyQuota));
  },
  onViewPressed: (id) => {
    dispatch(setTestVehicleTappedId(id));
    dispatch(downloadDeleteTestVehicleAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TestVehiclePage);
