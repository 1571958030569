import { countingVoucherToken, setVoucherTokenCount } from '../simple-action';
import { countVoucherToken } from '../../../helper';

export default (remark) => async (dispatch, getState) => {
  try {
    dispatch(countingVoucherToken(true));

    const { authentication } = getState();
    const { token } = authentication;
    const response = await countVoucherToken(remark, token);
    dispatch(setVoucherTokenCount(response));
  } finally {
    dispatch(countingVoucherToken(false));
  }
};
