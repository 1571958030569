import {
  ADDING_EDITING_CAR_VIEWING_EVENT,
  DOWNLOADING_DELETING_CAR_VIEWING_EVENT,
  DOWNLOADING_CAR_VIEWING_EVENTS,
  SET_CAR_VIEWING_EVENT_SEARCH_TEXT,
  SET_CAR_VIEWING_EVENT_SELECTED_ORDER_BY,
  SET_CAR_VIEWING_EVENT_SELECTED_PAGE_SIZE,
  SET_CAR_VIEWING_EVENT_TAPPED_ID,
  SET_CAR_VIEWING_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_CAR_VIEWING_EVENT_SELECTED_CITY_ID,
  SET_CAR_VIEWING_EVENT_SELECTED_VOUCHER_REWARD_ID,
} from '../action';
import { INITIAL_ORDER_BY_CAR_VIEWING_EVENTS } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_CAR_VIEWING_EVENTS,
  filterString: '',
  selectedCityId: '',
  selectedVoucherId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CAR_VIEWING_EVENTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_CAR_VIEWING_EVENT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_CAR_VIEWING_EVENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_CAR_VIEWING_EVENT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CAR_VIEWING_EVENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CAR_VIEWING_EVENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CAR_VIEWING_EVENT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CAR_VIEWING_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_CAR_VIEWING_EVENT_SELECTED_CITY_ID:
      return { ...state, selectedCityId: action.id };
    case SET_CAR_VIEWING_EVENT_SELECTED_VOUCHER_REWARD_ID:
      return { ...state, selectedVoucherId: action.id };
    default: return state;
  }
};
