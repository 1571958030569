import {
  DOWNLOADING_PROMO_CODE_BATCHES, ADDING_EDITING_PROMO_CODE_BATCH,
  DOWNLOADING_DELETING_PROMO_CODE_BATCH, SET_PROMO_CODE_BATCH_SELECTED_PAGE_SIZE,
  SET_PROMO_CODE_BATCH_TAPPED_ID, SET_PROMO_CODE_BATCH_SEARCH_TEXT,
  SET_PROMO_CODE_BATCH_SELECTED_ORDER_BY, SET_PROMO_CODE_BATCH_SELECTED_MERCHANT,
  CANCELLING_PROMO_CODE_BATCH, SET_PROMO_CODE_BATCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_PROMO_CODE_BATCH_SELECTED_REWARD_TYPE, SET_PROMO_CODE_BATCH_SELECTED_BYPASS_ACHIEVEMENT,
  SET_PROMO_CODE_BATCH_SELECTED_BYPASS_MAX_PURCHASE, SET_PROMO_CODE_BATCH_SELECTED_VOUCHERS,
  SET_PROMO_CODE_BATCH_TABLE_DATA_VOUCHERS, ADDING_PROMO_CODE_BATCH_VOUCHERS,
  SET_PROMO_CODE_BATCH_USER_TYPES, SET_PROMO_CODE_BATCH_SELECTED_AIRPORT,
} from '../action';
import { INITIAL_ORDER_BY_PROMO_CODE_BATCHES } from '../../constant';

const initialState = {
  addingEditing: false,
  addingVouchers: false,
  cancelling: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  selectedBypassAchievement: null,
  selectedBypassMaxPurchase: null,
  selectedMerchant: null,
  selectedRewardType: null,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PROMO_CODE_BATCHES,
  filterString: '',
  selectedVouchers: null,
  tableDataVouchers: [],
  selectedUserTypes: [],
  selectedAirport: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PROMO_CODE_BATCHES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_PROMO_CODE_BATCH:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_PROMO_CODE_BATCH:
      return { ...state, downloadingDeleting: action.status };
    case SET_PROMO_CODE_BATCH_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PROMO_CODE_BATCH_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PROMO_CODE_BATCH_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PROMO_CODE_BATCH_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PROMO_CODE_BATCH_SELECTED_MERCHANT:
      return { ...state, selectedMerchant: action.option };
    case CANCELLING_PROMO_CODE_BATCH:
      return { ...state, cancelling: action.status };
    case SET_PROMO_CODE_BATCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_PROMO_CODE_BATCH_SELECTED_REWARD_TYPE:
      return { ...state, selectedRewardType: action.option };
    case SET_PROMO_CODE_BATCH_SELECTED_BYPASS_ACHIEVEMENT:
      return { ...state, selectedBypassAchievement: action.option };
    case SET_PROMO_CODE_BATCH_SELECTED_BYPASS_MAX_PURCHASE:
      return { ...state, selectedBypassMaxPurchase: action.option };
    case ADDING_PROMO_CODE_BATCH_VOUCHERS:
      return { ...state, addingVouchers: action.status };
    case SET_PROMO_CODE_BATCH_SELECTED_VOUCHERS:
      return { ...state, selectedVouchers: action.option };
    case SET_PROMO_CODE_BATCH_TABLE_DATA_VOUCHERS:
      return { ...state, tableDataVouchers: action.data };
    case SET_PROMO_CODE_BATCH_USER_TYPES:
      return { ...state, selectedUserTypes: action.option };
    case SET_PROMO_CODE_BATCH_SELECTED_AIRPORT:
      return { ...state, selectedAirport: action.option };
    default: return state;
  }
};
