
import { INITIAL_ORDER_BY_VOUCHER_TOKENS } from '../../constant';
import {
  DOWNLOADING_VOUCHER_TOKENS, SET_VOUCHER_TOKEN_SEARCH_TEXT, REVOKING_SELECTED_VOUCHER_TOKENS,
  SET_VOUCHER_TOKEN_SELECTED_ORDER_BY, SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE,
  SET_VOUCHER_TOKEN_TAPPED_ID, DOWNLOADING_VOUCHER_TOKEN, MOVING_SELECTED_VOUCHER_TOKENS,
  SET_PURCHASER_SEARCH_TEXT, SET_REVOKER_SEARCH_TEXT, SET_CLAIMER_SEARCH_TEXT,
  DOWNLOADING_PURCHASERS, DOWNLOADING_REVOKERS, DOWNLOADING_CLAIMERS,
  REVOKING_VOUCHER_TOKEN, SET_VOUCHER_TOKEN_SELECTED_ROWS,
  SET_VOUCHER_TOKEN_IS_GARUDA_COMPANY_NAME, SET_VOUCHER_TOKEN_MOVE_ALL_DIALOG_VISIBILITY,
  SET_VOUCHER_TOKEN_SELECTED_VOUCHER_DESTINATION_OPTION,
} from '../action';

const initialState = {
  selectedRows: [],
  downloading: false,
  downloadingDeleting: false,
  revokingSelectedRows: false,
  moveSelectedDialogVisibility: false,
  movingSelectedRows: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_VOUCHER_TOKENS,
  claimerSearchBarText: '',
  purchaserSearchBarText: '',
  revokerSearchBarText: '',
  downloadingPurchasers: false,
  downloadingRevokers: false,
  downloadingClaimers: false,
  revoking: false,
  isGarudaCompanyName: false,
  selectedVoucherDestination: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_VOUCHER_TOKENS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_VOUCHER_TOKEN:
      return { ...state, downloadingDeleting: action.status };
    case SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOUCHER_TOKEN_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_VOUCHER_TOKEN_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOUCHER_TOKEN_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CLAIMER_SEARCH_TEXT:
      return { ...state, claimerSearchBarText: action.text };
    case SET_PURCHASER_SEARCH_TEXT:
      return { ...state, purchaserSearchBarText: action.text };
    case SET_REVOKER_SEARCH_TEXT:
      return { ...state, revokerSearchBarText: action.text };
    case DOWNLOADING_PURCHASERS:
      return { ...state, downloadingPurchasers: action.status };
    case DOWNLOADING_REVOKERS:
      return { ...state, downloadingRevokers: action.status };
    case DOWNLOADING_CLAIMERS:
      return { ...state, downloadingClaimers: action.status };
    case REVOKING_VOUCHER_TOKEN:
      return { ...state, revoking: action.status };
    case SET_VOUCHER_TOKEN_IS_GARUDA_COMPANY_NAME:
      return { ...state, isGarudaCompanyName: action.status };
    case SET_VOUCHER_TOKEN_SELECTED_ROWS:
      return { ...state, selectedRows: action.data };
    case REVOKING_SELECTED_VOUCHER_TOKENS:
      return { ...state, revokingSelectedRows: action.status };
    case SET_VOUCHER_TOKEN_MOVE_ALL_DIALOG_VISIBILITY:
      return { ...state, moveSelectedDialogVisibility: action.status };
    case SET_VOUCHER_TOKEN_SELECTED_VOUCHER_DESTINATION_OPTION:
      return { ...state, selectedVoucherDestination: action.option };
    case MOVING_SELECTED_VOUCHER_TOKENS:
      return { ...state, movingSelectedRows: action.status };
    default: return state;
  }
};
