import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { changePasswordAsync, setAlertErrorMessage, setSettingsDialogVisibility } from '../../redux/action';
import { RXFORM_SETTINGS_DIALOG } from '../../constant';
import { } from '../../helper';
import SettingsDialog from './settings-dialog.presentation';


const mapStateToProps = (state) => ({
  visible: state.uiBasePage.settingsDialogVisibility,
  changingPassword: state.uiBasePage.changingPassword,
});

const mapDispatchToProps = (dispatch) => ({
  onSavePressed: (values, logoutDevices) => {
    dispatch(changePasswordAsync({ ...values, logoutDevices }))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setSettingsDialogVisibility(false));
    dispatch(reset(RXFORM_SETTINGS_DIALOG));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialog);
