import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { downloadUsersAsync } from '../../../voucher';
import {
  PAGE_MODE_TABLE,
  INITIAL_ORDER_BY_COMPLETED_PROFILES,
  RXFORM_COMPLETED_PROFILE,
  MENUID_GAME_ACTIVITY_COMPLETED_PROFILE,
} from '../../constant';
import {
  transformInitialValues,
  transformUserDropdownData,
  toCurrency,
  debounceSearch,
} from '../../helper';
import {
  setActiveSideMenuItem,
  setAlertErrorMessage,
  setCompletedProfileSearchText,
  clearCompletedProfiles,
  setCompletedProfileSelectedPageSize,
  setCompletedProfileSelectedOrderBy,
  setCompletedProfileAdvancedFilterDialogSelectedFilterString,
  setCompletedProfileTappedId,
  downloadCompletedProfilesAsync,
  downloadCompletedProfileAsync,
  setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  clearUsers,
} from '../../redux/action';
import CompletedProfilePage from './completed-profile.presentation';

const getInitialValues = (state) => {
  const {
    completedProfiles,
    uiFunctionalPage,
    uiCompletedProfile,
  } = state;
  const { data } = completedProfiles;
  const { downloadingDeleting, tappedId } = uiCompletedProfile;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      pointAmount: toCurrency(found.pointAmount),
    })
    : {};
  return result;
};

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearUsers());
  dispatch(downloadUsersAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});

const searchNameDebounce = debounceSearch((dispatch) => {
  dispatch(clearCompletedProfiles());
  dispatch(downloadCompletedProfilesAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});

const mapStateToProps = (state) => ({
  downloading: state.uiCompletedProfile.downloading,
  downloadingDeleting: state.uiCompletedProfile.downloadingDeleting,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GAME_ACTIVITY_COMPLETED_PROFILE));
    dispatch(setCompletedProfileSearchText(''));
    dispatch(clearCompletedProfiles());
    dispatch(setCompletedProfileSelectedPageSize(20));
    dispatch(
      setCompletedProfileSelectedOrderBy(
        INITIAL_ORDER_BY_COMPLETED_PROFILES,
      ),
    );
    dispatch(downloadCompletedProfilesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('createdDate', 'createdDate')
      .replace('registeredUser', 'profile.id');

    dispatch(
      setCompletedProfileAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearCompletedProfiles());
    dispatch(downloadCompletedProfilesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCompletedProfilesAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCompletedProfileSelectedPageSize(pageSize));
    dispatch(downloadCompletedProfilesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setCompletedProfileSelectedPageSize(pageSize));
    dispatch(clearCompletedProfiles());
    dispatch(downloadCompletedProfilesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCompletedProfiles());
    dispatch(
      setCompletedProfileAdvancedFilterDialogSelectedFilterString(''),
    );
    dispatch(
      setCompletedProfileSelectedOrderBy(
        INITIAL_ORDER_BY_COMPLETED_PROFILES,
      ),
    );
    dispatch(downloadCompletedProfilesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCompletedProfileSearchText(text));
      if (text.length >= 3 || text.length === 0) {
        searchNameDebounce(dispatch);
      }
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCompletedProfileSelectedOrderBy(orderBy));
    dispatch(clearCompletedProfiles());
    dispatch(downloadCompletedProfilesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setCompletedProfileTappedId(id));
    dispatch(downloadCompletedProfileAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COMPLETED_PROFILE));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompletedProfilePage);
