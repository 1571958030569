import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, FormControl, FormHelperText, makeStyles, Typography,
} from '@material-ui/core';
import {
  COLOR_BACKGROUND_SECONDARY, COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_PRIMARY,
  FILE_EXTENSION_PDF, FILE_EXTENSION_CSV,
} from '../../constant';
import LocalizedString from '../../localization';

const pdfIcon = require('../../asset/pdf-icon.png');
const csvIcon = require('../../asset/csv-icon.png');

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
  },
  innerContainer: {
    padding: 20,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 12,
  },
  primaryText: {
    fontWeight: 'bold',
    color: COLOR_PRIMARY,
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const renderFile = (value, fileName, classes, acceptedFileExtension) => {
  let icon;
  if (acceptedFileExtension === FILE_EXTENSION_PDF)icon = pdfIcon;
  if (acceptedFileExtension === FILE_EXTENSION_CSV)icon = csvIcon;

  if (value.length > 0) {
    return (
      <div className={classes.rowContainer}>
        <Avatar src={icon} variant="square" className={classes.icon} />

        {typeof value === 'string' && (
        <a href={value} rel="noopener noreferrer">
          <Typography className={classes.primaryText}>
            {fileName || LocalizedString.common.buttonCaptionDownloadFile}
          </Typography>
        </a>
        )}
      </div>
    );
  }
  return null;
};

const renderField = (
  disabled, onFileChange, onFileSelected, value, fileName, classes,
  acceptedFileExtension,
) => {
  if (disabled) {
    return renderFile(value, fileName, classes);
  }
  return (
    <div className={classes.innerContainer}>
      <input
        type="file"
        onChange={(e) => onFileChange(e, onFileSelected)}
        accept={acceptedFileExtension}
        style={{ width: '100%' }}
      />

      {renderFile(value, fileName, classes, acceptedFileExtension)}
    </div>
  );
};

const FilePickerField = ({
  disabled, error, hidden,
  onFileChange, onFileSelected,
  value,
  fileName, helperText, label,
  acceptedFileExtension,
  ...props
}) => {
  const classes = useStyles();

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl
          className={classes.form}
          variant="outlined"
          error={error}
          {...props}
        >
          <Typography>{label}</Typography>
          {renderField(
            disabled, onFileChange, onFileSelected, value, fileName, classes,
            acceptedFileExtension,
          )}
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default FilePickerField;

FilePickerField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fileName: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  acceptedFileExtension: PropTypes.oneOf([FILE_EXTENSION_PDF, FILE_EXTENSION_CSV]),
};

FilePickerField.defaultProps = {
  error: false,
  disabled: false,
  hidden: false,
  fileName: null,
  label: null,
  helperText: null,
  value: null,
  acceptedFileExtension: FILE_EXTENSION_PDF,
};
