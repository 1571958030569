import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW, RXFIELD_TEST_VEHICLE_EVENT,
  RXFIELD_TEST_VEHICLE_MODEL, RXFIELD_TEST_VEHICLE_DAILY_QUOTA,
  RXSTATE_TEST_VEHICLES, RXSTATE_TEST_VEHICLE_PAGE, RXFORM_TEST_DRIVE_TEST_VEHICLE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateEvent } from '../../validation';

const renderDialogContent = (
  initialValues, addingEditing, downloadingDeleting, pageMode,
  events, loadingEvent, onChangeEventText, onEventOptionSelected,
  carModels, loadingCarModels, onChangeCarModelText, onCarModelOptionSelected,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_TEST_VEHICLE_EVENT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.testVehiclePage.placeholderEvent}
            label={LocalizedString.testVehiclePage.placeholderEvent}
            data={events}
            value={initialValues.event}
            loading={loadingEvent}
            onChangeText={onChangeEventText}
            onOptionSelected={onEventOptionSelected}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_VEHICLE_MODEL}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.testVehiclePage.placeholderModel}
            label={LocalizedString.testVehiclePage.placeholderModel}
            data={carModels}
            value={initialValues.modelName}
            loading={loadingCarModels}
            onChangeText={onChangeCarModelText}
            onOptionSelected={onCarModelOptionSelected}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_VEHICLE_DAILY_QUOTA}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.testVehiclePage.placeholderDailyQuota}
            placeholder={LocalizedString.testVehiclePage.placeholderDailyQuota}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const TestVehiclePage = ({
  initialValues, events, carModels, addingEditing, downloading,
  downloadingDeleting, loadingCarModels, loadingEvent, handleSubmit,
  onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeEventText, onChangePage, onChangePageSize, onChangeCarModelText,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onEditPressed, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSubmitPressed,
  onSortPressed, onViewPressed, pageMode, onEventOptionSelected, onCarModelOptionSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_TEST_VEHICLES}
    uiPage={RXSTATE_TEST_VEHICLE_PAGE}
    filterColumns={[
      {
        title: LocalizedString.testVehiclePage.labelEvent,
        field: RXFIELD_TEST_VEHICLE_EVENT,
        type: FILTER_TYPE_DROPDOWN,
        data: events,
        loading: loadingEvent,
        onChangeFilterText: onChangeEventText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.testVehiclePage.labelModel,
        field: RXFIELD_TEST_VEHICLE_MODEL,
        type: FILTER_TYPE_DROPDOWN,
        data: carModels,
        loading: loadingCarModels,
        onChangeFilterText: onChangeCarModelText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.testVehiclePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.testVehiclePage.labelEvent, field: 'event.name', sorting: !downloading },
      { title: LocalizedString.testVehiclePage.labelModel, field: 'modelName', sorting: !downloading },
      { title: LocalizedString.testVehiclePage.labelDailyQuota, field: 'dailyQuota', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCancelPressed={onCancelPressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onSubmitPressed={onSubmitPressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    title={LocalizedString.testVehiclePage.title}
    createNewButtonCaption={LocalizedString.testVehiclePage.buttonCaptionCreateTestVehicle}
    deleteButtonCaption={LocalizedString.testVehiclePage.buttonCaptionDeleteTestVehicle}
    editButtonCaption={LocalizedString.testVehiclePage.buttonCaptionEditTestVehicle}
    useFullWidth
  >
    {renderDialogContent(
      initialValues, addingEditing, downloadingDeleting, pageMode,
      events, loadingEvent, onChangeEventText, onEventOptionSelected,
      carModels, loadingCarModels, onChangeCarModelText, onCarModelOptionSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TEST_DRIVE_TEST_VEHICLE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidateEvent,
})(TestVehiclePage);

TestVehiclePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  carModels: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingCarModels: PropTypes.bool.isRequired,
  loadingEvent: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onEventOptionSelected: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeCarModelText: PropTypes.func.isRequired,
  onCarModelOptionSelected: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
