import { INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_TRANSACTION } from '../../constant';
import {
  DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTION, DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTIONS,
  SAVING_HYUNDAI_CHALLENGE_TRANSACTIONS, SET_HYUNDAI_CHALLENGE_TRANSACTION_ADVANCED_FILTER_STRING,
  SET_HYUNDAI_CHALLENGE_TRANSACTION_SEARCH_TEXT,
  SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_ORDER_BY,
  SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_PAGE_SIZE,
  SET_HYUNDAI_CHALLENGE_TRANSACTION_TAPPED_ID,
} from '../action';


const initialState = {
  downloading: false,
  downloadingDeleting: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_TRANSACTION,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_HYUNDAI_CHALLENGE_TRANSACTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_HYUNDAI_CHALLENGE_TRANSACTION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_HYUNDAI_CHALLENGE_TRANSACTION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_HYUNDAI_CHALLENGE_TRANSACTION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_HYUNDAI_CHALLENGE_TRANSACTION_ADVANCED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SAVING_HYUNDAI_CHALLENGE_TRANSACTIONS:
      return { ...state, saving: action.status };
    default: return state;
  }
};
