import PropTypes from 'prop-types';

export const MenuItemShape = PropTypes.shape({
  id: PropTypes.string,
  level: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  selected: PropTypes.bool,
  parentId: PropTypes.string,
  icon: PropTypes.node,
});

export const TableColumnShape = PropTypes.shape({
  title: PropTypes.string,
  field: PropTypes.string,
  sorting: PropTypes.bool,
});

export const FormInitialValueShape = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
  ]),
);

export const SimpleDataShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
});

export const SummaryCountShape = PropTypes.shape({
  name: PropTypes.string,
  count: PropTypes.number,
});

export const TotalCountShape = PropTypes.shape({
  downloading: PropTypes.bool,
  icon: PropTypes.node,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

export const PointShape = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

export const MoreMenuShape = PropTypes.shape({
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
});

export const DealerDetailShape = PropTypes.shape({
  dealerId: PropTypes.string,
  dealerCode: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  province: PropTypes.string,
  regionCode: PropTypes.string,
  openTime: PropTypes.string,
  closeTime: PropTypes.string,
  dealerStatus: PropTypes.string,
  dealerTypeCode: PropTypes.string,
  dealerType: PropTypes.string,
  hasChargingStation: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  email: PropTypes.string,
  phone: PropTypes.string,
  freeTradeZone: PropTypes.bool,
  timeZone: PropTypes.string,
  hourlyLaborPrice: PropTypes.number,
  contactBodyAndPaint: PropTypes.string,
  id: PropTypes.string,
});
