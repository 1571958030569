import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, makeStyles, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import { Lightbulb } from 'mdi-material-ui';
import {
  COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_PRIMARY, COLOR_TERTIARY,
} from '../constant';
import { SimpleDataShape } from '../type';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  helperContainer: {
    margin: '10px 0px 10px 0px',
  },
  rowHelperContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  goldText: {
    fontWeight: 'bold',
    color: COLOR_TERTIARY,
  },
  blackText: {
    color: COLOR_BODY_TEXT,
    marginLeft: 38,
  },
  greenText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const OutlinedDropdownField = ({
  editable, error, hidden, useHelperIcon,
  onOptionSelected, data, defaultValue,
  label, helperText, placeholder, secondaryHelperText, subHelperText, value,
  ...props
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const selected = data.find((x) => (typeof x === 'object'
      ? x.value === event.target.value : x === event.target.value));
    onOptionSelected(selected);
  };

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          <InputLabel>{label}</InputLabel>
          <Select
            value={typeof value === 'object' ? value.value : value}
            onChange={handleChange}
            label={label || undefined}
            {...props}
          >
            {data.map((x) => (
              <MenuItem value={typeof x === 'object' ? x.value : x} key={typeof x === 'object' ? x.value : x}>
                {typeof x === 'object' ? x.label : x}
              </MenuItem>
            ))}
          </Select>

          {useHelperIcon ? (
            <div className={classes.helperContainer}>
              <FormHelperText className={classes.greenText}>{helperText}</FormHelperText>
              <div className={classes.rowHelperContainer}>
                <Lightbulb style={{ color: COLOR_TERTIARY }} />
                <FormHelperText className={classes.goldText}>{secondaryHelperText}</FormHelperText>
              </div>
              <FormHelperText className={classes.blackText}>{subHelperText}</FormHelperText>
            </div>
          )
            : (<FormHelperText className={classes.greenText}>{helperText}</FormHelperText>)}
        </FormControl>
      </div>
    );
  }
  return null;
};

export default OutlinedDropdownField;

OutlinedDropdownField.propTypes = {
  editable: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  useHelperIcon: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  secondaryHelperText: PropTypes.string,
  subHelperText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([SimpleDataShape, PropTypes.object, PropTypes.string]),
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(SimpleDataShape),
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.object, PropTypes.bool,
    ])),
  ]).isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
};

OutlinedDropdownField.defaultProps = {
  editable: false,
  error: false,
  hidden: false,
  useHelperIcon: false,
  onOptionSelected: () => {},
  helperText: null,
  label: null,
  placeholder: null,
  secondaryHelperText: null,
  subHelperText: null,
  defaultValue: null,
  value: null,
};
