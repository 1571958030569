import { downloadGiias2022Transaction } from '../../../helper';
import { addGiias2022Transaction, downloadingGiias2022Transaction } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiias2022Transaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiias2022Transaction;

    const giias2022Transaction = await downloadGiias2022Transaction(tappedId, token);
    dispatch(addGiias2022Transaction(giias2022Transaction));
  } finally {
    dispatch(downloadingGiias2022Transaction(false));
  }
};
