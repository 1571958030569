import { downloadJaw2023Spks, transformSearchText } from '../../../helper';
import { downloadingJaw2023Spks, setJaw2023Spks } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, jaw2023Spks, uiJaw2023Spk } = getState();

  const { token } = authentication;
  const { meta } = jaw2023Spks;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiJaw2023Spk;

  try {
    dispatch(downloadingJaw2023Spks(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const spks = await downloadJaw2023Spks(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    dispatch(setJaw2023Spks(spks));
  } finally {
    dispatch(downloadingJaw2023Spks(false));
  }
};
