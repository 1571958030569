import { SET_TRANSACTION_PAID, CLEAR_TRANSACTION_PAID } from '../action';

const initialState = {
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_PAID: {
      return {
        data: action.count,
      };
    }
    case CLEAR_TRANSACTION_PAID:
      return initialState;
    default:
      return state;
  }
};
