import {
  RXFIELD_ACCESSORY_VARIANT,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';

// eslint-disable-next-line import/prefer-default-export
export const rxFormValidateAccessory = (values) => {
  const requiredFields = [
    RXFIELD_ACCESSORY_VARIANT,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  return errors;
};
