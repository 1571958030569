import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, debounceSearch,
} from '../../helper';
import {
  INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_TRANSACTION,
  MENUID_HYUNDAI_CHALLENGE_TRANSACTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import HyundaiChallengeTransactionPage from './transaction.presentation';
import {
  clearHyundaiChallengeTransactions, setHyundaiChallengeTransactionAdvancedFilterString,
  setHyundaiChallengeTransactionSearchText, setHyundaiChallengeTransactionSelectedOrderBy,
  setHyundaiChallengeTransactionSelectedPageSize, setHyundaiChallengeTransactionTappedId,
  downloadHyundaiChallengeTransactionAsync, downloadHyundaiChallengeTransactionsAsync,
  saveHyundaiChallengeTransactionsAsync,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { hyundaiChallengeTransactions, uiFunctionalPage, uiHyundaiChallengeTransaction } = state;
  const { data } = hyundaiChallengeTransactions;
  const { downloadingDeleting, tappedId } = uiHyundaiChallengeTransaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiHyundaiChallengeTransaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_HYUNDAI_CHALLENGE_TRANSACTION));
    dispatch(setHyundaiChallengeTransactionAdvancedFilterString(''));
    dispatch(setHyundaiChallengeTransactionSearchText(''));
    dispatch(clearHyundaiChallengeTransactions());
    dispatch(setHyundaiChallengeTransactionSelectedPageSize(20));
    dispatch(
      setHyundaiChallengeTransactionSelectedOrderBy(INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_TRANSACTION),
    );
    dispatch(downloadHyundaiChallengeTransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setHyundaiChallengeTransactionSelectedOrderBy(orderBy));
    dispatch(clearHyundaiChallengeTransactions());
    dispatch(downloadHyundaiChallengeTransactionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setHyundaiChallengeTransactionTappedId(id));
    dispatch(downloadHyundaiChallengeTransactionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setHyundaiChallengeTransactionSearchText(text));
    dispatch(clearHyundaiChallengeTransactions());
    dispatch(downloadHyundaiChallengeTransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadHyundaiChallengeTransactionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setHyundaiChallengeTransactionSelectedPageSize(pageSize));
    dispatch(downloadHyundaiChallengeTransactionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setHyundaiChallengeTransactionSelectedPageSize(pageSize));
    dispatch(clearHyundaiChallengeTransactions());
    dispatch(downloadHyundaiChallengeTransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearHyundaiChallengeTransactions());
    dispatch(setHyundaiChallengeTransactionAdvancedFilterString(''));
    dispatch(
      setHyundaiChallengeTransactionSelectedOrderBy(INITIAL_ORDER_BY_HYUNDAI_CHALLENGE_TRANSACTION),
    );
    dispatch(downloadHyundaiChallengeTransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id');

    dispatch(setHyundaiChallengeTransactionAdvancedFilterString(text));
    dispatch(clearHyundaiChallengeTransactions());
    dispatch(downloadHyundaiChallengeTransactionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onDownloadPressed: () => {
    dispatch(saveHyundaiChallengeTransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HyundaiChallengeTransactionPage);
