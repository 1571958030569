import {
  REST_URL_ADD_EDIT_EV_CHARGING_URL,
  REST_URL_DEALERS,
  REST_URL_CAR_SERVICE_RESERVATIONS,
  REST_URL_SERVICE_REQUESTS,
  REST_URL_VIEW_CAR_SERVICE_RESERVATION,
  REST_URL_VIEW_DELETE_SERVICE_REQUEST,
  REST_URL_SAVE_FILE,
  REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_INVOICE,
  REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_DEALER_DETAIL,
  REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_SUMMARY,
  REST_URL_VIEW_DEALER,
  REST_URL_CHARGING_STATIONS,
  REST_URL_VIEW_CHARGING_STATION,
  REST_URL_DEALER_PROVINCES,
  REST_URL_DEALER_CITIES,
  REST_URL_CHARGING_STATION_CITIES,
  REST_URL_CHARGING_STATION_PROVINCES,
  REST_URL_VIEW_DELETE_EV_CHARGING_URL,
  REST_URL_EV_CHARGING_URLS,
  REPAIR_TYPE_BODY_AND_PAINT_LABEL,
  REPAIR_TYPE_BODY_AND_PAINT,
  REPAIR_TYPE_MOBILE_SERVICE,
  REPAIR_TYPE_MOBILE_SERVICE_LABEL,
  REPAIR_TYPE_GENERAL_REPAIR,
  REPAIR_TYPE_GENERAL_REPAIR_LABEL,
  DELIVERY_METHOD_DEALER,
  DELIVERY_METHOD_DEALER_LABEL,
  DELIVERY_METHOD_PICKUP,
  DELIVERY_METHOD_PICKUP_LABEL,
  DELIVERY_METHOD_DELIVERY,
  DELIVERY_METHOD_DELIVERY_LABEL,
  DELIVERY_METHOD_PICKUP_DELIVERY,
  DELIVERY_METHOD_PICKUP_DELIVERY_LABEL,
  BANK_TRANSFER_METHOD_BCA_VA,
  BANK_TRANSFER_METHOD_BCA_VA_LABEL,
  BANK_TRANSFER_METHOD_BNI_VA,
  BANK_TRANSFER_METHOD_BNI_VA_LABEL,
  BANK_TRANSFER_METHOD_PERMATA_VA,
  BANK_TRANSFER_METHOD_PERMATA_VA_LABEL,
  BANK_TRANSFER_METHOD_MANDIRI_VA,
  BANK_TRANSFER_METHOD_MANDIRI_VA_LABEL,
  GOPAY_PAYMENT_METHOD,
  GOPAY_PAYMENT_METHOD_LABEL,
  SERVICE_STATUS_DRAFT,
  SERVICE_STATUS_DRAFT_LABEL,
  SERVICE_STATUS_WAITING_DMS,
  SERVICE_STATUS_WAITING_DMS_LABEL,
  SERVICE_STATUS_RESERVED,
  SERVICE_STATUS_RESERVED_LABEL,
  SERVICE_STATUS_WAITING_PAYMENT,
  SERVICE_STATUS_WAITING_PAYMENT_LABEL,
  SERVICE_STATUS_SCHEDULED,
  SERVICE_STATUS_SCHEDULED_LABEL,
  SERVICE_STATUS_CANCELLED,
  SERVICE_STATUS_CANCELLED_LABEL,
  SERVICE_STATUS_IN_PROGRESS,
  SERVICE_STATUS_IN_PROGRESS_LABEL,
  SERVICE_STATUS_FINISHED,
  SERVICE_STATUS_FINISHED_LABEL,
  SERVICE_STATUS_IN_PREPARATION,
  SERVICE_STATUS_IN_PREPARATION_LABEL,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_PENDING_LABEL,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_SUCCESS_LABEL,
  PAYMENT_STATUS_ERROR,
  PAYMENT_STATUS_ERROR_LABEL,
} from './constant';
import {
  buildQueryParams, sendGetRequest, transformQueryResult,
  sendDeleteRequest, sendGetFileRequest, sendPutRequest,
  sendPostRequest,
} from '../../helper';

export * from '../../helper';

export const downloadServiceRequests = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SERVICE_REQUESTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_SERVICE_REQUESTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadServiceRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SERVICE_REQUEST.replace(/\{id\}/, id), token);
  return response;
};

export const deleteServiceRequest = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SERVICE_REQUEST.replace(/\{id\}/, id), token);
};

export const downloadDealers = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DEALERS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_DEALERS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadDealer = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DEALER.replace(/\{id\}/, id), token);
  return response;
};

export const downloadDealerProvinces = async (token) => {
  const response = await sendGetRequest(REST_URL_DEALER_PROVINCES, token);
  const reduceResponse = response.reduce((a, v) => ({ ...a, [v]: v }), {});
  const transformedResponse = Object.keys(reduceResponse)
    .map((key) => ({ name: key, id: reduceResponse[key] }));
  return transformedResponse;
};

export const downloadDealerCities = async (token) => {
  const response = await sendGetRequest(REST_URL_DEALER_CITIES, token);
  const reduceResponse = response.reduce((a, v) => ({ ...a, [v]: v }), {});
  const transformedResponse = Object.keys(reduceResponse)
    .map((key) => ({ name: key, id: reduceResponse[key] }));
  return transformedResponse;
};

export const downloadCarServiceReservations = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_CAR_SERVICE_RESERVATIONS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_CAR_SERVICE_RESERVATIONS, pageNumber, pageSize, orderBy,
      searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCarServiceReservation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CAR_SERVICE_RESERVATION.replace(/\{id\}/, id), token);
  return response;
};

export const saveCarServiceReservations = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const downloadUrl = REST_URL_CAR_SERVICE_RESERVATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadCarServiceReservationInvoice = async (reservationId) => {
  const url = REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_INVOICE.replace(/{reservationId}/, reservationId);
  const response = await sendGetFileRequest(url);
  return response;
};

export const downloadCarServiceReservationDealerDetail = async (dealerId, token) => {
  const url = REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_DEALER_DETAIL.replace(/{dealerId}/, dealerId);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadCarServiceReservationSummary = async (reservationId, token) => {
  const url = REST_URL_DOWNLOAD_CAR_SERVICE_RESERVATION_SUMMARY.replace(/{reservationId}/, reservationId);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadChargingStations = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_CHARGING_STATIONS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_CHARGING_STATIONS, pageNumber, pageSize, orderBy,
      searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadChargingStation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CHARGING_STATION.replace(/\{id\}/, id), token);
  return response;
};

export const downloadChargingStationCities = async (token) => {
  const response = await sendGetRequest(REST_URL_CHARGING_STATION_CITIES, token);
  const reduceResponse = response.reduce((a, v) => ({ ...a, [v]: v }), {});
  const transformedResponse = Object.keys(reduceResponse)
    .map((key) => ({ name: key, id: reduceResponse[key] }));
  return transformedResponse;
};

export const downloadChargingStationProvinces = async (token) => {
  const response = await sendGetRequest(REST_URL_CHARGING_STATION_PROVINCES, token);
  const reduceResponse = response.reduce((a, v) => ({ ...a, [v]: v }), {});
  const transformedResponse = Object.keys(reduceResponse)
    .map((key) => ({ name: key, id: reduceResponse[key] }));
  return transformedResponse;
};

export const downloadEvChargingUrls = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_EV_CHARGING_URLS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_EV_CHARGING_URLS, pageNumber, pageSize, orderBy,
      searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEvChargingUrl = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EV_CHARGING_URL.replace(/\{id\}/, id), token);
  return response;
};

export const addEvChargingUrl = async (code, url, notes, token) => {
  const body = {
    code, url, notes,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_EV_CHARGING_URL, body, token);
};

export const editEvChargingUrl = async (id, code, url, notes, token) => {
  const body = {
    id, code, url, notes,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_EV_CHARGING_URL, body, token);
};

export const deleteEvChargingUrl = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EV_CHARGING_URL.replace(/\{id\}/, id), token);
};

export const getRepairType = (repairType) => {
  switch (repairType) {
    case REPAIR_TYPE_GENERAL_REPAIR:
      return REPAIR_TYPE_GENERAL_REPAIR_LABEL;
    case REPAIR_TYPE_MOBILE_SERVICE:
      return REPAIR_TYPE_MOBILE_SERVICE_LABEL;
    case REPAIR_TYPE_BODY_AND_PAINT:
      return REPAIR_TYPE_BODY_AND_PAINT_LABEL;
    default:
      return '';
  }
};

export const getDeliveryMethod = (deliveryMethod) => {
  switch (deliveryMethod) {
    case DELIVERY_METHOD_DEALER:
      return DELIVERY_METHOD_DEALER_LABEL;
    case DELIVERY_METHOD_PICKUP:
      return DELIVERY_METHOD_PICKUP_LABEL;
    case DELIVERY_METHOD_DELIVERY:
      return DELIVERY_METHOD_DELIVERY_LABEL;
    case DELIVERY_METHOD_PICKUP_DELIVERY:
      return DELIVERY_METHOD_PICKUP_DELIVERY_LABEL;
    default:
      return '';
  }
};

export const getPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case BANK_TRANSFER_METHOD_BCA_VA:
      return BANK_TRANSFER_METHOD_BCA_VA_LABEL;
    case BANK_TRANSFER_METHOD_BNI_VA:
      return BANK_TRANSFER_METHOD_BNI_VA_LABEL;
    case BANK_TRANSFER_METHOD_PERMATA_VA:
      return BANK_TRANSFER_METHOD_PERMATA_VA_LABEL;
    case BANK_TRANSFER_METHOD_MANDIRI_VA:
      return BANK_TRANSFER_METHOD_MANDIRI_VA_LABEL;
    case GOPAY_PAYMENT_METHOD:
      return GOPAY_PAYMENT_METHOD_LABEL;
    default:
      return '';
  }
};

export const getPaymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case PAYMENT_STATUS_PENDING:
      return PAYMENT_STATUS_PENDING_LABEL;
    case PAYMENT_STATUS_SUCCESS:
      return PAYMENT_STATUS_SUCCESS_LABEL;
    case PAYMENT_STATUS_ERROR:
      return PAYMENT_STATUS_ERROR_LABEL;
    default:
      return '';
  }
};

export const getServiceStatus = (serviceStatus) => {
  switch (serviceStatus) {
    case SERVICE_STATUS_DRAFT:
      return SERVICE_STATUS_DRAFT_LABEL;
    case SERVICE_STATUS_WAITING_DMS:
      return SERVICE_STATUS_WAITING_DMS_LABEL;
    case SERVICE_STATUS_RESERVED:
      return SERVICE_STATUS_RESERVED_LABEL;
    case SERVICE_STATUS_WAITING_PAYMENT:
      return SERVICE_STATUS_WAITING_PAYMENT_LABEL;
    case SERVICE_STATUS_SCHEDULED:
      return SERVICE_STATUS_SCHEDULED_LABEL;
    case SERVICE_STATUS_CANCELLED:
      return SERVICE_STATUS_CANCELLED_LABEL;
    case SERVICE_STATUS_IN_PROGRESS:
      return SERVICE_STATUS_IN_PROGRESS_LABEL;
    case SERVICE_STATUS_FINISHED:
      return SERVICE_STATUS_FINISHED_LABEL;
    case SERVICE_STATUS_IN_PREPARATION:
      return SERVICE_STATUS_IN_PREPARATION_LABEL;
    default:
      return '';
  }
};
