import { connect } from 'react-redux';
import {
  MENUID_CAR_SERVICE_DEALER, REST_URL_GOOGLE_MAP, INITIAL_ORDER_BY_DEALERS, PAGE_MODE_TABLE,
} from '../../constant';
import { transformInitialValues, transformDropdownData } from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage, setDealerSearchText,
  clearDealers, setDealerAdvancedFilterDialogSelectedFilterString, downloadDealersAsync,
  setDealerSelectedPageSize, setDealerSelectedOrderBy, setDealerTappedId,
  downloadDealerAsync, clearDealerProvinces,
  downloadDealerProvincesAsync, clearDealerCities, downloadDealerCitiesAsync,
} from '../../redux/action';
import CarServiceDealerPage from './dealer.presentation';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const {
    dealers, uiFunctionalPage,
    uiDealer,
  } = state;
  const { data } = dealers;
  const { downloadingDeleting, tappedId } = uiDealer;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      dealerId: found.dealerId,
      dealerCode: found.dealerCode,
      name: found.name,
      address: found.address,
      city: found.city,
      region: found.region,
      postalCode: found.postalCode,
      email: found.email,
      phone: found.phone,
      type: found.type,
      dealerStatus: found.dealerStatus,
      dealerType: found.dealerType,
      dealerTypeCode: found.dealerTypeCode,
      hasChargingStation:
            found.hasChargingStation === true
              ? GlobalLocalizedString.common.labelYes
              : GlobalLocalizedString.common.labelNo,
      latitude: found.latitude,
      longitude: found.longitude,
    })
    : {};
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiDealer.downloading,
  downloadingDeleting: state.uiDealer.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiDealer.tappedId,
  initialValues: getInitialValues(state),
  loadingDealerProvince: state.uiDealer.downloadingProvince,
  loadingDealerCity: state.uiDealer.downloadingCity,
  dealerProvinces: transformDropdownData(state.dealerProvinces.data),
  dealerCities: transformDropdownData(state.dealerCities.data),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_SERVICE_DEALER));
    dispatch(setDealerSearchText(''));
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearDealers());
    dispatch(setDealerSelectedPageSize(20));
    dispatch(setDealerSelectedOrderBy(INITIAL_ORDER_BY_DEALERS));
    dispatch(downloadDealersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(clearDealerProvinces());
    dispatch(clearDealerCities());
    dispatch(downloadDealerProvincesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDealerCitiesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/type/, 'type')
      .replace(/dealerCity/, 'city')
      .replace(/dealerProvince/, 'province')
      .replace(/region/, 'region')
      .replace(/dealerStatus/, 'dealerStatus')
      .replace(/dealerType/, 'dealerType')
      .replace(/hasChargingStation/, 'hasChargingStation')
      .replace(/status/, 'status');

    dispatch(setDealerAdvancedFilterDialogSelectedFilterString(text));

    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDealersAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDealerSelectedPageSize(pageSize));
    dispatch(downloadDealersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setDealerSelectedPageSize(pageSize));
    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearDealers());
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setDealerSelectedOrderBy(INITIAL_ORDER_BY_DEALERS));
    dispatch(downloadDealersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setDealerSearchText(text));
      dispatch(clearDealers());
      await dispatch(downloadDealersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setDealerSelectedOrderBy(orderBy));
    dispatch(clearDealers());
    dispatch(downloadDealersAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setDealerTappedId(id));
    dispatch(downloadDealerAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onOpenGoogleMaps: (lat, long) => {
    const url = REST_URL_GOOGLE_MAP.replace(/\{long\}/, long).replace(/\{lat\}/, lat);
    window.open(url);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarServiceDealerPage);
