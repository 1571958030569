import { downloadGiiasSemarangTransaction } from '../../../helper';
import { addGiiasSemarang2022Transaction, downloadingGiiasSemarang2022Transaction } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiiasSemarang2022Transaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiiasSemarangTransaction;

    const giias2022Transaction = await downloadGiiasSemarangTransaction(tappedId, token);
    dispatch(addGiiasSemarang2022Transaction(giias2022Transaction));
  } finally {
    dispatch(downloadingGiiasSemarang2022Transaction(false));
  }
};
