
import { INITIAL_ORDER_BY_GIFT_VOUCHER_REDEMPTIONS } from '../../constant';
import {
  DOWNLOADING_GIFT_VOUCHER_REDEMPTION,
  DOWNLOADING_GIFT_VOUCHER_REDEMPTIONS, SET_GIFT_VOUCHER_REDEMPTION_SEARCH_TEXT,
  SET_GIFT_VOUCHER_REDEMPTION_SELECTED_ORDER_BY, SET_GIFT_VOUCHER_REDEMPTION_SELECTED_PAGE_SIZE,
  SET_GIFT_VOUCHER_REDEMPTION_TAPPED_ID,
  SET_GIFT_VOUCHER_REDEMPTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_GIFT_VOUCHER_REDEMPTIONS,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_GIFT_VOUCHER_REDEMPTIONS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_GIFT_VOUCHER_REDEMPTION:
      return { ...state, downloadingDeleting: action.status };
    case SET_GIFT_VOUCHER_REDEMPTION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_GIFT_VOUCHER_REDEMPTION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_GIFT_VOUCHER_REDEMPTION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_GIFT_VOUCHER_REDEMPTION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_GIFT_VOUCHER_REDEMPTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
