import { downloadRedemptionHistory } from '../../../helper';
import {
  addRedemptionHistory, downloadingRedemptionHistory,

  setRedemptionHistorySelectedMerchant,
  setRedemptionHistorySelectedVoucher,
  setRedemptionHistorySelectedReward,
  setRedemptionHistorySelectedVoucherBypassMaxPurchase,
  setRedemptionHistorySelectedVoucherBypassAchievement,
} from '../simple-action';
import { approval } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingRedemptionHistory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRedemptionHistory;

    {
      const result = await downloadRedemptionHistory(tappedId, token);

      dispatch(addRedemptionHistory(result));

      dispatch(setRedemptionHistorySelectedMerchant(
        { label: result.merchantName || '', value: result.merchantId || '' },
      ));
      dispatch(setRedemptionHistorySelectedVoucher(
        { label: result.voucherName || '', value: result.voucherId || '' },
      ));
      dispatch(setRedemptionHistorySelectedReward(
        { label: result.rewardType || '', value: result.rewardType || '' },
      ));
      dispatch(setRedemptionHistorySelectedVoucherBypassMaxPurchase(
        {
          label: result.voucherBypassMaxPurchase
            ? approval[0].label : approval[1].label,
          value: result.voucherBypassMaxPurchase
            ? approval[0].value : approval[1].value,
        },
      ));
      dispatch(setRedemptionHistorySelectedVoucherBypassAchievement(
        {
          label: result.voucherBypassMaxPurchase
            ? approval[0].label : approval[1].label,
          value: result.voucherBypassMaxPurchase
            ? approval[0].value : approval[1].value,
        },
      ));
    }
  } finally {
    dispatch(downloadingRedemptionHistory(false));
  }
};
