import {
  downloadingDeletingNewUserNotification,
  addNewUserNotification,
} from '../simple-action';
import { downloadNewUserNotification } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiNewUserNotification } = getState();

  const { token } = authentication;
  const { tappedId } = uiNewUserNotification;

  try {
    dispatch(downloadingDeletingNewUserNotification(true));

    const newUserNotification = await downloadNewUserNotification(
      tappedId,
      token,
    );

    dispatch(addNewUserNotification(newUserNotification));
  } finally {
    dispatch(downloadingDeletingNewUserNotification(false));
  }
};
