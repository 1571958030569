import {
  REST_URL_DAILY_CHECKINS, REST_URL_COMPLETED_PROFILES,
  REST_URL_GAME_ACTIVITY_REGISTRATION_REFERRALS, REST_URL_RECURRANCE_REFERRAL_REWARDS,
  REST_URL_VIEW_DAILY_CHECKIN, REST_URL_VIEW_COMPLETED_PROFILE,
  REST_URL_VIEW_GAME_ACTIVITY_REGISTRATION_REFERRAL, REST_URL_VIEW_RECURRANCE_REFERRAL_REWARD,
  REST_URL_NEW_USER_NOTIFICATIONS, REST_URL_SAVE_FILE,
  REST_URL_VIEW_NEW_USER_NOTIFICATION,
} from './constant';
import {
  buildQueryParams, sendGetRequest, transformQueryResult, sendGetFileRequest,
} from '../../helper';

export * from '../../helper';

export const downloadGameActivityRegistrationReferrals = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_GAME_ACTIVITY_REGISTRATION_REFERRALS.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_GAME_ACTIVITY_REGISTRATION_REFERRALS, pageNumber, pageSize,
      orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGameActivityRegistrationReferral = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GAME_ACTIVITY_REGISTRATION_REFERRAL.replace(/\{id\}/, id), token);
  return response;
};

export const saveGameActivityRegistrationReferrals = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const downloadUrl = REST_URL_GAME_ACTIVITY_REGISTRATION_REFERRALS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadRecurrenceReferralRewards = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_RECURRANCE_REFERRAL_REWARDS.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_RECURRANCE_REFERRAL_REWARDS, pageNumber, pageSize,
      orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadRecurrenceReferralReward = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_RECURRANCE_REFERRAL_REWARD.replace(/\{id\}/, id), token);
  return response;
};

export const downloadDailyCheckins = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_DAILY_CHECKINS.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_DAILY_CHECKINS, pageNumber, pageSize,
      orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadDailyCheckin = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DAILY_CHECKIN.replace(/\{id\}/, id), token);
  return response;
};

export const downloadCompletedProfiles = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_COMPLETED_PROFILES.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_COMPLETED_PROFILES, pageNumber, pageSize,
      orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCompletedProfile = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_COMPLETED_PROFILE.replace(/\{id\}/, id), token);
  return response;
};

export const downloadNewUserNotifications = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_NEW_USER_NOTIFICATIONS.concat(filterString),
      pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_NEW_USER_NOTIFICATIONS, pageNumber, pageSize,
      orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadNewUserNotification = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_NEW_USER_NOTIFICATION.replace(/\{id\}/, id), token);
  return response;
};
