import { reset } from 'redux-form';
import {
  addingEditingModel, clearModels, setAlertErrorMessage, setFunctionalPageMode,
  setModelSelectedDiscontinued,
} from '../simple-action';
import { addModel, editModel, transformLocalImagePath } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_MODEL } from '../../../constant';
import downloadModelsAsync from './downloadModelsAsync';

export default (
  name, description, brochureUrl, image,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingModel(true));

    const { token } = getState().authentication;
    const { tappedId, selectedDiscontinued } = getState().uiModel;

    const isDiscontinued = selectedDiscontinued ? selectedDiscontinued.value : false;

    if (tappedId) {
      await editModel(
        tappedId, name, description, brochureUrl, transformLocalImagePath(image),
        isDiscontinued, token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addModel(
        name, description, brochureUrl, transformLocalImagePath(image),
        isDiscontinued, token,
      );
    }

    dispatch(reset(RXFORM_MODEL));
    dispatch(clearModels());
    dispatch(downloadModelsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setModelSelectedDiscontinued(''));
  } finally {
    dispatch(addingEditingModel(false));
  }
};
