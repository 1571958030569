import { connect } from 'react-redux';
import {
  loginAsync, setAlertErrorMessage, setAlertForcePopUp,
} from '../../redux/action';
import LoginScreen from './login.presentation';

const mapStateToProps = (state) => ({
  loggingIn: state.uiLogin.loggingIn,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoginPressed: async ({ username, password }) => {
    try {
      await dispatch(loginAsync(username, password, ownProps.history.push));
    } catch (error) {
      dispatch(setAlertForcePopUp(true));
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
