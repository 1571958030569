import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Paper, withStyles } from '@material-ui/core';
import {
  ArgumentAxis, Chart, Legend, LineSeries, Title, Tooltip, ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, HoverState } from '@devexpress/dx-react-chart';
import classNames from 'clsx';
import { toCurrency } from '../helper';

const legendStyles = () => ({
  root: {
    display: 'flex',
    top: 20,
    margin: 'auto',
    flexDirection: 'row',
  },
});

const LegendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);

LegendRootBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Root = withStyles(legendStyles, { name: 'LegendRoot' })(LegendRootBase);

const legendLabelStyles = (theme) => ({
  label: {
    paddingTop: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
});

const LegendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);

LegendLabelBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(LegendLabelBase);

const legendItemStyles = () => ({
  item: {
    flexDirection: 'column',
    marginTop: 20,
  },
});

const LegendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);

LegendItemBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(LegendItemBase);

const argumentAxisLabelStyles = () => ({
  label: {
    writingMode: 'vertical-lr',
    textOrientation: 'sideways',
    fontSize: 14,
  },
});

const ArgumentAxisLabel = ({ classes, ...restProps }) => (
  <ArgumentAxis.Label {...restProps} className={classes.label} />
);

ArgumentAxisLabel.propTypes = {
  classes: PropTypes.object.isRequired,
};

const xAxisLabel = withStyles(argumentAxisLabelStyles, { name: 'ArgumentAxisLabel' })(ArgumentAxisLabel);

const ValueAxisLabel = (props) => (
  <ValueAxis.Label text={`${props.text}%`} {...props} />
);

ValueAxisLabel.propTypes = {
  text: PropTypes.string,
};

ValueAxisLabel.defaultProps = {
  text: '',
};

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return (Target) => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const TooltipTitle = setStyle({
  fontWeight: 'bold',
  paddingBottom: 0,
})(Tooltip.Content);

const TooltipBody = setStyle({
  paddingTop: 0,
})(Tooltip.Content);

const SimpleLineChart = ({
  data, loading, useVerticalLabel, argumentField, name, title, valueField,
}) => {
  const [targetItem, setTargetItem] = useState(null);

  const TooltipContent = () => (
    <div>
      <div>
        <TooltipTitle
          text={data[targetItem.point][argumentField]}
        />
      </div>
      <div>
        <TooltipBody
          text={toCurrency(data[targetItem.point][valueField])}
        />
      </div>
    </div>
  );

  if (loading) {
    return (
      <CircularProgress color="inherit" size={20} />
    );
  }
  return (
    <Paper>
      <Chart data={data}>
        {useVerticalLabel ? (
          <ArgumentAxis
            tickFormat={() => (tick) => tick}
            labelComponent={xAxisLabel}
            showLine={false}
          />
        ) : (<ArgumentAxis tickFormat={() => (tick) => tick} />)}
        <ValueAxis labelComponent={ValueAxisLabel} />
        <LineSeries
          name={name}
          valueField={valueField}
          argumentField={argumentField}
        />
        <Legend position="bottom" rootComponent={Root} itemComponent={Item} labelComponent={Label} />
        <Title text={title} />
        <Animation />
        <EventTracker />
        <HoverState />
        <Tooltip
          targetItem={targetItem}
          onTargetItemChange={(target) => setTargetItem(target)}
          contentComponent={TooltipContent}
        />
        <Animation />
      </Chart>
    </Paper>
  );
};

export default SimpleLineChart;

SimpleLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  useVerticalLabel: PropTypes.bool,
  argumentField: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
};

SimpleLineChart.defaultProps = {
  loading: false,
  useVerticalLabel: false,
};
