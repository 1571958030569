import { LOGGING_IN, LOGGING_OUT } from '../action';

const initialState = {
  loggingIn: false,
  loggingOut: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return { ...state, loggingIn: action.status };
    case LOGGING_OUT:
      return { ...state, loggingOut: action.status };
    default: return state;
  }
};
