import { INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS } from '../../constant';
import {
  DOWNLOADING_TEST_DRIVE_EO_RESERVATION, SET_TEST_DRIVE_EO_RESERVATION_SELECTED_PAGE_SIZE,
  SET_TEST_DRIVE_EO_RESERVATION_TAPPED_ID, SET_TEST_DRIVE_EO_RESERVATION_SEARCH_TEXT,
  SET_TEST_DRIVE_EO_RESERVATION_SELECTED_ORDER_BY, DOWNLOADING_DELETING_TEST_DRIVE_EO_RESERVATION,
  SET_TEST_DRIVE_EO_RESERVATION_ADVANCED_FILTER_STRING,
  DOWNLOADING_SALES_CONSULTANTS,
  SET_SALES_CONSULTANT_SEARCH_STRING,
  SET_NOTIFICATION_SALES_CONSULTANT,
} from '../action/simple-action';

const initialState = {
  downloading: false,
  downloadingSalesConsultant: false,
  downloadingDeleting: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS,
  filterString: '',
  salesConsultantSearchString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_SALES_CONSULTANTS:
      return { ...state, downloadingSalesConsultant: action.status };
    case DOWNLOADING_TEST_DRIVE_EO_RESERVATION:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_TEST_DRIVE_EO_RESERVATION:
      return { ...state, downloadingDeleting: action.status };
    case SET_TEST_DRIVE_EO_RESERVATION_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_TEST_DRIVE_EO_RESERVATION_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_TEST_DRIVE_EO_RESERVATION_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_TEST_DRIVE_EO_RESERVATION_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_TEST_DRIVE_EO_RESERVATION_ADVANCED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_SALES_CONSULTANT_SEARCH_STRING:
      return { ...state, salesConsultantSearchString: action.text };
    case SET_NOTIFICATION_SALES_CONSULTANT:
      return { ...state, selectedConsultant: action.option };
    default: return state;
  }
};
