import { downloadWasteCollection } from '../../../helper';
import { addWasteCollection, downloadingDeletingWasteCollection } from '../simple-action';


export default () => async (dispatch, getState) => {
  const { authentication, uiWasteCollection } = getState();

  const { token } = authentication;
  const { tappedId } = uiWasteCollection;

  try {
    dispatch(downloadingDeletingWasteCollection(true));

    const wasteCollection = await downloadWasteCollection(
      tappedId,
      token,
    );

    dispatch(addWasteCollection(wasteCollection));
  } finally {
    dispatch(downloadingDeletingWasteCollection(false));
  }
};
