import { downloadCarServiceReservationDealerDetail } from '../../../helper';
import { downloadingCarServiceReservationDealerDetail, setCarServiceReservationDealerDetail } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    const { authentication, carServiceReservations, uiCarServiceReservation } = getState();
    const { token } = authentication;
    const { tappedId } = uiCarServiceReservation;
    const { data } = carServiceReservations;

    const dealerId = data[tappedId]?.dealer?.id;
    dispatch(downloadingCarServiceReservationDealerDetail(true));

    const result = await downloadCarServiceReservationDealerDetail(dealerId, token);
    dispatch(setCarServiceReservationDealerDetail(result));
  } finally {
    dispatch(downloadingCarServiceReservationDealerDetail(false));
  }
};
