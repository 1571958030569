import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_TEST_DRIVE_EO_EVENTS = 'DOWNLOADING_TEST_DRIVE_EO_EVENTS';
export const downloadingTestDriveEoEvents = makeActionCreator(DOWNLOADING_TEST_DRIVE_EO_EVENTS, 'status');

export const SET_TEST_DRIVE_EO_EVENTS = 'SET_TEST_DRIVE_EO_EVENTS';
export const setTestDriveEoEvents = makeActionCreator(SET_TEST_DRIVE_EO_EVENTS, 'data');

export const ADD_TEST_DRIVE_EO_EVENT = 'ADD_TEST_DRIVE_EO_EVENT';
export const addTestDriveEoEvent = makeActionCreator(ADD_TEST_DRIVE_EO_EVENT, 'data');

export const CLEAR_TEST_DRIVE_EO_EVENTS = 'CLEAR_TEST_DRIVE_EO_EVENTS';
export const clearTestDriveEoEvents = makeActionCreator(CLEAR_TEST_DRIVE_EO_EVENTS);

export const DOWNLOADING_DELETING_TEST_DRIVE_EO_EVENT = 'DOWNLOADING_DELETING_TEST_DRIVE_EO_EVENT';
export const downloadingDeletingTestDriveEoEvent = makeActionCreator(DOWNLOADING_DELETING_TEST_DRIVE_EO_EVENT, 'status');

export const COPYING_TEST_DRIVE_EO_EVENT_CODE = 'COPYING_TEST_DRIVE_EO_EVENT_CODE';
export const copyingTestDriveEoEventCode = makeActionCreator(COPYING_TEST_DRIVE_EO_EVENT_CODE, 'status');


export const SET_TEST_DRIVE_EO_EVENT_SELECTED_PAGE_SIZE = 'SET_TEST_DRIVE_EO_EVENT_SELECTED_PAGE_SIZE';
export const setTestDriveEoEventSelectedPageSize = makeActionCreator(SET_TEST_DRIVE_EO_EVENT_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_DRIVE_EO_EVENT_SELECTED_ORDER_BY = 'SET_TEST_DRIVE_EO_EVENT_SELECTED_ORDER_BY';
export const setTestDriveEoEventSelectedOrderBy = makeActionCreator(SET_TEST_DRIVE_EO_EVENT_SELECTED_ORDER_BY, 'order');

export const SET_TEST_DRIVE_EO_EVENT_TAPPED_ID = 'SET_TEST_DRIVE_EO_EVENT_TAPPED_ID';
export const setTestDriveEoEventTappedId = makeActionCreator(SET_TEST_DRIVE_EO_EVENT_TAPPED_ID, 'id');

export const SET_TEST_DRIVE_EO_EVENT_SEARCH_TEXT = 'SET_TEST_DRIVE_EO_EVENT_SEARCH_TEXT';
export const setTestDriveEoEventSearchText = makeActionCreator(SET_TEST_DRIVE_EO_EVENT_SEARCH_TEXT, 'text');

export const SET_TEST_DRIVE_EO_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_TEST_DRIVE_EO_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setTestDriveEoEventAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_TEST_DRIVE_EO_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_TEST_DRIVE_EO_TEST_VEHICLES = 'DOWNLOADING_TEST_DRIVE_EO_TEST_VEHICLES';
export const downloadingTestDriveEoTestVehicles = makeActionCreator(DOWNLOADING_TEST_DRIVE_EO_TEST_VEHICLES, 'status');

export const SET_TEST_DRIVE_EO_TEST_VEHICLES = 'SET_TEST_DRIVE_EO_TEST_VEHICLES';
export const setTestDriveEoTestVehicles = makeActionCreator(SET_TEST_DRIVE_EO_TEST_VEHICLES, 'data');

export const ADD_TEST_DRIVE_EO_TEST_VEHICLE = 'ADD_TEST_DRIVE_EO_TEST_VEHICLE';
export const addTestDriveEoTestVehicle = makeActionCreator(ADD_TEST_DRIVE_EO_TEST_VEHICLE, 'data');

export const CLEAR_TEST_DRIVE_EO_TEST_VEHICLES = 'CLEAR_TEST_DRIVE_EO_TEST_VEHICLES';
export const clearTestDriveEoTestVehicles = makeActionCreator(CLEAR_TEST_DRIVE_EO_TEST_VEHICLES);

export const DOWNLOADING_DELETING_TEST_DRIVE_EO_TEST_VEHICLE = 'DOWNLOADING_DELETING_TEST_DRIVE_EO_TEST_VEHICLE';
export const downloadingDeletingTestDriveEoTestVehicle = makeActionCreator(DOWNLOADING_DELETING_TEST_DRIVE_EO_TEST_VEHICLE, 'status');

export const ADDING_EDITING_TEST_DRIVE_EO_TEST_VEHICLE = 'ADDING_EDITING_TEST_DRIVE_EO_TEST_VEHICLE';
export const addingEditingTestDriveEoTestVehicle = makeActionCreator(ADDING_EDITING_TEST_DRIVE_EO_TEST_VEHICLE, 'status');


export const SET_TEST_DRIVE_EO_TEST_VEHICLE_SELECTED_PAGE_SIZE = 'SET_TEST_DRIVE_EO_TEST_VEHICLE_SELECTED_PAGE_SIZE';
export const setTestDriveEoTestVehicleSelectedPageSize = makeActionCreator(SET_TEST_DRIVE_EO_TEST_VEHICLE_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_DRIVE_EO_TEST_VEHICLE_SELECTED_ORDER_BY = 'SET_TEST_DRIVE_EO_TEST_VEHICLE_SELECTED_ORDER_BY';
export const setTestDriveEoTestVehicleSelectedOrderBy = makeActionCreator(SET_TEST_DRIVE_EO_TEST_VEHICLE_SELECTED_ORDER_BY, 'order');

export const SET_TEST_DRIVE_EO_TEST_VEHICLE_TAPPED_ID = 'SET_TEST_DRIVE_EO_TEST_VEHICLE_TAPPED_ID';
export const setTestDriveEoTestVehicleTappedId = makeActionCreator(SET_TEST_DRIVE_EO_TEST_VEHICLE_TAPPED_ID, 'id');

export const SET_TEST_DRIVE_EO_TEST_VEHICLE_SEARCH_TEXT = 'SET_TEST_DRIVE_EO_TEST_VEHICLE_SEARCH_TEXT';
export const setTestDriveEoTestVehicleSearchText = makeActionCreator(SET_TEST_DRIVE_EO_TEST_VEHICLE_SEARCH_TEXT, 'text');

export const SET_TEST_DRIVE_EO_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_TEST_DRIVE_EO_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setTestDriveEoTestVehicleAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_TEST_DRIVE_EO_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_TEST_DRIVE_EO_RESERVATION = 'DOWNLOADING_TEST_DRIVE_EO_RESERVATION';
export const downloadingTestDriveEoReservation = makeActionCreator(DOWNLOADING_TEST_DRIVE_EO_RESERVATION, 'status');

export const ADD_TEST_DRIVE_EO_RESERVATION = 'ADD_TEST_DRIVE_EO_RESERVATION';
export const addTestDriveEoReservation = makeActionCreator(ADD_TEST_DRIVE_EO_RESERVATION, 'data');

export const SET_TEST_DRIVE_EO_RESERVATIONS = 'SET_TEST_DRIVE_EO_RESERVATIONS';
export const setTestDriveEoReservations = makeActionCreator(SET_TEST_DRIVE_EO_RESERVATIONS, 'data');

export const CLEAR_TEST_DRIVE_EO_RESERVATIONS = 'CLEAR_TEST_DRIVE_EO_RESERVATIONS';
export const clearTestDriveEoReservations = makeActionCreator(CLEAR_TEST_DRIVE_EO_RESERVATIONS);

export const DOWNLOADING_DELETING_TEST_DRIVE_EO_RESERVATION = 'DOWNLOADING_DELETING_TEST_DRIVE_EO_RESERVATION';
export const downloadingDeletingTestDriveEoReservation = makeActionCreator(DOWNLOADING_DELETING_TEST_DRIVE_EO_RESERVATION, 'status');


export const SET_TEST_DRIVE_EO_RESERVATION_SELECTED_PAGE_SIZE = 'SET_TEST_DRIVE_EO_RESERVATION_SELECTED_PAGE_SIZE';
export const setTestDriveEoReservationSelectedPageSize = makeActionCreator(SET_TEST_DRIVE_EO_RESERVATION_SELECTED_PAGE_SIZE, 'size');

export const SET_TEST_DRIVE_EO_RESERVATION_SELECTED_ORDER_BY = 'SET_TEST_DRIVE_EO_RESERVATION_SELECTED_ORDER_BY';
export const setTestDriveEoReservationSelectedOrderBy = makeActionCreator(SET_TEST_DRIVE_EO_RESERVATION_SELECTED_ORDER_BY, 'order');

export const SET_TEST_DRIVE_EO_RESERVATION_TAPPED_ID = 'SET_TEST_DRIVE_EO_RESERVATION_TAPPED_ID';
export const setTestDriveEoReservationTappedId = makeActionCreator(SET_TEST_DRIVE_EO_RESERVATION_TAPPED_ID, 'id');

export const SET_TEST_DRIVE_EO_RESERVATION_SEARCH_TEXT = 'SET_TEST_DRIVE_EO_RESERVATION_SEARCH_TEXT';
export const setTestDriveEoReservationSearchText = makeActionCreator(SET_TEST_DRIVE_EO_RESERVATION_SEARCH_TEXT, 'text');

export const SET_TEST_DRIVE_EO_RESERVATION_ADVANCED_FILTER_STRING = 'SET_TEST_DRIVE_EO_RESERVATION_ADVANCED_FILTER_STRING';
export const setTestDriveEoReservationAdvancedFilterString = makeActionCreator(SET_TEST_DRIVE_EO_RESERVATION_ADVANCED_FILTER_STRING, 'text');

export const DOWNLOADING_SALES_CONSULTANTS = 'DOWNLOADING_SALES_CONSULTANTS';
export const downloadingSalesConsultants = makeActionCreator(DOWNLOADING_SALES_CONSULTANTS, 'status');

export const SET_SALES_CONSULTANTS = 'SET_SALES_CONSULTANTS';
export const setSalesConsultants = makeActionCreator(SET_SALES_CONSULTANTS, 'data');

export const CLEAR_SALES_CONSULTANTS = 'CLEAR_SALES_CONSULTANTS';
export const clearSalesConsultants = makeActionCreator(CLEAR_SALES_CONSULTANTS);

export const SET_SALES_CONSULTANT_SEARCH_STRING = 'SET_SALES_CONSULTANT_SEARCH_STRING';
export const setSalesConsultantSearchString = makeActionCreator(SET_SALES_CONSULTANT_SEARCH_STRING, 'text');

export const SET_NOTIFICATION_SALES_CONSULTANT = 'SET_NOTIFICATION_SALES_CONSULTANT';
export const setNotificationSelectedSalesConsultant = makeActionCreator(SET_NOTIFICATION_SALES_CONSULTANT, 'option');
