import { addTestDriveEoTestVehicle, downloadingDeletingTestDriveEoTestVehicle } from '../simple-action';
import { downloadTestDriveEoTestVehicle } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingTestDriveEoTestVehicle(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTestDriveEoTestVehicle;

    const result = await downloadTestDriveEoTestVehicle(tappedId, token);

    dispatch(addTestDriveEoTestVehicle(result));
  } finally {
    dispatch(downloadingDeletingTestDriveEoTestVehicle(false));
  }
};
