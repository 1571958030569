import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_VOUCHER_PURCHASE_REQUESTS, MENUID_VOUCHER_PURCHASE_REQUEST, PAGE_MODE_TABLE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  clearVoucherPurchaseRequests, downloadVoucherPurchaseRequestAsync,
  downloadVoucherPurchaseRequestsAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setVoucherPurchaseRequestSearchText, setVoucherPurchaseRequestSelectedOrderBy,
  setVoucherPurchaseRequestSelectedPageSize, setVoucherPurchaseRequestTappedId,
  setVoucherSearchText, clearVouchers, downloadVouchersAsync,
  setAdvancedFilterDialogSelectedFilterString,
  setVoucherAdvancedFilterDialogSelectedFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
} from '../../redux/action';
import VoucherPurchaseRequestPage from './voucher-purchase-request.presentation';
import { downloadUsersAsync } from '../../../../redux/action';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiVoucherPurchaseRequest, voucherPurchaseRequests } = state;
  const { data } = voucherPurchaseRequests;
  const { downloadingDeleting, tappedId } = uiVoucherPurchaseRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    voucherTokenId: found.voucherToken?.id || '',
    voucherId: found.voucherToken?.voucher?.id || '',
    voucherName: found.voucherToken?.voucher?.title || '',
    user: found.purchasedBy?.fullName || '',
    voucherImage:
    found.voucherToken && found.voucherToken.voucher && found.voucherToken.voucher.image
      ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.voucherToken.voucher.image}` : null,
  }) : {
    user: '',
    voucherId: '',
    voucherTokenId: '',
    voucherName: '',
    voucherImage: '',
    paymentId: '',
    requestStatus: '',
    statusNotes: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiVoucherPurchaseRequest.downloading,
  downloadingDeleting: state.uiVoucherPurchaseRequest.downloadingDeleting,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  users: transformUserDropdownData(state.users.data),
  loadingVoucher: state.uiVoucher.downloading,
  loadingUser: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_PURCHASE_REQUEST));
    dispatch(setVoucherPurchaseRequestSearchText(''));
    dispatch(clearVoucherPurchaseRequests());
    dispatch(setVoucherPurchaseRequestSelectedPageSize(20));
    dispatch(setVoucherPurchaseRequestSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_PURCHASE_REQUESTS));
    dispatch(downloadVoucherPurchaseRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVoucherPurchaseRequestsAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoucherPurchaseRequestSelectedPageSize(pageSize));
    dispatch(downloadVoucherPurchaseRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setVoucherPurchaseRequestSelectedPageSize(pageSize));
    dispatch(clearVoucherPurchaseRequests());
    dispatch(downloadVoucherPurchaseRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setVoucherPurchaseRequestSearchText(text));
    dispatch(clearVoucherPurchaseRequests());
    dispatch(downloadVoucherPurchaseRequestsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoucherPurchaseRequestSelectedOrderBy(orderBy));
    dispatch(clearVoucherPurchaseRequests());
    dispatch(downloadVoucherPurchaseRequestsAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setVoucherPurchaseRequestTappedId(id));
    dispatch(downloadVoucherPurchaseRequestAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/voucher/, 'voucherToken.voucher.id')
      .replace(/user/, 'purchasedBy.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearVoucherPurchaseRequests());
    dispatch(downloadVoucherPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearVoucherPurchaseRequests());
    dispatch(setVoucherPurchaseRequestSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_PURCHASE_REQUESTS));
    dispatch(downloadVoucherPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherPurchaseRequestPage);
