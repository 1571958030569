import { connect } from 'react-redux';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS, MENUID_TEST_DRIVE_EO_EVENT,
  PAGE_MODE_TABLE,
} from '../../constant';
import { toMoment, transformDropdownData, transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearCities, clearProvinces, clearTestDriveEoEvents, copyingTestDriveEoEventCode,
  setActiveSideMenuItem, setAlertErrorMessage, setCityAdvancedFilterDialogSelectedFilterString,
  setCitySearchText, setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText,
  setTestDriveEoEventAdvancedFilterDialogSelectedFilterString, setTestDriveEoEventSearchText,
  setTestDriveEoEventSelectedOrderBy, setTestDriveEoEventSelectedPageSize,
  setTestDriveEoEventTappedId,
  downloadTestDriveEoEventAsync, downloadTestDriveEoEventsAsync,
} from '../../redux/action';
import { downloadProvincesAsync, downloadCitiesAsync } from '../../../../redux/action';
import EventPage from './event.presentation';

const getInitialValues = (state) => {
  const {
    testDriveEoEvents, uiFunctionalPage, uiTestDriveEoEvent,
  } = state;
  const { data } = testDriveEoEvents;
  const { downloadingDeleting, tappedId } = uiTestDriveEoEvent;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      startTime: found.startTime ? toMoment(found.startTime, found.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      endTime: found.endTime ? toMoment(found.endTime, found.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      voucherRewards: found.voucherRewards[0].voucherName,
      province: found.city.province.name,
      city: found.city.name,
      eventCode: found.randomId ? `testdriveevent:${found.randomId}` : '',
      url1OpenOnExternalBrowser: found.url1OpenOnExternalBrowser
        ? GlobalLocalizedString.common.labelYes : GlobalLocalizedString.common.labelNo,
      url2OpenOnExternalBrowser: found.url2OpenOnExternalBrowser
        ? GlobalLocalizedString.common.labelYes : GlobalLocalizedString.common.labelNo,
      surveyUrlOpenOnExternalBrowser: found.surveyUrlOpenOnExternalBrowser
        ? GlobalLocalizedString.common.labelYes : GlobalLocalizedString.common.labelNo,
    }) : {
      vouchers: { label: '', value: '' },
      provinces: { label: '', value: '' },
      city: { label: '', value: '' },
      eventCode: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  cities: transformDropdownData(state.cities.data),
  provinces: transformDropdownData(state.provinces.data),
  copying: state.uiTestDriveEoEvent.copying,
  downloading: state.uiTestDriveEoEvent.downloading,
  loadingCity: state.uiCity.downloading,
  loadingProvince: state.uiProvince.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(setProvinceSearchText(''));
    dispatch(clearCities());
    dispatch(clearProvinces());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadProvincesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_TEST_DRIVE_EO_EVENT));
    dispatch(setTestDriveEoEventSearchText(''));
    dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearTestDriveEoEvents());
    dispatch(setTestDriveEoEventSelectedPageSize(20));
    dispatch(setTestDriveEoEventSelectedOrderBy(INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS));
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('city', 'city.id').replace('province', 'city.province.id');
    dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearTestDriveEoEvents());
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeCityText: async (text) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestDriveEoEventsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestDriveEoEventSelectedPageSize(pageSize));
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProvinceText: async (text) => {
    try {
      dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCopyPressed: (status) => {
    dispatch(copyingTestDriveEoEventCode(!status));
  },
  onRefresh: (pageSize) => {
    dispatch(setTestDriveEoEventSelectedPageSize(pageSize));
    dispatch(clearTestDriveEoEvents());
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestDriveEoEvents());
    dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setTestDriveEoEventSelectedOrderBy(INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS));
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setTestDriveEoEventSearchText(text));
      dispatch(clearTestDriveEoEvents());
      await dispatch(downloadTestDriveEoEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestDriveEoEventSelectedOrderBy(orderBy));
    dispatch(clearTestDriveEoEvents());
    dispatch(downloadTestDriveEoEventsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setTestDriveEoEventTappedId(id));
    dispatch(downloadTestDriveEoEventAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
