import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { Field } from 'redux-form';
import {
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_NAME,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_ADDRESS,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_POSTAL_CODE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_CITY,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_REGION,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_PROVINCE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_TIMEZONE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_EMAIL,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_PHONE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_DEALER_TYPE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_STATUS,
  COLOR_PRIMARY,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { AccessoryTransactionShape } from '../../type';

const TransactionDealerSection = ({ item, onOpenGoogleMaps }) => (
  <Grid container>
    <Grid item sm md>
      <Typography variant="subtitle2" style={{ marginLeft: 10, marginBottom: 10, fontWeight: 'bold' }}>
        {LocalizedString.accessoryTransactionPage.labelDealer}
      </Typography>
    </Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerName
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerName}
            defaultValue={item.pickupLocation.name}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerAddress
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerAddress}
            defaultValue={item.pickupLocation.address}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_POSTAL_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerPostalCode
                      }
            label={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerPostalCode
                      }
            defaultValue={item.pickupLocation.postalCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_CITY}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerCity
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerCity}
            defaultValue={item.pickupLocation.city}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_REGION}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerRegion
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerRegion}
            defaultValue={item.pickupLocation.region}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_PROVINCE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerProvince
                      }
            label={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerProvince
                      }
            defaultValue={item.pickupLocation.province}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_TIMEZONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerTimezone
                      }
            label={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerTimezone
                      }
            defaultValue={item.pickupLocation.timeZone}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerEmail
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerEmail}
            defaultValue={item.pickupLocation.email}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerPhone
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerPhone}
            defaultValue={item.pickupLocation.phone}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerStatus
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerStatus}
            defaultValue={item.pickupLocation.dealerStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DEALER_DEALER_TYPE}
            component={renderReduxFormOutlinedTextField}
            placeholder={
                        LocalizedString.accessoryTransactionPage.labelDealerItemDealerType
                      }
            label={LocalizedString.accessoryTransactionPage.labelDealerItemDealerType}
            defaultValue={item.pickupLocation.dealerType}
            disabled
          />
        </Grid>
        <Grid item>
          {item.pickupLocation.latitude && item.pickupLocation.longitude && (
            <Button
              variant="text"
              onClick={() => onOpenGoogleMaps(
                item.pickupLocation.latitude, item.pickupLocation.longitude,
              )}
              style={{
                color: COLOR_PRIMARY,
                marginLeft: 8,
              }}
            >
              {LocalizedString.accessoryTransactionPage.buttonCaptionOpenInGoogleMap}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default TransactionDealerSection;

TransactionDealerSection.propTypes = {
  item: AccessoryTransactionShape.isRequired,
  onOpenGoogleMaps: PropTypes.func.isRequired,
};
