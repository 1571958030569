import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES, MENUID_JAW_2023_SPK, PAGE_MODE_TABLE } from '../../constant';
import { transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  clearJaw2023Spks, downloadDeleteJaw2023SpkAsync,
  downloadJaw2023SpksAsync, saveJaw2023SpksAsync,
  setAlertErrorMessage, setJaw2023SpksOrderBy, setJaw2023SpksPageSize,
  setJaw2023SpksSearchText, setJaw2023SpksFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setJaw2023SpksTappedId, setActiveSideMenuItem,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import Jaw2023Spk from './jaw-2023-spk.presentation';

const getInitialValues = (state) => {
  const {
    jaw2023Spks, uiFunctionalPage, uiJaw2023Spk,
  } = state;
  const { data } = jaw2023Spks;
  const { downloadingSpk, tappedId } = uiJaw2023Spk;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingSpk
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    user: found.user
      ? { fullName: found.user.fullName, id: found.user.id }
      : { fullName: '', id: '' },
  };

  return result;
};

const mapStateToProps = (state) => ({
  spks: state.jaw2023Spks.data,
  downloading: state.uiJaw2023Spk.downloading,
  downloadingSpk: state.uiJaw2023Spk.downloadingSpk,
  users: transformUserDropdownData(state.users.data),
  initialValues: getInitialValues(state),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_JAW_2023_SPK));
    dispatch(setJaw2023SpksSearchText(''));
    dispatch(setJaw2023SpksFilterString(''));
    dispatch(clearJaw2023Spks());
    dispatch(setJaw2023SpksPageSize(20));
    dispatch(setJaw2023SpksOrderBy(INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES));

    dispatch(downloadJaw2023SpksAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setJaw2023SpksPageSize(pageSize));
    dispatch(clearJaw2023Spks());

    dispatch(downloadJaw2023SpksAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setJaw2023SpksOrderBy(orderBy));
    dispatch(clearJaw2023Spks());
    dispatch(downloadJaw2023SpksAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadJaw2023SpksAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setJaw2023SpksPageSize(pageSize));
    dispatch(downloadJaw2023SpksAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setJaw2023SpksSearchText(text));
      dispatch(clearJaw2023Spks());
      await dispatch(downloadJaw2023SpksAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/scanCodeUsername/, 'user.id');

    dispatch(setJaw2023SpksFilterString(text));
    dispatch(clearJaw2023Spks());
    dispatch(downloadJaw2023SpksAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setJaw2023SpksFilterString(''));
    dispatch(clearJaw2023Spks());
    dispatch(setJaw2023SpksOrderBy(INITIAL_ORDER_BY_JAW_2023_SCANNED_CODES));
    dispatch(downloadJaw2023SpksAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setJaw2023SpksTappedId(id));
    dispatch(downloadDeleteJaw2023SpkAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onDownloadPressed: () => {
    dispatch(saveJaw2023SpksAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Jaw2023Spk);
