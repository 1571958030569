import { connect } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_CAR_SERVICE_RESERVATIONS, PAGE_MODE_TABLE,
  MENUID_CAR_SERVICE_RESERVATION, DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFORM_CAR_SERVICE_RESERVATION, REGEX_MATCH_STRING_WITH_SPACES,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_CAR_SERVICE_RESERVATION_REPAIR_SELECTION,
  REPAIR_TYPE_BODY_AND_PAINT, RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD,
  DELIVERY_METHOD_PICKUP, DELIVERY_METHOD_DELIVERY, DELIVERY_METHOD_PICKUP_DELIVERY,
} from '../../constant';
import {
  getDeliveryMethod,
  getPaymentMethod,
  getPaymentStatus,
  getRepairType,
  getServiceStatus,
  transformInitialValues,
  transformUserDropdownData,
} from '../../helper';
import {
  clearCarServiceReservations, setActiveSideMenuItem, setAlertErrorMessage,
  setCarServiceReservationAdvancedFilterDialogSelectedFilterString,
  setCarServiceReservationSearchText,
  setCarServiceReservationSelectedOrderBy, setCarServiceReservationSelectedPageSize,
  setCarServiceReservationTappedId,
  downloadCarServiceReservationAsync, downloadCarServiceReservationsAsync, setUserSearchText,
  clearUsers,
  setDealerSearchText, clearDealers, setDealerAdvancedFilterDialogSelectedFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, downloadDealersAsync,
  saveCarServiceReservationAsync, downloadCarServiceReservationDealerDetailAsync,
  downloadCarServiceReservationSummaryAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import CarServiceReservationPage from './reservation.presentation';
import {
  debounceSearch, toCurrency, toMoment, transformDropdownData, transformUserAddress,
} from '../../../../helper';

const getInitialValues = (state) => {
  const {
    carServiceReservations, uiFunctionalPage, uiCarServiceReservation,
  } = state;
  const { data } = carServiceReservations;
  const { downloadingDeleting, tappedId } = uiCarServiceReservation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      address: transformUserAddress(found.user),
      customerName: found.user?.fullName || '',
      vin: found.vehicle?.vin || '',
      repairType: getRepairType(found.repairType || ''),
      paymentMethod: getPaymentMethod(found.payment?.paymentMethod || ''),
      paymentStatus: getPaymentStatus(found.payment?.paymentStatus || ''),
      deliveryMethod: getDeliveryMethod(found?.deliveryMethod || ''),
      serviceStatus: getServiceStatus(found?.serviceStatus || ''),
      pickupLocation: found?.deliveryMethod === DELIVERY_METHOD_DELIVERY ? '' : (found?.customerAddress || '-'),
      deliveryLocation: found?.deliveryMethod === DELIVERY_METHOD_PICKUP ? '' : (found?.customerAddress || '-'),
      paymentTotal: `Rp${toCurrency(found.payment?.total || 0)}`,
      odometer: toCurrency(found?.odometer || 0),
      serviceDate: found?.serviceDate ? toMoment(found?.serviceDate, found.dealer.timeZone)
        .format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      serviceFinishTime: found?.serviceFinishTime
        ? toMoment(found?.serviceFinishTime, found.dealer.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      arrivalTime: found?.arrivalTime
        ? toMoment(found?.arrivalTime, found.dealer.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      paymentDate: found.payment?.createdDate
        ? toMoment(found.payment?.createdDate, found.dealer.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      image01: found?.image01 ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found?.image01}` : '',
      image02: found?.image02 ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found?.image02}` : '',
      image03: found?.image03 ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found?.image03}` : '',
      image04: found?.image04 ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found?.image04}` : '',
      image05: found?.image05 ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found?.image05}` : '',
      reservationNotes: found?.repairType === REPAIR_TYPE_BODY_AND_PAINT
        ? found.bodyAndPaintNotes : found.reservationNotes,
      vaNumber: found?.payment?.vaNumber ? found?.payment?.vaNumber : '',
    }) : {};
  return result;
};

const selector = formValueSelector(RXFORM_CAR_SERVICE_RESERVATION);

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  dealers: transformDropdownData(state.dealers.data),
  downloading: state.uiCarServiceReservation.downloading,
  addingEditing: state.uiCarServiceReservation.addingEditing,
  downloadingDeleting: state.uiCarServiceReservation.downloadingDeleting,
  loadingUser: state.uiUser.downloading,
  loadingDealer: state.uiDealer.downloading,
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiCarServiceReservation.tappedId,
  isBodyAndPaint: selector(
    state, RXFIELD_CAR_SERVICE_RESERVATION_REPAIR_SELECTION,
  ) === REPAIR_TYPE_BODY_AND_PAINT,
  isPickup: selector(
    state, RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD,
  ) === DELIVERY_METHOD_PICKUP,
  isDelivery: selector(
    state, RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD,
  ) === DELIVERY_METHOD_DELIVERY,
  isPickupDelivery: selector(
    state, RXFIELD_CAR_SERVICE_RESERVATION_DELIVERY_METHOD,
  ) === DELIVERY_METHOD_PICKUP_DELIVERY,
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchDealerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setDealerSearchText(''));
    dispatch(clearDealers());
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDealersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_SERVICE_RESERVATION));
    dispatch(setCarServiceReservationSearchText(''));
    dispatch(setCarServiceReservationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCarServiceReservations());
    dispatch(setCarServiceReservationSelectedPageSize(20));
    dispatch(setCarServiceReservationSelectedOrderBy(INITIAL_ORDER_BY_CAR_SERVICE_RESERVATIONS));
    dispatch(downloadCarServiceReservationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id')
      .replace(/name/, 'dealer.id')
      .replace(/vin/, 'vehicle.vin')
      .replace(/paymentMethod/, 'payment.paymentMethod')
      .replace(/paymentStatus/, 'payment.paymentStatus');
    dispatch(setCarServiceReservationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCarServiceReservations());
    dispatch(downloadCarServiceReservationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CAR_SERVICE_RESERVATION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCarServiceReservationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCarServiceReservationSelectedPageSize(pageSize));
    dispatch(downloadCarServiceReservationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: (text) => {
    if (text.match(REGEX_MATCH_STRING_WITH_SPACES) || !text) {
      dispatch(setUserSearchText(text));
      if (text.length >= 3 || text.length === 0) {
        searchUserDebounce(dispatch, true);
      }
    }
  },
  onChangeDealerText: (text) => {
    if (text.match(REGEX_MATCH_STRING_WITH_SPACES) || !text) {
      dispatch(setDealerSearchText(text));
      if (text.length >= 3 || text.length === 0) {
        searchDealerDebounce(dispatch, true);
      }
    }
  },
  onDownloadPressed: () => {
    dispatch(saveCarServiceReservationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setCarServiceReservationSelectedPageSize(pageSize));
    dispatch(clearCarServiceReservations());
    dispatch(downloadCarServiceReservationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCarServiceReservations());
    dispatch(setCarServiceReservationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCarServiceReservationSelectedOrderBy(INITIAL_ORDER_BY_CAR_SERVICE_RESERVATIONS));
    dispatch(downloadCarServiceReservationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCarServiceReservationSearchText(text));
      dispatch(clearCarServiceReservations());
      await dispatch(downloadCarServiceReservationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCarServiceReservationSelectedOrderBy(orderBy));
    dispatch(clearCarServiceReservations());
    dispatch(downloadCarServiceReservationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCarServiceReservationTappedId(id));
    dispatch(downloadCarServiceReservationAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadCarServiceReservationDealerDetailAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadCarServiceReservationSummaryAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarServiceReservationPage);
