import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_COMPLETED_CHALLENGES, MENUID_GAMEQR_COMPLETED_CHALLENGE, PAGE_MODE_TABLE,
  RXFORM_COMPLETED_CHALLENGE, DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData, sortAsc, toMoment,
} from '../../helper';
import {
  clearCompletedChallenges, setActiveSideMenuItem,
  setAlertErrorMessage,
  setCompletedChallengeAdvancedFilterDialogSelectedFilterString, setCompletedChallengeSearchText,
  setCompletedChallengeSelectedOrderBy, setCompletedChallengeSelectedPageSize,
  downloadCompletedChallengesAsync,
  setChallengeSearchText, clearChallenges, downloadChallengesAsync,
  setChallengeAdvancedFilterDialogSelectedFilterString,
  downloadCompletedChallengeAsync, setCompletedChallengeTappedId,
} from '../../redux/action';
import {
  downloadUsersAsync, clearUsers, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
} from '../../../../redux/action';
import CompletedChallengePage from './completed-challenge.presentation';

const getInitialValues = (state) => {
  const {
    completedChallenges, uiFunctionalPage, uiCompletedChallenge,
  } = state;
  const { data } = completedChallenges;
  const { downloadingDeleting, tappedId, downloading } = uiCompletedChallenge;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      fullName: found.user ? found.user?.fullName : '',
      scannedCodes: found.scannedCodes.length > 0 && !downloading
        ? found.scannedCodes.sort((a, b) => sortAsc(a.createdDate, b.createdDate))
          .map((x, i) => ({
            ...x,
            no: i + 1,
            createdDate: toMoment(x.createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
          })) : [],
    }) : {
      fullName: '',
      scannedCodes: [],
    };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiCompletedChallenge.downloading,
  downloadingDeleting: state.uiCompletedChallenge.downloadingDeleting,
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiCompletedChallenge.tappedId,
  totalCurrentPage: state.completedChallenges.meta.currentPage,

  loadingChallenge: state.uiChallenge.downloading,
  challenges: Object.values(state.challenges.data).map((item) => ({
    label: item.title,
    value: item.id,
  })),

  loadingUsers: state.uiUser.downloading,
  users: transformUserDropdownData(state.users.data),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setChallengeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setChallengeSearchText(''));
    dispatch(clearChallenges());
    dispatch(downloadChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GAMEQR_COMPLETED_CHALLENGE));
    dispatch(setCompletedChallengeSearchText(''));
    dispatch(setCompletedChallengeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCompletedChallenges());
    dispatch(setCompletedChallengeSelectedPageSize(20));
    dispatch(setCompletedChallengeSelectedOrderBy(INITIAL_ORDER_BY_COMPLETED_CHALLENGES));
    dispatch(downloadCompletedChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/challenge=/, 'challenge.id=')
      .replace(/status=/, 'completedRewardType=')
      .replace(/user/, 'user.id');
    dispatch(setCompletedChallengeAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCompletedChallenges());
    dispatch(downloadCompletedChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COMPLETED_CHALLENGE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCompletedChallengesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCompletedChallengeSelectedPageSize(pageSize));
    dispatch(downloadCompletedChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeChallengeText: async (text) => {
    try {
      dispatch(setChallengeSearchText(text));
      dispatch(clearChallenges());
      await dispatch(downloadChallengesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setCompletedChallengeSelectedPageSize(pageSize));
    dispatch(clearCompletedChallenges());
    dispatch(downloadCompletedChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCompletedChallenges());
    dispatch(setCompletedChallengeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCompletedChallengeSelectedOrderBy(INITIAL_ORDER_BY_COMPLETED_CHALLENGES));
    dispatch(downloadCompletedChallengesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCompletedChallengeSearchText(text));
      dispatch(clearCompletedChallenges());
      await dispatch(downloadCompletedChallengesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCompletedChallengeSelectedOrderBy(orderBy));
    dispatch(clearCompletedChallenges());
    dispatch(downloadCompletedChallengesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCompletedChallengeTappedId(id));
    dispatch(downloadCompletedChallengeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompletedChallengePage);
