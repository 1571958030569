import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  INITIAL_ORDER_BY_GIIAS_MEDAN_PRIZE_REDEMPTION,
  MENUID_GIIAS_MEDAN_PRIZE_REDEMPTION,
  PAGE_MODE_TABLE,
} from '../../constant';
import {
  clearUsers, downloadUsersAsync, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../../../redux/action';
import GiiasMedanPrizeRedemptionPage from './prize-redemption.presentation';
import {
  clearVouchers, setVoucherAdvancedFilterDialogSelectedFilterString, setVoucherSearchText,
  downloadVouchersAsync,
} from '../../../voucher';
import {
  clearGiiasMedanPrizeRedemptions, setGiiasMedanPrizeRedemptionAdvancedFilterString,
  setGiiasMedanPrizeRedemptionSearchText, setGiiasMedanPrizeRedemptionSelectedOrderBy,
  setGiiasMedanPrizeRedemptionSelectedPageSize, setGiiasMedanPrizeRedemptionTappedId,
} from '../../redux/action/simple-action';
import {
  downloadGiiasMedanPrizeRedemptionAsync, downloadGiiasMedanPrizeRedemptionsAsync,
  saveGiiasMedanPrizeRedemptionsAsync,
} from '../../redux/action';


const getInitialValues = (state) => {
  const { giiasMedanPrizeRedemptions, uiFunctionalPage, uiGiiasMedanPrizeRedemption } = state;
  const { data } = giiasMedanPrizeRedemptions;
  const { downloadingDeleting, tappedId } = uiGiiasMedanPrizeRedemption;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.user.id,
    fullName: found.user.fullName,
  }) : {};
  return result;
};


const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiGiiasMedanPrizeRedemption.downloading,
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingVoucher: state.uiVoucher.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GIIAS_MEDAN_PRIZE_REDEMPTION));
    dispatch(setGiiasMedanPrizeRedemptionAdvancedFilterString(''));
    dispatch(setGiiasMedanPrizeRedemptionSearchText(''));
    dispatch(clearGiiasMedanPrizeRedemptions());
    dispatch(setGiiasMedanPrizeRedemptionSelectedPageSize(20));
    dispatch(
      setGiiasMedanPrizeRedemptionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_MEDAN_PRIZE_REDEMPTION),
    );
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setGiiasMedanPrizeRedemptionSelectedOrderBy(orderBy));
    dispatch(clearGiiasMedanPrizeRedemptions());
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setGiiasMedanPrizeRedemptionTappedId(id));
    dispatch(downloadGiiasMedanPrizeRedemptionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setGiiasMedanPrizeRedemptionSearchText(text));
    dispatch(clearGiiasMedanPrizeRedemptions());
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGiiasMedanPrizeRedemptionSelectedPageSize(pageSize));
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setGiiasMedanPrizeRedemptionSelectedPageSize(pageSize));
    dispatch(clearGiiasMedanPrizeRedemptions());
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGiiasMedanPrizeRedemptions());
    dispatch(setGiiasMedanPrizeRedemptionAdvancedFilterString(''));
    dispatch(
      setGiiasMedanPrizeRedemptionSelectedOrderBy(INITIAL_ORDER_BY_GIIAS_MEDAN_PRIZE_REDEMPTION),
    );
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('user', 'user.id')
      .replace('voucher', 'voucherId');

    dispatch(setGiiasMedanPrizeRedemptionAdvancedFilterString(text));
    dispatch(clearGiiasMedanPrizeRedemptions());
    dispatch(downloadGiiasMedanPrizeRedemptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveGiiasMedanPrizeRedemptionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GiiasMedanPrizeRedemptionPage);
