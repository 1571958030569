import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    carViewingMenu: {
      title: 'Car Viewing',
    },
    carViewingEventPage: {
      title: 'Event',

      labelNo: 'No',
      labelName: 'Name',
      labelLocation: 'Location',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelOpenTime: 'Open Time',
      labelCloseTime: 'Close Time',
      labelCity: 'City',
      labelVisibility: 'Visibility',

      buttonCaptionCreateNewCarViewingEvent: 'Create New Event',
      buttonCaptionEditCarViewingEvent: 'Edit Event',
      buttonCaptionDeleteCarViewingEvent: 'Delete Event',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderLocation: 'Location',
      placeholderCity: 'City',
      placeholderVoucherRewards: 'Voucher Rewards',
      placeholderImage: 'Image',
      placeholderStartDate: 'Start Date',
      placeholderEndDate: 'End Date',
      placeholderOpenTime: 'Open Time',
      placeholderEndTime: 'Close Time',
      placeholderVisibility: 'Visibility',
      placeholderQuotaPerSlot: 'Quota Per Slot',

      errMsgOpenCloseTime: 'Close Time must be greater Open Time',
      errMsgStartEndDate: 'End Date must be greater than or equal to Start Date',
    },
    carViewingReservationPage: {
      title: 'Reservation',

      labelNo: 'No',
      labelAdditionalVisitor: 'Additional Visitor',
      labelAdditionalVisitorFullName: 'Additional Visitor Full Name',
      labelAdditionalVisitorPhoneNumber: 'Additional Visitor Phone Number',
      labelEvent: 'Event',
      labelEventId: 'Event ID',
      labelEventName: 'Event Name',
      labelReservationCode: 'Reservation Code',
      labelReservedDate: 'Reserved Date',
      labelReservedTime: 'Reserved Time',
      labelVisitorFirstName: 'Visitor First Name',
      labelVisitorLastName: 'Visitor Last Name',
      labelVisitorEmail: 'Visitor Email',
      labelVisitorGender: 'Visitor Gender',
      labelVisitorPhoneNumber: 'Visitor Phone Number',
      labelUser: 'User',

      placeholderAdditionalVisitor: 'Additional Visitor',
      placeholderAdditionalVisitorFullName: 'Additional Visitor Full Name',
      placeholderAdditionalVisitorPhoneNumber: 'Additional Visitor Phone Number',
      placeholderEvent: 'Event',
      placeholderEventId: 'Event ID',
      placeholderEventName: 'Event Name',
      placeholderReservationCode: 'Reservation Code',
      placeholderReservedDate: 'Reserved Date',
      placeholderReservedTime: 'Reserved Time',
      placeholderVisitorFirstName: 'Visitor First Name',
      placeholderVisitorLastName: 'Visitor Last Name',
      placeholderVisitorEmail: 'Visitor Email',
      placeholderVisitorGender: 'Visitor Gender',
      placeholderVisitorPhoneNumber: 'Visitor Phone Number',
      placeholderUserId: 'User ID',
      placeholderUserFullName: 'User Full Name',
    },
    carViewingVoucherRewardPage: {
      title: 'Voucher Reward',

      labelNo: 'No',
      labelCreatedDate: 'Created Date',
      labelEvent: 'Event',
      labelEventName: 'Event Name',
      labelEventId: 'Event ID',
      labelNote: 'Note',
      labelUser: 'User',
      labelUserFullname: 'User Full Name',
      labelUserId: 'User ID',
      labelVoucher: 'Voucher',
      labelVoucherName: 'Voucher Name',
      labelVoucherId: 'Voucher ID',

      placeholderEventName: 'Event Name',
      placeholderEventId: 'Event ID',
      placeholderNote: 'Note',
      placeholderUserFullname: 'User Full Name',
      placeholderUserId: 'User ID',
      placeholderVoucherName: 'Voucher Name',
      placeholderVoucherId: 'Voucher ID',
    },
  },
  id: {
    carViewingMenu: {
      title: 'Car Viewing',
    },
    carViewingEventPage: {
      title: 'Event',

      labelNo: 'No',
      labelName: 'Nama',
      labelLocation: 'Lokasi',
      labelStartDate: 'Tanggal Mulai',
      labelEndDate: 'Tanggal Selesai',
      labelOpenTime: 'Jam Buka',
      labelCloseTime: 'Jam tutup',
      labelCity: 'Kota',
      labelVisibility: 'Visibilitas',

      buttonCaptionCreateNewCarViewingEvent: 'Buat Event Baru',
      buttonCaptionEditCarViewingEvent: 'Ubah Event',
      buttonCaptionDeleteCarViewingEvent: 'Hapus Event',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderLocation: 'Lokasi',
      placeholderCity: 'Kota',
      placeholderVoucherRewards: 'Voucher Rewards',
      placeholderImage: 'Gambar',
      placeholderStartDate: 'Tanggal Mulai',
      placeholderEndDate: 'Tanggal Selesai',
      placeholderOpenTime: 'Jam Buka',
      placeholderEndTime: 'Jam Tutup',
      placeholderVisibility: 'Visibilitas',
      placeholderQuotaPerSlot: 'Kuota Per Slot',

      errMsgOpenCloseTime: 'Jam Tutup harus lebih besar dari Jam Buka',
      errMsgStartEndDate: 'Tanggal selesai harus lebih besar atau sama dengan Tanggal Mulai',
    },
    carViewingReservationPage: {
      title: 'Reservasi',

      labelNo: 'No',
      labelAdditionalVisitor: 'Pengunjung Tambahan',
      labelAdditionalVisitorFullName: 'Nama Lengkap Pengunjung Tambahan',
      labelAdditionalVisitorPhoneNumber: 'Nomor Telepon Pengunjung Tambahan',
      labelEvent: 'Acara',
      labelEventId: 'ID Acara',
      labelEventName: 'Nama Acara',
      labelReservationCode: 'Kode Reservasi',
      labelReservedDate: 'Tanggal Reservasi',
      labelReservedTime: 'Waktu Reservasi',
      labelVisitorFirstName: 'Nama Depan Pengunjung',
      labelVisitorLastName: 'Nama Belakang Pengunjung',
      labelVisitorEmail: 'Email Pengunjung',
      labelVisitorGender: 'Jenis Kelamin Pengunjung',
      labelVisitorPhoneNumber: 'Nomor Telepon Pengunjung',
      labelUser: 'Pengguna',

      placeholderAdditionalVisitor: 'Pengunjung Tambahan ',
      placeholderAdditionalVisitorFullName: 'Nama Lengkap Pengunjung Tambahan',
      placeholderAdditionalVisitorPhoneNumber: 'Nomor Telepon Pengunjung Tambahan ',
      placeholderEvent: 'Acara',
      placeholderEventId: 'ID Acara',
      placeholderEventName: 'Nama Acara',
      placeholderReservationCode: 'Kode Reservasi',
      placeholderReservedDate: 'Tanggal Reservasi',
      placeholderReservedTime: 'Waktu Reservasi',
      placeholderVisitorFirstName: 'Nama Depan Pengunjung',
      placeholderVisitorLastName: 'Nama Belakang Pengunjung',
      placeholderVisitorEmail: 'Email Pengunjung',
      placeholderVisitorGender: 'Jenis Kelamin Pengunjung',
      placeholderVisitorPhoneNumber: 'Nomor Telepon Pengunjung',
      placeholderUserId: 'ID Pengguna',
      placeholderUserFullName: 'Nama Lengkap Pengguna',
    },
    carViewingVoucherRewardPage: {
      title: 'Hadiah Voucher',

      labelNo: 'No',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelEvent: 'Acara',
      labelEventName: 'Nama Acara',
      labelEventId: 'ID Acara',
      labelNote: 'Catatan',
      labelUser: 'Pengguna',
      labelUserFullname: 'Nama Lengkap Pengguna',
      labelUserId: 'ID Pengguna',
      labelVoucher: 'Voucher',
      labelVoucherName: 'Nama Voucher',
      labelVoucherId: 'ID Voucher',

      placeholderEventName: 'Nama Acara',
      placeholderEventId: 'ID Acara',
      placeholderNote: 'Catatan',
      placeholderUserFullname: 'Nama Lengkap Pengguna',
      placeholderUserId: 'ID Pengguna',
      placeholderVoucherName: 'Nama Voucher',
      placeholderVoucherId: 'ID Voucher',
    },
  },
});

export default LocalizedString;
