import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS, INITIAL_ORDER_BY_TEST_DRIVE_EO_TEST_VEHICLES,
  MENUID_TEST_DRIVE_EO_TEST_VEHICLE, PAGE_MODE_TABLE,
} from '../../constant';
import { transformDropdownData, transformInitialValues } from '../../helper';
import {
  clearTestDriveEoTestVehicles, clearTestDriveEoEvents, setActiveSideMenuItem, setAlertErrorMessage,
  setTestDriveEoEventAdvancedFilterDialogSelectedFilterString, setTestDriveEoEventSearchText,
  setTestDriveEoTestVehicleAdvancedFilterDialogSelectedFilterString,
  setTestDriveEoTestVehicleSearchText, setTestDriveEoTestVehicleSelectedOrderBy,
  setTestDriveEoTestVehicleSelectedPageSize, setTestDriveEoTestVehicleTappedId,
  downloadTestDriveEoEventsAsync, downloadTestDriveEoTestVehiclesAsync,
  downloadTestDriveEoTestVehicleAsync, editTestDriveEoTestVehicleAsync,
} from '../../redux/action';
import {
  clearModels, setModelAdvancedFilterDialogSelectedFilterString, setModelSearchText,
  downloadModelsAsync,
} from '../../../car-catalog/redux/action';
import TestVehiclePage from './test-vehicle.presentation';

const getInitialValues = (state) => {
  const {
    testDriveEoTestVehicles, uiFunctionalPage, uiTestDriveEoTestVehicle,
  } = state;
  const { data } = testDriveEoTestVehicles;
  const { downloadingDeleting, tappedId } = uiTestDriveEoTestVehicle;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  events: transformDropdownData(state.testDriveEoEvents.data),
  carModels: transformDropdownData(state.models.data),
  addingEditing: state.uiTestDriveEoTestVehicle.addingEditing,
  downloadingDeleting: state.uiTestDriveEoTestVehicle.downloadingDeleting,
  downloading: state.uiTestDriveEoTestVehicle.downloading,
  loadingCarModels: state.uiModel.downloading,
  loadingEvent: state.uiTestDriveEoEvent.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setTestDriveEoEventSearchText(''));
    dispatch(setModelSearchText(''));
    dispatch(clearTestDriveEoEvents());
    dispatch(clearModels());
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadModelsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('event', 'event.id');
    dispatch(setTestDriveEoTestVehicleAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearTestDriveEoTestVehicles());
    dispatch(downloadTestDriveEoTestVehiclesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_TEST_DRIVE_EO_TEST_VEHICLE));
    dispatch(setTestDriveEoTestVehicleSearchText(''));
    dispatch(setTestDriveEoTestVehicleAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearTestDriveEoTestVehicles());
    dispatch(setTestDriveEoTestVehicleSelectedPageSize(20));
    dispatch(setTestDriveEoTestVehicleSelectedOrderBy(
      INITIAL_ORDER_BY_TEST_DRIVE_EO_TEST_VEHICLES,
    ));
    dispatch(downloadTestDriveEoTestVehiclesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeCarModelText: async (text) => {
    try {
      dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setModelSearchText(text));
      dispatch(clearModels());
      await dispatch(downloadModelsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setTestDriveEoEventSearchText(text));
      dispatch(clearTestDriveEoEvents());
      await dispatch(downloadTestDriveEoEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestDriveEoTestVehiclesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestDriveEoTestVehicleSelectedPageSize(pageSize));
    dispatch(downloadTestDriveEoTestVehiclesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEditPressed: (id) => {
    dispatch(setTestDriveEoTestVehicleTappedId(id));
    dispatch(downloadTestDriveEoTestVehicleAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setTestDriveEoEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setTestDriveEoEventSearchText(''));
    dispatch(setModelSearchText(''));
    dispatch(clearTestDriveEoEvents());
    dispatch(clearModels());
    dispatch(downloadTestDriveEoEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadModelsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setTestDriveEoTestVehicleSelectedPageSize(pageSize));
    dispatch(clearTestDriveEoTestVehicles());
    dispatch(downloadTestDriveEoTestVehiclesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestDriveEoTestVehicles());
    dispatch(setTestDriveEoTestVehicleAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setTestDriveEoTestVehicleSelectedOrderBy(INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS));
    dispatch(downloadTestDriveEoTestVehiclesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSavePressed: async ({ dailyQuota }) => {
    try {
      await dispatch(editTestDriveEoTestVehicleAsync(dailyQuota));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setTestDriveEoTestVehicleSearchText(text));
      dispatch(clearTestDriveEoTestVehicles());
      await dispatch(downloadTestDriveEoTestVehiclesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestDriveEoTestVehicleSelectedOrderBy(orderBy));
    dispatch(clearTestDriveEoTestVehicles());
    dispatch(downloadTestDriveEoTestVehiclesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setTestDriveEoTestVehicleTappedId(id));
    dispatch(downloadTestDriveEoTestVehicleAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewTestDrive: (id) => {
    window.location.href = `#/test-drive-eo-reservation?modelName=${id}`;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TestVehiclePage);
