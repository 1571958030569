import { downloadingVoucherTokenAssignments, setVoucherTokenAssignments } from '../simple-action';
import { downloadVoucherTokenAssignments, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, voucherTokenAssignments, uiVoucherTokenAssignment, uiFunctionalPage,
  } = getState();
  const { filterString } = uiFunctionalPage;
  const { token } = authentication;
  const { meta } = voucherTokenAssignments;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiVoucherTokenAssignment;

  try {
    dispatch(downloadingVoucherTokenAssignments(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const result = await downloadVoucherTokenAssignments(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );
    dispatch(setVoucherTokenAssignments(result));
  } finally {
    dispatch(downloadingVoucherTokenAssignments(false));
  }
};
