import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_PROMO_CODES = '/integrator/api/v1/PromoCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_PROMO_CODE = '/integrator/api/v1/PromoCode';
export const REST_URL_VIEW_DELETE_PROMO_CODE = '/integrator/api/v1/PromoCode/{id}';
export const REST_URL_REDEMPTION_HISTORIES = '/integrator/api/v1/PromoCodeRedemption?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_REDEMPTION_HISTORY = '/integrator/api/v1/PromoCodeRedemption/{id}';
export const REST_URL_PROMO_CODE_BATCHES = '/integrator/api/v1/PromoCodeBatch?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_PROMO_CODE_BATCH = '/integrator/api/v1/PromoCodeBatch';
export const REST_URL_VIEW_DELETE_PROMO_CODE_BATCH = '/integrator/api/v1/PromoCodeBatch/{id}';
export const REST_URL_CANCEL_PROMO_CODE_BATCH = '/integrator/api/v1/PromoCodeBatchCancel/{id}';

export const ROUTE_NAME_PROMO_CODE = '/promo';
export const ROUTE_NAME_PROMO_CODE_PROMO_CODE = '/promo-code';
export const ROUTE_NAME_PROMO_CODE_REDEMPTION_HISTORY = '/redemption-history';
export const ROUTE_NAME_PROMO_CODE_PROMO_CODE_BATCH = '/promo-code-batch';

export const RXFORM_PROMO_CODE = 'promoCodePage';
export const RXFORM_REDEMPTION_HISTORY = 'redemptionHistoryPage';
export const RXFORM_PROMO_CODE_BATCH = 'promoCodeBatchPage';

export const RXFIELD_PROMO_CODE_REWARD = 'rewardType';
export const RXFIELD_PROMO_CODE_CODE = 'code';
export const RXFIELD_PROMO_CODE_VOUCHER = 'vouchers';
export const RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE = 'bypassMaxPurchaseValidation';
export const RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION = 'bypassAchievementValidation';
export const RXFIELD_PROMO_CODE_TOTAL_VOUCHER_ALLOCATED = 'maxRedemptionCount';
export const RXFIELD_PROMO_CODE_USER_TYPE = 'userType';
export const RXFIELD_PROMO_CODE_MAX_SIGN_UP_DATE = 'maxSignUpTime';
export const RXFIELD_PROMO_CODE_MIN_SIGN_UP_DATE = 'minSignUpTime';
export const RXFIELD_PROMO_CODE_COINS_AMOUNT = 'pointAmount';
export const RXFIELD_PROMO_CODE_COUPONS_AMOUNT = 'couponAmount';
export const RXFIELD_PROMO_CODE_VALID_FROM = 'validFrom';
export const RXFIELD_PROMO_CODE_VALID_UNTIL = 'validUntil';
export const RXFIELD_PROMO_CODE_CREATED_DATE = 'createdDate';
export const RXFIELD_PROMO_CODE_MERCHANT = 'merchant';
export const RXFIELD_PROMO_CODE_MERCHANT_TAG = 'merchantTag';
export const RXFIELD_PROMO_CODE_MESSAGE_TITLE = 'notificationMessageTitle';
export const RXFIELD_PROMO_CODE_MESSAGE_BODY = 'notificationMessageBody';
export const RXFIELD_REDEMPTION_HISTORY_PROMO_CODE = 'promoCode';
export const RXFIELD_REDEMPTION_HISTORY_REWARD = 'rewardType';
export const RXFIELD_REDEMPTION_HISTORY_VOUCHER = 'voucherRedemptions';
export const RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE = 'bypassMaxPurchaseValidation';
export const RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION = 'bypassAchievementValidation';
export const RXFIELD_REDEMPTION_HISTORY_TOTAL_VOUCHER_ALLOCATED = 'maxRedemptionCount';
export const RXFIELD_REDEMPTION_HISTORY_COINS_AMOUNT = 'pointAmount';
export const RXFIELD_REDEMPTION_HISTORY_TOTAL_COIN_ALLOCATED = 'maxRedemptionCount';
export const RXFIELD_REDEMPTION_HISTORY_COUPONS_AMOUNT = 'couponAmount';
export const RXFIELD_REDEMPTION_HISTORY_TOTAL_COUPON_ALLOCATED = 'maxRedemptionCount';
export const RXFIELD_REDEMPTION_HISTORY_VALID_FROM = 'validFrom';
export const RXFIELD_REDEMPTION_HISTORY_VALID_UNTIL = 'validUntil';
export const RXFIELD_REDEMPTION_HISTORY_MERCHANT = 'merchant';
export const RXFIELD_REDEMPTION_HISTORY_MERCHANT_TAG = 'merchantTag';
export const RXFIELD_REDEMPTION_HISTORY_MESSAGE_TITLE = 'notificationMessageTitle';
export const RXFIELD_REDEMPTION_HISTORY_MESSAGE_BODY = 'notificationMessageBody';
export const RXFIELD_REDEMPTION_HISTORY_USER = 'user';
export const RXFIELD_REDEMPTION_HISTORY_USER_ID = 'userId';
export const RXFIELD_REDEMPTION_HISTORY_REDEMPTION_STATUS = 'redemptionStatus';
export const RXFIELD_REDEMPTION_HISTORY_REDEMPTION_STATUS_NOTES = 'redemptionStatusNote';
export const RXFIELD_PROMO_CODE_BATCH_REWARD = 'rewardType';
export const RXFIELD_PROMO_CODE_BATCH_VOUCHERS = 'vouchers';
export const RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE = 'bypassMaxPurchaseValidation';
export const RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION = 'bypassAchievementValidation';
export const RXFIELD_PROMO_CODE_BATCH_MAX_REDEMPTION_COUNT = 'maxRedemptionCount';
export const RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT = 'pointAmount';
export const RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT = 'couponAmount';
export const RXFIELD_PROMO_CODE_BATCH_VALID_FROM = 'validFrom';
export const RXFIELD_PROMO_CODE_BATCH_VALID_UNTIL = 'validUntil';
export const RXFIELD_PROMO_CODE_BATCH_MERCHANT = 'merchant';
export const RXFIELD_PROMO_CODE_BATCH_MERCHANT_TAG = 'merchantTag';
export const RXFIELD_PROMO_CODE_BATCH_MESSAGE_TITLE = 'notificationMessageTitle';
export const RXFIELD_PROMO_CODE_BATCH_MESSAGE_BODY = 'notificationMessageBody';
export const RXFIELD_PROMO_CODE_BATCH_PREFIX = 'prefix';
export const RXFIELD_PROMO_CODE_BATCH_QUANTITY = 'quantity';
export const RXFIELD_PROMO_CODE_BATCH_RANDOM_CODE_LENGTH = 'randomCodeLength';
export const RXFIELD_PROMO_CODE_BATCH_TASK_STATUS = 'taskStatus';
export const RXFIELD_PROMO_CODE_BATCH_TASK_STATUS_NOTES = 'taskStatusNotes';
export const RXFIELD_PROMO_CODE_BATCH_PROGRESS = 'progress';
export const RXFIELD_PROMO_CODE_BATCH_PROGRESS_NOTES = 'progressNotes';
export const RXFIELD_PROMO_CODE_BATCH_START_TIME = 'startTime';
export const RXFIELD_PROMO_CODE_BATCH_END_TIME = 'endTime';
export const RXFIELD_PROMO_CODE_BATCH_USER_TYPE = 'userType';
export const RXFIELD_PROMO_CODE_BATCH_MAX_SIGN_UP_DATE = 'maxSignUpTime';
export const RXFIELD_PROMO_CODE_BATCH_MIN_SIGN_UP_DATE = 'minSignUpTime';

export const RXSTATE_PROMO_CODES = 'promoCodes';
export const RXSTATE_PROMO_CODE_PAGE = 'uiPromoCode';
export const RXSTATE_REDEMPTION_HISTORIES = 'redemptionHistories';
export const RXSTATE_REDEMPTION_HISTORY_PAGE = 'uiRedemptionHistory';
export const RXSTATE_PROMO_CODE_BATCHES = 'promoCodeBatches';
export const RXSTATE_PROMO_CODE_BATCH_PAGE = 'uiPromoCodeBatch';

export const INITIAL_ORDER_BY_PROMO_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_REDEMPTION_HISTORIES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_PROMO_CODE_BATCHES = `startTime ${ORDER_SORT_DESCENDING}`;

export const MENUID_PROMO_CODE = 'MENUID_PROMO_CODE';
export const MENUID_PROMO_CODE_PROMO_CODE = 'MENUID_PROMO_CODE_PROMO_CODE';
export const MENUID_PROMO_CODE_REDEMPTION_HISTORY = 'MENUID_PROMO_CODE_REDEMPTION_HISTORY';
export const MENUID_PROMO_CODE_PROMO_CODE_BATCH = 'MENUID_PROMO_CODE_PROMO_CODE_BATCH';

export const rewards = [
  { label: 'Voucher', value: 'Voucher' },
  { label: 'Points', value: 'Point' },
  { label: 'Coupons', value: 'Coupon' },
];

export const REWARD_TYPE_VOUCHER = 'Voucher';
export const REWARD_TYPE_COIN = 'Point';
export const REWARD_TYPE_COUPON = 'Coupon';
export const TASK_STATUS_QUEUING = 'Queuing';
export const TASK_STATUS_PROCESSING = 'Processing';

export const redemptionStatus = ['Success', 'Failed'];
export const TASK_STATUSES = [TASK_STATUS_QUEUING, TASK_STATUS_PROCESSING, 'Cancelling', 'Cancelled', 'Finished', 'Error'];

export const PROMO_CODE_USER_TYPE_OPTIONS = [
  {
    label: 'New Sign Up',
    value: 'New Sign Up',
  },
  {
    label: 'New Sign Up Blue',
    value: 'New Sign Up Blue',
  },
  {
    label: 'New Sign Up Blue+',
    value: 'New Sign Up Blue+',
  },
  {
    label: 'White',
    value: 'White',
  },
  {
    label: 'Blue',
    value: 'Blue',
  },
  {
    label: 'Blue+',
    value: 'Blue+',
  },
];
