import {
  DOWNLOADING_ALL_CONFIG_GROUPS, SET_ALL_CONFIG_GROUPS,
  SET_SELECTED_CONFIG_VALUE_FILTER,
  DOWNLOADING_CONFIG_VALUES,
  SET_CONFIG_VALUE_DISPLAY_UNSAVED_CHANGE_DIALOG,
  EDITING_CONFIG_ITEMS,
} from '../action';
import LocalizedString from '../../localization';

const initialState = {
  downloadingAllConfigGroups: false,
  downloadingConfigValues: false,
  configGroups: {},
  selectedFilter: LocalizedString.configValueScreen.labelFilterBy,
  displayUnsavedChangeDialog: false,
  editingConfigItems: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ALL_CONFIG_GROUPS:
      return { ...state, downloadingAllConfigGroups: action.status };
    case DOWNLOADING_CONFIG_VALUES:
      return { ...state, downloadingConfigValues: action.status };
    case SET_ALL_CONFIG_GROUPS:
      return { ...state, configGroups: action.data };
    case SET_SELECTED_CONFIG_VALUE_FILTER:
      return { ...state, selectedFilter: action.value };
    case SET_CONFIG_VALUE_DISPLAY_UNSAVED_CHANGE_DIALOG:
      return { ...state, displayUnsavedChangeDialog: action.view };
    case EDITING_CONFIG_ITEMS:
      return { ...state, editingConfigItems: action.status };
    default: return state;
  }
};
