import { reset } from 'redux-form';
import {
  PAGE_MODE_TABLE, RXFORM_CHALLENGE,
  DROPDOWN_MENU_ABSOLUTE, DROPDOWN_MENU_TIME_BASED,
} from '../../../constant';
import { addChallenge, editChallenge, toNumber } from '../../../helper';
import {
  addingEditingChallenge, clearChallenges, setAlertErrorMessage, setChallengeSelectedCategory,
  setChallengeSelectedScanPolicy, setChallengeSelectedVisibilityStatus, setFunctionalPageMode,
  setChallengeScanLimitTimezone,
  setChallengeSelectedVoucher,
  setChallengeSelectedCompleteReward,
  setChallengeSelectedBypassMaxPurchase,
  setChallengeSelectedBypassAchievement,
  setChallengeSelectedEnableBudget,
} from '../simple-action';
import downloadChallengeAsync from './downloadChallengesAsync';
import { REWARD_TYPE_VOUCHER } from '../../../../promo-code';

const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    id: x.id || '',
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

const transformCode = (data) => data.map((x) => ({
  id: x.id || '',
  order: x.order,
  code: x.code || '',
  points: x.points,
  label: x.label,
}));

export default (title, content, validFrom, validUntil, tags, media, codes, order,
  scanLimitMode, scanLimitAbsoluteValue, scanLimitPerHour, scanLimitPerDay,
  scanLimitPerWeek, scanLimitPerMonth,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  pointAmount,
  couponAmount,
  budget) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingChallenge(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedCategory, selectedScanPolicy, selectedVisibilityStatus,
      selectedIsPromoted, selectedScanLimitTimezone,
      selectedVoucher,
      selectedBypassMaxPurchase,
      selectedBypassAchievement,
      selectedCompleteReward,
      selectedEnableBudget,
    } = getState().uiChallenge;
    const { data } = getState().challenges;

    const found = data[tappedId];
    const categoryId = selectedCategory || found.category.id;
    const visibilityStatus = selectedVisibilityStatus || found.visibilityStatus;
    const scanPolicy = selectedScanPolicy || found.scanPolicy;
    const medias = media.length > 0 ? transformMedia(media) : [];
    const code = transformCode(codes);
    const isPromoted = selectedIsPromoted ? selectedIsPromoted.value : found.isPromoted;
    const newOrderValue = isPromoted && order ? toNumber(order) : 0;
    const newScanLimitAbsoluteValue = scanLimitAbsoluteValue
    && scanLimitMode === DROPDOWN_MENU_ABSOLUTE ? toNumber(scanLimitAbsoluteValue) : 0;
    const newScanLimitPerHour = scanLimitPerHour && scanLimitMode === DROPDOWN_MENU_TIME_BASED
      ? toNumber(scanLimitPerHour) : 0;
    const newScanLimitPerDay = scanLimitPerDay && scanLimitMode === DROPDOWN_MENU_TIME_BASED
      ? toNumber(scanLimitPerDay) : 0;
    const newScanLimitPerWeek = scanLimitPerWeek && scanLimitMode === DROPDOWN_MENU_TIME_BASED
      ? toNumber(scanLimitPerWeek) : 0;
    const newScanLimitPerMonth = scanLimitPerMonth && scanLimitMode === DROPDOWN_MENU_TIME_BASED
      ? toNumber(scanLimitPerMonth) : 0;
    const newScanLimitTimezone = scanLimitMode === DROPDOWN_MENU_TIME_BASED ? selectedScanLimitTimezone : '';
    const reward = selectedCompleteReward?.value || found.completedRewardType;
    const voucherIds = reward === REWARD_TYPE_VOUCHER ? [selectedVoucher.value] : [];
    const voucherBypassMaxPurchase = selectedBypassMaxPurchase?.value
    ?? found?.bypassMaxPurchaseValidation ?? false;
    const voucherBypassAchievementValidation = selectedBypassAchievement?.value
    ?? found?.bypassAchievementValidation ?? false;
    const enableBudget = selectedEnableBudget ? selectedEnableBudget?.value
      : found?.enableBudget;
    const budgetValue = toNumber(budget);

    if (tappedId) {
      await editChallenge(tappedId, title, content, validFrom, validUntil, categoryId,
        visibilityStatus, scanPolicy, tags, medias, code,
        isPromoted, newOrderValue, scanLimitMode, newScanLimitAbsoluteValue,
        newScanLimitPerHour, newScanLimitPerDay, newScanLimitPerWeek,
        newScanLimitPerMonth, newScanLimitTimezone, token,
        voucherIds,
        reward,
        pointAmount,
        couponAmount,
        bypassMaxPurchaseValidation?.value ?? voucherBypassMaxPurchase ?? false,
        bypassAchievementValidation?.value ?? voucherBypassAchievementValidation ?? false,
        enableBudget,
        budgetValue);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addChallenge(title, content, validFrom, validUntil, categoryId,
        visibilityStatus, scanPolicy, tags, medias, code,
        isPromoted, newOrderValue, scanLimitMode, newScanLimitAbsoluteValue,
        newScanLimitPerHour, newScanLimitPerDay, newScanLimitPerWeek,
        newScanLimitPerMonth, newScanLimitTimezone, token,
        voucherIds,
        reward,
        pointAmount,
        couponAmount,
        bypassMaxPurchaseValidation?.value ?? voucherBypassMaxPurchase ?? false,
        bypassAchievementValidation?.value ?? voucherBypassAchievementValidation ?? false,
        enableBudget,
        budgetValue);
    }

    dispatch(reset(RXFORM_CHALLENGE));
    dispatch(clearChallenges());
    dispatch(downloadChallengeAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setChallengeSelectedCategory(''));
    dispatch(setChallengeSelectedScanPolicy(''));
    dispatch(setChallengeSelectedVisibilityStatus(''));
    dispatch(setChallengeScanLimitTimezone(''));
    dispatch(setChallengeSelectedVoucher(null));
    dispatch(setChallengeSelectedCompleteReward(null));
    dispatch(setChallengeSelectedBypassMaxPurchase(null));
    dispatch(setChallengeSelectedBypassAchievement(null));
    dispatch(setChallengeSelectedEnableBudget(null));
  } finally {
    dispatch(addingEditingChallenge(false));
  }
};
