import {
  enablingDisablingUser, setAlertErrorMessage, setUserSelectedOrderBy,
  setUserSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_USERS, STATUS_DISABLED, STATUS_ENABLED } from '../../../constant';
import { enableDisableUser } from '../../../helper';
import downloadUsersAsync from './downloadUsersAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingUser(true));

    const { token } = getState().authentication;
    const { data } = getState().users;
    const { tappedId } = getState().uiUser;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableUser(tappedId, status, token);

    dispatch(setUserSelectedPageSize(20));
    dispatch(setUserSelectedOrderBy(INITIAL_ORDER_BY_USERS));
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(enablingDisablingUser(false));
  }
};
