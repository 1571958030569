import {
  buildQueryParams, sendGetRequest, transformQueryResult, sendPostRequest,
  sendPutRequest, sendDeleteRequest,
} from '../../helper';
import {
  REST_URL_CAR_VIEWING_EVENTS, REST_URL_ADD_EDIT_CAR_VIEWING_EVENT,
  REST_URL_VIEW_DELETE_CAR_VIEWING_EVENT,
  REST_URL_CAR_VIEWING_RESERVATIONS,
  REST_URL_VIEW_CAR_VIEWING_RESERVATION,
  REST_URL_CAR_VIEWING_VOUCHER_REWARDS,
  REST_URL_VIEW_CAR_VIEWING_VOUCHER_REWARD,
} from './constant';

export * from '../../helper';

export const downloadCarViewingEvents = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_CAR_VIEWING_EVENTS.concat(filterString), pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addCarViewingEvent = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_CAR_VIEWING_EVENT, body, token);
};

export const editCarViewingEvent = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_CAR_VIEWING_EVENT, body, token);
};

export const downloadCarViewingEvent = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CAR_VIEWING_EVENT.replace(/\{id\}/, id), token);
  return response;
};

export const deleteCarViewingEvent = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CAR_VIEWING_EVENT.replace(/\{id\}/, id), token);
};

export const downloadCarViewingReservations = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_CAR_VIEWING_RESERVATIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCarViewingReservation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CAR_VIEWING_RESERVATION.replace(/\{id\}/, id), token);
  return response;
};

export const downloadCarViewingVoucherRewards = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_CAR_VIEWING_VOUCHER_REWARDS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCarViewingVoucherReward = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CAR_VIEWING_VOUCHER_REWARD.replace(/\{id\}/, id), token);
  return response;
};
