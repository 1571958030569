import {
  clearVoucherTokens, movingSelectedVoucherTokens, setAlertInfoMessage, setAlertErrorMessage,
} from '../simple-action';
import { moveVoucherTokens } from '../../../helper';
import downloadVoucherTokensAsync from './downloadVoucherTokensAsync';
import LocalizedString from '../../../../../localization';

export default () => async (dispatch, getState) => {
  try {
    dispatch(movingSelectedVoucherTokens(true));

    const { token } = getState().authentication;
    const { selectedRows, selectedVoucherDestination } = getState().uiVoucherToken;

    let successful = 0;

    for (let i = 0; i < selectedRows.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await moveVoucherTokens([selectedRows[i].id], selectedVoucherDestination.value, token);
      successful += 1;
    }

    if (successful < selectedRows.length) {
      dispatch(setAlertInfoMessage(LocalizedString.common.errMsgFailedActions.replace(/\{number\}/, selectedRows.length - successful).replace(/\{total\}/, selectedRows.length)));
    }

    dispatch(clearVoucherTokens());
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  } finally {
    dispatch(movingSelectedVoucherTokens(false));
  }
};
