import {
  downloadingVoucherTokenAssignment, addVoucherTokenAssignment,
  setVoucherTokenAssignmentSelectedBypassPayment, setVoucherTokenAssignmentSelectedAssignmentMode,
  setVoucherTokenAssignmentSelectedBypassAchievement,
  setVoucherTokenAssignmentSelectedBypassMaxPurchase,
  setAlertErrorMessage, setGarudaAirportSearchText, clearGarudaAirports,
  setVoucherTokenAssignmentSelectedVoucher,
  setVoucherTokenAssignmentAssignmentAirport,
} from '../simple-action';
import { switches } from '../../../constant';
import { downloadVoucherTokenAssignment, isSelectedVoucherGarudaLounge } from '../../../helper';
import downloadGarudaAirportsAsync from './downloadGarudaAirportsAsync';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { tappedId } = getState().uiVoucherTokenAssignment;

  try {
    dispatch(downloadingVoucherTokenAssignment(true));

    const result = await downloadVoucherTokenAssignment(tappedId, token);
    dispatch(addVoucherTokenAssignment(result));

    const { voucher, garudaAirport } = result;
    const {
      title, id, merchant, garudaFlag,
    } = voucher;

    const selectedVoucher = {
      label: title,
      value: id,
      garudaFlag,
      merchant,
    };

    dispatch(setVoucherTokenAssignmentSelectedVoucher(selectedVoucher));
    dispatch(setVoucherTokenAssignmentSelectedAssignmentMode(result.codeAssignmentMode));
    dispatch(setVoucherTokenAssignmentSelectedBypassPayment(result.bypassPayment
      ? switches[0] : switches[1]));
    dispatch(setVoucherTokenAssignmentSelectedBypassAchievement(result.bypassAchievementValidation
      ? switches[0] : switches[1]));
    dispatch(setVoucherTokenAssignmentSelectedBypassMaxPurchase(result.bypassMaxPurchaseValidation
      ? switches[0] : switches[1]));

    if (isSelectedVoucherGarudaLounge(selectedVoucher, getState())) {
      dispatch(setVoucherTokenAssignmentAssignmentAirport({
        label: garudaAirport.name,
        value: garudaAirport.id,
      }));
      dispatch(setGarudaAirportSearchText(''));
      dispatch(clearGarudaAirports());
      dispatch(downloadGarudaAirportsAsync(1))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    }
  } finally {
    dispatch(downloadingVoucherTokenAssignment(false));
  }
};
