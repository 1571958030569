import {
  addCouponSubmission, downloadingDeletingCouponSubmission, setFunctionalPageMode,
} from '../simple-action';
import { downloadCouponSubmission } from '../../../helper';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCouponSubmission(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCouponSubmission;

    const couponSubmission = await downloadCouponSubmission(tappedId, token);
    dispatch(addCouponSubmission(couponSubmission));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingCouponSubmission(false));
  }
};
