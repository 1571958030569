import { connect } from 'react-redux';
import {
  MENUID_CAR_REGISTRATION_REGISTRATION_ATTEMPT, PAGE_MODE_TABLE,
  INITIAL_ORDER_BY_REGISTRATION_ATTEMPTS,
} from '../../constant';
import {
  transformUserDropdownData, transformInitialValues,
} from '../../helper';
import {
  clearRegistrationAttempts, clearUsers, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setRegistrationAttemptSearchText, setRegistrationAttemptSelectedPageSize,
  setRegistrationAttemptSelectedOrderBy, setRegistrationAttemptTappedId,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadRegistrationAttemptAsync, downloadRegistrationAttemptsAsync,
  saveCarRegistrationAttemptsAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import RegistrationAttemptPage from './registration-attempt.presentation';

const getInitialValues = (state) => {
  const { registrationAttempts, uiRegistrationAttempt, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiRegistrationAttempt;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? registrationAttempts.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {}) : {};
  return initVal;
};

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  downloading: state.uiRegistrationAttempt.downloading,
  loadingUsers: state.uiUser.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_REGISTRATION_REGISTRATION_ATTEMPT));
    dispatch(setRegistrationAttemptSearchText(''));
    dispatch(clearRegistrationAttempts());
    dispatch(setRegistrationAttemptSelectedPageSize(20));
    dispatch(setRegistrationAttemptSelectedOrderBy(INITIAL_ORDER_BY_REGISTRATION_ATTEMPTS));
    dispatch(downloadRegistrationAttemptsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearRegistrationAttempts());
    dispatch(downloadRegistrationAttemptsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRegistrationAttemptsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRegistrationAttemptSelectedPageSize(pageSize));
    dispatch(downloadRegistrationAttemptsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setRegistrationAttemptSelectedPageSize(pageSize));
    dispatch(clearRegistrationAttempts());
    dispatch(downloadRegistrationAttemptsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearRegistrationAttempts());
    dispatch(setRegistrationAttemptSelectedOrderBy(INITIAL_ORDER_BY_REGISTRATION_ATTEMPTS));
    dispatch(downloadRegistrationAttemptsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setRegistrationAttemptSearchText(text));
      dispatch(clearRegistrationAttempts());
      await dispatch(downloadRegistrationAttemptsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setRegistrationAttemptSelectedOrderBy(orderBy));
    dispatch(clearRegistrationAttempts());
    dispatch(downloadRegistrationAttemptsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setRegistrationAttemptTappedId(id));
    dispatch(downloadRegistrationAttemptAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveCarRegistrationAttemptsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationAttemptPage);
