import { isNaN } from 'lodash';
import moment from 'moment';
import { rxFormValidateRequiredFields } from '../../validation';
import { toMoment } from './helper';
import {
  RXFIELD_CAR_VIEWING_EVENT_NAME, RXFIELD_CAR_VIEWING_EVENT_DESCRIPTION,
  RXFIELD_CAR_VIEWING_EVENT_LOCATION, RXFIELD_CAR_VIEWING_EVENT_CITY,
  RXFIELD_CAR_VIEWING_EVENT_VOUCHER_REWARDS, RXFIELD_CAR_VIEWING_EVENT_IMAGE,
  RXFIELD_CAR_VIEWING_EVENT_START_DATE, RXFIELD_CAR_VIEWING_EVENT_END_DATE,
  RXFIELD_CAR_VIEWING_EVENT_OPEN_TIME, RXFIELD_CAR_VIEWING_EVENT_CLOSE_TIME,
  RXFIELD_CAR_VIEWING_EVENT_VISIBILITY, RXFIELD_CAR_VIEWING_EVENT_QUOTA_PER_SLOT,
  REVERSED_ISO_DATE_FORMAT, REVERSED_ISO_FULL_DATE_TIME_FORMAT,
} from './constant';

import GlobalLocalizedString from '../../localization';
import LocalizedString from './localization';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateCarViewingEvent = (values) => {
  const requiredFields = [
    RXFIELD_CAR_VIEWING_EVENT_NAME,
    RXFIELD_CAR_VIEWING_EVENT_DESCRIPTION,
    RXFIELD_CAR_VIEWING_EVENT_LOCATION,
    RXFIELD_CAR_VIEWING_EVENT_CITY,
    RXFIELD_CAR_VIEWING_EVENT_VOUCHER_REWARDS,
    RXFIELD_CAR_VIEWING_EVENT_IMAGE,
    RXFIELD_CAR_VIEWING_EVENT_START_DATE,
    RXFIELD_CAR_VIEWING_EVENT_END_DATE,
    RXFIELD_CAR_VIEWING_EVENT_OPEN_TIME,
    RXFIELD_CAR_VIEWING_EVENT_CLOSE_TIME,
    RXFIELD_CAR_VIEWING_EVENT_VISIBILITY,
    RXFIELD_CAR_VIEWING_EVENT_QUOTA_PER_SLOT,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);

  const startDate = toMoment(values.startDate).format(REVERSED_ISO_DATE_FORMAT);
  const endDate = toMoment(values.endDate).format(REVERSED_ISO_DATE_FORMAT);

  const openTime = toMoment(`1900-01-01T${values.openTime}`).format(REVERSED_ISO_FULL_DATE_TIME_FORMAT);
  const closeTime = toMoment(`1900-01-01T${values.closeTime}`).format(REVERSED_ISO_FULL_DATE_TIME_FORMAT);

  if (moment(startDate).isAfter(moment(endDate))) {
    errors.endDate = LocalizedString.carViewingEventPage.errMsgStartEndDate;
  }

  if (moment(openTime).isAfter(moment(closeTime))) {
    errors.closeTime = LocalizedString.carViewingEventPage.errMsgOpenCloseTime;
  }

  if (isNaN(Number(values[RXFIELD_CAR_VIEWING_EVENT_QUOTA_PER_SLOT]))) {
    errors.quotaPerSlot = GlobalLocalizedString.common.errMsgMustaNumber;
  }
  return errors;
};
