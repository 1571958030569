import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_JAW_2023_SCANNED_CODES,
  REST_URL_JAW_2023_VIEW_DELETE_SCANNED_CODE,
  REST_URL_SAVE_FILE,
  REST_URL_JAW_2023_PRIZE_REDEMPTIONS,
  REST_URL_VIEW_JAW_2023_PRIZE_REDEMPTION,
  REST_URL_JAW_2023_TRANSACTIONS,
  REST_URL_VIEW_JAW_2023_TRANSACTION,
  REST_URL_JAW_2023_SURVEYS,
  REST_URL_JAW_2023_VIEW_DELETE_SURVEY,
  REST_URL_JAW_2023_VIEW_DELETE_SPK,
  REST_URL_JAW_2023_SPKS,
} from './constant';

export * from '../../helper';

export const downloadJaw2023ScannedCodes = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_JAW_2023_SCANNED_CODES
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_JAW_2023_SCANNED_CODES, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadJaw2023ScannedCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_JAW_2023_VIEW_DELETE_SCANNED_CODE
    .replace(/\{id\}/, id), token);
  return response;
};

export const saveJaw2023ScannedCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_JAW_2023_SCANNED_CODES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadJaw2023PrizeRedemptions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_JAW_2023_PRIZE_REDEMPTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadJaw2023PrizeRedemption = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_JAW_2023_PRIZE_REDEMPTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveJaw2023PrizeRedemptions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_JAW_2023_PRIZE_REDEMPTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadJaw2023Transactions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_JAW_2023_TRANSACTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadJaw2023Transaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_JAW_2023_TRANSACTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveJaw2023Transactions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_JAW_2023_TRANSACTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};


export const downloadJaw2023Surveys = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_JAW_2023_SURVEYS
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_JAW_2023_SURVEYS, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadJaw2023Survey = async (id, token) => {
  const response = await sendGetRequest(REST_URL_JAW_2023_VIEW_DELETE_SURVEY
    .replace(/\{id\}/, id), token);
  return response;
};

export const saveJaw2023Surveys = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_JAW_2023_SURVEYS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadJaw2023Spks = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_JAW_2023_SPKS
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_JAW_2023_SPKS, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};
export const downloadJaw2023Spk = async (id, token) => {
  const response = await sendGetRequest(REST_URL_JAW_2023_VIEW_DELETE_SPK
    .replace(/\{id\}/, id), token);
  return response;
};

export const saveJaw2023Spks = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_JAW_2023_SPKS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};
