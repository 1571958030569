import {
  downloadTokenImports, transformSearchText, deleteTokenImports,
} from '../../../helper';
import { downloadingTokenImports, setTokenImports } from '../simple-action';

export default (selectedPageNo, deleteFilteredData = false) => async (dispatch, getState) => {
  const {
    authentication, tokenImports, uiTokenImport, uiFunctionalPage,
  } = getState();
  const { token } = authentication;
  const { meta } = tokenImports;
  const {
    selectedPageSize, searchBarText, orderBy,
  } = uiTokenImport;
  const { filterString } = uiFunctionalPage;

  try {
    dispatch(downloadingTokenImports(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    if (deleteFilteredData) {
      await deleteTokenImports(
        pageToBeDownloaded,
        selectedPageSize,
        orderBy,
        transformSearchText(searchBarText),
        filterString,
        token,
      );
    }

    const list = await downloadTokenImports(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setTokenImports(list));
    }
  } finally {
    dispatch(downloadingTokenImports(false));
  }
};
