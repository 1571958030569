import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearBranches, clearProfiles, clearProvinces,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText,
  setNotificationSelectedCompany,
  setNotificationSelectedCity, setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText, setProfileSelectedPageSize,
  setProfileSelectedBranchId, setProfileSelectedCompany, setProfileSelectedOrderBy,
  setProfileTappedId, setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText,
  downloadBranchesAsync, downloadProfileAsync, downloadProfilesAsync, addEditProfileAsync,
  enableDisableProfileAsync, saveProfilesAsync, sendProfileNotificationAsync,
  setRoleSelectedPageSize, setRoleSelectedOrderBy, clearRoles,
  downloadRolesAsync, setProfileSelectedRole,
  setRoleSearchText, setProfileSelectedGender,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData, clearCompaniesMData, downloadMDataCompaniesAsync,
  setCityAdvancedFilterDialogSelectedFilterString,
  setCitySearchText, clearCities, downloadCitiesAsync, setProfileSelectedCity,
  downloadProvincesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_PROFILES, MENUID_PERSONALIZATION_PROFILE, PAGE_MODE_TABLE,
  RXFIELD_PROFILE_BRANCH, RXFORM_PROFILE,
  TIME_MODE_CLOCK_IN, TIME_MODE_CLOCK_OUT, INITIAL_ORDER_BY_ROLES, RXFIELD_PROFILE_ROLES,
  RXFIELD_PROFILE_GENDER, RXFIELD_PROFILE_COMPANY, RXFIELD_PROFILE_SHELL_MEMBERSHIP,
} from '../../constant';
import {
  convertClockInOutHoursToMinutes, transformDropdownData, transformInitialValues,
  sortAsc,
} from '../../helper';
import ProfilePage from './profile.presentation';

const getAddressRegion = (address) => {
  if (!address) return '';
  const { city, district, subDistrict } = address;
  if (city) {
    return `${city.name} - ${city.province.name}`;
  }
  if (district) {
    return `${district.name} - ${district.city.name} - ${district.city.province.name}`;
  }
  if (subDistrict) {
    return `${subDistrict.name} - ${subDistrict.district.name} - ${subDistrict.district.city.name} - ${subDistrict.district.city.province.name}`;
  }
  return '';
};

const getInitialValues = (state) => {
  const { profiles, uiProfile, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiProfile;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? profiles.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        manager: found.manager ? found.manager.fullName : '',
        branch: found.branch ? { label: found.branch.name, value: found.branch.id } : null,
        gender: found.gender ? { label: found.gender, value: found.gender } : null,
        workingHourTimeZone: found.workingHourTimeZone !== null ? found.workingHourTimeZone : '',
        earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
          found.earliestClockInDriftTimeMinutes, found.startWorkingHour, TIME_MODE_CLOCK_IN,
        ),
        autoClockOutAfterMinutes: convertClockInOutHoursToMinutes(
          found.autoClockOutAfterMinutes, found.endWorkingHour, TIME_MODE_CLOCK_OUT,
        ),
        homeAddressRegion: getAddressRegion(found.homeAddress),
        workAddressRegion: getAddressRegion(found.workAddress),
        company: found.userCompany
          ? { label: found.userCompany.name, value: found.userCompany.id } : null,
        roles: !isEmpty(found.roles) ? found.roles.toString() : '',
        merchantTags: found.merchantTags.length ? found.merchantTags.map((e) => e.tag)
          .sort((a, b) => sortAsc(a, b)).join('\n') : '',
      })
    : {
      gender: { label: '', value: '' },
      branch: { label: '', value: '' },
      company: { label: '', value: '' },
      roles: '',
      workingHourTimeZone: '',
      homeAddressRegion: '',
      workAddressRegion: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  branches: transformDropdownData(state.branches.data),
  companies: transformDropdownData(state.companiesMdata.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiProfile.addingEditing,
  downloading: state.uiProfile.downloading,
  enablingDisabling: state.uiProfile.enablingDisabling,
  loadingBranch: state.uiBranch.downloading,
  loadingCompanies: state.uiCompany.downloading,
  sendingNotification: state.uiProfile.sendingNotification,
  initialValues: getInitialValues(state),
  tappedId: state.uiFunctionalPage.tappedId || '',
  currentProfileStatus: state.profiles.data[state.uiFunctionalPage.tappedId]
    ? state.profiles.data[state.uiFunctionalPage.tappedId].status : '',
  roles: transformDropdownData(state.roles.data),
  downloadingRoles: state.uiRole.downloading,
  downloadingDeleting: state.uiProfile.downloadingDeleting,
  cities: transformDropdownData(state.cities.data),
  loadingCity: state.uiCity.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(setBranchSearchText(''));
    dispatch(setCitySearchText(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCity(''));
    dispatch(clearCompaniesMData());
    dispatch(clearBranches());
    dispatch(clearCities());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_PERSONALIZATION_PROFILE));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedPageSize(20));
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('|', '')
      .replace(/branch/, 'branch.id')
      .replace(/company/, 'userCompany.id')
      .replace(/city/, 'homeAddress.city.id');
    const textWithShellMembershipId = text.includes(RXFIELD_PROFILE_SHELL_MEMBERSHIP);
    let filterText;
    if (textWithShellMembershipId) {
      if (text.includes(`${RXFIELD_PROFILE_SHELL_MEMBERSHIP}=true`)) {
        filterText = text.replace(/shellMembershipId=true/, 'shellMembershipId!=$NULL$');
      } else {
        filterText = text.replace(/shellMembershipId=false/, 'shellMembershipId=$NULL$');
      }
    } else {
      filterText = text;
    }
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(filterText));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onBranchOptionSelected: (option, changeForm = true) => {
    if (option) {
      dispatch(setProfileSelectedBranchId(option.value));
      if (changeForm) { dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_BRANCH, option)); }
    } else {
      dispatch(setProfileSelectedBranchId(''));
      if (changeForm) { dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_BRANCH, '')); }
    }
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedCity(''));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PROFILE));
    dispatch(setProfileSelectedGender(''));
    dispatch(setProfileSelectedRole(''));
    dispatch(setProfileSelectedCompany(''));
  },
  onChangeBranchText: async (text) => {
    try {
      dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setBranchSearchText(text));
      dispatch(clearBranches());
      await dispatch(downloadBranchesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadProfilesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setProfileSelectedPageSize(pageSize));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCompanyOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedCompany(option.value));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_COMPANY, option));
    } else {
      dispatch(setProfileSelectedCompany(''));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_COMPANY, ''));
    }
  },
  onConfirmEnableDisablePressed: () => {
    dispatch(enableDisableProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveProfilesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEditPressed: (id) => {
    dispatch(setProfileTappedId(id));

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(downloadProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setProfileTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onOpenSendNotificationDialog: () => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setNotificationSelectedCity(''));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setProfileSelectedPageSize(pageSize));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSelectedBranchId(''));
    dispatch(setProfileSelectedCompany(''));
    dispatch(setProfileSelectedCity(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    fullName, email, phone, dateOfBirth,
  }) => {
    try {
      await dispatch(addEditProfileAsync(
        fullName, email, phone, dateOfBirth,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationPressed: async (values) => {
    await dispatch(sendProfileNotificationAsync(values));
  },
  onSortPressed: (orderBy) => {
    dispatch(setProfileSelectedOrderBy(orderBy));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setProfileTappedId(id));
    dispatch(downloadProfileAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeRoleText: async (text) => {
    try {
      dispatch(setRoleSearchText(text));
      dispatch(clearRoles());
      await dispatch(downloadRolesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRoleOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedRole(option));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_ROLES, option));
    } else {
      dispatch(setProfileSelectedRole(''));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_ROLES, ''));
    }
  },
  onCreatePressed: () => {
    dispatch(setProfileTappedId(''));

    dispatch(setProfileSelectedCompany(''));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setRoleSearchText(''));
    dispatch(setRoleSelectedPageSize(20));
    dispatch(setRoleSelectedOrderBy(INITIAL_ORDER_BY_ROLES));
    dispatch(clearRoles());
    dispatch(downloadRolesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    fullName, email, phone, dateOfBirth, password,
  }) => {
    await dispatch(addEditProfileAsync(
      fullName, email, phone, dateOfBirth, password,
    ));
  },
  onGenderOptionSelected: (option) => {
    if (option) {
      dispatch(setProfileSelectedGender(option.value));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_GENDER, option));
    } else {
      dispatch(setProfileSelectedGender(''));
      dispatch(change(RXFORM_PROFILE, RXFIELD_PROFILE_GENDER, ''));
    }
  },
  onChangeCityText: async (text) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
