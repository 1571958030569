import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_CAR_VIEWING_EVENTS = 'DOWNLOADING_CAR_VIEWING_EVENTS';
export const downloadingCarViewingEvents = makeActionCreator(DOWNLOADING_CAR_VIEWING_EVENTS, 'status');

export const SET_CAR_VIEWING_EVENTS = 'SET_CAR_VIEWING_EVENTS';
export const setCarViewingEvents = makeActionCreator(SET_CAR_VIEWING_EVENTS, 'data');

export const ADD_CAR_VIEWING_EVENT = 'ADD_CAR_VIEWING_EVENT';
export const addCarViewingEvent = makeActionCreator(ADD_CAR_VIEWING_EVENT, 'data');

export const CLEAR_CAR_VIEWING_EVENTS = 'CLEAR_CAR_VIEWING_EVENTS';
export const clearCarViewingEvents = makeActionCreator(CLEAR_CAR_VIEWING_EVENTS);

export const ADDING_EDITING_CAR_VIEWING_EVENT = 'ADDING_EDITING_CAR_VIEWING_EVENT';
export const addingEditingCarViewingEvent = makeActionCreator(ADDING_EDITING_CAR_VIEWING_EVENT, 'status');

export const DOWNLOADING_DELETING_CAR_VIEWING_EVENT = 'DOWNLOADING_DELETING_CAR_VIEWING_EVENT';
export const downloadingDeletingCarViewingEvent = makeActionCreator(DOWNLOADING_DELETING_CAR_VIEWING_EVENT, 'status');


export const SET_CAR_VIEWING_EVENT_SELECTED_PAGE_SIZE = 'SET_CAR_VIEWING_EVENT_SELECTED_PAGE_SIZE';
export const setCarViewingEventSelectedPageSize = makeActionCreator(SET_CAR_VIEWING_EVENT_SELECTED_PAGE_SIZE, 'size');

export const SET_CAR_VIEWING_EVENT_SELECTED_ORDER_BY = 'SET_CAR_VIEWING_EVENT_SELECTED_ORDER_BY';
export const setCarViewingEventSelectedOrderBy = makeActionCreator(SET_CAR_VIEWING_EVENT_SELECTED_ORDER_BY, 'order');

export const SET_CAR_VIEWING_EVENT_TAPPED_ID = 'SET_CAR_VIEWING_EVENT_TAPPED_ID';
export const setCarViewingEventTappedId = makeActionCreator(SET_CAR_VIEWING_EVENT_TAPPED_ID, 'id');

export const SET_CAR_VIEWING_EVENT_SEARCH_TEXT = 'SET_CAR_VIEWING_EVENT_SEARCH_TEXT';
export const setCarViewingEventSearchText = makeActionCreator(SET_CAR_VIEWING_EVENT_SEARCH_TEXT, 'text');

export const SET_CAR_VIEWING_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CAR_VIEWING_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCarViewingEventAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CAR_VIEWING_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_CAR_VIEWING_EVENT_SELECTED_CITY_ID = 'SET_CAR_VIEWING_EVENT_SELECTED_CITY_ID';
export const setCarViewingEventSelectedCityId = makeActionCreator(SET_CAR_VIEWING_EVENT_SELECTED_CITY_ID, 'id');

export const SET_CAR_VIEWING_EVENT_SELECTED_VOUCHER_REWARD_ID = 'SET_CAR_VIEWING_EVENT_SELECTED_VOUCHER_REWARD_ID';
export const setCarViewingEventSelectedVoucherRewardId = makeActionCreator(SET_CAR_VIEWING_EVENT_SELECTED_VOUCHER_REWARD_ID, 'id');

export const DOWNLOADING_CAR_VIEWING_RESERVATIONS = 'DOWNLOADING_CAR_VIEWING_RESERVATIONS';
export const downloadingCarViewingReservations = makeActionCreator(DOWNLOADING_CAR_VIEWING_RESERVATIONS, 'status');

export const DOWNLOADING_CAR_VIEWING_RESERVATION = 'DOWNLOADING_CAR_VIEWING_RESERVATION';
export const downloadingCarViewingReservation = makeActionCreator(DOWNLOADING_CAR_VIEWING_RESERVATION, 'status');

export const ADD_CAR_VIEWING_RESERVATION = 'ADD_CAR_VIEWING_RESERVATION';
export const addCarViewingReservation = makeActionCreator(ADD_CAR_VIEWING_RESERVATION, 'data');

export const SET_CAR_VIEWING_RESERVATIONS = 'SET_CAR_VIEWING_RESERVATIONS';
export const setCarViewingReservations = makeActionCreator(SET_CAR_VIEWING_RESERVATIONS, 'data');

export const CLEAR_CAR_VIEWING_RESERVATIONS = 'CLEAR_CAR_VIEWING_RESERVATIONS';
export const clearCarViewingReservations = makeActionCreator(CLEAR_CAR_VIEWING_RESERVATIONS);

export const SET_CAR_VIEWING_RESERVATION_SELECTED_PAGE_SIZE = 'SET_CAR_VIEWING_RESERVATION_SELECTED_PAGE_SIZE';
export const setCarViewingReservationSelectedPageSize = makeActionCreator(SET_CAR_VIEWING_RESERVATION_SELECTED_PAGE_SIZE, 'size');

export const SET_CAR_VIEWING_RESERVATION_SELECTED_ORDER_BY = 'SET_CAR_VIEWING_RESERVATION_SELECTED_ORDER_BY';
export const setCarViewingReservationSelectedOrderBy = makeActionCreator(SET_CAR_VIEWING_RESERVATION_SELECTED_ORDER_BY, 'order');

export const SET_CAR_VIEWING_RESERVATION_TAPPED_ID = 'SET_CAR_VIEWING_RESERVATION_TAPPED_ID';
export const setCarViewingReservationTappedId = makeActionCreator(SET_CAR_VIEWING_RESERVATION_TAPPED_ID, 'id');

export const SET_CAR_VIEWING_RESERVATION_SEARCH_TEXT = 'SET_CAR_VIEWING_RESERVATION_SEARCH_TEXT';
export const setCarViewingReservationSearchText = makeActionCreator(SET_CAR_VIEWING_RESERVATION_SEARCH_TEXT, 'text');

export const SET_CAR_VIEWING_RESERVATION_ADVANCED_FILTER_STRING = 'SET_CAR_VIEWING_RESERVATION_ADVANCED_FILTER_STRING';
export const setCarViewingReservationAdvancedFilterString = makeActionCreator(SET_CAR_VIEWING_RESERVATION_ADVANCED_FILTER_STRING, 'text');

export const DOWNLOADING_CAR_VIEWING_VOUCHER_REWARDS = 'DOWNLOADING_CAR_VIEWING_VOUCHER_REWARDS';
export const downloadingCarViewingVoucherRewards = makeActionCreator(DOWNLOADING_CAR_VIEWING_VOUCHER_REWARDS, 'status');

export const DOWNLOADING_CAR_VIEWING_VOUCHER_REWARD = 'DOWNLOADING_CAR_VIEWING_VOUCHER_REWARD';
export const downloadingCarViewingVoucherReward = makeActionCreator(DOWNLOADING_CAR_VIEWING_VOUCHER_REWARD, 'status');

export const ADD_CAR_VIEWING_VOUCHER_REWARD = 'ADD_CAR_VIEWING_VOUCHER_REWARD';
export const addCarViewingVoucherReward = makeActionCreator(ADD_CAR_VIEWING_VOUCHER_REWARD, 'data');

export const SET_CAR_VIEWING_VOUCHER_REWARDS = 'SET_CAR_VIEWING_VOUCHER_REWARDS';
export const setCarViewingVoucherRewards = makeActionCreator(SET_CAR_VIEWING_VOUCHER_REWARDS, 'data');

export const CLEAR_CAR_VIEWING_VOUCHER_REWARDS = 'CLEAR_CAR_VIEWING_VOUCHER_REWARDS';
export const clearCarViewingVoucherRewards = makeActionCreator(CLEAR_CAR_VIEWING_VOUCHER_REWARDS);

export const SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_PAGE_SIZE = 'SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_PAGE_SIZE';
export const setCarViewingVoucherRewardSelectedPageSize = makeActionCreator(SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_PAGE_SIZE, 'size');

export const SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_ORDER_BY = 'SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_ORDER_BY';
export const setCarViewingVoucherRewardSelectedOrderBy = makeActionCreator(SET_CAR_VIEWING_VOUCHER_REWARD_SELECTED_ORDER_BY, 'order');

export const SET_CAR_VIEWING_VOUCHER_REWARD_TAPPED_ID = 'SET_CAR_VIEWING_VOUCHER_REWARD_TAPPED_ID';
export const setCarViewingVoucherRewardTappedId = makeActionCreator(SET_CAR_VIEWING_VOUCHER_REWARD_TAPPED_ID, 'id');

export const SET_CAR_VIEWING_VOUCHER_REWARD_SEARCH_TEXT = 'SET_CAR_VIEWING_VOUCHER_REWARD_SEARCH_TEXT';
export const setCarViewingVoucherRewardSearchText = makeActionCreator(SET_CAR_VIEWING_VOUCHER_REWARD_SEARCH_TEXT, 'text');

export const SET_CAR_VIEWING_VOUCHER_REWARD_ADVANCED_FILTER_STRING = 'SET_CAR_VIEWING_VOUCHER_REWARD_ADVANCED_FILTER_STRING';
export const setCarViewingVoucherRewardAdvancedFilterString = makeActionCreator(SET_CAR_VIEWING_VOUCHER_REWARD_ADVANCED_FILTER_STRING, 'text');
