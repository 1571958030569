import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  FunctionalPage, AccentButton, MapTokensDialog,
} from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_GARUDA_AIRPORT_DESCRIPTION, RXFIELD_GARUDA_AIRPORT_NAME,
  RXFORM_GARUDA_AIRPORT, RXSTATE_GARUDA_AIRPORTS, RXSTATE_GARUDA_AIRPORT_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { rxformValidateGarudaAirport } from '../../validation';
import { SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 10,
  },
}));

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GARUDA_AIRPORT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherGarudaAirportPage.labelName}
          label={LocalizedString.voucherGarudaAirportPage.labelName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GARUDA_AIRPORT_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherGarudaAirportPage.labelDescription}
          label={LocalizedString.voucherGarudaAirportPage.labelDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>
);

const GarudaAirportPage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage,
  onChangePageSize, onCreatePressed, onEditPressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed, onDeletePressed, onConfirmDeletePressed, pageMode,
  onOpenMapTokenPressed, viewModal,
  onMapTokenCancelPressed, airports, loadingAirports,
  onChangeAirportText, onAirportOptionSelected, onCountPressed,
  onMapTokenPressed, tokenCount, countingVoucherToken,
  onTokenRemarkTextChange, airport, tokenRemark,
  enableMapTokenButton, mapping,
}) => {
  const classes = useStyles();

  return (
    <div>
      <MapTokensDialog
        view={viewModal}
        onCancelPressed={onMapTokenCancelPressed}
        airports={airports}
        loadingAirports={loadingAirports}
        onChangeAirportText={onChangeAirportText}
        onAirportOptionSelected={onAirportOptionSelected}
        onCountPressed={onCountPressed}
        onMapTokenPressed={onMapTokenPressed}
        tokenCount={tokenCount}
        countingVoucherToken={countingVoucherToken}
        onTokenRemarkTextChange={onTokenRemarkTextChange}
        airport={airport}
        tokenRemark={tokenRemark}
        enableMapTokenButton={enableMapTokenButton}
        mapping={mapping}
      />

      <FunctionalPage
        data={RXSTATE_GARUDA_AIRPORTS}
        uiPage={RXSTATE_GARUDA_AIRPORT_PAGE}
        tableColumns={[
          {
            title: LocalizedString.voucherGarudaAirportPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.voucherGarudaAirportPage.labelName, field: 'name', sorting: !downloading },
          { title: LocalizedString.voucherGarudaAirportPage.labelDescription, field: 'description', sorting: !downloading },
        ]}
        handleSubmit={handleSubmit}
        onAppear={onAppear}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onCreatePressed={onCreatePressed}
        onEditPressed={onEditPressed}
        onRefresh={onRefresh}
        onSavePressed={onSavePressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onSubmitPressed={onSubmitPressed}
        onViewPressed={onViewPressed}
        onConfirmDeletePressed={onConfirmDeletePressed}
        onDeletePressed={onDeletePressed}
        createNewButtonCaption={
      LocalizedString.voucherGarudaAirportPage.buttonCaptionCreateNewGarudaAirport
    }
        deleteButtonCaption={
          LocalizedString.voucherGarudaAirportPage.buttonCaptionDeleteGarudaAirport
        }
        editButtonCaption={LocalizedString.voucherGarudaAirportPage.buttonCaptionEditGarudaAirport}
        title={LocalizedString.voucherGarudaAirportPage.title}
        useFullWidth
        renderCustomUpperButton={() => (
          <AccentButton
            onClick={onOpenMapTokenPressed}
            caption={LocalizedString.voucherGarudaAirportPage.buttonCaptionMapVoucherTokens}
            style={classes.button}
          />
        )}
      >
        {renderDialogContent(
          addingEditing, downloadingDeleting, pageMode,
        )}
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_GARUDA_AIRPORT,
  validate: rxformValidateGarudaAirport,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(GarudaAirportPage);

GarudaAirportPage.propTypes = {
  airports: PropTypes.arrayOf(SimpleDataShape).isRequired,
  airport: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  viewModal: PropTypes.bool.isRequired,
  loadingAirports: PropTypes.bool.isRequired,
  countingVoucherToken: PropTypes.bool.isRequired,
  enableMapTokenButton: PropTypes.bool.isRequired,
  mapping: PropTypes.bool.isRequired,
  tokenCount: PropTypes.number.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  tokenRemark: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  onOpenMapTokenPressed: PropTypes.func.isRequired,
  onMapTokenCancelPressed: PropTypes.func.isRequired,
  onAirportOptionSelected: PropTypes.func.isRequired,
  onChangeAirportText: PropTypes.func.isRequired,
  onCountPressed: PropTypes.func.isRequired,
  onMapTokenPressed: PropTypes.func.isRequired,
  onTokenRemarkTextChange: PropTypes.func.isRequired,
};
