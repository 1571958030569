import {
  REST_URL_TEST_DRIVE_EO_EVENTS, REST_URL_VIEW_DELETE_TEST_DRIVE_EO_EVENT,
  REST_URL_TEST_DRIVE_EO_TEST_VEHICLES, REST_URL_ADD_EDIT_TEST_DRIVE_EO_TEST_VEHICLE,
  REST_URL_VIEW_DELETE_TEST_DRIVE_EO_TEST_VEHICLE,
  REST_URL_RESERVATION_FOR_EOS, REST_URL_VIEW_RESERVATION_FOR_EO, REST_URL_SALES_CONSULTANTS, REST_URL_ADD_EDIT_TEST_DRIVE_SALES,
} from './constant';
import {
  buildQueryParams, transformQueryResult, sendGetRequest, sendPutRequest, transformSearchText, convertArrToObj,
} from '../../helper';

export * from '../../helper';

export const downloadTestDriveEoEvents = async (pageNumber, pageSize, orderBy, searchText, filterString = '',
  token) => {
  const url = buildQueryParams(REST_URL_TEST_DRIVE_EO_EVENTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestDriveEoEvent = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_TEST_DRIVE_EO_EVENT.replace(/\{id\}/, id), token);
  return response;
};

export const downloadTestDriveEoTestVehicles = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_TEST_DRIVE_EO_TEST_VEHICLES.concat(filterString),
    pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestDriveEoTestVehicle = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_TEST_DRIVE_EO_TEST_VEHICLE.replace(/\{id\}/, id), token);
  return response;
};

export const editTestDriveEoTestVehicle = async (id, eventId, modelId, dailyQuota, token) => {
  const body = {
    id,
    eventId,
    modelId,
    dailyQuota,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_TEST_DRIVE_EO_TEST_VEHICLE, body, token);
};

export const downloadTestDriveEoReservations = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_RESERVATION_FOR_EOS.concat(filterString), pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestDriveReservation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_RESERVATION_FOR_EO.replace(/\{id\}/, id), token);
  return response;
};

export const downloadSalesConsultants = async (searchString, token) => {
  const url = REST_URL_SALES_CONSULTANTS.replace(/\{searchString\}/, transformSearchText(searchString));
  const response = await sendGetRequest(url, token);
  const {
    data, currentPage, totalPages, totalCount,
  } = response;
  return {
    data: convertArrToObj(data, 'id'),
    meta: { currentPage, totalPages, totalCount },
  };
};


export const editSalesConsultans = async (id, SalesConsultantId, token) => {
  const body = {
    id,
    SalesConsultantId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_TEST_DRIVE_SALES, body, token);
};
