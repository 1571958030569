import {
  downloadAuthRegistrationAttempts,
  transformSearchText,
} from '../../../helper';
import {
  downloadingAuthRegistrationAttempts,
  setAuthRegistrationAttempts,
} from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication,
    authRegistrationAttempts,
    uiAuthRegistrationAttempt,
  } = getState();
  const { token } = authentication;
  const { meta } = authRegistrationAttempts;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiAuthRegistrationAttempt;

  try {
    dispatch(downloadingAuthRegistrationAttempts(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAuthRegistrationAttempts(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setAuthRegistrationAttempts(list));
    }
  } finally {
    dispatch(downloadingAuthRegistrationAttempts(false));
  }
};
