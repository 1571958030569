import {
  SET_ACTIVE_SIDEMENU_ITEM, RESET_SIDEMENU_STATE, SET_DRAWER_VISIBILITY, SET_DRAWER_MENU_EXPANSION,
  SET_SETTINGS_DIALOG_VISIBILITY, CHANGING_PASSWORD, SET_CHANGE_PASSWORD_SUCCESS,
} from '../action';
import { MENUID_ROOT } from '../../constant';

const initialState = {
  activeMenuId: 1,
  visibility: false,
  expandedMenuId: MENUID_ROOT,
  expanded: false,
  settingsDialogVisibility: false,
  changingPassword: false,
  changePasswordSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_SIDEMENU_ITEM:
      return { ...state, activeMenuId: action.id };
    case RESET_SIDEMENU_STATE:
      return initialState;
    case SET_DRAWER_VISIBILITY:
      return { ...state, visibility: action.status };
    case SET_DRAWER_MENU_EXPANSION:
      return { ...state, expanded: action.status, expandedMenuId: action.id };
    case SET_SETTINGS_DIALOG_VISIBILITY:
      return { ...state, settingsDialogVisibility: action.status };
    case CHANGING_PASSWORD:
      return { ...state, changingPassword: action.status };
    case SET_CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordSuccess: action.status };
    default: return state;
  }
};
