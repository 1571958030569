import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData,
} from '../../helper';
import {
  downloadTestDriveUniqueCodesAsync, setAlertErrorMessage, setTestDriveUniqueCodeSelectedOrderBy,
  downloadTestDriveUniqueCodeAsync, setTestDriveUniqueCodeTappedId,
  setTestDriveUniqueCodeSelectedPageSize,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setTestDriveUniqueCodeAdvancedFilterString, clearTestDriveUniqueCodes,
  setTestDriveUniqueCodeSearchText, setActiveSideMenuItem, saveTestDriveUniqueCodesAsync,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE, INITIAL_ORDER_BY_RESERVATIONS, MENUID_TEST_DRIVE_UNIQUE_CODE,
  INITIAL_ORDER_BY_UNIQUE_CODES,
} from '../../constant';
import { downloadUsersAsync } from '../../../../redux/action';
import UniqueCodePage from './unique-code.presentation';

const getInitialValues = (state) => {
  const { uniqueCodes, uiFunctionalPage, uiUniqueCode } = state;
  const { data } = uniqueCodes;
  const { downloadingDeleting, tappedId } = uiUniqueCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    userId: found.profile?.id || '',
    userFullName: found.profile?.fullName || '',
  }) : {
    userId: '',
    userFullName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  downloading: state.uiUniqueCode.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiUniqueCode.tappedId,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_TEST_DRIVE_UNIQUE_CODE));
    dispatch(setTestDriveUniqueCodeSearchText(''));
    dispatch(setTestDriveUniqueCodeAdvancedFilterString(''));
    dispatch(clearTestDriveUniqueCodes());
    dispatch(setTestDriveUniqueCodeSelectedPageSize(20));
    dispatch(setTestDriveUniqueCodeSelectedOrderBy(INITIAL_ORDER_BY_UNIQUE_CODES));
    dispatch(downloadTestDriveUniqueCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('userFullName', 'profile.id');

    dispatch(setTestDriveUniqueCodeAdvancedFilterString(text));
    dispatch(clearTestDriveUniqueCodes());
    dispatch(downloadTestDriveUniqueCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestDriveUniqueCodesAsync(pageNo))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestDriveUniqueCodeSelectedPageSize(pageSize));
    dispatch(downloadTestDriveUniqueCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveTestDriveUniqueCodesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setTestDriveUniqueCodeSelectedPageSize(pageSize));
    dispatch(clearTestDriveUniqueCodes());
    dispatch(downloadTestDriveUniqueCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestDriveUniqueCodes());
    dispatch(setTestDriveUniqueCodeAdvancedFilterString(''));
    dispatch(setTestDriveUniqueCodeSelectedOrderBy(INITIAL_ORDER_BY_RESERVATIONS));
    dispatch(downloadTestDriveUniqueCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setTestDriveUniqueCodeSearchText(text));
      dispatch(clearTestDriveUniqueCodes());
      await dispatch(downloadTestDriveUniqueCodesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestDriveUniqueCodeSelectedOrderBy(orderBy));
    dispatch(clearTestDriveUniqueCodes());
    dispatch(downloadTestDriveUniqueCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setTestDriveUniqueCodeTappedId(id));
    dispatch(downloadTestDriveUniqueCodeAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UniqueCodePage);
