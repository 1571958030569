import { connect } from 'react-redux';
import {
  transformInitialValues, transformUserDropdownData, transformDropdownData,
} from '../../helper';

import {
  INITIAL_ORDER_BY_CAR_VIEWING_VOUCHER_REWARD,
  MENUID_CAR_VIEWING_VOUCHER_REWARD,
  PAGE_MODE_TABLE,
} from '../../constant';
import { downloadUsersAsync, setActiveSideMenuItem, setAlertErrorMessage } from '../../../../redux/action';
import CarViewingVoucherRewardPage from './car-viewing-voucher-reward.presentation';
import {
  setCarViewingEventAdvancedFilterDialogSelectedFilterString, setCarViewingEventSearchText,
  clearCarViewingEvents,
  clearUsers, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  clearCarViewingVoucherRewards, setCarViewingVoucherRewardAdvancedFilterString,
  setCarViewingVoucherRewardSearchText, setCarViewingVoucherRewardSelectedOrderBy,
  setCarViewingVoucherRewardSelectedPageSize, setCarViewingVoucherRewardTappedId,
  downloadCarViewingEventsAsync,
  downloadCarViewingVoucherRewardAsync, downloadCarViewingVoucherRewardsAsync,
} from '../../redux/action';
import {
  clearVouchers, setVoucherAdvancedFilterDialogSelectedFilterString, setVoucherSearchText,
  downloadVouchersAsync,
} from '../../../voucher';


const getInitialValues = (state) => {
  const { carViewingVoucherRewards, uiFunctionalPage, uiCarViewingVoucherReward } = state;
  const { data } = carViewingVoucherRewards;
  const { downloadingDeleting, tappedId } = uiCarViewingVoucherReward;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    eventId: found.reservation.event?.id,
    eventName: found.reservation.event?.name,
    userId: found.reservation.profile?.id,
    userFullname: found.reservation.profile?.fullName,
    voucherName: found.voucherName,
    voucherId: found.voucherId,
    note: found.note,
  }) : {};
  return result;
};


const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiCarViewingVoucherReward.downloading,
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingVoucher: state.uiVoucher.downloading,
  events: transformDropdownData(state.carViewingEvents.data),
  loadingEvents: state.uiCarViewingEvent.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_VIEWING_VOUCHER_REWARD));
    dispatch(setCarViewingVoucherRewardAdvancedFilterString(''));
    dispatch(setCarViewingVoucherRewardSearchText(''));
    dispatch(clearCarViewingVoucherRewards());
    dispatch(setCarViewingVoucherRewardSelectedPageSize(20));
    dispatch(
      setCarViewingVoucherRewardSelectedOrderBy(INITIAL_ORDER_BY_CAR_VIEWING_VOUCHER_REWARD),
    );
    dispatch(downloadCarViewingVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setCarViewingVoucherRewardSelectedOrderBy(orderBy));
    dispatch(clearCarViewingVoucherRewards());
    dispatch(downloadCarViewingVoucherRewardsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setCarViewingVoucherRewardTappedId(id));
    dispatch(downloadCarViewingVoucherRewardAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setCarViewingVoucherRewardSearchText(text));
    dispatch(clearCarViewingVoucherRewards());
    dispatch(downloadCarViewingVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCarViewingVoucherRewardsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCarViewingVoucherRewardSelectedPageSize(pageSize));
    dispatch(downloadCarViewingVoucherRewardsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setCarViewingVoucherRewardSelectedPageSize(pageSize));
    dispatch(clearCarViewingVoucherRewards());
    dispatch(downloadCarViewingVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCarViewingVoucherRewards());
    dispatch(setCarViewingVoucherRewardAdvancedFilterString(''));
    dispatch(
      setCarViewingVoucherRewardSelectedOrderBy(INITIAL_ORDER_BY_CAR_VIEWING_VOUCHER_REWARD),
    );
    dispatch(downloadCarViewingVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('eventName', 'event.id')
      .replace('user', 'profile.id')
      .replace('voucher', 'voucherId');

    dispatch(setCarViewingVoucherRewardAdvancedFilterString(text));
    dispatch(clearCarViewingVoucherRewards());
    dispatch(downloadCarViewingVoucherRewardsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCarViewingEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCarViewingEventSearchText(''));
    dispatch(clearCarViewingEvents());
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setCarViewingEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setCarViewingEventSearchText(text));
      dispatch(clearCarViewingEvents());
      await dispatch(downloadCarViewingEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarViewingVoucherRewardPage);
