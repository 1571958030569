import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_POINT_VOID_REQUEST_POINT, RXFIELD_POINT_VOID_REQUEST_REASON,
  RXFIELD_POINT_VOID_REQUEST_MERCHANT_REFERENCE_ID,
  RXFIELD_POINT_SUBMISSION_USER, RXFIELD_POINT_SUBMISSION_AMOUNT, RXFIELD_POINT_SUBMISSION_LABEL,
  RXFIELD_POINT_SUBMISSION_VALID_UNTIL, RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID,
  RXFIELD_POINT_SUBMISSION_SEND_NOTIFICATION,
  RXFIELD_VOID_POINT_DIALOG_REASON, RXFIELD_VOID_POINT_DIALOG_REFERENCE_ID,
  RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION,
} from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateVoidPointRequest = (values) => {
  const requiredFields = [
    RXFIELD_POINT_VOID_REQUEST_REASON,
    RXFIELD_POINT_VOID_REQUEST_POINT,
    RXFIELD_POINT_VOID_REQUEST_MERCHANT_REFERENCE_ID,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidatePointSubmission = (values) => {
  const requiredFields = [
    RXFIELD_POINT_SUBMISSION_USER,
    RXFIELD_POINT_SUBMISSION_AMOUNT,
    RXFIELD_POINT_SUBMISSION_LABEL,
    RXFIELD_POINT_SUBMISSION_VALID_UNTIL,
    RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID,
    RXFIELD_POINT_SUBMISSION_SEND_NOTIFICATION,
  ];
  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
export const rxformValidateVoidPointDialog = (values) => {
  const requiredFields = [
    RXFIELD_VOID_POINT_DIALOG_REASON,
    RXFIELD_VOID_POINT_DIALOG_REFERENCE_ID,
    RXFIELD_VOID_POINT_DIALOG_SEND_NOTIFICATION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
