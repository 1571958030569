import { downloadChargingStation } from '../../../helper';
import { addChargingStation, downloadingDeletingChargingStation, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiChargingStation } = getState();

  const { token } = authentication;
  const { tappedId } = uiChargingStation;

  try {
    dispatch(downloadingDeletingChargingStation(true));

    const chargingStations = await downloadChargingStation(
      tappedId,
      token,
    );

    dispatch(addChargingStation(chargingStations));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingChargingStation(false));
  }
};
