import { transformSearchText } from '../../../../../helper';
import { downloadGiias2022PrizeRedemptions } from '../../../helper';
import { downloadingGiias2022PrizeRedemptions, setGiias2022PrizeRedemptions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, giias2022PrizeRedemptions, uiGiias2022PrizeRedemption } = getState();
  const { token } = authentication;
  const { meta } = giias2022PrizeRedemptions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiGiias2022PrizeRedemption;


  try {
    dispatch(downloadingGiias2022PrizeRedemptions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadGiias2022PrizeRedemptions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setGiias2022PrizeRedemptions(list));
    }
  } finally {
    dispatch(downloadingGiias2022PrizeRedemptions(false));
  }
};
