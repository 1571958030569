
import { INITIAL_ORDER_BY_PARTNER_MERCHANTS } from '../../constant';
import {
  DOWNLOADING_DELETING_PARTNER_MERCHANT,
  DOWNLOADING_PARTNER_MERCHANTS, SET_PARTNER_MERCHANT_SEARCH_TEXT,
  SET_PARTNER_MERCHANT_SELECTED_ORDER_BY,
  SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE,
  SET_PARTNER_MERCHANT_TAPPED_ID, COPYING_PARTNER_MERCHANT_DEEP_LINK,
  ADDING_EDITING_PARTNER_MERCHANT,
  SET_PARTNER_MERCHANT_SELECTED_COMPANY,
} from '../action';

const initialState = {
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_PARTNER_MERCHANTS,
  selectedCompany: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_PARTNER_MERCHANTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_PARTNER_MERCHANT:
      return { ...state, downloadingDeleting: action.status };
    case ADDING_EDITING_PARTNER_MERCHANT:
      return { ...state, addingEditing: action.status };
    case COPYING_PARTNER_MERCHANT_DEEP_LINK:
      return { ...state, copying: action.status };
    case SET_PARTNER_MERCHANT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_PARTNER_MERCHANT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_PARTNER_MERCHANT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_PARTNER_MERCHANT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_PARTNER_MERCHANT_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.value };
    default: return state;
  }
};
