import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage, SectionTitle } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  RXFORM_TEST_DRIVE_RESERVATION, RXSTATE_RESERVATIONS, RXSTATE_RESERVATION_PAGE,
  RESERVATION_TYPE_EVENT, DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_RESERVATION_TYPE,
  RXFIELD_RESERVATION_USER_ID, RXFIELD_RESERVATION_EVENT_NAME,
  RXFIELD_RESERVATION_EVENT_ID, RXFIELD_RESERVATION_EVENT_LOCATION,
  RXFIELD_RESERVATION_ACTIVITY_STATUS, RXFIELD_RESERVATION_RECEIVED_NEWS_UPDATE,
  RXFIELD_RESERVATION_SIM_IMAGE, RXFIELD_RESERVATION_SURVEY_IMAGE,
  RXFIELD_RESERVATION_USER_FULLNAME, RXFIELD_RESERVATION_VEHICLE_MODEL,
  RXFIELD_RESERVATION_RESERVED_DATE, RXFIELD_RESERVATION_COMPLETED_TIME,
  RXFIELD_RESERVATION_REFERENCE_ID, RXFIELD_RESERVATION_TESTDRIVE_IMAGE,
  RXFIELD_RESERVATION_OTHER_IMAGE, RXFIELD_RESERVATION_DEALER_CODE,
  RXFIELD_RESERVATION_DEALER_NAME, RXFIELD_RESERVATION_DEALER_CITY,
  FILTER_TYPE_SWITCH, ACTIVITY_STATUS_DROPDOWN, RESERVATION_TYPE_DROPDOWN,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, FILTER_TYPE_DATE_RANGE, RXFIELD_RESERVATION_FIRST_NAME,
  RXFIELD_RESERVATION_EMAIL, RXFIELD_RESERVATION_LAST_NAME, RXFIELD_RESERVATION_PHONE,
  RXFIELD_RESERVATION_GENDER,
  RXFIELD_RESERVATION_SALES_CONSULTANT_ID,
  RXFIELD_RESERVATION_SALES_CONSULTANT_NAME,
} from '../../constant';
import { renderReduxFormImageInputField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';

const renderDialogContent = (isEventReservationType, initialValues) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_TYPE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelReservationType}
            label={LocalizedString.reservationPage.labelReservationType}
            disabled
          />
        </Grid>
        { isEventReservationType ? (
          <>
            <Grid item>
              <Field
                name={RXFIELD_RESERVATION_EVENT_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelEventId}
                label={LocalizedString.reservationPage.labelEventId}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_RESERVATION_EVENT_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelEventName}
                label={LocalizedString.reservationPage.labelEventName}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_RESERVATION_EVENT_LOCATION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelEventLocation}
                label={LocalizedString.reservationPage.labelEventLocation}
                disabled
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Field
                name={RXFIELD_RESERVATION_DEALER_CODE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelDealerCode}
                label={LocalizedString.reservationPage.labelDealerCode}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_RESERVATION_DEALER_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelDealerName}
                label={LocalizedString.reservationPage.labelDealerName}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_RESERVATION_DEALER_CITY}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.reservationPage.labelDealerCity}
                label={LocalizedString.reservationPage.labelDealerCity}
                disabled
              />
            </Grid>
          </>
        ) }
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_ACTIVITY_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelActivityStatus}
            label={LocalizedString.reservationPage.labelActivityStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_RECEIVED_NEWS_UPDATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelReceivedNewsUpdate}
            label={LocalizedString.reservationPage.labelReceivedNewsUpdate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_SALES_CONSULTANT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.placeholderSalesConsultantID}
            label={LocalizedString.reservationPage.placeholderSalesConsultantID}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_SALES_CONSULTANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.placeholderSalesConsultantName}
            label={LocalizedString.reservationPage.placeholderSalesConsultantName}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelUserId}
            label={LocalizedString.reservationPage.labelUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelUserFullname}
            label={LocalizedString.reservationPage.labelUserFullname}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_VEHICLE_MODEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelVehicleModel}
            label={LocalizedString.reservationPage.labelVehicleModel}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_RESERVED_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelReservedDate}
            label={LocalizedString.reservationPage.labelReservedDate}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_COMPLETED_TIME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelCompletedTime}
            label={LocalizedString.reservationPage.labelCompletedTime}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.labelReferenceId}
            label={LocalizedString.reservationPage.labelReferenceId}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.reservationPage.labelUserInfo} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_FIRST_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.placeholderFirstName}
            label={LocalizedString.reservationPage.placeholderFirstName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_LAST_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.placeholderLastName}
            label={LocalizedString.reservationPage.placeholderLastName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_GENDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.placeholderGender}
            label={LocalizedString.reservationPage.placeholderGender}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.placeholderEmail}
            label={LocalizedString.reservationPage.placeholderEmail}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.reservationPage.placeholderPhone}
            label={LocalizedString.reservationPage.placeholderPhone}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_SIM_IMAGE}
            component={renderReduxFormImageInputField}
            placeholder={LocalizedString.reservationPage.labelSimImage}
            label={LocalizedString.reservationPage.labelSimImage}
            defaultValue={initialValues.simImage}
            fullImageWidth="100%"
            useFullWidthImage
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_SURVEY_IMAGE}
            component={renderReduxFormImageInputField}
            placeholder={LocalizedString.reservationPage.labelSurveyImage}
            label={LocalizedString.reservationPage.labelSurveyImage}
            defaultValue={initialValues.surveyImage}
            fullImageWidth="100%"
            useFullWidthImage
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_TESTDRIVE_IMAGE}
            component={renderReduxFormImageInputField}
            placeholder={LocalizedString.reservationPage.labelTestdriveImage}
            label={LocalizedString.reservationPage.labelTestdriveImage}
            defaultValue={initialValues.testDriveImage}
            fullImageWidth="100%"
            useFullWidthImage
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_RESERVATION_OTHER_IMAGE}
            component={renderReduxFormImageInputField}
            placeholder={LocalizedString.reservationPage.labelOtherImage}
            label={LocalizedString.reservationPage.labelOtherImage}
            defaultValue={initialValues.otherImage}
            fullImageWidth="100%"
            useFullWidthImage
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const ReservationPage = ({
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  isEventReservationType, initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onDownloadPressed,
  users, loadingUsers, onChangeUserText,
  dealers, loadingDealers, onChangeDealersText,
  events, loadingEvents, onChangeEventText,
}) => (
  <FunctionalPage
    data={RXSTATE_RESERVATIONS}
    uiPage={RXSTATE_RESERVATION_PAGE}
    tableColumns={[
      {
        title: LocalizedString.reservationPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.reservationPage.labelEventOrDealer,
        field: '',
        sorting: false,
        render: ({ reservationType, event, dealer }) => (
          reservationType === RESERVATION_TYPE_EVENT ? event?.name : dealer?.name
        ),
      },
      { title: LocalizedString.reservationPage.labelReservationType, field: 'reservationType', sorting: !downloading },
      {
        title: LocalizedString.reservationPage.labelUserFullname,
        field: '',
        sorting: !downloading,
        render: ({
          isForOther, profile, firstNameForOther, lastNameForOther,
        }) => (!isForOther
          ? profile.fullName : `${firstNameForOther} ${lastNameForOther}`),
      },
      { title: LocalizedString.reservationPage.labelVehicleModel, field: 'testVehicleModel', sorting: !downloading },
      { title: LocalizedString.reservationPage.labelReservationStatus, field: 'activityStatus', sorting: !downloading },
      {
        title: LocalizedString.reservationPage.labelCreatedDate,
        field: 'createdDate',
        render: ({ createdDate }) => toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
        sorting: !downloading,
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.reservationPage.labelEventName,
        field: RXFIELD_RESERVATION_EVENT_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: events,
        loading: loadingEvents,
        onChangeFilterText: onChangeEventText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.labelDealerName,
        field: RXFIELD_RESERVATION_DEALER_NAME,
        type: FILTER_TYPE_DROPDOWN,
        data: dealers,
        loading: loadingDealers,
        onChangeFilterText: onChangeDealersText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.labelReservationType,
        field: RXFIELD_RESERVATION_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: RESERVATION_TYPE_DROPDOWN,
      },
      {
        title: LocalizedString.reservationPage.labelVehicleModel,
        field: RXFIELD_RESERVATION_VEHICLE_MODEL,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.reservationPage.labelUserFullname,
        field: RXFIELD_RESERVATION_USER_FULLNAME,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.reservationPage.labelReservedDate,
        field: RXFIELD_RESERVATION_RESERVED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
      },
      {
        title: LocalizedString.reservationPage.labelReferenceId,
        field: RXFIELD_RESERVATION_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.reservationPage.labelActivityStatus,
        field: RXFIELD_RESERVATION_ACTIVITY_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: ACTIVITY_STATUS_DROPDOWN,
      },
      {
        title: LocalizedString.reservationPage.labelReceivedNewsUpdate,
        field: RXFIELD_RESERVATION_RECEIVED_NEWS_UPDATE,
        type: FILTER_TYPE_SWITCH,
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.reservationPage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onDownloadPressed={onDownloadPressed}
    enableSave
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(isEventReservationType, initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TEST_DRIVE_RESERVATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ReservationPage);

ReservationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  dealers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingEvents: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  loadingDealers: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  isEventReservationType: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeDealersText: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
