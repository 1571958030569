import {
  downloadingDeletingGameActivityRegistrationReferral, addGameActivityRegistrationReferral,
} from '../simple-action';
import { downloadGameActivityRegistrationReferral } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiGameActivityRegistrationReferral } = getState();

  const { token } = authentication;
  const { tappedId } = uiGameActivityRegistrationReferral;

  try {
    dispatch(downloadingDeletingGameActivityRegistrationReferral(true));

    const registrationReferral = await downloadGameActivityRegistrationReferral(
      tappedId,
      token,
    );

    dispatch(addGameActivityRegistrationReferral(registrationReferral));
  } finally {
    dispatch(downloadingDeletingGameActivityRegistrationReferral(false));
  }
};
