import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Typography, Checkbox,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, LockOutlined } from '@material-ui/icons/';
import { reduxForm, Field } from 'redux-form';
import {
  COLOR_BACKGROUND, COLOR_DANGEROUS, COLOR_PRIMARY,
  RXFIELD_SETTINGS_NEW_PASSWORD,
  RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE,
  RXFIELD_SETTINGS_OLD_PASSWORD,
  RXFORM_SETTINGS_DIALOG,
} from '../../constant';
import {} from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import { rxformValidateSettings } from '../../validation';
import AccentButton from '../accent-button';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    background: COLOR_PRIMARY,
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  icon: {
    fontSize: 20,
    color: COLOR_BACKGROUND,
  },
  boldText: {
    fontWeight: 'bold',
  },
  mainFieldsContainer: {
    padding: '17px 15px 24px 15px',
    border: `1px solid ${COLOR_PRIMARY}`,
    borderRadius: 10,
    marginBottom: 15,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const SettingsDialog = ({
  visible, changingPassword,
  onSavePressed, onCancelPressed, handleSubmit,
}) => {
  const classes = useStyles();
  const [logoutDevices, setLogoutDevices] = useState(false);

  return (
    <Dialog
      open={visible}
      onClose={onCancelPressed}
      fullWidth
    >
      <DialogTitle>
        <div className={classes.headerContainer}>
          <Typography variant="h4" className={classes.boldText}>
            {LocalizedString.common.labelSettings}
          </Typography>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className={classes.mainFieldsContainer}>
          <div className={classes.sectionContainer}>
            <div className={classes.iconContainer}>
              <LockOutlined className={classes.icon} />
            </div>

            <Typography variant="h6">
              {LocalizedString.common.labelChangePassword}
            </Typography>
          </div>
          <Field
            name={RXFIELD_SETTINGS_OLD_PASSWORD}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.common.placeholderOldPassword}
            label={LocalizedString.common.placeholderOldPassword}
            editable={changingPassword}
            secureTextEntry
            required
          />
          <Field
            name={RXFIELD_SETTINGS_NEW_PASSWORD}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.common.placeholderNewPassword}
            label={LocalizedString.common.placeholderNewPassword}
            editable={changingPassword}
            secureTextEntry
            required
          />
          <Field
            name={RXFIELD_SETTINGS_NEW_PASSWORD_RETYPE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.common.placeholderRetypeNewPassword}
            label={LocalizedString.common.placeholderRetypeNewPassword}
            editable={changingPassword}
            secureTextEntry
            required
          />

          <div className={classes.rowContainer}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={logoutDevices}
              onChange={() => setLogoutDevices(!logoutDevices)}
            />
            <Typography>
              {LocalizedString.common.labelLogoutDevices}
            </Typography>
          </div>
        </div>
      </DialogContent>

      <DialogActions className={classes.buttonContainer}>
        <AccentButton
          variant="text"
          caption={LocalizedString.common.buttonCaptionCancel}
          onClick={onCancelPressed}
          className={classes.redButton}
          disabled={changingPassword}
        />

        <AccentButton
          caption={LocalizedString.common.buttonCaptionSave}
          onClick={handleSubmit((values) => onSavePressed(values, logoutDevices))}
          loading={changingPassword}
        />
      </DialogActions>
    </Dialog>
  );
};

export default reduxForm({
  form: RXFORM_SETTINGS_DIALOG,
  validate: rxformValidateSettings,
})(SettingsDialog);

SettingsDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  changingPassword: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
};

SettingsDialog.defaultProps = {
};
