import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  SIMPLE_DATE_FORMAT, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE, PICKER_DATE_FORMAT,
  PICKER_MODE_DATE, RXSTATE_CAR_VIEWING_RESERVATION, RXSTATE_CAR_VIEWING_RESERVATIONS,
  RXFORM_CAR_VIEWING_RESERVATION, RXFIELD_CAR_VIEWING_RESERVATION_USER_ID, RESERVED_TIMES,
  RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_DATE, RXFIELD_CAR_VIEWING_RESERVATION_EVENT,
  RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_FIRST_NAME,
  RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_LAST_NAME, RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_GENDER,
  RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_PHONE_NUMBER,
  RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_EMAIL, RXFIELD_CAR_VIEWING_RESERVATION_EVENT_NAME,
  RXFIELD_CAR_VIEWING_RESERVATION_EVENT_ID, RXFIELD_CAR_VIEWING_RESERVATION_CODE,
  RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_TIME, RXFIELD_CAR_VIEWING_RESERVATION_USER_FULL_NAME,
  RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR_FULL_NAME,
  RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR_PHONE_NUMBER,
  RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import GlobalLocalizedString from '../../../../localization';

const renderDialogContent = (initialValues) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_FIRST_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderVisitorFirstName}
          label={LocalizedString.carViewingReservationPage.labelVisitorFirstName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_LAST_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderVisitorLastName}
          label={LocalizedString.carViewingReservationPage.labelVisitorLastName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_GENDER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderVisitorGender}
          label={LocalizedString.carViewingReservationPage.labelVisitorGender}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_PHONE_NUMBER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderVisitorPhoneNumber}
          label={LocalizedString.carViewingReservationPage.labelVisitorPhoneNumber}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_VISITOR_EMAIL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderVisitorEmail}
          label={LocalizedString.carViewingReservationPage.labelVisitorEmail}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderAdditionalVisitor}
          label={LocalizedString.carViewingReservationPage.labelAdditionalVisitor}
          disabled
        />
      </Grid>
      { initialValues.additionalVisitor === GlobalLocalizedString.common.labelYes ? (
        <Grid>
          <Grid item>
            <Field
              name={RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR_FULL_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.carViewingReservationPage
                .placeholderAdditionalVisitorFullName}
              label={LocalizedString.carViewingReservationPage.labelAdditionalVisitorFullName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CAR_VIEWING_RESERVATION_ADDITIONAL_VISITOR_PHONE_NUMBER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.carViewingReservationPage
                .placeholderAdditionalVisitorPhoneNumber}
              label={LocalizedString.carViewingReservationPage
                .labelAdditionalVisitorPhoneNumber}
              disabled
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_EVENT_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderEventName}
          label={LocalizedString.carViewingReservationPage.labelEventName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_EVENT_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderEventId}
          label={LocalizedString.carViewingReservationPage.labelEventId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderReservationCode}
          label={LocalizedString.carViewingReservationPage.labelReservationCode}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_DATE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderReservedDate}
          label={LocalizedString.carViewingReservationPage.labelReservedDate}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_TIME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderReservedTime}
          label={LocalizedString.carViewingReservationPage.labelReservedTime}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderUserId}
          label={LocalizedString.carViewingReservationPage.placeholderUserId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_VIEWING_RESERVATION_USER_FULL_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.carViewingReservationPage.placeholderUserFullName}
          label={LocalizedString.carViewingReservationPage.placeholderUserFullName}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const CarViewingReservationPage = ({
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  events, loadingEvents, onChangeEventText, users, loadingUser, onChangeUserText,
}) => (
  <FunctionalPage
    data={RXSTATE_CAR_VIEWING_RESERVATIONS}
    uiPage={RXSTATE_CAR_VIEWING_RESERVATION}
    tableColumns={[
      {
        title: LocalizedString.carViewingReservationPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.carViewingReservationPage.labelEventName, field: 'event.name', sorting: !downloading },
      { title: LocalizedString.carViewingReservationPage.labelUser, field: 'profile.fullName', sorting: !downloading },
      { title: LocalizedString.carViewingReservationPage.labelReservationCode, field: 'reservationCode', sorting: !downloading },
      {
        title: LocalizedString.carViewingReservationPage.labelReservedDate,
        field: 'reservedDate',
        render: ({ reservedDate }) => toMoment(reservedDate).format(SIMPLE_DATE_FORMAT),
        sorting: !downloading,
      },
      { title: LocalizedString.carViewingReservationPage.labelReservedTime, field: 'reservedSlot', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.carViewingReservationPage.labelEvent,
        field: RXFIELD_CAR_VIEWING_RESERVATION_EVENT,
        type: FILTER_TYPE_DROPDOWN,
        data: events,
        loading: loadingEvents,
        onChangeFilterText: onChangeEventText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.carViewingReservationPage.labelUser,
        field: 'profile',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.carViewingReservationPage.labelReservedDate,
        field: RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE,
        format: PICKER_DATE_FORMAT,
      },
      {
        title: LocalizedString.carViewingReservationPage.labelReservedTime,
        field: RXFIELD_CAR_VIEWING_RESERVATION_RESERVED_TIME,
        type: FILTER_TYPE_DROPDOWN,
        data: RESERVED_TIMES,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.carViewingReservationPage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CAR_VIEWING_RESERVATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CarViewingReservationPage);

CarViewingReservationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingEvents: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
};
