import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { AccentButton, FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW, RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_EVENT,
  RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_MODEL, RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_DAILY_QUOTA,
  RXSTATE_TEST_DRIVE_EO_TEST_VEHICLES, RXSTATE_TEST_DRIVE_EO_TEST_VEHICLE_PAGE,
  RXFORM_TEST_DRIVE_EO_TEST_VEHICLE,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const renderDialogContent = (initialValues, addingEditing, downloadingDeleting, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_EVENT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.testVehiclePage.placeholderEvent}
          label={LocalizedString.testVehiclePage.placeholderEvent}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_MODEL}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.testVehiclePage.placeholderModel}
          label={LocalizedString.testVehiclePage.placeholderModel}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_DAILY_QUOTA}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.testVehiclePage.placeholderDailyQuota}
          placeholder={LocalizedString.testVehiclePage.placeholderDailyQuota}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          type="number"
          required
        />
      </Grid>
    </Grid>
  </Grid>
);

const TestVehiclePage = ({
  initialValues, events, carModels,
  addingEditing, downloading, downloadingDeleting, loadingCarModels, loadingEvent,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onChangeCarModelText, onChangeEventText, onChangePage, onChangePageSize, onEditPressed, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  pageMode, onViewTestDrive,
}) => (
  <FunctionalPage
    data={RXSTATE_TEST_DRIVE_EO_TEST_VEHICLES}
    uiPage={RXSTATE_TEST_DRIVE_EO_TEST_VEHICLE_PAGE}
    filterColumns={[
      {
        title: LocalizedString.testVehiclePage.labelEvent,
        field: 'event',
        type: FILTER_TYPE_DROPDOWN,
        data: events,
        loading: loadingEvent,
        onChangeFilterText: onChangeEventText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.testVehiclePage.labelModel,
        field: 'modelId',
        type: FILTER_TYPE_DROPDOWN,
        data: carModels,
        loading: loadingCarModels,
        onChangeFilterText: onChangeCarModelText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.testVehiclePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.testVehiclePage.labelEvent, field: 'event.name', sorting: !downloading },
      { title: LocalizedString.testVehiclePage.labelModel, field: 'modelName', sorting: !downloading },
      { title: LocalizedString.testVehiclePage.labelDailyQuota, field: 'dailyQuota', sorting: !downloading },
      {
        title: LocalizedString.testVehiclePage.labelTestDrive,
        field: '',
        sorting: !downloading,
        render: ({ modelName }) => (
          <AccentButton
            caption="View Test Drive"
            variant="outlined"
            onClick={() => onViewTestDrive(
              modelName,
            )}
          />
        ),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.testVehiclePage.title}
    editButtonCaption={LocalizedString.testVehiclePage.buttonCaptionEditTestVehicle}
    disableCreate
    disableDelete
    useFullWidth
  >
    {renderDialogContent(initialValues, addingEditing, downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_TEST_DRIVE_EO_TEST_VEHICLE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TestVehiclePage);

TestVehiclePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  carModels: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingCarModels: PropTypes.bool.isRequired,
  loadingEvent: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeCarModelText: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onViewTestDrive: PropTypes.func.isRequired,
};
