import {
  REST_URL_PROMO_CODES, REST_URL_ADD_EDIT_PROMO_CODE,
  REST_URL_VIEW_DELETE_PROMO_CODE, REST_URL_REDEMPTION_HISTORIES,
  REST_URL_VIEW_REDEMPTION_HISTORY,
  REST_URL_PROMO_CODE_BATCHES,
  REST_URL_VIEW_DELETE_PROMO_CODE_BATCH,
  REST_URL_ADD_EDIT_PROMO_CODE_BATCH,
  REST_URL_CANCEL_PROMO_CODE_BATCH,
  REST_URL_SAVE_FILE, PROMO_CODE_USER_TYPE_OPTIONS,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendDeleteRequest, sendPostRequest, sendPutRequest,
  transformQueryResult, toNumber, sendGetFileRequest,
} from '../../helper';

export * from '../../helper';

export const downloadPromoCodes = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PROMO_CODES.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_PROMO_CODES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadPromoCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PROMO_CODE.replace(/\{id\}/, id),
    token);
  return response;
};

export const deletePromoCode = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_PROMO_CODE.replace(/\{id\}/, id), token);
};

export const addPromoCode = async (
  code,
  maxRedemptionCount,
  pointAmount,
  couponAmount,
  validFrom,
  validUntil,
  merchantTag,
  notificationMessageTitle,
  notificationMessageBody,
  merchantId,
  vouchers,
  rewardType,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  appliedToNewUser,
  appliedToNewUserBlue,
  appliedToNewUserBluePlus,
  appliedToWhiteUser,
  appliedToBlueUser,
  appliedToBluePlusUser,
  minSignUpTime,
  maxSignUpTime,
  token) => {
  const body = {
    code,
    maxRedemptionCount: maxRedemptionCount ? toNumber(maxRedemptionCount) : 0,
    pointAmount: pointAmount ? toNumber(pointAmount) : 0,
    couponAmount: couponAmount ? toNumber(couponAmount) : 0,
    validFrom,
    validUntil,
    merchantTag,
    notificationMessageTitle,
    notificationMessageBody,
    merchantId,
    vouchers,
    rewardType,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    appliedToNewUser,
    appliedToNewUserBlue,
    appliedToNewUserBluePlus,
    appliedToWhiteUser,
    appliedToBlueUser,
    appliedToBluePlusUser,
    minSignUpTime,
    maxSignUpTime,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_PROMO_CODE, body, token);
};

export const editPromoCode = async (id,
  code,
  maxRedemptionCount,
  pointAmount,
  couponAmount,
  validFrom,
  validUntil,
  merchantTag,
  notificationMessageTitle,
  notificationMessageBody,
  merchantId,
  vouchers,
  rewardType,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  appliedToNewUser,
  appliedToNewUserBlue,
  appliedToNewUserBluePlus,
  appliedToWhiteUser,
  appliedToBlueUser,
  appliedToBluePlusUser,
  minSignUpTime,
  maxSignUpTime,
  token) => {
  const body = {
    id,
    code,
    maxRedemptionCount: maxRedemptionCount ? toNumber(maxRedemptionCount) : 0,
    pointAmount: pointAmount ? toNumber(pointAmount) : 0,
    couponAmount: couponAmount ? toNumber(couponAmount) : 0,
    validFrom,
    validUntil,
    merchantTag,
    notificationMessageTitle,
    notificationMessageBody,
    merchantId,
    vouchers,
    rewardType,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    appliedToNewUser,
    appliedToNewUserBlue,
    appliedToNewUserBluePlus,
    appliedToWhiteUser,
    appliedToBlueUser,
    appliedToBluePlusUser,
    minSignUpTime,
    maxSignUpTime,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_PROMO_CODE, body, token);
};

export const downloadRedemptionHistories = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_REDEMPTION_HISTORIES.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_REDEMPTION_HISTORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadRedemptionHistory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_REDEMPTION_HISTORY.replace(/\{id\}/, id),
    token);
  return response;
};

export const downloadPromoCodeBatches = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_PROMO_CODE_BATCHES.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addPromoCodeBatch = async (prefix, quantity, randomCodeLength, validFrom, validUntil,
  rewardType, merchantId, merchantTag, maxRedemptionCount, notificationMessageTitle,
  notificationMessageBody, vouchers, bypassMaxPurchaseValidation, bypassAchievementValidation,
  pointAmount, couponAmount, appliedToNewUser, appliedToWhiteUser, appliedToBlueUser,
  appliedToBluePlusUser, minSignUpTime, maxSignUpTime, appliedToNewUserBlue,
  appliedToNewUserBluePlus, token) => {
  const body = {
    prefix,
    quantity,
    randomCodeLength,
    validFrom,
    validUntil,
    rewardType,
    merchantId,
    merchantTag,
    maxRedemptionCount,
    notificationMessageTitle,
    notificationMessageBody,
    vouchers,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    pointAmount,
    couponAmount,
    appliedToNewUser,
    appliedToWhiteUser,
    appliedToBlueUser,
    appliedToBluePlusUser,
    appliedToNewUserBlue,
    appliedToNewUserBluePlus,
    minSignUpTime,
    maxSignUpTime,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_PROMO_CODE_BATCH, body, token);
};

export const downloadPromoCodeBatch = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PROMO_CODE_BATCH.replace(/\{id\}/, id), token);
  return response;
};

export const cancelPromoCodeBatch = async (id, token) => {
  await sendPutRequest(REST_URL_CANCEL_PROMO_CODE_BATCH.replace(/\{id\}/, id), {}, token);
};

export const transformVouchers = (data) => (data.length > 0 ? data.map((x) => (x.voucherId
  ? {
    label: x.airportId ? `${x.voucherName} (${x.airportName})` : x.voucherName,
    value: x.voucherId,
    garudaFlag: x.garudaFlag,
    merchant: { id: x.merchantId, name: x.merchantName },
    airport: { label: x.airportName, value: x.airportId },
  } : x)) : []);

export const savePromoCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_PROMO_CODES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const getUserType = (state) => {
  let arr = [];

  if (state.appliedToNewUser) {
    arr = [...arr, PROMO_CODE_USER_TYPE_OPTIONS[0]];
  }
  if (state.appliedToNewUserBlue) {
    arr = [...arr, PROMO_CODE_USER_TYPE_OPTIONS[1]];
  }
  if (state.appliedToNewUserBluePlus) {
    arr = [...arr, PROMO_CODE_USER_TYPE_OPTIONS[2]];
  }
  if (state.appliedToWhiteUser) {
    arr = [...arr, PROMO_CODE_USER_TYPE_OPTIONS[3]];
  }
  if (state.appliedToBlueUser) {
    arr = [...arr, PROMO_CODE_USER_TYPE_OPTIONS[4]];
  }
  if (state.appliedToBluePlusUser) {
    arr = [...arr, PROMO_CODE_USER_TYPE_OPTIONS[5]];
  }
  return arr;
};
