import { clearCoupons, setVoidingCoupon } from '../simple-action';
import { addVoidCouponRequest } from '../../../helper';
import downloadCouponsAsync from './downloadCouponsAsync';

export default (values) => async (dispatch, getState) => {
  try {
    dispatch(setVoidingCoupon(true));

    const { token } = getState().authentication;
    const { meta } = getState().coupons;
    const { tappedId } = getState().uiCoupon;

    const reason = values?.reason || '';
    const merchantReferenceId = values?.merchantReferenceId || '';
    const pointId = tappedId || '';
    const sendNotification = values?.sendNotification?.value || false;
    const voidingCoupon = true;
    await addVoidCouponRequest(reason, merchantReferenceId, '', pointId, sendNotification, voidingCoupon, token);
    dispatch(clearCoupons());
    dispatch(downloadCouponsAsync(meta.currentPage));
  } finally {
    dispatch(setVoidingCoupon(false));
  }
};
