import { reset } from 'redux-form';
import { RXFORM_COUPON_VOID_REQUEST } from '../../../constant';
import { addVoidCouponRequest } from '../../../helper';
import { addingVoidCouponRequest, clearVoidCouponRequests, setAlertErrorMessage } from '../simple-action';
import downloadVoidCouponRequestsAsync from './downloadVoidCouponRequestsAsync';

export default (reason, merchantReferenceId, callbackUrl) => async (dispatch, getState) => {
  try {
    dispatch(addingVoidCouponRequest(true));

    const { token } = getState().authentication;
    const { selectedCoupon } = getState().uiCouponVoidRequest;
    const coupon = selectedCoupon.value;

    await addVoidCouponRequest(reason, merchantReferenceId, callbackUrl, coupon, token);

    dispatch(reset(RXFORM_COUPON_VOID_REQUEST));
    dispatch(clearVoidCouponRequests());
    dispatch(downloadVoidCouponRequestsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingVoidCouponRequest(false));
  }
};
