import { reset } from 'redux-form';
import {
  addingWasteDealerCode, clearWasteDealerCodes, setAlertErrorMessage,
} from '../simple-action';
import { addWasteDealerCode } from '../../../helper';
import { RXFORM_WASTE_DEALER_CODE } from '../../../constant';
import downloadWasteDealerCodesAsync from './downloadWasteDealerCodesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingWasteDealerCode(true));

    const { token } = getState().authentication;
    const { tappedDealerId } = getState().uiWasteDealerCode;
    await addWasteDealerCode(tappedDealerId, token);

    dispatch(reset(RXFORM_WASTE_DEALER_CODE));
    dispatch(clearWasteDealerCodes());
    dispatch(downloadWasteDealerCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingWasteDealerCode(false));
  }
};
