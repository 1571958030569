import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { transformInitialValues, transformDropdownData } from '../../helper';
import {
  INITIAL_ORDER_BY_CATALOG, MENUID_CAR_CATALOG_CATALOG, PAGE_MODE_TABLE,
  RXFIELD_CATALOG_DESCRIPTION,
  RXFIELD_CATALOG_ISDEFAULT,
  RXFIELD_CATALOG_MEDIA, RXFORM_CATALOG,
} from '../../constant';
import CatalogPage from './catalog.presentation';
import GlobalLocalizedString from '../../../../localization';
import {
  addEditCarAsync,
  clearCatalog, clearModels, downloadCatalogAsync, downloadDeleteCarAsync,
  downloadModelsAsync, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setCatalogAdvancedFilterDialogSelectedFilterString,
  setCatalogSearchText, setCatalogSelectedAddMediaMenu, setCatalogSelectedIsDefault,
  setCatalogSelectedModelId, setCatalogSelectedOrderBy,
  setCatalogSelectedPageSize,
  setCatalogTappedId,
  setModelAdvancedFilterDialogSelectedFilterString,
  setModelSearchText,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { catalog, uiCatalog, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiCatalog;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? catalog.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      model: { label: found.model.name, value: found.model.id },
      isDefault: found.isDefault
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
    }) : {
      trim: '',
      description: '',
      color: '',
      year: '',
      model: { label: '', value: '' },
      isDefault: null,
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  models: transformDropdownData(state.models.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiCatalog.addingEditing,
  downloading: state.uiCatalog.downloading,
  downloadingDeleting: state.uiCatalog.downloadingDeleting,
  selectedAddMediaMenu: state.uiCatalog.selectedAddMediaMenu,
  loadingModels: state.uiModel.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_CATALOG_CATALOG));
    dispatch(setCatalogSearchText(''));
    dispatch(clearCatalog());
    dispatch(setCatalogSelectedPageSize(20));
    dispatch(setCatalogSelectedOrderBy(INITIAL_ORDER_BY_CATALOG));
    dispatch(downloadCatalogAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_CATALOG, RXFIELD_CATALOG_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setCatalogSelectedAddMediaMenu(menu));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setModelAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setModelSearchText(''));
    dispatch(clearModels());
    dispatch(downloadModelsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('model', 'model.id');
    dispatch(setCatalogAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCatalog());
    dispatch(downloadCatalogAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CATALOG));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCatalogAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCatalogSelectedPageSize(pageSize));
    dispatch(downloadCatalogAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteCarAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setCatalogTappedId(''));
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_CATALOG, RXFIELD_CATALOG_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setCatalogTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setCatalogTappedId(id));
    dispatch(downloadDeleteCarAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setCatalogSelectedPageSize(pageSize));
    dispatch(clearCatalog());
    dispatch(downloadCatalogAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCatalog());
    dispatch(setCatalogAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCatalogSelectedOrderBy(INITIAL_ORDER_BY_CATALOG));
    dispatch(downloadCatalogAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    trim, color, year, description, media, colorCode, referenceId,
  }) => {
    try {
      await dispatch(addEditCarAsync(
        trim, color, year, description, media, colorCode, referenceId,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCatalogSearchText(text));
      dispatch(clearCatalog());
      await dispatch(downloadCatalogAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCatalogSelectedOrderBy(orderBy));
    dispatch(clearCatalog());
    dispatch(downloadCatalogAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    trim, color, year, description, media, colorCode, referenceId,
  }) => {
    await dispatch(addEditCarAsync(
      trim, color, year, description, media, colorCode, referenceId,
    ));
  },
  onViewPressed: (id) => {
    dispatch(setCatalogTappedId(id));
    dispatch(downloadDeleteCarAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onModelOptionSelected: (option) => {
    if (option) {
      dispatch(setCatalogSelectedModelId(option.value));
    } else {
      dispatch(setCatalogSelectedModelId(''));
    }
  },
  onChangeModelText: async (text) => {
    try {
      dispatch(setModelSearchText(text));
      dispatch(clearModels());
      await dispatch(downloadModelsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeContentText: (text) => {
    dispatch(change(RXFORM_CATALOG, RXFIELD_CATALOG_DESCRIPTION, text));
  },
  onDefaultOptionSelected: (option) => {
    if (option) {
      dispatch(setCatalogSelectedIsDefault(option));
      dispatch(change(RXFORM_CATALOG, RXFIELD_CATALOG_ISDEFAULT, option));
    } else {
      dispatch(setCatalogSelectedIsDefault(''));
      dispatch(change(RXFORM_CATALOG, RXFIELD_CATALOG_ISDEFAULT, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPage);
