import { downloadGiiasScannedCode } from '../../../helper';
import { downloadingGiiasScannedCode, setGiiasScannedCode, setFunctionalPageMode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiGiiasScannedCode } = getState();

  const { token } = authentication;
  const { tappedId } = uiGiiasScannedCode;

  try {
    dispatch(downloadingGiiasScannedCode(true));

    const scannedCode = await downloadGiiasScannedCode(
      tappedId,
      token,
    );

    dispatch(setGiiasScannedCode(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingGiiasScannedCode(false));
  }
};
