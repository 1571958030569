import {
  REST_URL_ACCESSORIES, REST_URL_EDIT_ACCESSORY,
  REST_URL_VIEW_ACCESSORY,
  REST_URL_SAVE_FILE,
  REST_URL_SYNC_ACCESSORIES,
  REST_URL_INVOICES, REST_URL_VIEW_INVOICE, REST_URL_SAVE_INVOICE,
  REST_URL_VEHICLE_MODELS,
  REST_URL_ACCESSORY_TRANSACTIONS,
  REST_URL_VIEW_ACCESSORY_TRANSACTION,
  REST_URL_SAVE_INVOICE_CSV,
  BANK_TRANSFER_METHOD_BCA_VA,
  BANK_TRANSFER_METHOD_BNI_VA,
  BANK_TRANSFER_METHOD_MANDIRI_VA,
  BANK_TRANSFER_METHOD_PERMATA_VA,
  GOPAY_TRANSFER_METHOD,
  PAYMENT_METHODS,
  TRANSACTION_STATUS_WAITING_PAYMENT,
  TRANSACTION_STATUS_IN_PROGRESS,
  TRANSACTION_STATUS_CANCELLED,
  TRANSACTION_STATUS_FINISHED,
  TRANSACTION_STATUS_ERROR,
  TRANSACTION_STATUSES,
} from './constant';
import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, sendPostRequest, sendPutRequest,
  transformQueryResult,
} from '../../helper';

export * from '../../helper';

export const downloadAccessories = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_ACCESSORIES.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_ACCESSORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const editAccessory = async (id, variants, token) => {
  const newVariant = variants.map((x) => ({
    id: x.id ? x.id : '',
    image: x.image.content ? x.image.content : '',
    order: x.order,
    description: x.description,
  }));
  const body = {
    id,
    variants: newVariant,
  };
  await sendPutRequest(REST_URL_EDIT_ACCESSORY, body, token);
};

export const downloadAccessory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_ACCESSORY.replace(/\{id\}/, id),
    token);
  return response;
};

export const saveAccessories = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_ACCESSORIES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const syncAccessories = async (token) => {
  await sendPostRequest(REST_URL_SYNC_ACCESSORIES, {}, token);
};

export const downloadVehicleModels = async (token) => {
  const url = REST_URL_VEHICLE_MODELS;
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadAccessoryTransactions = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_ACCESSORY_TRANSACTIONS.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_ACCESSORY_TRANSACTIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadAccessoryTransaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_ACCESSORY_TRANSACTION.replace(/\{id\}/, id),
    token);
  return response;
};

export const saveTransactions = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_ACCESSORY_TRANSACTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadInvoices = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_INVOICES.concat(filterString), pageNumber, pageSize,
      orderBy, searchText)
    : buildQueryParams(REST_URL_INVOICES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadInvoice = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_INVOICE.replace(/\{id\}/, id),
    token);
  return response;
};

export const saveInvoice = async (
  token, id, saveAsCsv, pageNumber, pageSize, orderBy,
  searchText, filterString) => {
  const csvUrl = filterString
    ? buildQueryParams(REST_URL_SAVE_INVOICE_CSV.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_SAVE_INVOICE_CSV, pageNumber, pageSize, orderBy, searchText);
  const url = saveAsCsv ? csvUrl : REST_URL_SAVE_INVOICE.replace(/\{id\}/, id);
  const response = saveAsCsv
    ? await sendGetFileRequest(url, token)
    : await sendGetFileRequest(url);
  return response;
};

export const transformPaymentMethod = (method) => {
  switch (method) {
    case BANK_TRANSFER_METHOD_BCA_VA:
      return PAYMENT_METHODS[0].label;
    case BANK_TRANSFER_METHOD_PERMATA_VA:
      return PAYMENT_METHODS[2].label;
    case BANK_TRANSFER_METHOD_BNI_VA:
      return PAYMENT_METHODS[1].label;
    case BANK_TRANSFER_METHOD_MANDIRI_VA:
      return PAYMENT_METHODS[3].label;
    case GOPAY_TRANSFER_METHOD:
      return PAYMENT_METHODS[4].label;
    default: return '';
  }
};

export const transformTransactionStatus = (status) => {
  switch (status) {
    case TRANSACTION_STATUS_WAITING_PAYMENT:
      return TRANSACTION_STATUSES[0].label;
    case TRANSACTION_STATUS_IN_PROGRESS:
      return TRANSACTION_STATUSES[1].label;
    case TRANSACTION_STATUS_CANCELLED:
    case TRANSACTION_STATUS_ERROR:
      return TRANSACTION_STATUSES[2].label;
    case TRANSACTION_STATUS_FINISHED:
      return TRANSACTION_STATUSES[3].label;
    default: return '';
  }
};
