import {
  downloadCompletedProfiles,
  transformSearchText,
} from '../../../helper';
import {
  downloadingCompletedProfiles,
  setCompletedProfiles,
} from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication,
    completedProfiles,
    uiCompletedProfile,
  } = getState();
  const { token } = authentication;
  const { meta } = completedProfiles;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiCompletedProfile;


  try {
    dispatch(downloadingCompletedProfiles(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadCompletedProfiles(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setCompletedProfiles(list));
    }
  } finally {
    dispatch(downloadingCompletedProfiles(false));
  }
};
