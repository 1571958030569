import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_SERVICE_REQUESTS = 'DOWNLOADING_SERVICE_REQUESTS';
export const downloadingServiceRequests = makeActionCreator(DOWNLOADING_SERVICE_REQUESTS, 'status');

export const SET_SERVICE_REQUESTS = 'SET_SERVICE_REQUESTS';
export const setServiceRequests = makeActionCreator(SET_SERVICE_REQUESTS, 'data');

export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST';
export const addServiceRequest = makeActionCreator(ADD_SERVICE_REQUEST, 'data');

export const CLEAR_SERVICE_REQUESTS = 'CLEAR_SERVICE_REQUESTS';
export const clearServiceRequests = makeActionCreator(CLEAR_SERVICE_REQUESTS);

export const DOWNLOADING_DELETING_SERVICE_REQUEST = 'DOWNLOADING_DELETING_SERVICE_REQUEST';
export const downloadingDeletingServiceRequest = makeActionCreator(DOWNLOADING_DELETING_SERVICE_REQUEST, 'status');


export const SET_SERVICE_REQUEST_SELECTED_PAGE_SIZE = 'SET_SERVICE_REQUEST_SELECTED_PAGE_SIZE';
export const setServiceRequestSelectedPageSize = makeActionCreator(SET_SERVICE_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_SERVICE_REQUEST_SELECTED_ORDER_BY = 'SET_SERVICE_REQUEST_SELECTED_ORDER_BY';
export const setServiceRequestSelectedOrderBy = makeActionCreator(SET_SERVICE_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_SERVICE_REQUEST_TAPPED_ID = 'SET_SERVICE_REQUEST_TAPPED_ID';
export const setServiceRequestTappedId = makeActionCreator(SET_SERVICE_REQUEST_TAPPED_ID, 'id');

export const SET_SERVICE_REQUEST_SEARCH_TEXT = 'SET_SERVICE_REQUEST_SEARCH_TEXT';
export const setServiceRequestSearchText = makeActionCreator(SET_SERVICE_REQUEST_SEARCH_TEXT, 'text');

export const SET_SERVICE_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SERVICE_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setServiceRequestAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SERVICE_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_DEALERS = 'DOWNLOADING_DEALERS';
export const downloadingDealers = makeActionCreator(DOWNLOADING_DEALERS, 'status');

export const SET_DEALERS = 'SET_DEALERS';
export const setDealers = makeActionCreator(SET_DEALERS, 'data');

export const ADD_DEALER = 'ADD_DEALER';
export const addDealer = makeActionCreator(ADD_DEALER, 'data');

export const CLEAR_DEALERS = 'CLEAR_DEALERS';
export const clearDealers = makeActionCreator(CLEAR_DEALERS);

export const DOWNLOADING_DELETING_DEALER = 'DOWNLOADING_DELETING_DEALER';
export const downloadingDeletingDealer = makeActionCreator(DOWNLOADING_DELETING_DEALER, 'status');


export const SET_DEALER_SELECTED_PAGE_SIZE = 'SET_DEALER_SELECTED_PAGE_SIZE';
export const setDealerSelectedPageSize = makeActionCreator(SET_DEALER_SELECTED_PAGE_SIZE, 'size');

export const SET_DEALER_SELECTED_ORDER_BY = 'SET_DEALER_SELECTED_ORDER_BY';
export const setDealerSelectedOrderBy = makeActionCreator(SET_DEALER_SELECTED_ORDER_BY, 'order');

export const SET_DEALER_TAPPED_ID = 'SET_DEALER_TAPPED_ID';
export const setDealerTappedId = makeActionCreator(SET_DEALER_TAPPED_ID, 'id');

export const SET_DEALER_SEARCH_TEXT = 'SET_DEALER_SEARCH_TEXT';
export const setDealerSearchText = makeActionCreator(SET_DEALER_SEARCH_TEXT, 'text');

export const SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setDealerAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_CAR_SERVICE_RESERVATIONS = 'DOWNLOADING_CAR_SERVICE_RESERVATIONS';
export const downloadingCarServiceReservations = makeActionCreator(DOWNLOADING_CAR_SERVICE_RESERVATIONS, 'status');

export const SET_CAR_SERVICE_RESERVATIONS = 'SET_CAR_SERVICE_RESERVATIONS';
export const setCarServiceReservations = makeActionCreator(SET_CAR_SERVICE_RESERVATIONS, 'data');

export const ADD_CAR_SERVICE_RESERVATION = 'ADD_CAR_SERVICE_RESERVATION';
export const addCarServiceReservation = makeActionCreator(ADD_CAR_SERVICE_RESERVATION, 'data');

export const CLEAR_CAR_SERVICE_RESERVATIONS = 'CLEAR_CAR_SERVICE_RESERVATIONS';
export const clearCarServiceReservations = makeActionCreator(CLEAR_CAR_SERVICE_RESERVATIONS);

export const DOWNLOADING_DELETING_CAR_SERVICE_RESERVATION = 'DOWNLOADING_DELETING_CAR_SERVICE_RESERVATION';
export const downloadingDeletingCarServiceReservation = makeActionCreator(DOWNLOADING_DELETING_CAR_SERVICE_RESERVATION, 'status');

export const SAVING_CAR_SERVICE_RESERVATIONS = 'SAVING_CAR_SERVICE_RESERVATIONS';
export const savingCarServiceReservations = makeActionCreator(SAVING_CAR_SERVICE_RESERVATIONS, 'status');


export const SET_CAR_SERVICE_RESERVATION_SELECTED_PAGE_SIZE = 'SET_CAR_SERVICE_RESERVATION_SELECTED_PAGE_SIZE';
export const setCarServiceReservationSelectedPageSize = makeActionCreator(SET_CAR_SERVICE_RESERVATION_SELECTED_PAGE_SIZE, 'size');

export const SET_CAR_SERVICE_RESERVATION_SELECTED_ORDER_BY = 'SET_CAR_SERVICE_RESERVATION_SELECTED_ORDER_BY';
export const setCarServiceReservationSelectedOrderBy = makeActionCreator(SET_CAR_SERVICE_RESERVATION_SELECTED_ORDER_BY, 'order');

export const SET_CAR_SERVICE_RESERVATION_TAPPED_ID = 'SET_CAR_SERVICE_RESERVATION_TAPPED_ID';
export const setCarServiceReservationTappedId = makeActionCreator(SET_CAR_SERVICE_RESERVATION_TAPPED_ID, 'id');

export const SET_CAR_SERVICE_RESERVATION_SEARCH_TEXT = 'SET_CAR_SERVICE_RESERVATION_SEARCH_TEXT';
export const setCarServiceReservationSearchText = makeActionCreator(SET_CAR_SERVICE_RESERVATION_SEARCH_TEXT, 'text');

export const SET_CAR_SERVICE_RESERVATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CAR_SERVICE_RESERVATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCarServiceReservationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CAR_SERVICE_RESERVATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const DOWNLOADING_CAR_SERVICE_RESERVATION_INVOICE = 'DOWNLOADING_CAR_SERVICE_RESERVATION_INVOICE';
export const downloadingCarServiceReservationInvoice = makeActionCreator(DOWNLOADING_CAR_SERVICE_RESERVATION_INVOICE, 'status');

export const DOWNLOADING_CAR_SERVICE_RESERVATION_DEALER_DETAIL = 'DOWNLOADING_CAR_SERVICE_RESERVATION_DEALER_DETAIL';
export const downloadingCarServiceReservationDealerDetail = makeActionCreator(DOWNLOADING_CAR_SERVICE_RESERVATION_DEALER_DETAIL, 'status');

export const SET_CAR_SERVICE_RESERVATION_DEALER_DETAIL = 'SET_CAR_SERVICE_RESERVATION_DEALER_DETAIL';
export const setCarServiceReservationDealerDetail = makeActionCreator(SET_CAR_SERVICE_RESERVATION_DEALER_DETAIL, 'detail');


export const DOWNLOADING_CAR_SERVICE_RESERVATION_SUMMARY = 'DOWNLOADING_CAR_SERVICE_RESERVATION_SUMMARY';
export const downloadingCarServiceReservationSummary = makeActionCreator(DOWNLOADING_CAR_SERVICE_RESERVATION_SUMMARY, 'status');

export const SET_CAR_SERVICE_RESERVATION_SUMMARY = 'SET_CAR_SERVICE_RESERVATION_SUMMARY';
export const setCarServiceReservationSummary = makeActionCreator(SET_CAR_SERVICE_RESERVATION_SUMMARY, 'data');


export const DOWNLOADING_CHARGING_STATIONS = 'DOWNLOADING_CHARGING_STATIONS';
export const downloadingChargingStations = makeActionCreator(DOWNLOADING_CHARGING_STATIONS, 'status');

export const SET_CHARGING_STATIONS = 'SET_CHARGING_STATIONS';
export const setChargingStations = makeActionCreator(SET_CHARGING_STATIONS, 'data');

export const ADD_CHARGING_STATION = 'ADD_CHARGING_STATION';
export const addChargingStation = makeActionCreator(ADD_CHARGING_STATION, 'data');

export const CLEAR_CHARGING_STATIONS = 'CLEAR_CHARGING_STATIONS';
export const clearChargingStations = makeActionCreator(CLEAR_CHARGING_STATIONS);

export const DOWNLOADING_DELETING_CHARGING_STATION = 'DOWNLOADING_DELETING_CHARGING_STATION';
export const downloadingDeletingChargingStation = makeActionCreator(DOWNLOADING_DELETING_CHARGING_STATION, 'status');


export const SET_CHARGING_STATION_SELECTED_PAGE_SIZE = 'SET_CHARGING_STATION_SELECTED_PAGE_SIZE';
export const setChargingStationSelectedPageSize = makeActionCreator(SET_CHARGING_STATION_SELECTED_PAGE_SIZE, 'size');

export const SET_CHARGING_STATION_SELECTED_ORDER_BY = 'SET_CHARGING_STATION_SELECTED_ORDER_BY';
export const setChargingStationSelectedOrderBy = makeActionCreator(SET_CHARGING_STATION_SELECTED_ORDER_BY, 'order');

export const SET_CHARGING_STATION_TAPPED_ID = 'SET_CHARGING_STATION_TAPPED_ID';
export const setChargingStationTappedId = makeActionCreator(SET_CHARGING_STATION_TAPPED_ID, 'id');

export const SET_CHARGING_STATION_SEARCH_TEXT = 'SET_CHARGING_STATION_SEARCH_TEXT';
export const setChargingStationSearchText = makeActionCreator(SET_CHARGING_STATION_SEARCH_TEXT, 'text');

export const SET_CHARGING_STATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CHARGING_STATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setChargingStationAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CHARGING_STATION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_DEALER_PROVINCES = 'DOWNLOADING_DEALER_PROVINCES';
export const downloadingDealerProvinces = makeActionCreator(DOWNLOADING_DEALER_PROVINCES, 'status');

export const SET_DEALER_PROVINCES = 'SET_DEALER_PROVINCES';
export const setDealerProvinces = makeActionCreator(SET_DEALER_PROVINCES, 'data');

export const ADD_DEALER_PROVINCE = 'ADD_DEALER_PROVINCE';
export const addDealerProvince = makeActionCreator(ADD_DEALER_PROVINCE, 'data');

export const CLEAR_DEALER_PROVINCES = 'CLEAR_DEALER_PROVINCES';
export const clearDealerProvinces = makeActionCreator(CLEAR_DEALER_PROVINCES);

export const SET_DEALER_PROVINCE_SELECTED_PAGE_SIZE = 'SET_DEALER_PROVINCE_SELECTED_PAGE_SIZE';
export const setDealerProvinceSelectedPageSize = makeActionCreator(SET_DEALER_PROVINCE_SELECTED_PAGE_SIZE, 'size');

export const SET_DEALER_PROVINCE_SELECTED_ORDER_BY = 'SET_DEALER_PROVINCE_SELECTED_ORDER_BY';
export const setDealerProvinceSelectedOrderBy = makeActionCreator(SET_DEALER_PROVINCE_SELECTED_ORDER_BY, 'order');

export const DOWNLOADING_DEALER_CITIES = 'DOWNLOADING_DEALER_CITIES';
export const downloadingDealerCities = makeActionCreator(DOWNLOADING_DEALER_CITIES, 'status');
export const SET_DEALER_CITIES = 'SET_DEALER_CITIES';
export const setDealerCities = makeActionCreator(SET_DEALER_CITIES, 'data');

export const ADD_DEALER_CITY = 'ADD_DEALER_CITY';
export const addDealerCity = makeActionCreator(ADD_DEALER_CITY, 'data');

export const CLEAR_DEALER_CITIES = 'CLEAR_DEALER_CITIES';
export const clearDealerCities = makeActionCreator(CLEAR_DEALER_CITIES);

export const SET_DEALER_CITY_SELECTED_PAGE_SIZE = 'SET_DEALER_CITY_SELECTED_PAGE_SIZE';
export const setDealerCitySelectedPageSize = makeActionCreator(SET_DEALER_CITY_SELECTED_PAGE_SIZE, 'size');

export const SET_DEALER_CITY_SELECTED_ORDER_BY = 'SET_DEALER_CITY_SELECTED_ORDER_BY';
export const setDealerCitySelectedOrderBy = makeActionCreator(SET_DEALER_CITY_SELECTED_ORDER_BY, 'order');


export const DOWNLOADING_CHARGING_STATION_CITIES = 'DOWNLOADING_CHARGING_STATION_CITIES';
export const downloadingChargingStationCities = makeActionCreator(DOWNLOADING_CHARGING_STATION_CITIES, 'status');

export const SET_CHARGING_STATION_CITIES = 'SET_CHARGING_STATION_CITIES';
export const setChargingStationCities = makeActionCreator(SET_CHARGING_STATION_CITIES, 'data');

export const CLEAR_CHARGING_STATION_CITIES = 'CLEAR_CHARGING_STATION_CITIES';
export const clearChargingStationCities = makeActionCreator(CLEAR_CHARGING_STATION_CITIES);


export const DOWNLOADING_CHARGING_STATION_PROVINCES = 'DOWNLOADING_CHARGING_STATION_PROVINCES';
export const downloadingChargingStationProvinces = makeActionCreator(DOWNLOADING_CHARGING_STATION_PROVINCES, 'status');

export const SET_CHARGING_STATION_PROVINCES = 'SET_CHARGING_STATION_PROVINCES';
export const setChargingStationProvinces = makeActionCreator(SET_CHARGING_STATION_PROVINCES, 'data');

export const CLEAR_CHARGING_STATION_PROVINCES = 'CLEAR_CHARGING_STATION_PROVINCES';
export const clearChargingStationProvinces = makeActionCreator(CLEAR_CHARGING_STATION_PROVINCES);


export const DOWNLOADING_EV_CHARGING_URLS = 'DOWNLOADING_EV_CHARGING_URLS';
export const downloadingEvChargingUrls = makeActionCreator(DOWNLOADING_EV_CHARGING_URLS, 'status');

export const DOWNLOADING_DELETING_EV_CHARGING_URL = 'DOWNLOADING_DELETING_EV_CHARGING_URL';
export const downloadingDeletingEvChargingUrl = makeActionCreator(DOWNLOADING_DELETING_EV_CHARGING_URL, 'status');

export const SET_EV_CHARGING_URLS = 'SET_EV_CHARGING_URLS';
export const setEvChargingUrls = makeActionCreator(SET_EV_CHARGING_URLS, 'data');

export const ADD_EV_CHARGING_URL = 'ADD_EV_CHARGING_URL';
export const addEvChargingUrl = makeActionCreator(ADD_EV_CHARGING_URL, 'data');

export const CLEAR_EV_CHARGING_URLS = 'CLEAR_EV_CHARGING_URLS';
export const clearEvChargingUrls = makeActionCreator(CLEAR_EV_CHARGING_URLS);

export const ADDING_EDITING_EV_CHARGING_URL = 'ADDING_EDITING_EV_CHARGING_URL';
export const addingEditingEvChargingUrl = makeActionCreator(ADDING_EDITING_EV_CHARGING_URL, 'status');

export const SET_EV_CHARGING_URL_SELECTED_PAGE_SIZE = 'SET_EV_CHARGING_URL_SELECTED_PAGE_SIZE';
export const setEvChargingUrlSelectedPageSize = makeActionCreator(SET_EV_CHARGING_URL_SELECTED_PAGE_SIZE, 'size');

export const SET_EV_CHARGING_URL_TAPPED_ID = 'SET_EV_CHARGING_URL_TAPPED_ID';
export const setEvChargingUrlTappedId = makeActionCreator(SET_EV_CHARGING_URL_TAPPED_ID, 'id');

export const SET_EV_CHARGING_URL_SEARCH_TEXT = 'SET_EV_CHARGING_URL_SEARCH_TEXT';
export const setEvChargingUrlSearchText = makeActionCreator(SET_EV_CHARGING_URL_SEARCH_TEXT, 'text');

export const SET_EV_CHARGING_URL_SELECTED_ORDER_BY = 'SET_EV_CHARGING_URL_SELECTED_ORDER_BY';
export const setEvChargingUrlSelectedOrderBy = makeActionCreator(SET_EV_CHARGING_URL_SELECTED_ORDER_BY, 'order');

export const SET_EV_CHARGING_URL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_EV_CHARGING_URL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setEvChargingUrlAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_EV_CHARGING_URL_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
