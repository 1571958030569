import { downloadTestDriveEoEvents, transformSearchText } from '../../../helper';
import { downloadingTestDriveEoEvents, setTestDriveEoEvents } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, testDriveEoEvents, uiTestDriveEoEvent } = getState();
  const { token } = authentication;
  const { meta } = testDriveEoEvents;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiTestDriveEoEvent;

  try {
    dispatch(downloadingTestDriveEoEvents(true));
    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadTestDriveEoEvents(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setTestDriveEoEvents(list));
    }
  } finally {
    dispatch(downloadingTestDriveEoEvents(false));
  }
};
