import {
  addDeviceException, clearDeviceExceptions, downloadingDeletingDeviceException,
  setAlertErrorMessage, setFunctionalPageMode, setDeviceExceptionTappedId,
} from '../simple-action';
import { downloadDeviceException, deleteDeviceException } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadDeviceExceptionsAsync from './downloadDeviceExceptionsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingDeviceException(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiDeviceException;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteDeviceException(tappedId, token);
      dispatch(setDeviceExceptionTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearDeviceExceptions());
      dispatch(downloadDeviceExceptionsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const deviceException = await downloadDeviceException(tappedId, token);
      dispatch(addDeviceException(deviceException));
    }
  } finally {
    dispatch(downloadingDeletingDeviceException(false));
  }
};
