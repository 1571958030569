import { downloadGiiasSemarangPrizeRedemption } from '../../../helper';
import { addGiiasSemarangPrizeRedemption, downloadingGiiasSemarangPrizeRedemption } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingGiiasSemarangPrizeRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiiasSemarangPrizeRedemption;

    const giiasSemarangPrizeRedemption = await
    downloadGiiasSemarangPrizeRedemption(tappedId, token);
    dispatch(addGiiasSemarangPrizeRedemption(giiasSemarangPrizeRedemption));
  } finally {
    dispatch(downloadingGiiasSemarangPrizeRedemption(false));
  }
};
