import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { downloadReferrersAsync } from '../../../../redux/action';
import { clearReferrers, downloadUsersAsync, setReferrerSearchText } from '../../../voucher';
import {
  PAGE_MODE_TABLE,
  MENUID_GAME_ACTIVITY_REGISTRATION_REFERRAL,
  INITIAL_ORDER_BY_GAME_ACTIVITY_REGISTRATION_REFERRALS,
  RXFORM_GAME_ACTIVITY_REGISTRATION_REFERRAL,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData, toCurrency, debounceSearch,
} from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage,
  setGameActivityRegistrationReferralSearchText, clearGameActivityRegistrationReferrals,
  setGameActivityRegistrationReferralSelectedPageSize,
  setGameActivityRegistrationReferralSelectedOrderBy,
  setGameActivityRegistrationReferralAdvancedFilterDialogSelectedFilterString,
  setGameActivityRegistrationReferralTappedId,
  downloadGameActivityRegistrationReferralsAsync, downloadGameActivityRegistrationReferralAsync,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  saveGameActivityRegistrationReferralsAsync,
} from '../../redux/action';
import GameActivityRegistrationReferralPage from './registration-referral.presentation';

const getInitialValues = (state) => {
  const {
    gameActivityRegistrationReferrals, uiFunctionalPage, uiGameActivityRegistrationReferral,
  } = state;
  const { data } = gameActivityRegistrationReferrals;
  const { downloadingDeleting, tappedId } = uiGameActivityRegistrationReferral;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      registeredUserId: found.referee.id ?? '',
      registeredUserName: found.referee.fullName ?? '',
      referrerUserId: found.referrer.id,
      referrerUserName: found.referrer.fullName,
      pointAmount: toCurrency(found.pointAmount),
    }) : {
      registeredUserId: '',
      registeredUserName: '',
      pointAmount: '',
      referrerUserId: '',
      referrerUserName: '',
    };
  return result;
};

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchReferrerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearReferrers());
    dispatch(downloadReferrersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapStateToProps = (state) => ({
  downloading: state.uiGameActivityRegistrationReferral.downloading,
  downloadingDeleting: state.uiGameActivityRegistrationReferral.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  referrers: transformUserDropdownData(state.referrers.data),
  loadingUsers: state.uiUser.downloading,
  loadingReferrers: state.uiUser.downloadingReferrers,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GAME_ACTIVITY_REGISTRATION_REFERRAL));
    dispatch(setGameActivityRegistrationReferralSearchText(''));
    dispatch(clearGameActivityRegistrationReferrals());
    dispatch(setGameActivityRegistrationReferralSelectedPageSize(20));
    dispatch(setGameActivityRegistrationReferralSelectedOrderBy(
      INITIAL_ORDER_BY_GAME_ACTIVITY_REGISTRATION_REFERRALS,
    ));
    dispatch(downloadGameActivityRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setReferrerSearchText(''));
    dispatch(clearUsers());
    dispatch(clearReferrers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadReferrersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('referrer', 'referrer.id')
      .replace('registeredUser', 'referee.id');

    dispatch(setGameActivityRegistrationReferralAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearGameActivityRegistrationReferrals());
    dispatch(downloadGameActivityRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadGameActivityRegistrationReferralsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setGameActivityRegistrationReferralSelectedPageSize(pageSize));
    dispatch(downloadGameActivityRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveGameActivityRegistrationReferralsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setGameActivityRegistrationReferralSelectedPageSize(pageSize));
    dispatch(clearGameActivityRegistrationReferrals());
    dispatch(downloadGameActivityRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearGameActivityRegistrationReferrals());
    dispatch(setGameActivityRegistrationReferralAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setGameActivityRegistrationReferralSelectedOrderBy(
      INITIAL_ORDER_BY_GAME_ACTIVITY_REGISTRATION_REFERRALS,
    ));
    dispatch(downloadGameActivityRegistrationReferralsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setGameActivityRegistrationReferralSearchText(text));
      dispatch(clearGameActivityRegistrationReferrals());
      await dispatch(downloadGameActivityRegistrationReferralsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setGameActivityRegistrationReferralSelectedOrderBy(orderBy));
    dispatch(clearGameActivityRegistrationReferrals());
    dispatch(downloadGameActivityRegistrationReferralsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setGameActivityRegistrationReferralTappedId(id));
    dispatch(downloadGameActivityRegistrationReferralAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_GAME_ACTIVITY_REGISTRATION_REFERRAL));
  },
  onChangeUserText: async (text) => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onChangeReferrerText: async (text) => {
    dispatch(setReferrerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchReferrerDebounce(dispatch);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GameActivityRegistrationReferralPage);
