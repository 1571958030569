import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    iims2023: {
      title: 'IIMS 2023',
    },
    scannedCodePage: {
      title: 'Scanned Code',

      placeholderScanTime: 'Scan Time',
      placeholderUsername: 'User Name',
      placeholderChallangeTitle: 'Challenge Title',
      placeholderChallangeCodeLabel: 'Challenge Code Label',
      placeholderChallangeCode: 'Challenge Code',
      placeholderChallangeCodeId: 'Challenge Code ID',
      placeholderChallangeUsername: 'Username',
      placeholderChallangeUserId: 'User ID',
      placeholderChallangeScanTime: 'Scan Time',
      placeholderChallangeRemark: 'Remark',

      labelNo: 'No',
      labelUser: 'User',
      labelActivity: 'Activity',
      labelScanTime: 'Scan Time',
    },
    prizeRedemptionPage: {
      title: 'Prize Redemption',

      labelNo: 'No',
      labelUser: 'User',
      labelUserId: 'User ID',
      labelUserFullname: 'Full Name',
      labelVoucher: 'Voucher',
      labelVoucherId: 'Voucher ID',
      labelVoucherTitle: 'Voucher Title',
      labelPoint: 'Point',
      labelPrizeTag: 'Prize Tag',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',

      placeholderUserId: 'User ID',
      placeholderUserFullname: 'Full Name',
      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherTitle: 'Voucher Title',
      placeholderPoint: 'Point',
      placeholderPrizeTag: 'Prize Tag',
      placeholderDescription: 'Description',
    },
    transactionPage: {
      title: 'Transaction',

      labelNo: 'No',
      labelUser: 'User',
      labelUserId: 'User ID',
      labelUserFullname: 'Full Name',
      labelType: 'Type',
      labelAmount: 'Amount',
      labelRemainingBalance: 'Remaining Balance',
      labelLabel: 'Label',
      labelReferenceId: 'Reference ID',
      labelCreatedDate: 'Created Date',

      placeholderUserId: 'User ID',
      placeholderUserFullname: 'Full Name',
      placeholderType: 'Type',
      placeholderAmount: 'Amount',
      placeholderRemainingBalance: 'Remaining Balance',
      placeholderLabel: 'Label',
      placeholderReferenceId: 'Reference ID',
    },
    surveyPage: {
      title: 'Survey',

      placeholderScanTime: 'Scan Time',
      placeholderUsername: 'User Name',
      placeholderChallangeTitle: 'Challenge Title',
      placeholderChallangeCodeLabel: 'Challenge Code Label',
      placeholderChallangeCode: 'Challenge Code',
      placeholderChallangeCodeId: 'Challenge Code ID',
      placeholderChallangeUsername: 'Username',
      placeholderChallangeUserId: 'User ID',
      placeholderChallangeScanTime: 'Scan Time',
      placeholderChallangeRemark: 'Remark',

      labelNo: 'No',
      labelUser: 'User',
      labelActivity: 'Activity',
      labelQuestion: 'Question',
      labelResponse: 'Response',
      firstName: 'First Name',
      lastName: 'Last Name',
      testVehicleModel: 'Test Vehicle Model',
      salesConsultantName: 'Sales Consultant Name',
      labelDate: 'Date',
      labelScanTime: 'Scan Time',
    },
    spkPage: {
      title: 'SPK',
      placeholderScanTime: 'Scan Time',
      placeholderUsername: 'User Name',
      placeholderChallangeTitle: 'Challenge Title',
      placeholderChallangeCodeLabel: 'Challenge Code Label',
      placeholderChallangeCode: 'Challenge Code',
      placeholderChallangeCodeId: 'Challenge Code ID',
      placeholderChallangeUsername: 'Username',
      placeholderChallangeUserId: 'User ID',
      placeholderChallangeScanTime: 'Scan Time',
      placeholderChallangeRemark: 'Remark',
      labelNo: 'No',
      labelUser: 'User',
      labelActivity: 'Activity',
      voucherTitle: 'Voucher',
      salesId: 'Sales Id',
      salesName: 'Sales Name',
      labelDate: 'Scan Time',
    },
  },
  id: {
    iims2023: {
      title: 'IIMS 2023',
    },
    scannedCodePage: {
      title: 'Kode Dipindai',

      placeholderScanTime: 'Waktu Pemindaian',
      placeholderUsername: 'Nama Pengguna',
      placeholderChallangeTitle: 'Judul Tantangan',
      placeholderChallangeCodeLabel: 'Nama Tantangan',
      placeholderChallangeCode: 'Kode Tantangan',
      placeholderChallangeCodeId: 'ID Kode Tantangan',
      placeholderChallangeUsername: 'Nama Pengguna',
      placeholderChallangeUserId: 'ID User',
      placeholderChallangeScanTime: 'Waktu Pemindaian',
      placeholderChallangeRemark: 'Komentar',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelActivity: 'Aktivitas',
      labelQuestion: 'Pertanyaan',
      labelResponse: 'Jawaban',
      firstName: 'Nama Depan',
      lastName: 'Name Belakang',
      testVehicleModel: 'Tipe Mobil',
      salesConsultantName: 'Sales Consultant Name',
      labelDate: 'Tanggal',
      labelScanTime: 'Waktu Memindai',
    },
    prizeRedemptionPage: {
      title: 'Penukaran Hadiah',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelUserId: 'ID Pengguna',
      labelUserFullname: 'Nama Lengkap',
      labelVoucher: 'Voucher',
      labelVoucherId: 'ID Voucher',
      labelVoucherTitle: 'Judul Voucher',
      labelPoint: 'Poin',
      labelPrizeTag: 'Label Hadiah',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDescription: 'Description',

      placeholderUserId: 'ID Pengguna',
      placeholderUserFullname: 'Nama Lengkap',
      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherTitle: 'Judul Voucher',
      placeholderPoint: 'Point',
      placeholderPrizeTag: 'Label Hadiah',
      placeholderDescription: 'Deskripsi',
    },
    transactionPage: {
      title: 'Transaksi',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelUserId: 'ID Pengguna',
      labelUserFullname: 'Nama Lengkap',
      labelType: 'Tipe',
      labelAmount: 'Jumlah',
      labelRemainingBalance: 'Sisa Saldo',
      labelLabel: 'Label',
      labelReferenceId: 'ID Referensi',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUserId: 'ID Pengguna',
      placeholderUserFullname: 'Nama Lengkap',
      placeholderType: 'Tipe',
      placeholderAmount: 'Jumlah',
      placeholderRemainingBalance: 'Sisa Saldo',
      placeholderLabel: 'Label',
      placeholderReferenceId: 'ID Referensi',
    },
    surveyPage: {
      title: 'Survey',

      placeholderScanTime: 'Scan Time',
      placeholderUsername: 'User Name',
      placeholderChallangeTitle: 'Challenge Title',
      placeholderChallangeCodeLabel: 'Challenge Code Label',
      placeholderChallangeCode: 'Challenge Code',
      placeholderChallangeCodeId: 'Challenge Code ID',
      placeholderChallangeUsername: 'Username',
      placeholderChallangeUserId: 'User ID',
      placeholderChallangeScanTime: 'Scan Time',
      placeholderChallangeRemark: 'Remark',

      labelNo: 'No',
      labelUser: 'User',
      labelActivity: 'Activity',
      labelScanTime: 'Scan Time',
    },
    spkPage: {
      title: 'SPK',

      placeholderScanTime: 'Scan Time',
      placeholderUsername: 'User Name',
      placeholderChallangeTitle: 'Challenge Title',
      placeholderChallangeCodeLabel: 'Challenge Code Label',
      placeholderChallangeCode: 'Challenge Code',
      placeholderChallangeCodeId: 'Challenge Code ID',
      placeholderChallangeUsername: 'Username',
      placeholderChallangeUserId: 'User ID',
      placeholderChallangeScanTime: 'Scan Time',
      placeholderChallangeRemark: 'Remark',

      labelNo: 'No',
      labelUser: 'User',
      labelActivity: 'Activity',
      voucherTitle: 'Voucher',
      salesId: 'Sales Id',
      salesName: 'Sales Name',
      labelDate: 'Waktu Memindai',
    },
  },
});

export default LocalizedString;
