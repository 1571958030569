import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CircularProgress, Grid, makeStyles, Typography, Divider,
} from '@material-ui/core';
import { Field } from 'redux-form';
import { LaborPartAccessoriesShape, SimpleDataShape } from '../../type';
import { renderReduxFormEditableTableField } from '../../../../redux-form-rendererer';
import { toCurrency } from '../../helper';
import {
  COLOR_PRIMARY, RXFIELD_CAR_SERVICE_RESERVATION_LABORS,
  RXFIELD_CAR_SERVICE_RESERVATION_PARTS, RXFIELD_CAR_SERVICE_RESERVATION_ACCESSORIES,
  COLOR_BODY_TEXT_LIGHTER, COLOR_DISABLED_ROW, RXFIELD_CAR_SERVICE_RESERVATION_ADDITIONAL,
} from '../../constant';
import LocalizedString from '../../localization';

const getInitialTableValues = (arr) => arr?.map((item, index) => ({
  ...item,
  no: index + 1,
  price: `Rp.${toCurrency(item.price || 0)}`,
}));

const getTotalTime = (durationInHours) => {
  const totalMinutes = durationInHours * 60;
  const hours = Math.floor(durationInHours);
  const minutes = totalMinutes % 60;
  return minutes ? `${hours} ${LocalizedString.reservationPage.labelHours} ${minutes} ${LocalizedString.reservationPage.labelMinutes}` : `${hours} ${LocalizedString.reservationPage.labelHours}`;
};

const getPaymentData = (summary) => {
  const {
    totalTimeHours, subTotal, tax,
    deliveryCharge, grandTotal,
  } = summary;

  return [
    {
      label: LocalizedString.reservationPage.labelSubTotal,
      value: `Rp.${toCurrency(subTotal || 0)}`,
    },
    {
      label: LocalizedString.reservationPage.labelPickupCharge,
      value: `Rp.${toCurrency(deliveryCharge || 0)}`,
    },
    {
      label: LocalizedString.reservationPage.labelDeliveryCharge,
      value: `Rp.${toCurrency(deliveryCharge || 0)}`,
    },
    {
      label: LocalizedString.reservationPage.labelDiscount,
      value: `Rp.${toCurrency(0)}`, // unavailable field
    },
    {
      label: LocalizedString.reservationPage.labelTax,
      value: `Rp.${toCurrency(tax || 0)}`,
    },
    {
      label: LocalizedString.reservationPage.labelGrandTotal,
      value: `Rp.${toCurrency(grandTotal || 0)}`,
    },
    {
      label: LocalizedString.reservationPage.labelTotalTime,
      value: getTotalTime(totalTimeHours),
    },
  ];
};

const mapStateToProps = (state) => ({
  reservationSummary: state.uiCarServiceReservation.reservationSummary,
  downloadingSummary: state.uiCarServiceReservation.downloadingSummary,
  laborsInitialValues: getInitialTableValues(
    state.uiCarServiceReservation.reservationSummary?.labors,
  ),
  partsInitialValues: getInitialTableValues(
    state.uiCarServiceReservation.reservationSummary?.parts,
  ),
  accessoriesInitialValues: getInitialTableValues(
    state.uiCarServiceReservation.reservationSummary?.accessories,
  ),
  additionalInitialValues: getInitialTableValues(
    state.uiCarServiceReservation.reservationSummary?.additional,
  ),
  paymentData: getPaymentData(state.uiCarServiceReservation.reservationSummary),
});

const mapDispatchToProps = () => ({

});

const useStyles = makeStyles(() => ({
  activityIndicator: {
    display: 'flex',
    justifyContent: 'center',
    margin: '11px 0px 11px 0px',
  },
  tableContainer: {
    position: 'relative',
    paddingTop: 0,
    marginTop: -8,
  },
  subHeading: {
    padding: '0px 0px 0px 8px',
  },
  divider: {
    height: 2,
    margin: '16px 0px 24px 0px',
    background: COLOR_PRIMARY,
  },
  spacer: {
    margin: '24px 0px 24px 0px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftPaymentColumn: {
    display: 'flex',
    padding: '8px 16px 8px 0px',
    border: `1px solid ${COLOR_BODY_TEXT_LIGHTER}`,
    backgroundColor: COLOR_DISABLED_ROW,
    justifyContent: 'flex-end',
    width: 'calc(40px + 60%)',
  },
  rightPaymentColumn: {
    display: 'flex',
    flex: '1',
    padding: '8px 0px 8px 16px',
    border: `1px solid ${COLOR_BODY_TEXT_LIGHTER}`,
  },
}));

const Footer = ({
  laborsInitialValues, partsInitialValues, accessoriesInitialValues, additionalInitialValues,
  paymentData, downloadingSummary,
}) => {
  const classes = useStyles();

  if (downloadingSummary) {
    return (
      <div className={classes.activityIndicator}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <div>
      <Divider className={classes.divider} />
      <Typography variant="subtitle2" className={classes.subHeading}>
        {LocalizedString.reservationPage.labelLaborDetail}
      </Typography>
      <Grid item sm={12} className={classes.tableContainer}>
        <Field
          name={RXFIELD_CAR_SERVICE_RESERVATION_LABORS}
          component={renderReduxFormEditableTableField}
          disabled
          disableToolbar
          hidden={false}
          loading={downloadingSummary}
          currentPage={1}
          totalCount={1}
          defaultValue={laborsInitialValues}
          tableColumns={[
            {
              title: LocalizedString.reservationPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            {
              title: LocalizedString.reservationPage.labelLabors, field: 'name', sorting: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelHours, field: 'quantity', quantity: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelPrice, field: 'price', sorting: false,
            },
          ]}
        />
      </Grid>
      <div className={classes.spacer} />
      <Typography variant="subtitle2" className={classes.subHeading}>
        {LocalizedString.reservationPage.labelPartDetail}
      </Typography>
      <Grid item sm={12} className={classes.tableContainer}>
        <Field
          name={RXFIELD_CAR_SERVICE_RESERVATION_PARTS}
          component={renderReduxFormEditableTableField}
          disabled
          disableToolbar
          hidden={false}
          loading={downloadingSummary}
          currentPage={1}
          totalCount={1}
          defaultValue={partsInitialValues}
          tableColumns={[
            {
              title: LocalizedString.reservationPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            {
              title: LocalizedString.reservationPage.labelParts, field: 'name', sorting: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelQuantity, field: 'quantity', quantity: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelPrice, field: 'price', sorting: false,
            },
          ]}
        />
      </Grid>
      <div className={classes.spacer} />
      <Typography variant="subtitle2" className={classes.subHeading}>
        {LocalizedString.reservationPage.labelAccessoriesDetail}
      </Typography>
      <Grid item sm={12} className={classes.tableContainer}>
        <Field
          name={RXFIELD_CAR_SERVICE_RESERVATION_ACCESSORIES}
          component={renderReduxFormEditableTableField}
          disabled
          disableToolbar
          hidden={false}
          loading={downloadingSummary}
          currentPage={1}
          totalCount={1}
          defaultValue={accessoriesInitialValues}
          tableColumns={[
            {
              title: LocalizedString.reservationPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            {
              title: LocalizedString.reservationPage.labelAccessories, field: 'name', sorting: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelQuantity, field: 'quantity', quantity: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelPrice, field: 'price', sorting: false,
            },
          ]}
        />
      </Grid>
      <Typography variant="subtitle2" className={classes.subHeading}>
        {LocalizedString.reservationPage.labelAdditionalDetail}
      </Typography>
      <Grid item sm={12} className={classes.tableContainer}>
        <Field
          name={RXFIELD_CAR_SERVICE_RESERVATION_ADDITIONAL}
          component={renderReduxFormEditableTableField}
          disabled
          disableToolbar
          hidden={false}
          loading={downloadingSummary}
          currentPage={1}
          totalCount={1}
          defaultValue={additionalInitialValues}
          tableColumns={[
            {
              title: LocalizedString.reservationPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            {
              title: LocalizedString.reservationPage.labelAdditional, field: 'name', sorting: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelQuantity, field: 'quantity', quantity: false, width: '30%',
            },
            {
              title: LocalizedString.reservationPage.labelPrice, field: 'price', sorting: false,
            },
          ]}
        />
      </Grid>
      <Grid item sm={12}>
        <div className={classes.spacer} />
        {paymentData.map((item) => (
          <div className={classes.row} key={item.label}>
            <div className={classes.leftPaymentColumn}>
              <Typography variant="body1">{item.label}</Typography>
            </div>
            <div className={classes.rightPaymentColumn}>
              <Typography variant="body1">{item.value}</Typography>
            </div>
          </div>
        ))}
      </Grid>

    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

Footer.propTypes = {
  accessoriesInitialValues: PropTypes.arrayOf(LaborPartAccessoriesShape),
  additionalInitialValues: PropTypes.arrayOf(LaborPartAccessoriesShape),
  laborsInitialValues: PropTypes.arrayOf(LaborPartAccessoriesShape),
  partsInitialValues: PropTypes.arrayOf(LaborPartAccessoriesShape),
  paymentData: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloadingSummary: PropTypes.bool,
};

Footer.defaultProps = {
  accessoriesInitialValues: [],
  additionalInitialValues: [],
  laborsInitialValues: [],
  partsInitialValues: [],
  downloadingSummary: false,
};
