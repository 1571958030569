import { SET_BOOKING_CREATED_BY_DELIVERY_METHOD, CLEAR_BOOKING_CREATED_BY_DELIVERY_METHOD } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_BOOKING_CREATED_BY_DELIVERY_METHOD: {
      return action.data;
    }
    case CLEAR_BOOKING_CREATED_BY_DELIVERY_METHOD:
      return state;
    default:
      return state;
  }
};
