import { downloadingDealerCities, setDealerCities } from '../simple-action';
import { downloadDealerCities } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingDealerCities(true));

    const list = await downloadDealerCities(token);
    if (list) {
      dispatch(setDealerCities(list));
    }
  } finally {
    dispatch(downloadingDealerCities(false));
  }
};
