import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS, REST_URL_HYUNDAI_CHALLENGE_SCANNED_CODES,
  REST_URL_HYUNDAI_CHALLENGE_TRANSACTIONS, REST_URL_HYUNDAI_CHALLENGE_VIEW_DELETE_SCANNED_CODE,
  REST_URL_SAVE_FILE, REST_URL_VIEW_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION,
  REST_URL_VIEW_HYUNDAI_CHALLENGE_TRANSACTION,
} from './constant';


export * from '../../helper';

export const downloadHyundaiChallengeScannedCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_HYUNDAI_CHALLENGE_SCANNED_CODES
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_HYUNDAI_CHALLENGE_SCANNED_CODES, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadHyundaiChallengeScannedCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_HYUNDAI_CHALLENGE_VIEW_DELETE_SCANNED_CODE
    .replace(/\{id\}/, id), token);
  return response;
};

export const saveHyundaiChallengeScannedCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_HYUNDAI_CHALLENGE_SCANNED_CODES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadHyundaiChallengePrizeRedemptions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadHyundaiChallengePrizeRedemption = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_HYUNDAI_CHALLENGE_PRIZE_REDEMPTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveHyundaiChallengePrizeRedemptions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_HYUNDAI_CHALLENGE_PRIZE_REDEMPTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadHyundaiChallengeTransactions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_HYUNDAI_CHALLENGE_TRANSACTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadHyundaiChallengeTransaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_HYUNDAI_CHALLENGE_TRANSACTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveHyundaiChallengeTransactions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_HYUNDAI_CHALLENGE_TRANSACTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};
