import { SET_BOOKING_CANCELED, CLEAR_BOOKING_CANCELED } from '../action';

const initialState = {
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKING_CANCELED: {
      return {
        data: action.count,
      };
    }
    case CLEAR_BOOKING_CANCELED:
      return initialState;
    default:
      return state;
  }
};
