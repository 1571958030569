import { downloadCarViewingVoucherReward } from '../../../helper';
import { addCarViewingVoucherReward, downloadingCarViewingVoucherReward } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCarViewingVoucherReward(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCarViewingVoucherReward;

    const CarViewingVoucherReward = await downloadCarViewingVoucherReward(tappedId, token);
    dispatch(addCarViewingVoucherReward(CarViewingVoucherReward));
  } finally {
    dispatch(downloadingCarViewingVoucherReward(false));
  }
};
