import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, InputAdornment, IconButton,
  Snackbar,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FunctionalPage } from '../../component';
import {
  approval, DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_TEST_DRIVE_CITY, RXFIELD_TEST_DRIVE_PROVINCE, RXFIELD_TEST_DRIVE_EVENT_NAME,
  RXFIELD_TEST_DRIVE_EVENT_START_TIME, RXFIELD_TEST_DRIVE_EVENT_END_TIME,
  RXFIELD_TEST_DRIVE_EVENT_URL1, RXFIELD_TEST_DRIVE_EVENT_URL1_EXTERNAL_BROWSER,
  RXFIELD_TEST_DRIVE_EVENT_DESCRIPTION, RXFIELD_TEST_DRIVE_EVENT_LOCATION, RXFORM_EVENT,
  RXSTATE_EVENTS, RXSTATE_EVENT_PAGE,
  RXFIELD_TEST_DRIVE_EVENT_VISIBILITY, RXFIELD_TEST_DRIVE_EVENT_URL2,
  RXFIELD_TEST_DRIVE_EVENT_URL2_EXTERNAL_BROWSER,
  visibilityStatuses,
  RXFIELD_TEST_DRIVE_EVENT_VOUCHER, RXFIELD_TEST_DRIVE_EVENT_PROVINCE,
  RXFIELD_TEST_DRIVE_EVENT_CITY, RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL,
  RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL_EXTERNAL_BROWSER,
  RXFIELD_TEST_DRIVE_EVENT_EVENT_CODE, COLOR_PRIMARY, snackbarDuration, PAGE_MODE_EDIT,
} from '../../constant';
import LocalizedString from '../../localization';
import { toMoment } from '../../helper';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField, renderReduxFormOutlinedDropdownTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import { rxformValidateEvent } from '../../validation';

const renderDialogContent = (
  initialValues,
  initialValueUrl1, onVisibilityUrl1OptionSelected,
  initialValueUrl2, onVisibilityUrl2OptionSelected,
  initialValueVisibility, onVisibilityOptionSelected,
  addingEditing, downloadingDeleting, pageMode,
  vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
  provinces, loadingProvince, onChangeProvinceText, onProvinceOptionSelected,
  cities, loadingCity, onChangeCityText, onCityOptionSelected,
  initialValueSurveyUrl, onVisibilitSurveyUrlOptionSelected,
  onCopyPressed, copying, selectedProvince, provinceId,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_NAME}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.eventPage.placeholderName}
            placeholder={LocalizedString.eventPage.placeholderName}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_EVENT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.eventPage.placeholderEventCode}
            label={LocalizedString.eventPage.placeholderEventCode}
            disabled
            hidden={pageMode !== PAGE_MODE_EDIT && pageMode !== PAGE_MODE_VIEW}
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                    <CopyToClipboard text={initialValues.eventCode}>
                      <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                    </CopyToClipboard>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.eventPage.placeholderDescription}
            placeholder={LocalizedString.eventPage.placeholderDescription}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_PROVINCE}
            component={renderReduxFormOutlinedDropdownTextField}
            label={LocalizedString.eventPage.placeholderProvince}
            placeholder={LocalizedString.eventPage.placeholderProvince}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={provinces}
            value={initialValues.provinces}
            loading={loadingProvince}
            onChangeText={onChangeProvinceText}
            onOptionSelected={onProvinceOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_CITY}
            component={renderReduxFormOutlinedDropdownTextField}
            label={LocalizedString.eventPage.placeholderCity}
            placeholder={LocalizedString.eventPage.placeholderCity}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW || !selectedProvince}
            data={cities}
            value={initialValues.cities}
            loading={loadingCity}
            onChangeText={(text) => onChangeCityText(
              text, pageMode === PAGE_MODE_EDIT ? provinceId : null,
            )}
            onOptionSelected={onCityOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_LOCATION}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.eventPage.placeholderLocation}
            placeholder={LocalizedString.eventPage.placeholderLocation}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.eventPage.placeholderSurveyURL}
            placeholder={LocalizedString.eventPage.placeholderSurveyURL}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL_EXTERNAL_BROWSER}
            component={renderReduxFormSimpleDropdownField}
            label={LocalizedString.eventPage.placeholderSurveyURLExternalBrowser}
            placeholder={LocalizedString.eventPage.placeholderSurveyURLExternalBrowser}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValueSurveyUrl}
            onOptionSelected={onVisibilitSurveyUrlOptionSelected}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_START_TIME}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.eventPage.placeholderStartTime}
            placeholder={LocalizedString.eventPage.placeholderStartTime}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_END_TIME}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.eventPage.placeholderEndTime}
            placeholder={LocalizedString.eventPage.placeholderEndTime}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_VISIBILITY}
            component={renderReduxFormSimpleDropdownField}
            label={LocalizedString.eventPage.placeholderVisibility}
            placeholder={LocalizedString.eventPage.placeholderVisibility}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={visibilityStatuses}
            value={initialValues.visibility}
            onOptionSelected={onVisibilityOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_VOUCHER}
            component={renderReduxFormOutlinedDropdownTextField}
            label={LocalizedString.eventPage.placeholderVoucherReward}
            placeholder={LocalizedString.eventPage.placeholderVoucherReward}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={vouchers}
            value={initialValues.vouchers}
            loading={loadingVouchers}
            onChangeText={onChangeVoucherText}
            onOptionSelected={onVoucherOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_URL1}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.eventPage.placeholderURL1}
            placeholder={LocalizedString.eventPage.placeholderURL1}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_URL1_EXTERNAL_BROWSER}
            component={renderReduxFormSimpleDropdownField}
            label={LocalizedString.eventPage.placeholderURL1ExternalBrowser}
            placeholder={LocalizedString.eventPage.placeholderURL1ExternalBrowser}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValueUrl1}
            onOptionSelected={onVisibilityUrl1OptionSelected}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_URL2}
            component={renderReduxFormOutlinedTextField}
            label={LocalizedString.eventPage.placeholderURL2}
            placeholder={LocalizedString.eventPage.placeholderURL2}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_TEST_DRIVE_EVENT_URL2_EXTERNAL_BROWSER}
            component={renderReduxFormSimpleDropdownField}
            label={LocalizedString.eventPage.placeholderURL2ExternalBrowser}
            placeholder={LocalizedString.eventPage.placeholderURL2ExternalBrowser}
            disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.url2OpenOnExternalBrowser}
            onOptionSelected={onVisibilityUrl2OptionSelected}
          />
        </Grid>
      </Grid>

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.eventPage.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
  </div>
);

const EventPage = ({
  initialValues, addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear,
  onApplyAdvancedFilterPressed, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onDeletePressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onViewPressed, pageMode, onCreatePressed, onSubmitPressed, onEditPressed,
  onSavePressed, onVisibilityOptionSelected, onUrl1ExtensionBrowserOptionSelected,
  onUrl2ExtensionBrowserOptionSelected,
  vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
  provinces, loadingProvince, onChangeProvinceText, onProvinceOptionSelected,
  cities, loadingCity, onChangeCityText, onCityOptionSelected,
  onSurveyUrlExtensionBrowserOptionSelected, onAdvancedFilterPressed,
  onCopyPressed, copying, selectedProvince, provinceId, events,
}) => (
  <FunctionalPage
    data={RXSTATE_EVENTS}
    uiPage={RXSTATE_EVENT_PAGE}
    filterColumns={[
      {
        title: LocalizedString.eventPage.placeholderStartTime,
        field: RXFIELD_TEST_DRIVE_EVENT_START_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.eventPage.placeholderEndTime,
        field: RXFIELD_TEST_DRIVE_EVENT_END_TIME,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.eventPage.placeholderVisibility,
        field: RXFIELD_TEST_DRIVE_EVENT_VISIBILITY,
        type: FILTER_TYPE_DROPDOWN,
        data: visibilityStatuses,
      },
      {
        title: LocalizedString.eventPage.placeholderCity,
        field: RXFIELD_TEST_DRIVE_CITY,
        type: FILTER_TYPE_DROPDOWN,
        data: cities,
        loading: loadingCity,
        onChangeFilterText: onChangeCityText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.eventPage.placeholderProvince,
        field: RXFIELD_TEST_DRIVE_PROVINCE,
        type: FILTER_TYPE_DROPDOWN,
        data: provinces,
        loading: loadingProvince,
        onChangeFilterText: onChangeProvinceText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.eventPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.eventPage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.eventPage.labelLocation, field: 'location', sorting: !downloading },
      {
        title: LocalizedString.eventPage.labelStartTime,
        field: 'startTime',
        sorting: !downloading,
        render: ({ city, startTime }) => (startTime
          ? toMoment(startTime, city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
          : null),
      },
      {
        title: LocalizedString.eventPage.labelEndTime,
        field: 'endTime',
        sorting: !downloading,
        render: ({ city, endTime }) => (endTime
          ? toMoment(endTime, city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.eventPage.labelVisibility, field: 'visibility', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCancelPressed={onCancelPressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onSubmitPressed={onSubmitPressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={(id) => onEditPressed(id, events)}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    title={LocalizedString.eventPage.title}
    createNewButtonCaption={LocalizedString.eventPage.buttonCaptionCreateEvent}
    deleteButtonCaption={LocalizedString.eventPage.buttonCaptionDeleteEvent}
    editButtonCaption={LocalizedString.eventPage.buttonCaptionEditEvent}
    useFullWidth
  >
    {renderDialogContent(
      initialValues,
      initialValues.url1OpenOnExternalBrowser, onUrl1ExtensionBrowserOptionSelected,
      initialValues.url2OpenOnExternalBrowser, onUrl2ExtensionBrowserOptionSelected,
      initialValues.visibility, onVisibilityOptionSelected,
      addingEditing, downloadingDeleting, pageMode,
      vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
      provinces, loadingProvince, onChangeProvinceText, onProvinceOptionSelected,
      cities, loadingCity, onChangeCityText, onCityOptionSelected,
      initialValues.surveyUrlOpenOnExternalBrowser, onSurveyUrlExtensionBrowserOptionSelected,
      onCopyPressed, copying, selectedProvince, provinceId,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_EVENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidateEvent,
})(EventPage);

EventPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  provinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  loadingProvince: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loadingVouchers: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCityOptionSelected: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeProvinceText: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onProvinceOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibilityOptionSelected: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onUrl1ExtensionBrowserOptionSelected: PropTypes.func.isRequired,
  onUrl2ExtensionBrowserOptionSelected: PropTypes.func.isRequired,
  onSurveyUrlExtensionBrowserOptionSelected: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedProvince: PropTypes.string.isRequired,
  provinceId: PropTypes.string.isRequired,
};
