import { SET_TRANSACTION_CREATED_BY_VEHICLE_MODEL, CLEAR_TRANSACTION_CREATED_BY_VEHICLE_MODEL } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_TRANSACTION_CREATED_BY_VEHICLE_MODEL: {
      return action.data;
    }
    case CLEAR_TRANSACTION_CREATED_BY_VEHICLE_MODEL:
      return state;
    default:
      return state;
  }
};
