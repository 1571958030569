import { INITIAL_ORDER_BY_WASTE_DEALER_CODE } from '../../constant';
import {
  DOWNLOADING_WASTE_DEALER_CODES,
  SET_WASTE_DEALER_CODES_ADVANCED_FILTER_STRING,
  SET_WASTE_DEALER_CODES_ORDER_BY, SET_WASTE_DEALER_CODES_SEARCH_TEXT,
  SET_WASTE_DEALER_CODES_SELECTED_PAGE_SIZE, SET_WASTE_DEALER_CODE_TAPPED_ID,
  DOWNLOADING_DELETING_WASTE_DEALER_CODE, ADDING_WASTE_DEALER_CODE,
  SET_WASTE_DEALER_CODE_TAPPED_DEALER_ID,
} from '../action';


const initialState = {
  downloading: false,
  downloadingDeleting: false,
  addingEditing: false,
  saving: false,
  selectedPageSize: 20,
  tappedId: '',
  tappedDealerId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_WASTE_DEALER_CODE,
  filterString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WASTE_DEALER_CODES:
      return { ...state, downloading: action.status };
    case SET_WASTE_DEALER_CODES_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WASTE_DEALER_CODE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WASTE_DEALER_CODE_TAPPED_DEALER_ID:
      return { ...state, tappedDealerId: action.id };
    case SET_WASTE_DEALER_CODES_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WASTE_DEALER_CODES_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WASTE_DEALER_CODES_ADVANCED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case DOWNLOADING_DELETING_WASTE_DEALER_CODE:
      return { ...state, downloadingDeleting: action.status };
    case ADDING_WASTE_DEALER_CODE:
      return { ...state, addingEditing: action.status };
    default: return state;
  }
};
