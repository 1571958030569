import { downloadingChargingStationProvinces, setChargingStationProvinces } from '../simple-action';
import { downloadChargingStationProvinces } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingChargingStationProvinces(true));

    const list = await downloadChargingStationProvinces(token);
    if (list) {
      dispatch(setChargingStationProvinces(list));
    }
  } finally {
    dispatch(downloadingChargingStationProvinces(false));
  }
};
