import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    carRegistrationMenu: {
      title: 'Car Registration',
    },
    registrationPage: {
      title: 'Registration',

      labelNo: 'No',
      labelVin: 'VIN',
      labelUser: 'User',
      labelUserCode: 'User Code',
      labelCarModel: 'Car Model',
      labelModelYear: 'Model Year',
      labelLicensePlate: 'License Plate',
      labelStatus: 'Status',
      labelApprovedOn: 'Approved on',
      labelRejectedOn: 'Rejected on',
      labelCreatedDate: 'Created Date',
      labelCity: 'City',
      labelProvince: 'Province',
      labelVehicleInfo: 'Vehicle Info',
      labelIdentityStatus: 'Identity Status',
      labelColorCode: 'Color Code',

      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',
      buttonCaptionAddNote: 'Add Notes',
      buttonCaptionChangeVin: 'Change VIN',
      buttonCaptionSubmit: 'Submit',
      buttonCaptionRefreshVehicleInfo: 'Refresh Vehicle Info',

      placeholderModel: 'Model',
      placeholderTrim: 'Trim',
      placeholderColor: 'Color',
      placeholderColorCode: 'Color Code',
      placeholderLicensePlate: 'License Plate',
      placeholderTransmission: 'Transmission',
      placeholderStnk: 'STNK',
      placeholderWarrantyStartDate: 'Warranty Start Date',
      placeholderWarrantyEndDate: 'Warranty End Date',
      placeholderWarrantyNotes: 'Warranty Notes',
      placeholderPersonId: 'Person ID',
      placeholderPersonalAccount: 'Personal Account',
      placeholderOwnerName: 'Owner Name',
      placeholderInvoiceNo: 'Invoice No',
      placeholderEngineNo: 'Engine No.',
      placeholderOwnerEmail: 'Owner Email',
      placeholderDealerCode: 'Dealer Code',
      placeholderDealerName: 'Dealer Name',
      placeholderDealerAddress: 'Dealer Address',
      placeholderModelYear: 'Model Year',
      placeholderUserId: 'User ID',
      placeholderUserName: 'User Name',
      placeholderUserCode: 'User Code',
      placeholderFullName: 'Full Name',
      placeholderNik: 'NIK',
      placeholderVin: 'VIN',
      placeholderSalutation: 'Salutation',
      placeholderPhone: 'Phone',
      placeholderEmail: 'Email',
      placeholderStreetAddress: 'Street Address',
      placeholderDistrict: 'District',
      placeholderCity: 'City',
      placeholderProvince: 'Province',
      placeholderTimeZone: 'Time Zone',
      placeholderRegistrationStatus: 'Registration Status',
      placeholderRegistrationNotes: 'Registration Notes',
      placeholderApprovedOn: 'Approved on',
      placeholderRejectedOn: 'Rejected on',
      placeholderNotes: 'Notes',
      placeholderPrice: 'Price',
      placeholderIdentityStatus: 'Identity Status',
      placeholderStnkName: 'STNK Name',
      placeholderStnkValidUntil: 'STNK Valid Until',
      placeholderStnkNumber: 'STNK Number',
      placeholderStnkImage: 'STNK Image',
      placeholderCurrentUserTier: 'Current User Tier',
      placeholderDateOfBirth: 'Date of Birth',
      placeholderYear: 'Year',
      placeholderNikImage: 'NIK Image',
      placeholderOwnershipProofImage: 'Ownership Proof Image',
      placeholderOtherImage1: 'Other 1 Image',
      placeholderOtherImage2: 'Other 2 Image',
      placeholderVehicleSource: 'Vehicle Source',
      placeholderVehiclePrice: 'Vehicle Price',
      placeholderBlueLinkType: 'Blue Link Type',
      placeholderBlueLinkTypeCode: 'Blue Link Type Code',
      placeholderBlueLinkTypeSub: 'Blue Link Type Sub',
      placeholderBlueLinkTypeSubCode: 'Blue Link Type Sub Code',

      msgRejectConfirmation: 'Are you sure want to reject this product?',
      msgApproveConfirmation: 'Are you sure want to approve this product?',
      msgAddNotesConfirmation: 'Do you want to add notes for this product?',
      msgChangeVinConfirmation: 'Do you want to change the VIN?',
      msgRefreshVehicleInfoConfirmation: 'Do you want to refresh this vehicle info?',

      errMsgPriceIsNan: 'Vehicle Price field must be a number',
    },
    membershipRewardPage: {
      title: 'Membership Reward',

      labelNo: 'No',
      labelUser: 'User',
      labelTier: 'Tier',
      labelAmount: 'Amount',
      labelAllocationStatus: 'Allocation Status',
      labelCreatedDate: 'Created Date',
      labelType: 'Type',
      labelNotes: 'Notes',
      labelScheduledDate: 'Scheduled Date',
      labelActualDate: 'Actual Date',
      labelRecipientId: 'Recipient ID',
      labelRecipientName: 'Recipient Name',

      placeholderUserId: 'User ID',
      placeholderUserName: 'User Name',
      placeholderAllocationStatus: 'Allocation Status',
      placeholderNotes: 'Notes',
      placeholderStoppedAt: 'Stopped At',
      placeholderTier: 'Tier',
      placeholderRewardAmount: 'Reward Amount',
    },
    registrationAttemptPage: {
      title: 'Registration Attempt',

      labelNo: 'No',
      labelUserID: 'User ID',
      labelUserName: 'User Name',
      labelVin: 'VIN',
      labelCreatedDate: 'Created Date',

      placeholderUserId: 'User ID',
      placeholderUserName: 'User Name',
      placeholderVin: 'VIN',
      placeholderCreatedDate: 'Created Date',
    },
    carOwnershipRewardPage: {
      title: 'Car Ownership Reward',

      labelVehicle: 'Vehicle',
      labelLicensePlate: 'License Plate',
      labelVin: 'VIN',
      labelTier: 'Tier',
      labelAmount: 'Amount',
      labelNo: 'No',
      labelType: 'Type',
      labelCreatedDate: 'Created Date',
      labelScheduledDate: 'Scheduled Date',
      labelActualDate: 'Actual Date',
      labelRecipientId: 'Recipient ID',
      labelRecipientName: 'Recipient Name',
      labelRewardAllocationStatus: 'Allocation Status',
      labelNotes: 'Notes',

      placeholderVin: 'VIN',
      placeholderModel: 'Model',
      placeholderTrim: 'Trim',
      placeholderColor: 'Color',
      placeholderLicensePlate: 'License Plate',
      placeholderEngineNo: 'Engine No.',
      placeholderTier: 'Tier',
      placeholderRewardAmount: 'Reward Amount',
      placeholderRegistrationId: 'Registration ID',
      placeholderApprovedOn: 'Approved On',
      placeholderRewardAllocationStatus: 'Allocation Status',
      placeholderRewardNotes: 'Notes',
    },
    carOwnershipPage: {
      title: 'Car Ownership',

      labelNo: 'No',
      labelUserName: 'User Name',
      labelVin: 'VIN',
      labelLicensePlate: 'License Plate',
      labelTrim: 'Trim',
      labelModelYear: 'Model Year',
      labelOwnershipStatus: 'Ownership Status',
      labelApprovedOn: 'Approved On',
      labelInactivatedOn: 'Inactivated On',
      labelUser: 'User',
      labelActive: 'Active',
      labelInactive: 'Inactive',
      labelColorCode: 'Color Code',

      placeholderVin: 'VIN',
      placeholderEngineNo: 'Engine No.',
      placeholderModel: 'Model',
      placeholderTrim: 'Trim',
      placeholderColor: 'Color',
      placeholderColorCode: 'Color Code',
      placeholderModelYear: 'Model Year',
      placeholderTransmission: 'Transmission',
      placeholderLicensePlate: 'License Plate',
      placeholderWarrantyStartDate: 'Warranty Start Date',
      placeholderWarrantyEndDate: 'Warranty End Date',
      placeholderWarrantyNote: 'Warranty Note',
      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderOwnershipRegistrationId: 'Ownership Registration ID',
      placeholderOwnershipApprovedOn: 'Ownership Approved On',
      placeholderOwnershipStatus: 'Ownership Status',
      placeholderInactivatedOn: 'Inactivated On',
      placeholderStnkNumber: 'STNK Number',
      placeholderStnkName: 'STNK Name',
      placeholderStnkValidUntil: 'STNK Valid Until',
      placeholderPkbFirstReminderSentOn: 'PKB First Reminder Sent On',
      placeholderPkbSecondReminderSentOn: 'PKB Second Reminder Sent On',
      placeholderStnkImage: 'STNK Image',
      placeholderBlueLinkType: 'Blue Link Type',
      placeholderBlueLinkTypeCode: 'Blue Link Type Code',
      placeholderBlueLinkTypeSub: 'Blue Link Type Sub',
      placeholderBlueLinkTypeSubCode: 'Blue Link Type Sub Code',
    },
    DMSUserSubmissionPage: {
      title: 'DMS User Submission',

      labelNo: 'No',
      labelVin: 'VIN',
      labelUser: 'User',
      labelSubmissionDelay: 'Submission Delay',
      labelSubmissionStatus: 'Submission Status',
      labelSubmittedOn: 'Submitted on',
      labelCreatedDate: 'Created Date',
      labelVehicleModel: 'Vehicle Model',
      labelRetryCount: 'Retry Count',

      placeholderModel: 'Model',
      placeholderUserId: 'User ID',
      placeholderUserName: 'User Name',
      placeholderUserCode: 'User Code',
      placeholderFullName: 'Full Name',
      placeholderRegistrationID: 'Registration ID',
      placeholderSubmissionStatus: 'Submission Status',
      placeholderSubmissionStatusNotes: 'Submission Status Notes',
      placeholderSubmittedOn: 'Submitted On',
      placeholderSubmissionDelaySecond: 'Submission Delay(seconds)',
      placeholderRetryCount: 'Retry Count',
      placeholderTier: 'Tier',
      placeholderVin: 'VIN',

      buttonCaptionRetry: 'Retry',

      msgRetryConfirmation: 'Are you sure want to retry this item?',
    },
  },
  id: {
    carRegistrationMenu: {
      title: 'Registrasi Mobil',
    },
    registrationPage: {
      title: 'Registrasi',

      labelNo: 'No',
      labelVin: 'VIN',
      labelUser: 'Pengguna',
      labelUserCode: 'Kode Pengguna',
      labelCarModel: 'Model Mobil',
      labelModelYear: 'Tahun Model',
      labelLicensePlate: 'Plat Nomor',
      labelStatus: 'Status',
      labelApprovedOn: 'Disetujui pada',
      labelRejectedOn: 'Ditolak pada',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelVehicleInfo: 'Info Kendaraan',
      labelIdentityStatus: 'Status Identitas',
      labelColorCode: 'Kode Warna',

      buttonCaptionApprove: 'Setujui',
      buttonCaptionReject: 'Tolak',
      buttonCaptionAddNote: 'Tambah Catatan',
      buttonCaptionChangeVin: 'Ubah VIN',
      buttonCaptionSubmit: 'Kirim',
      buttonCaptionRefreshVehicleInfo: 'Perbarui Info Kendaraan',

      placeholderModel: 'Model',
      placeholderTrim: 'Lis',
      placeholderColor: 'Warna',
      placeholderColorCode: 'Kode Warna',
      placeholderLicensePlate: 'Plat Nomor',
      placeholderTransmission: 'Transmisi',
      placeholderStnk: 'STNK',
      placeholderWarrantyStartDate: 'Tanggal Garansi Dimulai',
      placeholderWarrantyEndDate: 'Tanggal Garansi Berakhir',
      placeholderWarrantyNotes: 'Catatan Garansi',
      placeholderPersonId: 'ID Individu',
      placeholderPersonalAccount: 'Akun Personal',
      placeholderOwnerName: 'Nama Pemilik',
      placeholderInvoiceNo: 'No. Faktur',
      placeholderEngineNo: 'No. Mesin',
      placeholderOwnerEmail: 'Email Pemilik',
      placeholderDealerCode: 'Kode Penjual',
      placeholderDealerName: 'Nama Penjual',
      placeholderDealerAddress: 'Alamat Penjual',
      placeholderModelYear: 'Tahun Model',
      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderUserCode: 'Kode Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderNik: 'NIK',
      placeholderVin: 'VIN',
      placeholderSalutation: 'Sebutan',
      placeholderPhone: 'Telepon',
      placeholderEmail: 'Email',
      placeholderStreetAddress: 'Alamat Jalan',
      placeholderDistrict: 'Kecamatan',
      placeholderCity: 'Kota',
      placeholderProvince: 'Provinsi',
      placeholderTimeZone: 'Zona Waktu',
      placeholderRegistrationStatus: 'Status Registrasi',
      placeholderRegistrationNotes: 'Catatan Registrasi',
      placeholderApprovedOn: 'Disetujui pada',
      placeholderRejectedOn: 'Ditolak pada',
      placeholderNotes: 'Catatan',
      placeholderPrice: 'Harga',
      placeholderIdentityStatus: 'Status Identitas',
      placeholderStnkName: 'Nama STNK',
      placeholderStnkValidUntil: 'STNK Berlaku Sampai',
      placeholderStnkNumber: 'Nomor STNK',
      placeholderStnkImage: 'Foto STNK',
      placeholderCurrentUserTier: 'Tingkat Pengguna Sekarang',
      placeholderDateOfBirth: 'Tanggal Lahir',
      placeholderYear: 'Tahun',
      placeholderNikImage: 'Foto NIK',
      placeholderOwnershipProofImage: 'Foto Bukti Kepemilikkan',
      placeholderOtherImage1: 'Foto Lainnya 1',
      placeholderOtherImage2: 'Foto Lainnya 2',
      placeholderVehicleSource: 'Sumber Kendaraan',
      placeholderVehiclePrice: 'Harga Kendaraan',
      placeholderBlueLinkType: 'Tipe Blue Link',
      placeholderBlueLinkTypeCode: 'Kode Tipe Blue Link',
      placeholderBlueLinkTypeSub: 'Tipe Sub Blue Link',
      placeholderBlueLinkTypeSubCode: 'Kode Tipe SubBlue Link',

      msgRejectConfirmation: 'Apa Anda yakin ingin menolak produk ini?',
      msgApproveConfirmation: 'Apa Anda yakin ingin menyetujui produk ini?',
      msgAddNotesConfirmation: 'Apa Anda ingin menambah catatan untuk produk ini?',
      msgChangeVinConfirmation: 'Apa Anda ingin mengubah VIN?',
      msgRefreshVehicleInfoConfirmation: 'Apa Anda ingin memperbarui info kendaraan ini?',

      errMsgPriceIsNan: 'Harga Kendaraan field harus berupa angka',
    },
    membershipRewardPage: {
      title: 'Hadiah Keanggotaan',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelTier: 'Tingkat',
      labelAmount: 'Jumlah',
      labelAllocationStatus: 'Status Alokasi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelType: 'Tipe',
      labelNotes: 'Catatan',
      labelScheduledDate: 'Tanggal Terjadwal',
      labelActualDate: 'Tanggal Aktual',
      labelRecipientId: 'ID Penerima',
      labelRecipientName: 'Nama Penerima',

      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderAllocationStatus: 'Status Alokasi',
      placeholderNotes: 'Catatan',
      placeholderStoppedAt: 'Dihentikan pada',
      placeholderTier: 'Tingkat',
      placeholderRewardAmount: 'Jumlah Hadiah',
    },
    registrationAttemptPage: {
      title: 'Percobaan Pendaftaran',

      labelNo: 'No',
      labelUserID: 'ID Pengguna',
      labelUserName: 'Nama Pengguna',
      labelVin: 'VIN',
      labelCreatedDate: 'Tanggal Pembuatan',

      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderVin: 'VIN',
      placeholderCreatedDate: 'Tanggal Pembuatan',
    },
    carOwnershipRewardPage: {
      title: 'Hadiah Kepemilikan Mobil',

      labelVehicle: 'Kendaraan',
      labelLicensePlate: ' Plat Nomor',
      labelVin: 'VIN',
      labelTier: 'Tingkat',
      labelAmount: 'Jumlah',
      labelNo: 'No',
      labelType: 'Tipe',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelScheduledDate: 'Tanggal Terjadwal',
      labelActualDate: 'Tanggal Aktual',
      labelRecipientId: 'ID Penerima',
      labelRecipientName: 'Nama Penerima',
      labelRewardAllocationStatus: 'Status Alokasi',
      labelNotes: 'Catatan',

      placeholderVin: 'VIN',
      placeholderModel: 'Model',
      placeholderTrim: 'Lis',
      placeholderColor: 'Warna',
      placeholderLicensePlate: 'Plat Nomor',
      placeholderEngineNo: 'No. Mesin',
      placeholderTier: 'Tingkat',
      placeholderRewardAmount: 'Jumlah Hadiah',
      placeholderRegistrationId: 'ID Registrasi',
      placeholderApprovedOn: 'Disetujui pada',
      placeholderRewardAllocationStatus: 'Status Alokasi',
      placeholderRewardNotes: 'Catatan',
    },
    carOwnershipPage: {
      title: 'Kepemilikan Mobil',

      labelNo: 'No',
      labelUserName: 'Nama Pengguna',
      labelVin: 'VIN',
      labelLicensePlate: 'Plat Nomor',
      labelTrim: 'Lis',
      labelModelYear: 'Tahun Model',
      labelOwnershipStatus: 'Status Kepemilikan',
      labelApprovedOn: 'Disetujui Pada',
      labelInactivatedOn: 'Dinonaktifkan Pada',
      labelUser: 'Pengguna',
      labelActive: 'Aktif',
      labelInactive: 'Tidak Aktif',
      labelColorCode: 'Kode Warna',

      placeholderVin: 'VIN',
      placeholderEngineNo: 'No. Mesin',
      placeholderModel: 'Model',
      placeholderTrim: 'Lis',
      placeholderColor: 'Warna',
      placeholderColorCode: 'Kode Warna',
      placeholderModelYear: 'Tahun Model',
      placeholderTransmission: 'Transmisi',
      placeholderLicensePlate: 'Plat Nomor',
      placeholderWarrantyStartDate: 'Tanggal Mulai Garansi',
      placeholderWarrantyEndDate: 'Tanggal Garansi Berakhir',
      placeholderWarrantyNote: 'Catatan Garansi',
      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderOwnershipRegistrationId: 'ID Registrasi Kepemilikan',
      placeholderOwnershipApprovedOn: 'Kepemilikan Disetujui Pada',
      placeholderOwnershipStatus: 'Status Kepemilikan',
      placeholderInactivatedOn: 'Dinonaktifkan Pada',
      placeholderStnkNumber: 'Nomor STNK',
      placeholderStnkName: 'Nama STNK',
      placeholderStnkValidUntil: 'STNK Berlaku Sampai',
      placeholderPkbFirstReminderSentOn: 'Pengingat PKB Pertama Dikirim Pada',
      placeholderPkbSecondReminderSentOn: 'Pengingat PKB Kedua Dikirim Pada',
      placeholderStnkImage: 'Foto STNK',
      placeholderBlueLinkType: 'Tipe Blue Link',
      placeholderBlueLinkTypeCode: 'Kode Tipe Blue Link',
      placeholderBlueLinkTypeSub: 'Tipe Sub Blue Link',
      placeholderBlueLinkTypeSubCode: 'Kode Tipe SubBlue Link',
    },
    DMSUserSubmissionPage: {
      title: 'Submisi Pengguna DMS',

      labelNo: 'No',
      labelVin: 'VIN',
      labelUser: 'Pengguna',
      labelSubmissionDelay: 'Penundaan Penyerahan',
      labelSubmissionStatus: 'Status Pengajuan',
      labelSubmittedOn: 'Diserahkan pada',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelVehicleModel: 'Model Kendaraan',
      labelRetryCount: 'Hitungan Ulang',

      placeholderModel: 'Model',
      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderUserCode: 'Kode Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderRegistrationID: 'ID Pendaftaran',
      placeholderSubmissionStatus: 'Status Pengajuan',
      placeholderSubmissionStatusNotes: 'Catatan Status Pengajuan',
      placeholderSubmittedOn: 'Diserahkan Pada',
      placeholderSubmissionDelaySecond: 'Jeda Pengajuan(detik)',
      placeholderRetryCount: 'Hitungan Ulang',
      placeholderTier: 'Tingkat',
      placeholderVin: 'VIN',

      buttonCaptionRetry: 'Coba Lagi',

      msgRetryConfirmation: 'Apakah Anda yakin ingin mencoba lagi pilihan ini?',
    },
  },
});

export default LocalizedString;
