import { downloadWasteCollections, transformSearchText } from '../../../helper';
import { downloadingWasteCollections, setWasteCollections } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, wasteCollections, uiWasteCollection } = getState();

  const { token } = authentication;
  const { meta } = wasteCollections;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiWasteCollection;

  try {
    dispatch(downloadingWasteCollections(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const scannedCodes = await downloadWasteCollections(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    dispatch(setWasteCollections(scannedCodes));
  } finally {
    dispatch(downloadingWasteCollections(false));
  }
};
