import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_ACCESSORIES, MENUID_GENUINE_ACCESSORY_ACCESSORY, PAGE_MODE_TABLE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE,
  RXFIELD_ACCESSORY_ACTIVE,
  RXFIELD_ACCESSORY_VARIANT,
  RXFORM_ACCESSORY,
} from '../../constant';
import {
  transformInitialValues, transformVehicleDropdownData,
} from '../../helper';
import {
  clearAccessories, downloadAccessoryAsync,
  downloadAccessoriesAsync, downloadVehicleModelsAsync,
  clearVehicleModels,
  setActiveSideMenuItem, setAlertErrorMessage,
  setAccessorySearchText, setAccessorySelectedOrderBy,
  setAccessorySelectedPageSize, setAccessoryTappedId,
  editAccessoryAsync,
  setAccessoryisActive,
  saveAccessoriesAsync,
  syncAccessoriesFromDMSAsync,
} from '../../redux/action';
import AccessoryPage from './accessory.presentation';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiAccessory, accessories } = state;
  const { data } = accessories;
  const { downloadingDeleting, tappedId } = uiAccessory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    variants: found.variants.length > 0 && !downloadingDeleting
      ? found.variants.map((x, i) => ({
        ...x,
        no: i + 1,
        image: x.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${x.image}` : '',
      })) : [],
  }) : {
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiAccessory.downloading,
  saving: state.uiAccessory.saving,
  syncing: state.uiAccessory.syncing,
  downloadingDeleting: state.uiAccessory.downloadingDeleting,
  addingEditing: state.uiAccessory.addingEditing,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  totalCurrentPage: state.accessories.meta.currentPage,
  totalCount: state.accessories.meta.totalCount,
  loadingVehicleModel: state.uiAccessory.downloadingVehicleModels,
  vehicleModels: transformVehicleDropdownData(state.vehicleModels.data),
});


const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_GENUINE_ACCESSORY_ACCESSORY));
    dispatch(setAccessorySearchText(''));
    dispatch(clearAccessories());
    dispatch(setAccessorySelectedPageSize(20));
    dispatch(setAccessorySelectedOrderBy(INITIAL_ORDER_BY_ACCESSORIES));
    dispatch(downloadAccessoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAdvancedFilterPressed: () => {
    dispatch(clearVehicleModels());
    dispatch(downloadVehicleModelsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: () => {
    dispatch(clearAccessories());
    dispatch(downloadAccessoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onActiveOptionSelected: (option) => {
    if (option) {
      dispatch(setAccessoryisActive(option));
      dispatch(change(RXFORM_ACCESSORY, RXFIELD_ACCESSORY_ACTIVE, option));
    } else {
      dispatch(setAccessoryisActive(''));
      dispatch(change(RXFORM_ACCESSORY, RXFIELD_ACCESSORY_ACTIVE, ''));
    }
  },
  onAddVariantPressed: (data) => {
    dispatch(change(RXFORM_ACCESSORY, RXFIELD_ACCESSORY_VARIANT, data));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAccessoriesAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAccessorySelectedPageSize(pageSize));
    dispatch(downloadAccessoriesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setAccessorySelectedPageSize(pageSize));
    dispatch(clearAccessories());
    dispatch(downloadAccessoriesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearAccessories());
    dispatch(setAccessorySelectedOrderBy(INITIAL_ORDER_BY_ACCESSORIES));
    dispatch(downloadAccessoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(clearVehicleModels());
    dispatch(downloadVehicleModelsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setAccessorySearchText(text));
    dispatch(clearAccessories());
    dispatch(downloadAccessoriesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setAccessorySelectedOrderBy(orderBy));
    dispatch(clearAccessories());
    dispatch(downloadAccessoriesAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setAccessoryTappedId(id));
    dispatch(downloadAccessoryAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSavePressed: async ({
    variants,
  }) => {
    try {
      await dispatch(editAccessoryAsync(
        variants,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onEditPressed: (id) => {
    dispatch(setAccessoryTappedId(id));
    dispatch(downloadAccessoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_ACCESSORY));
  },
  onDownloadPressed: () => {
    dispatch(saveAccessoriesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSyncFromDMSPressed: () => {
    dispatch(syncAccessoriesFromDMSAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessoryPage);
