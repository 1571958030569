import {
  addPromoCodeBatch, downloadingDeletingPromoCodeBatch, setFunctionalPageMode,
  setPromoCodeBatchSelectedBypassAchievement, setPromoCodeBatchSelectedBypassMaxPurchase,
  setPromoCodeBatchSelectedMerchant, setPromoCodeBatchSelectedRewardType,
  setPromoCodeBatchSelectedVouchers,
  setPromoCodeBatchTableDataVouchers,
  setPromoCodeBatchUserTypes,
} from '../simple-action';
import { downloadPromoCodeBatch, transformVouchers, getUserType } from '../../../helper';
import { approval, PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingPromoCodeBatch(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPromoCodeBatch;

    const result = await downloadPromoCodeBatch(tappedId, token);

    dispatch(addPromoCodeBatch(result));
    dispatch(setPromoCodeBatchSelectedMerchant({
      label: result.merchantName,
      value: result.merchantId,
    }));
    dispatch(setPromoCodeBatchSelectedVouchers(null));
    dispatch(setPromoCodeBatchTableDataVouchers(transformVouchers(result.vouchers)));
    dispatch(setPromoCodeBatchSelectedRewardType({
      label: result.rewardType,
      value: result.rewardType,
    }));
    dispatch(setPromoCodeBatchSelectedBypassAchievement(result.bypassMaxPurchaseValidation
      ? approval[0] : approval[1]));
    dispatch(setPromoCodeBatchSelectedBypassMaxPurchase(result.bypassAchievementValidation
      ? approval[0] : approval[1]));
    dispatch(setPromoCodeBatchUserTypes(
      getUserType(result),
    ));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingPromoCodeBatch(false));
  }
};
