import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_GIIAS_MEDAN_SCANNED_CODES = '/giiasmdn2022/api/v1/ScannedCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_GIIAS_MEDAN_VIEW_DELETE_SCANNED_CODE = '/giiasmdn2022/api/v1/ScannedCode/{id}';
export const REST_URL_GIIAS_MEDAN_PRIZE_REDEMPTIONS = '/giiasmdn2022/api/v1/PrizeRedemption?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_GIIAS_MEDAN_PRIZE_REDEMPTION = '/giiasmdn2022/api/v1/PrizeRedemption/{id}';
export const REST_URL_GIIAS_MEDAN_TRANSACTIONS = '/giiasmdn2022/api/v1/Transaction?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_GIIAS_MEDAN_TRANSACTION = '/giiasmdn2022/api/v1/Transaction/{id}';

export const ROUTE_NAME_GIIAS_MEDAN_2022 = '/giias-medan-2022';
export const ROUTE_NAME_GIIAS_MEDAN_SCANNED_CODE = '/giias-medan-scanned-code';
export const ROUTE_NAME_GIIAS_MEDAN_PRIZE_REDEMPTION = '/giias-medan-prize-redemption';
export const ROUTE_NAME_GIIAS_MEDAN_TRANSACTION = '/giias-medan-transaction';

export const MENUID_GIIAS_MEDAN_2022 = 'MENUID_GIIAS_MEDAN_2022';
export const MENUID_GIIAS_MEDAN_SCANNED_CODE = 'MENUID_GIIAS_MEDAN_SCANNED_CODE';
export const MENUID_GIIAS_MEDAN_PRIZE_REDEMPTION = 'MENUID_GIIAS_MEDAN_PRIZE_REDEMPTION';
export const MENUID_GIIAS_MEDAN_TRANSACTION = 'MENUID_GIIAS_MEDAN_TRANSACTION';

export const RXSTATE_GIIAS_MEDAN_SCANNED_CODES = 'giiasMedanScannedCodes';
export const RXSTATE_GIIAS_MEDAN_SCANNED_CODE_PAGE = 'uiGiiasMedanScannedCode';
export const RXSTATE_GIIAS_MEDAN_PRIZE_REDEMPTIONS = 'giiasMedanPrizeRedemptions';
export const RXSTATE_GIIAS_MEDAN_PRIZE_REDEMPTION = 'uiGiiasMedanPrizeRedemption';
export const RXSTATE_GIIAS_MEDAN_TRANSACTIONS = 'giiasMedanTransactions';
export const RXSTATE_GIIAS_MEDAN_TRANSACTION = 'uiGiiasMedanTransaction';

export const INITIAL_ORDER_BY_GIIAS_MEDAN_SCANNED_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_GIIAS_MEDAN_PRIZE_REDEMPTION = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_GIIAS_MEDAN_TRANSACTION = `createdDate ${ORDER_SORT_DESCENDING}`;

export const RXFIELD_GIIAS_MEDAN_SCANNED_CODE_TIME = 'createdDate';
export const RXFIELD_GIIAS_MEDAN_SCANNED_CODE_USERNAME = 'scanCodeUsername';
export const RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_CODE_LABEL = 'challengeCodeLabel';
export const RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_USER_NAME = 'user.fullName';
export const RXFIELD_GIIAS_MEDAN_SCANNED_CODE_CHALLANGE_USER_ID = 'user.id';
export const RXFIELD_GIIAS_MEDAN_CHALLANGE_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_DESCRIPTION = 'description';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_POINT = 'point';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_PRIZE_TAG = 'prizeTag';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_USER = 'user';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_USER_FULLNAME = 'fullName';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_USER_ID = 'userId';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_VOUCHER = 'voucher';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_VOUCHER_ID = 'voucherId';
export const RXFIELD_GIIAS_MEDAN_PRIZE_REDEMPTION_VOUCHER_TITLE = 'voucherTitle';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_AMOUNT = 'amount';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_CREATED_DATE = 'createdDate';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_LABEL = 'label';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_REFERENCE_ID = 'referenceId';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_REMAINING_BALANCE = 'remainingBalance';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_TYPE = 'type';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_USER = 'user';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_USER_FULLNAME = 'fullName';
export const RXFIELD_GIIAS_MEDAN_TRANSACTION_USER_ID = 'userId';

export const RXFORM_GIIAS_MEDAN_SCANNED_CODE = 'giassMedanScannedCodePage';
export const RXFORM_GIIAS_MEDAN_PRIZE_REDEMPTION = 'giassMedanPrizeRedemptionPage';
export const RXFORM_GIIAS_MEDAN_TRANSACTION = 'giassMedanTransactionPage';
