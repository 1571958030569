import {
  DOWNLOADING_BOOKING_CREATED, DOWNLOADING_BOOKING_PROCEED_BY_DEALER,
  DOWNLOADING_BOOKING_WAIT_FOR_PAYMENT, DOWNLOADING_BOOKING_COMPLETED,
  DOWNLOADING_BOOKING_CANCELED,
  DOWNLOADING_BOOKING_CREATED_BY_REPAIR_SELECTION,
  DOWNLOADING_BOOKING_CREATED_BY_DELIVERY_METHOD,
  DOWNLOADING_BOOKING_CREATED_BY_SERVICE_INTERVAL,
  DOWNLOADING_BOOKING_CREATED_BY_VEHICLE_MODEL,
  DOWNLOADING_TRANSACTION_RECEIVED,
  DOWNLOADING_TRANSACTION_PENDING,
  DOWNLOADING_TRANSACTION_PAID,
  DOWNLOADING_TRANSACTION_CANCELED,
  DOWNLOADING_TRANSACTION_AMOUNT,
  DOWNLOADING_TRANSACTION_COUNT_BY_PART_NAME,
  DOWNLOADING_TRANSACTION_AMOUNT_BY_PART_NAME,
  DOWNLOADING_REMAINING_STOCK_BY_PART_NAME,
  DOWNLOADING_TRANSACTION_CREATED_BY_VEHICLE_MODEL,
} from '../action';
import { } from '../../constant';

const initialState = {
  downloadingBookingCreated: false,
  downloadingBookingProceedByDealer: false,
  downloadingBookingWaitForPayment: false,
  downloadingBookingCompleted: false,
  downloadingBookingCanceled: false,
  downloadingBookingCreatedByRepairSelection: false,
  downloadingBookingCreatedByDeliveryMethod: false,
  downloadingBookingCreatedByServiceInterval: false,
  downloadingBookingCreatedByVehicleModel: false,
  downloadingTransactionReceived: false,
  downloadingTransactionPending: false,
  downloadingTransactionPaid: false,
  downloadingTransactionCanceled: false,
  downloadingTransactionAmount: false,
  downloadingTransactionCountByPartName: false,
  downloadingTransactionAmountByPartName: false,
  downloadingRemaningStockByPartName: false,
  downloadingTransactionCreatedByVehicleModel: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_BOOKING_CREATED:
      return { ...state, downloadingBookingCreated: action.status };
    case DOWNLOADING_BOOKING_PROCEED_BY_DEALER:
      return { ...state, downloadingBookingProceedByDealer: action.status };
    case DOWNLOADING_BOOKING_WAIT_FOR_PAYMENT:
      return { ...state, downloadingBookingWaitForPayment: action.status };
    case DOWNLOADING_BOOKING_COMPLETED:
      return { ...state, downloadingBookingCompleted: action.status };
    case DOWNLOADING_BOOKING_CANCELED:
      return { ...state, downloadingBookingCanceled: action.status };
    case DOWNLOADING_BOOKING_CREATED_BY_REPAIR_SELECTION:
      return { ...state, downloadingBookingCreatedByRepairSelection: action.status };
    case DOWNLOADING_BOOKING_CREATED_BY_DELIVERY_METHOD:
      return { ...state, downloadingBookingCreatedByDeliveryMethod: action.status };
    case DOWNLOADING_BOOKING_CREATED_BY_SERVICE_INTERVAL:
      return { ...state, downloadingBookingCreatedByServiceInterval: action.status };
    case DOWNLOADING_BOOKING_CREATED_BY_VEHICLE_MODEL:
      return { ...state, downloadingBookingCreatedByVehicleModel: action.status };
    case DOWNLOADING_TRANSACTION_RECEIVED:
      return { ...state, downloadingTransactionReceived: action.status };
    case DOWNLOADING_TRANSACTION_PENDING:
      return { ...state, downloadingTransactionPending: action.status };
    case DOWNLOADING_TRANSACTION_PAID:
      return { ...state, downloadingTransactionPaid: action.status };
    case DOWNLOADING_TRANSACTION_CANCELED:
      return { ...state, downloadingTransactionCanceled: action.status };
    case DOWNLOADING_TRANSACTION_AMOUNT:
      return { ...state, downloadingTransactionAmount: action.status };
    case DOWNLOADING_TRANSACTION_COUNT_BY_PART_NAME:
      return { ...state, downloadingTransactionCountByPartName: action.status };
    case DOWNLOADING_TRANSACTION_AMOUNT_BY_PART_NAME:
      return { ...state, downloadingTransactionAmountByPartName: action.status };
    case DOWNLOADING_REMAINING_STOCK_BY_PART_NAME:
      return { ...state, downloadingRemaningStockByPartName: action.status };
    case DOWNLOADING_TRANSACTION_CREATED_BY_VEHICLE_MODEL:
      return { ...state, downloadingTransactionCreatedByVehicleModel: action.status };
    default: return state;
  }
};
