import { syncAccessories } from '../../../helper';
import '../../../../../component/functional-page/download-file.css';
import { syncingAccesories } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(syncingAccesories(true));
    await syncAccessories(token);
  } finally {
    dispatch(syncingAccesories(false));
  }
};
