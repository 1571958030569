import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  INITIAL_ORDER_BY_FAQS, MENUID_FAQ_MANAGEMENT_FAQ, PAGE_MODE_TABLE,
  RXFORM_FAQ,
  RXFIELD_FAQ_DETAILED_ANSWER,
  RXFIELD_FAQ_VISIBILITY_STATUS,
  RXFIELD_FAQ_CATEGORY,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData, debounceSearch, toNumber,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearFAQs, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setFAQSearchText, setFAQSelectedPageSize, setFAQSelectedOrderBy,
  setFAQTappedId, addEditFAQAsync, downloadDeleteFAQAsync, downloadFAQsAsync,
  setFAQsSelectedVisibilityStatus,
  clearFAQCategories,
  downloadFAQCategoriesAsync, setFAQCategorySearchText, setFAQSelectedCategoryId,
  setFaqAdvancedFilterDialogSelectedFilterString,
} from '../../redux/action';
import FaqPage from './faq.presentation';

const getInitialValues = (state) => {
  const {
    faqs, uiFunctionalPage, uiFAQ,
  } = state;
  const { data } = faqs;
  const { downloadingDeleting, tappedId } = uiFAQ;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found,
    { category: { label: found.category.name, value: found.category.id } })
    : {
      question: '',
      shortAnswer: '',
      detailedAnswer: '',
      order: 0,
      visibilityStatus: '',
      category: { label: '', value: '' },
    };
  return result;
};

const mapStateToProps = (state) => ({
  categories: transformDropdownData(state.faqCategories.data),
  pageMode: state.uiFunctionalPage.pageMode,
  dialogVisibility: state.uiFunctionalPage.visibility,
  addingEditing: state.uiFAQ.addingEditing,
  downloading: state.uiFAQ.downloading,
  downloadingDeleting: state.uiFAQ.downloadingDeleting,
  initialValues: getInitialValues(state),
  loadingCategory: state.uiFAQCategory.downloading,
});

const searchFAQCategoryDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearFAQCategories());
    dispatch(downloadFAQCategoriesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(clearFAQCategories());
    dispatch(downloadFAQCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_FAQ_MANAGEMENT_FAQ));
    dispatch(setFAQSearchText(''));
    dispatch(clearFAQs());
    dispatch(setFAQSelectedPageSize(20));
    dispatch(setFAQSelectedOrderBy(INITIAL_ORDER_BY_FAQS));
    dispatch(downloadFAQsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/category/, 'category.id');
    dispatch(setFaqAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearFAQs());
    dispatch(downloadFAQsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_FAQ));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadFAQsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setFAQSelectedPageSize(pageSize));
    dispatch(downloadFAQsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteFAQAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setFAQTappedId(''));
    dispatch(downloadFAQCategoriesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
  onDeletePressed: (id) => {
    dispatch(setFAQTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setFAQCategorySearchText(''));
    dispatch(clearFAQCategories());
    dispatch(downloadFAQCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setFAQTappedId(id));
    dispatch(downloadDeleteFAQAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setFAQSelectedPageSize(pageSize));
    dispatch(clearFAQs());
    dispatch(downloadFAQsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setFaqAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearFAQs());
    dispatch(setFAQSelectedOrderBy(INITIAL_ORDER_BY_FAQS));
    dispatch(downloadFAQsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    question, shortAnswer, detailedAnswer, order, visibilityStatus,
  }) => {
    try {
      await dispatch(addEditFAQAsync(
        question, shortAnswer, detailedAnswer, toNumber(order), visibilityStatus,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setFAQSearchText(text));
      dispatch(clearFAQs());
      await dispatch(downloadFAQsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setFAQSelectedOrderBy(orderBy));
    dispatch(clearFAQs());
    dispatch(downloadFAQsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    question, shortAnswer, detailedAnswer, order, visibilityStatus, categoryId,
  }) => {
    await dispatch(addEditFAQAsync(
      question, shortAnswer, detailedAnswer, toNumber(order), visibilityStatus, categoryId,
    ));
  },
  onViewPressed: (id) => {
    dispatch(setFAQTappedId(id));
    dispatch(downloadDeleteFAQAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeContentText: (text) => {
    dispatch(change(RXFORM_FAQ, RXFIELD_FAQ_DETAILED_ANSWER, text));
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setFAQsSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_FAQ, RXFIELD_FAQ_VISIBILITY_STATUS, option));
    } else {
      dispatch(setFAQsSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_FAQ, RXFIELD_FAQ_VISIBILITY_STATUS, ''));
    }
  },
  onChangeCategoryText: async (text) => {
    dispatch(setFAQCategorySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchFAQCategoryDebounce(dispatch, true);
    }
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setFAQSelectedCategoryId(option.value));
      dispatch(change(RXFORM_FAQ, RXFIELD_FAQ_CATEGORY, option));
    } else {
      dispatch(setFAQSelectedCategoryId(''));
      dispatch(change(RXFORM_FAQ, RXFIELD_FAQ_CATEGORY, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);
