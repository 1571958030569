import { rxFormValidateRequiredFields } from '../../validation';
import { RXFIELD_VOUCHER_CLAIM_CLAIM_NOTES } from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxFormValidateClaimVoucher = (values) => {
  const requiredFields = [
    RXFIELD_VOUCHER_CLAIM_CLAIM_NOTES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
