import { downloadingMembershipRewards, setMembershipReward } from '../simple-action';
import { downloadMembershipRewards, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, membershipRewards, uiMembershipReward } = getState();
  const { token } = authentication;
  const { meta } = membershipRewards;
  const { selectedPageSize, searchBarText, orderBy } = uiMembershipReward;
  const { filterString } = getState().uiFunctionalPage;

  try {
    dispatch(downloadingMembershipRewards(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadMembershipRewards(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setMembershipReward(list));
    }
  } finally {
    dispatch(downloadingMembershipRewards(false));
  }
};
