import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_COUPON_PURCHASE_REQUESTS,
  MENUID_COUPON_PURCHASE_REQUEST, PAGE_MODE_TABLE, RXFORM_COUPON_PURCHASE_REQUEST,
} from '../../constant';
import { transformInitialValues, transformUserDropdownData, toMoment } from '../../helper';
import {
  clearCouponPurchaseRequests, clearUsers, setAdvancedFilterDialogSelectedFilterString,
  setActiveSideMenuItem, setAlertErrorMessage, setCouponPurchaseRequestSearchText,
  setCouponPurchaseRequestSelectedPageSize, setCouponPurchaseRequestSelectedOrderBy,
  setCouponPurchaseRequestTappedId, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText,
  downloadCouponPurchaseRequestAsync, downloadCouponPurchaseRequestsAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import PurchaseRequestPage from './purchase-request.presentation';

const getInitialValues = (state) => {
  const {
    couponPurchaseRequests, uiFunctionalPage, uiCouponPurchaseRequest,
  } = state;
  const { data } = couponPurchaseRequests;
  const { downloadingDeleting, tappedId } = uiCouponPurchaseRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user.fullName,
    validUntil: toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
    items: Object.values(found.items).length > 0 && !downloadingDeleting
      ? Object.values(found.items).map((x, i) => ({
        ...x,
        no: i + 1,
        productCode: x.productCode,
        productName: x.productName,
        quantity: x.quantity,
        individualPrice: x.individualPrice,
        totalPrice: x.totalPrice,
      })) : [],
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    label: '',
    requestStatus: '',
    requestStatusNotes: null,
    merchantReferenceId: '',
    merchantName: '',
    callbackUrl: '',
    fullName: '',
    items: [],
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiCouponPurchaseRequest.downloading,
  downloadingDeleting: state.uiCouponPurchaseRequest.downloadingDeleting,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  totalCurrentPage: state.couponPurchaseRequests.meta.currentPage,
  totalCount: state.couponPurchaseRequests.meta.totalCount,
  loadingUser: state.uiUser.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_COUPON_PURCHASE_REQUEST));
    dispatch(setCouponPurchaseRequestSearchText(''));
    dispatch(clearCouponPurchaseRequests());
    dispatch(setCouponPurchaseRequestSelectedPageSize(20));
    dispatch(setCouponPurchaseRequestSelectedOrderBy(INITIAL_ORDER_BY_COUPON_PURCHASE_REQUESTS));
    dispatch(downloadCouponPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCouponPurchaseRequests());
    dispatch(downloadCouponPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COUPON_PURCHASE_REQUEST));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCouponPurchaseRequestsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCouponPurchaseRequestSelectedPageSize(pageSize));
    dispatch(downloadCouponPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setCouponPurchaseRequestSelectedPageSize(pageSize));
    dispatch(clearCouponPurchaseRequests());
    dispatch(downloadCouponPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCouponPurchaseRequests());
    dispatch(setCouponPurchaseRequestSelectedOrderBy(INITIAL_ORDER_BY_COUPON_PURCHASE_REQUESTS));
    dispatch(downloadCouponPurchaseRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCouponPurchaseRequestSearchText(text));
      dispatch(clearCouponPurchaseRequests());
      await dispatch(downloadCouponPurchaseRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCouponPurchaseRequestSelectedOrderBy(orderBy));
    dispatch(clearCouponPurchaseRequests());
    dispatch(downloadCouponPurchaseRequestsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCouponPurchaseRequestTappedId(id));
    dispatch(downloadCouponPurchaseRequestAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestPage);
