import { SET_VOUCHER_TOKEN_ASSIGNMENTS, ADD_VOUCHER_TOKEN_ASSIGNMENT, CLEAR_VOUCHER_TOKEN_ASSIGNMENTS } from '../action';

const initialState = {
  data: {},
  meta: {
    pageSize: 0,
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VOUCHER_TOKEN_ASSIGNMENTS: {
      return {
        data: { ...action.data.data },
        meta: action.data.meta,
      };
    }
    case ADD_VOUCHER_TOKEN_ASSIGNMENT: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case CLEAR_VOUCHER_TOKEN_ASSIGNMENTS:
      return initialState;
    default:
      return state;
  }
};
