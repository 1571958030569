import { downloadingTotalPersonCount, setTotalPersonCount } from '../simple-action';
import { downloadProfileTotalPersonCount } from '../../../helper';
import {
  FILTERED_RECIPIENTS_COMPANIES_TAG,
  FILTERED_RECIPIENTS_CITIES_TAG, FILTERED_RECIPIENTS_USER_TIER_TAG,
} from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingTotalPersonCount(true));

    const { token } = getState().authentication;
    const {
      selectedCompany, selectedCity, selectedShellMembership,
      selectedUserTier,
    } = getState().notification;

    const companiesTag = selectedCompany
      ? `${FILTERED_RECIPIENTS_COMPANIES_TAG}${selectedCompany}` : '';
    const shellMembershipTag = selectedShellMembership ?? '';
    const selectedUserTierTag = selectedUserTier && selectedUserTier.value ? `${FILTERED_RECIPIENTS_USER_TIER_TAG}${selectedUserTier.value}` : '';
    const cities = selectedCity.length
      ? selectedCity.reduce((p, c) => (!p ? c.value : `${p};${c.value}`), '') : '';
    const citiesTag = cities ? `${FILTERED_RECIPIENTS_CITIES_TAG}${cities}` : '';

    const filterString = `status=enabled${companiesTag}${citiesTag}${shellMembershipTag}${selectedUserTierTag}`;

    const response = await downloadProfileTotalPersonCount(filterString, token);

    dispatch(setTotalPersonCount(response.totalCount));
  } finally {
    dispatch(downloadingTotalPersonCount(false));
  }
};
