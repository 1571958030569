import { downloadGiiasSemarangScannedCode } from '../../../helper';
import { downloadingGiiasSemarangScannedCode, setFunctionalPageMode, setGiiasSemarangScannedCode } from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  const { authentication, uiGiiasSemarangScannedCode } = getState();

  const { token } = authentication;
  const { tappedId } = uiGiiasSemarangScannedCode;

  try {
    dispatch(downloadingGiiasSemarangScannedCode(true));

    const scannedCode = await downloadGiiasSemarangScannedCode(
      tappedId,
      token,
    );

    dispatch(setGiiasSemarangScannedCode(scannedCode));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingGiiasSemarangScannedCode(false));
  }
};
