import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Divider,
  Grid,
  List, ListItem,
  Typography,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  FunctionalPage, SectionTitle,
} from '../../component';
import {
  COLOR_DISABLED_ROW,
  COLOR_BACKGROUND,
  STATUS_DISABLED,
  FILTER_TYPE_DROPDOWN,
  RXSTATE_ACCESSORY_TRANSACTIONS,
  RXSTATE_ACCESSORY_TRANSACTION_PAGE,
  RXFORM_ACCESSORY_TRANSACTION,
  TRANSACTION_STATUSES,
  RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_METHOD,
  RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_DATE,
  RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_STATUS,
  RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_STATUS,
  RXFIELD_ACCESSORY_TRANSACTION_USER_FULLNAME,
  RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_ID,
  RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_STATUS_NOTES,
  RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_VA_NUMBER,
  RXFIELD_ACCESSORY_TRANSACTION_TAX,
  RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_TOTAL,
  RXFIELD_ACCESSORY_TRANSACTION_GRAND_TOTAL,
  RXFIELD_ACCESSORY_TRANSACTION_ITEMS,
  PICKER_DATE_FORMAT,
  RXFIELD_ACCESSORY_TRANSACTION_MEMBER_ID,
  RXFIELD_ACCESSORY_TRANSACTION_EMAIL,
  RXFIELD_ACCESSORY_TRANSACTION_ADDRESS,
  PAYMENT_METHODS, DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_TEXT, PICKER_MODE_DATE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  toMoment, transformUserAddress, transformPaymentMethod, transformTransactionStatus,
  toCurrency,
} from '../../helper';
import TransactionDealerSection from '../../component/transaction/transaction-dealer-section';
import TransactionAccessorySection from '../../component/transaction/transaction-accessory-section';

// eslint-disable-next-line react/prop-types
const renderList = ({ defaultValue: items, onOpenGoogleMaps }) => (
  <List style={{ margin: 0, padding: 0 }}>
    {items.map((item, index) => (
      <ListItem key={item.id}>
        <Grid container spacing={3} item sm md>
          <Grid container spacing={3}>
            <Grid item sm md>
              <Typography variant="subtitle1" style={{ marginLeft: 10, marginBottom: 10, fontWeight: 'bold' }}>
                {`${LocalizedString.accessoryTransactionPage.labelItem} ${index + 1}`}
              </Typography>
            </Grid>
          </Grid>
          <TransactionAccessorySection item={item} />
          <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
          <TransactionDealerSection item={item} onOpenGoogleMaps={onOpenGoogleMaps} />
          {index !== items.length - 1 && <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />}
        </Grid>
      </ListItem>
    ))}
  </List>
);

const renderDialogContent = (
  initialValues,
  downloadingDeleting,
  onOpenGoogleMaps,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_MEMBER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderMemberID}
            label={LocalizedString.accessoryTransactionPage.placeholderMemberID}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderUserFullName}
            label={LocalizedString.accessoryTransactionPage.placeholderUserFullName}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderEmail}
            label={LocalizedString.accessoryTransactionPage.placeholderEmail}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_ADDRESS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderAddress}
            label={LocalizedString.accessoryTransactionPage.placeholderAddress}
            disabled
            loading={downloadingDeleting}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderTransactionID}
            label={LocalizedString.accessoryTransactionPage.placeholderTransactionID}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderTransactionStatus}
            label={LocalizedString.accessoryTransactionPage.placeholderTransactionStatus}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_TRANSACTION_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderTransactionStatusNotes}
            label={LocalizedString.accessoryTransactionPage.placeholderTransactionStatusNotes}
            multiline
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderPaymentStatus}
            label={LocalizedString.accessoryTransactionPage.placeholderPaymentStatus}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_DATE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderPaymentDate}
            label={LocalizedString.accessoryTransactionPage.placeholderPaymentDate}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_METHOD}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderPaymentMethod}
            label={LocalizedString.accessoryTransactionPage.placeholderPaymentMethod}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_VA_NUMBER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderVANumber}
            label={LocalizedString.accessoryTransactionPage.placeholderVANumber}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_TAX}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderTax}
            label={LocalizedString.accessoryTransactionPage.placeholderTax}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_PAYMENT_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderSubTotal}
            label={LocalizedString.accessoryTransactionPage.placeholderSubTotal}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ACCESSORY_TRANSACTION_GRAND_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.accessoryTransactionPage.placeholderGrandTotal}
            label={LocalizedString.accessoryTransactionPage.placeholderGrandTotal}
            disabled
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.accessoryTransactionPage.labelItems} />

    {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
      <Field
        name={RXFIELD_ACCESSORY_TRANSACTION_ITEMS}
        component={renderList}
        placeholder={LocalizedString.accessoryTransactionPage.labelItems}
        label={LocalizedString.accessoryTransactionPage.labelItems}
        defaultValue={initialValues?.items}
        disabled
        loading={downloadingDeleting}
        {...{
          onOpenGoogleMaps,
        }}
      />
    )}
  </Grid>
);

const AccessoryTransactionPage = ({
  users,
  loadingUser,
  downloading,
  handleSubmit,
  onAppear,
  onChangePage,
  onChangePageSize,
  onRefresh,
  onSearchBarTextChanged,
  onSortPressed,
  onViewPressed,
  initialValues,
  downloadingDeleting,
  onCancelPressed,
  onApplyAdvancedFilterPressed,
  onResetAdvancedFilterPressed,
  onChangeUserText,
  onOpenGoogleMaps,
  onDownloadPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_ACCESSORY_TRANSACTIONS}
    uiPage={RXSTATE_ACCESSORY_TRANSACTION_PAGE}
    filterColumns={[
      {
        title: LocalizedString.accessoryTransactionPage.placeholderUser,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.accessoryTransactionPage.placeholderTransactionStatus,
        field: 'transactionStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: TRANSACTION_STATUSES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.accessoryTransactionPage.placeholderPaymentMethod,
        field: 'paymentMethod',
        type: FILTER_TYPE_DROPDOWN,
        data: PAYMENT_METHODS,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        format: PICKER_DATE_FORMAT,
        pickerMode: PICKER_MODE_DATE,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelTransactionID,
        field: 'transactionId',
        type: FILTER_TYPE_TEXT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.accessoryTransactionPage.labelNo,
        field: 'no',
        sorting: false,
        width: 40,
        customFilterAndSearch: (term, rowData) => rowData,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelTransactionID,
        field: 'transactionId',
        sorting: !downloading,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelMemberID,
        field: 'user.userCode',
        sorting: !downloading,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelUserFullName,
        field: 'user.fullName',
        sorting: !downloading,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelEmail,
        field: 'user.email',
        sorting: false,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelAddress,
        field: 'user.homeAddress',
        sorting: false,
        render: ({ user }) => transformUserAddress(user),
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelItemCount,
        field: 'items',
        sorting: false,
        render: ({ items }) => items.length,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelTransactionTotal,
        field: 'totalWithTax',
        sorting: false,
        render: ({ totalWithTax }) => (totalWithTax
          ? `Rp${toCurrency(totalWithTax)}` : null),
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelPaymentMethod,
        field: 'payment.paymentMethod',
        sorting: !downloading,
        render: ({ payment }) => (payment?.paymentMethod
          ? transformPaymentMethod(payment?.paymentMethod) : null),
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelPaymentDate,
        field: 'payment.createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelPaymentStatus,
        field: 'payment.paymentStatus',
        sorting: !downloading,
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelTransactionStatus,
        field: 'transactionStatus',
        sorting: !downloading,
        render: ({ transactionStatus }) => (transactionStatus
          ? transformTransactionStatus(transactionStatus) : null),
      },
      {
        title: LocalizedString.accessoryTransactionPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.accessoryTransactionPage.title}
    useFullWidth
    rowStyle={(rowData) => ({
      backgroundColor:
          rowData.status === STATUS_DISABLED
            ? COLOR_DISABLED_ROW
            : COLOR_BACKGROUND,
    })}
    onCancelPressed={onCancelPressed}
    onDownloadPressed={onDownloadPressed}
    enableSave
    disableCreate
    disableDelete
    disableEdit
  >
    {renderDialogContent(
      initialValues,
      downloadingDeleting,
      onOpenGoogleMaps,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_ACCESSORY_TRANSACTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AccessoryTransactionPage);

AccessoryTransactionPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingUser: PropTypes.bool.isRequired,
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onOpenGoogleMaps: PropTypes.func.isRequired,
};
