import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Field } from 'redux-form';
import {
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_NAME,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_VARIANT_CODE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_PRICE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_DESCRIPTION,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_PICKUP_TIME,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_QUANTITY,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_UNIT_PRICE,
  RXFIELD_ACCESSORY_TRANSACTION_ITEM_NOTES,
} from '../../constant';

import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { AccessoryTransactionShape } from '../../type';

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: 'stretch',
  },
}));

const InvoiceAccessorySection = ({ item }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid item sm md>
        <Typography variant="subtitle2" style={{ marginLeft: 10, marginBottom: 10, fontWeight: 'bold' }}>
          {LocalizedString.invoicePage.labelAccessory}
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_VARIANT_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderVariantCode}
              label={LocalizedString.invoicePage.placeholderVariantCode}
              defaultValue={item.accessory.variantCode}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderName}
              label={LocalizedString.invoicePage.placeholderName}
              defaultValue={item.accessory.name}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_PRICE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderPrice}
              label={LocalizedString.invoicePage.placeholderPrice}
              defaultValue={item.accessory.price}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderDescription}
              label={LocalizedString.invoicePage.placeholderDescription}
              defaultValue={item.accessory.description}
              disabled
              multiline
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_PICKUP_TIME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderPickupTime}
              label={LocalizedString.invoicePage.placeholderPickupTime}
              defaultValue={item.pickupTime}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_QUANTITY}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderQuantity}
              label={LocalizedString.invoicePage.placeholderQuantity}
              defaultValue={item.quantity}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_UNIT_PRICE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderUnitPrice}
              label={LocalizedString.invoicePage.placeholderUnitPrice}
              defaultValue={item.unitPrice}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ACCESSORY_TRANSACTION_ITEM_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.invoicePage.placeholderNotes}
              label={LocalizedString.invoicePage.placeholderNotes}
              defaultValue={item.notes}
              disabled
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvoiceAccessorySection;

InvoiceAccessorySection.propTypes = {
  item: AccessoryTransactionShape.isRequired,
};
