import { downloadTransactionPending } from '../../../helper';
import { downloadingTransactionPending, setTransactionPending } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingTransactionPending(true));
    const bookingCreated = await downloadTransactionPending(token, startDate, endDate);

    dispatch(setTransactionPending(bookingCreated));
  } finally {
    dispatch(downloadingTransactionPending(false));
  }
};
