import { reset } from 'redux-form';
import {
  addingEditingCarViewingEvent, clearCarViewingEvents, setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import {
  addCarViewingEvent, editCarViewingEvent, toNumber, toMoment,
} from '../../../helper';
import {
  RXFORM_CAR_VIEWING_EVENT, PAGE_MODE_TABLE, REVERSED_ISO_DATE_FORMAT, REST_BASE_URL,
} from '../../../constant';
import downloadCarViewingEventsAsync from './downloadCarViewingEventsAsync';

export default (
  name, description, location, startDate, endDate,
  openTime, closeTime, visibility, cityId,
  voucherIds, image, quotaPerSlot,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCarViewingEvent(true));

    const { token } = getState().authentication;
    const { tappedId, selectedCityId, selectedVoucherId } = getState().uiCarViewingEvent;
    const { data } = getState().carViewingEvents;

    const found = data[tappedId];

    if (tappedId) {
      const city = selectedCityId || found.city.id;
      const voucherReward = selectedVoucherId || found.voucherRewards[0].voucherId;

      const body = {
        id: tappedId,
        name,
        description,
        location,
        startDate: toMoment(startDate).format(REVERSED_ISO_DATE_FORMAT),
        endDate: toMoment(endDate).format(REVERSED_ISO_DATE_FORMAT),
        openTime,
        closeTime,
        visibility,
        cityId: city,
        voucherIds: [voucherReward],
        image: image.includes(REST_BASE_URL) ? '' : image,
        quotaPerSlot: toNumber(quotaPerSlot),
      };
      await editCarViewingEvent(body, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      const body = {
        name,
        description,
        location,
        startDate: toMoment(startDate).format(REVERSED_ISO_DATE_FORMAT),
        endDate: toMoment(endDate).format(REVERSED_ISO_DATE_FORMAT),
        openTime,
        closeTime,
        visibility,
        cityId: selectedCityId,
        voucherIds: [selectedVoucherId],
        image,
        quotaPerSlot: toNumber(quotaPerSlot),
      };
      await addCarViewingEvent(body, token);
    }

    dispatch(reset(RXFORM_CAR_VIEWING_EVENT));
    dispatch(clearCarViewingEvents());
    dispatch(downloadCarViewingEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingCarViewingEvent(false));
  }
};
