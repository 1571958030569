import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  FILTER_TYPE_DROPDOWN,
  RXSTATE_WASTE_DEALER_CODES,
  RXSTATE_WASTE_DEALER_CODE_PAGE,
  RXFIELD_WASTE_DEALER_CODE_DEALER,
  RXFIELD_WASTE_DEALER_CODE_DEALER_ID,
  RXFIELD_WASTE_DEALER_CODE_DEALER_CODE,
  RXFIELD_WASTE_DEALER_CODE_DEALER_NAME,
  RXFORM_WASTE_DEALER_CODE,
  PAGE_MODE_VIEW,
  PAGE_MODE_TABLE,
} from '../../constant';

const renderDialogContent = (
  addingEditing,
  downloadingDeleting,
  pageMode,
  initialValues,
  dealers,
  loadingDealers,
  onChangeDealerText,
  onDealerOptionSelected,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_DEALER_CODE_DEALER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.dealerCode.placeholderDealerId}
          label={LocalizedString.dealerCode.labelDealerId}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          hidden={pageMode === PAGE_MODE_TABLE}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_DEALER_CODE_DEALER_NAME}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.dealerCode.placeholderDealerName}
          label={LocalizedString.dealerCode.labelDealerName}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          data={dealers}
          value={initialValues.dealerName}
          loading={loadingDealers}
          onChangeText={onChangeDealerText}
          onOptionSelected={onDealerOptionSelected}
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_DEALER_CODE_DEALER_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.dealerCode.placeholderDealerCode}
          label={LocalizedString.dealerCode.labelDealerCode}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          hidden={pageMode === PAGE_MODE_TABLE}
        />
      </Grid>
    </Grid>
  </Grid>
);

const WasteDealerCodePage = ({
  addingEditing,
  dealers,
  downloading,
  downloadingDeleting,
  loadingDealers,
  initialValues,
  pageMode,
  handleSubmit,
  onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed,
  onAppear,
  onChangeDealerText,
  onChangePage,
  onChangePageSize,
  onCreatePressed,
  onConfirmDeletePressed,
  onDealerOptionSelected,
  onDeletePressed,
  onSortPressed,
  onRefresh,
  onSearchBarTextChanged,
  onResetAdvancedFilterPressed,
  onSubmitPressed,
  onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_WASTE_DEALER_CODES}
    uiPage={RXSTATE_WASTE_DEALER_CODE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.wasteCollection.labelNo,
        field: 'no',
        sorting: false,
        width: 40,
        customFilterAndSearch: (term, rowData) => rowData,
      },
      {
        title: LocalizedString.dealerCode.labelDealerId,
        field: 'dealer.id',
        sorting: !downloading,
      },
      {
        title: LocalizedString.dealerCode.labelDealerName,
        field: 'dealer.name',
        sorting: !downloading,
      },
      {
        title: LocalizedString.dealerCode.labelDealerCode,
        field: 'code',
        sorting: !downloading,
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.wasteCollection.labelDealer,
        field: RXFIELD_WASTE_DEALER_CODE_DEALER,
        type: FILTER_TYPE_DROPDOWN,
        data: dealers,
        loading: loadingDealers,
        onChangeFilterText: onChangeDealerText,
        useDropdownValue: true,
      },
    ]}
    title={LocalizedString.dealerCode.title}
    createNewButtonCaption={
      LocalizedString.dealerCode.buttonCaptionCreateDealerCode
    }
    deleteButtonCaption={
      LocalizedString.dealerCode.buttonCaptionDeleteDealerCode
    }
    disableEdit
    useFullWidth
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onSubmitPressed={onSubmitPressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
  >
    {renderDialogContent(
      addingEditing,
      downloadingDeleting,
      pageMode,
      initialValues,
      dealers,
      loadingDealers,
      onChangeDealerText,
      onDealerOptionSelected,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WASTE_DEALER_CODE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WasteDealerCodePage);

WasteDealerCodePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  dealers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingDealers: PropTypes.bool.isRequired,
  pageMode: PropTypes.string.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeDealerText: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onDealerOptionSelected: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  addingEditing: PropTypes.bool.isRequired,
};
