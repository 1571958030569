
import { INITIAL_ORDER_BY_GARUDA_AIRPORTS } from '../../constant';
import {
  ADDING_EDITING_GARUDA_AIRPORT, DOWNLOADING_DELETING_GARUDA_AIRPORT,
  DOWNLOADING_GARUDA_AIRPORTS, SET_GARUDA_AIRPORT_SEARCH_TEXT,
  SET_GARUDA_AIRPORT_SELECTED_ORDER_BY, SET_GARUDA_AIRPORT_SELECTED_PAGE_SIZE,
  SET_GARUDA_AIRPORT_TAPPED_ID, VIEW_MAP_VOUCHER_TOKEN_DIALOG,
  SET_MAP_TOKEN_SELECTED_AIRPORT, SET_MAP_TOKEN_AIRPORT_SEARCH_TEXT,
  DOWNLOADING_MAP_TOKEN_AIRPORTS, COUNTING_VOUCHER_TOKEN,
  SET_VOUCHER_TOKEN_COUNT, ENABLE_MAP_TOKEN_BUTTON,
  MAPPING_TOKEN_TO_AIRPORT,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  selectedAirport: '',
  viewModal: false,
  orderBy: INITIAL_ORDER_BY_GARUDA_AIRPORTS,
  downloadingAirports: false,
  airportSearchBarText: '',
  tokenCount: 0,
  countingVoucherToken: false,
  enableMapTokenButton: false,
  mapping: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_GARUDA_AIRPORTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_GARUDA_AIRPORT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_GARUDA_AIRPORT:
      return { ...state, downloadingDeleting: action.status };
    case SET_GARUDA_AIRPORT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_GARUDA_AIRPORT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_GARUDA_AIRPORT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_GARUDA_AIRPORT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case VIEW_MAP_VOUCHER_TOKEN_DIALOG:
      return { ...state, viewModal: action.view };
    case SET_MAP_TOKEN_SELECTED_AIRPORT:
      return { ...state, selectedAirport: action.option };
    case DOWNLOADING_MAP_TOKEN_AIRPORTS:
      return { ...state, downloadingAirports: action.status };
    case SET_MAP_TOKEN_AIRPORT_SEARCH_TEXT:
      return { ...state, airportSearchBarText: action.text };
    case COUNTING_VOUCHER_TOKEN:
      return { ...state, countingVoucherToken: action.status };
    case SET_VOUCHER_TOKEN_COUNT:
      return { ...state, tokenCount: action.count };
    case ENABLE_MAP_TOKEN_BUTTON:
      return { ...state, enableMapTokenButton: action.toggle };
    case MAPPING_TOKEN_TO_AIRPORT:
      return { ...state, mapping: action.status };
    default: return state;
  }
};
