import { connect } from 'react-redux';
import { change, formValueSelector, reset } from 'redux-form';
import {
  APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES, approval, DATE_TIME_FORMAT_WITHOUT_PIPE,
  INITIAL_ORDER_BY_VOUCHERS, MENUID_VOUCHER_VOUCHER, PAGE_MODE_TABLE, PAYMENT_TYPE_COIN,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_VOUCHER_ACTION_TYPE,
  RXFIELD_VOUCHER_APPLICABLE_AREA_MODE, RXFIELD_VOUCHER_APPLICABLE_BRANCHES,
  RXFIELD_VOUCHER_CATEGORY, RXFIELD_VOUCHER_DESCRIPTION, RXFIELD_VOUCHER_IMAGE,
  RXFIELD_VOUCHER_IS_PROMOTED, RXFIELD_VOUCHER_MERCHANT, RXFIELD_VOUCHER_PAYMENT_TYPE,
  RXFIELD_VOUCHER_STATUS, RXFIELD_VOUCHER_TAGS, RXFIELD_VOUCHER_TERMS_AND_CONDITIONS,
  RXFIELD_VOUCHER_TYPE, RXFIELD_VOUCHER_VISIBILITY, RXFORM_VOUCHER, VOUCHER_PAYMENT_TYPES,
  VOUCHER_TYPE_PUBLIC, TANAMERA_FLAG, RXFIELD_VOUCHER_TANAMERA_FLAG,
  GARUDA_FLAG, RXFIELD_VOUCHER_GARUDA_FLAG,
} from '../../constant';
import {
  localDateToUtc, transformApplicableBranches, transformInitialValues, transformDropdownData,
  toMoment, toNumber,
} from '../../helper';
import {
  addingVoucherApplicableBranches, addVoucher, clearCompaniesMData, clearVouchers,
  clearVoucherCategories, copyingVoucherDeepLink, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData, setCompanySearchTextMData,
  setVoucherAdvancedFilterDialogSelectedFilterString, setVoucherCategorySearchText,
  setVoucherSearchText, setVoucherSelectedApplicableBranches,
  setVoucherIsGarudaCompanyName, setVoucherIsTanameraCompanyName,
  setVoucherSelectedIsPromoted, setVoucherSelectedMerchantId, setVoucherSelectedOrderBy,
  setVoucherSelectedPageSize, setVoucherSelectedPaymentType, setVoucherSelectedTags,
  setVoucherSelectedTanameraFlag,
  setVoucherSelectedVoucherCategoryId, setVoucherTableDataApplicableBranches, setVoucherTappedId,
  addEditVoucherAsync, downloadVoucherAsync, downloadVoucherCategoriesAsync, downloadVouchersAsync,
  enableDisableVoucherAsync, setVoucherSelectedGarudaFlag, checkTanameraGarudaFlagAsync,
  saveVouchersAsync,
} from '../../redux/action';
import {
  clearBranches, setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText,
  downloadBranchesAsync, downloadMDataCompaniesAsync,
} from '../../../../redux/action';
import VoucherPage from './voucher.presentation';

const getInitialValues = (state) => {
  const {
    vouchers, uiFunctionalPage, uiVoucher,
  } = state;
  const { data } = vouchers;
  const {
    addingApplicableBranches, downloadingDeleting, tableDataApplicableBranches, tappedId,
  } = uiVoucher;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting && !addingApplicableBranches
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    merchant: found.merchant
      ? { label: found.merchant.name, value: found.merchant.id }
      : { label: '', value: '' },
    tanameraFlag: found.tanameraFlag
      ? TANAMERA_FLAG.find((item) => item.value === found.tanameraFlag)
      : { label: '', value: '' },
    category: found.category
      ? { label: found.category.name, value: found.category.id }
      : { label: '', value: '' },
    paymentType: found.paymentType === PAYMENT_TYPE_COIN ? VOUCHER_PAYMENT_TYPES[0]
      : VOUCHER_PAYMENT_TYPES[1],
    tags: found.tags ? { label: found.tags, value: found.tags } : { label: '', value: '' },
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    validFrom: found.validFrom ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
    applicableBranches: transformApplicableBranches(tableDataApplicableBranches),
    pageMode,
    isPromoted: found.isPromoted ? approval[0] : approval[1],
    order: found.order || 0,
    manualClaimMessage: found.manualClaimMessage || '',
    garudaFlag: found.garudaFlag
      ? GARUDA_FLAG.find((item) => item.value === found.garudaFlag)
      : { label: '', value: '' },
  }) : {
    title: '',
    code: '',
    type: '',
    paymentType: { label: '', value: '' },
    point: 0,
    maxPurchasePerUser: 0,
    minAchievementPoinToPurchase: 0,
    maxAchievementPoinToPurchase: 0,
    actionType: '',
    visibility: '',
    merchant: { label: '', value: '' },
    applicableAreaMode: '',
    applicableBranches: tableDataApplicableBranches.length > 0 ? tableDataApplicableBranches : [],
    category: { label: '', value: '' },
    validFrom: '',
    validUntil: '',
    image: '',
    description: '',
    descriptionFormat: '',
    termsAndConditions: '',
    termsAndConditionsFormat: '',
    status: '',
    pageMode,
    isPromoted: { label: '', value: '' },
    order: 0,
    tags: { label: '', value: '' },
    tanameraFlag: { label: '', value: '' },
    garudaFlag: { label: '', value: '' },
    monetaryValue: 0,
  };
  return result;
};

const selector = formValueSelector(RXFORM_VOUCHER);

const mapStateToProps = (state) => {
  const currentApplicableBranches = state.uiVoucher.tableDataApplicableBranches;
  const branches = transformDropdownData(state.branches.data);
  return ({
    branches: currentApplicableBranches.length > 0
      ? branches.filter((x) => currentApplicableBranches.every((y) => y.value !== x.value))
      : branches,
    merchants: transformDropdownData(state.companiesMdata.data),
    voucherCategories: transformDropdownData(state.voucherCategories.data),
    pageMode: state.uiFunctionalPage.pageMode,
    tableDataApplicableBranches: state.uiVoucher.tableDataApplicableBranches,
    addingApplicableBranches: state.uiVoucher.addingApplicableBranches,
    addingEditing: state.uiVoucher.addingEditing,
    downloading: state.uiVoucher.downloading,
    downloadingDeleting: state.uiVoucher.downloadingDeleting,
    enablingDisabling: state.uiVoucher.enablingDisabling,
    selectedApplicableBranches: state.uiVoucher.selectedApplicableBranches,
    selectedMerchantId: state.uiVoucher.selectedMerchantId,
    loadingBranches: state.uiBranch.downloading,
    loadingMerchant: state.uiCompany.downloading,
    loadingVoucherCategory: state.uiVoucherCategory.downloading,
    initialValues: getInitialValues(state),
    isPublicType: selector(state, RXFIELD_VOUCHER_TYPE) === VOUCHER_TYPE_PUBLIC,
    isPromoted: !!selector(state, RXFIELD_VOUCHER_IS_PROMOTED)?.value || false,
    isSpecificBranches: selector(state, RXFIELD_VOUCHER_APPLICABLE_AREA_MODE)
  === APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES,
    currentVoucherStatus: state.vouchers.data[state.uiFunctionalPage.tappedId]
      ? state.vouchers.data[state.uiFunctionalPage.tappedId].status : '',
    currentMerchant: selector(state, RXFIELD_VOUCHER_MERCHANT)?.value || '',
    tappedId: state.uiFunctionalPage.tappedId || '',
    tappedVoucher: state.vouchers.data[state.uiVoucher.tappedId],
    vouchers: state.vouchers.data,
    copying: state.uiVoucher.copying,
    isGarudaCompanyName: state.uiVoucher.isGarudaCompanyName || false,
    isTanameraCompanyName: state.uiVoucher.isTanameraCompanyName || false,
  });
};

const mapDispatchToProps = (dispatch) => ({
  onActionTypeOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_ACTION_TYPE, option));
    } else {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_ACTION_TYPE, ''));
    }
  },
  onAddApplicableBranchesPressed: async (defaultValue, newInitialValue, selectedOption,
    currentData, currentMerchant) => {
    if (selectedOption) {
      try {
        dispatch(addingVoucherApplicableBranches(true));
        if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
          const result = {
            ...currentData,
            applicableBranches: defaultValue.length > 0
              ? [...defaultValue, selectedOption] : [selectedOption],
          };
          await dispatch(addVoucher(result));
          dispatch(setVoucherTableDataApplicableBranches(result.applicableBranches));
          dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_APPLICABLE_BRANCHES,
            result.applicableBranches));
        } else {
          const newData = newInitialValue.length > 0
            ? [...newInitialValue.filter((x) => (x.value !== selectedOption.value)), selectedOption]
            : [selectedOption];
          await dispatch(setVoucherTableDataApplicableBranches(newData));
          dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_APPLICABLE_BRANCHES, newData));
        }
        dispatch(setBranchAdvancedFilterDialogSelectedFilterString(`status=enabled|company.id=${currentMerchant}`));
        dispatch(setBranchSearchText(''));
        dispatch(clearBranches());
        dispatch(downloadBranchesAsync(1))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      } finally {
        dispatch(setVoucherSelectedApplicableBranches(null));
        dispatch(addingVoucherApplicableBranches(false));
      }
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(setVoucherCategorySearchText(''));
    dispatch(clearCompaniesMData());
    dispatch(clearVoucherCategories());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadVoucherCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_VOUCHER_VOUCHER));
    dispatch(setVoucherSearchText(''));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearVouchers());
    dispatch(setVoucherSelectedPageSize(20));
    dispatch(setVoucherSelectedOrderBy(INITIAL_ORDER_BY_VOUCHERS));
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplicableAreaModeOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_APPLICABLE_AREA_MODE, option));
    } else {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_APPLICABLE_AREA_MODE, ''));
    }
  },
  onApplicableBranchesOptionSelected: (option) => {
    if (option) {
      dispatch(setVoucherSelectedApplicableBranches(option));
    } else {
      dispatch(setVoucherSelectedApplicableBranches(null));
    }
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/merchant/, 'merchant.id').replace(/category/, 'category.id');
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_VOUCHER));
    dispatch(setVoucherSelectedMerchantId(''));
    dispatch(setVoucherSelectedVoucherCategoryId(''));
    dispatch(setVoucherSelectedApplicableBranches(null));
    dispatch(setVoucherTableDataApplicableBranches([]));
    dispatch(setVoucherSelectedPaymentType(''));
    dispatch(setVoucherSelectedTags(''));
    dispatch(setVoucherSelectedTanameraFlag(''));
    dispatch(setVoucherSelectedGarudaFlag(''));
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setVoucherSelectedVoucherCategoryId(option.value));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_CATEGORY, option));
    } else {
      dispatch(setVoucherSelectedVoucherCategoryId(''));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_CATEGORY, ''));
    }
  },
  onChangeApplicableBranchesText: async (text, currentMerchant) => {
    try {
      dispatch(setBranchSearchText(text));
      if (currentMerchant) {
        dispatch(setBranchAdvancedFilterDialogSelectedFilterString(`status=enabled|company.id=${currentMerchant}`));
        dispatch(clearBranches());
        await dispatch(downloadBranchesAsync(1));
      } else {
        dispatch(clearBranches());
      }
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCategoryText: async (text) => {
    try {
      dispatch(setVoucherCategorySearchText(text));
      dispatch(clearVoucherCategories());
      await dispatch(downloadVoucherCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDescriptionText: (text) => {
    dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_DESCRIPTION, text));
  },
  onChangeMerchantText: async (text) => {
    try {
      if (!text) {
        dispatch(setBranchSearchText(''));
        dispatch(clearBranches());
        dispatch(setVoucherSelectedApplicableBranches(null));
      }
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadVouchersAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setVoucherSelectedPageSize(pageSize));
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeTermsConditionsText: (text) => {
    dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TERMS_AND_CONDITIONS, text));
  },
  onConfirmPressed: () => {
    dispatch(enableDisableVoucherAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCopyPressed: (status) => {
    dispatch(copyingVoucherDeepLink(!status));
  },
  onCreatePressed: () => {
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setVoucherTappedId(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(setVoucherCategorySearchText(''));
    dispatch(clearCompaniesMData());
    dispatch(clearVoucherCategories());
    dispatch(setVoucherTableDataApplicableBranches([]));
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadVoucherCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_GARUDA_FLAG, ''));
    dispatch(setVoucherIsGarudaCompanyName(false));
    dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TANAMERA_FLAG, ''));
    dispatch(setVoucherIsTanameraCompanyName(false));
  },
  onDeleteApplicableBranchesPressed: (newValue, currentData, currentMerchant) => {
    if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
      const result = { ...currentData, applicableBranches: newValue };
      dispatch(addVoucher(result));
      dispatch(setVoucherTableDataApplicableBranches(result.applicableBranches));
      dispatch(setBranchAdvancedFilterDialogSelectedFilterString(`status=enabled|company.id=${currentMerchant}`));
      dispatch(setBranchSearchText(''));
      dispatch(clearBranches());
      dispatch(downloadBranchesAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setVoucherTableDataApplicableBranches(newValue));
    }
    dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_APPLICABLE_BRANCHES, newValue));
  },
  onDownloadPressed: () => {
    dispatch(saveVouchersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEditPressed: (id, data) => {
    dispatch(setVoucherTappedId(id));
    dispatch(downloadVoucherAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString(`status=enabled|company.id=${data[id]?.merchant.id}`));
    dispatch(setCompanySearchTextMData(''));
    dispatch(setVoucherCategorySearchText(''));
    dispatch(setBranchSearchText(''));
    dispatch(clearCompaniesMData());
    dispatch(clearVoucherCategories());
    dispatch(clearBranches());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadVoucherCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(checkTanameraGarudaFlagAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setVoucherTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_IMAGE, image));
  },
  onIsPromotedSelected: (option) => {
    if (option) {
      dispatch(setVoucherSelectedIsPromoted(option));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_IS_PROMOTED, option));
    } else {
      dispatch(setVoucherSelectedIsPromoted(''));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_IS_PROMOTED, ''));
    }
  },
  onMerchantOptionSelected: async (option, prevOption, currentData) => {
    try {
      dispatch(addingVoucherApplicableBranches(true));
      if (option) {
        if (option.value !== prevOption) {
          if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
            const result = { ...currentData, applicableBranches: [] };
            await dispatch(addVoucher(result));
          }
          dispatch(setVoucherTableDataApplicableBranches([]));
          dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_APPLICABLE_BRANCHES, []));
        }
        dispatch(setBranchAdvancedFilterDialogSelectedFilterString(`status=enabled|company.id=${option.value}`));
        dispatch(setBranchSearchText(''));
        dispatch(clearBranches());
        dispatch(downloadBranchesAsync(1))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        dispatch(setVoucherSelectedMerchantId(option.value));
        dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_MERCHANT, option));
        dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TANAMERA_FLAG, ''));
        dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_GARUDA_FLAG, ''));
        dispatch(checkTanameraGarudaFlagAsync(option.label))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      } else {
        dispatch(setVoucherSelectedMerchantId(''));
        dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_MERCHANT, ''));
        if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
          const result = { ...currentData, applicableBranches: [] };
          await dispatch(addVoucher(result));
        }
        dispatch(setVoucherTableDataApplicableBranches([]));
        dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_APPLICABLE_BRANCHES, []));
        dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_GARUDA_FLAG, ''));
        dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TANAMERA_FLAG, ''));
        dispatch(setVoucherIsGarudaCompanyName(false));
        dispatch(setVoucherIsTanameraCompanyName(false));
      }
    } finally {
      dispatch(setVoucherSelectedApplicableBranches(null));
      dispatch(addingVoucherApplicableBranches(false));
    }
  },
  onPaymentTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setVoucherSelectedPaymentType(option));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_PAYMENT_TYPE, option));
    } else {
      dispatch(setVoucherSelectedPaymentType(''));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_PAYMENT_TYPE, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setVoucherSelectedPageSize(pageSize));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearVouchers());
    dispatch(setVoucherSelectedOrderBy(INITIAL_ORDER_BY_VOUCHERS));
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, code, type, point, maxPurchasePerUser, minAchievementPoinToPurchase,
    maxAchievementPoinToPurchase, actionType, visibility, applicableAreaMode,
    validFrom, validUntil, image, description, termsAndConditions, status,
    order, manualClaimMessage, monetaryValue,
  }) => {
    try {
      await dispatch(addEditVoucherAsync(title, code, type, toNumber(point),
        toNumber(maxPurchasePerUser), toNumber(minAchievementPoinToPurchase),
        toNumber(maxAchievementPoinToPurchase), actionType, visibility, applicableAreaMode,
        localDateToUtc(validFrom), localDateToUtc(validUntil), image,
        description, termsAndConditions, status, order, manualClaimMessage,
        toNumber(monetaryValue, true)));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setVoucherSelectedOrderBy(orderBy));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onStatusOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_STATUS, option));
    } else {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_STATUS, ''));
    }
  },
  onSubmitPressed: async ({
    title, code, type, point, maxPurchasePerUser, minAchievementPoinToPurchase,
    maxAchievementPoinToPurchase, actionType, visibility, applicableAreaMode,
    validFrom, validUntil, image, description, termsAndConditions, status,
    order, manualClaimMessage, monetaryValue,
  }) => {
    await dispatch(addEditVoucherAsync(title, code, type, toNumber(point),
      toNumber(maxPurchasePerUser), toNumber(minAchievementPoinToPurchase),
      toNumber(maxAchievementPoinToPurchase), actionType, visibility, applicableAreaMode,
      localDateToUtc(validFrom), localDateToUtc(validUntil), image, description,
      termsAndConditions, status, order, manualClaimMessage, toNumber(monetaryValue, true)));
  },
  onTagsOptionSelected: (option) => {
    if (option) {
      dispatch(setVoucherSelectedTags(option));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TAGS, option));
    } else {
      dispatch(setVoucherSelectedTags(''));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TAGS, ''));
    }
  },
  onTanameraFlagOptionSelected: (option) => {
    if (option) {
      dispatch(setVoucherSelectedTanameraFlag(option));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TANAMERA_FLAG, option));
    } else {
      dispatch(setVoucherSelectedTanameraFlag(''));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TANAMERA_FLAG, ''));
    }
  },
  onTypeOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TYPE, option));
    } else {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_TYPE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setVoucherTappedId(id));
    dispatch(downloadVoucherAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(checkTanameraGarudaFlagAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onVisibilityOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_VISIBILITY, option));
    } else {
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_VISIBILITY, ''));
    }
  },
  onGarudaFlagOptionSelected: (option) => {
    if (option) {
      dispatch(setVoucherSelectedGarudaFlag(option));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_GARUDA_FLAG, option));
    } else {
      dispatch(setVoucherSelectedGarudaFlag(''));
      dispatch(change(RXFORM_VOUCHER, RXFIELD_VOUCHER_GARUDA_FLAG, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherPage);
