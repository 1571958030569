import {
  addTestVehicle, clearTestVehicles, downloadingDeletingTestVehicle, setAlertErrorMessage,
  setFunctionalPageMode, setTestVehicleTappedId,
} from '../simple-action';
import { downloadTestVehicle, deleteTestVehicle } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadTestVehiclesAsync from './downloadTestVehiclesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingTestVehicle(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTestVehicle;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteTestVehicle(tappedId, token);
      dispatch(setTestVehicleTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearTestVehicles());
      dispatch(downloadTestVehiclesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadTestVehicle(tappedId, token);
      dispatch(addTestVehicle(result));
    }
  } finally {
    dispatch(downloadingDeletingTestVehicle(false));
  }
};
