import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, PAGE_MODE_TABLE, PAGE_MODE_VIEW,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE,
  RXFIELD_COUPON_VOID_REQUEST_CALLBACK_URL, RXFIELD_COUPON_VOID_REQUEST_MERCHANT_NAME,
  RXFIELD_COUPON_VOID_REQUEST_MERCHANT_REFERENCE_ID, RXFIELD_COUPON_VOID_REQUEST_COUPON,
  RXFIELD_COUPON_VOID_REQUEST_COUPON_ID, RXFIELD_COUPON_VOID_REQUEST_COUPON_AMOUNT,
  RXFIELD_COUPON_VOID_REQUEST_COUPON_NAME, RXFIELD_COUPON_VOID_REQUEST_REASON,
  RXFIELD_COUPON_VOID_REQUEST_REQUEST_STATUS, RXFIELD_COUPON_VOID_REQUEST_REQUEST_STATUS_NOTES,
  RXFIELD_COUPON_VOID_REQUEST_USER, RXFIELD_COUPON_VOID_REQUEST_USER_FULLNAME,
  RXFIELD_COUPON_VOID_REQUEST_USER_ID, RXFORM_COUPON_VOID_REQUEST, RXSTATE_COUPON_VOID_REQUESTS,
  RXSTATE_COUPON_VOID_REQUEST_PAGE, SUBMISSION_STATUS_VALUES,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateVoidCouponRequest } from '../../validation';

const renderDialogContent = (
  addingEditing, pageMode, coupons, initialValues,
  loadingCoupon, onChangeCouponText, onCouponOptionSelected,
) => {
  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_REASON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderReason}
            label={LocalizedString.voidRequestPage.labelReason}
            disabled={addingEditing}
            multiline
            required
          />
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_COUPON}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderCouponId}
            label={LocalizedString.voidRequestPage.labelCouponId}
            data={coupons}
            value={initialValues.coupon}
            loading={loadingCoupon}
            onChangeText={onChangeCouponText}
            onOptionSelected={onCouponOptionSelected}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_MERCHANT_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderMerchantReferenceId}
            label={LocalizedString.voidRequestPage.labelMerchantReferenceId}
            disabled={addingEditing}
            required
          />
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_CALLBACK_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderCallbackUrl}
            label={LocalizedString.voidRequestPage.labelCallbackUrl}
            multiline
            disabled={addingEditing}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_REASON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderReason}
            label={LocalizedString.voidRequestPage.labelReason}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderMerchantName}
            label={LocalizedString.voidRequestPage.labelMerchantName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_MERCHANT_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderMerchantReferenceId}
            label={LocalizedString.voidRequestPage.labelMerchantReferenceId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderUserId}
            label={LocalizedString.voidRequestPage.labelUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderUserFullname}
            label={LocalizedString.voidRequestPage.labelUserFullname}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_REQUEST_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderRequestStatus}
            label={LocalizedString.voidRequestPage.labelRequestStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_REQUEST_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderRequestStatusNote}
            label={LocalizedString.voidRequestPage.labelRequestStatusNote}
            multiline
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_CALLBACK_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderCallbackUrl}
            label={LocalizedString.voidRequestPage.labelCallbackUrl}
            multiline
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_COUPON_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderCouponId}
            label={LocalizedString.voidRequestPage.labelCouponId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_COUPON_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderCouponName}
            label={LocalizedString.voidRequestPage.labelCouponName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_COUPON_VOID_REQUEST_COUPON_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voidRequestPage.placeholderCouponAmount}
            label={LocalizedString.voidRequestPage.labelCouponAmount}
            disabled
          />
        </Grid>

      </Grid>
    </Grid>
  );
};

const VoidRequestPage = ({
  onAppear, downloading, pageMode, initialValues,
  onCancelPressed, handleSubmit,
  onChangePage, onSearchBarTextChanged,
  onChangePageSize, onRefresh,
  onSortPressed, onViewPressed,
  onSubmitPressed, addingEditing,
  users, onChangeUserText, loadingUser,
  onAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onCreatePressed,
  loadingCoupon, coupons, onChangeCouponText,
  onCouponOptionSelected,
}) => (
  <FunctionalPage
    data={RXSTATE_COUPON_VOID_REQUESTS}
    uiPage={RXSTATE_COUPON_VOID_REQUEST_PAGE}
    filterColumns={[
      {
        title: LocalizedString.voidRequestPage.labelCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voidRequestPage.labelCouponId,
        field: RXFIELD_COUPON_VOID_REQUEST_COUPON,
        type: FILTER_TYPE_DROPDOWN,
        data: coupons,
        loading: loadingCoupon,
        onChangeFilterText: onChangeCouponText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voidRequestPage.labelMerchantName,
        field: RXFIELD_COUPON_VOID_REQUEST_MERCHANT_NAME,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.voidRequestPage.labelRequestStatus,
        field: RXFIELD_COUPON_VOID_REQUEST_REQUEST_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: SUBMISSION_STATUS_VALUES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voidRequestPage.labelUserFullname,
        field: RXFIELD_COUPON_VOID_REQUEST_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.voidRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.voidRequestPage.labelUserFullname, field: 'point.user.fullName', sorting: !downloading },
      { title: LocalizedString.voidRequestPage.labelReason, field: 'reason', sorting: !downloading },
      { title: LocalizedString.voidRequestPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
      { title: LocalizedString.voidRequestPage.labelCouponName, field: 'point.label', sorting: !downloading },
      { title: LocalizedString.voidRequestPage.labelCouponAmount, field: 'point.amount', sorting: !downloading },
      { title: LocalizedString.voidRequestPage.labelRequestStatus, field: 'requestStatus', sorting: !downloading },
      { title: LocalizedString.voidRequestPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.voidRequestPage.title}
    createNewButtonCaption={LocalizedString.voidRequestPage.buttonCaptionCreateNew}
    onSubmitPressed={onSubmitPressed}
    onCreatePressed={onCreatePressed}
    useFullWidth
    disableDelete
    disableEdit
  >
    {renderDialogContent(addingEditing, pageMode, coupons, initialValues,
      loadingCoupon, onChangeCouponText, onCouponOptionSelected)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_COUPON_VOID_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidateVoidCouponRequest,
})(VoidRequestPage);

VoidRequestPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  coupons: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingCoupon: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeCouponText: PropTypes.func.isRequired,
  onCouponOptionSelected: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
