import { downloadingEvChargingUrls, setEvChargingUrls } from '../simple-action';
import { downloadEvChargingUrls, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, evChargingUrls, uiEvChargingUrl } = getState();
  const { token } = authentication;
  const { meta } = evChargingUrls;
  const {
    filterString, selectedPageSize, searchBarText, orderBy,
  } = uiEvChargingUrl;

  try {
    dispatch(downloadingEvChargingUrls(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadEvChargingUrls(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setEvChargingUrls(list));
    }
  } finally {
    dispatch(downloadingEvChargingUrls(false));
  }
};
