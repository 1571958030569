import { downloadingTestDriveVoucherRewards, setTestDriveVoucherRewards } from '../simple-action';
import { downloadTestDriveVoucherRewards, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, voucherRewards, uiVoucherReward } = getState();
  const { token } = authentication;
  const { meta } = voucherRewards;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiVoucherReward;


  try {
    dispatch(downloadingTestDriveVoucherRewards(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadTestDriveVoucherRewards(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setTestDriveVoucherRewards(list));
    }
  } finally {
    dispatch(downloadingTestDriveVoucherRewards(false));
  }
};
