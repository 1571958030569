import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_VOUCHER_TOKENS = 'DOWNLOADING_VOUCHER_TOKENS';
export const downloadingVoucherTokens = makeActionCreator(DOWNLOADING_VOUCHER_TOKENS, 'status');

export const DOWNLOADING_VOUCHER_TOKEN = 'DOWNLOADING_VOUCHER_TOKEN';
export const downloadingVoucherToken = makeActionCreator(DOWNLOADING_VOUCHER_TOKEN, 'status');

export const SET_VOUCHER_TOKENS = 'SET_VOUCHER_TOKENS';
export const setVoucherTokens = makeActionCreator(SET_VOUCHER_TOKENS, 'data');

export const ADD_VOUCHER_TOKEN = 'ADD_VOUCHER_TOKEN';
export const addVoucherToken = makeActionCreator(ADD_VOUCHER_TOKEN, 'data');

export const CLEAR_VOUCHER_TOKENS = 'CLEAR_VOUCHER_TOKENS';
export const clearVoucherTokens = makeActionCreator(CLEAR_VOUCHER_TOKENS);


export const SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE = 'SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE';
export const setVoucherTokenSelectedPageSize = makeActionCreator(SET_VOUCHER_TOKEN_SELECTED_PAGE_SIZE, 'size');

export const SET_VOUCHER_TOKEN_SELECTED_ORDER_BY = 'SET_VOUCHER_TOKEN_SELECTED_ORDER_BY';
export const setVoucherTokenSelectedOrderBy = makeActionCreator(SET_VOUCHER_TOKEN_SELECTED_ORDER_BY, 'order');

export const SET_VOUCHER_TOKEN_TAPPED_ID = 'SET_VOUCHER_TOKEN_TAPPED_ID';
export const setVoucherTokenTappedId = makeActionCreator(SET_VOUCHER_TOKEN_TAPPED_ID, 'id');

export const SET_VOUCHER_TOKEN_SEARCH_TEXT = 'SET_VOUCHER_TOKEN_SEARCH_TEXT';
export const setVoucherTokenSearchText = makeActionCreator(SET_VOUCHER_TOKEN_SEARCH_TEXT, 'text');

export const SAVING_VOUCHER_TOKENS = 'SAVING_VOUCHER_TOKENS';
export const savingVoucherTokens = makeActionCreator(SAVING_VOUCHER_TOKENS, 'status');


export const DOWNLOADING_VOUCHER_TOKEN_BY_CODE = 'DOWNLOADING_VOUCHER_TOKEN_BY_CODE';
export const downloadingVoucherTokenByCode = makeActionCreator(DOWNLOADING_VOUCHER_TOKEN_BY_CODE, 'status');

export const SET_VOUCHER_TOKEN_BY_CODE = 'SET_VOUCHER_TOKEN_BY_CODE';
export const setVoucherTokenByCode = makeActionCreator(SET_VOUCHER_TOKEN_BY_CODE, 'data');

export const CLEAR_VOUCHER_TOKEN_BY_CODE = 'CLEAR_VOUCHER_TOKEN_BY_CODE';
export const clearVoucherTokenByCode = makeActionCreator(CLEAR_VOUCHER_TOKEN_BY_CODE);

export const CLAIMING_VOUCHER_TOKEN_BY_CODE = 'CLAIMING_VOUCHER_TOKEN_BY_CODE';
export const claimingVoucherTokenByCode = makeActionCreator(CLAIMING_VOUCHER_TOKEN_BY_CODE, 'status');
