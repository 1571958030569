import { downloadTransactionCreatedByVehicleModel } from '../../../helper';
import { downloadingTransactionCreatedByVehicleModel, setTransactionCreatedByVehicleModel } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingTransactionCreatedByVehicleModel(true));
    const bookingCreated = await downloadTransactionCreatedByVehicleModel(token,
      startDate, endDate);

    dispatch(setTransactionCreatedByVehicleModel(bookingCreated));
  } finally {
    dispatch(downloadingTransactionCreatedByVehicleModel(false));
  }
};
