import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_TEST_DRIVE_EO_EVENTS = '/testdrive/api/v1/HyundaiEventForEo?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_TEST_DRIVE_EO_EVENT = '/testdrive/api/v1/HyundaiEventForEo/{id}';
export const REST_URL_TEST_DRIVE_EO_TEST_VEHICLES = '/testdrive/api/v1/TestVehicleForEo?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_TEST_DRIVE_EO_TEST_VEHICLE = '/testdrive/api/v1/TestVehicleForEo';
export const REST_URL_VIEW_DELETE_TEST_DRIVE_EO_TEST_VEHICLE = '/testdrive/api/v1/TestVehicleForEo/{id}';
export const REST_URL_RESERVATION_FOR_EOS = '/testdrive/api/v1/ReservationForEo?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=dealer.name&filterString=status=enabled';
export const REST_URL_VIEW_RESERVATION_FOR_EO = '/testdrive/api/v1/ReservationForEo/{id}';
export const REST_URL_ADD_EDIT_TEST_DRIVE_SALES = '/testdrive/api/v1/reservation';

export const ROUTE_NAME_TEST_DRIVE_EO = '/test-drive-eo';
export const ROUTE_NAME_TEST_DRIVE_EO_EVENT = '/event-eo';
export const ROUTE_NAME_TEST_DRIVE_EO_TEST_VEHICLE = '/test-vehicle-eo';
export const ROUTE_NAME_TEST_DRIVE_EO_RESERVATION = '/test-drive-eo-reservation';

export const RXFORM_TEST_DRIVE_EO_EVENT = 'testDriveEoEventPage';
export const RXFORM_TEST_DRIVE_EO_TEST_VEHICLE = 'testDriveEoTestVehiclePage';
export const RXFORM_TEST_DRIVE_EO_RESERVATION = 'testDriveEoReservationPage';

export const RXFIELD_TEST_DRIVE_EO_EVENT_NAME = 'name';
export const RXFIELD_TEST_DRIVE_EO_EVENT_START_TIME = 'startTime';
export const RXFIELD_TEST_DRIVE_EO_EVENT_END_TIME = 'endTime';
export const RXFIELD_TEST_DRIVE_EO_EVENT_URL1 = 'url1';
export const RXFIELD_TEST_DRIVE_EO_EVENT_URL1_EXTERNAL_BROWSER = 'url1OpenOnExternalBrowser';
export const RXFIELD_TEST_DRIVE_EO_EVENT_DESCRIPTION = 'description';
export const RXFIELD_TEST_DRIVE_EO_EVENT_LOCATION = 'location';
export const RXFIELD_TEST_DRIVE_EO_EVENT_VISIBILITY = 'visibility';
export const RXFIELD_TEST_DRIVE_EO_EVENT_URL2 = 'url2';
export const RXFIELD_TEST_DRIVE_EO_EVENT_URL2_EXTERNAL_BROWSER = 'url2OpenOnExternalBrowser';
export const RXFIELD_TEST_DRIVE_EO_EVENT_VOUCHER_REWARDS = 'voucherRewards';
export const RXFIELD_TEST_DRIVE_EO_EVENT_PROVINCE = 'province';
export const RXFIELD_TEST_DRIVE_EO_EVENT_CITY = 'city';
export const RXFIELD_TEST_DRIVE_EO_EVENT_SURVEY_URL = 'surveyUrl';
export const RXFIELD_TEST_DRIVE_EO_EVENT_SURVEY_URL_EXTERNAL_BROWSER = 'surveyUrlOpenOnExternalBrowser';
export const RXFIELD_TEST_DRIVE_EO_EVENT_EVENT_CODE = 'eventCode';
export const RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_EVENT = 'event.name';
export const RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_MODEL = 'modelName';
export const RXFIELD_TEST_DRIVE_EO_TEST_VEHICLE_DAILY_QUOTA = 'dailyQuota';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_TYPE = 'reservationType';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_ID = 'eventId';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_NAME = 'eventName';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_EVENT_LOCATION = 'eventLocation';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_ACTIVITY_STATUS = 'activityStatus';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_CODE = 'dealerCode';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_NAME = 'dealerName';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_DEALER_CITY = 'dealerCity';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_SIM_IMAGE = 'simImage';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_SURVEY_IMAGE = 'surverImage';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_USER_ID = 'userId';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_USER_FULLNAME = 'userFullname';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_VEHICLE_MODEL = 'testVehicleModel';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_RESERVED_DATE = 'reservedDate';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_COMPLETED_TIME = 'completedTime';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_TESTDRIVE_IMAGE = 'testdriveImage';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_OTHER_IMAGE = 'otherImage';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_FIRST_NAME = 'firstName';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_LAST_NAME = 'lastName';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_EMAIL = 'email';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_PHONE = 'phone';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_GENDER = 'gender';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_RECEIVED_NEWS_UPDATE = 'wantToReceiveNewsUpdate';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_REFERENCE_ID = 'referenceId';
export const RXFIELD_TEST_DRIVE_EO_RESERVATION_SALES_ID = 'salesId';

export const RXSTATE_TEST_DRIVE_EO_EVENTS = 'testDriveEoEvents';
export const RXSTATE_TEST_DRIVE_EO_EVENT_PAGE = 'uiTestDriveEoEvent';
export const RXSTATE_TEST_DRIVE_EO_TEST_VEHICLES = 'testDriveEoTestVehicles';
export const RXSTATE_TEST_DRIVE_EO_TEST_VEHICLE_PAGE = 'uiTestDriveEoTestVehicle';
export const RXSTATE_TEST_DRIVE_EO_RESERVATIONS = 'testDriveEoReservations';
export const RXSTATE_TEST_DRIVE_EO_RESERVATION_PAGE = 'uiTestDriveEoReservation';

export const INITIAL_ORDER_BY_TEST_DRIVE_EO_EVENTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_TEST_DRIVE_EO_TEST_VEHICLES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_TEST_DRIVE_EO_RESERVATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_TEST_DRIVE_EO = 'MENUID_TEST_DRIVE_EO';
export const MENUID_TEST_DRIVE_EO_EVENT = 'MENUID_TEST_DRIVE_EO_EVENT';
export const MENUID_TEST_DRIVE_EO_TEST_VEHICLE = 'MENUID_TEST_DRIVE_EO_TEST_VEHICLE';
export const MENUID_TEST_DRIVE_EO_RESERVATION = 'MENUID_TEST_DRIVE_EO_RESERVATION';

const ACTIVITY_STATUS_RESERVATION = 'Reservation';
const ACTIVITY_STATUS_COMPLETED = 'Completed';
const ACTIVITY_STATUS_CANCELLED = 'Cancelled';
const ACTIVITY_STATUS_QUEUEING = 'Queueing';
const ACTIVITY_STATUS_ONGOING = 'Ongoing';

export const RESERVATION_TYPE_EVENT = 'Event';
export const RESERVATION_TYPE_DEALER = 'Dealer';

export const ACTIVITY_STATUS_DROPDOWN = [
  {
    label: ACTIVITY_STATUS_COMPLETED,
    value: ACTIVITY_STATUS_COMPLETED,
  },
  {
    label: ACTIVITY_STATUS_ONGOING,
    value: ACTIVITY_STATUS_ONGOING,
  },
  {
    label: ACTIVITY_STATUS_QUEUEING,
    value: ACTIVITY_STATUS_QUEUEING,
  },
  {
    label: ACTIVITY_STATUS_RESERVATION,
    value: ACTIVITY_STATUS_RESERVATION,
  },
  {
    label: ACTIVITY_STATUS_CANCELLED,
    value: ACTIVITY_STATUS_CANCELLED,
  },
];

export const RESERVATION_TYPE_DROPDOWN = [
  {
    label: RESERVATION_TYPE_EVENT,
    value: RESERVATION_TYPE_EVENT,
  },
  {
    label: RESERVATION_TYPE_DEALER,
    value: RESERVATION_TYPE_DEALER,
  },
];
