import { addRegistration, rejectingRegistration } from '../simple-action';
import { approveRejectRegistration, downloadRegistration } from '../../../helper';
import { APPROVAL_STATUS_REJECTED } from '../../../constant';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(rejectingRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistration;

    await approveRejectRegistration(tappedId, APPROVAL_STATUS_REJECTED, notes, null, null, token);
    const result = await downloadRegistration(tappedId, token);

    dispatch(addRegistration(result));
  } finally {
    dispatch(rejectingRegistration(false));
  }
};
