import {
  addCompletedChallenge, downloadingDeletingCompletedChallenge,
} from '../simple-action';
import { downloadCompletedChallenge } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCompletedChallenge(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCompletedChallenge;

    const challenge = await downloadCompletedChallenge(tappedId, token);

    dispatch(addCompletedChallenge(challenge));
  } finally {
    dispatch(downloadingDeletingCompletedChallenge(false));
  }
};
