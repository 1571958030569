import { approvingReservation, setFunctionalPageMode } from '../simple-action';
import {
  approveReservation,
} from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import { clearTestDriveEoReservations, downloadTestDriveEoReservationsAsync } from '../../../../test-drive-eo';

export default (data) => async (dispatch, getState) => {
  try {
    dispatch(approvingReservation(true));

    const { token } = getState().authentication;

    await approveReservation(
      data,
      token,
    );

    // dispatch(addReservation(result));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    dispatch(clearTestDriveEoReservations());
    dispatch(downloadTestDriveEoReservationsAsync(1));
  } finally {
    dispatch(approvingReservation(false));
  }
};
