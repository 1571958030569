import PropTypes from 'prop-types';

export * from '../../type';

export const AcceessoryTransactionItemtype = PropTypes.shape({
  itemType: PropTypes.string,
  accessory: {
    id: PropTypes.string,
    status: PropTypes.string,
    variantId: PropTypes.string,
    variantCode: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    order: PropTypes.string,
    price: PropTypes.string,
    description: PropTypes.string,
  },
  part: PropTypes.string,
  pickupLocation: {
    dealerId: PropTypes.string,
    dealerCode: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    regionCode: PropTypes.string,
    province: PropTypes.string,
    openTime: PropTypes.string,
    closeTime: PropTypes.string,
    dealerStatus: PropTypes.string,
    dealerTypeCode: PropTypes.string,
    dealerType: PropTypes.string,
    hasChargingStation: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    timeZone: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    id: PropTypes.string,
  },
  pickupTime: PropTypes.string,
  quantity: PropTypes.string,
  unitPrice: PropTypes.string,
  tax: PropTypes.string,
  orderId: PropTypes.string,
  subTotalWithTax: PropTypes.string,
  notes: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
  createdDate: PropTypes.string,
  lastModifiedDate: PropTypes.string,
  createdBy: PropTypes.string,
  lastModifiedBy: PropTypes.string,
  remark: PropTypes.string,
});

export const AccessoryTransactionShape = PropTypes.shape({
  transactionStatus: PropTypes.string,
  transactionStatusNotes: PropTypes.string,
  transactionId: PropTypes.string,
  user: {
    id: PropTypes.string,
    fullName: PropTypes.string,
  },
  payment: {
    id: PropTypes.string,
    createdDate: PropTypes.string,
    total: PropTypes.string,
    paymentMethod: PropTypes.string,
    paymentStatus: PropTypes.string,
    validUntil: PropTypes.string,
    vaNumber: PropTypes.string,
    url: PropTypes.string,
    lastStatusCheck: PropTypes.string,
    paymentRawResponse: PropTypes.string,
    paymentRawCallback: PropTypes.string,
  },
  tax: PropTypes.string,
  totalWithTax: PropTypes.string,
  finishDate: PropTypes.string,
  items: PropTypes.arrayOf(AcceessoryTransactionItemtype),
  id: PropTypes.string,
  status: PropTypes.string,
  createdDate: PropTypes.string,
  lastModifiedDate: PropTypes.string,
  createdBy: PropTypes.string,
  lastModifiedBy: PropTypes.string,
  remark: PropTypes.string,
});
