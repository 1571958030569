import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_GIIAS_SURABAYA_SCANNED_CODES = 'DOWNLOADING_GIIAS_SURABAYA_SCANNED_CODES';
export const downloadingGiiasSurabayaScannedCodes = makeActionCreator(DOWNLOADING_GIIAS_SURABAYA_SCANNED_CODES, 'status');

export const DOWNLOADING_GIIAS_SURABAYA_SCANNED_CODE = 'DOWNLOADING_GIIAS_SURABAYA_SCANNED_CODE';
export const downloadingGiiasSurabayaScannedCode = makeActionCreator(DOWNLOADING_GIIAS_SURABAYA_SCANNED_CODE, 'status');

export const SET_GIIAS_SURABAYA_SCANNED_CODES = 'SET_GIIAS_SURABAYA_SCANNED_CODES';
export const setGiiasSurabayaScannedCodes = makeActionCreator(SET_GIIAS_SURABAYA_SCANNED_CODES, 'data');

export const SET_GIIAS_SURABAYA_SCANNED_CODE = 'SET_GIIAS_SURABAYA_SCANNED_CODE';
export const setGiiasSurabayaScannedCode = makeActionCreator(SET_GIIAS_SURABAYA_SCANNED_CODE, 'data');

export const CLEAR_GIIAS_SURABAYA_SCANNED_CODES = 'CLEAR_GIIAS_SURABAYA_SCANNED_CODES';
export const clearGiiasSurabayaScannedCodes = makeActionCreator(CLEAR_GIIAS_SURABAYA_SCANNED_CODES, 'data');

export const SET_GIIAS_SURABAYA_SCANNED_CODES_PAGE_SIZE = 'SET_GIIAS_SURABAYA_SCANNED_CODES_PAGE_SIZE';
export const setGiiasSurabayaScannedCodesPageSize = makeActionCreator(SET_GIIAS_SURABAYA_SCANNED_CODES_PAGE_SIZE, 'size');

export const SET_GIIAS_SURABAYA_SCANNED_CODES_ORDER_BY = 'SET_GIIAS_SURABAYA_SCANNED_CODES_ORDER_BY';
export const setGiiasSurabayaScannedCodesOrderBy = makeActionCreator(SET_GIIAS_SURABAYA_SCANNED_CODES_ORDER_BY, 'order');

export const SET_GIIAS_SURABAYA_SCANNED_CODES_SEARCH_TEXT = 'SET_GIIAS_SURABAYA_SCANNED_CODES_SEARCH_TEXT';
export const setGiiasSurabayaScannedCodesSearchText = makeActionCreator(SET_GIIAS_SURABAYA_SCANNED_CODES_SEARCH_TEXT, 'text');

export const SET_GIIAS_SURABAYA_SCANNED_CODES_TAPPED_ID = 'SET_GIIAS_SURABAYA_SCANNED_CODES_TAPPED_ID';
export const setGiiasSurabayaScannedCodesTappedId = makeActionCreator(SET_GIIAS_SURABAYA_SCANNED_CODES_TAPPED_ID, 'id');

export const SET_GIIAS_SURABAYA_SCANNED_CODES_FILTER_STRING = 'SET_GIIAS_SURABAYA_SCANNED_CODES_FILTER_STRING';
export const setGiiasSurabayaScannedCodesFilterString = makeActionCreator(SET_GIIAS_SURABAYA_SCANNED_CODES_FILTER_STRING, 'text');

export const SAVING_GIIAS_SURABAYA_2022_SCANNED_CODES = 'SAVING_GIIAS_SURABAYA_2022_TSCANNED_CODE';
export const savingGiiasSurabaya2022ScannedCodes = makeActionCreator(SAVING_GIIAS_SURABAYA_2022_SCANNED_CODES, 'status');


export const DOWNLOADING_GIIAS_SURABAYA_PRIZE_REDEMPTIONS = 'DOWNLOADING_GIIAS_SURABAYA_PRIZE_REDEMPTIONS';
export const downloadingGiiasSurabayaPrizeRedemptions = makeActionCreator(DOWNLOADING_GIIAS_SURABAYA_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_GIIAS_SURABAYA_PRIZE_REDEMPTION = 'DOWNLOADING_GIIAS_SURABAYA_PRIZE_REDEMPTION';
export const downloadingGiiasSurabayaPrizeRedemption = makeActionCreator(DOWNLOADING_GIIAS_SURABAYA_PRIZE_REDEMPTION, 'status');

export const ADD_GIIAS_SURABAYA_PRIZE_REDEMPTION = 'ADD_GIIAS_SURABAYA_PRIZE_REDEMPTION';
export const addGiiasSurabayaPrizeRedemption = makeActionCreator(ADD_GIIAS_SURABAYA_PRIZE_REDEMPTION, 'data');

export const SET_GIIAS_SURABAYA_PRIZE_REDEMPTIONS = 'SET_GIIAS_SURABAYA_PRIZE_REDEMPTIONS';
export const setGiiasSurabayaPrizeRedemptions = makeActionCreator(SET_GIIAS_SURABAYA_PRIZE_REDEMPTIONS, 'data');

export const CLEAR_GIIAS_SURABAYA_PRIZE_REDEMPTIONS = 'CLEAR_GIIAS_SURABAYA_PRIZE_REDEMPTIONS';
export const clearGiiasSurabayaPrizeRedemptions = makeActionCreator(
  CLEAR_GIIAS_SURABAYA_PRIZE_REDEMPTIONS,
);

export const SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE';
export const setGiiasSurabayaPrizeRedemptionSelectedPageSize = makeActionCreator(SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SELECTED_ORDER_BY = 'SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SELECTED_ORDER_BY';
export const setGiiasSurabayaPrizeRedemptionSelectedOrderBy = makeActionCreator(SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_TAPPED_ID = 'SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_TAPPED_ID';
export const setGiiasSurabayaPrizeRedemptionTappedId = makeActionCreator(SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_TAPPED_ID, 'id');

export const SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SEARCH_TEXT = 'SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SEARCH_TEXT';
export const setGiiasSurabayaPrizeRedemptionSearchText = makeActionCreator(SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING';
export const setGiiasSurabayaPrizeRedemptionAdvancedFilterString = makeActionCreator(SET_GIIAS_SURABAYA_PRIZE_REDEMPTION_ADVANCED_FILTER_STRING, 'text');

export const SAVING_GIIAS_SURABAYA_2022_PRIZE_REDEMPTIONS = 'SAVING_GIIAS_SURABAYA_2022_PRIZE_REDEMPTIONS';
export const savingGiiasSurabaya2022PrizeRedemptions = makeActionCreator(SAVING_GIIAS_SURABAYA_2022_PRIZE_REDEMPTIONS, 'status');

export const DOWNLOADING_GIIAS_SURABAYA_2022_TRANSACTIONS = 'DOWNLOADING_GIIAS_SURABAYA_2022_TRANSACTIONS';
export const downloadingGiiasSurabaya2022Transactions = makeActionCreator(DOWNLOADING_GIIAS_SURABAYA_2022_TRANSACTIONS, 'status');

export const DOWNLOADING_GIIAS_SURABAYA_2022_TRANSACTION = 'DOWNLOADING_GIIAS_SURABAYA_2022_TRANSACTION';
export const downloadingGiiasSurabaya2022Transaction = makeActionCreator(DOWNLOADING_GIIAS_SURABAYA_2022_TRANSACTION, 'status');

export const SAVING_GIIAS_SURABAYA_2022_TRANSACTIONS = 'SAVING_GIIAS_SURABAYA_2022_TRANSACTIONS';
export const savingGiiasSurabaya2022Transactions = makeActionCreator(SAVING_GIIAS_SURABAYA_2022_TRANSACTIONS, 'status');

export const ADD_GIIAS_SURABAYA_2022_TRANSACTION = 'ADD_GIIAS_SURABAYA_2022_TRANSACTION';
export const addGiiasSurabaya2022Transaction = makeActionCreator(ADD_GIIAS_SURABAYA_2022_TRANSACTION, 'data');

export const SET_GIIAS_SURABAYA_2022_TRANSACTIONS = 'SET_GIIAS_SURABAYA_2022_TRANSACTIONS';
export const setGiiasSurabaya2022Transactions = makeActionCreator(SET_GIIAS_SURABAYA_2022_TRANSACTIONS, 'data');

export const CLEAR_GIIAS_SURABAYA_2022_TRANSACTIONS = 'CLEAR_GIIAS_SURABAYA_2022_TRANSACTIONS';
export const clearGiiasSurabaya2022Transactions = makeActionCreator(
  CLEAR_GIIAS_SURABAYA_2022_TRANSACTIONS,
);

export const SET_GIIAS_SURABAYA_2022_TRANSACTION_SELECTED_PAGE_SIZE = 'SET_GIIAS_SURABAYA_2022_TRANSACTION_SELECTED_PAGE_SIZE';
export const setGiiasSurabaya2022TransactionSelectedPageSize = makeActionCreator(SET_GIIAS_SURABAYA_2022_TRANSACTION_SELECTED_PAGE_SIZE, 'size');

export const SET_GIIAS_SURABAYA_2022_TRANSACTION_SELECTED_ORDER_BY = 'SET_GIIAS_SURABAYA_2022_TRANSACTION_SELECTED_ORDER_BY';
export const setGiiasSurabaya2022TransactionSelectedOrderBy = makeActionCreator(SET_GIIAS_SURABAYA_2022_TRANSACTION_SELECTED_ORDER_BY, 'order');

export const SET_GIIAS_SURABAYA_2022_TRANSACTION_TAPPED_ID = 'SET_GIIAS_SURABAYA_2022_TRANSACTION_TAPPED_ID';
export const setGiiasSurabaya2022TransactionTappedId = makeActionCreator(SET_GIIAS_SURABAYA_2022_TRANSACTION_TAPPED_ID, 'id');

export const SET_GIIAS_SURABAYA_2022_TRANSACTION_SEARCH_TEXT = 'SET_GIIAS_SURABAYA_2022_TRANSACTION_SEARCH_TEXT';
export const setGiiasSurabaya2022TransactionSearchText = makeActionCreator(SET_GIIAS_SURABAYA_2022_TRANSACTION_SEARCH_TEXT, 'text');

export const SET_GIIAS_SURABAYA_2022_TRANSACTION_ADVANCED_FILTER_STRING = 'SET_GIIAS_SURABAYA_2022_TRANSACTION_ADVANCED_FILTER_STRING';
export const setGiiasSurabaya2022TransactionAdvancedFilterString = makeActionCreator(SET_GIIAS_SURABAYA_2022_TRANSACTION_ADVANCED_FILTER_STRING, 'text');
