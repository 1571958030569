import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    gameQRMenu: {
      title: 'Game QR',
    },
    gameQRCategoryPage: {
      title: 'Game QR Category',

      labelNo: 'No',
      labelName: 'Name',
      labelDescription: 'Description',
      placeholderImage: 'Image',

      placeholderStatus: 'Status',
      placeholderName: 'Name',
      placeholderDescription: 'Description',

      buttonCaptionCreateNewGameQRCategory: 'Create Game QR Category',
      buttonCaptionDeleteGameQRCategory: 'Delete Game QR Category',
      buttonCaptionEditGameQRCategory: 'Edit Game QR Category',
    },
    challengePage: {
      title: 'Challenge',

      labelNo: 'No',
      labelTitle: 'Title',
      labelCategory: 'Category',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelMedia: 'Media',
      labelOrder: 'Order',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelCodes: 'Codes',
      labelCode: 'Code',
      labelCoupons: 'Coupons',
      labelLabel: 'Label',
      labelSequential: 'Sequential',
      labelRandom: 'Random',
      labelAbsolute: 'Absolute',
      labelTimeBased: 'Time Based',
      labelWIB: 'WIB',
      labelWIT: 'WIT',
      labelWITA: 'WITA',
      labelCopied: 'Copied!',
      labelCreatedDate: 'Created Date',

      buttonCaptionCreateNewChallenge: 'Create New Challenge',
      buttonCaptionEditChallenge: 'Edit Challenge',
      buttonCaptionDeleteChallenge: 'Delete Challenge',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',

      placeholderTitle: 'Title',
      placeholderContent: 'Content',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderCategory: 'Category',
      placeholderVisibilityStatus: 'Visibility Status',
      placeholderReactionId: 'Reaction ID',
      placeholderTags: 'Tags',
      placeholderScanPolicy: 'Scan Policy',
      placeholderPromoted: 'Promoted',
      placeholderOrder: 'Order',
      placeholderScanLimitMode: 'Scan Limit Mode',
      placeholderScanLimitValue: 'Scan Limit Value',
      placeholderScanLimitHour: 'Scan Limit Hour',
      placeholderScanLimitDay: 'Scan Limit Day',
      placeholderScanLimitWeek: 'Scan Limit Week',
      placeholderScanLimitMonth: 'Scan Limit Month',
      placeholderScanLimitTimezone: 'Scan Limit Timezone',
      placeholderDeepLink: 'Deep Link',
      placeholderCompleteRewardType: 'Completed Reward Type',
      placeholderBypassMaxPurchase: 'Bypass Max Purchase',
      placeholderBypassAchievementValidation: 'Bypass Achievement Validation',
      placeholderCoinsAmount: 'Points Amount',
      placeholderCouponsAmount: 'Coupons Amount',
      placeholderVoucher: 'Voucher',
      placeholderBudget: 'Budget',
      placeholderSpending: 'Spending',
      placeholderEnableBudget: 'Enable Budget',

      errMsgValueMustBeGreater: 'Value must be greater than 0',
      errMsgValueMustBeGreaterOrEqual: 'Value must be greater or equal 0',
    },
    scannedCodePage: {
      title: 'Scanned Code',

      labelNo: 'No.',
      labelChallenge: 'Challenge',
      labelCode: 'Code',
      labelCodeLabel: 'Code Label',
      labelPoint: 'Point',
      labelUser: 'User',
      labelCity: 'City',
      labelChallengeCode: 'Challenge Code',
      labelScanDate: 'Scan Date',
      labelChallengeId: 'Challenge ID',
      labelChallengeName: 'Challenge Name',
      labelChallengeLabel: 'Challenge Label',
      labelUserId: 'User ID',
      labelFullName: 'Full Name',
    },
    completedChallengePage: {
      title: 'Completed Challenge',

      labelNo: 'No',
      labelChallenge: 'Challenge',
      labelRewardType: 'Reward Type',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelUser: 'User',
      labelCity: 'Kota',
      labelChallengeId: 'Challenge ID',
      labelChallengeName: 'Challenge Name',
      labelUserId: 'User ID',
      labelFullName: 'Full Name',
      labelPointAmount: 'Point Amount',
      labelCouponAmount: 'Coupon Amount',
      labelVoucherName: 'Voucher Name',
      labelScanDate: 'Scan Date',
      labelScannedCodesLabel: 'Label',
      labelScanedCodesPoint: 'Point',
      labelScannedCodes: 'Scanned Codes',
    },
  },
  id: {
    gameQRMenu: {
      title: 'QR Permainan',
    },
    gameQRCategoryPage: {
      title: 'Kategori QR Permainan',

      labelNo: 'No',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',

      placeholderStatus: 'Status',
      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderImage: 'Gambar',

      buttonCaptionCreateNewGameQRCategory: 'Buat Kategori QR Permainan',
      buttonCaptionDeleteGameQRCategory: 'Hapus Kategori QR Permainan',
      buttonCaptionEditGameQRCategory: 'Ubah Kategori QR Permainan',
    },
    challengePage: {
      title: 'Tantangan',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelCategory: 'Kategori',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelMedia: 'Media',
      labelOrder: 'Urutan',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelCodes: 'Kode',
      labelCode: 'Kode',
      labelCoupons: 'Kupon',
      labelLabel: 'Label',
      labelSequential: 'Berurutan',
      labelRandom: 'Acak',
      labelAbsolute: 'Mutlak',
      labelTimeBased: 'Berdasarkan Waktu',
      labelWIB: 'WIB',
      labelWIT: 'WIT',
      labelWITA: 'WITA',
      labelCopied: 'Disalin!',
      labelCreatedDate: 'Tanggal Pembuatan',

      buttonCaptionCreateNewChallenge: 'Buat Tantangan Baru',
      buttonCaptionEditChallenge: 'Ubah Tantangan',
      buttonCaptionDeleteChallenge: 'Hapus Tantangan',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',

      placeholderTitle: 'Judul',
      placeholderContent: 'Konten',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderCategory: 'Kategori',
      placeholderVisibilityStatus: 'Status Terlihat',
      placeholderReactionId: 'ID Reaksi',
      placeholderTags: 'Tag',
      placeholderScanPolicy: 'Kebijakan Pemindaian',
      placeholderPromoted: 'Dipromosikan',
      placeholderOrder: 'Urutan',
      placeholderScanLimitMode: 'Mode Batas Pindai',
      placeholderScanLimitValue: 'Jumlah Batas Pindai',
      placeholderScanLimitHour: 'Batas Pindai Jam',
      placeholderScanLimitDay: 'Batas Pindai Harian',
      placeholderScanLimitWeek: 'Batas Pindai Mingguan',
      placeholderScanLimitMonth: 'Batas Pindai Bulanan',
      placeholderScanLimitTimezone: 'Zona Waktu Batas Pindai',
      placeholderDeepLink: 'Tautan Dalam',
      placeholderCompleteRewardType: 'Tipe Hadiah Lengkap',
      placeholderBypassMaxPurchase: 'Bypass Maksimal Pembelian',
      placeholderBypassAchievementValidation: 'Bypass Validasi Pencapaian',
      placeholderCoinsAmount: 'Jumlah Poin',
      placeholderCouponsAmount: 'Jumlah Kupon',
      placeholderVoucher: 'Voucher',
      placeholderBudget: 'Anggaran',
      placeholderSpending: 'Pengeluaran',
      placeholderEnableBudget: 'Aktifkan Anggaran',

      errMsgValueMustBeGreater: 'Nilai harus lebih besar dari 0',
      errMsgValueMustBeGreaterOrEqual: 'Nilai harus lebih besar atau sama dengan 0',
    },
    scannedCodePage: {
      title: 'Kode Dipindai',

      labelNo: 'No.',
      labelChallenge: 'Tantangan',
      labelCode: 'Kode',
      labelCodeLabel: 'Label Kode',
      labelPoint: 'Poin',
      labelUser: 'Pengguna',
      labelChallengeCode: 'Kode Tantangan',
      labelScanDate: 'Tanggal Pindai',
      labelChallengeId: 'ID Tantangan',
      labelChallengeName: 'Nama Tantangan',
      labelChallengeLabel: 'Label Tantangan',
      labelUserId: 'ID Pengguna',
      labelFullName: 'Nama Lengkap',
    },
    completedChallengePage: {
      title: 'Tantangan Selesai',

      labelNo: 'No',
      labelChallenge: 'Tantangan',
      labelRewardType: 'Jenis Hadiah',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelUser: 'Pengguna',
      labelChallengeId: 'ID Tantangan',
      labelChallengeName: 'Nama Tantangan',
      labelUserId: 'ID Pengguna',
      labelFullName: 'Nama Lengkap',
      labelPointAmount: 'Jumlah Poin',
      labelCouponAmount: 'Jumlah Kupon',
      labelVoucherName: 'Nama Voucer',
      labelScanDate: 'Tanggal Pindai',
      labelScannedCodesLabel: 'Label',
      labelScanedCodesPoint: 'Poin',
      labelScannedCodes: 'Kode Scan',
    },
  },
});

export default LocalizedString;
