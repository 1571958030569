import { reset } from 'redux-form';
import {
  addingEditingAccessory, clearAccessories, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { editAccessory } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_ACCESSORY } from '../../../constant';
import downloadAccessoriesAsync from './downloadAccessoriesAsync';

export default (
  variants,
) => async (dispatch,
  getState) => {
  try {
    dispatch(addingEditingAccessory(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAccessory;

    await editAccessory(
      tappedId, variants, token,
    );
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));

    dispatch(reset(RXFORM_ACCESSORY));
    dispatch(clearAccessories());
    dispatch(downloadAccessoriesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingAccessory(false));
  }
};
