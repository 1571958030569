import { downloadJaw2023PrizeRedemption } from '../../../helper';
import { addJaw2023PrizeRedemption, downloadingJaw2023PrizeRedemption } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingJaw2023PrizeRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiJaw2023PrizeRedemption;

    const jaw2023PrizeRedemption = await downloadJaw2023PrizeRedemption(tappedId, token);
    dispatch(addJaw2023PrizeRedemption(jaw2023PrizeRedemption));
  } finally {
    dispatch(downloadingJaw2023PrizeRedemption(false));
  }
};
