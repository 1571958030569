import { INITIAL_ORDER_BY_TEST_DRIVE_EVENTS } from '../../constant';
import {
  DOWNLOADING_EVENTS, SET_EVENT_SEARCH_TEXT, SET_EVENT_SELECTED_PAGE_SIZE, SET_EVENT_TAPPED_ID,
  SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, SET_EVENT_SELECTED_ORDER_BY,
  SET_EVENT_VISIBILITY, SET_EVENT_URL1_EXTENSION_BROWSER, SET_EVENT_URL2_EXTENSION_BROWSER,
  ADDING_EDITING_EVENT, DOWNLOADING_DELETING_EVENT, SET_EVENT_SELECTED_VOUCHER,
  SET_EVENT_SELECTED_PROVINCE_ID, SET_EVENT_SELECTED_CITY_ID,
  SET_EVENT_SURVEY_URL_EXTENSION_BROWSER, COPYING_EVENT_CODE,
} from '../action';

const initialState = {
  addingEditing: false,
  copying: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  selectedProvinceId: '',
  selectedCityId: '',
  visibility: false,
  url1ExtensionBrowser: false,
  url2ExtensionBrowser: false,
  surveyUrlExtensionBrowser: false,
  orderBy: INITIAL_ORDER_BY_TEST_DRIVE_EVENTS,
  selectedVoucher: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_EVENTS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_EVENT:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_EVENT:
      return { ...state, downloadingDeleting: action.status };
    case SET_EVENT_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_EVENT_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_EVENT_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_EVENT_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_EVENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_EVENT_VISIBILITY:
      return { ...state, visibility: action.option };
    case SET_EVENT_URL1_EXTENSION_BROWSER:
      return { ...state, url1ExtensionBrowser: action.option.value };
    case SET_EVENT_URL2_EXTENSION_BROWSER:
      return { ...state, url2ExtensionBrowser: action.option.value };
    case SET_EVENT_SELECTED_VOUCHER:
      return { ...state, selectedVoucher: action.value };
    case SET_EVENT_SELECTED_PROVINCE_ID:
      return { ...state, selectedProvinceId: action.id };
    case SET_EVENT_SELECTED_CITY_ID:
      return { ...state, selectedCityId: action.id };
    case SET_EVENT_SURVEY_URL_EXTENSION_BROWSER:
      return { ...state, surveyUrlExtensionBrowser: action.option };
    case COPYING_EVENT_CODE:
      return { ...state, copying: action.status };
    default: return state;
  }
};
