import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_TEXT, FILTER_TYPE_DATE_RANGE,
  PICKER_DATE_FORMAT, PICKER_MODE_DATE,
  RXSTATE_CAR_SERVICE_EV_CHARGING_URLS,
  RXSTATE_CAR_SERVICE_EV_CHARGING_URL_PAGE,
  RXFORM_CAR_SERVICE_EV_CHARGING_URL,
  RXFIELD_CAR_SERVICE_EV_CHARGING_URL_CODE,
  RXFIELD_CAR_SERVICE_EV_CHARGING_URL_URL,
  RXFIELD_CAR_SERVICE_EV_CHARGING_URL_NOTES,
  PAGE_MODE_VIEW,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';
import { rxformValidateEvChargingUrl } from '../../validation';

const renderDialogContent = (initialValues, addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_EV_CHARGING_URL_CODE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.evChargingUrlPage.labelCode}
          placeholder={LocalizedString.evChargingUrlPage.placeholderCode}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_EV_CHARGING_URL_URL}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.evChargingUrlPage.labelURL}
          placeholder={LocalizedString.evChargingUrlPage.placeholderURL}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          multiline
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_EV_CHARGING_URL_NOTES}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.evChargingUrlPage.labelNotes}
          placeholder={LocalizedString.evChargingUrlPage.placeholderNotes}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          multiline
        />
      </Grid>
    </Grid>
  </Grid>
);

const EvChargingUrlPage = ({
  handleSubmit,
  addingEditing,
  downloading,
  onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed,
  onCancelPressed,
  onChangePage,
  onChangePageSize,
  onConfirmDeletePressed,
  onCreatePressed,
  onDeletePressed,
  onEditPressed,
  onRefresh,
  onResetAdvancedFilterPressed,
  onSavePressed,
  onSearchBarTextChanged,
  onSortPressed,
  onSubmitPressed,
  onViewPressed,
  initialValues,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_CAR_SERVICE_EV_CHARGING_URLS}
    uiPage={RXSTATE_CAR_SERVICE_EV_CHARGING_URL_PAGE}
    filterColumns={[
      {
        title: LocalizedString.evChargingUrlPage.placeholderCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        format: PICKER_DATE_FORMAT,
        pickerMode: PICKER_MODE_DATE,
      },
      {
        title: LocalizedString.evChargingUrlPage.placeholderCode,
        field: 'code',
        type: FILTER_TYPE_TEXT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.evChargingUrlPage.labelNo,
        field: 'no',
        sorting: false,
        width: 40,
        customFilterAndSearch: (term, rowData) => rowData,
      },
      {
        title: LocalizedString.evChargingUrlPage.labelCode,
        field: 'code',
        sorting: !downloading,
      },
      {
        title: LocalizedString.evChargingUrlPage.labelNotes,
        field: 'notes',
        sorting: !downloading,
      },
      {
        title: LocalizedString.evChargingUrlPage.labelURL,
        field: 'url',
        sorting: !downloading,
      },
      {
        title: LocalizedString.evChargingUrlPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.evChargingUrlPage.title}
    createNewButtonCaption={LocalizedString.evChargingUrlPage.buttonCaptionCreateNewEvChargingUrl}
    editButtonCaption={LocalizedString.evChargingUrlPage.buttonCaptionEditEvChargingUrl}
    deleteButtonCaption={LocalizedString.evChargingUrlPage.buttonCaptionDeleteEvChargingUrl}
    useFullWidth
  >
    {renderDialogContent(initialValues, addingEditing, pageMode)}
  </FunctionalPage>
);
export default reduxForm({
  form: RXFORM_CAR_SERVICE_EV_CHARGING_URL,
  validate: rxformValidateEvChargingUrl,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EvChargingUrlPage);

EvChargingUrlPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
