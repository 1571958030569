import {
  REST_URL_RESERVATIONS, REST_URL_VIEW_RESERVATION, REST_URL_ADD_EDIT_EVENT, REST_URL_EVENTS,
  REST_URL_VIEW_DELETE_EVENT, REST_URL_TEST_VEHICLES, REST_URL_VIEW_DELETE_TEST_VEHICLE,
  REST_URL_ADD_EDIT_TEST_VEHICLE, REST_URL_VIEW_VOUCHER_REWARD, REST_URL_VOUCHER_REWARDS,
  REST_URL_UNIQUE_CODES, REST_URL_VIEW_UNIQUE_CODE,
  REST_URL_SAVE_FILE, REST_URL_EVENT_ORGANIZERS, REST_URL_VIEW_DELETE_EVENT_ORGANIZER,
  REST_URL_ADD_EDIT_EVENT_ORGANIZER,
} from './constant';
import {
  buildQueryParams, transformQueryResult,
  sendGetRequest, sendDeleteRequest, sendPostRequest, sendPutRequest,
  sendGetFileRequest,
} from '../../helper';

export * from '../../helper';

export const downloadTestDriveReservations = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_RESERVATIONS.concat(filterString), pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestDriveReservation = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_RESERVATION.replace(/\{id\}/, id), token);
  return response;
};

export const saveTestDriveReservations = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_RESERVATIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadEvents = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_EVENTS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_EVENTS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadEvent = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT.replace(/\{id\}/, id), token);
  return response;
};

export const addEvent = async (name, description, location, visibility,
  startTime, endTime, url1, url2,
  url1OpenOnExternalBrowser, url2OpenOnExternalBrowser, cityId,
  surveyUrl, surveyUrlOpenOnExternalBrowser, voucherIds, token) => {
  const body = {
    name,
    description,
    location,
    startTime,
    endTime,
    visibility,
    cityId,
    surveyUrl,
    url1,
    url2,
    url1OpenOnExternalBrowser,
    url2OpenOnExternalBrowser,
    surveyUrlOpenOnExternalBrowser,
    voucherIds,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_EVENT, body, token);
};

export const editEvent = async (
  id, name, description, location, visibility,
  startTime, endTime, url1, url2,
  url1OpenOnExternalBrowser, url2OpenOnExternalBrowser, cityId, surveyUrl,
  surveyUrlOpenOnExternalBrowser, voucherIds, token,
) => {
  const body = {
    id,
    name,
    description,
    location,
    startTime,
    endTime,
    visibility,
    cityId,
    surveyUrl,
    url1,
    url2,
    url1OpenOnExternalBrowser,
    url2OpenOnExternalBrowser,
    surveyUrlOpenOnExternalBrowser,
    voucherIds,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_EVENT, body, token);
};

export const deleteEvent = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT.replace(/\{id\}/, id), token);
};

export const downloadTestDriveUniqueCodes = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = filterString
    ? buildQueryParams(REST_URL_UNIQUE_CODES.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_UNIQUE_CODES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestDriveUniqueCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_UNIQUE_CODE.replace(/\{id\}/, id), token);
  return response;
};

export const saveTestDriveUniqueCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_UNIQUE_CODES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};


export const downloadTestVehicles = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_TEST_VEHICLES.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_TEST_VEHICLES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestVehicle = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_TEST_VEHICLE.replace(/\{id\}/, id), token);
  return response;
};

export const addTestVehicle = async (eventId, modelId, dailyQuota, token) => {
  const body = {
    eventId,
    modelId,
    dailyQuota,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_TEST_VEHICLE, body, token);
};

export const editTestVehicle = async (
  id, eventId, modelId, dailyQuota, token,
) => {
  const body = {
    id,
    eventId,
    modelId,
    dailyQuota,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_TEST_VEHICLE, body, token);
};

export const deleteTestVehicle = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_TEST_VEHICLE.replace(/\{id\}/, id), token);
};

export const downloadTestDriveVoucherRewards = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = filterString
    ? buildQueryParams(REST_URL_VOUCHER_REWARDS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(
      REST_URL_VOUCHER_REWARDS, pageNumber, pageSize, orderBy, searchText,
    );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestDriveVoucherReward = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_VOUCHER_REWARD.replace(/\{id\}/, id), token);
  return response;
};

export const downloadTestDriveEventOrganizers = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_EVENT_ORGANIZERS.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_EVENT_ORGANIZERS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadTestDriveEventOrganizer = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_EVENT_ORGANIZER.replace(/\{id\}/, id), token);
  return response;
};

export const deleteTestDriveEventOrganizer = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_EVENT_ORGANIZER.replace(/\{id\}/, id), token);
};

export const addTestDriveEventOrganizer = async (userId, eventId, token) => {
  const body = {
    userId,
    eventId,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_EVENT_ORGANIZER, body, token);
};

export const editTestDriveEventOrganizer = async (
  id, userId, eventId, token,
) => {
  const body = {
    id,
    userId,
    eventId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_EVENT_ORGANIZER, body, token);
};
