import { transformSearchText } from '../../../../../helper';
import { downloadJaw2023PrizeRedemptions } from '../../../helper';
import { downloadingJaw2023PrizeRedemptions, setJaw2023PrizeRedemptions } from '../simple-action';


export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, jaw2023PrizeRedemptions, uiJaw2023PrizeRedemption,
  } = getState();
  const { token } = authentication;
  const { meta } = jaw2023PrizeRedemptions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiJaw2023PrizeRedemption;


  try {
    dispatch(downloadingJaw2023PrizeRedemptions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadJaw2023PrizeRedemptions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setJaw2023PrizeRedemptions(list));
    }
  } finally {
    dispatch(downloadingJaw2023PrizeRedemptions(false));
  }
};
