import { savingSubdistricts } from '../simple-action';
import { saveSubdistricts, transformSearchText } from '../../../helper';
import '../../../component/functional-page/download-file.css';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { searchBarText, orderBy, filterString } = getState().uiSubdistrict;

  try {
    dispatch(savingSubdistricts(true));

    const response = await saveSubdistricts(
      1,
      20,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'subdistrict.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(savingSubdistricts(false));
  }
};
