import moment from 'moment';
import { addRegistration, approvingRegistration } from '../simple-action';
import {
  approveRejectRegistration, downloadRegistration, toNumber,
} from '../../../helper';
import { APPROVAL_STATUS_APPROVED, REVERSED_ISO_DATE_FORMAT } from '../../../constant';

export default (notes, warrantyStartDate, vehiclePrice) => async (dispatch, getState) => {
  try {
    dispatch(approvingRegistration(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiRegistration;

    await approveRejectRegistration(
      tappedId,
      APPROVAL_STATUS_APPROVED,
      notes,
      warrantyStartDate
        ? moment(warrantyStartDate).format(REVERSED_ISO_DATE_FORMAT)
        : null,
      toNumber(vehiclePrice),
      token,
    );
    const result = await downloadRegistration(tappedId, token);

    dispatch(addRegistration(result));
  } finally {
    dispatch(approvingRegistration(false));
  }
};
