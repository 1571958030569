import {
  buildQueryParams, sendGetFileRequest, sendGetRequest, transformQueryResult,
} from '../../helper';
import {
  REST_URL_GIIAS_SCANNED_CODES, REST_URL_GIIAS_VIEW_DELETE_SCANNED_CODE,
  REST_URL_GIIAS_2022_PRIZE_REDEMPTIONS, REST_URL_VIEW_GIIAS_2022_PRIZE_REDEMPTION,
  REST_URL_GIIAS_2022_TRANSACTIONS, REST_URL_VIEW_GIIAS_2022_TRANSACTION,
  REST_URL_SAVE_FILE,
} from './constant';

export * from '../../helper';

export const downloadGiiasScannedCodes = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_GIIAS_SCANNED_CODES
      .concat(filterString), pageNumber, pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_GIIAS_SCANNED_CODES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiiasScannedCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_GIIAS_VIEW_DELETE_SCANNED_CODE
    .replace(/\{id\}/, id), token);
  return response;
};

export const downloadGiias2022PrizeRedemptions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_GIIAS_2022_PRIZE_REDEMPTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiias2022PrizeRedemption = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GIIAS_2022_PRIZE_REDEMPTION.replace(/\{id\}/, id), token);
  return response;
};

export const downloadGiias2022Transactions = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(
    REST_URL_GIIAS_2022_TRANSACTIONS.concat(filterString),
    pageNumber, pageSize, orderBy, searchText,
  );
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGiias2022Transaction = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_GIIAS_2022_TRANSACTION.replace(/\{id\}/, id), token);
  return response;
};

export const saveGiias2022Transactions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_2022_TRANSACTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const saveGiias2022ScannedCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_SCANNED_CODES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const saveGiias2022PrizeRedemptions = async (pageNumber, pageSize, orderBy,
  searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_GIIAS_2022_PRIZE_REDEMPTIONS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};
