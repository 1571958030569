import { downloadIims2023PrizeRedemption } from '../../../helper';
import { addIims2023PrizeRedemption, downloadingIims2023PrizeRedemption } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingIims2023PrizeRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiIims2023PrizeRedemption;

    const iims2023PrizeRedemption = await downloadIims2023PrizeRedemption(tappedId, token);
    dispatch(addIims2023PrizeRedemption(iims2023PrizeRedemption));
  } finally {
    dispatch(downloadingIims2023PrizeRedemption(false));
  }
};
