import { INITIAL_ORDER_BY_COUPON_VOID_REQUESTS } from '../../constant';
import {
  ADDING_VOID_COUPON_REQUEST, DOWNLOADING_VOID_COUPON_REQUEST, DOWNLOADING_VOID_COUPON_REQUESTS,
  SET_VOID_COUPON_REQUEST_SEARCH_TEXT, SET_VOID_COUPON_REQUEST_SELECTED_ORDER_BY,
  SET_VOID_COUPON_REQUEST_SELECTED_PAGE_SIZE, SET_VOID_COUPON_REQUEST_TAPPED_ID,
  SET_VOID_COUPON_REQUEST_SELECTED_COUPON,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_COUPON_VOID_REQUESTS,
  selectedCoupon: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_VOID_COUPON_REQUEST:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_VOID_COUPON_REQUESTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_VOID_COUPON_REQUEST:
      return { ...state, downloadingDeleting: action.status };
    case SET_VOID_COUPON_REQUEST_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_VOID_COUPON_REQUEST_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_VOID_COUPON_REQUEST_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_VOID_COUPON_REQUEST_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_VOID_COUPON_REQUEST_SELECTED_COUPON:
      return { ...state, selectedCoupon: action.option };
    default: return state;
  }
};
