/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, Typography, CircularProgress,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { AutocompleteTextField, FunctionalPage } from '../../component';
import {
  COLOR_DISABLED_ROW, COLOR_BACKGROUND, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  RXFORM_PROMO_CODE, RXSTATE_PROMO_CODES,
  RXSTATE_PROMO_CODE_PAGE, STATUS_DISABLED, DATE_TIME_FORMAT_WITHOUT_PIPE,
  REWARD_TYPE_VOUCHER, REWARD_TYPE_COIN, REWARD_TYPE_COUPON,
  RXFIELD_PROMO_CODE_REWARD, RXFIELD_PROMO_CODE_CODE, RXFIELD_PROMO_CODE_VOUCHER,
  RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE,
  RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_PROMO_CODE_TOTAL_VOUCHER_ALLOCATED, RXFIELD_PROMO_CODE_COINS_AMOUNT,
  RXFIELD_PROMO_CODE_COUPONS_AMOUNT, RXFIELD_PROMO_CODE_VALID_FROM,
  RXFIELD_PROMO_CODE_VALID_UNTIL, RXFIELD_PROMO_CODE_MERCHANT,
  RXFIELD_PROMO_CODE_MERCHANT_TAG, RXFIELD_PROMO_CODE_MESSAGE_TITLE,
  RXFIELD_PROMO_CODE_MESSAGE_BODY, REGEX_MATCH_STRING_INSIDE_CURLY_BRACES,
  FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_PROMO_CODE_CREATED_DATE,
  rewards, approval, RXFIELD_PROMO_CODE_USER_TYPE, PROMO_CODE_USER_TYPE_OPTIONS,
  RXFIELD_PROMO_CODE_MIN_SIGN_UP_DATE, RXFIELD_PROMO_CODE_MAX_SIGN_UP_DATE,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormDateTimePickerField,
  renderReduxFormAutocompleteTableField,
  renderReduxFormAutocompleteCheckboxField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxFormValidatePromoCode } from '../../validation';
import { toMoment } from '../../helper';
import { VOUCHER_FLAG_GARUDA_LOUNGE } from '../../../voucher';

const useStyles = makeStyles(() => ({
  italicText: {
    fontStyle: 'italic',
  },
  captionText: {
    marginLeft: 10,
  },
}));

const renderAdditionalPlaceholder = (classes) => {
  const additionalUrlPlaceholder = LocalizedString.promoCodePage.helperMessageField;
  const additionalPlaceholderHtmlComponent = `<p><i>${additionalUrlPlaceholder.match(REGEX_MATCH_STRING_INSIDE_CURLY_BRACES) ? additionalUrlPlaceholder.replace(/{(.*?)}/g, '<b>{$1}</b>') : additionalUrlPlaceholder}</i></p>`;

  return (
    <div className={classes.captionText}>
      <Typography variant="body2" className={classes.italicText}>
        {GlobalLocalizedString.common.labelYouCanUsePlaceholdersFieldsAbove}
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: additionalPlaceholderHtmlComponent }} />
    </div>
  );
};

const renderDialogContent = (
  initialValues, addingEditing, downloadingDeleting, pageMode,
  onRewardOptionSelected,
  vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
  onBypassMaxPurchaseOptionSelected, onBypassAchievementValidationOptionSelected,
  merchants, loadingMerchants, onChangeMerchantText, onMerchantOptionSelected, classes,
  onAddVouchersPressed, onDeleteVouchersPressed,
  addingVouchers, tappedPromoCode, selectedVoucher, tableDataVouchers,
  selectedRewardType, onUserTypeSelected, selectedUserTypes, isSelectedVoucherGarudaLounge,
  airports, loadingAirport, onAirportOptionSelected, onChangeAirportText, selectedAirport,
  garudaCompanyNameConfigValue,
) => {
  const isNewUserSelected = selectedUserTypes.some(
    (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[0].label,
  )
  || selectedUserTypes.some(
    (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[1].label,
  )
  || selectedUserTypes.some(
    (x) => x.label === PROMO_CODE_USER_TYPE_OPTIONS[2].label,
  );

  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodePage.placeholderPromoCode}
            label={LocalizedString.promoCodePage.placeholderPromoCode}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_REWARD}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.promoCodePage.placeholderReward}
            label={LocalizedString.promoCodePage.placeholderReward}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={rewards}
            value={initialValues.reward}
            onOptionSelected={onRewardOptionSelected}
            required
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          {downloadingDeleting ? (
            <CircularProgress color="inherit" />
          ) : (
            <Field
              name={RXFIELD_PROMO_CODE_VOUCHER}
              component={renderReduxFormAutocompleteTableField}
              label={LocalizedString.promoCodePage.placeholderVoucher}
              currentTableData={tableDataVouchers}
              defaultValue={initialValues.vouchers}
              options={vouchers}
              adding={addingVouchers}
              disableAdd={pageMode === PAGE_MODE_VIEW}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={selectedRewardType !== REWARD_TYPE_VOUCHER}
              loadingOptions={loadingVouchers}
              onAddPressed={(defaultValue, newInitialValue, selectedOption,
                currentData) => onAddVouchersPressed(defaultValue, newInitialValue, selectedOption,
                currentData, selectedAirport, garudaCompanyNameConfigValue)}
              onChangeOptionText={onChangeVoucherText}
              onDeletePressed={onDeleteVouchersPressed}
              onOptionSelected={(option) => onVoucherOptionSelected(option, selectedVoucher,
                garudaCompanyNameConfigValue, isSelectedVoucherGarudaLounge)}
              selectedOption={selectedVoucher}
              optionPlaceholder={LocalizedString.promoCodePage.placeholderVoucher}
              currentTappedData={tappedPromoCode}
              onBlur={(e) => e.preventDefault()}
              renderCustomFields={() => isSelectedVoucherGarudaLounge && (
              <Grid item sm={10}>
                <AutocompleteTextField
                  options={airports}
                  loading={loadingAirport}
                  placeholder={LocalizedString.promoCodePage.placeholderAirport}
                  label={LocalizedString.promoCodePage.placeholderAirport}
                  onChangeText={onChangeAirportText}
                  onOptionSelected={onAirportOptionSelected}
                  value={selectedAirport}
                  disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                />
              </Grid>
              )}
            />
          )}
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.promoCodePage.placeholderBypassMaxPurchase}
            label={LocalizedString.promoCodePage.placeholderBypassMaxPurchase}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassMaxPurchase}
            onOptionSelected={onBypassMaxPurchaseOptionSelected}
            required
            hidden={selectedRewardType !== REWARD_TYPE_VOUCHER}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.promoCodePage.placeholderBypassAchievementValidation}
            label={LocalizedString.promoCodePage.placeholderBypassAchievementValidation}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.voucherBypassAchievementValidation}
            onOptionSelected={onBypassAchievementValidationOptionSelected}
            required
            hidden={selectedRewardType !== REWARD_TYPE_VOUCHER}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>


        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_COINS_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodePage.placeholderCoinsAmount}
            label={LocalizedString.promoCodePage.placeholderCoinsAmount}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
            hidden={selectedRewardType !== REWARD_TYPE_COIN}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_COUPONS_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodePage.placeholderCouponsAmount}
            label={LocalizedString.promoCodePage.placeholderCouponsAmount}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
            hidden={selectedRewardType !== REWARD_TYPE_COUPON}
          />
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_TOTAL_VOUCHER_ALLOCATED}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodePage.placeholderTotalVoucherAllocated}
            label={LocalizedString.promoCodePage.placeholderTotalVoucherAllocated}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>

        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_USER_TYPE}
            component={renderReduxFormAutocompleteCheckboxField}
            label={LocalizedString.promoCodePage.placeholderUserType}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={PROMO_CODE_USER_TYPE_OPTIONS}
            loading={downloadingDeleting}
            onOptionSelected={onUserTypeSelected}
            onBlur={(e) => e.preventDefault()}
            onChangeText={() => {}}
          />
        </Grid>

        { isNewUserSelected ? (
          <>
            <Grid item>
              <Field
                name={RXFIELD_PROMO_CODE_MIN_SIGN_UP_DATE}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.promoCodePage.placeholderMinSignupDate}
                placeholder={LocalizedString.promoCodePage.placeholderMinSignupDate}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                hidden={!isNewUserSelected}
                required={isNewUserSelected}
              />
            </Grid>

            <Grid item>
              <Field
                name={RXFIELD_PROMO_CODE_MAX_SIGN_UP_DATE}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.promoCodePage.placeholderMaxSignupDate}
                placeholder={LocalizedString.promoCodePage.placeholderMaxSignupDate}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                hidden={!isNewUserSelected}
                required={isNewUserSelected}
              />
            </Grid>
          </>
        ) : (<></>)}
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.promoCodePage.placeholderValidFrom}
            placeholder={LocalizedString.promoCodePage.placeholderValidFrom}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.promoCodePage.placeholderValidUntil}
            placeholder={LocalizedString.promoCodePage.placeholderValidUntil}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_MERCHANT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.promoCodePage.placeholderMerchant}
            label={LocalizedString.promoCodePage.placeholderMerchant}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={merchants}
            value={initialValues.merchant}
            loading={loadingMerchants}
            onChangeText={onChangeMerchantText}
            onOptionSelected={onMerchantOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_MERCHANT_TAG}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodePage.placeholderMerchantTag}
            label={LocalizedString.promoCodePage.placeholderMerchantTag}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_MESSAGE_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodePage.placeholderMessageTitle}
            label={LocalizedString.promoCodePage.placeholderMessageTitle}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
          {renderAdditionalPlaceholder(classes)}
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROMO_CODE_MESSAGE_BODY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.promoCodePage.placeholderMessageBody}
            label={LocalizedString.promoCodePage.placeholderMessageBody}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
            multiline
          />
          {renderAdditionalPlaceholder(classes)}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PromoCodePage = ({
  downloading, handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh, onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  addingEditing, downloadingDeleting, pageMode, onSavePressed,
  onEditPressed, onCancelPressed, onSubmitPressed, onDeletePressed,
  onConfirmDeletePressed, onCreatePressed, onRewardOptionSelected,
  vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
  onBypassMaxPurchaseOptionSelected, onBypassAchievementValidationOptionSelected,
  merchants, loadingMerchants, onChangeMerchantText, onMerchantOptionSelected,
  onAddVouchersPressed, onDeleteVouchersPressed,
  addingVouchers, tappedPromoCode, selectedVoucher, tableDataVouchers,
  selectedRewardType, onDownloadPressed, onUserTypeSelected, selectedUserTypes,
  isSelectedVoucherGarudaLounge, airports, loadingAirport, onAirportOptionSelected,
  onChangeAirportText, selectedAirport, garudaCompanyNameConfigValue,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_PROMO_CODES}
      uiPage={RXSTATE_PROMO_CODE_PAGE}
      filterColumns={[
        {
          title: LocalizedString.promoCodePage.placeholderValidFrom,
          field: RXFIELD_PROMO_CODE_VALID_FROM,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.promoCodePage.placeholderValidUntil,
          field: RXFIELD_PROMO_CODE_VALID_UNTIL,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.promoCodePage.placeholderCreatedDate,
          field: RXFIELD_PROMO_CODE_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.promoCodePage.placeholderMerchant,
          field: RXFIELD_PROMO_CODE_MERCHANT,
          type: FILTER_TYPE_DROPDOWN,
          data: merchants,
          loading: loadingMerchants,
          onChangeFilterText: onChangeMerchantText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.promoCodePage.placeholderReward,
          field: RXFIELD_PROMO_CODE_REWARD,
          type: FILTER_TYPE_DROPDOWN,
          data: rewards,
          useDropdownValue: true,
        },
      ]}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      tableColumns={[
        {
          title: LocalizedString.promoCodePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.promoCodePage.placeholderPromoCode, field: 'code', sorting: !downloading },
        { title: LocalizedString.promoCodePage.placeholderMerchant, field: 'merchantName', sorting: !downloading },
        { title: LocalizedString.promoCodePage.placeholderReward, field: 'rewardType', sorting: !downloading },
        {
          title: LocalizedString.promoCodePage.placeholderVoucher,
          field: 'vouchers',
          sorting: false,
          render: ({ vouchers: currVoucher }) => currVoucher.map((item, index) => {
            const voucherName = item.garudaFlag === VOUCHER_FLAG_GARUDA_LOUNGE
              ? `${item.voucherName} (${item.airportName})` : item.voucherName;
            return (index === currVoucher.length - 1 ? voucherName : `${voucherName} | `);
          }),
        },
        {
          title: LocalizedString.promoCodePage.placeholderVoucherQty, field: 'vouchers.length', sorting: false,
        },
        { title: LocalizedString.promoCodePage.placeholderPointAmount, field: 'pointAmount', sorting: !downloading },
        { title: LocalizedString.promoCodePage.placeholderCouponsAmount, field: 'couponAmount', sorting: !downloading },
        {
          title: LocalizedString.promoCodePage.placeholderValidFrom,
          field: 'validFrom',
          sorting: !downloading,
          render: ({ validFrom }) => (validFrom
            ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.promoCodePage.placeholderValidUntil,
          field: 'validUntil',
          sorting: !downloading,
          render: ({ validUntil }) => (validUntil
            ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.promoCodePage.placeholderCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.promoCodePage.title}
      createNewButtonCaption={LocalizedString.promoCodePage.buttonCaptionCreatePromoCode}
      onCreatePressed={onCreatePressed}
      onSubmitPressed={onSubmitPressed}
      editButtonCaption={LocalizedString.promoCodePage.buttonCaptionEditPromoCode}
      onEditPressed={onEditPressed}
      onSavePressed={onSavePressed}
      deleteButtonCaption={LocalizedString.promoCodePage.buttonCaptionDeletePromoCode}
      onDeletePressed={onDeletePressed}
      onConfirmDeletePressed={onConfirmDeletePressed}
      useFullWidth
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
      onCancelPressed={onCancelPressed}
      onDownloadPressed={onDownloadPressed}
      enableSave
    >
      {renderDialogContent(
        initialValues, addingEditing, downloadingDeleting, pageMode,
        onRewardOptionSelected,
        vouchers, loadingVouchers, onChangeVoucherText, onVoucherOptionSelected,
        onBypassMaxPurchaseOptionSelected, onBypassAchievementValidationOptionSelected,
        merchants, loadingMerchants, onChangeMerchantText, onMerchantOptionSelected, classes,
        onAddVouchersPressed, onDeleteVouchersPressed,
        addingVouchers, tappedPromoCode, selectedVoucher, tableDataVouchers,
        selectedRewardType, onUserTypeSelected, selectedUserTypes,
        isSelectedVoucherGarudaLounge, airports, loadingAirport, onAirportOptionSelected,
        onChangeAirportText, selectedAirport, garudaCompanyNameConfigValue,
      )}
    </FunctionalPage>
  );
};
export default reduxForm({
  form: RXFORM_PROMO_CODE,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxFormValidatePromoCode,
})(PromoCodePage);

PromoCodePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  tappedPromoCode: FormInitialValueShape,
  airports: PropTypes.arrayOf(SimpleDataShape).isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedAirport: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  garudaCompanyNameConfigValue: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedRewardType: PropTypes.string,
  selectedVoucher: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  tableDataVouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedUserTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  isSelectedVoucherGarudaLounge: PropTypes.bool.isRequired,
  loadingAirport: PropTypes.bool.isRequired,
  loadingVouchers: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingMerchants: PropTypes.bool.isRequired,
  addingVouchers: PropTypes.bool.isRequired,
  onAirportOptionSelected: PropTypes.func.isRequired,
  onChangeAirportText: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onRewardOptionSelected: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onBypassMaxPurchaseOptionSelected: PropTypes.func.isRequired,
  onBypassAchievementValidationOptionSelected: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  onMerchantOptionSelected: PropTypes.func.isRequired,
  onAddVouchersPressed: PropTypes.func.isRequired,
  onDeleteVouchersPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onUserTypeSelected: PropTypes.func.isRequired,
};

PromoCodePage.defaultProps = {
  selectedAirport: {},
  selectedVoucher: null,
  tappedPromoCode: {},
  selectedRewardType: '',
};
