import { downloadBookingProceedByDealer } from '../../../helper';
import { downloadingBookingProceedByDealer, setBookingProceedByDealer } from '../simple-action';

export default (startDate, endDate) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingBookingProceedByDealer(true));
    const bookingCreated = await downloadBookingProceedByDealer(token, startDate, endDate);

    dispatch(setBookingProceedByDealer(bookingCreated));
  } finally {
    dispatch(downloadingBookingProceedByDealer(false));
  }
};
