import { downloadIims2023Transaction } from '../../../helper';
import { addIims2023Transaction, downloadingIims2023Transaction } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingIims2023Transaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiIims2023Transaction;

    const Iims2023Transaction = await downloadIims2023Transaction(tappedId, token);
    dispatch(addIims2023Transaction(Iims2023Transaction));
  } finally {
    dispatch(downloadingIims2023Transaction(false));
  }
};
