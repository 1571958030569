import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  INITIAL_ORDER_BY_WASTE_DEALER_CODE,
  MENUID_E_WASTE_DEALER_CODE,
  PAGE_MODE_TABLE,
  RXFIELD_WASTE_DEALER_CODE_DEALER_NAME,
  RXFORM_WASTE_DEALER_CODE,
} from '../../constant';
import {
  transformInitialValues,
  transformDropdownData,
  debounceSearch,
} from '../../helper';
import {
  setActiveSideMenuItem,
  setAlertErrorMessage,
} from '../../../../redux/action';
import WasteDealerCodePage from './waste-dealer-code.presentation';
import GlobalLocalizedString from '../../../../localization';
import {
  addWasteDealerCodeAsync,
  clearWasteDealerCodes,
  downloadDeleteWasteDealerCodeAsync,
  downloadWasteDealerCodesAsync,
  setAlertConfirmationMessage,
  setWasteDealerCodesAdvancedFilterString,
  setWasteDealerCodesSearchText,
  setWasteDealerCodesSelectedOrderBy,
  setWasteDealerCodesSelectedPageSize,
  setWasteDealerCodeTappedDealerId,
  setWasteDealerCodeTappedId,
} from '../../redux/action';
import {
  clearDealers,
  downloadDealersAsync,
  setDealerAdvancedFilterDialogSelectedFilterString,
  setDealerSearchText,
} from '../../../car-service/redux/action';

const getInitialValues = (state) => {
  const { wasteDealerCodes, uiFunctionalPage, uiWasteDealerCode } = state;
  const { data } = wasteDealerCodes;
  const { tappedId, downloadingDeleting } = uiWasteDealerCode;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId]
    : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      name: found.dealer.name ?? '',
      dealerId: found.dealer.id ?? '',
      code: found.code ?? '',
    }) : {};

  return result;
};

const searchDealerDebounce = debounceSearch((dispatch) => {
  dispatch(
    setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'),
  );
  dispatch(clearDealers());
  dispatch(downloadDealersAsync(1))
    .catch((error) => setAlertErrorMessage(error));
});

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiWasteDealerCode.downloading,
  dealers: transformDropdownData(state.dealers.data),
  initialValues: getInitialValues(state),
  loadingDealers: state.uiDealer.downloading,
  addingEditing: state.uiWasteDealerCode.addingEditing,
  downloadingDeleting: state.uiWasteDealerCode.downloadingDeleting,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_E_WASTE_DEALER_CODE));
    dispatch(setWasteDealerCodesAdvancedFilterString(''));
    dispatch(setWasteDealerCodesSearchText(''));
    dispatch(clearWasteDealerCodes());
    dispatch(setWasteDealerCodesSelectedPageSize(20));
    dispatch(
      setWasteDealerCodesSelectedOrderBy(INITIAL_ORDER_BY_WASTE_DEALER_CODE),
    );
    dispatch(downloadWasteDealerCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setWasteDealerCodesSelectedOrderBy(orderBy));
    dispatch(clearWasteDealerCodes());
    dispatch(downloadWasteDealerCodesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setWasteDealerCodeTappedId(id));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setWasteDealerCodesSearchText(text));
    dispatch(clearWasteDealerCodes());
    dispatch(downloadWasteDealerCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWasteDealerCodesAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWasteDealerCodesSelectedPageSize(pageSize));
    dispatch(downloadWasteDealerCodesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setWasteDealerCodesSelectedPageSize(pageSize));
    dispatch(clearWasteDealerCodes());
    dispatch(downloadWasteDealerCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearWasteDealerCodes());
    dispatch(setWasteDealerCodesAdvancedFilterString(''));
    dispatch(
      setWasteDealerCodesSelectedOrderBy(INITIAL_ORDER_BY_WASTE_DEALER_CODE),
    );
    dispatch(downloadWasteDealerCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('dealer', 'dealer.id');

    dispatch(setWasteDealerCodesAdvancedFilterString(text));
    dispatch(clearWasteDealerCodes());
    dispatch(downloadWasteDealerCodesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(
      setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'),
    );
    dispatch(setDealerSearchText(''));
    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeDealerText: async (text) => {
    dispatch(setDealerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDealerDebounce(dispatch);
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteWasteDealerCodeAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onCreatePressed: () => {
    dispatch(setWasteDealerCodeTappedDealerId(''));
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setDealerSearchText(''));
    dispatch(clearDealers());
    dispatch(downloadDealersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
  onDeletePressed: (id) => {
    dispatch(setWasteDealerCodeTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onSubmitPressed: async () => {
    await dispatch(addWasteDealerCodeAsync());
  },
  onDealerOptionSelected: (option) => {
    if (option) {
      dispatch(setWasteDealerCodeTappedDealerId(option.value));
      dispatch(change(RXFORM_WASTE_DEALER_CODE, RXFIELD_WASTE_DEALER_CODE_DEALER_NAME, option));
    } else {
      dispatch(setWasteDealerCodeTappedDealerId(''));
      dispatch(change(RXFORM_WASTE_DEALER_CODE, RXFIELD_WASTE_DEALER_CODE_DEALER_NAME, ''));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WasteDealerCodePage);
