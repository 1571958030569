import { transformSearchText } from '../../../../../helper';
import { downloadJaw2023Transactions } from '../../../helper';
import { downloadingJaw2023Transactions, setJaw2023Transactions } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, jaw2023Transactions, uiJaw2023Transaction } = getState();
  const { token } = authentication;
  const { meta } = jaw2023Transactions;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiJaw2023Transaction;


  try {
    dispatch(downloadingJaw2023Transactions(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadJaw2023Transactions(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setJaw2023Transactions(list));
    }
  } finally {
    dispatch(downloadingJaw2023Transactions(false));
  }
};
