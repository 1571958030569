import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, withStyles,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { reduxForm, Field } from 'redux-form';
import {
  COLOR_BODY_TEXT, COLOR_ICON, COLOR_PRIMARY, FILTER_TYPE_DATE, FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_MULTIPLE_VALUES, FILTER_TYPE_NUMBER, FILTER_TYPE_NUMBER_RANGE,
  FILTER_TYPE_RADIO_BUTTON, FILTER_TYPE_SWITCH, FILTER_TYPE_TEXT, REGEX_DATE_FORMAT,
  REGEX_DATE_TIME_FORMAT, RXFORM_ADVANCED_FILTER_DIALOG, switches,
} from '../../../constant';
import {
  localDateToUtc, toMoment, toNumber, toUtcMoment,
} from '../../../helper';
import LocalizedString from '../../../localization';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormRadioButtonField, renderReduxFormSimpleDropdownField,
} from '../../../redux-form-rendererer';
import { TableColumnShape } from '../../../type';
import AccentButton from '../../accent-button';
import SectionTitle from '../../section-title';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  dialogContent: {
    paddingTop: 20,
    paddingBottom: 0,
    overflow: 'auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '12px 0px 12px 0px',
  },
  buttonText: {
    color: COLOR_BODY_TEXT,
  },
  buttonOutline: {
    color: COLOR_BODY_TEXT,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
}));

const getGroupedColumns = (filterColumns, filterSections) => {
  const mapped = filterColumns.reduce((acc, item) => (acc.has(item.sectionId)
    ? acc.set(item.sectionId, acc.get(item.sectionId).concat({ ...item }))
    : acc.set(item.sectionId, [{ ...item }])),
  new Map());

  return [...mapped].map((e) => {
    const { sectionId } = e[1][0];
    const title = sectionId ? filterSections.find((x) => x.id === sectionId).title : null;
    return ({ title, data: e[1] });
  });
};

const onClosePress = (onCancelAdvancedFilterPressed, onClosePressed, onResetPressed,
  setErrorDateRange, setErrorNumRange, filterString) => {
  setErrorDateRange(null);
  setErrorNumRange(null);
  onClosePressed();
  onCancelAdvancedFilterPressed();
  if (typeof onResetPressed === 'function' && !filterString) { onResetPressed(); }
};

const onHandleSubmit = async (e, onApplyFilterPressed, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onClosePressed, onError, setErrorDateRange, setErrorNumRange) => {
  setErrorDateRange(null);
  setErrorNumRange(null);

  let result = '';
  let minNum = null;
  let maxNum = null;
  let minDate = '';
  let maxDate = '';

  Object.keys(e).forEach((key) => {
    if (typeof e[key] === 'string' && e[key].match(/^[0-9]*$/)) {
      if (key.includes('>')) {
        minNum += toNumber(e[key]);
      }
      if (key.includes('<')) {
        maxNum += toNumber(e[key]);
      }
    }
    if (typeof e[key] === 'string' && (e[key].match(REGEX_DATE_TIME_FORMAT) || e[key].match(REGEX_DATE_FORMAT))) {
      if (key.includes('>')) {
        minDate += toUtcMoment(e[key]);
      }
      if (key.includes('<')) {
        maxDate += toUtcMoment(e[key]);
      }
    }
  });

  const dateRangeCheck = minDate && maxDate && !toMoment(maxDate).isSameOrAfter(toMoment(minDate));
  const numberRangeCheck = (minNum && maxNum && minNum > maxNum)
  || (minNum > maxNum && maxNum === 0);
  if (dateRangeCheck) {
    setErrorDateRange(LocalizedString.common.errMsgStartEndDate);
  }
  if (numberRangeCheck) {
    setErrorNumRange(LocalizedString.common.errMsgMinMaxNum);
  }
  if (!dateRangeCheck && !numberRangeCheck) {
    Object.keys(e).forEach((key) => {
      if ((!e[key] && typeof e[key] !== 'boolean' && typeof e[key] !== 'object')) {
        return delete e[key];
      }
      if (typeof e[key] === 'string' && (e[key].match(REGEX_DATE_TIME_FORMAT) || e[key].match(REGEX_DATE_FORMAT))) {
        if (e[key].match(REGEX_DATE_TIME_FORMAT)) {
          result += `|${key}=${localDateToUtc(e[key])}`;
        } else if (e[key].match(REGEX_DATE_FORMAT)) {
          if (key.includes('>')) {
            result += `|${key}=${localDateToUtc(toMoment(e[key]).startOf('day'))}`;
          }
          if (key.includes('<')) {
            result += `|${key}=${localDateToUtc(toMoment(e[key]).endOf('day'))}`;
          }
        }
      }
      if ((typeof e[key] === 'object' && e[key].value)
        || (typeof e[key] === 'string' && !(e[key].match(REGEX_DATE_TIME_FORMAT) || e[key].match(REGEX_DATE_FORMAT)))
        || (typeof e[key] === 'boolean')) {
        result += `|${key}=${e[key] && typeof e[key] === 'object' && e[key].value ? encodeURIComponent(e[key].value) : encodeURIComponent(e[key])}`;
      }
      if (e[key] instanceof Array) {
        if (e[key].some((item) => typeof item === 'string')) {
          const transformArr = e[key].map((x) => encodeURIComponent(x));
          result += `|${key}><${transformArr.join(';')}`;
        }
        if (e[key].some((item) => typeof item === 'object')) {
          const transformArr = e[key].map((x) => encodeURIComponent(x.value));
          result += `|${key}><${transformArr.join(';')}`;
        }
      }
      return result;
    });

    if (result) {
      try {
        onApplyFilterPressed(result);
        await onApplyAdvancedFilterPressed(result);
        onClosePress(onCancelAdvancedFilterPressed, onClosePressed, null, setErrorDateRange,
          setErrorNumRange, null);
      } catch (error) {
        onError(error);
      }
    }
  }
};

const renderItem = (item, downloading, errorDateRange, errorNumRange, onChangeDate,
  onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange) => {
  let component = null;

  switch (item.type) {
    case FILTER_TYPE_DATE: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormDateTimePickerField}
            label={item.title}
            disabled={downloading}
            pickerMode={item.pickerMode}
            onChangeDate={(e) => onChangeDate(e, item.field)}
            dateFormat={item.format}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_DATE_RANGE: {
      component = (
        <Grid item container key={item.field}>
          <Grid item sm md>
            <Field
              name={`${item.field}>`}
              component={renderReduxFormDateTimePickerField}
              label={`${LocalizedString.common.labelMin} ${item.title}`}
              disabled={downloading}
              pickerMode={item.pickerMode}
              onChangeDate={(e) => {
                setErrorDateRange(null);
                onChangeDate(e, `${item.field}>`);
              }}
              helperText={errorDateRange}
              dateFormat={item.format}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={`${item.field}<`}
              component={renderReduxFormDateTimePickerField}
              label={`${LocalizedString.common.labelMax} ${item.title}`}
              disabled={downloading}
              pickerMode={item.pickerMode}
              onChangeDate={(e) => {
                setErrorDateRange(null);
                onChangeDate(e, `${item.field}<`);
              }}
              helperText={errorDateRange}
              dateFormat={item.format}
            />
          </Grid>
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_DROPDOWN: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            data={item.data}
            loading={item.loading}
            onChangeText={item.onChangeFilterText}
            onOptionSelected={item.onFilterOptionSelected ? (e) => {
              onChangeDropdown(e, item.field);
              item.onFilterOptionSelected();
            } : (e) => onChangeDropdown(e, item.field)}
            onBlur={item.useDropdownValue ? (e) => e.preventDefault() : undefined}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_MULTIPLE_VALUES: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormAutocompleteCheckboxField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            data={item.data}
            loading={item.loading}
            onChangeText={item.onChangeFilterText}
            onOptionSelected={item.onFilterOptionSelected ? (e) => {
              onChangeDropdown(e, item.field);
              item.onFilterOptionSelected(e);
            } : (e) => onChangeDropdown(e, item.field)}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_NUMBER: {
      component = (
        <Grid item xs md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            type="number"
            onChangeText={(e) => onChangeText(e, item.field)}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_NUMBER_RANGE: {
      component = (
        <Grid item container key={item.field}>
          <Grid item sm md>
            <Field
              name={`${item.field}>`}
              component={renderReduxFormOutlinedTextField}
              placeholder={`${LocalizedString.common.labelMin} ${item.title}`}
              label={`${LocalizedString.common.labelMin} ${item.title}`}
              disabled={downloading}
              onChangeText={(e) => {
                setErrorNumRange(null);
                onChangeText(e, `${item.field}>`);
              }}
              type="number"
              helperText={errorNumRange}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={`${item.field}<`}
              component={renderReduxFormOutlinedTextField}
              placeholder={`${LocalizedString.common.labelMax} ${item.title}`}
              label={`${LocalizedString.common.labelMax} ${item.title}`}
              disabled={downloading}
              onChangeText={(e) => {
                setErrorNumRange(null);
                onChangeText(e, `${item.field}<`);
              }}
              type="number"
              helperText={errorNumRange}
            />
          </Grid>
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_RADIO_BUTTON: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormRadioButtonField}
            label={item.title}
            data={item.data}
            onOptionSelected={item.onFilterOptionSelected}
            disabled={downloading}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_SWITCH: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormSimpleDropdownField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            data={item.data && item.data.length > 0 ? item.data : switches}
            onOptionSelected={(e) => onChangeSwitch(e, item.field)}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_TEXT: {
      component = (
        <Grid item xs md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            onChangeText={(e) => onChangeText(e, item.field)}
          />
        </Grid>
      );
      break;
    }

    default: {
      component = (
        <Grid item xs md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
          />
        </Grid>
      );
      break;
    }
  }

  return component;
};

const renderItemContainer = (data, downloading, errorDateRange, errorNumRange, onChangeDate,
  onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange) => (
    <Grid container>
      {data.map((x) => renderItem(x, downloading, errorDateRange, errorNumRange, onChangeDate,
        onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange))}
    </Grid>
);

const AdvancedFilterDialog = ({
  filterColumns, filterSections,
  advancedFilterVisibility, downloading,
  handleSubmit, onApplyFilterPressed, onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed,
  onChangeDate, onChangeDropdown, onChangeText, onChangeSwitch, onClosePressed, onError,
  onResetAdvancedFilterPressed, onResetPressed,
  classes,
  filterString,
}) => {
  const [errorDateRange, setErrorDateRange] = useState(null);
  const [errorNumRange, setErrorNumRange] = useState(null);
  const columnsWithSection = filterSections.length > 0
    ? getGroupedColumns(filterColumns, filterSections) : [];

  return (
    <Dialog
      open={advancedFilterVisibility}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <div className={classes.headerContainer}>
        <DialogTitle>{LocalizedString.common.labelAdvancedFilter}</DialogTitle>

        <IconButton
          onClick={() => onClosePress(onCancelAdvancedFilterPressed, onClosePressed, onResetPressed,
            setErrorDateRange, setErrorNumRange, filterString)}
          disabled={downloading}
        >
          <CloseRounded style={{ color: COLOR_ICON }} />
        </IconButton>
      </div>

      <DialogContent className={classes.dialogContent}>
        <form
          onSubmit={handleSubmit((e) => onHandleSubmit(e, onApplyFilterPressed,
            onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onClosePressed, onError,
            setErrorDateRange, setErrorNumRange))}
          className={classes.form}
        >

          {columnsWithSection.length > 0 ? columnsWithSection.map((x) => (
            <div>
              {x.title && (<SectionTitle title={x.title} />)}
              {renderItemContainer(x.data, downloading, errorDateRange, errorNumRange,
                onChangeDate, onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange,
                setErrorNumRange)}
            </div>
          )) : renderItemContainer(filterColumns, downloading, errorDateRange, errorNumRange,
            onChangeDate, onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange,
            setErrorNumRange)}

          <DialogActions className={classes.buttonContainer}>
            <AccentButton
              onClick={() => onClosePress(onCancelAdvancedFilterPressed, onClosePressed,
                onResetPressed, setErrorDateRange, setErrorNumRange, filterString)}
              variant="text"
              caption={LocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              disabled={downloading}
            />

            <AccentButton
              onClick={() => {
                setErrorDateRange(null);
                setErrorNumRange(null);
                onClosePressed();
                onResetPressed();
                onResetAdvancedFilterPressed();
              }}
              variant="outlined"
              caption={LocalizedString.common.buttonCaptionReset}
              className={classes.buttonOutline}
              disabled={downloading}
            />

            <AccentButton
              type="submit"
              disableElevation
              caption={LocalizedString.common.buttonCaptionApply}
              disabled={downloading}
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(reduxForm({
  form: RXFORM_ADVANCED_FILTER_DIALOG,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AdvancedFilterDialog));

AdvancedFilterDialog.propTypes = {
  filterColumns: PropTypes.arrayOf(TableColumnShape).isRequired,
  filterSections: PropTypes.arrayOf(TableColumnShape).isRequired,
  advancedFilterVisibility: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onChangeSwitch: PropTypes.func.isRequired,
  onClosePressed: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetPressed: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  filterString: PropTypes.string.isRequired,
};
