import {
  downloadingDeletingTestDriveUniqueCode, addTestDriveUniqueCode,
} from '../simple-action';
import { downloadTestDriveUniqueCode } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingTestDriveUniqueCode(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiUniqueCode;

    const uniqueCode = await downloadTestDriveUniqueCode(tappedId, token);
    dispatch(addTestDriveUniqueCode(uniqueCode));
  } finally {
    dispatch(downloadingDeletingTestDriveUniqueCode(false));
  }
};
