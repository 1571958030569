import { connect } from 'react-redux';
import { change, reset, formValueSelector } from 'redux-form';
import {
  INITIAL_ORDER_BY_TEST_DRIVE_EVENTS, PAGE_MODE_TABLE,
  RXFORM_EVENT, MENUID_TEST_DRIVE_EVENT, RXFIELD_TEST_DRIVE_EVENT_URL1_EXTERNAL_BROWSER,
  RXFIELD_TEST_DRIVE_EVENT_URL2_EXTERNAL_BROWSER, RXFIELD_TEST_DRIVE_EVENT_VISIBILITY,
  DATE_TIME_FORMAT_WITHOUT_PIPE, RXFIELD_TEST_DRIVE_EVENT_VOUCHER,
  RXFIELD_TEST_DRIVE_EVENT_PROVINCE, RXFIELD_TEST_DRIVE_EVENT_CITY,
  RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL_EXTERNAL_BROWSER,
} from '../../constant';
import { transformInitialValues, toMoment, transformDropdownData } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearEvents, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setEventAdvancedFilterDialogSelectedFilterString, setEventSearchText,
  setEventSelectedOrderBy, setEventSelectedPageSize, setEventTappedId,
  downloadDeleteEventAsync, downloadEventsAsync,
  addEditEventAsync, setEventUrl1ExtensionBrowser,
  setEventVisibility, setEventUrl2ExtensionBrowser,
  setEventSelectedVoucher, setProvinceAdvancedFilterDialogSelectedFilterString,
  setProvinceSearchText, clearProvinces, setEventSelectedProvinceId,
  setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText,
  clearCities, setEventSelectedCityId, setEventSurveyUrlExtensionBrowser,
  copyingEventCode,
} from '../../redux/action';
import {
  downloadProvincesAsync, downloadCitiesAsync,
} from '../../../../redux/action';
import {
  downloadVouchersAsync,
  setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText, clearVouchers,
} from '../../../voucher';
import EventPage from './event.presentation';

const getInitialValues = (state) => {
  const {
    events, uiFunctionalPage, uiEvent,
  } = state;
  const { data } = events;
  const { downloadingDeleting, tappedId } = uiEvent;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      name: found.name ?? '',
      url1: found.url1 ?? '',
      url2: found.url2 ?? '',
      surverUrl: found.surveyUrl ?? '',
      startTime: found.startTime ? toMoment(found.startTime, found.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      endTime: found.endTime ? toMoment(found.endTime, found.city.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      vouchers: {
        label: found.voucherRewards[0].voucherName,
        value: found.voucherRewards[0].voucherId,
      },
      provinces: { label: found.city?.province.name, value: found.city?.province.id },
      city: { label: found.city?.name, value: found.city?.id },
      eventCode: found.randomId ? `testdriveevent:${found.randomId}` : '',
    }) : {
      vouchers: { label: '', value: '' },
      provinces: { label: '', value: '' },
      city: { label: '', value: '' },
      eventCode: '',
    };
  return result;
};

const selector = formValueSelector(RXFORM_EVENT);

const mapStateToProps = (state) => ({
  events: state.events.data,
  initialValues: getInitialValues(state),
  cities: transformDropdownData(state.cities.data),
  provinces: transformDropdownData(state.provinces.data),
  downloading: state.uiEvent.downloading,
  addingEditing: state.uiEvent.addingEditing,
  copying: state.uiEvent.copying,
  downloadingDeleting: state.uiEvent.downloadingDeleting,
  loadingCity: state.uiCity.downloading,
  loadingEvent: state.uiEvent.downloading,
  loadingProvince: state.uiProvince.downloading,
  loadingVouchers: state.uiVoucher.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiEvent.tappedId,
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  selectedProvince: typeof selector(state, RXFIELD_TEST_DRIVE_EVENT_PROVINCE) === 'object'
    ? selector(state, RXFIELD_TEST_DRIVE_EVENT_PROVINCE)?.label || ''
    : selector(state, RXFIELD_TEST_DRIVE_EVENT_PROVINCE) || '',
  provinceId: state.uiEvent.selectedProvinceId,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('city', 'city.id').replace('province', 'city.province.id');
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_TEST_DRIVE_EVENT));
    dispatch(setEventSearchText(''));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEvents());
    dispatch(setEventSelectedPageSize(20));
    dispatch(setEventSelectedOrderBy(INITIAL_ORDER_BY_TEST_DRIVE_EVENTS));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setEventSelectedCityId(''));
    dispatch(reset(RXFORM_EVENT));
  },
  onChangeCityText: async (text, provinceId) => {
    try {
      if (provinceId) {
        dispatch(setCityAdvancedFilterDialogSelectedFilterString(`status=enabled|province.id=${provinceId}`));
      }
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventSelectedPageSize(pageSize));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProvinceText: async (text) => {
    try {
      dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCityOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedCityId(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_CITY, option));
    } else {
      dispatch(setEventSelectedCityId(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_CITY, ''));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteEventAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCopyPressed: (status) => {
    dispatch(copyingEventCode(!status));
  },
  onCreatePressed: () => {
    dispatch(setEventTappedId(''));
    dispatch(setEventSearchText(''));
    dispatch(setEventUrl1ExtensionBrowser(false));
    dispatch(setEventUrl2ExtensionBrowser(false));
    dispatch(setEventSurveyUrlExtensionBrowser(false));

    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setEventTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id, events) => {
    dispatch(setEventTappedId(id));
    dispatch(setEventSelectedProvinceId(events[id]?.city?.province?.id));
    dispatch(downloadDeleteEventAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setCityAdvancedFilterDialogSelectedFilterString(`status=enabled|province.id=${events[id]?.city?.province?.id}`));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onProvinceOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedProvinceId(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_PROVINCE, option));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_CITY, null));
      dispatch(setEventSelectedCityId(''));
      dispatch(setCityAdvancedFilterDialogSelectedFilterString(`status=enabled|province.id=${option.value}`));
      dispatch(setCitySearchText(''));
      dispatch(clearCities());
      dispatch(downloadCitiesAsync(1))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      dispatch(setEventSelectedProvinceId(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_PROVINCE, null));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setEventSelectedPageSize(pageSize));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearEvents());
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSelectedOrderBy(INITIAL_ORDER_BY_TEST_DRIVE_EVENTS));
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description, location, visibility,
    startTime, endTime, url1, url2, surveyUrl,
  }) => {
    try {
      await dispatch(addEditEventAsync(name, description, location,
        visibility, startTime, endTime, url1, url2, surveyUrl));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventSearchText(text));
      dispatch(clearEvents());
      await dispatch(downloadEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventSelectedOrderBy(orderBy));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },

  onUrl1ExtensionBrowserOptionSelected: (option) => {
    if (option) {
      dispatch(setEventUrl1ExtensionBrowser(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_URL1_EXTERNAL_BROWSER, option));
    } else {
      dispatch(setEventUrl1ExtensionBrowser(false));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_URL1_EXTERNAL_BROWSER, ''));
    }
  },
  onUrl2ExtensionBrowserOptionSelected: (option) => {
    if (option) {
      dispatch(setEventUrl2ExtensionBrowser(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_URL2_EXTERNAL_BROWSER, option));
    } else {
      dispatch(setEventUrl2ExtensionBrowser(false));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_URL2_EXTERNAL_BROWSER, ''));
    }
  },
  onSubmitPressed: async ({
    name, description, location, visibility,
    startTime, endTime, url1, url2, surveyUrl,
  }) => {
    await dispatch(addEditEventAsync(name, description, location,
      visibility, startTime, endTime, url1, url2, surveyUrl));
  },
  onSurveyUrlExtensionBrowserOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSurveyUrlExtensionBrowser(option.value));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL_EXTERNAL_BROWSER, option));
    } else {
      dispatch(setEventSurveyUrlExtensionBrowser(false));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL_EXTERNAL_BROWSER, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setEventTappedId(id));
    dispatch(downloadDeleteEventAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityOptionSelected: (option) => {
    if (option) {
      dispatch(setEventVisibility(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_VISIBILITY, option));
    } else {
      dispatch(setEventVisibility(''));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_VISIBILITY, ''));
    }
  },
  onVoucherOptionSelected: (option) => {
    if (option) {
      dispatch(setEventSelectedVoucher(option));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_VOUCHER, option));
    } else {
      dispatch(setEventSelectedVoucher(null));
      dispatch(change(RXFORM_EVENT, RXFIELD_TEST_DRIVE_EVENT_VOUCHER, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
