import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const SIMPLE_ACTION_NAME = 'SIMPLE_ACTION_NAME';
export const simpleActionName = makeActionCreator(SIMPLE_ACTION_NAME, 'actionValueName');


export const DOWNLOADING_CONFIG_GROUPS = 'DOWNLOADING_CONFIG_GROUPS';
export const downloadingConfigGroups = makeActionCreator(DOWNLOADING_CONFIG_GROUPS, 'status');

export const SET_CONFIG_GROUPS = 'SET_CONFIG_GROUPS';
export const setConfigGroups = makeActionCreator(SET_CONFIG_GROUPS, 'data');

export const ADD_CONFIG_GROUP = 'ADD_CONFIG_GROUP';
export const addConfigGroup = makeActionCreator(ADD_CONFIG_GROUP, 'data');

export const CLEAR_CONFIG_GROUPS = 'CLEAR_CONFIG_GROUPS';
export const clearConfigGroups = makeActionCreator(CLEAR_CONFIG_GROUPS);

export const ADDING_EDITING_CONFIG_GROUP = 'ADDING_EDITING_CONFIG_GROUP';
export const addingEditingConfigGroup = makeActionCreator(ADDING_EDITING_CONFIG_GROUP, 'status');

export const DOWNLOADING_DELETING_CONFIG_GROUP = 'DOWNLOADING_DELETING_CONFIG_GROUP';
export const downloadingDeletingConfigGroup = makeActionCreator(DOWNLOADING_DELETING_CONFIG_GROUP, 'status');

export const SET_CONFIG_GROUP_SELECTED_PAGE_SIZE = 'SET_CONFIG_GROUP_SELECTED_PAGE_SIZE';
export const setConfigGroupSelectedPageSize = makeActionCreator(SET_CONFIG_GROUP_SELECTED_PAGE_SIZE, 'size');

export const SET_CONFIG_GROUP_SELECTED_ORDER_BY = 'SET_CONFIG_GROUP_SELECTED_ORDER_BY';
export const setConfigGroupSelectedOrderBy = makeActionCreator(SET_CONFIG_GROUP_SELECTED_ORDER_BY, 'order');

export const SET_CONFIG_GROUP_TAPPED_ID = 'SET_CONFIG_GROUP_TAPPED_ID';
export const setConfigGroupTappedId = makeActionCreator(SET_CONFIG_GROUP_TAPPED_ID, 'id');

export const SET_CONFIG_GROUP_SEARCH_TEXT = 'SET_CONFIG_GROUP_SEARCH_TEXT';
export const setConfigGroupSearchText = makeActionCreator(SET_CONFIG_GROUP_SEARCH_TEXT, 'text');


export const DOWNLOADING_CONFIG_ITEMS = 'DOWNLOADING_CONFIG_ITEMS';
export const downloadingConfigItems = makeActionCreator(DOWNLOADING_CONFIG_ITEMS, 'status');

export const SET_CONFIG_ITEMS = 'SET_CONFIG_ITEMS';
export const setConfigItems = makeActionCreator(SET_CONFIG_ITEMS, 'data');

export const ADD_CONFIG_ITEM = 'ADD_CONFIG_ITEM';
export const addConfigItem = makeActionCreator(ADD_CONFIG_ITEM, 'data');

export const CLEAR_CONFIG_ITEMS = 'CLEAR_CONFIG_ITEMS';
export const clearConfigItems = makeActionCreator(CLEAR_CONFIG_ITEMS);

export const ADDING_EDITING_CONFIG_ITEM = 'ADDING_EDITING_CONFIG_ITEM';
export const addingEditingConfigItem = makeActionCreator(ADDING_EDITING_CONFIG_ITEM, 'status');

export const DOWNLOADING_DELETING_CONFIG_ITEM = 'DOWNLOADING_DELETING_CONFIG_ITEM';
export const downloadingDeletingConfigItem = makeActionCreator(DOWNLOADING_DELETING_CONFIG_ITEM, 'status');

export const SET_CONFIG_ITEM_SELECTED_PAGE_SIZE = 'SET_CONFIG_ITEM_SELECTED_PAGE_SIZE';
export const setConfigItemSelectedPageSize = makeActionCreator(SET_CONFIG_ITEM_SELECTED_PAGE_SIZE, 'size');

export const SET_CONFIG_ITEM_SELECTED_ORDER_BY = 'SET_CONFIG_ITEM_SELECTED_ORDER_BY';
export const setConfigItemSelectedOrderBy = makeActionCreator(SET_CONFIG_ITEM_SELECTED_ORDER_BY, 'order');

export const SET_CONFIG_ITEM_TAPPED_ID = 'SET_CONFIG_ITEM_TAPPED_ID';
export const setConfigItemTappedId = makeActionCreator(SET_CONFIG_ITEM_TAPPED_ID, 'id');

export const SET_CONFIG_ITEM_SEARCH_TEXT = 'SET_CONFIG_ITEM_SEARCH_TEXT';
export const setConfigItemSearchText = makeActionCreator(SET_CONFIG_ITEM_SEARCH_TEXT, 'text');

export const SET_CONFIG_ITEM_SELECTED_DATA_TYPE = 'SET_CONFIG_ITEM_SELECTED_DATA_TYPE';
export const setConfigItemSelectedDataType = makeActionCreator(SET_CONFIG_ITEM_SELECTED_DATA_TYPE, 'option');

export const SET_CONFIG_ITEM_SELECTED_USED_ON = 'SET_CONFISET_CONFIG_ITEM_SELECTED_USED_ONG_ITEM_SEARCH_TEXT';
export const setConfigItemSelectedUsedOn = makeActionCreator(SET_CONFIG_ITEM_SELECTED_USED_ON, 'option');

export const SET_CONFIG_ITEM_SELECTED_CONFIG_GROUP = 'SET_CONFIG_ITEM_SELECTED_CONFIG_GROUP';
export const setConfigItemSelectedConfigGroup = makeActionCreator(SET_CONFIG_ITEM_SELECTED_CONFIG_GROUP, 'option');

export const SET_CONFIG_ITEM_DULICATE_MODE = 'SET_CONFIG_ITEM_DULICATE_MODE';
export const setConfigItemDuplicateMode = makeActionCreator(SET_CONFIG_ITEM_DULICATE_MODE, 'status');


export const DOWNLOADING_ALL_CONFIG_GROUPS = 'DOWNLOADING_ALL_CONFIG_GROUPS';
export const downloadingAllConfigGroups = makeActionCreator(DOWNLOADING_ALL_CONFIG_GROUPS, 'status');

export const SET_ALL_CONFIG_GROUPS = 'SET_ALL_CONFIG_GROUPS';
export const setAllConfigGroups = makeActionCreator(SET_ALL_CONFIG_GROUPS, 'data');

export const DOWNLOADING_CONFIG_VALUES = 'DOWNLOADING_CONFIG_VALUES';
export const downloadingConfigValues = makeActionCreator(DOWNLOADING_CONFIG_VALUES, 'status');

export const SET_CONFIG_VALUES = 'SET_CONFIG_VALUES';
export const setConfigValues = makeActionCreator(SET_CONFIG_VALUES, 'data');

export const CLEAR_CONFIG_VALUES = 'CLEAR_CONFIG_VALUES';
export const clearConfigValues = makeActionCreator(CLEAR_CONFIG_VALUES);

export const SET_CONFIG_VALUE_DISPLAY_UNSAVED_CHANGE_DIALOG = 'SET_CONFIG_VALUE_DISPLAY_UNSAVED_CHANGE_DIALOG';
export const setConfigValueDisplayUnsavedChangeDialog = makeActionCreator(SET_CONFIG_VALUE_DISPLAY_UNSAVED_CHANGE_DIALOG, 'view');

export const SET_SELECTED_CONFIG_VALUE_FILTER = 'SET_SELECTED_CONFIG_VALUE_FILTER';
export const setSelectedConfigValueFilter = makeActionCreator(SET_SELECTED_CONFIG_VALUE_FILTER, 'value');

export const EDITING_CONFIG_ITEMS = 'EDITING_CONFIG_ITEMS';
export const editingConfigItems = makeActionCreator(EDITING_CONFIG_ITEMS, 'status');


export const RESYNCING_CONFIGS = 'RESYNCING_CONFIGS';
export const resyncingConfigs = makeActionCreator(RESYNCING_CONFIGS, 'status');

export const SET_RESYNC_CONFIGS = 'SET_RESYNC_CONFIGS';
export const setResyncConfigs = makeActionCreator(SET_RESYNC_CONFIGS, 'data');

export const RESYNCING_MASTER_DATA = 'RESYNCING_MASTER_DATA';
export const resyncingMasterData = makeActionCreator(RESYNCING_MASTER_DATA, 'status');

export const SET_RESYNC_MASTER_DATA = 'SET_RESYNC_MASTER_DATA';
export const setResyncMasterData = makeActionCreator(SET_RESYNC_MASTER_DATA, 'data');

export const RESYNCING_USERS = 'RESYNCING_USERS';
export const resyncingUsers = makeActionCreator(RESYNCING_USERS, 'status');

export const SET_RESYNC_USERS = 'SET_RESYNC_USERS';
export const setResyncUsers = makeActionCreator(SET_RESYNC_USERS, 'data');

export const RESYNCING_DB_USERS = 'RESYNCING_DB_USERS';
export const resyncingDBUsers = makeActionCreator(RESYNCING_DB_USERS, 'status');

export const SET_RESYNC_DB_USERS = 'SET_RESYNC_DB_USERS';
export const setResyncDBUsers = makeActionCreator(SET_RESYNC_DB_USERS, 'data');

export const SET_SELECTED_RESYNC_ACTION = 'SET_SELECTED_RESYNC_ACTION';
export const setSelectedResyncAction = makeActionCreator(SET_SELECTED_RESYNC_ACTION, 'option');

export const SET_SELECTED_MASTER_DATA_RESYNC_ACTION = 'SET_SELECTED_MASTED_DATA_RESYNC_ACTION';
export const setSelectedMasterDataResyncAction = makeActionCreator(SET_SELECTED_MASTER_DATA_RESYNC_ACTION, 'option');

export const CLEAR_SELECTED_MASTER_DATA_RESYNC_ACTION = 'CLEAR_SELECTED_MASTER_DATA_RESYNC_ACTION';
export const clearSelectedMasterDataResyncAction = makeActionCreator(
  CLEAR_SELECTED_MASTER_DATA_RESYNC_ACTION,
);

export const SET_SELECTED_CHANNEL_RESYNC_ACTION = 'SET_SELECTED_CHANNEL_RESYNC_ACTION';
export const setSelectedChannelResyncAction = makeActionCreator(SET_SELECTED_CHANNEL_RESYNC_ACTION, 'option');
