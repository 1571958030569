import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  status,
  FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT,
  RXSTATE_CAR_SERVICE_CHARGING_STATIONS,
  RXSTATE_CAR_SERVICE_CHARGING_STATION_PAGE,
  RXFORM_CAR_SERVICE_CHARGING_STATION,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_STATION_ID,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_NAME,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_ADDRESS,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_CITY,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_REGION,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_POSTAL_CODE,
  COLOR_PRIMARY,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_CATEGORY,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_LONGITUDE,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_LATITUDE,
  RXFIELD_CAR_SERVICE_CHARGING_STATION_TYPE,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  buttonText: {
    color: COLOR_PRIMARY,
    marginLeft: 8,
  },
}));

const renderDialogContent = (initialValues, onOpenGoogleMaps, classes) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_STATION_ID}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelStationId}
          placeholder={LocalizedString.chargingStationPage.placeholderStationId}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_NAME}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelName}
          placeholder={LocalizedString.chargingStationPage.placeholderName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_ADDRESS}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelAddress}
          placeholder={LocalizedString.chargingStationPage.placeholderAddress}
          disabled
          multiline
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_CITY}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelCity}
          placeholder={LocalizedString.chargingStationPage.placeholderCity}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_REGION}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelRegion}
          placeholder={LocalizedString.chargingStationPage.placeholderRegion}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_POSTAL_CODE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelPostalCode}
          placeholder={LocalizedString.chargingStationPage.placeholderPostalCode}
          disabled
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_TYPE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelType}
          placeholder={LocalizedString.chargingStationPage.placeholderType}
          disabled
        />
      </Grid>

      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_CATEGORY}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelCategory}
          placeholder={LocalizedString.chargingStationPage.placeholderCategory}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_LATITUDE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelLatitude}
          placeholder={LocalizedString.chargingStationPage.placeholderLatitude}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_CAR_SERVICE_CHARGING_STATION_LONGITUDE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.chargingStationPage.labelLongitude}
          placeholder={LocalizedString.chargingStationPage.placeholderLongitude}
          disabled
        />
      </Grid>
      <Grid item>
        {initialValues.latitude && initialValues.longitude && (
          <Button
            variant="text"
            onClick={() => onOpenGoogleMaps(initialValues.latitude, initialValues.longitude)}
            className={classes.buttonText}
          >
            {LocalizedString.chargingStationPage.buttonCaptionOpenMap}
          </Button>
        )}
      </Grid>
    </Grid>
  </Grid>
);

const CarServiceChargingStationPage = ({
  downloading,
  onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed,
  onChangePage,
  onChangePageSize,
  onRefresh,
  onResetAdvancedFilterPressed,
  onSearchBarTextChanged,
  onSortPressed,
  onViewPressed,
  initialValues,
  onOpenGoogleMaps,
  chargingStationCities,
  chargingStationProvinces,
  loadingChargingStationProvince,
  loadingChargingStationCity,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_CAR_SERVICE_CHARGING_STATIONS}
      uiPage={RXSTATE_CAR_SERVICE_CHARGING_STATION_PAGE}
      filterColumns={[
        {
          title: LocalizedString.chargingStationPage.labelChargerType,
          field: 'cargerType',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.chargingStationPage.labelProvince,
          field: 'province',
          type: FILTER_TYPE_DROPDOWN,
          data: chargingStationProvinces,
          loading: loadingChargingStationProvince,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.chargingStationPage.labelCity,
          field: 'city',
          type: FILTER_TYPE_DROPDOWN,
          data: chargingStationCities,
          loading: loadingChargingStationCity,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.chargingStationPage.labelRegion,
          field: 'region',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.chargingStationPage.labelCategory,
          field: 'category',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.chargingStationPage.labelSattus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.chargingStationPage.labelNo,
          field: 'no',
          sorting: false,
          width: 40,
          customFilterAndSearch: (term, rowData) => rowData,
        },
        {
          title: LocalizedString.chargingStationPage.labelName,
          field: 'name',
          sorting: !downloading,
        },
        {
          title: LocalizedString.chargingStationPage.labelCity,
          field: 'city',
          sorting: !downloading,
        },
        {
          title: LocalizedString.chargingStationPage.labelProvince,
          field: 'province',
          sorting: !downloading,
        },
        {
          title: LocalizedString.chargingStationPage.labelRegion,
          field: 'region',
          sorting: !downloading,
        },
        {
          title: LocalizedString.chargingStationPage.labelChargerType,
          field: 'cargerType',
          sorting: !downloading,
        },
        {
          title: LocalizedString.chargingStationPage.labelCategory,
          field: 'category',
          sorting: !downloading,
        },
      ]}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      title={LocalizedString.chargingStationPage.title}
      disableCreate
      disableEdit
      disableDelete
      useFullWidth
    >
      {renderDialogContent(initialValues, onOpenGoogleMaps, classes)}
    </FunctionalPage>
  );
};
export default reduxForm({
  form: RXFORM_CAR_SERVICE_CHARGING_STATION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CarServiceChargingStationPage);

CarServiceChargingStationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  chargingStationCities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  chargingStationProvinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingChargingStationProvince: PropTypes.bool.isRequired,
  loadingChargingStationCity: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onOpenGoogleMaps: PropTypes.func.isRequired,
};
