import {
  downloadEvChargingUrl,
  deleteEvChargingUrl,
} from '../../../helper';
import {
  addEvChargingUrl,
  downloadingDeletingEvChargingUrl,
  setFunctionalPageMode,
  setEvChargingUrlTappedId,
  setAlertErrorMessage,
  clearEvChargingUrls,
} from '../simple-action';
import { PAGE_MODE_VIEW, PAGE_MODE_TABLE } from '../../../constant';
import downloadEvChargingUrlsAsync from './downloadEvChargingUrlsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingEvChargingUrl(true));

    const { authentication, uiEvChargingUrl } = getState();

    const { token } = authentication;
    const { tappedId } = uiEvChargingUrl;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteEvChargingUrl(tappedId, token);
      dispatch(setEvChargingUrlTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearEvChargingUrls());
      dispatch(downloadEvChargingUrlsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const chargingStations = await downloadEvChargingUrl(
        tappedId,
        token,
      );

      dispatch(addEvChargingUrl(chargingStations));
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    }
  } finally {
    dispatch(downloadingDeletingEvChargingUrl(false));
  }
};
