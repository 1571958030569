import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, FormControl, FormHelperText, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { AddCircleOutlineRounded } from '@material-ui/icons';
import { COLOR_PRIMARY, COLOR_BODY_TEXT, COLOR_DANGEROUS } from '../constant';
import AccentButton from './accent-button';
import AutocompleteTextField from './autocomplete-text-field';
import EditableTableField from './editable-table-field';
import OutlinedTextField from './outlined-text-field';
import LocalizedString from '../localization';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  subHeading: {
    margin: '0px 0px 20px 33px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const AutocompleteTableField = ({
  currentTableData, defaultValue, options,
  adding, disableAdd, disabled, error, hidden, loadingOptions,
  onAddPressed, onChangeOptionText, onChangeTextFieldText, onDeletePressed, onOptionSelected,
  renderCustomFields,
  buttonCaption, helperText, label, optionPlaceholder, textFieldPlaceholder, textFieldType,
  textFieldValue,
  currentTappedData, selectedOption,
  ...props
}) => {
  const classes = useStyles();

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          {adding ? (<CircularProgress color="inherit" />) : (
            <Grid item xs sm={12}>
              <Typography variant="h6" className={classes.subHeading}>
                {label}
              </Typography>

              {(!disableAdd || !disabled) && (
              <div className={classes.rowContainer}>
                <Grid item sm={10}>
                  <AutocompleteTextField
                    options={options}
                    loading={loadingOptions}
                    placeholder={optionPlaceholder}
                    label={optionPlaceholder}
                    onChangeText={onChangeOptionText}
                    onOptionSelected={onOptionSelected}
                    value={selectedOption}
                    disabled={disabled || disableAdd}
                  />
                </Grid>
                {textFieldPlaceholder && (
                <Grid item sm={10}>
                  <OutlinedTextField
                    placeholder={textFieldPlaceholder}
                    label={textFieldPlaceholder}
                    onChangeText={onChangeTextFieldText}
                    value={textFieldValue}
                    disabled={disabled || disableAdd}
                    type={textFieldType}
                  />
                </Grid>
                )}
                {renderCustomFields()}
                <Grid item>
                  <AccentButton
                    startIcon={<AddCircleOutlineRounded />}
                    caption={buttonCaption}
                    onClick={() => onAddPressed(defaultValue, currentTableData, selectedOption,
                      currentTappedData, textFieldValue)}
                    loading={adding}
                    disabled={disabled || disableAdd}
                  />
                </Grid>
              </div>
              )}

              <EditableTableField
                disabled={disabled}
                addButtonCaption={null}
                onDeletePressed={(data) => onDeletePressed(data, currentTappedData)}
                defaultValue={defaultValue}
                customLookUpField="label"
                disableEdit
                disableToolbar
                label=""
                tableColumns={[{ title: optionPlaceholder, field: 'label', sorting: false }]}
                {...props}
              />
            </Grid>
          )}
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default AutocompleteTableField;

AutocompleteTableField.propTypes = {
  currentTableData: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  adding: PropTypes.bool,
  disableAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  loadingOptions: PropTypes.bool,
  onAddPressed: PropTypes.func,
  onChangeOptionText: PropTypes.func,
  onChangeTextFieldText: PropTypes.func,
  onDeletePressed: PropTypes.func,
  renderCustomFields: PropTypes.func,
  onOptionSelected: PropTypes.func,
  currentTappedData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number,
    PropTypes.object, PropTypes.bool, PropTypes.array])),
  selectedOption: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  buttonCaption: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  optionPlaceholder: PropTypes.string,
  textFieldPlaceholder: PropTypes.string,
  textFieldType: PropTypes.string,
  textFieldValue: PropTypes.string,
};

AutocompleteTableField.defaultProps = {
  currentTableData: [],
  defaultValue: [],
  options: [],
  adding: false,
  disableAdd: false,
  disabled: false,
  error: false,
  hidden: false,
  loadingOptions: false,
  onAddPressed: () => {},
  onChangeOptionText: () => {},
  onChangeTextFieldText: () => {},
  onDeletePressed: () => {},
  onOptionSelected: () => {},
  renderCustomFields: () => {},
  currentTappedData: {},
  selectedOption: {},
  buttonCaption: LocalizedString.common.buttonCaptionAdd,
  helperText: '',
  optionPlaceholder: '',
  textFieldPlaceholder: '',
  textFieldType: 'text',
  textFieldValue: '',
};
