import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { FunctionalPage } from '../../../../component';
import {
  DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_CREATED_DATE,
  RXFORM_GIFT_VOUCHER_REDEMPTION,
  STATUS_DISABLED, COLOR_DISABLED_ROW, COLOR_BACKGROUND,
  RXSTATE_GIFT_VOUCHER_REDEMPTIONS, RXSTATE_GIFT_VOUCHER_REDEMPTION_PAGE,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_MERCHANT_NAME,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_ID,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_NAME,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY_ID,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY_NAME,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_TOKEN_ID,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_GIFT_VOUCHER_ID,
  RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.giftVoucherRedemptionPage.placeholderVoucherId}
            label={LocalizedString.giftVoucherRedemptionPage.placeholderVoucherId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.giftVoucherRedemptionPage.placeholderVoucherName}
            label={LocalizedString.giftVoucherRedemptionPage.placeholderVoucherName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_GIFT_VOUCHER_REDEMPTION_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.giftVoucherRedemptionPage.placeholderMerchantName}
            label={LocalizedString.giftVoucherRedemptionPage.placeholderMerchantName}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.giftVoucherRedemptionPage.placeholderRedeemedById}
            label={LocalizedString.giftVoucherRedemptionPage.placeholderRedeemedById}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.giftVoucherRedemptionPage.placeholderRedeemedByName}
            label={LocalizedString.giftVoucherRedemptionPage.placeholderRedeemedByName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_TOKEN_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.giftVoucherRedemptionPage.placeholderVoucherTokenId}
            label={LocalizedString.giftVoucherRedemptionPage.placeholderVoucherTokenId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_GIFT_VOUCHER_REDEMPTION_GIFT_VOUCHER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.giftVoucherRedemptionPage.placeholderGiftVoucherId}
            label={LocalizedString.giftVoucherRedemptionPage.placeholderGiftVoucherId}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const GiftVoucherRedemptionPage = ({
  users, downloading, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_GIFT_VOUCHER_REDEMPTIONS}
    uiPage={RXSTATE_GIFT_VOUCHER_REDEMPTION_PAGE}
    filterColumns={[
      {
        title: LocalizedString.giftVoucherRedemptionPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.giftVoucherRedemptionPage.placeholderRedeemedBy,
        field: RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.giftVoucherRedemptionPage.placeholderGiftVoucherId,
        field: RXFIELD_GIFT_VOUCHER_REDEMPTION_GIFT_VOUCHER_ID,
        type: FILTER_TYPE_TEXT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.giftVoucherRedemptionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.giftVoucherRedemptionPage.labelVoucherName, field: 'voucherToken.voucher.title', sorting: !downloading },
      { title: LocalizedString.giftVoucherRedemptionPage.labelMerchant, field: 'voucherToken.voucher.merchant.name', sorting: !downloading },
      { title: LocalizedString.giftVoucherRedemptionPage.labelRedeemedBy, field: 'user.fullName', sorting: !downloading },
      {
        title: LocalizedString.giftVoucherRedemptionPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? moment(createdDate).format(DATE_TIME_FORMAT_WITH_SECONDS_WITHOUT_PIPE) : null),
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.giftVoucherRedemptionPage.title}
    useFullWidth
    disableCreate
    disableDelete
    disableEdit
    rowStyle={({ status }) => ({
      backgroundColor: status === STATUS_DISABLED
        ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
    })}
  >
    {renderDialogContent()}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_GIFT_VOUCHER_REDEMPTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(GiftVoucherRedemptionPage);

GiftVoucherRedemptionPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
