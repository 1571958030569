import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  CircularProgress, Divider, Grid, makeStyles, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import { AccentButton, FunctionalPage } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_DISABLED_ROW, COLOR_PRIMARY, COLOR_SECONDARY, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW, RXFIELD_STATUS, RXFIELD_USER_ACTIVE_SESSION,
  RXFIELD_USER_USERNAME, RXFIELD_USER_FULLNAME, RXFIELD_USER_EMAIL, RXFIELD_USER_PHONE,
  RXFIELD_USER_REFERRER, RXFIELD_USER_ROLES, RXFIELD_USER_TYPE, RXFORM_USER, RXSTATE_USERS,
  RXSTATE_USER_PAGE, status, STATUS_DISABLED, USER_TYPE_DEFAULT, USER_TYPE_INTEGRATION,
  USER_TYPE_SYSTEM,
  STATUS_ENABLED,
  COLOR_TEXT_ON_PRIMARY,
  COLOR_DANGEROUS,
  RXFIELD_USER_NEW_PASSWORD,
  RXFIELD_USER_NEW_PASSWORD_RETYPE,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_USER_USER_CODE,
  RXFIELD_USER_REFERRER_ID,
  RXFIELD_USER_REFERRER_NAME,
} from '../../constant';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedTextField,
  renderReduxFormTransferListField,
  renderReduxFormOutlinedDropdownTextField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { toMoment } from '../../helper';
import { rxformValidateUser } from '../../validation';

const TYPE = [
  USER_TYPE_DEFAULT,
  USER_TYPE_INTEGRATION,
  USER_TYPE_SYSTEM,
];

const useStyles = makeStyles(() => ({
  heading: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
  },
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
}));

const renderDialogContent = (defaultActiveSessionsValue, roles, addingEditing, loadingRole,
  loadingToken, onDeleteActiveSessionPressed, onRoleSelected, activeSessionPageSize,
  totalCurrentPage, tokenTotalCount, pageMode, classes,
  onCreateChangeRoleText, onCreateRoleOptionSelected, defaultRolesValue) => {
  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <div>
        <Grid container>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_USER_FULLNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.userScreen.placeholderUserFullName}
                label={LocalizedString.userScreen.placeholderUserFullName}
                disabled={addingEditing}
                required
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_USER_NEW_PASSWORD}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.common.placeholderNewPassword}
                label={LocalizedString.common.placeholderNewPassword}
                disabled={addingEditing}
                secureTextEntry
                required
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_USER_NEW_PASSWORD_RETYPE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.common.placeholderRetypeNewPassword}
                label={LocalizedString.common.placeholderRetypeNewPassword}
                disabled={addingEditing}
                secureTextEntry
                required
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_USER_USERNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.userScreen.placeholderUserUsername}
                label={LocalizedString.userScreen.placeholderUserUsername}
                disabled={addingEditing}
                required
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_USER_ROLES}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.userScreen.placeholderUserRoles}
                label={LocalizedString.userScreen.placeholderUserRoles}
                disabled={addingEditing}
                data={roles}
                loading={loadingRole}
                onChangeText={onCreateChangeRoleText}
                onOptionSelected={onCreateRoleOptionSelected}
                value={defaultRolesValue}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Grid container>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_USER_USERNAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.userScreen.placeholderUserUsername}
              label={LocalizedString.userScreen.placeholderUserUsername}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_USER_FULLNAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.userScreen.placeholderUserFullName}
              label={LocalizedString.userScreen.placeholderUserFullName}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_USER_USER_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.userScreen.placeholderUserUserCode}
              label={LocalizedString.userScreen.placeholderUserUserCode}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_USER_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.userScreen.placeholderUserEmail}
              label={LocalizedString.userScreen.placeholderUserEmail}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_USER_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.userScreen.placeholderUserPhone}
              label={LocalizedString.userScreen.placeholderUserPhone}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_USER_REFERRER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.userScreen.placeholderReferrerId}
              label={LocalizedString.userScreen.placeholderReferrerId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_USER_REFERRER_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.userScreen.placeholderReferrerName}
              label={LocalizedString.userScreen.placeholderReferrerName}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>

      <Field
        name={RXFIELD_USER_ROLES}
        component={renderReduxFormTransferListField}
        label={LocalizedString.userScreen.placeholderUserRoles}
        disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
        data={roles}
        loading={loadingRole}
        onOptionSelected={onRoleSelected}
        required
      />

      <Divider className={classes.divider} />
      {loadingToken ? (<CircularProgress />) : (
        <div>
          <Typography variant="subtitle1" className={classes.heading}>
            {tokenTotalCount > 20
              ? LocalizedString.userScreen.placeholderTopActiveSessions.replace(/\{pageSize\}/, activeSessionPageSize)
              : LocalizedString.userScreen.placeholderActiveSessions}
          </Typography>
          <Field
            name={RXFIELD_USER_ACTIVE_SESSION}
            component={renderReduxFormEditableTableField}
            disabled={addingEditing}
            onDeletePressed={onDeleteActiveSessionPressed}
            hidden={pageMode === PAGE_MODE_TABLE}
            loading={loadingToken}
            currentPage={totalCurrentPage}
            totalCount={tokenTotalCount}
            defaultValue={defaultActiveSessionsValue}
            tableColumns={[
              {
                title: LocalizedString.userScreen.labelNo, field: 'no', sorting: false, width: 40,
              },
              { title: LocalizedString.userScreen.labelLastActivity, field: 'lastActivity', sorting: false },
              { title: LocalizedString.userScreen.labelValidUntil, field: 'validUntil', sorting: false },
              { title: LocalizedString.userScreen.labelAppVersion, field: 'appVersion', sorting: false },
              {
                title: LocalizedString.userScreen.labelDevice,
                field: 'device',
                render: ({ deviceManufacturer, deviceModel }) => (`${deviceManufacturer} ${deviceModel}`),
                customFilterAndSearch: (term, rowData) => (`${rowData.deviceManufacturer} ${rowData.deviceModel}`).indexOf(term) !== -1,
                sorting: false,
              },
              { title: LocalizedString.userScreen.labelDeviceId, field: 'deviceId', sorting: false },
              {
                title: LocalizedString.userScreen.labelOS,
                field: 'os',
                render: ({ osName, osVersion }) => (`${osName} ${osVersion}`),
                customFilterAndSearch: (term, rowData) => (`${rowData.osName} ${rowData.osVersion}`).indexOf(term) !== -1,
                sorting: false,
              },
              { title: LocalizedString.userScreen.labelTimezone, field: 'timeZone', sorting: false },

            ]}
            disableEdit
            disableToolbar
            customDeleteButtonCaption={LocalizedString.userScreen.buttonCaptionDisable}
            customDeleteConfirmationMessage={LocalizedString.userScreen.msgDisableRowConfirmation}
          />
        </div>
      )}

    </div>
  );
};

const UserPage = ({
  initialValues, roles,
  addingEditing, downloading, loadingRole, loadingToken, resyncing, onDownloadPressed,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelPressed, onChangePage,
  onChangePageSize, onConfirmContextMenuPressed, onContextMenuPressed, onDeleteActiveSessionPressed,
  onEditPressed, onRefresh, onResetAdvancedFilterPressed, onRoleSelected, onSavePressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed, onAdvancedFilterPressed,
  activeSessionPageSize, totalCurrentPage, tokenTotalCount, downloadingReferrer,
  pageMode, tappedId, referrers, onChangeReffererText,
  currentUserStatus, enablingDisabling,
  currentUserType, settingPassword, setPasswordDialogVisibility,
  onCancelSetPasswordPressed, onSaveSetPasswordPressed,
  onCreatePressed, onCreateChangeRoleText, onCreateRoleOptionSelected, onSubmitPressed,
}) => {
  const classes = useStyles();

  const renderSetPasswordDialog = () => (
    <Dialog
      open={setPasswordDialogVisibility}
      maxWidth="lg"
      classes={{ paper: classes.paper }}
    >
      <div className={classes.headerContainer}>
        <DialogTitle>{LocalizedString.userScreen.buttonCaptionSetPassword}</DialogTitle>
      </div>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSaveSetPasswordPressed)}
          className={classes.form}
        >
          <Grid container spacing={3}>
            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_USER_NEW_PASSWORD}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderNewPassword}
                  label={LocalizedString.common.placeholderNewPassword}
                  editable={settingPassword}
                  secureTextEntry
                  required
                />

              </Grid>
            </Grid>

            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_USER_NEW_PASSWORD_RETYPE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderRetypeNewPassword}
                  label={LocalizedString.common.placeholderRetypeNewPassword}
                  editable={settingPassword}
                  secureTextEntry
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <DialogActions className={classes.buttonContainer}>
            <AccentButton
              onClick={onCancelSetPasswordPressed}
              variant="text"
              caption={LocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              disabled={addingEditing || downloading || settingPassword}
            />
            <AccentButton
              variant="contained"
              type="submit"
              className={classes.button}
              loading={settingPassword}
              disableElevation
              caption={LocalizedString.common.buttonCaptionSave}
              disabled={addingEditing || downloading}
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  return (
    <div>
      {renderSetPasswordDialog()}
      <FunctionalPage
        data={RXSTATE_USERS}
        uiPage={RXSTATE_USER_PAGE}
        moreMenus={[
          {
            caption: LocalizedString.common.buttonCaptionResync,
            disabled: resyncing,
            onPress: () => onContextMenuPressed(tappedId,
              LocalizedString.common.msgResyncConfirmation),
          },
          {
            caption: LocalizedString.common.buttonCaptionEnable,
            disabled: currentUserStatus === STATUS_ENABLED || enablingDisabling,
            onPress: () => onContextMenuPressed(tappedId,
              LocalizedString.common.msgEnableConfirmation),
          },
          {
            caption: LocalizedString.common.buttonCaptionDisable,
            disabled: currentUserStatus === STATUS_DISABLED || enablingDisabling,
            onPress: () => onContextMenuPressed(tappedId,
              LocalizedString.common.msgDisableConfirmation),
          },
          {
            caption: LocalizedString.userScreen.buttonCaptionSetPassword,
            disabled: currentUserType !== USER_TYPE_INTEGRATION || settingPassword,
            onPress: () => onContextMenuPressed(
              tappedId, LocalizedString.userScreen.buttonCaptionSetPassword,
            ),
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.userScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.userScreen.labelUsername, field: 'username', sorting: !downloading },
          { title: LocalizedString.userScreen.labelFullName, field: 'fullName', sorting: !downloading },
          { title: LocalizedString.userScreen.labelEmail, field: 'email', sorting: !downloading },
          { title: LocalizedString.userScreen.labelPhone, field: 'phone', sorting: !downloading },
          {
            title: LocalizedString.userScreen.labelRoles,
            field: 'roles',
            sorting: false,
            render: (data) => {
              if (!data.roles) {
                return '';
              }
              if (data.roles.length === 1) {
                return data.roles;
              }
              const rolesWithSeparator = data.roles.reduce((prev, curr) => {
                if (!prev) {
                  return curr;
                }
                return `${prev};${curr}`;
              }, '');
              return rolesWithSeparator;
            },
          },
          { title: LocalizedString.userScreen.labelUserType, field: 'type', sorting: !downloading },
          {
            title: LocalizedString.userScreen.labelCreatedDate,
            field: 'createdDate',
            sorting: !downloading,

            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.common.placeholderStatus,
            field: RXFIELD_STATUS,
            type: FILTER_TYPE_DROPDOWN,
            data: status,
          },
          {
            title: LocalizedString.common.placeholderType,
            field: RXFIELD_USER_TYPE,
            type: FILTER_TYPE_DROPDOWN,
            data: TYPE,
          },
          {
            title: LocalizedString.userScreen.labelReferrer,
            field: RXFIELD_USER_REFERRER,
            type: FILTER_TYPE_DROPDOWN,
            data: referrers,
            loading: downloadingReferrer,
            onChangeFilterText: onChangeReffererText,
            useDropdownValue: true,
          },
        ]}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        handleSubmit={handleSubmit}
        onAppear={onAppear}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onConfirmDeletePressed={onConfirmContextMenuPressed}
        onEditPressed={onEditPressed}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSavePressed={onSavePressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        onCreatePressed={onCreatePressed}
        onSubmitPressed={onSubmitPressed}
        disableDelete
        editButtonCaption={LocalizedString.userScreen.buttonCaptionEditUser}
        createNewButtonCaption={LocalizedString.userScreen.buttonCaptionCreateNewIntegrationUser}
        title={LocalizedString.userScreen.title}
        useFullWidth
        usefullWidthDialog
        rowStyle={(rowData) => ({
          backgroundColor: rowData.status === STATUS_DISABLED
            ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
        })}
        onDownloadPressed={onDownloadPressed}
        enableSave
      >
        {renderDialogContent(initialValues.activeSessions, roles, addingEditing, loadingRole,
          loadingToken, onDeleteActiveSessionPressed, onRoleSelected, activeSessionPageSize,
          totalCurrentPage, tokenTotalCount, pageMode, classes,
          onCreateChangeRoleText, onCreateRoleOptionSelected, initialValues.roles)}
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_USER,
  validate: rxformValidateUser,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(UserPage);

UserPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  referrers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingReferrer: PropTypes.bool.isRequired,
  loadingRole: PropTypes.bool.isRequired,
  loadingToken: PropTypes.bool.isRequired,
  resyncing: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  settingPassword: PropTypes.bool.isRequired,
  setPasswordDialogVisibility: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeReffererText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onDeleteActiveSessionPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onRoleSelected: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onSaveSetPasswordPressed: PropTypes.func.isRequired,
  onCancelSetPasswordPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onCreateChangeRoleText: PropTypes.func.isRequired,
  onCreateRoleOptionSelected: PropTypes.func.isRequired,
  activeSessionPageSize: PropTypes.number.isRequired,
  totalCurrentPage: PropTypes.number.isRequired,
  tokenTotalCount: PropTypes.number.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  currentUserStatus: PropTypes.string.isRequired,
  currentUserType: PropTypes.string.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
