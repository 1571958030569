
import downloadGarudaAirportsAsync from './downloadGarudaAirportsAsync';
import { PAGE_MODE_TABLE } from '../../../constant';
import { deleteGarudaAirport, downloadGarudaAirport } from '../../../helper';
import {
  addGarudaAirport, clearGarudaAirports, downloadingDeletingGarudaAirport,
  setAlertErrorMessage, setFunctionalPageMode, setGarudaAirportTappedId,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingGarudaAirport(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGarudaAirport;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteGarudaAirport(tappedId, token);
      dispatch(setGarudaAirportTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearGarudaAirports());
      dispatch(downloadGarudaAirportsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const category = await downloadGarudaAirport(tappedId, token);
      dispatch(addGarudaAirport(category));
    }
  } finally {
    dispatch(downloadingDeletingGarudaAirport(false));
  }
};
