import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_COUPON_TRANSACTIONS,
  MENUID_COUPON_COUPON_TRANSACTION, PAGE_MODE_TABLE, RXFORM_COUPON_TRANSACTION,
} from '../../constant';
import {
  transformInitialValues, transformUserDropdownData, toMoment, debounceSearch,
} from '../../helper';
import {
  clearCouponTransactions, clearUsers, setActiveSideMenuItem, setAlertErrorMessage,
  setCouponTransactionAdvancedFilterDialogSelectedFilterString, setCouponTransactionSearchText,
  setCouponTransactionSelectedPageSize, setCouponTransactionSelectedOrderBy,
  setCouponTransactionTappedId, setUserSearchText, setUserAdvancedFilterDialogSelectedFilterString,
  downloadCouponTransactionAsync, downloadCouponTransactionsAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import CouponTransactionPage from './coupon-transaction.presentation';

const getInitialValues = (state) => {
  const {
    couponTransactions, uiFunctionalPage, uiCouponTransaction,
  } = state;
  const { data } = couponTransactions;
  const { downloadingDeleting, tappedId } = uiCouponTransaction;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user.fullName,
    effectiveDate: found.effectiveDate
      ? toMoment(found.effectiveDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    items: isEmpty(found.items) ? found.items : found.items.map((item, count) => ({
      ...item,
      no: count + 1,
    })),
  }) : {
    effectiveDate: '',
    transactionNumber: '',
    type: '',
    referenceId: '',
    tags: null,
    amount: '',
    label: '',
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    fullName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiCouponTransaction.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setCouponTransactionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_COUPON_COUPON_TRANSACTION));
    dispatch(setCouponTransactionSearchText(''));
    dispatch(clearCouponTransactions());
    dispatch(setCouponTransactionSelectedPageSize(20));
    dispatch(setCouponTransactionSelectedOrderBy(INITIAL_ORDER_BY_COUPON_TRANSACTIONS));
    dispatch(downloadCouponTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setCouponTransactionAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCouponTransactions());
    dispatch(downloadCouponTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COUPON_TRANSACTION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCouponTransactionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCouponTransactionSelectedPageSize(pageSize));
    dispatch(downloadCouponTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setCouponTransactionSelectedPageSize(pageSize));
    dispatch(clearCouponTransactions());
    dispatch(downloadCouponTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCouponTransactionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCouponTransactions());
    dispatch(setCouponTransactionSelectedOrderBy(INITIAL_ORDER_BY_COUPON_TRANSACTIONS));
    dispatch(downloadCouponTransactionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCouponTransactionSearchText(text));
      dispatch(clearCouponTransactions());
      await dispatch(downloadCouponTransactionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCouponTransactionSelectedOrderBy(orderBy));
    dispatch(clearCouponTransactions());
    dispatch(downloadCouponTransactionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCouponTransactionTappedId(id));
    dispatch(downloadCouponTransactionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponTransactionPage);
