import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  debounceSearch, transformInitialValues, toMoment,
  transformUserDropdownData, transformDropdownData,
} from '../../helper';
import {
  downloadTestDriveReservationsAsync, setAlertErrorMessage, setTestDriveReservationSelectedOrderBy,
  clearTestDriveReservations, downloadDeleteTestDriveReservationAsync,
  setTestDriveReservationTappedId, setTestDriveReservationSearchText,
  setTestDriveReservationSelectedPageSize, setTestDriveReservationAdvancedFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
  setEventAdvancedFilterDialogSelectedFilterString, clearEvents,
  downloadEventsAsync, setEventSearchText, saveTestDriveReservationsAsync,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE, DATE_TIME_FORMAT_WITHOUT_PIPE, RESERVATION_TYPE_EVENT,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, INITIAL_ORDER_BY_RESERVATIONS, SIMPLE_DATE_FORMAT,
} from '../../constant';
import { downloadUsersAsync } from '../../../../redux/action';
import GlobalLocalizedString from '../../../../localization';
import ReservationPage from './reservation.presentation';
import {
  downloadDealersAsync, setDealerAdvancedFilterDialogSelectedFilterString,
  clearDealers, setDealerSearchText,
} from '../../../car-service/redux/action';

const getInitialValues = (state) => {
  const { reservations, uiFunctionalPage, uiReservation } = state;
  const { data } = reservations;
  const { downloadingDeleting, tappedId } = uiReservation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    eventId: found.event?.id,
    eventName: found.event?.name,
    eventLocation: found.event?.location,
    dealerCode: found.dealer?.dealerCode,
    dealerName: found.dealer?.name,
    dealerCity: found.dealer?.city,
    wantToReceiveNewsUpdate: found.wantToReceiveNewsUpdate
      ? GlobalLocalizedString.common.labelYes
      : GlobalLocalizedString.common.labelNo,
    userId: found.profile?.id,
    userFullname: found.profile?.fullName,
    reservedDate: found.reservedDate
      ? toMoment(found.reservedDate).format(SIMPLE_DATE_FORMAT)
      : null,
    completedTime: found.completedTime
      ? toMoment(found.completedTime, !isEmpty(found.event)
        ? found.event.city.province.timeZone : null).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
      : null,
    simImage: found.simImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.simImage}` : null,
    testDriveImage: found.testDriveImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.testDriveImage}` : null,
    surveyImage: found.surveyImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.surveyImage}` : null,
    otherImage: found.otherImage ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.otherImage}` : null,
  }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiFunctionalPage.tappedId || '',
  downloading: state.uiReservation.downloading,
  initialValues: getInitialValues(state),
  isEventReservationType: state.reservations.data[state.uiFunctionalPage.tappedId]
    ?.reservationType === RESERVATION_TYPE_EVENT,
  events: transformDropdownData(state.events.data),
  loadingEvents: state.uiEvent.downloading,
  users: transformUserDropdownData(state.users.data),
  loadingUsers: state.uiUser.downloading,
  dealers: Object.values(state.dealers.data).map((item) => ({
    label: item.name,
    value: item.dealerCode,
  })),
  loadingDealers: state.uiDealer.downloading,
});

const searchDebounce = debounceSearch((dispatch) => {
  dispatch(clearTestDriveReservations());
  dispatch(downloadTestDriveReservationsAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(downloadTestDriveReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onSortPressed: (orderBy) => {
    dispatch(setTestDriveReservationSelectedOrderBy(orderBy));
    dispatch(clearTestDriveReservations());
    dispatch(downloadTestDriveReservationsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onViewPressed: (id) => {
    dispatch(setTestDriveReservationTappedId(id));
    dispatch(downloadDeleteTestDriveReservationAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSearchBarTextChanged: async (text) => {
    dispatch(setTestDriveReservationSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDebounce(dispatch);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadTestDriveReservationsAsync(pageNo + 1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setTestDriveReservationSelectedPageSize(pageSize));
    dispatch(downloadTestDriveReservationsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onRefresh: (pageSize) => {
    dispatch(setTestDriveReservationSelectedPageSize(pageSize));
    dispatch(clearTestDriveReservations());
    dispatch(downloadTestDriveReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearTestDriveReservations());
    dispatch(setTestDriveReservationAdvancedFilterString(''));
    dispatch(setTestDriveReservationSelectedOrderBy(INITIAL_ORDER_BY_RESERVATIONS));
    dispatch(downloadTestDriveReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace('eventName', 'event.id')
      .replace('dealerName', 'dealer.dealerCode')
      .replace('userFullname', 'profile.id');

    dispatch(setTestDriveReservationAdvancedFilterString(text));
    dispatch(clearTestDriveReservations());
    dispatch(downloadTestDriveReservationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDealerSearchText(''));
    dispatch(downloadDealersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onChangeEventText: async (text) => {
    try {
      dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setEventSearchText(text));
      dispatch(clearEvents());
      await dispatch(downloadEventsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDealersText: async (text) => {
    try {
      dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setDealerSearchText(text));
      dispatch(clearDealers());
      await dispatch(downloadDealersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveTestDriveReservationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationPage);
