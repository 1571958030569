import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_ACCESSORIES = 'DOWNLOADING_ACCESSORIES';
export const downloadingAccessories = makeActionCreator(DOWNLOADING_ACCESSORIES, 'status');

export const SET_ACCESSORIES = 'SET_ACCESSORIES';
export const setAccessories = makeActionCreator(SET_ACCESSORIES, 'data');

export const ADD_ACCESSORY = 'ADD_ACCESSORY';
export const addAccessory = makeActionCreator(ADD_ACCESSORY, 'data');

export const CLEAR_ACCESSORIES = 'CLEAR_ACCESSORIES';
export const clearAccessories = makeActionCreator(CLEAR_ACCESSORIES);

export const ADDING_EDITING_ACCESSORY = 'ADDING_EDITING_ACCESSORY';
export const addingEditingAccessory = makeActionCreator(ADDING_EDITING_ACCESSORY, 'status');

export const DOWNLOADING_DELETING_ACCESSORY = 'DOWNLOADING_DELETING_ACCESSORY';
export const downloadingDeletingAccessory = makeActionCreator(DOWNLOADING_DELETING_ACCESSORY, 'status');

export const SAVING_ACCESORIES = 'SAVING_ACCESORIES';
export const savingAccesories = makeActionCreator(SAVING_ACCESORIES, 'status');

export const SYNCING_ACCESORIES = 'SYNCING_ACCESORIES';
export const syncingAccesories = makeActionCreator(SYNCING_ACCESORIES, 'status');

export const SET_ACCESSORY_SELECTED_PAGE_SIZE = 'SET_ACCESSORY_SELECTED_PAGE_SIZE';
export const setAccessorySelectedPageSize = makeActionCreator(SET_ACCESSORY_SELECTED_PAGE_SIZE, 'size');

export const SET_ACCESSORY_SELECTED_ORDER_BY = 'SET_ACCESSORY_SELECTED_ORDER_BY';
export const setAccessorySelectedOrderBy = makeActionCreator(SET_ACCESSORY_SELECTED_ORDER_BY, 'order');

export const SET_ACCESSORY_TAPPED_ID = 'SET_ACCESSORY_TAPPED_ID';
export const setAccessoryTappedId = makeActionCreator(SET_ACCESSORY_TAPPED_ID, 'id');

export const SET_ACCESSORY_SEARCH_TEXT = 'SET_ACCESSORY_SEARCH_TEXT';
export const setAccessorySearchText = makeActionCreator(SET_ACCESSORY_SEARCH_TEXT, 'text');

export const SET_ACCESSORY_IS_ACTIVE = 'SET_ACCESSORY_IS_ACTIVE';
export const setAccessoryisActive = makeActionCreator(SET_ACCESSORY_IS_ACTIVE, 'status');


export const DOWNLOADING_INVOICES = 'DOWNLOADING_INVOICES';
export const downloadingInvoices = makeActionCreator(DOWNLOADING_INVOICES, 'status');

export const SET_INVOICES = 'SET_INVOICES';
export const setInvoices = makeActionCreator(SET_INVOICES, 'data');

export const ADD_INVOICE = 'ADD_INVOICE';
export const addInvoice = makeActionCreator(ADD_INVOICE, 'data');

export const CLEAR_INVOICES = 'CLEAR_INVOICES';
export const clearInvoices = makeActionCreator(CLEAR_INVOICES);

export const ADDING_EDITING_INVOICE = 'ADDING_EDITING_INVOICE';
export const addingEditingInvoice = makeActionCreator(ADDING_EDITING_INVOICE, 'status');

export const DOWNLOADING_DELETING_INVOICE = 'DOWNLOADING_DELETING_INVOICE';
export const downloadingDeletingInvoice = makeActionCreator(DOWNLOADING_DELETING_INVOICE, 'status');

export const SET_INVOICE_SELECTED_PAGE_SIZE = 'SET_INVOICE_SELECTED_PAGE_SIZE';
export const setInvoiceSelectedPageSize = makeActionCreator(SET_INVOICE_SELECTED_PAGE_SIZE, 'size');

export const SET_INVOICE_SELECTED_ORDER_BY = 'SET_INVOICE_SELECTED_ORDER_BY';
export const setInvoiceSelectedOrderBy = makeActionCreator(SET_INVOICE_SELECTED_ORDER_BY, 'order');

export const SET_INVOICE_TAPPED_ID = 'SET_INVOICE_TAPPED_ID';
export const setInvoiceTappedId = makeActionCreator(SET_INVOICE_TAPPED_ID, 'id');

export const SET_INVOICE_SEARCH_TEXT = 'SET_INVOICE_SEARCH_TEXT';
export const setInvoiceSearchText = makeActionCreator(SET_INVOICE_SEARCH_TEXT, 'text');

export const SET_INVOICE_IS_ACTIVE = 'SET_INVOICE_IS_ACTIVE';
export const setInvoiceisActive = makeActionCreator(SET_INVOICE_IS_ACTIVE, 'status');

export const SET_INVOICE_SENDING_PDF = 'SET_INVOICE_SENDING_PDF';
export const setInvoiceSendingPdf = makeActionCreator(SET_INVOICE_SENDING_PDF, 'status');

export const SET_INVOICES_FILTER_STRING = 'SET_INVOICES_FILTER_STRING';
export const setInvoicesFilterString = makeActionCreator(SET_INVOICES_FILTER_STRING, 'text');


export const DOWNLOADING_VEHICLE_MODELS = 'DOWNLOADING_VEHICLE_MODELS';
export const downloadingVehicleModels = makeActionCreator(DOWNLOADING_VEHICLE_MODELS, 'status');

export const SET_VEHICLE_MODELS = 'SET_VEHICLE_MODELS';
export const setVehicleModels = makeActionCreator(SET_VEHICLE_MODELS, 'data');

export const CLEAR_VEHICLE_MODELS = 'CLEAR_VEHICLE_MODELS';
export const clearVehicleModels = makeActionCreator(CLEAR_VEHICLE_MODELS);


export const DOWNLOADING_ACCESSORY_TRANSACTIONS = 'DOWNLOADING_ACCESSORY_TRANSACTIONS';
export const downloadingAccessoryTransactions = makeActionCreator(DOWNLOADING_ACCESSORY_TRANSACTIONS, 'status');

export const DOWNLOADING_DELETING_ACCESSORY_TRANSACTION = 'DOWNLOADING_DELETING_ACCESSORY_TRANSACTION';
export const downloadingDeletingAccessoryTransaction = makeActionCreator(DOWNLOADING_DELETING_ACCESSORY_TRANSACTION, 'status');

export const SAVING_ACCESSORY_TRANSACTIONS = 'SAVING_ACCESSORY_TRANSACTIONS';
export const savingAccessoryTransactions = makeActionCreator(SAVING_ACCESSORY_TRANSACTIONS, 'status');

export const SET_ACCESSORY_TRANSACTIONS = 'SET_ACCESSORY_TRANSACTIONS';
export const setAccessoryTransactions = makeActionCreator(SET_ACCESSORY_TRANSACTIONS, 'data');

export const ADD_ACCESSORY_TRANSACTION = 'ADD_ACCESSORY_TRANSACTION';
export const addAccessoryTransaction = makeActionCreator(ADD_ACCESSORY_TRANSACTION, 'data');

export const CLEAR_ACCESSORY_TRANSACTIONS = 'CLEAR_ACCESSORY_TRANSACTIONS';
export const clearAccessoryTransactions = makeActionCreator(CLEAR_ACCESSORY_TRANSACTIONS);

export const SET_ACCESSORY_TRANSACTIONS_SELECTED_PAGE_SIZE = 'SET_ACCESSORY_TRANSACTIONS_SELECTED_PAGE_SIZE';
export const setAccessoryTransactionsSelectedPageSize = makeActionCreator(SET_ACCESSORY_TRANSACTIONS_SELECTED_PAGE_SIZE, 'size');

export const SET_ACCESSORY_TRANSACTIONS_SELECTED_ORDER_BY = 'SET_ACCESSORY_TRANSACTIONS_SELECTED_ORDER_BY';
export const setAccessoryTransactionsSelectedOrderBy = makeActionCreator(SET_ACCESSORY_TRANSACTIONS_SELECTED_ORDER_BY, 'order');

export const SET_ACCESSORY_TRANSACTIONS_ADVANCED_FILTER_STRING = 'SET_ACCESSORY_TRANSACTIONS_ADVANCED_FILTER_STRING';
export const setAccessoryTransactionsAdvancedFilterString = makeActionCreator(SET_ACCESSORY_TRANSACTIONS_ADVANCED_FILTER_STRING, 'text');

export const SET_ACCESSORY_TRANSACTION_TAPPED_ID = 'SET_ACCESSORY_TRANSACTION_TAPPED_ID';
export const setAccessoryTransactionTappedId = makeActionCreator(SET_ACCESSORY_TRANSACTION_TAPPED_ID, 'id');

export const SET_ACCESSORY_TRANSACTIONS_SEARCH_TEXT = 'SET_ACCESSORY_TRANSACTIONS_SEARCH_TEXT';
export const setAccessoryTransactionsSearchText = makeActionCreator(SET_ACCESSORY_TRANSACTIONS_SEARCH_TEXT, 'text');
