import _, { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import {
  RXFIELD_PROMO_CODE_REWARD, RXFIELD_PROMO_CODE_CODE, RXFIELD_PROMO_CODE_VOUCHER,
  RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE,
  RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_PROMO_CODE_TOTAL_VOUCHER_ALLOCATED, RXFIELD_PROMO_CODE_COINS_AMOUNT,
  RXFIELD_PROMO_CODE_COUPONS_AMOUNT, RXFIELD_PROMO_CODE_VALID_FROM,
  RXFIELD_PROMO_CODE_VALID_UNTIL, RXFIELD_PROMO_CODE_MERCHANT,
  RXFIELD_PROMO_CODE_MERCHANT_TAG, RXFIELD_PROMO_CODE_MESSAGE_TITLE,
  RXFIELD_PROMO_CODE_MESSAGE_BODY, RXFIELD_PROMO_CODE_MIN_SIGN_UP_DATE,
  RXFIELD_PROMO_CODE_MAX_SIGN_UP_DATE,
  REWARD_TYPE_VOUCHER, REWARD_TYPE_COIN, REWARD_TYPE_COUPON, RXFIELD_PROMO_CODE_BATCH_QUANTITY,
  RXFIELD_PROMO_CODE_BATCH_PREFIX, RXFIELD_PROMO_CODE_BATCH_RANDOM_CODE_LENGTH,
  RXFIELD_PROMO_CODE_BATCH_REWARD, RXFIELD_PROMO_CODE_BATCH_VOUCHERS,
  RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE,
  RXFIELD_PROMO_CODE_BATCH_MAX_REDEMPTION_COUNT, RXFIELD_PROMO_CODE_BATCH_VALID_FROM,
  RXFIELD_PROMO_CODE_BATCH_VALID_UNTIL, RXFIELD_PROMO_CODE_BATCH_MERCHANT,
  RXFIELD_PROMO_CODE_BATCH_MERCHANT_TAG, RXFIELD_PROMO_CODE_BATCH_MESSAGE_TITLE,
  RXFIELD_PROMO_CODE_BATCH_MESSAGE_BODY,
  RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT, RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT,
  RXFIELD_PROMO_CODE_BATCH_MIN_SIGN_UP_DATE, RXFIELD_PROMO_CODE_BATCH_MAX_SIGN_UP_DATE,
} from './constant';
import { rxFormValidateRequiredFields } from '../../validation';
import LocalizedString from '../../localization';

export const rxFormValidatePromoCode = (values) => {
  const requiredFields = [
    RXFIELD_PROMO_CODE_CODE,
    RXFIELD_PROMO_CODE_REWARD,
    RXFIELD_PROMO_CODE_TOTAL_VOUCHER_ALLOCATED,
    RXFIELD_PROMO_CODE_VALID_FROM,
    RXFIELD_PROMO_CODE_VALID_UNTIL, RXFIELD_PROMO_CODE_MERCHANT,
    RXFIELD_PROMO_CODE_MERCHANT_TAG, RXFIELD_PROMO_CODE_MESSAGE_TITLE,
    RXFIELD_PROMO_CODE_MESSAGE_BODY, RXFIELD_PROMO_CODE_MIN_SIGN_UP_DATE,
    RXFIELD_PROMO_CODE_MAX_SIGN_UP_DATE,
  ];
  let errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!isEmpty(values)) {
    const found = Object.entries(values).find((x) => x[0] === 'rewardType');
    if (found) {
      const rewardType = found[1];

      const rewardTypeVoucher = rewardType?.value === REWARD_TYPE_VOUCHER
        || rewardType === REWARD_TYPE_VOUCHER;
      const rewardTypeCoin = rewardType?.value === REWARD_TYPE_COIN
        || rewardType === REWARD_TYPE_COIN;
      const rewardTypeCoupon = rewardType?.value === REWARD_TYPE_COUPON
        || rewardType === REWARD_TYPE_COUPON;

      if (rewardTypeVoucher) {
        const additionalRequiredField = [
          RXFIELD_PROMO_CODE_VOUCHER_BYPASS_MAX_PURCHASE,
          RXFIELD_PROMO_CODE_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION,
        ];
        requiredFields.push(...additionalRequiredField);
        if (!values[RXFIELD_PROMO_CODE_VOUCHER]?.length) {
          errors[RXFIELD_PROMO_CODE_VOUCHER] = LocalizedString.common.errMsgRequired;
        }
      }
      if (rewardTypeCoin) {
        requiredFields.push(RXFIELD_PROMO_CODE_COINS_AMOUNT);
      }
      if (rewardTypeCoupon) {
        requiredFields.push(RXFIELD_PROMO_CODE_COUPONS_AMOUNT);
      }
    }
  }
  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  if (!isUndefined(values.bypassMaxPurchaseValidation)) {
    errors = _.omit(errors, ['bypassMaxPurchaseValidation']);
  }
  if (!isUndefined(values.bypassAchievementValidation)) {
    errors = _.omit(errors, ['bypassAchievementValidation']);
  }

  if (values.minSignUpTime && values.maxSignUpTime) {
    if (values?.minSignUpTime !== null && values?.maxSignUpTime !== null && values?.maxSignUpTime !== '') {
      if (!moment(values.maxSignUpTime).isAfter(moment(values.minSignUpTime))) {
        errors.maxSignUpTime = LocalizedString.common.errMsgStartEndDate;
      }
    }
  }

  return errors;
};

export const rxformValidatePromoCodeBatch = (values) => {
  let requiredFields = [
    RXFIELD_PROMO_CODE_BATCH_PREFIX,
    RXFIELD_PROMO_CODE_BATCH_QUANTITY,
    RXFIELD_PROMO_CODE_BATCH_RANDOM_CODE_LENGTH,
    RXFIELD_PROMO_CODE_BATCH_REWARD, RXFIELD_PROMO_CODE_BATCH_MAX_REDEMPTION_COUNT,
    RXFIELD_PROMO_CODE_BATCH_VALID_FROM,
    RXFIELD_PROMO_CODE_BATCH_VALID_UNTIL,
    RXFIELD_PROMO_CODE_BATCH_MERCHANT,
    RXFIELD_PROMO_CODE_BATCH_MERCHANT_TAG,
    RXFIELD_PROMO_CODE_BATCH_MESSAGE_TITLE,
    RXFIELD_PROMO_CODE_BATCH_MESSAGE_BODY,
    RXFIELD_PROMO_CODE_BATCH_MIN_SIGN_UP_DATE,
    RXFIELD_PROMO_CODE_BATCH_MAX_SIGN_UP_DATE,
  ];

  const rewardType = values.rewardType?.value || values.rewardType || '';

  if (rewardType === REWARD_TYPE_VOUCHER) {
    requiredFields = [
      ...requiredFields,
      RXFIELD_PROMO_CODE_BATCH_VOUCHERS,
      RXFIELD_PROMO_CODE_BATCH_BYPASS_ACHIEVEMENT_VALIDATION,
      RXFIELD_PROMO_CODE_BATCH_BYPASS_MAX_PURCHASE,
    ];
  }
  if (rewardType === REWARD_TYPE_COIN) {
    requiredFields = [...requiredFields, RXFIELD_PROMO_CODE_BATCH_COINS_AMOUNT];
  }
  if (rewardType === REWARD_TYPE_COUPON) {
    requiredFields = [...requiredFields, RXFIELD_PROMO_CODE_BATCH_COUPONS_AMOUNT];
  }

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }

  if (values.minSignUpTime && values.maxSignUpTime) {
    if (values?.minSignUpTime !== null && values?.maxSignUpTime !== null && values?.maxSignUpTime !== '') {
      if (!moment(values.maxSignUpTime).isAfter(moment(values.minSignUpTime))) {
        errors.maxSignUpTime = LocalizedString.common.errMsgStartEndDate;
      }
    }
  }

  return errors;
};
