import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormImageInputField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  RXFORM_WASTE_COLLECTION, RXSTATE_WASTE_COLLECTION_PAGE, RXSTATE_WASTE_COLLECTIONS,
  RXFIELD_WASTE_COLLECTION_DEALER, RXFIELD_WASTE_COLLECTION_PROFILE,
  RXFIELD_WASTE_COLLECTION_ACTIVITY_STATUS, RXFIELD_WASTE_COLLECTION_CREATED_DATE,
  RXFIELD_WASTE_COLLECTION_USER_ID, RXFIELD_WASTE_COLLECTION_USER_NAME,
  RXFIELD_WASTE_COLLECTION_FIRST_NAME, RXFIELD_WASTE_COLLECTION_LAST_NAME,
  RXFIELD_WASTE_COLLECTION_GENDER, RXFIELD_WASTE_COLLECTION_EMAIL, RXFIELD_WASTE_COLLECTION_PHONE,
  RXFIELD_WASTE_COLLECTION_RECEIVE_NEWS_UPDATE, RXFIELD_WASTE_COLLECTION_DEALER_ID,
  RXFIELD_WASTE_COLLECTION_DEALER_NAME, RXFIELD_WASTE_COLLECTION_UNIQUE_CODE,
  RXFIELD_WASTE_COLLECTION_COMPLATED_TIME,
  RXFIELD_WASTE_COLLECTION_IMAGE,
  ACTIVITY_STATUS,
} from '../../constant';

const renderDialogContent = (initialValues, downloadingDeleting) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderUserID}
          label={LocalizedString.wasteCollection.labelUserID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_USER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderUserName}
          label={LocalizedString.wasteCollection.labelUserName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_FIRST_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderFirstName}
          label={LocalizedString.wasteCollection.labelFirstName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_LAST_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderLastName}
          label={LocalizedString.wasteCollection.labelLastName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_GENDER}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.wasteCollection.placeholderGender}
          placeholder={LocalizedString.wasteCollection.labelGender}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_EMAIL}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.wasteCollection.placeholderEmail}
          placeholder={LocalizedString.wasteCollection.labelEmail}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_PHONE}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.wasteCollection.placeholderPhone}
          placeholder={LocalizedString.wasteCollection.labelPhone}
          disabled
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_RECEIVE_NEWS_UPDATE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderReceiveNewsUpdate}
          label={LocalizedString.wasteCollection.labelReceiveNewsUpdate}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_DEALER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderDealerID}
          label={LocalizedString.wasteCollection.labelDealerID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_DEALER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderDealerName}
          label={LocalizedString.wasteCollection.labelDealerName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_UNIQUE_CODE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderUniqueCode}
          label={LocalizedString.wasteCollection.labelUniqueCode}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_COMPLATED_TIME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wasteCollection.placeholderCompletedTime}
          label={LocalizedString.wasteCollection.labelCompletedTime}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_ACTIVITY_STATUS}
          component={renderReduxFormOutlinedTextField}
          label={LocalizedString.wasteCollection.labelActivityStatus}
          placeholder={LocalizedString.wasteCollection.placeholderActivityStatus}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WASTE_COLLECTION_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.wasteCollection.labelImage}
          disabled
          useCropper={false}
          loading={downloadingDeleting}
          useFullWidthImage
          fullImageWidth="100%"
          defaultValue={initialValues[RXFIELD_WASTE_COLLECTION_IMAGE]}
        />
      </Grid>
    </Grid>
  </Grid>
);

const WasteCollectionPage = ({
  onAppear, downloading, handleSubmit, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  dealers, loadingDealers, onChangeDealerText,
  users, loadingUsers, onChangeUserText,
  onDownloadPressed, downloadingDeleting,
}) => (
  <FunctionalPage
    data={RXSTATE_WASTE_COLLECTIONS}
    uiPage={RXSTATE_WASTE_COLLECTION_PAGE}
    tableColumns={[
      {
        title: LocalizedString.wasteCollection.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.wasteCollection.labelDealer, field: 'dealer.name', sorting: !downloading },
      { title: LocalizedString.wasteCollection.labelUser, field: 'profile.fullName', sorting: !downloading },
      { title: LocalizedString.wasteCollection.labelActivityStatus, field: 'activityStatus', sorting: !downloading },
      {
        title: LocalizedString.wasteCollection.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.wasteCollection.labelDealer,
        field: RXFIELD_WASTE_COLLECTION_DEALER,
        type: FILTER_TYPE_DROPDOWN,
        data: dealers,
        loading: loadingDealers,
        onChangeFilterText: onChangeDealerText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wasteCollection.labelProfile,
        field: RXFIELD_WASTE_COLLECTION_PROFILE,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wasteCollection.labelActivityStatus,
        field: RXFIELD_WASTE_COLLECTION_ACTIVITY_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: ACTIVITY_STATUS,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wasteCollection.labelCreatedDate,
        field: RXFIELD_WASTE_COLLECTION_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.wasteCollection.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
    enableSave
    onDownloadPressed={onDownloadPressed}
  >
    {renderDialogContent(initialValues, downloadingDeleting)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WASTE_COLLECTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WasteCollectionPage);

WasteCollectionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  dealers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingDealers: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeDealerText: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
};
