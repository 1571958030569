import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    testDriveMenu: {
      title: 'Test Drive',
    },
    reservationPage: {
      title: 'Reservation',

      labelNo: 'No',
      labelReservationStatus: 'Reservation Status',
      labelReservationType: 'Reservation Type',
      labelEventId: 'Event ID',
      labelEventName: 'Event Name',
      labelEventLocation: 'Event Location',
      labelActivityStatus: 'Activity Status',
      labelDealerCode: 'Dealer Code',
      labelDealerName: 'Dealer Name',
      labelDealerCity: 'Dealer City',
      labelSimImage: 'SIM Image',
      labelUserId: 'User ID',
      labelUserFullname: 'User Full Name',
      labelVehicleModel: 'Vehicle Model',
      labelReservedDate: ' Reserved Date',
      labelCompletedTime: 'Completed Time',
      labelTestdriveImage: 'Test Drive Image',
      labelOtherImage: 'Other Image',
      labelEventOrDealer: 'Event / Dealer',
      labelCreatedDate: 'Created Date',
      labelReceivedNewsUpdate: 'Received News Update',
      labelSurveyImage: 'Survey Image',
      labelReferenceId: 'Reference ID',
      labelUserInfo: 'User Info',

      placeholderFirstName: 'First Name',
      placeholderLastName: 'Last Name',
      placeholderEmail: 'Email',
      placeholderPhone: 'Phone Number',
      placeholderGender: 'Gender',
      placeholderSalesConsultantID: 'Sales Consultant ID',
      placeholderSalesConsultantName: 'Sales Consultant Name',
    },
    eventPage: {
      title: 'Event',

      labelNo: 'No',
      labelName: 'Name',
      labelLocation: 'Location',
      labelStartTime: 'Start Time',
      labelEndTime: 'End Time',
      labelVisibility: 'Visibility',
      labelCopied: 'Copied!',

      placeholderName: 'Name',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderURL1: 'URL 1',
      placeholderURL1ExternalBrowser: 'Open URL 1 on Ext Browser',
      placeholderDescription: 'Description',
      placeholderLocation: 'Location',
      placeholderVisibility: 'Visibility',
      placeholderURL2: 'URL 2',
      placeholderURL2ExternalBrowser: 'Open URL 2 on Ext Browser',
      placeholderVoucherReward: 'Voucher Rewards',
      placeholderProvince: 'Province',
      placeholderCity: 'City',
      placeholderSurveyURL: 'Survey URL',
      placeholderSurveyURLExternalBrowser: 'Open Survey on Ext Browser',
      placeholderEventCode: 'Event Code',

      buttonCaptionCreateEvent: 'Create New Event',
      buttonCaptionEditEvent: 'Edit Event',
      buttonCaptionDeleteEvent: 'Delete Event',
    },
    uniqueCodePage: {
      title: 'Unique Code',

      labelNo: 'No',
      labelFullName: 'Full Name',
      labelCode: 'Code',
      labelCreatedDate: 'Created Date',
      labelUserId: 'User ID',
    },
    testVehiclePage: {
      title: 'Test Vehicle',

      labelNo: 'No',
      labelEvent: 'Event',
      labelModel: 'Model',
      labelDailyQuota: 'Daily Quota',

      placeholderEvent: 'Event',
      placeholderModel: 'Model',
      placeholderDailyQuota: 'Daily Quota',

      buttonCaptionCreateTestVehicle: 'Create New Test Vehicle',
      buttonCaptionEditTestVehicle: 'Edit Test Vehicle',
      buttonCaptionDeleteTestVehicle: 'Delete Test Vehicle',
    },
    voucherRewardPage: {
      title: 'Voucher Reward',

      labelNo: 'No',
      labelCreatedDate: 'Created Date',
      labelEvent: 'Event',
      labelEventName: 'Event Name',
      labelEventId: 'Event ID',
      labelNote: 'Note',
      labelUser: 'User',
      labelUserFullname: 'User Full Name',
      labelUserId: 'User ID',
      labelVoucherName: 'Voucher Name',
      labelVoucherId: 'Voucher ID',
    },
    eventOrganizerPage: {
      title: 'Event Organizer',

      labelNo: 'No',
      labelUser: 'User',
      labelEventName: 'Event Name',
      labelCreatedDate: 'Created Date',
      labelUserId: 'User ID',
      labelUserFullname: 'User Full Name',
      labelEventId: 'Event ID',

      buttonCaptionCreateEventOrganizer: 'Create New Event Organizer',
      buttonCaptionEditEventOrganizer: 'Edit Event Organizer',
      buttonCaptionDeleteEventOrganizer: 'Delete Event Organizer',
    },
  },
  id: {
    testDriveMenu: {
      title: 'Uji Coba',
    },
    reservationPage: {
      title: 'Reservasi',

      labelNo: 'No',
      labelReservationStatus: 'Status Reservasi',
      labelReservationType: 'Tipe Reservasi',
      labelEventId: 'ID Acara',
      labelEventName: 'Nama Acara',
      labelEventLocation: 'Lokasi Acara',
      labelActivityStatus: 'Status Aktivitas',
      labelDealerCode: 'Kode Dealer',
      labelDealerName: 'Nama Dealer',
      labelDealerCity: 'Kota Dealer',
      labelSimImage: 'Foto SIM',
      labelUserId: 'ID Pengguna',
      labelUserFullname: 'Nama Lengkap Pengguna',
      labelVehicleModel: 'Model Kendaraan',
      labelReservedDate: ' Tanggal Reservasi',
      labelCompletedTime: 'Waktu Selesai',
      labelTestdriveImage: 'Foto Test Drive',
      labelOtherImage: 'Foto Lain',
      labelEventOrDealer: 'Acara / Dealer',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelReceivedNewsUpdate: 'Terima Berita Terkini',
      labelSurveyImage: 'Foto Survey',
      labelReferenceId: 'ID Referensi',
      labelUserInfo: 'Info Pengguna',

      placeholderFirstName: 'Nama Depan',
      placeholderLastName: 'Nama Belakang',
      placeholderEmail: 'Email',
      placeholderPhone: 'Nomor Telepon',
      placeholderGender: 'Jenis Kelamin',
      placeholderSalesConsultantID: 'ID Konsultan Penjualan',
      placeholderSalesConsultantName: 'Nama Konsultan Penjualan',
    },
    eventPage: {
      title: 'Acara',

      labelNo: 'No',
      labelName: 'Nama',
      labelLocation: 'Lokasi',
      labelStartTime: 'Waktu Mulai',
      labelEndTime: 'Waktu Selesai',
      labelVisibility: 'Visibilitas',
      labelCopied: 'Disalin!',

      placeholderName: 'Nama',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderURL1: 'URL 1',
      placeholderURL1ExternalBrowser: 'Buka URL 1 di Browser Ekstensi',
      placeholderDescription: 'Deskripsi',
      placeholderLocation: 'Lokasi',
      placeholderVisibility: 'Visibilitas',
      placeholderURL2: 'URL 2',
      placeholderURL2ExternalBrowser: 'Buka URL 2 di Browser Ekstensi',
      placeholderVoucherReward: 'Hadiah Voucher',
      placeholderProvince: 'Provinsi',
      placeholderCity: 'Kota',
      placeholderSurveyURL: 'URL Survei',
      placeholderSurveyURLExternalBrowser: 'Buka URL Survei di Browser Ekstensi',
      placeholderEventCode: 'Kode Acara',

      buttonCaptionCreateEvent: 'Buat Acara Baru',
      buttonCaptionEditEvent: 'Ubah Acara',
      buttonCaptionDeleteEvent: 'Hapus Acara',
    },
    uniqueCodePage: {
      title: 'Kode Unik',

      labelNo: 'No',
      labelFullName: 'Nama Lengkap',
      labelCode: 'Kode',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelUserId: 'ID Pengguna',
    },
    testVehiclePage: {
      title: 'Tes Kendaraan',

      labelNo: 'No',
      labelEvent: 'Acara',
      labelModel: 'Model',
      labelDailyQuota: 'Kuota Harian',

      placeholderEvent: 'Acara',
      placeholderModel: 'Model',
      placeholderDailyQuota: 'Kuota Harian',

      buttonCaptionCreateTestVehicle: 'Buat Tes Kendaraan Baru',
      buttonCaptionEditTestVehicle: 'Ubah Tes Kendaraan',
      buttonCaptionDeleteTestVehicle: 'Hapus Tes Kendaraan',
    },
    voucherRewardPage: {
      title: 'Hadiah Voucher',

      labelNo: 'No',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelEvent: 'Acara',
      labelEventName: 'Nama Acara',
      labelEventId: 'ID Acara',
      labelNote: 'Catatan',
      labelUser: 'Pengguna',
      labelUserFullname: 'Nama Lengkap Pengguna',
      labelUserId: 'ID Pengguna',
      labelVoucherName: 'Nama Voucher',
      labelVoucherId: 'ID Voucher',

    },
    eventOrganizerPage: {
      title: 'Penyelenggara Acara',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelEventName: 'Nama Acara',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelUserId: 'ID Penggua',
      labelUserFullname: 'Nama Lengkap Pengguna',
      labelEventId: 'ID Acara',

      buttonCaptionCreateEventOrganizer: 'Buat Penyelenggara Acara Baru',
      buttonCaptionEditEventOrganizer: 'Ubah Penyelenggara Acara',
      buttonCaptionDeleteEventOrganizer: 'Hapus Penyelenggara Acara',
    },
  },
});

export default LocalizedString;
