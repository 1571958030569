import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_DISCUSSION_COMMENT, MENUID_DISCUSSION_DISCUSSION_COMMENT, PAGE_MODE_TABLE } from '../../constant';
import { transformDropdownData, transformUserDropdownData, transformInitialValues } from '../../helper';
import {
  clearDiscussionComments, clearDiscussions, clearUsers, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setDiscussionAdvancedFilterDialogSelectedFilterString, setDiscussionSearchText,
  setDiscussionCommentSearchText, setDiscussionCommentSelectedOrderBy,
  setDiscussionCommentSelectedPageSize, setDiscussionCommentTappedId,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadDiscussionCommentAsync, downloadDiscussionCommentsAsync, downloadDiscussionsAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import DiscussionCommentPage from './discussion-comment.presentation';

const getInitialValues = (state) => {
  const {
    discussionComments, uiFunctionalPage, uiDiscussionComment,
  } = state;
  const { data } = discussionComments;
  const { downloadingDetail, tappedId } = uiDiscussionComment;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDetail
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      userId: found.user?.id || '',
      fullName: found.user?.fullName || '',
      replyToId: found.replyTo?.id || '',
      replyToComment: found.replyTo?.comment || '',
      discussionId: found.discussion?.id || '',
      discussionName: found.discussion?.name || '',
    }) : {
      userId: '',
      fullName: '',
      replyToId: '',
      replyToComment: '',
      discussionId: '',
      discussionName: '',
      comment: '',
      reactionId: '',
      replyCount: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  discussions: transformDropdownData(state.discussions.data),
  downloading: state.uiDiscussionComment.downloading,
  loadingUser: state.uiUser.downloading,
  dialogVisibility: state.uiFunctionalPage.visibility,
  loadingDiscussion: state.uiDiscussion.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDiscussionAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setDiscussionSearchText(''));
    dispatch(clearDiscussions());
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDiscussionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_DISCUSSION_DISCUSSION_COMMENT));
    dispatch(setDiscussionCommentSearchText(''));
    dispatch(clearDiscussionComments());
    dispatch(setDiscussionCommentSelectedPageSize(20));
    dispatch(setDiscussionCommentSelectedOrderBy(INITIAL_ORDER_BY_DISCUSSION_COMMENT));
    dispatch(downloadDiscussionCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id')
      .replace(/discussion/, 'discussion.id').replace(/replyToId/, 'replyTo.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearDiscussionComments());
    dispatch(downloadDiscussionCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDiscussionCommentsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDiscussionCommentSelectedPageSize(pageSize));
    dispatch(downloadDiscussionCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeDiscussionText: async (text) => {
    try {
      dispatch(setDiscussionSearchText(text));
      dispatch(clearDiscussions());
      await dispatch(downloadDiscussionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setDiscussionCommentSelectedPageSize(pageSize));
    dispatch(clearDiscussionComments());
    dispatch(downloadDiscussionCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearDiscussionComments());
    dispatch(setDiscussionCommentSelectedOrderBy(INITIAL_ORDER_BY_DISCUSSION_COMMENT));
    dispatch(downloadDiscussionCommentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setDiscussionCommentSearchText(text));
      dispatch(clearDiscussionComments());
      await dispatch(downloadDiscussionCommentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setDiscussionCommentSelectedOrderBy(orderBy));
    dispatch(clearDiscussionComments());
    dispatch(downloadDiscussionCommentsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setDiscussionCommentTappedId(id));
    dispatch(downloadDiscussionCommentAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionCommentPage);
