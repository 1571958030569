import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_RESERVATIONS = '/testdrive/api/v1/Reservation?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=dealer.name&filterString=status=enabled';
export const REST_URL_VIEW_RESERVATION = '/testdrive/api/v1/Reservation/{id}';
export const REST_URL_EVENTS = '/testdrive/api/v1/HyundaiEvent?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_EVENT = '/testdrive/api/v1/HyundaiEvent/{id}';
export const REST_URL_ADD_EDIT_EVENT = '/testdrive/api/v1/HyundaiEvent';
export const REST_URL_UNIQUE_CODES = '/testdrive/api/v1/UniqueCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_UNIQUE_CODE = '/testdrive/api/v1/UniqueCode/{id}';
export const REST_URL_TEST_VEHICLES = '/testdrive/api/v1/TestVehicle?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_TEST_VEHICLE = '/testdrive/api/v1/TestVehicle/{id}';
export const REST_URL_ADD_EDIT_TEST_VEHICLE = '/testdrive/api/v1/TestVehicle';
export const REST_URL_VOUCHER_REWARDS = '/testdrive/api/v1/VoucherReward?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_VOUCHER_REWARD = '/testdrive/api/v1/VoucherReward/{id}';
export const REST_URL_EVENT_ORGANIZERS = '/testdrive/api/v1/EventOrganizer?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_EVENT_ORGANIZER = '/testdrive/api/v1/EventOrganizer/{id}';
export const REST_URL_ADD_EDIT_EVENT_ORGANIZER = '/testdrive/api/v1/EventOrganizer';

export const ROUTE_NAME_TEST_DRIVE = '/test-drive';
export const ROUTE_NAME_TEST_DRIVE_EVENT = '/event';
export const ROUTE_NAME_TEST_DRIVE_RESERVATION = '/reservation';
export const ROUTE_NAME_TEST_DRIVE_UNIQUE_CODE = '/unique-code';
export const ROUTE_NAME_TEST_DRIVE_TEST_VEHICLE = '/test-vehicle';
export const ROUTE_NAME_TEST_DRIVE_VOUCHER_REWARD = '/voucher-reward';
export const ROUTE_NAME_TEST_DRIVE_EVENT_ORGANIZER = '/event-organizer';

export const RXFORM_EVENT = 'eventPage';
export const RXFORM_TEST_DRIVE_RESERVATION = 'reservationPage';
export const RXFORM_TEST_DRIVE_UNIQUE_CODE = 'uniqueCodePage';
export const RXFORM_TEST_DRIVE_TEST_VEHICLE = 'testVehiclePage';
export const RXFORM_TEST_DRIVE_VOUCHER_REWARD = 'voucherRewardPage';
export const RXFORM_TEST_DRIVE_EVENT_ORGANIZER = 'eventOrganizerPage';

export const RXFIELD_TEST_DRIVE_EVENT_NAME = 'name';
export const RXFIELD_TEST_DRIVE_EVENT_START_TIME = 'startTime';
export const RXFIELD_TEST_DRIVE_EVENT_END_TIME = 'endTime';
export const RXFIELD_TEST_DRIVE_EVENT_URL1 = 'url1';
export const RXFIELD_TEST_DRIVE_EVENT_URL1_EXTERNAL_BROWSER = 'url1OpenOnExternalBrowser';
export const RXFIELD_TEST_DRIVE_EVENT_DESCRIPTION = 'description';
export const RXFIELD_TEST_DRIVE_EVENT_LOCATION = 'location';
export const RXFIELD_TEST_DRIVE_EVENT_VISIBILITY = 'visibility';
export const RXFIELD_TEST_DRIVE_EVENT_URL2 = 'url2';
export const RXFIELD_TEST_DRIVE_EVENT_URL2_EXTERNAL_BROWSER = 'url2OpenOnExternalBrowser';
export const RXFIELD_TEST_DRIVE_EVENT_VOUCHER = 'vouchers';
export const RXFIELD_TEST_DRIVE_EVENT_PROVINCE = 'provinces';
export const RXFIELD_TEST_DRIVE_EVENT_CITY = 'city';
export const RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL = 'surveyUrl';
export const RXFIELD_TEST_DRIVE_EVENT_SURVEY_URL_EXTERNAL_BROWSER = 'surveyUrlOpenOnExternalBrowser';
export const RXFIELD_TEST_DRIVE_EVENT_EVENT_CODE = 'eventCode';
export const RXFIELD_TEST_DRIVE_CITY = 'city';
export const RXFIELD_TEST_DRIVE_PROVINCE = 'province';
export const RXFIELD_RESERVATION_TYPE = 'reservationType';
export const RXFIELD_RESERVATION_EVENT_ID = 'eventId';
export const RXFIELD_RESERVATION_EVENT_NAME = 'eventName';
export const RXFIELD_RESERVATION_EVENT_LOCATION = 'eventLocation';
export const RXFIELD_RESERVATION_ACTIVITY_STATUS = 'activityStatus';
export const RXFIELD_RESERVATION_DEALER_CODE = 'dealerCode';
export const RXFIELD_RESERVATION_DEALER_NAME = 'dealerName';
export const RXFIELD_RESERVATION_DEALER_CITY = 'dealerCity';
export const RXFIELD_RESERVATION_SIM_IMAGE = 'simImage';
export const RXFIELD_RESERVATION_SURVEY_IMAGE = 'surverImage';
export const RXFIELD_RESERVATION_USER_ID = 'userId';
export const RXFIELD_RESERVATION_USER_FULLNAME = 'userFullname';
export const RXFIELD_RESERVATION_VEHICLE_MODEL = 'testVehicleModel';
export const RXFIELD_RESERVATION_RESERVED_DATE = 'reservedDate';
export const RXFIELD_RESERVATION_COMPLETED_TIME = 'completedTime';
export const RXFIELD_RESERVATION_TESTDRIVE_IMAGE = 'testdriveImage';
export const RXFIELD_RESERVATION_OTHER_IMAGE = 'otherImage';
export const RXFIELD_RESERVATION_FIRST_NAME = 'firstName';
export const RXFIELD_RESERVATION_LAST_NAME = 'lastName';
export const RXFIELD_RESERVATION_EMAIL = 'email';
export const RXFIELD_RESERVATION_PHONE = 'phone';
export const RXFIELD_RESERVATION_GENDER = 'gender';
export const RXFIELD_RESERVATION_RECEIVED_NEWS_UPDATE = 'wantToReceiveNewsUpdate';
export const RXFIELD_RESERVATION_SALES_CONSULTANT_ID = 'salesConsultantId';
export const RXFIELD_RESERVATION_SALES_CONSULTANT_NAME = 'salesConsultantName';
export const RXFIELD_RESERVATION_REFERENCE_ID = 'referenceId';
export const RXFIELD_UNIQUE_CODE_USER_ID = 'userId';
export const RXFIELD_UNIQUE_CODE_USER_FULL_NAME = 'userFullName';
export const RXFIELD_UNIQUE_CODE_CODE = 'code';
export const RXFIELD_UNIQUE_CODE_CREATED_DATE = 'createdDate';
export const RXFIELD_TEST_VEHICLE_EVENT = 'event';
export const RXFIELD_TEST_VEHICLE_MODEL = 'model';
export const RXFIELD_TEST_VEHICLE_DAILY_QUOTA = 'dailyQuota';
export const RXFIELD_VOUCHER_REWARD_CREATED_DATE = 'createdDate';
export const RXFIELD_VOUCHER_REWARD_EVENT_NAME = 'eventName';
export const RXFIELD_VOUCHER_REWARD_EVENT_ID = 'eventId';
export const RXFIELD_VOUCHER_REWARD_NOTE = 'note';
export const RXFIELD_VOUCHER_REWARD_USER_FULLNAME = 'userFullname';
export const RXFIELD_VOUCHER_REWARD_USER_ID = 'userId';
export const RXFIELD_VOUCHER_REWARD_VOUCHER_NAME = 'voucherName';
export const RXFIELD_VOUCHER_REWARD_VOUCHER_ID = 'voucherId';
export const RXFIELD_EVENT_ORGANIZER_USER_ID = 'userId';
export const RXFIELD_EVENT_ORGANIZER_USER_FULLNAME = 'userFullName';
export const RXFIELD_EVENT_ORGANIZER_EVENT_ID = 'eventId';
export const RXFIELD_EVENT_ORGANIZER_EVENT_NAME = 'eventName';
export const RXFIELD_EVENT_ORGANIZER_CREATED_DATE = 'createdDate';

export const RXSTATE_EVENTS = 'events';
export const RXSTATE_EVENT_PAGE = 'uiEvent';
export const RXSTATE_RESERVATIONS = 'reservations';
export const RXSTATE_RESERVATION_PAGE = 'uiReservation';
export const RXSTATE_UNIQUE_CODES = 'uniqueCodes';
export const RXSTATE_UNIQUE_CODES_PAGE = 'uiUniqueCode';
export const RXSTATE_TEST_VEHICLES = 'testVehicles';
export const RXSTATE_TEST_VEHICLE_PAGE = 'uiTestVehicle';
export const RXSTATE_VOUCHER_REWARDS = 'voucherRewards';
export const RXSTATE_VOUCHER_REWARD_PAGE = 'uiVoucherReward';
export const RXSTATE_EVENT_ORGANIZERS = 'eventOrganizers';
export const RXSTATE_EVENT_ORGANIZER_PAGE = 'uiEventOrganizer';

export const INITIAL_ORDER_BY_TEST_DRIVE_EVENTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_RESERVATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_UNIQUE_CODES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_TEST_VEHICLES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VOUCHER_REWARD = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_EVENT_ORGANIZERS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_TEST_DRIVE = 'MENUID_TEST_DRIVE';
export const MENUID_TEST_DRIVE_EVENT = 'MENUID_TEST_DRIVE_EVENT';
export const MENUID_TEST_DRIVE_RESERVATION = 'MENUID_TEST_DRIVE_RESERVATION';
export const MENUID_TEST_DRIVE_UNIQUE_CODE = 'MENUID_TEST_DRIVE_UNIQUE_CODE';
export const MENUID_TEST_DRIVE_TEST_VEHICLE = 'MENUID_TEST_DRIVE_TEST_VEHICLE';
export const MENUID_TEST_DRIVE_VOUCHER_REWARD = 'MENUID_TEST_DRIVE_VOUCHER_REWARD';
export const MENUID_TEST_DRIVE_EVENT_ORGANIZER = 'MENUID_TEST_DRIVE_EVENT_ORGANIZER';

const ACTIVITY_STATUS_RESERVATION = 'Reservation';
const ACTIVITY_STATUS_COMPLETED = 'Completed';
const ACTIVITY_STATUS_DOCUMENT_SUBMITTED = 'DocumentSubmitted';

export const RESERVATION_TYPE_EVENT = 'Event';
export const RESERVATION_TYPE_DEALER = 'Dealer';

export const ACTIVITY_STATUS_DROPDOWN = [
  {
    label: ACTIVITY_STATUS_RESERVATION,
    value: ACTIVITY_STATUS_RESERVATION,
  },
  {
    label: ACTIVITY_STATUS_COMPLETED,
    value: ACTIVITY_STATUS_COMPLETED,
  },
  {
    label: ACTIVITY_STATUS_DOCUMENT_SUBMITTED,
    value: ACTIVITY_STATUS_DOCUMENT_SUBMITTED,
  },
];

export const RESERVATION_TYPE_DROPDOWN = [
  {
    label: RESERVATION_TYPE_EVENT,
    value: RESERVATION_TYPE_EVENT,
  },
  {
    label: RESERVATION_TYPE_DEALER,
    value: RESERVATION_TYPE_DEALER,
  },
];
