import { INITIAL_ORDER_BY_DEALERS } from '../../constant';
import {
  DOWNLOADING_DEALERS,
  SET_DEALER_SEARCH_TEXT, SET_DEALER_SELECTED_PAGE_SIZE, SET_DEALER_TAPPED_ID,
  SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  DOWNLOADING_DELETING_DEALER, SET_DEALER_SELECTED_ORDER_BY,
  DOWNLOADING_DEALER_CITIES, DOWNLOADING_DEALER_PROVINCES,
} from '../action';

const initialState = {
  downloading: false,
  downloadingDeleting: false,
  downloadingProvince: false,
  downloadingCity: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_DEALERS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_DEALERS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_DEALER:
      return { ...state, downloadingDeleting: action.status };
    case DOWNLOADING_DEALER_CITIES:
      return { ...state, downloadingCity: action.status };
    case DOWNLOADING_DEALER_PROVINCES:
      return { ...state, downloadingProvince: action.status };
    case SET_DEALER_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_DEALER_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_DEALER_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_DEALER_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_DEALER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    default: return state;
  }
};
