import {
  REST_URL_GAMEQR_CATEGORIES, REST_URL_ADD_EDIT_GAMEQR_CATEGORY,
  REST_URL_VIEW_DELETE_GAMEQR_CATEGORY,
  REST_URL_CHALLENGES, REST_URL_ADD_EDIT_CHALLENGE, REST_URL_VIEW_DELETE_CHALLENGE,
  REST_URL_SCANNED_CODES, REST_URL_VIEW_DELETE_SCANNED_CODE,
  REST_URL_COMPLETED_CHALLENGES, REST_URL_VIEW_DELETE_COMPLETED_CHALLENGE,
} from './constant';
import {
  buildQueryParams, sendGetRequest, sendDeleteRequest, sendPostRequest, sendPutRequest,
  transformMediaData, transformQueryResult, toNumber,
} from '../../helper';

export * from '../../helper';

export const downloadGameQRCategories = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_GAMEQR_CATEGORIES.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_GAMEQR_CATEGORIES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadGameQRCategory = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_GAMEQR_CATEGORY.replace(/\{id\}/, id), token);
  return response;
};

export const addGameQRCategory = async (name, description, image, token) => {
  const body = {
    name, description, image,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_GAMEQR_CATEGORY, body, token);
};

export const editGameQRCategory = async (id, name, description, image, token) => {
  const body = {
    id, name, description, image,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_GAMEQR_CATEGORY, body, token);
};

export const deleteGameQRCategory = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_GAMEQR_CATEGORY.replace(/\{id\}/, id), token);
};

export const downloadChallenges = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_CHALLENGES.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_CHALLENGES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = transformMediaData(response.data);
  return transformQueryResult(response, transformData);
};

export const addChallenge = async (title, content, validFrom, validUntil, categoryId,
  visibilityStatus, scanPolicy, tags, media, codes, isPromoted, order,
  scanLimitMode, scanLimitAbsoluteValue, scanLimitPerHour, scanLimitPerDay,
  scanLimitPerWeek, scanLimitPerMonth, scanLimitTimeZone, token,
  voucherIds,
  completedRewardType,
  pointAmount,
  couponAmount,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  enableBudget,
  budget,
) => {
  const body = {
    title,
    content,
    validFrom,
    validUntil,
    categoryId,
    visibilityStatus,
    scanPolicy,
    tags,
    media,
    codes,
    isPromoted,
    order,
    scanLimitMode,
    scanLimitAbsoluteValue,
    scanLimitPerHour,
    scanLimitPerDay,
    scanLimitPerWeek,
    scanLimitPerMonth,
    scanLimitTimeZone,
    voucherIds,
    completedRewardType,
    pointAmount: pointAmount ? toNumber(pointAmount) : 0,
    couponAmount: couponAmount ? toNumber(couponAmount) : 0,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    enableBudget,
    budget,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_CHALLENGE, body, token);
};

export const editChallenge = async (id, title, content, validFrom, validUntil, categoryId,
  visibilityStatus, scanPolicy, tags, media, codes, isPromoted, order, scanLimitMode,
  scanLimitAbsoluteValue, scanLimitPerHour, scanLimitPerDay,
  scanLimitPerWeek, scanLimitPerMonth, scanLimitTimeZone, token,
  voucherIds,
  completedRewardType,
  pointAmount,
  couponAmount,
  bypassMaxPurchaseValidation,
  bypassAchievementValidation,
  enableBudget,
  budget,
) => {
  const body = {
    id,
    title,
    content,
    validFrom,
    validUntil,
    categoryId,
    visibilityStatus,
    scanPolicy,
    tags,
    media,
    codes,
    isPromoted,
    order,
    scanLimitMode,
    scanLimitAbsoluteValue,
    scanLimitPerHour,
    scanLimitPerDay,
    scanLimitPerWeek,
    scanLimitPerMonth,
    scanLimitTimeZone,
    voucherIds,
    completedRewardType,
    pointAmount: pointAmount ? toNumber(pointAmount) : 0,
    couponAmount: couponAmount ? toNumber(couponAmount) : 0,
    bypassMaxPurchaseValidation,
    bypassAchievementValidation,
    enableBudget,
    budget,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_CHALLENGE, body, token);
};

export const downloadChallenge = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CHALLENGE.replace(/\{id\}/, id), token);
  const transformData = transformMediaData(response);
  return transformData;
};

export const deleteChallenge = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CHALLENGE.replace(/\{id\}/, id), token);
};

export const downloadScannedCodes = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_SCANNED_CODES.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_SCANNED_CODES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadScannedCode = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SCANNED_CODE.replace(/\{id\}/, id), token);
  return response;
};

export const downloadCompletedChallenges = async (pageNumber, pageSize, orderBy,
  searchText, filterString, token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_COMPLETED_CHALLENGES.concat(filterString), pageNumber,
      pageSize, orderBy, searchText)
    : buildQueryParams(REST_URL_COMPLETED_CHALLENGES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCompletedChallenge = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_COMPLETED_CHALLENGE.replace(/\{id\}/, id), token);
  return response;
};
