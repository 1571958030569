import { connect } from 'react-redux';
import {
  change, formValueSelector, reset,
} from 'redux-form';
import {
  INITIAL_ORDER_BY_REDEMPTION_HISTORIES, MENUID_PROMO_CODE_REDEMPTION_HISTORY, PAGE_MODE_TABLE,
  RXFIELD_REDEMPTION_HISTORY_VOUCHER, RXFORM_REDEMPTION_HISTORY,
  RXFIELD_REDEMPTION_HISTORY_MERCHANT, RXFIELD_REDEMPTION_HISTORY_REWARD,
  RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE,
  RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION,
  RXFIELD_REDEMPTION_HISTORY_COUPONS_AMOUNT, RXFIELD_REDEMPTION_HISTORY_COINS_AMOUNT,
  DATE_TIME_FORMAT_WITHOUT_PIPE, REWARD_TYPE_COIN, REWARD_TYPE_COUPON,
  RXFIELD_REDEMPTION_HISTORY_USER,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData, toMoment,
  transformUserDropdownData,
} from '../../helper';
import {
  setActiveSideMenuItem, setAlertErrorMessage, setAlertConfirmationMessage,
  setAdvancedFilterDialogSelectedFilterString, downloadRedemptionHistoriesAsync,
  downloadRedemptionHistoryAsync, setRedemptionHistorySearchText,
  setRedemptionHistorySelectedOrderBy, setRedemptionHistorySelectedPageSize,
  setRedemptionHistoryTappedId, setRedemptionHistorySelectedMerchant,
  setRedemptionHistorySelectedVoucher, setRedemptionHistorySelectedReward,
  setRedemptionHistorySelectedVoucherBypassMaxPurchase,
  setRedemptionHistorySelectedVoucherBypassAchievement,
  clearRedemptionHistories, setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData, clearCompaniesMData, setUserAdvancedFilterDialogSelectedFilterString,
  setUserSearchText, clearUsers, setRedemptionHistorySelectedUser,
} from '../../redux/action';
import {
  downloadMDataCompaniesAsync, downloadUsersAsync,
} from '../../../../redux/action';
import RedemptionHistoryPage from './redemption-history.presentation';
import GlobalLocalizedString from '../../../../localization';
import {
  downloadVouchersAsync,
  setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText, clearVouchers, VOUCHER_FLAG_GARUDA_LOUNGE,
} from '../../../voucher';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiRedemptionHistory, redemptionHistories } = state;
  const { data } = redemptionHistories;
  const { downloadingDeleting, tappedId } = uiRedemptionHistory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    merchant: found.merchantId
      ? { label: found.merchantName, value: found.merchantId }
      : { label: '', value: '' },
    user: found.user.id
      ? { label: found.user.fullName, value: found.user.id }
      : { label: '', value: '' },
    userId: found.user.id,
    voucherRedemptions: found.voucherRedemptions.length > 0
      ? found.voucherRedemptions.map((x) => (x.garudaFlag === VOUCHER_FLAG_GARUDA_LOUNGE ? `${x.voucherName} - ${x.airportName} (${x.voucherId})` : `${x.voucherName} (${x.voucherId})`)).join('\n') : '',
    rewardType: found.rewardType || '',
    couponAmount: found.couponAmount ? `${found.couponAmount}` : '',
    pointAmount: found.pointAmount ? `${found.pointAmount}` : '',
    validFrom: found.validFrom
      ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    validUntil: found.validUntil
      ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
  }) : {
  };
  return result;
};

const selector = formValueSelector(RXFORM_REDEMPTION_HISTORY);

const mapStateToProps = (state) => ({
  downloading: state.uiRedemptionHistory.downloading,
  downloadingDeleting: state.uiRedemptionHistory.downloadingDeleting,
  addingEditing: state.uiRedemptionHistory.addingEditing,

  tappedId: state.uiFunctionalPage.tappedId || '',
  pageMode: state.uiFunctionalPage.pageMode,

  initialValues: getInitialValues(state),
  formSelectedRewardType: selector(state, RXFIELD_REDEMPTION_HISTORY_REWARD),

  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingVouchers: state.uiVoucher.downloading,

  merchants: transformDropdownData(state.companiesMdata.data),
  users: transformUserDropdownData(state.users.data),
  loadingMerchants: state.uiCompany.downloading,
  loadingUsers: state.uiUser.downloading,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PROMO_CODE_REDEMPTION_HISTORY));
    dispatch(setRedemptionHistorySearchText(''));
    dispatch(clearRedemptionHistories());
    dispatch(setRedemptionHistorySelectedPageSize(20));
    dispatch(setRedemptionHistorySelectedOrderBy(INITIAL_ORDER_BY_REDEMPTION_HISTORIES));
    dispatch(downloadRedemptionHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRedemptionHistoriesAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRedemptionHistorySelectedPageSize(pageSize));
    dispatch(downloadRedemptionHistoriesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setRedemptionHistorySelectedPageSize(pageSize));
    dispatch(clearRedemptionHistories());
    dispatch(downloadRedemptionHistoriesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setRedemptionHistorySearchText(text));
    dispatch(clearRedemptionHistories());
    dispatch(downloadRedemptionHistoriesAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setRedemptionHistorySelectedOrderBy(orderBy));
    dispatch(clearRedemptionHistories());
    dispatch(downloadRedemptionHistoriesAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setRedemptionHistoryTappedId(id));
    dispatch(downloadRedemptionHistoryAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/user/, 'user.id')
      .replace(/voucher/, 'voucherId')
      .replace(/merchant/, 'merchantId');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearRedemptionHistories());
    dispatch(downloadRedemptionHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearRedemptionHistories());
    dispatch(setRedemptionHistorySelectedOrderBy(INITIAL_ORDER_BY_REDEMPTION_HISTORIES));
    dispatch(downloadRedemptionHistoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVoucherOptionSelected: (option) => {
    if (option) {
      dispatch(setRedemptionHistorySelectedVoucher(option));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_VOUCHER, option));
    } else {
      dispatch(setRedemptionHistorySelectedVoucher(''));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_VOUCHER, ''));
    }
  },
  onChangeVoucherText: async (text) => {
    try {
      dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setVoucherSearchText(text));
      dispatch(clearVouchers());
      await dispatch(downloadVouchersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onMerchantOptionSelected: (option) => {
    if (option) {
      dispatch(setRedemptionHistorySelectedMerchant(option));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_MERCHANT, option));
    } else {
      dispatch(setRedemptionHistorySelectedMerchant(''));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_MERCHANT, ''));
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setRedemptionHistorySelectedUser(option));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_USER, option));
    } else {
      dispatch(setRedemptionHistorySelectedUser(''));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_USER, ''));
    }
  },
  onChangeMerchantText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRewardOptionSelected: (option) => {
    if (option) {
      const rewardTypeCoin = option.value === REWARD_TYPE_COIN;
      const rewardTypeCoupon = option.value === REWARD_TYPE_COUPON;
      dispatch(setRedemptionHistorySelectedReward(option));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_REWARD, option));
      if (rewardTypeCoin || rewardTypeCoupon) {
        dispatch(setRedemptionHistorySelectedVoucher(''));
        dispatch(setRedemptionHistorySelectedVoucherBypassMaxPurchase(null));
        dispatch(setRedemptionHistorySelectedVoucherBypassAchievement(null));
        dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_VOUCHER, ''));
        dispatch(change(RXFORM_REDEMPTION_HISTORY,
          RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE, null));
        dispatch(change(RXFORM_REDEMPTION_HISTORY,
          RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION, null));
        if (rewardTypeCoin) {
          dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_COUPONS_AMOUNT, '0'));
        }
        if (rewardTypeCoupon) {
          dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_COINS_AMOUNT, '0'));
        }
      }
    } else {
      dispatch(setRedemptionHistorySelectedReward(''));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_REWARD, ''));
    }
  },
  onBypassMaxPurchaseOptionSelected: (option) => {
    if (option) {
      dispatch(setRedemptionHistorySelectedVoucherBypassMaxPurchase(option));
      dispatch(change(RXFORM_REDEMPTION_HISTORY,
        RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE, option));
    } else {
      dispatch(setRedemptionHistorySelectedVoucherBypassMaxPurchase(''));
      dispatch(change(RXFORM_REDEMPTION_HISTORY,
        RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_MAX_PURCHASE, ''));
    }
  },
  onBypassAchievementValidationOptionSelected: (option) => {
    if (option) {
      dispatch(setRedemptionHistorySelectedVoucherBypassAchievement(option));
      dispatch(change(RXFORM_REDEMPTION_HISTORY,
        RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION, option));
    } else {
      dispatch(setRedemptionHistorySelectedVoucherBypassAchievement(''));
      dispatch(change(RXFORM_REDEMPTION_HISTORY, RXFIELD_REDEMPTION_HISTORY_VOUCHER_BYPASS_ACHIEVEMENT_VALIDATION, ''));
    }
  },
  onCancelPressed: () => {
    dispatch(setRedemptionHistorySelectedMerchant(null));
    dispatch(setRedemptionHistorySelectedVoucher(null));
    dispatch(setRedemptionHistorySelectedReward(null));
    dispatch(setRedemptionHistorySelectedVoucherBypassMaxPurchase(null));
    dispatch(setRedemptionHistorySelectedVoucherBypassAchievement(null));

    dispatch(reset(RXFORM_REDEMPTION_HISTORY));
  },
  onDeletePressed: (id) => {
    dispatch(setRedemptionHistoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadRedemptionHistoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setRedemptionHistoryTappedId(''));
    dispatch(setRedemptionHistorySelectedMerchant(null));
    dispatch(setRedemptionHistorySelectedVoucher(null));
    dispatch(setRedemptionHistorySelectedReward(null));
    dispatch(setRedemptionHistorySelectedVoucherBypassMaxPurchase(null));
    dispatch(setRedemptionHistorySelectedVoucherBypassAchievement(null));

    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionHistoryPage);
