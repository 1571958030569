import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_COUPONS, MENUID_COUPON_COUPON, PAGE_MODE_TABLE,
  RXFORM_COUPON, RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION,
  RXFIELD_VOID_COUPON_DIALOG_REASON, RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID,
  RXFORM_CUSTOM_FIELD_DIALOG,
} from '../../constant';
import {
  debounceSearch, transformInitialValues, transformUserDropdownData, toMoment, toCurrency,
} from '../../helper';
import {
  clearCoupons, clearUsers, setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString,
  setAlertErrorMessage, setCouponSearchText, setCouponSelectedPageSize, setCouponSelectedOrderBy,
  setCouponTappedId, setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText,
  downloadCouponAsync, downloadCouponsAsync, setCouponAdvancedFilterDialogSelectedFilterString,
  showVoidCouponDialog, setVoidCouponDialogSelectedSendNotification, voidCouponAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import CouponPage from './coupon.presentation';

const getInitialValues = (state) => {
  const {
    coupons, uiFunctionalPage, uiCoupon,
  } = state;
  const { data } = coupons;
  const { downloadingDeleting, tappedId } = uiCoupon;
  const { pageMode } = uiFunctionalPage;
  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user?.fullName || '',
    validUntil: found.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    voidedOn: found.voidedOn ? toMoment(found.voidedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    expiredCouponAlreadyProcessed: false,
    label: '',
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    isVoided: false,
    voidedOn: null,
    voidReferenceId: null,
    voidNotes: null,
    voidedBy: null,
    fullName: '',
  };
  return result;
};

const getSelectedCouponName = (state) => {
  const {
    coupons, uiCoupon,
  } = state;
  const { data } = coupons;
  const { tappedId } = uiCoupon;
  if (tappedId) {
    const found = Object.values(data).find((x) => x.id === tappedId);
    if (found) {
      return found?.label ? `${toCurrency(found?.amount || 0)} (${found?.label})` : '';
    }
    return '';
  }
  return '';
};

const getSelectedUser = (state) => {
  const {
    coupons, uiCoupon,
  } = state;
  const { data } = coupons;
  const { tappedId } = uiCoupon;
  if (tappedId) {
    const found = Object.values(data).find((x) => x.id === tappedId);
    if (found) {
      return found?.user?.fullName ?? '';
    }
    return '';
  }
  return '';
};

const mapStateToProps = (state) => ({
  downloading: state.uiCoupon.downloading,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.users.data),
  loadingUser: state.uiUser.downloading,
  tappedId: state.uiFunctionalPage.tappedId || '',
  showVoidDialog: state.uiCoupon.showVoidDialog,
  voidingCoupon: state.uiCoupon.voidingCoupon,
  selectedSendNotificationValue: state.uiCoupon.selectedSendNotification,
  selectedCouponName: getSelectedCouponName(state),
  selectedUser: getSelectedUser(state),
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_COUPON_COUPON));
    dispatch(setCouponSearchText(''));
    dispatch(setCouponAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCoupons());
    dispatch(setCouponSelectedPageSize(20));
    dispatch(setCouponSelectedOrderBy(INITIAL_ORDER_BY_COUPONS));
    dispatch(downloadCouponsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(setCouponAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCoupons());
    dispatch(downloadCouponsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COUPON));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCouponsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCouponSelectedPageSize(pageSize));
    dispatch(downloadCouponsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setUserSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setCouponSelectedPageSize(pageSize));
    dispatch(clearCoupons());
    dispatch(downloadCouponsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCouponAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCoupons());
    dispatch(setCouponSelectedOrderBy(INITIAL_ORDER_BY_COUPONS));
    dispatch(downloadCouponsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCouponSearchText(text));
      dispatch(clearCoupons());
      await dispatch(downloadCouponsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCouponSelectedOrderBy(orderBy));
    dispatch(clearCoupons());
    dispatch(downloadCouponsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCouponTappedId(id));
    dispatch(downloadCouponAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVoidPressed: (id) => {
    dispatch(showVoidCouponDialog(true));
    dispatch(setCouponTappedId(id));
    dispatch(setVoidCouponDialogSelectedSendNotification(null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION,
      null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_REASON, null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID, null));
  },
  onCloseVoidModal: () => {
    dispatch(showVoidCouponDialog(false));
    dispatch(setVoidCouponDialogSelectedSendNotification(null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION,
      null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_REASON, null));
    dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_REFERENCE_ID, null));
  },
  onVoidCouponModalOKPressed: (values) => {
    dispatch(voidCouponAsync(values))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSendNotificationOptionSelected: (option) => {
    if (option) {
      dispatch(setVoidCouponDialogSelectedSendNotification(option));
      dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION,
        option));
    } else {
      dispatch(setVoidCouponDialogSelectedSendNotification(null));
      dispatch(change(RXFORM_CUSTOM_FIELD_DIALOG, RXFIELD_VOID_COUPON_DIALOG_SEND_NOTIFICATION,
        null));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponPage);
