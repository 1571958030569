import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, PICKER_MODE_DATE_TIME,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  COLOR_DISABLED_ROW, COLOR_BACKGROUND, RXFIELD_PARTNER_ADMIN_VALID_FROM,
  RXFIELD_PARTNER_ADMIN_VALID_UNTIL, RXFIELD_PARTNER_ADMIN_CREATED_DATE,
  RXFIELD_PARTNER_ADMIN_VOUCHER_ID, RXFIELD_PARTNER_ADMIN_VOUCHER,
  RXFIELD_PARTNER_ADMIN_PURCHASED_BY, RXFIELD_PARTNER_ADMIN_CLAIMED_BY,
  RXFIELD_PARTNER_ADMIN_REVOKED_BY, RXFIELD_PARTNER_ADMIN_CODE,
  RXFIELD_PARTNER_ADMIN_IS_PURCHASED, approval, RXFIELD_PARTNER_ADMIN_IS_CLAIMED,
  RXFIELD_PARTNER_ADMIN_IS_REVOKED, RXFIELD_PARTNER_ADMIN_PURCHASED_ON,
  RXFIELD_PARTNER_ADMIN_PURCHASED_BY_ID, RXFIELD_PARTNER_ADMIN_REVOKED_ON,
  RXFIELD_PARTNER_ADMIN_REVOKED_BY_ID, RXFIELD_PARTNER_ADMIN_REVOCATION_NOTES,
  RXFIELD_PARTNER_ADMIN_CLAIM_NOTES, RXFIELD_PARTNER_ADMIN_CLAIMED_ON,
  RXFIELD_PARTNER_ADMIN_CLAIMED_BY_ID, RXFIELD_PARTNER_ADMIN_VOUCHER_IMAGE,
  FILTER_TYPE_TEXT, FILTER_TYPE_SWITCH,
  RXFIELD_PARTNER_ADMIN_VOUCHER_NAME, RXFIELD_PARTNER_ADMIN_MERCHANT_NAME,
  RXFORM_VOUCHER_TOKEN, RXSTATE_PARTNER_ADMIN_VOUCHER_TOKENS,
  RXSTATE_PARTNER_ADMIN_VOUCHER_TOKEN_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
  renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (
  initialValues,
) => {
  const hidePurchaseSubField = !initialValues.isPurchased
  || initialValues.isPurchased.value === false;
  const hideClaimSubField = !initialValues.isClaimed
  || initialValues.isClaimed.value === false;
  const hideRevokeSubField = !initialValues.isRevoked
  || initialValues.isRevoked.value === false;
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderCode}
            label={LocalizedString.partnerVoucherTokenPage.placeholderCode}
            disabled
            secureTextEntry
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_VALID_FROM}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderValidFrom}
            label={LocalizedString.partnerVoucherTokenPage.placeholderValidFrom}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_VALID_UNTIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderValidUntil}
            label={LocalizedString.partnerVoucherTokenPage.placeholderValidUntil}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_VOUCHER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderVoucherName}
            label={LocalizedString.partnerVoucherTokenPage.placeholderVoucherName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderMerchantName}
            label={LocalizedString.partnerVoucherTokenPage.placeholderMerchantName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_IS_PURCHASED}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderPurchased}
            label={LocalizedString.partnerVoucherTokenPage.placeholderPurchased}
            disabled
            data={approval}
            value={initialValues.isPurchased}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_PURCHASED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderPurchasedOn}
            label={LocalizedString.partnerVoucherTokenPage.placeholderPurchasedOn}
            disabled
            hidden={hidePurchaseSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_PURCHASED_BY_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderPurchasedById}
            label={LocalizedString.partnerVoucherTokenPage.placeholderPurchasedById}
            disabled
            hidden={hidePurchaseSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_PURCHASED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderPurchasedBy}
            label={LocalizedString.partnerVoucherTokenPage.placeholderPurchasedBy}
            disabled
            hidden={hidePurchaseSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_IS_REVOKED}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderRevoked}
            label={LocalizedString.partnerVoucherTokenPage.placeholderRevoked}
            disabled
            data={approval}
            value={initialValues.isRevoked}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_REVOKED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderRevokedOn}
            label={LocalizedString.partnerVoucherTokenPage.placeholderRevokedOn}
            disabled
            hidden={hideRevokeSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_REVOKED_BY_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderRevokedById}
            label={LocalizedString.partnerVoucherTokenPage.placeholderRevokedById}
            disabled
            hidden={hideRevokeSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_REVOKED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderRevokedBy}
            label={LocalizedString.partnerVoucherTokenPage.placeholderRevokedBy}
            disabled
            hidden={hideRevokeSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_REVOCATION_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderRevocationNotes}
            label={LocalizedString.partnerVoucherTokenPage.placeholderRevocationNotes}
            disabled
            hidden={hideRevokeSubField}
            multiline
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_IS_CLAIMED}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderClaimed}
            label={LocalizedString.partnerVoucherTokenPage.placeholderClaimed}
            disabled
            data={approval}
            value={initialValues.isClaimed}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_CLAIMED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderClaimedOn}
            label={LocalizedString.partnerVoucherTokenPage.placeholderClaimedOn}
            disabled
            hidden={hideClaimSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_CLAIMED_BY_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderClaimedById}
            label={LocalizedString.partnerVoucherTokenPage.placeholderClaimedById}
            disabled
            hidden={hideClaimSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_CLAIMED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderClaimedBy}
            label={LocalizedString.partnerVoucherTokenPage.placeholderClaimedBy}
            disabled
            hidden={hideClaimSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_CLAIM_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.partnerVoucherTokenPage.placeholderClaimNotes}
            label={LocalizedString.partnerVoucherTokenPage.placeholderClaimNotes}
            disabled
            hidden={hideClaimSubField}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PARTNER_ADMIN_VOUCHER_IMAGE}
            component={renderReduxFormImageInputField}
            label={LocalizedString.partnerVoucherTokenPage.placeholderVoucherImage}
            disabled
            defaultValue={initialValues.voucherImage}
            useFullWidthImage
            fullImageWidth="100%"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const partnerVoucherTokenPage = ({
  downloading,
  handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues,
  vouchers, loadingVoucher, onChangeVoucherText,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onDownloadPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_PARTNER_ADMIN_VOUCHER_TOKENS}
    uiPage={RXSTATE_PARTNER_ADMIN_VOUCHER_TOKEN_PAGE}
    filterColumns={[
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderValidFrom,
        field: RXFIELD_PARTNER_ADMIN_VALID_FROM,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderValidUntil,
        field: RXFIELD_PARTNER_ADMIN_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderCreatedDate,
        field: RXFIELD_PARTNER_ADMIN_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderPurchasedDate,
        field: RXFIELD_PARTNER_ADMIN_PURCHASED_ON,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderClaimedDate,
        field: RXFIELD_PARTNER_ADMIN_CLAIMED_ON,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderRevokedDate,
        field: RXFIELD_PARTNER_ADMIN_REVOKED_ON,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderVoucherId,
        field: RXFIELD_PARTNER_ADMIN_VOUCHER_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderVoucherName,
        field: RXFIELD_PARTNER_ADMIN_VOUCHER,
        type: FILTER_TYPE_DROPDOWN,
        data: vouchers,
        loading: loadingVoucher,
        onChangeFilterText: onChangeVoucherText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderPurchased,
        field: RXFIELD_PARTNER_ADMIN_IS_PURCHASED,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderClaimed,
        field: RXFIELD_PARTNER_ADMIN_IS_CLAIMED,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.placeholderRevoked,
        field: RXFIELD_PARTNER_ADMIN_IS_REVOKED,
        type: FILTER_TYPE_SWITCH,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    tableColumns={[
      {
        title: LocalizedString.partnerVoucherTokenPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.labelCode,
        field: 'code',
        sorting: !downloading,
        render: ({ code }) => {
          const firstFourDigit = code.slice(0, 4);
          const restOfDigitAsAsterisk = code.slice(4).replace(/./g, '*');
          return `${firstFourDigit}${restOfDigitAsAsterisk}`;
        },
      },
      { title: LocalizedString.partnerVoucherTokenPage.labelVoucherName, field: 'voucher.title', sorting: !downloading },
      { title: LocalizedString.partnerVoucherTokenPage.labelMerchantName, field: 'voucher.merchant.name', sorting: !downloading },
      {
        title: LocalizedString.partnerVoucherTokenPage.labelValidFrom,
        field: 'validFrom',
        sorting: !downloading,
        render: ({ validFrom }) => (validFrom
          ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.partnerVoucherTokenPage.labelValidUntil,
        field: 'validUntil',
        sorting: !downloading,
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.partnerVoucherTokenPage.labelPurchased, field: 'isPurchased', sorting: !downloading },
      { title: LocalizedString.partnerVoucherTokenPage.labelClaimed, field: 'isClaimed', sorting: !downloading },
      { title: LocalizedString.partnerVoucherTokenPage.labelRevoked, field: 'isRevoked', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onDownloadPressed={onDownloadPressed}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.partnerVoucherTokenPage.title}
    useFullWidth
    disableCreate
    disableEdit
    disableDelete
    enableSave
    rowStyle={({
      isClaimed, isRevoked, validFrom, validUntil,
    }) => {
      const notValidYet = toMoment().isBefore(validFrom);
      const alreadyInvalid = toMoment().isAfter(validUntil);
      return ({
        backgroundColor: isClaimed || isRevoked || notValidYet || alreadyInvalid
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      });
    }}
  >
    {renderDialogContent(
      initialValues,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_VOUCHER_TOKEN,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(partnerVoucherTokenPage);

partnerVoucherTokenPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
};
