import {
  revokingVoucherToken, setAlertErrorMessage, setVoucherTokenSelectedOrderBy,
  setVoucherTokenSelectedPageSize, setVoucherTokenTappedId,
} from '../simple-action';
import { INITIAL_ORDER_BY_VOUCHER_TOKENS } from '../../../constant';
import { revokeVoucherToken } from '../../../helper';
import downloadVoucherTokensAsync from './downloadVoucherTokensAsync';

export default (reason) => async (dispatch, getState) => {
  try {
    dispatch(revokingVoucherToken(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiVoucherToken;

    await revokeVoucherToken(tappedId, reason, token);

    dispatch(setVoucherTokenTappedId(''));
    dispatch(setVoucherTokenSelectedPageSize(20));
    dispatch(setVoucherTokenSelectedOrderBy(INITIAL_ORDER_BY_VOUCHER_TOKENS));
    dispatch(downloadVoucherTokensAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  } finally {
    dispatch(revokingVoucherToken(false));
  }
};
