import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_WASTE_COLLECTIONS = '/ewaste/api/v1/WasteCollection?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_WASTE_COLLECTION = '/ewaste/api/v1/WasteCollection/{id}';
export const REST_URL_DEALER_CODES = '/ewaste/api/v1/DealerCode?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_WASTE_DEALER_CODE = '/ewaste/api/v1/DealerCode/{id}';
export const REST_URL_ADD_EDIT_WASTE_DEALER_CODE = '/ewaste/api/v1/DealerCode';
export const REST_URL_DELETE_WASTE_DEALER_CODE = '/ewaste/api/v1/DealerCode/{id}';

export const ROUTE_NAME_E_WASTE = '/e-waste';
export const ROUTE_NAME_WASTE_DEALER_CODE = '/dealer-code';
export const ROUTE_NAME_WASTE_COLLECTION = '/waste-collection';

export const MENUID_E_WASTE = 'MENUID_E_WASTE';
export const MENUID_E_WASTE_DEALER_CODE = 'MENUID_E_WASTE_DEALER_CODE';
export const MENUID_E_WASTE_WASTE_COLLECTION = 'MENUID_E_WASTE_WASTE_COLLECTION';

export const RXSTATE_WASTE_COLLECTIONS = 'wasteCollections';
export const RXSTATE_WASTE_COLLECTION_PAGE = 'uiWasteCollection';

export const RXSTATE_WASTE_DEALER_CODES = 'wasteDealerCodes';
export const RXSTATE_WASTE_DEALER_CODE_PAGE = 'uiWasteDealerCode';

export const INITIAL_ORDER_BY_WASTE_COLLECTIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_WASTE_DEALER_CODE = `createdDate ${ORDER_SORT_DESCENDING}`;

export const RXFIELD_WASTE_COLLECTION_CREATED_DATE = 'createdDate';
export const RXFIELD_WASTE_COLLECTION_DEALER = 'dealer';
export const RXFIELD_WASTE_COLLECTION_PROFILE = 'user';
export const RXFIELD_WASTE_COLLECTION_ACTIVITY_STATUS = 'activityStatus';
export const RXFIELD_WASTE_COLLECTION_USER_ID = 'profile.id';
export const RXFIELD_WASTE_COLLECTION_USER_NAME = 'profile.fullName';
export const RXFIELD_WASTE_COLLECTION_FIRST_NAME = 'firstName';
export const RXFIELD_WASTE_COLLECTION_LAST_NAME = 'lastName';
export const RXFIELD_WASTE_COLLECTION_GENDER = 'gender';
export const RXFIELD_WASTE_COLLECTION_EMAIL = 'email';
export const RXFIELD_WASTE_COLLECTION_PHONE = 'phone';
export const RXFIELD_WASTE_COLLECTION_RECEIVE_NEWS_UPDATE = 'wantToReceiveNewsUpdate';
export const RXFIELD_WASTE_COLLECTION_DEALER_ID = 'dealer.id';
export const RXFIELD_WASTE_COLLECTION_DEALER_NAME = 'dealer.name';
export const RXFIELD_WASTE_COLLECTION_UNIQUE_CODE = 'uniqueCode';
export const RXFIELD_WASTE_COLLECTION_COMPLATED_TIME = 'completedTime';
export const RXFIELD_WASTE_COLLECTION_IMAGE = 'image';

export const RXFIELD_WASTE_DEALER_CODE_DEALER = 'dealer';
export const RXFIELD_WASTE_DEALER_CODE_DEALER_ID = 'dealer.id';
export const RXFIELD_WASTE_DEALER_CODE_DEALER_CODE = 'code';
export const RXFIELD_WASTE_DEALER_CODE_DEALER_NAME = 'dealer.name';

export const RXFORM_WASTE_COLLECTION = 'wasteCollectionPage';
export const RXFORM_WASTE_DEALER_CODE = 'wasteDealerCodePage';

export const ACTIVITY_STATUS = ['Submitted', 'Finished'];
