
import { downloadGiftVoucherRedemption } from '../../../helper';
import {
  addGiftVoucherRedemption, downloadingDeletingGiftVoucherRedemption,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingGiftVoucherRedemption(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiGiftVoucherRedemption;

    const category = await downloadGiftVoucherRedemption(tappedId, token);
    dispatch(addGiftVoucherRedemption(category));
  } finally {
    dispatch(downloadingDeletingGiftVoucherRedemption(false));
  }
};
