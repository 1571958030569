import { connect } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';
import {
  clearMembershipRewards, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setMembershipRewardSearchText, setMembershipRewardSelectedPageSize,
  setMembershipRewardSelectedOrderBy, setMembershipRewardTappedId,
  downloadMembershipRewardAsync, downloadMembershipRewardsAsync,
  setUserAdvancedFilterDialogSelectedFilterString, setUserSearchText, clearUsers,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_MEMBERSHIP_REWARDS,
  MENUID_CAR_REGISTRATION_MEMBERSHIP_REWARD,
  PAGE_MODE_TABLE, RXFIELD_MEMBERSHIP_REWARD_STOPPED_AT, RXFORM_MEMBERSHIP_REWARD,
} from '../../constant';
import {
  toCurrency, toMoment, transformInitialValues,
  sortAsc, transformUserDropdownData,
} from '../../helper';
import MembershipRewardPage from './membership-reward.presentation';
import { downloadUsersAsync } from '../../../../redux/action';

const transformDate = (date) => (date ? toMoment(date).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
  : null);

const getInitialValues = (state) => {
  const { membershipRewards, uiFunctionalPage, uiMembershipReward } = state;
  const { data } = membershipRewards;
  const {
    downloadingDeleting, tappedId,
  } = uiMembershipReward;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    rewardAmount: toCurrency(found.rewardAmount),
    stoppedAt: transformDate(found.stoppedAt),
    items: Object.values(found.items).length > 0 && !downloadingDeleting
      ? Object.values(found.items).sort((a, b) => sortAsc(a.scheduledDate, b.scheduledDate))
        .map((x, i) => ({
          ...x,
          no: i + 1,
          recipient: {
            ...x.recipient,
          },
          amount: toCurrency(x.amount),
          scheduledDate: transformDate(x.scheduledDate),
          actualDate: transformDate(x.actualDate),
          type: x.type,
        })) : [],
  }) : { items: [] };
  return result;
};

const selector = formValueSelector(RXFORM_MEMBERSHIP_REWARD);

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  downloading: state.uiMembershipReward.downloading,
  downloadingDeleting: state.uiMembershipReward.downloadingDeleting,
  loadingUser: state.uiUser.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  totalCurrentPage: state.membershipRewards.meta.currentPage,
  totalCount: state.membershipRewards.meta.totalCount,
  isStopped: selector(state, RXFIELD_MEMBERSHIP_REWARD_STOPPED_AT),
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_REGISTRATION_MEMBERSHIP_REWARD));
    dispatch(setMembershipRewardSearchText(''));
    dispatch(clearMembershipRewards());
    dispatch(setMembershipRewardSelectedPageSize(20));
    dispatch(setMembershipRewardSelectedOrderBy(INITIAL_ORDER_BY_MEMBERSHIP_REWARDS));
    dispatch(downloadMembershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/profile/, 'profile.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearMembershipRewards());
    dispatch(downloadMembershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_MEMBERSHIP_REWARD));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadMembershipRewardsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setMembershipRewardSelectedPageSize(pageSize));
    dispatch(downloadMembershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setMembershipRewardSelectedPageSize(pageSize));
    dispatch(clearMembershipRewards());
    dispatch(downloadMembershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearMembershipRewards());
    dispatch(setMembershipRewardSelectedOrderBy(INITIAL_ORDER_BY_MEMBERSHIP_REWARDS));
    dispatch(downloadMembershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setMembershipRewardSearchText(text));
      dispatch(clearMembershipRewards());
      await dispatch(downloadMembershipRewardsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setMembershipRewardSelectedOrderBy(orderBy));
    dispatch(clearMembershipRewards());
    dispatch(downloadMembershipRewardsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setMembershipRewardTappedId(id));
    dispatch(downloadMembershipRewardAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipRewardPage);
