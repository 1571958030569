import { downloadHyundaiChallengeTransaction } from '../../../helper';
import { addHyundaiChallengeTransaction, downloadingHyundaiChallengeTransaction } from '../simple-action';


export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingHyundaiChallengeTransaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiHyundaiChallengeTransaction;

    const transaction = await downloadHyundaiChallengeTransaction(tappedId, token);
    dispatch(addHyundaiChallengeTransaction(transaction));
  } finally {
    dispatch(downloadingHyundaiChallengeTransaction(false));
  }
};
