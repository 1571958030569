import { connect } from 'react-redux';
import DealerHeader from './dealer-header.presentation';

const mapStateToProps = (state, ownProps) => ({
  downloading: ownProps?.downloading && true,
  dealerDetail: ownProps?.dealerDetail || {},
  paymentStatus: ownProps?.paymentStatus || '',
  bookingCode: ownProps?.bookingCode || '',
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DealerHeader);
