import { downloadingMyConfigItems, setMyConfigItems } from '../simple-action';
import { downloadMyConfigItems } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;

  try {
    dispatch(downloadingMyConfigItems(true));

    const list = await downloadMyConfigItems(token);

    if (list) {
      dispatch(setMyConfigItems(list));
    }
  } finally {
    dispatch(downloadingMyConfigItems(false));
  }
};
