
import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    partnerAdminMenu: {
      title: 'Partner Admin',
    },
    partnerVoucherTokenPage: {
      title: 'Partner Voucher Token',

      labelNo: 'No',
      labelCode: 'Code',
      labelVoucherName: 'Voucher Name',
      labelMerchantName: 'Merchant Name',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelPurchased: 'Purchased',
      labelRevoked: 'Revoked',
      labelClaimed: 'Claimed',

      placeholderCode: 'Code',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderPurchased: 'Purchased',
      placeholderPurchasedOn: 'Purchased On',
      placeholderPurchasedBy: 'Purchased By',
      placeholderPurchasedById: 'Purchased By ID',
      placeholderRevoked: 'Revoked',
      placeholderRevokedOn: 'Revoked On',
      placeholderRevokedBy: 'Revoked By',
      placeholderRevokedById: 'Revoked by ID',
      placeholderRevocationNotes: 'Revocation Notes',
      placeholderVoucherName: 'Voucher Name',
      placeholderMerchantName: 'Merchant Name',
      placeholderClaimed: 'Claimed',
      placeholderClaimedOn: 'Claimed On',
      placeholderClaimedBy: 'Claimed By',
      placeholderClaimedById: 'Claimed By ID',
      placeholderClaimNotes: 'Claim Notes',
      placeholderCreatedDate: 'Created Date',
      placeholderVoucherId: 'Voucher ID',
      placeholderVoucherImage: 'Voucher Image',
      placeholderPurchasedDate: 'Purchased Date',
      placeholderClaimedDate: 'Claimed Date',
      placeholderRevokedDate: 'Revoked Date',

      buttonCaptionRevoke: 'Revoke',

      msgRevokeConfirmation: 'Are you sure you want to revoke this voucher?',
    },
    voucherClaimPage: {
      title: 'Voucher Claim',

      labelVoucherCode: 'Voucher Code',
      labelVoucherName: 'Voucher Name',
      labelMerchant: 'Merchant',
      labelValidDate: 'Valid Date',
      labelCustomer: 'Customer',
      labelRedeemedOn: 'Redeemed on',
      labelIsClaimed: 'Is Claimed',
      labelClaimedBy: 'Claimed By',
      labelClaimedOn: 'Claimed On',
      labelClaimNotes: 'Claim Notes',
      labelIsRevoked: 'Is Revoked',
      labelRevokedBy: 'Revoked By',
      labelRevokedOn: 'Revoked On',
      labelRevocationNotes: 'Revocation Notes',
      labelNotes: 'Notes',
      labelSearch: 'Search',

      buttonCaptionSearch: 'Search',
      buttonCaptionClaimVoucher: 'Claim Voucher',
    },
  },
  id: {
    partnerAdminMenu: {
      title: 'Admin Mitra',
    },
    partnerVoucherTokenPage: {
      title: 'Token Voucher Mitra',

      labelNo: 'No',
      labelCode: 'Kode',
      labelVoucherName: 'Nama Voucher',
      labelMerchantName: 'Nama Penjual',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelPurchased: 'Dibeli',
      labelRevoked: 'Dicabut',
      labelClaimed: 'Diklaim',

      placeholderCode: 'Kode',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderPurchased: 'Dibeli',
      placeholderPurchasedOn: 'Dibeli Pada',
      placeholderPurchasedBy: 'Dibeli Oleh',
      placeholderPurchasedById: 'Dibeli Oleh ID',
      placeholderRevoked: 'Dicabut',
      placeholderRevokedOn: 'Dicabut Pada',
      placeholderRevokedBy: 'Dicabut Oleh',
      placeholderRevokedById: 'Dicabut Oleh ID',
      placeholderRevocationNotes: 'Catatan Pencabutan',
      placeholderVoucherName: 'Nama Voucher',
      placeholderMerchantName: 'Nama Penjual',
      placeholderClaimed: 'Diklaim',
      placeholderClaimedOn: 'Diklaim Pada',
      placeholderClaimedBy: 'Diklaim Oleh',
      placeholderClaimedById: 'Diklaim Oleh ID',
      placeholderClaimNotes: 'Catatan Klaim',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderVoucherId: 'ID Voucher',
      placeholderVoucherImage: 'Gambar Voucher',
      placeholderPurchasedDate: 'Tanggal Pembelian',
      placeholderClaimedDate: 'Tanggal Diklaim',
      placeholderRevokedDate: 'Tanggal Dicabut',

      buttonCaptionRevoke: 'Cabut',

      msgRevokeConfirmation: 'Apa Anda yakin ingin mencabut voucher ini?',
    },
    voucherClaimPage: {
      title: 'Klaim Voucher',

      labelVoucherCode: 'Kode Voucher',
      labelVoucherName: 'Nama Voucher',
      labelMerchant: 'Penjual',
      labelValidDate: 'Tanggal Berlaku',
      labelCustomer: 'Pelanggan',
      labelRedeemedOn: 'Ditebus Pada',
      labelIsClaimed: 'Diklaim',
      labelClaimedBy: 'Diklaim Oleh',
      labelClaimedOn: 'Diklaim Pada',
      labelClaimNotes: 'Catatan Klaim',
      labelIsRevoked: 'Dicabut',
      labelRevokedBy: 'Dicabut Oleh',
      labelRevokedOn: 'Dicabut Pada',
      labelRevocationNotes: 'Catatan Pencabutan',
      labelNotes: 'Catatan',
      labelSearch: 'Cari',

      buttonCaptionSearch: 'Cari',
      buttonCaptionClaimVoucher: 'Klaim Voucher',
    },
  },
});

export default LocalizedString;
