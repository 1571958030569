import { savingGiias2022Transactions } from '../simple-action';
import { saveGiias2022Transactions, transformSearchText } from '../../../helper';
import '../../../../../component/functional-page/download-file.css';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { searchBarText, orderBy, filterString } = getState().uiGiias2022Transaction;

  try {
    dispatch(savingGiias2022Transactions(true));

    const response = await saveGiias2022Transactions(
      1,
      20,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'giias-2022-transaction.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(savingGiias2022Transactions(false));
  }
};
