import { connect } from 'react-redux';
import { reset, change, formValueSelector } from 'redux-form';
import {
  DIALOG_TYPE_ADD, INITIAL_ORDER_BY_CONFIG_ITEMS, MENUID_CONFIGURATION_CONFIGITEM, PAGE_MODE_TABLE,
  RXFIELD_CONFIG_ITEM_ALLOW_OVERRIDE, RXFIELD_CONFIG_ITEM_DATA_TYPE, RXFIELD_CONFIG_ITEM_ENCRYPTED,
  RXFIELD_CONFIG_ITEM_NEED_RESTART, RXFIELD_CONFIG_ITEM_USED_ON, RXFORM_CONFIG_ITEM,
  RXFIELD_CONFIG_ITEM_MULTILINE,
} from '../../constant';
import { transformDropdownData, transformInitialValues } from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  clearConfigGroups, clearConfigItems, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setConfigGroupSearchText, setConfigItemDuplicateMode, setConfigItemSearchText,
  setConfigItemSelectedPageSize, setConfigItemSelectedOrderBy, setConfigItemSelectedDataType,
  setConfigItemSelectedUsedOn, setConfigItemSelectedConfigGroup, setConfigItemTappedId,
  setDialogVisibility,
  addEditConfigItemAsync, downloadConfigGroupsAsync, downloadConfigItemsAsync,
  downloadDeleteConfigItemAsync,
} from '../../redux/action';
import ConfigItemPage from './config-item.presentation';

const getInitialValues = (state) => {
  const { configItems, uiFunctionalPage, uiConfigItem } = state;
  const { data } = configItems;
  const { downloadingDeleting, duplicateMode, tappedId } = uiConfigItem;
  const { pageMode } = uiFunctionalPage;

  const found = (pageMode !== PAGE_MODE_TABLE && !downloadingDeleting) || duplicateMode
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    configGroup: { label: found.configGroup.name, value: found.configGroup.id },
  }) : {
    order: 0,
    name: '',
    value: '',
    label: '',
    description: '',
    dataType: '',
    usedOn: '',
    allowOverride: false,
    encrypted: false,
    needRestart: false,
    configGroup: '',
    configSection: '',
    multiLine: '',
  };
  return result;
};

const selector = formValueSelector(RXFORM_CONFIG_ITEM);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiConfigItem.addingEditing,
  downloading: state.uiConfigItem.downloading,
  loadingConfigGroups: state.uiConfigGroup.downloading,
  configGroups: transformDropdownData(state.configGroups.data),
  initialValues: getInitialValues(state),
  encrypted: state.form.configItemPage ? state.form.configItemPage.values.encrypted
    : getInitialValues(state).encrypted,
  selectedDataType: state.uiConfigItem.selectedDataType,
  tappedId: state.uiFunctionalPage.tappedId || '',
  isMultiline: selector(state, RXFIELD_CONFIG_ITEM_MULTILINE) === 'true'
  || selector(state, RXFIELD_CONFIG_ITEM_MULTILINE) === true,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setConfigItemSelectedConfigGroup(''));
    dispatch(setConfigGroupSearchText(''));
    dispatch(clearConfigGroups());
    dispatch(downloadConfigGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAllowOverrideOptionSelected: (option) => {
    dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_ALLOW_OVERRIDE, option));
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CONFIGURATION_CONFIGITEM));
    dispatch(setConfigItemSearchText(''));
    dispatch(clearConfigItems());
    dispatch(setConfigItemSelectedPageSize(20));
    dispatch(setConfigItemSelectedOrderBy(INITIAL_ORDER_BY_CONFIG_ITEMS));
    dispatch(downloadConfigItemsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/configGroup/, 'configGroup.name');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearConfigItems());
    dispatch(downloadConfigItemsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setConfigItemSelectedDataType(''));
    dispatch(setConfigItemSelectedConfigGroup(''));
    dispatch(reset(RXFORM_CONFIG_ITEM));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CONFIG_ITEM));
    dispatch(setConfigItemDuplicateMode(false));
  },
  onChangeConfigGroupText: async (text) => {
    try {
      dispatch(setConfigGroupSearchText(text));
      dispatch(clearConfigGroups());
      await dispatch(downloadConfigGroupsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadConfigItemsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setConfigItemSelectedPageSize(pageSize));
    dispatch(downloadConfigItemsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfigGroupsOptionSelected: (option) => {
    if (option) {
      dispatch(setConfigItemSelectedConfigGroup(option.value));
    } else {
      dispatch(setConfigItemSelectedConfigGroup(''));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteConfigItemAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setConfigItemTappedId(''));
    dispatch(setConfigGroupSearchText(''));
    dispatch(clearConfigGroups());
    dispatch(downloadConfigGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDataTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setConfigItemSelectedDataType(option));
      dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_DATA_TYPE, option));
    } else {
      dispatch(setConfigItemSelectedDataType(''));
      dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_DATA_TYPE, ''));
    }
  },
  onDeletePressed: (id) => {
    dispatch(setConfigItemTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDuplicatePressed: () => {
    dispatch(setDialogVisibility(true, DIALOG_TYPE_ADD,
      LocalizedString.configItemScreen.buttonCaptionDuplicateConfigItem));
    dispatch(setConfigItemDuplicateMode(true));
  },
  onEditPressed: (id) => {
    dispatch(setConfigItemTappedId(id));
    dispatch(setConfigGroupSearchText(''));
    dispatch(clearConfigGroups());
    dispatch(downloadConfigGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEncryptedOptionSelected: (option) => {
    dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_ENCRYPTED, option));
  },
  onNeedRestartOptionSelected: (option) => {
    dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_NEED_RESTART, option));
  },
  onRefresh: (pageSize) => {
    dispatch(setConfigItemSelectedPageSize(pageSize));
    dispatch(clearConfigItems());
    dispatch(downloadConfigItemsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearConfigItems());
    dispatch(setConfigItemSelectedOrderBy(INITIAL_ORDER_BY_CONFIG_ITEMS));
    dispatch(downloadConfigItemsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    order, name, value, label, description, allowOverride,
    encrypted, needRestart, configSection, enumOptions,
    multiLine,
  }) => {
    try {
      await dispatch(addEditConfigItemAsync(parseInt(order, 10), name, value, label, description,
        allowOverride, encrypted, needRestart, configSection, enumOptions, multiLine));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setConfigItemSearchText(text));
      dispatch(clearConfigItems());
      await dispatch(downloadConfigItemsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setConfigItemSelectedOrderBy(orderBy));
    dispatch(clearConfigItems());
    dispatch(downloadConfigItemsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    order, name, value, label, description, allowOverride,
    encrypted, needRestart, configSection, enumOptions,
    multiLine,
  }) => {
    await dispatch(addEditConfigItemAsync(parseInt(order, 10), name, value, label, description,
      allowOverride, encrypted, needRestart, configSection, enumOptions, multiLine));
  },
  onUsedOnOptionSelected: (option) => {
    if (option) {
      dispatch(setConfigItemSelectedUsedOn(option));
      dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_USED_ON, option));
    } else {
      dispatch(setConfigItemSelectedUsedOn(''));
      dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_USED_ON, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setConfigItemTappedId(id));
  },
  onMultiLineSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_MULTILINE, option));
    } else {
      dispatch(change(RXFORM_CONFIG_ITEM, RXFIELD_CONFIG_ITEM_MULTILINE, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigItemPage);
