import { downloadIims2023Spks, transformSearchText } from '../../../helper';
import { downloadingIims2023Spks, setIims2023Spks } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, iims2023Spks, uiIims2023Spk } = getState();

  const { token } = authentication;
  const { meta } = iims2023Spks;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiIims2023Spk;

  try {
    dispatch(downloadingIims2023Spks(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;

    const spks = await downloadIims2023Spks(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    dispatch(setIims2023Spks(spks));
  } finally {
    dispatch(downloadingIims2023Spks(false));
  }
};
