import {
  ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING, TAG_WHITE, TAG_BLUE, TAG_BLUE_PLUS,
} from '../../constant';
import LocalizedString from './localization';

export * from '../../constant';

export const REST_URL_VOUCHER_CATEGORIES = '/voucher/api/v1/VoucherCategory?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_VOUCHER_CATEGORY = '/voucher/api/v1/VoucherCategory';
export const REST_URL_VIEW_DELETE_VOUCHER_CATEGORY = '/voucher/api/v1/VoucherCategory/{id}';
export const REST_URL_VOUCHERS = '/voucher/api/v1/Voucher?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_VOUCHER = '/voucher/api/v1/Voucher';
export const REST_URL_VIEW_DELETE_VOUCHER = '/voucher/api/v1/Voucher/{id}';
export const REST_URL_ENABLE_DISABLE_VOUCHER = '/voucher/api/v1/Voucher/Status';
export const REST_URL_TOKEN_REQUESTS = '/voucher/api/v1/VoucherTokenRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_TOKEN_REQUEST = '/voucher/api/v1/VoucherTokenRequest/{id}';
export const REST_URL_ADD_TOKEN_REQUEST = '/voucher/api/v1/VoucherTokenRequest';
export const REST_URL_VOUCHER_TOKENS = '/voucher/api/v1/VoucherToken?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_VOUCHER_TOKEN = '/voucher/api/v1/VoucherToken/{id}';
export const REST_URL_REVOKE_VOUCHER_TOKEN = '/voucher/api/v1/RevokeVoucherToken';
export const REST_URL_MOVE_VOUCHER_TOKENS = '/voucher/api/v1/MoveVoucherToken';
export const REST_URL_CANCEL_TOKEN_REQUEST = '/voucher/api/v1/VoucherTokenRequestCancel/{id}';
export const REST_URL_TOKEN_IMPORTS = '/voucher/api/v1/VoucherTokenImport?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_DELETE_TOKEN_IMPORT = '/voucher/api/v1/VoucherTokenImport/{id}';
export const REST_URL_IMPORT_EDIT_TOKEN_IMPORT = '/voucher/api/v1/VoucherTokenImport';
export const REST_URL_RETRY_IMPORT_TOKEN = '/voucher/api/v1/VoucherTokenImport/Retry/{id}';
export const REST_URL_SAVE_TOKEN_IMPORT = '/voucher/api/v1/VoucherTokenImport/Download?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VOUCHER_PURCHASE_REQUESTS = '/voucher/api/v1/PurchaseRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_VOUCHER_PURCHASE_REQUEST = '/voucher/api/v1/PurchaseRequest/{id}';
export const REST_URL_PROMOTED_MERCHANTS = '/voucher/api/v1/Merchant?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_PROMOTED_MERCHANT = '/voucher/api/v1/Merchant/{id}';
export const REST_URL_EDIT_PROMOTED_MERCHANT = '/voucher/api/v1/Merchant';
export const REST_URL_GARUDA_AIRPORTS = '/voucher/api/v1/Garuda/Airport?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_ADD_EDIT_GARUDA_AIRPORT = '/voucher/api/v1/Garuda/Airport';
export const REST_URL_VIEW_DELETE_GARUDA_AIRPORT = '/voucher/api/v1/Garuda/Airport/{id}';
export const REST_URL_COUNT_VOUCHER_TOKEN = '/voucher/api/v1/VoucherToken?pageSize=1&filterString=remark={remark}';
export const REST_URL_MAP_TOKEN_TO_AIRPORT = '/voucher/api/v1/Garuda/MapTokenAirport';
export const REST_URL_VOUCHER_TOKEN_ASSIGNMENTS = '/voucher/api/v1/VoucherTokenAssignment?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&searchStringIncludedFields=voucher.title&filterString=';
export const REST_URL_ADD_EDIT_VOUCHER_TOKEN_ASSIGNMENT = '/voucher/api/v1/VoucherTokenAssignment';
export const REST_URL_VIEW_VOUCHER_TOKEN_ASSIGNMENT = '/voucher/api/v1/VoucherTokenAssignment/{id}';
export const REST_URL_VOUCHER_TOKEN_ASSIGNMENT_STATUS = '/voucher/api/v1/VoucherTokenAssignment/Status';
export const REST_URL_GIFT_VOUCHER_REDEMPTIONS = '/voucher/api/v1/VoucherTokenAssignmentRedemption?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status%3Denabled';
export const REST_URL_VIEW_GIFT_VOUCHER_REDEMPTION = '/voucher/api/v1/VoucherTokenAssignmentRedemption/{id}';
export const REST_URL_VOUCHER_USAGES = '/voucher/api/v1/VoucherUsage?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';

export const ROUTE_NAME_VOUCHER = '/voucher';
export const ROUTE_NAME_VOUCHER_CATEGORY = '/voucher-category';
export const ROUTE_NAME_VOUCHER_VOUCHER = '/voucher-voucher';
export const ROUTE_NAME_VOUCHER_TOKEN_REQUEST = '/voucher-token-request';
export const ROUTE_NAME_VOUCHER_TOKEN = '/voucher-token';
export const ROUTE_NAME_VOUCHER_TOKEN_IMPORT = '/voucher-token-import';
export const ROUTE_NAME_VOUCHER_PURCHASE_REQUEST = '/voucher-purchase-request';
export const ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT = '/voucher-promoted-merchant';
export const ROUTE_NAME_VOUCHER_GARUDA_AIRPORT = '/voucher-garuda-airport';
export const ROUTE_NAME_VOUCHER_TOKEN_ASSIGNMENT = '/voucher-token-assignment';
export const ROUTE_NAME_VOUCHER_GIFT_VOUCHER_REDEMPTION = '/voucher-gift-voucher-redemption';
export const ROUTE_NAME_VOUCHER_USAGE = '/voucher-usage';

export const RXFORM_VOUCHER_CATEGORY = 'voucherCategory';
export const RXFORM_VOUCHER = 'voucher';
export const RXFORM_TOKEN_REQUEST = 'tokenRequest';
export const RXFORM_VOUCHER_TOKEN = 'voucherToken';
export const RXFORM_TOKEN_IMPORT = 'tokenImport';
export const RXFORM_VOUCHER_PURCHASE_REQUEST = 'voucherPurchaseRequest';
export const RXFORM_PROMOTED_MERCHANT = 'promotedMerchant';
export const RXFORM_GARUDA_AIRPORT = 'garudaAirport';
export const RXFORM_MAP_TOKENS_DIALOG = 'mapTokensDialog';
export const RXFORM_VOUCHER_TOKEN_ASSIGNMENT = 'voucherTokenAssignment';
export const RXFORM_GIFT_VOUCHER_REDEMPTION = 'giftVoucherRedemtion';
export const RXFORM_VOUCHER_USAGE = 'voucherUsage';

export const RXFIELD_VOUCHER_CATEGORY_NAME = 'name';
export const RXFIELD_VOUCHER_CATEGORY_DESCRIPTION = 'description';
export const RXFIELD_VOUCHER_CATEGORY_IMAGE = 'image';
export const RXFIELD_VOUCHER_TITLE = 'title';
export const RXFIELD_VOUCHER_CODE = 'code';
export const RXFIELD_VOUCHER_TYPE = 'type';
export const RXFIELD_VOUCHER_POINT = 'point';
export const RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER = 'maxPurchasePerUser';
export const RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE = 'minAchievementPoinToPurchase';
export const RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE = 'maxAchievementPoinToPurchase';
export const RXFIELD_VOUCHER_ACTION_TYPE = 'actionType';
export const RXFIELD_VOUCHER_VISIBILITY = 'visibility';
export const RXFIELD_VOUCHER_MERCHANT = 'merchant';
export const RXFIELD_VOUCHER_GARUDA_FLAG = 'garudaFlag';
export const RXFIELD_VOUCHER_APPLICABLE_AREA_MODE = 'applicableAreaMode';
export const RXFIELD_VOUCHER_APPLICABLE_BRANCHES = 'applicableBranches';
export const RXFIELD_VOUCHER_CATEGORY = 'category';
export const RXFIELD_VOUCHER_VALID_FROM = 'validFrom';
export const RXFIELD_VOUCHER_VALID_UNTIL = 'validUntil';
export const RXFIELD_VOUCHER_IMAGE = 'image';
export const RXFIELD_VOUCHER_DESCRIPTION = 'description';
export const RXFIELD_VOUCHER_TERMS_AND_CONDITIONS = 'termsAndConditions';
export const RXFIELD_VOUCHER_STATUS = 'status';
export const RXFIELD_VOUCHER_DEEP_LINK = 'deepLink';
export const RXFIELD_VOUCHER_IS_PROMOTED = 'isPromoted';
export const RXFIELD_VOUCHER_ORDER = 'order';
export const RXFIELD_VOUCHER_TAGS = 'tags';
export const RXFIELD_VOUCHER_MANUAL_CLAIM_MESSAGE = 'manualClaimMessage';
export const RXFIELD_VOUCHER_PAYMENT_TYPE = 'paymentType';
export const RXFIELD_VOUCHER_TANAMERA_FLAG = 'tanameraFlag';
export const RXFIELD_VOUCHER_MONETARY_VALUE = 'monetaryValue';
export const RXFIELD_TOKEN_REQUEST_VOUCHER = 'voucher';
export const RXFIELD_TOKEN_REQUEST_QUANTITY = 'quantity';
export const RXFIELD_TOKEN_REQUEST_VALID_FROM = 'validFrom';
export const RXFIELD_TOKEN_REQUEST_VALID_UNTIL = 'validUntil';
export const RXFIELD_TOKEN_REQUEST_TASK_STATUS = 'taskStatus';
export const RXFIELD_TOKEN_REQUEST_PROGRESS = 'progress';
export const RXFIELD_TOKEN_REQUEST_PROGRESS_NOTES = 'progressNotes';
export const RXFIELD_TOKEN_REQUEST_START_TIME = 'startTime';
export const RXFIELD_TOKEN_REQUEST_END_TIME = 'endTime';
export const RXFIELD_TOKEN_REQUEST_MERCHANT = 'merchant';
export const RXFIELD_TOKEN_REQUEST_VOUCHER_ID = 'voucherId';
export const RXFIELD_TOKEN_REQUEST_VOUCHER_NAME = 'voucherName';
export const RXFIELD_VOUCHER_TOKEN_CODE = 'code';
export const RXFIELD_VOUCHER_TOKEN_VALID_FROM = 'validFrom';
export const RXFIELD_VOUCHER_TOKEN_VALID_UNTIL = 'validUntil';
export const RXFIELD_VOUCHER_TOKEN_IS_PURCHASED = 'isPurchased';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_ON = 'purchasedOn';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_BY = 'purchasedBy';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_BY_ID = 'purchasedById';
export const RXFIELD_VOUCHER_TOKEN_IS_REVOKED = 'isRevoked';
export const RXFIELD_VOUCHER_TOKEN_REVOKED_ON = 'revokedOn';
export const RXFIELD_VOUCHER_TOKEN_REVOKED_BY = 'revokedBy';
export const RXFIELD_VOUCHER_TOKEN_REVOKED_BY_ID = 'revokedById';
export const RXFIELD_VOUCHER_TOKEN_REVOCATION_NOTES = 'revocationNotes';
export const RXFIELD_VOUCHER_TOKEN_REMARK = 'remark';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER = 'selectedVoucher';
export const RXFIELD_VOUCHER_TOKEN_MERCHANT = 'merchant';
export const RXFIELD_VOUCHER_TOKEN_IS_CLAIMED = 'isClaimed';
export const RXFIELD_VOUCHER_TOKEN_CLAIMED_ON = 'claimedOn';
export const RXFIELD_VOUCHER_TOKEN_CLAIMED_BY = 'claimedBy';
export const RXFIELD_VOUCHER_TOKEN_CLAIMED_BY_ID = 'claimedById';
export const RXFIELD_VOUCHER_TOKEN_CLAIM_NOTES = 'claimNotes';
export const RXFIELD_VOUCHER_TOKEN_CREATED_DATE = 'createdDate';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER_ID = 'voucherId';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER_IMAGE = 'voucherImage';
export const RXFIELD_VOUCHER_TOKEN_VOUCHER_NAME = 'voucherName';
export const RXFIELD_VOUCHER_TOKEN_MERCHANT_NAME = 'merchantName';
export const RXFIELD_VOUCHER_TOKEN_GARUDA_AIRPORT = 'garudaAirport';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_POINT = 'purchasedPoint';
export const RXFIELD_VOUCHER_TOKEN_PURCHASED_NOTES = 'purchaseNotes';
export const RXFIELD_VOUCHER_TOKEN_MOVE_DIALOG_VOUCHER = 'voucher';
export const RXFIELD_TOKEN_IMPORT_CODE = 'code';
export const RXFIELD_TOKEN_IMPORT_VOUCHER_ID = 'voucherId';
export const RXFIELD_TOKEN_IMPORT_VOUCHER_NAME = 'voucherName';
export const RXFIELD_TOKEN_IMPORT_MERCHANT_NAME = 'merchantName';
export const RXFIELD_TOKEN_IMPORT_VALID_FROM = 'validFrom';
export const RXFIELD_TOKEN_IMPORT_VALID_UNTIL = 'validUntil';
export const RXFIELD_TOKEN_IMPORT_IMPORT_STATUS = 'importStatus';
export const RXFIELD_TOKEN_IMPORT_STATUS_NOTES = 'importStatusNotes';
export const RXFIELD_TOKEN_IMPORT_VOUCHER = 'voucher';
export const RXFIELD_TOKEN_IMPORT_MERCHANT = 'merchant';
export const RXFIELD_TOKEN_IMPORT_CSV_FILE = 'csvFile';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_USER = 'user';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_ID = 'voucherId';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_TOKEN_ID = 'voucherTokenId';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_NAME = 'voucherName';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER_IMAGE = 'voucherImage';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_PAYMENT_ID = 'paymentId';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_REQUEST_STATUS = 'requestStatus';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_STATUS_NOTES = 'requestStatusNotes';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_CREATED_DATE = 'createdDate';
export const RXFIELD_VOUCHER_PURCHASE_REQUEST_VOUCHER = 'voucher';
export const RXFIELD_PROMOTED_MERCHANT_NAME = 'name';
export const RXFIELD_PROMOTED_MERCHANT_IS_PROMOTED = 'isPromoted';
export const RXFIELD_PROMOTED_MERCHANT_ADDRESS = 'address';
export const RXFIELD_PROMOTED_MERCHANT_ORDER = 'order';
export const RXFIELD_PROMOTED_MERCHANT_CITY = 'city';
export const RXFIELD_PROMOTED_MERCHANT_COMPANY_LOGO = 'logo';
export const RXFIELD_PROMOTED_MERCHANT_COMPANY_BANNER = 'banner';
export const RXFIELD_PROMOTED_MERCHANT_WEBSITE = 'website';
export const RXFIELD_PROMOTED_MERCHANT_STATUS = 'status';
export const RXFIELD_PROMOTED_MERCHANT_PLAY_STORE_URL = 'playStoreUrl';
export const RXFIELD_PROMOTED_MERCHANT_APP_STORE_URL = 'appStoreUrl';
export const RXFIELD_GARUDA_AIRPORT_NAME = 'name';
export const RXFIELD_GARUDA_AIRPORT_DESCRIPTION = 'description';
export const RXFIELD_MAP_TOKEN_DIALOG_AIRPORT = 'airport';
export const RXFIELD_MAP_TOKEN_DIALOG_TOKEN_REMARK = 'tokenRemark';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_TOKEN_ID = 'voucherTokenId';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_ID = 'voucherId';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_NAME = 'voucherName';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_TO_ID = 'assignedToId';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_TO_NAME = 'assignedToName';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_BY_ID = 'assignedById';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_BY_NAME = 'assignedByName';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE = 'codeAssignmentMode';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_PAYMENT = 'bypassPayment';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_MAX_PURCHASE = 'bypassMaxPurchaseValidation';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_ACHIEVEMENT = 'bypassAchievementValidation';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE = 'code';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_FROM = 'validFrom';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_UNTIL = 'validUntil';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTES = 'notes';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_IMAGE = 'image';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATED_DATE = 'createdDate';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTIF_MESSAGE_TITLE = 'notificationMessageTitle';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTIF_MESSAGE_BODY = 'notificationMessageBody';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER = 'createVoucher';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE = 'createCodeAssignmentMode';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE = 'createCode';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_FROM = 'createValidFrom';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_UNTIL = 'createValidUntil';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_PAYMENT = 'createBypassPayment';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD = 'assignmentMethod';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM = 'assignmentValidFrom';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL = 'assignmentValidUntil';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_MAX_PURCHASE = 'createBypassMaxPurchase';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_ACHIEVEMENT = 'createBypassAchievement';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_REDEMPTION_CODE = 'redemptionCode';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_STATUS = 'status';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTES = 'createNotes';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS = 'createUsers';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTIF_MESSAGE_TITLE = 'createNotificationMessageTitle';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTIF_MESSAGE_BODY = 'createNotificationMessageBody';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_METHOD = 'createAssignmentMethod';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM = 'createAssignmentValidFrom';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL = 'createAssignmentValidUntil';
export const RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_AIRPORT = 'garudaAirport';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_MERCHANT_NAME = 'merchantName';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_ID = 'voucherId';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_NAME = 'voucherName';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY = 'redeemedBy';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY_ID = 'redeemedById';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_REDEEMED_BY_NAME = 'redeemedByName';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_VOUCHER_TOKEN_ID = 'voucherTokenId';
export const RXFIELD_GIFT_VOUCHER_REDEMPTION_GIFT_VOUCHER_ID = 'giftVoucherId';
export const RXFIELD_VOUCHER_USAGE_TITLE = 'title';
export const RXFIELD_VOUCHER_USAGE_MERCHANT_NAME = 'merchantName';
export const RXFIELD_VOUCHER_USAGE_TOKEN_COUNT = 'tokenCount';
export const RXFIELD_VOUCHER_USAGE_AVAILABLE_TOKEN_COUNT = 'availableTokenCount';
export const RXFIELD_VOUCHER_USAGE_PURCHASED_TOKEN_COUNT = 'purchasedTokenCount';
export const RXFIELD_VOUCHER_USAGE_CLAIMED_TOKEN_COUNT = 'claimedTokenCount';
export const RXFIELD_VOUCHER_USAGE_REVOKED_TOKEN_COUNT = 'revokedTokenCount';
export const RXFIELD_VOUCHER_USAGE_EXPIRED_TOKEN_COUNT = 'expiredTokenCount';
export const RXFIELD_VOUCHER_USAGE_VALID_FROM = 'validFrom';
export const RXFIELD_VOUCHER_USAGE_VALID_UNTIL = 'validUntil';
export const RXFIELD_VOUCHER_USAGE_TYPE = 'type';
export const RXFIELD_VOUCHER_USAGE_ACTION_TYPE = 'actionType';
export const RXFIELD_VOUCHER_USAGE_VISIBILITY = 'visibility';
export const RXFIELD_VOUCHER_USAGE_MERCHANT = 'merchant';
export const RXFIELD_VOUCHER_USAGE_APPLICABLE_AREA_MODE = 'applicableAreaMode';
export const RXFIELD_VOUCHER_USAGE_CATEGORY = 'category';
export const RXFIELD_VOUCHER_USAGE_STATUS = 'status';

export const RXSTATE_VOUCHER_CATEGORIES = 'voucherCategories';
export const RXSTATE_VOUCHER_CATEGORY_PAGE = 'uiVoucherCategory';
export const RXSTATE_VOUCHERS = 'vouchers';
export const RXSTATE_VOUCHER_PAGE = 'uiVoucher';
export const RXSTATE_TOKEN_REQUESTS = 'tokenRequests';
export const RXSTATE_TOKEN_REQUEST_PAGE = 'uiTokenRequest';
export const RXSTATE_VOUCHER_TOKENS = 'voucherTokens';
export const RXSTATE_VOUCHER_TOKEN_PAGE = 'uiVoucherToken';
export const RXSTATE_TOKEN_IMPORTS = 'tokenImports';
export const RXSTATE_TOKEN_IMPORT_PAGE = 'uiTokenImport';
export const RXSTATE_VOUCHER_PURCHASE_REQUESTS = 'voucherPurchaseRequests';
export const RXSTATE_VOUCHER_PURCHASE_REQUEST_PAGE = 'uiVoucherPurchaseRequest';
export const RXSTATE_PROMOTED_MERCHANTS = 'promotedMerchants';
export const RXSTATE_PROMOTED_MERCHANT_PAGE = 'uiPromotedMerchant';
export const RXSTATE_GARUDA_AIRPORTS = 'garudaAirports';
export const RXSTATE_GARUDA_AIRPORT_PAGE = 'uiGarudaAirport';
export const RXSTATE_VOUCHER_TOKEN_ASSIGNMENTS = 'voucherTokenAssignments';
export const RXSTATE_VOUCHER_TOKEN_ASSIGNMENT_PAGE = 'uiVoucherTokenAssignment';
export const RXSTATE_GIFT_VOUCHER_REDEMPTIONS = 'giftVoucherRedemptions';
export const RXSTATE_GIFT_VOUCHER_REDEMPTION_PAGE = 'uiGiftVoucherRedemption';
export const RXSTATE_VOUCHER_USAGES = 'voucherUsages';
export const RXSTATE_VOUCHER_USAGE_PAGE = 'uiVoucherUsage';

export const INITIAL_ORDER_BY_VOUCHER_CATEGORIES = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_VOUCHERS = `title ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_TOKEN_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VOUCHER_TOKENS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_TOKEN_IMPORTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VOUCHER_PURCHASE_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_PROMOTED_MERCHANTS = `name ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_GARUDA_AIRPORTS = `createdDate ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_VOUCHER_TOKEN_ASSIGNMENTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_GIFT_VOUCHER_REDEMPTIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_VOUCHER_USAGES = `title ${ORDER_SORT_ASCENDING}`;

export const MENUID_VOUCHER = 'MENUID_VOUCHER';
export const MENUID_VOUCHER_CATEGORY = 'MENUID_VOUCHER_CATEGORY';
export const MENUID_VOUCHER_VOUCHER = 'MENUID_VOUCHER_VOUCHER';
export const MENUID_VOUCHER_TOKEN_REQUEST = 'MENUID_VOUCHER_TOKEN_REQUEST';
export const MENUID_VOUCHER_TOKEN = 'MENUID_VOUCHER_TOKEN';
export const MENUID_VOUCHER_TOKEN_IMPORT = 'MENUID_VOUCHER_TOKEN_IMPORT';
export const MENUID_VOUCHER_PURCHASE_REQUEST = 'MENUID_VOUCHER_PURCHASE_REQUEST';
export const MENUID_VOUCHER_PROMOTED_MERCHANT = 'MENUID_VOUCHER_PROMOTED_MERCHANT';
export const MENUID_VOUCHER_GARUDA_AIRPORT = 'MENUID_VOUCHER_GARUDA_AIRPORT';
export const MENUID_VOUCHER_TOKEN_ASSIGNMENT = 'MENUID_VOUCHER_TOKEN_ASSIGNMENT';
export const MENUID_VOUCHER_GIFT_VOUCHER_REDEMPTION = 'MENUID_VOUCHER_GIFT_VOUCHER_REDEMPTION';
export const MENUID_VOUCHER_USAGE = 'MENUID_VOUCHER_USAGE';

export const VOUCHER_TYPE_PUBLIC = 'Public';
export const VOUCHER_TYPE_PRIVATE = 'Private';
export const APPLICABLE_AREA_MODE_ALL_BRANCHES = 'AllBranches';
export const APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES = 'SpecificBranches';
export const VOUCHER_FLAG_GARUDA_LOUNGE = 'lounge';

export const VOUCHER_ACTION_TYPES = ['Regular', 'Url'];
export const VOUCHER_APPLICABLE_AREA_MODES = [
  { label: LocalizedString.voucherPage.labelAllBranches, value: APPLICABLE_AREA_MODE_ALL_BRANCHES },
  {
    label: LocalizedString.voucherPage.labelSpecificBranches,
    value: APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES,
  },
];
export const VOUCHER_USAGE_APPLICABLE_AREA_MODES = [
  {
    label: LocalizedString.voucherUsagePage.labelAllBranches,
    value: APPLICABLE_AREA_MODE_ALL_BRANCHES,
  },
  {
    label: LocalizedString.voucherUsagePage.labelSpecificBranches,
    value: APPLICABLE_AREA_MODE_SPECIFIC_BRANCHES,
  },
];
export const VOUCHER_TYPES = [VOUCHER_TYPE_PUBLIC, VOUCHER_TYPE_PRIVATE];
export const VOUCHER_USAGE_TYPES = [VOUCHER_TYPE_PUBLIC, VOUCHER_TYPE_PRIVATE];

export const TOKEN_REQUEST_TASK_STATUS = [
  'Queuing', 'Processing', 'Cancelling',
  'Cancelled', 'Finished', 'Error',
];

export const TASK_STATUS_QUEUING = 'Queuing';
export const TASK_STATUS_PROCESSING = 'Processing';
export const TOKEN_IMPORT_IMPORT_STATUS = ['Error', 'Success'];

export const PAYMENT_TYPE_COIN = 'Coin';
export const PAYMENT_TYPE_COUPON = 'Coupon';
export const VOUCHER_PAYMENT_TYPES = [
  { label: LocalizedString.voucherPage.labelCoin, value: PAYMENT_TYPE_COIN },
  { label: LocalizedString.voucherPage.labelCoupon, value: PAYMENT_TYPE_COUPON },
];

export const NONE_OPTION = '';

export const VOUCHER_TAGS = [
  { label: LocalizedString.voucherPage.labelNone, value: NONE_OPTION },
  { label: TAG_WHITE, value: TAG_WHITE },
  { label: TAG_BLUE, value: TAG_BLUE },
  { label: TAG_BLUE_PLUS, value: TAG_BLUE_PLUS },
];

export const TANAMERA_FLAG = [
  { label: LocalizedString.voucherPage.labelNone, value: NONE_OPTION },
  { label: 'starterpack', value: 'starterpack' },
];

export const GARUDA_FLAG = [
  { label: LocalizedString.voucherPage.labelNone, value: NONE_OPTION },
  { label: 'lounge', value: 'lounge' },
];

export const GARUDA_COMPANY_NAME = 'GarudaCompanyName';
export const TANAMERA_COMPANY_NAME = 'TanameraCompanyName';

export const ASSIGNMENT_MODE_CUSTOM_CODE = 'AssignCustomCode';
export const ASSIGNMENT_MODE_GENERATE_NEW_CODE = 'GenerateNewCode';
export const ASSIGNMENT_MODE_USE_AVAILABLE_CODE = 'UseAvailableCode';

export const ASSIGNMENT_METHOD_IMMEDIATELY = 'Immediately';
export const ASSIGNMENT_METHOD_URL_ACCESS = 'UrlAccess';

export const ASSIGNMENT_MODE_OPTION = [
  ASSIGNMENT_MODE_GENERATE_NEW_CODE,
  ASSIGNMENT_MODE_USE_AVAILABLE_CODE,
  ASSIGNMENT_MODE_CUSTOM_CODE,
];

export const ASSIGNMENT_METHOD_OPTION = [
  ASSIGNMENT_METHOD_IMMEDIATELY,
  ASSIGNMENT_METHOD_URL_ACCESS,
];

export const CONFIG_VALUE_GARUDA_COMPANY_NAME = 'GarudaCompanyName';
