import { editingConfigItems } from '../simple-action';
import { editConfigItems } from '../../../helper';
import downloadFilteredConfigItemsAsync from './downloadFilteredConfigItemsAsync';

export default (values) => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;
  try {
    dispatch(editingConfigItems(true));
    const payload = Object.values(values).map((value, index) => ({
      id: Object.keys(values)[index],
      value,
    }));
    const body = {
      configs: payload,
    };
    await editConfigItems(body, token);
    await dispatch(downloadFilteredConfigItemsAsync());
  } finally {
    dispatch(editingConfigItems(false));
  }
};
