import {
  DOWNLOADING_MEMBERSHIP_REWARDS, DOWNLOADING_DELETING_MEMBERSHIP_REWARD,
  SET_MEMBERSHIP_REWARD_SELECTED_PAGE_SIZE,
  SET_MEMBERSHIP_REWARD_TAPPED_ID, SET_MEMBERSHIP_REWARD_SEARCH_TEXT,
  SET_MEMBERSHIP_REWARD_SELECTED_ORDER_BY,
} from '../action';
import { INITIAL_ORDER_BY_MEMBERSHIP_REWARDS } from '../../constant';

const initialState = {
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_MEMBERSHIP_REWARDS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_MEMBERSHIP_REWARDS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_MEMBERSHIP_REWARD:
      return { ...state, downloadingDeleting: action.status };
    case SET_MEMBERSHIP_REWARD_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_MEMBERSHIP_REWARD_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_MEMBERSHIP_REWARD_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_MEMBERSHIP_REWARD_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
