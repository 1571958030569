import { connect } from 'react-redux';
import { reset } from 'redux-form';
import moment from 'moment-timezone';
import {
  INITIAL_ORDER_BY_SERVICE_REQUESTS, PAGE_MODE_TABLE,
  RXFORM_SERVICE_REQUEST, MENUID_CAR_SERVICE_SERVICE_REQUEST,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import { transformInitialValues, transformUserDropdownData } from '../../helper';
import {
  clearServiceRequests, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setServiceRequestAdvancedFilterDialogSelectedFilterString, setServiceRequestSearchText,
  setServiceRequestSelectedOrderBy, setServiceRequestSelectedPageSize, setServiceRequestTappedId,
  downloadDeleteServiceRequestAsync, downloadServiceRequestsAsync, setUserSearchText, clearUsers,
  setDealerSearchText, clearDealers, setDealerAdvancedFilterDialogSelectedFilterString,
  setUserAdvancedFilterDialogSelectedFilterString, downloadDealersAsync,
} from '../../redux/action';
import { downloadUsersAsync } from '../../../../redux/action';
import ServiceRequestPage from './service-request.presentation';
import GlobalLocalizedString from '../../../../localization';
import { transformDropdownData } from '../../../../helper';

const getInitialValues = (state) => {
  const {
    serviceRequests, uiFunctionalPage, uiServiceRequest,
  } = state;
  const { data } = serviceRequests;
  const { downloadingDeleting, tappedId } = uiServiceRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};

  const result = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      name: found.dealer.name ?? '',
      fullName: found.user.fullName ?? '',
      serviceDate: moment(found.serviceDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) ?? '',
    }) : {};
  return result;
};

const mapStateToProps = (state) => ({
  users: transformUserDropdownData(state.users.data),
  dealers: transformDropdownData(state.dealers.data),
  downloading: state.uiServiceRequest.downloading,
  addingEditing: state.uiServiceRequest.addingEditing,
  downloadingDeleting: state.uiServiceRequest.downloadingDeleting,
  loadingUser: state.uiUser.downloading,
  loadingDealer: state.uiDealer.downloading,
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
  tappedId: state.uiServiceRequest.tappedId,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setUserAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setDealerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setUserSearchText(''));
    dispatch(setDealerSearchText(''));
    dispatch(clearDealers());
    dispatch(clearUsers());
    dispatch(downloadUsersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDealersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_SERVICE_SERVICE_REQUEST));
    dispatch(setServiceRequestSearchText(''));
    dispatch(setServiceRequestAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearServiceRequests());
    dispatch(setServiceRequestSelectedPageSize(20));
    dispatch(setServiceRequestSelectedOrderBy(INITIAL_ORDER_BY_SERVICE_REQUESTS));
    dispatch(downloadServiceRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/fullName/, 'user.id')
      .replace(/name/, 'dealer.id')
      .replace(/vin/, 'vehicle.vin')
      .replace(/licensePlate/, 'vehicle.licensePlate');
    dispatch(setServiceRequestAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearServiceRequests());
    dispatch(downloadServiceRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SERVICE_REQUEST));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadServiceRequestsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setServiceRequestSelectedPageSize(pageSize));
    dispatch(downloadServiceRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setUserSearchText(text));
      dispatch(clearUsers());
      await dispatch(downloadUsersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeDealerText: async (text) => {
    try {
      dispatch(setDealerSearchText(text));
      dispatch(clearDealers());
      await dispatch(downloadDealersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteServiceRequestAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setServiceRequestTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onRefresh: (pageSize) => {
    dispatch(setServiceRequestSelectedPageSize(pageSize));
    dispatch(clearServiceRequests());
    dispatch(downloadServiceRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearServiceRequests());
    dispatch(setServiceRequestAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setServiceRequestSelectedOrderBy(INITIAL_ORDER_BY_SERVICE_REQUESTS));
    dispatch(downloadServiceRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setServiceRequestSearchText(text));
      dispatch(clearServiceRequests());
      await dispatch(downloadServiceRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setServiceRequestSelectedOrderBy(orderBy));
    dispatch(clearServiceRequests());
    dispatch(downloadServiceRequestsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setServiceRequestTappedId(id));
    dispatch(downloadDeleteServiceRequestAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestPage);
