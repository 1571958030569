import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearCarOwnershipRewards, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setCarOwnershipRewardSearchText, setCarOwnershipRewardSelectedPageSize,
  setCarOwnershipRewardSelectedOrderBy, setCarOwnershipRewardTappedId,
  downloadCarOwnershipRewardAsync, downloadCarOwnershipRewardsAsync,
} from '../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_CAR_OWNERSHIP_REWARDS,
  MENUID_CAR_REGISTRATION_CAR_OWNERSHIP_REWARD,
  PAGE_MODE_TABLE, RXFORM_REGISTRATION,
} from '../../constant';
import {
  toCurrency, toMoment, transformInitialValues,
} from '../../helper';
import CarOwnershipRewardPage from './car-ownership-reward.presentation';
import { sortAsc } from '../../../../helper';

const transformDate = (date) => (date ? toMoment(date).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
  : null);

const getInitialValues = (state) => {
  const { carOwnershipRewards, uiFunctionalPage, uiCarOwnershipReward } = state;
  const { data } = carOwnershipRewards;
  const {
    downloadingDeleting, tappedId,
  } = uiCarOwnershipReward;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    rewardAmount: toCurrency(found.rewardAmount),
    registration: {
      ...found.registration,
      approvedOn: transformDate(found.registration.approvedOn),
    },
    items: Object.values(found.items).length > 0 && !downloadingDeleting
      ? Object.values(found.items).sort((a, b) => sortAsc(a.scheduledDate, b.scheduledDate))
        .map((x, i) => ({
          ...x,
          no: i + 1,
          recipient: {
            ...x.recipient,
          },
          amount: toCurrency(x.amount),
          scheduledDate: transformDate(x.scheduledDate),
          actualDate: transformDate(x.actualDate),
          type: x.type,
        })) : [],
  }) : { items: [] };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiCarOwnershipReward.downloading,
  downloadingDeleting: state.uiCarOwnershipReward.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
  totalCurrentPage: state.carOwnershipRewards.meta.currentPage,
  totalCount: state.carOwnershipRewards.meta.totalCount,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CAR_REGISTRATION_CAR_OWNERSHIP_REWARD));
    dispatch(setCarOwnershipRewardSearchText(''));
    dispatch(clearCarOwnershipRewards());
    dispatch(setCarOwnershipRewardSelectedPageSize(20));
    dispatch(setCarOwnershipRewardSelectedOrderBy(INITIAL_ORDER_BY_CAR_OWNERSHIP_REWARDS));
    dispatch(downloadCarOwnershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id').replace(/city/, 'city.id')
      .replace(/province/, 'city.province.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCarOwnershipRewards());
    dispatch(downloadCarOwnershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_REGISTRATION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCarOwnershipRewardsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCarOwnershipRewardSelectedPageSize(pageSize));
    dispatch(downloadCarOwnershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setCarOwnershipRewardSelectedPageSize(pageSize));
    dispatch(clearCarOwnershipRewards());
    dispatch(downloadCarOwnershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCarOwnershipRewards());
    dispatch(setCarOwnershipRewardSelectedOrderBy(INITIAL_ORDER_BY_CAR_OWNERSHIP_REWARDS));
    dispatch(downloadCarOwnershipRewardsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCarOwnershipRewardSearchText(text));
      dispatch(clearCarOwnershipRewards());
      await dispatch(downloadCarOwnershipRewardsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCarOwnershipRewardSelectedOrderBy(orderBy));
    dispatch(clearCarOwnershipRewards());
    dispatch(downloadCarOwnershipRewardsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setCarOwnershipRewardTappedId(id));
    dispatch(downloadCarOwnershipRewardAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarOwnershipRewardPage);
