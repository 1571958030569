import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import * as Action from '../action';
import authentication from './authentication';
import users from './users';
import currentUser from './currentUser';
import uiLogin from './uiLogin';
import uiProfile from './uiProfile';
import alert from './alert';
import uiBasePage from './uiBasePage';
import provinces from './provinces';
import uiProvince from './uiProvince';
import cities from './cities';
import uiCity from './uiCity';
import uiCompany from './uiCompany';
import uiFunctionalPage from './uiFunctionalPage';
import branches from './branches';
import uiBranch from './uiBranch';
import permissions from './permissions';
import uiPermission from './uiPermission';
import uiUser from './uiUser';
import roles from './roles';
import uiRole from './uiRole';
import apiKeys from './apiKeys';
import uiApiKey from './uiApiKey';
import tokens from './tokens';
import uiToken from './uiToken';
import applications from './applications';
import uiApplication from './uiApplication';
import cropImage from './cropImage';
import profiles from './profiles';
import totalUsers from './totalUsers';
import activeUsers from './activeUsers';
import activeDevices from './activeDevices';
import osDistribution from './osDistribution';
import loginUsing from './loginUsing';
import uiSummary from './uiSummary';
import androidVersionDistribution from './androidVersionDistribution';
import androidDeviceModelDistribution from './androidDeviceModelDistribution';
import iosVersionDistribution from './iosVersionDistribution';
import iosDeviceModelDistribution from './iosDeviceModelDistribution';
import companies from './companies';
import companiesMdata from './companiesMdata';
import divisions from './divisions';
import departments from './departments';
import browserDistribution from './browserDistribution';
import timezoneDistribution from './timezoneDistribution';
import notification from './notification';
import appVersionDistribution from './appVersionDistribution';
import services from './services';
import uiService from './uiService';
import mapDrawer from './mapDrawer';
import scopes from './scopes';
import uiScope from './uiScope';
import banners from './banners';
import uiBanner from './uiBanner';
import managers from './managers';
import deviceManufacturers from './deviceManufacturers';
import deviceManufacturerDistribution from './deviceManufacturerDistribution';
import deviceModels from './deviceModels';
import osNames from './osNames';
import osVersions from './osVersions';
import timeZones from './timeZones';
import appVersions from './appVersions';
import onlineUsers from './onlineUsers';
import districts from './districts';
import uiDistrict from './uiDistrict';
import subdistricts from './subdistricts';
import uiSubdistrict from './uiSubdistrict';
import referrers from './referrers';
import deviceExceptions from './deviceExceptions';
import uiDeviceException from './uiDeviceException';
import authRegistrationAttempts from './authRegistrationAttempts';
import uiAuthRegistrationAttempt from './uiAuthRegistrationAttempt';
import authPasswordResetAttempts from './authPasswordResetAttempts';
import uiAuthPasswordResetAttempt from './uiAuthPasswordResetAttempt';
import myConfigItems from './myConfigItems';
import {
  configActions, configGroups, configItems, configValues, uiConfigAction,
  uiConfigGroup, uiConfigItem, uiConfigValue,
} from '../../module/configuration';
import {
  totalClockInToday, totalClockOutToday, todayClockInStatus, todayClockOutStatus,
  uiAttendanceSummary, todayClockInLocationStatus, todayClockOutLocationStatus,
  clockInStatusTrend, clockOutStatusTrend, clockInLocationTrend, clockOutLocationTrend,
  histories, uiHistory, additionalFields, uiAdditionalField, additionalFieldParents,
  employees,
} from '../../module/attendance';
import {
  faqCategories, uiFAQCategory, faqs, uiFAQ,
} from '../../module/faq';
import {
  emails, smses, pushNotifications, notificationTasks, whatsapps,
  uiEmail, uiSms, uiPushNotification, uiNotificationTask, uiWhatsapp,
} from '../../module/notification';
import {
  previousMonthLocations, previousMonthPacketTypes, previousMonthTopRecipients, previousMonthTrends,
  previousYearLocations, previousYearPacketTypes, previousYearTopRecipients, previousYearTrends,
  thisMonthLocations, thisMonthPacketTypes, thisMonthTopRecipients, thisMonthTrends,
  thisYearLocations, thisYearPacketTypes, thisYearTopRecipients, thisYearTrends,
  packets, packetTypes, pickupLocations,
  uiPacket, uiPacketType, uiPickupLocation, uiPacketSummary,
} from '../../module/packet';
import {
  activities, activityTypes, customers, industries,
  uiActivity, uiActivityType, uiCustomer, uiIndustry,
} from '../../module/activity';
import {
  companyMappings, infoMappings, uiCompanyMapping, uiInfoMapping,
} from '../../module/bcard';
import {
  shortlinks, uiShortlink,
} from '../../module/shortlink';
import {
  auditTrails, uiAuditTrail,
} from '../../module/audit';
import {
  pointAchievement, pointBalance, points, pointSubmissions, pointTransactions,
  pointPurchaseRequests,
  uiPointAchievement, uiPoint, uiPointSubmission, uiPointTransaction, uiPointPurchaseRequest,
  pointVoidRequests, uiPointVoidRequest, pointLeaderBoard, uiPointLeaderBoard,
} from '../../module/point';
import {
  voucherCategories, uiVoucherCategory, vouchers, uiVoucher,
  tokenRequests, uiTokenRequest, voucherTokens, uiVoucherToken,
  purchasers, claimers, revokers, tokenImports, uiTokenImport,
  voucherPurchaseRequests, uiVoucherPurchaseRequest,
  promotedMerchants, uiPromotedMerchant,
  garudaAirports, uiGarudaAirport,
  mapTokenAirports, voucherTokenAssignments, uiVoucherTokenAssignment,
  giftVoucherRedemptions, uiGiftVoucherRedemption,
  voucherUsages, uiVoucherUsage,
} from '../../module/voucher';
import {
  discussions, discussionComments, uiDiscussionComment, uiDiscussion,
  reportedComments, uiReportedComment,
} from '../../module/discussion';
import {
  forumCategories, uiForumCategory, forumParentCategories,
  forumPosts, uiForumPost,
} from '../../module/forum';
import {
  challenges, gameQRCategories, uiChallenge, uiGameQRCategory,
  scannedCodes, uiScannedCode, uiCompletedChallenge, completedChallenges,
} from '../../module/game-qr';
import {
  catalog, models, uiCatalog, uiModel,
} from '../../module/car-catalog';
import {
  serviceRequests, uiServiceRequest, dealers, uiDealer,
  carServiceReservations, uiCarServiceReservation,
  chargingStations, uiChargingStation,
  dealerCities, dealerProvinces, chargingStationCities,
  chargingStationProvinces,
  uiEvChargingUrl, evChargingUrls,
} from '../../module/car-service';
import {
  events, uiEvent,
  reservations, uiReservation,
  testVehicles, uiTestVehicle,
  voucherRewards, uiVoucherReward,
  uniqueCodes, uiUniqueCode,
  eventOrganizers, uiEventOrganizer,
} from '../../module/test-drive';
import {
  carOwnerships, carOwnershipRewards, membershipRewards, registrations,
  uiCarOwnership, uiCarOwnershipReward, uiMembershipReward, uiRegistration,
  registrationAttempts, uiRegistrationAttempt, dmsUserSubmissions, uiDMSUserSubmission,
} from '../../module/car-registration';
import {
  news, newsCategories, uiNews, uiNewsCategory,
} from '../../module/news';
import { partnerMerchants, uiPartnerMerchant } from '../../module/partner';
import {
  couponAchievement, couponBalance, couponLeaderBoard, couponPurchaseRequests, coupons,
  couponSubmissions, couponTransactions, couponVoidRequests,
  uiCoupon, uiCouponAchievement, uiCouponLeaderBoard, uiCouponPurchaseRequest, uiCouponSubmission,
  uiCouponTransaction, uiCouponVoidRequest,
} from '../../module/coupon';
import {
  partnerVoucherTokens, uiPartnerVoucherToken, uiVoucherClaim, voucherTokenByCode,
} from '../../module/partner-admin';
import {
  promoCodeBatches, promoCodes, redemptionHistories,
  uiPromoCode, uiPromoCodeBatch, uiRedemptionHistory,
} from '../../module/promo-code';
import {
  carViewingEvents, uiCarViewingEvent, carViewingReservations, uiCarViewingReservation,
  carViewingVoucherRewards, uiCarViewingVoucherReward,
} from '../../module/car-viewing';
import {
  testDriveEoEvents, testDriveEoTestVehicles, testDriveEoReservations,
  uiTestDriveEoEvent, uiTestDriveEoTestVehicle, uiTestDriveEoReservation, salesConsultants,
} from '../../module/test-drive-eo';
import {
  giiasScannedCodes, uiGiiasScannedCode,
  giias2022PrizeRedemptions, uiGiias2022PrizeRedemption,
  giias2022Transactions, uiGiias2022Transaction,
} from '../../module/giias-2022';
import {
  giiasSurabayaScannedCodes, uiGiiasSurabayaScannedCode,
  giiasSurabayaPrizeRedemptions, uiGiiasSurabayaPrizeRedemption,
  giiasSurabayaTransactions, uiGiiasSurabayaTransaction,
} from '../../module/giias-surabaya-2022';
import {
  giiasMedanPrizeRedemptions, giiasMedanScannedCodes, giiasMedanTransactions,
  uiGiiasMedanPrizeRedemption, uiGiiasMedanScannedCode, uiGiiasMedanTransaction,
} from '../../module/giias-medan-2022';
import {
  iims2023PrizeRedemptions, iims2023ScannedCodes, iims2023Transactions,
  uiIims2023PrizeRedemption, uiIims2023ScannedCode, uiIims2023Transaction, iims2023Surveys, uiIims2023Survey,
  iims2023Spks, uiIims2023Spk,
} from '../../module/iims-2023';
import {
  jaw2023PrizeRedemptions, jaw2023ScannedCodes, jaw2023Transactions,
  uiJaw2023PrizeRedemption, uiJaw2023ScannedCode, uiJaw2023Transaction, jaw2023Surveys, uiJaw2023Survey,
  jaw2023Spks, uiJaw2023Spk,
} from '../../module/jaw-2023';
import {
  giiasSemarangPrizeRedemptions, giiasSemarangScannedCodes, giiasSemarangTransactions,
  uiGiiasSemarangPrizeRedemption, uiGiiasSemarangScannedCode, uiGiiasSemarangTransaction,
} from '../../module/giias-semarang-2022';
import {
  accessories, uiAccessory, vehicleModels,
  accessoryTransactions, uiAccessoryTransaction,
  invoices, uiInvoice,
} from '../../module/genuine-accessory';
import {
  uiWasteCollection, wasteCollections, uiWasteDealerCode, wasteDealerCodes,
} from '../../module/e-waste';
import {
  gameActivityRegistrationReferrals, uiGameActivityRegistrationReferral,
  recurrenceReferralRewards, uiRecurrenceReferralReward,
  dailyCheckins, uiDailyCheckin, completedProfiles, uiCompletedProfile,
  newUserNotifications, uiNewUserNotification,
} from '../../module/game-activity';
import {
  uiServicePromo, bookingCreated, bookingProceedByDealer,
  bookingWaitForPayment, bookingCompleted, bookingCanceled,
  bookingCreatedDeliveryMethod, bookingCreatedServiceInterval,
  bookingCreatedVehicleModel, bookingCreatedRepairSelection,
  transactionReceived, transactionPending, transactionPaid,
  transactionCanceled, transactionAmount,
  transactionCountByPartName, transactionAmountByPartName,
  remaningStockByPartName, transactionCreatedByVehicleModel,
} from '../../module/dashboard';

import {
  hyundaiChallengePrizeRedemptions, hyundaiChallengeScannedCodes, hyundaiChallengeTransactions,
  uiHyundaiChallengePrizeRedemption, uiHyundaiChallengeScannedCode, uiHyundaiChallengeTransaction,
} from '../../module/hyundai-challenge';


const reducer = combineReducers({
  accessories,
  accessoryTransactions,
  activeDevices,
  activeUsers,
  activities,
  activityTypes,
  additionalFieldParents,
  additionalFields,
  alert,
  androidDeviceModelDistribution,
  androidVersionDistribution,
  apiKeys,
  applications,
  appVersionDistribution,
  appVersions,
  auditTrails,
  authentication,
  authPasswordResetAttempts,
  authRegistrationAttempts,
  banners,
  bookingCanceled,
  bookingCompleted,
  bookingCreated,
  bookingCreatedDeliveryMethod,
  bookingCreatedRepairSelection,
  bookingCreatedServiceInterval,
  bookingCreatedVehicleModel,
  bookingProceedByDealer,
  bookingWaitForPayment,
  branches,
  browserDistribution,
  carOwnershipRewards,
  carOwnerships,
  carServiceReservations,
  carViewingEvents,
  carViewingReservations,
  carViewingVoucherRewards,
  catalog,
  challenges,
  chargingStationCities,
  chargingStationProvinces,
  chargingStations,
  cities,
  claimers,
  clockInLocationTrend,
  clockInStatusTrend,
  clockOutLocationTrend,
  clockOutStatusTrend,
  companies,
  companiesMdata,
  companyMappings,
  completedChallenges,
  completedProfiles,
  configActions,
  configGroups,
  configItems,
  configValues,
  couponAchievement,
  couponBalance,
  couponLeaderBoard,
  couponPurchaseRequests,
  coupons,
  couponSubmissions,
  couponTransactions,
  couponVoidRequests,
  cropImage,
  currentUser,
  customers,
  dailyCheckins,
  dealerCities,
  dealerProvinces,
  dealers,
  departments,
  deviceExceptions,
  deviceManufacturerDistribution,
  deviceManufacturers,
  deviceModels,
  discussionComments,
  discussions,
  districts,
  divisions,
  dmsUserSubmissions,
  emails,
  employees,
  evChargingUrls,
  eventOrganizers,
  events,
  faqCategories,
  faqs,
  forumCategories,
  forumParentCategories,
  forumPosts,
  gameActivityRegistrationReferrals,
  gameQRCategories,
  garudaAirports,
  giftVoucherRedemptions,
  giias2022PrizeRedemptions,
  giias2022Transactions,
  giiasMedanPrizeRedemptions,
  giiasMedanScannedCodes,
  giiasMedanTransactions,
  iims2023PrizeRedemptions,
  iims2023ScannedCodes,
  iims2023Surveys,
  iims2023Spks,
  iims2023Transactions,
  jaw2023PrizeRedemptions,
  jaw2023ScannedCodes,
  jaw2023Surveys,
  jaw2023Spks,
  jaw2023Transactions,
  giiasScannedCodes,
  giiasSemarangPrizeRedemptions,
  giiasSemarangScannedCodes,
  giiasSemarangTransactions,
  giiasSurabayaPrizeRedemptions,
  giiasSurabayaScannedCodes,
  giiasSurabayaTransactions,
  histories,
  hyundaiChallengePrizeRedemptions,
  hyundaiChallengeScannedCodes,
  hyundaiChallengeTransactions,
  industries,
  infoMappings,
  invoices,
  iosDeviceModelDistribution,
  iosVersionDistribution,
  loginUsing,
  managers,
  mapDrawer,
  mapTokenAirports,
  membershipRewards,
  models,
  myConfigItems,
  news,
  newsCategories,
  newUserNotifications,
  notification,
  notificationTasks,
  onlineUsers,
  osDistribution,
  osNames,
  osVersions,
  packets,
  packetTypes,
  partnerMerchants,
  partnerVoucherTokens,
  permissions,
  pickupLocations,
  pointAchievement,
  pointBalance,
  pointLeaderBoard,
  pointPurchaseRequests,
  points,
  pointSubmissions,
  pointTransactions,
  pointVoidRequests,
  previousMonthLocations,
  previousMonthPacketTypes,
  previousMonthTopRecipients,
  previousMonthTrends,
  previousYearLocations,
  previousYearPacketTypes,
  previousYearTopRecipients,
  previousYearTrends,
  profiles,
  promoCodeBatches,
  promoCodes,
  promotedMerchants,
  provinces,
  purchasers,
  pushNotifications,
  recurrenceReferralRewards,
  redemptionHistories,
  referrers,
  registrationAttempts,
  registrations,
  remaningStockByPartName,
  reportedComments,
  reservations,
  revokers,
  roles,
  scannedCodes,
  scopes,
  serviceRequests,
  services,
  shortlinks,
  smses,
  subdistricts,
  testDriveEoEvents,
  salesConsultants,
  testDriveEoReservations,
  testDriveEoTestVehicles,
  testVehicles,
  thisMonthLocations,
  thisMonthPacketTypes,
  thisMonthTopRecipients,
  thisMonthTrends,
  thisYearLocations,
  thisYearPacketTypes,
  thisYearTopRecipients,
  thisYearTrends,
  timezoneDistribution,
  timeZones,
  todayClockInLocationStatus,
  todayClockInStatus,
  todayClockOutLocationStatus,
  todayClockOutStatus,
  tokenImports,
  tokenRequests,
  tokens,
  totalClockInToday,
  totalClockOutToday,
  totalUsers,
  transactionAmount,
  transactionAmountByPartName,
  transactionCanceled,
  transactionCountByPartName,
  transactionCreatedByVehicleModel,
  transactionPaid,
  transactionPending,
  transactionReceived,
  uiAccessory,
  uiAccessoryTransaction,
  uiActivity,
  uiActivityType,
  uiAdditionalField,
  uiApiKey,
  uiApplication,
  uiAttendanceSummary,
  uiAuditTrail,
  uiAuthPasswordResetAttempt,
  uiAuthRegistrationAttempt,
  uiBanner,
  uiBasePage,
  uiBranch,
  uiCarOwnership,
  uiCarOwnershipReward,
  uiCarServiceReservation,
  uiCarViewingEvent,
  uiCarViewingReservation,
  uiCarViewingVoucherReward,
  uiCatalog,
  uiChallenge,
  uiChargingStation,
  uiCity,
  uiCompany,
  uiCompanyMapping,
  uiCompletedChallenge,
  uiCompletedProfile,
  uiConfigAction,
  uiConfigGroup,
  uiConfigItem,
  uiConfigValue,
  uiCoupon,
  uiCouponAchievement,
  uiCouponLeaderBoard,
  uiCouponPurchaseRequest,
  uiCouponSubmission,
  uiCouponTransaction,
  uiCouponVoidRequest,
  uiCustomer,
  uiDailyCheckin,
  uiDealer,
  uiDeviceException,
  uiDiscussion,
  uiDiscussionComment,
  uiDistrict,
  uiDMSUserSubmission,
  uiEmail,
  uiEvChargingUrl,
  uiEvent,
  uiEventOrganizer,
  uiFAQ,
  uiFAQCategory,
  uiForumCategory,
  uiForumPost,
  uiFunctionalPage,
  uiGameActivityRegistrationReferral,
  uiGameQRCategory,
  uiGarudaAirport,
  uiGiftVoucherRedemption,
  uiGiias2022PrizeRedemption,
  uiGiias2022Transaction,
  uiGiiasMedanPrizeRedemption,
  uiGiiasMedanScannedCode,
  uiGiiasMedanTransaction,
  uiIims2023PrizeRedemption,
  uiIims2023ScannedCode,
  uiIims2023Survey,
  uiIims2023Spk,
  uiIims2023Transaction,
  uiJaw2023PrizeRedemption,
  uiJaw2023ScannedCode,
  uiJaw2023Survey,
  uiJaw2023Spk,
  uiJaw2023Transaction,
  uiGiiasScannedCode,
  uiGiiasSemarangPrizeRedemption,
  uiGiiasSemarangScannedCode,
  uiGiiasSemarangTransaction,
  uiGiiasSurabayaPrizeRedemption,
  uiGiiasSurabayaScannedCode,
  uiGiiasSurabayaTransaction,
  uiHistory,
  uiHyundaiChallengePrizeRedemption,
  uiHyundaiChallengeScannedCode,
  uiHyundaiChallengeTransaction,
  uiIndustry,
  uiInfoMapping,
  uiInvoice,
  uiLogin,
  uiMembershipReward,
  uiModel,
  uiNews,
  uiNewsCategory,
  uiNewUserNotification,
  uiNotificationTask,
  uiPacket,
  uiPacketSummary,
  uiPacketType,
  uiPartnerMerchant,
  uiPartnerVoucherToken,
  uiPermission,
  uiPickupLocation,
  uiPoint,
  uiPointAchievement,
  uiPointLeaderBoard,
  uiPointPurchaseRequest,
  uiPointSubmission,
  uiPointTransaction,
  uiPointVoidRequest,
  uiProfile,
  uiPromoCode,
  uiPromoCodeBatch,
  uiPromotedMerchant,
  uiProvince,
  uiPushNotification,
  uiRecurrenceReferralReward,
  uiRedemptionHistory,
  uiRegistration,
  uiRegistrationAttempt,
  uiReportedComment,
  uiReservation,
  uiRole,
  uiScannedCode,
  uiScope,
  uiService,
  uiServicePromo,
  uiServiceRequest,
  uiShortlink,
  uiSms,
  uiSubdistrict,
  uiSummary,
  uiTestDriveEoEvent,
  uiTestDriveEoReservation,
  uiTestDriveEoTestVehicle,
  uiTestVehicle,
  uiToken,
  uiTokenImport,
  uiTokenRequest,
  uiUniqueCode,
  uiUser,
  uiVoucher,
  uiVoucherCategory,
  uiVoucherClaim,
  uiVoucherPurchaseRequest,
  uiVoucherReward,
  uiVoucherToken,
  uiVoucherTokenAssignment,
  uiVoucherUsage,
  uiWasteCollection,
  uiWasteDealerCode,
  uiWhatsapp,
  uniqueCodes,
  users,
  vehicleModels,
  voucherCategories,
  voucherPurchaseRequests,
  voucherRewards,
  vouchers,
  voucherTokenAssignments,
  voucherTokenByCode,
  voucherTokens,
  voucherUsages,
  wasteCollections,
  wasteDealerCodes,
  whatsapps,
  form: formReducer,
});

export default (state, action) => {
  const matches = /(SET|CLEAR)_ERROR_(.+)/.exec(action.type);
  if (matches) {
    const reduxState = reducer(state, action);
    const [, stateOperation, stateName] = matches;
    const stateSlice = reduxState[stateName];
    if (stateSlice && stateOperation === 'SET') {
      return {
        ...reduxState,
        [stateName]: { ...stateSlice, error: action.error },
      };
    }
    if (stateSlice && stateOperation === 'CLEAR') {
      return {
        ...reduxState,
        [stateName]: { ...stateSlice, error: undefined },
      };
    }
  }

  if (action.type === Action.CLEAR_ALL_STATES) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};
