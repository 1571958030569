import { downloadingConfigGroups, setConfigGroups } from '../simple-action';
import { downloadConfigGroups, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, configGroups, uiConfigGroup } = getState();
  const { token } = authentication;
  const { meta } = configGroups;
  const { selectedPageSize, searchBarText, orderBy } = uiConfigGroup;

  try {
    dispatch(downloadingConfigGroups(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadConfigGroups(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      token,
    );

    if (list) {
      dispatch(setConfigGroups(list));
    }
  } finally {
    dispatch(downloadingConfigGroups(false));
  }
};
