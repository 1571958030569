import { reset } from 'redux-form';
import {
  addingEditingTestDriveEoTestVehicle, clearTestDriveEoTestVehicles, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { editTestDriveEoTestVehicle } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_TEST_DRIVE_EO_TEST_VEHICLE } from '../../../constant';
import downloadTestDriveEoTestVehiclesAsync from './downloadTestDriveEoTestVehiclesAsync';

export default (dailyQuota) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingTestDriveEoTestVehicle(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiTestDriveEoTestVehicle;

    const { data } = getState().testDriveEoTestVehicles;
    const found = data[tappedId];

    await editTestDriveEoTestVehicle(tappedId, found.event.id, found.modelId, dailyQuota, token);
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));

    dispatch(reset(RXFORM_TEST_DRIVE_EO_TEST_VEHICLE));
    dispatch(clearTestDriveEoTestVehicles());
    dispatch(downloadTestDriveEoTestVehiclesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingTestDriveEoTestVehicle(false));
  }
};
