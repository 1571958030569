import { INITIAL_ORDER_BY_TEST_VEHICLES } from '../../constant';
import {
  DOWNLOADING_TEST_VEHICLES,
  SET_TEST_VEHICLE_SEARCH_TEXT, SET_TEST_VEHICLE_SELECTED_PAGE_SIZE, SET_TEST_VEHICLE_TAPPED_ID,
  SET_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, SET_TEST_VEHICLE_SELECTED_EVENT,
  SET_TEST_VEHICLE_SELECTED_MODEL, SET_TEST_VEHICLE_SELECTED_ORDER_BY,
  ADDING_EDITING_TEST_VEHICLE, DOWNLOADING_DELETING_TEST_VEHICLE,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  filterString: '',
  tappedId: '',
  searchBarText: '',
  selectedEvent: '',
  selectedModel: '',
  orderBy: INITIAL_ORDER_BY_TEST_VEHICLES,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_TEST_VEHICLES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_TEST_VEHICLE:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_TEST_VEHICLE:
      return { ...state, downloadingDeleting: action.status };
    case SET_TEST_VEHICLE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_TEST_VEHICLE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_TEST_VEHICLE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_TEST_VEHICLE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_TEST_VEHICLE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_TEST_VEHICLE_SELECTED_EVENT:
      return { ...state, selectedEvent: action.option };
    case SET_TEST_VEHICLE_SELECTED_MODEL:
      return { ...state, selectedModel: action.option };
    default: return state;
  }
};
