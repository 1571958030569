import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { MENUID_COUPON_LEADER_BOARD, RXFORM_COUPON_LEADER_BOARD } from '../../constant';
import {
  clearCouponLeaderBoard, setActiveSideMenuItem, setAlertErrorMessage,
  downloadCouponLeaderBoardAsync,
} from '../../redux/action';
import LeaderBoardPage from './leader-board.presentation';

const getInitialValues = (state) => {
  const { couponLeaderBoard, uiCouponLeaderBoard } = state;
  const { downloading } = uiCouponLeaderBoard;

  const initVal = !downloading && couponLeaderBoard.length > 0
    ? {
      result: couponLeaderBoard.map((item, i) => ({
        ...item,
        no: i + 1,
        user: {
          ...item.user,
          fullName: item.email ? `${item.user.fullName} (${item.email})` : item.user.fullName,
        },
      })),
    }
    : { result: [] };
  return initVal;
};

const mapStateToProps = (state) => ({
  downloading: state.uiCouponLeaderBoard.downloading,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_COUPON_LEADER_BOARD));
    dispatch(clearCouponLeaderBoard());
    dispatch(downloadCouponLeaderBoardAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyPressed: async ({
    startRank, count, startPeriod, endPeriod,
  }) => {
    try {
      await dispatch(downloadCouponLeaderBoardAsync(startRank, count, startPeriod, endPeriod));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COUPON_LEADER_BOARD));
  },
  onResetPressed: () => {
    dispatch(reset(RXFORM_COUPON_LEADER_BOARD));
    dispatch(clearCouponLeaderBoard());
    dispatch(downloadCouponLeaderBoardAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardPage);
