import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_CAR_SERVICE_EV_CHARGING_URL_CODE,
  RXFIELD_CAR_SERVICE_EV_CHARGING_URL_URL,
  RXFIELD_CAR_SERVICE_EV_CHARGING_URL_NOTES,
} from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateEvChargingUrl = (values) => {
  const requiredFields = [
    RXFIELD_CAR_SERVICE_EV_CHARGING_URL_CODE,
    RXFIELD_CAR_SERVICE_EV_CHARGING_URL_URL,
    RXFIELD_CAR_SERVICE_EV_CHARGING_URL_NOTES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
