import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { formValueSelector, reset, change } from 'redux-form';
import {
  addNotificationDialogFilterForm, clearNotificationDialogFilterForm, clearCompanies,
  setAlertErrorMessage,
  setBranchSearchText, setCompanySearchText,
  setDepartmentSearchText, setDivisionSearchText, setNotificationDialogVisibility,
  setNotificationSelectedBranch, setNotificationSelectedCompany,
  setTotalPersonCount,
  downloadCompaniesAsync,
  downloadTotalPersonCountAsync, sendNotificationsAsync,
  setCityAdvancedFilterDialogSelectedFilterString,
  setCitySearchText, clearCities, downloadCitiesAsync, setNotificationSelectedCity,
  setNotificationSelectedShellMembership, setNotificationSelectedUserTier,
} from '../../redux/action';
import {
  RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY, RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE,
  RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL, SHELL_MEMBERSHIP_ID_NOT_NULL,
  SHELL_MEMBERSHIP_ID_NULL, RXFIELD_SEND_PERSONAL_NOTIFICATION_SHELL_MEMBERSHIP,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_USER_TIER,
} from '../../constant';
import { transformDropdownData, debounceSearch } from '../../helper';
import SendNotificationDialog from './send-notification-dialog.presentation';

const selector = formValueSelector(RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL);

const getInitialValues = (state, ownProps) => {
  if (ownProps.initialValues) {
    return {
      ...ownProps.initialValues,
      ...state.form.sendPersonalNotificationModal.values,
      ...state.notification.filterForm,
    };
  }
  if (state.form.sendPersonalNotificationModal
    && !isEmpty(state.notification.filterForm)) {
    return { ...state.form.sendPersonalNotificationModal.values, ...state.notification.filterForm };
  }
  return state.notification.filterForm;
};

const mapStateToProps = (state, ownProps) => ({
  companies: transformDropdownData(state.companiesMdata.data),
  downloadingCompanies: state.notification.downloadingCompanies,
  downloadingTotalPersonCount: state.notification.downloadingTotalPersonCount,
  sendingNotifications: state.notification.sendingNotifications,
  visible: state.notification.visibility,
  totalPersonCount: state.notification.totalPersonCount,
  filterForm: state.notification.filterForm,
  initialValues: getInitialValues(state, ownProps),
  isEmptyRequiredFields: !!(!selector(state, RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE)
  || !selector(state, RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY)
  || (ownProps.sendToFieldName && !selector(state, ownProps.sendToFieldName))),
  cities: transformDropdownData(state.cities.data),
  loadingCity: state.uiCity.downloading,
});

const transformSelectedValue = (value) => value.reduce((prev, curr) => (
  prev ? `${prev};${curr.label || curr}` : curr.label || curr), '');

const searchCompanyDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearCompanies());
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchCityDebounce = debounceSearch(
  (dispatch) => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onCancelPressed: (useDefaultFilterSection) => {
    dispatch(setTotalPersonCount(0));
    dispatch(clearNotificationDialogFilterForm());
    dispatch(setNotificationDialogVisibility(false));
    dispatch(reset(RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL));

    if (useDefaultFilterSection) {
      dispatch(setBranchSearchText(''));
      dispatch(setCompanySearchText(''));
      dispatch(setDepartmentSearchText(''));
      dispatch(setDivisionSearchText(''));
      dispatch(setNotificationSelectedBranch(''));
      dispatch(setNotificationSelectedCompany(''));
      dispatch(setNotificationSelectedCity(''));
    }
  },
  onChangeCompanyText: (text) => {
    dispatch(setCompanySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchCompanyDebounce(dispatch, true);
    }
  },
  onChangeDate: (e, field) => {
    dispatch(addNotificationDialogFilterForm(field, e));
  },
  onChangeDropdown: (e, field) => {
    if (e) {
      dispatch(addNotificationDialogFilterForm(field, e));
    } else {
      dispatch(addNotificationDialogFilterForm(field, ''));
    }
  },
  onChangeNumber: (e, field) => {
    dispatch(addNotificationDialogFilterForm(field, e.target.value));
  },
  onChangeSwitch: (e, field) => {
    dispatch(addNotificationDialogFilterForm(field, e.value));
  },
  onCompanyOptionSelected: (value) => {
    dispatch(setNotificationSelectedCompany(transformSelectedValue(value)));
    dispatch(setTotalPersonCount(0));
  },
  onCountPressed: () => {
    dispatch(downloadTotalPersonCountAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
  onSendNotificationPressed: async (values, onSendPressed, useDefaultFilterSection,
    filterString) => {
    try {
      if (useDefaultFilterSection) {
        await dispatch(sendNotificationsAsync(values));
      } else {
        onSendPressed(values, filterString);
      }
      dispatch(setNotificationDialogVisibility(false));
      dispatch(reset(RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCityText: (text) => {
    dispatch(setCitySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchCityDebounce(dispatch, true);
    }
  },
  onCityOptionSelected: (option) => {
    if (option) {
      dispatch(setNotificationSelectedCity(option));
    } else {
      dispatch(setNotificationSelectedCity(''));
    }
  },
  onShellMembershipOptionSelected: (option) => {
    if (option) {
      const { value } = option;
      if (value) {
        dispatch(setNotificationSelectedShellMembership(SHELL_MEMBERSHIP_ID_NOT_NULL));
        dispatch(change(
          RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
          RXFIELD_SEND_PERSONAL_NOTIFICATION_SHELL_MEMBERSHIP, option,
        ));
      } else {
        dispatch(setNotificationSelectedShellMembership(SHELL_MEMBERSHIP_ID_NULL));
        dispatch(change(
          RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
          RXFIELD_SEND_PERSONAL_NOTIFICATION_SHELL_MEMBERSHIP, option,
        ));
      }
    } else {
      dispatch(setNotificationSelectedShellMembership(''));
      dispatch(change(
        RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL, RXFIELD_SEND_PERSONAL_NOTIFICATION_SHELL_MEMBERSHIP, '',
      ));
    }
  },
  onUserTierOptionSelected: (option) => {
    if (option) {
      dispatch(setNotificationSelectedUserTier(option));
      dispatch(change(
        RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
        RXFIELD_SEND_PERSONAL_NOTIFICATION_USER_TIER, option,
      ));
    } else {
      dispatch(setNotificationSelectedUserTier(''));
      dispatch(change(
        RXFORM_SEND_PERSONAL_NOTIFICATION_MODAL,
        RXFIELD_SEND_PERSONAL_NOTIFICATION_USER_TIER, '',
      ));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SendNotificationDialog);
