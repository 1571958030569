import moment from 'moment';
import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_TEST_DRIVE_EVENT_NAME, RXFIELD_TEST_DRIVE_EVENT_START_TIME,
  RXFIELD_TEST_DRIVE_EVENT_END_TIME,
  RXFIELD_TEST_DRIVE_EVENT_DESCRIPTION, RXFIELD_TEST_DRIVE_EVENT_LOCATION, RXFORM_EVENT,
  RXFIELD_TEST_DRIVE_EVENT_VISIBILITY, RXFIELD_TEST_DRIVE_EVENT_PROVINCE,
  RXFIELD_TEST_DRIVE_EVENT_CITY, RXFIELD_TEST_DRIVE_EVENT_VOUCHER,
  RXFIELD_EVENT_ORGANIZER_USER_FULLNAME, RXFIELD_EVENT_ORGANIZER_EVENT_NAME,
} from './constant';
import LocalizedString from '../../localization';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateEvent = (values) => {
  const requiredFields = [
    RXFIELD_TEST_DRIVE_EVENT_NAME, RXFIELD_TEST_DRIVE_EVENT_START_TIME,
    RXFIELD_TEST_DRIVE_EVENT_END_TIME,
    RXFIELD_TEST_DRIVE_EVENT_DESCRIPTION, RXFIELD_TEST_DRIVE_EVENT_LOCATION, RXFORM_EVENT,
    RXFIELD_TEST_DRIVE_EVENT_VISIBILITY, RXFIELD_TEST_DRIVE_EVENT_PROVINCE,
    RXFIELD_TEST_DRIVE_EVENT_CITY, RXFIELD_TEST_DRIVE_EVENT_VOUCHER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.endTime).isAfter(moment(values.startTime))) {
    errors.endTime = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateEventOrganizer = (values) => {
  const requiredFields = [
    RXFIELD_EVENT_ORGANIZER_USER_FULLNAME,
    RXFIELD_EVENT_ORGANIZER_EVENT_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  return errors;
};
