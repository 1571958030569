import { reset } from 'redux-form';
import {
  addingEditingDeviceException, clearDeviceExceptions, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { addDeviceException, editDeviceException } from '../../../helper';
import { RXFORM_DEVICE_EXCEPTION, PAGE_MODE_TABLE } from '../../../constant';
import downloadDeviceExceptionsAsync from './downloadDeviceExceptionsAsync';

export default (deviceId, label, maxRegistration) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingDeviceException(true));

    const { token } = getState().authentication;
    const {
      tappedId,
    } = getState().uiDeviceException;

    if (tappedId) {
      await editDeviceException(tappedId, deviceId, label, maxRegistration, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addDeviceException(deviceId, label, maxRegistration, token);
    }

    dispatch(reset(RXFORM_DEVICE_EXCEPTION));
    dispatch(clearDeviceExceptions());
    dispatch(downloadDeviceExceptionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingDeviceException(false));
  }
};
