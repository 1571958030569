import {
  downloadingAttendanceSummaryTodayClockInStatus, setAttendanceSummaryTodayClockInStatus,
} from '../simple-action';
import { downloadAttendanceSummaryTodayClockInStatus } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTodayClockInStatus(true));

    const { authentication } = getState();
    const { token } = authentication;

    const data = await downloadAttendanceSummaryTodayClockInStatus(token);

    dispatch(setAttendanceSummaryTodayClockInStatus(data));
  } finally {
    dispatch(downloadingAttendanceSummaryTodayClockInStatus(false));
  }
};
