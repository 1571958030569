import { downloadAccessoryTransaction } from '../../../helper';
import { addAccessoryTransaction, downloadingDeletingAccessoryTransaction } from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingAccessoryTransaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAccessoryTransaction;

    const accessoryTransaction = await downloadAccessoryTransaction(tappedId, token);
    dispatch(addAccessoryTransaction(accessoryTransaction));
  } finally {
    dispatch(downloadingDeletingAccessoryTransaction(false));
  }
};
