import {
  addCouponTransaction, downloadingDeletingCouponTransaction, setFunctionalPageMode,
} from '../simple-action';
import { downloadCouponTransaction } from '../../../helper';
import { PAGE_MODE_VIEW } from '../../../constant';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCouponTransaction(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCouponTransaction;

    const couponTransaction = await downloadCouponTransaction(tappedId, token);
    dispatch(addCouponTransaction(couponTransaction));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingCouponTransaction(false));
  }
};
