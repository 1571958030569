import { reset } from 'redux-form';
import { RXFORM_COUPON_SUBMISSION } from '../../../constant';
import { addCouponSubmission, localDateToUtc } from '../../../helper';
import { addingCouponSubmission, clearCouponSubmissions, setAlertErrorMessage } from '../simple-action';
import downloadCouponSubmissionsAsync from './downloadCouponSubmissionsAsync';

export default (merchantReferenceId, tags, amount,
  validUntil, label, callbackUrl) => async (dispatch, getState) => {
  try {
    dispatch(addingCouponSubmission(true));

    const { token } = getState().authentication;
    const { selectedUser, selectedSendNotification } = getState().uiCouponSubmission;

    await addCouponSubmission(merchantReferenceId, tags, amount,
      localDateToUtc(validUntil), label,
      selectedUser.value, callbackUrl, selectedSendNotification.value, token);

    dispatch(reset(RXFORM_COUPON_SUBMISSION));
    dispatch(clearCouponSubmissions());
    dispatch(downloadCouponSubmissionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingCouponSubmission(false));
  }
};
