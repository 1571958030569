import { downloadVoucherUsages, transformSearchText } from '../../../helper';
import { downloadingVoucherUsages, setVoucherUsages } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, voucherUsages, uiVoucherUsage } = getState();
  const { token } = authentication;
  const { meta } = voucherUsages;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiVoucherUsage;

  try {
    dispatch(downloadingVoucherUsages(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadVoucherUsages(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setVoucherUsages(list));
    }
  } finally {
    dispatch(downloadingVoucherUsages(false));
  }
};
