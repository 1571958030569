import { downloadVehicleModels } from '../../../helper';
import { downloadingVehicleModels, setVehicleModels } from '../simple-action';

export default () => async (dispatch, getState) => {
  const { authentication } = getState();
  const { token } = authentication;

  try {
    dispatch(downloadingVehicleModels(true));

    const list = await downloadVehicleModels(token);

    if (list) {
      dispatch(setVehicleModels(list));
    }
  } finally {
    dispatch(downloadingVehicleModels(false));
  }
};
