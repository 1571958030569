import {
  addMembershipReward, downloadingDeletingMembershipReward, setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_VIEW } from '../../../constant';
import { downloadMembershipReward } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingMembershipReward(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiMembershipReward;

    const membershipReward = await downloadMembershipReward(tappedId, token);
    dispatch(addMembershipReward(membershipReward));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  } finally {
    dispatch(downloadingDeletingMembershipReward(false));
  }
};
