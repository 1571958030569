import { reset } from 'redux-form';
import { RXFORM_POINT_VOID_REQUEST } from '../../../constant';
import { addVoidPointRequest } from '../../../helper';
import { addingVoidPointRequest, clearVoidPointRequests, setAlertErrorMessage } from '../simple-action';
import downloadVoidPointRequestsAsync from './downloadVoidPointRequestsAsync';

export default (reason, merchantReferenceId, callbackUrl) => async (dispatch, getState) => {
  try {
    dispatch(addingVoidPointRequest(true));

    const { token } = getState().authentication;
    const { selectedPoint } = getState().uiPointVoidRequest;
    const point = selectedPoint.value;

    await addVoidPointRequest(reason, merchantReferenceId, callbackUrl, point, token);

    dispatch(reset(RXFORM_POINT_VOID_REQUEST));
    dispatch(clearVoidPointRequests());
    dispatch(downloadVoidPointRequestsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingVoidPointRequest(false));
  }
};
