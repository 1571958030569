import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT,
  RXSTATE_GAME_ACTIVITY_REGISTRATION_REFERRALS,
  RXSTATE_GAME_ACTIVITY_REGISTRATION_REFERRAL_PAGE,
  RXFORM_GAME_ACTIVITY_REGISTRATION_REFERRAL,
  RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER_ID,
  RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER_NAME,
  RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_POINT,
  RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER_USER_ID,
  RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER_USER_NAME,
  RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER,
  RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER,
} from '../../constant';

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationReferralPage.placeholderRegisteredUserID}
          label={LocalizedString.registrationReferralPage.labelRegisteredUserID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationReferralPage.placeholderRegisteredUserName}
          label={LocalizedString.registrationReferralPage.labelRegisteredUserName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_POINT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationReferralPage.placeholderPoint}
          label={LocalizedString.registrationReferralPage.labelPoint}
          disabled
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER_USER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationReferralPage.placeholderReferrerUserID}
          label={LocalizedString.registrationReferralPage.labelReferrerUserID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER_USER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.registrationReferralPage.placeholderReferrerUserName}
          label={LocalizedString.registrationReferralPage.labelReferrerUserName}
          disabled
        />
      </Grid>
    </Grid>
  </Grid>
);

const GameActivityRegistrationReferralPage = ({
  onAppear, downloading, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues, onAdvancedFilterPressed, onDownloadPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  referrers, users, loadingUsers, loadingReferrers, onChangeUserText, onChangeReferrerText,
}) => (
  <FunctionalPage
    data={RXSTATE_GAME_ACTIVITY_REGISTRATION_REFERRALS}
    uiPage={RXSTATE_GAME_ACTIVITY_REGISTRATION_REFERRAL_PAGE}
    tableColumns={[
      {
        title: LocalizedString.registrationReferralPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.registrationReferralPage.labelRegisteredUser, field: 'referee.fullName', sorting: !downloading },
      { title: LocalizedString.registrationReferralPage.labelReferrer, field: 'referrer.fullName', sorting: !downloading },
      { title: LocalizedString.registrationReferralPage.labelPoint, field: 'pointAmount', sorting: !downloading },
      {
        title: LocalizedString.registrationReferralPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.registrationReferralPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.registrationReferralPage.labelRegisteredUser,
        field: RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REGISTERED_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUsers,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.registrationReferralPage.labelReferrer,
        field: RXFIELD_GAME_ACTIVITY_REGISTRATION_REFERRAL_REFERRER,
        type: FILTER_TYPE_DROPDOWN,
        loading: loadingReferrers,
        data: referrers,
        onChangeFilterText: onChangeReferrerText,
        useDropdownValue: true,
      },
    ]}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onDownloadPressed={onDownloadPressed}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.registrationReferralPage.title}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    enableSave
    useFullWidth
  >
    {renderDialogContent(initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_GAME_ACTIVITY_REGISTRATION_REFERRAL,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(GameActivityRegistrationReferralPage);

GameActivityRegistrationReferralPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  referrers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingReferrers: PropTypes.bool.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onChangeReferrerText: PropTypes.func.isRequired,
};
