import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_DATE_TIME_FORMAT, PICKER_MODE_DATE_TIME, RXFORM_MEMBERSHIP_REWARD,
  RXSTATE_MEMBERSHIP_REWARDS, RXSTATE_MEMBERSHIP_REWARD_PAGE,
  RXFIELD_MEMBERSHIP_REWARD_TIER, RXFIELD_MEMBERSHIP_REWARD_REWARD_AMOUNT,
  RXFIELD_MEMBERSHIP_REWARD_SCHEDULED_REWARD, RXFIELD_MEMBERSHIP_REWARD_ALLOCATION_STATUS,
  RXFIELD_MEMBERSHIP_REWARD_USER_ID, RXFIELD_MEMBERSHIP_REWARD_USER_NAME,
  RXFIELD_MEMBERSHIP_REWARD_NOTES, RXFIELD_MEMBERSHIP_REWARD_STOPPED_AT,
  MEMBERSHIP_REWARD_ALLOCATION_STATUS, PAGE_MODE_TABLE,
} from '../../constant';
import { toMoment, toCurrency } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormEditableTableField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: 'relative',
    paddingTop: 10,
  },
}));

const renderDialogContent = (
  itemInitialValues, classes, downloadingDeleting,
  isStopped, pageMode, totalCurrentPage, tokenTotalCount,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_MEMBERSHIP_REWARD_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.membershipRewardPage.placeholderUserId}
            label={LocalizedString.membershipRewardPage.placeholderUserId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_MEMBERSHIP_REWARD_USER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.membershipRewardPage.placeholderUserName}
            label={LocalizedString.membershipRewardPage.placeholderUserName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_MEMBERSHIP_REWARD_ALLOCATION_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.membershipRewardPage.placeholderAllocationStatus}
            label={LocalizedString.membershipRewardPage.placeholderAllocationStatus}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_MEMBERSHIP_REWARD_STOPPED_AT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.membershipRewardPage.placeholderStoppedAt}
            label={LocalizedString.membershipRewardPage.placeholderStoppedAt}
            disabled
            hidden={!isStopped}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_MEMBERSHIP_REWARD_TIER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.membershipRewardPage.placeholderTier}
            label={LocalizedString.membershipRewardPage.placeholderTier}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_MEMBERSHIP_REWARD_REWARD_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.membershipRewardPage.placeholderRewardAmount}
            label={LocalizedString.membershipRewardPage.placeholderRewardAmount}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_MEMBERSHIP_REWARD_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.membershipRewardPage.placeholderNotes}
            label={LocalizedString.membershipRewardPage.placeholderNotes}
            disabled
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
    {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
      <Grid item sm={12} className={classes.tableContainer}>
        <Field
          name={RXFIELD_MEMBERSHIP_REWARD_SCHEDULED_REWARD}
          component={renderReduxFormEditableTableField}
          disabled
          disableToolbar
          hidden={pageMode === PAGE_MODE_TABLE}
          loading={downloadingDeleting}
          currentPage={totalCurrentPage}
          totalCount={tokenTotalCount}
          defaultValue={itemInitialValues}
          tableColumns={[
            {
              title: LocalizedString.membershipRewardPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            { title: LocalizedString.membershipRewardPage.labelScheduledDate, field: 'scheduledDate', sorting: false },
            { title: LocalizedString.membershipRewardPage.labelAmount, field: 'amount', sorting: false },
            { title: LocalizedString.membershipRewardPage.labelActualDate, field: 'actualDate', sorting: false },
            { title: LocalizedString.membershipRewardPage.labelRecipientId, field: 'recipient.id', sorting: false },
            { title: LocalizedString.membershipRewardPage.labelRecipientName, field: 'recipient.fullName', sorting: false },
            { title: LocalizedString.membershipRewardPage.labelNotes, field: 'notes', sorting: false },
          ]}
        />
      </Grid>
    )}
  </Grid>
);

const MembershipRewardPage = ({
  initialValues, downloading, downloadingDeleting,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onChangePage, onChangePageSize, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  totalCurrentPage, totalCount, pageMode, users, loadingUser, onChangeUserText,
  onAdvancedFilterPressed, isStopped,
}) => {
  const classes = useStyles();
  return (
    <FunctionalPage
      data={RXSTATE_MEMBERSHIP_REWARDS}
      uiPage={RXSTATE_MEMBERSHIP_REWARD_PAGE}
      filterColumns={[
        {
          title: LocalizedString.membershipRewardPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_FORMAT,
        },
        {
          title: LocalizedString.registrationPage.labelUser,
          field: 'profile',
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUser,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.membershipRewardPage.labelAllocationStatus,
          field: 'rewardAllocationStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: MEMBERSHIP_REWARD_ALLOCATION_STATUS,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.membershipRewardPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.membershipRewardPage.labelUser, field: 'profile.fullName', sorting: !downloading },
        { title: LocalizedString.membershipRewardPage.labelTier, field: 'tier', sorting: !downloading },
        {
          title: LocalizedString.membershipRewardPage.labelAmount,
          field: 'rewardAmount',
          sorting: !downloading,
          render: ({ rewardAmount }) => (rewardAmount
            ? toCurrency(rewardAmount) : toCurrency(0)),
        },
        {
          title: LocalizedString.membershipRewardPage.labelAllocationStatus,
          field: 'rewardAllocationStatus',
          sorting: !downloading,
        },
        {
          title: LocalizedString.membershipRewardPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      disableCreate
      disableDelete
      disableEdit
      title={LocalizedString.membershipRewardPage.title}
      useFullWidth
      usefullWidthDialog
    >
      {renderDialogContent(initialValues.items, classes, downloadingDeleting,
        isStopped, pageMode, totalCurrentPage, totalCount)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_MEMBERSHIP_REWARD,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(MembershipRewardPage);

MembershipRewardPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  totalCurrentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageMode: PropTypes.string.isRequired,
  isStopped: PropTypes.string,
};

MembershipRewardPage.defaultProps = {
  isStopped: '',
};
