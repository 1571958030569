import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  clearDeviceExceptions, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setDeviceExceptionSearchText,
  setDeviceExceptionSelectedPageSize, setDeviceExceptionSelectedOrderBy, setDeviceExceptionTappedId,
  addEditDeviceExceptionAsync, downloadDeviceExceptionsAsync,
  downloadDeleteDeviceExceptionAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_DEVICE_EXCEPTIONS, PAGE_MODE_TABLE, MENUID_AUTHENTICATION_DEVICE_EXCEPTION,
  RXFORM_DEVICE_EXCEPTION,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import DeviceExceptionPage from './device-exception.presentation';

const getInitialValues = (state) => {
  const {
    deviceExceptions, uiFunctionalPage, uiDeviceException,
  } = state;
  const { data } = deviceExceptions;
  const {
    downloadingDeleting, tappedId,
  } = uiDeviceException;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found) : {};
  return initVal;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiDeviceException.addingEditing,
  downloading: state.uiDeviceException.downloading,
  downloadingDeleting: state.uiDeviceException.downloadingDeleting,
  deviceExceptions: state.deviceExceptions.data,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_AUTHENTICATION_DEVICE_EXCEPTION));
    dispatch(setDeviceExceptionSearchText(''));
    dispatch(clearDeviceExceptions());
    dispatch(setDeviceExceptionSelectedPageSize(20));
    dispatch(setDeviceExceptionSelectedOrderBy(INITIAL_ORDER_BY_DEVICE_EXCEPTIONS));
    dispatch(downloadDeviceExceptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_DEVICE_EXCEPTION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDeviceExceptionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDeviceExceptionSelectedPageSize(pageSize));
    dispatch(downloadDeviceExceptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteDeviceExceptionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setDeviceExceptionTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setDeviceExceptionTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setDeviceExceptionTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setDeviceExceptionSelectedPageSize(pageSize));
    dispatch(clearDeviceExceptions());
    dispatch(downloadDeviceExceptionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ deviceId, label, maxRegistration }) => {
    try {
      await dispatch(addEditDeviceExceptionAsync(deviceId, label, maxRegistration));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setDeviceExceptionSearchText(text));
      dispatch(clearDeviceExceptions());
      await dispatch(downloadDeviceExceptionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setDeviceExceptionSelectedOrderBy(orderBy));
    dispatch(clearDeviceExceptions());
    dispatch(downloadDeviceExceptionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ deviceId, label, maxRegistration }) => {
    await dispatch(addEditDeviceExceptionAsync(deviceId, label, maxRegistration));
  },
  onViewPressed: (id) => {
    dispatch(setDeviceExceptionTappedId(id));
    dispatch(downloadDeleteDeviceExceptionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceExceptionPage);
